import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import { keyAndClick } from '../../util/ui-a11y';

export const useStyles = makeStyles(theme => ({
	checkmark: {
		position: 'relative',
		borderRadius: '50%',
		color: theme.colors.pureWhite,
		top: '4px',
		padding: '5px',
		'&.correct': {
			background: theme.colors.icons.successGreen
		},
		'&.incorrect': {
			background: theme.colors.icons.errorRed
		}
	},
	hotspotImageContainer: {
		width: '600px',
		height: '336px',
		position: 'relative',
		margin: '0 auto'
	},
	hotspotImage: {
		width: '100%',
		height: '100%',
		borderRadius: '10px'
	},

	hotspot: {
		position: 'absolute',
		width: '40px',
		height: '40px',
		border: `4px solid ${theme.colors.white}`,
		borderRadius: '100%',
		margin: '-2px',
		top: 0,
		left: 0,
		textAlign: 'center'
	},
	radioButton: {
		display: 'none'
	},
	hotspotContainer: {
		position: 'absolute',
		width: '48px',
		height: '48px',
		borderRadius: '100%',
		border: '6px solid rgb(19,19,19,0.55)',
		cursor: props => props.interactive ? 'pointer' : 'default',
		transition: 'all 0.3s ease',
		'&:hover, &.selected, &:focus': {
			transform: 'scale(1.6)',
			boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.75)'
		}
	},
	innerBigCircle: {
		width: '24px',
		height: '24px',
		borderRadius: '50%',
		backgroundColor: theme.colors.readingZone.primary,
		transform: 'translate(16%, 16%)',
		display: 'block'
	},

	innerSmallCircle: {
		transform: 'translate(100%, 100%)',
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		backgroundColor: theme.colors.white,
		display: 'block'
	}
}));

const ImageWithHotSpot = ({
	hotspotOptions,
	questionImage,
	interactive,
	value,
	onSelection
}) => {
	const classes = useStyles({interactive});
	const handleRadioChange = (e, id) => {
		e.preventDefault();
		if (!interactive) {
			return;
		}
		onSelection(id);
	};

	const generateHotSpots = () =>
		hotspotOptions.map((hotspot, index) => (
			<div key={index}>
				<input
					className={classes.radioButton}
					value={`hotspot-${index}`}
					id={hotspot.id}
					checked={interactive && value.includes(hotspot.id)}
					name="hotspot-radio"
					type="radio"
					data-testid={`hotspotOption-${index}`}
					onChange={e => handleRadioChange(e, hotspot.id)}
				></input>

				{/* The standard image dimension are 1694 x 1000 pixels.
					As we maintain fixed width and height of 600px and 336px respectively
					need to scale the image dimensions we used 2.825 fow width and 2.976 for height as a scale factor.
					20 pixels are subracted as the hospot circles are of 40px raduis */}

				<label
					className={`${classes.hotspotContainer} ${value.includes(hotspot.id) ? 'selected': ''}`}
					htmlFor={hotspot.id}
					style={{
						left: `${hotspot.image_coord_x / 2.825 - 20}px`,
						top: `${hotspot.image_coord_y / 2.976 - 20}px`
					}}
					tabIndex={0}
					{...keyAndClick((e) => {handleRadioChange(e, hotspot.id)})}
				>
					<span className={classes.hotspot}>
						{renderTickMark(hotspot)}
					</span>
				</label>
			</div>
		));

	const renderTickMark = hotspotOption => {
		if (interactive) {
			if (value.includes(hotspotOption.id)) {
				return (
					<span className={classes.innerBigCircle}>
						<span className={classes.innerSmallCircle}></span>
					</span>
				);
			}
			return null;
		} else {
			switch (hotspotOption.status) {
				case 'correct':
					return (
						<i
							className={`r180 check ${classes.checkmark} correct`}
						/>
					);

				case 'incorrect':
					return (
						<i
							className={`r180 x ${classes.checkmark} incorrect`}
						/>
					);

				default:
					return null;
			}
		}
	};

	return (
		<div className={classes.hotspotImageContainer}>
			<img src={questionImage} alt="" className={classes.hotspotImage} />
			<form>{generateHotSpots()}</form>
		</div>
	);
};

ImageWithHotSpot.defaultProps = {
	hotspotOptions: [],
	interactive: true,
	value: []
};

ImageWithHotSpot.propTypes = {
	questionImage: PropTypes.string.isRequired,
	onSelection: PropTypes.func.isRequired,
	interactive: PropTypes.bool,
	value: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	),
	hotspotOptions: PropTypes.arrayOf(
		PropTypes.shape({
			image_coord_x: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			]),
			image_coord_y: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			]),
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			status: PropTypes.string
		})
	)
};
export default React.memo(ImageWithHotSpot);
