import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ActivityButton from '../ActivityButton';
import MagicTextArea from '../MagicTextArea/MagicTextArea';
import {convertTagsToText, formattedText} from './WritingUtil';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import { isFirefox } from '@reading/r180/src/utils/browsers';
import { sleep } from '@reading/r180/src/utils/sleep';
import { isEmpty } from 'lodash';

export const useStyles = makeStyles(theme => ({
	'@keyframes grow': {
		'0%': {
			transform: 'scale(0.8)'
		},
		'100%': {
			transform: 'scale(1)'
		}
	},
	'@keyframes shrink': {
		'0%': {
			transform: 'scale(1)'
		},
		'100%': {
			transform: 'scale(0.8)'
		}
	},
	wrapper: {
		width: '752px',
		height: 'auto',
		borderRadius: '8px',
		border: `2px solid ${theme.colors.white}`,
		background: theme.colors.white,
		overflow: 'hidden',
        boxShadow: '0 0 6px rgba(0,0,0,0.12)',
		animation: '$grow 0.6s ease-in',

		'&.shrinking': {
			animation: '$shrink 0.4s ease-in',
		},
	},
	titleBar: {
		position: 'relative',
		width: '100%',
		height: '48px',
		padding: '8px 16px',
		background: theme.colors.writingZone.primary,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		alignItems: 'stretch',
		'& .letter': {
			display: 'inline-block',
			width: '32px',
			height: '32px',
			borderRadius: '16px',
			background: theme.colors.white,
			border: `2px solid ${theme.colors.writingZone.green3}`,
			boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.35)',
			color: theme.colors.writingZone.background,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '24px',
			textAlign: 'center',
			marginRight: '16px',
			lineHeight: isFirefox() ? '26px' : '28px'
		},
		'& .title': {
			display: 'inline-block',
			color: theme.colors.white,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '24px',
			position: 'relative',
			top: isFirefox() ? '-1px' : '-2px'
		},
		'& .info': {
			position: 'absolute',
			top: isFirefox() ? '10px' : '12px',
			right: '12px',
			width: '24px',
			height: '24px',
			background: theme.colors.writingZone.primary,
			borderRadius: '12px',
			color: theme.colors.white,
			fontSize: '24px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	},
	inactiveTitleBar: {
		position: 'relative',
		width: '100%',
		height: '32px',
		padding: '4px 24px',
		background: theme.colors.writingZone.green4,
		marginTop: '3px',
		'& .title': {
			display: 'inline-block',
			color: theme.colors.writingZone.background,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '18px'
		}
	},
	completedWriting: {
		fontSize: '18px',
		fontFamily: theme.fonts.context,
		color: theme.colors.grey,
		width: '100%',
		lineHeight: `32px`,
		padding: '16px'
	},
	writingArea: {
		width: '100%',
		height: 'auto',
		minHeight: '120px',
		padding: '10px 20px 0 20px',
		background: theme.colors.white
	},
	buttonRow: {
		width: '100%',
		padding: '32px 16px',
		background: theme.colors.white,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'& button:first-of-type': {
			marginRight: '32px'
		}
	}
}));

const WritingPanel = React.forwardRef((props, ref) => {
	const {
		id,
		className,
		sections,
		activePanelIndex,
		completedPanelData,
		setCompletedPanel,
		orderID,
		resetClicked,
		isResetClicked,
		panelGOStatus,
		updatePanelsGo,
		setSelectedIndex,
		mode,
		onInfo
	} = props;

	const classes = useStyles();

	const [selectedPanelIndex, setSelectedPanelIndex] = useState(activePanelIndex);
	const [isShrinking, setShrinking] = useState(false);

	const acronymMap = {
		informative: {
			acronymIndex: {
				thesis: 0,
				idea: 1,
				detail: 2,
				end: 3
			}
		},
		argument: {
			acronymIndex: {
				thesis: 0,
				reason: 1,
				evidence: 2,
				negativeEnd: 3,
				positiveEnd: 3
			}
		}
	};

	const handleInfo = (sectionId, sectionIndex) => {
		onInfo(
			id,
			sectionId,
			sectionIndex,
			sectionIndex === selectedPanelIndex
		);
	};

	const updateGoOn = currID => {
		const panelsSatatus = {
			...panelGOStatus
		};
		if (sections.length > 1) {
			panelsSatatus[`${id}${currID}`] = false;
		} else {
			panelsSatatus[currID] = false;
		}

		updatePanelsGo(panelsSatatus);
	};

	const handleReset = (sectionId, sectionIndex, disabledPanel) => {
		if (disabledPanel) {
			return false;
		}
		AudioPlayer2.stopAll();
		const {onReset} = props;
		onReset(id, sectionId, sectionIndex);
	};

	let disabledPanel;

	if (sections.length > 1) {
		disabledPanel = panelGOStatus[`${id}${sections[selectedPanelIndex].id}`];
	} else {
		disabledPanel = panelGOStatus[id];
	}

	const handleGoOn = async(sectionId, sectionIndex, text, disabledPanel) => {
		if (disabledPanel) {
			return false;
		}

		AudioPlayer2.stopAll();

		setShrinking(true);
		await sleep(400);
		setShrinking(false);

		const panelName = sections[0].id;
		const {onGoOn} = props;
		onGoOn(id, sectionId, sectionIndex);
		setSelectedPanelIndex(selectedPanelIndex + 1);
		const panelUniqueID = `${sectionId}${orderID}`;
		completedPanelData[panelUniqueID] = formattedText(text);
		setCompletedPanel(
			completedPanelData,
			panelUniqueID,
			panelName,
			sectionId,
			sectionIndex,
			id
		);
	};

	const handleMinimumCountMet = (id) => {
		updateGoOn(id);
	}

	const handleMinimumCountUnmet = (id) => {
		const panelsSatatus = {
			...panelGOStatus
		};
		if (sections.length > 1) {
			panelsSatatus[`${id}${id}`] = true;
		} else {
			panelsSatatus[id] = true;
		}

		updatePanelsGo(panelsSatatus);
	}

	const handleTextChange = (sectionId, sectionIndex, text) => {
		AudioPlayer2.stopAll();
		const {onTextChange} = props;
		onTextChange(id, sectionId, sectionIndex, text);
	};

	useEffect(() => {
		sections.forEach((section, idx) => {
			/* highlight the current section in the Acronym Board on left */
			if (idx === selectedPanelIndex) {
				setSelectedIndex(
					acronymMap[mode].acronymIndex[
						section.id.replace(/[0-9]/g, '')
					]
				);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPanelIndex]);

	return (
		<div
			ref={ref}
			id={id}
			className={`writing-panel ${classes.wrapper} ${className}`}
		>
			{sections.map((section, idx) => {
				const {id, letter, title, text, originalText} = section;
				const active = idx === selectedPanelIndex;
				const completed = idx < selectedPanelIndex;
				const inactive = active === false && completed === false;

				return (
					<div
						key={`section-${idx}`}
						className={`section
									${active ? 'active' : ''}
									${completed ? 'completed' : ''}
									${inactive ? 'inactive' : ''}
									${isShrinking ? 'shrinking' : ''}
								`}
					>
						{inactive === false ? (
							<div className={`${classes.titleBar}`}>
								<div className={`letter`}>{letter}</div>
								<div className="title">{title}</div>
								<button
									className="info"
									onClick={() => handleInfo(id, idx)}
								>
									<i className="r180 instructions" />
								</button>
							</div>
						) : (
							<div className={`${classes.inactiveTitleBar}`}>
								<div className="title">{title}</div>
							</div>
						)}

						{active && (
							<>
								<div className={`${classes.writingArea}`}>
									<MagicTextArea
										startText={text}
										onTextChange={text =>
											handleTextChange(id, idx, text)
										}
										isResetTriggered={isResetClicked}
										resetClicked={resetClicked}
										updateGoOn={() => {}}
										id={id}
										originalText={originalText}
										onMinimumCountMet={handleMinimumCountMet}
										onMinimumCountUnmet={handleMinimumCountUnmet}
									/>
								</div>
								<div className={`${classes.buttonRow}`}>
									<ActivityButton
										onClick={() =>
											handleReset(id, idx, disabledPanel)
										}
										icon="reset"
										id="reset"
										isPrimary={false}
										text="Reset"
										disabled={disabledPanel}
									/>
									<ActivityButton
										onClick={() =>
											handleGoOn(
												id,
												idx,
												text,
												disabledPanel
											)
										}
										icon="arrow-right"
										data-testid="go-on"
										isPrimary={true}
										text="Go On"
										disabled={disabledPanel}
									/>
								</div>
							</>
						)}
						{completed && (
							<div className={`${classes.completedWriting}`}>
								{convertTagsToText(text)}
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
});

WritingPanel.defaultProps = {
	id: '',
	className: '',
	sections: [],
	activePanelIndex: 0
};
WritingPanel.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	sections: PropTypes.array.isRequired,
	activePanelIndex: PropTypes.number
};

export default React.memo(WritingPanel);
