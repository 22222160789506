import React from 'react';
import { Modal } from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";
import {clusterStyles} from "@reading/r180/src/utils/constants";
import AnchorVideo from '../../../components/AnchorVideo';
import { MEDIA_SERVER_URL } from '@reading/r180/src/utils/constants';
import { ActivityButton, SoundButton } from '../../../index';
import ClusterSounds from '../ClusterSounds/ClusterSounds';
import { keyAndClick } from '../../../util/ui-a11y';
import { bulkReplace } from '@reading/r180/src/utils/stringUtils';
import { MEDIA } from '@reading/r180/src/activities/SegmentSelection/SegmentSelection';
import { isFirefox } from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme=>({
	modalBody:{
		width: '608px',
		height: 'auto',
		maxHeight: '670px',
		background: theme.colors.white,
		color: theme.colors.black,
		position: 'absolute',
		left: '50%',
		'@media (min-height: 765px)': {
			top: '50% !important',
			transform: 'translate(-50%, -50%)',
		},
		'@media (max-height: 764.9px)': {
			top: '10px !important',
			transform: 'translate(-50%, 0)',
			maxHeight: '96vh'
		},
		padding: '0',
		overflowX: 'hidden',
		overflowY: isFirefox() ? 'auto' : 'overlay',
        '&::-webkit-scrollbar': {
            width: '6px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            padding: '10px 0'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#CCC',
            borderRadius: '8px'
        },
	},
	videoPlayer:{
		width:'100%',
	},
	content: {
		padding: '40px 40px 40px 64px'
	},
	title: {
		fontSize: '32px',
		fontFamily: theme.fonts.uiBold,
		position: 'relative'
	},
	titleSoundButton: {
		position: 'absolute',
		left: '-40px',
		top: '7px',
		border: 'none',
		fontSize: '16px',
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.3)'
		}
	},
	description: {
		marginTop:'8px',
		fontSize:'18px',
		fontFamily: theme.fonts.uiBold,
	},
	clustersButtons: {
		marginTop: '32px',
		color: theme.colors.white,
		display: 'flex',
		flexDirection: 'row',
		justifyContent:' space-between',
	},
	clusters: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	clusterIcon: {
		background: theme.colors.black,
		color: theme.colors.white,
		width: '48px',
		height: '48px',
		borderRadius: '24px',
		marginRight: '40px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontFamily: 'r180',
		fontSize: '32px',
		cursor: 'pointer',
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.3)'
		}
	},
	...clusterStyles
}));

const SegmentPreviewModal = (props) => {
	const { open, onClose, selectedSegment, stage, onSubmit } = props;
	const classes = useStyles();

	const mediaConstants = {
		stage: stage.toLowerCase(),
		segment: selectedSegment.segment_number.toString().padStart(2, '0')
	};

	const posterSrc = `${MEDIA_SERVER_URL}${bulkReplace(
		MEDIA.segmentFeature,
		mediaConstants
	)}`;

	const videoSrc = `${MEDIA_SERVER_URL}${bulkReplace(
		MEDIA.segmentVideo,
		mediaConstants
	)}`;

	const selectedSegmentSound = {
		name: selectedSegment.id,
		src: `${MEDIA_SERVER_URL}${bulkReplace(
			MEDIA.segmentTitle,
			mediaConstants
		)}`
	};
	const playSounds = [];

	return (
		<Modal open={open} onClose={onClose} className='r180-modal'>
			<div
				className={`${classes.modalBody} modal-window`}
				aria-label={'Segment Preview'}
				role={'dialog'}
			>
				<AnchorVideo
					id="segmentPreviewVideo"
					containerStyle={{ width: '608px' }}
					poster={posterSrc}
					autoPlay={false}
					aria-label={'Anchor video'}
					compact={true}
					hideCloseButton={false}
					handleCloseButton={onClose}
				>
					<source src={videoSrc} type={'video/mp4'} />
				</AnchorVideo>
				<div className={classes.content}>
					<div
						className={classes.title}
						aria-label={'Segment title'}
						role={'region'}
					>
						<SoundButton
							size={32}
							className={`${classes.titleSoundButton}`}
							alt={selectedSegment.name}
							sound={selectedSegmentSound}
							aria-label={`${selectedSegment.name} VO`}
							role={'button'}
						/>
						{selectedSegment.name}
					</div>
					<div
						role={'region'}
						className={classes.description}
						aria-label={'Segment description'}
					>
						{selectedSegment.description}
					</div>
					<div className={classes.clustersButtons}>
						<div
							className={classes.clusters}
							aria-label={'Segment clusters'}
							role={'group'}
						>
							{selectedSegment.clusters.map(cluster => {
								playSounds[cluster.id] = ClusterSounds(cluster);
								return (
									<span
										key={`clusterSound${cluster.id}`}
										size={48}
										className={`${classes.clusterIcon} ${classes[`cluster_${cluster.id}`]
											}`}
										alt={cluster.title}
										{...keyAndClick(playSounds[cluster.id])}
										tabIndex={0}
										aria-label={cluster.name}
										role={'button'}
									/>
								);
							})}
						</div>
						<ActivityButton
							datatestid='submitButton'
							text="Submit"
							icon="check"
							onClick={onSubmit}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default React.memo(SegmentPreviewModal);
