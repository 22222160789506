import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/styles';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {displaySafe} from '@reading/r180/src/utils/stringUtils';

export const useStyle = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		flex: '0 0 auto',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		'& .mini-word-card-speaker': {
			transition: 'transform 0.3s ease',
			'& i': {
				position: 'relative',
				top: '2px',
				color: theme.colors.slate
			}
		}
	},
	labelWrapper: {
		fontFamily: theme.fonts.context,
		fontSize: '32px',
		color: theme.colors.black,
		marginLeft: '12px',
		userSelect: 'none'
	}
}));

const WordNSpeakerRow = ({label}) => {
	const classes = useStyle();

	const playAudio = () => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(label);
	};

	return (
		<div className={classes.wrapper}>
			<button onClick={playAudio} data-testid="speaker-button" className='mini-word-card-speaker'>
				<i className="r180 speaker-center pointer" />
			</button>
			<div className={classes.labelWrapper}>{displaySafe(label)}</div>
		</div>
	);
};

WordNSpeakerRow.defaultProps = {
	label: ''
};

WordNSpeakerRow.propTypes = {
	label: PropTypes.string.isRequired
};

export default React.memo(WordNSpeakerRow);
