import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ActivityButton from '../ActivityButton';
import NoteTextArea from '../NoteTextArea/NoteTextArea';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import {isFirefox} from '@reading/r180/src/utils/browsers';
import {sleep} from '@reading/r180/src/utils/sleep';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';

export const useStyles = makeStyles(theme => ({
	'@keyframes grow': {
		'0%': {
			transform: 'scale(0.8)'
		},
		'100%': {
			transform: 'scale(1)'
		}
	},
	'@keyframes shrink': {
		'0%': {
			transform: 'scale(1)'
		},
		'100%': {
			transform: 'scale(0.8)'
		}
	},
	wrapper: {
		background: theme.colors.white,
		borderRadius: '8px',
		marginBottom: '16px',
		boxShadow: '0 0 6px rgba(0,0,0,0.12)',
		height: 'auto',
		minHeight: '100px',
		border: `2px solid ${theme.colors.white}`,
		overflow: 'hidden',

		'&.selected': {
			width: '752px',
			marginLeft: '-72px',
			animation: '$grow 0.6s ease-in',

			'&.shrinking': {
				animation: '$shrink 0.4s ease-in'
			},

			'& .title-bar': {
				position: 'relative',
				width: '100%',
				height: '48px',
				padding: '8px 16px',
				background: theme.colors.writingZone.primary,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'left',
				alignItems: 'stretch',

				'& h4': {
					fontSize: '22px',
					color: theme.colors.white,
					letterSpacing: '0.15px',
					position: 'relative',
					top: '4.5px'
				},

				'& .letter': {
					display: 'inline-block',
					width: '32px',
					height: '32px',
					borderRadius: '16px',
					background: theme.colors.white,
					border: `2px solid ${theme.colors.writingZone.green3}`,
					boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.35)',
					color: theme.colors.writingZone.background,
					fontFamily: theme.fonts.buttonBold,
					fontSize: '24px',
					textAlign: 'center',
					marginRight: '16px',
					lineHeight: '28px'
				},

				'& .info': {
					position: 'absolute',
					top: isFirefox() ? '10px' : '12px',
					right: '12px',
					width: '24px',
					height: '24px',
					background: theme.colors.writingZone.primary,
					borderRadius: '12px',
					color: theme.colors.white,
					fontSize: '24px'
				}
			}
		},

		'&.completed': {
			'& .title-bar': {
				background: theme.colors.writingZone.green5,
				'& h4': {
					color: theme.colors.writingZone.background
				},
				'& .revise, & .info': {
					color: theme.colors.writingZone.background
				}
			}
		}
	},

	titleBar: {
		height: 'auto',
		padding: '5px 10px',
		borderRadius: '8px 8px 0 0',
		background: theme.colors.paleGrey,
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		position: 'relative',

		'& h4': {
			fontSize: '18px',
			fontWeight: 'bold',
			lineHeight: '22px',
			color: theme.colors.grey,
			margin: 0
		},

		'& .revise, & .info': {
			color: theme.colors.grey,
			position: 'absolute',
			top: '6px',
			right: '12px',
			width: '24px',
			height: '24px',
			background: 'transparent',
			borderRadius: '12px',
			fontSize: '18px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	},

	textInput: {
		'--textHeight': '40px',
		backgroundSize: '100% var(--textHeight)',
		lineHeight: 'var(--textHeight)',
		minHeight: 'calc((var(--textHeight) * 2) + 5px)',
		background: `linear-gradient( to bottom, ${theme.colors.white} 95%, ${theme.colors.white} 96%, #D1D3D3 100% )`
	},

	content: {
		padding: '24px',
		background: theme.colors.white,
		borderRadius: '0 0 8px 8px'
	},

	footer: {
		width: '100%',
		padding: '32px 16px',
		background: theme.colors.white,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'& button:first-of-type': {
			marginRight: '32px'
		}
	}
}));

const WritingPanelNarrative = props => {
	const {
		index,
		className,
		isActive,
		title,
		letter,
		text,
		isCompleted,
		onPanelCompleted,
		onRevise
	} = props;

	const classes = useStyles();
	const {setState} = useBatchedSetState();

	const ref = useRef();

	const [currentText, setCurrentText] = useState(text);
	const [enableGoOn, setEnableGoOn] = useState(false);
	const [isShrinking, setShrinking] = useState(false);

	useEffect(() => {
		if (isActive) {
			window.scrollTo({
				left: 0,
				top: ref.current.offsetTop - ref.current.offsetHeight,
				behavior: 'smooth'
			});
		}
	}, [isActive]);

	const handleTextChange = text => {
		setState(() => {
			setCurrentText(text);

			if (text.length > 10) {
				setEnableGoOn(true);
			} else {
				setEnableGoOn(false);
			}
		});
	};

	const handleNext = async () => {
		setShrinking(true);
		await sleep(400);
		setShrinking(false);
		AudioPlayer2.stopAll();
		if (typeof onPanelCompleted === 'function') {
			onPanelCompleted(currentText);
		}
	};

	const handleReset = () => {
		setState(() => {
			setCurrentText('');
			setEnableGoOn(false);
		});
	};

	const handleRevise = index => {
		if (typeof onRevise === 'function') {
			onRevise(index);
		}
	};

	const handleInfo = index => {
		AudioPlayer2.stopAll();
		if (letter.toLowerCase() === 'b') {
			AudioPlayer2.play('narrative-beginning');
		} else if (letter.toLowerCase() === 'm') {
			AudioPlayer2.play('narrative-middle');
		} else if (letter.toLowerCase() === 'e') {
			AudioPlayer2.play('narrative-end');
		}
	};

	const Header = () => (
		<div className={`${classes.titleBar} title-bar`}>
			{isActive && <div className={`letter`}>{letter}</div>}

			<h4>{title}</h4>
			{isCompleted ? (
				<button className="revise" onClick={() => handleRevise(index)}>
					<i className="r180 edit" />
				</button>
			) : (
				<button
					className="info"
					onClick={() => handleInfo(index)}
					data-testid="someRevise"
				>
					<i className="r180 instructions" />
				</button>
			)}
		</div>
	);

	const Footer = () => (
		<div className={classes.footer}>
			<ActivityButton
				onClick={handleReset}
				icon="reset"
				id="reset"
				isPrimary={false}
				text="Reset"
				disabled={false}
			/>
			<ActivityButton
				onClick={handleNext}
				icon="arrow-right"
				id="go-on"
				isPrimary={true}
				text="Go On"
				disabled={enableGoOn === false}
			/>
		</div>
	);

	const activeClass = isActive ? 'selected' : 'not-selected';
	const completed = isCompleted ? 'completed' : '';
	const shrinking = isShrinking ? 'shrinking' : '';

	return (
		<div
			ref={ref}
			className={`writing-narrative ${classes.wrapper} ${className} ${activeClass} ${completed} ${shrinking}`}
		>
			<Header />

			{isActive ? (
				<>
					<div className={classes.content}>
						<NoteTextArea
							disabled={false}
							text={currentText}
							onTextChange={handleTextChange}
						/>
					</div>
					<Footer />
				</>
			) : (
				<div className={classes.content}>{text}</div>
			)}
		</div>
	);
};

WritingPanelNarrative.defaultProps = {
	className: '',
	sections: [],
	activePanelIndex: 0,
	isCompleted: false
};
WritingPanelNarrative.propTypes = {
	className: PropTypes.string,
	sections: PropTypes.array.isRequired,
	activePanelIndex: PropTypes.number,
	isCompleted: PropTypes.bool
};

export default React.memo(WritingPanelNarrative);
