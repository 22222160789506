import { makeStyles } from '@material-ui/core';
import React from 'react';

export const useStyles = makeStyles(theme => ({
	zoneFooter: {
		position: 'relative',
		textAlign: 'left',
		padding: '0px',
		margin: 'auto',
		marginTop: '16px',
		height: theme.footerHeight,
		width: theme.activityFrameWidth,
		minWidth: theme.activityFrameMinWidth,
		maxWidth: theme.activityFrameMaxWidth,
		zIndex: '1',
		lineHeight: "64px",
		"& .activity-support-button": {
			marginRight: "16px",
			position: 'relative',
			top: '-3px'
		},
		"& .activity-support-button:last-of-type": {
			marginRight: "0"
		},
		'& .activity-button': {
			marginTop: '12px'
		}
	}
}));

export default function Footer(props) {
	const classes = useStyles();

    return (
        <footer className={`${classes.zoneFooter}`}>
            {props.children}
        </footer>
    );
}
