import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import useUtterance from '../../../utils/useUtterance';

export const useStyles = makeStyles(theme => ({
	wordPanel: {
		fontSize: '48px',
		'& .word': {
			display: 'inline-block',
			padding: '36px',
			borderRadius: '18px',
			textAlign: 'center',
			border: `2px solid ${theme.colors.readingZone.background}`,
			boxShadow: `0px 0px 0px 2px ${theme.colors.readingZone.blue5}`,
			transition: 'box-shadow 0.3s linear',
			'&.all-caps': {
				textTransform: 'uppercase'
			}
		},
		'& .r180': {
			fontSize: '32px',
			marginRight: '32px',
			color: theme.colors.readingZone.background
		}
	}
}));

const Prompt = props => {
	const {id, className, text, isAllCaps, onComplete} = props;
	const classes = useStyles();

	const {volumeLevel, status, record} = useUtterance({
		silenceTime: 4000,
		onMicAccept: () => {
			// 	// give the component some time to render and the person to recognize what they need to say
			setTimeout(() => {
				record();
			}, 500);
		}
	});

	const [isTextCorrect, setTextCorrect] = useState(false);

	// useEffect(() => {
	// 	// give the component some time to render and the person to recognize what they nee dto say
	// 	setTimeout(() => {
	// 		record();
	// 	}, 500);
	// }, [record]);

	useEffect(() => {
		if (status === 'sound') {
			setTextCorrect(true);
			setTimeout(() => {
				onComplete();
			}, 3000);
		} else if (status === 'silence') {
			setTextCorrect(false);
			setTimeout(() => {
				onComplete();
			}, 3000);
		}
	}, [status, onComplete]);

	return (
		<div className={`${classes.wordPanel} ${className} word-panel animate__animated animate__zoomIn`} id={id}>
			{isTextCorrect && <i className="r180 check-answer" />}
			<div
				className={`word ${isAllCaps ? 'all-caps' : ''}`}
				style={{boxShadow: `0px 0px 0px ${Math.max(2, (volumeLevel - 1) * 20)}px rgb(204, 233, 250)`}}
			>
				{text}
			</div>
		</div>
	);
};

Prompt.defaultProps = {
	id: '',
	className: '',
	text: '',
	isAllCaps: false
};
Prompt.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	text: PropTypes.string,
	isAllCaps: PropTypes.bool,
	onComplete: PropTypes.func.isRequired
};

export default React.memo(Prompt);
