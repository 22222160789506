import React, {useState, useEffect, useMemo} from 'react';
import Navbar from '../../containers/App/Navbar';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import {useStyles} from './DiscrepancyStyles.style';
import {Discrepancy} from './Discrepancy';
import {isEmpty} from 'lodash';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import WordCard from '@reading/common/src/components/WordCard/WordCard';
import media from '../../api/media';
import {uiSlice} from '../../store/slices/ui';
import {bulkReplace} from '../../utils/stringUtils';
import {MEDIA} from '../ReadingPassage/ReadingPassageUtils';
import useBatchedSetState from '../../utils/useBatchedSetState';

import {
	ReadingPassage,
	TabBar,
	ReadingQuestionPanel,
	AnchorVideo,
	CaptionedAudioPlayer2 as AudioPlayer2
} from '@reading/common';
import {useHistory} from 'react-router-dom';
import {Modal} from '@material-ui/core';
import {sleep} from '../../utils/sleep';
import {MEDIA_SERVER_URL} from '../../utils/constants';
import {KnowledgeForReading} from '../WordCard/KnowledgeForReading';
import {EXT} from '../Foundational/WordBuilder/WordBuilderConstants';
import {getContentInfo} from '../../media/mediaUtil';

export const handleSubmit = async (
	setEnableFeedback,
	setChoiceContent,
	choiceContent,
	selectedTabIndex,
	correctAnwserId,
	setTrailCounter,
	trailCounter,
	setShowGoON,
	showGoON,
	setSubmitEnabled,
	dispatch,
	history,
	activityData,
	batchUpdate
) => {
	if (trailCounter > 1 || showGoON) {
		setTrailCounter(trailCounter + 1);
		try {
			await Discrepancy.completeActivity(history, activityData);
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
		return false;
	}
	setEnableFeedback(true);
	const newChoiceContent = [...choiceContent];
	const selectedTabIndexVal = parseInt(selectedTabIndex) - 1;
	const correctAnswerSelected = correctAnwserId === selectedTabIndexVal;
	if (trailCounter === 0 && correctAnswerSelected) {
		AudioPlayer2.play(Discrepancy.VO[6].name);

		setShowGoON(true);
	} else if (trailCounter === 1 && correctAnswerSelected) {
		AudioPlayer2.play(Discrepancy.VO[7].name);
		setShowGoON(true);
	} else if (trailCounter < 1) {
		setSubmitEnabled(false);
		const playBackFunction = async () => {
			await AudioPlayer2.playSync(Discrepancy.VO[5].name);
			setSubmitEnabled(true);
		};
		playBackFunction();
	}

	batchUpdate.setState(() => {
		const incrementCounter = trailCounter + 1;
		setTrailCounter(incrementCounter);
		if (incrementCounter === 2) {
			setShowGoON(true);
		}
		newChoiceContent[selectedTabIndexVal].status = correctAnswerSelected ? 'correct' : 'incorrect';
		setChoiceContent(newChoiceContent);
	});
};
export const closeVideo = setSelectedTabIndex => {
	setSelectedTabIndex(Discrepancy.prevTabIndex);
};

export const MainContent = props => {
	const {
		tabsData,
		classes,
		playInstructions,
		selectedTabIndex,
		handleTabSelection,
		activityData,
		updateChoiceUpdate,
		setSelectedTabIndex,
		videoPath,
		videoCaptionPath,
		setCardWord,
		enableFeedback,
		setEnableFeedback,
		choiceContent,
		setChoiceContent,
		correctAnwserId,
		setTrailCounter,
		trailCounter,
		isSubmitEnabled,
		setShowGoON,
		showGoON,
		setSubmitEnabled,
		dispatch,
		batchUpdate,
		previousTab
	} = props;

	const history = useHistory();
	const isPanelDisabled = !isSubmitEnabled ? !isSubmitEnabled : showGoON;
	return (
		<>
			{tabsData.length && (
				<div className={`${classes.wrapper} ${isPanelDisabled ? 'disablePanel' : ''}`}>
					<div className={classes.leftColumn}>
						<button
							type="button"
							className="instructions"
							data-testid={'recordInstructions'}
							aria-label={'Instructions'}
							onClick={playInstructions}
						>
							<i className="r180 instructions" />
						</button>
						<TabBar
							tabs={Discrepancy.tabHeaderContent}
							id={'discrepancyTabs'}
							onClick={tab => handleTabSelection(tab)}
							selectedTab={selectedTabIndex}
							className={classes.discrepancyPanel}
						/>
						<div className={classes.strip} />
						{selectedTabIndex < 4 ? (
							<ReadingPassage
								passageString={tabsData[parseInt(selectedTabIndex) - 1]}
								level={activityData.level}
								readPhrases={false}
								readWords={false}
								segment={activityData.segment}
								className={classes.discrepancyPanel}
								stage={activityData.stage}
								setCardWord={setCardWord}
								enableFeedback={enableFeedback}
							/>
						) : (
							<Modal className={classes.videoModal} open={selectedTabIndex === '4'}>
								<div className={classes.videoModalBody} data-testid={'videoModal'}>
									<AnchorVideo
										id="discrepancyActivityVideo"
										containerStyle={{width: '940px'}}
										hideVideoTimeDuration
										autoPlay={false}
										aria-label={'Anchor video'}
										ended={true}
										hideCloseButton={false}
										handleCloseButton={() => {
											setSelectedTabIndex(previousTab);
										}}
									>
										<source src={videoPath} type={'video/mp4'} />
										<track
											src={videoCaptionPath}
											kind={'subtitles'}
											srcLang={'en'}
											label={'English subtitles'}
										/>
									</AnchorVideo>
								</div>
							</Modal>
						)}
					</div>
					<div className={classes.rightColumn}>
						{selectedTabIndex < 4 && (
							<ReadingQuestionPanel
								buttonIcon="check"
								buttonText={showGoON ? 'Go On' : 'Submit'}
								className={classes.discrepancyPanel}
								id="discrepancyReading"
								interactive={!isSubmitEnabled ? isSubmitEnabled : !showGoON}
								isSubmitEnabled={isSubmitEnabled}
								onAnswerChange={updateChoiceUpdate}
								question={{}}
								preSelectedIndex={parseInt(selectedTabIndex) - 1}
								questionType="SELECTED_RESPONSE_SINGLE_ANSWER"
								questions={choiceContent}
								showResetButton={false}
								title="Which text has the same information as the Anchor Text?"
								titleSound={Discrepancy.titleSound}
								dispatch={dispatch}
								history={history}
								onSubmit={() =>
									handleSubmit(
										setEnableFeedback,
										setChoiceContent,
										choiceContent,
										selectedTabIndex,
										correctAnwserId,
										setTrailCounter,
										trailCounter,
										setShowGoON,
										showGoON,
										setSubmitEnabled,
										dispatch,
										history,
										activityData,
										batchUpdate
									)
								}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};
const DiscrepancyActivity = () => {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const batchUpdate = useBatchedSetState();

	const {activityData, studyList, settings, isActivityCharged} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			studyList: state.activity.studylist,
			settings: state.session.session.settings,
			isActivityCharged: state.ui.chargedActivities['discrepancy_passages'] === true
		};
	}, shallowEqual);

	const [selectedTabIndex, setSelectedTabIndex] = React.useState('1');
	const [previousTab, setPreviousTab] = useState('0');
	const [tabsData, setTabsData] = React.useState([]);
	const [videoPath, setVideoPath] = React.useState('');
	const [videoCaptionPath, setVideoCaptionPath] = useState('');
	const [cardWord, setCardWord] = React.useState(false);
	const [wordCardData, setWordCardData] = React.useState(false);
	const [correctAnwserId, setCorrectAnwserId] = useState(0);
	const [enableFeedback, setEnableFeedback] = useState(false);
	const [choiceContent, setChoiceContent] = useState(Discrepancy.choiceContent);
	const [trailCounter, setTrailCounter] = useState(0);
	const [submitEnabled, setSubmitEnabled] = useState(false);
	const [showGoON, setShowGoON] = useState(false);

	const isActivity = activityData ? activityData.activityServerId === 'discrepancy_passages' : false;

	useEffect(() => {
		const loadContentData = async () => {
			const {wordsDataList, videoPath, videoCaptionPath, correctChoiceIndex} = await Discrepancy.loadContent(
				activityData
			);
			batchUpdate.setState(() => {
				setTabsData(wordsDataList);
				setVideoPath(videoPath);
				setVideoCaptionPath(videoCaptionPath);
				setCorrectAnwserId(correctChoiceIndex);
			});

			dispatch(uiSlice.actions.setActivityCharged('discrepancy_passages'));

			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				AudioPlayer2.stopAll();
				await AudioPlayer2.playSync(Discrepancy.VO[0].name);
				await AudioPlayer2.playSync('instructionsVO');
			}

			setSubmitEnabled(true);
		};
		if (!isEmpty(activityData) && isActivity) {
			loadContentData();
		}
	}, [activityData]);

	const resetRadioButton = () => {
		const newChoiceContent = [...choiceContent];
		newChoiceContent.forEach(item => {
			delete item.status;
		});
		setChoiceContent(newChoiceContent);
	};

	const handleTabSelection = tab => {
		const isDiabledMode = !submitEnabled ? !submitEnabled : showGoON;
		if (isDiabledMode) {
			return false;
		}
		if (tab.id === '4') {
			setPreviousTab(selectedTabIndex);
		}
		setSelectedTabIndex(`${tab.id}`);
		setEnableFeedback(false);
		resetRadioButton();
	};

	const updateChoiceUpdate = answers => {
		if (showGoON) {
			return false;
		}
		if (!isEmpty(answers[0])) {
			setSelectedTabIndex(`${answers[0].id + 1}`);
		}
		resetRadioButton();
		setEnableFeedback(false);
	};

	const playInstructions = () => {
		if (!submitEnabled ? !submitEnabled : showGoON) {
			return false;
		}
		AudioPlayer2.stopAll();
		AudioPlayer2.play('instructionsVO');
	};

	useEffect(() => {
		const getWordCardData = async () => {
			try {
				let wordCardData = await media.getOne(bulkReplace(MEDIA.WORD_CARD, {word: cardWord.word}));
				if (!wordCardData.contextualData[0]) {
					wordCardData.contextualData[0] = {
						contextualDefinition: wordCardData.definition,
						contextualSentence: wordCardData.sentence
					};
				}
				const word = cardWord.word.toLowerCase();
				const constants = {
					word: word,
					asset_id: getContentInfo(activityData, false).assetId
				};
				const WORD_CTXT_URL = `${MEDIA_SERVER_URL}assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`;
				const WORD_DEF_URL = `${MEDIA_SERVER_URL}assets/dictio/def/r180u_def_def-{word}.${EXT}`;
				const word_text_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, constants);
				const word_def_url = bulkReplace(WORD_DEF_URL, constants);
				const word_ctx_url = bulkReplace(WORD_CTXT_URL, constants);
				// the pronounced word
				AudioPlayer2.load({name: word, src: word_text_url});

				// the definition
				AudioPlayer2.load({name: `def-${word}`, src: word_def_url});

				// the context
				AudioPlayer2.load({name: `ctx-${word}`, src: word_ctx_url});

				setWordCardData(wordCardData);
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				setWordCardData(false);
				setCardWord(false);
			}
		};
		if (cardWord && cardWord.word) {
			getWordCardData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardWord]);

	const isWordCardOpen = cardWord && cardWord.word && wordCardData ? true : false;
	return (
		<div className={classes.mainWrapper}>
			<Navbar helpSoundUrl={Discrepancy.helpVO} />
			<ActivitySuspense
				requiredRenderData={[activityData]}
				showSpinner={isActivityCharged === false}
				title={'Discrepancy Task'}
			>
				<Modal
					className={`${classes.wordCardModal} r180-modal`}
					open={isWordCardOpen}
					onClose={() => {
						setCardWord(false);
					}}
				>
					<div className={`${classes.wordCardModalBody} modal-window`} data-testid={'wordCardModal'}>
						<WordCard
							wordCardData={wordCardData}
							card={{
								progress: 0,
								maxProgress: 0,
								word_card: {
									word_card_activities: [],
									word_card_sentence: wordCardData.sentence,
									word_card_definition: wordCardData.definition,
									word_text: wordCardData.text
								}
							}}
							taskData={{video_hint: ''}}
							hideVideoTool={true}
							handleClose={() => {
								setCardWord(false);
							}}
							showExitButton={true}
						/>
					</div>
				</Modal>
				<div className={classes.rootWrapper}>
					<MainContent
						tabsData={tabsData}
						classes={classes}
						playInstructions={playInstructions}
						selectedTabIndex={selectedTabIndex}
						handleTabSelection={handleTabSelection}
						activityData={activityData}
						updateChoiceUpdate={updateChoiceUpdate}
						setSelectedTabIndex={setSelectedTabIndex}
						videoPath={videoPath}
						videoCaptionPath={videoCaptionPath}
						setCardWord={setCardWord}
						setEnableFeedback={setEnableFeedback}
						enableFeedback={enableFeedback}
						choiceContent={choiceContent}
						setChoiceContent={setChoiceContent}
						correctAnwserId={correctAnwserId}
						trailCounter={trailCounter}
						setTrailCounter={setTrailCounter}
						isSubmitEnabled={submitEnabled}
						showGoON={showGoON}
						setShowGoON={setShowGoON}
						setSubmitEnabled={setSubmitEnabled}
						dispatch={dispatch}
						batchUpdate={batchUpdate}
						previousTab={previousTab}
					/>
				</div>
			</ActivitySuspense>
		</div>
	);
};

export default DiscrepancyActivity;
