import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		background: 'transparent',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .q': {
			display: 'inline-block',
			color: theme.colors.white,
			fontSize: '18px',
			fontWeight: '700',
			marginRight: '24px',
		},
		'& .numbers': {
			position: 'relative'
		},
		'& .line': {
			position: 'absolute',
			display: 'inline-block',
			height: '3px',
			width: '100%',
			background: theme.colors.paleSlate,
			left: '0',
			top: '14px',
			zIndex: '1'
		},
		'& .number': {
			position: 'relative',
			display: 'inline-block',
			height: '24px',
			width: '24px',
			borderRadius: '50%',
			textAlign: 'center',
			lineHeight: '24px',
			fontSize: '16px',
			color: theme.colors.paleSlate,
			background: theme.colors.white,
			marginRight: '24px',
			zIndex: '2',
			'&:last-of-type': {
				marginRight: 0
			},
			'&.selected': {
				height: '30px',
				width: '30px',
				lineHeight: '22px',
				border: `3px solid ${theme.colors.readingZone.blue3}`,
				boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
				fontSize: '18px',
				color: theme.colors.softBlack,
				fontWeight: '700',
				top: '1px'
			}
		}
	}

}));

const QuestionBreadcrumb = (props) => {
	const { id, className, selected, totalQuestions, ['data-testid']:testid } = props;
	const classes = useStyles();

	let numbers = [];
	for (let i=1; i<=totalQuestions; i++) {
		numbers.push(<span key={i} className={`number ${i === selected ? 'selected': ''}`}>
			{i}
		</span>)
	}

	return (
		<div className={`${classes.wrapper} ${className}`} id={id} data-testid={testid}>
			<span className='q'>Question</span>
			<span className='numbers'>
				<span className='line'></span>
				{numbers}
			</span>
		</div>
	);
};

QuestionBreadcrumb.defaultProps = {
	id: '',
	className: '',
	selected: 1,
	totalQuestions: 4
};
QuestionBreadcrumb.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	selected: PropTypes.number,
	totalQuestions: PropTypes.number
};

export default React.memo(QuestionBreadcrumb);

