import React from 'react';
import useDnd from './useDnd';

const DndContext = React.createContext();

const DndProvider = ({ children, dndData, defaultType = "questions", onDndChange }) => {
    const [dndSource, dndState, methods] = useDnd(dndData, defaultType, onDndChange);

    return (
        <DndContext.Provider value={{ dndTiles: dndSource, dndState, methods }}>
            {dndSource && children}
        </DndContext.Provider>
    )
}
// --- xx -----------------------------------------------

export { DndContext, DndProvider }
export default DndProvider