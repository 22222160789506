import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import ReadingSentences from './ReadingSentences';

export const useStyles = makeStyles(theme => ({
	proofreadingContainer: {
		background: theme.colors.white,
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	sentencesContainer: {
		width: '68%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start'
	}
}));

const ProofreadingContainer = props => {
	const {id, sentences, result, onSentenceChange, onBtnDisabled, isSelectionDisabled} = props;
	const classes = useStyles();

	return (
		<div id={id} className={classes.proofreadingContainer}>
			<div className={classes.sentencesContainer}>
				<ReadingSentences
					sentences={sentences}
					result={result}
					onSentenceChange={onSentenceChange}
					onBtnDisabled={onBtnDisabled}
					isSelectionDisabled={isSelectionDisabled}
				/>
			</div>
		</div>
	);
};

ProofreadingContainer.defaultProps = {
	id: '',
	isSelectionDisabled: false
};

ProofreadingContainer.propTypes = {
	id: PropTypes.string.isRequired,
	sentences: PropTypes.array,
	result: PropTypes.array,
	onSentenceChange: PropTypes.func,
	onBtnDisabled: PropTypes.func,
	isSelectionDisabled: PropTypes.bool
};

export default React.memo(ProofreadingContainer);
