import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { camelCase } from 'lodash';
import { isFirefox } from '../../utils/browsers';

export const useStyles = makeStyles(theme => {
	return {
		zoneButton: ({zoneCode='explore_zone'}) => ({
			position: 'relative',
			marginBottom: '12px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			'&:last-of-type': {
				marginBottom: '0'
			},
			'& .progress': {
				position: 'relative',
				marginRight: '24px',
				transform: 'scale(0.9)',
				'& .complete': {
					height: '24px',
					width: '24px',
					borderRadius: '12px',
					background: '#55565A',
					color: theme.colors.white,
					textAlign: 'center',
					fontSize: '16px',
					position: 'absolute',
					left: isFirefox() ? '26px' : '27px',
					top: '60px',
					zIndex: '99',
					'& i': {
						position: 'relative',
						top: '1px'
					}
				},
				'& .locked': {
					height: '24px',
					width: '24px',
					borderRadius: '12px',
					background: '#55565A',
					color: theme.colors.white,
					textAlign: 'center',
					fontSize: '16px',
					position: 'absolute',
					left: isFirefox() ? '26px' : '27px',
					top: '60px',
					zIndex: '99',
					'& i': {
						position: 'relative',
						top: '1px'
					}
				},
				'& .line': {
					width: '3px',
					height: '12px',
					background: theme.colors.white,
					position: 'absolute',
					top: '-3px',
					left: '36px'
				}
			},
			'&:hover': {
				cursor: 'pointer',
				'& .zone-label': {
					borderBottom: `2px solid ${theme.colors.white}`
				}
			},
			'&.disabled': {
				'&:hover': {
					cursor: 'auto',
					'& .zone-label': {
						borderBottom: '2px solid transparent'
					}
				},
				'& .zone-icon': {
					background: `${theme.colors.paleGrey} !important`,
					color: `${theme.colors.black} !important`
				},
				'& .progress-arc': {
					boxShadow: 'none !important'
				}
			},
			'&.completed': {
				'&:hover': {
					cursor: 'auto',
					'& .zone-label': {
						borderBottom: '2px solid transparent'
					}
				}
			}
		}),
		zoneLabel: {
			width: 'auto',
			textAlign: 'left',
			color: theme.colors.white,
			fontSize: '24px',
			fontFamily: theme.fonts.uiBold,
			fontWeight: 'bold',
			letterSpacing: '-0.5px',
			borderBottom: '2px solid transparent',
			position: 'relative',
			top: '-4px'
		},
		zoneIcon: ({zoneCode='explore_zone'}) => {
			if (typeof zoneCode !== 'string') {
				zoneCode = 'explore_zone';
			}
			return {
				width: '52px',
				height:'52px',
				textAlign: 'center',
				fontFamily: 'r180',
				fontSize: '32px',
				position: 'absolute',
				top: '12px',
				left:'12px',
				borderRadius: '50%',
				color: theme.colors.white,
				background: zoneCode && theme.colors[camelCase(zoneCode)].primary,
				display:'flex',
				justifyContent:'center',
				alignItems:'center',
		}},
		zoneProgressDot:{
			position:'absolute',
			width:'12px',
			height:'12px',
			borderRadius:'6px',
			zIndex:'2',
			left:'32px',
			top:'32px',
			background: theme.colors.white,
			color:theme.colors.white,
		},
		progressBgArc: {
			width:'76px',
			height:'76px',
			position:'absolute',
			borderRadius:'38px',
			border:`solid 6px ${theme.colors.transWhite}`,
			zIndex:'2',
			boxShadow: '0px 4px 0px #131313'
		},
		progressArc: {
			color: theme.colors.white,
			position: 'relative',
			zIndex: '1'
		},
		iconDisabled: {
			color: theme.colors.palegrey,
			cursor: 'auto'
		}
	};
});

const ZoneMenuItem = ({
	zoneState: {available},
	zoneDisplay: {displayName},
	zoneProgress: {current, max},
	zoneCode,
	onClick
}) => {
	const classes = useStyles({zoneCode});

	const adjustedCurrent = Math.min(max, current);

	const calculatedProgress = (100 * adjustedCurrent) / max;

	const angle = 2 * Math.PI * adjustedCurrent/max - Math.PI/2;
	const radius = 36;

	const isComplete = calculatedProgress === 100 && available === false;
	const isInProgress = calculatedProgress > 0 && calculatedProgress < 100 && available === true && isComplete === false;
	const isLocked = calculatedProgress === 0 && available === false;

	return (
		<div
			className={`${classes.zoneButton} ${isLocked ? 'disabled' : ''} ${isComplete ? 'completed' : ''}`}
			tabIndex={isInProgress === false ? 0 : -1 }
			onClick={isLocked || isComplete ? () => {} : onClick}
			aria-label={displayName}
			role={'region'}
		>
			<div className='progress'>
				<div className={classes.progressContainer} >
					<div className={`${classes.progressBgArc} progress-arc`}></div>
					<CircularProgress
						variant={'static'}
						key={`zonebutton_${zoneCode}`}
						value={(100.0 * adjustedCurrent) / max}
						size={76}
						className={`${classes.progressArc}`}
						thickness={4}
						aria-valuenow={adjustedCurrent}
						aria-valuemax={max}
						aria-valuemin={0}
						aria-label={`Progress: ${adjustedCurrent}/${max}`}
					></CircularProgress>
				</div>
				{ isInProgress && <div className='line in-progress'></div> }
				{ isComplete && <div className='complete'><i className='r180 star' /></div> }
				{ isLocked && <div className='locked'><i className='r180 lock' /></div> }
				{ isInProgress && (
					<div
						aria-hidden={true}
						className={`${classes.zoneProgressDot}`}
						style={{ transform: `translate(${Math.cos(angle)*radius}px,${Math.sin(angle)*radius}px)` }}
					>
					</div>
				)}
				<div className={`${classes.zoneIcon} zone-icon`}>
					<i className={`r180 ${zoneCode.split('_')[0]}`} />
				</div>
			</div>
			<div className={`${classes.zoneLabel} zone-label`} id={`label_${zoneCode}`} role="button">
				{displayName.replace(' Zone', '')}
			</div>
		</div>
	);
};

export default React.memo(ZoneMenuItem);
