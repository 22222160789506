import {isEmpty} from 'lodash';

export const isLetter = (letter) => {
	return letter.toLowerCase() !== letter.toUpperCase();
}

export const bulkReplace = (text, replacements) => {
	if (isEmpty(text)) {
		return text;
	}
	let newText = text;
	Object.keys(replacements).forEach(key => {
		const value = replacements[key];
		const replace = '{' + key + '}';
		const regex = new RegExp(replace, 'g');
		newText = newText.replace(regex, value);
	});
	return newText;
};

export const stripHtmlTags = (textToStrip, includeFormatting = false, removeEmptyDivTag = false) => {
	if (textToStrip) {
		let t = textToStrip.replace(/<p>/g, '');
		t = t.replace(/<\/p>/g, '');
		if (includeFormatting === false) {
			t = t.replace(/<W>/g, '');
			t = t.replace(/<\/W>/g, '');
			t = t.replace(/<b>/g, '');
			t = t.replace(/<\/b>/g, '');
			t = t.replace(/<i>/g, '');
			t = t.replace(/<\/i>/g, '');
			t = t.replace(/&nbsp;/g, ' ');
			t = t.replace(/<em>/g, '');
			t = t.replace(/<\/em>/g, '');
			if (removeEmptyDivTag && t.indexOf('<div>') > -1) {
				t = t.replace(/<div>/g, '');
				t = t.replace(/<\/div>/g, '');
			}
		}
		else {
			t = t.replace(/<W>/g, '<b>');
			t = t.replace(/<\/W>/g, '</b>');
		}
		return t;
	} else {
		return '';
	}
};

export const displaySafe = text => {
	if (isEmpty(text)) {
		return '';
	}

	let strippedText = text;

	// some words have underscores and numbers that should be removed
	// e.g. focus_3
	if (strippedText.indexOf('_') > -1) {
		strippedText = strippedText.substring(0, strippedText.indexOf('_'));
	}

	return strippedText;
};

export const camelCaseToDefault = text => {
	let t = text.split('_');
	return t.map(word => capitalizeWord(word)).join(' ');
};

export const capitalizeWord = text => {
	if (isEmpty(text)) {
		return text;
	} else if (text.length === 1) {
		return text.toUpperCase();
	} else {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}
};

export const stripFileExtensions = text => {
	if (isEmpty(text)) {
		return text;
	} else if (text.indexOf('.') === -1) {
		return text;
	} else {
		return text.substring(0, text.lastIndexOf('.'));
	}
};

export const stripAllExtraWhiteSpace = text => {
	if (isEmpty(text)) {
		return text;
	}
	let strippedText = text.trim();
	strippedText = strippedText.replace(/\s+/g, ' ');
	return strippedText;
};

export const stripBogusHtml = text => {
	if (isEmpty(text)) {
		return text;
	}

	let workingText = text.trim();
	let strippedText = '';

	while (workingText.indexOf('<') > -1) {
		strippedText += workingText.substring(0, workingText.indexOf('<'));
		workingText = workingText.substring(workingText.indexOf('>') + 1);
	}

	return strippedText;
};
