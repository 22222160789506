import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FastForward, FastRewind } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
	root: {
		// padding: '12px',
		color: theme.colors.paleGrey,
		'&:hover,&:focus': {
			color: theme.colors.readingZone.primary
		}
	},
	disable: {
		color: '#626466 !important'
	},
	fastForwardStyle: {
		marginRight: '0px'
	}
});

function FastSeekButton({
	classes,
	containerStyle,
	iconButtonStyle,
	iconDefaultStyle,
	iconStyle,
	disabled,
	handleFastForward
}) {
	return (
		<div style={containerStyle}>
			<IconButton
				aria-label="FastForward"
				className={disabled ? classes.disable : classes.root}
				color="inherit"
				style={iconButtonStyle}
				onClick={handleFastForward}
				disabled={disabled}
			>
				<i className="r180 f-forward" style={{ fontSize: '24px' }}></i>
			</IconButton>
		</div>
	);
}

FastSeekButton.defaultProps = {
	classes: {},
	containerStyle: {},
	iconStyle: {},
	iconButtonStyle: {},
	handleFastForward: () => { }
};
FastSeekButton.propTypes = {
	classes: PropTypes.object,
	containerStyle: PropTypes.object,
	iconStyle: PropTypes.object,
	iconButtonStyle: PropTypes.object,
	handleFastForward: PropTypes.func.isRequired
};

export default memo(withStyles(styles)(FastSeekButton));
