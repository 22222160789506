import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SpellingFeedbackItem from './SpellingFeedbackItem';
import { AudioPlayer2 } from '@shared/core';
import { useUnmount } from 'react-use';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(theme => ({
	wrapper: {
		// position: 'relative',
		// width: '600px',
		// textAlign: 'center',
		// margin: 'auto'
	},
	dialogPaper: {
		overflow: 'inherit',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		width: '608px',
		height: '328px'
	}
}));

export default function SpellingChallenge(props) {
	const { feedbackId, feedbackInstanceNo, isIncrementFeedbackInstance, correctWord, feedbackWord, decodeInfoData, onCorrectiveFeedbackKeyPress, onCorrectiveFeedbackBlur } = props;
	const classes = useStyles();
	const [showDialog, setShowDialog] = useState(true);
	useUnmount(() => {
		AudioPlayer2.stopAll();
	});

	const handleKeyPress = (word, typeWord, isCorrect) => {
		setShowDialog(!isCorrect);
		if (typeof onCorrectiveFeedbackKeyPress === 'function') {
			onCorrectiveFeedbackKeyPress(word, typeWord, isCorrect);
		}
	};

	const handleBlur = (word, typeWord, isCorrect) => {
		setShowDialog(!isCorrect);
		if (typeof onCorrectiveFeedbackBlur === 'function') {
			onCorrectiveFeedbackBlur(word, typeWord, isCorrect);
		}
	};

	return (
		<div className={classes.wrapper} id={feedbackId}>
			{
				<Dialog
					aria-labelledby="simple-dialog-title"
					open={showDialog}
					classes={{ paper: classes.dialogPaper }}
					maxWidth={'lg'}
				>
					<SpellingFeedbackItem
						id={feedbackId}
						word={correctWord}
						instanceNo={feedbackInstanceNo}
						isIncrementInstance={isIncrementFeedbackInstance}
						selectedWord={feedbackWord}
						onKeyPress={handleKeyPress}
						onBlur={handleBlur}
						decodingInfoData={decodeInfoData}

					/>
				</Dialog>



			}
		</div>
	);
}

SpellingChallenge.defaultProps = {
	feedbackId: '',
	feedbackInstanceNo: 1,
	isIncrementFeedbackInstance: true,
	correctWord: '',
	feedbackWord: '',
	decodeInfoData: {},
	onCorrectiveFeedbackKeyPress: () => { },
	onCorrectiveFeedbackBlur: () => { },
};
SpellingChallenge.propTypes = {
	feedbackId: PropTypes.string,
	feedbackInstanceNo: PropTypes.number,
	isIncrementFeedbackInstance: PropTypes.bool,
	correctWord: PropTypes.string.isRequired,
	feedbackWord: PropTypes.string.isRequired,
	decodeInfoData: PropTypes.object.isRequired,
	onCorrectiveFeedbackKeyPress: PropTypes.func,
	onCorrectiveFeedbackBlur: PropTypes.func,
};
