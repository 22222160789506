import React from 'react';
import {useStyles} from './InstructionsButton.style.js';
import SoundButton from '../SoundButton';
import HoverTip from '../HoverTip/HoverTip.js';
import PropTypes from 'prop-types';

const InstructionsButton = ({audioSrc, audioName, moveLeft}) => {
	const classes = useStyles();

	return (
		<HoverTip title='Click the icon for more information'>
			<div
				className={`${classes.instructionsButtonWrapper} ${
					moveLeft ? 'moveLeft' : ''
				}`}
			>
				<SoundButton
					icon={`instructions`}
					sound={{
						name: audioName.length ? audioName : 'Intro',
						src: audioSrc
					}}
					aria-label={'Instructions audio'}
				/>
			</div>
		</HoverTip>
	);
};

InstructionsButton.defaultProps = {
	audioName: ''
};
InstructionsButton.propTypes = {
	audioName: PropTypes.string
};

export default React.memo(InstructionsButton);
