import {useReducer} from 'react';

function useSetState(initialState = {}) {
	return useReducer(
		(state, newState) => ({...state, ...newState}),
		initialState
	);
}

export default useSetState;
