/*
	At the time of writing this application, all major browsers can handle the mp3 format in the AudioPlayer2
	However, at points in the past, Firefox, for example, could not play mp3 due to patent and licensing issues
	and could only play .ogg files.
	This utility function exists so that in the future if a browser drops support for mp3 file extension, the
	code will only need to be changed in one spot.
	https://caniuse.com/mp3
*/
export const getAudioExt = () => {
	return 'mp3';
}
