import {cloneDeep, isEmpty} from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import ActivityFrame from '../../../containers/App/ActivityFrame';
import ActivitySuspense from '../../../containers/App/ActivitySuspense';
import Footer from '../../../containers/App/Footer';
import FooterForwardBack from '../../../containers/App/FooterForwardBack';
import FSProgressBar from '../../../containers/App/FSProgressBar';
import Navbar from '../../../containers/App/Navbar';
import {ActivitySupportButton, FoundationalIntro, ActivityInstructionButton, MicCheck, WordList} from '@reading/common';
import {makeStyles} from '@material-ui/core';
import {useRef} from 'react';
import {WordMeaningMatchUtil} from './WordMeaningMatchUtil';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {WordMeaningMatchConstants} from './WordMeaningMatchConstants';
import FoundationalIntroModal from '@reading/common/src/components/FoundationalIntro/FoundationIntroModal';
import {FoundationalUtil} from '../FoundationalUtil';
import useBatchedSetState from '../../../utils/useBatchedSetState';
import ReadAloudPrompt from './ReadAloudPrompt';
import {uiSlice} from '../../../store/slices/ui';

const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	wordList: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'flex-start',
		fontSize: '32px',
		fontWeight: '700',
		'& .word': {
			textAlign: 'center'
		},
		'& .word-list:first-of-type': {
			marginRight: '48px'
		}
	}
}));

export default function WordMeaningMatchActivity(props) {
	const activityName = 'word_meaning_match';

	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const {setState} = useBatchedSetState();

	const questionTimer = useRef(Date.now());
	const questionTimeLimit = useRef(null);
	const interactive = useRef(true);

	const {activityData, contentData, saveTemplate, settings, isActivityCharged} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			contentData: state.activity.content,
			saveTemplate: state.activity.save_template,
			settings: state.activity.settings,
			isActivityCharged: state.ui.chargedActivities[activityName] === true
		};
	});

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	// settings common to all FS activities
	const {
		activityTimeLimit,
		isFirstVisit,
		isAccuracyOnly,
		isAutoplay,
		numVisits,
		isActivity
	} = FoundationalUtil.getActivitySettings(activityData, contentData, settings, activityName);

	// state variables/functions common to all FS activities
	const [currentScreen, setCurrentScreen] = useState(isFirstVisit ? 1 : 2);
	const [forwardStatus, setForwardStatus] = useState(FooterForwardBack.START);
	const [isForwardDisabled, setForwardDisabled] = useState(true);
	const [isPaused, setPaused] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [helpCount, setHelpCount] = useState(1);
	const [currentVO, setCurrentVO] = useState('real');
	const [isActvityButtonsDisabled, setActivityButtonsDisabled] = useState(false);

	// state variables/functions unique to this activity
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState({});
	const [isGradable, setGradable] = useState(false);
	const [numCorrect, setNumCorrect] = useState(0);
	const [numIncorrect, setNumIncorrect] = useState(0);
	const [answer, setAnswer] = useState({});

	// all FS activities use this code to kick things off
	useDeepCompareEffect(() => {
		const init = async () => {
			if (isActivityCharged === false) {
				await WordMeaningMatchUtil.initialize({contentData});
				dispatch(uiSlice.actions.setActivityCharged(activityName));
			}

			if (currentScreen === 2) {
				initActivity();
			}
		};

		if (isEmpty(contentData) === false && isActivity) {
			init();
		}
	}, [contentData, isActivity]);

	// all FS activities use this code to initialize the activity itself
	const initActivity = () => {
		AudioPlayer2.stopAll();
		setState(() => {
			setAnswer(saveTemplate);
			setForwardDisabled(false);
			setForwardStatus(FooterForwardBack.START);
		});
	};

	// all FS activities will use this code to start the actual activity (not the start screen)
	const startActivity = () => {
		AudioPlayer2.stopAll();
		questionTimer.current = Date.now();
		setState(() => {
			setCurrentQuestion(contentData.activity_words[currentQuestionIndex]);
			setForwardDisabled(true);
			setForwardStatus(FooterForwardBack.VALID);
		});
	};

	// all FS activities will have a pause button
	const handlePause = () => {
		if (isAutoplay) {
			AudioPlayer2.play(WordMeaningMatchConstants.COVER_UP);
		}
		setPaused(true);
	};

	// all FS activities will have a Continue button (opposite "Pause")
	const handleContinue = async () => {
		if (isAutoplay) {
			await AudioPlayer2.playSync(WordMeaningMatchConstants.COVER_OFF);
		}

		setState(() => {
			setPaused(false);
			questionTimer.current = Date.now();
		});
	};

	// all FS activities will handle the instructions button in the bottom left corner
	const handleInstructions = () => {
		setModalOpen(true);
		if (isEmpty(currentQuestion) === false) {
			handlePause();
		}
	};

	// all FS activities will handle the instruction icon in the upper left corner
	const handleInstructionClick = () => {
		AudioPlayer2.stopAll();
		if (isEmpty(currentQuestion) === false) {
			handlePause();
		}
	};

	// all FS activities will handle the help button (which may go away in the future)
	const handleHelpClick = () => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(WordMeaningMatchUtil.getHelpSound(helpCount, currentScreen === 4));
		if (currentScreen === 3 && isEmpty(currentQuestion) === false) {
			handlePause();
			setHelpCount(helpCount + 1);
		}
	};

	const handleForward = () => {
		AudioPlayer2.stopAll();
		// the foundational intro
		if (currentScreen === 1) {
			setCurrentScreen(2);
		}
		// they pressed the "Start" button on the activity screen
		else if (currentScreen === 3) {
			if (isEmpty(currentQuestion)) {
				startActivity();
			} else {
				if (isGradable) {
					// this was the last question, move to the last screen
					if (currentQuestionIndex === contentData.activity_words.length - 1) {
						if (isAutoplay) {
							AudioPlayer2.play(WordMeaningMatchUtil.getFinalScoreSound(numCorrect));
						}

						let tempAnswer = cloneDeep(answer);
						tempAnswer.activity_words[currentQuestionIndex].sentence.response_time =
							Date.now() - questionTimer.current;
						tempAnswer.has_partial = true;
						FoundationalUtil.sendProgressToServer(tempAnswer);
						setState(() => {
							setCurrentScreen(4);
							setAnswer(answer);
						});
					}
					// go to the next question
					else {
						handleQuestionComplete();
					}
				} else {
					// get the updated score
					if (answer.activity_words[currentQuestionIndex].sentence.score === 'correct') {
						AudioPlayer2.play(WordMeaningMatchConstants.CORRECT);
						setNumCorrect(numCorrect + 1);
					} else {
						AudioPlayer2.play(WordMeaningMatchConstants.INCORRECT);
						setNumIncorrect(numIncorrect + 1);
					}

					setState(() => {
						setForwardStatus(FooterForwardBack.VALID);
						setGradable(true);
					});
				}
			}
		}
		// the activity is complete, send it to the server
		else {
			let tempAnswer = cloneDeep(answer);
			let time = 0;
			tempAnswer.activity_words.forEach(a => {
				time += a.sentence.response_time;
			});
			tempAnswer.tracked_time = time;
			try {
				FoundationalUtil.completeActivity(history, tempAnswer);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		}
	};

	// all FS activities have to determine the right instruction VO to play
	const getInstructionVOSrc = useCallback(() => {
		return WordMeaningMatchUtil.getRandomizedSound(isFirstVisit, currentVO);
	}, [isFirstVisit, currentVO]);

	// all FS activities will handle the video completion
	const handleVideoComplete = useCallback(() => {
		if (currentScreen === 1) {
			setForwardDisabled(false);
		}
	}, [currentScreen]);

	// all FS activities will handle the close button
	const handleModalClose = () => {
		setModalOpen(false);
	};

	// all FS activities will display an Intro modal
	const intro = useMemo(
		() => (
			<FoundationalIntro
				title="Word Meaning Match"
				videoUrl={WordMeaningMatchConstants.INTRO_VIDEO}
				icons={[
					{text: 'Listen', icon: 'listen'},
					{text: 'Find', icon: 'preview'},
					{text: 'Click', icon: 'spot'}
				]}
				onVideoComplete={handleVideoComplete}
				isFirstVisit={isFirstVisit}
				instructionSoundSrc={getInstructionVOSrc()}
			/>
		),
		[handleVideoComplete, isFirstVisit, getInstructionVOSrc]
	);

	/******************
	 *
	 *   Custom code specific for your activity goes here
	 *
	 ******************/

	//////////
	// the mic check
	//////////
	const handleMicCheck = result => {
		initActivity();
		setCurrentScreen(3);
	};

	///////////
	// Functions from the ReadAloudPrompt
	///////////
	const handleQuestionComplete = () => {
		let tempAnswer = cloneDeep(answer);
		tempAnswer.activity_words[currentQuestionIndex].sentence.response_time = Date.now() - questionTimer.current;
		tempAnswer.has_partial = true;
		FoundationalUtil.sendProgressToServer(tempAnswer);

		setState(() => {
			setAnswer(answer);
			setGradable(false);
			setForwardDisabled(true);
			setForwardStatus(FooterForwardBack.SUBMIT);
			setCurrentQuestionIndex(currentQuestionIndex + 1);
			setCurrentQuestion(contentData.activity_words[currentQuestionIndex + 1]);
		});

		questionTimer.current = Date.now();
	};

	const handleValidationReady = isCorrect => {
		let tempAnswer = cloneDeep(answer);
		tempAnswer.activity_words[currentQuestionIndex].sentence.score = isCorrect ? 'correct' : 'incorrect';

		setState(() => {
			setAnswer(tempAnswer);
			setForwardDisabled(false);
			setForwardStatus(FooterForwardBack.SUBMIT);
		});
	};

	return (
		<>
			<Navbar helpSoundUrl={''} onHelp={handleHelpClick} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={[contentData, activityData]}
				title="Word Meaning Match"
			>
				<ActivityFrame isWhiteBackground={currentScreen !== 4}>
					<>
						<FSProgressBar title="Smart Zone" isVisible={currentScreen !== 4} />

						<ActivityInstructionButton
							audioSrc={getInstructionVOSrc()}
							fs={true}
							withoutFrame={currentScreen === 4}
							onSoundStart={handleInstructionClick}
						/>

						<div className={classes.wrapper}>
							{currentScreen === 1 && intro}

							{currentScreen === 2 && <MicCheck onMicCheckFinished={handleMicCheck} />}

							{isPaused === false && currentScreen === 3 && isEmpty(currentQuestion) === false && (
								<ReadAloudPrompt
									key={currentQuestion.id}
									question={currentQuestion}
									isGradable={isGradable}
									onValidationReady={handleValidationReady}
									onQuestionComplete={handleQuestionComplete}
								/>
							)}
							{isPaused && <div className="paused">&nbsp;</div>}

							{currentScreen === 4 && (
								<div className={`${classes.wordList}`}>
									<WordList id="" isCorrect isTimedOut={false} wordList={['Total = ' + numCorrect]} />
									<WordList
										id=""
										isCorrect={false}
										isTimedOut={false}
										wordList={['Total = ' + numIncorrect]}
									/>
								</div>
							)}
						</div>
					</>
				</ActivityFrame>

				{isModalOpen && (
					<FoundationalIntroModal isOpen={isModalOpen} onClose={handleModalClose}>
						{intro}
					</FoundationalIntroModal>
				)}

				<Footer>
					{(isPaused === false && currentScreen === 2) ||
						(currentScreen === 3 && isEmpty(currentQuestion) && (
							<>
								<ActivitySupportButton
									icon="instructions"
									onClick={handleInstructions}
									text="Instructions"
								/>
							</>
						))}
					{currentScreen === 3 && isPaused === false && isEmpty(currentQuestion) === false && (
						<ActivitySupportButton icon="pause" onClick={handlePause} text="Pause" disabled={false} />
					)}
					{isPaused && isEmpty(currentQuestion) === false && (
						<ActivitySupportButton icon="go-on" onClick={handleContinue} text="Continue" />
					)}
					<FooterForwardBack
						onForward={handleForward}
						isBackVisible={false}
						isForwardVisible={true}
						isForwardDisabled={isForwardDisabled}
						status={forwardStatus}
					/>
				</Footer>
			</ActivitySuspense>
		</>
	);
}
