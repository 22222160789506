import * as React from 'react';
import PropTypes from 'prop-types';

import {
	createContext,
	useContext,
	useEffect,
	useState,
	useReducer
} from 'react';

import { SpellingCorrectiveFeedbackPreloaderStatus, SpellingCorrectiveFeedbackText } from './constants';

import { FeedbackSpellUtil } from './FeedbackSpellUtil';
import { MEDIA_SERVER_URL } from '@reading/r180/src/utils/constants';
import { getAudioExt } from '@reading/r180/src/utils/audio';

export const SpellingCorrectiveFeedbackActionMap = {
	UPDATE_WORD_CARD: '@@spelling-feedback/update',
	SET_WORD_CARD: '@@spelling-feedback/set-word-card',
	PRELOAD_SOUND: '@@spelling-feedback/preload-sound',
	PRELOAD_SOUND_SUCCESS: '@@spelling-feedback/preload-sound-success',
	PRELOAD_SOUND_ERROR: '@@spelling-feedback/preload-sound-error'
};

const initialState = {
	audioBaseUrl: `${MEDIA_SERVER_URL}assets/dictio`,
	partsAudioPath: '/parts/r180u_wp_',
	tipsAudioPath: '/decodingtips/r180u_',
	spellAudioPath: '/alph/r180u_alph_',
	wordTextAudioPath: '/word/r180u_word_',
	sfxAudioPath: '/sfx/r180u_sfx_',
	audioFormat: 'mp3',
	data: null,
	error: null,
	loading: false,
	preloader: {}
};

export const SpellingCorrectiveFeedbackContext = createContext(initialState);

export const preloaderMiddleWare = (dispatch, action) => {
	if (action.type === SpellingCorrectiveFeedbackActionMap.PRELOAD_SOUND) {
		FeedbackSpellUtil.preloadAssets(
			action.payload.assets,
			action.payload.name
		).then(result => {
			dispatch({
				type: SpellingCorrectiveFeedbackActionMap.PRELOAD_SOUND_SUCCESS,
				payload: result
			});
		});
	}
};

export const applyMiddleWare = dispatch => action => {
	dispatch(action);
	preloaderMiddleWare(dispatch, action);
};

export const spellingCorrectiveFeedbackReducer = (state = initialState, action) => {
	let newState;

	switch (action.type) {
		case SpellingCorrectiveFeedbackActionMap.UPDATE_WORD_CARD:
			newState = { ...state };
			break;

		case SpellingCorrectiveFeedbackActionMap.SET_WORD_CARD:
			newState = { ...state, data: action.payload.spellingCorrectiveFeedbackData };

			break;

		case SpellingCorrectiveFeedbackActionMap.PRELOAD_SOUND:
			newState = {
				...state,
				preloader: {
					...state.preloader,
					[action.payload.name]: {
						name: action.payload.name,
						assets: action.payload.assets,
						status: SpellingCorrectiveFeedbackPreloaderStatus.LOADING
					}
				}
			};

			break;
		case SpellingCorrectiveFeedbackActionMap.PRELOAD_SOUND_SUCCESS:
			newState = {
				...state,
				preloader: {
					...state.preloader,
					[action.payload.name]: {
						...state.preloader[action.payload.name],
						status: SpellingCorrectiveFeedbackPreloaderStatus.COMPLETED
					}
				}
			};

			break;
		default:
			newState = { ...state };
			break;
	}

	return newState;
};

export const SpellingCorrectiveFeedbackProvider = ({ children, spellingCorrectiveFeedbackData }) => {
	const [state, dispatch] = useReducer(spellingCorrectiveFeedbackReducer, initialState);
	const { Provider } = SpellingCorrectiveFeedbackContext;

	const enhancedDispatcher = applyMiddleWare(dispatch);

	useEffect(() => {
		if (spellingCorrectiveFeedbackData && !state.data) {
			dispatch({
				type: SpellingCorrectiveFeedbackActionMap.SET_WORD_CARD,
				payload: { spellingCorrectiveFeedbackData }
			});
		}
	}, [spellingCorrectiveFeedbackData, state]);

	return (
		<Provider value={{ state, dispatch, enhancedDispatcher }}>
			{children}
		</Provider>
	);
};

SpellingCorrectiveFeedbackProvider.propTypes = {
	children: PropTypes.node,
	spellingCorrectiveFeedbackData: PropTypes.shape({
		text: PropTypes.string,
		instanceNumber: PropTypes.number,
		decodingInfo: PropTypes.shape({
			wordText: PropTypes.string,
			segments: PropTypes.string,
			markup: PropTypes.string,
			parts: PropTypes.arrayOf(
				PropTypes.shape({
					index: PropTypes.number,
					text: PropTypes.string,
					mediaName: PropTypes.string
				})
			)
		}),
		contextualData: PropTypes.arrayOf(
			PropTypes.shape({
				segmentId: PropTypes.string,
				contextualSentence: PropTypes.string,
				contextualDefinition: PropTypes.string
			})
		)
	})
};

export const useSpellingCorrectiveFeedbackDispatch = () => {
	const { enhancedDispatcher } = useContext(SpellingCorrectiveFeedbackContext);

	return enhancedDispatcher;
};
/* istanbul ignore next */
export const useSpellingCorrectiveFeedbackSelector = d => {
	const ctx = useContext(SpellingCorrectiveFeedbackContext);

	return d(ctx.state);
};

/* istanbul ignore next */
export const usePreloader = name => {
	const { state } = useContext(SpellingCorrectiveFeedbackContext);
	const [preloader, setPreloader] = useState(null);
	useEffect(() => {
		state &&
			state.preloader &&
			state.preloader[name] &&
			setPreloader(state.preloader[name]);
	}, [name, state]);

	return preloader;
};
/* istanbul ignore next */
export const useWordTextSound = () => {
	const { state } = useContext(SpellingCorrectiveFeedbackContext);
	const [sound, setSound] = useState(null);

	const EXT = getAudioExt();

	useEffect(() => {
		if (
			state &&
			state.data &&
			state.data.decodingInfo &&
			state.data.decodingInfo.wordText
		) {
			const { wordText } = state.data.decodingInfo;
			const wordTextSound = {
				audioName: `wordText-${wordText}`,
				audioUrl: `/word/r180u_word_${wordText}.${EXT}`
			};

			setSound(wordTextSound);
		}
	}, [state]);

	return sound;
};
/* istanbul ignore next */
export const useWordPartsDecoder = () => {
	const { state } = useContext(SpellingCorrectiveFeedbackContext);
	const [wordParts, setWordParts] = useState(null);

	const { getWordParts } = FeedbackSpellUtil;

	const { attachSoundForWordParts } = FeedbackSpellUtil;

	useEffect(() => {
		if (
			state &&
			state.data &&
			state.data.decodingInfo &&
			state.data.decodingInfo.parts
		) {
			const audioPath = [
				state.partsAudioPath,
				state.data.instanceNumber,
				state.audioFormat
			];

			const wParts = getWordParts(state.data.decodingInfo);

			const wPartsWithSound = attachSoundForWordParts(
				wParts,
				state.data.decodingInfo.parts,
				audioPath
			);

			setWordParts(wPartsWithSound);
		}
	}, [state, getWordParts, attachSoundForWordParts]);

	return wordParts;
};

/* istanbul ignore next */
export const useWordSpellDecoder = () => {
	const { state } = useContext(SpellingCorrectiveFeedbackContext);
	const [wordSpell, setWordSpell] = useState(null);

	useEffect(() => {
		if (state && state.data && state.data.decodingInfo) {
			const audioPath = [
				state.spellAudioPath,
				state.data.instanceNumber,
				state.audioFormat
			];

			const wSpell = FeedbackSpellUtil.getWordSpell(state.data.decodingInfo);

			const wSpellWithSound = FeedbackSpellUtil.attachSoundForWordSpell(
				wSpell,
				audioPath
			);

			setWordSpell(wSpellWithSound);
		}
	}, [state]);

	return wordSpell;
};
