import React, {Fragment, useState, useEffect} from 'react';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {makeStyles, Menu, MenuItem} from '@material-ui/core';
import {isEmpty} from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import HoverTip from '../HoverTip/HoverTip';
import SoundButton from '../SoundButton';
import {keyAndClick} from '../../util/ui-a11y';
import {TipModal} from '@reading/common';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {isFirefox} from '@reading/r180/src/utils/browsers';
import CorrectIncorrect from '../../components/CorrectIncorrect';

export const DragHelper = props => {
	const {
		children,
		options,
		onMenuItemClick,
		isOrderingQuestion,
		isMatchingQuestion,
		isSortingQuestion,
		isDragging,
		showSoundButton,
		item
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const getBorderClass = () => {
		switch (true) {
			case (isOrderingQuestion ||
				isSortingQuestion ||
				isMatchingQuestion) &&
				isDragging:
				return `draggable ${item.status}`;
			case isMatchingQuestion:
			case isOrderingQuestion:
			case isSortingQuestion:
				return `ordering ${item.status}`;
			default:
				return '';
		}
	};
	const useStyles = makeStyles(theme => ({
		dragHelper: ({
			isOrderingQuestion,
			isSortingQuestion,
			isMatchingQuestion
		}) => ({
			borderRadius: '8px',
			overflow: 'hidden',
			lineHeight: '0',
			'&.draggable': {
				border: `2px solid ${theme.colors.readingZone.primary}`
			},
			'&.ordering': {
				border: `1px solid ${theme.colors.paleGrey}`
			},
			'&.correct': {
				border: isMatchingQuestion
					? `1px solid ${theme.colors.successGreen}`
					: `2px solid ${theme.colors.successGreen}`
			},
			'&.incorrect': {
				border: isMatchingQuestion
					? `1px solid ${theme.colors.errorRed}`
					: `2px solid ${theme.colors.errorRed}`
			},
			'& .helper': {
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				width: '24px',
				background:
					isOrderingQuestion ||
					isSortingQuestion ||
					isMatchingQuestion
						? 'none'
						: theme.colors.softBlack,
				color: theme.colors.white,
				opacity: '0.8',
				borderTopLeftRadius: '8px',
				borderBottomLeftRadius: '8px',
				'& button': {
					position: 'absolute',
					bottom: '2px',
					left: '5px',
					color: theme.colors.white,
					background: 'transparent',
					fontSize: '16px'
				}
			},
			'& .drag-content': {}
		}),
		dragIconWrapper: {
			position: 'absolute',
			right: '2px'
		},
		dragIcon: ({
			isOrderingQuestion,
			isSortingQuestion,
			isMatchingQuestion
		}) => ({
			lineHeight: '8px',
			letterSpacing: '4px',
			position: 'relative',
			left: '1px',
			color:
				isOrderingQuestion || isSortingQuestion || isMatchingQuestion
					? theme.colors.grey
					: theme.colors.white
		}),
		dragMenuItem: {
			background: theme.colors.white,
			color: theme.colors.black,
			fontFamily: theme.fonts.uiLight,
			fontStyle: 'normal',
			fontWeight: '700',
			fontSize: '18px',
			lineHeight: '22px',
			'&:hover': {
				background: theme.colors.paleGrey
			}
		},
		soundIcon: {
			position: 'relative',
			left: '-2px',
			height: '50px'
		}
	}));
	const classes = useStyles({
		isOrderingQuestion,
		isSortingQuestion,
		isMatchingQuestion
	});

	const handleClick = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = e => {
		e.stopPropagation();
		setAnchorEl(null);
	};

	const handleOptionClick = (
		e,
		cb,
		title,
		index,
		source_droppableId,
		destination_droppableId
	) => {
		e.stopPropagation();
		cb(e, title, index, source_droppableId, destination_droppableId);
		handleClose(e);
	};
	const handleDragContentClick = e => {
		e.stopPropagation();
		if (item.content.type === 'div') {
			AudioPlayer2.play(item.text);
		}
	};
	return (
		<HoverTip title="Click and drag into the correct container">
			<div className={`${clsx(classes.dragHelper, getBorderClass())}`}>
				<div className="helper">
					{showSoundButton && (
						<div className={classes.soundIcon}>
							<SoundButton
								border={false}
								sound={item.audio}
								size={32}
								className={'transparent'}
							/>
						</div>
					)}
					<div className={classes.dragIconWrapper}>
						<div className={classes.dragIcon}>..</div>
						<div className={classes.dragIcon}>..</div>
						<div className={classes.dragIcon}>..</div>
					</div>

					{!isSortingQuestion && options && options.length > 0 && (
						<>
							<button
								type="button"
								className="no-drag"
								aria-controls="drag-menu"
								aria-haspopup="true"
								onClick={handleClick}
							>
								<i className="r180 arrow-right" />
							</button>
							<Menu
								id="drag-menu"
								anchorEl={anchorEl}
								keepMounted
								open={isEmpty(anchorEl) === false}
								onClose={handleClose}
							>
								{options.map(option => {
									return (
										<MenuItem
											className={`${classes.dragMenuItem}`}
											key={option.title}
											onClick={e =>
												handleOptionClick(
													e,
													onMenuItemClick,
													option.title,
													option.index,
													option.source_droppableId,
													option.destination_droppableId
												)
											}
										>
											{option.title}
										</MenuItem>
									);
								})}
							</Menu>
						</>
					)}
				</div>
				<div
					className="drag-content"
					onClick={e => handleDragContentClick(e)}
				>
					{children}
				</div>
			</div>
		</HoverTip>
	);
};

DragHelper.defaultProps = {};

DragHelper.propTypes = {};

const DragAndDrop = props => {
	const {
		bucketInfoList,
		bucketItemList,
		bucketStyleList,
		droppableStyleList,
		disableHelperBar,
		showSoundButton,
		isMatchingQuestion,
		isOrderingQuestion,
		isSortingQuestion,
		interactive,
		dragDisableId,
		onDragDrop,
		showInfo
	} = props;

	const getBorderForInnerContainer = colors => {
		if (isOrderingQuestion) {
			return 'none';
		} else if (isSortingQuestion) {
			return `1px dashed ${colors.grey}`;
		} else if (isMatchingQuestion) {
			return 'none';
		} else {
			return `2px dotted ${colors.paleGrey}`;
		}
	};

	const getMinWidthForInnerContainer = () => {
		if (isSortingQuestion) {
			return isFirefox() ? '100%' : '95%';
		}
		return '94%';
	};
	const useStyles = makeStyles(theme => ({
		dragDropContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexWrap: 'wrap'
		},
		innerBucket: {
			borderRadius: '8px',
			height: '93%',
			width: isSortingQuestion ? '216px' : '3%',
			minWidth: getMinWidthForInnerContainer(),
			position: 'relative',
			left: isSortingQuestion ? '2px' : '2.7%',
			right: '2.7%',
			top: '3.7%',
			bottom: '3.7%',
			border: getBorderForInnerContainer({
				grey: theme.colors.grey,
				paleGrey: theme.colors.paleGrey
			}),
			'& #correct, #incorrect': {
				position: 'absolute',
				left: '10px',
				top: '-5px',
				color: theme.colors.white,
				borderRadius: '50%',
				padding: '6px 6px 4px 5px',
				'&.check': {
					background: theme.colors.successGreen
				},
				'&.x': {
					background: theme.colors.errorRed
				}
			},
			'& #arrowTop': {
				position: 'absolute',
				left: '15px',
				bottom: '35px',
				borderRadius: '50%',
				transform: 'rotate(90deg)',
				cursor: 'pointer',
				'&:hover': {
					color: theme.colors.readingZone.primary
				}
			},
			'& #arrowBottom': {
				position: 'absolute',
				left: '15px',
				top: '-5px',
				borderRadius: '50%',
				transform: 'rotate(90deg)',
				cursor: 'pointer',
				'&.ordering': {
					bottom: '-48px',
					left: '-18px'
				},
				'&:hover': {
					color: theme.colors.readingZone.primary
				}
			}
		},
		innerBucketDragging: {
			border: isSortingQuestion
				? `2px dashed ${theme.colors.readingZone.primary}`
				: `2px dotted ${theme.colors.readingZone.primary}`,
			background: isSortingQuestion
				? theme.colors.readingZone.blue5
				: 'inherit',
			dragItem: {
				'& .extraHelpers': {
					display: 'none'
				}
			}
		},
		outerBucketDraggingOver: {
			background: theme.colors.readingZone.blue5
		},

		outerBucket: {
			borderRadius: '0px 0px 8px 8px',
			padding: '4px',
			'&.correct': {
				padding: '0px !important',
				border: `4px solid ${theme.colors.successGreen}`
			},
			'&.incorrect': {
				padding: '0px  !important',
				border: `4px solid ${theme.colors.errorRed}`
			},
			'&::-webkit-scrollbar': {
				width: '6px'
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				padding: '10px 0'
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#CCC',
				borderRadius: '8px'
			}
		},
		dragItem: {
			userSelect: 'none',
			display: 'inline',
			float: 'left',
			borderRadius: '8px',
			textAlign: 'center',
			position: 'relative',
			margin: '1px',
			'&:hover': {
				border: 'solid 3px',
				borderColor: theme.colors.readingZone.primary
			},

			'& .extraHelpers': {
				position: 'absolute',
				left: '-15%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'space-around'
			}
		},
		dragImageItem: {
			userSelect: 'none',
			display: 'inline',
			float: 'left',
			borderRadius: '8px',
			textAlign: 'center',
			position: 'relative',
			margin: '1px',
			transition: '0.3s ease all',
			'&:hover': {
				marginTop: '-15px',
				marginLeft: '-10px',
				border: 'solid 3px',
				borderColor: theme.colors.readingZone.primary,
				'& .imgContentDiv': {
					transition: '0.3s ease all',
					zIndex: 1000,
					width: '160px !important',
					height: '120px !important'
				}
			}
		},
		dragDestinationItem: {
			userSelect: 'none',
			display: 'inline',
			float: 'left',
			borderRadius: '8px',
			textAlign: 'center',
			position: 'relative',
			margin: '1px'
		},
		itemDragging: {
			border: 'solid 3px',
			borderColor: theme.colors.readingZone.primary,
			width: 'fit-content',

			'& .extraHelpers': {
				display: 'none'
			}
		},
		imageItemDragging: {
			border: 'solid 3px',
			borderColor: theme.colors.readingZone.primary,
			width: '160px !important',
			height: '120px !important',
			'& .imgContentDiv': {
				width: '160px !important',
				height: '115px !important'
			}
		},
		connectDragItem: {
			marginLeft: '24px'
		},
		dropHere: {
			color: theme.colors.paleGrey,
			fontStyle: 'italic',
			fontWeight: 'normal',
			fontSize: '18px',
			lineHeight: '18px',
			opacity: '0.8',
			display: 'flex',
			justifyContent: 'center',
			marginTop: '33%'
		},
		dropHereDragging: {
			color: theme.colors.readingZone.primary
		},
		dropHereHide: {
			display: 'none'
		},
		iconsContainer: {
			display: 'flex',
			flexDirection: 'column',
			position: 'absolute',
			left: isSortingQuestion
				? '159px'
				: isMatchingQuestion
				? '186px'
				: '310px',
			bottom: '26px',
			top: isSortingQuestion
				? '12px'
				: isMatchingQuestion
				? '40px'
				: '32px',
			zIndex: isMatchingQuestion ? '3' : 'inherit',
			fontSize: '16px'
		},
		containerHeader: {
			background: isSortingQuestion ? 'none' : theme.colors.softBlack,
			borderRadius: '8px 8px 0px 0px',
			minHeight: '24px'
		},
		containerHeaderToolTip: {
			display: 'inline-block',
			float: 'left',
			marginLeft: '10px',
			'& i': {
				position: 'relative',
				color: theme.colors.white,
				fontSize: '16px',
				cursor: 'pointer'
			}
		},
		containerHeaderText: {
			display: 'inline-block',
			fontSize: '16px',
			fontFamily: theme.fonts.uiLight,
			fontStyle: 'normal',
			fontWeight: 'bold',
			color: theme.colors.white,
			lineHeight: '18px',
			float: 'left',
			marginLeft: isSortingQuestion ? '2px' : '10px',
			marginTop: '2px'
		},
		itemsSpacing: {
			marginBottom: '24px'
		}
	}));
	const classes = useStyles();

	const [stateBucketItem, setStateBucketItem] = useState(bucketItemList);
	const [stateBucketInfo, setStateBucketInfo] = useState(bucketInfoList);
	const [stateBucketStyle, setStateBucketStyle] = useState(bucketStyleList);
	const [stateDroppableStyle, setStateDroppableStyle] = useState(
		droppableStyleList
	);
	const [isOpenTipModal, setOpenTipModal] = useState(false);
	const [selectedWord, setSelectedWord] = useState();

	// use effect added to update the stateBucketInfo when bucketInfoList will change
	useEffect(() => {
		stateBucketItem.forEach((item, ind) => {
			if (stateBucketInfo[ind].IsDroppableSourceSpeakerVisible) {
				AudioPlayer2.load({
					name: item[0].text,
					src: item[0].textUrl
				});
			}
		});
	});

	// use effect added to update the stateBucketInfo when bucketInfoList will change
	useEffect(() => {
		setStateBucketInfo(bucketInfoList);
	}, [bucketInfoList]);

	// use effect added to update the bucketItemList when bucketItemList will change

	useEffect(() => {
		setStateBucketItem(bucketItemList);
	}, [bucketItemList]);

	useEffect(() => {
		setStateBucketStyle(bucketStyleList);
	}, [bucketStyleList]);

	useEffect(() => {
		setStateDroppableStyle(droppableStyleList);
	}, [droppableStyleList]);

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const move = (
		source,
		destination,
		droppableSource,
		droppableDestination
	) => {
		const sourceClone = Array.from(source);
		const destClone = Array.from(destination);
		const [removed] = sourceClone.splice(droppableSource.index, 1);
		destClone.splice(droppableDestination.index, 0, removed);
		const result = {};
		result[droppableSource.droppableId] = sourceClone;
		result[droppableDestination.droppableId] = destClone;
		return result;
	};

	const getBucketStyle = (isDraggingOver, givenBucketStyle) => ({
		...givenBucketStyle
	});
	const getDroppableStyle = givenDroppableStyle => ({
		...givenDroppableStyle
	});

	const handleMenuItemClick = (
		e,
		title,
		index,
		source_droppableId,
		destination_droppableId
	) => {
		if (!interactive) {
			return;
		}
		e.stopPropagation();
		e.preventDefault();

		const result = move(
			stateBucketItem[source_droppableId],
			stateBucketItem[destination_droppableId],
			{droppableId: source_droppableId, index: index},
			{droppableId: destination_droppableId, index: index}
		);
		const newState = [...stateBucketItem];
		if (result[source_droppableId] == 0) {
			result[source_droppableId] = [{id: 'empty'}];
		}
		newState[source_droppableId] = result[source_droppableId];
		newState[destination_droppableId] = result[destination_droppableId];
		setStateBucketItem(newState.filter(group => group.length));
		if (typeof onDragDrop === 'function') {
			const stateClone = cloneDeep(newState);
			const cleanState = removeEmptyItem(stateClone);
			onDragDrop(cleanState);
		}
	};

	const buildMenuItemList = (sourceIndex, itemIndex) => {
		const menuItems = [];
		stateBucketInfo.map(
			(el, index) =>
				el.type === 'destination' &&
				sourceIndex !== index &&
				menuItems.push({
					title: el.menuText,
					index: itemIndex,
					source_droppableId: sourceIndex,
					destination_droppableId: index
				})
		);
		return menuItems;
	};

	const onDragEnd = result => {
		const {source, destination} = result;

		if (!destination) {
			return;
		}
		const sInd = +source.droppableId;
		const dInd = +destination.droppableId;

		if (sInd === dInd) {
			if (isMatchingQuestion) {
				return;
			}
			const items = reorder(
				stateBucketItem[sInd],
				source.index,
				destination.index
			);
			if (isOrderingQuestion) {
				items.forEach((item, index) => {
					item.studentSequence = index;
				});
			}
			const newState = [...stateBucketItem];
			newState[sInd] = items;
			setStateBucketItem(newState);
			if (typeof onDragDrop === 'function') {
				onDragDrop(newState);
			}
		} else {
			if (isMatchingQuestion) {
				onDragDrop(result);
			} else {
				const result = move(
					stateBucketItem[sInd],
					stateBucketItem[dInd],
					source,
					destination
				);
				const newState = [...stateBucketItem];
				if (result[sInd] == 0) {
					result[sInd] = [{id: 'empty'}];
				}
				newState[sInd] = result[sInd];
				newState[dInd] = result[dInd];
				setStateBucketItem(newState.filter(group => group.length));
				if (typeof onDragDrop === 'function') {
					const stateClone = cloneDeep(newState);
					const cleanState = removeEmptyItem(stateClone);
					onDragDrop(cleanState);
				}
			}
		}
	};

	const removeEmptyItem = data => {
		data.map((el, ind) =>
			el.map((item, index) => item.id === 'empty' && el.splice(index, 1))
		);

		return data;
	};
	const handleTopArrowClick = (event, item) => {
		event.preventDefault();
		if (item.studentSequence === 0) {
			return;
		}
		const items = reorder(
			stateBucketItem[0],
			item.studentSequence,
			item.studentSequence - 1
		);
		items.forEach((item, index) => {
			item.studentSequence = index;
		});
		const newState = [...stateBucketItem];
		newState[0] = items;
		setStateBucketItem(newState);
		if (typeof onDragDrop === 'function') {
			onDragDrop(newState);
		}
	};

	const handleBottomArrowClick = (event, item) => {
		event.preventDefault();
		if (item.studentSequence === stateBucketItem[0].length - 1) {
			return;
		}
		const items = reorder(
			stateBucketItem[0],
			item.studentSequence,
			item.studentSequence + 1
		);
		items.forEach((item, index) => {
			item.studentSequence = index;
		});
		const newState = [...stateBucketItem];
		newState[0] = items;
		setStateBucketItem(newState);
		if (typeof onDragDrop === 'function') {
			onDragDrop(newState);
		}
	};

	const showIcons = item => {
		switch (item.status) {
			case 'correct': {
				return (
					<div className={classes.iconsContainer}>
						<CorrectIncorrect correct={true} />
					</div>
				);
			}
			case 'incorrect': {
				return (
					<div className={classes.iconsContainer}>
						<CorrectIncorrect correct={false} />
					</div>
				);
			}

			default: {
				return (
					<>
						{isOrderingQuestion && (
							<div className={classes.iconsContainer}>
								<i
									id="arrowTop"
									className="r180 arrow-left"
									{...keyAndClick(e =>
										handleTopArrowClick(e, item)
									)}
									tabIndex={0}
								/>
								<i
									id="arrowBottom"
									className="r180 arrow-right ordering"
									{...keyAndClick(e =>
										handleBottomArrowClick(e, item)
									)}
									tabIndex={0}
								/>
							</div>
						)}
						{isSortingQuestion && (
							<div className={classes.iconsContainer}>
								<i
									id="arrowBottom"
									className="r180 arrow-right"
									tabIndex={0}
								/>
							</div>
						)}
					</>
				);
			}
		}
	};

	const handleContainerTipClick = (
		word,
		containerHeader,
		containerHeaderTipUrl
	) => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(containerHeaderTipUrl);
	};

	const handleTipClick = (word, bucketinfo) => {
		setSelectedWord({
			text: word.text,
			textUrl: word.textUrl,
			tip: word.tip,
			tipUrl: word.tipUrl
		});
		setOpenTipModal(true);
	};

	const handleSpeakerClick = word => {
		setSelectedWord({
			text: word.text,
			textUrl: word.textUrl,
			tip: word.tip,
			tipUrl: word.tipUrl
		});
		AudioPlayer2.play(word.text);
	};
	const handleTipModalGoOn = e => {
		setOpenTipModal(false);
	};
	const handleCancelExit = e => {
		setOpenTipModal(false);
	};

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<div
					id="drag-drop-container"
					className={classes.dragDropContainer}
				>
					{stateBucketItem.map((el, ind) => (
						<Fragment key={ind}>
							<div
								style={getDroppableStyle(
									stateDroppableStyle[ind]
								)}
							>
								{stateBucketInfo[ind].header && (
									<div className={classes.containerHeader}>
										{showInfo && (
											<div
												className={
													classes.containerHeaderToolTip
												}
											>
												<button
													type="button"
													onClick={() =>
														handleContainerTipClick(
															el[0],
															stateBucketInfo[
																ind
															],
															stateBucketInfo[ind]
																.headerTipUrl
														)
													}
													aria-label="info"
												>
													<i
														className={`r180 info`}
													/>
												</button>
											</div>
										)}
										<div
											className={
												classes.containerHeaderText
											}
										>
											{stateBucketInfo[ind].header}
										</div>
										<div style={{clear: 'both'}}></div>
									</div>
								)}

								<Droppable
									isDropDisabled={
										dragDisableId &&
										stateBucketInfo[ind].type ===
											dragDisableId
									}
									key={ind}
									droppableId={`${ind}`}
								>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											className={
												isSortingQuestion &&
												snapshot.isDraggingOver
													? `${classes.outerBucket}  ${classes.outerBucketDraggingOver} ${stateBucketInfo[ind].status}`
													: `${classes.outerBucket} ${stateBucketInfo[ind].status}`
											}
											style={getBucketStyle(
												snapshot.isDraggingOver,
												stateBucketStyle[ind]
											)}
										>
											{/* {...provided.droppableProps} */}
											<div
												className={
													!isMatchingQuestion &&
													!isOrderingQuestion &&
													snapshot.isDraggingOver
														? `${classes.innerBucket} ${classes.innerBucketDragging}`
														: `${
																classes.innerBucket
														  } ${isOrderingQuestion &&
																snapshot.isDraggingOver &&
																classes.innerBucketDragging}}`
												}
											>
												{el.map((item, index) => (
													<Draggable
														key={item.id}
														draggableId={item.id}
														index={index}
														isDragDisabled={
															!interactive
														}
													>
														{(provided, snapshot) =>
															item.id ===
															'empty' ? (
																<div
																	ref={
																		provided.innerRef
																	}
																	{...provided.dragHandleProps}
																></div>
															) : (
																<div
																	ref={
																		provided.innerRef
																	}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	className={`${
																		!isMatchingQuestion &&
																		!isOrderingQuestion &&
																		!isSortingQuestion &&
																		snapshot.isDragging
																			? item
																					.content
																					.type ===
																			  'img'
																				? `${classes.dragImageItem} ${classes.imageItemDragging}`
																				: `${classes.dragItem} ${classes.itemDragging}`
																			: stateBucketInfo[
																					ind
																			  ]
																					.header
																			? `${classes.dragDestinationItem}`
																			: item
																					.content
																					.type ===
																			  'img'
																			? `${classes.dragImageItem}`
																			: `${classes.dragItem}`
																	} ${isMatchingQuestion &&
																		item.connected &&
																		classes.connectDragItem} ${isOrderingQuestion &&
																		classes.itemsSpacing}`}
																	style={{
																		...snapshot.isDragging,
																		...provided
																			.draggableProps
																			.style,
																		...item.style
																	}}
																>
																	<div
																		className={
																			'extraHelpers'
																		}
																		style={{
																			width:
																				'28px'
																		}}
																	>
																		{stateBucketInfo[
																			ind
																		]
																			.IsDroppableSourceTipVisible && (
																			<button
																				type="button"
																				onClick={() =>
																					handleTipClick(
																						el[0],
																						stateBucketInfo
																					)
																				}
																				aria-label="Play Sound"
																			>
																				<i
																					className={`r180 info`}
																					style={{
																						position:
																							'relative',
																						color:
																							'#0093E6',
																						fontSize:
																							'16px',
																						cursor:
																							'pointer'
																					}}
																				/>
																			</button>
																		)}
																		{stateBucketInfo[
																			ind
																		]
																			.IsDroppableSourceSpeakerVisible && (
																			<button
																				type="button"
																				onClick={() =>
																					handleSpeakerClick(
																						el[0]
																					)
																				}
																				aria-label="Play Sound"
																			>
																				<i
																					className={`r180 speaker-center`}
																					style={{
																						position:
																							'relative',
																						color:
																							'#8EA9B9',
																						fontSize:
																							'16px',
																						cursor:
																							'pointer'
																					}}
																				/>
																			</button>
																		)}
																	</div>
																	{disableHelperBar ===
																	false ? (
																		<DragHelper
																			options={buildMenuItemList(
																				ind,
																				index
																			)}
																			onMenuItemClick={
																				handleMenuItemClick
																			}
																			showSoundButton={
																				showSoundButton
																			}
																			isMatchingQuestion={
																				isMatchingQuestion
																			}
																			isOrderingQuestion={
																				isOrderingQuestion
																			}
																			isSortingQuestion={
																				isSortingQuestion
																			}
																			item={
																				item
																			}
																			isDragging={
																				snapshot.isDragging
																			}
																		>
																			{
																				item.content
																			}
																			{(isOrderingQuestion ||
																				isSortingQuestion ||
																				isMatchingQuestion) &&
																				showIcons(
																					item
																				)}
																		</DragHelper>
																	) : (
																		<>
																			{
																				item.content
																			}
																		</>
																	)}
																</div>
															)
														}
													</Draggable>
												))}
												{stateBucketInfo[ind].status ===
													'correct' && (
													<i
														key={ind}
														id="correct"
														className="r180 check validation"
													/>
												)}
												{stateBucketInfo[ind].status ===
													'incorrect' && (
													<i
														key={ind}
														id="incorrect"
														className="r180 x validation"
													/>
												)}
											</div>
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</div>
						</Fragment>
					))}
				</div>
			</DragDropContext>
			{selectedWord && (
				<TipModal
					isOpen={isOpenTipModal}
					onConfirm={handleTipModalGoOn}
					onCancel={handleCancelExit}
					word={selectedWord.text}
					wordUrl={selectedWord.textUrl}
					tip={selectedWord.tip}
					tipUrl={selectedWord.tipUrl}
				/>
			)}
		</>
	);
};

export default DragAndDrop;

DragAndDrop.defaultProps = {
	bucketInfoList: [],
	bucketItemList: [],
	bucketStyleList: [],
	droppableStyleList: [],
	disableHelperBar: false,
	showSoundButton: false,
	isMatchingQuestion: false,
	isOrderingQuestion: false,
	isSortingQuestion: false,
	interactive: true,
	dragDisableId: null,
	showInfo: true
};

DragAndDrop.propTypes = {
	bucketInfoList: PropTypes.arrayOf(PropTypes.object).isRequired,
	bucketItemList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object))
		.isRequired,
	bucketStyleList: PropTypes.arrayOf(PropTypes.object).isRequired,
	droppableStyleList: PropTypes.arrayOf(PropTypes.object).isRequired,
	disableHelperBar: PropTypes.bool,
	showSoundButton: PropTypes.bool,
	isMatchingQuestion: PropTypes.bool,
	isOrderingQuestion: PropTypes.bool,
	isSortingQuestion: PropTypes.bool,
	interactive: PropTypes.bool,
	onDragDrop: PropTypes.func.isRequired,
	showInfo: PropTypes.bool
};
