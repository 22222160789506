export const convertTagsToText = text => {
	const multiRegex = new RegExp('<multi/>', 'g');
	let convertedText = text.replace(
		multiRegex,
		'                                  '
	);
	const wordRegex = new RegExp('<word/>', 'g');
	convertedText = convertedText.replace(wordRegex, '                 ');
	const fillRegex = new RegExp('<fill/>', 'g');
	convertedText = convertedText.replace(fillRegex, '    ');
	return convertedText;
};

export const formattedText = text => {
	const multiRegex = new RegExp('<multi/>', 'g');
	let convertedText = text.replace(multiRegex, '');
	const wordRegex = new RegExp('<word/>', 'g');
	convertedText = convertedText.replace(wordRegex, '');
	const fillRegex = new RegExp('<fill/>', 'g');
	convertedText = convertedText.replace(fillRegex, '');
	const doubleSpacRegex = new RegExp(' {2}', 'g');
	convertedText = convertedText.replace(doubleSpacRegex, ' ');
	const fullStopRegex = new RegExp('/s.', 'g');
	convertedText = `${convertedText}`.replace(fullStopRegex, '.');

	return convertedText;
};

export const parsePassageText = text => {
	if (typeof text === 'string') {
		const regexSymbols = /<(\/?|\!?)(passage)>/g;
		const passageCleaned = text.replace(regexSymbols, '');
		const paragraphs = passageCleaned
			.split('<paragraph>')
			.filter(i => i.length > 0)
			.map(item => {
				var regexSymbolsPara1 = /(<([^>]+)>)/gi;
				const paraText1 = item.replace(regexSymbolsPara1, '');

				return paraText1;
			});

		return paragraphs;
	}
};
export const sortByMode = (draftData, mode, uniqueIDMap) => {
	switch (mode) {
		case 'informative': {
			const ideasCount = Object.keys(draftData).filter(
				i => i.indexOf('idea') > -1
			).length;

			let finalIdeaString = `${draftData['thesis0']} `;
			const ideasList = [];
			Object.keys(uniqueIDMap).filter(
				i => i.indexOf('idea') > -1 && ideasList.push(uniqueIDMap[i])
			);
			ideasList.forEach(i => {
				const matchString = Object.keys(i).filter(
					i => i.indexOf('idea') > -1
				)[0];

				const idea = draftData[i.idea.panelUniqueID];

				const firstDetail = draftData[i.detail1.panelUniqueID] || '';

				const secondtDetail = draftData[i.detail2.panelUniqueID] || '';
				finalIdeaString += `${idea} ${firstDetail} ${secondtDetail} `;
			});
			finalIdeaString +=
				draftData[
				Object.keys(draftData).filter(i => i.indexOf('end') > -1)[0]
				];
			return finalIdeaString;
		}
		case 'argument': {
			const ideasCount = Object.keys(draftData).filter(
				i => i.indexOf('reason') > -1
			).length;

			let finalIdeaString = `${draftData['thesis0']} `;
			const ideasList = [];
			Object.keys(uniqueIDMap).filter(
				i => i.indexOf('reason') > -1 && ideasList.push(uniqueIDMap[i])
			);
			ideasList.forEach(i => {
				const matchString = Object.keys(i).filter(
					i => i.indexOf('reason') > -1
				)[0];
				const idea =
					draftData[
					uniqueIDMap[matchString][matchString].panelUniqueID
					];
				const firstDetail = draftData[i.evidence1.panelUniqueID] || '';

				const secondtDetail =
					draftData[i.evidence2.panelUniqueID] || '';
				finalIdeaString += `${idea} ${firstDetail} ${secondtDetail} `;
			});
			const endPanelMapId = Object.keys(draftData).filter(
				i =>
					i.indexOf('positiveEnd') > -1 ||
					i.indexOf('negativeEnd') > -1
			)[0];
			finalIdeaString += draftData[endPanelMapId];

			return finalIdeaString;
		}

		case 'narrative': {
			const narrativeArr = draftData.map(item => {
				let text = item.narrative;
				if (text.charAt(text.length - 2) === '.') {
					text = text.substring(0, text.length - 2);
				}
				return text.trim();
			});

			return narrativeArr.join('. ');
		}

		/*istanbul ignore next*/
		default: {
		}
	}
};

export const spellCheck = (writingToolsSpellCheck, reviewText) => {
	let updatedReviewText = '';
	updatedReviewText = reviewText
		.split(' ')
		.map(item => {
			const regexSymbols = /[^\w\s]/gi;
			let cleanItem = item.replace(regexSymbols, '').toLowerCase();
			cleanItem = cleanItem.trim();
			if (writingToolsSpellCheck[cleanItem]) {
				return item;
			} else if (item.trim().length === 1 && regexSymbols.test(item)) {
				return item;
			} else if (item.trim().length === 1) {
				return item;
			} else {
				return item.replace(item, match => `<span>${match}</span>`);
			}
		})
		.join(' ');
	return updatedReviewText;
};

export const tiredWords = (writingToolsTiredWordsObj, reviewText) => {
	const wordFrequencyMap = wordFrequency(reviewText);
	let tiredWordsMapList = [];

	let updatedReviewText = '';
	updatedReviewText = reviewText
		.split(' ')
		.map((item, idx) => {
			const filterItem = item.replace(/[^\w\s]/gi, '');
			let cleanItem = filterItem.toLowerCase();
			cleanItem = cleanItem.trim();
			if (writingToolsTiredWordsObj[cleanItem]) {
				if (writingToolsTiredWordsObj[cleanItem].threshold === 1) {
					// if the threshold level count is 1
					tiredWordsMapList.push({ word: filterItem, id: idx });
					return item.replace(
						filterItem,
						match =>
							`<span class="tiredWords" id=${idx}>${match}</span>`
					);
				} else if (
					wordFrequencyMap[cleanItem] >=
					writingToolsTiredWordsObj[cleanItem].threshold
				) {
					// if the threshold level count is equal to max freq of word
					tiredWordsMapList.push({ word: filterItem, id: idx });
					return item.replace(
						filterItem,
						match =>
							`<span class="tiredWords" id=${idx}>${match}</span>`
					);
				} else {
					return item;
				}
			} else {
				return item;
			}
		})
		.join(' ');
	return { updatedReviewText, tiredWordsMapList };
};

const wordFrequency = string => {
	const words = string.replace(/[^\w\s]/gi, '').toLowerCase().split(/\s/);
	let freqMap = {};
	words.forEach(function (w) {
		if (!freqMap[w]) {
			freqMap[w] = 0;
		}
		freqMap[w] += 1;
	});

	return freqMap;
};

export const sentenceSense = (reviewText, prevShortestSentenceIndexList) => {
	let updatedReviewText = '';
	let shortestSentenceList = [];
	let shortestSentenceIndexList = [];

	//  If the toggle is re-activated it only shows the (up to 3) original sentences highlighted - it does not re-check for short sentences.
	if (prevShortestSentenceIndexList.length > 0) {
		shortestSentenceList = prevShortestSentenceIndexList;
		updatedReviewText = reviewText
			.split('.')
			.map((item, idx) => {
				const filterItem = prevShortestSentenceIndexList.filter((item) => item.index === idx).map(item => item)[0];
				let isSentenceSense = false;

				if (filterItem) {
					//If a previously underlined sentence is edited or modified then we will not re-highlight it.
					if (filterItem.index === idx && filterItem.sentence === item) {
						isSentenceSense = true;
					}
				}

				if (isSentenceSense) {
					return item.replace(
						item.trim(),
						match => `<span id=${idx}>${match}</span>`
					);
				} else {
					return item;
				}

			})
			.join('.');
	} else {
		// this code will run only for the first time.
		shortestSentenceIndexList = topThreeShortestSentence(reviewText);
		updatedReviewText = reviewText
			.split('.')
			.map((item, idx) => {
				if (shortestSentenceIndexList.includes(idx)) {
					const wordCount = countWords(item);
					shortestSentenceList.push({ index: idx, wordCount: wordCount, sentence: item })
					if (wordCount > 5) {
						return item;
					} else {
						return item.replace(
							item.trim(),
							match => `<span id=${idx}>${match}</span>`
						);
					}
				} else {
					return item;
				}
			})
			.join('.');
	}
	return { updatedReviewText, shortestSentenceList };
};

const topThreeShortestSentence = reviewText => {
	let sentenceList = {};
	let topThreeShortestSentenceIndex = 0;
	reviewText.split('.').forEach((sentence, idx) => {
		if (!sentence.trim()) {
			return;
		}
		const wordCount = countWords(sentence);
		if (wordCount <= 5) {
			sentenceList[idx] = wordCount;
		}
	});
	topThreeShortestSentenceIndex = Object.keys(sentenceList)
		.sort(function (a, b) {
			return sentenceList[a] - sentenceList[b];
		})
		.map(i => Number(i))
		.slice(0, 3);
	return topThreeShortestSentenceIndex;
};

const countWords = str => {
	str = str.replace(/(^\s*)|(\s*$)/gi, '');
	str = str.replace(/[ ]{2,}/gi, ' ');
	str = str.replace(/\n /, '\n');
	return str.split(' ').length;
};

export const updateReviewTextWithoutHighlight = (
	reviewText,
	tiredWordsMap,
	tiredWordId,
	swapWord
) => {
	let updatedReviewText = reviewText;
	tiredWordsMap.forEach((map, idx) => {
		if (Number(tiredWordId) === map.id) {
			updatedReviewText = updatedReviewText.replace(
				`<span class="tiredWords" id=${map.id}>${map.word}</span>`,
				swapWord
			);
		} else {
			updatedReviewText = updatedReviewText.replace(
				`<span class="tiredWords" id=${map.id}>${map.word}</span>`,
				map.word
			);
		}
	});
	return updatedReviewText;
};
