import React, {useEffect, useState, useRef} from 'react';
import {useUnmount} from 'react-use';
import PropTypes from 'prop-types';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';
import ReadingQuestionPanel from '../ReadingQuestionPanel/ReadingQuestionPanel';
import {bulkReplace, stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import {createAssetId} from '@reading/r180/src/media/mediaUtil';
import {isEmpty, shuffle} from 'lodash';

const EXT = getAudioExt();

const BUTTON_NAMES = {
	SUBMIT: 'Submit',
	GOON: 'Go On',
	TRYAGAIN: 'Try Again'
};
const BUTTON_ICONS = {
	CHECK: 'check',
	ARROW_RIGHT: 'arrow-right',
	RESET: 'reset'
};
const MAX_ATTEMPTS = 3;
export const MEDIA = {
	TITLE_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_selresp_q{dataId}_question.${EXT}`,
	OPTION_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_selresp_q{dataId}_{optionId}.${EXT}`
};

const MultiPart = props => {
	const {data, stage, segment, level, onGoOnClicked, onShowFeedback} = props;
	const [answers, setAnswers] = useState([]);
	const [options, setOptions] = useState([]);
	const [attempts, setAttempts] = useState(0);
	const [isCorrect, setCorrect] = useState(true);
	const [interactive, setInteractive] = useState(true);
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
	const [buttonName, setButtonName] = useState(BUTTON_NAMES.SUBMIT);
	const [buttonIcon, setButtonIcon] = useState(BUTTON_ICONS.CHECK);
	const [correctiveFeedback, setCorrectiveFeedback] = useState(false);
	const timeout = useRef(false);

	// create the options, map the object to our components, randomize the array
	useEffect(() => {
		if (isEmpty(data) === false) {
			const multiChoiceOptions = shuffle([
				...data.distractors,
				...data.answers
			]).map((option, index) => {
				return {
					id: option.id,
					text: option.text,
					sound: {
						name: `multi_choice_${index}`,
						src: bulkReplace(MEDIA.OPTION_SOUND, {
							asset_id: createAssetId(
								stage,
								segment,
								level,
								true
							),
							dataId: data.id,
							optionId: option.id
						})
					},
					status: ''
				};
			});
			setOptions(multiChoiceOptions);

			const correctAnswers = data.answers.map(answer => {
				return answer.id;
			});
			setAnswers(correctAnswers);
		} else {
			setOptions([]);
		}
	}, [data]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		clearTimeout(timeout.current);
	});

	const handleAnswerChange = selectedOptions => {
		const selectedAnswerIds = selectedOptions.map(option => option.id);
		const newOptions = options.map(option => {
			if (selectedAnswerIds.includes(option.id)) {
				return {...option, status: 'selected'};
			} else {
				return {...option, status: ''};
			}
		});
		setOptions(newOptions);
		setIsSubmitEnabled(answers.length === selectedAnswerIds.length);
	};
	const handleSelect = () => {
		if (!isSubmitEnabled) {
			return;
		}
		AudioPlayer2.stopAll();
		switch (buttonName) {
			case BUTTON_NAMES.SUBMIT: {
				const newOptions = options.map(option => {
					if (option.status === 'selected') {
						if (answers.includes(option.id)) {
							return {...option, status: 'correct'};
						} else {
							return {...option, status: 'incorrect'};
						}
					} else {
						return {...option, status: ''};
					}
				});
				const correctOptionsLength = newOptions.filter(
					option => option.status === 'correct'
				).length;

				// All answers are correct
				if (correctOptionsLength === answers.length) {
					AudioPlayer2.play(`compcorrattempt${attempts + 1}`);
					onShowFeedback(false, []);
					setButtonName(BUTTON_NAMES.GOON);
					setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
					setAttempts(attempts => attempts + 1);
					setCorrect(true);
					setInteractive(false);
					setOptions(newOptions);
				} else {
					AudioPlayer2.play(`compincorrattempt${attempts + 1}`);
					if (attempts === MAX_ATTEMPTS - 1) {
						timeout.current = setTimeout(() => {
							onShowFeedback(false, []);
						}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 2000);
						setOptions(newOptions);
						setInteractive(false);
						setButtonName(BUTTON_NAMES.GOON);
						setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
						setCorrectiveFeedback(true);
					} else {
						timeout.current = setTimeout(() => {
							onShowFeedback(true, data.corrective_feedback);
							setInteractive(true);
							setButtonName(BUTTON_NAMES.SUBMIT);
							setButtonIcon(BUTTON_ICONS.CHECK);
							setIsSubmitEnabled(true);
							setOptions(options);
						}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 1000);
						setInteractive(false);
						setButtonName(BUTTON_NAMES.SUBMIT);
						setButtonIcon(BUTTON_ICONS.CHECK);
						setIsSubmitEnabled(false);
						setOptions(newOptions);
					}
					setCorrect(false);
					setAttempts(attempts => attempts + 1);
				}
				break;
			}
			default: {
				onShowFeedback(false, []);
				onGoOnClicked(attempts, isCorrect);
				break;
			}
		}
	};

	return (
		<ReadingQuestionPanel
			title={stripHtmlTags(data.stem)}
			titleSound={{
				name: `Title_${data.id}`,
				src: bulkReplace(MEDIA.TITLE_SOUND, {
					asset_id: createAssetId(stage, segment, level, true),
					dataId: data.id
				})
			}}
			questionType={data.type}
			questions={options}
			onAnswerChange={handleAnswerChange}
			onSubmit={handleSelect}
			isSubmitEnabled={isSubmitEnabled}
			buttonText={buttonName}
			buttonIcon={buttonIcon}
			showResetButton={false}
			interactive={interactive}
			correctAnswers={answers}
			correctiveFeedback={correctiveFeedback}
		/>
	);
};

MultiPart.propTypes = {
	data: PropTypes.object.isRequired,
	stage: PropTypes.string.isRequired,
	segment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onGoOnClicked: PropTypes.func.isRequired,
	onShowFeedback: PropTypes.func.isRequired
};

export default React.memo(MultiPart);
