/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import HMHRedux from '../redux-util';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {transitionToZoneMenu} from '../../utils/navigation';
import {IS_PRODUCTION_ENV} from '../../utils/constants';
import {isEmpty} from 'lodash';

// slice constants
const sliceName = 'ui';

export const zoneMenuAction = createAsyncThunk(`${sliceName}/zonemenu`, async (params, thunkAPI) => {
	try {
		const {history, studentActivityId} = params;
		await transitionToZoneMenu(history, studentActivityId);
		return;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

// slice reducers
export const uiSlice = HMHRedux.createSlice({
	name: sliceName,
	logoutAction: 'session/logout',
	initialState: {
		// an activity is 'charged' when all the assets (audio, images, videos) have been downloaded
		// into browser memory and do not need to be re-downloaded
		// you can use this to determine whether the big deluxe loader should display
		isCharging: false,
		chargedActivities: {},
		// an activity is transitioning when it calls completeActivity and before the first render of the next
		// activity happens.  It's during this roughly 0.5s that we need to show a blank screen so that
		// the first activity doesn't render the data from the second activity (most common) and the
		// second activity doesn't render with the data from the first activity (less common)
		isTransitioning: false,
		errorMessage: '',
		captionSrc: null,
		spellDataLoaded: false,
		isZoneMenuModalOpen: false,
		isExitModalOpen: false,
		autoPlayedSounds: []
	},
	reducers: {
		setErrorMessage: (state, action) => {
			if (IS_PRODUCTION_ENV === false && isEmpty(action.payload) === false) {
				console.log(action.payload);
			}
			state.errorMessage = action.payload;
		},
		setCharging: (state, action) => {
			state.isCharging = true;
		},
		setActivityCharged: (state, action) => {
			state.isCharging = false;
			state.chargedActivities[action.payload] = true;
		},
		setTransitioning: (state, action) => {
			state.errorMessage = '';
			state.isTransitioning = action.payload;
		},
		setZoneMenuModalOpen: (state, action) => {
			state.isZoneMenuModalOpen = action.payload;
		},
		setExitModalOpen: (state, action) => {
			state.isExitModalOpen = action.payload;
		},
		setCaptionSrc: (state, action) => {
			state.captionSrc = action.payload;
		},
		setPlainBackGround: state => {
			state.isCharging = false;
		},
		removePlainBackGround: state => {
			state.isCharging = true;
		},
		spellClinicData: state => {
			state.spellDataLoaded = true;
		},
		cancelRecording: state => {
			state.cancelRecording = true;
		},
		clearCancelRecordingFlag: state => {
			state.cancelRecording = false;
		},
		addAutoPlayedSound: (state, action) => {
			state.autoPlayedSounds.push(action.payload);
		}
	},
	extraReducers: {
		[zoneMenuAction.fulfilled]: (state, action) => {
			state.errorMessage = '';
		}
	}
});

export default uiSlice.reducer;
