import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import MiniWordNSpeakerRow from './MiniWordNSpeakerRow';
import WordNSpeakerRow from './WordNSpeakerRow';
import ProgressCircle from './ProgressCircle';
import WordCardParts from './Parts/WordCardParts';
import WordCardTips from './Tips/WordCardTips';
import WordCardSpell from './Spell/WordCardSpell';
import WordCardSynonym from './Synonym/WordCardSynonym';
import WordCardToolbar from './WordCardToolbar';
import {WordCardProvider} from './WordCardContext';
import {useStyle} from './WordCard.style';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import WordCardFamilies from './Families/WordCardFamilies';
import WordCardExamples from './Examples/WordCardExamples';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {AnchorVideo, HoverTip} from '@reading/common';
import useRouteInfo from '@reading/r180/src/utils/useRouteInfo';
import WordCardInContext from './Context/WordCardInContext';
import {isEmpty} from 'lodash';
import Close from '../Close';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';

const WordCard = props => {
	const {
		className,
		wordCardData,
		powerWordData,
		card,
		kill,
		taskData,
		isSpellPreselected,
		isTipsPreselected,
		isFirstStudyWord,
		hideVideoTool,
		progress,
		maxProgress,
		activityData,
		isFinishedWithIntro,
		handleClose,
		showExitButton,
		onTipsComplete,
		onSpellComplete,
		onPartsComplete,
		videoSrcURL
	} = props;
	const classes = useStyle();

	const [status, setStatus] = useState('info');
	const [isAnimating, setAnimating] = useState(false);
	const {zone} = useRouteInfo();

	const partOfSpeech = card.word_card.part_of_speech
		? `(${card.word_card.part_of_speech})`
		: '';
	const wordText = card.word_card.word_text;

	let definition = '';
	let sentence = card.word_card.word_card_sentence || wordCardData.sentence;
	if (
		wordCardData &&
		wordCardData.contextualData &&
		Array.isArray(wordCardData.contextualData) &&
		wordCardData.contextualData.length > 0
	) {
		if (isEmpty(activityData)) {
			definition =
				card.word_card_definition ||
				card.word_card.word_card_definition;
		} else if (wordCardData.contextualData.length === 1) {
			definition = wordCardData.contextualData[0].contextualDefinition;
		}
		// we need to iterate over the array to find the right one
		else {
			const segmentId = `${
				activityData.stage
			}${activityData.segment.toString().padStart(2, '0')}`;
			const match = wordCardData.contextualData.filter(ctx => {
				return ctx.segmentId === segmentId;
			});
			if (match.length > 0) {
				definition = match[0].contextualDefinition;
				sentence = match[0].contextualSentence;
			}
		}
	}

	const defLabel = `${partOfSpeech} ${definition}`;
	const ctxLabel = sentence;

	const onWordButtonClick = async (item, lang) => {
		// play a sound effect when they click
		KnowledgeForReading.playClickSound();

		if (item === 'foreign') {
			const soundPath = bulkReplace(
				KnowledgeForReading.WORD_CARD_FOREIGN_SOUND,
				{
					lang: lang,
					word: wordText
				}
			);
			AudioPlayer2.loadSound(soundPath);
			AudioPlayer2.play(soundPath);
		} else {
			AudioPlayer2.stopAll();
			setStatus(item);
		}
	};

	const videoHint = useMemo(() => {
		return taskData.video_hint;
	}, [taskData]);

	// cleanup on unmount
	useEffect(() => {
		/* istanbul ignore next */
		return () => {
			setStatus('info');
		};
	}, []);

	useEffect(() => {
		if (kill) {
			setStatus('info');
		}
	}, [kill]);

	useEffect(() => {
		if (
			status === 'spell' ||
			status === 'tips' ||
			status === 'parts' ||
			status === 'consejo'
		) {
			setAnimating(true);
		} else {
			setAnimating(false);
		}
	}, [status]);

	const reset = () => {
		setStatus('info');
	};

	useEffect(() => {
		reset();
		if (isSpellPreselected) {
			setTimeout(() => {
				setStatus('spell');
			}, 1000);
			AudioPlayer2.play(wordText);
		}
		if (isTipsPreselected && isFinishedWithIntro) {
			setStatus('tips');
		}
	}, [wordCardData, isFinishedWithIntro, isTipsPreselected]);

	/* istanbul ignore next */
	const closeVideo = video => {
		setStatus('info');
		video.pause();
	};

	/* istanbul ignore next */
	const handleVideoEnd = (e, video) => {
		closeVideo(video);
	};

	/* istanbul ignore next */
	const handleCloseBtn = (e, video) => {
		closeVideo(video);
	};

	/* istanbul ignore next */

	/* istanbul ignore next */
	const renderDetailsArea = () => {
		switch (status) {
			case 'anchor-video': {
				return (
					<AnchorVideo
						id={'word-card-video'}
						autoPlay={true}
						pause={false}
						time={((videoHint && videoHint.start_time) || 0) / 1000}
						video_end_time={
							((videoHint && videoHint.end_time) || 0) / 1000
						}
						vol={0.7}
						hideFwdButton={true}
						hideRewindButton={true}
						hideCaptionButton={true}
						hideReplayButton={true}
						hideCloseButton={false}
						hideVideoTimeDuration={true}
						handleVideoEnded={handleVideoEnd}
						handleCloseButton={handleCloseBtn}
						containerStyle={{
							zIndex: 100,
							marginTop: '66px'
						}}
					>
						<source
							src={
								(videoHint && videoHint.src_url) || videoSrcURL
							}
						/>
					</AnchorVideo>
				);
			}
			case 'espanol': {
				return null;
			}
			case 'consejo': {
				return null;
			}
			case 'synonym': {
				return <WordCardSynonym wordCard={card.word_card} />;
			}
			case 'family': {
				return <WordCardFamilies wordCard={card.word_card} />;
			}
			case 'examples': {
				return <WordCardExamples wordCard={card.word_card} />;
			}
			case 'words-in-context': {
				return <WordCardInContext wordCard={card.word_card} />;
			}
			default:
				return (
					<>
						<hr className={`${classes.divider} top`} />
						<div
							className={`${classes.definitionAreaWrapper} mb-2`}
						>
							{defLabel &&
								wordCardData &&
								wordCardData.decodingInfo && (
									<HoverTip
										title="Click to hear a definition of the word"
										placement="left"
									>
										<div>
											<MiniWordNSpeakerRow
												prefix="def"
												wordText={wordText}
												label={defLabel}
												disabled={isAnimating}
											/>
										</div>
									</HoverTip>
								)}
						</div>
						<div className={classes.contextSentenceAreaWrapper}>
							{ctxLabel &&
								wordCardData &&
								wordCardData.decodingInfo && (
									<HoverTip
										title="Click to hear the word used in a sentence"
										placement="left"
									>
										<div>
											<MiniWordNSpeakerRow
												prefix="ctx"
												wordText={wordText}
												label={ctxLabel}
												className={'emphasis'}
												disabled={isAnimating}
											/>
										</div>
									</HoverTip>
								)}
						</div>
						<hr className={`${classes.divider} bottom`} />
					</>
				);
		}
	};

	/* istanbul ignore next */
	const renderMainWordArea = () => {
		if (status === 'parts') {
			return (
				<WordCardParts
					kill={kill}
					onComplete={() => {
						if (typeof onPartsComplete === 'function') {
							onPartsComplete();
						}
						setStatus('info');
					}}
					decodingInfo={wordCardData.decodingInfo}
				/>
			);
		} else if (status === 'spell') {
			return (
				<WordCardSpell
					onComplete={() => {
						if (typeof onSpellComplete === 'function') {
							onSpellComplete();
						}
						setStatus('info');
					}}
				/>
			);
		} else if (status === 'tips') {
			return (
				<WordCardTips
					decodingInfo={wordCardData.decodingInfo}
					onComplete={() => {
						if (typeof onTipsComplete === 'function') {
							onTipsComplete();
						}
						setStatus('info');
					}}
					isTipsHighlightWithSameColor={true}
				/>
			);
		} else {
			return (
				<WordNSpeakerRow
					label={wordText}
					isSpellPreselected={isSpellPreselected}
				/>
			);
		}
	};

	return (
		<WordCardProvider wordCardData={wordCardData}>
			<div className={`${classes.wrapper} ${className}`}>
				<div className={classes.headerWrapper} />

				<div className={classes.mainWordAreaWrapper}>
					{renderMainWordArea()}
					{showExitButton && (
						<div className={classes.closeButton}>
							<Close handleOnClose={() => handleClose()} />
						</div>
					)}
				</div>

				<div className={classes.detailsAreaWrapper}>
					{renderDetailsArea()}
				</div>

				<div
					className={`${classes.decodingAreaWrapper} ${
						isSpellPreselected || isTipsPreselected
							? classes.spellPreselected
							: ''
					}`}
				>
					<WordCardToolbar
						onWordButtonChange={onWordButtonClick}
						wordCard={card.word_card}
						isSpellPreselected={isSpellPreselected}
						isTipsPreselected={isTipsPreselected}
						hideVideoTool={hideVideoTool}
						status={status}
						completedActivities={
							powerWordData.completedActivityList
						}
					/>
				</div>

				<div className={`${classes.footerWrapper}`}>
					{zone === 'explore' || zone === 'language' ? (
						<div className={classes.masterProgressAreaWrapper}>
							<div className={classes.progressDetailsWrapper}>
								COMPLETED ACTIVITIES {progress} of {maxProgress}
							</div>
							<ProgressCircle
								progress={progress}
								max={maxProgress}
							/>
						</div>
					) : (
						<span>&nbsp;</span>
					)}
				</div>
			</div>
		</WordCardProvider>
	);
};

WordCard.defaultProps = {
	className: '',
	kill: false,
	isSpellPreselected: false,
	isTipsPreselected: false,
	isFirstStudyWord: false,
	hideVideoTool: false,
	handleClose: () => {},
	showExitButton: false,
	videoSrcURL: '',
	powerWordData: {}
};

WordCard.propTypes = {
	className: PropTypes.string,
	wordCardData: PropTypes.shape({
		text: PropTypes.string.isRequired,
		instanceNumber: PropTypes.number.isRequired,
		decodingInfo: PropTypes.shape({
			wordText: PropTypes.string.isRequired,
			segments: PropTypes.string.isRequired,
			markup: PropTypes.string.isRequired,
			parts: PropTypes.arrayOf(
				PropTypes.shape({
					index: PropTypes.number.isRequired,
					text: PropTypes.string.isRequired,
					mediaName: PropTypes.string.isRequired
				})
			)
		}),
		contextualData: PropTypes.arrayOf(
			PropTypes.shape({
				segmentId: PropTypes.string.isRequired,
				contextualSentence: PropTypes.string.isRequired,
				contextualDefinition: PropTypes.string.isRequired
			})
		)
	}).isRequired,
	kill: PropTypes.bool,
	isSpellPreselected: PropTypes.bool,
	isTipsPreselected: PropTypes.bool,
	isFirstStudyWord: PropTypes.bool,
	hideVideoTool: PropTypes.bool,
	handleClose: PropTypes.func,
	showExitButton: PropTypes.bool,
	videoSrcURL: PropTypes.string,
	powerWordData: PropTypes.object
};

export default React.memo(WordCard);
