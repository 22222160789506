import React, {useEffect} from 'react';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Navbar from '../../containers/App/Navbar';
import {MEDIA_ACTIVITY_ASSETS_URL_BASE, MEDIA_SERVER_URL} from '../../utils/constants';
import {updateActivityAction} from '../../store/slices/activity';
import {gotoNextActivity, transitionToNextActivity} from '../../utils/navigation';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import SegmentClusterRow from '@reading/common/src/activityComponents/SegmentSelection/SegmentClusterRow/SegmentClusterRow';
import SegmentPreviewModal from '@reading/common/src/activityComponents/SegmentSelection/SegmentPreviewModal/SegmentPreviewModal';
import SegmentConfirmModal from '@reading/common/src/activityComponents/SegmentSelection/SegmentConfirmModal/SegmentConfirmModal';
import RecommendedSegment from '@reading/common/src/activityComponents/SegmentSelection/RecommendedSegment/RecommendedSegment';
import {getAudioExt} from '../../utils/audio';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';
import useIdleHelp from '../../utils/useIdleHelp';
import media from '../../api/media';
import useBatchedSetState from '../../utils/useBatchedSetState';
import { uiSlice } from '../../store/slices/ui';

const EXT = getAudioExt();

export const useStyles = makeStyles(theme => ({
	wrapper: {
		color: theme.colors.white,
		paddingBottom: '64px',
		'& header': {
			background: theme.colors.exploreZone.background
		}
	}
}));

export const MEDIA = {
	segmentImage: `${MEDIA_ACTIVITY_ASSETS_URL_BASE}segselect/r180u_segselect_feature_{stage}{segment}.jpg`,
	segmentTitle: `${MEDIA_ACTIVITY_ASSETS_URL_BASE}segselect/r180u_segselect_segmenttitle_{stage}{segment}.${EXT}`,
	helpSound: `${MEDIA_ACTIVITY_ASSETS_URL_BASE}segselect/r180u_segselect_help.${EXT}`,
	instructionAudio: `${MEDIA_ACTIVITY_ASSETS_URL_BASE}segselect/r180u_segselect_intro.${EXT}`,
	segmentThumb: `${MEDIA_ACTIVITY_ASSETS_URL_BASE}segselect/r180u_segselect_thumb_{stage}{segment}.jpg`,
	segmentFeature: `${MEDIA_ACTIVITY_ASSETS_URL_BASE}segselect/r180u_segselect_feature_{stage}{segment}.jpg`,
	segmentVideo: `/assets/video/r180u_video_{stage}{segment}_preview.mp4`,
	clusterTitle: `${MEDIA_ACTIVITY_ASSETS_URL_BASE}segselect/r180u_segselect_clustertitle_{cluster}.${EXT}`,
	clusterSummary: `${MEDIA_ACTIVITY_ASSETS_URL_BASE}segselect/r180u_segselect_clustersummary_{cluster}.${EXT}`
};

const SegmentSelection = props => {
	const [selectedSegment, setSelectedSegment] = React.useState({segment_number: 1, clusters: []});
	const [recommendedSegment, setRecommendedSegment] = React.useState({segment_number: 1, clusters: []});
	const [showPreview, setShowPreview] = React.useState(false);
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [contentData, setContentData] = React.useState(false);

	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();
	const {setState} = useBatchedSetState();

	const {segmentSelection, activityData} = useSelector(state => {
		return {segmentSelection: state.activity.segmentselection, activityData: state.activity.activityData};
	});

	useEffect(() => {
		const getMediaData = async () => {
			const {stage} = activityData;
			try {
				const result = await media.getOne(`/content/segments/r180u_segments_${stage.toLowerCase()}.json`);
				setContentData(result);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		};
		if (activityData && activityData.stage && activityData.activityServerId === 'segment_selection') {
			getMediaData();
		}
	}, [activityData]);

	let completedSegments = [];
	if (segmentSelection) {
		completedSegments = segmentSelection.completedSegments;
	}

	useDeepCompareEffect(() => {
		if (contentData && Array.isArray(contentData.segments)) {
			const incompleteSegments = contentData.segments.filter(segment => {
				if (
					completedSegments.find(completedSegment => {
						return completedSegment.segmentName === segment.id;
					})
				) {
					return false;
				}
				return true;
			});
			setRecommendedSegment(incompleteSegments[Math.floor(Math.random() * incompleteSegments.length)]);
		}
	}, [completedSegments, contentData]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	let clusters = [];
	if (contentData && contentData.segments) {
		clusters = contentData.segments.reduce((acc, segment) => {
			segment.clusters.forEach(cluster => {
				if (!acc.find(ac => ac.id === cluster.id)) {
					acc.push(cluster);
				}
			});
			return acc;
		}, []);
	}

	const previewSegment = segment => {
		setState(() => {
			setSelectedSegment(segment);
			setShowPreview(true);
		});
	};

	const preSubmitSegment = segment => {
		setState(() => {
			setSelectedSegment(segment);
			setShowPreview(false);
			setShowConfirm(true);
		});
	};

	const handlePreviewSubmit = (e) => {
		e.stopPropagation();
		setState(() => {
			setShowConfirm(true);
			setShowPreview(false);
		});
	};

	const handlePreviewClose = (e) => {
		e.stopPropagation();
		setShowPreview(false);
	};

	const handleConfirmSubmit = (e) => {
		e.stopPropagation();
		submitSegment(selectedSegment);
	};

	const handleConfirmClose = (e) => {
		e.stopPropagation();
		setShowConfirm(false);
	};

	const submitSegment = async segment => {
		const postData = {
			segmentselection: {
				currentSegment: segment.id,
				challengeAccepted: false,
				userId: segmentSelection.userId
			},
			activity: {
				studentActivityId: activityData.studentActivityId,
				isComplete: true
			}
		};
		try {
			transitionToNextActivity(history, postData);
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
	};

	useIdleHelp(`${MEDIA_SERVER_URL}${bulkReplace(MEDIA.helpSound, {})}`);

	return (
		<>
			<div className={`${classes.wrapper} explore-container`}>
				<Navbar helpSoundUrl={`${MEDIA_SERVER_URL}${bulkReplace(MEDIA.helpSound, {})}`} short={true} />
				<ActivitySuspense requiredRenderData={[activityData, segmentSelection, contentData]}>

					{showPreview &&
						<SegmentPreviewModal
							open={showPreview}
							onClose={handlePreviewClose}
							stage={activityData.stage}
							selectedSegment={selectedSegment}
							onSubmit={handlePreviewSubmit}
						/>
					}

					{showConfirm &&
						<SegmentConfirmModal
							open={showConfirm}
							handleClose={handleConfirmClose}
							handleSubmit={handleConfirmSubmit}
							selectedSegment={selectedSegment}
							stage={activityData.stage}
						/>
					}

					<RecommendedSegment
						recommendedSegment={recommendedSegment}
						activityData={activityData}
						previewSegment={previewSegment}
						preSubmitSegment={preSubmitSegment}
					/>

					{clusters.map(cluster => (
						<SegmentClusterRow
							key={`cluster_row_${cluster.id}`}
							previewSegment={previewSegment}
							completedSegments={completedSegments}
							stage={activityData.stage}
							segments={contentData.segments}
							cluster={cluster}
						/>
					))}

				</ActivitySuspense>
			</div>
		</>
	);
};

export default SegmentSelection;
