import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(theme => ({
	wrapper: {
		width: '80%',
		height: 'auto',
		minHeight: '220px',
		margin: 'auto',
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '8px',
		background: theme.colors.white,
		'& > .header': {
			height: '60px',
			background: theme.colors.lightGrey,
			padding: '18px 32px',
			borderBottom: `1px solid ${theme.colors.paleGrey}`,
			color: theme.colors.softBlack,
			letterSpacing: '0.02em',
			fontSize: '18px',
			borderTopRightRadius: '8px',
			borderTopLeftRadius: '8px',
			fontFamily: theme.fonts.buttonBold,
			'& i': {
				position: 'relative',
				top: '1px'
			}
		},
		'& .content > div': {
			display: 'block',
			float: 'left',
			height: '100%',
			minHeight: '160px',
			width: '50%',
			textAlign: 'center',
			color: theme.colors.grey,
			fontFamily: theme.fonts.context,
			'& h4': {
				textTransform: 'uppercase',
				fontFamily: theme.fonts.button,
				marginTop: '12px',
				marginBottom: '12px',
				letterSpacing: '0.04em',
				fontSize: '18px',
				color: theme.colors.slate,
			},
			'& .word-entry': {
				marginBottom: '12px'
			}
		},
		'& .left': {
			borderRight: `1px solid ${theme.colors.paleGrey}`,
		},
		'& .right': {
		}
	}
}));

const WordCardExamples = (props) => {
	const { wordCard } = props;
	const classes = useStyle();

	const exampleActivity = wordCard.word_card_activities.filter((act) => {
		return act.word_card_activity.activity_server_id === 'example_non_example';
	})[0];

	const examples = exampleActivity.word_card_activity.examples;
	const nonExamples = exampleActivity.word_card_activity.non_examples;

	return (
		<div className={`${classes.wrapper} animate__animated animate__bounceIn`}>
			<div className='header'>
				<i className='r180 examples-non-examples mr-1' />
				Examples &amp; Non-Examples
			</div>
			<div className='content'>
				<div className='left'>
					<h4>Examples</h4>
					{
						examples.map((s) => {
							return <div key={s.id} className='word-entry'>{s.text}</div>
						})
					}
				</div>
				<div className='right'>
					<h4>Non-Examples</h4>
					{
						nonExamples.map((s) => {
							return <div key={s.id} className='word-entry'>{s.text}</div>
						})
					}
				</div>
			</div>
		</div>
	)
}


WordCardExamples.defaultProps = {
};

WordCardExamples.propTypes = {
};

export default React.memo(WordCardExamples);
