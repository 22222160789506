import {makeStyles} from '@material-ui/styles';
import {isFirefox} from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100%',
		background: theme.colors.white,
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		padding: '0px 0px 0px 16px',
		overflowY: 'hidden',
		overflowX: 'hidden'
	},
	scrollContainer: {
		overflowY: isFirefox() ? 'auto' : 'overlay',
		scrollbarWidth: 'thin',

		'&::-webkit-scrollbar': {
			width: '6px'
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			padding: '10px 0'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#CCC',
			borderRadius: '8px'
		},
		display: 'flex',
		flexGrow: 1,
		flexShrink: 1,
		minWidth: '432px',
		height: '60vh',
		width: '592px'
	},
	compactScrollContainer: {
		width: '492px !important'
	},
	textTitle: {
		fontSize: '48px',
		fontWeight: 700,
		marginBottom: '42px',
		marginTop: '20px',
		left: '-16px',
		position: 'relative',
		fontFamily: theme.fonts.ui
	},
	textAuthor: {
		fontSize: '16px',
		fontWeight: 700,
		marginBottom: '16px'
	},
	textBody: {
		fontFamily: theme.fonts.context,
		marginLeft: '16px',
		flexGrow: 1,
		marginTop: '24px',
		fontSize: '18px',
		lineHeight: '40px',
		textUnderlineOffset: '6px'
	},
	word: {display: 'inline'},
	wordHighlighted: {
		background: theme.colors.highlightYellow,
		textDecoration: 'underline'
	},
	wordBold: {fontWeight: 'bold'},
	wordItalic: {fontStyle: 'italic'},
	wordUnderline: {textDecoration: 'underline'},
	feedbackWord: {
		textDecoration: 'underline',
		textDecorationColor: theme.colors.errorRed
	},
	passageQuestionHighlight: {
		textDecoration: 'underline',
		textDecorationStyle: 'dashed',
		textDecorationColor: theme.colors.grey,
		cursor: 'pointer',
		textDecorationThickness: 'from-font'
	},
	passageQuestionHighlightBackground: {
		background: theme.colors.readingZone.blue5,
		textDecorationColor: theme.colors.readingZone.primary
	},
	highlightFluencyCheckOptions: {
		textDecoration: 'underline',
		background: theme.colors.readingZone.blue5,
		textDecorationColor: theme.colors.readingZone.primary
	},
	space: {display: 'inline'},
	punc: {display: 'inline'},
	sentence: {display: 'inline'},
	phrase: {display: 'inline'},
	paragraph: {
		marginBottom: '1em',
		paddingRight: '2em'
	},
	header: {marginBottom: '2em'},
	paragraphNumber: {
		fontSize: '14px',
		marginRight: '32px',
		display: 'inline',
		marginLeft: '-16px',
		color: theme.colors.slate,
		fontFamily: theme.fonts.uiBold
	},
	paragraphIndent: {display: 'inline', width: '48px'},
	correctiveFeedbackEnabled: {
		display: 'inline',
		textDecoration: 'underline',
		background: theme.colors.readingZone.blue5,
		textDecorationColor: theme.colors.readingZone.primary,
		textDecorationThickness: '2px'
	}
}));
