import {makeStyles} from "@material-ui/core/styles";

export default makeStyles(theme=>({
	wrapper: {
		background:theme.colors.white,
		color:theme.colors.black,
		fontSize:'32px',
		lineHeight:'38px',
		border:`1px solid ${theme.colors.cyan}`,
		height:'48px',
		minWidth:'48px',
		textAlign:'center',
		padding:'8px',
		float:'left',
		borderRadius:'8px',
		margin:'1px',
		marginTop:'-1px',
		boxSizing:'border-box',
	},
	disabled:{
		background:theme.colors.paleGrey,
		border:`solid 1px ${theme.colors.paleGrey}`,
		color:theme.colors.grey,
	}
}))
