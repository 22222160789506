import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { stripAllExtraWhiteSpace } from '@reading/r180/src/utils/stringUtils';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';



export const useStyles = makeStyles(theme => ({
	wrapper: {
		width: '100%',
		height: 'auto'
	},
	textArea: {
		border: 'none',
		padding: '4px 0',
		fontSize: '18px',
		fontFamily: theme.fonts.context,
		color: theme.colors.grey,
		width: '100%',
		lineHeight: props => `${props.lineHeight}px`,
		backgroundImage:
			'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyxpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2Mzg1OCwgMjAxOS8wMy8wNi0wMzoxODozNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIEVsZW1lbnRzIDE4LjAgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjhBMjk2QzJCQTJEMTExRUJCQ0MxQzA0ODVBRDlFODZGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjhBMjk2QzJDQTJEMTExRUJCQ0MxQzA0ODVBRDlFODZGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OEEyOTZDMjlBMkQxMTFFQkJDQzFDMDQ4NUFEOUU4NkYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OEEyOTZDMkFBMkQxMTFFQkJDQzFDMDQ4NUFEOUU4NkYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6dhwjYAAAAIklEQVR42mL8//8/AzpgYsACRgVHBWkqyHLpyhWMtAgQYABpYwa4k9JviQAAAABJRU5ErkJggg==)',
		backgroundRepeat: 'repeat',
		backgroundSize: props => `5px ${props.lineHeight}px`,
		resize: 'none',
		overflowY: 'hidden',
		'&.hiderules': {
			backgroundImage: 'none'
		}
	}
}));

const MagicTextArea = props => {
	const {
		id,
		className,
		startText,
		onTextChange,
		textAreaHeight,
		textAreaWidth,
		showLines,
		isResetTriggered,
		resetClicked,
		updateGoOn,
		originalText,
		disabled,
		lineHeight,
		onMinimumCountMet,
		onMinimumCountUnmet
	} = props;

	const MINIMUM_CHARACTER_COUNT = 8;

	const classes = useStyles({ lineHeight });
	const textarea = useRef();
	const { setState } = useBatchedSetState();

	const convertTagsToText = text => {
		const multiRegex = new RegExp('<multi/>', 'g');
		let convertedText = text.replace(
			multiRegex,
			'                                  '
		);
		const wordRegex = new RegExp('<word/>', 'g');
		convertedText = convertedText.replace(wordRegex, '                 ');
		const fillRegex = new RegExp('<fill/>', 'g');
		convertedText = convertedText.replace(fillRegex, '    ');
		return convertedText;
	};

	const [initialText, setInitialText] = useState('');
	const [initialCharCount, setInitialCharCount] = useState(0);
	const [text, setText] = useState(convertTagsToText(startText));
	const [currentCaretPos, setCurrentCaretPos] = useState(0);
	const [rowCount, setRowCount] = useState(2);

	const handleChange = e => {
		let caretPos = textarea.current.selectionStart;
		let newText = e.target.value;
		const panelCharactersCount = stripAllExtraWhiteSpace(
			textarea.current.value
		).replace(/\s/g, '').length;

		// if they reach the defined minimum character count, let the parent know
		if (panelCharactersCount > initialCharCount + MINIMUM_CHARACTER_COUNT - 1) {
			if (typeof updateGoOn === 'function') {
				updateGoOn(id);
			}
			if (typeof onMinimumCountMet === 'function') {
				onMinimumCountMet(id);
			}
		}
		else
		{
			if (typeof onMinimumCountMet === 'function') {
				onMinimumCountUnmet(id);
			}
		}

		// if they clicked in the middle of a big space, delete the space before the letter they typed
		if (caretPos > 3 && newText.charAt(caretPos - 3) === ' ' && newText.charAt(caretPos - 2) === ' ') {
			const stripped = newText.substring(0, caretPos - 3).trim() + ' ';
			newText = stripped + newText.substring(caretPos - 1);
			caretPos = stripped.length + 1;
		}

		// as they type, remove the white spaces after the caret, shrinking the empty space
		if (newText.charAt(caretPos) === ' ' && newText.charAt(caretPos + 1) === ' ') {
			newText = newText.substring(0, caretPos) + newText.substring(caretPos + 1);
		}

		const newRowCount = Math.floor(textarea.current.scrollHeight / lineHeight);

		setState(() => {
			setText(newText);
			setCurrentCaretPos(caretPos);
			setRowCount(newRowCount);
		});

		if (typeof onTextChange === 'function') {
			onTextChange(stripAllExtraWhiteSpace(newText), newRowCount);
		}
	};

	useEffect(() => {
		setInitialText(startText);
		setState(() => {
			if (originalText.length) {
				setInitialCharCount(convertTagsToText(originalText).replace(/\s/g, '').length);
			} else {
				setInitialCharCount(
					stripAllExtraWhiteSpace(textarea.current.value).replace(
						/\s/g,
						''
					).length
				);
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// this is necessary to keep the caret where they're typing, since we manipulate the text
	// in the handleChange event hanlder
	useEffect(() => {
		textarea.current.selectionStart = currentCaretPos;
		textarea.current.selectionEnd = currentCaretPos;
	}, [currentCaretPos]);

	useEffect(() => {
		if (isResetTriggered) {
			setText(convertTagsToText(initialText));
			setInitialCharCount(convertTagsToText(initialText).replace(/\s/g, '').length);
			resetClicked(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isResetTriggered]);

	return (
		<div id={id} className={`${classes.wrapper} ${className}`}>
			<textarea
				ref={textarea}
				className={`${classes.textArea} ${!showLines ? 'hiderules' : ''
					}`}
				onChange={handleChange}
				disabled={disabled}
				value={text}
				style={{
					height: textAreaHeight.length
						? textAreaHeight
						: `${rowCount * lineHeight}px`,
					width: textAreaWidth.length ? textAreaWidth : '100%'
				}}
			/>
		</div>
	);
};

MagicTextArea.defaultProps = {
	id: '',
	className: '',
	startText: '',
	textAreaHeight: '',
	textAreaWidth: '',
	showLines: true,
	originalText: '',
	disabled: false,
	lineHeight: 42
};
MagicTextArea.propTypes = {
	id: PropTypes.string,
	textAreaHeight: PropTypes.string,
	textAreaWidth: PropTypes.string,
	className: PropTypes.string,
	startText: PropTypes.string,
	onTextChange: PropTypes.func.isRequired,
	showLines: PropTypes.bool,
	originalText: PropTypes.string,
	updateGoOn: PropTypes.func,
	disabled: PropTypes.bool,
	lineHeight: PropTypes.number,
	onMinimumCountMet: PropTypes.func,
	onMinimumCountUnmet: PropTypes.func
};

export default React.memo(MagicTextArea);
