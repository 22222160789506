import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MEDIA_SERVER_URL } from '@reading/r180/src/utils/constants';
import { getAudioExt } from '@reading/r180/src/utils/audio';
import {
	ActivityButton,
	CaptionedAudioPlayer2 as AudioPlayer2
} from '@reading/common';
import { sortByMode } from './WritingUtil';
import { useStyles } from './ReviewLayout.style';
import useAppMeasurements from '@reading/r180/src/utils/useAppMeasurements';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import { capitalizeWord } from '@reading/r180/src/utils/stringUtils';

const EXT = getAudioExt();

const SOUNDS = {
	TITLE_INTRO: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_publish_title_lintro.${EXT}`,
	TITLE_HELP: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_publish_title_help.${EXT}`,

	IMAGE_INTRO: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_publish_image_lintro.${EXT}`,
	IMAGE_HELP: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_publish_image_help.${EXT}`,

	LAYOUT_INTRO: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_publish_layout_lintro.${EXT}`,
	LAYOUT_HELP: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_publish_layout_help.${EXT}`
};

const ReviewLayout = props => {
	const {
		className,
		id,
		completedPanelData,
		mode,
		author,
		reviewText,
		images,
		onReviewLayoutComplete,
		onPublish,
		autoPlay
	} = props;

	const { headerHeight } = useAppMeasurements();
	const classes = useStyles({ headerHeight });
	const { setState } = useBatchedSetState();

	const [layout, setLayout] = useState(0);
	const [headerText, setHeaderText] = useState('');
	const [headerImage, setHeaderImage] = useState('');
	const [isPublishEnabled, setPublishEnabled] = useState(false);
	const [charCount, setCharCount] = useState(0);
	const [currentPanel, setCurrentPanel] = useState(1);

	const displayText = reviewText
		? reviewText
		: sortByMode(completedPanelData, mode);

	useEffect(() => {
		Object.keys(SOUNDS).forEach(key => {
			AudioPlayer2.loadSound(SOUNDS[key]);
		});

		playAudio(SOUNDS.LAYOUT_INTRO);
	}, []);

	const playAudio = async type => {
		AudioPlayer2.stopAll();
		if (autoPlay) {
			AudioPlayer2.play(SOUNDS[type]);
		}
	};

	const handleLayout = layoutID => {
		setState(() => {
			setLayout(layoutID);
			if (currentPanel === 1) {
				setCurrentPanel(2);
				AudioPlayer2.stopAll();
				if (autoPlay) {
					AudioPlayer2.play(SOUNDS.IMAGE_INTRO);
				}
			}
		});
		const content = document.getElementsByClassName('content-wrapper')[0];
		if (content) {
			content.scrollTo(0, 0);
		}
	};

	const handleHeaderImageChange = img => {
		setState(() => {
			setHeaderImage(img);
			setPublishEnabled(img !== '' && headerText !== '');
			if (currentPanel === 2) {
				setCurrentPanel(3);
				AudioPlayer2.stopAll();
				if (autoPlay) {
					AudioPlayer2.play(SOUNDS.TITLE_INTRO);
				}
			}
		});
		if (headerText !== '') {
			if (typeof onReviewLayoutComplete === 'function') {
				onReviewLayoutComplete(
					reviewText,
					layout,
					headerText,
					headerImage
				);
			}
		}
	};

	const handleHeaderTextChange = text => {
		setState(() => {
			setHeaderText(text);
			setCharCount(text.length);
			setPublishEnabled(headerImage !== '' && text !== '');
		});
		if (headerImage !== '') {
			if (typeof onReviewLayoutComplete === 'function') {
				onReviewLayoutComplete(
					reviewText,
					layout,
					headerText,
					headerImage
				);
			}
		}
	};

	return (
		<div
			className={`${classes.wrapper} ${className} review-layout-container animate__animated animate__fadeIn`}
			id={id}
		>
			<div className="review-layout">
				<div className="preview-panel">
					<div className={`layout layout-${layout}`}>
						<div
							className={`image-wrapper`}
							style={{
								backgroundImage: `url(${headerImage})`
							}}
						></div>
						<div className="content-title">
							<h3 className="article-title">{headerText}</h3>
							<h5 className="article-author">{author}</h5>
						</div>
						<div className="content-wrapper">
							<div
								className="content"
								dangerouslySetInnerHTML={{ __html: displayText }}
							></div>
						</div>
					</div>
				</div>
				<div className="control-panel">
					<div className={`control-card`}>
						<div className="control-card-header">
							<button data-testid="layout-audio" onClick={() => playAudio('LAYOUT_INTRO')}>
								<i className="r180 speaker-center" />
							</button>

							<h4>Layout</h4>
							<button
								data-testid="layout-audioHelp"
								onClick={() => playAudio('LAYOUT_HELP')}
							>
								<i className="r180 instructions" />
							</button>
						</div>
						<div className="control-card-content">
							<div className="item-list">
								{layouts.map((item, i) => (
									<button
										data-testid="layout-template"
										onClick={() => handleLayout(i + 1)}
										key={item + i}
									>
										<img
											className={`layout-thumb ${layout ===
												i + 1 && 'selected'}`}
											src={item}
											alt="Layout"
										/>
									</button>
								))}
							</div>
						</div>
					</div>

					<div
						className={`control-card ${currentPanel < 2 ? 'inactive' : ''
							}`}
					>
						<div className="control-card-header">
							<button
								data-testid="image-audio"
								dataonClick={() => playAudio('IMAGE_INTRO')}
							>
								<i className="r180 speaker-center" />
							</button>
							<h4>Image</h4>
							<button
								data-testid="image-audioHelp"
								onClick={() => playAudio('IMAGE_HELP')}
							>
								<i className="r180 instructions" />
							</button>
						</div>
						<div className="control-card-content">
							<div className="item-list">
								{images.map(img => (
									<button
										data-testid="image-template"
										onClick={() =>
											handleHeaderImageChange(img)
										}
										key={img}
										disabled={currentPanel < 2}
									>
										<img
											className={`thumb ${headerImage ===
												img && 'selected'}`}
											src={img}
											alt="Layout"
										/>
									</button>
								))}
							</div>
						</div>
					</div>

					<div
						className={`control-card ${currentPanel < 3 ? 'inactive' : ''
							}`}
					>
						<div className="control-card-header">
							<button
								data-testid="mode-audioHelp"
								onClick={() => playAudio('TITLE_INTRO')}
							>
								<i className="r180 speaker-center" />
							</button>
							<h4>{capitalizeWord(mode)} Title</h4>
							<button
								data-testid="mode-audio"
								onClick={() => playAudio('TITLE_HELP')}
							>
								<i className="r180 instructions" />
							</button>
						</div>
						<div className="control-card-content">
							<input
								className={`textfield ${charCount > 30 ? 'small' : ''
									}`}
								type="text"
								data-testid="mode-title"
								value={headerText}
								maxLength={70}
								onChange={e =>
									handleHeaderTextChange(e.target.value)
								}
								disabled={currentPanel < 3}
								spellCheck={false}
								autoCorrect={'off'}
							/>
						</div>
					</div>

					<div className="control-card">
						<ActivityButton
							id="publish-button"
							icon="arrow-right"
							onClick={onPublish}
							text="Publish"
							disabled={isPublishEnabled === false}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

ReviewLayout.defaultProps = {
	id: '',
	className: '',
	completedPanelData: {},
	mode: '',
	author: '',
	reviewText: '',
	images: [],
	autoPlay: false
};
ReviewLayout.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	completedPanelData: PropTypes.object,
	mode: PropTypes.string,
	author: PropTypes.string,
	reviewText: PropTypes.string,
	images: PropTypes.array,
	onReviewLayoutComplete: PropTypes.func,
	onPublish: PropTypes.func,
	autoPlay: PropTypes.bool
};

export default React.memo(ReviewLayout);

export const layouts = [
	'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5NS41IiBoZWlnaHQ9IjExOS44OCIgdmlld0JveD0iMCAwIDk1LjUgMTE5Ljg4Ij48dGl0bGU+bGF5b3V0LTE8L3RpdGxlPjxwYXRoIGZpbGw9IiNmM2YzZjMiIGQ9Ik0wIDBoOTUuNXYxMTkuODhIMHoiLz48cGF0aCBmaWxsPSIjZDFkM2QzIiBkPSJNNSA1aDg2LjV2NDEuMzNINXoiLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiM1NTU2NWEiIHN0cm9rZS13aWR0aD0iNCIgZD0iTTMyIDYwLjMzaDMyIi8+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNTU1NjVhIiBkPSJNMzIgNzAuODNoMzJNMzIgNzQuODNoMzJNMzIgNzguODNoMzJNMzIgODIuODNoMzJNMzIgODYuODNoMzJNMzIgOTAuODNoMzJNMzIgOTQuODNoMzJNMzIgOTguODNoMzJNMzIgMTAyLjgzaDMyTTMyIDEwNi44M2gzMiIvPjwvc3ZnPg==',
	'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5NS41IiBoZWlnaHQ9IjExOS4zMSIgdmlld0JveD0iMCAwIDk1LjUgMTE5LjMxIj48dGl0bGU+bGF5b3V0LTI8L3RpdGxlPjxwYXRoIGZpbGw9IiNmM2YzZjMiIGQ9Ik0wIDBoOTUuNXYxMTkuMzFIMHoiLz48cGF0aCBmaWxsPSIjZDFkM2QzIiBkPSJNNC42NyA1aDg2LjV2NDEuMzNINC42N3oiLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiM1NTU2NWEiIHN0cm9rZS13aWR0aD0iNCIgZD0iTTI2LjU4IDI3LjMzaDQzIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTI2LjQyIDM2LjIxaDQydjgwaC00MnoiLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiM1NTU2NWEiIGQ9Ik0zMS41OCA0MC44M2gzMk0zMS41OCA0NC44M2gzMk0zMS41OCA0OC44M2gzMk0zMS41OCA1Mi44M2gzMk0zMS41OCA1Ny44M2gzMk0zMS41OCA2MS44M2gzMk0zMS41OCA2NS44M2gzMk0zMS41OCA2OS44M2gzMk0zMS41OCA3My44M2gzMk0zMS41OCA3Ny44M2gzMk0zMS41OCA4MS44M2gzMk0zMS41OCA4NS44M2gzMk0zMS41OCA5MC44M2gzMk0zMS41OCA5NC44M2gzMk0zMS41OCA5OC44M2gzMk0zMS41OCAxMDIuODNoMzJNMzEuNTggMTA2LjgzaDMyIi8+PC9zdmc+',
	'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5NS42NyIgaGVpZ2h0PSIxMTkuODgiIHZpZXdCb3g9IjAgMCA5NS42NyAxMTkuODgiPjx0aXRsZT5sYXlvdXQtMzwvdGl0bGU+PHBhdGggZmlsbD0iI2YzZjNmMyIgZD0iTTAgMGg5NS41djExOS44OEgweiIvPjxwYXRoIGZpbGw9IiNkMWQzZDMiIGQ9Ik0uMTcgNWg3NHY0MS4zM2gtNzR6TTc4LjE3IDVoMTcuNXY0MS4zM2gtMTcuNXoiLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiM1NTU2NWEiIHN0cm9rZS13aWR0aD0iNCIgZD0iTTIzLjE3IDU3LjMzaDUwIi8+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNTU1NjVhIiBkPSJNMjMuMTcgNzAuODNoNTBNMjMuMTcgNzQuODNoNTBNMjMuMTcgNzguODNoNTBNMjMuMTcgODIuODNoNTBNMjMuMTcgODYuODNoNTBNMjMuMTcgOTAuODNoNTBNMjMuMTcgOTQuODNoNTBNMjMuMTcgOTguODNoNTBNMjMuMTcgMTAyLjgzaDUwTTIzLjE3IDEwNi44M2g1MCIvPjwvc3ZnPg==',
	'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5NS41IiBoZWlnaHQ9IjExOS4zMSIgdmlld0JveD0iMCAwIDk1LjUgMTE5LjMxIj48dGl0bGU+bGF5b3V0LTQ8L3RpdGxlPjxwYXRoIGZpbGw9IiNmM2YzZjMiIGQ9Ik0wIDBoOTUuNXYxMTkuMzFIMHoiLz48cGF0aCBmaWxsPSIjZDFkM2QzIiBkPSJNNC41IDVIOTF2NDEuMzNINC41eiIvPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzU1NTY1YSIgc3Ryb2tlLXdpZHRoPSI0IiBkPSJNMTAuMjUgNTguMzNoMzJNMTAuMjUgNjYuMzNoMzIiLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiM1NTU2NWEiIGQ9Ik01My4yNSA1Ni44M2gzMk01My4yNSA2MC44M2gzMk01My4yNSA2NC44M2gzMk01My4yNSA2OC44M2gzMk01My4yNSA3Mi44M2gzMk01My4yNSA3Ny44M2gzMk01My4yNSA4MS44M2gzMk01My4yNSA4NS44M2gzMk01My4yNSA4OS44M2gzMk01My4yNSA5My44M2gzMk01My4yNSA5OC44M2gzMk01My4yNSAxMDIuODNoMzJNNTMuMjUgMTA2LjgzaDMyIi8+PC9zdmc+'
];
