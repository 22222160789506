import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import { IS_PRODUCTION_ENV } from '../utils/constants';

import HMHRedux from './redux-util';

// the slices we create
import userContext from './slices/userContext';
import activity from './slices/activity';
import session from './slices/session';
import ui from './slices/ui';
import kfr from './slices/kfr';
import success from './slices/success';

const loggerMiddleware = createLogger({
	level: 'info',
	collapsed: (getState, action) => {
		if (IS_PRODUCTION_ENV === false) {
			HMHRedux.configureLogger(getState, action);
		}
		return false;
	}
});

const middleware = [
	...getDefaultMiddleware({
		serializableCheck: false,
		immutableCheck: false
	}),
	//loggerMiddleware,
	save({
		states: ['userContext', 'activity'],
		namespace: 'r180'
	})
];

const reducer = combineReducers({
	activity,
	userContext,
	session,
	ui,
	kfr,
	success
});

const reduxStore = configureStore({
	reducer,
	middleware,
	devTools: IS_PRODUCTION_ENV === false,
	preloadedState: process.env.NODE_ENV === 'production' ? load({
		states: ['userContext', 'activity'],
		namespace: 'r180'
	}) : {}
});

export default reduxStore;
