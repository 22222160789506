import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            overflow: 'overlay'
        },
    },
    menuItems: {
        fontFamily: theme.fonts.uiBold,
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        padding: '8px',
        cursor: 'pointer',

        '& .MuiList-padding': {
            padding: 0
        },

        '& .MuiPaper-rounded': {
            borderRadius: '8px'
        },

        '& li.MuiListItem-button': {
            '&:hover': {
                color: theme.colors.white,
                background: '#0093E6'
            },

            '&.MuiListItem-gutters': {
                paddingLeft: '2em',
                paddingRight: '2em',
            }
        }
    },
    icon: {
        fontSize: '12px',
        transition: 'transform 0.25s ease-in-out',
        padding: '10px',
        cursor: 'pointer',

        '&.open': {
            transform: 'rotate(180deg)'
        }
    }
}));

const DropSwitch = (props) => {
    const { menuItems, onMenuChange, icon } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);


    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // ---------------------------------------------------


    const handleClose = () => {
        setAnchorEl(null);
    };
    // ---------------------------------------------------


    const handleMenuClick = (event, id) => {
        if (typeof onMenuChange === 'function') {
            onMenuChange(id);
        }
        // handleClose(event);
    }
    // ---------------------------------------------------

    return (
        <>
            <button aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpen} >
                <i className={`r180 ${icon} ${Boolean(anchorEl) && 'open'} ${classes.icon}`} />
            </button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menuItems}
            >
                {menuItems.map(item => (
                    <MenuItem key={item.id} onClick={e => handleMenuClick(e, item.id)}>{item.title}</MenuItem>
                ))}
            </Menu>
        </>
    );
};

DropSwitch.defaultProps = {
    icon: 'arrow-down'
}
export default React.memo(DropSwitch);