/* eslint-disable prefer-promise-reject-errors */
const transport = props =>
	new Promise((resolve, reject) => {
		if (!props) {
			return resolve({error: 'No props passed'});
		}

		if (!props.url && typeof props.url !== 'string') {
			return resolve({error: 'URL not valid'});
		}

		const {url, method, headers, body, credentials} = props;

		window
			.fetch(url, {
				method,
				headers,
				body,
				credentials
			})
			.then(response => {
				if (response.status === 204) {
					return resolve();
				}

				if (response.ok) {
					if (headers && headers.Accept === 'text/plain') {
						response
							.text()
							.then(responseText => {
								resolve(responseText);
							})
							.catch(err => {
								reject(err);
							});
					} else {
						// Accept: application/json
						response
							.json()
							.then(responseJson => {
								//check if response is an Array
								if (Array.isArray(responseJson)) {
									responseJson = responseJson.map(item => ({
										...item,
										traceId: response.headers.get(
											'x-b3-traceId'
										)
									}));
									//for teacherResponse api we are getting a value as response
									//hence below line of code is written to handle this scenario
								} else {
									typeof responseJson === 'object' &&
										(responseJson = {
											...responseJson,
											traceId: response.headers.get(
												'x-b3-traceid'
											)
										});
								}

								resolve(responseJson);
							})
							.catch(err => {
								reject(err);
							});
					}
				} else if (response.status >= 400) {
					if (headers && headers.Accept === 'text/plain') {
						response
							.text()
							.then(responseText => {
								reject(responseText);
							})
							.catch(err => {
								reject(err);
							});
					} else {
						// Accept: application/json
						response
							.json()
							.then(responseJson => {
								responseJson = {
									...responseJson,
									traceId: response.headers.get(
										'x-b3-traceid'
									)
								};
								reject(responseJson);
							})
							.catch(err => {
								reject(err);
							});
					}
				} else {
					throw new Error('Unhandled Response in Transport');
				}
			})
			.catch(error => {
				reject(error);
			});
	});

export default transport;
