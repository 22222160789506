import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import api from '../../api/api';
import { getContentInfo } from '../../media/mediaUtil';
import { getAudioExt } from '../../utils/audio';
import { MEDIA_SERVER_URL } from '../../utils/constants';
import { completeActivityAndGoToResultsPage, transitionToNextActivity } from '../../utils/navigation';
import { bulkReplace } from '../../utils/stringUtils';
import { loadSleep } from '../../utils/ui';

const EXT = getAudioExt();

export const SpeedChallenge = {
	TRIAL_SIZE: 5,
	MAX_TRIAL_COUNT: 40,
	MAX_RECAL_LEVEL: 3,
	FLUENT: 'Fluent',
	MISSED: 'Missed',
	STUDYLIST: 'S',
	REVIEWLIST: 'R',

	SOUND_INTRO: `${MEDIA_SERVER_URL}assets/activi/speedchall/r180u_speedchall_intro.${EXT}`,
	SOUND_PAUSE: `${MEDIA_SERVER_URL}assets/activi/speedchall/r180u_speedchall_pause.${EXT}`,

	SOUND_NEXT: `${MEDIA_SERVER_URL}assets/activi/speedchall/r180u_speedchall_next.${EXT}`,
	SOUND_REPEAT: `${MEDIA_SERVER_URL}assets/activi/speedchall/r180u_speedchall_repeat.${EXT}`,

	SOUND_CONTINUE: `${MEDIA_SERVER_URL}assets/activi/speedchall/r180u_speedchall_continue.${EXT}`,
	SOUND_HELP_TRIAL: `${MEDIA_SERVER_URL}assets/activi/speedchall/r180u_speedchall_trialhelp.${EXT}`,
	SOUND_HELP_FEEDBACK: `${MEDIA_SERVER_URL}assets/activi/speedchall/r180u_speedchall_feedbackhelp.${EXT}`,
	SOUND_FEEDBACK: `${MEDIA_SERVER_URL}assets/activi/speedchall/r180u_speedchall_feedback.${EXT}`,

	WORD_SOUND_URL: `${MEDIA_SERVER_URL}assets/dictio/word/r180u_word_{word}.${EXT}`,
	WORD_CTX_URL: `${MEDIA_SERVER_URL}assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,

	SFX_CORRECT: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_correct.${EXT}`,
	SFX_INCORRECT: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_incorrect.${EXT}`,
	SFX_CLICK: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_click.${EXT}`,

	SEQUENCE: {
		1: ['S', 'S', 'S', 'S', 'S'],
		2: ['S', 'R', 'S', 'R', 'S', 'R', 'S', 'R', 'S', 'R'],
		3: ['S', 'R', 'R', 'S', 'R', 'R', 'S', 'R', 'R', 'S', 'R', 'R', 'S', 'R', 'R']
	},
	TIMER_VALUE: {
		1: 3,
		2: 2,
		3: 1
	},

	init: async (activityData, studyList, reviewList, isActivityCharged, settings) => {
		const start = new Date().getTime();

		//////////////
		// load the activity sounds
		//////////////
		AudioPlayer2.loadSound(SpeedChallenge.SOUND_INTRO);
		AudioPlayer2.loadSound(SpeedChallenge.SOUND_HELP_TRIAL);
		AudioPlayer2.loadSound(SpeedChallenge.SOUND_HELP_FEEDBACK);
		AudioPlayer2.loadSound(SpeedChallenge.SOUND_FEEDBACK);
		AudioPlayer2.load({ name: 'intro', src: SpeedChallenge.SOUND_INTRO });
		AudioPlayer2.load({ name: 'pause', src: SpeedChallenge.SOUND_PAUSE });
		AudioPlayer2.load({ name: 'next', src: SpeedChallenge.SOUND_NEXT });
		AudioPlayer2.load({ name: 'repeat', src: SpeedChallenge.SOUND_REPEAT });
		AudioPlayer2.load({ name: 'continue', src: SpeedChallenge.SOUND_CONTINUE });
		AudioPlayer2.load({ name: 'correct_spell', src: SpeedChallenge.SFX_CORRECT });
		AudioPlayer2.load({ name: 'incorrect_spell', src: SpeedChallenge.SFX_INCORRECT });
		AudioPlayer2.load({ name: 'click', src: SpeedChallenge.SFX_CLICK });

		const studyWords = [];
		const reviewWords = [];

		// load each of the words sounds and sentences
		studyList.fluencyScores.forEach(wordObj => {
			const word = wordObj.wordId;
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(SpeedChallenge.WORD_SOUND_URL, constants);
			const word_ctx_url = bulkReplace(SpeedChallenge.WORD_CTX_URL, constants);

			// the pronounced word
			AudioPlayer2.load({ name: word, src: word_text_url });
			// the context
			AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });
			studyWords.push({ ...wordObj, name: wordObj.wordId })
		});

		reviewList.fluencyScores.forEach(wordObj => {
			const word = wordObj.wordId;
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(SpeedChallenge.WORD_SOUND_URL, constants);
			const word_ctx_url = bulkReplace(SpeedChallenge.WORD_CTX_URL, constants);

			// the pronounced word
			AudioPlayer2.load({ name: word, src: word_text_url });
			// the context
			AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });
			reviewWords.push({ ...wordObj, name: wordObj.wordId })
		});

		const end = new Date().getTime();
		if (isActivityCharged === false) {
			await loadSleep(end - start);
		}
		return { studyWords, reviewWords };
	},

	isCompletionPercentageCovered: (currentTrailResponseMap, studyWordList, recallValue) => {
		let isCompleted = false;
		// filter study from the currentTrailResponseMap, because we need to calculate percentage only based on responsed study list.
		const filteredStudyListResponseMap = currentTrailResponseMap.filter(trailResponseObj => {
			const match = studyWordList.filter(studyListObj => {
				return studyListObj.wordId === trailResponseObj.wordId;
			});
			return match.length !== 0;
		});

		const completedPercentage = SpeedChallenge.calculateCompletionPercentage(filteredStudyListResponseMap);
		if (recallValue === 1) {
			if (completedPercentage === 100) {
				isCompleted = true;
			}
		}
		else {
			//recallvalue 2 and 3
			if (completedPercentage >= 80) {
				isCompleted = true;
			}
		}
		return isCompleted;

	},

	calculateCompletionPercentage: (currentTrailResponseMap) => {

		const filteredResponseMap = currentTrailResponseMap.filter(trailResponseObj => {
			return trailResponseObj.isCorrect === true
		});
		const completedPercentage = Math.round((filteredResponseMap.length / currentTrailResponseMap.length) * 100);
		return completedPercentage;

	},

	// mark the item as complete, but don't navigate away from page
	completeActivityNoTransition: (activityData, recallValue, fluencyAssessment, fluencyScoresArray) => {
		const cleanFluencyScoresArray = fluencyScoresArray.map((item) => {
			return {
				wordId: item.wordId,
				score: item.score,
				answer: item.answer
			}
		});

		completeActivityAndGoToResultsPage({
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			},
			fluencyassessment: {
				recall: recallValue,
				studentSegmentId: fluencyAssessment.studentSegmentId,
				fluencyAssessmentZone: fluencyAssessment.fluencyAssessmentZone
			},
			fluencyassessmentresponse: {
				fluencyScores: cleanFluencyScoresArray,
				studentActivityId: activityData.studentActivityId,
				assessmentZone: fluencyAssessment.fluencyAssessmentZone
			}
		});

	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendProgressToServer: (activityData, recallValue, fluencyAssessment, fluencyScoresArray) => {
		const cleanFluencyScoresArray = fluencyScoresArray.map((item) => {
			return {
				wordId: item.wordId,
				score: item.score,
				answer: item.answer
			}
		})

		let newActivityData = {
			activity: {
				isComplete: false,
				studentActivityId: activityData.studentActivityId
			},
			fluencyassessment: {
				recall: recallValue,
				studentSegmentId: fluencyAssessment.studentSegmentId,
				fluencyAssessmentZone: fluencyAssessment.fluencyAssessmentZone
			}, fluencyassessmentresponse: {
				fluencyScores: cleanFluencyScoresArray,
				studentActivityId: activityData.studentActivityId,
				assessmentZone: fluencyAssessment.fluencyAssessmentZone
			}
		}

		api.activity.updateActivity(newActivityData);
	}
};
