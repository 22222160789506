import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { appRouteList, appRouteMap } from './ActivityRouter';
import Switch from "react-switch";
import { isDemoMode, setDemoMode } from '../../utils/ui';
import { flatten } from 'lodash';

export const useStyles = makeStyles(theme => ({
	demo: {
		position: 'absolute',
		display: 'block',
		lineHeight: '1.4',
		fontSize: '15px',
		textShadow: 'none',
		top: '-47px',
		left: '50%',
		transform: 'translateX(-50%)',
		width: '400px',
		height: 'auto',
		background: 'transparent',
		transition: 'top 0.3s ease',
		textAlign: 'center',
		zIndex: '99999',
		color: '#fff',
		'&.expanded': {
			top: '0'
		},
		'& .clear-hide': {
			position: 'absolute',
			top: '54px',
			left: '32px',
			background: 'transparent',
			border: '1px solid transparent',
			height: '32px',
			width: '32px',
			cursor: 'pointer',
			'&:hover': {
				border: '1px dashed #444',
			}
		},
		'& .choices': {
			background: '#333',
			height: '48px',
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px',
			lineHeight: '48px',
			'& select': {
				position: 'relative',
				top: '-1.5px'
			},
			'& button': {
				fontSize: '15px',
				background: theme.colors.readingZone.primary,
				marginLeft: '30px',
				padding: '4px 12px',
				borderRadius: '3px',
				color: '#fff',
				fontWeight: '600',
				'& span': {
					position: 'relative',
					top: '-2px',
					marginRight: '8px'
				}
			}
		},
		'& .tab': {
			width: '240px',
			height: '38px',
			lineHeight: '38px',
			display: 'block',
			cursor: 'pointer',
			background: 'rgba(88, 88, 88, 0.6)',
			margin: 'auto',
			textAlign: 'center',
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px',
			'& span': {
				position: 'relative',
				top: '-2px'
			},
			'& > div': {
				marginLeft: '20px !important',
				top: '4px'
			},
			'&.hidden': {
				display: 'none'
			}
		}
	}
}));

const DemoMode = (props) => {
	const classes = useStyles();

	const path = window.location.href;
	const baseUrl = path.substring(0, path.indexOf('/app') + 5);

	const [selectedPath, setSelectedPath] = useState(path);
	const [isSwitchedOn, setSwitchedOn] = useState(isDemoMode());
	const [isTotallyHidden, setTotallyHidden] = useState(false);

	const handleGoOn = () => {
		window.location.href = selectedPath;
	};

	const handleSelect = (e) => {
		setSelectedPath(e.target.value);
	};

	const handleSwitch = (isOn) => {
		setSwitchedOn(isOn);
		setDemoMode(isOn);
		if (isOn === false) {
			setTimeout(() => {
				window.location.reload();
			}, 800);
		}
	};

	const handleClearClick = () => {
		setTotallyHidden(!isTotallyHidden);
	}

	const options = flatten(Object.keys(appRouteMap).map((zone) => {
		return appRouteMap[zone].map((r, i) => {
			let prefix = 'zone/';
			if (r.route === '') {
				prefix = '';
			}
			if (zone === 'explore' && r.pageTitle !== 'Knowledge For Reading') {
				return null;
			}
			if (r.pageTitle === 'Informative' || r.pageTitle === 'Narrative' || r.pageTitle === 'Argument') {
				return null;
			}
			return <option value={`${baseUrl}${prefix}${zone}/${r.route}`} key={`${r.route}-${i}`}>{r.pageTitle}</option>;
		});
	}));

	const handleClick = (e) => {
		e.stopPropagation();
		if (e.ctrlKey) {
			const activity = path.substring(path.lastIndexOf('/') + 1);
			const match = appRouteList.find((r) => {
				return activity === r.route;
			});
			if (match) {
				alert(match.author.join(' '));
			}
			else {
				alert('No Author');
			}
		}
	}

    return (
        <div className={`${classes.demo} ${isSwitchedOn ? 'expanded' : ''}`} onClick={handleClick}>
			<div className={'clear-hide'} onClick={handleClearClick}></div>
			<div className='choices'>
				<select
					id='choose-activity-select'
					onChange={handleSelect}
					value={selectedPath}
				>
					{options}
				</select>
				<button type='button' onClick={handleGoOn}><span>Go</span><i className='r180 go-on'></i></button>
			</div>
            <div className={`tab ${isTotallyHidden ? 'hidden' : ''}`}>
				<span>Demo Mode</span>
				<Switch
					onChange={handleSwitch}
					checked={isSwitchedOn}
					handleDiameter={18}
					height={24}
					width={48}
				/>
			</div>
        </div>
    );
}

export default React.memo(DemoMode);
