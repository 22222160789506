import {makeStyles} from '@material-ui/styles';

export const useStyle = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '480px',
		backgroundColor: theme.colors.white,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: 8
	},

	headerWrapper: {
		backgroundColor: theme.colors.softBlack,
		height: 16,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8
	},

	footerWrapper: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.softBlack,
		height: 48,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8
	},

	mainWordAreaWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 112
	},

	detailsAreaWrapper: {
		display: 'block',
		height: 224,
		position: 'relative'
	},

	decodingAreaWrapper: {
		display: 'flex',
		backgroundColor: 'transparent',
		padding: '0px 0px'
	},

	definitionAreaWrapper: {
		display: 'flex',
		backgroundColor: 'transparent',
		padding: '0px 42px'
	},

	contextSentenceAreaWrapper: {
		display: 'flex',
		backgroundColor: 'transparent',
		padding: '0px 42px',
		marginTop: 24
	},

	masterProgressAreaWrapper: {
		display: 'flex',
		backgroundColor: 'transparent',
		padding: '0 24px',
		flex: '1 0 auto',
		alignItems: 'center',
		userSelect: 'none',
		justifyContent: 'space-between'
	},

	progressDetailsWrapper: {
		display: 'flex',
		padding: '0px',
		fontFamily: theme.fonts.buttonBold,
		fontSize: '18px',
		lineHeight: '18px',
		alignItems: 'center',
		letterSpacing: '0.04em',
		color: theme.colors.white
	},

	divider: {
		backgroundColor: theme.colors.paleGrey,
		border: 'none',
		height: '1px',
		width: '84%',
		marginTop: '0',
		marginBottom: '0',
		'&.top': {
			marginBottom: '32px'
		},
		'&.bottom': {
			position: 'absolute',
			bottom: 0,
			left: '8%'
		}
	},

	hLine: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 0 auto',
		width: 1
	},

	vLine: {
		display: 'flex',
		flexDirection: 'column',
		flex: '0 0 auto',
		margin: '5px 8px',
		alignSelf: 'stretch',
		width: '1px',
		backgroundColor: '#D1D3D3'
	},
	hide: {
		display: 'none'
	},
	spellPreselected: {
		justifyContent: 'center'
	},
	closeButton: {
		position: 'absolute',
		top: '24px',
		right: '5px'
	}
}));
