import { MEDIA_SERVER_URL } from '../../utils/constants';
import { getAudioExt } from '../../utils/audio';
import { loadSleep } from '../../utils/ui';
import media from '../../api/media';
import { bulkReplace } from '../../utils/stringUtils';
import { KnowledgeForReading } from '../WordCard/KnowledgeForReading';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { isEmpty } from 'lodash';
import { createAssetId } from '../../media/mediaUtil';
import { getContentInfo } from '../../media/mediaUtil';
import { formattedText, parsePassageText } from '@reading/common';
import { transitionToNextActivity } from '../../utils/navigation';
import api from '../../api/api';

const EXT = getAudioExt();

export const Writing = {
	////////////
	//  Constants needed on the Writing selector page
	////////////
	ACTIVITY_STATE: {
		INITIAL: 1,
		SELECTOR: 2,
		PLANNING_DRAFTING: 3,
		REVISION: 4,
		EDITING: 5,
		PUBLISHING: 6
	},
	INFORMATIVE: 'informative',
	ARGUMENT: 'argument',
	AGREE_SELECTED: 2,
	STRONGLY_AGREE_SELECTED: 3,
	ADDMORE: {
		argument: 'Add More Reasons and Evidence',
		informative: 'Add Idea and Details'
	},
	END: 'end',
	ADDPANEL_MAX_LIMIT: '11',
	ADDPANEL_CUTTOFF: '12',
	POSITIVE_KEY: 'positiveEnd',
	NEGATIVE_KEY: 'negativeEnd',
	WRITING_SUBMENU_INFO: `content/segments/r180u_segments_{stage}.json`,
	ERASE_TEXT_ALERT: 'Do you want to erase what you wrote and start again?',
	SCREEN_LOADER_TITLE: 'Writing',
	PROGRESS_LOADER_TITLE: 'Your writing is being put together',
	PUBLISH_LOADER_TITLE: 'You published your writing',
	WRITING_WRITING_PROMPT: {
		informative: `${MEDIA_SERVER_URL}/assets/activi/writing/{level}/r180u_writing_informative_{word}_question.${EXT}`,
		argument: `${MEDIA_SERVER_URL}/assets/activi/writing/{level}/r180u_writing_argument_{word}_question.${EXT}`,
		narrative: `${MEDIA_SERVER_URL}/assets/activi/writing/{level}/r180u_writing_narrative_{word}_question.${EXT}`
	},
	WRITING_PROMPT_INTRO: {
		argument: {
			name: 'argumentIntro',
			src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_plan_lintro.${EXT}`
		},
		informative: {
			name: 'informativeIntro',
			src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_plan_lintro.${EXT}`
		},
		narrative: {
			name: 'narrativeIntro',
			src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_plan_lintro.${EXT}`
		}
	},

	WRITING_SUBMENU_HELP: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_submenu_help.${EXT}`,
	WRITING_SUBMENU_INTRO: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_submenu_lintro.${EXT}`,
	WRITING_RATING_INTRO: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_edit_spelling_lintro.${EXT}`,

	WRITING_SUBMENU_SOUND_MAP: {
		argument: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_submenu_arginfo.${EXT}`,
		informative: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_submenu_infinfo.${EXT}`,
		narrative: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_submenu_narinfo.${EXT}`
	},

	WRITING_SUBMENU_DATA_MAP: {
		argument: `content/activi/writing/r180u_content_writing_{asset_id}_argument.json`,
		informative: `content/activi/writing/r180u_content_writing_{asset_id}_informative.json`,
		narrative: `content/activi/writing/r180u_content_writing_{asset_id}_narrative.json`
	},
	WRITING_TOOLS_SPELLCHECK: `content/dictio/r180u_spellcheck.json`,
	WRITING_TOOLS_TIREDWORDS: `content/dictio/r180u_tiredwords.json`,
	WRITING_GOON: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_goon.${EXT}`,
	WRITING_REVIEW_LOADER: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_loadingtext.${EXT}`,
	WRITING_PUBLISH_LOADER: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_publish_submit_lintro.${EXT}`,
	WRITING_PROMPT_CHOICES: [`Strongly Disagree`, `Disagree`, `Agree`, `Strongly Agree`],
	WRITING_PROMPT_IMG: '/assets/images/r180u_herobanner_{asset_id}.jpg',
	WRITING_REVIEW_LAYOUT_ZONEMENU_IMG: '/assets/activi/zonemenu/r180u_zonemenu_{asset_id}.jpg',
	WRITING_REVIEW_LAYOUT_SEGSELECT_IMG: '/assets/activi/segselect/r180u_segselect_preview_{asset_id}.jpg',
	WRITING_REVIEW_LAYOUT_BANNER_IMG: '/assets/images/r180u_herobanner_{asset_id}.jpg',
	WRITING_PANELS: {
		help: {
			informative: {
				THESIS: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_thesis_help.${EXT}`,
				panel1: {
					idea1: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_idea_help.${EXT}`,
					detail1: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_detail_help.${EXT}`,
					detail2: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_detail_help.${EXT}`
				},
				panel2: {
					idea2: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_idea_help.${EXT}`,
					detail1: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_detail_help.${EXT}`,
					detail2: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_detail_help.${EXT}`
				},
				end: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_ending_help.${EXT}`,
				revise: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_revise_help.${EXT}`
			},

			argument: {
				THESIS: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_thesis_help.${EXT}`,
				panel1: {
					reason1: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_reason_help.${EXT}`,
					reason2: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_reason_help.${EXT}`,
					evidence1: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_evidence_help.${EXT}`,
					evidence2: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_evidence_help.${EXT}`
				},
				panel2: {
					reason1: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_idea_help.${EXT}`,
					reason2: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_reason_help.${EXT}`,
					evidence1: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_evidence_help.${EXT}`,
					evidence2: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_evidence_help.${EXT}`
				},
				positiveEnd: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_ending_help.${EXT}`,
				negativeEnd: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_ending_help.${EXT}`,
				end: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_ending_help.${EXT}`,
				revise: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_revise_help.${EXT}`
			},

			narrative: {
				beginning: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_begnotes_help.${EXT}`,
				middle: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_midnotes_help.${EXT}`,
				end: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_endnotes_help.${EXT}`
			}
		},
		informative: {
			THESIS: {
				name: 'informative_thesis',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_thesis_lintro_lowlvl.${EXT}`
			},
			panel1: {
				idea1: {
					name: 'informative_idea1',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_idea_lintro_lowlvl.${EXT}`
				},
				detail1: {
					name: 'informative_detail1',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_detail1_lintro_lowlvl.${EXT}`
				},
				detail2: {
					name: 'informative_detail2',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_detail2_lintro.${EXT}`
				}
			},
			panel2: {
				idea2: {
					name: 'informative_idea21',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_idea2_lintro_lowlvl.${EXT}`
				},
				detail1: {
					name: 'informative_detail21',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_detail3_lintro_lowlvl.${EXT}`
				},
				detail2: {
					name: 'informative_detail22',
					src: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_informative_detail4_lintro.${EXT}`
				}
			},
			panel3: {
				idea3: {
					name: 'informative_idea21',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_idea3_lintro_lowlvl.${EXT}`
				},
				detail1: {
					name: 'informative_detail21',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_detail3_lintro_lowlvl.${EXT}`
				},
				detail2: {
					name: 'informative_detail22',
					src: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_informative_detail4_lintro.${EXT}`
				}
			},
			end: {
				name: 'informative_end',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_ending_lintro_lowlvl.${EXT}`
			},
			goon: {
				name: 'informative_goon',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_goonprompt.${EXT}`
			},
			revise: {
				name: 'informative_revise',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_revise_lintro.${EXT}`
			}
		},
		argument: {
			THESIS: {
				name: 'argument_thesis',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_thesis_lintro_lowlvl.${EXT}`
			},
			panel1: {
				reason1: {
					name: 'argument_reason1',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_reason_lintro_lowlvl.${EXT}`
				},
				evidence1: {
					name: 'argument_evidence1',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_evidence1_lintro.${EXT}`
				},
				evidence2: {
					name: 'argument_evidence2',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_evidence2_lintro.${EXT}`
				}
			},
			panel2: {
				reason2: {
					name: 'argument_reason2',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_reason2_lintro_lowlvl.${EXT}`
				},
				evidence1: {
					name: 'argument_evidence3',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_evidence3_lintro.${EXT}`
				},
				evidence2: {
					name: 'argument_evidence4',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_evidence4_lintro.${EXT}`
				}
			},
			end: {
				name: 'argument_end',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_ending_lintro_lowlvl.${EXT}`
			},
			goon: {
				name: 'argument_goon',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_goonprompt.${EXT}`
			},
			revise: {
				name: 'argument_revise',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_revise_lintro.${EXT}`
			}
		},
		narrative: {
			'writing-notes': {
				name: 'notes_intro',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_who_lintro_lowlvl.${EXT}`
			},
			panel1: {
				who: {
					name: 'notes_who',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_who_lintro_lowlvl.${EXT}`
				},
				when: {
					name: 'notes_when',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_when_lintro.${EXT}`
				},
				where: {
					name: 'notes_where',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_where_lintro.${EXT}`
				},
				what1: {
					name: 'notes_what1',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_what1_lintro_lowlvl.${EXT}`
				},
				what2: {
					name: 'notes_what2',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_what2_lintro.${EXT}`
				},
				how1: {
					name: 'notes_how1',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_how1_lintro.${EXT}`
				},
				how2: {
					name: 'notes_how2',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_how2_lintro.${EXT}`
				},
			},

			panel2: {
				beginning: {
					name: 'narrative_beginning',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_beginning_lintro_lowlvl.${EXT}`
				},
				middle: {
					name: 'narrative_middle',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_middle_lintro_lowlvl.${EXT}`
				},
				ending: {
					name: 'narrative_ending',
					src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_ending_lintro_lowlvl.${EXT}`
				}
			},
			revise: {
				name: 'narrative_revise',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_revise_lintro.${EXT}`
			},
			rating: {
				name: 'narrative_rating',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_edit_spelling_lintro.${EXT}`
			},
			publish: {
				name: 'narrative_publish',
				src: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_publish_layout_lintro.${EXT}`
			}
		}
	},
	////////////
	//  Constants needed on actual Writing page
	////////////
	WRITING_PASSAGE_HTML: `content/passages/r180u_passage_{asset_id}.html`,

	WORD_CARD_LIST_URL: `content/wordcards/r180u_wordcards_{asset_id}.json`,

	HELP: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_informative_plan_help.${EXT}`,

	SOUNDS: {
		informative: {
			QUES: `${MEDIA_SERVER_URL}assets/activi/writing/{stage}/r180u_writing_informative_{asset_id}_question.${EXT}`,
			INTRO: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_informative_plan_lintro.${EXT}`,
			HELP: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_informative_plan_help.${EXT}`
		},
		strategy: {
			QUES: `${MEDIA_SERVER_URL}assets/activi/writing/{stage}/r180u_writing_informative_{asset_id}_question.${EXT}`,
			INTRO: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_informative_strategy_lintro.${EXT}`,
			HELP: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_informative_strategy_help.${EXT}`
		},
		narrative: {
			QUES: `${MEDIA_SERVER_URL}assets/activi/writing/{stage}/r180u_writing_narrative_{asset_id}_question.${EXT}`,
			INTRO: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_narrative_plan_lintro.${EXT}`,
			HELP: `${MEDIA_SERVER_URL}assets/activi/writing/r180u_writing_narrative_plan_help.${EXT}`
		}
	},

	WRITING_INFORMATIVE: `${MEDIA_SERVER_URL}content/activi/writing/r180u_content_writing_{asset_id}_informative.json`,
	WRITING_NARRATIVE: `${MEDIA_SERVER_URL}content/activi/writing/r180u_content_writing_{asset_id}_narrative.json`,

	PANEL_STATUS: {
		PENDING: 'pending',
		CURRENT: 'current',
		COMPLETED: 'completed'
	},
	acronymBoard: {
		informative: {
			name: 'acronym_informative',
			source: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_strategy_lintro.${EXT}`
		},
		help: {
			informative: {
				name: 'acronym_help_informative',
				source: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_strategy_help.${EXT}`
			},
			argument: {
				name: 'acronym_help_argument',
				source: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_strategy_help.${EXT}`
			}
		},
		info: {
			argument: [
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_thesisinfo.${EXT}`,
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_reasoninfo.${EXT}`,
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_evidenceinfo.${EXT}`,
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_endinginfo.${EXT}`
			],
			informative: [
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_thesisinfo.${EXT}`,
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_ideainfo.${EXT}`,
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_detailinfo.${EXT}`,
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_endinginfo.${EXT}`
			],
			narrative: [
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_thesisinfo.${EXT}`,
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_thesisinfo.${EXT}`,
				`${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_informative_thesisinfo.${EXT}`
			]
		},

		argument: {
			name: 'acronym_argument',
			source: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_argument_strategy_lintro.${EXT}`
		},

		narrative: {
			name: 'acronym_narrative',
			source: `${MEDIA_SERVER_URL}/assets/activi/writing/r180u_writing_narrative_strategy_lintro.${EXT}`
		}
	},

	acronymMap: {
		informative: {
			acronym: 'tide',
			abbreviations: ['Thesis Statement', 'Idea', 'Detail', 'Ending']
		},
		argument: {
			acronym: 'tree',
			abbreviations: ['Thesis Statement', 'Reason', 'Evidence', 'Ending']
		},
		narrative: {
			acronym: 'bme',
			abbreviations: ['Beginning', 'Middle', 'Ending']
		}
	},

	getActivityName: (path) => {
		if (path.includes('argument')) {
			return 'writing_argument'
		}

		if (path.includes('narrative')) {
			return 'writing_narrative'
		}

		return 'writing_informative'
	},

	initSubmenu: async (stage, segment, level) => {
		const asset_id = createAssetId(stage, segment, level, true);
		AudioPlayer2.loadSound(Writing.WRITING_SUBMENU_HELP);
		AudioPlayer2.loadSound(Writing.WRITING_SUBMENU_INTRO);

		AudioPlayer2.load({ name: 'submenu_arg_info', src: Writing.WRITING_SUBMENU_SOUND_MAP['argument'] });
		AudioPlayer2.load({ name: 'submenu_inf_info', src: Writing.WRITING_SUBMENU_SOUND_MAP['informative'] });
		AudioPlayer2.load({ name: 'submenu_nar_info', src: Writing.WRITING_SUBMENU_SOUND_MAP['narrative'] });

		////////
		// load the segment information
		////////
		const { segments } = await media.getOne(bulkReplace(Writing.WRITING_SUBMENU_INFO, { stage }));

		const selectedSegment = segments.filter(seg => {
			return seg.segment_number === segment;
		})[0];
		const selectorData = [
			{
				choice: selectedSegment.writing_options[0],
				passageData: await media.getOne(
					bulkReplace(Writing.WRITING_SUBMENU_DATA_MAP[selectedSegment.writing_options[0]], {
						asset_id
					})
				)
			},
			{
				choice: selectedSegment.writing_options[1],
				passageData: await media.getOne(
					bulkReplace(Writing.WRITING_SUBMENU_DATA_MAP[selectedSegment.writing_options[1]], {
						asset_id
					})
				)
			},
		];

		return selectorData;
	},

	init: async (contentData, settings, isActivityCharged, choiceSelected) => {
		const start = new Date().getTime();

		// load the word card data based on the assetId
		const assets = {
			asset_id: Writing.getAssetId(contentData.contentId)
		};
		const { word_cards } = await media.getOne(bulkReplace(Writing.WORD_CARD_LIST_URL, assets));
		// load the word card data based on the assetId
		const passgeAsset = {
			asset_id: Writing.getAssetId(contentData.contentId)
		};

		const passageTextData = await media.getOne(bulkReplace(Writing.WRITING_PASSAGE_HTML, passgeAsset));
		/* istanbul ignore next */
		const passageText = parsePassageText(passageTextData);
		//////////////
		// load the sounds needed in each word card that could appear from the 'Power Words' dropdown
		//////////////
		AudioPlayer2.loadSound(Writing.WRITING_PUBLISH_LOADER);
		if (word_cards) {
			word_cards.forEach(wordCard => {
				const word = wordCard.word_card.word_text;
				const constants = {
					word: word,
					asset_id: Writing.getAssetId(contentData.contentId, false)
				};

				const word_text_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, constants);
				const word_def_url = bulkReplace(KnowledgeForReading.WORD_DEF_URL, constants);
				const word_ctx_url = bulkReplace(KnowledgeForReading.WORD_CTX_URL, constants);

				// the pronounced word
				AudioPlayer2.load({
					name: word,
					src: word_text_url
				});

				// the definition
				AudioPlayer2.load({
					name: `def-${word}`,
					src: word_def_url
				});

				// the context
				AudioPlayer2.load({
					name: `ctx-${word}`,
					src: word_ctx_url
				});

				// load the foreign language sound file if necessary
				if (settings.language !== 'none') {
					const newWordUrl = word_text_url.replace(/word/g, settings.language);
					AudioPlayer2.load({
						name: word + '-foreign',
						src: newWordUrl
					});
				}
			});
		}

		//////////////
		// now we have to loop through the 6 word_card objects and load in the word-specific data
		//////////////
		let newWordCardMap = {};
		if (word_cards) {
			await Promise.all(word_cards.map(async (wordCard) => {
				const word = wordCard.word_card.word_text;
				const constants = {
					word
				};
				const wordContent = await media.getOne(bulkReplace(KnowledgeForReading.WORD_CARD_URL, constants));
				newWordCardMap[word] = wordContent;
			}));
		}

		/* Load Acronym  Intro VO */
		Object.keys(Writing.acronymBoard).forEach(item => {
			AudioPlayer2.load({
				name: Writing.acronymBoard[item].name,
				src: Writing.acronymBoard[item].source
			});
		});
		Object.keys(Writing.acronymBoard.help).forEach(item => {
			AudioPlayer2.load({
				name: Writing.acronymBoard.help[item].name,
				src: Writing.acronymBoard.help[item].source
			});
		});
		/* Load acronym Board VO  which triggsers on click of Circle */
		Writing.acronymBoard.info[contentData.type].forEach(item => {
			AudioPlayer2.loadSound(item);
		});
		/* Load Informative Panels   VO */

		Object.keys(Writing.WRITING_PANELS).forEach(item => {
			Object.keys(Writing.WRITING_PANELS[item]).forEach(panel => {
				if (panel.indexOf('panel') > -1) {
					Object.keys(Writing.WRITING_PANELS[item][panel]).forEach(data => {
						AudioPlayer2.load({
							name: Writing.WRITING_PANELS[item][panel][data].name,
							src: Writing.WRITING_PANELS[item][panel][data].src
						});
					});
				} else {
					AudioPlayer2.load({
						name: Writing.WRITING_PANELS[item][panel].name,
						src: Writing.WRITING_PANELS[item][panel].src
					});
				}
			});
		});

		// load the help
		Object.keys(Writing.WRITING_PANELS.help).forEach((key) => {
			const mode = Writing.WRITING_PANELS.help[key];
			Object.keys(mode).forEach((sound) => {
				const url = mode[sound];
				AudioPlayer2.load({
					name: `${key}-${sound}`,
					src: url
				});
			});
		});

		/* Load Writing Prompt INTRO VO */
		Object.keys(Writing.WRITING_PROMPT_INTRO).forEach(item => {
			AudioPlayer2.load({
				name: Writing.WRITING_PROMPT_INTRO[item].name,
				src: Writing.WRITING_PROMPT_INTRO[item].src
			});
		});

		//////////////
		// load the video information needed in the 'Video' button
		//////////////
		const segmentID = Writing.getAssetId(contentData.contentId, false);
		const videoID = `r180u_video_${segmentID}.mp4`;
		const videoCaptionID = `r180u_${segmentID}.vtt`;
		const videoUrl = `${MEDIA_SERVER_URL}/assets/video/${videoID}`;
		const videoCaptionInfo =
			[{
				src: `${MEDIA_SERVER_URL}/assets/video/${videoCaptionID}`,
				label: 'English subtitles',
				kind: 'subtitles',
				srcLang: 'en'
			}];
		//////////////
		//  convert the content data into data suitable for rendering
		//////////////

		const renderData = Writing.convertContentToData(contentData, choiceSelected);

		const [writingToolsSpellCheckObj, writingToolsTiredWordsObj] = await Promise.all([
			media.getOne(Writing.WRITING_TOOLS_SPELLCHECK),
			media.getOne(Writing.WRITING_TOOLS_TIREDWORDS)
		]);

		const end = new Date().getTime();

		/* istanbul ignore else */
		if (isActivityCharged === false) {
			await loadSleep(end - start);
		}

		return {
			wordCards: word_cards,
			newWordCardMap,
			renderData,
			videoUrl,
			videoCaptionInfo,
			writingToolsSpellCheckObj,
			writingToolsTiredWordsObj,
			passageText
		};
	},

	getAssetId: (writingAssetId, longStyle = true) => {
		/* istanbul ignore next */
		if (isEmpty(writingAssetId)) {
			return '';
		}

		if (longStyle) {
			return writingAssetId.toLowerCase().replace(/_/g, '');
		} else {
			return writingAssetId
				.toLowerCase()
				.replace(/_/g, '')
				.substring(0, 3);
		}
	},

	convertContentToData: (data, choiceSelected) => {
		switch (data.type) {
			case 'informative':
				return Writing.createInformativeData(data);
			case 'argument':
				return Writing.createArgumentData(data, choiceSelected);
			case 'narrative':
				return Writing.createNarrativeData(data);
			default:
				return {};
		}
	},
	sectionContentLength: panel => {
		const formattedIdea = panel.sections.filter(item => formattedText(item.text).length > 0);
		return formattedIdea.length > 0;
	},

	createInformativeData: contentData => {
		const data = {};
		data.panels = [];

		data.question = {
			prompt: contentData.questionPrompt,
			markup: contentData.questionPromptMarkup
		};

		const thesisPanel = {
			status: Writing.PANEL_STATUS.PENDING,
			id: 'thesis',
			sections: [
				{
					id: 'thesis',
					title: 'Thesis Statement',
					letter: 'T',
					text: contentData.topicSentence,
					originalText: contentData.topicSentence
				}
			]
		};
		data.panels.push(thesisPanel);

		const idea1 = {
			status: Writing.PANEL_STATUS.PENDING,
			id: 'idea1',
			sections: [
				{
					id: 'idea',
					title: 'Idea',
					letter: 'I',
					text: contentData.idea1Support,
					originalText: contentData.idea1Support
				},
				{
					id: 'detail1',
					title: 'Detail',
					letter: 'D',
					text: contentData.idea1Detail1,
					originalText: contentData.idea1Detail1
				},
				{
					id: 'detail2',
					title: 'Detail',
					letter: 'D',
					text: contentData.idea1Detail2,
					originalText: contentData.idea1Detail2
				}
			]
		};
		data.panels.push(idea1);

		const idea2 = {
			status: Writing.PANEL_STATUS.PENDING,
			id: 'idea2',
			sections: [
				{
					id: 'idea',
					title: 'Idea',
					letter: 'I',
					text: contentData.idea2Support,
					originalText: contentData.idea2Support
				},
				{
					id: 'detail1',
					title: 'Detail',
					letter: 'D',
					text: contentData.idea2Detail1,
					originalText: contentData.idea2Detail1
				},
				{
					id: 'detail2',
					title: 'Detail',
					letter: 'D',
					text: contentData.idea2Detail2,
					originalText: contentData.idea2Detail2
				}
			]
		};
		data.panels.push(idea2);

		const idea3 = {
			status: Writing.PANEL_STATUS.PENDING,
			id: 'idea3',
			sections: [
				{
					id: 'idea',
					title: 'Idea',
					letter: 'I',
					text: contentData.idea3Support,
					originalText: contentData.idea3Support
				},
				{
					id: 'detail1',
					title: 'Detail',
					letter: 'D',
					text: contentData.idea3Detail1,
					originalText: contentData.idea3Detail1
				},
				{
					id: 'detail2',
					title: 'Detail',
					letter: 'D',
					text: contentData.idea3Detail2,
					originalText: contentData.idea3Detail2
				}
			]
		};
		/* istanbul ignore else */
		if (Writing.sectionContentLength(idea3)) {
			data.panels.push(idea3);
		}

		const endPanel = {
			status: Writing.PANEL_STATUS.PENDING,
			id: 'end',
			sections: [
				{
					id: 'end',
					title: 'End',
					letter: 'E',
					text: contentData.endSupport,
					originalText: contentData.endSupport
				}
			]
		};
		data.panels.push(endPanel);
		return data;
	},

	createArgumentData: (contentData, choiceSelected) => {
		const data = {};
		data.panels = [];

		data.question = {
			prompt: contentData.questionPrompt,
			markup: contentData.questionPromptMarkup
		};

		data.positive = {
			topic: contentData.positiveTopicSentence
		};
		/* istanbul ignore next */
		if (choiceSelected === Writing.STRONGLY_AGREE_SELECTED || choiceSelected === Writing.AGREE_SELECTED) {
			const thesisPanel = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'thesis',
				sections: [
					{
						id: 'thesis',
						title: 'Thesis Statement',
						letter: 'T',
						text: contentData.positiveTopicSentence,
						originalText: contentData.positiveTopicSentence
					}
				]
			};
			data.panels.push(thesisPanel);
			const positive1 = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'reason1',
				sections: [
					{
						id: 'reason1',
						title: 'Reason',
						letter: 'R',
						text: contentData.positiveReason1Support,
						originalText: contentData.positiveReason1Support
					},
					{
						id: 'evidence1',
						title: 'Evidence',
						letter: 'E',
						text: contentData.positiveReason1Evidence1,
						originalText: contentData.positiveReason1Evidence1
					},
					{
						id: 'evidence2',
						title: 'Evidence',
						letter: 'E',
						text: contentData.positiveReason1Evidence2,
						originalText: contentData.positiveReason1Evidence2
					}
				]
			};
			data.panels.push(positive1);

			const positive2 = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'reason2',
				sections: [
					{
						id: 'reason2',
						title: 'Reason',
						letter: 'R',
						text: contentData.positiveReason2Support,
						originalText: contentData.positiveReason2Support
					},
					{
						id: 'evidence1',
						title: 'Evidence',
						letter: 'E',
						text: contentData.positiveReason2Evidence1,
						originalText: contentData.positiveReason2Evidence1
					},
					{
						id: 'evidence2',
						title: 'Evidence',
						letter: 'E',
						text: contentData.positiveReason2Evidence2,
						originalText: contentData.positiveReason2Evidence2
					}
				]
			};
			data.panels.push(positive2);

			const positive3 = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'reason3',
				sections: [
					{
						id: 'reason3',
						title: 'Reason',
						letter: 'R',
						text: contentData.positiveReason3Support,
						originalText: contentData.positiveReason3Support
					},
					{
						id: 'evidence1',
						title: 'Evidence',
						letter: 'E',
						text: contentData.positiveReason3Evidence1,
						originalText: contentData.positiveReason3Evidence1
					},
					{
						id: 'evidence2',
						title: 'Evidence',
						letter: 'E',
						text: contentData.positiveReason3Evidence2,
						originalText: contentData.positiveReason3Evidence2
					}
				]
			};
			/* istanbul ignore else */
			if (Writing.sectionContentLength(positive3)) {
				data.panels.push(positive3);
			}

			const positiveEnd = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'positiveEnd',
				sections: [
					{
						id: 'positiveEnd',
						title: 'Ending',
						letter: 'E',
						text: contentData.positiveEndSupport,
						originalText: contentData.positiveEndSupport
					}
				]
			};
			data.panels.push(positiveEnd);

			data.positive = {
				topic: contentData.positiveTopicSentence
			};
		} else {
			const thesisPanel = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'thesis',
				sections: [
					{
						id: 'thesis',
						title: 'Thesis Statement',
						letter: 'T',
						text: contentData.negativeTopicSentence,
						originalText: contentData.negativeTopicSentence
					}
				]
			};
			data.panels.push(thesisPanel);
			const negative1 = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'reason1',
				sections: [
					{
						id: 'reason1',
						title: 'Reason',
						letter: 'R',
						text: contentData.negativeReason1Support,
						originalText: contentData.negativeReason1Support
					},
					{
						id: 'evidence1',
						title: 'Evidence',
						letter: 'E',
						text: contentData.negativeReason1Evidence1,
						originalText: contentData.negativeReason1Evidence1
					},
					{
						id: 'evidence2',
						title: 'Evidence',
						letter: 'E',
						text: contentData.negativeReason1Evidence2,
						originalText: contentData.negativeReason1Evidence2
					}
				]
			};
			data.panels.push(negative1);

			const negative2 = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'reason2',
				sections: [
					{
						id: 'reason2',
						title: 'Reason',
						letter: 'R',
						text: contentData.negativeReason2Support,
						originalText: contentData.negativeReason2Support
					},
					{
						id: 'evidence1',
						title: 'Evidence',
						letter: 'E',
						text: contentData.negativeReason2Evidence1,
						originalText: contentData.negativeReason2Evidence1
					},
					{
						id: 'evidence2',
						title: 'Evidence',
						letter: 'E',
						text: contentData.negativeReason2Evidence2,
						originalText: contentData.negativeReason2Evidence2
					}
				]
			};
			data.panels.push(negative2);

			const negative3 = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'reason3',
				sections: [
					{
						id: 'reason3',
						title: 'Reason',
						letter: 'R',
						text: contentData.negativeReason3Support,
						originalText: contentData.negativeReason3Support
					},
					{
						id: 'evidence1',
						title: 'Evidence',
						letter: 'E',
						text: contentData.negativeReason3Evidence1,
						originalText: contentData.negativeReason3Evidence1
					},
					{
						id: 'evidence2',
						title: 'Evidence',
						letter: 'E',
						text: contentData.negativeReason3Evidence2,
						originalText: contentData.negativeReason3Evidence2
					}
				]
			};
			if (Writing.sectionContentLength(negative3)) {
				data.panels.push(negative3);
			}
			const negativeEnd = {
				status: Writing.PANEL_STATUS.PENDING,
				id: 'negativeEnd',
				sections: [
					{
						id: 'negativeEnd',
						title: 'End',
						letter: 'E',
						text: contentData.negativeEndSupport,
						originalText: contentData.negativeEndSupport
					}
				]
			};
			data.panels.push(negativeEnd);
		}
		return data;
	},

	addBreaks: (data) => {
		return data.split('.').join('\n')
	},

	createNarrativeData: (contentData = {}) => {
		const BME = { B: [], M: [], E: [] };
		const audioSrc = Writing.WRITING_PANELS.narrative.panel1 || {};
		const BMELabels = {
			'who': { tag: 'B', title: 'Who', audio: audioSrc.who },
			'when': { tag: 'B', title: 'When', audio: audioSrc.when },
			'where': { tag: 'B', title: 'Where', audio: audioSrc.where },
			'whatPlotSupport': { tag: 'M', title: 'What 1', audio: audioSrc.what1 },
			'whatMotivationSupport': { tag: 'M', title: 'What 2', audio: audioSrc.what2 },
			'howCharacterEmotion': { tag: 'E', title: 'How 1', audio: audioSrc.how1 },
			'howEndSupport': { tag: 'E', title: 'How 2', audio: audioSrc.how2 },
		}

		Object.keys(BMELabels).forEach(label => {
			Object.keys(contentData).forEach(key => {
				if (key.includes(label)) {
					BME[BMELabels[label].tag].push({
						key,
						title: BMELabels[label].title,
						audio: BMELabels[label].audio,
						text: Writing.addBreaks(contentData[key]),
						notes: ''
					})
				}
			})
		})

		return {
			question: contentData.questionPromptMarkup,
			bme: [
				{ key: 'B', title: 'Beginning', sections: BME['B'] },
				{ key: 'M', title: 'Middle', sections: BME['M'] },
				{ key: 'E', title: 'End', sections: BME['E'] },
			]
		};
	},

	addNewPanel: (contentData, newPanelIndex) => {
		// eslint-disable-next-line default-case
		switch (contentData.type) {
			case Writing.ARGUMENT: {
				const newPanel = {
					status: Writing.PANEL_STATUS.COMPLETED,
					id: `reason${newPanelIndex}`,
					sections: [
						{
							id: `reason${newPanelIndex}`,
							title: 'Reason',
							letter: 'R',
							text: '',
							originalText: ''
						},
						{
							id: 'evidence1',
							title: 'Evidence',
							letter: 'E',
							text: '',
							originalText: ''
						},
						{
							id: 'evidence2',
							title: 'Evidence',
							letter: 'E',
							text: '',
							originalText: ''
						}
					]
				};
				return newPanel;
			}
			case Writing.INFORMATIVE: {
				const newPanel = {
					status: Writing.PANEL_STATUS.COMPLETED,
					id: `idea${newPanelIndex}`,
					sections: [
						{
							id: 'idea',
							title: 'Idea',
							letter: 'I',
							text: '',
							originalText: ''
						},
						{
							id: 'detail1',
							title: 'Detail',
							letter: 'D',
							text: '',
							originalText: ''
						},
						{
							id: 'detail2',
							title: 'Detail',
							letter: 'D',
							text: '',
							originalText: ''
						}
					]
				};
				return newPanel;
			}
		}
	},
	sendProgressToServer: (isComplete, activityState, studentActivityId) => {
		let newActivityData = {
			activity: {
				isComplete: isComplete,
				studentActivityId: studentActivityId
			},
			writingstatus: {
				completedState: activityState,
				studentActivityId: studentActivityId
			}
		}
		api.activity.updateActivity(newActivityData);
	},
	getWritingPrompt: (activityData, mode) => {
		/* istanbul ignore else */
		if (mode) {
			const constants = {
				word: getContentInfo(activityData, true).assetId,
				level: activityData.stage
			};
			const audioPath = bulkReplace(Writing.WRITING_WRITING_PROMPT[mode], constants);
			return audioPath;
		}
	},
	getWritingPromptImage: (activityData, contentData) => {
		const assets = {
			asset_id: Writing.getAssetId(contentData.contentId, false)
		};

		const imagePath = bulkReplace(Writing.WRITING_PROMPT_IMG, assets);
		return `${MEDIA_SERVER_URL}${imagePath}`;
	},
	getReviewLayoutImages: (activityData, contentData) => {
		let imagePathList = [];
		const assets = {
			asset_id: Writing.getAssetId(contentData.contentId, false)
		};
		imagePathList.push(`${MEDIA_SERVER_URL}${bulkReplace(Writing.WRITING_REVIEW_LAYOUT_BANNER_IMG, assets)}`);
		imagePathList.push(`${MEDIA_SERVER_URL}${bulkReplace(Writing.WRITING_REVIEW_LAYOUT_ZONEMENU_IMG, assets)}`);
		imagePathList.push(`${MEDIA_SERVER_URL}${bulkReplace(Writing.WRITING_REVIEW_LAYOUT_SEGSELECT_IMG, assets)}`);
		return imagePathList;
	},
	// move to the next activity
	completeActivity: async (history, activityData) => {
		await transitionToNextActivity(history, {
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			},
			writingstatus: {
				completedState: 'Publishing',
				studentActivityId: activityData.studentActivityId
			}
		});
	},
	publishToServer: (activityData, filename, uploadTypeInt, publishData) => {
		// TODO This call to GET gets the S3 address to post to, but the S3 endpoint itsn't ready yet.
		// After it's ready we'll need to upload this data to S3
		api.activity.uploadSingleFile(activityData.studentActivityId, filename, uploadTypeInt, publishData);
	}
};
