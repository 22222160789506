import React, { useState, useEffect } from 'react';
import { WritingPanelNarrative } from '@reading/common';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

const defaultNarratives = [
    { key: 'B', title: 'Beginning', narrative: '', audio: { name: 'narrative_beginning' } },
    { key: 'M', title: 'Middle', narrative: '', audio: { name: 'narrative_middle' } },
    { key: 'E', title: 'End', narrative: '', audio: { name: 'narrative_ending' } },
];

const WritingNarratives = props => {
    const {
        className,
        sections = defaultNarratives,
        onSectionCompleted,
        onNarrativesCompleted,
		isAutoPlay
    } = props;

	const {setState} = useBatchedSetState();

    const [narratives, setNarratives] = useState(sections);
    const [currentPanelIndex, setCurrentPanelIndex] = useState(0);

    useEffect(() => {
        const playAudio = async() => {
            const audio = narratives[currentPanelIndex].audio.name;
            AudioPlayer2.stopAll();
			if (isAutoPlay) {
	            await AudioPlayer2.playSync(audio);
			}
        }

        if (currentPanelIndex >= 0 && currentPanelIndex < 100) {
			playAudio();
		}

    }, [currentPanelIndex, narratives]);

    const handlePanelComplete = (text) => {
		AudioPlayer2.stopAll();
        const _narratives = [...narratives];
        _narratives[currentPanelIndex].narrative = text;
		setState(() => {
			setNarratives([..._narratives]);

			if (currentPanelIndex < sections.length - 1) {
				setCurrentPanelIndex(currentPanelIndex + 1);

				if (typeof onSectionCompleted === 'function') {
					onSectionCompleted(text, currentPanelIndex + 1);
				}
			}
			else {
				if (typeof onNarrativesCompleted === 'function') {
					setCurrentPanelIndex(999);
					onNarrativesCompleted(_narratives);
				}
			}
		});
    }

    const handleRevise = (i) => {
        setCurrentPanelIndex(i);
    }

    return (
        <div className={`writing-notes ${className}`}>
            {narratives.map((panel, i) => {
                return (
                    <WritingPanelNarrative
						key={panel.key}
                        index={i}
                        letter={panel.key}
                        title={panel.title}
                        text={panel.narrative}
                        isActive={currentPanelIndex === i}
						isCompleted={currentPanelIndex > i}
                        onRevise={handleRevise}
                        onPanelCompleted={handlePanelComplete}
                    />
                )
            })}
        </div>
    );
};

export default React.memo(WritingNarratives);
