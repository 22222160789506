import {Howl} from './utils/howler';
import Logger from '../Logger/Logger.js';
import {isFirefox} from 'react-device-detect';

let count = 0;

const isLocaleAsset = src => {
	const srcType = typeof src;
	const isSrcAry = Array.isArray(src);
	const isSrcStr = srcType === 'string';
	const isSrcObj = srcType === 'object';

	if (!isSrcAry && !isSrcStr && isSrcObj) {
		return true;
	}

	return false;
};

const prefixBaseUris = (srcAry, baseUri) => {
	return srcAry.map(file => baseUri + file);
};

const filterMediaTypes = srcAry => {
	const ogg = srcAry.filter(file => file.slice(-4) === '.ogg');
	const mp3 = srcAry.filter(file => file.slice(-4) === '.mp3');

	if (ogg && mp3 && isFirefox) {
		srcAry = ogg;
	}

	if (ogg && mp3 && !isFirefox) {
		srcAry = mp3;
	}

	return srcAry;
};

class AudioPlayer {
	constructor() {
		this.assets = {};
		this.mediaBaseUri = '';
	}

	setMediaUri(mediaBaseUri) {
		this.mediaBaseUri = mediaBaseUri;
	}

	createAsset(sound) {
		const asset = {
			id: count,
			name: sound.name,
			isLocale: false,
			playing: false,
			loaded: false,
			fire: () => {
				this.fire(sound.name);
			},
			play: () => {
				this.play(sound.name);
			},
			pause: () => {
				this.pause(sound.name);
			},
			stop: () => {
				this.stop(sound.name);
			},
			togglePlay: callback => {
				this.togglePlay(sound.name, null, callback);
			},
			onPlay: null,
			onPause: null,
			onStop: null,
			onEnd: null
		};

		let src;
		if (Array.isArray(sound.src)) {
			const srcAry = sound.src;
			const prefixedSrcAry = prefixBaseUris(srcAry, this.mediaBaseUri);
			src = filterMediaTypes(prefixedSrcAry);
		} else {
			src = this.mediaBaseUri + sound.src;
		}

		const howl = new Howl({
			src,
			onplay: () => {
				asset.playing = true;
				this.onPlay(asset);
			},
			onpause: () => {
				asset.playing = false;
				this.onPause(asset);
			},
			onload: () => {
				asset.loaded = true;
			},
			onstop: () => {
				asset.playing = false;
				this.onStop(asset);
			},
			onend: () => {
				asset.playing = false;
				this.onEnd(asset);
			}
		});

		asset.howl = howl;
		this.assets[sound.name] = asset;

		count += 1;
		return asset;
	}

	createLocaleAsset(sound) {
		const asset = {
			id: count,
			isLocale: true,
			name: sound.name,
			playing: false,
			loaded: false,
			userStopped: false,
			fire: locale => {
				this.fire(sound.name, locale || asset.defaultLocale);
			},
			play: locale => {
				this.play(sound.name, locale || asset.defaultLocale);
			},
			togglePlay: (locale, callback) => {
				this.togglePlay(
					sound.name,
					locale || asset.defaultLocale,
					callback
				);
			},
			pause: locale => {
				this.pause(sound.name, locale || asset.defaultLocale);
			},
			stop: locale => {
				this.stop(sound.name, locale || asset.defaultLocale);
			},
			onPlay: null,
			onPause: null,
			onStop: null,
			onEnd: null
		};

		const howls = {};
		let defaultLocale;

		const soundCount = Reflect.ownKeys(sound.src).length;
		let soundsLoaded = 0;

		Reflect.ownKeys(sound.src).forEach((key, idx) => {
			let src;

			if (Array.isArray(sound.src[key])) {
				const srcAry = sound.src[key];
				const prefixedSrcAry = prefixBaseUris(
					srcAry,
					this.mediaBaseUri
				);
				src = filterMediaTypes(prefixedSrcAry);
			} else {
				src = this.mediaBaseUri + sound.src[key];
			}

			if (idx === 0) {
				defaultLocale = key;
			}

			const localeHowl = new Howl({
				src,
				onload: () => {
					soundsLoaded += 1;
					if (soundsLoaded === soundCount) {
						asset.loaded = true;
					}
				},
				onplay: () => {
					asset.playing = true;
					this.onPlay(asset);
				},
				onpause: () => {
					asset.playing = false;
					this.onPause(asset);
				},
				onstop: () => {
					asset.playing = false;
					this.onStop(asset);
				},
				onend: () => {
					asset.playing = false;
					this.onEnd(asset);
				}
			});

			howls[key] = localeHowl;
		});

		asset.howl = howls;
		asset.defaultLocale = defaultLocale;

		this.assets[sound.name] = asset;

		count += 1;

		return asset;
	}

	load(sound) {
		if (!Reflect.has(sound, 'src')) {
			throw new Error('AudioPlayer.load(): Sound has no src.');
		}

		if (!Reflect.has(sound, 'name')) {
			throw new Error('AudioPlayer.load(): Sound has no name.');
		}

		if (Reflect.has(this.assets, sound.name)) {
			Logger.warn(
				`AudioPlayer.load(): Sound with name ${sound.name} already exists.`
			);

			const existingAsset = this.get(sound.name);
			return existingAsset;
		}

		if (isLocaleAsset(sound.src)) {
			return this.createLocaleAsset(sound);
		}

		return this.createAsset(sound);
	}

	get(name) {
		if (!Reflect.has(this.assets, name)) {
			Logger.warn(
				`AudioPlayer.get(): Asset with name "${name}" does not exist.`
			);
			return false;
		}

		const asset = this.assets[name];

		return asset;
	}

	playLocale(asset, locale) {
		Reflect.ownKeys(asset.howl).forEach(localeKey => {
			asset.howl[localeKey].stop();
		});

		asset.howl[locale].play();
	}

	play(name, locale) {
		const asset = this.get(name);

		if (locale && asset.isLocale) {
			if (!asset.playing) {
				this.playLocale(asset, locale);
			}

			return;
		}

		if (!asset.playing) {
			asset.howl.play();
		}
	}

	stopLocale(asset) {
		Reflect.ownKeys(asset.howl).forEach(localeKey => {
			asset.howl[localeKey].stop();
		});
	}

	stop(name, locale) {
		const asset = this.get(name);

		if (locale && asset.isLocale) {
			this.stopLocale(asset);
			return;
		}

		if (asset.playing === true) {
			asset.howl.stop();
		}
	}

	pauseLocale(asset) {
		Reflect.ownKeys(asset.howl).forEach(localeKey => {
			asset.howl[localeKey].pause();
		});
	}

	pause(name, locale) {
		const asset = this.get(name);

		if (locale && asset.isLocale) {
			if (asset.playing) {
				this.pauseLocale(asset);
			}

			return;
		}

		if (asset.playing) {
			asset.howl.pause();
		}
	}

	fireLocale(asset, locale) {
		Reflect.ownKeys(asset.howl).forEach(localeKey => {
			asset.howl[localeKey].stop();
		});

		asset.howl[locale].play();
	}

	fire(name, locale) {
		const asset = this.get(name);

		if (locale && asset.isLocale) {
			this.fireLocale(asset, locale);
			return;
		}

		asset.howl.stop();
		asset.howl.play();
	}

	togglePlayLocale(asset, locale) {
		if (asset.playing) {
			Reflect.ownKeys(asset.howl).forEach(localeKey => {
				asset.howl[localeKey].stop();
			});
		} else {
			asset.howl[locale].play();
		}
	}

	togglePlay(name, locale, callback) {
		const asset = this.get(name);

		if (!asset) {
			return;
		}

		if (locale && asset.isLocale) {
			this.togglePlayLocale(asset, locale);
			return callback(asset);
		}

		if (asset.playing) {
			asset.howl.stop();
		} else {
			asset.howl.play();
		}

		if (callback) {
			return callback(asset);
		}
	}

	unload(name) {
		const asset = this.get(name);

		if (!asset) {
			return;
		}

		if (asset.isLocale) {
			Reflect.ownKeys(asset.howl).forEach(localeKey => {
				asset.howl[localeKey].unload();
			});
		} else {
			asset.howl.unload();
		}

		asset.loaded = false;
		delete this.assets[asset.name];
	}

	stopAll() {
		Reflect.ownKeys(this.assets).forEach(name => {
			const asset = this.assets[name];
			asset.stop();
		});
	}

	onPlay(asset) {
		if (asset.onPlay instanceof Function) {
			asset.onPlay();
		}
	}

	onPause(asset) {
		if (asset.onPause instanceof Function) {
			asset.onPause();
		}
	}

	onStop(asset) {
		if (asset.onStop instanceof Function) {
			asset.onStop();
		}
	}

	onEnd(asset) {
		if (asset.onEnd instanceof Function) {
			asset.onEnd();
		}
	}
}

export default new AudioPlayer();
