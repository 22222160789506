import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useEffect} from 'react';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';
import SpellingClinicInput from './SpellingClinicInput';
import PropTypes from 'prop-types';
import {sleep} from '@reading/r180/src/utils/sleep';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));
const SpellingClinicItem = props => {
	const {
		wordsList,
		configData,
		staticMode,
		activeIndex,
		finalScreen,
		finalReport,
		showIcon,
		showAudio,
		maxStudyWords,
		enableGoOn,
		setGoOnDisabled
	} = props;
	const classes = useStyles();
	const wrapperRef = useRef();
	const [playerIndex, setPlayerIndex] = useState(0);

	/*istanbul ignore next*/
	const initialize = async () => {
		if (!staticMode) {
			await sleep(1000);
			AudioPlayer2.stopAll();
			const currentWord = wordsList[playerIndex].text.toLowerCase();
			const audioPlayer = AudioPlayer2.load({
				name: `strName${currentWord}`,
				src: bulkReplace(configData.WORDS_VO, {
					currentWord
				})
			});
			wrapperRef.current.children[playerIndex].classList.add('hasFocus');
			audioPlayer.play(`strName${currentWord}`);
			/*istanbul ignore next*/

			audioPlayer.onEnd = async () => {
				await sleep(1200);
				wrapperRef.current &&
					wrapperRef.current.children[playerIndex].classList.remove(
						'hasFocus'
					);

				let newIndex = playerIndex + 1;
				if (newIndex < configData.MAX_WORDS) {
					setPlayerIndex(newIndex);
				} else {
					if (typeof setGoOnDisabled === 'function') {
						setGoOnDisabled();
					}
					if (enableGoOn) {
						enableGoOn(false);
					}
				}
			};
		} else {
			if (!finalScreen && activeIndex < maxStudyWords) {
				wrapperRef.current.children[activeIndex].classList.add(
					'hasFocus'
				);
				await sleep(1200);
			} else if (wrapperRef.current) {
				Array.from(wrapperRef.current.children).forEach(i =>
					i.classList.remove('hasFocus')
				);
			}
		}
	};

	/*istanbul ignore next*/
	useEffect(() => {
			initialize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playerIndex]);

	return (
		<div className={classes.container} ref={wrapperRef}>
			{wordsList &&
				wordsList.map((item, index) => (
					<SpellingClinicInput
						item={item}
						key={index}
						index={index}
						finalReport={finalReport}
						staticMode={staticMode}
						showIcon={
							!showIcon ? showIcon : activeIndex >= index + 1
						}
						showAudio={showAudio}
						activeIndex={activeIndex}
						finalScreen={finalScreen}
					/>
				))}
		</div>
	);
};

SpellingClinicItem.defaultProps = {
	staticMode: false,
	finalScreen: false,
	maxStudyWords: 3
};
SpellingClinicItem.propTypes = {
	staticMode: PropTypes.bool,
	finalScreen: PropTypes.bool,
	maxStudyWords: PropTypes.number
};

export default React.memo(SpellingClinicItem);
