/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import HMHRedux from '../redux-util';

// slice constants
const sliceName = 'userContext';

// slice actions
export const getUserContextAction = HMHRedux.async(sliceName, 'getUserContext');
export const storeUserContextAction = HMHRedux.async(sliceName, 'storeUserContext');

// slice reducers
export const userContextSlice = HMHRedux.createSlice({
	name: sliceName,
	logoutAction: 'session/logout',
	initialState: {
		sifToken: process.env.REACT_APP_SIF_TOKEN,
		assignmentId: '',
		activityId: process.env.REACT_APP_CUSTOM_ACTIVITY_ID,
		correlationId: process.env.REACT_APP_CUSTOM_CORRELATION_ID
	},
	reducers: {},
	extraReducers: {
		[getUserContextAction.fulfilled]: (state, action) => {
			state.sifToken = action.payload.data.sif_token;
			state.assignmentId = action.payload.data.assignment_id;
			state.activityId = action.payload.data.activity_id;
			state.correlationId = action.payload.data.correlation_id;
		},
		[storeUserContextAction.fulfilled]: (state, action) => {
			state.sifToken = action.payload.data.sif_token;
			state.assignmentId = action.payload.data.assignment_id;
			state.activityId = action.payload.data.activity_id;
			state.correlationId = action.payload.data.correlation_id;
		}
	}
});

export default userContextSlice.reducer;
