import React, {useState} from 'react';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import Navbar from '../../containers/App/Navbar';
import {useHistory} from 'react-router-dom';
import {ActivityInstructionButton, SubzoneSelector} from '@reading/common';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import {makeStyles} from '@material-ui/core';
import {transitionToTask} from '../../utils/navigation';
import {useDispatch, useSelector} from 'react-redux';
import {capitalizeWord} from '../../utils/stringUtils';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {Writing} from './Writing';
import useIdleHelp from '../../utils/useIdleHelp';
import {uiSlice} from '../../store/slices/ui';
import {sleep} from '../../utils/sleep';
import {isEmpty} from 'lodash';

const useStyle = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		marginTop: '80px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'top',
		justifyContent: 'center',
		flexWrap: 'wrap'
	}
}));

const WritingSelectorActivity = props => {
	const classes = useStyle();
	const history = useHistory();
	const dispatch = useDispatch();

	const {activityData, settings} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			settings: state.session.session.settings
		};
	});

	const isActivity = activityData
		? activityData.activityServerId === 'writing' || activityData.activityServerId === 'writing_landing'
		: false;

	const [selectorInfo, setSelectorInfo] = useState([]);
	const [isSoundPlaying, setSoundPlaying] = useState(true);

	useDeepCompareEffect(() => {
		const loadSounds = async () => {
			const selectorData = await Writing.initSubmenu(
				activityData.stage,
				activityData.segment,
				activityData.level
			);
			setSelectorInfo(selectorData);
			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				await AudioPlayer2.playSync(Writing.WRITING_SUBMENU_INTRO);
			}
			setSoundPlaying(false);
		};

		if (isEmpty(activityData) === false && isActivity) {
			loadSounds();
		}
	}, [activityData]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	const handleClick = submenu => {
		transitionToTask(history, `/zone/writing/writing-${submenu.choice}`);
	};

	useIdleHelp(Writing.WRITING_SUBMENU_HELP);

	return (
		<ActivitySuspense showSpinner={false} requiredRenderData={selectorInfo} title={'Writing'}>
			<Navbar helpSoundUrl={Writing.WRITING_SUBMENU_HELP} />

			<div className={classes.wrapper}>
				<ActivityInstructionButton audioSrc={Writing.WRITING_SUBMENU_INTRO} withoutFrame={true} />
				{selectorInfo[0] && (
					<SubzoneSelector
						className="mr-3"
						title={capitalizeWord(selectorInfo[0].choice)}
						onClick={() => {
							handleClick(selectorInfo[0]);
						}}
						zone="writing"
						showInfoButton={true}
						sound={{
							name: 'submenu1',
							src: Writing.WRITING_SUBMENU_SOUND_MAP[selectorInfo[0].choice]
						}}
						text={selectorInfo[0].passageData.questionPrompt}
						isComplete={isSoundPlaying}
					/>
				)}

				{selectorInfo[1] && (
					<SubzoneSelector
						className="ml-3"
						title={capitalizeWord(selectorInfo[1].choice)}
						onClick={() => {
							handleClick(selectorInfo[1]);
						}}
						zone="writing"
						showInfoButton={true}
						sound={{
							name: 'submenu2',
							src: Writing.WRITING_SUBMENU_SOUND_MAP[selectorInfo[1].choice]
						}}
						text={selectorInfo[1].passageData.questionPrompt}
						isComplete={isSoundPlaying}
					/>
				)}

				{selectorInfo[2] && (
					<SubzoneSelector
						className="ml-3"
						title={capitalizeWord(selectorInfo[2].choice)}
						onClick={() => {
							handleClick(selectorInfo[2]);
						}}
						zone="writing"
						showInfoButton={true}
						sound={{
							name: 'submenu2',
							src: Writing.WRITING_SUBMENU_SOUND_MAP[selectorInfo[2].choice]
						}}
						text={selectorInfo[2].passageData.questionPrompt}
						isComplete={isSoundPlaying}
					/>
				)}
			</div>
		</ActivitySuspense>
	);
};

export default WritingSelectorActivity;
