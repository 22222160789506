import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { sleep } from '@reading/r180/src/utils/sleep';
import { isFirefox } from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '240px',
        height: '588px',
		background: '#FFFFEB',
        borderRadius: '4px',
		marginLeft: '80px',
		marginTop: '30px',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		overflow: 'hidden',
		transition: 'height ease 0.7s',

		'&.collapsed': {
			height: '48px'
		}
    },

    title: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '9px 24px',
        alignItems: 'center',
        background: '#FFFCA1',
        borderRadius: '4px 4px 0 0',
        boxShadow: '0 2px 4px rgba(0,0,0,0.12)',
        zIndex: 9,

        '& h3': {
            margin: 0,
            lineHeight: '30px',
			fontWeight: '700',
			fontSize: '24px',
			letterSpacing: '0.15px'
        }
    },

	collapse: {
		'&:hover': {
			transform: 'scale(1.2)'
		}
	},

    content: {
        height: '540px',
        background: theme.colors.white,
		overflowY: isFirefox() ? 'auto' : 'overlay',
		'&::-webkit-scrollbar': {
			width: '6px'
		},

		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			padding: '10px 0'
		},

		'&::-webkit-scrollbar-thumb': {
			background: '#CCC',
			borderRadius: '8px'
		},
    },

    section: {
        padding: '16px',
        borderLeft: '2px solid transparent',
		color: theme.colors.grey,
		background: '#FFFFEB',
		transition: 'background 0.3s ease',

        '& .title': {
            textTransform: 'uppercase',
            textDecoration: 'underline',
            fontSize: '16px',
            fontWeight: 'bold',
            margin: '0 0 16px',
        },

        '& .notes': {
            '& .note': {
                fontSize: '16px',
                marginBottom: '16px',
				lineHeight: '24px'
            }
        },

        '&.active': {
            borderLeft: `4px solid ${theme.colors.softBlack}`,
            background: theme.colors.white,
			color: theme.colors.black
        },
    }
}));

const NotesPad = props => {
    const {
        className,
        notes,
        activeIndex
    } = props;

    const classes = useStyles();
	const ref = useRef();

	const [isCollapsed, setCollapsed] = useState(false);

	useEffect(() => {
		const scroll = async() => {
			await sleep(100);
			const noteScroll = document.getElementsByClassName('scrollable-note-area');
			if (noteScroll.length > 0) {
				document.getElementsByClassName('scrollable-note-area')[0].scrollTo({
					left: 0,
					top: ref.current.offsetTop - ref.current.offsetHeight,
					behavior: 'smooth'
				});
			}
		};
		scroll();
	}, [activeIndex]);

	const handleCollapse = () => {
		setCollapsed(!isCollapsed);
	};

    const renderNotes = (notes) => {
        return notes.map(note => (
            <div className="note" key={note.title}>
                <strong>{note.title}: </strong>
                {note.notes}
            </div>
        ))
    };

    const renderSection = (sections) => {
        return sections.map((item, i) => {
            const isActive = activeIndex === i;

            return (
                <section className={`${classes.section} ${isActive && 'active'}`} ref={isActive ? ref : null} key={item.title}>
                    <h4 className="title">{item.title}</h4>
                    <div className="notes">
                        {renderNotes(item.sections)}
                    </div>
                </section>
            )
        })
    };

    return (
        <div className={`notes-pad ${classes.wrapper} ${isCollapsed ? 'collapsed' : ''} ${className}`}>
            <div className={classes.title}>
                <h3>Notes</h3>
                <button className={classes.collapse} onClick={handleCollapse}>
                    <i className={`r180 ${isCollapsed ? 'expands' : 'collapse'}`} />
                </button>
            </div>

            <div className={`${classes.content} scrollable-note-area`}>
                {!isEmpty(notes) && renderSection(notes)}
            </div>
        </div>
    );
};

NotesPad.defaultProps = {
    className: '',
    notes: [],
	activeIndex: ''
};
NotesPad.propTypes = {
    className: PropTypes.string,
    notes: PropTypes.array,
    activeIndex: PropTypes.number
};

export default React.memo(NotesPad);
