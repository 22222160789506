import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	ClickAwayListener,
	Fade,
	Grid,
	Paper,
	Popper,
	Typography
} from '@material-ui/core';
import { useSetState, Languages } from '@reading/common';
import { withStyles } from '@material-ui/core/styles';

import CaptionButton from '../VideoPlayer/CaptionButton';
import FullScreenIconButton from '../VideoPlayer/FullScreenIconButton';
import PlaySwitch from '../VideoPlayer/PlaySwitch';
import SeekController from '../VideoPlayer/SeekController';
import FastSeekButton from '../VideoPlayer/FastSeekButton';
import RewindSeekButton from '../VideoPlayer/RewindSeekButton';
import ScreenLoader from '../VideoPlayer/ScreenLoader';
import ReplayButton from '../VideoPlayer/ReplayButton';
import formatTime from './utils/formatTime';
import Video from './Video';
import { styles } from './AnchorVideo.style';
import { isSafari } from '@reading/r180/src/utils/browsers';

const AnchorVideo = props => {
	const {
		classes,
		id,
		children,
		containerStyle,
		controls,
		ended,
		handleVideoEnded,
		handleCloseButton,
		time,
		video_end_time,
		pause,
		play,
		poster,
		vol,
		captionButtonEnabled,
		fullScreenButtonEnabled,
		videoKey,
		autoPlay,
		onVideoPlay,
		onVideoPause,
		hideFwdButton,
		hideRewindButton,
		hideCaptionButton,
		hideReplayButton,
		hideVideoTimeDuration,
		hideCloseButton,
		compact
	} = props;

	const initialSettings = {
		currentTime: time || '',
		remainingTime: time || '',
		playing: play,
		volume: vol,
		isEnded: ended,
		duration: 0,
		seeking: false,
		seeked: false,
		canPlay: false,
		isCaptionToggleOn: false,
		anchorEl: null,
		caption: Languages.English,
		isMetaDataLoaded: false,
		isfullScreenModeEnabled: false,
		hideReplayBtn: true
	};
	const [
		{
			currentTime,
			remainingTime,
			playing,
			volume,
			isEnded,
			duration,
			isCaptionToggleOn,
			anchorEl,
			caption,
			canPlay,
			isMetaDataLoaded,
			hideReplayBtn,
			isfullScreenModeEnabled
		},
		setState
	] = useSetState(initialSettings);
	let video = useRef(null);
	let videoContainerRef = useRef(null);
	// const classes = styles();

	/* istanbul ignore next */
	useEffect(() => {
		pause ? video.current.pause() : video.current.play();
		play && video.current.play();
		if (time) {
			setVideoCurrentTime();
		}
	}, [pause, play, time]);
	/* istanbul ignore next */
	useEffect(() => {
		video.current.volume = initialSettings.volume;
		video.current.addEventListener('loadedmetadata', event => {
			setState({ isMetaDataLoaded: true });
			if (isSafari && time) {
				setVideoCurrentTime();
			}
		});
		document.addEventListener('fullscreenchange', onExitFullScreenHandler);
		document.addEventListener(
			'webkitfullscreenchange',
			onExitFullScreenHandler
		);
		document.addEventListener(
			'mozfullscreenchange',
			onExitFullScreenHandler
		);
		document.addEventListener(
			'MSFullscreenChange',
			onExitFullScreenHandler
		);

		if (video_end_time) {
			video.current.addEventListener('timeupdate', closeVideoOnEndTime);
		}
	}, []);
	/* istanbul ignore next */
	const closeVideoOnEndTime = e => {
		if (video.current) {
			if (video.current.currentTime >= video_end_time) {
				video.current.pause();
				video.current.removeEventListener(
					'timeupdate',
					closeVideoOnEndTime
				);
				if (typeof handleVideoEnded === 'function') {
					handleCloseButtonClick(e, video.current);
				}
			}
		}
	};
	/* istanbul ignore next */
	const onExitFullScreenHandler = () => {
		if (
			!document.fullscreenElement &&
			!document.webkitIsFullScreen &&
			!document.mozFullScreen &&
			!document.msFullscreenElement
		) {
			setState({ isfullScreenModeEnabled: false });
		} else {
			setState({ isfullScreenModeEnabled: true });
		}
	};

	/** set current time for video */
	const setVideoCurrentTime = () => {
		video.current.currentTime = time;
		video.current.remainingTime = duration - time;
	};

	const onPlay = () => {
		onVideoPlay(video.current);
		setState({ playing: true });
	};
	const onPause = () => {
		onVideoPause(video.current);
		setState({ playing: false });
	};
	const onEnded = e => {
		if (!hideCloseButton) {
			handleExitFullScreen();
		}
		if (typeof handleVideoEnded === 'function') {
			handleVideoEnded(e, video.current);
		}
		setState({ isEnded: true });
		setState({ hideReplayBtn: hideReplayButton });
	};
	const handleOnContextMenu = event => event.preventDefault();
	const onSeeking = () => setState({ seeking: true, seeked: false });
	const onSeeked = () => setState({ seeking: false, seeked: true });
	const onCanPlay = () => setState({ canPlay: true });
	const handleMetaData = event => {
		setState({ duration: event.target.duration });
	};
	const handleTimeUpdate = event => {
		const currentTime = event.target.currentTime;
		setState({
			currentTime: currentTime,
			remainingTime: duration - currentTime
		});
	};
	const handlePlaySwitch = useCallback(() => {
		playing ? video.current.pause() : video.current.play();
	}, [playing]);

	const handleFullScreen = useCallback(() => {
		const videoEl = videoContainerRef.current;
		var requestFullScreen =
			videoEl.requestFullscreen ||
			videoEl.msRequestFullscreen ||
			videoEl.mozRequestFullScreen ||
			videoEl.webkitRequestFullscreen;
		(async () => {
			await requestFullScreen.call(videoEl);
		})().catch(() => { });
	}, [video]);

	const handleExitFullScreen = useCallback(() => {
		const videoEl = document;
		var requestExitFullScreen =
			videoEl.exitFullscreen ||
			videoEl.msExitFullscreen ||
			videoEl.webkitExitFullscreen;
		(async () => {
			await requestExitFullScreen.call(videoEl);
		})().catch(() => { });
	}, [video]);

	const handleSeekChange = useCallback(
		val => {
			video.current.currentTime = (val / 100) * duration;
			video.current.remainingTime = duration - video.current.currentTime;
		},
		[duration]
	);
	const handleCaptionsChange = useCallback(
		event => {
			setState({ anchorEl: event.currentTarget, isCaptionToggleOn: true });
		},
		[setState]
	);
	const handleCaptionList = el => {
		[...video.current.textTracks].forEach(track => {
			if (track.language === el.currentTarget.value) {
				setState({ caption: el.currentTarget.value });
				track.mode = 'showing';
			} else {
				track.mode = 'hidden';
			}
		});
	};
	const handleCaptionToggle = () => {
		setState({ caption: 'off' });
		[...video.current.textTracks].forEach(track => {
			track.mode = 'hidden';
		});
	};
	const handleClickAway = () => setState({ isCaptionToggleOn: false });
	const renderButtons = () => {
		return [...video.current.textTracks].map(track => (
			<Button
				key={track.language}
				value={track.language}
				aria-label="HandleCaptionList"
				color={caption === track.language ? 'secondary' : 'default'}
				onClick={handleCaptionList}
			>
				{track.language}
			</Button>
		));
	};
	const handleFastRewind = () => {
		if (video.current.currentTime >= 5) {
			video.current.currentTime = video.current.currentTime - 5;
			video.current.remainingTime = video.current.remainingTime + 5;
		}
	};
	const handleFastForward = () => {
		if (video.current.currentTime < duration - 5) {
			video.current.currentTime = video.current.currentTime + 5;
			video.current.remainingTime = video.current.remainingTime - 5;
		}
	};
	const handleReplay = e => {
		video.current.play();
		setState({ hideReplayBtn: true });
	};
	const handleCloseButtonClick = e => {
		if (video_end_time) {
			video.current.removeEventListener(
				'timeupdate',
				closeVideoOnEndTime
			);
		}
		handleExitFullScreen();
		if (typeof handleCloseButton === 'function') {
			handleCloseButton(e, video.current);
		}
	};
	const captionToggleId = isCaptionToggleOn ? 'simple-popper' : null;
	const controllerStyle = controls ? {} : { display: 'none' };
	const playSwitchStyle = {};
	const fwdButtonStyle = hideFwdButton ? { display: 'none' } : {};
	const rewindButtonStyle = hideRewindButton ? { display: 'none' } : {};
	const captionButtonStyle = hideCaptionButton ? { display: 'none' } : {};
	const videoTimeDurationStyle =
		hideVideoTimeDuration || compact ? { display: 'none' } : {};
	const replayButton = hideReplayBtn
		? { display: 'none' }
		: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center'
		};
	return (
		<div
			id={id}
			style={containerStyle}
			ref={videoContainerRef}
			className={
				id !== 'videoContainer'
					? classes.videoHintContainer
					: classes.videoContainer
			}
		>
			<Grid
				container
				justify="center"
				alignItems="center"
				spacing={0}
				className={
					id !== 'videoContainer'
						? isfullScreenModeEnabled
							? classes.videoHintContainerFullScreen
							: classes.videoHintContainer
						: isfullScreenModeEnabled
							? classes.videoContainerFullScreen
							: classes.videoContainer
				}
			>
				<Grid container item spacing={0}>
					<Grid
						container
						spacing={0}
						onClick={
							!isMetaDataLoaded ? () => { } : handlePlaySwitch
						}
						className={classes.container}
					>
						<Video
							video={video}
							handleContextMenu={handleOnContextMenu}
							handleTimeUpdate={handleTimeUpdate}
							handleMetaData={handleMetaData}
							onCanPlay={onCanPlay}
							onSeeked={onSeeked}
							onPlay={onPlay}
							onPause={onPause}
							onEnded={onEnded}
							onSeeking={onSeeking}
							poster={poster}
							videoKey={videoKey}
							autoPlay={autoPlay}
							isZoom={!hideCaptionButton}
						>
							{children}
						</Video>
					</Grid>
				</Grid>
				<Grid
					container
					item
					xs={12}
					direction="row"
					justify="space-around"
					alignItems="center"
					spacing={0}
					className={
						id !== 'videoContainer'
							? isfullScreenModeEnabled
								? classes.controllerHintFullScreenBG
								: classes.controllerBG
							: isfullScreenModeEnabled
								? classes.controllerFullScreenBG
								: classes.controllerBG
					}
					style={controllerStyle}
					data-testid="Controller"
				>
					<Grid
						container
						item
						direction="row"
						justify="flex-start"
						alignItems="center"
						xs={compact ? 3 : 2}
					>
						<PlaySwitch
							playing={playing}
							onIconButtonClick={handlePlaySwitch}
							iconButtonStyle={playSwitchStyle}
						/>

						<RewindSeekButton
							handleFastRewind={handleFastRewind}
							iconButtonStyle={rewindButtonStyle}
						/>

						<FastSeekButton
							disabled={!isEnded}
							handleFastForward={handleFastForward}
							iconButtonStyle={fwdButtonStyle}
						/>
					</Grid>
					<Grid
						container
						item
						direction="row"
						justify="space-evenly"
						alignItems="center"
						xs={compact ? 6 : 8}
					>
						<Grid
							item
							xs={1}
							justify="center"
							container
							className={classes.leftVideoTime}
							style={{ ...videoTimeDurationStyle }}
						>
							<Typography
								variant="overline"
								className={classes.timeFont}
							>
								{formatTime(currentTime)}
							</Typography>
						</Grid>
						<Grid
							item
							xs={compact ? 12 : 6}
							className={classes.silder}
							container
						>
							<SeekController
								disabled={!isEnded}
								sliderValue={currentTime / duration}
								onSlide={handleSeekChange}
							/>
						</Grid>
						<Grid
							item
							container
							xs={1}
							justify="center"
							className={classes.rightVideoTime}
							style={{ ...videoTimeDurationStyle }}
						>
							<Typography
								align="center"
								className={classes.timeFont}
								variant="overline"
							>
								{formatTime(remainingTime)}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						item
						direction="row"
						justify="flex-end"
						alignItems="center"
						xs={compact ? 3 : 2}
					>
						{captionButtonEnabled && (
							<ClickAwayListener onClickAway={handleClickAway}>
								<CaptionButton
									data-testid={'CaptionButton'}
									caption={caption}
									handleCaptions={handleCaptionsChange}
									isCaptionToggleOn={isCaptionToggleOn}
									iconButtonStyle={captionButtonStyle}
								/>
							</ClickAwayListener>
						)}
						<Popper
							transition
							open={isCaptionToggleOn}
							anchorEl={anchorEl}
							id={captionToggleId}
							data-testid="Captions Selection"
							placement="top"
							disablePortal
							style={{
								zIndex: 99999
							}}
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Paper
										style={{
											marginBottom: '10px',
											display: 'flex',
											flexDirection: 'column'
										}}
									>
										<Button
											data-testid="HandleCaptionToggle"
											aria-label="Caption Off"
											onClick={handleCaptionToggle}
										>
											{video.current.textTracks.length > 0
												? 'off'
												: 'no caption'}
										</Button>
										{renderButtons()}
									</Paper>
								</Fade>
							)}
						</Popper>
						{!isfullScreenModeEnabled
							? fullScreenButtonEnabled && (
								<FullScreenIconButton
									onIconButtonClick={handleFullScreen}
									iconStyle={'full-screen'}
								/>
							)
							: fullScreenButtonEnabled && (
								<FullScreenIconButton
									onIconButtonClick={handleExitFullScreen}
									iconStyle={'exit-full-screen'}
								/>
							)}
					</Grid>
					{!isMetaDataLoaded && <ScreenLoader />}
				</Grid>
			</Grid>

			<Grid
				id="replayButton"
				style={replayButton}
				container
				direction="row"
				alignItems="center"
				justify="center"
			>
				<ReplayButton
					icon={'reset'}
					onClick={handleReplay}
				></ReplayButton>
			</Grid>
			{!hideCloseButton && (
				<Grid
					container
					direction="row"
					alignItems="center"
					justify="center"
				>
					<button
						type="button"
						id="closeButton"
						className={`${classes.closeButton}`}
						onClick={handleCloseButtonClick}
					>
						<i className={`r180 close-alt closeIcon`} />
					</button>
				</Grid>
			)}
		</div>
	);
};

export default withStyles(styles)(AnchorVideo);

AnchorVideo.defaultProps = {
	id: 'videoContainer',
	children: React.node,
	containerStyle: {},
	controls: true,
	ended: false,
	handleVideoEnded: () => { },
	handleCloseButton: () => { },
	time: 0,
	pause: false,
	play: false,
	poster: '',
	vol: 0.5,
	captionButtonEnabled: true,
	fullScreenButtonEnabled: true,
	videoKey: '',
	autoPlay: true,
	onVideoPlay: () => { },
	onVideoPause: () => { },
	hideFwdButton: false,
	hideRewindButton: false,
	hideCaptionButton: false,
	hideReplayButton: false,
	hideVideoTimeDuration: false,
	hideCloseButton: true,
	compact: false
};

AnchorVideo.propTypes = {
	id: PropTypes.string,
	children: PropTypes.node,
	containerStyle: PropTypes.object,
	controls: PropTypes.bool,
	ended: PropTypes.bool,
	handleVideoEnded: PropTypes.func,
	handleCloseButton: PropTypes.func,
	time: PropTypes.number,
	video_end_time: PropTypes.number,
	pause: PropTypes.bool,
	play: PropTypes.bool,
	poster: PropTypes.string,
	vol: PropTypes.number,
	captionButtonEnabled: PropTypes.bool,
	fullScreenButtonEnabled: PropTypes.bool,
	videoKey: PropTypes.string,
	autoPlay: PropTypes.bool,
	onVideoPlay: PropTypes.func,
	onVideoPause: PropTypes.func,
	hideFwdButton: PropTypes.bool,
	hideRewindButton: PropTypes.bool,
	hideCaptionButton: PropTypes.bool,
	hideReplayButton: PropTypes.bool,
	hideVideoTimeDuration: PropTypes.bool,
	hideCloseButton: PropTypes.bool,
	compact: PropTypes.bool
};
