import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';

const styles = theme => ({
	root: {
		color: theme.colors.paleGrey,
		'&:hover,&:focus': {
			color: theme.colors.readingZone.primary
		}
	}
});

function FullScreenIconButton({
	classes,
	iconButtonStyle,
	iconDefaultStyle,
	iconStyle,
	onIconButtonClick
}) {
	return (
		<IconButton
			aria-label="Full-screen"
			className={classes.root}
			color="inherit"
			style={iconButtonStyle}
			onClick={onIconButtonClick}
		>
			<i className={`r180 ${iconStyle}`} style={{ fontSize: '24px' }}></i>
		</IconButton>
	);
}

FullScreenIconButton.defaultProps = {
	classes: {},
	iconButtonStyle: {},
	iconDefaultStyle: {
		color: 'inherit',
		fontSize: 'inherit'
	},
	iconStyle: {}
};
FullScreenIconButton.propTypes = {
	classes: PropTypes.object,
	iconButtonStyle: PropTypes.object,
	iconDefaultStyle: PropTypes.object,
	iconStyle: PropTypes.string,
	onIconButtonClick: PropTypes.func.isRequired
};

export default memo(withStyles(styles)(FullScreenIconButton));
