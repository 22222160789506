import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax } from 'gsap';
import clsx from 'clsx';
import { AudioPlayer2 } from '@shared/core';

import { useStyle } from './FeedbackSpell.style';
import {
	useSpellingCorrectiveFeedbackDispatch,
	usePreloader
} from './SpellingCorrectiveFeedbackContext';

import {
	SpellingCorrectiveFeedbackPreloaderStatus,
	SpellingCorrectiveFeedbackText,
	SpellingCorrectiveFeedbackDecoder
} from './constants';

import { FeedbackSpellUtil } from './FeedbackSpellUtil';

const FeedbackSpell = (props) => {

	const { wordText, onComplete, spellLetter, spellWord, highlightSpecificWord, IsCorrect, decodingInfo, feedbackData } = props;
	decodingInfo.segments = wordText;
	const wordSpell = FeedbackSpellUtil.wordSpellDecoderForSCF(decodingInfo);
	const wordTextSound = FeedbackSpellUtil.wordTextAudio(decodingInfo);
	const spellingCorrectiveFeedbackDispatch = useSpellingCorrectiveFeedbackDispatch();
	const preloader = usePreloader(SpellingCorrectiveFeedbackDecoder.SPELL);

	const normalColor = 'rgba(255, 175, 102, 0)';
	const highlightColor = 'rgba(255, 175, 102, 1)';
	const tempHighlightColor = 'rgba(145, 124, 123, 1)';

	const timelineRef = useRef(null);
	const spritesRef = useRef(null);

	const classes = useStyle({ normalColor });

	const init = useCallback(() => {
		const spellSounds = wordSpell.filter(
			w => w.type === SpellingCorrectiveFeedbackText.NORMAL
		);

		if (!preloader) {
			spellingCorrectiveFeedbackDispatch({
				type: '@@spelling-feedback/preload-sound',
				payload: {
					name: SpellingCorrectiveFeedbackDecoder.SPELL,
					assets: [wordTextSound, ...spellSounds]
				}
			});
		}
	}, [wordSpell, preloader, spellingCorrectiveFeedbackDispatch, wordTextSound]);

	const start = () => {
		timelineRef.current.play();
	};

	const playSound = useCallback(sound => () => AudioPlayer2.play(sound), []);

	const destroy = useCallback(() => {
		timelineRef.current && timelineRef.current.pause();
		timelineRef.current && timelineRef.current.clear();
		timelineRef.current && timelineRef.current.kill();
		timelineRef.current = null;
	}, []);

	const addEnterStageScene = useCallback(
		timeline => {
			const sprites = wordSpell.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline
				.set(spritesRef.current, { opacity: 1 })
				.set(sprites, { opacity: 0 });
			const delay = 0.1;
			wordSpell.forEach((x, i) => {
				if (x.type === SpellingCorrectiveFeedbackText.NORMAL) {
					const sprite = spritesRef.current.querySelector(`#${x.id}`);
					timeline
						.set(sprite, { opacity: 0 })
						.set(sprite, { scale: 0 })
						.set(sprite, { x: 0 })
						.to(
							sprite,
							{
								transformOrigin: '50% 50%',
								x: 0,
								duration: FeedbackSpellUtil.getDuration(x.audioName),
								delay,
								opacity: 1,
								autoAlpha: 1,
								scale: 1,
								'background-color': normalColor,
								onStart: playSound(x.audioName)
							},
							x.id
						);
				}
			});
		},
		[highlightColor, normalColor, playSound, wordSpell]
	);

	const addReadWordTextScene = useCallback(
		timeline => {
			timeline
				.add(
					'ReadWordTextScene',
					`+=${FeedbackSpellUtil.getDuration(wordTextSound.audioName)}`
				)
				.set(spritesRef.current, { paddingLeft: 1, paddingRight: 1, opacity: 1, delay: 0 })
				.to(
					spritesRef.current,
					{
						duration: FeedbackSpellUtil.getDuration(
							wordTextSound.audioName
						),
						delay: 0,
						opacity: 1,
						'background-color': highlightColor,
						onStart: playSound(wordTextSound.audioName)
					},
					'ReadWordTextScene'
				);
		},
		[highlightColor, playSound, wordTextSound]
	);

	const addHighlightSpecificTextScene = useCallback(
		(timeline, playHighlightSpecificWord) => {
			let specificWordIndexArray = [];
			let startIndex = 0;
			let endIndex = 0;
			if (highlightSpecificWord.useIncorrectMarkupObj) {
				if (feedbackData.errors[highlightSpecificWord.errorCodeIndex].incorrectMarkup.includes("[")) {
					startIndex = feedbackData.errors[highlightSpecificWord.errorCodeIndex].incorrectMarkup.indexOf("[");
					endIndex = feedbackData.errors[highlightSpecificWord.errorCodeIndex].incorrectMarkup.indexOf("]") - 2;
				}

				if (feedbackData.errors[highlightSpecificWord.errorCodeIndex].incorrectMarkup.indexOf("[") + 1 === feedbackData.errors[highlightSpecificWord.errorCodeIndex].incorrectMarkup.indexOf("]")) {
					endIndex = startIndex;
				}

			}
			if (!highlightSpecificWord.useIncorrectMarkupObj) {
				if (feedbackData.errors[highlightSpecificWord.errorCodeIndex].correctMarkup.includes("[")) {
					startIndex = feedbackData.errors[highlightSpecificWord.errorCodeIndex].correctMarkup.indexOf("[");
					endIndex = feedbackData.errors[highlightSpecificWord.errorCodeIndex].correctMarkup.indexOf("]") - 2;
				}

				if (feedbackData.errors[highlightSpecificWord.errorCodeIndex].correctMarkup.indexOf("[") + 1 === feedbackData.errors[highlightSpecificWord.errorCodeIndex].correctMarkup.indexOf("]")) {
					endIndex = startIndex;
				}

			}

			for (var i = startIndex; i <= endIndex; i++) {
				specificWordIndexArray.push(i);
			}

			const sprites = wordSpell.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline
				.set(spritesRef.current, { opacity: 1 })
				.set(sprites, { opacity: 1 });
			const delay = 2;
			wordSpell.forEach((x, i) => {
				if (specificWordIndexArray.includes(i)) {
					const sprite = spritesRef.current.querySelector(`#${x.id}`);
					timeline
						.set(sprite, { opacity: 1 })
						.set(sprite, { scale: 1 })
						.set(sprite, { x: 0 })
						.set(sprite, { 'background-color': highlightColor })
						.set(sprite, { duration: 0 })
						.to(
							sprite,
							{
								delay,
								'background-color': highlightColor,
								onStart: playHighlightSpecificWord && playSound(x.audioName)
							},
							x.id
						);
				}

			});
		},
		[highlightColor, playSound, wordTextSound]
	);

	const addNormalizeWordTextScene = useCallback(
		timeline => {
			timeline.add('NormalizeWordTextScene', '+=0.2').to(
				spritesRef.current,
				{
					duration: 0.2,
					delay: 0.2,
					'background-color': normalColor
				},
				'NormalizeWordTextScene'
			);
		},
		[normalColor]
	);

	const setup = useCallback(() => {
		const timeline = new TimelineMax({ paused: true });
		if (spellLetter) {
			addEnterStageScene(timeline);
		}
		if (highlightSpecificWord.isTrue) {
			addHighlightSpecificTextScene(timeline, highlightSpecificWord.playHighlightSpecificWord);
		}
		if (spellWord) {
			addReadWordTextScene(timeline);
		}
		addNormalizeWordTextScene(timeline);
		timelineRef.current = timeline;
	}, [
		addEnterStageScene,
		addReadWordTextScene,
		addNormalizeWordTextScene,
		addHighlightSpecificTextScene
	]);

	useEffect(() => {
		if (wordSpell) {
			init();

			if (
				preloader &&
				preloader.status === SpellingCorrectiveFeedbackPreloaderStatus.COMPLETED
			) {
				setup();
				start();
			}
		}

		return () => {
			destroy();
		};
	}, [wordSpell, preloader, destroy, init, setup, start]);

	const renderWordSpell = () => {
		return (
			<div className="sprites">
				<div ref={spritesRef} className={classes.spriteWrapper}>
					{wordSpell &&
						wordSpell.map(x => (
							<div
								className={clsx({
									[classes.normalPartsWrapper]:
										x.type === SpellingCorrectiveFeedbackText.NORMAL,
									[classes.silentWrapper]:
										x.type === SpellingCorrectiveFeedbackText.SILENT,
									[classes.plusWrapper]:
										x.type === SpellingCorrectiveFeedbackText.SEPARATOR
								})}
								key={x.id}
								id={x.id}
							>
								<span className={`${IsCorrect ? 'correct' : 'incorrect'}`} >{x.text}</span>
							</div>
						))}
				</div>
			</div>
		);
	};

	return <div className={classes.wrapper}>{renderWordSpell()}</div>;
};

FeedbackSpell.defaultProps = {
	onComplete: () => { }
};

FeedbackSpell.propTypes = {
	onComplete: PropTypes.func
};

export default React.memo(FeedbackSpell);
