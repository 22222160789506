import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import SoundButton from '../SoundButton';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: '0px',
		display: 'grid',
		gridTemplateRows: `1fr`,
		gridTemplateColumns: `1fr`,
		gridGap: '4px',
		justifyItems: 'stretch',
		alignItems: 'center'
	},
	questionWrapper: {
		display: 'flex',
		flexDirection: 'row',
		fontSize: '16px',
		fontWeight: '400',
		lineHeight: '22px',
		marginBottom: '10px'
	},
	stemContent: {
		color: theme.colors.grey,
		fontFamily: theme.fonts.uiBold,
		marginLeft: '4px'
	},
	checkBoxWrapper: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '20px'
	},
	soundButton: {
		width: '32px'
	},
	buttonWrapper: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '10px',
		marginLeft: '10px'
	},
	radioButton: {
		background: theme.colors.white,
		border: `1px solid ${theme.colors.grey}`,
		height: '24px',
		width: '24px',
		borderRadius: '50%',
		position: 'relative',
		marginBottom: '5px',
		marginRight: '10px'
	},
	selection: {
		background: theme.colors.readingZone.primary,
		height: '0',
		width: '0',
		left: '12px',
		top: '12px',
		borderRadius: '50%',
		position: 'absolute',
		transition: 'all 0.2s'
	},
	selected: {
		height: '16px',
		width: '16px',
		left: '3.5px',
		top: '3px'
	}
}));

const ChallengeQuestion = props => {
	const {onAnswerChange, questions} = props;
	const classes = useStyles();
	const [selectedValue, setSelectedValue] = useState('');

	const handleSelect = (e, value) => {
		e.preventDefault();
		setSelectedValue(value);
		onAnswerChange(value);
	};

	return (
		<div className={classes.wrapper}>
			{questions.map((choice, index) => {
				return (
					<Fragment key={index}>
						{choice.stem.length > 0 && (
							<div className={classes.questionWrapper}>
								<div className={classes.soundButton}>
									<SoundButton
										border={false}
										sound={choice.sound}
										size={32}
										className={'transparent'}
									/>
								</div>
								<div className={classes.stemContent}>
									{stripHtmlTags(choice.stem)}
								</div>
							</div>
						)}
						<div className={classes.checkBoxWrapper}>
							<div className={classes.buttonWrapper}>
								<button
									data-testid={'challenge-accept'}
									className={classes.radioButton}
									onClick={e => handleSelect(e, 'YES')}
								>
									<div
										className={`${classes.selection} ${
											selectedValue === 'YES'
												? classes.selected
												: ''
										}`}
									></div>
								</button>
								<div>Yes</div>
							</div>

							<div className={classes.buttonWrapper}>
								<button
									data-testid={'challenge-decline'}
									className={classes.radioButton}
									onClick={e => handleSelect(e, 'NO')}
								>
									<div
										className={`${classes.selection} ${
											selectedValue === 'NO'
												? classes.selected
												: ''
										}`}
									></div>
								</button>
								<div>No</div>
							</div>
						</div>
					</Fragment>
				);
			})}
		</div>
	);
};

ChallengeQuestion.propTypes = {
	questions: PropTypes.array.isRequired,
	onAnswerChange: PropTypes.func.isRequired
};

export default ChallengeQuestion;
