import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import DragAndDrop from '../ClickAndStick/DragAndDrop';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: '-32px -40px -40px -40px',
		display: 'grid',
		gridTemplateRows: `1fr`,
		gridTemplateColumns: `1fr`,
		gridGap: '4px',
		justifyItems: 'stretch',
		alignItems: 'center',
		'& .spacer': {
			background: theme.colors.white
		}
	},
	dragAndDropContainer: {
		position: 'relative',
		display: 'flex',
		height: '465px',
		justifyContent: 'center'
	},
	orderedNumber: {
		height: '66px',
		width: '20px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontWeight: 700,
		margin: '24px',
		position: 'relative'
	},
	optionsContainer: {
		background: theme.colors.white,
		height: '88px',
		width: '360px',
		padding: '18px'
	},
	optionsText: {
		position: 'relative',
		display: 'block',
		textAlign: 'left',
		marginLeft: '10px',
		marginTop: '-10px',
		width: '85%',
		height: '66px',
		left: '5px',
		lineHeight: '24px'
	},
	numberSequences: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column'
	},
	orderingSequences: {
		position: 'relative',
		margin: '-12px'
	},
	preview: {
		background: theme.colors.readingZone.blue5,
		position: 'absolute',
		width: '347px',
		height: '78px',
		left: '50px',
		borderRadius: '8px',
		'&:after': {
			borderRadius: '8px',
			padding: '5px',
			border: `2px dashed ${theme.colors.readingZone.primary}`,
			position: 'absolute',
			content: '" "',
			top: '5px',
			left: '5px',
			bottom: '5px',
			right: '5px'
		}
	}
}));

const OrderingQuestion = props => {
	const {id, className, onAnswerChange, question, interactive} = props;
	const classes = useStyles();
	const items = question.map(answer => {
		return {
			id: `${answer.id}`,
			content: (
				<div className={classes.optionsContainer}>
					<span className={classes.optionsText}>
						{stripHtmlTags(answer.text)}
					</span>
				</div>
			),
			audio: answer.sound,
			status: answer.status,
			studentSequence: answer.studentSequence
		};
	});
	const generateOrderingSequences = () => {
		return question.map((_, index) => {
			let num;
			switch (index) {
				case 1: {
					num = '2nd';
					break;
				}
				case 2: {
					num = '3rd';
					break;
				}
				case 3: {
					num = '4th';
					break;
				}
				default: {
					num = '1st';
					break;
				}
			}
			return (
				<>
					<div key={index} className={classes.orderedNumber}>
						{num}
						<span className={classes.preview}></span>
					</div>
				</>
			);
		});
	};

	return (
		<div className={`${classes.wrapper} ${className}`} id={id}>
			<div className="spacer"></div>
			<div className={classes.dragAndDropContainer}>
				<div className={classes.numberSequences}>
					{generateOrderingSequences()}
				</div>
				<div className={classes.orderingSequences}>
					<DragAndDrop
						disableHelperBar={false}
						showSoundButton={true}
						isOrderingQuestion={true}
						onDragDrop={items => onAnswerChange(items)}
						bucketInfoList={[
							{
								type: 'source',
								header: '',
								menuText: 'Source 1'
							}
						]}
						bucketItemList={[items]}
						bucketStyleList={[
							{
								width: 376,
								height: 460
							}
						]}
						droppableStyleList={[
							{
								top: '0%',
								minHeight: '300px',
								minWidth: '300px',
								marginBottom: '24px'
							}
						]}
						interactive={interactive}
					></DragAndDrop>
				</div>
			</div>
		</div>
	);
};

OrderingQuestion.defaultProps = {
	id: '',
	className: '',
	question: {},
	interactive: true
};
OrderingQuestion.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	question: PropTypes.array.isRequired,
	onAnswerChange: PropTypes.func.isRequired,
	interactive: PropTypes.bool
};

export default OrderingQuestion;
