import React, {useEffect, useState, useRef} from 'react';
import {ActivityInstructionButton, ActivitySupportButton, StartActivity, WordList} from '@reading/common';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import {CaptionedAudioPlayer2 as AudioPlayer2, WordAssessment} from '@reading/common';
import useIdleHelp from '../../utils/useIdleHelp';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {uiSlice} from '../../store/slices/ui';
import {SpeedChallenge} from './SpeedChallenge';
import {cloneDeep, isEmpty, shuffle} from 'lodash';
import {getContentInfo} from '../../media/mediaUtil';
import media from '../../api/media';
import ActivityFrame from '../../containers/App/ActivityFrame';
import {sleep} from '../../utils/sleep';
import {transitionFromResultsPageToNextActivity} from '../../utils/navigation';
import useBatchedSetState from '../../utils/useBatchedSetState';

export default function SpeedChallengeActivity(props) {
	const {activityData, powerWordData, settings, reviewList, studyList, fluencyAssessment} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			powerWordData: state.activity.powerwords,
			settings: state.session.session.settings,
			reviewList: state.activity.reviewlist,
			studyList: state.activity.studylist,
			fluencyAssessment: state.activity.fluencyassessment
		};
	});

	const [contentData, setContentData] = useState({});

	useDeepCompareEffect(() => {
		const loadMedia = async () => {
			const {urlPath} = getContentInfo(activityData, true);

			if (urlPath !== '/') {
				const mediaData = await media.getOne(urlPath);
				setContentData(mediaData);
			}
		};

		if (isEmpty(activityData) === false) {
			loadMedia();
		}
	}, [activityData]);

	const dispatch = useDispatch();
	const history = useHistory();
	const currentIndex = useRef(0);
	const {setState} = useBatchedSetState();
	const configuredData = useRef({});

	// check if the activity is already charged
	const isActivityCharged = useSelector(state => {
		return state.ui.chargedActivities['speed-challenge'] === true;
	});

	const isActivity = activityData ? activityData.activityServerId === 'speed_challenge' : false;

	const [status, setStatus] = useState(FooterForwardBack.VALID);
	const [selectedWord, setSelectedWord] = useState('');
	const [showReviewPage, setShowReviewPage] = useState(false);
	const [showCircularTimer, setShowCircularTimer] = useState(true);
	const [isActivityPaused, setActivityPaused] = useState(false);
	const [isRecallIterationCompleted, setIsRecallIterationCompleted] = useState(false);
	const [timerValue, setTimerValue] = useState(15);
	const [recallValue, setRecallValue] = useState(1);
	const [recallMaxTrailRoundCount, setRecallMaxTrailRoundCount] = useState(0);
	const [currentTrialRoundCount, setCurrentTrialRoundCount] = useState(0);
	const [correctWordList, setCorrectWordList] = useState([]);
	const [incorrectWordList, setIncorrectWordList] = useState([]);
	const [wordList, setWordList] = useState([]);
	const [trialWordList, setTrialWordList] = useState([]);
	const [studyWordList, setStudyWordList] = useState([]);
	const [reviewWordList, setReviewWordList] = useState([]);
	const [currentTrailResponseMap, setCurrentTrailResponseMap] = useState([]);
	const [overallTrailResponseMap, setOverallTrailResponseMap] = useState([]);
	const [disableActivity, setDisableActivity] = useState(false);
	const [isStarted, setStarted] = React.useState(false);
	const [firstSound, setFirstSound] = useState('');
	const [isComplete, setComplete] = useState(false);

	useDeepCompareEffect(() => {
		const initialize = async () => {
			if (isActivityCharged === false) {
				dispatch(uiSlice.actions.setCharging());
			}

			const {studyWords, reviewWords} = await SpeedChallenge.init(
				activityData,
				studyList,
				reviewList,
				isActivityCharged,
				settings
			);
			setState(() => {
				setStudyWordList(studyWords);
				setReviewWordList(reviewWords);
				setShowCircularTimer(!settings.accuracyOnly ? true : false);
			});
			const recall = fluencyAssessment.recall === 0 ? 1 : fluencyAssessment.recall;
			loadNextRecallData(recall, studyWords, reviewWords);
			dispatch(uiSlice.actions.setActivityCharged('speed-challenge'));
			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				AudioPlayer2.play(SpeedChallenge.SOUND_INTRO);
			}
		};

		if (contentData && contentData.assessment_words && isActivity) {
			initialize();
		}

		/* eslint-disable */
	}, [activityData, contentData, powerWordData]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	useEffect(() => {
		if (isStarted) {
			AudioPlayer2.play(firstSound);
		}
	}, [isStarted]);

	const loadNextRecallData = (recall, studyWords, reviewWords) => {
		const {trialWords} = getSpellWords(recall, studyWords, reviewWords);
		setState(() => {
			setRecallMaxTrailRoundCount(trialWords.length);
			setRecallValue(recall);
			setTimerValue(
				settings.accuracyOnly
					? false
					: fluencyAssessment.medianResposeRateMillis / 1000 + SpeedChallenge.TIMER_VALUE[recall]
			);
			setTrialWordList(trialWords);
		});
		loadNextRoundOfTrial(trialWords);
	};

	const loadNextRoundOfTrial = (trialWords) => {	
		AudioPlayer2.stopAll();
		currentIndex.current++;
		const currentTrialWordObj = trialWords[currentTrialRoundCount];
		if(!currentTrialWordObj){
			return;
		}
		if (isStarted === true) {
			AudioPlayer2.play(currentTrialWordObj.wordId);
		} else {
			setFirstSound(currentTrialWordObj.wordId);
		}

		const nextTrialRoundCount = currentTrialRoundCount + 1;
		let currentTrialWordList = contentData.assessment_words
			.filter(word => word.text === currentTrialWordObj.wordId)[0]
			.wordZoneDistractors.map(obj => {
				return {name: obj};
			});

		currentTrialWordList = currentTrialWordList.slice(0, SpeedChallenge.TRIAL_SIZE);
		currentTrialWordList.push({name: currentTrialWordObj.wordId});
		const shuffledCurrentTrialWordList = shuffle(currentTrialWordList);
		setSelectedWord(currentTrialWordObj.wordId);

		if (nextTrialRoundCount === recallMaxTrailRoundCount) {
			setState(() => {
				setIsRecallIterationCompleted(true);
				setCurrentTrialRoundCount(0);
			});
		} else {
			setState(() => {
				setIsRecallIterationCompleted(false);
				setCurrentTrialRoundCount(nextTrialRoundCount);
			});
		}

		// If max trial count (40) reached then consider the recall iteration as completed.
		if (overallTrailResponseMap.length + 1 === SpeedChallenge.MAX_TRIAL_COUNT) {
			setState(() => {
				setIsRecallIterationCompleted(true);
				setCurrentTrialRoundCount(0);
			});
		}

		setWordList(shuffledCurrentTrialWordList);
	};

	const getSpellWords = (recall = 1, studyList = [], reviewList = []) => {
		const sequence = SpeedChallenge.SEQUENCE[recall];
		const seqLen = sequence.length;
		const totalWords = [...studyList, ...reviewList].length;
		const [studyShuffled, reviewShuffled] = [shuffle(studyList), shuffle(reviewList)];
		const wordsList = sequence
			.map((item, i) => {
				if (item === SpeedChallenge.STUDYLIST) return studyShuffled.splice(0, 1)[0];
				if (item === SpeedChallenge.REVIEWLIST) return reviewShuffled.splice(0, 1)[0];
			})
			.filter(item => item && item.wordId);
		return {
			trialWords: wordsList
		};
	};

	const isRecallCompleted = (newTrailResponseMap, newOverallTrailResponseMap) => {
		let isCompleted = false;

		if (SpeedChallenge.isCompletionPercentageCovered(newTrailResponseMap, studyWordList, recallValue)) {
			isCompleted = true;
		}

		// If max trial count (40) reached then consider the recall as completed.
		if (newOverallTrailResponseMap.length >= SpeedChallenge.MAX_TRIAL_COUNT) {
			isCompleted = true;
		}

		return isCompleted;
	};

	const isActivityCompleted = newCurrentTrailResponseMap => {
		let isCompleted = false;
		// If max recall trail is completed reached then consider the activity as completed.
		if (SpeedChallenge.MAX_RECAL_LEVEL === recallValue) {
			isCompleted = true;
		}

		// If max trial count (40) reached then consider the recall as completed.
		if (overallTrailResponseMap.length >= SpeedChallenge.MAX_TRIAL_COUNT) {
			isCompleted = true;
		}

		return isCompleted;
	};

	const handleForward = async e => {
		AudioPlayer2.stopAll();
		if (!showReviewPage) {
			setShowReviewPage(true);
			await sleep(800);
			AudioPlayer2.play(SpeedChallenge.SOUND_FEEDBACK);

			//send progress to server when activity is completed
			SpeedChallenge.completeActivityNoTransition(
				activityData,
				recallValue,
				fluencyAssessment,
				configuredData.current
			);
		} else {
			try {
				await transitionFromResultsPageToNextActivity(history);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		}
	};

	const handleWordClick = async e => {
		setDisableActivity(true);
		AudioPlayer2.stopAll();
		await AudioPlayer2.playSync(selectedWord);
		setDisableActivity(false);
	};

	const handleSentenceClick = async e => {
		setDisableActivity(true);
		AudioPlayer2.stopAll();
		await AudioPlayer2.playSync(`ctx-${selectedWord}`);
		setDisableActivity(false);
	};

	const handlePauseClick = e => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play('pause');
		setState(() => {
			setDisableActivity(false);
			setShowCircularTimer(false);
			setTimerValue(false);
			setActivityPaused(!isActivityPaused);
		});
		if (isActivityPaused) {
			setState(() => {
				setShowCircularTimer(true);
				setTimerValue(
					fluencyAssessment.medianResposeRateMillis / 1000 + SpeedChallenge.TIMER_VALUE[recallValue]
				);
			});
		}
	};

	const handleWordClickCallback = (clickedWord, isCorrect, ev) => {
		if (isCorrect) {
			AudioPlayer2.play('correct_spell');
		} else {
			AudioPlayer2.play('incorrect_spell');
		}

		const wordClickData = {
			isCorrect,
			prepareReviewPageData,
			isActivityCompleted,
			setShowReviewPage,
			setStatus,
			loadNextRoundOfTrial,
			trialWordList,
			isRecallIterationCompleted,
			isRecallCompleted,
			loadNextRecallData,
			recallValue,
			studyWordList,
			reviewWordList,
			clickedWord,
			activityData,
			fluencyAssessment,
			setComplete
		};
		handleSpeedChallengeWordClick(wordClickData);
	};

	const handleWordTimeoutCallback = () => {
		const wordClickData = {
			isCorrect: false,
			prepareReviewPageData,
			isActivityCompleted,
			setShowReviewPage,
			setStatus,
			loadNextRoundOfTrial,
			trialWordList,
			isRecallIterationCompleted,
			isRecallCompleted,
			loadNextRecallData,
			recallValue,
			studyWordList,
			reviewWordList,
			clickedWord: '',
			activityData,
			fluencyAssessment,
			setComplete
		};
		handleSpeedChallengeWordClick(wordClickData);
	};

	const handleSpeedChallengeWordClick = wordClickData => {
		const {
			isCorrect,
			prepareReviewPageData,
			isActivityCompleted,
			setShowReviewPage,
			setStatus,
			loadNextRoundOfTrial,
			trialWordList,
			isRecallIterationCompleted,
			isRecallCompleted,
			loadNextRecallData,
			recallValue,
			studyWordList,
			reviewWordList,
			clickedWord,
			activityData,
			fluencyAssessment,
			setComplete
		} = wordClickData;

		const {newCurrentTrailResponseMap, newOverallTrailResponseMap} = prepareReviewPageData(
			isCorrect,
			isRecallIterationCompleted,
			clickedWord
		);

		configuredData.current = newCurrentTrailResponseMap;

		if (isRecallIterationCompleted) {
			if (isRecallCompleted(newCurrentTrailResponseMap, newOverallTrailResponseMap)) {
				if (isActivityCompleted(newCurrentTrailResponseMap)) {
					setComplete(true);
				} else {
					// Move to next recall
					const nextRecallValue = recallValue + 1;
					loadNextRecallData(nextRecallValue, studyWordList, reviewWordList);
				}
			} else {
				// Reload the recall again
				loadNextRecallData(recallValue, studyWordList, reviewWordList);
			}
		} else {
			loadNextRoundOfTrial(trialWordList);
		}

		//save data
		SpeedChallenge.sendProgressToServer(activityData, recallValue, fluencyAssessment, newCurrentTrailResponseMap);
	};

	const prepareReviewPageData = (isCorrect, isRecallIterationCompleted, clickedWord) => {
		let newCurrentTrailResponseMap = cloneDeep(currentTrailResponseMap);
		const wordScore = isCorrect ? SpeedChallenge.FLUENT : SpeedChallenge.MISSED;
		newCurrentTrailResponseMap.push({
			wordId: selectedWord,
			isCorrect: isCorrect,
			recall: recallValue,
			answer: clickedWord,
			score: wordScore
		});
		if (isRecallIterationCompleted) {
			//clear the currentTrailResponseMap if the current iteration is completed
			setCurrentTrailResponseMap([]);
		} else {
			setCurrentTrailResponseMap(newCurrentTrailResponseMap);
		}

		let newOverallTrailResponseMap = cloneDeep(overallTrailResponseMap);
		newOverallTrailResponseMap.push({
			wordId: selectedWord,
			isCorrect: isCorrect,
			recall: recallValue,
			answer: clickedWord,
			score: wordScore
		});
		setOverallTrailResponseMap(newOverallTrailResponseMap);

		let newCorrectWordList = cloneDeep(correctWordList);
		let newIncorrectWordList = cloneDeep(incorrectWordList);
		if (isCorrect) {
			newCorrectWordList.push(selectedWord);
		} else {
			newIncorrectWordList.push(selectedWord);
		}
		setState(() => {
			setCorrectWordList(newCorrectWordList);
			setIncorrectWordList(newIncorrectWordList);
		});
		return {newCurrentTrailResponseMap, newOverallTrailResponseMap};
	};

	const renderViewBasedOnCondition = () => {
		return showReviewPage ? (
			<div className="mt-4" style={{margin: 'auto', textAlign: 'center', display: 'flex'}}>
				<div style={{display: 'inline-block', marginRight: '56px'}}>
					<WordList
						wordList={correctWordList.map((word, idx) => {
							return word;
						})}
					/>
				</div>
				<div style={{display: 'inline-block'}}>
					<WordList
						isCorrect={false}
						wordList={incorrectWordList.map((word, idx) => {
							return word;
						})}
					/>
				</div>
			</div>
		) : (
			<div style={{position: 'relative', height: '100%'}}>
				<WordAssessment
					id={`SpeedChallenge-${currentIndex.current}`}
					correctWord={{
						name: selectedWord
					}}
					words={wordList}
					onWordClick={handleWordClickCallback}
					onTimeOut={handleWordTimeoutCallback}
					timeOut={timerValue}
					isDisabled={disableActivity}
					showTimer={showCircularTimer}
					showFeedBack={false}
					isPaused={isActivityPaused}
				/>
			</div>
		);
	};

	const handleStartActivity = () => {
		AudioPlayer2.stopAll();
		setStarted(true);
	};
	const handleBeforeZoneMenu = () => {
		handlePauseClick();
	};
	const handleActivityExit = isClosed => {
		handlePauseClick();
		if (isClosed) {
			SpeedChallenge.sendProgressToServer(activityData, recallValue, fluencyAssessment, currentTrailResponseMap);
		}
	};

	const helpSound = showReviewPage === false ? SpeedChallenge.SOUND_HELP_TRIAL : SpeedChallenge.SOUND_HELP_FEEDBACK;
	const instructionSound = showReviewPage === false ? SpeedChallenge.SOUND_INTRO : SpeedChallenge.SOUND_FEEDBACK;

	useIdleHelp(helpSound);

	return (
		<>
			<Navbar
				helpSoundUrl={helpSound}
				beforeZoneMenu={handleBeforeZoneMenu}
				beforeClose={handleBeforeZoneMenu}
				onZoneMenuClosed={handleActivityExit}
				onExitAppClosed={handleActivityExit}
			/>

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={wordList}
				title="Speed Challenge"
			>
				<ActivityFrame isWhiteBackground={showReviewPage === false}>
					<>
						<ActivityInstructionButton audioSrc={instructionSound} white={showReviewPage} />
						{isStarted ? (
							renderViewBasedOnCondition()
						) : (
							<StartActivity
								id="startActivity"
								onStartActivity={handleStartActivity}
								startActivityAudioSrc={SpeedChallenge.SOUND_INTRO}
							/>
						)}
					</>
				</ActivityFrame>

				{isStarted === true && (
					<Footer>
						{showReviewPage === false && (
							<>
								<ActivitySupportButton
									data-testid="word"
									id="word"
									icon="word"
									onClick={handleWordClick}
									text="Word"
									disabled={isComplete || isActivityPaused}
								/>
								<ActivitySupportButton
									data-testid="sentence"
									id="sentence"
									icon="sentence"
									onClick={handleSentenceClick}
									text="Sentence"
									disabled={isComplete || isActivityPaused}
								/>
								<ActivitySupportButton
									data-testid="pause"
									id="pause"
									icon={isActivityPaused ? 'play' : 'pause'}
									onClick={handlePauseClick}
									text={isActivityPaused ? 'Resume' : 'Pause'}
									disabled={isComplete}
								/>
							</>
						)}
						<FooterForwardBack
							status={status}
							onForward={handleForward}
							isBackVisible={false}
							isForwardDisabled={(isComplete || showReviewPage) === false}
						/>
					</Footer>
				)}
			</ActivitySuspense>
		</>
	);
}
