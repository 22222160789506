import React from 'react';

import {createMuiTheme} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/styles';

import './R180Theme.css';

const theme = createMuiTheme({
	activityFrameWidth: '87.5%',
	activityFrameMaxWidth: '1020px',
	activityFrameMinWidth: '896px',
	activityFrameHeight: '72vh',
	activityFrameMaxHeight: '640px',
	activityFrameMinHeight: '552px',
	navbarHeight: '15vh',
	navbarMinHeight: '120px',
	navbarMaxHeight: '176px',
	footerHeight: '80px',
	colors: {
		black: '#131313',
		white: '#FBFCFD',
		pureWhite: '#FFFFFF',
		transWhite: '#FBFCFD88',
		transBlack: 'rgba(19,19,19,0.8)',
		softBlack: '#37383C',
		grey: '#55565A',
		paleGrey: '#D1D3D3',
		lightGrey: '#F5F5F5',
		slate: '#63757E',
		paleSlate: '#8EA9B9',
		cyan: '#33A9EB',
		lightBlue: '#CCE9FA',
		skyBlue: '#0093E6',
		successGreen: '#008000',
		warningYellow: '#FFBE2E',
		warningRed: '#BD0101',
		errorRed: '#E60000',
		highlightYellow: '#FFFCB0',
		darkBlue: '#005E9D',
		zoneMenu: {
			background: '#37383C'
		},
		Zone: {
			primary: '#D1D3D3', //For when there is no zone (segment selection screen)
			background: '#55565A',
		},
		exploreZone: {
			primary: '#FF193D',
			background: '#980000',
			red2: '#FF4764',
			red3: '#FF758B',
			red4: '#FFA3B1',
			red5: '#FFD1D8'
		},
		languageZone: {
			primary: '#FF7900',
			background: '#CE4A00',
			orange2: '#FF9433',
			orange3: '#FFAF66',
			orange4: '#FFC999',
			orange5: '#FFE4CC'
		},
		writingZone: {
			primary: '#49A428',
			background: '#2E7801',
			green2: '#5AC832',
			green3: '#71DC47',
			green4: '#A5E48D',
			green5: '#DDF6CD'
		},
		successZone: {
			primary: '#37B7AC',
			background: '#02707B',
			aqua2: '#53C3B6',
			aqua3: '#6DD0C5',
			aqua4: '#CCF2EA',
			aqua5: '#E4FAF5'
		},
		fluencyZone: {
			primary: '#7600FF',
			background: '#502672',
			purple2: '#9133FF',
			purple3: '#AD66FF',
			purple4: '#DEC2FF',
			purple5: '#E4CCFF',
			blue5: '#CCE9FA',
			blue6: '#016BB3',
			blue7: '#66BEF0'
		},
		readingZone: {
			primary: '#0093E6',
			background: '#016BB3',
			blue2: '#33A9EB',
			blue3: '#66BEF0',
			blue4: '#99D4F5',
			blue5: '#CCE9FA'
		},
		skillsZone: {
			primary: '#0093E6',
			background: '#016BB3',
			blue2: '#33A9EB',
			blue3: '#66BEF0',
			blue4: '#99D4F5',
			blue5: '#CCE9FA'
		},
		icons: {
			successGreen: '#008000',
			errorRed: '#E60000'
		}
	},
	fonts: {
		context: window.location.pathname.indexOf('skills') > -1 ? ['cursive'] : ['Merriweather', 'Helvetica', 'Arial', 'sans-serif'],
		contextItalic: window.location.pathname.indexOf('skills') > -1 ? ['cursive'] : [
			'Merriweather-Italic',
			'Helvetica',
			'Arial',
			'sans-serif'
		],
		contextBold: window.location.pathname.indexOf('skills') > -1 ? ['cursive'] : ['Merriweather-Bold', 'Helvetica', 'Arial', 'sans-serif'],
		contextBoldItalic: window.location.pathname.indexOf('skills') > -1 ? ['cursive'] : [
			'Merriweather-Bold-Italic',
			'Helvetica',
			'Arial',
			'sans-serif'
		],
		uiLight: ['OpenSans-Light', 'Helvetica', 'Arial', 'sans-serif'],
		ui:  ['OpenSans-Regular', 'Helvetica', 'Arial', 'sans-serif'],
		uiBold: ['OpenSans-Bold', 'Helvetica', 'Arial', 'sans-serif'],
		uiSemibold: ['OpenSans-SemiBold', 'Helvetica', 'Arial', 'sans-serif'],
		button:  ['OpenSans-Regular', 'Helvetica', 'Arial', 'sans-serif'],
		buttonBold: ['OpenSans-Bold', 'Helvetica', 'Arial', 'sans-serif'],
		buttonItalic: ['OpenSans-Italic', 'Helvetica', 'Arial', 'sans-serif']
	}
});

const R180Theme = ({children}) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default R180Theme;
