import React, {useState, useEffect, useMemo, useRef} from 'react';
import {makeStyles} from '@material-ui/core';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import PlayProgress from './PlayProgress';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';
import AudioRecorder from '@reading/common/src/components/AudioRecorder/AudioRecorder';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import { sleep } from '@reading/r180/src/utils/sleep';

export const useStyles = makeStyles(theme => ({
	progressPage: {
		background: theme.colors.white,
		display: 'flex',
		alignItems: 'center',
		verticalAlign: 'middle',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	wordContainer: {
		'& #selfCheckWord': {
			'& span': {
				fontSize: '32px'
			},
			'&:hover': {
				background: 'none'
			}
		},
		'& h2': {
			marginBottom: '112px',
			display: 'inline-block',
			marginRight: '0',
			transition: 'margin 0.3s ease',
			fontSize: '32px',
			fontFamily: theme.fonts.context,
			'&.not-recording': {
				marginRight: '30px'
			}
		}
	},
	iconWrapper: {
		background: theme.colors.white,
		border: `solid 1px ${theme.colors.paleGrey}`,
		borderRadius: '8px',
		padding: '10px',
		margin: '10px',
		position: 'relative',
		height: '56px',
		width: '56px',
		'& i': {
			color: theme.colors.skyBlue,
			fontSize: '20px',
			position: 'relative',
			'&.thumbs-up': {
				top: '0px'
			},
			'&.thumbs-down': {
				top: '3px'
			}
		},
		'&:disabled': {
			cursor: 'not-allowed'
		},
		'&:hover': {
			// background: theme.colors.fluencyZone.blue5,
			border: `2px solid ${theme.colors.fluencyZone.blue5}`,
			boxShadow: `0px 0px 6px 2px ${theme.colors.fluencyZone.blue5}`
		},
		'&.selected': {
			background: theme.colors.fluencyZone.blue5,
			borderColor: theme.colors.fluencyZone.blue3
		}
	}
}));

const SelfCheckActivityProgressPage = props => {
	const {
		id,
		activityWord,
		onSelfFeedback,
		progressValue,
		activityAudio,
		activityPauseVO,
		activityContinueVO
	} = props;

	// this hack is needed to get the progress monitor to reset to 0
	// behind the scenes.  The useEffect below bases the recording on the
	// progress going back to 0, which is not good design.
	// So we need to hack the progress back to really close to 0 so it looks
	// like 0 visually, but is not actually 0 which would trigger bad things to happen.
	const HACK_PROGRESS_NUMBER = 0.15;

	const classes = useStyles();
	const {setState} = useBatchedSetState();
	const clockCount = useRef(1);

	const [showProgress, setShowProgress] = useState(true);
	const [isRecording, setRecording] = useState(true);
	const [progress, setProgress] = useState(progressValue);
	const [icon, setIcon] = useState('pause');
	const [showModal, setShowModal] = useState(false);
	const [correctSelected, setCorrectSelected] = useState(false);
	const [isPlaying, setPlaying] = useState(false);

	const audioRecorder = useMemo(() => {
		const audioRecorder = new AudioRecorder();
		audioRecorder.progressCallback = progress => {};
		return audioRecorder;
	}, []);

	useEffect(() => {
		setState(() => {
			setCorrectSelected(false);
			if (progressValue >= 0) {
				setProgress(0);
				setRecording(true);
				setIcon('pause');
				audioRecorder.stopPlay();
			}
		});
	}, [progressValue, audioRecorder]);

	const handleSelfFeedback = (id, type) => {
		onSelfFeedback(id, type);
		if (type === 'correct') {
			setCorrectSelected(true);
			clockCount.current = clockCount.current + 1;
			setProgress(HACK_PROGRESS_NUMBER);
		}
		else {
			setCorrectSelected(false);
			clockCount.current = clockCount.current + 1;
			setProgress(HACK_PROGRESS_NUMBER);
		}
	}

	useEffect(() => {
		if (progress === HACK_PROGRESS_NUMBER) {
			return;
		}
		const timer = setTimeout(async() => {
			if (!showModal) {
				if (progress < 100) {
					if (progress === 0 && audioRecorder.micRecorder) {
						audioRecorder.record();
					}
					setProgress(prevProgress => prevProgress + 1);
				} else {
					setState(() => {
						setRecording(false);
						setIcon('play');
					});
					audioRecorder.stopRecord(false);
					AudioPlayer2.load({
						name: activityWord.wordId,
						src: activityWord.word_url
					});
					await AudioPlayer2.playSync(activityWord.wordId);
					setPlaying(true);
					audioRecorder.play();
					await sleep(3000);
					setPlaying(false);
				}
			} else {
				clearTimeout(timer);
			}
		}, 50);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [progress, showModal, audioRecorder]);

	const handleRecording = async(value) => {
		if (value === 'pause') {
			AudioPlayer2.load(activityPauseVO);
			AudioPlayer2.play(activityPauseVO.name);
			setState(() => {
				setShowModal(true);
				clockCount.current = clockCount.current + 1;
				setProgress(HACK_PROGRESS_NUMBER);
			});
			audioRecorder.stopRecord(true);
		} else {
			audioRecorder.play();
			setPlaying(true);
			await sleep(5000);
			setPlaying(false);
		}
	};

	/* istanbul ignore next */
	const hideModel = () => {
		const audioPlayer = AudioPlayer2.load(activityContinueVO);
		AudioPlayer2.play(activityContinueVO.name);
		audioPlayer.onEnd = () => {
			setShowModal(false);
			audioRecorder.record();
		};
	};

	return (
		<div id={id} className={classes.progressPage}>
			<div className={classes.wordContainer}>
				<h2 className={isRecording ? 'recording' : 'not-recording'}>
					{activityWord.wordId}
				</h2>
				{!isRecording && (
					<span className='animate__animated animate__zoomIn'>
						<button
							key={activityWord.wordId + 'correct'}
							className={`${classes.iconWrapper} ${correctSelected ? 'selected' : ''} correct-thumb`}
							data-testid={'selfFeedback'}
							aria-label={'Feedback thumbs-up'}
							onClick={() =>
								handleSelfFeedback(activityWord.wordId, 'correct')
							}
						>
							<i className={`r180 thumbs-up`} />
						</button>
						<button
							key={activityWord.wordId + 'incorrect'}
							className={classes.iconWrapper}
							data-testid={'selfFeedback'}
							aria-label={'Feedback thumbs-down'}
							onClick={() =>
								handleSelfFeedback(activityWord.wordId, 'incorrect')
							}
						>
							<i className={`r180 thumbs-down`} />
						</button>
					</span>
				)}
			</div>
			<div>
				<PlayProgress
					key={clockCount.current}
					progress={progress}
					showProgess={showProgress}
					isRecording={isRecording}
					icon={icon}
					handleRecording={handleRecording}
					isPlaying={isPlaying}
				/>
			</div>
			{showModal && (
				<ConfirmationModal
					onCloseClicked={hideModel}
					onPrimaryButtonClicked={hideModel}
					onSecondaryButtonClicked={() => {}}
					open
					primaryButtonIcon="arrow-right"
					primaryButtonText="Resume"
					secondaryButtonIcon="x"
					secondaryButtonText="No"
					showCloseIcon
					showSecondaryButton={false}
					showWarningIcon={false}
					subTitle=""
					title="You have paused your activity."
					titleSound={{
						name: activityAudio.name,
						src: activityAudio.url
					}}
				/>
			)}
		</div>
	);
};

SelfCheckActivityProgressPage.defaultProps = {
	id: ''
};

export default React.memo(SelfCheckActivityProgressPage);
