import axios from 'axios';
import reduxStore from '../store/redux-setup';
import activity from './activity';
import userContext from './userContext';
import session from './session';
import navigate from './navigate';
import spelling from './spelling';
import { API_BASE } from "../utils/constants";

const baseEndpoint = API_BASE;

axios.interceptors.request.use(async config => {
	try {
		const { userContext } = reduxStore.getState();

		const { sifToken, correlationId, activityId } = userContext;

		config.headers.common['Authorization'] = sifToken;
		config.headers.common['x-correlation-id'] = correlationId;
		config.headers.common['ActivityId'] = activityId;
		config.headers.common['Access-Control-Expose-Headers'] = 'x-b3-traceid';

		return config;
	} catch (err) {
		console.log('ERR', err);
		return config;
	}
});

export default {
	activity: activity(baseEndpoint),
	userContext: userContext(baseEndpoint),
	session: session(baseEndpoint),
	navigate: navigate(baseEndpoint),
	spelling: spelling(baseEndpoint)
};
