import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {AnchorVideo} from '@reading/common';
import {Modal} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	modal: {
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
		position: 'absolute',
		width: '890px',
		height: '549px'
	},
	closeButton: {
		position: 'absolute',
		top: '24px',
		right: '10px',
		border: `1px solid ${theme.colors.black}`,
		fontSize: '12px',
		height: '24px',
		width: '24px',
		borderRadius: '12px',
		zIndex: '999',
		lineHeight: '24px',
		textAlign: 'center',
		'&:hover': {
			transform: 'scale(1.2)'
		}
	}
}));

const VideoModal = (props) => {
	const {
		className,
		id,
		videoButtonId,
		isOpen,
		onModalClose,
		videoContainerStyle,
		videoVol,
		start_time,
		end_time,
		videoSrcURL,
		videoSrcType,
		videoTracks,
		videoPlayOnLoad,
		videoPoster,
		hideFwdBtn,
		hideRewindBtn,
		hideCaptionBtn,
		hideReplayBtn,
		hideCloseBtn
	} = props;

	const classes = useStyles();

	const anchorVideoStyle = {
		...videoContainerStyle
	};

	const handleClose = () => {
		if (typeof onModalClose !== 'undefined') {
			onModalClose();
		}
	};

	return (
		<Modal
			disableBackdropClick
			disableEscapeKeyDown
			aria-labelledby="video-window"
			aria-describedby="video-card-window"
			open={isOpen}
			style={{zIndex: '99999'}}
			onClose={handleClose}
			className="r180-modal"
		>
			<div
				className={`${classes.modal} ${className} modal-window`}
				id={id}
			>
				<AnchorVideo
					id={videoButtonId}
					autoPlay={videoPlayOnLoad}
					time={start_time}
					video_end_time={end_time}
					vol={videoVol}
					containerStyle={anchorVideoStyle}
					poster={videoPoster}
					handleVideoEnded={handleClose}
					handleCloseButton={handleClose}
					hideFwdButton={hideFwdBtn}
					hideRewindButton={hideRewindBtn}
					hideCaptionButton={hideCaptionBtn}
					hideReplayButton={hideReplayBtn}
					hideCloseButton={hideCloseBtn}
				>
					<source src={videoSrcURL} type={videoSrcType} />

					{videoTracks.map((track, index) => (
						<track
							key={index}
							src={track.src}
							label={track.label}
							kind={track.kind}
							srcLang={track.srcLang}
						/>
					))}
				</AnchorVideo>
			</div>
		</Modal>
	);
}

VideoModal.defaultProps = {
	className: '',
	id: '',
	videoButtonId: 'videoHintContainer',
	videoContainerStyle: {
		width: '100%',
		height: '100%'
	},
	videoVol: 0.1,
	videoSrcURL: '',
	videoSrcType: 'video/mp4',
	videoTracks: [
		{
			src: '',
			label: 'English subtitles',
			kind: 'subtitles',
			srcLang: 'en'
		},
		{
			src: '',
			label: 'English subtitles',
			kind: 'subtitles',
			srcLang: 'de'
		},
		{
			src: '',
			label: 'Spanish subtitles',
			kind: 'subtitles',
			srcLang: 'es'
		}
	],
	videoPlayOnLoad: true,
	videoPoster: '',
	hideFwdBtn: false,
	hideRewindBtn: false,
	hideCaptionBtn: true,
	hideReplayBtn: true,
	hideCloseBtn: false
};

VideoModal.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	videoButtonId: PropTypes.string.isRequired,
	start_time: PropTypes.number.isRequired,
	end_time: PropTypes.number.isRequired,
	videoContainerStyle: PropTypes.object.isRequired,
	videoVol: PropTypes.number,
	videoSrcURL: PropTypes.string.isRequired,
	videoSrcType: PropTypes.string,
	videoTracks: PropTypes.arrayOf(PropTypes.object).isRequired,
	videoPlayOnLoad: PropTypes.bool,
	videoPoster: PropTypes.string,
	hideFwdBtn: PropTypes.bool,
	hideRewindBtn: PropTypes.bool,
	hideCaptionBtn: PropTypes.bool,
	hideReplayBtn: PropTypes.bool,
	hideCloseBtn: PropTypes.bool,
	videoButtonClass: PropTypes.object
};

export default React.memo(VideoModal);
