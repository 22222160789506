import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import CorrectIncorrect from '../CorrectIncorrect/CorrectIncorrect';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: '-32px -40px -40px -40px',
		display: 'flex',
		flexDirection: 'column',
		height: '480px',
		alignItems: props => (props.displayOnlyWords ? 'center' : 'flex-start'),
		overflowY: props => (props.displayOnlyWords ? 'scroll' : 'inherit')
	},
	optionsContainer: {
		width: props => (props.displayOnlyWords ? '290px' : '452px'),
		height: 'auto',
		margin: '0px 10px 10px',
		padding: '16px',
		background: theme.colors.lightGrey,
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '8px',
		display: 'flex',
		flexDirection: 'row',
		'&.correct': {
			border: `2px solid ${theme.colors.successGreen}`
		},
		'&.incorrect': {
			border: `2px solid ${theme.colors.errorRed}`
		}
	},
	optionsText: {
		textIndent: '32px',
		width: '400px'
	},
	iconsWrapper: {
		marginLeft: '-24px'
	},
	iconsContainer: {
		fontSize: '16px',
		width: '24px',
		height: '24px',
		borderRadius: '24px',
		position: 'relative',
		left: props => (props.displayOnlyWords ? '5px' : '24px'),
		'& .delete': {
			color: theme.colors.skyBlue,
			cursor: 'pointer'
		}
	}
}));

const HighlightQuestion = props => {
	const {id, className, onAnswerChange, question, displayOnlyWords} = props;
	const classes = useStyles({displayOnlyWords});
	const showIcons = item => {
		switch (item.status) {
			case 'correct': {
				return <CorrectIncorrect />;
			}
			case 'incorrect': {
				return <CorrectIncorrect correct={false} />;
			}

			default: {
				return (
					<button
						className={`${classes.iconsContainer} ${item.status}`}
						onClick={() => onAnswerChange(item)}
					>
						<i className="r180 delete" />
					</button>
				);
			}
		}
	};
	return (
		<div className={`${classes.wrapper} ${className}`} id={id}>
			{question.map((option, index) => {
				return (
					<div
						key={index}
						className={`${classes.optionsContainer} ${option.status}`}
					>
						<div
							className={classes.optionsText}
							dangerouslySetInnerHTML={{__html: option.text}}
						></div>
						<div className={classes.iconsWrapper}>
							{showIcons(option)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

HighlightQuestion.defaultProps = {
	id: '',
	className: '',
	displayOnlyWords: false
};
HighlightQuestion.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	question: PropTypes.array.isRequired,
	onAnswerChange: PropTypes.func.isRequired,
	displayOnlyWords: PropTypes.bool
};

export default HighlightQuestion;
