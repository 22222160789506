import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	button: {
		position: 'relative',
		height: '40px',
		width: '136px',
		lineHeight: '16px',
		borderRadius: '24px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0px 16px',
		fontSize: '16px',
		textTransform: 'none',
		fontFamily: theme.fonts.buttonBold,
		background: theme.colors.black,
		color: theme.colors.white,
		transition: '0.15s ease all',
		border: '2px solid transparent',
		'&:hover:not(.disabled)': {
			transform: 'scale(1.2)'
		},
		'&:active:not(.disabled), &:focus:not(.disabled)': {
			boxShadow: `0 0 0 2px ${theme.colors.black}`,
			backgroundClip: 'padding-box',
		},
		'& i': {
			fontSize: '14px'
		},
		'&.disabled, &:disabled': {
			background: theme.colors.black,
			opacity: 0.4,
			color: theme.colors.grey,
			cursor: 'not-allowed'
		},
		'&.secondary': {
			background: theme.colors.white,
			color: theme.colors.black,
			border: `solid 1px ${theme.colors.black}`,
			'&.disabled, &:disabled': {
				background: theme.colors.white,
				borderColor: `${theme.colors.black} !important`,
				color: theme.colors.black
			}
		}
	}
}));

const ActivityButton = (props) => {
	const {id, text, icon, onClick, isPrimary, className, disabled} = props;
	const classes = useStyles();
	const secondaryClass = isPrimary ? '' : 'secondary';
	const disabledClass = disabled ? 'disabled' : '';

	return (
		<button
			data-testid={id}
			onClick={onClick}
			disabled={disabled}
			className={`${classes.button} ${secondaryClass} activity-button ${disabledClass} ${className}`}
		>
			<span className={classes.buttonText}>{text}</span>
			<i className={`r180 ${icon}`} />
		</button>
	);
}

ActivityButton.defaultProps = {
	id: '',
	className: '',
	text: '',
	icon: '',
	isPrimary: true,
	disabled: false
};
ActivityButton.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	text: PropTypes.string,
	icon: PropTypes.string,
	isPrimary: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func
};

export default React.memo(ActivityButton);
