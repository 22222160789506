import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {isEmpty} from 'lodash';
import {bulkReplace} from '../../../utils/stringUtils';
import {loadSleep} from '../../../utils/ui';
import {FoundationalUtil} from '../FoundationalUtil';
import {WordMeaningMatchConstants} from './WordMeaningMatchConstants';

export const WordMeaningMatchUtil = {
	initialize: async params => {
		const {contentData} = params;

		const start = new Date().getTime();

		// preload the images used
		contentData.activity_words.forEach(word => {
			let i = new Image();
			i.src = bulkReplace(WordMeaningMatchConstants.IMAGE_PATH, {word: word.text});
		});

		// load the directions
		AudioPlayer2.loadSound(WordMeaningMatchConstants.READ_WORD);
		AudioPlayer2.loadSound(WordMeaningMatchConstants.READ_SENTENCE);

		// correct & incorrect
		AudioPlayer2.loadSound(WordMeaningMatchConstants.CORRECT);
		AudioPlayer2.loadSound(WordMeaningMatchConstants.INCORRECT);

		// load the SFX
		AudioPlayer2.loadSound(WordMeaningMatchConstants.COVER_UP);
		AudioPlayer2.loadSound(WordMeaningMatchConstants.COVER_OFF);

		// load the help sounds
		WordMeaningMatchConstants.HELP.forEach(sound => {
			AudioPlayer2.loadSound(sound);
		});

		AudioPlayer2.loadSound(WordMeaningMatchConstants.FEEDBACK_HELP);

		// load the intros
		WordMeaningMatchConstants.LONG_INTRO.forEach(sound => {
			AudioPlayer2.loadSound(sound);
		});
		WordMeaningMatchConstants.SHORT_INTRO.forEach(sound => {
			AudioPlayer2.loadSound(sound);
		});

		// load the final screen sounds
		WordMeaningMatchConstants.ONE_INCORRECT.forEach(sound => {
			AudioPlayer2.loadSound(sound);
		});

		WordMeaningMatchConstants.TWO_MORE_INCORRECT.forEach(sound => {
			AudioPlayer2.loadSound(sound);
		});

		WordMeaningMatchConstants.ALL_CORRECT.forEach(sound => {
			AudioPlayer2.loadSound(sound);
		});

		const end = new Date().getTime();
		await loadSleep(end - start);
	},

	getRandomizedSound: type => {
		if (type.indexOf('lintro') > -1) {
			return FoundationalUtil.getRandomSound(WordMeaningMatchConstants.LONG_INTRO);
		} else {
			return FoundationalUtil.getRandomSound(WordMeaningMatchConstants.SHORT_INTRO);
		}
	},

	getHelpSound: (helpCount, isLastScreen) => {
		if (isLastScreen) {
			return WordMeaningMatchConstants.FEEDBACK_HELP;
		} else {
			let visit = Math.max(0, helpCount);
			visit = Math.min(visit, 5);
			return WordMeaningMatchConstants.HELP[visit];
		}
	},

	getFinalScoreSound: numCorrect => {
		if (numCorrect === 3) {
			return FoundationalUtil.getRandomSound(WordMeaningMatchConstants.ALL_CORRECT);
		} else if (numCorrect === 2) {
			return FoundationalUtil.getRandomSound(WordMeaningMatchConstants.ONE_INCORRECT);
		} else {
			return FoundationalUtil.getRandomSound(WordMeaningMatchConstants.TWO_MORE_INCORRECT);
		}
	},

	getRidOfBrackets: text => {
		if (isEmpty(text)) {
			return '';
		}

		text = text.replace('[', '');
		return text.replace(']', '');
	}
};
