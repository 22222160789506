import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		'@media screen and (max-width: 1100px)': {
			minWidth: 'fit-content',
		},

		'& .instructions': {
			color: theme.colors.white,
			fontSize: '24px',
			top: '16px',
			position: 'relative',
			left: '3px'
		},

		'&.disablePanel': {
			'& .tab': { cursor: 'not-allowed' },
			'& .questionPanel *': { cursor: 'not-allowed' }
		}
	},

	leftColumn: { minWidth: '512px', maxWidth: '648px', top: '-53px', lineHeight: '30px' },
	rightColumn: { maxWidth: '482px', marginLeft: '8px', position: 'relative', top: '60px' },

	discrepancyPanel: {
		'&#discrepancyTabs': {
			left: '10%',
			width: '90%'
		},
		'& .questionPanel': {
			'& .header': {
				background: theme.colors.successZone.aqua5
			}
		},

		'& .tab': {
			background: theme.colors.successZone.aqua5,
			cursor: 'pointer',
			'&.selected': {
				background: theme.colors.successZone.primary
			}
		}
	},
	strip: {
		width: 'auto',
		height: '16px',
		background: theme.colors.successZone.primary
	},
	videoModal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	videoModalBody: {
		position: 'relative',
		top: '-20px',
		'& .videoClose': {
			position: 'absolute',
			right: '23px',
			top: '3px',
			color: theme.colors.white,
			cursor: 'pointer',
			fontSize: '24px'
		}
	},
	rootWrapper: {
		position: 'relative',
		top: '-17px'
	},
	wordCardModal: { display: 'flex', alignItems: 'center', justifyContent: 'center' }
}));
