import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ActivityInstructionButton, ProofreadingContainer, StartActivity} from '@reading/common';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {cloneDeep, isEmpty} from 'lodash';
import {Proofreading} from './Proofreading';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import useIdleHelp from '../../utils/useIdleHelp';
import {uiSlice} from '../../store/slices/ui';
import ActivityFrame from '../../containers/App/ActivityFrame';
import useBatchedSetState from '../../utils/useBatchedSetState';
import {sleep} from '../../utils/sleep';

export default function ProofreadingActivity(props) {
	const {studylist, activityData} = useSelector(state => {
		return {
			studylist: state.activity.studylist,
			activityData: state.activity.activityData
		};
	});

	const history = useHistory();
	const dispatch = useDispatch();
	const {setState} = useBatchedSetState();

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	// check if the activity is already charged
	const {isActivityCharged, settings} = useSelector(state => {
		return {
			isActivityCharged: state.ui.chargedActivities['proofreading'] === true,
			settings: state.session.session.settings
		};
	});

	const isActivity = activityData ? activityData.activityServerId === 'proofreading' : false;

	const [screen, setScreen] = useState(1);
	const [sentences, setSentences] = useState([]);
	const [isBtnDisabled, setBtnDisabled] = useState(true);
	const [status, setStatus] = useState(FooterForwardBack.SUBMIT);
	const [result, setResult] = useState([]);
	const [attemptCount, setAttemptCount] = useState(0);
	const [isSelectionDisabled, setSelectionDisabled] = useState(false);

	useDeepCompareEffect(() => {
		const initialize = async () => {
			const sentencesDataList = await Proofreading.initData(studylist);
			setSentences(sentencesDataList);
			dispatch(uiSlice.actions.setActivityCharged('proofreading'));
			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				AudioPlayer2.play(Proofreading.INTRO_VO);
			}
		};
		if (isEmpty(studylist) === false && isActivity) {
			initialize();
		}
	}, [studylist]);

	const handleForward = async () => {
		AudioPlayer2.stopAll();

		// they pressed the "Go On" button and we're ready to move to the next activity
		if (status === FooterForwardBack.VALID) {
			try {
				await Proofreading.completeActivity(history, activityData);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		} else if (status === FooterForwardBack.INVALID) {

		/* istanbul ignore next */
			setState(() => {
				setSelectionDisabled(false);
				setStatus(FooterForwardBack.SUBMIT);
				let newSentences = cloneDeep(sentences);
				newSentences.forEach(s => {
					if (s.attemptAgain) {
						s.showFeedBackIcon = false;
						s.isSelected = false;
					}
				});
				setSentences(newSentences);
			});
		}
		// They pressed the "Submit" button
		else {
			const isAllCorrect = sentences.filter(rowItem => rowItem.sentence.includes(`<W>${rowItem.text}</W>`));
			let resultArry = [];
			sentences.forEach(item => {
				if (item.sentence.includes(`<W>${item.text}</W>`)) {
					resultArry.push(true);
					item.attemptAgain = false;
				} else {
					resultArry.push(false);
					item.attemptAgain = true;
					setAttemptCount(attemptCount + 1);
				}
				item.showFeedBackIcon = true;
				item.isSelected = true;
			});

			setResult(resultArry);

			// they have them all correct, so play the sound and show the "Go On" button
			if (isAllCorrect.length === Proofreading.IS_ALL_CORRECT_LENGTH) {
				setState(() => {
					setStatus(FooterForwardBack.VALID);
					setBtnDisabled(true);
				});
				if (attemptCount === 0) {
					await AudioPlayer2.playSync(Proofreading.DONE);
				} else {
					await AudioPlayer2.playSync(Proofreading.DONE2);
				}
				setState(() => {
					setBtnDisabled(false);
				});

				Proofreading.sendProgressToServer(activityData);
			}
			// they have one or more incorrect
			else {
				setState(() => {
					setBtnDisabled(true);
					setAttemptCount(attemptCount + 1);
					setSelectionDisabled(true);
				});
				await AudioPlayer2.playSync(Proofreading.TRY_AGAIN);
				setState(() => {
					setStatus(FooterForwardBack.INVALID);
					setBtnDisabled(false);
				});
				Proofreading.sendProgressToServer(activityData);
			}
		}
	};
    /* istanbul ignore next */
	const handleSentenceChange = async value => {
		setState(() => {
			setBtnDisabled(
				value.filter(v => {
					return v.isSelected === true;
				}).length !== value.length
			);
			setSentences(value);
		});
	};

	const handleStartActivity = () => {
		AudioPlayer2.stopAll();
		setScreen(2);
	};

	useIdleHelp(attemptCount === 0 ? Proofreading.TRIAL_HELP : Proofreading.MIDDLE_HELP);

	return (
		<>
			<Navbar helpSoundUrl={attemptCount === 0 ? Proofreading.TRIAL_HELP : Proofreading.MIDDLE_HELP} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={sentences}
				title="Proofreading"
			>
				<ActivityFrame>
					<>
						<ActivityInstructionButton audioSrc={Proofreading.INTRO_VO} />
						{screen === 1 ? (
							<StartActivity
								onStartActivity={handleStartActivity}
								startActivityAudioSrc={Proofreading.INTRO_VO}
							/>
						) : (
							<ProofreadingContainer
								id="proofreading"
								sentences={sentences}
								result={result}
								onSentenceChange={handleSentenceChange}
								isSelectionDisabled={isSelectionDisabled}
								// onBtnDisabled={handleBtnDisabled}
							/>
						)}
					</>
				</ActivityFrame>

				<Footer>
					{screen === 2 && (
						<FooterForwardBack
							status={status}
							onForward={handleForward}
							isBackVisible={false}
							isForwardDisabled={isBtnDisabled}
						/>
					)}
				</Footer>
			</ActivitySuspense>
		</>
	);
}
