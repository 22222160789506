import {makeStyles} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import ProgressMeter from '@reading/common/src/components/ProgressMeter/ProgressMeter';
import {useSelector} from 'react-redux';

export const useStyles = makeStyles(theme => ({
	fsProgress: {
		position: 'relative',
		width: '100%',
		height: '43px',
		lineHeight: '43px',
		background: theme.colors.paleGrey,
		paddingLeft: '14px',
		paddingRight: '14px',
		fontSize: '18px',
		color: theme.colors.grey,
		fontWeight: '700',
		letterSpacing: '0.15px',
		textAlign: 'center',
		'& .progress-meter': {
			width: '550px'
		}
	},
	title: {
		float: 'left'
	},
	progressBadge: {
		float: 'right',
		marginTop: '4px',
		'& .badge': {
			background: theme.colors.paleSlate,
			borderRadius: '50%',
			width: 'auto',
			minWidth: '34px',
			height: '34px',
			textAlign: 'center',
			lineHeight: '34px'
		}
	}
}));

const FSProgressBar = props => {
	const {className, title, isVisible} = props;
	const classes = useStyles();

	const {activityData} = useSelector(state => {
		return {
			activityData: state.activity.activityData
		};
	});

	const activityNum = activityData.status_bar ? activityData.status_bar.activities_encountered : 0;
	const activityTotal = activityData.status_bar ? activityData.status_bar.activities_total : 1;
	const activityPercent = (activityNum / activityTotal) * 100;

	return (
		<>
			{isVisible ? (
				<div className={`${classes.fsProgress} fs-progress-bar ${className}`}>
					<div className={`${classes.title}`}>{title}</div>
					<ProgressMeter color={'#fc6'} progress={activityPercent} />
					<div className={`${classes.progressBadge}`}>
						<div className={`badge`}>
							{activityNum}/{activityTotal}
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};

FSProgressBar.defaultProps = {
	className: '',
	title: '',
	isVisible: true
};

FSProgressBar.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	isVisible: PropTypes.bool
};

export default React.memo(FSProgressBar);
