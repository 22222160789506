import React, { useEffect, useRef, useContext } from 'react';
import Sortable from 'sortablejs';
import { DndContext } from './DndProvider';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    dropZone: {
        position: 'relative',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        gap: '8px',
        width: '100%',
        borderRadius: '8px',
        background: theme.colors.white,
        border: `dashed 1px transparent`,
        transition: 'all 0.25s ease',

        '& .helpers': {
            display: 'none'
        },

        '& .image-tile': {
            height: '82px'
        },

        '&:empty': {
            border: `dashed 1px ${theme.colors.paleGrey}`,
            background: 'transparent',
            transition: 'all 0.35s ease',
        },

        '& .drop-ghost': {
            background: 'rgba(0,147,230,0.4)',
            boxShadow: 'inset 0 0 2px 2px #FFF',
            borderRadius: '8px',
            pointerEvents: 'none',
            transition: 'background 0.25s ease',
            // width: 2,

            '& *': {
                opacity: 0
                // display: 'none'
            }
        }
    },
}));

const DropZone = (props) => {
    const { type = "answers", meta, children } = props;
    const classes = useStyles();
    const dropRef = useRef(null);
    const sortable = useRef(null);
    const { dndTiles, dndState, methods } = useContext(DndContext);

    const dropOptions = {
        group: {
            name: "answers",
            pull: ['questions', "answers"],
            put: true
        },
        sort: false,
        revertOnSpill: true,
        swapThreshold: 0.5,
        useReact: true,
        dropContainer: '.drop-content',
        dragoverBubble: true,
        ghostClass: 'drop-ghost',
        chosenClass: 'selected',
        dragClass: 'dragging',
        animation: 180,
        handle: ".drag-helper",
        dataIdAttr: 'data-dnd-id',
        onStateChanges(arr) {
            // Update DND Provider state
            methods.updateMultiple(arr)
        },
    }
    // --- xx -----------------------------------------------

    useEffect(() => {
        if (dropRef.current) {
            if (sortable.current) {
                sortable.current.destroy()
            }
            sortable.current = new Sortable(dropRef.current, dropOptions);
            sortable.current.dndType = type;
            methods.setNode(type, dropRef.current);
        }
    }, [dndTiles])
    // --- xx -----------------------------------------------

    const handleChange = (elm, to) => {
        const toContainer = dndState.nodes[to];
        toContainer.appendChild(elm)

        const toItems = toContainer.querySelectorAll('[data-dnd-id]');
        const changeObj = {
            from: { id: type, items: sortable.current.toArray() },
            to: { id: to, items: Array.from(toItems).map(i => i.dataset.dndId) }
        }

        methods.updateMultiple(changeObj)
    }
    // --- xx -----------------------------------------------

    const component = React.isValidElement(children)
        ? children
        : null;

    if (dndTiles)
        return (
            <div ref={dropRef}
                data-type={type}
                className={`${classes.dropZone} drop-zone`}
            >
                {dndTiles.map((item) => (
                    item.target === type &&
                    (React.cloneElement(component,
                        {
                            ...component.props,
                            onMenuChange: handleChange,
                            item,
                            meta: meta,
                            type: type,
                        }, null))
                ))}
            </div>
        );
    else
        return null;
};

export default React.memo(DropZone);