import React, {useEffect, useState, Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import RadioCheck from '../../components/RadioCheck';

export const useStyles = makeStyles(theme => ({
	divider: {
		width: '102px',
		height: '0',
		position: 'relative',
		top: '17px',
		left: '-6px',
		borderBottom: `2px dashed ${theme.colors.paleGrey}`,
	},
	feedbackWrapper: {
		marginTop: '100px',
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '40px'
	},
	radioSection: {
		position: 'relative',
		textAlign: 'center',
		width: '40px'
	},
	choiceLabel: {
		position: 'relative',
		textAlign: 'center',
		fontSize: '16px',
		color: theme.colors.softBlack,
		fontFamily: theme.fonts.uiBold,
		top: '34px',
		left: '-38px',
		minWidth: '100px'
	}
}));

const FeedbackBar = props => {
	const {choices, updateSelection, setWritingStatus, voComplete} = props;

	const classes = useStyles();

	const [selectionList, updateSelectionList] = useState([
		false,
		false,
		false,
		false
	]);
	const [isChoiceSelected, updateChoiceSelection] = useState(false);

	const handleChoiceSelect = selectedIndex => {
		updateChoiceSelection(true);
		const newSelectionList = [...selectionList];
		newSelectionList.forEach(
			(item, index) => (newSelectionList[index] = false)
		);
		newSelectionList[selectedIndex] = true;
		updateSelectionList(newSelectionList);
		updateSelection(selectedIndex);
		/*istanbul ignore else */
		if (voComplete) {
			setWritingStatus();
		}
	};


	useEffect(() => {
		/*istanbul ignore next */
		if (isChoiceSelected && voComplete) {
			setWritingStatus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [voComplete]);

	return (
		<div className={classes.feedbackWrapper}>
			{selectionList.map((item, index) => {
				return (
					<Fragment key={`writingPromptChoice${index}`}>
						<div className={classes.radioSection}>
							<RadioCheck
								className=""
								id={`writingPromptChoice${index}`}
								isSelected={item}
								item={{}}
								onSelect={() => handleChoiceSelect(index)}
								status="initial"
								type="single"
							/>
							<div className={classes.choiceLabel}>
								{choices[index]}
							</div>
						</div>
						{index !== 3 && <div className={classes.divider} />}
					</Fragment>
				);
			})}
		</div>
	);
};

export default React.memo(FeedbackBar);
