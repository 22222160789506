import React, {useState} from 'react';
import {ActivityInstructionButton, StartActivity} from '@reading/common';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import {useDeepCompareEffect} from 'react-use';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {useDispatch, shallowEqual, useSelector} from 'react-redux';
import {uiSlice} from '../../store/slices/ui';
import {isEmpty} from 'lodash';
import {SpellingClinic} from './SpellingClinic';
import SpellingClinicComponent from '@reading/common/src/components/SpellingClinic/SpellingClinic';
import {useHistory} from 'react-router-dom';
import {useUnmount} from 'react-use';
import ActivityFrame from '../../containers/App/ActivityFrame';
import useBatchedSetState from '../../utils/useBatchedSetState';
import { sleep } from '../../utils/sleep';

const SpellingClinicActivity = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {setState} = useBatchedSetState();

	const {activityData, studyList} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			studyList: state.activity.studylist
		};
	}, shallowEqual);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	const { isActivityCharged, settings } = useSelector(state => {
		return {
			isActivityCharged: state.ui.chargedActivities['spelling-clinic'] === true,
			settings: state.session.session.settings
		};
	});

	const isActivity = activityData ? activityData.activityServerId === 'spelling_clinic' : false;
	const [isStarted, setStarted] = useState(false);
	const [wordsList, setWordsList] = useState([]);
	const [screenIndex, setScreenIndex] = useState(0);
	const [studyWordIndex, setStudyWordIndex] = useState(0);
	const [wordCardIndex, setWordCardIndex] = useState(0);
	const [isGoOnDisabled, setGoOnDisabled] = useState(true);
	const [hasWhiteBackground, setWhiteBackGround] = useState(false);

	
	useDeepCompareEffect(() => {
		const loadMedia = async () => {
			const finalData = await SpellingClinic.initData(studyList, activityData, isActivityCharged);
			setState(() => {
				setWordsList(finalData);
				setWhiteBackGround(true);
			});

			dispatch(uiSlice.actions.setActivityCharged('spelling-clinic'));
			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				AudioPlayer2.stopAll();
				await AudioPlayer2.playSync(SpellingClinic.INTRO_VO);
			}
		};

		if (!isEmpty(studyList) && isActivity) {
			loadMedia();
		}
	}, [studyList]);


	const handleGoOn = async () => {
		AudioPlayer2.stopAll();
		let screenIndexTemp = screenIndex;
		setScreenIndex(++screenIndexTemp);

		// we're done with the activity, go to the next one
		if (screenIndexTemp > SpellingClinic.LAST_SCREEN) {
			try {
				await SpellingClinic.completeActivity(history, activityData);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		} else {
			if (screenIndexTemp === SpellingClinic.LAST_SCREEN) {
				AudioPlayer2.play(SpellingClinic.SPELLCLINIC_COMPLETE);
			} else {
				SpellingClinic.sendProgressToServer(activityData);
			}

			if (screenIndexTemp === SpellingClinic.WORD_CARD_SCREEN) {
				const studyWordCount = studyWordIndex + 1;

				setState(() => {
					setStudyWordIndex(studyWordCount);
					setWhiteBackGround(true);
					setGoOnDisabled(true);
				});

				await AudioPlayer2.playSync(SpellingClinic.WORD_SPELL_EXPLAIN);
				AudioPlayer2.play(wordsList[wordCardIndex - 1].text);
			}

		
			if (screenIndexTemp > SpellingClinic.LANDING_SCREEN) {
				const studyWordCount = studyWordIndex + 1;

				if (screenIndexTemp % 2) {
					const wordCardCount = wordCardIndex + 1;
					setGoOnDisabled(false);
					setWordCardIndex(wordCardCount);
					
					if (wordCardCount <= SpellingClinic.WORDCARD_COUNT) {
						setWhiteBackGround(false);
						setGoOnDisabled(true);
						
					}
				} else {
				

					if (screenIndexTemp !== SpellingClinic.LANDING_SCREEN) {
						setGoOnDisabled(true);
					}

					
					if (screenIndexTemp !== SpellingClinic.WORD_CARD_SCREEN) {
						AudioPlayer2.play(wordsList[wordCardIndex - 1].text);
					}

					setWhiteBackGround(true);
				}
				setStudyWordIndex(studyWordCount);
			}
		}
	};

	const handleStartActivity = async () => {
		AudioPlayer2.stopAll();
		setStarted(true);
	}

	const isWordCardEnabled = SpellingClinic.WORDCARD_SCREEN_LIST.indexOf(screenIndex) > -1;

	return (
		<>
			<Navbar helpSoundUrl={SpellingClinic.SCREEN_HELP_VO[screenIndex]} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={wordsList}
				title="Spelling Clinic"
			>
				<ActivityFrame isWhiteBackground={hasWhiteBackground}>
					<ActivityInstructionButton audioSrc={SpellingClinic.INTRO_VO} white={isWordCardEnabled} disabled={SpellingClinic.WORDCARD_SCREEN_LIST.includes(screenIndex) && isGoOnDisabled } />

					{ isStarted === false &&
						<StartActivity
							onStartActivity={handleStartActivity}
							startActivityAudioSrc={SpellingClinic.INTRO_VO}
						/>
					}
					{
						isStarted &&
							<SpellingClinicComponent
								key={`screen${screenIndex}`}
								data-testid={'studylist'}
								wordsList={wordsList}
								studyWordIndex={studyWordIndex - 1}
								configData={SpellingClinic}
								screenIndex={screenIndex}
								wordCardIndex={wordCardIndex}
								setGoOnDisabled={setGoOnDisabled}
								handleForward={handleGoOn}
							/>
					}

				</ActivityFrame>
				<Footer>
					<FooterForwardBack
						isForwardDisabled={isGoOnDisabled || isStarted === false}
						status={FooterForwardBack.VALID}
						onForward={handleGoOn}
						isBackVisible={false}
					/>
				</Footer>
			</ActivitySuspense>
		</>
	);
};
export default React.memo(SpellingClinicActivity);
