import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import api from '../../api/api';
import { getContentInfo } from '../../media/mediaUtil';
import { getAudioExt } from '../../utils/audio';
import { MEDIA_SERVER_URL } from '../../utils/constants';
import { transitionToNextActivity } from '../../utils/navigation';
import { bulkReplace } from '../../utils/stringUtils';
import { loadSleep } from '../../utils/ui';
import media from '../../api/media';

const EXT = getAudioExt();

export const ContextPassageUtil = {
	ACTIVITY_NAME: 'context-passages',
	MAX_TRIAL: 2,
	MAX_ATTEMPTS: 3,
	SEGMENTS: `/content/segments/r180u_segments_{stage}.json`,
	WORD_CARD: `/content/dictio/word/r180u_content_{word}.json`,
	WORD_CONTEXT: `/assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,
	WORD_DEFINITION: `/assets/dictio/def/r180u_def_{word}.${EXT}`,

	SOUND_INTRO: `${MEDIA_SERVER_URL}assets/activi/cntxtpass/r180u_cntxtpass_intro.${EXT}`,
	SOUND_INSTRUCTION: `${MEDIA_SERVER_URL}assets/activi/cntxtpass/r180u_cntxtpass_instructions.${EXT}`,
	SOUND_HELP_TRIAL: `${MEDIA_SERVER_URL}assets/activi/cntxtpass/r180u_cntxtpass_trialhelp.${EXT}`,
	SOUND_STATICQUES: `${MEDIA_SERVER_URL}assets/activi/cntxtpass/r180u_cntxtpass_staticques.${EXT}`,
	SOUND_FIRSTTRY: `${MEDIA_SERVER_URL}assets/activi/cntxtpass/r180u_cntxtpass_correctfirsttry.${EXT}`,
	SOUND_SECONDTRY: `${MEDIA_SERVER_URL}assets/activi/cntxtpass/r180u_cntxtpass_correctfirsttry.${EXT}`,
	SOUND_INCORRECT: `${MEDIA_SERVER_URL}assets/activi/cntxtpass/r180u_cntxtpass_incorrect.${EXT}`,

	WORD_SOUND_URL: `${MEDIA_SERVER_URL}assets/dictio/word/r180u_word_{word}.${EXT}`,
	WORD_CTX_URL: `${MEDIA_SERVER_URL}assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,
	WORD_DEF_URL: `${MEDIA_SERVER_URL}assets/dictio/def/r180u_def_def-{word}.${EXT}`,

	init: async mediaConstants => {
		const start = new Date().getTime();

		// //////////////
		// // load the activity sounds
		// //////////////
		AudioPlayer2.loadSound(ContextPassageUtil.SOUND_INTRO);
		AudioPlayer2.loadSound(ContextPassageUtil.SOUND_INSTRUCTION);
		AudioPlayer2.loadSound(ContextPassageUtil.SOUND_HELP_TRIAL);
		AudioPlayer2.loadSound(ContextPassageUtil.SOUND_STATICQUES);
		AudioPlayer2.loadSound(ContextPassageUtil.SOUND_FIRSTTRY);
		AudioPlayer2.loadSound(ContextPassageUtil.SOUND_SECONDTRY);
		AudioPlayer2.loadSound(ContextPassageUtil.SOUND_INCORRECT);
		const videoID = `r180u_video_${mediaConstants.stage}${mediaConstants.segment}`;
		const videoCaptionID = `r180u_${mediaConstants.stage}${mediaConstants.segment}`;
		const videoUrl = `${MEDIA_SERVER_URL}/assets/video/${videoID}.mp4`;
		const videoCaptionInfo =
			[{
				src: `${MEDIA_SERVER_URL}/assets/video/${videoCaptionID}.vtt`,
				label: 'English subtitles',
				kind: 'subtitles',
				srcLang: 'en'
			}];

		const end = new Date().getTime();
		await loadSleep(end - start);

		return { videoUrl, videoCaptionInfo };
	},

	fetchPassageContent: async (urlPath, ext) => {
		let dataArray = [];
		dataArray = await Promise.all([
			media.getOne(urlPath.replace('count', 1).replace('ext', ext)),
			media.getOne(urlPath.replace('count', 2).replace('ext', ext)),
			media.getOne(urlPath.replace('count', 3).replace('ext', ext))
		]);
		return dataArray;
	},

	// move to the next activity
	completeActivity: async (history, activityData, attemptInfo, studentSegmentId) => {
		await transitionToNextActivity(history, {
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			},
			contextpassage: {
				passages: attemptInfo,
				studentSegmentId: studentSegmentId
			}
		});
	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendProgressToServer: (activityData, attemptInfoArray, studentSegmentId) => {
		let newActivityData = {
			activity: {
				isComplete: false,
				studentActivityId: activityData.studentActivityId
			},
			contextpassage: {
				passages: attemptInfoArray,
				studentSegmentId: studentSegmentId
			}
		};
		api.activity.updateActivity(newActivityData);
	},

	getWordAttempt: (attemptInfoArray, wordIndex, isValid) => {
		let wordAttemptCount = 1;
		if (attemptInfoArray.length > 0) {
			const prevWordsInfo = attemptInfoArray[attemptInfoArray.length - 1].words;
			if (prevWordsInfo.length > 0) {
				wordAttemptCount = prevWordsInfo[wordIndex].isCorrect
					? prevWordsInfo[wordIndex].wordAttempts
					: prevWordsInfo[wordIndex].wordAttempts + 1;
			}
		}
		return wordAttemptCount;
	},
	loadAudioForSelectedWord: (wordText, activityData) => {
		const word = wordText;
		const constants = {
			word: word,
			asset_id: getContentInfo(activityData, false).assetId
		};
		const word_text_url = bulkReplace(ContextPassageUtil.WORD_SOUND_URL, constants);
		const word_ctx_url = bulkReplace(ContextPassageUtil.WORD_CTX_URL, constants);
		const word_def_url = bulkReplace(ContextPassageUtil.WORD_DEF_URL, constants);

		//the pronounced word
		AudioPlayer2.load({ name: word, src: word_text_url });

		//the context
		AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });

		//the definition
		AudioPlayer2.load({ name: `def-${word}`, src: word_def_url });
	}
};
