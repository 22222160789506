import React, {useRef, useState} from 'react';
import {ActivityInstructionButton, ActivitySupportButton, SpellingChallengeList, WordList} from '@reading/common';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import {CaptionedAudioPlayer2 as AudioPlayer2, StartActivity} from '@reading/common';
import useIdleHelp from '../../utils/useIdleHelp';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {cloneDeep, isEmpty} from 'lodash';
import {uiSlice} from '../../store/slices/ui';
import {SpellingChallenge} from './SpellingChallenge';
import ActivityFrame from '../../containers/App/ActivityFrame';
import {transitionFromResultsPageToNextActivity} from '../../utils/navigation';
import useBatchedSetState from '../../utils/useBatchedSetState';
import { sleep } from '../../utils/sleep';

export default function SpellingChallengeActivity(props) {

	const dispatch = useDispatch();
	const history = useHistory();
	const {setState} = useBatchedSetState();
	const focusWithoutSounds = useRef(false);

	const trialSize = SpellingChallenge.TRIAL_SIZE;

	const {activityData, reviewList, studyList, fluencyAssessment, settings} = useSelector(
		state => {
			return {
				activityData: state.activity.activityData,
				reviewList: state.activity.reviewlist ? state.activity.reviewlist.fluencyScores : [],
				studyList: state.activity.studylist ? state.activity.studylist.fluencyScores : [],
				fluencyAssessment: state.activity.fluencyassessment,
				settings: state.session.session.settings
			};
		}
	);

	const isActivityCharged = useSelector(state => {
		return state.ui.chargedActivities['spelling-challenge'] === true;
	});

	const isActivity = activityData ? activityData.activityServerId === 'spelling_challenge' : false;

	const [status, setStatus] = useState(FooterForwardBack.INITIAL);
	const [selectedWord, setSelectedWord] = useState('');
	const [isCompleted, setIsCompleted] = useState(false);
	const [trailRoundCount, setTrailRoundCount] = useState(1);
	const [trailCount, setTrailCount] = useState(0);
	const [showReviewPage, setShowReviewPage] = useState(false);
	const [correctWordList, setCorrectWordList] = useState([]);
	const [incorrectWordList, setIncorrectWordList] = useState([]);
	const [totalWordsList, setTotalWordsList] = useState([]);
	const [pageNo, setPageNo] = useState(0);
	const [isPaused, setIsPaused] = useState(false);
	const [wordList, setWordList] = useState([]);
	const [trailResponseMap, setTailResponseMap] = useState([]);
	const [showContent, setShowContent] = useState(false);

	useDeepCompareEffect(() => {

		const initialize = async () => {
			const {studyWords, reviewWords} = await SpellingChallenge.init(
				activityData,
				studyList,
				reviewList,
				isActivityCharged,
				settings
			);

			const {trialWords, totalWords} = SpellingChallenge.getSpellWords(
				Math.max(1, fluencyAssessment.recall),
				studyWords,
				reviewWords,
				pageNo,
				trialSize
			);

			setState(() => {
				setWordList(trialWords);
				setTotalWordsList(totalWords);
			});

			dispatch(uiSlice.actions.setActivityCharged('spelling-challenge'));
			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				AudioPlayer2.loadSound(SpellingChallenge.SOUND_INTRO);
				AudioPlayer2.play(SpellingChallenge.SOUND_INTRO);
			}
		};
		if (reviewList && studyList && isActivity) {
			initialize();
		}
	}, [reviewList, studyList]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	const handleForward = async e => {
		AudioPlayer2.stopAll();
		const allWordsIncorrect = incorrectWordList.length === studyList.length + reviewList.length;

		if (!isCompleted && !allWordsIncorrect) {
			if (trailCount < totalWordsList.length) {
				loadNextRoundOfTrail();
				setStatus(FooterForwardBack.INITIAL);
			} else {
				const {trialWords, totalWords} = SpellingChallenge.getSpellWords(
					3,
					studyList,
					reviewList,
					0,
					trialSize
				);
				setState(() => {
					setWordList(trialWords);
					setTotalWordsList(totalWords);
					setStatus(FooterForwardBack.INITIAL);
				});
			}
		}

		if (showReviewPage) {
			try {
				await transitionFromResultsPageToNextActivity(history);
				return;
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		}
		else if (
			(status === FooterForwardBack.VALID && isCompleted) ||
			trailCount >= SpellingChallenge.MAX_TRIAL_COUNT ||
			allWordsIncorrect
		) {
			// complete the activity without navigating, no need to await the response
			SpellingChallenge.completeActivityNoTransition(
				activityData,
				trailRoundCount, // TODO This always seems to be 1, but must be 3 to go to Proofreading
				fluencyAssessment,
				trailResponseMap
			);
			setShowReviewPage(true);
			return;
		}

	};

	const checkActivityComplete = correctList => {
		// Completion criteria is 100% study list complete
		if (correctList.length === studyList.length) {
			AudioPlayer2.stopAll();
			setState(() => {
				setIsCompleted(true);
				setStatus(FooterForwardBack.VALID);
			});
		}
	};

	const validate = (word, typedWord, _e, index) => {
		// figure out if what they typed is correct
		const isCorrect = word === typedWord;
		let correctList = [];

		if (isCorrect) {
			if (studyList.find(w => w.wordId === word)) {
				correctList = [...correctWordList, word];
				AudioPlayer2.play('continue');
				setCorrectWordList(correctList);
			}
			setTrailCount(trailCount + 1);
			sendTrailProgressToServer(isCorrect, word, typedWord);
		}

		checkActivityComplete(correctList);
	};

	const loadNextRoundOfTrail = () => {
		const trailroundStartIndex = trailRoundCount * SpellingChallenge.TRIAL_SIZE;
		const newTrailRoundCount = trailRoundCount + 1;
		const nextRoundWordList = totalWordsList.slice(
			trailroundStartIndex,
			Math.min(SpellingChallenge.TRIAL_SIZE, totalWordsList.length) * newTrailRoundCount
		);
		SpellingChallenge.loadNextRoundWordSound(nextRoundWordList, activityData);
		setState(() => {
			setTrailRoundCount(newTrailRoundCount);
			setWordList(nextRoundWordList);
		});
	};

	const handleWordFocus = async(word) => {
		if (focusWithoutSounds.current === false) {
			AudioPlayer2.stopAll();
			await sleep(550);
			await AudioPlayer2.playSync(word);
			AudioPlayer2.play(`ctx-${word}`);
			setSelectedWord(word);
		}
	};

	const handleKeyPress = (key, word, typedWord, isHomophoneWord) => {
		AudioPlayer2.stopAll();
		if (isHomophoneWord) {
			AudioPlayer2.play('wrong_homophone');
		}
		if (key === 'Enter') {
			validate(word, typedWord);
		}
	};

	const handleWordBlur = (word, typedWord, _e, index) => {
		AudioPlayer2.stopAll();
	};

	const handleWordClick = e => {
		AudioPlayer2.stopAll();
		if (isEmpty(selectedWord) === false) {
			AudioPlayer2.play(selectedWord);
		}
		focusWithoutSounds.current = true;
		const focusedDiv = document.getElementsByClassName('hasFocus')[0];
		focusedDiv.getElementsByClassName('spell-input')[0].focus();
		focusWithoutSounds.current = false;
	};

	const handleSentenceClick = e => {
		AudioPlayer2.stopAll();
		if (isEmpty(selectedWord) === false) {
			AudioPlayer2.play(`ctx-${selectedWord}`);
		}
		focusWithoutSounds.current = true;
		const focusedDiv = document.getElementsByClassName('hasFocus')[0];
		focusedDiv.getElementsByClassName('spell-input')[0].focus();
		focusWithoutSounds.current = false;
	};

	const handleIncorrectWord = typed => {
		setState(() => {
			setTrailCount(trailCount + 1);
			setIncorrectWordList([...incorrectWordList, typed]);
		});
		sendTrailProgressToServer(false, selectedWord, typed);
	};

	const sendTrailProgressToServer = (isCorrect, word, typedWord) => {
		const wordScore = isCorrect ? SpellingChallenge.FLUENT : SpellingChallenge.MISSED;
		let newTrailResponseMap = cloneDeep(trailResponseMap);
		newTrailResponseMap.push({wordId: word, answer: typedWord, score: wordScore});
		setTailResponseMap(newTrailResponseMap);
		SpellingChallenge.sendProgressToServer(activityData, trailRoundCount, fluencyAssessment, newTrailResponseMap);
	};

	const handlePauseClick = e => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play('pause');
		setIsPaused(!isPaused);
	};

	const handleTrialCompleted = () => {
		AudioPlayer2.stopAll();
		/* to avoid overlap of VO */
		AudioPlayer2.play('feedback');
		setState(() => {
			setSelectedWord('');
			//setTailResponseMap([]);
			setStatus(FooterForwardBack.VALID);
		});
	};

	useIdleHelp(SpellingChallenge.SOUND_HELP_TRIAL);

	const setContentStatus = contentStatus => {
		AudioPlayer2.stopAll();
		setShowContent(contentStatus);
	}

	return (
		<>
			<Navbar helpSoundUrl={SpellingChallenge.SOUND_HELP_TRIAL} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={[wordList]}
				title="Spelling Challenge"
			>
				<ActivityFrame isWhiteBackground={showReviewPage === false}>
					<>
						<ActivityInstructionButton audioName={'intro'} white={showReviewPage} />
						{!showContent ? (
							<StartActivity
								onStartActivity={setContentStatus}
								startActivityAudioSrc={SpellingChallenge.SOUND_INTRO}
							/>
						) : showReviewPage ? (
							<div className="mt-4" style={{margin: 'auto', textAlign: 'center'}}>
								<div style={{display: 'inline-block', marginRight: '56px'}}>
									<WordList
										wordList={correctWordList.map(word => {
											return word;
										})}
									/>
								</div>
								<div style={{display: 'inline-block'}}>
									<WordList
										isCorrect={false}
										wordList={incorrectWordList.map(word => {
											return word;
										})}
									/>
								</div>
							</div>
						) : (
							<div style={{position: 'relative'}} className="fill-app-frame">
								<SpellingChallengeList
									onWordFocus={handleWordFocus}
									onWordBlur={handleWordBlur}
									onWordKeyPress={handleKeyPress}
									wordObjList={wordList}
									onIncorrectWord={handleIncorrectWord}
									onTrialCompleted={handleTrialCompleted}
									selectedWord={selectedWord}
									trailCount={trailRoundCount}
									isPaused={isPaused}
									correctWordList={correctWordList}
									studyList={studyList}
									isCompleted={isCompleted}
								/>
							</div>
						)}
					</>
				</ActivityFrame>

				<Footer>
					{showReviewPage === false && (
						<>
							<ActivitySupportButton
								icon="word"
								onClick={handleWordClick}
								text="Word"
								disabled={isEmpty(selectedWord) || isPaused}
							/>
							<ActivitySupportButton
								icon="sentence"
								onClick={handleSentenceClick}
								text="Sentence"
								disabled={isEmpty(selectedWord) || isPaused}
							/>
							<ActivitySupportButton
								icon={isPaused ? 'play' : 'pause'}
								onClick={handlePauseClick}
								text={isPaused ? 'Play' : 'Pause'}
								disabled={isEmpty(selectedWord)}
							/>
						</>
					)}

					<FooterForwardBack status={status} onForward={handleForward} isBackVisible={false} />
				</Footer>
			</ActivitySuspense>
		</>
	);
}
