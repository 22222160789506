import React, {useState} from 'react';
import HoverTip from '../HoverTip/HoverTip.js';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import useRouteInfo from '@reading/r180/src/utils/useRouteInfo';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';

const useStyles = makeStyles(theme => ({
	instructionsButton: {
		position: 'absolute',
		left: '8px',
		top: '8px',
		zIndex: '10',
		transition: 'transform 0.3s ease',
		'& .instruction-button': {
			position: 'relative',
			display: 'inline-block',
			background: 'transparent',
			width: '24px',
			height: '24px',
			outline: 'none',
			textAlign: 'center',
			cursor: 'pointer',
			transition: 'transform 0.3s ease',
			'& i': {
				color: theme.colors.softBlack,
				fontSize: '24px'
			}
		},
		'&.without-frame': {
			left: `${window.innerWidth / 15 - 60}px`,
			'& i': {
				color: `${theme.colors.white} !important`
			}
		},
		'&.white': {
			'& i': {
				color: `${theme.colors.white} !important`
			}
		},
		'&.isPlaying': {
			'& i': {
				color: `${theme.colors.readingZone.primary} !important`
			}
		},
		'&.fs': {
			top: '48px'
		},
		'&:hover': {
			transform: 'scale(1.2)'
		},
		'&.disable': {
			'& button': {
				opacity: '0.5',
				cursor: 'not-allowed'
			}
		}
	}
}));

const ActivityInstructionButton = props => {
	const {
		audioSrc,
		audioName,
		withoutFrame,
		style,
		white,
		fs,
		onSoundStart,
		onSoundEnd,
		disabled
	} = props;
	const {zone} = useRouteInfo();
	const classes = useStyles(zone);

	const [playing, setPlaying] = useState(false);

	const audioReference = audioSrc || audioName;

	const handleClick = async e => {
		e.stopPropagation();
		if (disabled === true) {
			e.preventDefault();
			return false;
		}
		if (playing) {
			AudioPlayer2.stop(audioReference);
			setPlaying(false);
			if (typeof onSoundEnd === 'function') {
				onSoundEnd();
			}
		} else {
			if (typeof onSoundStart === 'function') {
				onSoundStart();
			}
			AudioPlayer2.stopAll();
			setPlaying(true);
			await AudioPlayer2.playSync(audioReference);
			setPlaying(false);
			if (typeof onSoundEnd === 'function') {
				onSoundEnd();
			}
		}
	};

	return (
		<HoverTip title='Click the icon for more information'>
			<div className={`${classes.instructionsButton}
			                 ${withoutFrame ? 'without-frame' : ''}
							 ${white ? 'white' : ''}
							 ${playing ? 'isPlaying' : ''}
							 ${fs ? 'fs' : ''}
							${disabled ? 'disable' : ''}`}
				style={style}
			>
				<button
					id={`instruction-button`}
					data-id="instruction-button"
					className="instruction-button"
					aria-label={'instructions'}
					onClick={handleClick}
				>
					<i className={`r180 instructions`} />
				</button>
			</div>
		</HoverTip>
	);
};

ActivityInstructionButton.defaultProps = {
	audioSrc: '',
	audioName: '',
	withoutFrame: false,
	white: false,
	style: {},
	fs: false,
	disabled: false
};
ActivityInstructionButton.propTypes = {
	audioSrc: PropTypes.string,
	audioName: PropTypes.string,
	withoutFrame: PropTypes.bool,
	white: PropTypes.bool,
	style: PropTypes.object,
	fs: PropTypes.bool,
	onSoundStart: PropTypes.func,
	onSoundEnd: PropTypes.func,
	disabled: PropTypes.bool
};

export default React.memo(ActivityInstructionButton);
