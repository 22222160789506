import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import WordTitle from '../../components/WordTitle';
import ActivityButton from '../../components/ActivityButton';

export const useStyles = makeStyles(theme => ({
	startActivityPage: {
		background: theme.colors.white,
		display: 'flex',
		alignItems: 'center',
		verticalAlign: 'middle',
		justifyContent: 'center',
		width: '100%',
		height: '100%'
	},
	componentWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexFlow: 'column',
		width: '520px',
		height: '192px',
		borderRadius: '8px',
		boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.35)',
		'& #testMic-wordText': {
			background: theme.colors.white,
			'& i': {
				color: theme.colors.slate
			},
			'& span': {
				color: theme.colors.black,
				fontFamily: theme.fonts.uiBold,
				fontStyle: 'normal',
				fontWeight: 'bold',
				fontSize: '32px',
				lineHeight: '38px'
			}
		}
	},
	btnWrapper: {
		marginTop: '18px'
	}
}));

const StartActivity = props => {
	const {id, onStartActivity, startActivityAudioSrc, isDisabled} = props;
	const classes = useStyles();

	return (
		<div id={id} className={classes.startActivityPage}>
			<div className={classes.componentWrapper}>
				<WordTitle
					id="testMic-wordText"
					className="pageTitle"
					word={'Press start to begin.'}
					src={startActivityAudioSrc}
					commonTextSpeaker={true}
				/>
				<div className={classes.btnWrapper}>
					<ActivityButton
						data-testid="primaryButton"
						id="primaryButton"
						text={'Start'}
						icon="go-on"
						onClick={onStartActivity}
						disabled={isDisabled}
					/>
				</div>
			</div>
		</div>
	);
};

StartActivity.defaultProps = {
	id: '',
	startActivityAudioSrc: '',
	isDisabled: false
};

StartActivity.propTypes = {
	id: PropTypes.string.isRequired,
	onStartActivity: PropTypes.func.isRequired,
	startActivityAudioSrc: PropTypes.string,
	isDisabled: PropTypes.bool
};

export default React.memo(StartActivity);
