import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		height: '72px',
		width: 'auto',
		display: 'inline-block',
		textAlign: 'center',
		transition: 'all ease 0.3s',
		cursor: 'pointer',
		'& .number': {
			fontSize: '16px',
			color: theme.colors.white,
			height: '20px',
			width: '20px',
			lineHeight: '18px',
			background: theme.colors.slate,
			borderRadius: '10px',
			position: 'absolute',
			top: '-7px',
			left: 'calc(50% + 7px)',
			textAlign: 'center',
			zIndex: '80',
		},
		'&:hover': {
			transform: 'scale(1.2)',
			'& .number': {
				background: theme.colors.readingZone.primary
			},
			'& .circle': {
				borderColor: theme.colors.readingZone.background,
				color: theme.colors.readingZone.background
			},
			'& .text': {
				color: theme.colors.readingZone.background
			}
		}
	},
	circle: {
		position: 'relative',
		height: '48px',
		width: '48px',
		lineHeight: '48px',
		borderRadius: '24px',
		border: `2px solid ${theme.colors.slate}`,
		textAlign: 'center',
		margin: 'auto',
		marginBottom: '12px',
		color: theme.colors.slate,
		fontSize: '24px'
	},
	text: {
		fontSize: '16px',
		fontWeight: '700',
		color: theme.colors.slate,
		textAlign: 'center'
	}
}));

const TitleIcon = props => {
	const {id, className, icon, text, number} = props;
	const classes = useStyles();

	return (
		<div className={`${classes.wrapper} ${className}`} id={id}>
			<span className='number'>{number}</span>
			<div className={`${classes.circle} circle`}>
				<i className={`r180 ${icon}`} />
			</div>
			<div className={`${classes.text} text`}>
				{text}
			</div>
		</div>
	);
};

TitleIcon.defaultProps = {
	id: '',
	className: '',
	icon: '',
	text: '',
	number: 1,
	selected: false
};
TitleIcon.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	icon: PropTypes.string,
	text: PropTypes.string,
	number: PropTypes.number,
	selected: PropTypes.bool
};

export default React.memo(TitleIcon);
