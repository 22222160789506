import { CaptionedAudioPlayer2 as AudioPlayer2 } from "@reading/common";
import { useIdleTimer } from "react-idle-timer";
import { TIMEOUT } from "./constants";

const useIdleHelp = (soundUrl) => {

	const handleActive = () => {};
	const handleAction = () => {};

	const handleIdle = () => {
		if(localStorage.getItem("quiet")){return;}
		AudioPlayer2.load({
			name: 'help-timeout',
			src: soundUrl
		});

		AudioPlayer2.play('help-timeout');
	};

	useIdleTimer({
		timeout: TIMEOUT.INACTIVITY_HELP,
		onIdle: handleIdle,
		onActive: handleActive,
		onAction: handleAction,
		debounce: 500
	  });
}

export default useIdleHelp;
