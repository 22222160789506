import React from 'react';
import ReadAndRecordActivity from '../activities/ReadAndRecord/ReadAndRecordActivity';
import ReadingPassageActivity from '../activities/ReadingPassage/ReadingPassageActivity';
import ReadingPassageActivityB from '../activities/ReadingPassage/ReadingPassageActivityB';
import ReadingStrategySelector from '../activities/ReadingStrategySelector/ReadingStrategySelector';

const readingZoneRoutes = [
	{
		route: 'read_and_record',
		serverRoute: 'read_and_record',
		pageTitle: 'Read & Record',
		component: <ReadAndRecordActivity />,
		author: ['Luc', 'Kiran']
	},
	{
		route: 'reading_passage_rz',
		serverRoute: 'reading_passage_rz',
		pageTitle: 'Anchor Text',
		component: <ReadingPassageActivity />,
		author: ['Luc', 'Kiran']
	},
	{
		route: 'reading_passage_rz_b',
		serverRoute: 'reading_passage_rz_b',
		pageTitle: 'Anchor Text B',
		component: <ReadingPassageActivityB />,
		author: ['Luc', 'Kiran']
	},
	{
		route: 'reading_strategy_selector',
		serverRoute: 'reading_strategy_selector',
		pageTitle: 'Reading Strategy Selector',
		component: <ReadingStrategySelector />,
		author: ['Luc', 'Kiran']
	},
	{
		route: 'reading_strategy_selector_sorting',
		serverRoute: 'reading_strategy_selector',
		pageTitle: 'Reading Strategy Selector - Sorting',
		component: <ReadingStrategySelector />,
		author: ['Luc', 'Kiran']
	},
];

export default readingZoneRoutes;
