import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import { Fragment } from 'react';

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		width: '40px',
		height: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		left: 'calc(14% - 121px)'
	},
	letter: {
		position: 'relative',
		height: '32px',
		width: '32px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: `2px solid ${theme.colors.white}`,
		borderRadius: '16px',
		background: theme.colors.writingZone.background,
		textAlign: 'center',
		fontFamily: theme.fonts.uiBold,
		fontSize: '18px',
		color: theme.colors.white,
		textTransform: 'uppercase',
		zIndex: '10',
		cursor: 'pointer',
		transition: 'all 0.3s ease',

		'&.selected': {
			height: '40px',
			width: '40px',
			borderRadius: '20px',
			borderColor: theme.colors.writingZone.green3,
			color: theme.colors.writingZone.background,
			fontSize: '24px',
			background: theme.colors.white,
			boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.35)'
		},

		'&.tree': {
			borderRadius: '0',
			border: 'none',
			boxShadow: 'none',
			backgroundImage: 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1NiA1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTYgNTc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojRkJGQ0ZEO3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yOC40LDEuNUMyOC4yLDEuMiwyNy45LDEsMjcuNSwxcy0wLjcsMC4yLTAuOSwwLjVMMy43LDQxLjJjLTAuMiwwLjMtMC4yLDAuNywwLDFjMC4yLDAuMywwLjUsMC41LDAuOSwwLjUKCWg0NS45YzAuNCwwLDAuNy0wLjIsMC45LTAuNXMwLjItMC43LDAtMUwyOC40LDEuNXoiLz4KPC9zdmc+Cg==")',
			backgroundSize: '110% 120%',
			backgroundPosition: '-1.5px -2px',
			height: '40px',
			width: '40px',
			'&.selected': {
				background: 'none',
				backgroundImage: 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2MyA2MyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjMgNjM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkJGQ0ZEO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojNzFEQzQ3O3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTMxLjUsOGwyMi45LDM5LjhIOC42TDMxLjUsOHoiLz4KCTxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0zMi40LDcuNUMzMi4yLDcuMiwzMS45LDcsMzEuNSw3cy0wLjcsMC4yLTAuOSwwLjVMNy43LDQ3LjJjLTAuMiwwLjMtMC4yLDAuNywwLDFzMC41LDAuNSwwLjksMC41aDQ1LjkKCQljMC40LDAsMC43LTAuMiwwLjktMC41czAuMi0wLjcsMC0xTDMyLjQsNy41eiIvPgo8L2c+Cjwvc3ZnPgo=")',
				backgroundSize: '130% 130%',
				backgroundPosition: '-6px -6px',
			}
		},
	},
	dotted: {
		width: '0px',
		margin: '8px 0',
		height: '32px',
		borderRight: `dotted 2px ${theme.colors.white}`,

		'&:last-child': {
			height: 0
		}
	}
}));

const AcronymBanner = props => {
	const {
		id,
		className,
		acronym,
		letterIndex,
		audioPaths
	} = props;

	const classes = useStyles();

	const letters = acronym.split('');

	const playAudio = idx => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(audioPaths[idx]);
	};

	const isTree = acronym.toLowerCase() === 'tree';

	return (
		<div id={id} className={`${classes.wrapper} ${className} acronym-banner`}>
			{letters.map((letter, idx) => {
				return (
					<Fragment key={letter + idx}>
						<button
							className={`${classes.letter} ${isTree ? 'tree' : ''} ${letterIndex === idx ? 'selected' : ''}`}
							onClick={() => playAudio(idx)}
							data-testid={'acronymCircle'}
						>
							{letter}
						</button>
						<div className={`${classes.dotted}`}></div>
					</Fragment>
				);
			})}
		</div>
	);
};

AcronymBanner.defaultProps = {
	id: '',
	className: '',
	acronym: ''
};
AcronymBanner.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	acronym: PropTypes.string.isRequired
};

export default React.memo(AcronymBanner);
