import axios from 'axios';

export default baseEndpoint => ({
	getCorrectiveFeedbackData: (word, feedbackWord) => {
		const payload = {
			id: '1',
			wordText: word,
			segmentation: word,
			wordBase: '',
			tipCode: '0',
			baseChange: '0'
		}
		const url = `${baseEndpoint}/spellingcf/word/${word}/spelling/${feedbackWord}`;
		return axios.get(url, { payload }).then(response => response.data)
	}
});
