import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { bulkReplace } from '../../../utils/stringUtils';
import { loadSleep } from '../../../utils/ui';
import { FoundationalUtil } from '../FoundationalUtil';
import { ProgressMonitorConstants } from './ProgressMonitorConstants';
import { isEmpty, shuffle } from 'lodash';
import { getAudioExt } from '../../../utils/audio';
import { getRandomInt } from '../../../utils/random';
import { sleep } from '../../../utils/sleep';



const EXT = getAudioExt();

// 1: fluent, 2: correct, 3: missed , 4:outoftime

export const ProgressMonitorUtil = {

	initialize: async (params) => {

		const { contentData, isActivityCharged } = params;
		const start = new Date().getTime();
		const wordList = contentData && contentData.activity_words;
		let assessmentWords = [];
		const scoreMap = {};

		if (!isEmpty(wordList)) {
			wordList.forEach(word => {
				const words = [
					word.text,
					...shuffle(word.distractors).slice(0, ProgressMonitorConstants.MAX_DISTRACTION_WORDS)
				];

				assessmentWords.push({
					id: word.id,
					name: word.text,
					type: word.type,
					words: words.map(w => ({ name: w })),
					trialNo: 1
				});
				scoreMap[word.text] = {
					1: 0,
					2: 0,
					3: 0,
					type: word.type
				}


				//Load sound for word
				const wordtext = word.text;
				const constants = {
					wordtext: wordtext,
				};
				const word_text_url = bulkReplace(ProgressMonitorConstants.WORD_PATH, constants);
				const word_ctx_url = bulkReplace(ProgressMonitorConstants.WORD_SENTENCE_PATH, constants);
				AudioPlayer2.load({ name: word.text, src: word_text_url });
				AudioPlayer2.load({ name: `ctx-${word.text}`, src: word_ctx_url });
			});
		}
		assessmentWords = shuffle(assessmentWords);

		// load the play pause continue
		AudioPlayer2.loadSound(ProgressMonitorConstants.PLAY);
		AudioPlayer2.loadSound(ProgressMonitorConstants.PAUSE);
		AudioPlayer2.loadSound(ProgressMonitorConstants.CONTINUE);

		// load the long intro
		AudioPlayer2.loadSound(ProgressMonitorConstants.LONG_INTRO);
		AudioPlayer2.loadSound(ProgressMonitorConstants.AO_LONG_INTRO);
		AudioPlayer2.loadSound(ProgressMonitorConstants.AO_SHORT_INTRO);

		// load the short intro
		ProgressMonitorConstants.SHORT_INTRO.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});

		//load the complete audio
		ProgressMonitorConstants.COMPLETE.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});


		// load the help sound
		AudioPlayer2.loadSound(ProgressMonitorConstants.FEEDBACK_HELP);
		AudioPlayer2.loadSound(ProgressMonitorConstants.HELP_1);
		AudioPlayer2.loadSound(ProgressMonitorConstants.HELP_2);
		AudioPlayer2.loadSound(ProgressMonitorConstants.HELP_3);

		// load the feedback screen header audio
		AudioPlayer2.loadSound(ProgressMonitorConstants.FEEDBACK_FLUENT);
		AudioPlayer2.loadSound(ProgressMonitorConstants.FEEDBACK_SLOW);
		AudioPlayer2.loadSound(ProgressMonitorConstants.FEEDBACK_MISSED);

		// load the feedback screen business logic audio
		ProgressMonitorConstants.FEEDBACK_PASS_TOPICLESS.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});

		ProgressMonitorConstants.FEEDBACK_PROPASS_TOPIC.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});

		ProgressMonitorConstants.FEEDBACK_NOPASS_WORDLOW.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});

		ProgressMonitorConstants.FEEDBACK_PROMOTION_CLOSE.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});

		ProgressMonitorConstants.FEEDBACK_STUDYWORDS_LOWREP.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});

		ProgressMonitorConstants.FEEDBACK_STUDYWORDS_HIGHREP.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});


		const end = new Date().getTime();
		if (isActivityCharged === false) {
			await loadSleep(end - start);
		}

		return {
			assessmentWords, scoreMap
		};
	},

	getFluencyCorrectTimeout: (contentData) => {
		let timeout = 0;
		const responseTimeLimit = contentData && contentData.response_time_limit ? contentData.response_time_limit : ProgressMonitorConstants.REPONSE_TIME_LIMIT;
		const fluencyThreshold = contentData && contentData.fluency_threshold ? contentData.fluency_threshold : ProgressMonitorConstants.FLUNCEY_THRESHOLD;
		timeout = responseTimeLimit / 1000 + fluencyThreshold / 1000;
		return timeout;
	},

	isThirdRound: (trials) => {
		const score = `${trials[1]}${trials[2]}`;
		return !ProgressMonitorConstants.CompletionCriteria.includes(score);
	},

	evaluateResults: (scores) => {
		// Refer the logic table from './trialScoreLogic.md'
		const results = { fluent: [], slow: [], missed: [] };
		let wordScore = 0;
		Object.keys(scores).map(item => {

			const score = `${scores[item][1]}${scores[item][2]}${scores[item][3]}`;
			if (scores[item].type === ProgressMonitorConstants.TARGET) {
				wordScore = ProgressMonitorConstants.TargetEvaluationMap[score];
			} else {
				wordScore = ProgressMonitorConstants.ReviewEvaluationMap[score];
			}

			switch (wordScore) {
				case 1:
					results.fluent.push(item);
					break;

				case 2:
					results.slow.push(item);
					break;
				case 3:
					results.missed.push(item);
					break;
				case 4:
					results.missed.push(item);
					break;
				default:
					results.missed.push(item);
					break;
			}
		});

		return results;
	},

	evaluateResultPercentage: (scores, type) => {

		const results = { fluent: 0, slow: 0, missed: 0 };
		const totalWordCount = Object.keys(scores).length;
		const filteredScoreMap = Object.keys(scores).filter(item => scores[item].type === type).map(item => scores[item]);
		const scoreMap = ProgressMonitorUtil.evaluateResults(filteredScoreMap);
		results.fluent = Math.round(scoreMap.fluent.length / totalWordCount * 100);
		results.slow = Math.round(scoreMap.slow.length / totalWordCount * 100);
		results.missed = Math.round(scoreMap.missed.length / totalWordCount * 100);
		return results;

	},

	feedbackScreenVO: async (scoreMap, trailCount, isAutoPlay) => {

		const resultPercentage = ProgressMonitorUtil.evaluateResultPercentage(scoreMap, "target");
		const slowAndMissedPercentage = resultPercentage.slow + resultPercentage.missed;

		// Lesson Attempt = Trail Attempt.
		// Lesson Attempt max = Trail Count Max (3)
		// Progress Monitor Repetitions per Study List Max = Trail Count Max (3)	

		if (isAutoPlay) {
			// activity completed 
			await AudioPlayer2.playSync(ProgressMonitorConstants.COMPLETE[getRandomInt(0, 4)]);
			await sleep(500);

			if (resultPercentage.fluent > ProgressMonitorConstants.ACTIVITY_COMPLETION_PER && trailCount < ProgressMonitorConstants.MAX_TRAIL_COUNT) {
				//Progress Monitor is passed AND Lesson Attempts < Progress Monitor Lesson Max: pass_Topicless
				AudioPlayer2.play(FoundationalUtil.getRandomSound(ProgressMonitorConstants.FEEDBACK_PASS_TOPICLESS));
			}
			else if (slowAndMissedPercentage > 0 && slowAndMissedPercentage <= 15) {
				//15% of Target words or less scored as Correct ,Missed or out of Time
				AudioPlayer2.play(FoundationalUtil.getRandomSound(ProgressMonitorConstants.FEEDBACK_PROMOTION_CLOSE));
			}
			else if ((slowAndMissedPercentage > 15 && slowAndMissedPercentage <= 30) && trailCount <= ProgressMonitorConstants.MAX_TRAIL_COUNT) {
				// between 15% and 30% of Target words scored as Correct or Missed or Out of Time AND Lesson Attempts ≤ Progress Monitor Repetitions per Study List Max
				AudioPlayer2.play(FoundationalUtil.getRandomSound(ProgressMonitorConstants.FEEDBACK_STUDYWORDS_LOWREP));
			}
			else if ((slowAndMissedPercentage > 30) && trailCount >= ProgressMonitorConstants.MAX_TRAIL_COUNT) {
				// More than 30 % of Target words scored as Correct or Missed or Out of Time AND Lesson Attempts >= Progress Monitor Repetitions per Study List Max
				AudioPlayer2.play(FoundationalUtil.getRandomSound(ProgressMonitorConstants.FEEDBACK_STUDYWORDS_HIGHREP));
			}
			else if (trailCount === ProgressMonitorConstants.MAX_TRAIL_COUNT) {
				//Provisionally Advanced,  Lesson Attempts = Progress Monitor Lesson Max: propass_Topicequal
				AudioPlayer2.play(FoundationalUtil.getRandomSound(ProgressMonitorConstants.FEEDBACK_PROPASS_TOPIC));
			}

			//TODO - Passed Target word criteria AND Mastered Words with a Word Score of Fluent < Review Maintenance Criteria
		}

	},

	// this activity calls for playing random intros
	getRandomizedSound: (type, isAccuracyOnly) => {
		if (type.indexOf('lintro') > -1) {
			if (isAccuracyOnly) {
				return ProgressMonitorConstants.AO_LONG_INTRO; // audio not available now, in future it will be random sound
			}
			else {
				return ProgressMonitorConstants.LONG_INTRO;
			}
		}
		else {
			if (isAccuracyOnly) {
				return ProgressMonitorConstants.AO_SHORT_INTRO; // audio not available now, in future it will be random sound
			} else {
				return FoundationalUtil.getRandomSound(ProgressMonitorConstants.SHORT_INTRO);
			}
		}

	},

	getHelpSound: (count) => {
		switch (count) {
			case 0: return ProgressMonitorConstants.HELP_3; // Independent of Autoplay setting User clicks Help button after clicking Pause to halt the activity temporarily: acthelp3
			case 1: return ProgressMonitorConstants.HELP_1;
			case 2: return ProgressMonitorConstants.HELP_2;
			default: return ProgressMonitorConstants.HELP_2;
		}
	}
}
