import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SoundButton from '../../components/SoundButton';
import { makeStyles } from '@material-ui/core';
import { DragDropContext } from 'react-beautiful-dnd';
import shuffle from 'lodash/shuffle';
import cloneDeep from 'lodash/cloneDeep';
import { dragEndHandler } from '../../components/WordMatch/drag-utility';
import { assignCoordinates } from './wordMatchUtil';
import DropHolder from '../../components/WordMatch/DropHolder';
import DragHolder from '../../components/WordMatch/DragHolder';
import { ActivityButton } from '../..';
import clsx from 'clsx';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
	wordMatch: {
		position: 'relative',
		width: '100%',
		height: 'auto',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		justifyContent: 'center',
		fontFamily: theme.fonts.context,
		'& > .leftPanel': {
			position: 'relative',
			width: '312px',
			margin: '24px 0px'
		},
		'& > .rightPanel': {
			position: 'relative',
			width: '344px',
			margin: '48px 0px 48px 96px',
			'& > .scrambled': {
				width: '344px',
				height: '456px',
				background: theme.colors.lightGrey,
				borderRadius: '16px',
				boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.25)'
			}
		}
	},
	wordRow: {
		width: '312px',
		height: '72px',
		position: 'relative',
		margin: '0',
		paddingBottom: '16px',
		marginBottom: '16px',
		textAlign: 'right',
		borderBottom: `1px solid ${theme.colors.paleGrey}`,
		'&:last-of-type': {
			borderBottom: 'none'
		},
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		lineHeight: '34px',
		color: theme.colors.black,
		fontSize: '24px',
		fontWeight: 400,
		fontStyle: 'normal',
		fontFamily: theme.fonts.context
	},
	wordWrapper: { marginRight: '40px' },
	dropHolderWrapper: { width: '56px', height: '56px' },
	soundButton: {
		color: theme.colors.grey,
		'&:hover': {
			borderColor: theme.colors.readingZone.primary,
			color: theme.colors.readingZone.primary
		}
	},
	mixButtonRow: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '312px',
		marginTop: '32px'
	},
	soundBtnWrapper: {
		position: 'absolute',
	},
	disableMixUp: {
		pointerEvents: 'none',
		cursor: 'not-allowed',
		color: `${theme.colors.paleGrey} !important`,
		border: `1px solid ${theme.colors.paleGrey} !important`
	},
	mixUpButtonStyle: {
		padding: '12px 16px',
		fontSize: '20px',
		height: '40px',
		width: '136px',
		'& span': {
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			fontStyle: 'normal',
			fontFamily: theme.fonts.ui
		},
		'& i': {
			fontSize: '16px'
		},
		'&:active:not(.disabled), &:focus:not(.disabled)': {
			boxShadow: `0 0 0 2px ${theme.colors.paleGrey}`,
		},
	}
}));

const WordMatch = props => {
	const {
		id,
		words,
		enableSubmit,
		submitFlag,
		activitySubmittedOnce,
		reset,
		autoCorrect
	} = props;
	const classes = useStyles();
	const {setState} = useBatchedSetState();

	const wordData = cloneDeep(words);
	const [isCtaDisabled, setCtaDisabled] = useState(true);
	const [playedSounds, setPlayedSounds] = useState([]);
	const [isMixup, setMixup] = useState(false);
	const [destAudioMap, setDestAudioMap] = useState({});
	const [updatedRightAudio, setUpdatedRightAudio] = useState(cloneDeep(words));
	const [updatedLeftAudio, setUpdatedLeftAudio] = useState([]);
	const [resultSet, setResultSet] = useState({});
	const [isSubmitted, setSubmitted] = useState(false);
	const [wordMapAfterSubmit, setWordMapAfterSubmit] = useState({});

	useEffect(() => {
		if (submitFlag === true) {
			onSubmit();
		}
		if (reset === true) {
			resetValues();
		}
		setSubmitted(submitFlag);
	}, [submitFlag, reset]);

	useEffect(() => {
		if (updatedRightAudio.length == 0) {
			setUpdatedRightAudio(cloneDeep(words));
		}
	}, [words]);

	useEffect(() => {
		if (autoCorrect) {
			correctOptions();
		}
	}, [autoCorrect]);

	const handleDragEnd = result =>
		dragEndHandler(
			result,
			setDestAudioMap,
			setUpdatedRightAudio,
			setUpdatedLeftAudio,
			destAudioMap,
			words,
			enableSubmit,
			updatedRightAudio,
			updatedLeftAudio,
			setResultSet,
			resultSet
		);

	const handleMixButton = () => {
		setState(() => {
			setMixup(true);
			setCtaDisabled(true);
		});
		const wordSource = [...updatedRightAudio];
		const randomizedArray = shuffle(wordSource);
		assignCoordinates(randomizedArray);
	};

	const correctOptions = () => {
		enableSubmit('Correct', resultSet);
	};

	const onSubmit = () => {
		let afterSubmitMapObj = {};
		let tempResultSet = resultSet;
		for (let i = 0; i < wordData.length; i++) {
			if (tempResultSet[wordData[i].name] === true) {
				afterSubmitMapObj[wordData[i].name] = true;
			}
		}
		setWordMapAfterSubmit(afterSubmitMapObj);
	};

	const resetValues = () => {
		let tempResultSet = resultSet;
		let destinationAudioMapper = {};
		let tempArr = [];
		let leftDraggedSet = [];
		for (let i = 0; i < wordData.length; i++) {
			if (tempResultSet[wordData[i].name] === true) {
				destinationAudioMapper[i] = true;

				leftDraggedSet[i] = wordData[i];
			} else {
				tempArr.push(wordData[i]);
			}
		}
		setState(() => {
			const randomizedArray = shuffle(tempArr);
			enableSubmit('Initial', resultSet);
			setUpdatedLeftAudio(leftDraggedSet);
			setUpdatedRightAudio([...tempArr]);
			assignCoordinates(randomizedArray);
			setDestAudioMap(destinationAudioMapper);
			setPlayedSounds([]);
		})
	};

	const handleSoundButtonClick = (event, selectedWord) => {
		event.preventDefault();
		if (!playedSounds.includes(selectedWord)) {
			const newPlayedSounds = [...playedSounds, selectedWord];
			if (newPlayedSounds.length === words.length) {
				setCtaDisabled(false);
			}
			setPlayedSounds(newPlayedSounds);
		}
	};

	return (
		<div className={classes.wordMatch} id={id}>
			<DragDropContext onDragEnd={handleDragEnd}>
				<div className="leftPanel">
					<div className="wordRows">
						{wordData.map((word, j) => {
							return (
								<div className={classes.wordRow} key={word.name}>
									<span className={classes.wordWrapper}>{word.name}</span>

									{isMixup ? (
										<div className={classes.dropHolderWrapper}>
											<DropHolder
												iterationIndex={j}
												words={words}
												name={word.name}
												destAudioMap={destAudioMap}
												updatedLeftAudio={updatedLeftAudio}
												resultSet={resultSet}
												submitted={isSubmitted}
												activitySubmittedOnce={
													activitySubmittedOnce
												}
												reset={reset}
												wordMapAfterSubmit={
													wordMapAfterSubmit
												}
											/>
										</div>
									) : (

										<SoundButton
											className={classes.soundButton}
											id={`word-match-${word.name}`}
											size={56}
											sound={word}
											onClick={event =>
												handleSoundButtonClick(
													event,
													word.name
												)
											}
										/>

									)}
								</div>
							);
						})}
					</div>
					<div className={classes.mixButtonRow}>
						<ActivityButton
							id="primaryButton"
							text="Mix"
							icon={'mixup'}
							className={clsx({
								[classes.mixUpButtonStyle]: true,
								[classes.disableMixUp]: isCtaDisabled
							})}
							onClick={handleMixButton}
							isPrimary={false}
						/>
					</div>
				</div>
				<div className="rightPanel">
					<div className="scrambled">
						<DragHolder
							isMixup={isMixup}
							classes={classes}
							words={words}
							updatedRightAudio={updatedRightAudio}
							activitySubmittedOnce={activitySubmittedOnce}
							reset={reset}
							wordMapAfterSubmit={wordMapAfterSubmit}
						/>
					</div>
				</div>
			</DragDropContext>
		</div >
	);
};

WordMatch.defaultProps = {
	id: '',
	words: []
};

WordMatch.propTypes = {
	id: PropTypes.string,
	enableSubmit: PropTypes.func,
	submitFlag: PropTypes.bool,
	activitySubmittedOnce: PropTypes.bool,
	autoCorrect: PropTypes.bool,
	words: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			src: PropTypes.string.isRequired
		}).isRequired
	)
};

export default React.memo(WordMatch);
