import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ActivityButton from '../ActivityButton/ActivityButton';
import {SoundButton} from '../..';
import {isEmpty} from 'lodash';
import ChallengeQuestion from './ChallengeQuestion';
import ChoiceQuestion from './ChoiceQuestion';
import ChartQuestion from './ChartQuestion';
import HighlightQuestion from './HighlightQuestion';
import MatchingQuestion from './MatchingQuestion';
import OrderingQuestion from './OrderingQuestion';
import FocusAttention from './FocusAttention';
import RateRecording from './RateRecording';
import SortingQuestion from './SortingQuestion';
import QuestionType from '../../constants/Languages/QuestionType';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import reduxStore from '@reading/r180/src/store/redux-setup';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'inline-block',
		'& .questionPanel': {
			background: theme.colors.white,
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			borderRadius: '8px',
			width: '480px',
			height: 'auto',
			'& .header': {
				background: props =>
					props.isSuccessZone
						? theme.colors.successZone.aqua5
						: theme.colors.readingZone.blue5,
				minHeight: '96px',
				fontFamily: theme.fonts.uiBold,
				fontSize: '18px',
				lineHeight: '22px',
				padding: '0 16px 0 12px',
				display: 'flex',
				justifyContent: 'left',
				alignItems: 'center',
				borderRadius: '8px 8px 0 0',
				'& .sound-button': {
					marginRight: '12px'
				}
			},
			'& .content': {
				padding: '40px',
				height: 'auto',
				'& .question-row': {
					marginBottom: '24px',
					'&:last-of-type': {
						marginBottom: '0'
					}
				}
			}
		},
		'& .button-row': {
			background: 'transparent',
			marginTop: '24px',
			textAlign: 'right',
			'& button.right': {
				float: 'right'
			},
			'& button.left': {
				float: 'left'
			}
		},
		'& p': {
			display: 'inline'
		}
	}
}));

const ReadingQuestionPanel = props => {
	const {
		id,
		className,
		title,
		titleSound,
		questionType,
		question,
		questions,
		onAnswerChange,
		interactive,
		buttonText,
		buttonIcon,
		showResetButton,
		onSubmit,
		onReset,
		isSubmitEnabled,
		isSuccessZone,
		preSelectedIndex,
		correctAnswers,
		correctiveFeedback
	} = props;

	const classes = useStyles({isSuccessZone: isSuccessZone});

	const [isAnswered, setAnswered] = useState(false);

	useEffect(() => {
		if (
			reduxStore.getState().session.session.settings.autoPlayEnabled &&
			titleSound
		) {
			AudioPlayer2.stopAll();
			AudioPlayer2.play(titleSound.name);
		}
	}, [titleSound.name]);

	const handleAnswerChange = answers => {
		switch (questionType) {
			case QuestionType.CHART:
			case QuestionType.HIGHLIGHT:
			case QuestionType.MULTIPLE:
			case QuestionType.SORTING: {
				onAnswerChange(answers);
				break;
			}
			case QuestionType.ORDERING: {
				setAnswered(true);
				onAnswerChange(answers);
				break;
			}
			default: {
				setAnswered(isEmpty(answers) === false);
				onAnswerChange(answers);
				break;
			}
		}
	};

	const handleDisable = () => {
		switch (questionType) {
			case QuestionType.CHALLENGE_ACCEPTANCE:
			case QuestionType.FOCUS_ATTENTION:
			case QuestionType.RATE_RECORDING:
				return isAnswered === false && buttonText === 'Go On';
			case QuestionType.HIGHLIGHT:
			case QuestionType.MULTIPLE:
			case QuestionType.MATCHING:
			case QuestionType.SORTING:
			case QuestionType.SINGLE:
			case QuestionType.CHART:
				return !isSubmitEnabled;
			default:
				return isAnswered === false && buttonText === 'Submit';
		}
	};

	const renderQuestion = () => {
		switch (questionType) {
			case QuestionType.CHALLENGE_ACCEPTANCE:
				return (
					<ChallengeQuestion
						questions={questions}
						onAnswerChange={handleAnswerChange}
					/>
				);

			case QuestionType.SINGLE:
			case QuestionType.MULTIPLE:
				return (
					<ChoiceQuestion
						questionType={questionType}
						questions={questions}
						interactive={interactive}
						onAnswerChange={handleAnswerChange}
						preSelectedIndex={preSelectedIndex}
						correctAnswers={correctAnswers}
						correctiveFeedback={correctiveFeedback}
					/>
				);
			case QuestionType.CHART:
				return (
					<>
						<ChartQuestion
							question={question}
							interactive={interactive}
							onAnswerChange={handleAnswerChange}
						/>
						<div style={{clear: 'both'}}> </div>
					</>
				);
			case QuestionType.HIGHLIGHT: {
				return (
					<HighlightQuestion
						question={questions}
						interactive={interactive}
						onAnswerChange={handleAnswerChange}
						displayOnlyWords={isSuccessZone}
					/>
				);
			}

			case QuestionType.MATCHING:
				return (
					<MatchingQuestion
						question={question}
						interactive={interactive}
						onAnswerChange={handleAnswerChange}
					/>
				);

			case QuestionType.ORDERING:
				return (
					<OrderingQuestion
						question={questions}
						interactive={interactive}
						onAnswerChange={handleAnswerChange}
					/>
				);
			case QuestionType.RATE_RECORDING:
				return (
					<RateRecording
						questions={questions}
						onAnswerChange={handleAnswerChange}
					/>
				);

			case QuestionType.FOCUS_ATTENTION:
				return (
					<FocusAttention
						questions={questions}
						onAnswerChange={handleAnswerChange}
					/>
				);
			case QuestionType.SORTING:
				return (
					<SortingQuestion
						question={question}
						interactive={interactive}
						onAnswerChange={handleAnswerChange}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div
			className={`${classes.wrapper} ${className}`}
			id={id}
			data-testid={id}
		>
			<div className={`questionPanel`}>
				<div className={`header`}>
					{isEmpty(titleSound) === false && (
						<SoundButton
							border={false}
							sound={titleSound}
							size={32}
							className={'transparent'}
							disabled={!interactive}
						/>
					)}

					<div dangerouslySetInnerHTML={{__html: title}}></div>
				</div>
				<div className={`content`}>{renderQuestion()}</div>
			</div>
			<div className={`button-row`}>
				{showResetButton && (
					<ActivityButton
						className="left"
						text={'Reset'}
						icon={'reset'}
						isPrimary={false}
						onClick={onReset}
					/>
				)}
				<ActivityButton
					className="right"
					text={buttonText}
					icon={buttonIcon}
					onClick={onSubmit}
					disabled={handleDisable()}
				/>
			</div>
		</div>
	);
};

ReadingQuestionPanel.defaultProps = {
	id: '',
	className: '',
	title: '',
	titleSound: {},
	questionType: QuestionType.SINGLE,
	question: {},
	questions: [],
	interactive: true,
	buttonText: 'Submit',
	buttonIcon: 'check',
	showResetButton: false,
	isSubmitEnabled: true,
	isSuccessZone: false,
	preSelectedIndex: -1
};
ReadingQuestionPanel.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleSound: PropTypes.shape({
		name: PropTypes.string.isRequired,
		src: PropTypes.string.isRequired
	}),
	questionType: PropTypes.string,
	question: PropTypes.object,
	questions: PropTypes.array,
	onAnswerChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	interactive: PropTypes.bool,
	buttonText: PropTypes.string,
	buttonIcon: PropTypes.string,
	showResetButton: PropTypes.bool,
	onReset: PropTypes.func,
	isSubmitEnabled: PropTypes.bool,
	isSuccessZone: PropTypes.bool,
	preSelectedIndex: PropTypes.number
};

export default ReadingQuestionPanel;
