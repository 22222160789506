import {getAudioExt} from '../../../utils/audio';
import {MEDIA_SERVER_URL} from '../../../utils/constants';

const EXT = getAudioExt();

export const WordMeaningMatchConstants = {
	INTRO_VIDEO: `${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_lintro_code.mp4`,

	IMAGE_PATH: `${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_{word}.jpg`,

	COVER_UP: `${MEDIA_SERVER_URL}assets/fs/audio/fx/fs_fx_screen_coverup_on.${EXT}`,
	COVER_OFF: `${MEDIA_SERVER_URL}assets/fs/audio/fx/fs_fx_screen_coverup_off.${EXT}`,

	READ_WORD: `${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_cinstruct_1_ha.${EXT}`,
	READ_SENTENCE: `${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_sinstruct_1_ha.${EXT}`,

	CORRECT: `${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_correct_1_ha.${EXT}`,
	INCORRECT: `${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_incorrect_1_ha.${EXT}`,

	LONG_INTRO: [`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_lintro_ha.${EXT}`],

	SHORT_INTRO: [
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_sintro_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_sintro_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_sintro_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_sintro_4_ha.${EXT}`
	],

	HELP: [
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_acthelp1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_acthelp2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_acthelp3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_acthelp4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_acthelp5_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_acthelp6_ha.${EXT}`
	],

	FEEDBACK_HELP: `${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_feedbhelp_ha.${EXT}`,

	ONE_INCORRECT: [
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_1incorrect_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_1incorrect_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_1incorrect_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_1incorrect_4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_1incorrect_5_ha.${EXT}`
	],

	TWO_MORE_INCORRECT: [
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_2plusincorrect_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_2plusincorrect_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_2plusincorrect_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_2plusincorrect_4_ha.${EXT}`
	],

	ALL_CORRECT: [
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_allcorrect_1_ha${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_allcorrect_2_ha${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_allcorrect_3_ha${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_allcorrect_4_ha${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/wwmm/fs_wwmm_allcorrect_5_ha${EXT}`
	]
};
