import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import WordCardCircleButton from './WordCardCircleButton';
import {makeStyles} from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';
import reduxStore from '@reading/r180/src/store/redux-setup';
import {foreignLanguageMap} from '@reading/r180/src/utils/constants';
import useRouteInfo from '@reading/r180/src/utils/useRouteInfo';

export const useStyle = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		padding: '8px 24px',
		margin: 'auto',
		height: '120px',
		width: '100%',
		'& .button-panel > div:last-of-type': {
			marginRight: 0
		},
		'& .carousel-root': {
			width: '100%'
		},
		'& .carousel.carousel-slider': {
			height: '92px'
		},
		'& .carousel .slide': {
			background: theme.colors.white
		},
		'& .carousel .control-arrow, .carousel.carousel-slider .control-arrow': {
			opacity: 1
		},
		'& .carousel .control-next.control-arrow::before': {
			borderLeft: `8px solid ${theme.colors.softBlack}`
		},
		'& .carousel .control-prev.control-arrow::before': {
			borderRight: `8px solid ${theme.colors.softBlack}`
		},
		'& .carousel.carousel-slider .control-arrow:hover': {
			background: theme.colors.white
		},
		'& .button-panel': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'flex-start',
			paddingTop: '6px'
		}
	}
}));

const WordCardToolbar = props => {
	const {
		onWordButtonChange,
		wordCard,
		isSpellPreselected,
		isTipsPreselected,
		hideVideoTool,
		status,
		completedActivities
	} = props;
	const classes = useStyle();
	const [selectedButton, setSelectedButton] = useState('');
	const [anyButtonClicked, setButtonClicked] = useState(false);

	const sessionSlice = reduxStore.getState().session;
	const settings = useMemo(() => {
		return sessionSlice.session.settings;
	}, [sessionSlice]);

	const language = settings.language;
	const tipsEnabled = settings.pronunciationTipsEnabled;

	const {zone} = useRouteInfo();

	const handleClick = (buttonLabel, lang) => {
		setSelectedButton(buttonLabel);
		onWordButtonChange(buttonLabel, lang);
		setButtonClicked(true);
	};

	const hasSynAnt =
		wordCard.word_card_activities &&
		wordCard.word_card_activities.filter(act => {
			return (
				act.word_card_activity.activity_server_id ===
				'synonyms_and_antonyms'
			);
		}).length > 0 && completedActivities && completedActivities.includes('synonyms_and_antonyms');

	const hasFamily =
		wordCard.word_card_activities &&
		wordCard.word_card_activities.filter(act => {
			return act.word_card_activity.activity_server_id === 'word_family';
		}).length > 0 && completedActivities && completedActivities.includes('word_family');

	const hasExamples =
		wordCard.word_card_activities &&
		wordCard.word_card_activities.filter(act => {
			return (
				act.word_card_activity.activity_server_id ===
				'example_non_example'
			);
		}).length > 0 && completedActivities && completedActivities.includes('example_non_example');

	const hasContext =
		wordCard.word_card_activities &&
		wordCard.word_card_activities.filter(act => {
			return (
				act.word_card_activity.activity_server_id === 'words_in_context'
			);
		}).length > 0 && completedActivities && completedActivities.includes('words_in_context');

	let panel1 = [];
	let panel2 = [];

	const hasExtraButtons = hasSynAnt || hasFamily || hasExamples || hasContext;

	panel1.push(
		<WordCardCircleButton
			outlined
			key="parts"
			label="Parts"
			icon="parts"
			hoverTitle="Click to hear the word in parts"
			active={selectedButton === 'parts' && status !== 'info'}
			onClick={() => handleClick('parts')}
		/>
	);
	panel1.push(
		<WordCardCircleButton
			outlined
			key="spelling"
			label="Spell"
			icon="spelling"
			hoverTitle="Click to hear the spelling of the word"
			active={
				(selectedButton === 'spell' && status !== 'info') ||
				(!anyButtonClicked && isSpellPreselected)
			}
			onClick={() => handleClick('spell')}
		/>
	);

	panel1.push(
		<WordCardCircleButton
			outlined
			key="tips"
			label="Tip"
			icon="tip"
			hoverTitle="Click for a tip"
			active={
				(selectedButton === 'tips' && status !== 'info') ||
				(!anyButtonClicked && isTipsPreselected)
			}
			onClick={() => handleClick('tips')}
		/>
	);

	if (language !== 'none' && zone !== 'fluency') {
		panel1.push(
			<WordCardCircleButton
				outlined
				key="foreign"
				label={foreignLanguageMap[language]}
				icon="espanol"
				active={selectedButton === 'foreign' && status !== 'info'}
				onClick={() => handleClick('foreign', language)}
			/>
		);
	}

	/* istanbul ignore else */
	if (!isSpellPreselected && !isTipsPreselected && !hideVideoTool) {
		panel1.push(
			<WordCardCircleButton
				outlined
				key="video"
				label="Video"
				icon="anchor-video"
				hoverTitle="Click for a video"
				active={selectedButton === 'anchor-video' && status !== 'info'}
				onClick={() => handleClick('anchor-video')}
			/>
		);
	}
	/* istanbul ignore next */

	if (language === 'span' && tipsEnabled) {
		panel1.push(
			<WordCardCircleButton
				outlined
				key="consejo"
				label="Consejo"
				icon="consejo"
				active={selectedButton === 'consejo' && status !== 'info'}
				onClick={() => handleClick('consejo')}
			/>
		);
	}
	if (hasSynAnt && zone === 'language') {
		panel2.push(
			<WordCardCircleButton
				className="wide"
				outlined
				key="syn"
				label="Synonyms &amp;<br/>Antonyms"
				icon="synonym-antonyms"
				hoverTitle="Click for synonyms and antonyms"
				active={selectedButton === 'synonym' && status !== 'info'}
				onClick={() => handleClick('synonym')}
			/>
		);
	}
	/* istanbul ignore next */

	if (hasFamily && zone === 'language') {
		panel2.push(
			<WordCardCircleButton
				className="wide"
				outlined
				key="famililies"
				label="Word<br/>Family"
				icon="word-families"
				hoverTitle="Click for word family"
				active={selectedButton === 'family' && status !== 'info'}
				onClick={() => handleClick('family')}
			/>
		);
	}
	/* istanbul ignore next */

	if (hasExamples && zone === 'language') {
		panel2.push(
			<WordCardCircleButton
				className="wide"
				outlined
				key="examples"
				label="Examples &amp;<br/>Non-Examples"
				icon="examples-non-examples"
				hoverTitle="Click for examples"
				active={selectedButton === 'examples' && status !== 'info'}
				onClick={() => handleClick('examples')}
			/>
		);
	}

	if (hasContext && zone === 'language') {
		panel2.push(
			<WordCardCircleButton
				className="wide"
				outlined
				key="context"
				label="Words in<br/>Context"
				icon="wordincontext"
				hoverTitle="Click for words in context"
				active={
					selectedButton === 'words-in-context' && status !== 'info'
				}
				onClick={() => handleClick('words-in-context')}
			/>
		);
	}

	return (
		<div className={classes.wrapper}>
			<Carousel
				showStatus={false}
				showIndicators={false}
				infiniteLoop={false}
				showThumbs={false}
				showArrows={zone === 'language' && hasExtraButtons}
				tabIndex={-1}
			>
				<div className="button-panel">
					{panel1.map(but => {
						return but;
					})}
				</div>
				{zone === 'language' && hasExtraButtons ? (
					<div className="button-panel">
						{panel2.map(but => {
							return but;
						})}
					</div>
				) : null}
			</Carousel>
		</div>
	);
};
WordCardToolbar.defaultProps = {
	isSpellPreselected: false,
	isTipsPreselected: false,
	hideVideoTool: false
};
WordCardToolbar.propTypes = {
	isSpellPreselected: PropTypes.bool,
	isTipsPreselected: PropTypes.bool,
	hideVideoTool: PropTypes.bool,
	onWordButtonChange: PropTypes.func.isRequired
};

export default React.memo(WordCardToolbar);
