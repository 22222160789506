import axios from 'axios';
import {isDemoMode} from '../utils/ui';

export default baseEndpoint => ({
	getOne: () => axios.get(`${baseEndpoint}/activity`).then(response => response.data),

	updateActivity: data => {
		if (isDemoMode()) {
			if (data.activity.activityServerId === 'reading_strategy_selector') {
				// do nothing
			}
			else if (data.activity && data.activity.isComplete) {
				alert('Demo Mode - Activity Complete');
			}
		} else {
			return axios.get(`${baseEndpoint}/activity`, data).then(response => response.data);
		}
	},

	updateZoneReport: (studentActivityId, data) =>
		axios.put(`${baseEndpoint}/zonereport/${studentActivityId}`, data).then(response => response.data),

	uploadSingleFile: (studentActivityId, filename, uploadTypeInt, data) =>
		axios
			.get(`${baseEndpoint}/singleupload/${studentActivityId}/${filename}/${uploadTypeInt}`, data)
			.then(response => response.data)
});
