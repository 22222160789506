import queryString from 'query-string';

import transport from '../Transport';
import ErrorStore from '../ErrorStore';
import DataStore from '../DataStore';
import Request from '../Request';
import SessionStorage from '../SessionStorage';
import Logger from '../Logger';
import PlatformHelper from '../PlatformHelper';

import isValidUserContext from './lib/is-valid-user-context';

const PRODUCTION_PROXY_PROVIDER_URI =
	'/api/provider/v1/callback/correlationid/';

const userContext = {
	storeContext: async context => {
		try {
			Logger.log('storing user context - validation, set DataStore, set Request context');
			await isValidUserContext(context);
			await DataStore.set('userContext', context);
			return Request.setUserContext(context);
		} catch (error) {
			Logger.log('error storing user context');
			return error;
		}
	},

	getLocalContext: () => ({
		// eslint-disable-next-line camelcase
		custom_sif_token: process.env.REACT_APP_SIF_TOKEN,
		// eslint-disable-next-line camelcase
		custom_assignment_id: process.env.REACT_APP_CUSTOM_ASSIGNMENT_ID,
		// eslint-disable-next-line camelcase
		custom_activity_id: process.env.REACT_APP_CUSTOM_ACTIVITY_ID,
		// eslint-disable-next-line camelcase
		custom_correlation_id: process.env.REACT_APP_CUSTOM_CORRELATION_ID
	}),

	fetchUserContext: correlationIdParam => {
		return new Promise(resolve => {
			if (correlationIdParam === 'local') {
				const localUserContext = userContext.getLocalContext();
				Logger.log('fetchUserContext: local context is being used');
				return resolve(localUserContext);
			}

			const REACT_APP_CONTENT_PROVIDER_URI =
				process.env.REACT_APP_CONTENT_PROVIDER_URI ||
				PRODUCTION_PROXY_PROVIDER_URI;

			const url = REACT_APP_CONTENT_PROVIDER_URI + correlationIdParam;

			const options = {
				url,
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				mode: 'cors'
			};

			return transport(options)
				.then(resolve)
				.catch(response => {
					ErrorStore.push({
						title: 'UserContext Error',
						action: `${response.status} while fetching Ed user context`,
						origin: 'UserContext.fetchUserContext()',
						link: response.url,
						error: response
					}).then(error => {
						resolve(error);
					});
				});
		});
	},

	fetchAndVerifyUserContext: async correlationIdParam => {
		const response = await userContext.fetchUserContext(correlationIdParam);

		if (response) {
			if (Reflect.has(response, 'error')) {
				return response;
			}

			await DataStore.set('correlationId', correlationIdParam);

			const context = {
				sifToken: response.custom_sif_token,
				assignmentId: response.custom_assignment_id,
				activityId: response.custom_activity_id,
				correlationId: response.custom_correlation_id
			};

			Logger.log(
				'fetchAndVerifyUserContext: storing user context: ',
				context
			);
			await userContext.storeContext(context);
			return userContext;
		} else {
			throw new Error('No valid Ed User Context received.');
		}
	},

	cacheInitialUrlParams: async location => {
		if (location) {
			const search = queryString.parse(location.search);
			await DataStore.set('initialUrlParams', search);
		}
	},

	/**
	 * Performs logout-related behavior for the current user.
	 *
	 * @param activeIntervalIds the IDs of any active intervals that should be cleared
	 * @returns {Promise<void>}
	 */
	logout: async activeIntervalIds => {
		Logger.debug('Logging out user, interval IDs: ', activeIntervalIds);
		PlatformHelper.invalidateSIDCookie();
		SessionStorage.set('loggedOut', true);
		await DataStore.set('sid', null);
		if (activeIntervalIds) {
			activeIntervalIds.forEach(interval => {
				clearInterval(interval);
			});
		}
	},

	init: async (restoredAppState, correlationId) => {
		await userContext.cacheInitialUrlParams(window.location);

		// If the correlationId has changed, we should re-auth
		if (
			correlationId &&
			restoredAppState &&
			Reflect.has(restoredAppState, 'userContext') &&
			Reflect.has(restoredAppState, 'correlationId') &&
			correlationId !== restoredAppState.correlationId
		) {
			await userContext.fetchAndVerifyUserContext(correlationId);
			Logger.log(
				'UserContext.init: userContext established from correlationId: ',
				userContext
			);
			return userContext;
		}

		// If the there is no new correlationId passed, and we are restoring an
		// old state, we should set the userContext up from the restored state
		if (
			!correlationId &&
			restoredAppState &&
			Reflect.has(restoredAppState, 'userContext') &&
			Reflect.has(restoredAppState, 'correlationId')
		) {
			Logger.log(
				'UserContext.init: userContext established from restoredAppState: ',
				restoredAppState
			);
			return await userContext.storeContext(restoredAppState.userContext);
		}

		// if the app is running on SAM, a correlationId will not be provided
		if (PlatformHelper.isM180OnSAM(process.env)) {
			Logger.log('UserContext.init: app is running on SAM');
			return {};
		}

		if (!correlationId) {
			Logger.warn(
				'UserContext.init: No correlationId provided to UserContext.init'
			);
			const error = {
				title: 'UserContext Error',
				action: 'No "custom_correlation_id" param was found',
				origin: 'UserContext.sync()'
			};
			await ErrorStore.push(error);
			return error;
		}

		await userContext.fetchAndVerifyUserContext(correlationId);
		// Only cache the Initial URL params if this is a new session.
		// For example: Spanish RI needs to remember the test window type.
		await userContext.cacheInitialUrlParams(window.location);
		Logger.log(
			'UserContext.init: userContext established and verified: ',
			userContext
		);
		return userContext;
	}
};

export default userContext;
