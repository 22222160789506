import React, { useEffect, useRef, useContext } from 'react';
import Sortable from 'sortablejs';
import { makeStyles } from '@material-ui/core';
import { DndContext } from './DndProvider';

const useStyles = makeStyles(theme => ({
    dragWrapper: {
        minWidth: '168px',
        maxWidth: '230px',
        width: 'auto',
        height: '56px',
        borderRadius: '8px',
        background: 'transparent',
        flexGrow: 1,
        flexShrink: 0,
        border: `dashed 1px transparent`,

        '&:empty': {
            borderColor: theme.colors.grey,
        },

        '&.image': {
            height: 'max(92px, 14vh)',
            minWidth: '156px',
        }
    },
}));

const DragZone = (props) => {
    const {
        children, className, item, meta, type = "questions",
        maxItems = 1, pull = true, ...options
    } = props;
    const classes = useStyles();
    const dragRef = useRef(null);
    const sortable = useRef(null);
    const { dndTiles, methods } = useContext(DndContext);

    const dragOptions = {
        group: {
            name: type,
            pull: pull,
            put: (to) => (to.toArray().length === maxItems - 1)
        },
        animation: 0,
        revertOnSpill: true,
        useReact: true,
        dropContainer: '.drag-wrapper',
        tilesParent: '.drag-tiles',
        dragoverBubble: true,
        ghostClass: 'ghost',
        chosenClass: 'selected',
        dragClass: 'dragging',
        dataIdAttr: 'data-dnd-id',
        handle: ".drag-helper",
        onStateChanges(arr) {
            // Update DND Provider state
            methods.updateMultiple(arr)
        },
        ...options
    }
    // --- xx -----------------------------------------------


    useEffect(() => {
        if (dragRef.current) {
            if (sortable.current) {
                sortable.current.destroy()
            }
            sortable.current = new Sortable(dragRef.current, dragOptions);
            sortable.current.dndType = type
        }
    }, [dndTiles])
    // --- xx -----------------------------------------------


    const component = React.isValidElement(children)
        ? children
        : null;

    return (
        <div ref={dragRef} data-type={type} className={`${classes.dragWrapper} ${meta.answerType.toLowerCase()} ${className}`}>
            {component}
        </div>
    );
};

export default React.memo(DragZone);