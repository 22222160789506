import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {transitionToNextActivity} from '../../utils/navigation';
import {uiSlice} from '../../store/slices/ui';
import Navbar from '../../containers/App/Navbar';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import useStyles from './SuccessScreen.style';
import {ActivityButton} from '@reading/common';

const SuccessScreen = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const {
		activityData: {studentActivityId, activityServerId, stage, segment, level},
		segmentrating: {studentSegmentId}
	} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			segmentrating: state.activity.segmentrating
		};
	});
	const completeActivity = async () => {
		const postData = {
			segmentrating: {
				rating: '1',
				studentSegmentId
			},
			activity: {
				studentActivityId,
				isComplete: true
			}
		};
		try {
			await transitionToNextActivity(history, postData);
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
	};
	const loaded = activityServerId === 'success_screen';
	return (
		<div className={`${classes.wrapper}`}>
			<Navbar helpSoundUrl={''} short={true} />
			<ActivitySuspense requiredRenderData={[{loaded}]} showSpinner={!loaded} title={'Success Screen'}>
				<div className={classes.content}>
					<ActivityButton onClick={completeActivity} text={'Complete Activity'} isPrimary={true} />
				</div>
			</ActivitySuspense>
		</div>
	);
};

export default SuccessScreen;
