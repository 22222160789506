import {CLIENT_BASE_URL} from "./constants";

export default function useRouteInfo(loc=window.location) {
	let container = '';
	let zone = '';
	let activity = '';
	const base = CLIENT_BASE_URL;
	const pathname = loc.pathname.replace(base, '');
	container = pathname.split('/')[1];

	if (container === 'zone') {
		zone = pathname.split('/')[2];
		activity = pathname.split('/')[3];
	}

	return {container, zone, activity};
}
