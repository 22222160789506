import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ActivityInstructionButton, WordContextLanding, WordContextProblemView} from '@reading/common';
import ActivitySuspense from '../../../containers/App/ActivitySuspense';
import Footer from '../../../containers/App/Footer';
import FooterForwardBack from '../../../containers/App/FooterForwardBack';
import Navbar from '../../../containers/App/Navbar';
import useIdleHelp from '../../../utils/useIdleHelp';
import {useDispatch, useSelector} from 'react-redux';
import {uiSlice} from '../../../store/slices/ui';
import {useUnmount, useDeepCompareEffect} from 'react-use';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {getModifiedContentData} from './WordsInContext';
import ActivityFrame from '../../../containers/App/ActivityFrame';
import {isEmpty, shuffle} from 'lodash';
import {KnowledgeForReading} from '../KnowledgeForReading';
import {LanguageZone} from '../LanguageZone';
import useBatchedSetState from '../../../utils/useBatchedSetState';
import {sleep} from '../../../utils/sleep';

export default function WordsInContextActivity(props) {
	const history = useHistory();
	const dispatch = useDispatch();
	const {setState} = useBatchedSetState();
	const renderTime = useRef(new Date().getTime());

	const {contentData, activityData, settings, ui} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			contentData: state.activity.taskData,
			settings: state.session.session.settings,
			ui: state.ui
		};
	});

	const isActivity = activityData ? activityData.activityServerId === 'words_in_context' : false;

	const [isGoOnEnabled, setGoOnEnabled] = useState(true);
	const [isBtnDisabled, setBtnDisabled] = useState(true);
	const [status, setStatus] = useState(FooterForwardBack.VALID);
	const [modifiedContentData, setModifiedContentData] = useState({});
	const [seletedOptions, setSeletedOptions] = useState([]);
	const [showFeedBackIcons, setShowFeedBackIcons] = useState(false);
	const [attempts, setAttempts] = useState(0);
	const [selectedProblems, resetSelectedProblems] = useState([]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	useDeepCompareEffect(() => {
		const updateData = async () => {
			const modifiedData = getModifiedContentData(contentData, activityData);
			setModifiedContentData(modifiedData);

			if (
				settings.autoPlayEnabled === true &&
				ui.autoPlayedSounds.includes('word-context-' + modifiedData.power_word_focus) === false
			) {
				dispatch(uiSlice.actions.addAutoPlayedSound('word-context-' + modifiedData.power_word_focus));
				await sleep(800);
				AudioPlayer2.load({
					name: modifiedData.power_word_focus,
					src: modifiedData.power_word_focus_url
				});
				await AudioPlayer2.playSync(KnowledgeForReading.SOUND_WORD_FAM_INTRO);
				await AudioPlayer2.playSync(modifiedData.power_word_focus);
				await sleep(1000);
				await AudioPlayer2.playSync(modifiedData.word_card_sentence);
			}
			setBtnDisabled(false);
		};
		if (isEmpty(contentData) === false && isActivity) {
			updateData();
		}
	}, [contentData]); // eslint-disable-line

	const updateAfterGuess = (activityData, contentData, correctCount, totalCount, responsesToServer) => {
		LanguageZone.sendProgressToServer(
			activityData,
			contentData.power_word_focus,
			correctCount,
			totalCount,
			responsesToServer
		);
	};

	const handleForward = async () => {
		AudioPlayer2.stopAll();
		const responseList = [];
		/* istanbul ignore next */
		if (seletedOptions.length > 0) {
			contentData.word_card_activityData.words_in_context.answers.forEach(a => {
				responseList.push({
					answerId: seletedOptions.filter(option => {
						return a.id === option.id;
					})[0].isUpSelected,
					questionId: a.id,
					isCorrect: seletedOptions.filter(option => {
						return a.id === option.id;
					})[0].isSelected
				});
			});
			contentData.word_card_activityData.words_in_context.distractors.forEach(a => {
				responseList.push({
					answerId: seletedOptions.filter(option => {
						return a.id === option.id;
					})[0].isUpSelected,
					questionId: a.id,
					isCorrect: seletedOptions.filter(option => {
						return a.id === option.id;
					})[0].isSelected
				});
			});
		}

		const isAllCorrect = seletedOptions.filter(option => option.isSelected);

		/* istanbul ignore next */
		if (isAllCorrect.length / 4 >= LanguageZone.COMPLETE_THRESHOLD) {
			if (status === FooterForwardBack.VALID) {
				try {
					await LanguageZone.completeActivity(
						history,
						activityData,
						contentData.word_text,
						isAllCorrect.length,
						seletedOptions.length
					);
				} catch (err) {
					dispatch(uiSlice.actions.setErrorMessage(err));
				}
			} else {
				setState(() => {
					setShowFeedBackIcons(true);
					setStatus(FooterForwardBack.VALID);
					setAttempts(KnowledgeForReading.WORDS_IN_CONTEXT_REDIRECT_TO_FINAL_ATTEMPT);
				});
				if (isAllCorrect.length === 3) {
					AudioPlayer2.play(KnowledgeForReading.SOUND_WORD_FAM_ALMOST);
				} else {
					AudioPlayer2.play(KnowledgeForReading.SOUND_WORD_FAM_CORRECT);
				}
				updateAfterGuess(activityData, contentData, isAllCorrect.length, 4, responseList);
			}
		} else {
		/* istanbul ignore next */
			switch (attempts) {
				case 1:
				case 3: {
					// when incorrect options selected
					setState(() => {
						setShowFeedBackIcons(true);
						setStatus(FooterForwardBack.INVALID);
						setAttempts(attempts + 1);
					});
					AudioPlayer2.play(KnowledgeForReading.SOUND_WORD_FAM_TRY_AGAIN);
					updateAfterGuess(activityData, contentData, isAllCorrect.length, 4, responseList);
					break;
				}
				case 2:
				case 4: {
					// reset the user seleted options to initial state
					let updatedData = getModifiedContentData(contentData, activityData);
					const shuffledOptions = shuffle(updatedData.problemsData);
					updatedData.problemsData = shuffledOptions;

					renderTime.current = new Date().getTime();

					setState(() => {
						setModifiedContentData(updatedData);
						setShowFeedBackIcons(false);
						setStatus(FooterForwardBack.SUBMIT);
						setAttempts(attempts + 1);
						setBtnDisabled(true);
					});
					resetSelectedProblems([]);
					updateAfterGuess(activityData, contentData, isAllCorrect.length, 4, []);
					break;
				}
				case 5: {
					// After all attempts show the correct options and enable GoOn
					seletedOptions.forEach(option => {
						if (option.answer) {
							option.isSelected = true;
							option.isUpSelected = true;
							option.isDownSelected = false;
						} else {
							option.isSelected = true;
							option.isUpSelected = false;
							option.isDownSelected = true;
						}
					});
					setState(() => {
						setShowFeedBackIcons(true);
						setStatus(FooterForwardBack.VALID);
						setAttempts(attempts + 1);
					});
					AudioPlayer2.play(KnowledgeForReading.SOUND_WORD_FAM_INCORRECT_FINAL);
					updateAfterGuess(activityData, contentData, isAllCorrect.length, 4, responseList);
					break;
				}
				case 6: {
					try {
						await LanguageZone.completeActivity(
							history,
							activityData,
							contentData.word_text,
							isAllCorrect.length,
							seletedOptions.length
						);
					} catch (err) {
						dispatch(uiSlice.actions.setErrorMessage(err));
					}

					break;
				}
				default: {
					setState(() => {
						setGoOnEnabled(false);
						setStatus(FooterForwardBack.SUBMIT);
						setBtnDisabled(true);
						setAttempts(attempts + 1);
					});
					break;
				}
			}
		}
	};

	const handleInstructionStart = () => {
		if (isGoOnEnabled) {
			setBtnDisabled(true);
		}
	};

	const handleInstructionEnd = async () => {
		if (isGoOnEnabled) {
			await AudioPlayer2.playSync(modifiedContentData.power_word_focus);
			setBtnDisabled(false);
		}
	};

	/* istanbul ignore next */
	const getHelpSound = () => {
		if (isGoOnEnabled) {
			return KnowledgeForReading.SOUND_WORD_FAM_STUDY_HELP;
		} else {
			return KnowledgeForReading.SOUND_WORD_FAM_TRIAL_HELP;
		}
	};

	/* istanbul ignore next */
	const getIntroSound = () => {
		if (isGoOnEnabled) {
			return KnowledgeForReading.SOUND_WORD_FAM_INTRO;
		} else {
			return KnowledgeForReading.SOUND_WORD_FAM_TRIAL_INTRO;
		}
	};

	useIdleHelp(getHelpSound());

	return (
		<>
			<Navbar helpSoundUrl={getHelpSound()} />
			<ActivitySuspense requiredRenderData={contentData} title="Words in Context">
				<ActivityFrame isWhiteBackground={true}>
					<ActivityInstructionButton
						audioSrc={getIntroSound()}
						onSoundStart={handleInstructionStart}
						onSoundEnd={handleInstructionEnd}
					/>
					{isGoOnEnabled ? (
						<WordContextLanding
							id="wordContextLanding"
							wordCard={modifiedContentData && modifiedContentData}
							setBtnDisabled={setBtnDisabled}
						/>
					) : (
						<WordContextProblemView
							id="wordContextProblemView"
							wordCard={modifiedContentData && modifiedContentData}
							setBtnDisabled={setBtnDisabled}
							setSeletedOptions={setSeletedOptions}
							showFeedBackIcons={showFeedBackIcons}
							selectedProblemsData={selectedProblems}
							renderTime={renderTime}
						/>
					)}
				</ActivityFrame>
				<Footer>
					<FooterForwardBack
						status={status}
						onForward={handleForward}
						isBackVisible={false}
						isForwardDisabled={isBtnDisabled}
					/>
				</Footer>
			</ActivitySuspense>
		</>
	);
}
