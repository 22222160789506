import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import HoverTip from '../../HoverTip/HoverTip'

const useStyles = makeStyles(theme => ({
	replayButton: {
		marginBottom: '26px',
		background: theme.colors.grey,
		width: '96px',
		height: '96px',
		border: '4px solid',
		borderColor: theme.colors.white,
		borderRadius: '50%',
		outline: 'none',
		opacity: '60%',
		cursor: 'pointer',
		'&:hover,&:focus': {
			width: '104px',
			height: '104px',
			opacity: '80%'
		}
	},
	icon: {
		fontSize: '45px',
		color: theme.colors.white
	}
}));

export default function ReplayButton(props) {
	const { icon, onClick, id } = props;
	const classes = useStyles();

	return (
		<HoverTip title='Click to Replay' placement='top'>
			<button
				type="button"
				id={id}
				aria-describedby={id}
				className={`${classes.replayButton}`}
				onClick={onClick}
			>
				<i className={`r180 ${icon} ${classes.icon}`} />
			</button>
		</HoverTip>
	);
}

ReplayButton.defaultProps = {
	id: '',
	icon: 'reset',
	onClick: () => { }
};
ReplayButton.propTypes = {
	id: PropTypes.string,
	icon: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};
