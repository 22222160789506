
const useAppMeasurements = () => {

	const header = document.getElementsByTagName("header")[0];
	let headerHeight = 0;
	let headerWidth = 0;
	if (header) {
		headerHeight = header.offsetHeight;
		headerWidth = header.offsetWidth;
	}

	const footer = document.getElementsByTagName("footer")[0];
	let footerHeight = 0;
	let footerWidth = 0;
	if (footer) {
		footerHeight = footer.offsetHeight;
		footerWidth = footer.offsetWidth;
	}

	// these are the entire browser application size
	const screenWidth = window.screen.width;
	const screenHeight = window.screen.height;

	// the space inside the browser, excluding the browsers navbar and and borders
	const browserWindowWidth = window.screen.availableWidth;
	const browserWindowHeight = window.screen.availHeight;

	return {
		headerHeight,
		headerWidth,
		footerHeight,
		footerWidth,
		screenWidth,
		screenHeight,
		browserWindowWidth,
		browserWindowHeight
	};
};

export default useAppMeasurements;
