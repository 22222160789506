import React from 'react';
import {MEDIA_ACTIVITY_ASSETS_URL_BASE, MEDIA_SERVER_URL} from "@reading/r180/src/utils/constants";

const ZoneMenuMask = ({className, activity}) => {
	const relUrlBase = window.location
	return (
		<svg className={className} width="1024" height="656" viewBox="0 0 1074 656" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid={'ZoneMenuMask_svg'}>
			<defs>
				<image id="image0" width="840" height="473" href={`${
					MEDIA_SERVER_URL
					}/${MEDIA_ACTIVITY_ASSETS_URL_BASE}zonemenu/r180u_zonemenu_${
					activity.stage
					}${activity.segment.toString().padStart(2, '0')}.jpg`} />
				<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
					<use href="#image0" transform="translate(0 -0.0407618) scale(0.00119048 0.00228652)" />
				</pattern>
				<linearGradient id="paint0_linear" x1="-0.27" y1="0.6" x2="1" y2="0.3" gradientUnits="objectBoundingBox">
					<stop offset="0.179637" />
					<stop offset="0.731393" stopOpacity="0" />
				</linearGradient>
			</defs>
			<mask id="mask0" mask-type="alpha" maskUnits="objectBoundingBox" x="0" y="0" width="1" height="1">
				<path d="M1072.48 160.337C1101.86 316.029 701.301 517.278 177.719 609.87C-345.862 702.461 -794.106 651.284 -823.482 495.622C-852.858 339.959 -452.361 138.734 71.2357 46.1333C594.747 -46.4578 1043.05 4.7139 1072.49 160.337" fill="white" />
			</mask>
			<g mask={`url(${relUrlBase}#mask0)`}>
				<rect x="-150" y="-150" width="1477" height="769" fill={`url(${relUrlBase}#pattern0)`} />
			</g>
			<g mask={`url(${relUrlBase}#mask0)`}>
				<rect y="-33" width="1384" height="696" fill={`url(${relUrlBase}#paint0_linear)`} />
			</g>

		</svg>
	);
}

export default React.memo(ZoneMenuMask);
