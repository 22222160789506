import React from 'react';
import PropTypes from 'prop-types';
import {ActivityButton} from '@reading/common';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	FooterForwardBack: {
		float: 'right'
	}
}));

export default function FooterForwardBack(props) {
	const classes = useStyles();

	const handleBack = e => {
		const {onBack} = props;
		e.stopPropagation();

		if (typeof onBack === 'function') {
			onBack();
		}
	};

	const handleForward = e => {
		const {onForward} = props;
		e.stopPropagation();

		if (typeof onForward === 'function') {
			onForward();
		}
	};

	const {className, backText, status, isForwardDisabled, isForwardVisible, isBackDisabled, isBackVisible} = props;
	let {forwardText} = props;

	let forwardIcon = '';
	if (status === FooterForwardBack.INITIAL) {
		forwardText = 'Submit';
		forwardIcon = 'check';
	} else if (status === FooterForwardBack.SUBMIT) {
		forwardText = 'Submit';
		forwardIcon = 'check';
	} else if (status === FooterForwardBack.INVALID) {
		forwardText = 'Try Again';
		forwardIcon = 'reset';
	} else if (status === FooterForwardBack.VALID) {
		forwardText = 'Go On';
		forwardIcon = 'go-on';
	} else if (status === FooterForwardBack.START) {
		forwardText = 'Start';
		forwardIcon = 'arrow-right';
	}


	return (
		<div className={`${classes.FooterForwardBack} ${className}`}>
			{isBackVisible && (
				<ActivityButton
					className="mr-2"
					icon="arrow-left"
					disabled={isBackDisabled}
					iconPosition="left"
					isPrimary={false}
					onClick={handleBack}
					text={backText}
				/>
			)}
			{isForwardVisible && (
				<ActivityButton
					icon={forwardIcon}
					disabled={isForwardDisabled || status === FooterForwardBack.INITIAL}
					iconPosition="right"
					isPrimary
					onClick={handleForward}
					text={forwardText}
				/>
			)}
		</div>
	);
}

FooterForwardBack.INITIAL = 'initial';
FooterForwardBack.SUBMIT = 'submit';
FooterForwardBack.INVALID = 'invalid';
FooterForwardBack.VALID = 'valid';
FooterForwardBack.START = 'start';

FooterForwardBack.defaultProps = {
	className: '',
	backText: 'Back',
	forwardText: 'Submit',
	status: FooterForwardBack.INITIAL,
	isForwardDisabled: false,
	isBackDisabled: false,
	isForwardVisible: true,
	isBackVisible: true
};

FooterForwardBack.propTypes = {
	className: PropTypes.string,
	backText: PropTypes.string,
	forwardText: PropTypes.string,
	status: PropTypes.string,
	isForwardDisabled: PropTypes.bool,
	isBackDisabled: PropTypes.bool,
	onBack: PropTypes.func,
	onForward: PropTypes.func,
	isForwardVisible: PropTypes.bool,
	isBackVisible: PropTypes.bool
};
