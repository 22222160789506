import React from 'react';
import WritingActivity from '../activities/Writing/WritingActivity';
import WritingActivityNarrative from '../activities/Writing/WritingActivityNarrative';
import WritingSelectorActivity from '../activities/Writing/WritingSelectorActivity';

const writingZoneRoutes = [
	{
		route: 'writing-landing',
		serverRoute: 'writing',
		pageTitle: 'Writing Selection',
		component: <WritingSelectorActivity />,
		author: ['Mike', 'Ramkumar', 'Sheik', 'Sumanth']
	},
	{
		route: 'writing-informative',
		pageTitle: 'Informative',
		component: <WritingActivity />,
		author: ['Mike', 'Ramkumar', 'Sheik', 'Sumanth']
	},
	{
		route: 'writing-argument',
		pageTitle: 'Argument',
		component: <WritingActivity />,
		author: ['Mike', 'Ramkumar', 'Sheik', 'Sumanth']
	},
	{
		route: 'writing-narrative',
		pageTitle: 'Narrative',
		component: <WritingActivityNarrative />,
		author: ['Mike', 'Ramkumar', 'Sheik', 'Sumanth']
	}
];

export default writingZoneRoutes;
