import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import exploreZoneRoutes from '../../routes/exploreZoneRoutes';
import successZoneRoutes from '../../routes/successZoneRoutes';
import languageZoneRoutes from '../../routes/languageZoneRoutes';
import writingZoneRoutes from '../../routes/writingZoneRoutes';
import readingZoneRoutes from '../../routes/readingZoneRoutes';
import fluencyZoneRoutes from '../../routes/fluencyZoneRoutes';
import foundationalRoutes from '../../routes/foundationalSkillsRoutes';

export const appRouteMap = {
	zone_menu: [{route: '', pageTitle: 'Zone Menu'}],
	segment_selection: [{route: '', pageTitle: 'Segment Selection'}],
	explore: exploreZoneRoutes,
	reading: readingZoneRoutes,
	language: languageZoneRoutes,
	fluency: fluencyZoneRoutes,
	writing: writingZoneRoutes,
	success: successZoneRoutes,
	skills: foundationalRoutes
};

export const appRouteList = [
	...exploreZoneRoutes,
	...successZoneRoutes,
	...languageZoneRoutes,
	...writingZoneRoutes,
	...readingZoneRoutes,
	...fluencyZoneRoutes,
	...foundationalRoutes
];

export default function ActivityRouter(props) {
	const {path} = useRouteMatch();

	return (
		<div id='zone-frame'>
			<Switch>
				{exploreZoneRoutes.map(r => {
					return (
						<Route path={`${path}/explore/${r.route}`} exact key={r.route}>
							{r.component}
						</Route>
					);
				})}
				{successZoneRoutes.map(r => {
					return (
						<Route path={`${path}/success/${r.route}`} exact key={r.route}>
							{r.component}
						</Route>
					);
				})}
				{languageZoneRoutes.map(r => {
					return (
						<Route path={`${path}/language/${r.route}`} exact key={r.route}>
							{r.component}
						</Route>
					);
				})}
				{writingZoneRoutes.map(r => {
					return (
						<Route path={`${path}/writing/${r.route}`} exact key={r.route}>
							{r.component}
						</Route>
					);
				})}
				{readingZoneRoutes.map(r => {
					return (
						<Route path={`${path}/reading/${r.route}`} exact key={r.route}>
							{r.component}
						</Route>
					);
				})}
				{fluencyZoneRoutes.map(r => {
					return (
						<Route path={`${path}/fluency/${r.route}`} exact key={r.route}>
							{r.component}
						</Route>
					);
				})}
				{foundationalRoutes.map(r => {
					return (
						<Route path={`${path}/skills/${r.route}`} exact key={r.route}>
							{r.component}
						</Route>
					);
				})}
			</Switch>
		</div>
	);
}
