// TODO  TODO
export const IS_PRODUCTION_ENV = window.location.href.indexOf('TODO -  Need to add production url') > -1;

export const MEDIA_SERVER_URL = process.env.REACT_APP_MEDIA_URL || '/r180/media/student/';
export const API_BASE = process.env.REACT_APP_API_URL || '/api/r180/api/v1';
export const CLIENT_BASE_URL = process.env.REACT_APP_BASE || '/r180/student/app';
export const MEDIA_ACTIVITY_ASSETS_URL_BASE = 'assets/activi/';

export const ZONES = {
	EXPLORE: 'explore',
	READING: 'reading',
	LANGUAGE: 'language',
	FLUENCY: 'fluency',
	WRITING: 'writing',
	SUCCESS: 'success',
	ZMENU: 'zmenu',
	SEGMENTS: 'segments',
	SKILL: 'skill'
};

export const CLUSTER_ICONS = {
	'1001': '&#xe907;', //   Brains
	'1003': '&#xe90f;', //   Earth
	'1012': '&#xe919;', //   Freedom
	'1005': '&#xe91b;', //   Get Tech
	'1006': '&#xe91e;', //   Hidden World
	'1008': '&#xe927;', //   Moving On
	'1009': '&#xe92d;', //   Play On
	'1004': '&#xe92e;', //   POV
	'1007': '&#xe93f;', //   Showtime
	'1010': '&#xe943;', //   Stand Up
	'1002': '&#xe94d;', //   True Grit
	'1011': '&#xe94e;', //   Uncivil Wars

	'2001': '&#xe907;', //   Get Smart
	'2002': '&#xe94d;', //   What it takes
	'2003': '&#xe90f;', //   That's Garbage
	'2004': '&#xe91b;', //   High Tech
	'2005': '&#xe916;', //   Eye of the storm
	'2006': '&#xe950;', //   Voyagers
	'2007': '&#xe952;', //   Wild Things
	'2008': '&#xe94c;', //   Transformers
	'2009': '&#xe93f;', //   Many Voices
	'2010': '&#xe906;', //   Back from the brink
	'2011': '&#xe92d;', //   Huddle up
	'2012': '&#xe93b;', //   Roots

	'3001': '&#xe94d;', //   Eye on the prize
	'3002': '&#xe957;', //   Out on a limb
	'3003': '&#xe90f;', //   Our Planet
	'3004': '&#xe94b;', //   Tomorrowland
	'3005': '&#xe926;', //   Mosaic Nation
	'3006': '&#xe92e;', //   Debatable
	'3007': '&#xe951;', //   War Zone
	'3008': '&#xe943;', //   Disruptors
	'3009': '&#xe936;', //   Reach out
	'3010': '&#xe906;', //   Worlds Collide
	'3011': '&#xe908;', //   Breaking the Mold
	'3012': '&#xe91c;' //   Grass Roots
};

export let clusterStyles = {};
Object.keys(CLUSTER_ICONS).forEach(key => {
	clusterStyles[`cluster_${key}`] = {
		'&::before': {
			content: `"\\${CLUSTER_ICONS[key].replace('&#x', '00').replace(';', '')}"`
		}
	};
});

export const foreignLanguageMap = {
	English: 'English',
	Spanish: 'Español',
	Vietnamese: 'Tiếng Việt',
	Tagalog: 'Tagalog',
	Cantonese: '标准粤语',
	Mandarin: '普通話'
};

export const TIMEOUT = {
	INACTIVITY_HELP: 1000 * 60 * 5, // 5 minutes - play the help directions
	INACTIVITY_PROMPT: 1000 * 60 * 10, // 10 minutes - show the "are you there?" prompt
	INACTIVITY_NO_RESPONSE: 1000 * 60 * 5 // 5 minutes - automatically log them out, this is minutes after INACTIVITY_PROMPT shows
};

export const ZONE_CODES = [
	'explore_zone',
	'reading_zone',
	'language_zone',
	'fluency_zone',
	'writing_zone',
	'success_zone'
];
