import React, {useEffect, useState} from 'react';
import {AnchorVideo, ActivityInstructionButton, ActivitySupportVideoSummaryButton, HoverTip} from '@reading/common';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {isEmpty} from 'lodash';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import {AnchorVideo as AnchorVideoConfig} from './AnchorVideo';
import useRouteInfo from '../../utils/useRouteInfo';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {MEDIA_SERVER_URL} from '../../utils/constants';
import {useUnmount} from 'react-use';
import {useDispatch} from 'react-redux';
import useIdleHelp from '../../utils/useIdleHelp';
import {IS_PRODUCTION_ENV} from '../../utils/constants';
import ActivityFrame from '../../containers/App/ActivityFrame';
import {transitionToNextActivity} from '../../utils/navigation';
import {uiSlice} from '../../store/slices/ui';
import {sleep} from '../../utils/sleep';

export default function AnchorVideoActivity(props) {

	const { activityData, contentData, bundledActivityData, settings, isTransitioning } = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			contentData: state.activity.contentData,
			bundledActivityData: state.activity.bundledActivityData,
			settings: state.session.session.settings,
			isTransitioning: state && state.ui && state.ui.isTransitioning
		};
	});

	const dispatch = useDispatch();
	const history = useHistory();
	const [anchorVideoState, setAnchorVideoState] = useState(
		history.location.state ? history.location.state.anchorVideoState : AnchorVideoConfig.STATE.DEFAULT
	); // DEFAULT | INTRO | FIRST_SEGMENT
	const [status, setStatus] = useState(IS_PRODUCTION_ENV ? FooterForwardBack.INITIAL : FooterForwardBack.VALID);
	const [videoData, setVideoData] = useState({});
	const [tracks, setTracks] = useState([]);
	const [video, setVideo] = useState();
	const [pauseVideo, setPauseVideo] = useState(true);

	const isActivity = activityData ? activityData.activityServerId === 'anchor_video' : false;

	// create the options, map the object to our components, randomize the array
	useEffect(() => {
		const initialize = async () => {
			const modifiedActivityData = {...activityData, anchorVideoState};
			const {taskData} = await AnchorVideoConfig.init(modifiedActivityData);
			const initialVideoData = {
				playOnLoad: true,
				videoStartTime: 0,
				volume: 0.7,
				containerStyle: {},
				srcType: 'video/mp4',
				poster_url: taskData.video_poster_url,
				summary_url: taskData.video_summary_url,
				thumb_url: taskData.video_thumb_url,
				video_url: taskData.video_url,
				vtt_url: taskData.video_vtt_url,
				help_url: taskData.help_url,
				isComplete: false,
				startTime: new Date()
			};
			const tracks = [
				{src: taskData.video_vtt_url, label: 'English subtitles', kind: 'subtitles', srcLang: 'en'}
			];
			setVideoData(initialVideoData);
			setTracks(tracks);
			setPauseVideo(true);
			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				AudioPlayer2.play('anchor_video_intro');
			}
		};
		if (isEmpty(activityData) === false && isActivity && isTransitioning === false) {
			initialize();
		}
	}, [activityData, anchorVideoState]);

	// stop any sounds
	/* istanbul ignore next */
	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	const playVO = (strName, soundURL) => {
		const audioPlayer = AudioPlayer2.load({name: strName, src: MEDIA_SERVER_URL + soundURL});
		AudioPlayer2.play(strName);
	};

	const handleVideoEnd = () => {
		playVO(AnchorVideoConfig.VO_REPLAY_INTO, AnchorVideoConfig.SOUND_AV_REPLAY_INTRO_URL);
		if (anchorVideoState === AnchorVideoConfig.STATE.DEFAULT) {
			let newVideoData = videoData;
			newVideoData.isComplete = true;
			setVideoData(newVideoData);
			if (status === FooterForwardBack.INITIAL) {
				setStatus(FooterForwardBack.VALID);
			}
		}
	};

	const handleForward = async e => {
		AudioPlayer2.stopAll();
		if (!videoData.isComplete) {
			setPauseVideo(true);
		}
		/* istanbul ignore next */
		if (status === FooterForwardBack.VALID) {
			try {
				await transitionToNextActivity(history, {
					activity: {
						studentActivityId: bundledActivityData.activity.studentActivityId,
						isComplete: true
					},
					introstatus: {
						activityId: bundledActivityData.introstatus.activityId,
						finished: true
					}
				});
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		}
	};
	const handleVideoPlay = video => {
		setPauseVideo(false);
		setTimeout(() => AudioPlayer2.stopAll(), 200);
		setVideo(video);
	};
	const handleHelp = e => {
		setPauseVideo(true);
	};
	const handleBeforeExitApp = () => {
		setPauseVideo(true);
	};
	const handleBeforeZoneMenu = () => {
		setPauseVideo(true);
	};
	const handleZoneMenuClosed = () => {
		setPauseVideo(false);
	};
	const handleExitAppClosed = () => {
		setPauseVideo(false);
	};
	useIdleHelp(videoData ? videoData.help_url : '');

	return (
		<>
			<Navbar
				helpSoundUrl={videoData ? videoData.help_url : ''}
				onHelp={handleHelp}
				beforeZoneMenu={handleBeforeZoneMenu}
				beforeClose={handleBeforeExitApp}
				onZoneMenuClosed={handleZoneMenuClosed}
				onExitAppClosed={handleExitAppClosed}
			/>

			<ActivitySuspense requiredRenderData={activityData}>
				<ActivityFrame>
					<ActivityInstructionButton audioSrc={AnchorVideoConfig.SOUND_AV_INTRO_URL} white={true} />
					<AnchorVideo
						autoPlay={videoData.playOnLoad}
						time={videoData.videoStartTime}
						vol={videoData.volume}
						containerStyle={videoData.containerStyle}
						poster={videoData.poster_url}
						handleVideoEnded={handleVideoEnd}
						onVideoPlay={handleVideoPlay}
						pause={pauseVideo}
					>
						<source src={videoData.video_url} type={videoData.srcType} />
						{tracks.map((track, index) => (
							<track
								key={index}
								src={track.src}
								label={track.label}
								kind={track.kind}
								srcLang={track.srcLang}
							/>
						))}
					</AnchorVideo>
				</ActivityFrame>
				<Footer>
					{anchorVideoState === AnchorVideoConfig.STATE.DEFAULT && (
						<ActivitySupportVideoSummaryButton soundUrl={videoData.summary_url} video={video} />
					)}
					<HoverTip title={status === FooterForwardBack.VALID ? 'Click to Go On' : ''} placement="top">
						<span>
							<FooterForwardBack status={status} onForward={handleForward} isBackVisible={false} />
						</span>
					</HoverTip>
				</Footer>
			</ActivitySuspense>
		</>
	);
}
