import React, { useState } from 'react';

import PropTypes from 'prop-types';

import SoundButton from '../../components/SoundButton';
import DndHolder from './DndHolder';

const DragHolder = ({ isMixup, classes, words, updatedRightAudio, activitySubmittedOnce, reset, wordMapAfterSubmit }) =>
	isMixup &&
	updatedRightAudio.map((word, j) => {
		return (
			<div
				className={classes.soundBtnWrapper}
				style={{ top: word.top, left: word.left }}
				key={j}
			>
				{word.name === 'empty' ? (
					<div />
				) : (
						<DndHolder
							droppablePefix={'droppableRight'}
							index={j}
							draggablePrefix={'rightSideAudio'}
							data={words}
							name={word.name}
							word={word}
							isDroppable={false}
							activitySubmittedOnce={activitySubmittedOnce}
							reset={reset}
							wordMapAfterSubmit={wordMapAfterSubmit}
						>
						</DndHolder>
					)}
			</div>
		);
	});

DragHolder.defaultProps = {
	isMixup: false,
	classes: {},
	words: [],
	updatedRightAudio: [],
	isDroppable: false
};
DragHolder.prototype = {
	isDroppable: PropTypes.bool,
	activitySubmittedOnce: PropTypes.bool,
	reset: PropTypes.bool,
	wordMapAfterSubmit: PropTypes.object,
	droppablePefix: PropTypes.string,
	word: PropTypes.string,
	index: PropTypes.string,
	draggablePrefix: PropTypes.string,
	data: PropTypes.array,
	name: PropTypes.string,
	isMixup: PropTypes.bool,
	classes: PropTypes.object,
	words: PropTypes.array,
	updatedRightAudio: PropTypes.array
};
export default DragHolder;
