import {MEDIA_SERVER_URL} from '../../../utils/constants';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {VO, EXT} from './WordBuilderConstants';

const FS_MEDIA_SERVER_URL = MEDIA_SERVER_URL + 'assets/fs/';

export const preloadImages = ({word_sets, setImagesLoaded}) => {
	let cachedImages = {};
	let numImages = 0;
	let loadedImages = 0;
	const handleLoad = () => {
		loadedImages++;
		if (loadedImages >= numImages) {
			setImagesLoaded(true);
		}
	};
	word_sets.forEach(({activity_words: activityWords}) => {
		numImages += activityWords.length + 1;
	});
	word_sets.forEach(({base_word: baseWord, activity_words: activityWords}) => {
		//load images into image objects as cache
		cachedImages[baseWord] = new Image();
		cachedImages[baseWord].onload = handleLoad;
		cachedImages[baseWord].src = `${FS_MEDIA_SERVER_URL}/activi/wbld/fs_wbld_${baseWord}.jpg`;
		activityWords.forEach(({text}) => {
			cachedImages[text] = new Image();
			cachedImages[text].onload = handleLoad;
			cachedImages[text].src = `${FS_MEDIA_SERVER_URL}/activi/wbld/fs_wbld_${text}.jpg`;
		});
	});
	return cachedImages;
};

export const preloadAudio = word_sets => {
	let cachedAudio = {};
	word_sets.forEach(({base_word: baseWord, activity_words: activityWords}) => {
		//load audio into cache
		AudioPlayer2.load({
			name: 'baseword',
			src: `${FS_MEDIA_SERVER_URL}/dictio/word/fs_word_${baseWord}.${EXT}}`
		});
		activityWords.forEach(({text}) => {
			cachedAudio[`word_${text}`] = AudioPlayer2.load({
				name: `word_${text}`,
				src: `${FS_MEDIA_SERVER_URL}/dictio/word/fs_word_${text}.${EXT}`
			});
			cachedAudio[`ctxt_${text}`] = AudioPlayer2.load({
				name: `ctxt_${text}`,
				src: `${FS_MEDIA_SERVER_URL}/dictio/ctxt/fs_ctxt_${text}.${EXT}`
			});
		});
		Object.keys(VO).forEach(key => {
			cachedAudio[`vo_${key}`] = AudioPlayer2.load({name: key, src: VO[key]});
		});
	});
	return cachedAudio;
};
