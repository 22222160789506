import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import {gotoNextActivity} from '../../utils/navigation';
import {updateActivityAction} from '../../store/slices/activity.js';
import {useDispatch, useSelector} from 'react-redux';
import {MEDIA_ACTIVITY_ASSETS_URL_BASE, MEDIA_SERVER_URL, ZONE_CODES as ZONES} from '../../utils/constants';
import {getAudioExt} from '../../utils/audio';
import useIdleHelp from '../../utils/useIdleHelp';
import ZoneMenuNavbar from './ZoneMenuNavbar';
import {makeStyles} from '@material-ui/core/styles';
import ZoneMenuItem from './ZoneMenuItem';
import { uiSlice } from '../../store/slices/ui';
import { useMount, useUnmount } from 'react-use';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import { isEmpty } from 'lodash';
import media from '../../api/media';
import { isFirefox } from '../../utils/browsers';

export const useStyles = makeStyles(theme => ({
	zoneMenu: {
		width: '100vw',
		height: '100vh',
		position:'relative',
		paddingTop: '30px',
		background: 'linear-gradient(90.49deg, #1F252B 7.93%, rgba(0, 0, 0, 0) 43.32%)',
		overflow: 'hidden'
	},
	leftTitle: {
		position: 'absolute',
		top: '161px',
		left: `-1750px`,
		'@media (max-width: 1023.9px)': {
			left: `-2000px`
		},
		'@media (max-width: 1310.9px) and (min-width: 1024px)': {
			left: `calc(100vw - 3030px)`
		},
		height: '280px',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		background: 'transparent',
		'& .spacer': {
			width: '2000px',
			height: '100%',
			background: 'linear-gradient(270.6deg, rgba(85, 86, 90, 0.85) 0.49%, #131313 99.45%)',
			opactiy: '0.9'
		},
		'& .text': {
			width: '440px',
			height: '100%',
			background: isFirefox() ? `linear-gradient(89.75deg, rgba(85, 86, 90, 0.7) 2.1%, rgba(99, 117, 126, 0.525) 99.64%)` : `linear-gradient(89.75deg, rgba(85, 86, 90, 0.5) 2.1%, rgba(99, 117, 126, 0.125) 99.64%)`,
			borderRadius: '0 8px 8px 0',
			color: theme.colors.white,
			fontFamily: theme.fonts.uiBold,
			textShadow: '0px 0px 16px #000',
			padding: '24px 12px 12px 60px',
			backdropFilter: 'blur(20px)',
			'-webkit-backdrop-filter': 'blur(20px)',
			'& h1': {
				fontSize: '56px',
				lineHeight: '122%',
				margin: '0',
			},
			'& h2': {
				fontSize: '24px',
				lineHeight: '30px',
				letterSpacing: '0.15px'
			},
			'& h4': {
				fontSize: '16px',
				lineHeight: '30px',
				margin: '0',
				letterSpacing: '0.15px',
				textTransform: 'uppercase',
			}
		}
	},
	rightMenu: {
		position: 'absolute',
		top: '131px',
		right: '0',
		width: '372px',
		height: '79vh',
		maxHeight: '592px',
		overflowY: isFirefox() ? 'auto' : 'overlay',
        '&::-webkit-scrollbar': {
            width: '6px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            padding: '10px 0'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#CCC',
            borderRadius: '8px'
        },
		border: `1px solid ${theme.colors.grey}`,
		borderRight: 'none',
		boxShadow: `0px 0px 8px 8px rgba(0, 0, 0, 0.3)`,
		borderRadius: '8px 0 0 8px',
		background: 'linear-gradient(270.6deg, #131313 0.49%, rgba(85, 86, 90, 0.85) 99.45%)',
		padding: '18px 48px',
		zIndex: '200'
	},
	bottomPanel: {
		position: 'absolute',
		width: '100vw',
		height: '16vh',
		left: '0',
		bottom: '0',
		background: 'linear-gradient(180deg, #131313 49.08%, rgba(19, 19, 19, 0) 96.86%)',
		transform: 'rotate(180deg)',
		zIndex: '99'
	},
	backgroundSegmentImage: {
		width: '100vw',
		height: '95vh'
	}
}));

const ZoneMenu = props => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const {studentActivityId} = useSelector(state => state.activity.activityData);
	const {activityData, zoneStatus} = useSelector(state => {
		return {activityData: state.activity.activityData, zoneStatus: state.activity.zonestatus};
	});

	const [segmentName, setSegmentName] = useState('');

	const EXT = getAudioExt();
	const HELP_SOUND = `${MEDIA_SERVER_URL}${MEDIA_ACTIVITY_ASSETS_URL_BASE}zonemenu/r180u_zonemenu_help.${EXT}`;
	const SEGMENT_INFO = `content/segments/r180u_segments_{stage}.json`;

	useMount(() => {
		AudioPlayer2.loadSound(HELP_SOUND);
	});

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	useEffect(() => {
		const getSegmentInfo = async() => {
			const response = await media.getOne(SEGMENT_INFO.replace('{stage}', activityData.stage));
			const match = response.segments.find((segment) => {
				return segment.segment_number === activityData.segment;
			});
			setSegmentName(match.name);
		};

		if (isEmpty(activityData) === false) {
			getSegmentInfo();
		}
	}, [activityData]);

	const setZone = async(zone) => {
		const postData = {
			activity: {
				studentActivityId,
				isComplete: true,
				navigation: zone
			}
		};
		try {
			const result = await dispatch(updateActivityAction({data: postData}));
			if (result.payload) {
				gotoNextActivity({history, zone: zone.replace('_zone', '')});
			}
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
	};

	const imageName = activityData && activityData.segment ? `${activityData.stage}${activityData.segment.toString().padStart(2, '0')}` : '';

	useIdleHelp(HELP_SOUND);

	return (
		<>
			<ZoneMenuNavbar helpSoundUrl={HELP_SOUND} />
			<ActivitySuspense
				requiredRenderData={[activityData, zoneStatus]}
				showSpinner={!activityData && true}
				title={'Zone Menu'}
			>
				<div className={classes.zoneMenu}>
					<div
						className={classes.backgroundSegmentImage}
						style={{
							background: `url(${MEDIA_SERVER_URL}/${MEDIA_ACTIVITY_ASSETS_URL_BASE}zonemenu/r180u_zonemenu_${imageName}.jpg)`,
							backgroundSize: '100% 100%'
						}}
					>
					</div>

					<div className={`${classes.leftTitle} animate__animated animate__slideInLeft`}>
						<div className='spacer'></div>
						<div className='text'>
							<h4>Segment</h4>
							<h1>{segmentName}</h1>
						</div>
					</div>

					<div className={classes.bottomPanel}></div>

					<div className={`${classes.rightMenu} animate__animated animate__slideInRight animate_delay-1s`} role={'main'}>
						{ZONES.map(zone => {
							if (isEmpty(zoneStatus)) {
								return null;
							}
							return (
								zoneStatus.state[zone.toLowerCase()] &&
								zoneStatus.state[zone.toLowerCase()].enabled && (
									<ZoneMenuItem
										datatestid={`zoneButton`}
										key={`zoneButton_${zone}`}
										zoneState={zoneStatus.state[zone.toLowerCase()]}
										zoneDisplay={zoneStatus.display[zone]}
										zoneProgress={zoneStatus.progress[zone]}
										zoneCode={zone}
										onClick={() => {
											setZone(zone);
										}}
									/>
								)
							);
						})}
					</div>
				</div>
			</ActivitySuspense>
		</>
	);
};

export default ZoneMenu;
