import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {useUnmount} from 'react-use';
import SpellingClinicItem from './SpellingClinicItem';
import ComponentLoader from './ComponentLoader';

const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100%'
	}
}));

const SpellingClinic = props => {
	const {
		wordsList,
		configData,
		screenIndex,
		studyWordIndex,
		wordCardIndex,
		setGoOnDisabled,
		handleForward
	} = props;
	const classes = useStyles();

	/* istanbul ignore next */
	useUnmount(() => {
		AudioPlayer2.stopAll();
	});

	return (
		<>
			{wordsList.length && (
				<div className={`${classes.wrapper} fill-app-frame`} data-testid={'studylist'}>
					{screenIndex === 0 && (
							<SpellingClinicItem
								wordsList={wordsList}
								configData={configData}
								setGoOnDisabled={setGoOnDisabled}
							/>
					)}
					{screenIndex > 0 && (
							<ComponentLoader
								wordsList={wordsList}
								studyWordIndex={studyWordIndex}
								wordCardIndex={wordCardIndex}
								setGoOnDisabled={setGoOnDisabled}
								handleForward={handleForward}
								configData={configData}
							/>
					)}
				</div>
			)}
		</>
	);
};

SpellingClinic.defaultProps = {
	wordsList: [],
	configData: {},
	screenIndex: 0,
	studyWordIndex: 0,
	wordCardIndex: 0
};

SpellingClinic.propTypes = {
	wordsList: PropTypes.array,
	configData: PropTypes.object,
	screenIndex: PropTypes.number,
	studyWordIndex: PropTypes.number,
	wordCardIndex: PropTypes.number,
	setGoOnDisabled: PropTypes.func,
	handleForward: PropTypes.func
};

export default React.memo(SpellingClinic);
