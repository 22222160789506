import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import SelfCheckActivityProgressPage from './SelfCheckActivityProgressPage';
import WordList from '../../components/WordList';
import {isFirefox} from '@reading/r180/src/utils/browsers';
import StartActivity from '../StartActivity';
import MicCheck from '../../components/MicCheck';

export const useStyles = makeStyles(theme => ({
	selfCheckContainer: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		'& .wordListContainer': {
			display: 'inline-block',
			marginRight: '50px'
		},
		'& .wordListWrapper': {
			overflowY: isFirefox() ? 'auto' : 'overlay',
			display: 'flex',

			'&::-webkit-scrollbar': {
				width: '6px'
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				padding: '10px 0'
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#CCC',
				borderRadius: '8px'
			}
		}
	}
}));

const SelfCheckContainer = props => {
	const {
		id,
		wordList,
		activityWord,
		pageDetails,
		activityAudio,
		progressValue,
		activityPauseVO,
		activityContinueVO,
		activityNextVO,
		activityFeedbackVO,
		onTestMicGoOn,
		onStartActivity,
		onSelfFeedback
	} = props;

	const classes = useStyles();
	const mounted = useRef(false);

	useEffect(() => {
		if (pageDetails === 'resultPage') {
			const correctWords = wordList.filter(
				word => word.feedback === 'correct'
			);

			if (!mounted.current) {
				if (correctWords.length === wordList.length) {
					AudioPlayer2.load(activityNextVO);
					AudioPlayer2.playSync(activityNextVO.name);
				} else {
					AudioPlayer2.load(activityFeedbackVO);
					AudioPlayer2.playSync(activityFeedbackVO.name);
				}
				mounted.current = true;
			}
		}
	}, [pageDetails, wordList, activityNextVO, activityFeedbackVO]);

	const renderContent = () => {
		switch (pageDetails) {
			case 'testMicPage': {
				return <MicCheck onMicCheckFinished={onTestMicGoOn} />;
			}
			case 'progressPage': {
				return (
					<SelfCheckActivityProgressPage
						id={'progressPage'}
						activityWord={activityWord}
						onSelfFeedback={onSelfFeedback}
						progressValue={progressValue}
						activityAudio={activityAudio}
						activityPauseVO={activityPauseVO}
						activityContinueVO={activityContinueVO}
					/>
				);
			}
			case 'resultPage': {
				return (
					<div className={'wordListWrapper'}>
						<div className={'wordListContainer'}>
							<WordList
								key="correct"
								wordList={wordList
									.filter(word => {
										return word.feedback === 'correct';
									})
									.map(w => w.wordId)}
							/>
						</div>
						<div className={'wordListContainer'}>
							<WordList
								key="incorrect"
								isCorrect={false}
								wordList={wordList
									.filter(word => {
										return word.feedback === 'incorrect';
									})
									.map(w => w.wordId)}
							/>
						</div>
					</div>
				);
			}
			default: {
				return (
					<StartActivity
						onStartActivity={onStartActivity}
						startActivityAudioSrc={activityAudio.url}
					/>
				);
			}
		}
	};

	return (
		<div id={id} className={classes.selfCheckContainer}>
			{renderContent()}
		</div>
	);
};

SelfCheckContainer.defaultProps = {
	id: ''
};

SelfCheckContainer.propTypes = {
	id: PropTypes.string.isRequired,
	onTestMicGoOn: PropTypes.func,
	onStartActivity: PropTypes.func
};

export default React.memo(SelfCheckContainer);
