import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './Navbar.style.js';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useRouteInfo from '../../utils/useRouteInfo.js';
import { useSelector } from 'react-redux';
import { capitalizeWord } from '../../utils/stringUtils';
import NavbarHelpButton from './NavbarHelpButton.js';
import { AppModal } from '@reading/common';
import { HoverTip } from '@reading/common';
import { logout } from '../../store/slices/session.js';
import { appRouteMap } from './ActivityRouter.js';
import { uiSlice, zoneMenuAction } from '../../store/slices/ui.js';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import SettingsMenu from './SettingsMenu.js';
import { CLIENT_BASE_URL } from '../../utils/constants.js';

const Navbar = (props) => {
	const { className, helpSoundUrl, onHelp, short, onZoneMenuClosed, onExitAppClosed } = props;
	const { zone, activity } = useRouteInfo();
	const classes = useStyles({ zone });
	const history = useHistory();
	const dispatch = useDispatch();

	const [activityServerId, setActivityServerId] = useState('');

	const [isOpenZoneMenuModal, setOpenZoneMenuModal] = useState(false);
	const [isOpenExitAppModal, setOpenExitAppModal] = useState(false);
	const [isOpenSettings, setOpenSettings] = useState(false);


	const user = useSelector(state => {
		return state.session.session.user;
	});

	const activityData = useSelector(state => {
		return state.activity.activityData;
	});

	const name = user && user.firstName ? `${user.firstName} ${user.lastName}` : '';

	const [activityTitle, setActivityTitle] = useState('');
	const [zoneTitle, setZoneTitle] = useState('');
	const [scrollY, setScrollY] = useState(0);

	// determine the zone and page title to display in the NavBar
	useEffect(() => {
		const zoneRoutes = appRouteMap[zone];

		const title = zoneRoutes
			? zoneRoutes.filter(r => {
				return r.route === activity;
			})
			: [];

		if (title.length > 0) {
			setActivityTitle(title[0].pageTitle);
		} else {
			setActivityTitle('Learning');
		}

		if (activityData) {
			setActivityServerId(activityData.activityServerId);
		}

		// only R180 has this field
		if (activityData && activityData.activityServerId) {
			setZoneTitle(`${capitalizeWord(zone)} Zone`);
		}
		// FS
		else if (activityData) {
			setZoneTitle(activityData.topic_title);
		}
		else {
			setZoneTitle('');
		}

	}, [activityData, zone, activity]);

	// in some designs the Navbar changes colors while scrolling
	// though it doesn't do this now, we decided not to remove this code
	// in case it gets added back in the future
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(document.body.scrollTop || document.documentElement.scrollTop);
	};

	// they click the zone menu button in R180
	const handleZoneMenuClick = async e => {
		AudioPlayer2.stopAll();
		const { beforeZoneMenu } = props;
		e.stopPropagation();

		if (typeof beforeZoneMenu === 'function') {
			await beforeZoneMenu();
		}

		dispatch(uiSlice.actions.setZoneMenuModalOpen(true));
		setOpenZoneMenuModal(true);
	};

	// they click the exit button in R180 or FS
	const handleExitClick = async e => {
		AudioPlayer2.stopAll();
		const { beforeClose } = props;
		e.stopPropagation();

		if (typeof beforeClose === 'function') {
			await beforeClose();
		}
		dispatch(uiSlice.actions.setExitModalOpen(true));
		setOpenExitAppModal(true);
	};

	// they click 'Yes' in the zone menu modal in R180
	const handleConfirmZoneMenu = e => {
		if (typeof onZoneMenuClosed === 'function') {
			onZoneMenuClosed(true);
		}
		setOpenZoneMenuModal(false);
		dispatch(uiSlice.actions.setZoneMenuModalOpen(false));
		dispatch(zoneMenuAction({ history, studentActivityId: activityData.studentActivityId }));
	};

	// they click 'No" in the zone menu modal in R180
	const handleCancelZoneMenu = e => {
		if (typeof onZoneMenuClosed === 'function') {
			onZoneMenuClosed(false);
		}
		dispatch(uiSlice.actions.setZoneMenuModalOpen(false));
		setOpenZoneMenuModal(false);
	};

	// they click 'Yes' in the exit modal in R180 or FS
	const handleConfirmExit = async(e) => {
		if (typeof onExitAppClosed === 'function') {
			onExitAppClosed(true);
		}
		setOpenExitAppModal(false);
		dispatch(uiSlice.actions.setExitModalOpen(false));
		try {
			await dispatch(logout());
		}
		finally {
			history.push(`${CLIENT_BASE_URL}/logout`);
		}
	};

	// they click 'No' in the exit modal in R180 or FS
	const handleCancelExit = e => {
		if (typeof onExitAppClosed === 'function') {
			onExitAppClosed(false);
		}
		dispatch(uiSlice.actions.setExitModalOpen(false));
		setOpenExitAppModal(false);
	};

	const toggleOpenSettings = ()=>{
		setOpenSettings(!isOpenSettings);
	}

	const isScrolled = scrollY > 0;

	return (
		<header className={`${classes.Navbar} navbar-${zone} ${isScrolled ? 'isScrolled' : ''} ${className} ${short ? 'short' : ''}`}>
			<div className="container">
				{activityServerId !== 'zone_menu' && activityServerId !== 'segment_selection' && (
					<div className="left">
						<button type="button" onClick={handleZoneMenuClick} aria-label="Zone Menu">
							<i className="r180 zone-menu" />
						</button>
						<span className="zoneName">{zoneTitle}</span>
						<i className="r180 arrow-right" />
						<span className="activityName">{activityTitle}</span>
					</div>
				)}
				<div className="right">
					<span className="personName">{name}</span>
					<button className={`settings-button settings-click`} onClick={toggleOpenSettings}>
						{
							isOpenSettings === false
						 	?
						 		<span className='settings-click'><i className='r180 Accessibility settings-click' /><i className='r180 arrow-down settings-click' /></span>
							:
								<span className='settings-click'><i className='r180 Accessibility settings-click' /><i className='r180 arrow-up settings-click' /></span>
						}
					</button>
					<NavbarHelpButton soundUrl={helpSoundUrl} onHelpClick={onHelp} />
					<HoverTip title='Click the "X" to sign out'>
						<button type="button" onClick={handleExitClick} aria-label="Close">
							<i className="r180 close-alt" />
						</button>
					</HoverTip>
				</div>
			</div>
			{isOpenExitAppModal && (
				<AppModal
					isOpen={isOpenExitAppModal}
					onConfirm={handleConfirmExit}
					onCancel={handleCancelExit}
					text={`Are you sure you want to Sign Out?`}
					showSpeakerIcon={false}
				/>
			)}
			{isOpenZoneMenuModal && (
				<AppModal
					isOpen={isOpenZoneMenuModal}
					onConfirm={handleConfirmZoneMenu}
					onCancel={handleCancelZoneMenu}
					text={`Are you sure you want to go back to the Zone Menu?`}
					showSpeakerIcon={false}
				/>
			)}
			{isOpenSettings && (
				<SettingsMenu
					onClose={() => setOpenSettings(false)}
				/>
			)}
		</header>
	);
}

Navbar.defaultProps = {
	className: '',
	helpSoundUrl: '',
	short: false
};

Navbar.propTypes = {
	className: PropTypes.string,
	helpSoundUrl: PropTypes.string,
	beforeZoneMenu: PropTypes.func,
	onHelp: PropTypes.func,
	onZoneMenuClosed: PropTypes.func,
	onExitAppClosed: PropTypes.func,
	beforeClose: PropTypes.func
};

export default React.memo(Navbar);
