import React from 'react';
import SoundButton from '../../components/SoundButton';
import {makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';

export const useStyles = makeStyles(theme => ({
	toolTipDesc: {
		width: '600px',
		height: '145px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		transition: 'margin .5s',
		fontFamily: theme.fonts.context,
		fontSize: '24px',
		padding: '0 72px'
	},
	descSoundButton: {
		verticalAlign: 'top',
		background: 'none',
		color: theme.colors.slate,
		border: '0px',
		position: 'relative',
		left: '-10px',
		top: '-43px'
	},
	divider: {
		borderTop: `1px solid ${theme.colors.paleGrey}`,
		width: '80%',
		position: 'relative',
		top: ' -12px'
	},
	descText: {
		position: 'relative', top: '-27px'
}
}));

const ToolTipDescription = (props) => {
	const { showDescription, index, item, choicesData } = props;

	const classes = useStyles();

	const generateMorphAudio = (choicesData, item) =>
		choicesData[item.answer_text].morphologyTipId.length === 1
			? `0${choicesData[item.answer_text].morphologyTipId}`
			: choicesData[item.answer_text].morphologyTipId;

	return (
		<>
			{showDescription && (
				<>
					<span className={classes.divider} />
					<div className={classes.toolTipDesc}>
						<SoundButton
							className={classes.descSoundButton}
							icon="speaker-center"
							id={`${item.answer_text}${index}morphologyTipText`}
							size={32}
							sound={{
								name: `${item.answer_text}morphologyText${index}`,
								src: bulkReplace(
									KnowledgeForReading.WORD_FAMILY_MORPH,
									{
										item: generateMorphAudio(
											choicesData,
											item
										)
									}
								)
							}}
						/>
						<span className={classes.descText}>
							{choicesData[item.answer_text].morphologyTipText}
						</span>
					</div>
				</>
			)}
		</>
	);
};
ToolTipDescription.prototype = {
	showDescription: PropTypes.bool,
	index: PropTypes.number,
	item: PropTypes.object,
	choicesData: PropTypes.object
};

ToolTipDescription.defaultProps = {
	showDescription: false,
	index: 0,
	item: {},
	choicesData: {}
};
export default React.memo(ToolTipDescription);
