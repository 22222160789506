import React, {useRef, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import Switch from "react-switch";
import { sessionSlice, setSettingAction } from '../../store/slices/session';

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'absolute',
		width: '360px',
		borderRadius: '8px',
		top: '60px',
		right: '48px',
		zIndex: 888,
		color: theme.colors.black,
		background: theme.colors.lightGrey,
		padding: '20px 16px',
		boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.55)',
		'& .go-on': {
			transform: 'rotate(-90deg)',
			color: theme.colors.lightGrey,
			position: 'absolute',
			top: '-14px',
			left: '254px'
		},
		'& .settings-row': {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: '16px',
			'&:last-of-type': {
				marginBottom: '0'
			},
			'& .r180': {
				marginRight: '16px',
				fontSize: '24px'
			}
		}
	},
}));

const SettingsMenu = (props) => {
	const { onClose } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const menuRef = useRef();

	const { settings } = useSelector((state) => {
		return state.session.session;
	});

	const handleSwitch = (name, isSelected) => {
		const data = {variable: name, value: isSelected};
		dispatch(sessionSlice.actions.setSetting(data));
		dispatch(setSettingAction(data));
	};

	const handleClickOutside = (e) => {
		if (menuRef.current.contains(e.target) === false && e.target.className.indexOf('settings-click') === -1) {
			onClose();
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	});

	return (
		<div ref={menuRef} className={`${classes.wrapper} animate__animated animate__fadeIn settings-menu`}>

			<i className='r180 go-on' />

			<div className={`settings-row`}>
				<i className='r180 closed-captions'></i>
				<span>Closed Captions</span>
				<Switch
					onChange={(isSelected) => handleSwitch('captioningEnabled', isSelected)}
					checked={!!settings.captioningEnabled}
					handleDiameter={18}
					height={24}
					width={48}
				/>
			</div>

			<div className={`settings-row`}>
				<i className='r180 instructions'></i>
				<span>Autoplay Instructions</span>
				<Switch
					onChange={(isSelected) => handleSwitch('autoPlayEnabled', isSelected)}
					checked={!!settings.autoPlayEnabled}
					handleDiameter={18}
					height={24}
					width={48}
				/>
			</div>

		</div>
	);
};

export default React.memo(SettingsMenu);
