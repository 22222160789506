import React from 'react';
import { useMount, useUnmount } from 'react-use';
import Navbar from '../../containers/App/Navbar';
import { ActivityInstructionButton, CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import { useHistory } from 'react-router-dom';
import { SubzoneSelector } from '@reading/common';
import { makeStyles } from '@material-ui/core';
import { transitionToNextActivity } from '../../utils/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { uiSlice } from '../../store/slices/ui';
import { FluencyAssessmentSelector } from './FluencyAssessmentSelector';
import { isEmpty } from 'lodash';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import useAppMeasurements from '../../utils/useAppMeasurements';

const useStyle = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		marginTop: '80px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap'
	}
}));

const FluencyAssessmentSelectorActivity = props => {

	const classes = useStyle();
	const history = useHistory();
	const dispatch = useDispatch();
	const { headerHeight } = useAppMeasurements();

    const {activityData, fluencyassessmentstatus} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			fluencyassessmentstatus: state.activity.fluencyassessmentstatus
		};
	});

	const isActivity = activityData ? activityData.activityServerId === 'fluency_assessment_selector' : false;

	const isRequiredSpellingFinished = fluencyassessmentstatus && fluencyassessmentstatus.spellingPassed && fluencyassessmentstatus.availableZones.length === 2;

	useMount(() => {
		const init = async() => {
			await FluencyAssessmentSelector.init();
			if (fluencyassessmentstatus && fluencyassessmentstatus.hasStarted) {
                AudioPlayer2.play(FluencyAssessmentSelector.INTRO2);
			}
			else if (isRequiredSpellingFinished) {
                AudioPlayer2.play(FluencyAssessmentSelector.MODAL);
            }
            else if (fluencyassessmentstatus && fluencyassessmentstatus.availableZones.length === 2) {
                AudioPlayer2.play(FluencyAssessmentSelector.INTRO1);
            }
            else {
                AudioPlayer2.play(FluencyAssessmentSelector.INTRO2);
            }
		}

		if (isEmpty(activityData) === false && isActivity) {
			init();
		}
	});

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	const handleClick = async nextActivity => {
		AudioPlayer2.stopAll();
		if (nextActivity === 'spelling_zone') {
            if (isRequiredSpellingFinished) {
                await AudioPlayer2.playSync(FluencyAssessmentSelector.MODAL_ACCEPT);
            }
            else {
                await AudioPlayer2.playSync(FluencyAssessmentSelector.SPELLING);
            }
        }
        else {
            await AudioPlayer2.playSync(FluencyAssessmentSelector.WORD);
        }
		try {
			await transitionToNextActivity(history, {
				activity: {
					isComplete: true,
					navigation: nextActivity,
					studentActivityId: activityData.studentActivityId
				}
			});
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
	};

	return (
		<>
			<Navbar helpSoundUrl={FluencyAssessmentSelector.HELP} />

            <ActivitySuspense requiredRenderData={fluencyassessmentstatus} title="Fluency Selector">
				<ActivityInstructionButton
					audioSrc={FluencyAssessmentSelector.INTRO1}
					white={true}
					style={{top: `${headerHeight + 90}px`, left: '80px'}}
				/>
				<div className={`${classes.wrapper} animate__animated animate__zoomIn`}>
					<SubzoneSelector
						className="mr-3"
						title="Spelling"
						onClick={() => handleClick('spelling_zone')}
						zone="fluency"
						isComplete={fluencyassessmentstatus && fluencyassessmentstatus.availableZones.includes('spelling_zone') === false}
					/>

					<SubzoneSelector
						className="ml-3"
						title="Word"
						onClick={() => handleClick('word_zone')}
						zone="fluency"
						isComplete={fluencyassessmentstatus && fluencyassessmentstatus.availableZones.includes('word_zone') === false}
					/>
				</div>
			</ActivitySuspense>
		</>
	);
};

export default FluencyAssessmentSelectorActivity;
