export const isChromium = () => {
	return !!window.chrome;
};

export const isFirefox = () => {
	return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
};

export const isSafari = () => {
	return navigator.userAgent.indexOf("Safari") > -1;
}

export const isIE = () => {
	return (navigator.userAgent.indexOf("MSIE") > -1 ) || (!!document.documentMode === true );
}

