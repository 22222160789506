const updateItemValues = (item, xaxis, yaxis) => {
	item.top = `${yaxis}%`;
	item.left = `${xaxis}%`;
};

export const assignCoordinates = randomizedArray => {
	let xaxis = 16;
	let yaxis = 22;
	let xaxisIncrement = 48;
	let yaxisIncrement = 22;

	randomizedArray.forEach((item, index) => {
		// reset x-axis at index 2 to assign the appropriate co-ordinates
		index === 2 ? (xaxis = 40) : (xaxis = 16);
		// Not required default case for this switch method. so that disabling the lint rule for this method
		// eslint-disable-next-line default-case
		switch (index) {
			case 1:
			case 4: {
				xaxis += xaxisIncrement;
				break;
			}
			case 2:
			case 3: {
				yaxis += yaxisIncrement;
				break;
			}
		}
		updateItemValues(item, xaxis, yaxis);
	});
};
