import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import api from '../../api/api';
import { getContentInfo } from '../../media/mediaUtil';
import { getAudioExt } from '../../utils/audio';
import { MEDIA_SERVER_URL } from '../../utils/constants';
import { completeActivityAndGoToResultsPage, transitionToNextActivity } from '../../utils/navigation';
import { bulkReplace } from '../../utils/stringUtils';
import { loadSleep } from '../../utils/ui';

const EXT = getAudioExt();

export const SpellingAssessment = {
	TRIAL_SIZE: 10,
	FLUENT: 'Fluent',
	MISSED: 'Missed',
	MAX_TRIAL_COUNT: 40,
	MAX_TRIAL_ROUND_COUNT: 4,
	MAX_INCORRECT_TRIALS: 3,
	HOMOPHONE_ATTEMPT_START_COUNT: 1,
	HOMOPHONE_ATTEMPT_MAX_COUNT: 3,

	DATATOSERVER_STATE_ZONE: 'zonereport',
	DATATOSERVER_STATE_TRIALROUND: 'trailround',

	SOUND_INTRO: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_intro.${EXT}`,
	SOUND_PAUSE: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_pause.${EXT}`,
	SOUND_CONTINUE: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_continue.${EXT}`,
	SOUND_FEEDBACK: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_feedback.${EXT}`,
	SOUND_WRONG: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_wrong.${EXT}`,
	SOUND_WRONG_HOMOPHONE: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_wrong_homophone.${EXT}`,
	SOUND_HELP_TRIAL: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_trialhelp.${EXT}`,
	SOUND_HELP_FEEDBACK: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_feedbackhelp.${EXT}`,
	SOUND_HELP_RESULTS: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_resultshelp.${EXT}`,

	WORD_SOUND_URL: `${MEDIA_SERVER_URL}assets/dictio/word/r180u_word_{word}.${EXT}`,
	WORD_CTX_URL: `${MEDIA_SERVER_URL}assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,

	SFX_CORRECT: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_correct.${EXT}`,
	SFX_INCORRECT: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_incorrect.${EXT}`,

	init: async (activityData, contentData, wordfluency, isActivityCharged, settings) => {
		const start = new Date().getTime();

		//////////////
		// load the activity sounds
		//////////////
		AudioPlayer2.loadSound(SpellingAssessment.SOUND_INTRO);
		AudioPlayer2.loadSound(SpellingAssessment.SOUND_PAUSE);
		AudioPlayer2.loadSound(SpellingAssessment.SOUND_CONTINUE);
		AudioPlayer2.loadSound(SpellingAssessment.SOUND_FEEDBACK);
		AudioPlayer2.loadSound(SpellingAssessment.SOUND_WRONG);
		AudioPlayer2.loadSound(SpellingAssessment.SOUND_WRONG_HOMOPHONE);
		AudioPlayer2.loadSound(SpellingAssessment.SOUND_HELP_TRIAL);
		AudioPlayer2.loadSound(SpellingAssessment.SOUND_HELP_FEEDBACK);
		AudioPlayer2.loadSound(SpellingAssessment.SOUND_HELP_RESULTS);

		AudioPlayer2.loadSound(SpellingAssessment.SFX_CORRECT);
		AudioPlayer2.loadSound(SpellingAssessment.SFX_INCORRECT);

		// the contentData JSON supplies 40+ words to spell, but we only present TRIAL_SIZE at a time
		// the wordfluency contains the words already mastered and that should be skipped
		// then we take the first TRIAL_SIZE words after eliminating the ones already completed
		const previousIncorrectWordsCount = wordfluency.fluencyScores.filter(word => {
			return word.score !== SpellingAssessment.FLUENT;
		}).length;

		let spellingWords = contentData.assessment_words.filter(wordObj => {
			const match = wordfluency.fluencyScores.filter(completedWord => {
				return completedWord.wordId === wordObj.text;
			});
			return match.length === 0;
		});

		const pendingSpellingWordList = spellingWords;

		spellingWords = spellingWords.slice(0, Math.min(SpellingAssessment.TRIAL_SIZE, spellingWords.length));

		// load each of the words sounds and sentences
		spellingWords.forEach(wordObj => {
			const word = wordObj.text;
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(SpellingAssessment.WORD_SOUND_URL, constants);
			const word_ctx_url = bulkReplace(SpellingAssessment.WORD_CTX_URL, constants);

			// the pronounced word
			AudioPlayer2.load({ name: word, src: word_text_url });

			// the context
			AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });
		});

		const end = new Date().getTime();
		if (isActivityCharged === false) {
			await loadSleep(end - start);
		}

		return { spellingWords, previousIncorrectWordsCount, pendingSpellingWordList };
	},

	loadNextRoundWordSound: (spellingWords, activityData) => {
		spellingWords.forEach(wordObj => {
			const word = wordObj.text;
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(SpellingAssessment.WORD_SOUND_URL, constants);
			const word_ctx_url = bulkReplace(SpellingAssessment.WORD_CTX_URL, constants);

			// the pronounced word
			AudioPlayer2.load({ name: word, src: word_text_url });

			// the context
			AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });
		});
	},

	// mark the item as complete, but don't navigate away from page
	completeActivityNoTransition: (activityData, fluencyassessment, wordfluency) => {

		completeActivityAndGoToResultsPage({
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			},
			fluencyassessment,
			wordfluency
		});
	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendProgressToServer: (activityData, wordfluency, typedWordMap, totalWordsToAssess = 0) => {
		const fluencyUpdate = Object.keys(typedWordMap).map((key) => {
			return {
				wordId: key,
				score: typedWordMap[key] ? SpellingAssessment.FLUENT : SpellingAssessment.MISSED
			}
		});


		let newActivityData = {
			activity: {
				isComplete: false,
				studentActivityId: activityData.studentActivityId
			},
			fluencyassessment: {
				fluencyAssessmentZone: 'spelling_zone',
				totalWordsToAssess: totalWordsToAssess,
				studentSegmentId: wordfluency.studentSegmentId
			},
			wordfluency: {
				assessmentZone: 'spelling_zone',
				studentSegmentId: wordfluency.studentSegmentId,
				fluencyScores: fluencyUpdate
			}
		};
		api.activity.updateActivity(newActivityData);
	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendWordResultToServer: (activityData, isCorrect, word) => {
		let zoneReportData = {
			attempts: [{
				id: word,
				correct: isCorrect,
				timestamp: new Date()
			}]
		}
		api.activity.updateZoneReport(activityData.studentActivityId, zoneReportData);
	},

	buildWordfluencyList: (wordList, wordMap) => {
		const wordFluencyList = [];
		wordList.forEach(word => {
			const wordScore = wordMap[word.text] ? SpellingAssessment.FLUENT : SpellingAssessment.MISSED;
			wordFluencyList.push({
				wordId: word.text,
				score: wordScore
			});
		});
		return wordFluencyList;
	}
};
