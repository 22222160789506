import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import WritingSupportTray from '../WritingSupportTray';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		width: 'auto',
		height: 'auto',
		background: 'transparent',
		transition: '0.5s width ease',
		'&.narrow': {
			width: '512px'
		}
	},
	container: {
		width: 'auto',
		height: 'auto',
		margin: 'auto',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px 8px 0 0'
	},
	content: {
		padding: '24px',
		background: 'rgba(255, 255, 255, 0.6)',
		'&.showWhiteBg': {
			background: theme.colors.white
		},
		'& > div': {
			marginBottom: '24px'
		},
		'& .writing-panel': {
			position: 'relative',
			left: '-76px'
		}
	}
}));

const WritingPanelContainer = props => {
	const {
		id,
		className,
		children,
		onInstructionClick,
		onPassageClick,
		onScratchPadClick,
		onPowerWordsClick,
		onVideoClick,
		powerWords,
		showDraftPanel,
		narrow
	} = props;

	const classes = useStyles();

	return (
		<div
			id={id}
			className={`${classes.wrapper} ${narrow ? 'narrow' : ''} ${className} writing-panel-container`}
		>
			<div className={`${classes.container}`}>
				<WritingSupportTray
					onInstructionClick={onInstructionClick}
					onPassageClick={onPassageClick}
					onScratchPadClick={onScratchPadClick}
					onPowerWordsClick={onPowerWordsClick}
					onVideoClick={onVideoClick}
					powerWords={powerWords}
					narrow={narrow}
				/>

				<div
					className={`${classes.content} ${showDraftPanel ? 'showWhiteBg' : ''}`}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

WritingPanelContainer.defaultProps = {
	id: '',
	className: '',
	narrow: false
};

WritingPanelContainer.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	narrow: PropTypes.bool,
	onInstructionClick: PropTypes.func.isRequired,
	onPassageClick: PropTypes.func.isRequired,
	onScratchPadClick: PropTypes.func.isRequired,
	onPowerWordsClick: PropTypes.func.isRequired,
	onVideoClick: PropTypes.func.isRequired,
	powerWords: PropTypes.arrayOf(
		PropTypes.shape({
			word: PropTypes.string.isRequired,
			sound_name: PropTypes.string.isRequired,
			sound_src: PropTypes.string.isRequired
		})
	).isRequired
};

export default React.memo(WritingPanelContainer);
