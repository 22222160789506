import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import api from '../api/api';
import { appRouteList } from '../containers/App/ActivityRouter';
import reduxStore from '../store/redux-setup';
import {getActivityAction, updateActivityAction} from '../store/slices/activity';
import {seedInitialDataAction} from '../store/slices/activity';
import { uiSlice } from '../store/slices/ui';
import {CLIENT_BASE_URL} from './constants';
import { isDemoMode } from './ui';

/**
 * This function should be used when transitioning from one page to another
 * WITHOUT needing to update the activityData in the Redux store
 * but still needing to change the route.
 * In other words, only use this when you're moving to another page
 * and don't need to call the API to get the new activity
 * Examples of where this is used:
 * - Knowledge For Reading, when going from the Word Cards to the tasks - Image Choice, Imge Hot Spot
 * - Language Zone - going from syn-ant Intro to Body
 * @param history - from the useHistory() hook
 * @param route - the path of the task you'd like to transition to
 * @param taskData - the data you want to pass down to the task
 */
export const transitionToTask = (history, route, taskData = {}) => {
	let modifiedRoute = route;
	if (route.charAt(0) === '/') {
		modifiedRoute = modifiedRoute.substring(1);
	}

	AudioPlayer2.stopAll();
	reduxStore.dispatch(uiSlice.actions.setErrorMessage(''));
	history.push({
		pathname: `${CLIENT_BASE_URL}/${modifiedRoute}`,
		state: taskData
	});
};

/**
 * This function should be used when you have completed all the trials/tasks in an activity and are then
 * transitioning to a summary screen or a results screen.  In this situation, we need to tell the server
 * that we've finished the activity so if the user exits here, or presses refresh, they don't have to start
 * the activity at the beginning, they'll simply start at the next activity.  We can't transition to the next
 * activity yet because we sill have to show the results/summary screen.
 * -- R180 Results Screens are in:
 * RZ All Questions Types
 * FZ Spelling Assessment
 * FZ Spelling Challenge
 * FZ Self Check
 * FZ Speed Challenge
 * FZ Word Assessment
 * @param data - the data you need to send to the server letting it know you're done with the Activity
 */
export const completeActivityAndGoToResultsPage = async (data) => {
	try {
		api.activity.updateActivity(data);
	} catch (err) {
		throw err;
	}
};

/**
 * This function should be used when you are showing a results/summary screen and you've already called
 * completeActivityAndGoToResultsPage() and you need to transition to the next activity.  This should NEVER be
 * called without calling completeActivityAndGoToResultsPage() first, you'll get a server error if you do.
 * @param history - from the useHistory() hook
 */
export const transitionFromResultsPageToNextActivity = async (history) => {
	try {
		AudioPlayer2.stopAll();
		reduxStore.dispatch(uiSlice.actions.setTransitioning(true));
		await gotoNextActivity({history});
	} catch (err) {
		throw err;
	} finally {
		reduxStore.dispatch(uiSlice.actions.setTransitioning(false));
	}
}


/**
 * This function MUST be used when moving from one Activity to another
 * It will make the required calls to the API to tell it you're done with the current
 * activity and need to move to the next one.
 * You will need to send data to the server, and the data structure for each activity
 * can be found at https://scm.eng.hmhco.com/Supplemental-and-Intervention-Solutions/r180fs/tree/master/poc/R180API/postActivity
 * This function will handle the re-routing as well, so calling this function
 * from an Activity should be the last step in an Activity
 * @param history - from the useHistory() hook
 * @param data - the data you need to send to the server letting it know you're done with the Activity
 */
export const transitionToNextActivity = async (history, data) => {
	try {
		AudioPlayer2.stopAll();
		reduxStore.dispatch(uiSlice.actions.setTransitioning(true));
		const response = await reduxStore.dispatch(updateActivityAction({data}));
		if (response.payload) {
			await gotoNextActivity({history});
		}
	} catch (err) {
		throw err;
	} finally {
		reduxStore.dispatch(uiSlice.actions.setTransitioning(false));
	}
};

/**
 * This function can be used to jump to the Zone Menu from anywhere in the app
 * This function will handle the re-routing as well, so calling this function
 * from an Activity should be the last step in an Activity
 * @param history - from the useHistory() hook
 * @param studentActivityId - the student activity Id
 */
export const transitionToZoneMenu = async (history, studentActivityId) => {
	try {
		AudioPlayer2.stopAll();
		reduxStore.dispatch(uiSlice.actions.setTransitioning(true));
		await api.navigate.getOne(studentActivityId, 'zone_menu');
		await gotoNextActivity({history});
	} catch (err) {
		throw err;
	} finally {
		reduxStore.dispatch(uiSlice.actions.setTransitioning(false));
	}
};

/**
 * This function can be used when more fine-grained control is needed over sending data
 * to the server with the POST /activity before transitioning to the next Activity.
 * As an example, it's used in the Zone Menu and Segment Selector to jump to the right Activity
 * This function will handle the re-routing as well, so calling this function
 * from an Activity should be the last step in an Activity
 * @param history - from the useHistory() hook
 * @param zone - if the zone to jump to is known
 */
export const gotoNextActivity = async ({history, zone = null}) => {
	if (!zone) {
		//////////////
		// DEMO MODE
		//////////////
		if (process.env.REACT_APP_PRELOAD_MOCK_ACTIVITY_DATA === 'true' || isDemoMode()) {
			console.log('DEMO MODE NAVIGATION');
			reduxStore.dispatch(seedInitialDataAction());
		}
		const zoneMatches = history.location.pathname.match(`${CLIENT_BASE_URL}/zone/([a-z]+)/.*`);
		if (zoneMatches) {
			zone = zoneMatches[1];
		}
	}
	try {
		const response = await reduxStore.dispatch(getActivityAction());

		// figure out if we're in R180 or FS
		// R180 has "activity" object with the routing information
		if (response.payload.activity) {
			const {
				activity: {activityServerId}
			} = response.payload;

			const result = `${activityServerId}`;

			if (result) {
				if (result.includes('zone_menu') || result.includes('segment_selection')) {
					history.push(`${CLIENT_BASE_URL}/${result}`);
				} else if (result.includes('anchor_video')) {
					history.push(`${CLIENT_BASE_URL}/zone/explore/${result}`);
				} else {
					// convert the server provided route to our internal React route
					const route = appRouteList.filter(r => {
						return r.serverRoute === result;
					});

					if (route.length > 0) {
						history.push(`${CLIENT_BASE_URL}/zone/${zone}/${route[0].route}`);
					} else {
						history.push(`${CLIENT_BASE_URL}/zone/${zone}/${result}`);
					}
				}
			}
		}
		// FS has "state" object with the routing information
		else {
			const {series, topic, activity_id} = response.payload.state;
			// convert the server provided route to our internal React route
			const route = appRouteList.filter(r => {
				return r.serverRoute === activity_id;
			});
			history.push(`${CLIENT_BASE_URL}/zone/skills/${route[0].route}`);
		}

	} catch (err) {
		throw err;
	}
};

/**
 * This function can be used to jump to another activity that's not in the normal Activity flow
 * This function will handle the re-routing as well, so calling this function
 * from an Activity should be the last step in an Activity
 * In practice though, this likely would never be called directly, as most Activities need to use the normal flow
 * The current only use case is moving to the Zone Menu, which you should use the transitionToZoneMenu() instead
 * @param activityId - the student activity Id
 * @param navTo - the activity server Id
 * @param history - from the useHistory() hook
 */
export const transitionOutFromFlow = async ({activityId, navTo, history}) => {
	try {
		await api.navigate.getOne(activityId, navTo);
		await gotoNextActivity({history});
	} catch (e) {
		throw e;
	}
};
