import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	questionMark: {
		position: 'relative',
		display: 'inline-block',
		background: theme.colors.white,
		width: '56px',
		height: '56px',
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '8px',
		outline: 'none',
		textAlign: 'center',
		cursor: 'pointer',
		color: theme.colors.paleGrey
	},
	dashedBorder: {
		border: `1px dashed ${theme.colors.warningYellow}`,
		borderRadius: '6px',
		display: 'inline-block',
		margin: '3px'
	},
	highlightBorder: {
		border: `1px dashed ${theme.colors.readingZone.primary}`,
		'& i': {
			color: `${theme.colors.readingZone.primary}`
		}
	},
	iconWrapper: {
		position: 'relative',
		bottom: '9%'
	}
}));

const QuestionMark = (props) => {
	const {id, size, snapshot} = props;
	const classes = useStyles();

	const sizePx = size + 'px';
	const lineHeight = size * 1.05 + 'px';
	const fontSize = Math.floor(size / 2) + 'px';

	const subBorderHeight = size - 8 + 'px';
	const subBorderWeight = size - 10 + 'px';

	return (
		<div
			id={id}
			style={{
				width: sizePx,
				height: sizePx,
				lineHeight: lineHeight,
				fontSize: fontSize
			}}
			className={classes.questionMark}
		>
			<span
				className={clsx({
					[classes.dashedBorder]: true,
					[classes.highlightBorder]: snapshot.isDraggingOver
				})}
				style={{
					height: subBorderHeight,
					width: subBorderWeight
				}}
			>
				<span className={classes.iconWrapper}>
					<i className="r180 help" />
				</span>
			</span>
		</div>
	);
}

QuestionMark.defaultProps = {
	id: '',
	size: 56,
	snapshot: {}
};
QuestionMark.propTypes = {
	id: PropTypes.string,
	size: PropTypes.number
};

export default React.memo(QuestionMark);
