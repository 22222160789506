import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {Modal} from '@material-ui/core';
import {ActivityButton, SoundButton} from '../../../index';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {MEDIA} from '@reading/r180/src/activities/SegmentSelection/SegmentSelection';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';
import Close from '../../../components/Close';

export const useStyles = makeStyles(theme=>({
	modalBody:{
		width: '520px',
		background: theme.colors.white,
		color: theme.colors.black,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		padding: '48px 88px 32px 88px',
		'& .close-button': {
			position: 'absolute',
			top: '8px',
			right: '8px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	},
	title: {
		position: 'relative',
		fontFamily: theme.fonts.uiBold,
		fontSize: '32px',
		marginBottom: '24px',
		'& .sound-button': {
			position: 'absolute',
			left: '-40px',
			border: 'none',
			top: '8px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.3)'
			}
		}
	},
	prompt:{
		fontFamily: theme.fonts.uiSemibold,
		fontSIze: '24px',
		marginBottom:'40px'
	},
	buttons:{
		display:'flex',
		justifyContent:'space-between',
		flexDirection:'row',
	}
}));

const SegmentConfirmModal = (props) => {
	const { open, handleClose, handleSubmit, selectedSegment, stage } = props;
	const classes = useStyles();

	const mediaConstants = {
		stage: stage.toLowerCase(),
		segment: selectedSegment.segment_number.toString().padStart(2, '0')
	};

	const selectedSegmentSound = {
		name: selectedSegment.id,
		src: `${MEDIA_SERVER_URL}${bulkReplace(
			MEDIA.segmentTitle,
			mediaConstants
		)}`
	};

	return (
		<Modal open={open} onClose={handleClose} className='r180-modal'>
			<div
				className={`${classes.modalBody} modal-window`}
				aria-label={'Confirm segment selection'}
				role={'dialog'}
			>
				<Close
					handleOnClose={handleClose}
				/>
				<div
					className={classes.title}
					aria-label={'Selected segment title'}
					role={'heading'}
				>
					<SoundButton
						size={32}
						className={classes.titleSoundButton}
						alt={selectedSegment.name}
						sound={selectedSegmentSound}
						aria-label={`${selectedSegment.name} VO`}
					/>
					{selectedSegment.name}
				</div>
				<div className={classes.prompt}>
					Are you sure you want to select this segment?
				</div>
				<div className={classes.buttons}>
					<ActivityButton
						datatestid='cancelButton'
						text="Cancel"
						icon="x"
						isPrimary={false}
						onClick={handleClose}
					/>
					<ActivityButton
						datatestid='submitButton'
						text="Submit"
						icon="check"
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default React.memo(SegmentConfirmModal);
