import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TitleIcon from './TitleIcon';
import AnchorVideo from '../AnchorVideo';
import ActivityInstructionButton from '../ActivityInstructionButton';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'stretch',
		padding: '46px'
	},
	iconBanner: {
		width: '100%',
		height: '135px',
		padding: '0 30px',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'flext-start'
	},
	bottomRow: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'stretch',
		minHeight: '340px',
	},
	title: {
		width: '30%',
		fontWeight: '700',
		fontSize: '32px',
		paddingTop: '30px',
		lineHeight: '60px'
	},
	videoBlock: {
		width: '70%',
		background: theme.colors.white
	}
}));

const FoundationalIntro = props => {
	const { id, className, icons, title, videoUrl, onVideoComplete, isFirstVisit, instructionSoundSrc } = props;
	const classes = useStyles();

	// TODO - for testing only
	setTimeout(() => {
		onVideoComplete();
	}, 8000);


	// Listen for when the video ends and then call onVideoComplete
	const handleVideoComplete = () => {
		onVideoComplete();
	};

	return (
		<div className={`${classes.wrapper} ${className}`} id={id}>
			<ActivityInstructionButton audioSrc={instructionSoundSrc} />
			<div className={`${classes.iconBanner}`}>
				{
					icons.map((icon, idx) => {
						return <TitleIcon
							key={`title-${idx}`}
							icon={icon.icon}
							text={icon.text}
							number={idx + 1}
						/>
					})
				}
			</div>
			<div className={`${classes.bottomRow}`}>
				<div className={`${classes.title}`}>
					Welcome to
					<br />
					{title}
				</div>
				<div className={`${classes.videoBlock}`}>

					<AnchorVideo
						id={'fs-intro'}
						compact={true}
						autoPlay={true}
						time={0}
						video_end_time={0}
						vol={0.1}
						containerStyle={{
							width: '100%',
							height: '100%'
						}}
						poster={''}
						handleVideoEnded={handleVideoComplete}
						hideFwdButton={isFirstVisit}
						hideRewindButton={false}
						hideCaptionButton={false}
						hideReplayButton={false}
						hideCloseButton={true}
					>
						<source src={videoUrl} type={'video/mp4'} />

						{[
							{
								src: videoUrl,
								label: 'English subtitles',
								kind: 'subtitles',
								srcLang: 'en'
							}
						].map((track, index) => (
							<track
								key={index}
								src={track.src}
								label={track.label}
								kind={track.kind}
								srcLang={track.srcLang}
							/>
						))}
					</AnchorVideo>

				</div>
			</div>
		</div>
	);
};

FoundationalIntro.defaultProps = {
	id: '',
	className: '',
	icons: [],
	title: '',
	videoUrl: '',
	isFirstVisit: false,
	instructionSoundSrc: ''
};
FoundationalIntro.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	icons: PropTypes.array,
	title: PropTypes.string,
	videoUrl: PropTypes.string,
	onVideoComplete: PropTypes.func,
	isFirstVisit: PropTypes.bool,
	instructionSoundSrc: PropTypes.string
};

export default React.memo(FoundationalIntro);
