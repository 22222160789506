import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import ErrorMessage from './ErrorMessage';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import { isFirefox } from '../../utils/browsers';

const useStyles = makeStyles(theme => ({
	activityFrame: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		margin: 'auto',
		width: theme.activityFrameWidth,
		minWidth: theme.activityFrameMinWidth,
		maxWidth: theme.activityFrameMaxWidth,
		height: theme.activityFrameHeight,
		minHeight: theme.activityFrameMinHeight,
		maxHeight: theme.activityFrameMaxHeight,
		overflowX: 'hidden',
		overflowY: isFirefox() ? 'auto' : 'overlay',
        '&::-webkit-scrollbar': {
            width: '6px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            padding: '10px 0'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#CCC',
            borderRadius: '8px'
        },
		borderRadius: '8px',
		background: 'transparent',
		'&.white': {
			background: theme.colors.white
		}
	}
}));

const ActivityFrame = (props) => {
	const classes = useStyles();
	const {isWhiteBackground} = props;

	const {errorMessage} = useSelector(state => {
		return {
			errorMessage: state.ui ? state.ui.errorMessage : ''
		};
	});
	return (
		<div className={`${classes.activityFrame} ${isWhiteBackground ? 'white' : ''}`}>
			{isEmpty(errorMessage) === false && <ErrorMessage errorMessage={errorMessage} />}
			{props.children}
		</div>
	);
}

ActivityFrame.defaultProps = {
	isWhiteBackground: false
};
ActivityFrame.propTypes = {
	isWhiteBackground: PropTypes.bool
};

export default React.memo(ActivityFrame);
