import transport from '../../Transport';

/**
 * This was adopted almost verbatim, with one major deviation, from an older library:
 * https://scm.eng.hmhco.com/Supplemental-and-Intervention-Solutions/scent2-lib-getmediaserver
 *
 * It returns a single media server URL taken from the set of all available media servers. The set of available media
 * servers is determined by the endpoint that handles the apiURL param. The media server can either be a CDN or an MMA.
 *
 * The algorithm for determining which of those servers to use is pretty simple.
 * - If there are no MMAs, return the arbitrary first CDN that is not of type 'hmhcentral'.
 * - If there are MMAs, return the first of those MMAs that return a valid ping response. If none were successfully
 * pinged, return the CDN as per above.
 *
 * @param apiURL The endpoint that returns a list of available CDNs and MMAs
 * @returns {Promise<[]>} a Promise that resolves to the URL of the media server that should be used
 */
export const getMediaServerURL = apiURL => {
	const options = {
		url: apiURL,
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	};
	let validCDNs = [];
	return transport(options)
		.then(data => processAPIData(data))
		.then(res => {
			validCDNs = res.CDNs;
			return res.MMAs && res.MMAs.length > 0 ? pingAllMMAs(res.MMAs) : [];
		})
		.then(validMMAs =>
			validMMAs.length === 0 ? validCDNs[0].url : validMMAs[0].url
		);
};

/**
 * Major deviation from the library here.
 *
 * The gms library would filter out any CDN not of type HMHCENTRAL. Here, we do the inverse. The media for M180 resides
 * in the SAM CDN, not the HMHCENTRAL CDN, so we filter out HMHCENTRAL.
 */
export const processAPIData = data => {
	if (typeof data === 'string') {
		data = JSON.parse(data);
	}
	let CDNs = [];
	if (typeof data.cdnServers !== 'undefined') {
		CDNs = data.cdnServers.filter(server => {
			//Note: this is the major deviation from the library
			return (
				typeof server.serverType !== 'undefined' &&
				server.serverType.trim().toLowerCase() !== 'hmhcentral'
			);
		});
		CDNs = CDNs.map(cdn => {
			const url = new URL(cdn.url);
			cdn.url = url.origin;
			return cdn;
		});
	}

	const MMAs = data.mediaServers;
	return {CDNs, MMAs};
};

/**
 * Comments from: https://scm.eng.hmhco.com/Supplemental-and-Intervention-Solutions/scent2-lib-getmediaserver
 *
 * tries to download the DUMMY file from each MMA, saves the ones that are successful.
 */
export const pingMMA = mma => {
	const dummyFileUrl = getMMASentinelFileURL(mma.url);
	const pingTimeout = getDefaultMMAPingTimeout();

	const options = {
		url: dummyFileUrl,
		method: 'GET',
		headers: {
			Accept: 'text/plain'
		}
	};

	return Promise.race([
		transport(options),
		new Promise((_, reject) =>
			setTimeout(
				() =>
					reject(
						new Error(
							'MMA with check file URL ' +
								dummyFileUrl +
								' is not reachable.'
						)
					),
				pingTimeout
			)
		)
	])
		.then(() => mma)
		.catch(err => {
			console.warn(err.message);
		});
};

/**
 * Comments from: https://scm.eng.hmhco.com/Supplemental-and-Intervention-Solutions/scent2-lib-getmediaserver
 *
 * tries to download the DUMMY file from each MMA, saves the ones that are successful.
 */
export const pingAllMMAs = allMMAs => {
	const pingPromises = allMMAs.map(mma => pingMMA(mma));

	return Promise.all(pingPromises).then(validMMAs =>
		validMMAs.filter(mma => mma !== undefined)
	);
};

export const getMMASentinelFileURL = mmaUrl =>
	`${mmaUrl}${mmaUrl.endsWith('/') ? '' : '/'}crossdomain.gif`;

export const getDefaultMMAPingTimeout = () => 2000;
