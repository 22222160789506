import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {keyAndClick} from '../../util/ui-a11y';
import {useDeepCompareEffect} from 'react-use';

const useStyles = makeStyles(theme => ({
	soundButton: {
		position: 'relative',
		display: 'inline-block',
		background: theme.colors.white,
		width: '64px',
		height: '64px',
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '8px',
		outline: 'none',
		textAlign: 'center',
		cursor: 'pointer',
		color: theme.colors.slate,
		transition: 'transform 0.3s ease',
		'&.disabled, &:disabled': {
			background: theme.colors.paleGrey,
			color: theme.colors.grey,
			cursor: 'not-allowed'
		},
		'&.playing, &.dragging': {
			borderColor: theme.colors.readingZone.primary,
			color: theme.colors.readingZone.primary
		},
		'&.no-border': {
			border: 'none'
		},
		'&.transparentBg': {border: 'none', background: 'none'}
	}
}));

const SoundButton = (props) => {
	const {
		disabled,
		icon,
		onClick,
		id,
		sound,
		style,
		isDragging,
		className,
		border,
		onSoundCompleted,
		transparentBackGround
	} = props;
	let {size} = props;

	const classes = useStyles();

	const [playing, setPlaying] = useState(false);

	useDeepCompareEffect(() => {
		const audioPlayer = AudioPlayer2.load({
			name: sound.name,
			src: sound.src
		});

		audioPlayer.onStop = () => {
			setPlaying(false);
			if (typeof onSoundCompleted !== 'undefined') {
				onSoundCompleted();
			}
		};

		return () => {
			delete audioPlayer.onStop;
		};


	}, [sound]);

	const handleClick = async(e) => {
		if (typeof onClick === 'function') {
			onClick(e, !playing);
		}
		if (!sound.src.length) {
			return false;
		}
		const {disabled} = props;

		if (e) {
			e.stopPropagation();
		}

		if (disabled) {
			return;
		}

		if (playing === false) {
			AudioPlayer2.stopAll();
			setPlaying(true);
			await AudioPlayer2.playSync(sound.name);
			setPlaying(false);
			if (typeof onSoundCompleted !== 'undefined') {
				onSoundCompleted();
			}
		}
		else {
			AudioPlayer2.stopAll();
			setPlaying(false);
		}
	};

	if (isDragging) {
		size = size * 1.5;
	}

	const sizePx = size + 'px';
	const lineHeight = size * 1.05 + 'px';
	const fontSize = Math.floor(size / 2) + 'px';

	const disabledClass = disabled ? 'disabled' : '';
	const playingClass = playing ? 'playing' : '';
	const draggingClass = isDragging ? 'dragging' : '';
	const borderClass = border ? '' : 'no-border';
	const transparentBG = transparentBackGround ? 'transparentBg' : '';
	return (
		<div
			id={id ? id : `${sound.name} `}
			data-id="testDiv"
			className={`${classes.soundButton}
			            ${disabledClass}
						${playingClass}
						${draggingClass}
						${borderClass}
						${className}
						${transparentBG}
						sound-button`}
			style={{
				...style,
				width: sizePx,
				height: sizePx,
				lineHeight: lineHeight,
				fontSize: fontSize
			}}
			{...keyAndClick(handleClick)}
			tabIndex={0}
			role={'button'}
			aria-label={`sound: ${sound.name}`}
		>
			<i className={`r180 ${icon}`} />
		</div>
	);
}

SoundButton.defaultProps = {
	id: '',
	icon: 'speaker-center',
	disabled: false,
	size: 64,
	border: true,
	sound: {},
	style: {},
	onClick: () => {},
	transparentBackGround: false
};
SoundButton.propTypes = {
	id: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	disabled: PropTypes.bool,
	size: PropTypes.number,
	border: PropTypes.bool,
	sound: PropTypes.shape({
		name: PropTypes.string.isRequired,
		src: PropTypes.string.isRequired
	}),
	style: PropTypes.object,
	onClick: PropTypes.func,
	onSoundCompleted: PropTypes.func,
	transparentBackGround: PropTypes.bool
};

export default React.memo(SoundButton);
