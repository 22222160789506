import React, {useEffect, useState, useRef} from 'react';
import {useUnmount} from 'react-use';
import PropTypes from 'prop-types';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';
import ReadingQuestionPanel from '../ReadingQuestionPanel/ReadingQuestionPanel';
import {bulkReplace, stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import {createAssetId} from '@reading/r180/src/media/mediaUtil';
import {isEmpty, cloneDeep, shuffle} from 'lodash';

const EXT = getAudioExt();

const BUTTON_NAMES = {
	SUBMIT: 'Submit',
	GOON: 'Go On',
	TRYAGAIN: 'Try Again'
};
const BUTTON_ICONS = {
	CHECK: 'check',
	ARROW_RIGHT: 'arrow-right',
	RESET: 'reset'
};
const MAX_ATTEMPTS = 3;
export const MEDIA = {
	TITLE_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_matchconn_q{dataId}_question.${EXT}`,
	OPTION_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_matchconn_q{dataId}_{optionId}.${EXT}`,
	PROMPT_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_matchconn_q{dataId}_p{optionId}.${EXT}`
};

const ConnectMatchingQuestion = props => {
	const {data, stage, segment, level, onGoOnClicked, onShowFeedback} = props;
	const [matchingOptions, setMatchingOptions] = useState({
		draggablePrompts: [],
		fixedChoices: []
	});
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
	const [attempts, setAttempts] = useState(0);
	const [isCorrect, setCorrect] = useState(true);
	const [interactive, setInteractive] = useState(true);
	const [answers, setAnswers] = useState([]);
	const [buttonName, setButtonName] = useState(BUTTON_NAMES.SUBMIT);
	const [buttonIcon, setButtonIcon] = useState(BUTTON_ICONS.CHECK);
	let timeout = useRef(false);

	// create the options, map the object to our components
	useEffect(() => {
		if (isEmpty(data) === false) {
			const promptItems = shuffle(cloneDeep(data.prompts)).map(prompt => {
				return {
					id: prompt.id,
					text: prompt.text,
					audio: {
						name: `Matching_${prompt.id}`,
						src: bulkReplace(MEDIA.PROMPT_SOUND, {
							asset_id: createAssetId(
								stage,
								segment,
								level,
								true
							),
							dataId: data.id,
							optionId: prompt.id
						})
					},
					status: '',
					connected: false
				};
			});

			const choiceItems = shuffle(cloneDeep(data.choices)).map(choice => {
				return {
					id: choice.id,
					text: choice.text,
					audio: {
						name: `Matching_${choice.id}`,
						src: bulkReplace(MEDIA.OPTION_SOUND, {
							asset_id: createAssetId(
								stage,
								segment,
								level,
								true
							),
							dataId: data.id,
							optionId: choice.id
						})
					},
					status: ''
				};
			});
			setAnswers(data.answers);
			setMatchingOptions({
				draggablePrompts: promptItems,
				fixedChoices: choiceItems
			});
		} else {
			setMatchingOptions({
				draggablePrompts: [],
				fixedChoices: []
			});
		}
	}, [data]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		clearTimeout(timeout.current);
	});

	const handleAnswerChange = result => {
		const {source, destination} = result;
		const oldOptions = cloneDeep(matchingOptions.draggablePrompts);
		oldOptions[+destination.droppableId - 1].connected = {
			...oldOptions[source.index]
		}.connected;
		oldOptions[source.index].connected = true;

		oldOptions.splice(
			source.index,
			1,
			oldOptions.splice(
				+destination.droppableId - 1,
				1,
				oldOptions[source.index]
			)[0]
		);
		const newOptions = oldOptions.map(option => ({
			...option,
			status: ''
		}));
		const newFixedChoices = matchingOptions.fixedChoices.map(option => ({
			...option,
			status: ''
		}));

		setMatchingOptions({
			draggablePrompts: newOptions,
			fixedChoices: newFixedChoices
		});

		setIsSubmitEnabled(
			newOptions.filter(option => option.connected === false).length === 0
		);
	};

	const handleSelect = () => {
		if (!isSubmitEnabled) {
			return;
		}
		AudioPlayer2.stopAll();
		switch (buttonName) {
			case BUTTON_NAMES.SUBMIT: {
				const newFixedChoices = cloneDeep(matchingOptions.fixedChoices);
				const newOptions = matchingOptions.draggablePrompts.map(
					(prompt, index) => {
						const isCorrectAnswer =
							answers.filter(answer => {
								if (
									`${answer.prompt_id}` === `${prompt.id}` &&
									`${answer.choice_id}` ===
										`${newFixedChoices[index].id}`
								) {
									return answer.correct === true;
								}
								return false;
							}).length > 0;
						if (isCorrectAnswer) {
							newFixedChoices[index].status = 'correct';
							return {...prompt, status: 'correct'};
						} else {
							newFixedChoices[index].status = 'incorrect';
							return {...prompt, status: 'incorrect'};
						}
					}
				);

				const correctOptionsLength = newOptions.filter(
					option => option.status === 'correct'
				).length;

				// All answers are correct
				if (correctOptionsLength === newOptions.length) {
					AudioPlayer2.play(`compcorrattempt${attempts + 1}`);
					onShowFeedback(false, []);
					setIsSubmitEnabled(true);
					setButtonName(BUTTON_NAMES.GOON);
					setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
					setAttempts(attempts => attempts + 1);
					setCorrect(true);
					setInteractive(false);
					setMatchingOptions({
						draggablePrompts: newOptions,
						fixedChoices: newFixedChoices
					});
				} else {
					AudioPlayer2.play(`compincorrattempt${attempts + 1}`);
					if (attempts === MAX_ATTEMPTS - 1) {
						timeout.current = setTimeout(() => {
							onShowFeedback(false, []);
						}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 2000);
						setMatchingOptions({
							draggablePrompts: newOptions,
							fixedChoices: newFixedChoices
						});
						setInteractive(false);
						setIsSubmitEnabled(true);
						setButtonName(BUTTON_NAMES.GOON);
						setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
					} else {
						timeout.current = setTimeout(() => {
							onShowFeedback(true, data.corrective_feedback);
							setInteractive(true);
							setButtonName(BUTTON_NAMES.SUBMIT);
							setButtonIcon(BUTTON_ICONS.CHECK);
							setIsSubmitEnabled(true);
						}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 1000);
						setInteractive(false);
						setIsSubmitEnabled(false);
						setMatchingOptions({
							draggablePrompts: newOptions,
							fixedChoices: newFixedChoices
						});
					}
					setCorrect(false);
					setAttempts(attempts => attempts + 1);
				}
				break;
			}
			default: {
				onShowFeedback(false, []);
				onGoOnClicked(attempts, isCorrect);
				break;
			}
		}
	};

	return (
		<ReadingQuestionPanel
			title={stripHtmlTags(data.stem)}
			titleSound={{
				name: `Title_${data.id}`,
				src: bulkReplace(MEDIA.TITLE_SOUND, {
					asset_id: createAssetId(stage, segment, level, true),
					dataId: data.id
				})
			}}
			questionType={data.type}
			question={matchingOptions}
			onAnswerChange={handleAnswerChange}
			onSubmit={handleSelect}
			isSubmitEnabled={isSubmitEnabled}
			buttonText={buttonName}
			buttonIcon={buttonIcon}
			showResetButton={false}
			interactive={interactive}
		/>
	);
};

ConnectMatchingQuestion.propTypes = {
	data: PropTypes.object.isRequired,
	stage: PropTypes.string.isRequired,
	segment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onGoOnClicked: PropTypes.func.isRequired,
	onShowFeedback: PropTypes.func.isRequired
};

export default React.memo(ConnectMatchingQuestion);
