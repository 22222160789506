import React, {useEffect, useState, useRef} from 'react';
import {useUnmount} from 'react-use';
import PropTypes from 'prop-types';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';
import ReadingQuestionPanel from '../ReadingQuestionPanel/ReadingQuestionPanel';
import {bulkReplace, stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import {createAssetId} from '@reading/r180/src/media/mediaUtil';
import {isEmpty, shuffle, cloneDeep} from 'lodash';

const EXT = getAudioExt();

const BUTTON_NAMES = {
	SUBMIT: 'Submit',
	GOON: 'Go On',
	TRYAGAIN: 'Try Again'
};
const BUTTON_ICONS = {
	CHECK: 'check',
	ARROW_RIGHT: 'arrow-right',
	RESET: 'reset'
};
const MAX_ATTEMPTS = 3;
export const MEDIA = {
	TITLE_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_ordering_q{dataId}_question.${EXT}`,
	OPTION_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_ordering_q{dataId}_{optionId}.${EXT}`
};

const OrderingSequence = props => {
	const {data, stage, segment, level, onGoOnClicked, onShowFeedback} = props;
	const [answers, setAnswers] = useState([]);
	const [options, setOptions] = useState([]);
	const [attempts, setAttempts] = useState(0);
	const [isCorrect, setCorrect] = useState(true);
	const [interactive, setInteractive] = useState(true);
	const [buttonName, setButtonName] = useState(BUTTON_NAMES.SUBMIT);
	const [buttonIcon, setButtonIcon] = useState(BUTTON_ICONS.CHECK);
	let timeout = useRef(false);

	// create the options, map the object to our components, randomize the array
	useEffect(() => {
		if (isEmpty(data) === false) {
			const orderingOptions = shuffle(cloneDeep(data.answers)).map(
				(option, index) => {
					return {
						id: option.id,
						text: option.text,
						sound: {
							name: `OrderingAnswer_${index}`,
							src: bulkReplace(MEDIA.OPTION_SOUND, {
								asset_id: createAssetId(
									stage,
									segment,
									level,
									true
								),
								dataId: data.id,
								optionId: option.id
							})
						},
						status: '',
						studentSequence: index
					};
				}
			);
			setOptions(orderingOptions);

			setAnswers(cloneDeep(data.answers));
		} else {
			setOptions([]);
		}
	}, [data]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		clearTimeout(timeout.current);
	});

	const handleAnswerChange = selectedOptions => {
		if (buttonName === BUTTON_NAMES.TRYAGAIN) {
			const changedAnswerIdSequence = selectedOptions[0].map(
				option => option.id
			);
			const newOptions = options
				.map(option => ({
					...option,
					status: '',
					studentSequence: changedAnswerIdSequence.indexOf(
						`${option.id}`
					)
				}))
				.sort((a, b) => a.studentSequence - b.studentSequence);
			setInteractive(true);
			setButtonName(BUTTON_NAMES.SUBMIT);
			setButtonIcon(BUTTON_ICONS.CHECK);
			setOptions(newOptions);
		} else {
			const changedAnswerIdSequence = selectedOptions[0].map(
				option => option.id
			);
			const newOptions = options
				.map(option => ({
					...option,
					studentSequence: changedAnswerIdSequence.indexOf(
						`${option.id}`
					)
				}))
				.sort((a, b) => a.studentSequence - b.studentSequence);
			setOptions(newOptions);
		}
	};
	const handleSelect = () => {
		AudioPlayer2.stopAll();
		switch (buttonName) {
			case BUTTON_NAMES.SUBMIT: {
				const newOptions = options.map(option => {
					if (
						answers[answers.findIndex(opt => opt.id === option.id)]
							.sequence === option.studentSequence
					) {
						return {...option, status: 'correct'};
					} else {
						return {...option, status: 'incorrect'};
					}
				});
				const correctOptionsLength = newOptions.filter(
					option => option.status === 'correct'
				).length;

				// All sequences are correct on first attempt
				if (correctOptionsLength === options.length) {
					AudioPlayer2.play(`compcorrattempt${attempts + 1}`);
					onShowFeedback(false, []);
					setButtonName(BUTTON_NAMES.GOON);
					setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
					setAttempts(attempts => attempts + 1);
					setCorrect(true);
					setInteractive(false);
					setOptions(newOptions);
				} else {
					AudioPlayer2.play(`compincorrattempt${attempts + 1}`);
					if (attempts === MAX_ATTEMPTS - 1) {
						timeout.current = setTimeout(() => {
							onShowFeedback(false, []);
						}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 2000);

						const correctOptions = options
							.map(option => {
								return {
									...option,
									status: 'correct',
									studentSequence:
										answers[
											answers.findIndex(
												opt => opt.id === option.id
											)
										].sequence
								};
							})
							.sort(
								(a, b) => a.studentSequence - b.studentSequence
							);
						setOptions(correctOptions);
						setInteractive(false);
						setButtonName(BUTTON_NAMES.GOON);
						setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
					} else {
						if (attempts === 0) {
							timeout.current = setTimeout(() => {
								onShowFeedback(true, data.corrective_feedback);
							}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 2000);
						}
						setInteractive(true);
						setButtonName(BUTTON_NAMES.TRYAGAIN);
						setButtonIcon(BUTTON_ICONS.RESET);
						setOptions(newOptions);
					}
					setCorrect(false);
					setAttempts(attempts => attempts + 1);
				}
				break;
			}
			case BUTTON_NAMES.TRYAGAIN: {
				const newOptions = options.map(option => ({
					...option,
					status: ''
				}));
				setInteractive(true);
				setOptions(shuffle(newOptions));
				setButtonName(BUTTON_NAMES.SUBMIT);
				setButtonIcon(BUTTON_ICONS.CHECK);
				break;
			}
			default: {
				onShowFeedback(false, []);
				onGoOnClicked(attempts, isCorrect);
				break;
			}
		}
	};

	return (
		<ReadingQuestionPanel
			title={stripHtmlTags(data.stem)}
			titleSound={{
				name: `Title_${data.id}`,
				src: bulkReplace(MEDIA.TITLE_SOUND, {
					asset_id: createAssetId(stage, segment, level, true),
					dataId: data.id
				})
			}}
			questionType={data.type}
			questions={options}
			onAnswerChange={handleAnswerChange}
			onSubmit={handleSelect}
			buttonText={buttonName}
			buttonIcon={buttonIcon}
			showResetButton={false}
			interactive={interactive}
		/>
	);
};

OrderingSequence.propTypes = {
	data: PropTypes.object.isRequired,
	stage: PropTypes.string.isRequired,
	segment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onGoOnClicked: PropTypes.func.isRequired,
	onShowFeedback: PropTypes.func.isRequired
};

export default React.memo(OrderingSequence);
