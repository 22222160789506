import {Grid, Modal} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import ActivityButton from '../ActivityButton';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	content: {
		top: '50%',
		outline: 'none',
		left: '50%',
		transform: `translate(-50%, -50%)`,
		position: 'absolute',
		backgroundColor: theme.colors.white,
		padding: '48px 48px 32px',
		maxWidth: '540px',
		width: '70%',
		boxShadow:
			'0px 4px 4px rgba(0, 0, 0, 0.35), 0px 0px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px'
	},
	subHeaderText: {
		position: 'relative',
		height: 'auto',
		width: 'auto',
		display: 'block',
		fontSize: '24px',
		fontFamily: theme.fonts.uiSemibold,
		textAlign: 'center',
		padding: '0px 21px 0px 21px'
	},
	buttonWrapper: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '36px',
		justifyContent: 'space-evenly',
		width: '100%'
	},
	closeButton: {
		fontSize: '24px',
		position: 'absolute',
		right: '16px',
		top: '16px'
	},
	speakerIcon: {
		cursor: 'pointer',
		color: theme.colors.grey,
		fontSize: '20px'
	},
	speakerText: {
		padding: '10px'
	}
}));

const AppModal = (props) => {
	const {
		isOpen,
		onConfirm,
		onCancel,
		text,
		showSpeakerIcon,
		confirmText,
		cancelText,
		showCancel
	} = props;

	const classes = useStyles();
	const [playing, setPlaying] = useState(false);

	useEffect(() => {
		if (isEmpty(text) === false) {
			const audioPlayer = AudioPlayer2.load({
				name: 'TODO',
				src: 'TODO'
			});

            audioPlayer.onStop = () => {
                setPlaying(false);
            };

			return () => {
                delete audioPlayer.onStop;
            };
		}
	}, [text]);

	const handleClick = async(e) => {
		e.stopPropagation();
		if (playing === false) {
			AudioPlayer2.stopAll();
			setPlaying(true);
			await AudioPlayer2.playSync(text);
			setPlaying(false);
		}
		else {
			AudioPlayer2.stopAll();
			setPlaying(false);
		}
	};

	return (
		<>
			<Modal
				className='r180-modal'
				disableBackdropClick //prevent closing of modal on clicking outside
				disableEscapeKeyDown //prevent closing of modal on pressing ESC key
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={isOpen}
				style={{zIndex: '99999'}}
			>
				<Fade in={isOpen} timeout={500}>
					<div className={`${classes.content} modal-window`}>
						<button
							className={classes.closeButton}
							onClick={onCancel}
							data-testid="closeButton"
							aria-label="Close"
						>
							<i className="r180 close-alt" />
						</button>

						<div className={classes.subHeaderText}>
							{showSpeakerIcon && (
								<i
									onClick={e => {
										handleClick(e);
									}}
									className={clsx(
										'r180',
										'speaker-center',
										classes.speakerIcon
									)}
								/>
							)}
							<span className={classes.speakerText}>{text}</span>
						</div>

						<Grid
							container
							justify="center"
							alignItems="center"
							direction="row"
							display="flex"
						>
							<Grid item className={classes.buttonWrapper}>
								{showCancel && (
									<ActivityButton
										data-testid="secondaryButton"
										id="secondaryButton"
										text={cancelText}
										icon="x"
										onClick={onCancel}
										isPrimary={false}
									/>
								)}
								<ActivityButton
									data-testid="primaryButton"
									id="primaryButton"
									text={confirmText}
									icon="check"
									onClick={onConfirm}
								/>
							</Grid>
						</Grid>
					</div>
				</Fade>
			</Modal>
		</>
	);
}

AppModal.defaultProps = {
	isOpen: true,
	text: '',
	confirmText: 'Yes',
	cancelText: 'No',
	showCancel: true,
	showSpeakerIcon: false
};

AppModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	confirmText: PropTypes.string,
	cancelText: PropTypes.string,
	showCancel: PropTypes.bool,
	showSpeakerIcon: PropTypes.bool
};

export default React.memo(AppModal);
