import React from 'react';
import { makeStyles } from '@material-ui/core';
import { displaySafe } from '@reading/r180/src/utils/stringUtils';

export const useStyle = makeStyles(theme => ({
	wrapper: {
		width: '80%',
		height: '100%',
		minHeight: '220px',
		overflow: 'hidden',
		margin: 'auto',
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '8px',
		background: theme.colors.white,
		'& > .header': {
			height: '60px',
			background: theme.colors.lightGrey,
			padding: '18px 32px',
			borderBottom: `1px solid ${theme.colors.paleGrey}`,
			color: theme.colors.softBlack,
			letterSpacing: '0.02em',
			fontSize: '18px',
			borderTopRightRadius: '8px',
			borderTopLeftRadius: '8px',
			fontFamily: theme.fonts.buttonBold,
			'& i': {
				position: 'relative',
				top: '1px'
			}
		},
		'& .content > div': {
			display: 'block',
			float: 'left',
			width: '50%',
			height: '100%',
			minHeight: '160px',
			textAlign: 'center',
			color: theme.colors.grey,
			fontFamily: theme.fonts.context,
			'& h4': {
				textTransform: 'uppercase',
				fontFamily: theme.fonts.button,
				marginTop: '12px',
				marginBottom: '12px',
				letterSpacing: '0.04em',
				fontSize: '18px',
				color: theme.colors.slate,
			},
			'& .word-entry': {
				marginBottom: '12px'
			}
		},
		'& .left': {
			borderRight: `1px solid ${theme.colors.paleGrey}`,
		},
		'& .right': {
		}
	}
}));

const WordCardSynonym = (props) => {
	const { wordCard } = props;
	const classes = useStyle();

	const synAntActivity = wordCard.word_card_activities.filter((act) => {
		return act.word_card_activity.activity_server_id === 'synonyms_and_antonyms';
	})[0];

	const synonyms = synAntActivity.word_card_activity.synonyms;
	const antonyms = synAntActivity.word_card_activity.antonyms;

	return (
		<div className={`${classes.wrapper} animate__animated animate__bounceIn`}>
			<div className='header'>
				<i className='r180 synonym-antonyms mr-1' />
				Synonyms &amp; Antonyms
			</div>
			<div className='content'>
				<div className='left'>
					<h4>Synonyms</h4>
					{
						synonyms.map((s) => {
							return <div key={s.id} className='word-entry'>{displaySafe(s.text)}</div>
						})
					}
				</div>
				<div className='right'>
					<h4>Antonyms</h4>
					{
						antonyms.map((s) => {
							return <div key={s.id} className='word-entry'>{displaySafe(s.text)}</div>
						})
					}
				</div>
			</div>
		</div>
	)
}


WordCardSynonym.defaultProps = {
};

WordCardSynonym.propTypes = {
};

export default React.memo(WordCardSynonym);
