import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { getAudioExt } from '../../utils/audio';
import { MEDIA_SERVER_URL } from '../../utils/constants';
import { completeActivityAndGoToResultsPage } from '../../utils/navigation';
import { bulkReplace } from '../../utils/stringUtils';
import { loadSleep } from '../../utils/ui';
import api from '../../api/api';
import { isEmpty } from 'lodash';
import { transitionToNextActivity } from '../../utils/navigation';

const EXT = getAudioExt();

export const WordMatch = {
    MAX_ATTEMPS: 2,

    GO_ON_FEEDBACK: `${MEDIA_SERVER_URL}assets/activi/wordmatch/r180u_wordmatch_feedback_first_try.${EXT}`, // nearly identical to CORRECT_FEEDBACK
    CORRECT_FEEDBACK: `${MEDIA_SERVER_URL}assets/activi/wordmatch/r180u_wordmatch_feedback_many_tries.${EXT}`,
    FEEDBACK_HELP: `${MEDIA_SERVER_URL}assets/activi/wordmatch/r180u_wordmatch_feedbackhelp.${EXT}`,
    INSTRUCTIONS: `${MEDIA_SERVER_URL}assets/activi/wordmatch/r180u_wordmatch_instructions.${EXT}`, // same sound as SOUND_INTRO
    INCORRECT_FEEDBACK: `${MEDIA_SERVER_URL}assets/activi/wordmatch/r180u_wordmatch_inter_round_sequence_good_try.${EXT}`,
    INTERROUND_HELP: `${MEDIA_SERVER_URL}assets/activi/wordmatch/r180u_wordmatch_interroundhelp.${EXT}`,
    SOUND_INTRO: `${MEDIA_SERVER_URL}assets/activi/wordmatch/r180u_wordmatch_intro.${EXT}`,
    SOUND_HELP: `${MEDIA_SERVER_URL}assets/activi/wordmatch/r180u_wordmatch_premixhelp.${EXT}`, // nearly the same as TRIAL_HELP, but TRIAL_HELP is more descriptive
    TRIAL_HELP: `${MEDIA_SERVER_URL}assets/activi/wordmatch/r180u_wordmatch_trialhelp.${EXT}`,

    WORD_UTL: `${MEDIA_SERVER_URL}assets/dictio/word/r180u_word_{word}.${EXT}`,

    initData: async (studylist, isActivityCharged) => {

        const start = new Date().getTime();

        AudioPlayer2.loadSound(WordMatch.GO_ON_FEEDBACK);
        AudioPlayer2.loadSound(WordMatch.CORRECT_FEEDBACK);
        AudioPlayer2.loadSound(WordMatch.FEEDBACK_HELP);
        AudioPlayer2.loadSound(WordMatch.INSTRUCTIONS);
        AudioPlayer2.loadSound(WordMatch.INCORRECT_FEEDBACK);
        AudioPlayer2.loadSound(WordMatch.INTERROUND_HELP);
        AudioPlayer2.loadSound(WordMatch.SOUND_INTRO);
        AudioPlayer2.loadSound(WordMatch.SOUND_HELP);
        AudioPlayer2.loadSound(WordMatch.TRIAL_HELP);

        let words = [];
        studylist.fluencyScores.forEach(word => {
            let obj = {
                name: word.wordId,
                src: bulkReplace(WordMatch.WORD_UTL, { word: word.wordId })
            };
            words.push(obj);
        });

        const end = new Date().getTime();
        if (isActivityCharged === false) {
            await loadSleep(end - start);
        }

        return words;
    },

    // move to the next activity
    completeActivity: async (history, activityData) => {
        await transitionToNextActivity(history, {
            activity: {
                isComplete: true,
                studentActivityId: activityData.studentActivityId
            }
        });
    },

    // no need to make this async or await it since these server calls
    // can happen in the background without blocking the UI
    sendProgressToServer: (activityData) => {
        if (isEmpty(activityData)) {
            return;
        }

        let savedActivityData = {
            activity: {
                studentActivityId: activityData.studentActivityId,
                isComplete: false
            }
        };

        api.activity.updateActivity(savedActivityData);
    }
};
