import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import DragAndDrop from '../ClickAndStick/DragAndDrop';
import {isFirefox} from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: '-42px -40px -40px -40px',
		display: 'grid',
		gridTemplateRows: `1fr`,
		gridTemplateColumns: `1fr`,
		gridGap: '4px',
		justifyItems: 'stretch',
		alignItems: 'center',
		'& .spacer': {
			background: theme.colors.white
		}
	},
	dragAndDropContainer: {
		position: 'relative',
		display: 'flex',
		height: '450px'
	},
	optionsContainer: {
		background: theme.colors.white,
		height: 'auto',
		width: '210px',
		padding: '14px',
		minHeight: '88px'
	},
	optionsText: {
		position: 'relative',
		display: 'block',
		textAlign: 'left',
		marginLeft: '5px',
		marginTop: '-10px',
		width: '140px',
		height: 'auto',
		left: '5px',
		lineHeight: '24px',
		fontSize: '16px'
	},
	headerWrapper: {
		background: theme.colors.softBlack,
		borderRadius: '4px 4px 0 0',
		width: '232px',
		fontSize: '16px',
		fontFamily: theme.fonts.uiBold,
		color: theme.colors.white,
		textAlign: 'center',
		clear: 'both'
	}
}));

const SortingQuestion = props => {
	const {id, className, onAnswerChange, question, interactive} = props;
	const classes = useStyles();
	const headerList = question.headerList.map(item => ({
		...item,
		header: item.text && (
			<div className={classes.headerWrapper}>{item.text}</div>
		)
	}));
	const items = question.bucketItemsList.map(option => {
		if (option.length > 0) {
			return option.map(item => {
				return {
					...item,
					id: `${item.id}`,
					content: (
						<div className={classes.optionsContainer}>
							<span
								className={classes.optionsText}
								dangerouslySetInnerHTML={{__html: item.text}}
							></span>
						</div>
					)
				};
			});
		} else {
			return [{id: 'empty'}];
		}
	});
	return (
		<div className={`${classes.wrapper} ${className}`} id={id}>
			<div className="spacer"></div>
			<div className={classes.dragAndDropContainer}>
				<DragAndDrop
					onDragDrop={state => {
						onAnswerChange(state);
					}}
					interactive={interactive}
					showSoundButton={true}
					isSortingQuestion={true}
					bucketInfoList={headerList}
					bucketItemList={items}
					bucketStyleList={[
						{
							width: 232,
							height: 240,
							margin: '0 2px 15px',
							borderRadius: '0 0 4px 4px',
							overflowY: isFirefox() ? 'auto' : 'overlay',
							overflowX: 'hidden',
							scrollbarWidth: 'thin',

							'&::-webkit-scrollbar': {
								width: '6px'
							},
							'&::-webkit-scrollbar-track': {
								background: 'transparent',
								padding: '10px 0'
							},
							'&::-webkit-scrollbar-thumb': {
								background: '#CCC',
								borderRadius: '8px'
							},
							boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.3)'
						},
						{
							width: 232,
							height: 240,
							margin: '0 2px 15px',
							borderRadius: '0 0 4px 4px',
							overflowY: isFirefox() ? 'auto' : 'overlay',
							overflowX: 'hidden',
							scrollbarWidth: 'thin',

							'&::-webkit-scrollbar': {
								width: '6px'
							},
							'&::-webkit-scrollbar-track': {
								background: 'transparent',
								padding: '10px 0'
							},
							'&::-webkit-scrollbar-thumb': {
								background: '#CCC',
								borderRadius: '8px'
							},
							boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.3)'
						},
						{
							width: '470px',
							height: '150px',
							overflowY: isFirefox() ? 'auto' : 'overlay',
							scrollbarWidth: 'thin',

							'&::-webkit-scrollbar': {
								width: '6px'
							},
							'&::-webkit-scrollbar-track': {
								background: 'transparent',
								padding: '10px 0'
							},
							'&::-webkit-scrollbar-thumb': {
								background: '#CCC',
								borderRadius: '8px'
							},
							margin: '10px'
						}
					]}
					droppableStyleList={[
						{
							position: 'absolute',
							top: '0%',
							left: '4px'
						},
						{
							position: 'absolute',
							top: '0%',
							left: '240px'
						},
						{
							position: 'absolute',
							top: '275px',
							left: '0px'
						}
					]}
					disableHelperBar={false}
					showInfo={false}
				/>
			</div>
		</div>
	);
};

SortingQuestion.defaultProps = {
	id: '',
	className: '',
	interactive: true
};
SortingQuestion.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	question: PropTypes.object.isRequired,
	onAnswerChange: PropTypes.func.isRequired,
	interactive: PropTypes.bool
};

export default SortingQuestion;
