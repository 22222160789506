import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(_theme => ({
	progressCircle: {
		transitionProperty: 'stroke-dashoffset',
		transitionTimingFunction: 'linear',
		transitionDuration: '0s'
	}
}));

const CircularTimer = props => {
	const {size, strokeWidth, circleStroke, countDownTimer, percent} = props;

	const classes = useStyles();
	const circleRef = useRef();
	const [styles, setStyles] = useState({});

	const center = size / 2;
	const radius = size / 2 - strokeWidth;
	const circumference = 2 * Math.PI * radius;

	useEffect(() => {
		if (circleRef.current) {
			setTimeout(() => setProgress(percent), 600);
		}
	}, [percent, countDownTimer]);
	// --------------------------------------------------------

	// Set circular Progress percentage
	const setProgress = percent => {
		const offset = circumference - (percent / 100) * circumference;
		setStyles({
			...styles,
			strokeDasharray: `${circumference} ${circumference}`,
			strokeDashoffset: offset,
			transitionDuration: `${countDownTimer}s`
		});
	};
	// --------------------------------------------------------

	return (
		<>
			<svg className="progress-ring" width={size} height={size}>
				<circle
					ref={circleRef}
					style={styles}
					className={classes.progressCircle}
					transform={`rotate(-90 ${center} ${center})`}
					stroke={circleStroke}
					strokeWidth={strokeWidth}
					fill="transparent"
					cx={center}
					cy={center}
					r={radius}
				/>
			</svg>
		</>
	);
};

CircularTimer.defaultProps = {
	size: 50,
	strokeWidth: 16.5,
	circleStroke: '#3e98c7',
	countDownTimer: 10,
	percent: 0
};

export default React.memo(CircularTimer);
