import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import reduxStore from '@reading/r180/src/store/redux-setup.js';
import { useUnmount } from 'react-use';
import { uiSlice } from '@reading/r180/src/store/slices/ui';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		borderBottom: `2px solid ${theme.colors.lightGrey}`
	},
	controlBar: {
		position: 'relative',
		height: '72px',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'stretch',
		background: theme.colors.white,
		'& .panel': {
			textAlign: 'center',
			position: 'relative',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: '16px',
			fontWeight: '400',
			'&.left-panel': {
				width: '30%'
			},
			'&.center-panel': {
				width: '40%'
			},
			'&.right-panel': {
				width: '30%'
			}
		},
		'& .instructions': {
			position: 'absolute',
			top: '1px',
			left: '1px',
			color: theme.colors.softBlack,
			fontSize: '24px',
			marginTop: '3px',
			marginLeft: '2px',
			height: '28px',
			width: '24px'
		},
		'& .start-over, & .save, & .done': {
			color: theme.colors.black,
			'& i': {
				marginRight: '9px',
				color: theme.colors.readingZone.primary
			},
			'&:disabled': {
				color: theme.colors.slate,
				cursor: 'not-allowed',
				'& i': {
					color: theme.colors.slate
				}
			}
		},
		'& .start-over': {
			marginLeft: '20px'
		},
		'& .play-button-wrapper': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		'& .record-button-wrapper': {
			marginLeft: '15px'
		},
		'& .record, & .play-button': {
			height: '36px',
			width: '36px',
			borderRadius: '18px',
			border: `2px solid ${theme.colors.readingZone.blue5}`,
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			color: theme.colors.readingZone.background,
			background: theme.colors.white,
			marginRight: '12px',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		'& .countdown': {
			display: 'inline-block',
			height: '36px',
			width: '36px',
			borderRadius: '18px',
			background: theme.colors.readingZone.blue5,
			border: `1px solid ${theme.colors.readingZone.blue3}`,
			color: theme.colors.readingZone.background,
			marginRight: '12px',
			fontSize: '24px',
			fontWeight: '700',
			'& + span': {
				position: 'relative',
				top: '-2px'
			}
		},
		'& .button-wrapper': {
			display: 'flex',
			height: '46px',
			width: '46px',
			borderRadius: '23px',
			justifyContent: 'center',
			alignItems: 'center',
			background: theme.colors.readingZone.blue5,
			marginRight: '12px',
			float: 'left',
			'& .action-button': {
				height: '36px',
				width: '36px',
				borderRadius: '18px',
				background: theme.colors.white,
				border: `3px solid ${theme.colors.readingZone.blue3}`,
				boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
				color: theme.colors.readingZone.background,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}
		},
		'& .pauseLabel': {
			height: '46px',
			lineHeight: '46px'
		}
	},
	playingBar: {
		height: '44px',
		width: '100%',
		background: theme.colors.white,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		'& .ten': {
			display: 'inline-block',
			fontSize: '10px',
			color: theme.colors.grey,
			fontWeight: '600'
		},
		'& button': {
			marginTop: '3px'
		},
		'& i': {
			color: theme.colors.readingZone.primary,
			fontSize: '16px'
		},
		'& i.skip-to-start': {
			marginRight: '24px'
		},
		'& i.rewind': {
			marginRight: '2px'
		},
		'& i.f-forward': {
			marginLeft: '4px',
			marginRight: '24px'
		},
		'& .progress-bar': {
			cursor: 'pointer',
			position: 'relative',
			height: '10px',
			width: '280px',
			background: theme.colors.readingZone.blue5,
			borderRadius: '8px',
			marginLeft: '8px',
			marginRight: '8px',
			overflow: 'hidden',
			'& .bar': {
				height: '10px',
				width: '280px',
				position: 'absolute',
				top: '0',
				left: '-120px',
				background: theme.colors.readingZone.blue3,
				zIndex: '10'
			}
		}
	},
	buttonLabel: { cursor: 'pointer' }
}));

const RecordControlBar = props => {
	const {
		id,
		className,
		onInstructions,
		onStartOver,
		isStartOverDisabled,
		onSave,
		isSaveDisabled,
		onRecord,
		onPause,
		onDone,
		canPlayRecording,
		onPlay,
		onRecordingSkipBack,
		onRecordingRewind,
		onRecordingFastForward,
		onRecordingSkipForward,
		playbackProgress,
		onSeekPercent,
		startRecordCountDown,
		onRecordButtonClick,
		pausePlayback,
		isPlayBackClicked
	} = props;

	const [isRecording, setRecording] = useState(false);
	const [countdown, setCountdown] = useState(0);
	const [isPlaying, setPlaying] = useState(false);
	const { dispatch, subscribe, getState } = reduxStore;
	const classes = useStyles({ isPlaying });
	const unsubscribe = subscribe(() => {
		const {
			ui: { cancelRecording }
		} = getState();
		if (cancelRecording) {
			handleDone({ preventDefault: () => { } });
			dispatch(uiSlice.actions.clearCancelRecordingFlag());
		}
	});
	useUnmount(() => {
		unsubscribe();
	});

	const startCountDown = () => {
		setCountdown(3);
		setTimeout(() => {
			setCountdown(2);
		}, 1000);
		setTimeout(() => {
			setCountdown(1);
		}, 2000);
		setTimeout(() => {
			setCountdown(0);
			setRecording(true);
			onRecord();
		}, 3000);
	};

	const startRecording = () => {
		setRecording(true);
		onRecord();
	};

	useEffect(() => {
		if (startRecordCountDown) {
			startCountDown();
		}
	}, [startRecordCountDown]);

	const handleRecord = e => {
		e.preventDefault();
		onRecordButtonClick();
	};

	const handlePause = e => {
		e.preventDefault();
		setRecording(false);
		setPlaying(false);
		onPause();
	};

	const handleDone = e => {
		e.preventDefault();
		setRecording(false);
		onDone();
	};

	const handlePlay = e => {
		e.preventDefault();
		setPlaying(true);
		onPlay();
	};
	const handlePlaybackPause = e => {
		e.preventDefault();
		setPlaying(false);
		pausePlayback();
	};

	return (
		<div className={`${classes.wrapper}`}>
			<div className={`${classes.controlBar} ${className}`} id={id}>
				<div className={`panel left-panel`}>
					<button
						type="button"
						className="instructions"
						data-testid={'recordInstructions'}
						onClick={onInstructions}
						aria-label={'Instructions'}
					>
						<i className="r180 instructions" />
					</button>
					<button
						type="button"
						className="start-over"
						onClick={onStartOver}
						disabled={isStartOverDisabled}
						aria-label={'Start over'}
					>
						<i className="r180 reset" />
						Start Over
					</button>
				</div>
				<div className={`panel center-panel`}>
					{canPlayRecording ? (
						!isPlayBackClicked ? (
							<div className="play-button-wrapper">
								<button
									type="button"
									className="play-button"
									onClick={handlePlay}
									aria-label={'Play'}
								>
									<i className="r180 play" />
								</button>
								<span
									className={classes.buttonLabel}
									onClick={handlePlay}
								>
									Play
								</span>
							</div>
						) : (
							<div className="play-button-wrapper">
								<button
									type="button"
									className="play-button"
									onClick={handlePlaybackPause}
									aria-label={'Pause'}
								>
									<i className="r180 pause" />
								</button>
								<span
									className={classes.buttonLabel}
									onClick={handlePlaybackPause}
								>
									Pause
								</span>
							</div>
						)
					) : isRecording === false ? (
						<div className="record-button-wrapper">
							{countdown === 0 ? (
								<button
									type="button"
									className="record"
									onClick={handleRecord}
									data-testid={'recordButton'}
									aria-label={'Record'}
								>
									<i className="r180 mic" />
								</button>
							) : (
								<div className="countdown">{countdown}</div>
							)}
							<span
								className={classes.buttonLabel}
								onClick={handleRecord}
							>
								Record
							</span>
						</div>
					) : (
						<div>
							<div className="button-wrapper">
								<button
									type="button"
									className="action-button"
									onClick={handlePause}
									aria-label={'Pause'}
								>
									<i className="r180 pause" />
								</button>
							</div>
							<span
								className={`pauseLabel ${classes.buttonLabel}`}
								onClick={handlePause}
								data-testid={'stoppause'}
							>
								Pause
							</span>
						</div>
					)}
				</div>
				<div className={`panel right-panel`}>
					{!canPlayRecording ? (
						<button
							type="button"
							className="done"
							onClick={handleDone}
							aria-label={'Done'}
							disabled={isSaveDisabled}
						>
							<i className="r180 check" />
							Done
						</button>
					) : (
						<button
							type="button"
							className="save"
							onClick={onSave}
							disabled={isSaveDisabled}
							aria-label={'Save'}
						>
							<i className="r180 save" />
							Save
						</button>
					)}
				</div>
			</div>
			{canPlayRecording && (
				<div className={`${classes.playingBar}`}>
					<button
						type="button"
						data-testid="seekstart"
						onClick={onRecordingSkipBack}
						aria-label={'Seek to start'}
					>
						<i className="r180 skip-to-start" />
					</button>
					<button
						type="button"
						data-testid="seekback"
						onClick={onRecordingRewind}
						aria-label={'Seek back 10s'}
					>
						<i className="r180 rewind" />
					</button>
					<span className="ten">10</span>
					<div
						className="progress-bar"
						onClick={e => {
							e.preventDefault();
							onSeekPercent(
								e.nativeEvent.offsetX /
								e.currentTarget.clientWidth
							);
						}}
					>
						<div
							className="bar"
							style={{ left: `-${100 - playbackProgress}%` }}
						></div>
					</div>
					<span className="ten">10</span>
					<button
						type="button"
						data-testid="seekforward"
						onClick={onRecordingFastForward}
						aria-label={'Seek ahead 10s'}
					>
						<i className="r180 f-forward" />
					</button>
					<button
						type="button"
						data-testid="seekend"
						onClick={onRecordingSkipForward}
						aria-label={'Seek to end'}
					>
						<i className="r180 skip-to-end" />
					</button>
				</div>
			)}
		</div>
	);
};

RecordControlBar.defaultProps = {
	id: '',
	className: '',
	isStartOverDisabled: false,
	isSaveDisabled: false,
	canPlayRecording: false,
	playbackProgress: 0,
	startRecordCountDown: false,
	onRecordButtonClick: () => { },
	isReadRecord: false
};
RecordControlBar.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	onInstructions: PropTypes.func.isRequired,
	onStartOver: PropTypes.func.isRequired,
	isStartOverDisabled: PropTypes.bool,
	onSave: PropTypes.func.isRequired,
	isSaveDisabled: PropTypes.bool,
	onRecord: PropTypes.func.isRequired,
	onPause: PropTypes.func.isRequired,
	onDone: PropTypes.func.isRequired,
	canPlayRecording: PropTypes.bool,
	onPlay: PropTypes.func.isRequired,
	onRecordingSkipBack: PropTypes.func.isRequired,
	onRecordingRewind: PropTypes.func.isRequired,
	onRecordingFastForward: PropTypes.func.isRequired,
	onRecordingSkipForward: PropTypes.func.isRequired,
	playbackProgress: PropTypes.number,
	startRecordCountDown: PropTypes.bool,
	onRecordButtonClick: PropTypes.func,
	isReadRecord: PropTypes.bool
};

export default React.memo(RecordControlBar);
