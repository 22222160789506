import React, {useState} from 'react';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import MiniWordCard from '@reading/common/src/components/WordCard/MiniWordCard';
import WordCard from '@reading/common/src/components/WordCard/WordCard';
import {ActivityInstructionButton, CaptionedAudioPlayer2 as AudioPlayer2, SubzoneSelector} from '@reading/common';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import {isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {KnowledgeForReading} from './KnowledgeForReading';
import {uiSlice} from '../../store/slices/ui';
import {ActivitySupportButton, HoverTip} from '@reading/common';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import useIdleHelp from '../../utils/useIdleHelp';
import useRouteInfo from '../../utils/useRouteInfo';
import {transitionToTask} from '../../utils/navigation';
import {getContentInfo} from '../../media/mediaUtil';
import media from '../../api/media';
import {activitySlice} from '../../store/slices/activity';
import {kfrSlice} from '../../store/slices/kfr';
import {getRandomInt} from '../../utils/random';
import {ExploreZone} from './ExploreZone';
import useBatchedSetState from '../../utils/useBatchedSetState';
import {isDemoMode} from '../../utils/ui';
import {sleep} from '../../utils/sleep';

const useStyle = makeStyles(theme => ({
	listWrapper: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap'
	},
	buttonWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		flexWrap: 'wrap',
		paddingLeft: `100px`,
		color: 'white'
	},
	buttonStyle: {
		alignSelf: 'center'
	}
}));

export const handleTaskSelection = async (
	selectedActivity,
	selectedTask,
	history,
	powerWordMap,
	contentData,
	activityData,
	dispatch
) => {
	const taskName = selectedActivity.word_card_activity.activity_server_id;

	let updatedselectedActivityTask = {};
	let synonym_antonym_word_list_obj = {};

	switch (taskName) {
		case 'synonyms_and_antonyms': {
			let selectedActivityTask = selectedTask.word_card_activities.filter(word_card_activity => {
				return word_card_activity.word_card_activity.activity_server_id === taskName;
			})[0]['word_card_activity'];

			const {synonymsAndAntonymsWordList, buckets} = await KnowledgeForReading.loadSynonymData(
				selectedActivityTask
			);

			synonym_antonym_word_list_obj.power_word_focus = selectedTask.word_text;
			synonym_antonym_word_list_obj['SynonymsAndAntonymsWordList'] = synonymsAndAntonymsWordList;
			synonym_antonym_word_list_obj['buckets'] = buckets;
			updatedselectedActivityTask = {...selectedActivityTask, ...synonym_antonym_word_list_obj};

			dispatch(activitySlice.actions.setTaskData(updatedselectedActivityTask));

			if (isDemoMode()) {
				history.push(`synonym-antonym-intro`);
			} else {
				try {
					await KnowledgeForReading.completeActivity(
						history,
						activityData,
						'synonyms_and_antonyms',
						contentData,
						selectedTask
					);
				} catch (err) {
					dispatch(uiSlice.actions.setErrorMessage(err));
				}
			}

			break;
		}
		case 'word_family': {
			selectedTask['content_data'] = {...contentData};
			selectedTask['power_word_focus'] = selectedTask.word_text;

			dispatch(activitySlice.actions.setTaskData(selectedTask));

			if (isDemoMode()) {
				history.push(`word-family`);
			} else {
				try {
					await KnowledgeForReading.completeActivity(
						history,
						activityData,
						'word_family',
						contentData,
						selectedTask
					);
				} catch (err) {
					dispatch(uiSlice.actions.setErrorMessage(err));
				}
			}

			break;
		}

		case 'words_in_context': {
			let selectedActivityTask = selectedTask.word_card_activities.filter(word_card_activity => {
				return word_card_activity.word_card_activity.activity_server_id === taskName;
			})[0]['word_card_activity'];

			if (!selectedTask.word_card_activityData) {
				selectedTask['word_card_activityData'] = {
					words_in_context: selectedActivityTask
				};
				selectedTask['word_cards'] = contentData.word_cards;
				selectedTask['power_word_focus'] = selectedTask.word_text;
			}

			dispatch(activitySlice.actions.setTaskData(selectedTask));

			if (isDemoMode()) {
				history.push(`words-in-context`);
			} else {
				try {
					await KnowledgeForReading.completeActivity(
						history,
						activityData,
						'words_in_context',
						contentData,
						selectedTask
					);
				} catch (err) {
					dispatch(uiSlice.actions.setErrorMessage(err));
				}
			}

			break;
		}

		case 'example_non_example': {
			let selectedActivityTask = selectedTask.word_card_activities.filter(word_card_activity => {
				return word_card_activity.word_card_activity.activity_server_id === taskName;
			})[0]['word_card_activity'];

			const {synonymsAndAntonymsWordList, buckets} = KnowledgeForReading.loadExampleData(
				selectedActivityTask,
				activityData
			);
			synonym_antonym_word_list_obj.power_word_focus = selectedTask.word_text;
			synonym_antonym_word_list_obj['SynonymsAndAntonymsWordList'] = synonymsAndAntonymsWordList;
			synonym_antonym_word_list_obj['buckets'] = buckets;
			updatedselectedActivityTask = {...selectedActivityTask, ...synonym_antonym_word_list_obj};

			dispatch(activitySlice.actions.setTaskData(updatedselectedActivityTask));

			if (isDemoMode()) {
				history.push(`example-nonexample-intro`);
			} else {
				try {
					await KnowledgeForReading.completeActivity(
						history,
						activityData,
						'example_non_example',
						contentData,
						selectedTask
					);
				} catch (err) {
					dispatch(uiSlice.actions.setErrorMessage(err));
				}
			}

			break;
		}
		default: {
			return;
		}
	}
};

export const getSelectedTask = (task, wordCard) => {
	let task_data = {};
	let activity_data = {};

	// challenges
	if (task.word_cards) {
		task_data = task.word_cards.filter(q => {
			return q.word_card.word_text === wordCard.word_card.word_text;
		})[0]['word_card'];
	}

	if (task.questions) {
		task_data = task.questions.filter(q => {
			return q.power_word_focus === wordCard.word_card.word_text;
		})[0];
	}

	if (wordCard.word_card.word_card_activities) {
		activity_data = wordCard.word_card.word_card_activities;
	}

	return {task_data, activities: activity_data};
};

const WordCardActivity = props => {
	const {activityData, powerWordData, settings, wordcards, ui} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			powerWordData: state.activity.powerwords,
			wordcards: state.activity.wordcards,
			settings: state.session.session.settings,
			ui: state.ui
		};
	});

	const {zone} = useRouteInfo();
	const {setState} = useBatchedSetState();
	const dispatch = useDispatch();
	const classes = useStyle();
	const history = useHistory();

	const [contentData, setContentData] = useState({});

	useDeepCompareEffect(() => {
		const loadMedia = async () => {
			const {urlPath} = getContentInfo(activityData, true);

			if (urlPath !== '/') {
				let mediaData = await media.getOne(urlPath);
				if (zone === 'explore') {
					mediaData.word_cards = mediaData.word_cards.filter(wc => {
						return wc.word_card.mandatory;
					});
				}
				setContentData(mediaData);
			}
		};

		if (isEmpty(activityData) === false) {
			loadMedia();
		}
	}, [activityData]);

	// Pre-load the word card if it was currently selected
	const preloadedWordCard = useSelector(state => {
		return state.kfr.currentWordCard;
	});

	// check if the activity is already charged
	const isActivityCharged = useSelector(state => {
		return state.ui.chargedActivities[zone] === true;
	});

	let prevActivity = '';
	if (history.location.state) {
		prevActivity = history.location.state.prevActivity;
	}

	const isActivity = activityData
		? activityData.activityServerId === 'knowledge_for_reading' ||
		  activityData.activityServerId === 'word_card_selector'
		: false;

	const [selectedWordCard, setSelectedWordCard] = useState(null);
	const [allWordCards, setAllWordCards] = useState([]);
	const [wordCardDataMap, setWordCardDataMap] = useState({});
	const [selectedTask, setSelectedTask] = useState({});
	const [allTasks, setAllTasks] = useState(null);
	const [powerWordMap, setPowerWordMap] = useState({});
	const [isAllMiniWordCardCompleted, setIsAllMiniWordCardCompleted] = useState(false);
	const [isGoOnButtonVisible, setIsGoOnButtonVisible] = useState(false);
	const [selectedActivities, setSelectedActivities] = useState({});
	const [random] = useState(getRandomInt(1, 3));

	/* istanbul ignore next */
	useDeepCompareEffect(() => {
		const initialize = async () => {
			const {taskData, newWordCardMap, powerWordMapData, wordCards} = await KnowledgeForReading.init(
				activityData,
				contentData.word_cards,
				powerWordData ? powerWordData.powerwords : [],
				wordcards ? wordcards.wordCards : [],
				prevActivity,
				settings,
				zone,
				isActivityCharged
			);

			// set the data to cause a re-render
			setState(() => {
				setAllWordCards(wordCards);
				setWordCardDataMap(newWordCardMap);
				setAllTasks(taskData);
				setPowerWordMap(powerWordMapData);
			});

			// if they're coming back from an activity, load
			// in the word card for them
			if (isEmpty(preloadedWordCard) === false) {
				const {task_data, activities} = getSelectedTask(taskData, preloadedWordCard);
				setState(() => {
					setSelectedTask(task_data);
					setSelectedWordCard(preloadedWordCard);
					setSelectedActivities(activities);
				});
			}

			const isCompleted = updateGoOnButtonStatus(powerWordMapData, preloadedWordCard);

			dispatch(uiSlice.actions.setActivityCharged(zone));

			if (settings.autoPlayEnabled === true && ui.autoPlayedSounds.includes('word-card-' + zone) === false) {
				await sleep(1000);
				dispatch(uiSlice.actions.addAutoPlayedSound('word-card-' + zone));
				if (zone === 'language') {
					AudioPlayer2.play(KnowledgeForReading.SOUND_LANG_INTRO1);
				}
				else {
					if (isCompleted === false) {
						AudioPlayer2.play(KnowledgeForReading.SOUND_INTRO);
					}
				}
			}
		};

		if (activityData && contentData && contentData.word_cards && isActivity) {
			initialize();
		}

		/* eslint-disable */
	}, [activityData, contentData]);

	// stop any sounds
	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	/* istanbul ignore next */
	const updateGoOnButtonStatus = (powerWordMapData, preloadedWordCard) => {
		if (!isEmpty(powerWordMapData)) {
			if (!isEmpty(preloadedWordCard)) {
				if (powerWordMapData[preloadedWordCard.word_card.word_text].isCompleted) {
					setIsGoOnButtonVisible(false);
				} else {
					setIsGoOnButtonVisible(true);
				}
			}

			const {completedQuestions} = KnowledgeForReading.getWordCardStatusInfo(powerWordMapData);
			if (completedQuestions === KnowledgeForReading.TOTAL_COMPLETED_ACTIVITY_COUNT) {
				setState(() => {
					setIsAllMiniWordCardCompleted(true);
					setIsGoOnButtonVisible(true);
				});
				return true;
			}
		}
		return false;
	};

	/* istanbul ignore next */
	const onSelectWordCard = selectedWord => {
		AudioPlayer2.stopAll();

		// save the selectedWord into redux state so we can
		// pre-load it when the Activities go back
		dispatch(kfrSlice.actions.storeCurrentWordCard(selectedWord));

		const {task_data, activities} = getSelectedTask(allTasks, selectedWord);

		setState(() => {
			setIsGoOnButtonVisible(true);
			setSelectedTask(task_data);
			setSelectedActivities(activities);
			setSelectedWordCard(selectedWord);
		});
	};

	/* istanbul ignore next */
	const handleForward = async e => {
		AudioPlayer2.stopAll();
		let route = '';

		// All the Tasks in KFR are complete, transition to next activity (Zone Menu)
		if (isAllMiniWordCardCompleted) {
			if (isEmpty(selectedWordCard)) {
				try {
					const powerWords = powerWordData.powerwords.map(p => {
						return {
							wordId: p.wordId,
							correct: p.correct,
							total: p.total
						};
					});

					await KnowledgeForReading.completeKFRActivity(history, activityData, powerWords);
				} catch (err) {
					dispatch(uiSlice.actions.setErrorMessage(err));
				}
			} else {
				handleWordSelectorClick();
			}
		}
		// go to one of the KFR's Tasks
		else {
			route = KnowledgeForReading.TASKS_TO_ROUTE[selectedTask.type];

			transitionToTask(history, `zone/explore/${route}`, {
				taskActivity: selectedTask,
				powerWordMapData: powerWordMap,
				activityWord: selectedWordCard
			});
		}
	};

	/* istanbul ignore next */
	const handleWordSelectorClick = e => {
		AudioPlayer2.stopAll();
		dispatch(kfrSlice.actions.storeCurrentWordCard(null));
		setState(() => {
			setSelectedWordCard(null);
			setIsGoOnButtonVisible(isAllMiniWordCardCompleted);
		});
	};

	const handleSubzoneClick = selectedActivity => {
		handleTaskSelection(selectedActivity, selectedTask, history, powerWordMap, contentData, activityData, dispatch);
	};

	const getHelpSound = () => {
		if (zone === 'language') {
			if (isEmpty(selectedWordCard)) {
				return KnowledgeForReading.SOUND_LANG_HELP;
			} else {
				return KnowledgeForReading.SOUND_LANG_HELP_WORD_CARD;
			}
		}
		else {
			if (isAllMiniWordCardCompleted) {
				return KnowledgeForReading.SOUND_ALL_COMPLETE;
			}
			else if (isEmpty(selectedWordCard)) {
				return KnowledgeForReading.SOUND_HELP_WORD_CARD;
			} else {
				return KnowledgeForReading.SOUND_HELP_WORD_CARD_DETAIL;
			}
		}
	};

	const getIntroSound = () => {
		if (zone === 'language') {
			if (isEmpty(selectedWordCard)) {
				let finishedCards = 0;
				let totalCards = allWordCards.length;
				allWordCards.forEach(w => {
					if (powerWordMap[w.word_card.word_text]) {
						if (powerWordMap[w.word_card.word_text].isCompleted) {
							finishedCards++;
						}
					}
				});
				if (finishedCards === totalCards) {
					return KnowledgeForReading.SOUND_LANG_INTRO_DONE;
				} else if (finishedCards === totalCards - 1) {
					if (random === 1) {
						return KnowledgeForReading.SOUND_LANG_INTRO_ONE_MORE1;
					} else if (random === 2) {
						return KnowledgeForReading.SOUND_LANG_INTRO_ONE_MORE2;
					} else {
						return KnowledgeForReading.SOUND_LANG_INTRO_ONE_MORE3;
					}
				} else {
					if (random === 1) {
						return KnowledgeForReading.SOUND_LANG_INTRO1;
					} else if (random === 2) {
						return KnowledgeForReading.SOUND_LANG_INTRO2;
					} else {
						return KnowledgeForReading.SOUND_LANG_INTRO3;
					}
				}
			} else {
				const numCompleted = powerWordMap[selectedWordCard.word_card.word_text].completedActivity;
				if (numCompleted === 1) {
					if (random === 1) {
						return KnowledgeForReading.SOUND_LANG_NONE_COMPLETE1;
					} else {
						return KnowledgeForReading.SOUND_LANG_NONE_COMPLETE2;
					}
				} else if (numCompleted === 2) {
					if (random === 1) {
						return KnowledgeForReading.SOUND_LANG_ONE_COMPLETE1;
					} else {
						return KnowledgeForReading.SOUND_LANG_ONE_COMPLETE2;
					}
				} else {
					return KnowledgeForReading.SOUND_LANG_INTRO_DONE;
				}
			}
		} else {
			if (isEmpty(selectedWordCard)) {
				return KnowledgeForReading.SOUND_INTRO;
			} else {
				return KnowledgeForReading.SOUND_HELP_WORD_CARD_DETAIL;
			}
		}
	};

	useIdleHelp(getHelpSound());

	let wcProgress = 0;
	if (isEmpty(selectedWordCard) === false) {
		wcProgress = powerWordMap[selectedWordCard.word_card.word_text]
			? powerWordMap[selectedWordCard.word_card.word_text].completedActivity
			: KnowledgeForReading.INCOMPLETE_ACTIVITY_COUNT;
		if (selectedWordCard.word_card.mandatory === false) {
			wcProgress = wcProgress - 1;
		}
	}

	return (
		<>
			<Navbar helpSoundUrl={getHelpSound()} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={[allWordCards]}
				title={zone === 'language' ? 'Word Cards' : 'Knowledge For Reading'}
				style={{position: 'relative'}}
			>
				<ActivityInstructionButton audioSrc={getIntroSound()} withoutFrame={true} />

				<div className={`${classes.listWrapper} m-auto`}>
					{isEmpty(selectedWordCard) ? (
						<div style={{width: '970px', marginTop: '30px', textAlign: 'center'}}>
							{allWordCards.map((w, idx) => {
								/* istanbul ignore next */

								const maxProgress = powerWordMap[w.word_card.word_text]
									? powerWordMap[w.word_card.word_text].totalActivity
									: KnowledgeForReading.TOTAL_ACTIVITY_COUNT;

								let progress = powerWordMap[w.word_card.word_text]
									? powerWordMap[w.word_card.word_text].completedActivity
									: KnowledgeForReading.INCOMPLETE_ACTIVITY_COUNT;

								if (w.word_card.mandatory === false) {
									progress = progress - 1;
								}

								let isComplete = false;

								if (zone === 'explore') {
									isComplete = powerWordMap[w.word_card.word_text]
										? powerWordMap[w.word_card.word_text].completedActivity === 1
										: false;
								} else {
									isComplete = powerWordMap[w.word_card.word_text]
										? powerWordMap[w.word_card.word_text].isCompleted
										: false;
								}

								return (
									<div style={{display: 'inline-block'}} key={w.word_card.id}>
										<MiniWordCard
											id={'' + w.word_card.id}
											className={idx % 3 === 2 ? 'no-right-margin' : ''}
											progress={progress}
											maxProgress={maxProgress}
											label={w.word_card.word_text}
											onEnterClick={() => onSelectWordCard(w)}
											isCompleted={isComplete}
										/>
									</div>
								);
							})}
						</div>
					) : (
						<>
							<WordCard
								key={selectedWordCard.word_card.word_text}
								className=""
								activityData={activityData}
								card={selectedWordCard}
								kill={!selectedWordCard}
								wordCardData={wordCardDataMap[selectedWordCard.word_card.word_text]}
								history={history}
								taskData={selectedTask}
								powerWordData={powerWordMap[selectedWordCard.word_card.word_text]}
								progress={wcProgress}
								maxProgress={
									powerWordMap[selectedWordCard.word_card.word_text]
										? powerWordMap[selectedWordCard.word_card.word_text].totalActivity
										: KnowledgeForReading.TOTAL_ACTIVITY_COUNT
								}
							/>

							{zone === 'language' && isEmpty(selectedActivities) === false && (
								<div className={`${classes.buttonWrapper}`}>
									{selectedActivities
										.filter((val, i) => i < 2)
										.map((activity, idx) => {
											let isComplete = false;

											if (zone === 'explore') {
												isComplete =
													powerWordMap[selectedWordCard.word_card.word_text]
														.completedActivity > 0;
											} else {
												isComplete = powerWordMap[selectedWordCard.word_card.word_text]
													.completedActivityList
													? powerWordMap[
															selectedWordCard.word_card.word_text
													  ].completedActivityList.includes(
															activity.word_card_activity.activity_server_id
													  )
													: false;
											}

											return (
												<SubzoneSelector
													key={idx}
													className="mb-4"
													title={
														KnowledgeForReading.TASKS_ALIAS_NAME[
															activity.word_card_activity.activity_server_id
														]
													}
													text={''}
													onClick={() => handleSubzoneClick(activity)}
													zone="language"
													isComplete={isComplete}
												/>
											);
										})}
								</div>
							)}
						</>
					)}
				</div>

				<Footer>
					{isEmpty(selectedWordCard) === false && (
						/* istanbul ignore next */
						<HoverTip title="Click to go back" placement="top">
							<span>
								<ActivitySupportButton
									icon="view-list"
									onClick={handleWordSelectorClick}
									text="All Word Cards"
								/>
							</span>
						</HoverTip>
					)}
					{ExploreZone.isGoOnVisible(zone, selectedWordCard, powerWordMap, isAllMiniWordCardCompleted) ? (
						<FooterForwardBack
							onForward={handleForward}
							forwardText={'Go On'}
							isForwardVisible={isGoOnButtonVisible}
							isBackVisible={false}
							status={FooterForwardBack.VALID}
						/>
					) : null}
				</Footer>
			</ActivitySuspense>
		</>
	);
};

WordCardActivity.propTypes = {};

export default WordCardActivity;
