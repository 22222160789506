import axios from 'axios';

export default baseEndpoint => ({
	getOne: (stage = 'A') => axios.get(`${baseEndpoint}/session/start/stage/${stage}`).then(response => response.data),

	patch: ({variable, value}) => {
		return axios
			.put(`${baseEndpoint}/studentsetting/name/${variable}`, value.toString(), {
				headers: {'Content-Type': 'text/plain'}
			})
			.then(response => response.data);
	},

	logout: () => {
		return axios.get(`${baseEndpoint}/logout`).then(response => response.data);
	}
});
