import {cloneDeep, isEmpty, shuffle} from 'lodash';
import React, {useCallback, useMemo} from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import ActivityFrame from '../../../containers/App/ActivityFrame';
import ActivitySuspense from '../../../containers/App/ActivitySuspense';
import Footer from '../../../containers/App/Footer';
import FooterForwardBack from '../../../containers/App/FooterForwardBack';
import FSProgressBar from '../../../containers/App/FSProgressBar';
import Navbar from '../../../containers/App/Navbar';
import {WordTile, ActivitySupportButton, FoundationalIntro, ActivityInstructionButton} from '@reading/common';
import {makeStyles} from '@material-ui/core';
import {useRef} from 'react';
import {FastTrackUtil} from './FastTrackUtil';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {sleep} from '../../../utils/sleep';
import {FastTrackConstants} from './FastTrackConstants';
import FoundationalIntroModal from '@reading/common/src/components/FoundationalIntro/FoundationIntroModal';
import {FoundationalUtil} from '../FoundationalUtil';
import useBatchedSetState from '../../../utils/useBatchedSetState';
import {uiSlice} from '../../../store/slices/ui';

const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		alignItems: 'center'
	}
}));

export default function FastTrackActivity(props) {
	const activityName = 'fast_track';

	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const {setState} = useBatchedSetState();

	const questionTimer = useRef(Date.now());
	const questionTimeLimit = useRef(null);
	const interactive = useRef(true);

	const {activityData, contentData, saveTemplate, settings, isActivityCharged} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			contentData: state.activity.content,
			saveTemplate: state.activity.save_template,
			settings: state.activity.settings,
			isActivityCharged: state.ui.chargedActivities[activityName] === true
		};
	});

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	// settings common to all FS activities
	const {
		activityTimeLimit,
		isFirstVisit,
		isAccuracyOnly,
		isAutoplay,
		numVisits,
		isActivity
	} = FoundationalUtil.getActivitySettings(activityData, contentData, settings, activityName);

	// state variables/functions common to all FS activities
	const [currentScreen, setCurrentScreen] = useState(isFirstVisit ? 1 : 2);
	const [forwardStatus, setForwardStatus] = useState(FooterForwardBack.START);
	const [isForwardDisabled, setForwardDisabled] = useState(true);
	const [isPaused, setPaused] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [helpCount, setHelpCount] = useState(1);
	const [currentVO, setCurrentVO] = useState('real');
	const [isActvityButtonsDisabled, setActivityButtonsDisabled] = useState(false);

	// state variables/functions unique to this activity
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState([]);
	const [answers, setAnswers] = useState([]);

	// all FS activities use this code to kick things off
	useDeepCompareEffect(() => {
		const init = async () => {
			if (isActivityCharged === false) {
				await FastTrackUtil.initialize({contentData});
				dispatch(uiSlice.actions.setActivityCharged(activityName));
			}

			if (currentScreen === 2) {
				initActivity();
			}
		};

		if (isEmpty(contentData) === false && isActivity) {
			init();
		}
	}, [contentData, isActivity]);

	// all FS activities use this code to initialize the activity itself
	const initActivity = () => {
		AudioPlayer2.stopAll();
		const introType = contentData[`${contentData.activity_words[0].type}_section_intro_vo`];
		setState(() => {
			setCurrentVO(introType);
			setForwardDisabled(false);
			setForwardStatus(FooterForwardBack.START);
		});
		if (isAutoplay) {
			AudioPlayer2.play(FastTrackUtil.getRandomizedSound(isFirstVisit, introType));
		}
	};

	// all FS activities will use this code to start the actual activity (not the start screen)
	const startActivity = () => {
		AudioPlayer2.stopAll();
		questionTimer.current = Date.now();
		setState(() => {
			createNextQuestion(0);
			setForwardDisabled(true);
			setForwardStatus(FooterForwardBack.VALID);
		});
	};

	// all FS activities will have a pause button
	const handlePause = () => {
		if (isAutoplay) {
			AudioPlayer2.play(FastTrackConstants.COVER_UP);
		}
		setPaused(true);
		stopQuestionTimer();
	};

	// all FS activities will have a Continue button (opposite "Pause")
	const handleContinue = async () => {
		if (isAutoplay) {
			await AudioPlayer2.playSync(FastTrackConstants.COVER_OFF);
		}

		setState(() => {
			setCurrentQuestion(shuffleQuestions(currentQuestion));
			setPaused(false);
			startQuestionTimer();
		});

		AudioPlayer2.play(currentQuestion[0].correctWord);
	};

	// all FS activities will handle the instructions button in the bottom left corner
	const handleInstructions = () => {
		setModalOpen(true);
		if (isEmpty(currentQuestion) === false) {
			handlePause();
		}
	};

	// all FS activities will handle the instruction icon in the upper left corner
	const handleInstructionClick = () => {
		AudioPlayer2.stopAll();
		if (isEmpty(currentQuestion) === false) {
			handlePause();
		}
	};

	// all FS activities will handle the help button (which may go away in the future)
	const handleHelpClick = () => {
		AudioPlayer2.stopAll();
		if (isEmpty(currentQuestion) === false) {
			AudioPlayer2.play(FastTrackUtil.getHelpSound(helpCount));
			setHelpCount(helpCount + 1);
			handlePause();
		} else {
			AudioPlayer2.play(FastTrackConstants.INTRO_HELP);
		}
	};

	// all FS activities will handle the Go On button
	const handleForward = () => {
		// the foundational intro screen is always 1
		if (currentScreen === 1) {
			setCurrentScreen(2);
			initActivity();
		}
		// if the button says "Start", handle it here
		else if (forwardStatus === FooterForwardBack.START) {
			startActivity();
		}
		// any other clicks should be handled here in this block
		else {
			const serverData = createServerData(answers, true);
			try {
				FoundationalUtil.completeActivity(history, serverData);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		}
	};

	// all FS activities will call the server with data
	const createServerData = (answers, isComplete) => {
		let realTime = 0;
		let nonsenseTime = 0;
		let totalTime = 0;
		answers.forEach(a => {
			totalTime += a.response_time;
			if (a.type === 'real') {
				realTime += a.response_time;
			} else {
				nonsenseTime += a.response_time;
			}
		});

		const updatedSaveTemplate = cloneDeep(saveTemplate);
		updatedSaveTemplate.has_partial = isComplete === false;
		updatedSaveTemplate.tracked_time = totalTime;
		updatedSaveTemplate.nonsense_section_response_time = nonsenseTime;
		updatedSaveTemplate.real_section_response_time = realTime;
		updatedSaveTemplate.speaker_button_clicks = 0;
		updatedSaveTemplate.user_directed_navigation = null;
		updatedSaveTemplate.activity_words = answers;
		return updatedSaveTemplate;
	};

	// most FS activities will track the time in a question
	const startQuestionTimer = () => {
		if (isAccuracyOnly) {
			return;
		}
		questionTimeLimit.current = setTimeout(() => {
			if (isAutoplay) {
				AudioPlayer2.play(FastTrackConstants.NO_RESPONSE);
				handlePause();
				return;
			}
			// this isn't very React'y, but using correct React code inside a setTimeout inside a functional component
			// is very error-prone
			document.getElementsByClassName('xb')[0].click();
		}, activityTimeLimit);
	};

	// most FS activities will track the time in a question
	const stopQuestionTimer = () => {
		if (isAccuracyOnly) {
			return;
		}
		clearTimeout(questionTimeLimit.current);
	};

	// all FS activities have to determine the right instruction VO to play
	const getInstructionVOSrc = useCallback(() => {
		return FastTrackUtil.getRandomizedSound(isFirstVisit, currentVO);
	}, [isFirstVisit, currentVO]);

	// all FS activities will handle the video completion
	const handleVideoComplete = useCallback(() => {
		if (currentScreen === 1) {
			setForwardDisabled(false);
		}
	}, [currentScreen]);

	// all FS activities will handle the close button
	const handleModalClose = () => {
		setModalOpen(false);
	};

	// all FS activities will display an Intro modal
	const intro = useMemo(
		() => (
			<FoundationalIntro
				title="Fast Track"
				videoUrl={FastTrackConstants.INTRO_VIDEO}
				icons={[
					{text: 'Listen', icon: 'listen'},
					{text: 'Find', icon: 'preview'},
					{text: 'Click', icon: 'spot'}
				]}
				onVideoComplete={handleVideoComplete}
				isFirstVisit={isFirstVisit}
				instructionSoundSrc={getInstructionVOSrc()}
			/>
		),
		[handleVideoComplete, isFirstVisit, getInstructionVOSrc]
	);

	/******************
	 *
	 *   Custom code specific for your activity goes here
	 *
	 ******************/

	const shuffleQuestions = questions => {
		let shuffledQuestions = shuffle(questions);
		const now = Date.now();
		shuffledQuestions.forEach(q => {
			q.key = now;
		});
		return shuffledQuestions;
	};

	const createNextQuestion = questionIndex => {
		const questions = contentData.activity_words;

		// construct the array of choices that make up a question
		const q = questions[questionIndex];
		let qArray = q.distractors.map(dist => {
			return {
				word: dist,
				correctWord: q.text,
				isCorrect: false,
				type: q.type
			};
		});
		qArray.push({
			word: q.text,
			correctWord: q.text,
			isCorrect: true,
			type: q.type
		});

		AudioPlayer2.play(q.text);

		// shuffle them before they're rendered
		const shuffledQ = shuffleQuestions(qArray);
		setCurrentQuestion(shuffledQ);

		// kick off the question time limit
		startQuestionTimer();

		interactive.current = true;
	};

	const handleSelection = async (e, answer) => {
		e.stopPropagation();

		if (interactive.current === false) {
			return;
		}

		interactive.current = false;

		// kill the question time limit timeout
		stopQuestionTimer();

		// construct the answer object and add it to state
		let newAnswers = cloneDeep(answers);
		newAnswers.push({
			accuracy_score:
				Date.now() - questionTimer.current >= activityTimeLimit && isAccuracyOnly === false
					? 'out_of_time'
					: answer.isCorrect
					? 'correct'
					: 'missed',
			id: answer.correctWord,
			response_time:
				isAccuracyOnly === false
					? Math.min(Date.now() - questionTimer.current, activityTimeLimit)
					: Date.now() - questionTimer.current,
			type: answer.type,
			word_selected: answer.word
		});
		setAnswers(newAnswers);

		// if they got it wrong, show them the right one
		if (answer.isCorrect === false) {
			await AudioPlayer2.playSync(FastTrackConstants.INCORRECT);
			setActivityButtonsDisabled(true);
			await sleep(100);
			const qTemp = cloneDeep(currentQuestion);
			qTemp.find(q => q.isCorrect).highlight = true;
			setCurrentQuestion(qTemp);
			await sleep(1000);
			qTemp.find(q => q.isCorrect).highlight = false;
			setState(() => {
				setCurrentQuestion(qTemp);
				setActivityButtonsDisabled(false);
			});
		}
		// they got it right
		else {
			await AudioPlayer2.playSync(FastTrackConstants.CORRECT);
		}

		// send the progress to the server
		const serverData = createServerData(newAnswers, false);
		FoundationalUtil.sendProgressToServer(serverData);

		// if there's more questions, continue with the next one
		if (currentQuestionIndex < contentData.activity_words.length - 1) {
			questionTimer.current = Date.now();
			createNextQuestion(currentQuestionIndex + 1);
			setCurrentQuestionIndex(currentQuestionIndex + 1);
		}
		// if it's the last question, let them press "Go On"
		else {
			setForwardDisabled(false);
		}
	};

	const handleWord = () => {
		AudioPlayer2.play(currentQuestion[0].correctWord);

		// shuffle the words again and re-start the timer
		setCurrentQuestion(shuffleQuestions(currentQuestion));
		stopQuestionTimer();
		startQuestionTimer();
	};

	return (
		<>
			<Navbar helpSoundUrl={''} onHelp={handleHelpClick} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={[contentData, activityData]}
				title="Fast Track"
			>
				<ActivityFrame isWhiteBackground={true}>
					<>
						<FSProgressBar title="Smart Zone" />

						<ActivityInstructionButton
							audioSrc={getInstructionVOSrc()}
							fs={true}
							onSoundStart={handleInstructionClick}
						/>

						<div className={classes.wrapper}>
							{currentScreen === 1
								? intro
								: currentQuestion.map(q => {
										return (
											<WordTile
												key={`${q.word}-${q.key}`}
												className={`animate__animated ${
													isPaused ? 'animate__zoomOut' : 'animate__zoomIn'
												} ${q.isCorrect ? 'xa' : 'xb'}`}
												border
												selected={q.highlight === true}
												onClick={e => handleSelection(e, q)}
												text={q.word}
											/>
										);
								  })}
						</div>
					</>
				</ActivityFrame>

				{isModalOpen && (
					<FoundationalIntroModal isOpen={isModalOpen} onClose={handleModalClose}>
						{intro}
					</FoundationalIntroModal>
				)}

				<Footer>
					{isPaused === false && currentScreen > 1 && (
						<>
							<ActivitySupportButton
								icon="instructions"
								onClick={handleInstructions}
								text="Instructions"
								disabled={isActvityButtonsDisabled}
							/>
							{forwardStatus === FooterForwardBack.VALID && (
								<>
									<ActivitySupportButton
										icon="word"
										onClick={handleWord}
										text="Word"
										disabled={isActvityButtonsDisabled}
									/>
									<ActivitySupportButton
										icon="pause"
										onClick={handlePause}
										text="Pause"
										disabled={isActvityButtonsDisabled}
									/>
								</>
							)}
						</>
					)}
					{isPaused && <ActivitySupportButton icon="go-on" onClick={handleContinue} text="Continue" />}
					<FooterForwardBack
						onForward={handleForward}
						isBackVisible={false}
						isForwardVisible={true}
						isForwardDisabled={isForwardDisabled}
						status={forwardStatus}
					/>
				</Footer>
			</ActivitySuspense>
		</>
	);
}
