import {makeStyles} from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
	instructionsButtonWrapper: {
		position: 'absolute',
		left: '0px',
		zIndex: '1',
		top: '80px',

		'&.moveLeft': {
			top: '60px',
			left: '4px',
			background: 'transparent',
			borderRadius: '50%',
			padding: 0,
			'& > div': {
				border: 'none',
				color: theme.colors.white,
				background: 'transparent',
				'&.playing': {
					color: theme.colors.readingZone.primary
				}
			}
		}
	}
}));
