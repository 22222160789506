import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import HoverTip from '../../HoverTip/HoverTip'

const styles = theme => ({
	root: {
		color: theme.colors.paleGrey,
		'&:hover,&:focus': {
			color: theme.colors.readingZone.primary
		}
	}
});

function PlaySwitch({
	classes,
	playing,
	onIconButtonClick,
	iconButtonStyle,
	iconDefaultStyle,
	iconStyle
}) {
	return (
		<HoverTip title={`Click to ${playing ? 'Pause' : 'Play'}`} placement='top' >
			<IconButton
				aria-label="Play/Pause"
				className={classes.root}
				color="inherit"
				style={iconButtonStyle}
				onClick={onIconButtonClick}
			>
				{playing ? (
					<i
						className="r180 pause isPlayingTrue"
						data-testid="isPlayingFlag"
						style={{ fontSize: '24px' }}
					></i>
				) : (
						<i className="r180 play" style={{ fontSize: '24px' }}></i>
					)}
			</IconButton>
		</HoverTip>
	);
}

PlaySwitch.defaultProps = {
	classes: {},
	iconButtonStyle: {},
	iconDefaultStyle: {
		color: 'inherit',
		fontSize: 'inherit'
	},
	iconStyle: {},
	playing: false
};
PlaySwitch.propTypes = {
	classes: PropTypes.object,
	iconButtonStyle: PropTypes.object,
	iconDefaultStyle: PropTypes.object,
	iconStyle: PropTypes.object,
	playing: PropTypes.bool,
	onIconButtonClick: PropTypes.func.isRequired
};

export default memo(withStyles(styles)(PlaySwitch));
