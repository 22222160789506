import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import WordTitle from '../../components/WordTitle';
import ActivityDirections from '../../components/ActivityDirections';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import {isFirefox} from '@reading/r180/src/utils/browsers';
import {sleep} from '@reading/r180/src/utils/sleep';

export const useStyles = makeStyles(theme => ({
	contextTitle: {
		textAlign: 'center',
		fontFamily: theme.fonts.uiBold,
		fontSize: '18px',
		lineHeight: '22px',
		padding: '25px'
	},
	wordTextWrapper: {
		textAlign: 'center',
		marginBottom: '50px',
		paddingBottom: '30px',
		marginLeft: '100px',
		marginRight: '100px',
		borderBottom: `1px solid ${theme.colors.paleGrey}`
	},
	wordText: {
		background: theme.colors.white,
		textAlign: 'center',
		'& span': {
			color: theme.colors.black,
			fontFamily: theme.fonts.contextBold
		}
	},
	wordSentence: {
		textAlign: 'center',
		fontFamily: theme.fonts.context,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '24px',
		lineHeight: '34px',
		color: theme.colors.black,
		animationDelay: '7.5s',
		'& .activity-button-speaker': {
			position: 'relative',
			top: isFirefox() ? '-2px' : '-1px'
		}
	}
}));

const WordContextLanding = props => {
	const {id, wordCard, setBtnDisabled} = props;
	const classes = useStyles();

	const handleSpeakerComplete = () => {
		setBtnDisabled(false);
	};

	return (
		<div id={id}>
			<p className={classes.contextTitle}>
				The sentence below correctly uses the word:
			</p>
			<div
				className={`${classes.wordTextWrapper} animate__animated animate__fadeIn`}
			>
				<WordTitle
					id="wordContext-wordText"
					className={classes.wordText}
					word={wordCard.power_word_focus}
					src={wordCard.power_word_focus_url}
					onSpeakerComplete={handleSpeakerComplete}
				/>
			</div>
			<div>
				<div
					className={`${classes.wordSentence} animate__animated animate__fadeIn`}
				>
					<ActivityDirections
						id="wordContext-ActivityDirections"
						text={wordCard.word_card_sentence}
						src={wordCard.word_card_sentence_url}
					/>
				</div>
			</div>
		</div>
	);
};

WordContextLanding.defaultProps = {
	id: ''
};

WordContextLanding.propTypes = {
	id: PropTypes.string,
	wordCard: PropTypes.object,
	setBtnDisabled: PropTypes.func
};

export default React.memo(WordContextLanding);
