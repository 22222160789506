/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import HMHRedux from '../redux-util';

// slice constants
const sliceName = 'success';


// slice reducers
export const successSlice = HMHRedux.createSlice({
	name: sliceName,
	logoutAction: 'session/logout',
	initialState: {
		prevAnswer: {} // stores the answers that aren't submitted yet in activities when moving around Success Zone.
	},
	reducers: {
		storeActivityAnswer: (state, action) => {
			const answer = action.payload;
			state.prevAnswer[answer.id] = answer.answer;
		},
	},
	extraReducers: {

	}
});

export default successSlice.reducer;
