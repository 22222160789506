import React, {useState, useEffect, useMemo, useRef} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {useHistory} from 'react-router-dom';
import media from '../../api/media';
import {bulkReplace} from '../../utils/stringUtils';
import {createAssetId} from '../../media/mediaUtil';
import {useStyles} from './ReadAndRecord.style';
import {
	ReadingPassage,
	TabBar,
	ReadAloudControlBar,
	AnchorVideo,
	ActivityButton,
	SoundButton,
	QuestionType,
	ActivitySupportVideoSummaryButton,
	CaptionedAudioPlayer2 as AudioPlayer2
} from '@reading/common';
import RecordControlBar from '@reading/common/src/activityComponents/RecordControlBar/RecordControlBar';
import Navbar from '../../containers/App/Navbar';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import WordCard from '@reading/common/src/components/WordCard/WordCard';
import {getAudioExt} from '../../utils/audio';
import AudioRecorder from '@reading/common/src/components/AudioRecorder/AudioRecorder';
import {Modal} from '@material-ui/core';
import useIdleHelp from '../../utils/useIdleHelp';
import {ReadAndRecord} from './ReadAndRecord';
import ConfirmationModal from '@reading/common/src/components/Modals/ConfirmationModal';
import {ReadingQuestionPanel} from '@reading/common';
import {uiSlice} from '../../store/slices/ui';
import {getContentInfo} from '../../media/mediaUtil';
import {KnowledgeForReading} from '../WordCard/KnowledgeForReading';
import {MEDIA_SERVER_URL} from '../../utils/constants';
import useBatchedSetState from '../../utils/useBatchedSetState';
import {MIN_REC_LENGTH_SEC} from '../ReadingPassage/ReadingPassageUtils';

const EXT = getAudioExt();

const ReadAndRecordActivity = () => {
	const {setState} = useBatchedSetState();
	const audioRecorder = useMemo(() => {
		const audioRecorder = new AudioRecorder();
		audioRecorder.recordingReady = duration => {
			if (duration >= ReadAndRecord.MIN_REC_LENGTH_SEC) {
				setState(() => {
					setCanPlay(true);
					setCanSave(true);
				});
				if (autoPlayEnabled) {
					AudioPlayer2.play('mandrecordhelppost');
				}
			} else {
				AudioPlayer2.play('feedback_tooshort');
				setState(() => {
					setCanPlay(false);
					setCanSave(false);
				});
			}
		};
		return audioRecorder;
	}, []);

	/* ShallEqual added to avoid  unnecessary rerendering of components.
	   This fixes tips in Wordcard Modal- infinite animation */
	const {
		activityData: {studentActivityId, activityServerId, stage, segment, level},
		readingsettings,
		session: {
			session: {
				settings: {autoPlayEnabled}
			}
		}
	} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			readingsettings: state.activity.readingsettings,
			session: state.session
		};
	}, shallowEqual);

	const [passageString, setPassageString] = useState(false);
	const [activeTab, setActiveTab] = useState(ReadAndRecord.tabs.allTabs[1]);
	const [previousTab, setPreviousTab] = useState(ReadAndRecord.tabs.allTabs[0]);
	const [segmentData, setSegmentData] = useState(false);
	const [fluencyQuestions, setFluencyQuestions] = useState([]);
	const [showRecordingTips, setShowRecordingTips] = useState(false);
	const [canPlay, setCanPlay] = useState(false);
	const [cardWord, setCardWord] = useState(false);
	const [wordCardData, setWordCardData] = useState(false);
	const [mode, setMode] = useState(ReadAndRecord.MODES.RECORD);
	const [readWords, setReadWords] = useState(false);
	const [readPhrases, setReadPhrases] = useState(false);
	const [readMode, setReadMode] = useState('word');
	const [wordDelay, setWordDelay] = useState(ReadAndRecord.WORD_DELAYS[3]);
	const [playbackProgress, setPlaybackProgress] = useState(0);
	const [readAloudComplete, setReadAloudComplete] = useState(false);
	const [showRecordingSaveModal, setShowRecordingSaveModal] = useState(false);
	const [showRecordingSubmitModal, setShowRecordingSubmitModal] = useState(false);
	const [showRecordingKillModal, setShowRecordingKillModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [video, setVideo] = useState({pause: () => {}});
	const [loaded, setLoaded] = useState(false);
	const [sendProgressFlag, setSendProgressFlag] = useState(false);
	const [startRecordCountDown, setStartRecordCountDown] = useState(false);
	const [posterSrc, setPosterSrc] = useState('');
	const [videoSrc, setVideoSrc] = useState('');
	const [videoCaptionSrc, setVideoCaptionSrc] = useState('');
	const [elSummarySrc, setElSummarySrc] = useState('');
	const [selectedTabID, setSelectedTabID] = useState('');
	const [selectedTabIndex, setSelectedTabIndex] = useState('');
	const saveDelayTimeout = useRef(0);
	const [canSave, setCanSave] = useState(false);
	const [tipsHaveBeenShown, setTipsHaveBeenShown] = useState(false);
	const [readAloudPaused, setReadAloudPaused] = useState(false);
	const [readType, setReadType] = useState('word');
	const [isPlayBackClicked, setPlayBackClicked] = useState(false);

	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const whichHelp = () => {
		if (activeTab.id === '1') {
			return ReadAndRecord.readHelpSound;
		}
		if (activeTab.id === '2') {
			return ReadAndRecord.recordHelpSound;
		}
	};

	const handleGoOn = () => {
		if (activeTab.id === '1') {
			setActiveTab({id: '2'});
			setStartRecordCountDown(false);
			return;
		}
		if (activeTab.id === '2' && mode === ReadAndRecord.MODES.RECORD && canPlay) {
			setShowRecordingSaveModal(true);
		}
	};

	const isGoOnDisabled = () => {
		const DISABLED = true;
		const ENABLED = false;
		if (activeTab.id === '1') {
			return ENABLED;
		}
		if (activeTab.id === '2') {
			if (mode === ReadAndRecord.MODES.RECORD) {
				if (!canPlay) {
					return DISABLED;
				}
				return ENABLED;
			}
			if (mode === ReadAndRecord.MODES.RATE) {
				return DISABLED;
			}
		}
	};

	useIdleHelp(whichHelp());

	useEffect(() => {
		if (stage && segment) {
			setPosterSrc(
				bulkReplace(ReadAndRecord.segmentFeature, {
					asset_id: createAssetId(stage, segment, level, false)
				})
			);
			setVideoSrc(
				bulkReplace(ReadAndRecord.segmentVideo, {
					asset_id: createAssetId(stage, segment, level, false)
				})
			);
			setVideoCaptionSrc(
				bulkReplace(ReadAndRecord.segmentVideoCaptions, {
					asset_id: createAssetId(stage, segment, level, false)
				})
			);
			setElSummarySrc(
				bulkReplace(ReadAndRecord.ELSummary, {
					asset_id: createAssetId(stage, segment, level, false)
				})
			);
		}
	}, [stage, segment]);

	useEffect(() => {
		if (passageString && segmentData) {
			setLoaded(true);
		}
	}, [passageString, segmentData]);

	useEffect(() => {
		audioRecorder.progressCallback = (progress, isPlayBackCompleted) => {
			if (mode === ReadAndRecord.MODES.RECORD && activeTab.id === '2' && canPlay) {
				setPlaybackProgress(progress);
			}
			if (isPlayBackCompleted) {
				setPlayBackClicked(false);
			}
		};
		audioRecorder.levelsCallback = level => {
			if (
				mode === ReadAndRecord.MODES.RECORD &&
				activeTab.id === '2' &&
				!canPlay &&
				audioRecorder.micRecorder.state === 'recording'
			) {
				let newStyle = {};
				newStyle.borderWidth = `${3 + 8 * level}px`;
				const size = 36 + 8 * level * 2;
				newStyle.width = `${size}px`;
				newStyle.height = `${size}px`;
				newStyle.borderRadius = `${size / 2}px`;
				const stopElem = document.querySelector('.action-button');

				if (stopElem) {
					stopElem.style.borderWidth = newStyle.borderWidth;
					stopElem.style.width = newStyle.width;
					stopElem.style.height = newStyle.height;
					stopElem.style.borderRadius = newStyle.borderRadius;
				}

				if (level < ReadAndRecord.NOISE_THRESHOLD) {
					if (!audioRecorder.silenceTimeout) {
						audioRecorder.silenceTimeout = setTimeout(silenceTimeoutHandler, ReadAndRecord.SILENCE_TIME);
					}
				} else {
					clearTimeout(audioRecorder.silenceTimeout);
					audioRecorder.silenceTimeout = null;
				}

				return newStyle;
			}
		};
	}, [mode, activeTab, canPlay]);

	useEffect(() => {
		const getPassageMedia = async () => {
			try {
				const payload = await media.getOne(
					bulkReplace(ReadAndRecord.PASSAGE_TEXT, {asset_id: createAssetId(stage, segment, level, true)})
				);
				setPassageString(payload);
			} catch (e) {
				setPassageString(false);
				dispatch(uiSlice.actions.setErrorMessage(e));
			}
		};
		if (activityServerId === 'read_and_record' && stage && segment && level) {
			getPassageMedia();
			ReadAndRecord.preloadSound(stage, segment, level);
		}
	}, [activityServerId, stage, segment, level]);

	useEffect(() => {
		const getContentData = async () => {
			try {
				const payload = await media.getOne(bulkReplace(ReadAndRecord.SEGMENTS, {stage}));
				const segmentData = payload.segments.find(filterSegment => filterSegment.segment_number === segment);
				setSegmentData(segmentData);
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				setSegmentData({title: ''});
			}
		};
		if (stage) {
			getContentData();
		}
	}, [stage]);

	useEffect(() => {
		AudioPlayer2.stopAll();
		setTimeout(() => {
			if (autoPlayEnabled) {
				AudioPlayer2.play('mandrecord');
			}
		}, 2000);
		const getFluencyQuestions = async () => {
			try {
				const payload = await media.getOne(ReadAndRecord.FLUENCY_RATING_QUESTION);
				setFluencyQuestions(payload.questions);
			} catch (e) {
				setFluencyQuestions([]);
			}
		};
		getFluencyQuestions();
	}, []);

	useEffect(() => {
		const getWordCardData = async () => {
			try {
				let wordCardData = await media.getOne(bulkReplace(ReadAndRecord.WORD_CARD, {word: cardWord.word}));
				if (!wordCardData.contextualData[0]) {
					wordCardData.contextualData[0] = {
						contextualDefinition: wordCardData.definition,
						contextualSentence: wordCardData.sentence
					};
				}
				const data = {studentActivityId, activityServerId, stage, segment, level};
				const word = cardWord.word.toLowerCase();
				const constants = {
					word: word,
					asset_id: getContentInfo(data, false).assetId
				};

				const WORD_CTXT_URL = `${MEDIA_SERVER_URL}/assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`;
				const WORD_DEF_URL = `${MEDIA_SERVER_URL}/assets/dictio/def/r180u_def_def-{word}.${EXT}`;
				const word_text_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, constants);
				const word_def_url = bulkReplace(WORD_DEF_URL, constants);
				const word_ctx_url = bulkReplace(WORD_CTXT_URL, constants);
				// the pronounced word
				AudioPlayer2.load({name: word, src: word_text_url});

				// the definition
				AudioPlayer2.load({name: `def-${word}`, src: word_def_url});

				// the context
				AudioPlayer2.load({name: `ctx-${word}`, src: word_ctx_url});

				setWordCardData(wordCardData);
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				setWordCardData(false);
				setCardWord(false);
			}
		};
		if (cardWord && cardWord.word) {
			getWordCardData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cardWord]);

	const getTabs = () => {
		if (startRecordCountDown) {
			return ReadAndRecord.tabs.onlyRecordActive;
		}
		return ReadAndRecord.tabs.allTabs;
	};

	const silenceTimeoutHandler = () => {
		audioRecorder.stopRecord(false);
		dispatch(uiSlice.actions.cancelRecording());
	};

	const renderRateRecording = () => {
		return (
			<ReadingQuestionPanel
				onAnswerChange={() => {}} //Fun note, these answers are discarded!
				onSubmit={() => {
					setMode(ReadAndRecord.MODES.ATTENTION);
				}}
				questionType={QuestionType.RATE_RECORDING}
				title={'You recorded your reading. Now rate your recording.'}
				titleSound={{
					name: 'Rate_Recording_Title',
					src: ' ' // TODO : No audio exists in legacy as well as media repo but design has audio icon
				}}
				buttonText={'Go On'}
				questions={fluencyQuestions}
				buttonIcon="arrow-right"
			/>
		);
	};

	const renderFocusAttention = () => {
		return (
			<ReadingQuestionPanel
				title={'Where will you focus your effort next time you practice reading fluently.'}
				titleSound={{
					name: 'Focus_Attention_Title',
					src: ReadAndRecord.Focus_Attention_Audio
				}}
				questionType={QuestionType.FOCUS_ATTENTION}
				questions={fluencyQuestions}
				onSubmit={() => setShowRecordingSubmitModal(true)}
				onAnswerChange={() => {}}
				buttonText="Go On"
				buttonIcon="arrow-right"
			/>
		);
	};

	const handleVideoPlay = video => {
		AudioPlayer2.stopAll();
		setVideo(video);
	};

	useEffect(() => {
		if (sendProgressFlag) {
			ReadAndRecord.sendProgressToServer({
				activity: {
					studentActivityId,
					isComplete: false
				},
				readingsettings
			});
			setSendProgressFlag(false);
		}
	}, [sendProgressFlag]);

	useEffect(() => {
		if (mode === ReadAndRecord.MODES.DONE) {
			ReadAndRecord.completeActivity(
				{
					activity: {
						isComplete: true,
						studentActivityId: studentActivityId
					},
					readingsettings
				},
				history,
				dispatch
			);
		}
	}, [mode]);

	const uploadRecording = () => {
		return audioRecorder.uploadRecording(studentActivityId);
	};

	/*useEffect(() => {
		console.log({loaded, autoPlayEnabled});
		if (loaded && autoPlayEnabled) {
			recordInstructions();
		}
	}, [loaded]);*/
	useEffect(() => {
		if (autoPlayEnabled) {
			if (activeTab.id === '1') {
				readAloudInstructions();
			}
			if (activeTab.id === '2') {
				recordInstructions();
			}
		}
	}, [activeTab]);
	useEffect(() => {
		if (autoPlayEnabled && showRecordingSubmitModal) {
			AudioPlayer2.play('submitrecording');
		}
	}, [showRecordingSubmitModal]);

	const readAloudInstructions = async () => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play('readaloud');
	};

	const recordInstructions = () => {
		AudioPlayer2.stopAll();
		if (!canPlay) {
			AudioPlayer2.play('record');
		} else {
			AudioPlayer2.play('postrecord');
		}
	};

	/* To remove the flicker effect in the WordCard Modal where the previous words
	   shown for a second while new selection data is being fetched */
	const isDataLoaded = wordCardData && wordCardData.text === cardWord.word;

	return (
		<div className={`${classes.wrapper}`}>
			<Navbar helpSoundUrl={whichHelp()} short={true} />
			<ActivitySuspense requiredRenderData={[{loaded}]} showSpinner={!loaded} title={'Read And Record'}>
				{isDataLoaded && cardWord && cardWord.word && wordCardData && (
					<Modal
						className={`${classes.wordCardModal} r180-modal`}
						open={cardWord && cardWord.word && wordCardData}
						onClose={() => {
							setCardWord(false);
						}}
					>
						<div className={classes.wordCardModalBody} data-testid={'wordCardModal'}>
							<WordCard
								wordCardData={wordCardData}
								card={{
									progress: 0,
									maxProgress: 0,
									word_card: {
										word_card_activities: [],
										word_card_sentence: wordCardData.sentence,
										word_card_definition: wordCardData.definition,
										word_text: wordCardData.text
									}
								}}
								taskData={{video_hint: ''}}
								hideVideoTool={true}
								handleClose={() => {
									setCardWord(false);
								}}
								showExitButton={true}
							/>
						</div>
					</Modal>
				)}
				{activeTab.id === '3' && (
					<Modal className={`${classes.wordCardModal} r180-modal`} open={activeTab.id === '3'}>
						<div className={`${classes.videoModalBody} modal-window`} data-testid={'videoModal'}>
							<AnchorVideo
								id="readingRecordVideo"
								containerStyle={{width: '865px'}}
								hideVideoTimeDuration
								poster={posterSrc}
								autoPlay={false}
								aria-label={'Anchor video'}
								onVideoPlay={handleVideoPlay}
								ended={true}
								hideCloseButton={false}
								handleCloseButton={() => {
									setActiveTab(previousTab);
								}}
							>
								<source src={videoSrc} type={'video/mp4'} />
								<track
									src={videoCaptionSrc}
									kind={'subtitles'}
									srcLang={'en'}
									label={'English subtitles'}
								/>
							</AnchorVideo>
							<ActivitySupportVideoSummaryButton soundUrl={elSummarySrc} video={video} />
						</div>
					</Modal>
				)}
				{showRecordingTips && (
					<Modal
						open={showRecordingTips}
						onClose={() => {
							setShowRecordingTips(false);
						}}
						className={`${classes.wordCardModal} r180-modal`}
					>
						<div className={classes.recordingTipsModalBody} data-testid={'recordingTipsModal'}>
							<div className={classes.tipHeader}>
								<SoundButton
									border={false}
									sound={{name: 'recordingTips', src: 'placeholder'}}
									size={32}
									className={'transparent'}
								/>
								<span className={classes.contentRight}>As you record your reading remember to:</span>
							</div>
							<div className={classes.tipsEntry}>
								<div>
									<span className={classes.tipCheck}>
										<i className="r180 check" />
									</span>
								</div>
								Pronounce the words carefully.
							</div>
							<div className={classes.tipsEntry}>
								<div>
									<span className={classes.tipCheck}>
										<i className="r180 check" />
									</span>
								</div>
								<span>
									Use punctuation like periods, commas, and exclamation points to help you read with
									expression.
								</span>
							</div>
							<div className={classes.tipsEntry}>
								<div>
									<span className={classes.tipCheck}>
										<i className="r180 check" />
									</span>
								</div>
								Try to make your reading sound clear and natural like the modeled reading.
							</div>
							<div className={classes.tipsButton}>
								<ActivityButton
									onClick={() => {
										setShowRecordingTips(false);
										setStartRecordCountDown(true);
									}}
									text={'Go On'}
									icon={'arrow-right'}
								/>
							</div>
						</div>
					</Modal>
				)}
				{showRecordingSaveModal && (
					<ConfirmationModal
						className={classes.recordingSaveConfirmModal}
						open={showRecordingSaveModal}
						onPrimaryButtonClicked={() => {
							if (autoPlayEnabled) {
								AudioPlayer2.stopAll();
								AudioPlayer2.play('selfcheckratingintro');
							}
							setMode(ReadAndRecord.MODES.RATE);
							setShowRecordingSaveModal(false);
						}}
						onSecondaryButtonClicked={() => {
							setShowRecordingSaveModal(false);
						}}
						onCloseClicked={() => {
							setShowRecordingSaveModal(false);
						}}
						title={'Are you done listening to your recording and ready to start reviewing it?'}
						primaryButtonText={'Yes'}
						primaryButtonIcon={'check'}
						secondaryButtonIcon={'close'}
						secondaryButtonText={'No'}
						showCloseIcon={false}
					/>
				)}
				{showRecordingKillModal && (
					<ConfirmationModal
						className={classes.recordingKillConfirmModal}
						open={showRecordingKillModal}
						onSecondaryButtonClicked={() => {
							setState(() => {
								setCanPlay(false);
								setCanSave(false);
								setMode(ReadAndRecord.MODES.RECORD);
								setShowRecordingKillModal(false);
							});
						}}
						onPrimaryButtonClicked={() => {
							setShowRecordingKillModal(false);
						}}
						onCloseClicked={() => {
							setShowRecordingKillModal(false);
						}}
						title={'Are you sure you want to start over?'}
						subTitle={'Your recording will be deleted!'}
						showWarningIcon={true}
						primaryButtonText={'No'}
						primaryButtonIcon={'close'}
						secondaryButtonIcon={'check'}
						secondaryButtonText={'Yes'}
						showCloseIcon={false}
					/>
				)}
				{showRecordingSubmitModal && (
					<ConfirmationModal
						open={showRecordingSubmitModal}
						className={classes.recordingSubmitConfirmModal}
						onPrimaryButtonClicked={async () => {
							// TODO :: save your recording here
							if (await uploadRecording()) {
								setState(async () => {
									setShowRecordingSubmitModal(false);
									setShowConfirmationModal(true);
									if (autoPlayEnabled) {
										AudioPlayer2.stopAll();
										await AudioPlayer2.playSync('submitrecordingaccepted');
									}
								});
							} else {
								dispatch(uiSlice.actions.setErrorMessage('Unable to upload recording'));
							}
						}}
						onSecondaryButtonClicked={async () => {
							if (autoPlayEnabled) {
								await AudioPlayer2.playSync('submitrecordingrejected');
							}
							setMode(ReadAndRecord.MODES.ATTENTION);
							setShowRecordingSubmitModal(false);
						}}
						onCloseClicked={async () => {
							if (autoPlayEnabled) {
								await AudioPlayer2.playSync('submitrecordingrejected');
							}
							setMode(ReadAndRecord.MODES.ATTENTION);
							setShowRecordingSubmitModal(false);
						}}
						title={'Do you want to submit your recording?'}
						titleSound={{
							name: 'Submit_Recording_Title',
							src: ReadAndRecord.Submit_Recording_Title_Audio
						}}
						primaryButtonText={'Yes'}
						secondaryButtonText={'No'}
						primaryButtonIcon={'check'}
						secondaryButtonIcon={'x'}
						showCloseIcon={false}
					/>
				)}

				{showConfirmationModal && (
					<ConfirmationModal
						open={showConfirmationModal}
						className={classes.recordingConfirmationModal}
						onPrimaryButtonClicked={() => {
							setMode(ReadAndRecord.MODES.DONE);
							AudioPlayer2.stopAll();
							setShowConfirmationModal(false);
						}}
						onCloseClicked={() => {
							setMode(ReadAndRecord.MODES.DONE);
							AudioPlayer2.stopAll();
							setShowConfirmationModal(false);
						}}
						title={'Thanks for submitting your recording!'}
						titleSound={{
							name: 'Congrats_Title',
							src: ''
						}}
						primaryButtonText={'Go On'}
						primaryButtonIcon={'arrow-right'}
						showSecondaryButton={false}
						showCloseIcon={false}
					/>
				)}

				{mode === ReadAndRecord.MODES.RECORD && (
					<ActivityButton
						onClick={handleGoOn}
						text={'Go On'}
						icon={'arrow-right'}
						disabled={isGoOnDisabled()}
						className={`${classes.goOn}`}
					/>
				)}
				<div className={`${classes.clearBoth}`} />

				<div className={`${classes.contentWrapper}`}>
					<div
						className={`${classes.leftColumn} ${mode !== ReadAndRecord.MODES.RECORD && classes.leftNarrow}`}
					>
						<div className={`${classes.tabsAndControls}`}>
							<TabBar
								id={'tabs'}
								tabs={getTabs()}
								onClick={tab => {
									setState(() => {
										if (tab.id === '3') {
											setPreviousTab(activeTab);
										}

										setActiveTab(tab);
										setSelectedTabIndex(tab.id);
										setReadWords(false);
										setReadPhrases(false);
										setSelectedTabID(`tab${tab.name}`);
									});
								}}
								selectedTab={activeTab.id}
							/>
							{activeTab.id === '1' && (
								<ReadAloudControlBar
									onPlay={() => {
										if (readMode === 'word') {
											setReadWords(true);
										} else {
											setReadPhrases(true);
										}
										setReadAloudPaused(false);
									}}
									onPause={() => {
										setReadWords(false);
										setReadPhrases(false);
										setReadAloudPaused(true);
									}}
									onReadTypeChange={type => {
										setReadMode(type);
										setSelectedTabID(`tab${type}`);
										/* Enable/disbale the read/phrase modes on the swicth */
										if (type === 'word' && readPhrases) {
											setReadWords(true);
											setReadPhrases(false);
										}
										if (type === 'phrase' && readWords) {
											setReadWords(false);

											setReadPhrases(true);
										}
										setReadType(type);
									}}
									onInstructions={readAloudInstructions}
									onSpeedChange={speed => {
										setWordDelay(ReadAndRecord.WORD_DELAYS[speed]);
									}}
									selectedTabIndex={selectedTabIndex}
									setReadMode={setReadMode}
									readAloudComplete={readAloudComplete}
									setReadAloudComplete={setReadAloudComplete}
								/>
							)}
							{activeTab.id === '2' && (
								<RecordControlBar
									onInstructions={recordInstructions}
									onStartOver={() => {
										clearTimeout(audioRecorder.silenceTimeout);
										audioRecorder.stopPlay();
										setShowRecordingKillModal(true);
										setPlayBackClicked(false);
										audioRecorder.silenceTimeout = null;
									}}
									isSaveDisabled={!canSave || mode != ReadAndRecord.MODES.RECORD}
									isStartOverDisabled={!canPlay}
									onSave={() => {
										setShowRecordingSaveModal(true);
									}}
									onRecord={() => {
										clearTimeout(audioRecorder.silenceTimeout);
										saveDelayTimeout.current = setTimeout(() => {
											setCanSave(true);
										}, MIN_REC_LENGTH_SEC * 1000);
										setCanSave(false);
										AudioPlayer2.stopAll();
										audioRecorder.record();
										audioRecorder.silenceTimeout = null;
									}}
									onPause={() => {
										audioRecorder.stopRecord(true);
										audioRecorder.stopPlay();
										setStartRecordCountDown(false);
										clearTimeout(audioRecorder.silenceTimeout);
										clearTimeout(saveDelayTimeout.current);
									}}
									onDone={() => {
										audioRecorder.stopRecord(false);
										audioRecorder.stopPlay();
										setStartRecordCountDown(false);
										clearTimeout(audioRecorder.silenceTimeout);
										clearTimeout(saveDelayTimeout.current);
									}}
									onPlay={() => {
										AudioPlayer2.stopAll();
										audioRecorder.play();
										setPlayBackClicked(true);
									}}
									canPlayRecording={canPlay}
									playbackProgress={playbackProgress}
									onRecordingSkipBack={() => {
										audioRecorder.seek(ReadAndRecord.SEEKSTART);
									}}
									onRecordingRewind={() => {
										audioRecorder.seek(ReadAndRecord.SEEKBACK);
									}}
									onRecordingFastForward={() => {
										audioRecorder.seek(ReadAndRecord.SEEKFORWARD);
									}}
									onRecordingSkipForward={() => {
										audioRecorder.seek(ReadAndRecord.SEEKEND);
									}}
									onSeekPercent={seekPercent => {
										audioRecorder.seekPercent(seekPercent);
									}}
									startRecordCountDown={startRecordCountDown}
									onRecordButtonClick={() => {
										if (!tipsHaveBeenShown) {
											setShowRecordingTips(true);
											setTipsHaveBeenShown(true);
										} else {
											setStartRecordCountDown(true);
										}
									}}
									isReadRecord={true}
									isPlayBackClicked={isPlayBackClicked}
									pausePlayback={() => {
										AudioPlayer2.stopAll();
										audioRecorder.pause();
										setPlayBackClicked(false);
									}}
								/>
							)}
						</div>
						<div className={`${classes.passage}`}>
							<ReadingPassage
								passageString={passageString}
								title={segmentData.name}
								setCardWord={setCardWord}
								readWords={readWords}
								readPhrases={readPhrases}
								wordDelay={wordDelay}
								stage={stage}
								segment={segment}
								level={level}
								setReadAloudComplete={isComplete => setReadAloudComplete(isComplete)}
								selectedTabID={selectedTabID}
								selectedTabIndex={selectedTabIndex}
								readAloudComplete={readAloudComplete}
								isCompact={mode === ReadAndRecord.MODES.RATE || mode === ReadAndRecord.MODES.ATTENTION}
								readAloudPaused={readAloudPaused}
								readType={readType}
							/>
						</div>
					</div>
					<div className={`${classes.rightColumn}`}>
						{mode === ReadAndRecord.MODES.RATE && renderRateRecording()}
						{mode === ReadAndRecord.MODES.ATTENTION && renderFocusAttention()}
					</div>
				</div>
			</ActivitySuspense>
		</div>
	);
};

export default ReadAndRecordActivity;
