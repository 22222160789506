import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			overflow: 'overlay'
		}
	},
	synonymsAndAntonyms: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		rowGap: 'min(15px, 2vh)',
		width: '100%',
		height: '100%',
		padding: '16px 22px',

		'& .header': {
			flexShrink: 0,
			flexGrow: 0,
			maxHeight: 'min(40vh, 40%)',
			overflowY: 'hidden',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			alignItems: 'center',
			rowGap: 'min(16px, 3vh)',

			'& .title,& .text': {
				flexGrow: 0,
				width: '100%',

				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				alignContent: 'flex-start'
			},

			'& .text': {
				maxHeight: 'min(96px, 12vh)',
				overflow: 'hidden',
				marginTop: '-8px',

				'& .activity-directions': {
					maxWidth: '60vw'
				}
			}
		},

		'& .drag-container': {
			flexGrow: 0,
			minHeight: 'min(18vh, 22%)',
			maxHeight: 'min(22vh,28%)',
			overflow: 'hidden',
			flexShrink: 0,
			paddingBottom: 10
		},

		'& .drop-container': {
			'--animate-duration': '0.3s',
			flexGrow: 3,
			flexShrink: 0,
			width: '100%',
			display: 'flex',
			columnGap: 22
		}
	}
}));

export default useStyles;
