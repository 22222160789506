import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sortByMode, spellCheck, tiredWords, updateReviewTextWithoutHighlight, sentenceSense } from './WritingUtil';
import TiredWordsPopover from './TiredWordsPopover';
import MagicTextArea from '../MagicTextArea/MagicTextArea';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { MEDIA_SERVER_URL } from '@reading/r180/src/utils/constants';
import { getAudioExt } from '@reading/r180/src/utils/audio';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import useAppMeasurements from '@reading/r180/src/utils/useAppMeasurements';
import { isFirefox } from '@reading/r180/src/utils/browsers';
import { capitalizeWord } from '@reading/r180/src/utils/stringUtils';

export const useStyles = makeStyles(theme => ({
	bigWrapper: {
		background: theme.colors.white,
	},
	wrapper: {
		width: '100%',
		background: theme.colors.white,
		height: props => `calc(100vh - ${props.headerHeight + 298}px)`,
		minHeight: '400px',
		lineHeight: '32px',
		padding: '0px 40px 0px 40px',
		overflowY: props => props.isEditMode ? 'hidden' : isFirefox() ? 'auto' : 'overlay',

		'&::-webkit-scrollbar': {
			width: '6px'
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			padding: '10px 0'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#CCC',
			borderRadius: '8px'
		},

		'& textarea': {
			fontFamily: theme.fonts.ui,
			overflowY: 'auto',
			paddingRight: '20px',
			'&::-webkit-scrollbar': {
				width: '6px'
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				padding: '10px 0'
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#CCC',
				borderRadius: '8px'
			},
		}
	},
	titleBar: {
		position: 'relative',
		width: '100%',
		height: '32px',
		padding: '3px 11px',
		background: theme.colors.writingZone.green5,

		'& .title': {
			display: 'inline-block',
			color: theme.colors.writingZone.background,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '18px',
			position: 'relative'
		},
		'& .revise': {
			float: 'right',
			color: theme.colors.writingZone.background,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '16px',
			marginRight: '8px',
			position: 'relative',
			top: '1.5px'
		}
	},
	writingArea: {
		width: '100%',
		height: 'auto',
		maxHeight: '128px',
		overflowY: isFirefox() ? 'auto' : 'overlay',

		'&::-webkit-scrollbar': {
			width: '6px'
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			padding: '10px 0'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#CCC',
			borderRadius: '8px'
		},
		background: theme.colors.lightGrey,
		color: theme.colors.grey,
		fontSize: '18px',
		padding: '16px',
		lineHeight: '32px',
		textIndent: '32px',
		marginBottom: '24px',
		'&:last-of-type': {
			marginBottom: '0'
		}
	},
	editButtonWrapper: {
		padding: '10px',
		marginTop: '-8px',
		marginBottom: '8px',
		'&.edit': {
			display: 'flex',
			justifyContent: 'flex-end',
		},
		'&  i': {
			color: theme.colors.readingZone.primary,
			marginRight: '10px'
		},
		'&  i.hide': {
			display: 'none'
		},
		'& span': {
			fontSize: '16px',
			fontFamily: theme.fonts.button,
			color: theme.colors.black
		}
	},
	reviewTextWrapper: {
		//color: theme.colors.grey,
		padding: '4px 0',
	},
	spellCheckWrapper: {
		'& span': {
			textDecoration: 'underline wavy',
			textDecorationColor: theme.colors.errorRed,
			textDecorationSkipInk: 'none',
			textUnderlineOffset: '4px',
			textDecorationThickness: '2px'
		}
	},
	tiredWordsWrapper: {
		'& span': {
			textDecoration: 'underline wavy',
			textDecorationColor: theme.colors.readingZone.primary,
			cursor: 'pointer',
			textDecorationSkipInk: 'none',
			textUnderlineOffset: '4px',
			textDecorationThickness: '2px'
		}
	},
	sentenceSenseWrapper: {
		'& span': {
			textDecoration: 'underline double',
			textDecorationColor: theme.colors.slate,
			textDecorationSkipInk: 'none',
			textUnderlineOffset: '4px',
			textDecorationThickness: '2px'
		}
	}
}));

const ReviewPanel = props => {
	const {
		completedPanelData,
		mode,
		uniqueIDMap,
		writingToolsSpellCheckObj,
		writingToolsTiredWordsObj,
		spellCheckEnabled,
		tiredWordsEnabled,
		sentenceSenseEnabled,
		isWritingToolActive,
		onReviewPanelEdit
	} = props;

	const [reviewText, setReviewText] = useState(
		sortByMode(completedPanelData, mode, uniqueIDMap)
	);

	const EXT = getAudioExt();
	const { headerHeight } = useAppMeasurements();

	const { setState } = useBatchedSetState();

	const SOUND_URL = MEDIA_SERVER_URL + `assets/activi/writing/r180u_writing_edit_notiredwords_lintro.${EXT}`;

	const [isEditMode, setEditMode] = useState(false);
	const [reviewTextWithoutHighlight, setReviewTextWithoutHighlight] = useState(sortByMode(completedPanelData, mode, uniqueIDMap));
	const [tiredWordsMap, setTiredWordsMap] = useState([]);
	const [sentenceSenseList, setSentenceSenseList] = useState([]);

	const classes = useStyles({ headerHeight, isEditMode });

	const handleTextChange = text => {
		setState(() => {
			setReviewText(text);
			setReviewTextWithoutHighlight(text);
		});
	};

	const handleEdit = () => {
		setState(() => {
			setReviewText(reviewTextWithoutHighlight);
			setEditMode(!isEditMode);
		});
		if (isEditMode) {
			writingTools();
		}
	};

	const handleTiredWordSwap = (selectedTiredWordId, swapWord) => {
		let selectedTiredWordElement = document.getElementById(selectedTiredWordId);
		const newSwapWord = selectedTiredWordElement.textContent.charAt(0) === selectedTiredWordElement.textContent.charAt(0).toUpperCase() ? capitalizeWord(swapWord) : swapWord;
		if (selectedTiredWordElement.textContent) {
			setState(() => {
				setReviewText(reviewText.replace(`<span class="tiredWords" id=${selectedTiredWordId}>${selectedTiredWordElement.textContent}</span>`, newSwapWord));
				const updatedReviewText = updateReviewTextWithoutHighlight(reviewText, tiredWordsMap, selectedTiredWordId, newSwapWord)
				setReviewTextWithoutHighlight(updatedReviewText);
			});
		}
	}

	useEffect(() => {
		AudioPlayer2.loadSound(SOUND_URL);
	}, []);

	useEffect(() => {
		writingTools();
	}, [spellCheckEnabled, tiredWordsEnabled, sentenceSenseEnabled]);

	useEffect(() => {
		if (typeof onReviewPanelEdit === 'function') {
			onReviewPanelEdit(reviewTextWithoutHighlight);
		}
	}, [reviewTextWithoutHighlight]);

	const writingTools = () => {
		if (spellCheckEnabled) {
			setReviewText(spellCheck(writingToolsSpellCheckObj, reviewTextWithoutHighlight));
		}
		else if (tiredWordsEnabled) {
			const { updatedReviewText, tiredWordsMapList } = tiredWords(writingToolsTiredWordsObj, reviewTextWithoutHighlight);
			setState(() => {
				setTiredWordsMap(tiredWordsMapList);
				setReviewText(updatedReviewText);
			});
			if (tiredWordsMapList.length <= 0) {
				AudioPlayer2.stopAll();
				AudioPlayer2.play(SOUND_URL);
			}
		}
		else if (sentenceSenseEnabled) {
			const { updatedReviewText, shortestSentenceList } = sentenceSense(reviewTextWithoutHighlight, sentenceSenseList);
			setState(() => {
				setSentenceSenseList(shortestSentenceList);
				setReviewText(updatedReviewText);
			});
		}
		else {
			setReviewText(reviewTextWithoutHighlight);
		}
	}

	return (
		<div className={`${classes.bigWrapper} animate__animated animate__fadeIn`}>
			<div className={`${classes.editButtonWrapper} edit`}>
				<button data-testid={'reviewPanelEdit'} className="revise" onClick={handleEdit}>
					<i className={`r180 edit ${isEditMode ? 'hide' : ''}`} />
					<span>{isEditMode ? 'Done' : 'Edit'}</span>
				</button>
			</div>
			<div className={`${classes.wrapper}`}>
				{isEditMode ? (
					<MagicTextArea
						startText={reviewText}
						textAreaHeight={'400px'}
						textAreaWidth={'560px'}
						showLines={false}
						onTextChange={text => handleTextChange(text)}
						lineHeight={32}

					/>
				) : (
					<div
						className={`${classes.reviewTextWrapper}
						            ${spellCheckEnabled ? classes.spellCheckWrapper : ''}
									${tiredWordsEnabled ? classes.tiredWordsWrapper : ''}
									${sentenceSenseEnabled ? classes.sentenceSenseWrapper : ''}`
						}
						dangerouslySetInnerHTML={{ __html: reviewText }}>
					</div>
				)}

				<TiredWordsPopover
					writingToolsTiredWordsObj={writingToolsTiredWordsObj}
					onTiredWordSwap={handleTiredWordSwap} />
			</div>
		</div>
	);
};

export default React.memo(ReviewPanel);
