import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import SoundButton from '../../components/SoundButton';
import WordTitle from '../../components/WordTitle';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
	wordsContextProblemViewer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	rowContainer: {
		position: 'relative',
		margin: '20px 0',
		'&.row1': {
			'animationDelay': '0.3s'
		},
		'&.row2': {
			'animationDelay': '0.6s'
		},
		'&.row3': {
			'animationDelay': '0.9s'
		},
		'&.row4': {
			'animationDelay': '1.2s'
		}
	},
	contextProblems: {
		width: '65%',
		marginTop: '50px',
		'& .context-row': {
			borderBottom: `1px solid ${theme.colors.paleGrey}`
		},
		'& :last-child .context-row': {
			border: 'none'
		}
	},
	contextItems: {
		fontFamily: theme.fonts.context,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '18px',
		lineHeight: '32px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline',
		justifyContent: 'center',
		letterSpacing: '0.15px',
		color: theme.colors.black,
		margin: '10px 0'
	},
	itemText: {
		width: '65%'
	},
	iconsContainer: {
		alignSelf: 'flex-start',
		width: '30%',
		textAlign: 'right',
		'& :nth-child(2)': {
			marginRight: '0px'
		}
	},
	iconWrapper: {
		background: theme.colors.white,
		border: `solid 1px ${theme.colors.paleGrey}`,
		borderRadius: '8px',
		padding: '10px',
		margin: '0 10px 10px 10px',
		position: 'relative',
		height: '56px',
		width: '56px',
		outline: '4px',
		'& i': {
			color: theme.colors.skyBlue,
			fontSize: '20px',
			position: 'relative',
			'&.thumbs-up': {
				top: '0px'
			},
			'&.thumbs-down': {
				top: '3px'
			}
		},
		'&:disabled': {
			cursor: 'not-allowed'
		},
		'&:hover': {
            border: `2px solid ${theme.colors.fluencyZone.blue5}`,
            boxShadow: `0px 0px 6px 2px ${theme.colors.fluencyZone.blue5}`
        }
	},
	audioIcon: {
		width: '5%',
		position: 'relative',
		top: '1.5px',
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.2)'
		},
		'& div': {
			border: 'none',
			'& i': {
				fontSize: '16px',
				color: theme.colors.paleSlate
			}
		}
	},
	activeClass: {
		background: theme.colors.fluencyZone.blue5,
        borderColor: theme.colors.fluencyZone.blue3
	},
	feedBackIconWrapper: {
		position: 'absolute',
		top: '-10px',
		right: '-10px',
		'& .check': {
			width: '20px',
			height: '20px',
			borderRadius: '20px',
			color: theme.colors.white,
			fontSize: '12px',
			border: theme.colors.successGreen,
			background: theme.colors.successGreen,
			padding: '4px'
		},
		'& .x': {
			width: '20px',
			height: '20px',
			borderRadius: '20px',
			color: theme.colors.white,
			fontSize: '12px',
			border: theme.colors.errorRed,
			background: theme.colors.errorRed,
			padding: '4px'
		}
	}
}));

const WordContextProblemView = (props) => {
	const {id, wordCard, setBtnDisabled, setSeletedOptions,	showFeedBackIcons, selectedProblemsData, renderTime} = props;
	const classes = useStyles();
	const {setState} = useBatchedSetState();

	const [selectedProblems, setSelectedProblems] = useState(selectedProblemsData);
	const [problemsData, setProblemsData] = useState([]);

	useEffect(() => {
		setSelectedProblems(selectedProblemsData);
	}, [selectedProblemsData]);

	useEffect(() => {
		setProblemsData(wordCard.problemsData);
	}, [wordCard.problemsData]);

	useEffect(() => {
		AudioPlayer2.play(KnowledgeForReading.SOUND_WORD_FAM_TRIAL_INTRO);
	}, []);

	const handleOptions = (problemId, isSelected, choice) => {
		if (showFeedBackIcons) {
			return;
		}

		let updatedProblemData = problemsData;
		updatedProblemData.filter(problem => {
			if (problem.id === problemId) {
				problem.isSelected = isSelected;
				if (choice === 'isUpSelected') {
					problem.isUpSelected = true;
					problem.isDownSelected = false;
				} else {
					problem.isUpSelected = false;
					problem.isDownSelected = true;
				}
			}
			return problem;
		});
		let updateSelectedItems = selectedProblems;
		updateSelectedItems.push({problemId});

		updateSelectedItems = updateSelectedItems.filter(
			(item, index, self) =>
				index === self.findIndex(t => t.problemId === item.problemId)
		);

		setState(() => {
			if (updateSelectedItems.length === 4) {
				setBtnDisabled(false);
			}

			setProblemsData(updatedProblemData);
			setSeletedOptions(updatedProblemData);
			setSelectedProblems(updateSelectedItems);
		});
	};

	return (
		<div id={id} className={classes.wordsContextProblemViewer}>
			<WordTitle
				className="mt-3 mb-2 animate__animated animate__fadeIn"
				word={wordCard.power_word_focus}
				src={wordCard.power_word_focus_url}
			/>
			<div className={classes.contextProblems}>
				{problemsData.map((problem, index) => {
					const isAnswerSelected = problem.answer ? true : false;
					const isDistractorSelected = problem.distractor
						? true
						: false;

					return (
						<div
							key={problem.id + '-' + renderTime.current}
							className={`${classes.rowContainer} animate__animated animate__fadeIn row${index+1} `}
						>
							<div className={`${classes.contextItems} context-row`}>
								<span className={classes.audioIcon}>
									<SoundButton
										id={`words-context-${problem.text}`}
										size={26}
										sound={{
											name: problem.text,
											src: problem.text_url
										}}
									/>
								</span>
								<span
									className={classes.itemText}
									dangerouslySetInnerHTML={{
										__html: stripHtmlTags(problem.text)
									}}
								></span>
								<div
									className={`${
										classes.iconsContainer
									} ${showFeedBackIcons &&
										classes.disableStyle}`}
								>
									<button
										className={`${
											classes.iconWrapper
										} icon-thumbs-up ${
											problem.isUpSelected
												? classes.activeClass
												: ''
										}`}
										disabled={showFeedBackIcons}
										data-testid={'isUpSelected'}
										onClick={() =>
											handleOptions(
												problem.id,
												isAnswerSelected,
												'isUpSelected'
											)
										}
									>
										<i className={`r180 thumbs-up`} />
										{showFeedBackIcons ? (
											<span
												className={
													classes.feedBackIconWrapper
												}
											>
												{problem.answer &&
													problem.isSelected && (
														<i className="r180 check" />
													)}

												{problem.distractor &&
													!problem.isSelected && (
														<i className="r180 x" />
													)}
											</span>
										) : (
											''
										)}
									</button>
									<button
										className={`${
											classes.iconWrapper
										} icon-thumbs-down ${
											problem.isDownSelected
												? classes.activeClass
												: ''
										}`}
										disabled={showFeedBackIcons}
										data-testid={'isDownSelected'}
										onClick={() =>
											handleOptions(
												problem.id,
												isDistractorSelected,
												'isDownSelected'
											)
										}
									>
										<i className={`r180 thumbs-down`} />
										{showFeedBackIcons ? (
											<span
												className={
													classes.feedBackIconWrapper
												}
											>
												{problem.distractor &&
													problem.isSelected && (
														<i className="r180 check" />
													)}

												{problem.answer &&
													!problem.isSelected && (
														<i className="r180 x" />
													)}
											</span>
										) : (
											''
										)}
									</button>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

WordContextProblemView.defaultProps = {
	id: ''
};

WordContextProblemView.propTypes = {
	id: PropTypes.string,
	wordCard: PropTypes.object,
	setBtnDisabled: PropTypes.func,
	setSeletedOptions: PropTypes.func,
	showFeedBackIcons: PropTypes.bool,
	selectedProblemsData: PropTypes.array
};

export default React.memo(WordContextProblemView);
