import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ContextPassageSupportTray from './ContextPassageSupportTray';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		width: '648px',
		height: 'auto',
		background: 'transparent'
	},
	container: {
		width: 'auto',
		height: 'auto',
		margin: 'auto',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px 8px 0 0'
	},
	content: {
		padding: '0px',
		background: 'rgba(255, 255, 255, 0.6)',
		'&.showWhiteBg': { background: theme.colors.white },
		'& > div': {
			marginBottom: '24px'
		},
		'& .success-panel': {
			position: 'relative',
			left: '-76px'
		}
	}
}));

const ContextPassageContainer = props => {
	const {
		id,
		className,
		children,
		onInstructionClick,
		onVideoClick,
		showDraftPanel
	} = props;
	const classes = useStyles();

	return (
		<div
			id={id}
			className={`${classes.wrapper} ${className} success-panel-container`}
		>
			<div className={`${classes.container}`}>
				<ContextPassageSupportTray
					onInstructionClick={onInstructionClick}
					onVideoClick={onVideoClick}
					powerWords={[]}
				/>

				<div
					className={`${classes.content} ${showDraftPanel ? 'showWhiteBg' : ''
						}`}
				>
					{children}
				</div>
			</div>
		</div>
	);
};

ContextPassageContainer.defaultProps = {
	id: '',
	className: ''
};

ContextPassageContainer.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	onInstructionClick: PropTypes.func.isRequired,
	onVideoClick: PropTypes.func.isRequired
};

export default ContextPassageContainer;
