import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { shuffle, flatten, isEmpty } from 'lodash';
import api from '../../api/api';
import media from '../../api/media';
import { getContentInfo } from '../../media/mediaUtil';
import { getAudioExt } from '../../utils/audio';
import { MEDIA_SERVER_URL } from '../../utils/constants';
import { completeActivityAndGoToResultsPage } from '../../utils/navigation';
import { bulkReplace } from '../../utils/stringUtils';
import { loadSleep } from '../../utils/ui';

const EXT = getAudioExt();
const sequences = {
	1: ['S', 'S'],
	2: ['S', 'R'],
	3: ['S', 'R', 'R']
}

export const SpellingChallenge = {
	TRIAL_SIZE: 10,
	FLUENT: 'Fluent',
	MISSED: 'Missed',
	MAX_TRIAL_COUNT: 40,
	MAX_TRIAL_ROUND_COUNT: 4,
	MAX_INCORRECT_TRIALS: 3,
	HOMOPHONE_ATTEMPT_START_COUNT: 1,
	HOMOPHONE_ATTEMPT_MAX_COUNT: 3,

	DATATOSERVER_STATE_ZONE: 'zonereport',
	DATATOSERVER_STATE_TRIALROUND: 'trailround',

	SOUND_INTRO: `${MEDIA_SERVER_URL}assets/activi/spellchall/r180u_spellchall_intro.${EXT}`,
	SOUND_PAUSE: `${MEDIA_SERVER_URL}assets/activi/spellchall/r180u_spellchall_pause.${EXT}`,
	SOUND_CONTINUE: `${MEDIA_SERVER_URL}assets/activi/spellchall/r180u_spellchall_continue.${EXT}`,
	SOUND_FEEDBACK: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_feedback.${EXT}`,
	SOUND_WRONG: `${MEDIA_SERVER_URL}assets/activi/spellchall/r180u_spellchall_wrong.${EXT}`,
	SOUND_WRONG_HOMOPHONE: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_wrong_homophone.${EXT}`,
	SOUND_HELP_TRIAL: `${MEDIA_SERVER_URL}assets/activi/spellas/r180u_spellas_trialhelp.${EXT}`,
	SOUND_HELP_FEEDBACK: `${MEDIA_SERVER_URL}assets/activi/spellchall/r180u_spellchall_complete.${EXT}`,
	SOUND_HELP_RESULTS: `${MEDIA_SERVER_URL}assets/activi/spellchall/r180u_spellchall_resultshelp.${EXT}`,

	WORD_SOUND_URL: `${MEDIA_SERVER_URL}assets/dictio/word/r180u_word_{word}.${EXT}`,
	WORD_CTX_URL: `${MEDIA_SERVER_URL}assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,
	WORD_DECODE_URL: `content/dictio/word/r180u_content_`,

	SFX_CORRECT: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_correct.${EXT}`,
	SFX_INCORRECT: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_incorrect.${EXT}`,

	init: async (activityData, studyList, reviewList, isActivityCharged, settings) => {
		const start = new Date().getTime();

		//////////////
		// load the activity sounds
		//////////////
		AudioPlayer2.load({ name: 'intro', src: SpellingChallenge.SOUND_INTRO });
		AudioPlayer2.load({ name: 'pause', src: SpellingChallenge.SOUND_PAUSE });
		AudioPlayer2.load({ name: 'continue', src: SpellingChallenge.SOUND_CONTINUE });
		AudioPlayer2.load({ name: 'feedback', src: SpellingChallenge.SOUND_FEEDBACK });
		AudioPlayer2.load({ name: 'wrong', src: SpellingChallenge.SOUND_WRONG });
		AudioPlayer2.load({ name: 'wrong_homophone', src: SpellingChallenge.SOUND_WRONG_HOMOPHONE });
		AudioPlayer2.load({ name: 'help_trial', src: SpellingChallenge.SOUND_HELP_TRIAL });
		AudioPlayer2.load({ name: 'help_feedback', src: SpellingChallenge.SOUND_HELP_FEEDBACK });
		AudioPlayer2.load({ name: 'help_results', src: SpellingChallenge.SOUND_HELP_RESULTS });

		AudioPlayer2.load({ name: 'correct_spell', src: SpellingChallenge.SFX_CORRECT });
		AudioPlayer2.load({ name: 'incorrect_spell', src: SpellingChallenge.SFX_INCORRECT });

		const studyWords = [];
		const reviewWords = [];

		// load each of the words sounds and sentences
		await Promise.all(studyList.map(async(wordObj) => {
			const word = wordObj.wordId;
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(SpellingChallenge.WORD_SOUND_URL, constants);
			const word_ctx_url = bulkReplace(SpellingChallenge.WORD_CTX_URL, constants);
			// const word_decode_url = bulkReplace(SpellingChallenge.WORD_DECODE_URL, constants);

			// the pronounced word
			AudioPlayer2.load({ name: word, src: word_text_url });

			// the context
			AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });

			// the decoding info
			let decodingData = await media.getOne(`${SpellingChallenge.WORD_DECODE_URL}${word}.json`);

			studyWords.push({ ...wordObj, decodingInfo: decodingData['decodingInfo'] });
		}));

		await Promise.all(reviewList.map(async(wordObj) => {
			const word = wordObj.wordId;
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(SpellingChallenge.WORD_SOUND_URL, constants);
			const word_ctx_url = bulkReplace(SpellingChallenge.WORD_CTX_URL, constants);
			// const word_decode_url = bulkReplace(SpellingChallenge.WORD_DECODE_URL, constants);

			// the pronounced word
			AudioPlayer2.load({ name: word, src: word_text_url });

			// the context
			AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });

			// the decoding info
			let decodingData = await media.getOne(`${SpellingChallenge.WORD_DECODE_URL}${word}.json`);
			reviewWords.push({ ...wordObj, decodingInfo: decodingData['decodingInfo'] });
		}));

		const end = new Date().getTime();
		if (isActivityCharged === false) {
			await loadSleep(end - start);
		}

		return { studyWords, reviewWords };
	},

	getSpellWords: (recall = 1, studyList = [], reviewList = [], page = 0, trial = 10) => {
		const sequence = sequences[recall]
		const seqLen = sequence.length;
		const totalWords = [...studyList, ...reviewList].length;
		const [studyShuffled, reviewShuffled] = [shuffle(studyList), shuffle(reviewList)];

		const seqTimes = Math.floor(totalWords / seqLen) + Number(Boolean(totalWords % seqLen));
		const arrTemplate = flatten(new Array(seqTimes).fill(sequence));
		arrTemplate.length = totalWords;

		const wordsList = arrTemplate.map((item, i) => {
			if (item === 'S')
				return studyShuffled.splice(0, 1)[0]
			if (item === 'R')
				return reviewShuffled.splice(0, 1)[0]
		}).filter(item => item && item.wordId)

		return {
			trialWords: wordsList.slice((page * trial), trial),
			totalWords: wordsList
		};
	},

	loadNextRoundWordSound: (spellingWords, activityData) => {
		spellingWords.forEach(wordObj => {
			const word = wordObj.text;
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(SpellingChallenge.WORD_SOUND_URL, constants);
			const word_ctx_url = bulkReplace(SpellingChallenge.WORD_CTX_URL, constants);

			// the pronounced word
			AudioPlayer2.load({ name: word, src: word_text_url });

			// the context
			AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });
		});
	},

	// mark the item as complete, but don't navigate away from page
	completeActivityNoTransition: (activityData, recall, fluencyAssessment, fluencyAssessmentResponse) => {

		completeActivityAndGoToResultsPage({
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			},
			fluencyassessment: {
				studentSegmentId: fluencyAssessment.studentSegmentId,
				fluencyAssessmentZone: fluencyAssessment.fluencyAssessmentZone,
				recall: recall
			},
			fluencyassessmentresponse: {
				fluencyScores: fluencyAssessmentResponse,
				studentActivityId: activityData.studentActivityId,
				assessmentZone: fluencyAssessment.fluencyAssessmentZone
			}
		});
	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendProgressToServer: (activityData, recall, fluencyAssessment, fluencyAssessmentResponse) => {
		if (isEmpty(activityData)) {
			return;
		}

		const savedActivityData = {
			activity: {
				isComplete: false,
				studentActivityId: activityData.studentActivityId
			},
			fluencyassessment: {
				studentSegmentId: fluencyAssessment.studentSegmentId,
				fluencyAssessmentZone: fluencyAssessment.fluencyAssessmentZone,
				recall: recall
			},
			fluencyassessmentresponse: {
				fluencyScores: fluencyAssessmentResponse,
				studentActivityId: activityData.studentActivityId,
				assessmentZone: fluencyAssessment.fluencyAssessmentZone
			}
		};
		api.activity.updateActivity(savedActivityData);
	}
};
