import React, { useEffect, useState } from 'react';
import { useStyles } from './ContextPassage.style';
import { keyAndClick } from '../../util/ui-a11y';
import { MenuItem, Select } from '@material-ui/core';
import { cloneDeep, shuffle } from 'lodash';
import CorrectIncorrect from '../../components/CorrectIncorrect/CorrectIncorrect';

const ContextPassage = props => {
	const {
		passageString,
		contentData,
		title,
		setCardWord,
		preSelectValue,
		preTrailValidationMap,
		onSelectChange,
		prevSelection
	} = props;

	const { omissions } = contentData;
	const [activeWord, setActiveWord] = useState({ word: false, uid: false });
	const [selectValue, setSelectValue] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		setSelectValue(preSelectValue);
	}, [preSelectValue, preTrailValidationMap]);

	const handleChange = (event, id, omissionCount, target) => {
		const isCorrect = event.target.value === target ? true : false;
		let newSelectValue = cloneDeep(selectValue);
		newSelectValue[id - 1] = event.target.value;
		setSelectValue(newSelectValue);
		if (typeof onSelectChange === 'function') {
			onSelectChange(newSelectValue, id, isCorrect);
		}
	};

	const wordClick = ({ word, uid, dataId, startIndex, endIndex }) => {
		if (word === activeWord.word && uid === activeWord.uid && setCardWord) {
			setCardWord({ word: dataId, uid });
		} else {
			setActiveWord({ word, uid });
		}
	};

	const checkPrevSelection = (prevSelection, item) => {
		let isPrevSelect = false;
		if (prevSelection) {
			isPrevSelect = prevSelection.includes(item);
		}
		return isPrevSelect;
	};

	let wordId = 0;
	let phraseCount = 0;
	let letterIndex = 0;
	let paragraphCount = 1;
	let omissionCount = -1;
	const parseNode = (node, highlightFeedbackWord = false) => {
		if (node.nodeName === 'WORD') {
			const isOmmision = node.getAttribute('data-type');
			if (isOmmision === 'omission') {
				omissionCount++;
				const { distractors, id, target } = omissions[omissionCount];
				const newDistractors = [...distractors, ...[target]];
				const shuffledDistractors = shuffle(newDistractors.map((text, i) => { return text.split('_')[0] }));
				return preTrailValidationMap[omissionCount] === true ||
					preTrailValidationMap[omissionCount] === false ? (
					<div
						className={`${classes.correctIncorrectWrapper} ${preTrailValidationMap[omissionCount]
							? 'correct'
							: 'incorrect'
							}`}
					>
						<div className={classes.correctIncorrectTarget}>
							{target}
						</div>
						<div className={classes.correctIncorrectIcon}>
							<CorrectIncorrect
								correct={
									preTrailValidationMap[omissionCount]
										? true
										: false
								}
							/>
						</div>
					</div>
				) : (
					<Select
						data-testid={'select-control'}
						labelId="customized-select-label"
						id={id}
						value={
							selectValue[omissionCount]
								? selectValue[omissionCount]
								: ' '
						}
						onChange={e =>
							handleChange(e, id, omissionCount, target)
						}
						disableUnderline={true}
						className={`${classes.selectControl} ${selectValue[omissionCount]
							? classes.selectClass
							: ''
							}`}
					>
						{shuffledDistractors.map((item, idx) => (
							<MenuItem
								className={`${classes.selectItem} ${checkPrevSelection(
									prevSelection[omissionCount],
									item
								)
									? classes.preSelect
									: ''
									} `}
								disabled={checkPrevSelection(
									prevSelection[omissionCount],
									item
								)}
								value={item}
							>
								<div style={{ width: '120px' }}>{item}</div>
								{checkPrevSelection(
									prevSelection[omissionCount],
									item
								) && (
										<CorrectIncorrect
											correct={false}
											style={{ backgroundColor: '#63757E' }}
										/>
									)}
							</MenuItem>
						))}
					</Select>
				);
			} else {
				const startIndex = letterIndex;
				letterIndex += node.innerHTML.length;
				const endIndex = letterIndex;
				const uid = wordId++;
				const word = node.innerHTML;
				const dataId = node.getAttribute('data-id');
				let wordClasses = node.getAttribute('class');
				if (wordClasses) {
					wordClasses = wordClasses.split(' ');
				} else {
					wordClasses = [];
				}
				if (highlightFeedbackWord) {
					wordClasses.push('feedback');
				} else {
					wordClasses = wordClasses.filter(
						item => item !== 'feedback'
					);
				}
				return (
					<Word
						key={`word${uid} `}
						onClick={wordClick}
						word={word}
						dataId={dataId}
						activeWord={activeWord}
						uid={uid}
						wordClasses={wordClasses}
						startIndex={startIndex}
						endIndex={endIndex}
					/>
				);
			}
		}
		if (node.nodeName === 'PUNC') {
			letterIndex += node.innerHTML.length;
			const punctClasses = [];
			return (
				<Punc
					punc={node.innerHTML}
					key={`punc${letterIndex} `}
					punctClasses={punctClasses}
				/>
			);
		}
		if (node.nodeName === 'SPACE') {
			letterIndex += node.innerHTML.length;
			const spaceClasses = [];
			return (
				<Space
					key={`space${letterIndex} `}
					spaceClasses={spaceClasses}
				/>
			);
		}
		if (node.nodeName === 'PHRASE') {
			phraseCount++;
			return (
				<Phrase index={phraseCount} key={`phrase${phraseCount} `}>
					{Array.from(node.children).map(node =>
						parseNode(node, highlightFeedbackWord)
					)}
				</Phrase>
			);
		}
		if (node.nodeName === 'PARAGRAPH') {
			return (
				<Paragraph
					index={paragraphCount++}
					key={`para${paragraphCount} `}
				>
					{Array.from(node.children).map(node => parseNode(node))}
				</Paragraph>
			);
		}
		if (node.nodeName === 'HEADERTEXT') {
			return (
				<HeaderText>
					{Array.from(node.children).map(node => parseNode(node))}
				</HeaderText>
			);
		}
	};
	const parser = new DOMParser();
	var omissionString = new RegExp('"omission"/>', 'g');
	let modifiedPassageString = passageString.replace(
		omissionString,
		'"omission">OMISSION</word>'
	);
	const passageData = Array.from(
		parser.parseFromString(modifiedPassageString, 'text/html')
			.documentElement.children[1].children[0].children
	);

	const parsedPassage = passageData.map(node => parseNode(node));

	return (
		<div className={`${classes.wrapper} `}>
			<div className={`${classes.textTitle} `} role={'heading'}>
				{title}
			</div>
			<div
				className={`${classes.scrollContainer} `}
				data-id={'scrollContainer'}
			>
				<div className={`${classes.textBody} `}>{parsedPassage}</div>
			</div>
		</div>
	);
};

const Paragraph = props => {
	const { children, index } = props;
	const classes = useStyles();
	return (
		<div className={classes.paragraph}>
			<div className={classes.paragraphNumber}>{index}.</div>
			<div className={classes.paragraphIndent}></div>
			{children}
		</div>
	);
};

const HeaderText = props => {
	const { children } = props;
	const classes = useStyles();
	return (
		<div className={classes.header} role={'heading'}>
			{children}
		</div>
	);
};
const Phrase = props => {
	const { children, index } = props;
	const classes = useStyles();
	return (
		<div
			className={classes.phrase}
			data-phraseindex={index}
			data-type="phrase"
		>
			{children}
		</div>
	);
};
/*
	
 */
const Word = props => {
	const {
		word,
		dataId,
		onClick,
		uid,
		activeWord,
		wordClasses,
		startIndex,
		endIndex
	} = props;
	const classes = useStyles();
	return (
		<div
			dangerouslySetInnerHTML={{ __html: word }}
			{...keyAndClick(() => {
				onClick({ word, uid, dataId, startIndex, endIndex });
			})}
			className={`${classes.word} ${activeWord.word === word &&
				activeWord.uid === uid &&
				classes.wordHighlighted} ${wordClasses.indexOf('bold') > -1 &&
				classes.wordBold} ${wordClasses.indexOf('italic') > -1 &&
				classes.wordItalic} ${wordClasses.indexOf('underline') > -1 &&
				classes.wordUnderline} ${wordClasses.indexOf('feedback') > -1 &&
				classes.feedbackWord} ${wordClasses.indexOf(
					'passageQuestionHighlight'
				) > -1 && classes.passageQuestionHighlight} ${wordClasses.indexOf(
					'applyBackground'
				) > -1 && classes.passageQuestionHighlightBackground} `}
			data-dataid={dataId}
			data-uid={uid}
			data-type="word"
		></div>
	);
};

const Punc = props => {
	const { punc, punctClasses } = props;
	const classes = useStyles();
	return (
		<div
			className={`${classes.punc} ${punctClasses.indexOf(
				'applyBackground'
			) > -1 && classes.passageQuestionHighlightBackground} `}
		>
			{punc}
		</div>
	);
};

const Space = props => {
	const { spaceClasses } = props;
	const classes = useStyles();
	return (
		<div
			className={`${classes.space} ${spaceClasses.indexOf(
				'applyBackground'
			) > -1 && classes.passageQuestionHighlightBackground} `}
		>
			{' '}
		</div>
	);
};
export default ContextPassage;
