import { transitionToNextActivity } from "../../utils/navigation";
import api from '../../api/api';

export const LanguageZone = {
	COMPLETE_THRESHOLD: 0.75,

	completeActivity: async(history, activityData, wordId, correctLength, questionsLength) => {
		await transitionToNextActivity(history, {
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			},
			wordperformance: {
				wordId: wordId,
				correct: correctLength,
				total: questionsLength,
				studentActivityId: activityData.studentActivityId
			}
		});
	},
	sendProgressToServer: (activityData, wordId, correctCount, questionsCount, responsesToServer) => {
		const savedActivityData = {
			activity: {
				isComplete: false,
				studentActivityId: activityData.studentActivityId
			},
			wordperformance: {
				wordId: wordId,
				correct: correctCount,
				total: questionsCount,
				responses: responsesToServer,
				studentActivityId: activityData.studentActivityId
			}
		};
		api.activity.updateActivity(savedActivityData);
	}
}
