import React from 'react';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import useRouteInfo from '@reading/r180/src/utils/useRouteInfo';
import {CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
	progressWrapper: {
		margin: 'auto',
		marginTop: '5em',
		textAlign: 'center',
		position: 'relative',
		width: 180,
		height: 180
	},
	suspenseContainer: {},
	spinner: {
		margin: 'auto',
		zIndex: 10,
		'& svg': {
			color: theme.colors.white
		},
		'&.MuiCircularProgress-indeterminate': {
			animationDuration: '6s'
		}
	},
	center: {
		position: 'absolute',
		left: 10,
		top: 10,
		height: 160,
		width: 160,
		borderRadius: '50%',
		'& i': {
			position: 'relative',
			top: 30,
			fontSize: 100,
			color: theme.colors.white
		}
	},
	declaration: {
		position: 'relative',
		width: '80%',
		left: '10%',
		textAlign: 'center',
		fontSize: 64,
		fontWeight: 700,
		color: theme.colors.white,
		letterSpacing: '0.04em',
		marginTop: 30,
		marginBottom: 30,
		paddingBottom: 30,
		borderBottom: `1px solid ${theme.colors.white}`
	},
	activityName: {
		textAlign: 'center',
		fontSize: 36,
		fontWeight: 700,
		color: theme.colors.white,
		letterSpacing: '0.04em'
	}
}));

const ActivitySuspense = (props) => {
	const {showSpinner, requiredRenderData, title, children, style, message} = props;
	let {zone} = useRouteInfo();
	if (isEmpty(zone)) {
		zone = 'explore';
	}
	const classes = useStyles();

	const isTransitioning = useSelector(state => {
		return state && state.ui && state.ui.isTransitioning;
	});

	let isRenderable = true;
	if (Array.isArray(requiredRenderData)) {
		requiredRenderData.forEach(data => {
			isRenderable = isRenderable && isEmpty(data) === false;
		});
	} else {
		isRenderable = isEmpty(requiredRenderData) === false;
	}

	return showSpinner || isTransitioning ? (
		<>
			<div data-testid="isRenderableNotPresent" className={`${classes.progressWrapper}`}>
				<CircularProgress className={`${classes.spinner}`} size={180} />
				<div className={`${classes.center}`}>
					<i className={`r180 ${zone}`} />
				</div>
			</div>
			<div className={`${classes.declaration}`}>{!isEmpty(message) ? message : "Let's Go!"}</div>
			<div className={`${classes.activityName}`}>{isTransitioning ? '' : title}</div>
		</>
	) : (
		<div data-testid="isRenderablePresent" className={`${classes.suspenseContainer}`} style={style}>
			{isRenderable ? children : null}
		</div>
	);
}

ActivitySuspense.defaultProps = {
	message: ''
};
ActivitySuspense.propTypes = {
	message: PropTypes.string
};

export default React.memo(ActivitySuspense);
