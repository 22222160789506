/* istanbul ignore file */
const constants = {
	DEFAULT_HIGHLIGHT_DURATION_MS: 500,
	TARGET_HIGHLIGHT_DELAY_MS: 1000,
	INTER_SEGMENT_DELAY_MS: 350,
	INTRA_EFFECT_DELAY_MS: 400,
	DELAY_BETWEEN_ANIMATED_LETTER_CHANGING_MS: 250,
	DELAY_BETWEEN_HIGHLIGHT_AND_WORD_MS: 250,
	DELAY_BETWEEN_HOST_AND_WORD_MS: 350,
	DELAY_BETWEEN_LETTERS_MS: 250,
	DEFAULT_SPACE_WIDTH_PX: 14,
	MAX_VALID_TIPCODE: 33
};

constants.events = {
	INLINE_INIT: 'r180uInlineDecodingTipInit',
	INLINE_READY: 'r180uInlineDecodingTipReady'
};

constants.cleaningModes = {
	ALL: 'all',
	TARGET: 'target',
	DIGRAPH: 'digraph',
	HIGHLIGHT: 'highlight',
	REMOVAL: 'removal',
	ADDITION: 'addition',
	SOFT_SEGMENTS: 'soft_segments',
	PASSAGE: 'passage',
	MORPHEME: 'morpheme',
	SILENT: 'silent'
};

constants.animationSequence2ElementMap = {
	ab: 'ab_2',
	ace: 'ace',
	ack: 'ack',
	act: 'act_2',
	ad: 'ad_2',
	ade: 'ade_2',
	aft: 'aft',
	ag: 'ag',
	age: 'age_2',
	aid: 'aid',
	ail: 'ail',
	ain: 'ain',
	aint: 'aint',
	air: 'air_2',
	ait: 'ait',
	ake: 'ake',
	ale: 'ale',
	alk: 'alk',
	all: 'all',
	am: 'am',
	ame: 'ame',
	amp: 'amp',
	an: 'an',
	ance: 'ance',
	and: 'and',
	ane: 'ane',
	ang: 'ang',
	ange: 'ange',
	ank: 'ank',
	ant: 'ant_2',
	ap: 'ap',
	ape: 'ape',
	ar: 'ar_2',
	ard: 'ard',
	are: 'are_2',
	ark: 'ark',
	arm: 'arm_2',
	arp: 'arp',
	art: 'art_2',
	ase: 'ase',
	ash: 'ash',
	ask: 'ask',
	asp: '',
	ast: 'ast',
	aste: 'aste',
	at: 'at_3',
	atch: 'atch',
	ate: 'ate',
	aught: 'aught',
	aunch: 'aunch',
	ave: 'ave',
	aw: 'aw',
	awl: 'awl',
	awn: 'awn',
	ax: '',
	ay: 'ay',
	aze: 'aze',
	each: 'each_2',
	ead: 'ead',
	eak: 'eak',
	eal: 'eal',
	eam: 'eam',
	ean: 'ean',
	eap: 'eap',
	ear: 'ear_2',
	ease: 'ease',
	east: 'east',
	eat: 'eat',
	eave: 'eave',
	eck: 'eck',
	ed: 'ed_3',
	edge: 'edge_3',
	eech: 'eech',
	eed: 'eed',
	eek: 'eek',
	eel: 'eel',
	een: 'een',
	eep: 'eep',
	eer: 'eer',
	eet: 'eet',
	eeze: 'eeze',
	eg: 'eg',
	eigh: 'eigh',
	ell: 'ell',
	elt: 'elt',
	em: 'em',
	en: 'en_2',
	ence: 'ence',
	ench: '',
	end: 'end_2',
	ent: 'ent',
	ept: 'ept',
	ess: 'ess',
	est: 'est',
	et: 'et',
	etch: 'etch',
	ew: 'ew',
	ext: 'ext',
	ib: '',
	ice: 'ice',
	ick: 'ick',
	id: 'id',
	ide: 'ide',
	ife: 'ife',
	iff: 'iff',
	ift: 'ift',
	ig: 'ig',
	igh: 'igh',
	ight: 'ight',
	ike: 'ike',
	ile: 'ile',
	ill: 'ill',
	ilt: 'ilt',
	im: 'im',
	ime: 'ime',
	imp: 'imp',
	in: 'in_2',
	inch: 'inch',
	ind: 'ind',
	ine: 'ine_2',
	ing: 'ing',
	inge: '',
	ink: 'ink',
	int: 'int',
	ip: 'ip',
	ipe: 'ipe',
	ire: 'ire',
	irl: 'irl',
	irt: 'irt',
	isk: 'isk',
	iss: 'iss',
	ist: 'ist',
	it: 'it',
	itch: 'itch',
	ite: 'ite_2',
	ive: 'ive',
	ix: 'ix',
	oach: 'oach',
	oar: '',
	oat: 'oat',
	ob: 'ob',
	obe: 'obe',
	ock: 'ock',
	od: 'od_2',
	ode: 'ode',
	og: 'og',
	oil: 'oil',
	oin: 'oin',
	oke: 'oke',
	old: 'old',
	ole: 'ole',
	oll: 'oll',
	olt: 'olt',
	omp: 'omp',
	ond: 'ond',
	one: 'one_3',
	ong: 'ong_2',
	ood: 'ood',
	oof: 'oof_2',
	ook: 'ook',
	ool: 'ool',
	oom: 'oom',
	oon: 'oon',
	oop: 'oop',
	oose: 'oose_2',
	oot: 'oot',
	op: 'op',
	ope: 'ope',
	orch: 'orch',
	ord: 'ord',
	ore: 'ore',
	ork: 'ork',
	orm: 'orm',
	orn: 'orn',
	ort: 'ort',
	ose: 'ose',
	oss: 'oss',
	ot: 'ot',
	otch: '',
	ote: 'ote',
	ouch: 'ouch',
	oud: 'oud',
	ought: 'ought',
	ould: 'ould',
	ounce: 'ounce',
	ound: 'ound',
	ouse: 'ouse',
	out: 'out',
	ove: 'ove_4',
	ow: 'ow_2',
	owl: 'owl',
	own: 'own',
	ox: 'ox',
	oy: 'oy',
	ub: 'ub',
	ube: 'ube',
	uck: 'uck',
	ud: 'ud',
	ude: 'ude',
	udge: 'udge',
	uff: 'uff',
	ug: 'ug',
	uke: '',
	ule: 'ule',
	ull: 'ull_2',
	um: 'um',
	umb: 'umb',
	ump: 'ump',
	un: 'un',
	unch: 'unch',
	une: 'une',
	ung: 'ung',
	unk: 'unk',
	unt: 'unt',
	ure: 'ure',
	urn: 'urn',
	urt: 'urt',
	use: '',
	ush: 'ush',
	ust: 'ust',
	ut: 'ut',
	utch: 'utch',
	ute: 'ute',
	y: 'i_2'
};

constants.audio = {
	BASE_CHANGE_1: '610sv101',
	BASE_CHANGE_2: '610sv102',
	BASE_CHANGE_3: '610sv103',
	BASE_CHANGE_4: '610sv104',
	BASE_CHANGE_5: '610sv105',

	TIP_AUDIO_PREFIX: '610sv',
	TIP_1_WORD_ZONE_AUDIO_1: '610sv001',
	TIP_1_WORD_ZONE_AUDIO_2: '610sv002',
	TIP_1_SPELLING_ZONE_AUDIO_1: '610sv001',
	TIP_1_SPELLING_ZONE_AUDIO_2: '610sv501',
	TIP_2_WORD_ZONE_AUDIO_1: '610sv003',
	TIP_2_WORD_ZONE_AUDIO_2: '610sv002',
	TIP_2_SPELLING_ZONE_AUDIO_1: '610sv003',
	TIP_2_SPELLING_ZONE_AUDIO_2: '610sv501',
	TIP_3_WORD_ZONE_AUDIO_1: '610sv005',
	TIP_3_WORD_ZONE_AUDIO_2: '610sv006',
	TIP_3_SPELLING_ZONE_AUDIO_1: '610sv502',
	TIP_3_SPELLING_ZONE_AUDIO_2: '610sv503',
	TIP_4_WORD_ZONE_AUDIO_1: '610sv026',
	TIP_4_WORD_ZONE_AUDIO_2: '610sv027',
	TIP_4_SPELLING_ZONE_AUDIO_1: '610sv504a',
	TIP_4_SPELLING_ZONE_AUDIO_2: '610sv504b',
	TIP_5_WORD_ZONE_AUDIO_1: '610sv028',
	TIP_5_WORD_ZONE_AUDIO_2: '610sv029',
	TIP_5_SPELLING_ZONE_AUDIO_1: '610sv028',
	TIP_5_SPELLING_ZONE_AUDIO_2: '610sv506',
	TIP_6_WORD_ZONE_AUDIO_1: '610sv030',
	TIP_6_WORD_ZONE_AUDIO_2: '610sv031',
	TIP_6_SPELLING_ZONE_AUDIO_1: '610sv030',
	TIP_6_SPELLING_ZONE_AUDIO_2: '610sv507',
	TIP_7_WORD_ZONE_AUDIO_1: '610sv032',
	TIP_7_WORD_ZONE_AUDIO_2: '610sv033',
	TIP_7_SPELLING_ZONE_AUDIO_1: '610sv032',
	TIP_7_SPELLING_ZONE_AUDIO_2: '610sv508',
	TIP_8_WORD_ZONE_AUDIO_1: '610sv030',
	TIP_8_WORD_ZONE_AUDIO_2: '610sv035',
	TIP_8_SPELLING_ZONE_AUDIO_1: '610sv030',
	TIP_8_SPELLING_ZONE_AUDIO_2: '610sv510',
	TIP_9_WORD_ZONE_AUDIO_1: '610sv030',
	TIP_9_WORD_ZONE_AUDIO_2: '610sv035',
	TIP_9_SPELLING_ZONE_AUDIO_1: '610sv030',
	TIP_9_SPELLING_ZONE_AUDIO_2: '610sv510',
	TIP_10_WORD_ZONE_AUDIO_1: '610sv028',
	TIP_10_WORD_ZONE_AUDIO_2: '610sv036',
	TIP_10_SPELLING_ZONE_AUDIO_1: '610sv028',
	TIP_10_SPELLING_ZONE_AUDIO_2: '610sv511',

	TIP_11_WORD_ZONE_AUDIO_1: '610sv032',
	TIP_11_WORD_ZONE_AUDIO_2_VARIATION_1: '610sv035',
	TIP_11_WORD_ZONE_AUDIO_2_VARIATION_2: '610sv037',
	TIP_11_SPELLING_ZONE_AUDIO_1: '610sv032',
	TIP_11_SPELLING_ZONE_AUDIO_2_VARIATION_1: '610sv512',
	TIP_11_SPELLING_ZONE_AUDIO_2_VARIATION_2: '610sv513',

	TIP_12_AUDIO_1: '610sv004',
	TIP_12_AUDIO_2: '610sv038',
	TIP_12_VARIATION_2_1: '610sv042',
	TIP_12_VARIATION_2_2: '610sv041',
	TIP_12_VARIATION_2_3: '610sv040',
	TIP_12_VARIATION_2_4: 'tip12var2d',
	TIP_12_VARIATION_2_5: 'tip12var2e',
	TIP_12_VARIATION_2_6: 'tip12var2f',
	TIP_12_VARIATION_2_7: 'tip12var2g',
	TIP_12_VARIATION_2_8: 'tip12var2h',
	TIP_12_VARIATION_2_9: 'tip12var2i',

	TIP_12_WORD_ZONE_AUDIO_1: '610sv004',
	TIP_12_WORD_ZONE_AUDIO_2: '610sv038',
	TIP_12_WORD_ZONE_AUDIO_3: '610sv039', //ed
	TIP_12_WORD_ZONE_AUDIO_4: '610sv043', // ing
	TIP_12_WORD_ZONE_AUDIO_5: '610sv044', //s
	TIP_12_WORD_ZONE_AUDIO_6: '610sv045', //es
	TIP_12_WORD_ZONE_AUDIO_VARIATION_2_1: '610sv042',
	TIP_12_WORD_ZONE_AUDIO_VARIATION_2_2: '610sv041',
	TIP_12_WORD_ZONE_AUDIO_VARIATION_2_3: '610sv040',
	TIP_12_WORD_ZONE_AUDIO_VARIATION_2_4: 'tip12var2d',
	TIP_12_WORD_ZONE_AUDIO_VARIATION_2_5: 'tip12var2e',
	TIP_12_WORD_ZONE_AUDIO_VARIATION_2_6: 'tip12var2f',
	TIP_12_WORD_ZONE_AUDIO_VARIATION_2_7: 'tip12var2g',
	TIP_12_WORD_ZONE_AUDIO_VARIATION_2_8: 'tip12var2h',
	TIP_12_WORD_ZONE_AUDIO_VARIATION_2_9: 'tip12var2i',

	TIP_13_WORD_ZONE_AUDIO_1: '610sv004',
	TIP_13_WORD_ZONE_AUDIO_2: '610sv038',
	TIP_13_SPELLING_ZONE_AUDIO_1: '610sv004',
	TIP_13_SPELLING_ZONE_AUDIO_2: '610sv038',
	TIP_13_WORD_ZONE_AUDIO_3: '610sv039', //ed
	TIP_13_WORD_ZONE_AUDIO_4: '610sv043', // ing
	TIP_13_WORD_ZONE_AUDIO_5: '610sv045', // es

	TIP_14_AUDIO_1: '610sv004',
	TIP_14_AUDIO_2: '610sv046',

	TIP_14_WORD_ZONE_AUDIO_1: '610sv004',
	TIP_14_WORD_ZONE_AUDIO_2: '610sv046',
	TIP_14_WORD_ZONE_AUDIO_3: '610sv047', // un
	TIP_14_WORD_ZONE_AUDIO_4: '610sv048', // re
	TIP_14_WORD_ZONE_AUDIO_5: '610sv061', // trans
	TIP_14_WORD_ZONE_AUDIO_6: '610sv069', // il
	TIP_14_WORD_ZONE_AUDIO_7: '610sv071', // im
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_1: '610sv347',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_2: '610sv348',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_3: '610sv361',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_4: '610sv369',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_5: '610sv371',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_6: 'tip12var2e',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_7: 'tip12var2f',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_8: 'tip12var2g',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_9: 'tip12var2h',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_10: 'tip12var2i',
	TIP_14_WORD_ZONE_AUDIO_8: '610sv049', // in
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_11: '610sv349',
	TIP_14_WORD_ZONE_AUDIO_9: '610sv050', // dis
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_12: '610sv350',
	TIP_14_WORD_ZONE_AUDIO_10: '610sv051', // en
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_13: '610sv351',
	TIP_14_WORD_ZONE_AUDIO_11: '610sv052', // non
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_14: '610sv352',
	TIP_14_WORD_ZONE_AUDIO_12: '610sv053', // in
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_15: '610sv353',
	TIP_14_WORD_ZONE_AUDIO_13: '610sv054', // over
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_16: '610sv354',
	TIP_14_WORD_ZONE_AUDIO_14: '610sv055', // mis
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_17: '610sv355',
	TIP_14_WORD_ZONE_AUDIO_15: '610sv056', // sub
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_18: '610sv356',
	TIP_14_WORD_ZONE_AUDIO_16: '610sv057', // pre
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_19: '610sv357',
	TIP_14_WORD_ZONE_AUDIO_17: '610sv058', //inter
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_20: '610sv358',
	TIP_14_WORD_ZONE_AUDIO_18: '610sv059',
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_21: '610sv359',
	TIP_14_WORD_ZONE_AUDIO_19: '610sv060', //de
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_22: '610sv360',
	TIP_14_WORD_ZONE_AUDIO_20: '610sv062', //
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_23: '610sv362',
	TIP_14_WORD_ZONE_AUDIO_21: '610sv063', // semi
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_24: '610sv363',
	TIP_14_WORD_ZONE_AUDIO_22: '610sv064', // anti
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_25: '610sv364',
	TIP_14_WORD_ZONE_AUDIO_23: '610sv065', // mid
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_26: '610sv365',
	TIP_14_WORD_ZONE_AUDIO_24: '610sv066', // under
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_27: '610sv366',
	TIP_14_WORD_ZONE_AUDIO_25: '610sv067', // im
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_28: '610sv367',
	TIP_14_WORD_ZONE_AUDIO_26: '610sv068', //
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_29: '610sv368',
	TIP_14_WORD_ZONE_AUDIO_27: '610sv070', // em
	TIP_14_WORD_ZONE_AUDIO_VARIATION_2_30: '610sv370',

	TIP_15_WORD_ZONE_AUDIO_1: '610sv004',
	TIP_15_WORD_ZONE_AUDIO_2: '610sv046',

	TIP_15_AUDIO_1: '610sv004',
	TIP_15_AUDIO_2: '610sv046',

	TIP_16_AUDIO_1: '610sv004',
	TIP_16_AUDIO_2: '610sv072',
	TIP_16_VARIATION1_19: 'tip16var19a',
	TIP_16_VARIATION2_19: 'tip16var19b',
	TIP_16_VARIATION1_20: 'tip16var20a',
	TIP_16_VARIATION2_20: 'tip16var20b',
	TIP_16_VARIATION1_21: 'tip16var21a',
	TIP_16_VARIATION2_21: 'tip16var21b',

	TIP_16_WORD_ZONE_AUDIO_1: '610sv004',
	TIP_16_WORD_ZONE_AUDIO_2: '610sv072',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_1: '610sv073', //ly
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_1: '610sv373',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_2: '610sv074', //er
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_2: '610sv374',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_3: '610sv075', //ion
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_3: '610sv375',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_4: '610sv076', //tion
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_4: '610sv376',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_5: '610sv077', //ation
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_5: '610sv377',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_6: '610sv078', //ition
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_6: '610sv378',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_7: '610sv079', //ible
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_7: '610sv379',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_8: '610sv080', //able
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_8: '610sv380',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_9: '610sv081', //al
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_9: '610sv381',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_10: '610sv082', //ial
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_10: '610sv382',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_11: '610sv083', //y
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_11: '610sv383',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_12: '610sv084', //ness
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_12: '610sv384',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_13: '610sv085', //ty
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_13: '610sv385',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_14: '610sv086', // ment
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_14: '610sv386',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_15: '610sv087', // ic
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_15: '610sv387',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_16: '610sv088', // ous
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_16: '610sv388',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_17: '610sv089', // eous
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_17: '610sv389',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_18: '610sv090', // en
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_18: '610sv390',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_19: '610sv091', // er
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_19: '610sv391',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_20: '610sv092', // ive - varCode
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_20: '610sv392', // suffix makes into adjective.
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_21: '610sv093', // ative
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_21: '610sv393',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_22: '610sv094', // itive
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_22: '610sv394',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_23: '610sv095', // ful
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_23: '610sv395',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_24: '610sv096', //less
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_24: '610sv396',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_25: '610sv097', //est
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_25: '610sv397',
	TIP_16_WORD_ZONE_AUDIO_VARIATION1_26: '610sv098', //or
	TIP_16_WORD_ZONE_AUDIO_VARIATION2_26: '610sv398',

	TIP_17_AUDIO_1: '610sv004',
	TIP_17_AUDIO_2: '610sv072',

	TIP_17_WORD_ZONE_AUDIO_1: '610sv004',
	TIP_17_WORD_ZONE_AUDIO_2: '610sv072',

	TIP_18_WORD_ZONE_AUDIO_1: '610sv004',
	TIP_18_WORD_ZONE_AUDIO_2: '610sv072',
	TIP_18_SPELLING_ZONE_AUDIO_1: '610sv004',
	TIP_18_SPELLING_ZONE_AUDIO_2: '610sv072',
	TIP_18_WORD_ZONE_AUDIO_VARIATION_1: '610sv073', //ly
	TIP_18_WORD_ZONE_AUDIO_VARIATION_2: '610sv074', //er
	TIP_18_WORD_ZONE_AUDIO_VARIATION_3: '610sv075', // ion
	TIP_18_WORD_ZONE_AUDIO_VARIATION_4: '610sv076', // tion
	TIP_18_WORD_ZONE_AUDIO_VARIATION_5: '610sv077', // ation
	TIP_18_WORD_ZONE_AUDIO_VARIATION_6: '610sv078', // ition
	TIP_18_WORD_ZONE_AUDIO_VARIATION_7: '610sv079', // ible
	TIP_18_WORD_ZONE_AUDIO_VARIATION_8: '610sv080', // able
	TIP_18_WORD_ZONE_AUDIO_VARIATION_9: '610sv081', // al
	TIP_18_WORD_ZONE_AUDIO_VARIATION_10: '610sv082', // ial
	TIP_18_WORD_ZONE_AUDIO_VARIATION_11: '610sv083', // y
	TIP_18_WORD_ZONE_AUDIO_VARIATION_12: '610sv084', // ness
	TIP_18_WORD_ZONE_AUDIO_VARIATION_13: '610sv085', // ty
	TIP_18_WORD_ZONE_AUDIO_VARIATION_14: '610sv086', // ment
	TIP_18_WORD_ZONE_AUDIO_VARIATION_15: '610sv087', // ic
	TIP_18_WORD_ZONE_AUDIO_VARIATION_16: '610sv088', // ous
	TIP_18_WORD_ZONE_AUDIO_VARIATION_17: '610sv089', // eous
	TIP_18_WORD_ZONE_AUDIO_VARIATION_18: '610sv090', // en
	TIP_18_WORD_ZONE_AUDIO_VARIATION_19: '610sv091', // er
	TIP_18_WORD_ZONE_AUDIO_VARIATION_20: '610sv092', // ive
	TIP_18_WORD_ZONE_AUDIO_VARIATION_21: '610sv093', // ative
	TIP_18_WORD_ZONE_AUDIO_VARIATION_22: '610sv094', // itive
	TIP_18_WORD_ZONE_AUDIO_VARIATION_23: '610sv095', // ful
	TIP_18_WORD_ZONE_AUDIO_VARIATION_24: '610sv096', // less
	TIP_18_WORD_ZONE_AUDIO_VARIATION_25: '610sv097', // est
	TIP_18_WORD_ZONE_AUDIO_VARIATION_26: '610sv098', // or

	TIP_19_WORD_ZONE_AUDIO_1: '610sv122',
	TIP_19_SPELLING_ZONE_AUDIO_1: '610sv521',
	TIP_19_WORD_ZONE_AUDIO_2: '610sv004',
	TIP_19_WORD_ZONE_AUDIO_VARIATION_1_PREFIX: '610sv046',
	TIP_19_WORD_ZONE_AUDIO_VARIATION_1_ENDINGS: '610sv123',
	TIP_19_WORD_ZONE_AUDIO_VARIATION_2_ENDING: '610sv124',
	TIP_19_WORD_ZONE_AUDIO_VARIATION_2_ENDINGS: '610sv125',
	TIP_19_WORD_ZONE_AUDIO_VARIATION_2_AND: '610sv126',

	TIP_20_VARIATION_1_APOSTROPHE: '610sv399',
	TIP_20_VARIATION_1_APOSTROPHE_S: '610sv400',

	TIP_21_WORD_ZONE_AUDIO_1: '610sv106',
	TIP_21_WORD_ZONE_AUDIO_2: '610sv100',
	TIP_21_WORD_ZONE_AUDIO_3: '610sv107',
	TIP_21_SPELLING_ZONE_AUDIO_1: '610sv106',
	TIP_21_SPELLING_ZONE_AUDIO_2: '610sv100',
	TIP_21_SPELLING_ZONE_AUDIO_3: '610sv107',

	TIP_23_AUDIO_1: '610sv099',
	TIP_23_AUDIO_2: '610sv100',

	TIP_23_WORD_ZONE_AUDIO_1: '610sv099',
	TIP_23_WORD_ZONE_AUDIO_2: '610sv100',

	TIP_24_WORD_ZONE_AUDIO_1: '610sv007',
	TIP_24_SPELLING_ZONE_AUDIO_1: '610sv007',
	TIP_24_WORD_ZONE_AUDIO_VARIATION_5_1: '610sv012', // form
	TIP_24_WORD_ZONE_AUDIO_VARIATION_5_2: '610sv312',
	TIP_24_WORD_ZONE_AUDIO_VARIATION_12_1: '610sv019', // mot
	TIP_24_WORD_ZONE_AUDIO_VARIATION_12_2: '610sv319',
	TIP_24_WORD_ZONE_AUDIO_VARIATION_13_1: '610sv020', // port
	TIP_24_WORD_ZONE_AUDIO_VARIATION_13_2: '610sv320',
	TIP_24_WORD_ZONE_AUDIO_VARIATION_17_1: '610sv024', // spec
	TIP_24_WORD_ZONE_AUDIO_VARIATION_17_2: '610sv324',
	TIP_24_WORD_ZONE_AUDIO_VARIATION_19_1: '610sv127', // duc
	TIP_24_WORD_ZONE_AUDIO_VARIATION_19_2: '610sv427',
	TIP_24_WORD_ZONE_AUDIO_VARIATION_4_1: '610sv011', // commun
	TIP_24_WORD_ZONE_AUDIO_VARIATION_4_2: '610sv311',
	TIP_24_WORD_ZONE_AUDIO_VARIATION_15_1: '610sv022', // sign
	TIP_24_WORD_ZONE_AUDIO_VARIATION_15_2: '610sv322',

	TIP_25_WORD_ZONE_AUDIO_1: '610sv108',
	TIP_25_WORD_ZONE_AUDIO_2_PART1: '610sv109a',
	TIP_25_WORD_ZONE_AUDIO_2_PART2: '610sv109b',
	TIP_25_WORD_ZONE_AUDIO_2_PART3: '610sv109c',
	TIP_25_SPELLING_ZONE_AUDIO_1: '610sv517',
	TIP_25_SPELLING_ZONE_AUDIO_2: '610sv518a',

	TIP_26_WORD_ZONE_AUDIO_1: '610sv110',
	TIP_26_WORD_ZONE_AUDIO_2: '610sv111',
	TIP_26_SPELLING_ZONE_AUDIO_1: '610sv517',
	TIP_26_SPELLING_ZONE_AUDIO_2: '610sv518a',

	TIP_27_WORD_ZONE_AUDIO_1_MULTI_SYLLABLE: '610sv112',
	TIP_27_WORD_ZONE_AUDIO_2: '610sv114',
	TIP_27_WORD_ZONE_AUDIO_1_SINGLE_SYLLABLE: '610sv113',
	TIP_27_SPELLING_ZONE_AUDIO_1_MULTI_SYLLABLE: '610sv517',
	TIP_27_SPELLING_ZONE_AUDIO_1_MULTI_SYLLABLE_PART_2: '610sv518a',
	TIP_27_SPELLING_ZONE_AUDIO_1_SINGLE_SYLLABLE: '610sv501',
	TIP_27_SPELLING_ZONE_AUDIO_2_MULTI_SYLLABLE: '610sv518b',
	TIP_27_SPELLING_ZONE_AUDIO_2_SINGLE_SYLLABLE: '610sv520',

	TIP_28_WORD_ZONE_AUDIO_1: '610sv028a',
	TIP_28_WORD_ZONE_AUDIO_2: '610sv028b',
	TIP_28_WORD_ZONE_AUDIO_3: '610sv115',
	TIP_28_SPELLING_ZONE_AUDIO_1: '610sv028a',
	TIP_28_SPELLING_ZONE_AUDIO_2: '610sv028b',
	TIP_28_SPELLING_ZONE_AUDIO_3: '610sv512',

	TIP_29_WORD_ZONE_AUDIO_1: '610sv116',
	TIP_29_WORD_ZONE_AUDIO_2: '610sv117',
	TIP_29_SPELLING_ZONE_AUDIO_1: '610sv514',
	TIP_29_SPELLING_ZONE_AUDIO_2: '610sv515',

	TIP_30_WORD_ZONE_AUDIO_1: '610sv118a',
	TIP_30_WORD_ZONE_AUDIO_2: '610sv118b',
	TIP_30_WORD_ZONE_AUDIO_3: '610sv119',
	TIP_30_SPELLING_ZONE_AUDIO_1: '610sv118a',
	TIP_30_SPELLING_ZONE_AUDIO_2: '610sv118b',
	TIP_30_SPELLING_ZONE_AUDIO_3: '610sv516',

	TIP_31_WORD_ZONE_AUDIO_1: '610sv120',
	TIP_31_WORD_ZONE_AUDIO_2: '610sv121',
	TIP_31_SPELLING_ZONE_AUDIO_1: '610sv517',
	TIP_31_SPELLING_ZONE_AUDIO_2: '610sv518',

	TIP_32_WORD_ZONE_AUDIO_1: '610sv004',
	TIP_32_WORD_ZONE_AUDIO_2: '610sv002',
	TIP_32_SPELLING_ZONE_AUDIO_1: '610sv004',
	TIP_32_SPELLING_ZONE_AUDIO_2: '610sv501'
};

constants.spanishTipLocation = {
	INITIAL: 'initial',
	ANY: 'any',
	FINAL: 'final'
};

constants.spanishTipConfig = {
	TIP_25: 25,
	DEFAULT_AUDIO_LENGTH: 8,
	TIP_25_LENGTH: 6
};

export const DecodingTipConfig = constants;
