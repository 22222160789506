import React from 'react';
import WordCardActivity from '../activities/WordCard/WordCardActivity';
import SynonymsAndAntonymsIntroActivity from '../activities/WordCard/SynonymsAndAntonyms/SynonymsAndAntonymsIntroActivity';
import SynonymsAndAntonymsBodyActivity from '../activities/WordCard/SynonymsAndAntonyms/SynonymsAndAntonymsBodyActivity';
import WordFamilyActivity from '../activities/WordCard/WordFamily/WordFamilyActivity';
import WordsInContextActivity from '../activities/WordCard/WordsInContext/WordsInContextActivity';
import DragDropActivity from '../activities/WordCard/DragDropActivity';

const languageZoneRoutes = [
	{
		route: 'word-card-selector',
		serverRoute: 'word_card_selector',
		pageTitle: 'Word Card Selector',
		component: <WordCardActivity />,
		author: ['Mike']
	},
	{
		route: 'synonym-antonym-intro',
		serverRoute: 'synonyms_and_antonyms',
		pageTitle: 'Synonyms & Antonyms',
		component: <SynonymsAndAntonymsIntroActivity />,
		author: ['Ramkumar', 'Sheik']
	},
	{
		route: 'synonym-antonym-body',
		pageTitle: 'Synonyms & Antonyms',
		component: <DragDropActivity />,
		author: ['Ramkumar', 'Sheik']
	},
	{
		route: 'word-family',
		serverRoute: 'word_family',
		pageTitle: 'Word Family',
		component: <WordFamilyActivity />,
		author: ['Sumanth']
	},
	{
		route: 'words-in-context',
		serverRoute: 'words_in_context',
		pageTitle: 'Words in Context',
		component: <WordsInContextActivity />,
		author: ['Mike']
	},
	{
		route: 'example-nonexample-intro',
		serverRoute: 'example_non_example',
		pageTitle: 'Examples & Non-Examples',
		component: <SynonymsAndAntonymsIntroActivity />,
		author: ['Ramkumar', 'Sheik']
	},
	{
		route: 'example-nonexample-body',
		pageTitle: 'Examples & Non-Examples',
		component: <DragDropActivity />,
		author: ['Ramkumar', 'Sheik']
	}
];

export default languageZoneRoutes;
