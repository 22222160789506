import React from 'react';
import { makeStyles } from '@material-ui/core';
import { IS_PRODUCTION_ENV } from '../../utils/constants';
import { uiSlice } from '../../store/slices/ui';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
	error: {
		position: 'absolute',
		width: '100%',
		height: 'auto',
		lineHeight: '28px',
		background: theme.colors.errorRed,
		color: theme.colors.white,
		borderRadius: '8px',
		textAlign: 'center',
		padding: '8px',
		fontWeight: '600',
		fontFamily: theme.fonts.uiBold,
		'& div': {
			position: 'relative'
		},
		zIndex: '999',
		border: '3px solid #fff',
		boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.4)'
	},
	close: {
		position: 'absolute',
		right: '-3px',
		top: '-6px',
		color: theme.colors.white,
		fontSize: '10px'
	}
}));

const ErrorMessage = (props) => {
	const { errorMessage } = props;

	const classes = useStyles();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(uiSlice.actions.setErrorMessage(''));
	};

	let displayedError = '';
	if (IS_PRODUCTION_ENV) {
		displayedError = 'There was a server error.  Please try again.';
	} else if (errorMessage.details) {
		displayedError = `Server Error - ${errorMessage.status} - ${errorMessage.details.error.message}`;
		console.log(errorMessage);
	} else if (errorMessage.response) {
		let errorMessageText;
		if (errorMessage.response.data.error) {
			errorMessageText = errorMessage.response.data.error.message;
		} else {
			errorMessageText = errorMessage.response.statusText;
		}
		displayedError = `Server Error - ${errorMessage.response.status} - ${errorMessageText}`;
		console.log(errorMessage);
	} else if (errorMessage.message) {
		displayedError = `Server Error - ${errorMessage.message}`;
		console.log(errorMessage);
	}
	else {
		console.log(errorMessage);
	}

	return (
		<div className={`${classes.error} error-message`}>
			<div>
				<button className={`${classes.close}`} onClick={handleClose}>
					<i className="r180 x" />
				</button>
				{displayedError}
			</div>
		</div>
	);
}

export default React.memo(ErrorMessage);
