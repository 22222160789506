import { makeStyles } from '@material-ui/styles'

export const useStyle = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		margin: '0 32px 32px 0',
		marginBottom: 44,
		width: '288px',
		backgroundColor: theme.colors.white,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: 5,
		'&.no-right-margin': {
			marginRight: '0'
		}
	},
	headerWrapper: {
		display: 'flex',
		backgroundColor: theme.colors.softBlack,
		height: 8,
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
	},
	footerWrapper: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.colors.softBlack,
		height: 32,
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
	},

	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: 120,
	},
	masterProgressAreaWrapper: {
		display: 'flex',
		padding: '0 8px 0 24px',
		flex: '1 0 auto',
		alignItems: 'center',
		userSelect: 'none',
		justifyContent: 'space-between',
	},
	progressDetailsWrapper: {
		fontFamily: theme.fonts.buttonBold,
		fontSize: '18px',
		color: theme.colors.white,
		letterSpacing: '0.02em',
	},
	enterButton: {
		position: 'absolute',
		top: '100%',
		left: '50%',
		transform: 'translateX(-50%) translateY(-50%)',
		cursor: 'pointer',
		background: theme.colors.white,
		borderRadius: '50%',
		width: 40,
		height: 40,
		textAlign: 'center',
		border: `1px solid ${theme.colors.paleGrey}`,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		transition: 'all ease 0.3s',
		'& i': {
			position: 'relative',
			top: '0px',
			color: theme.colors.softBlack
		},
		'&:hover:not(.isCompleted), &:focus': {
			width: 48,
			height: 48,
			border: `1px solid ${theme.colors.readingZone.primary}`,
			'& i': {
				top: '0px',
			}
		},
		'&:active:not(.isCompleted)': {
			width: 48,
			height: 48,
			border: `1px solid ${theme.colors.readingZone.primary}`,
			'& i': {
				top: '0px',
				color: `${theme.colors.readingZone.primary}`
			}
		}
	}
}));
