import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		zIndex: '10',
		'@media screen and (max-width: 1100px)': {
			minWidth: 'fit-content',
		}
	},
	leftColumn: {
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		position: 'absolute',
		top: '80px',
		bottom: '0px',
		maxWidth: '648px',
		minWidth: '512px'
	},
	leftNarrow: { position: 'relative' },
	rightColumn: {
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		position: 'relative',
		top: '120px',
		width: '488px',
		marginLeft: '8px'
	},
	passage: { display: 'flex', flexDirection: 'column', flexGrow: 1 },
	wordCardModal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	wordCardModalBody: {},
	videoModalBody: {
		background: theme.colors.black,
		'& #videoSummaryButton': {
			margin: '10px'
		}
	},
	recordingTipsModalBody: {
		width: '520px',
		height: '440px',
		background: theme.colors.white,
		position: 'relative',
		paddingLeft: '68px',
		paddingRight: '68px',
		paddingBottom: '48px',
		paddingTop: '48px'
	},
	fluencyCheckScoresModalBody: {
		width: '480px',
		height: '440px',
		background: theme.colors.white,
		position: 'relative',
		display: 'flex',
		flexDirection: 'column'
	},
	fluencyScoreHeader: {
		width: '480px',
		height: '24px',
		background: theme.colors.successGreen
	},
	fluencyCorrect: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		marginTop: '-12px'
	},
	fluencyCheck: {
		display: 'block',
		float: 'left',
		textAlign: 'center',
		lineHeight: '24px',
		marginRight: '20px',
		fontSize: '16px',
		width: '24px',
		height: '24px',
		borderRadius: '12px',
		color: theme.colors.white,
		background: theme.colors.successGreen,
		border: `1px solid ${theme.colors.white}`
	},
	successTitle: {
		display: 'flex',
		height: '94px',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '-12px',
		fontSize: '32px',
		fontFamily: theme.fonts.uiBold,
		borderBottom: `2px solid ${theme.colors.successGreen}`
	},
	successBody: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '130px'
	},
	successContent: {
		display: 'flex',
		flexDirection: 'column',
		width: '240px',
		justifyContent: 'center',
		alignItems: 'center'
	},
	successBodyTitle: {
		fontSize: '16px',
		fontFamily: theme.fonts.uiBold
	},
	successBodyContent: {
		fontSize: '24px',
		fontFamily: theme.fonts.uiBold,
		color: theme.colors.successGreen
	},
	successBodyBorder: {
		width: '400px',
		height: '0px',
		margin: '0 auto',
		borderBottom: `1px solid ${theme.colors.paleGrey}`
	},
	wrongWordsContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '240px'
	},
	wrongWordsHeader: {
		margin: '24px 0',
		fontSize: '16px',
		fontFamily: theme.fonts.uiBold
	},
	wrongWordsWrapper: {
		width: '480px',
		overflowY: 'scroll',
		height: '100px',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	wrongWordsText: {
		fontSize: '18px',
		fontFamily: theme.fonts.ui,
		margin: '5px',
		display: 'flex',
		justifyContent: 'center',
		flexBasis: '220px'
	},
	fluencyGoOnButton: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		margin: '24px 0'
	},
	fluencyScoreFooter: {
		width: '480px',
		height: '8px',
		background: theme.colors.successGreen
	},
	modalX: {
		position: 'absolute',
		top: '8px',
		right: '8px',
		cursor: 'pointer'
	},
	tipsTitle: {
		fontSize: '24px',
		lineHeight: '30px',
		marginBottom: '8px',
		fontFamily: theme.fonts.uiSemibold,
		display: 'flex'
	},
	tipsEntry: {
		marginTop: '24px',
		fontFamily: theme.fonts.uiBold
	},
	tipCheck: {
		display: 'block',
		float: 'left',
		textAlign: 'center',
		lineHeight: '24px',
		marginRight: '20px',
		fontSize: '16px',
		width: '24px',
		height: '24px',
		borderRadius: '12px',
		color: theme.colors.white,
		background: theme.colors.successGreen
	},
	tipsButton: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginTop: '24px'
	},
	goOn: {
		position: 'absolute',
		bottom: '24px',
		right: '32px'
	},
	recordingSaveConfirmModal: {
		maxWidth: '520px'
	},
	recordingKillConfirmModal: {
		maxWidth: '520px'
	},
	micBypassModalBody: {
		maxWidth: '520px',
		background: theme.colors.white,
		padding: '16px',
		position: 'relative'
	},
	hiddenSkipLink: {
		width: '0px',
		height: '0px',
		fontSize: '0px'
	},
	clearBoth: {
		clear: 'both'
	},
	contentRight: {
		marginLeft: '16px'
	}
}));
