import React, { useState } from 'react';
import { SynonymsAndAntonymsIntro, ActivityInstructionButton } from '@reading/common';
import Footer from '../../../containers/App/Footer';
import FooterForwardBack from '../../../containers/App/FooterForwardBack';
import Navbar from '../../../containers/App/Navbar';
import { useHistory } from 'react-router-dom';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import ActivitySuspense from '../../../containers/App/ActivitySuspense';
import { KnowledgeForReading } from '../KnowledgeForReading';
import useIdleHelp from '../../../utils/useIdleHelp';
import { useUnmount } from 'react-use';
import useRouteInfo from '../../../utils/useRouteInfo';
import { transitionToTask } from '../../../utils/navigation';
import { useSelector, useDispatch } from 'react-redux';
import ActivityFrame from '../../../containers/App/ActivityFrame';

export default function SynonymsAndAntonymsIntroActivity(props) {

	const history = useHistory();
	const dispatch = useDispatch();

	const { bundledActivityData, contentData, powerWordData, settings } = useSelector(state => {
		return {
			bundledActivityData: state.activity.bundledActivityData,
			powerWordData: state.activity.powerwords,
			contentData: state.activity.taskData,
			settings: state.session.session.settings
		};
	});

	const { zone } = useRouteInfo();
	const [activityStartTime] = useState(new Date());
	const [isDisabled, setDisabled] = useState(true);

	// stop any sounds
	useUnmount(() => {
		AudioPlayer2.stopAll();
	});

	const updateAfterGuess = (isCorrect, answerId, isComplete) => {
		let attemptArray = [];
		attemptArray.push({
			correct: isCorrect,
			id: '',
			timestamp: new Date()
		});
		let datatoServer = {
			isComplete: isComplete,
			startTime: activityStartTime,
			endTime: isComplete ? new Date() : ''
		};

		KnowledgeForReading.sendProgressToServer(
			datatoServer,
			attemptArray,
			contentData,
			bundledActivityData,
			powerWordData,
			history,
			dispatch
		);
	};

	const handleForward = e => {
		AudioPlayer2.stopAll();
		updateAfterGuess(true, '', true);

		const route =
			contentData.activity_server_id === 'synonyms_and_antonyms'
				? 'synonym-antonym-body'
				: 'example-nonexample-body';

		transitionToTask(history, `zone/${zone}/${route}`, {
			taskActivity: contentData,
			powerWordMapData: powerWordData,
			activityData: bundledActivityData.activity
		});
	};

	const handleVOEnded = () => {
		setTimeout(() => {
			setDisabled(false);
		}, 1000);
	};

	/* istanbul ignore next */
	const getHelpSound = () => {
		if (contentData.activity_server_id === 'example_non_example') {
			return KnowledgeForReading.SOUND_EXAMP_INTRO_HELP;
		}
		else {
			return KnowledgeForReading.SOUND_SYNANT_INTRO_HELP;
		}
	}

	/* istanbul ignore next */
	const getIntroSound = () => {
		if (contentData.activity_server_id === 'example_non_example') {
			return KnowledgeForReading.SOUND_EXAMP_INTRO;
		}
		else {
			return KnowledgeForReading.SOUND_SYNANT_INTRO;
		}
	}

	/* istanbul ignore next */
	const getInstructions = () => {
		if (contentData.activity_server_id === 'example_non_example') {
			return 'The examples & non-examples below all relate to the Power Word:';
		}
		else {
			return 'All of these words are synonyms or antonyms of the following word:';
		}
	}

	useIdleHelp(getHelpSound());

	return (
		<>
			<Navbar helpSoundUrl={getHelpSound()} />

			<ActivitySuspense requiredRenderData={contentData}>

				<ActivityFrame isWhiteBackground={true}>
					<ActivityInstructionButton audioSrc={getIntroSound()} />
					<SynonymsAndAntonymsIntro
						word={contentData.power_word_focus}
						wordUrl={contentData.power_word_focus_url}
						instructions={getInstructions()}
						instructionsUrl={contentData.stem_url}
						handleVOEnd={handleVOEnded}
						synonymsAndAntonymsWordList={contentData.SynonymsAndAntonymsWordList}
						isAutoplay={settings.autoPlayEnabled}
						introSound={getIntroSound()}
					/>
				</ActivityFrame>

				<Footer>
					<FooterForwardBack
						status={FooterForwardBack.VALID}
						onForward={handleForward}
						isBackVisible={false}
						isForwardDisabled={isDisabled}
					/>
				</Footer>

			</ActivitySuspense>

		</>
	);
}
