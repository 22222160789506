import {makeStyles} from "@material-ui/core/styles";

export default makeStyles(theme=>({
	wrapper:{
		display:'flex',
		flexDirection:'column',
		alignItems:'center',
		position:'relative',
	},
	imagesRow:{
		display:'flex',
		flexDirection:'row',
		position:'relative',
		justifyContent:'center',
		width:'100%',
		pointerEvents:'none',
		height:'260px',
	},
	sourceDiv: {
		left:'52px',
		top:'32px',
		textAlign:'center',
		width:'184px',
		position:'absolute',
		fontSize:'32px',
		lineHeight:'56px',
	},
	sourceImg: {
		width:'100%',
		background:theme.colors.paleGrey,
	},
	arrow:{
		position:'absolute',
		top:'96px',
		left: '292px',
	},
	destDiv : {
		position:'relative',
		marginTop:'32px',
		width:'296px',
		pointerEvents:'all',
	},
	destImg: {
		width:'100%',
		background:theme.colors.paleGrey,
	},
	destSound: {
		position:'absolute',
		top:'16px',
		left:'16px',
		borderRadius:'12px',
	},
	dragTarget: {
		width:'640px',
		height:'48px',
		marginTop:'40px',
		borderRadius:'8px 8px 0px 0px',
		display:'flex',
		flexDirection:'row',
		justifyContent:'center',
		background:theme.colors.lightGrey,
		border:`dashed 1px ${theme.colors.paleGrey}`,
		boxShadow:`0px 0px 0px 4px ${theme.colors.lightGrey}`,
	},
	dragSources: {
		width:'648px',
		display:'flex',
		flexDirection:'row',
		position:'relative',
		marginTop:'27px',
	},
	wordTiles:{
		'& div': {margin:'4px'},
		width:'500px',
		maxHeight:'135px',
		display:'flex',
		flexDirection:'row',
		flexWrap:'wrap',
	},
	discardPile: {
		width:'128px',
		height:'128px',
		position:'absolute',
		right:'0px',
		top:'0px',
		background:theme.colors.lightGrey,
		borderRadius:'8px',
		border:`dashed 1px ${theme.colors.paleGrey}`,
		boxShadow:`0px 0px 0px 4px ${theme.colors.lightGrey}`,
		boxSizing:'border-box',
	},
	reset:{
		position:'absolute',
		left:'36px',
		bottom:'56px',
		float:'left',
		'& button': {
			color: theme.colors.readingZone.background,
			background:'none',
			'&:hover':{
				color: `${theme.colors.readingZone.background}!important`,
				background:'none!important',
			}
		}

	},
	feedbackIcon:{
		position:'absolute',
		top:'288px',
		right:'200px',
	},
	'@keyframes moveLeftShrink':{
		"0%":{
			position:'absolute',
			top:'32px',
			left:'300px',
			width:'296px',
			marginTop:'0px',
			fontSize:'48px',
		},
		"100%": {
			position: 'absolute',
			top: '32px',
			left: '52px',
			width: '184px',
			marginTop:'0px',
			fontSize:'32px',
		}
	},
	startImage:{
		width:'368px',
		height:'184px',
		marginTop:'64px',
		fontSize:'48px',
	},
	bigWord:{
		display:'flex',
		height:'64px',
		fontSize:'inherit',
		justifyContent:'center',
		alignItems:'center',
	},
	animMoveLeftShrink: {
		animationDuration: '3s',
		animationFillMode: 'both',
		animationName: '$moveLeftShrink',
	},
	'@keyframes startMoveLeftShrink':{
		"0%":{
			position:'absolute',
			top:'64px',
			left:'264px',
			width:'368px',
			marginTop:'0px',
			fontSize:'48px',
		},
		"100%": {
			position: 'absolute',
			top: '32px',
			left: '52px',
			width: '184px',
			marginTop:'0px',
			fontSize:'32px',
		}
	},
	animStartMoveLeftShrink: {
		animationDuration: '3s',
		animationFillMode: 'both',
		animationName: '$startMoveLeftShrink',
	},
	animInvisible: {
		filter: 'opacity(0)',
	},
	'@keyframes fadein':{
		"0%": {
			filter: 'opacity(0)'
		},
		"100%": {
			filter: 'opacity(1)'
		}
	},
	animFadeIn: {
		animationDuration:'3s',
		animationName:`$fadein`,
	},


}))
