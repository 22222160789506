import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {isEmpty} from 'lodash';
import {HoverTip} from '@reading/common';

const useStyles = makeStyles(theme => ({
	button: {
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.2)'
		},
		'& i.playing': {
			color: theme.colors.readingZone.primary
		}
	}
}));

const NavbarHelpButton = (props) => {
	const {className, soundUrl} = props;

	const classes = useStyles();

	const [playing, setPlaying] = useState(false);

	useEffect(() => {
		if (isEmpty(soundUrl) === false) {
			const audioPlayer = AudioPlayer2.load({
				name: soundUrl,
				src: soundUrl
			});

			audioPlayer.onStop = () => {
				setPlaying(false);
			};

			return () => {
				delete audioPlayer.onStop;
			};
		}
	}, [soundUrl]);

	const handleHelpClick = async e => {
		const {onHelpClick} = props;
		e.stopPropagation();
		if (typeof onHelpClick !== 'undefined') {
			await onHelpClick(e);
		}

		if (playing === false) {
			AudioPlayer2.stopAll();
			setPlaying(true);
			await AudioPlayer2.playSync(soundUrl);
			setPlaying(false);
		}
		else {
			AudioPlayer2.stopAll();
			setPlaying(false);
		}
	};

	return (
		<HoverTip title='Click the "?" for help'>
			<button
				type="button"
				className={`${classes.button} ${className} navbar-help-button`}
				onClick={handleHelpClick} aria-label="Help"
			>
				<i className={`r180 help ${playing ? 'playing' : ''}`} />
			</button>
		</HoverTip>
	);
}

NavbarHelpButton.defaultProps = {
	className: '',
	soundUrl: ''
};

NavbarHelpButton.propTypes = {
	className: PropTypes.string,
	onHelpClick: PropTypes.func,
	soundUrl: PropTypes.string.isRequired
};

export default React.memo(NavbarHelpButton);
