import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	playButton: {
		height: '64px',
		width: '64px',
		borderRadius: '50%',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		color: theme.colors.readingZone.background,
		background: theme.colors.white,
		fontSize: '30px',
		opacity: 1,
		'&.roundedBorder': {
			border: `3px solid ${theme.colors.readingZone.blue3}`,
			paddingLeft: '5px'
		},
		'& .disabled': {
			background: 'none',
			cursor: 'not-allowed'
		},
		'&.isPlaying': {
			background: theme.colors.readingZone.blue5
		}
	}
}));

const PlayIcon = props => {
	const classes = useStyles();
	const {recordHandler, buttonClass, iconClass, isActivelyPlaying} = props;

	return (
		<div className={'circleSpread'}>
			<button
				type="button"
				className={`${classes.playButton} ${buttonClass} ${isActivelyPlaying ? 'isPlaying' : ''}`}
				onClick={recordHandler}
			>
				<i className={`r180 ${iconClass}`} />
			</button>
		</div>
	);
};
export default React.memo(PlayIcon);
