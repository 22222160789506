import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(theme => ({
	content: {
		paddingTop: '64px',
		display: 'flex',
		width: '100%',
		justifyContent: 'center'
	}
}));
