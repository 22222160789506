import React from 'react';
import WordCardActivity from '../activities/WordCard/WordCardActivity';
import AnchorVideoActivity from '../activities/AnchorVideo/AnchorVideoActivity';
import ImageMultipleChoiceActivity from '../activities/WordCard/ImageMultipleChoice/ImageMultipleChoiceActivity';
import ImageHotSpotActivity from '../activities/WordCard/ImageHotSpot/ImageHotSpotActivity';
import DragDropActivity from '../activities/WordCard/DragDropActivity';

const exploreZoneRoutes = [
	{
		route: 'anchor_video',
		serverRoute: 'anchor_video',
		pageTitle: 'Anchor Video',
		component: <AnchorVideoActivity />,
		author: ['Sheik']
	},
	{
		route: 'knowledge_for_reading',
		serverRoute: 'knowledge_for_reading',
		pageTitle: 'Knowledge For Reading',
		component: <WordCardActivity />,
		author: ['Mike']
	},
	{
		route: 'image-drag-drop',
		pageTitle: 'Drag & Drop',
		component: <DragDropActivity />,
		author: ['Ramkumar', 'Sheik']
	},
	{
		route: 'image-choice',
		pageTitle: 'Image Choice',
		component: <ImageMultipleChoiceActivity />,
		author: ['Mike']
	},
	{
		route: 'image-hot-spot',
		pageTitle: 'Image Hot Spot',
		component: <ImageHotSpotActivity />,
		author: ['Mike']
	}
];

export default exploreZoneRoutes;
