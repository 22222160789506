import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	inputWrapper: {
		width: '24px',
		marginRight: '10px',
		position: 'relative',
		'& i': {
			height: '24px',
			width: '24px',
			color: theme.colors.white,
			borderRadius: props => (props.type === 'single' ? '50%' : '4px'),
			padding: '3px 3px',
			position: 'relative',
			top: '5px',
			fontSize: '18px',
			'&.check': {
				background: theme.colors.successGreen
			},
			'&.x': {
				background: theme.colors.errorRed
			}
		}
	},
	radioButton: {
		background: theme.colors.white,
		border: `1px solid ${theme.colors.grey}`,
		height: '24px',
		width: '24px',
		borderRadius: props => (props.type === 'single' ? '50%' : '4px'),
		position: 'relative',
		top: '4px',
		verticalAlign: 'middle',
		'& .selection': {
			background: theme.colors.readingZone.primary,
			height: '0',
			width: '0',
			left: '12px',
			top: '12px',
			borderRadius: props => (props.type === 'single' ? '50%' : '4px'),
			position: 'absolute',
			transition: 'all 0.2s'
		},
		'&.selected': {
			'& .selection': {
				height: '16px',
				width: '16px',
				left: '3px',
				top: '3px'
			}
		}
	}
}));

const RadioCheck = props => {
	const {
		id,
		className,
		onSelect,
		item,
		isSelected,
		type,
		status,
		showCorrectiveFeedback
	} = props;
	const classes = useStyles({type});

	const handleSelect = e => {
		e.preventDefault();
		onSelect(item);
	};

	return (
		<div
			className={`${classes.inputWrapper} ${className} radio-check`}
			id={id}
		>
			{status !== 'correct' &&
			status !== 'incorrect' &&
			!showCorrectiveFeedback ? (
				<button
					className={`${classes.radioButton} ${
						isSelected ? 'selected' : ''
					}`}
					onClick={handleSelect}
				>
					<div className="selection"></div>
				</button>
			) : status === 'correct' || showCorrectiveFeedback ? (
				<i className="r180 check" />
			) : (
				<i className="r180 x" />
			)}
		</div>
	);
};

RadioCheck.defaultProps = {
	id: '',
	className: '',
	item: {},
	isSelected: true,
	type: 'single',
	status: 'initial',
	showCorrectiveFeedback: false
};
RadioCheck.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	item: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	isSelected: PropTypes.bool,
	type: PropTypes.string,
	status: PropTypes.string,
	showCorrectiveFeedback: PropTypes.bool
};

export default React.memo(RadioCheck);
