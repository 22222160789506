import React from 'react';
import DevTool from '../pages/DevTool/DevTool';
import ZoneMenu from '../activities/ZoneMenu/ZoneMenu';
import SegmentSelection from '../activities/SegmentSelection/SegmentSelection';
import {CLIENT_BASE_URL} from "../utils/constants";
import Logout from '../pages/Logout';

export const base = CLIENT_BASE_URL;

export const RouteMap = [
	{ path: `${base}/devtool`, component: props => <DevTool {...props} /> },
	{
		path: `${base}/zone_menu`,
		component: props => <ZoneMenu {...props} />
	},
	{
		path: `${base}/segment_selection`,
		component: props => <SegmentSelection {...props} />
	},
	{
		path: `${base}/logout`,
		component: props => <Logout {...props} />
	}
];
