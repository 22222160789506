import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax } from 'gsap';
import clsx from 'clsx';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';

import { useStyle } from './WordCardSpell.style';
import {
	useWordSpellDecoder,
	UseWordCardDispatch,
	usePreloader,
	useWordTextSound
} from '../WordCardContext';

import {
	WordCardPreloaderStatus,
	WordCardText,
	WordCardDecoder
} from '../constants';

import { WordCardUtil } from '../WordCardUtil';
import { KnowledgeForReading } from '@reading/r180/src/activities/WordCard/KnowledgeForReading';

const WordCardSpell = ({ onComplete }) => {
	const wordSpell = useWordSpellDecoder();
	const wordCardDispatch = UseWordCardDispatch();
	const preloader = usePreloader(WordCardDecoder.SPELL);
	const wordTextSound = useWordTextSound();

	const normalColor = 'rgba(255, 175, 102, 0)';
	const highlightColor = 'rgba(255, 175, 102, 1)';

	const timelineRef = useRef(null);
	const spritesRef = useRef(null);

	const classes = useStyle({ normalColor });

	const init = useCallback(() => {
		const spellSounds = wordSpell.filter(
			w => w.type === WordCardText.NORMAL
		);

		if (!preloader) {
			wordCardDispatch({
				type: '@@word-card/preload-sound',
				payload: {
					name: WordCardDecoder.SPELL,
					assets: [wordTextSound, ...spellSounds]
				}
			});
		}
	}, [wordSpell, preloader, wordCardDispatch, wordTextSound]);

	const start = useCallback(() => {
		timelineRef.current.play();
	}, []);

	const stop = useCallback(() => {
		AudioPlayer2.stopAll();
		onComplete();
	}, [onComplete]);

	const playSound = useCallback(sound => () => AudioPlayer2.play(sound), []);

	const destroy = useCallback(() => {
		AudioPlayer2.stopAll();
		timelineRef.current && timelineRef.current.pause();
		timelineRef.current && timelineRef.current.clear();
		timelineRef.current && timelineRef.current.kill();
		timelineRef.current = null;
	}, []);

	const addEnterStageScene = useCallback(
		timeline => {
			const sprites = wordSpell.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline
				.set(spritesRef.current, { opacity: 1 })
				.set(sprites, { opacity: 0 });
			const delay = 0.1;
			wordSpell.forEach((x, i) => {
				if (x.type === WordCardText.NORMAL) {
					const sprite = spritesRef.current.querySelector(`#${x.id}`);
					timeline
						.set(sprite, { opacity: 0 })
						.set(sprite, { scale: 0 })
						.set(sprite, { x: 0 })
						.set(sprite, { 'background-color': highlightColor })
						.to(
							sprite,
							{
								transformOrigin: '50% 50%',
								x: 0,
								duration: WordCardUtil.getDuration(x.audioName),
								delay,
								opacity: 1,
								autoAlpha: 1,
								scale: 1,
								'background-color': normalColor,
								onStart: playSound(x.audioName)
							},
							x.id
						);
				} else if (x.type === WordCardText.SEPARATOR) {
					const sprite = spritesRef.current.querySelector(`#${x.id}`);
					timeline
						.set(sprite, { opacity: 0 })
						.set(sprite, { y: -50 })
						.to(
							sprite,
							{
								transformOrigin: '50% 50%',
								y: 0,
								duration: 0.5,
								delay,
								opacity: 1,
								autoAlpha: 1,
								scale: 1,
								onStart: playSound(
									KnowledgeForReading.WORD_CARD_SFX.SHOW_SOUND
										.name
								)
							},
							x.id
						);
				}
			});
		},
		[highlightColor, normalColor, playSound, wordSpell]
	);

	const addConnectPartsScene = useCallback(
		timeline => {
			const normalWordParts = wordSpell.filter(
				x => x.type === WordCardText.NORMAL
			);

			const separatorParts = wordSpell.filter(
				x => x.type === WordCardText.SEPARATOR
			);

			const wordSprites = normalWordParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			const separatorSprites = separatorParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline
				.add('ConnectPartsScene', '+=0.35')
				.to(
					wordSprites,
					{
						duration: 0.35,
						delay: 1,
						'background-color': normalColor
					},
					'ConnectPartsScene'
				)
				.to(
					separatorSprites,
					{
						transformOrigin: '50% 50%',
						duration: 0.35,
						delay: 1,
						width: 0,
						height: 0,
						scale: 0,
						'margin-left': 0,
						'margin-right': 0,
						opacity: 0,
						onStart: playSound(
							KnowledgeForReading.WORD_CARD_SFX.CONTRACT_SOUND
								.name
						)
					},
					'ConnectPartsScene'
				);
		},
		[normalColor, playSound, wordSpell]
	);

	const addReadWordTextScene = useCallback(
		timeline => {
			timeline
				.add(
					'ReadWordTextScene',
					`+=${WordCardUtil.getDuration(wordTextSound.audioName)}`
				)
				.set(spritesRef.current, { paddingLeft: 4, paddingRight: 4 })
				.to(
					spritesRef.current,
					{
						duration: WordCardUtil.getDuration(
							wordTextSound.audioName
						),
						'background-color': highlightColor,
						onStart: playSound(wordTextSound.audioName)
					},
					'ReadWordTextScene'
				);
		},
		[highlightColor, playSound, wordTextSound]
	);

	const addNormalizeWordTextScene = useCallback(
		timeline => {
			timeline.add('NormalizeWordTextScene', '+=0.2').to(
				spritesRef.current,
				{
					duration: 0.2,
					delay: 0.2,
					'background-color': normalColor
				},
				'NormalizeWordTextScene'
			);
		},
		[normalColor]
	);

	const addLeaveStageScene = useCallback(
		timeline => {
			timeline.add('LeaveStageScene', '+=0.2').to(
				spritesRef.current,
				{
					duration: 0.2,
					delay: 1,
					opacity: 0,
					onStart: playSound(
						KnowledgeForReading.WORD_CARD_SFX.HIDE_SOUND.name
					),
					onComplete: () => stop()
				},
				'LeaveStageScene'
			);
		},
		[playSound, stop]
	);

	const setup = useCallback(() => {
		const timeline = new TimelineMax({ paused: true });

		addEnterStageScene(timeline);
		addConnectPartsScene(timeline);
		addReadWordTextScene(timeline);
		addNormalizeWordTextScene(timeline);
		addLeaveStageScene(timeline);

		timelineRef.current = timeline;
	}, [
		addEnterStageScene,
		addConnectPartsScene,
		addReadWordTextScene,
		addNormalizeWordTextScene,
		addLeaveStageScene
	]);

	useEffect(() => {
		if (wordSpell) {
			init();

			if (
				preloader &&
				preloader.status === WordCardPreloaderStatus.COMPLETED
			) {
				setup();
				start();
			}
		}

		return () => {
			destroy();
		};
	}, [wordSpell]);

	const renderWordSpell = () => {
		return (
			<div className="sprites">
				<div ref={spritesRef} className={classes.spriteWrapper}>
					{wordSpell &&
						wordSpell.map(x => (
							<div
								className={clsx({
									[classes.normalPartsWrapper]:
										x.type === WordCardText.NORMAL,
									[classes.silentWrapper]:
										x.type === WordCardText.SILENT,
									[classes.plusWrapper]:
										x.type === WordCardText.SEPARATOR
								})}
								key={x.id}
								id={x.id}
							>
								<span>{x.text}</span>
							</div>
						))}
				</div>
			</div>
		);
	};

	return <div className={classes.wrapper}>{renderWordSpell()}</div>;
};

WordCardSpell.defaultProps = {
	onComplete: () => { }
};

WordCardSpell.propTypes = {
	onComplete: PropTypes.func
};

export default React.memo(WordCardSpell);
