import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { isEmpty } from 'lodash';
import { displaySafe, stripHtmlTags } from '@reading/r180/src/utils/stringUtils';

const useStyles = makeStyles(theme => ({
	activityDirections: {
		position: 'relative',
		height: 'auto',
		width: 'auto',
		maxWidth: '704px',
		margin: 'auto',
		display: 'block',
		fontFamily: theme.fonts.context,
		textAlign: 'center',
		letterSpacing: '0.15px',
		'& button': {
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			},
			'& i': {
				position: 'relative',
				top: '0.5px',
				color: theme.colors.paleSlate,
				fontSize: '16px',
				marginRight: '12px',
				cursor: 'pointer',
				'&.playing': {
					color: theme.colors.readingZone.primary
				}
			}
		}
	}
}));

const ActivityDirections = (props) => {
	const { id, className, style, text, src, onSoundPlayed } = props;
	const classes = useStyles();

	const [isPlaying, setPlaying] = useState(false);

	/* istanbul ignore next */
	useEffect(() => {
		if (isEmpty(text) === false) {
			const audioPlayer = AudioPlayer2.load({
				name: text,
				src: src
			});

            audioPlayer.onStop = () => {
                setPlaying(false);
				if (typeof onSoundPlayed === 'function') {
					onSoundPlayed();
				}
            };

			return () => {
                delete audioPlayer.onStop;
            };
		}
	}, [text, src]);

	const handleClick = async(e) => {
		e.stopPropagation();
		if (isPlaying === false) {
			AudioPlayer2.stopAll();
			setPlaying(true);
			await AudioPlayer2.playSync(text);
			setPlaying(false);
			if (typeof onSoundPlayed === 'function') {
				onSoundPlayed();
			}
		}
		else {
			AudioPlayer2.stopAll();
			setPlaying(false);
		}
	};

	return (
		<div
			id={id}
			className={`${classes.activityDirections} ${className} activity-directions`}
			style={style}
		>
			<button
				className='activity-button-speaker'
				data-testid="activity-directions"
				onClick={e => {handleClick(e)}}
			>
				<i className={`r180 speaker-center ${isPlaying ? 'playing' : ''}`} />
			</button>
			<span
				dangerouslySetInnerHTML={{ __html: stripHtmlTags(displaySafe(text), true) }}
			></span>
		</div>
	);
}

ActivityDirections.defaultProps = {
	id: '',
	className: '',
	style: {},
	text: '',
	src: ''
};
ActivityDirections.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	style: PropTypes.object,
	text: PropTypes.string.isRequired,
	src: PropTypes.string.isRequired,
	onSoundPlayed: PropTypes.func
};

export default React.memo(ActivityDirections);
