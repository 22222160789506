import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%'
    },
    textInput: {
        '--textHeight': '40px',
        backgroundSize: '100% var(--textHeight)',
        lineHeight: 'var(--textHeight)',
        minHeight: 'calc((var(--textHeight) * 2) + 5px)',
        background: `linear-gradient( to bottom, ${theme.colors.white} 95%, ${theme.colors.white} 96%, #D1D3D3 100% )`,
    },
}));

const NoteTextArea = props => {
    const {
        className,
        disabled,
        text,
        onTextChange,
    } = props;

    const classes = useStyles();
    const defaultText = useRef(text);
    const textRef = useRef();

    useEffect(()=>{
        textRef.current.focus();
    }, []);

    useEffect(()=>{
        if (text === ''){
            textRef.current.innerText = '';
            textRef.current.focus();
        }
    }, [text]);

    const handleTextChange = (e) => {
        const html = e.target.innerText || '';
        const sanitized = html.replace(/<[^>]+>/g, '')

        if (typeof onTextChange === 'function') {
            onTextChange(sanitized);
        }
    }

    return (
        <div className={`${classes.wrapper} ${className}`}>
            <div
                ref={textRef}
                className={`note-text-area ${classes.textInput}`}
                onInput={handleTextChange}
                contentEditable={!disabled}>
                {defaultText.current}
            </div>
        </div>
    );
};

NoteTextArea.defaultProps = {
    className: '',
    text: '',
    disabled: false,
};
NoteTextArea.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool,
};

export default React.memo(NoteTextArea);
