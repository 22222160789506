import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { isFirefox } from '@reading/r180/src/utils/browsers';

let perToFixBodyZoom = 0;
let thumb = 24;
let railTrack = 16;
if (window.devicePixelRatio < 2 && window.devicePixelRatio > 1 && window.innerHeight <= 720) {
	if (!isFirefox()) {
		perToFixBodyZoom = 50;
		thumb = 17; // reduce size of thumb to balance the change in global zoom value
		railTrack = 11; // reduce height of railTrack to balance the change in global zoom value
	}
}


const styles = theme => ({
	root: {
		color: theme.colors.readingZone.primary,
		height: 8,
		'&:focus': {
			border: `6px solid ${theme.colors.paleGrey}`,
		},
	},
	thumb: {
		height: thumb,
		width: thumb,
		backgroundColor: theme.colors.readingZone.primary,
		border: `4px solid ${theme.colors.paleGrey}`,
		marginTop: -4,
		marginLeft: -12
	},
	thumbdisable: {
		display: 'none'
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 4px)'
	},
	track: {
		height: `${railTrack}px`,
		borderRadius: '12px',
		backgroundColor: theme.colors.readingZone.primary,
	},
	rail: {
		height: `${railTrack}px`,
		borderRadius: '12px',
		backgroundColor: theme.colors.white,
		opacity: 100,
	},
	slider: {
		zoom: `${100 + perToFixBodyZoom}% `,
	}
});

function SeekController({ classes, disabled, onSlide, sliderValue }) {
	const handleSlide = (event, val) => {
		onSlide(val);
	};

	return (
		<Slider
			classes={{
				track: classes.track,
				thumb: disabled ? classes.thumbdisable : classes.thumb,
				rail: classes.rail
			}}
			value={sliderValue * 100}
			disabled={disabled}
			aria-labelledby="Video Seek Controller"
			onChange={handleSlide}
			className={perToFixBodyZoom > 0 ? classes.slider : ''}
		/>
	);
}

SeekController.defaultProps = {
	classes: {},
	disabled: true
};
SeekController.propTypes = {
	classes: PropTypes.object,
	disabled: PropTypes.bool,
	onSlide: PropTypes.func.isRequired,
	sliderValue: PropTypes.number.isRequired
};

export default memo(withStyles(styles)(SeekController));
