import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {getAllQuestions, playResultVO} from './WordFamilyUtils';
import ResultRows from './ResultsRows';
import {useUnmount} from 'react-use';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';

export const useStyles = makeStyles(theme => ({
	resultsWrapper: {
		fontFamily: theme.fonts.context,
		color: theme.colors.black,
		padding: '2rem 2rem 0 2rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& .omission': {
			width: 'auto',
			textAlign: 'center',
			display: 'inline-block',
			'&.correct': {
				color: theme.colors.successGreen
			},
			'&.incorrect': {
				color: theme.colors.errorRed
			}
		}
	},
	correctCount: {color: theme.colors.successGreen},
	pageTitle: {
		textAlign: 'center',
		fontSize: '32px',
		fontFamily: theme.fonts.uiBold,
		marginBottom: '16px'
	}
}));

const WordFamilyResults = ({
	questionsList,
	correctResult,
	answeredChoice,
	setIntroVO
}) => {
	const classes = useStyles();

	const questionsSet = getAllQuestions(questionsList);
	const totalQuestions = correctResult.length;
	const totalCorrect = correctResult.filter(i => i).length;

	return (
		<div className={classes.resultsWrapper}>
			<div className={classes.pageTitle}>
				You answered{' '}
				<span className={classes.correctCount}>{totalCorrect}</span> of{' '}
				{totalQuestions} correctly!
			</div>
			<div>
				<ResultRows
					correctResult={correctResult}
					answeredChoice={answeredChoice}
					questionsSet={questionsSet}
					totalQuestions={totalQuestions}
				/>
			</div>
		</div>
	);
};

WordFamilyResults.propTypes = {
	questionsList: PropTypes.object,
	correctResult: PropTypes.array,
	answeredChoice: PropTypes.array,
	setInstructionVO: PropTypes.func
};

WordFamilyResults.defaultProps = {
	questionsList: {},
	correctResult: [],
	answeredChoice: []
};
export default React.memo(WordFamilyResults);
