import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {
	getCurrentQuestionTitle,
	getCurentQuestionChice,
	getChoiceSequence,
	playQuestionVO
} from './WordFamilyUtils';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import Choices from './Choices';
import SoundButton from '../../components/SoundButton';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import PropTypes from 'prop-types';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
	contentWrapper: {
		marginTop: '24px',
		fontFamily: theme.fonts.context,
		fontSize: '24px',
		color: theme.colors.black,
		textAlign: 'center',
		'& .omission': {
			minWidth: '150px',
			textAlign: 'center',
			borderBottom: `1px solid ${theme.colors.paleGrey}`,
			display: 'inline-block',
			color: theme.colors.darkBlue
		}
	},
	contentTitleWrapper: {
		display: 'flex',
		justifyContent: 'center',
		margin: ' 0 128px 0 96px'
	},
	title: {textAlign: 'left'},

	titleSoundButton: {
		verticalAlign: 'top',
		marginRight: '21px',
		transition: 'all 0.3s ease',
		'&.audioIcon': {
			background: 'none',
			color: theme.colors.slate,
			border: '0px',
			opacity: 0.5
		},
		'&.audioIconActive': {
			background: 'none',
			color: theme.colors.slate,
			border: '0px',
			opacity: 1
		}
	}
}));

const QuestionContent = props => {
	const {
		questionsList,
		currentIndex,
		updateSubmitStatus,
		isResetQuestion,
		setResetQuestion,
		setCorrectResults,
		setAnsweredChoice,
		correctResult,
		answeredChoice,
		stageDetails,
		screenViewIndex,
		isAnimPlayingScreen,
		isVoiceOverComplete,
		isSpecificWordStatus,
		setForwardDisabled,
		setScreenViewIndex,
		setInstructionDisabled,
		isZoneMenuModalOpen,
		isExitModalOpen,

	} = props;

	const questionVoiceOver = useRef('');

	const classes = useStyles();
	const {setState} = useBatchedSetState();

	const [selectedText, setSelectedText] = useState('');
	const [choiceIndex, setChoiceIndex] = useState('');
	const [soundValues, setSoundValues] = useState({});
	const [questionMarkup, setQuestionMarkup] = useState(
		getCurrentQuestionTitle(currentIndex, questionsList, selectedText)
	);
	const [isAudioDisabled, setAudioStatus] = useState(true);
	const [questionVO, setQuestionVP] = useState('sample');
	const [getQuestionChoice, setQuestionChoice] = useState(
		getCurentQuestionChice(currentIndex, questionsList)
	);
	const [choiceSequenceArray, setChoiceSequenceArray] = useState(
		getChoiceSequence(getQuestionChoice)
	);

	const initiateAudio = async(e, questionID, choiceID) => {
		AudioPlayer2.stopAll();
		if (soundValues.hasOwnProperty(`${questionID}${choiceID}`)) {
			await AudioPlayer2.playSync(`${questionID}${choiceID}VOAudio`);
		} else {
			AudioPlayer2.load({
				src: questionVoiceOver.current,
				name: `${questionID}${choiceID}VOAudio`
			});
			await AudioPlayer2.playSync(`${questionID}${choiceID}VOAudio`);
		}
		setForwardDisabled(false);
	};

	const onChoiceSelect = e => {
		setForwardDisabled(true);
		setState(() => {
			setAudioStatus(false);
			setSelectedText(e.currentTarget.textContent);
			answeredChoice[currentIndex] = e.currentTarget.textContent;
			setAnsweredChoice(answeredChoice);
			correctResult[currentIndex] = getQuestionChoice.filter(
				i => i.answer_text === e.currentTarget.innerText
			)[0].correct;
			setCorrectResults(correctResult);
			setChoiceIndex(
				choiceSequenceArray.indexOf(e.currentTarget.textContent)
			);
		});

		let {voiceOver, questionID, choiceID} = playQuestionVO(
			e.currentTarget.textContent,
			questionsList,
			currentIndex,
			stageDetails
		);
		let obj = {};
		questionVoiceOver.current = voiceOver;
		obj[`${questionID}${choiceID}`] = questionVoiceOver.current;

		setState(() => {
			setQuestionVP(voiceOver);
			initiateAudio(e, questionID, choiceID);
			setSoundValues(prevState => ({
				...prevState,
				...obj
			}));
			updateSubmitStatus('submit');
			//setForwardDisabled(false);
		});
	};

	useEffect(() => {
		setState(() => {
			setQuestionMarkup(
				getCurrentQuestionTitle(
					currentIndex,
					questionsList,
					selectedText
				)
			);
			setQuestionChoice(
				getCurentQuestionChice(currentIndex, questionsList)
			);
			setChoiceSequenceArray(getChoiceSequence(getQuestionChoice));
			/* Reset Selections on TryAgain */
			/* istanbul ignore else */
			if (isResetQuestion) {
				setResetQuestion(false);
				setSelectedText('');
				setChoiceIndex('');
				setAudioStatus(true);
			}
		});
	}, [
		questionsList,
		selectedText,
		currentIndex,
		isResetQuestion,
		getQuestionChoice,
		setResetQuestion
	]);

	return (
		<div className={classes.contentWrapper}>
			{screenViewIndex === 2 && (
				<div className={classes.contentTitleWrapper}>
					<SoundButton
						className={`${classes.titleSoundButton} ${
							isAudioDisabled ? 'audioIcon' : 'audioIconActive'
						}`}
						icon="speaker-center"
						id={`QuestionTitle`}
						disabled={isAudioDisabled}
						size={32}
						sound={{
							name: 'QuestionTitleVO',
							src: questionVO.toLowerCase()
						}}
					/>
					<span
						className={classes.title}
						dangerouslySetInnerHTML={{
							__html: stripHtmlTags(questionMarkup)
						}}
					></span>
				</div>
			)}
			<Choices
				choiceList={getQuestionChoice}
				onChoiceSelect={onChoiceSelect}
				choiceIndex={choiceIndex}
				screenViewIndex={screenViewIndex}
				isAnimPlayingScreen={isAnimPlayingScreen}
				isVoiceOverComplete={isVoiceOverComplete}
				isSpecificWordStatus={isSpecificWordStatus}
				setForwardDisabled={setForwardDisabled}
				setScreenViewIndex={setScreenViewIndex}
				setInstructionDisabled={setInstructionDisabled}
				answeredChoice={answeredChoice}
				currentIndex={currentIndex}
				isZoneMenuModalOpen={isZoneMenuModalOpen}
				isExitModalOpen={isExitModalOpen}
			/>
		</div>
	);
};

QuestionContent.propTypes = {
	questionsList: PropTypes.object,
	currentIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	updateSubmitStatus: PropTypes.func,
	isResetQuestion: PropTypes.bool,
	setResetQuestion: PropTypes.func,
	setCorrectResults: PropTypes.func,
	setAnsweredChoice: PropTypes.func,
	correctResult: PropTypes.array,
	answeredChoice: PropTypes.array,
	stageDetails: PropTypes.object,
	screenViewIndex: PropTypes.number,
	isAnimPlayingScreen: PropTypes.bool,
	voiceOverComplete: PropTypes.bool,
	isSpecificWordStatus: PropTypes.bool
};

QuestionContent.defaultProps = {
	currentIndex: 0,
	correctResult: [],
	answeredChoice: [],
	stageDetails: {},
	screenViewIndex: 0,
	voiceOverComplete: false,
	isAnimPlayingScreen: true,
	isSpecificWordStatus: false
};
export default React.memo(QuestionContent);
