
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
export const dragEndHandler = (
	result,
	setDestAudioMap,
	setUpdatedRightAudio,
	setUpdatedLeftAudio,
	destAudioMap,
	words,
	enableSubmit,
	updatedRightAudio,
	updatedLeftAudio,
	setResultSet,
	resultSet
) => {
	const { destination, source } = result;
	let resultSetObj = resultSet;
	AudioPlayer2.stopAll();
	if (!destination || destination.droppableId === source.droppableId) {
		return;
	}
	if (updatedLeftAudio && typeof (updatedLeftAudio[destination.index]) !== "undefined") {
		return;
	}

	const sourceIndex = parseInt(
		result.destination.index
	);

	const draggedItemIndex = parseInt(
		result.source.index
	);
	let draggedWord = source.droppableId.split("_")[1];
	let draggedWordObj = words.filter(word => word.name === draggedWord)[0];
	const updatedDestAudio = {
		...destAudioMap,
		[sourceIndex]: true
	};
	const leftDraggedSet = [...updatedLeftAudio];
	leftDraggedSet[sourceIndex] = draggedWordObj;
	setUpdatedLeftAudio(leftDraggedSet);

	const updatedSourceAudio = [...updatedRightAudio];
	updatedSourceAudio[draggedItemIndex].name = 'empty';
	setUpdatedRightAudio(updatedSourceAudio);
	setDestAudioMap(updatedDestAudio);

	if (typeof (resultSetObj) !== "undefined") {
		const droppableWord = destination.droppableId.split("_")[1].trim();
		if (draggedWord === droppableWord) {
			resultSetObj[draggedWord] = true;
		}
		else {
			resultSetObj[draggedWord] = false;
		}
		let resultSetTempObj = {
			...resultSet, ...resultSetObj
		};
		if (Object.keys(updatedDestAudio).length === 5) {
			enableSubmit('Submit', resultSetTempObj);
		}
		setResultSet(resultSetTempObj);
	}

};
