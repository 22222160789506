import React, {useRef, useState, useEffect} from 'react';
import {
	ActivityInstructionButton,
	ActivitySupportButton,
	SpellingClinicItem,
	ComponentSwitcher,
	StartActivity
} from '@reading/common';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import {useDeepCompareEffect} from 'react-use';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {useDispatch, shallowEqual, useSelector} from 'react-redux';
import {uiSlice} from '../../store/slices/ui';
import {isEmpty} from 'lodash';
import {WordClinic} from './WordClinic';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import {useUnmount} from 'react-use';
import ActivityFrame from '../../containers/App/ActivityFrame';
import {sleep} from '../../utils/sleep';

const useStyles = makeStyles(theme => ({
	disableIcon: {
		opacity: '0.5',
		'& div': {
			cursor: 'default'
		}
	},
	wrapper: {
		position: 'relative',
		margin: 'auto',
		height: '100%'
	}
}));

export const handleForward = async (
	screenIndex,
	setScreenIndex,
	studyWordIndex,
	wordCardIndex,
	setWordCardIndex,
	dispatch,
	setStudyWordIndex,
	wordsList,
	isGoOnDisabled,
	setInstructionVO,
	setIconDisabled,
	history,
	activityData,
	activityStartTime,
	setWhiteBackGround,
	setFinishedWithIntro,
	setGoOnDisabled
) => {
	if (screenIndex >= WordClinic.LAST_SCREEN) {
		try {
			await WordClinic.completeActivity(history, activityData);
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
		return false;
	}

	if (isGoOnDisabled) {
		return false;
	}

	AudioPlayer2.stopAll();

	/*  Using Pre increment (++screenIndex) to increment screen count */
	// This code is not done correctly - incrementing a const which became a variable
	// becuase it was passed in to the function.  I tried a few different ways to get
	// this function inside the functional component but all the screens are very touchy
	// and broke in every attempt I made.  So I just left this as is.
	setScreenIndex(++screenIndex);

	if (screenIndex === WordClinic.LAST_SCREEN) {
		/*  Using Pre increment (++studyWordIndex) to increment screen count*/
		// This code is not done correctly - incrementing a const which became a variable
		// becuase it was passed in to the function.  I tried a few different ways to get
		// this function inside the functional component but all the screens are very touchy
		// and broke in every attempt I made.  So I just left this as is.
		setStudyWordIndex(++studyWordIndex);
		AudioPlayer2.play('WordClinic_Complete');
		setInstructionVO({src: WordClinic.WORD_CLINIC_COMPLETE, name: 'WordClinic_Complete'});

		WordClinic.sendProgressToServer(activityData);
	} else {
		WordClinic.sendProgressToServer(activityData);
	}

	if (screenIndex % 3 === WordClinic.WORD_CARD_SCREEN) {
		setGoOnDisabled(true);
		setInstructionVO({
			src: WordClinic.WordClinicExplain[1].src,
			name: 'word_Explain_1'
		});
	}

	if (screenIndex % 3 === WordClinic.RECORD_NEXT_SCREEN) {
		setInstructionVO({name: '', src: ''});
		setIconDisabled(true);
	}

	if (screenIndex % 3 === WordClinic.RECORD_SCREEN) {
		AudioPlayer2.play('word_Explain_2');
		setInstructionVO({
			src: WordClinic.WordClinicExplain[2].src,
			name: 'word_Explain_2'
		});
	}

	if (screenIndex === WordClinic.LAST_BUT_SCREEN) {
		setIconDisabled(false);
		AudioPlayer2.stopAll();
		/* to  avoid the 2 VO playing at a time */
		setWhiteBackGround(true);
		await sleep(4800);
		await AudioPlayer2.playSync('word_oneword_Left');
		setInstructionVO({name: 'word_oneword_Left', src: WordClinic.WORD_BEFORE_LAST});
	}

	/* istanbul ignore else */
	if (screenIndex > WordClinic.LANDING_SCREEN) {
		const studyWordCount = screenIndex === 1 || (screenIndex - 1) % 3 === 0 ? studyWordIndex + 1 : studyWordIndex;

		/* Based on  Even and Odd Screens  wordcard and playback, white background updated */
		if (screenIndex === 1 || (screenIndex - 1) % 3 === 0) {
			setWhiteBackGround(false);
		} else {
			setWhiteBackGround(true);
		}

		setWordCardIndex(studyWordCount);
		setStudyWordIndex(studyWordCount);

		if (screenIndex === 1) {
			await AudioPlayer2.playSync('word_Explain');
			await AudioPlayer2.playSync('word_Explain_1');
			setFinishedWithIntro(true);
		}
	}
};

const WordClinicActivity = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const classes = useStyles();

	const {activityData, studyList, isActivityCharged, settings} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			studyList: state.activity.studylist,
			isActivityCharged: state.ui.chargedActivities['word-clinic'] === true,
			settings: state.session.session.settings
		};
	}, shallowEqual);

	const [isStarted, setStarted] = useState(false);
	const [wordsList, setWordsList] = useState([]);
	const [screenIndex, setScreenIndex] = useState(0);
	const [buttonStatus, setButtonStatus] = useState('valid');
	const [studyWordIndex, setStudyWordIndex] = useState(0);
	const [wordCardIndex, setWordCardIndex] = useState(0);
	const [isGoOnDisabled, setGoOnDisabled] = useState(true);
	const [instructionVO, setInstructionVO] = useState({
		name: 'WCIntro',
		src: WordClinic.INTRO_VO
	});
	const [isIconDisabled, setIconDisabled] = useState(false);
	const [hasWhiteBackground, setWhiteBackGround] = useState(true);
	const [isFinishedWithIntro, setFinishedWithIntro] = useState(false);
	const [isWordButtonEnabled, setWordButtonEnabled] = useState(true);

	const activityStartTime = useRef(new Date());

	const isActivity = activityData ? activityData.activityServerId === 'word_clinic' : false;

	/* istanbul ignore next */
	useDeepCompareEffect(() => {
		const initialiazeData = async () => {
			const finalData = await WordClinic.initData(studyList);
			setWordsList(finalData);
			if (isActivityCharged === false) {
				await WordClinic.loadAudioFiles(finalData, activityData);
				dispatch(uiSlice.actions.setActivityCharged('word-clinic'));
				if (settings.autoPlayEnabled === true) {
					await sleep(1000);
					AudioPlayer2.play('WCIntro');
				}
			}
		};

		if (!isEmpty(studyList) && isActivity) {
			initialiazeData();
		}
	}, [studyList]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	const showStaticScreen = screenIndex !== 0 && screenIndex % 3 === 0 && wordCardIndex - 1 !== 5;
	let staticScreenClicked = false;

	const timerFunc = () =>
		setTimeout(() => {
			/* STATIC WORD LIST SCREEN : Progress to nextscreen only
		     	when the user click is not triggered */
			!staticScreenClicked && handleGoOn();
			staticScreenClicked = false;
		}, 2000);

	useEffect(() => {
		/* istanbul ignore else*/

		if (showStaticScreen) {
			timerFunc();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showStaticScreen]);

	/* istanbul ignore next */
	const handleGoOn = () => {
		if (showStaticScreen) {
			/* STATIC  WORD LISTSCREEN :indicates that User Click already triggered and cancels
			   timer functions which loads next screen */
			staticScreenClicked = true;
		}
		handleForward(
			screenIndex,
			setScreenIndex,
			studyWordIndex,
			wordCardIndex,
			setWordCardIndex,
			dispatch,
			setStudyWordIndex,
			wordsList,
			isGoOnDisabled,
			setInstructionVO,
			setIconDisabled,
			history,
			activityData,
			activityStartTime.current,
			setWhiteBackGround,
			setFinishedWithIntro,
			setGoOnDisabled
		);
	};

	const handleWordClick = () => {
		if (isEmpty(wordsList[wordCardIndex - 1]) === false) {
			AudioPlayer2.play(wordsList[wordCardIndex - 1].text);
		}
	};

	const enableGoOn = () => {
		setGoOnDisabled(false);
	};

	const handleRecording = isRecording => {
		if (isRecording) {
			setWordButtonEnabled(false);
		} else {
			setWordButtonEnabled(true);
		}
	};

	const handleStartActivity = async () => {
		AudioPlayer2.stopAll();
		setStarted(true);
	};

	const showWordButton = screenIndex === wordCardIndex * 2 + (wordCardIndex - 1);
	const isWordCardEnabled = WordClinic.WORDCARD_SCREEN_LIST.indexOf(screenIndex) > -1;

	return (
		<>
			<Navbar helpSoundUrl={WordClinic.SCREEN_HELP_VO} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={wordsList}
				title="Word Clinic"
			>
				<ActivityFrame isWhiteBackground={hasWhiteBackground}>
					<>
						<ActivityInstructionButton
							audioName={instructionVO.name}
							className={`${isIconDisabled ? classes.disableIcon : ''}`}
							white={isWordCardEnabled}
						/>
						{wordsList.length && (
							<div className={classes.wrapper} data-testid={'studylist'}>
								{isStarted === false && (
									<StartActivity
										onStartActivity={handleStartActivity}
										startActivityAudioSrc={WordClinic.INTRO_VO}
									/>
								)}
								{isStarted && screenIndex === 0 && (
									<SpellingClinicItem
										wordsList={wordsList}
										configData={WordClinic}
										showAudio={false}
										enableGoOn={enableGoOn}
									/>
								)}
								{isStarted && screenIndex > 0 && (
									<ComponentSwitcher
										wordsList={wordsList}
										studyWordIndex={studyWordIndex - 1}
										wordCardIndex={wordCardIndex}
										setGoOnDisabled={setGoOnDisabled}
										handleForward={handleGoOn}
										configData={WordClinic}
										screenIndex={screenIndex}
										isFinishedWithIntro={isFinishedWithIntro}
										onRecording={handleRecording}
									/>
								)}
							</div>
						)}
					</>
				</ActivityFrame>
				<Footer>
					{showWordButton && (
						<ActivitySupportButton
							icon="word"
							onClick={handleWordClick}
							text="Word"
							disabled={isWordButtonEnabled === false}
						/>
					)}
					<FooterForwardBack
						isForwardDisabled={isGoOnDisabled}
						status={buttonStatus}
						onForward={handleGoOn}
						isBackVisible={false}
					/>
				</Footer>
			</ActivitySuspense>
		</>
	);
};
export default React.memo(WordClinicActivity);
