import { getAudioExt } from "../../../utils/audio";
import { FS_MEDIA_SERVER_URL } from "../../../utils/constants";
import { MEDIA_SERVER_URL } from '../../../utils/constants';

const EXT = getAudioExt();

export const ProgressMonitorConstants = {
	ACTIVITY_TITLE: 'Progress Monitor',
	DEFAULT_INTRO_VO: 'lintro',
	INTRO_VIDEO: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_lintro_code.mp4`,
	REPONSE_TIME_LIMIT: 1000 * 10,
	FLUNCEY_THRESHOLD: 1000 * 3,
	ACTIVITY_COMPLETION_PER: 70,
	WORD_PATH: `${MEDIA_SERVER_URL}assets/fs/dictio/word/fs_word_{wordtext}.${EXT}`,
	WORD_SENTENCE_PATH: `${MEDIA_SERVER_URL}assets/fs/dictio/ctxt/fs_ctxt_{wordtext}.${EXT}`,
	MAX_TRAIL_COUNT: 3,
	MAX_DISTRACTION_WORDS: 4,
	FLUENT: 'Fluent',
	MISSED: 'Missed',
	OUTOFTIME: 'Out Of Time',
	DEFAULT_TIME_OUT: 15,
	TARGET: 'target',
	CompletionCriteria: ['10', '22', '23', '24', '32', '33', '34', '42', '43', '44'],
	TargetEvaluationMap: {
		'100': 1,
		'211': 1,
		'212': 2,
		'213': 2,
		'214': 2,
		'311': 1,
		'312': 2,
		'313': 3,
		'314': 3,
		'411': 1,
		'412': 2,
		'413': 3,
		'414': 4,
		'220': 2,
		'230': 3,
		'240': 2,
		'330': 3,
		'320': 3,
		'340': 3,
		'440': 4,
		'420': 4,
		'430': 3
	},
	ReviewEvaluationMap: {
		'100': 1,
		'200': 2,
		'300': 3,
		'400': 4
	},
	TRIAL_SCORE: {
		1: 'fluent',
		2: 'correct',
		3: 'missed',
		4: 'out_of_time'
	},
	AO_LONG_INTRO: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_lintro_ha.${EXT}`, // temp using lintro VO, aolintro (not yet recorded)
	AO_SHORT_INTRO: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_sintro_1_ha.${EXT}`, // temp using sintro_1 VO, aolintro (not yet recorded)
	LONG_INTRO: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_lintro_ha.${EXT}`,
	SHORT_INTRO: [
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_sintro_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_sintro_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_sintro_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_sintro_4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_sintro_5_ha.${EXT}`,
	],
	COMPLETE: [
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_actcomplete_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_actcomplete_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_actcomplete_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_actcomplete_4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_actcomplete_5_ha.${EXT}`,
	],
	PLAY: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_play_ha.${EXT}`,
	PAUSE: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_pause_ha.${EXT}`,
	CONTINUE: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_continue_ha.${EXT}`,
	FEEDBACK_HELP: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_feedbhelp1_ha.${EXT}`,
	HELP_1: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_acthelp1_ha.${EXT}`,
	HELP_2: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_acthelp2_ha.${EXT}`,
	HELP_3: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_acthelp3_ha.${EXT}`,
	FEEDBACK_FLUENT: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_fluent_ha.${EXT}`,
	FEEDBACK_SLOW: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_correct_ha.${EXT}`,
	FEEDBACK_MISSED: `${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_missed_ha.${EXT}`,

	FEEDBACK_PASS_TOPICLESS: [
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_pass_topicless_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_pass_topicless_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_pass_topicless_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_pass_topicless_4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_pass_topicless_5_ha.${EXT}`,
	],
	FEEDBACK_PROPASS_TOPIC: [
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_propass_topicequal_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_propass_topicequal_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_propass_topicequal_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_propass_topicequal_4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_propass_topicequal_5_ha.${EXT}`,
	],
	FEEDBACK_NOPASS_WORDLOW: [
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_nopass_rwordlow_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_nopass_rwordlow_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_nopass_rwordlow_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_nopass_rwordlow_4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_nopass_rwordlow_5_ha.${EXT}`,
	],
	FEEDBACK_PROMOTION_CLOSE: [
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_promotionclose_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_promotionclose_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_promotionclose_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_promotionclose_4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_promotionclose_5_ha.${EXT}`,
	],
	FEEDBACK_STUDYWORDS_LOWREP: [
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_lowrep_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_lowrep_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_lowrep_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_lowrep_4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_lowrep_5_ha.${EXT}`,
	],
	FEEDBACK_STUDYWORDS_HIGHREP: [
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_highrep_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_highrep_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_highrep_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_highrep_4_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/fpgm/fs_fpgm_studywords_highrep_5_ha.${EXT}`,
	]
}
