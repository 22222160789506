import React, {useEffect, useState, useRef} from 'react';
import {useUnmount} from 'react-use';
import PropTypes from 'prop-types';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';
import ReadingQuestionPanel from '../ReadingQuestionPanel/ReadingQuestionPanel';
import {bulkReplace, stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import {createAssetId} from '@reading/r180/src/media/mediaUtil';
import {sleep} from '@reading/r180/src/utils/sleep';
const EXT = getAudioExt();

const BUTTON_NAMES = {
	SUBMIT: 'Submit',
	GOON: 'Go On',
	TRYAGAIN: 'Try Again'
};
const BUTTON_ICONS = {
	CHECK: 'check',
	ARROW_RIGHT: 'arrow-right',
	RESET: 'reset'
};
const MAX_ATTEMPTS = 3;
export const MEDIA = {
	TITLE_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_highlight_q{dataId}_question.${EXT}`
};

const HighlightingPassage = props => {
	const {
		data,
		stage,
		segment,
		level,
		onGoOnClicked,
		onDeleteIconClicked,
		selectedHighlightedOptions,
		onAnswerCheck
	} = props;
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
	const [attempts, setAttempts] = useState(0);
	const [isCorrect, setCorrect] = useState(true);
	const [interactive, setInteractive] = useState(true);
	const [answers, setAnswers] = useState([]);
	const [highlightOptions, setHighlightOptions] = useState([]);
	const [buttonName, setButtonName] = useState(BUTTON_NAMES.SUBMIT);
	const [buttonIcon, setButtonIcon] = useState(BUTTON_ICONS.CHECK);
	const timeout = useRef(false);

	useEffect(() => {
		setAnswers(data.answers);
	}, [data]);

	useEffect(() => {
		const maximumAllowableResponses =
			data.maximum_allowable_responses === null
				? answers.length
				: data.maximum_allowable_responses;
		setIsSubmitEnabled(
			selectedHighlightedOptions.length === maximumAllowableResponses
		);
	}, [selectedHighlightedOptions, answers]);

	useEffect(() => {
		setHighlightOptions(selectedHighlightedOptions);
	}, [selectedHighlightedOptions]);

	useUnmount(() => {
		clearTimeout(timeout.current);
		AudioPlayer2.stopAll();
	});

	const handleSelect = async () => {
		if (!isSubmitEnabled) {
			return;
		}
		AudioPlayer2.stopAll();
		switch (buttonName) {
			case BUTTON_NAMES.SUBMIT: {
				const newOptions = highlightOptions.map(option => {
					const isCorrectAnswer =
						answers.filter(answer => answer.id === option.id)
							.length > 0;
					if (isCorrectAnswer) {
						return {...option, status: 'correct'};
					} else {
						return {...option, status: 'incorrect'};
					}
				});

				const correctOptionsLength = newOptions.filter(
					option => option.status === 'correct'
				).length;
				const maximumAllowableResponses =
					data.maximum_allowable_responses === null
						? answers.length
						: data.maximum_allowable_responses;

				// All answers are correct
				if (correctOptionsLength === maximumAllowableResponses) {
					setCorrect(true);
					onAnswerCheck(false);
					setIsSubmitEnabled(true);
					setButtonName(BUTTON_NAMES.GOON);
					setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
					setAttempts(attempts => attempts + 1);
					setInteractive(false);
					setHighlightOptions(newOptions);
					await AudioPlayer2.playSync(
						`compcorrattempt${attempts + 1}`
					);
					timeout.current = setTimeout(() => {
						onAnswerCheck(true);
					}, 2000);
				} else {
					setCorrect(false);
					if (attempts === MAX_ATTEMPTS - 1) {
						onAnswerCheck(false);
						setHighlightOptions(newOptions);
						setInteractive(false);
						setIsSubmitEnabled(true);
						setButtonName(BUTTON_NAMES.GOON);
						setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
						setAttempts(attempts => attempts + 1);
						await AudioPlayer2.playSync(
							`compincorrattempt${attempts + 1}`
						);
						timeout.current = setTimeout(() => {
							onAnswerCheck(true);
						}, 2000);
					} else {
						onAnswerCheck(false);
						setInteractive(false);
						setIsSubmitEnabled(false);
						setHighlightOptions(newOptions);
						setAttempts(attempts => attempts + 1);
						await AudioPlayer2.playSync(
							`compincorrattempt${attempts + 1}`
						);
						timeout.current = setTimeout(() => {
							onAnswerCheck(true);
							setInteractive(true);
							setButtonName(BUTTON_NAMES.SUBMIT);
							setButtonIcon(BUTTON_ICONS.CHECK);
							setIsSubmitEnabled(true);
							setHighlightOptions(selectedHighlightedOptions);
						}, 1000);
					}
				}
				break;
			}
			default: {
				onAnswerCheck(true);
				onGoOnClicked(attempts, isCorrect);
				break;
			}
		}
	};
	return (
		<ReadingQuestionPanel
			title={stripHtmlTags(data.stem)}
			titleSound={{
				name: `Title_${data.id}`,
				src: bulkReplace(MEDIA.TITLE_SOUND, {
					asset_id: createAssetId(stage, segment, level, true),
					dataId: data.id
				})
			}}
			questionType={data.type}
			questions={highlightOptions}
			onAnswerChange={onDeleteIconClicked}
			onSubmit={handleSelect}
			isSubmitEnabled={isSubmitEnabled}
			buttonText={buttonName}
			buttonIcon={buttonIcon}
			showResetButton={false}
			interactive={interactive}
		/>
	);
};

HighlightingPassage.propTypes = {
	data: PropTypes.object.isRequired,
	stage: PropTypes.string.isRequired,
	segment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onGoOnClicked: PropTypes.func.isRequired,
	onDeleteIconClicked: PropTypes.func.isRequired,
	selectedHighlightedOptions: PropTypes.array.isRequired,
	onAnswerCheck: PropTypes.func.isRequired
};

export default React.memo(HighlightingPassage);
