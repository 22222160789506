import { cloneDeep, isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import { useHistory, useRouteMatch } from 'react-router-dom';
import media from '../../api/media';
import { getContentInfo } from '../../media/mediaUtil';
import { uiSlice } from '../../store/slices/ui';
import {
	AcronymBanner,
	ActivityButton,
	CaptionedAudioPlayer2 as AudioPlayer2,
	WritingAcronymBoard,
	VideoModal,
	WritingTools,
	ActivityInstructionButton
} from '@reading/common';
import useIdleHelp from '../../utils/useIdleHelp';
import Navbar from '../../containers/App/Navbar';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import { Writing } from './Writing';
import { makeStyles } from '@material-ui/core';
import { WritingPanelContainer } from '@reading/common';
import { WritingPanel } from '@reading/common';
import { InactiveWritingPanel } from '@reading/common';
import { CompletedWritingPanel } from '@reading/common';
import { Scratchpad } from '@reading/common';
import { PassageWindow } from '@reading/common';
import { WordCardModal } from '@reading/common';
import { StaticPanel } from '@reading/common';
import { ReviewPanel, ReviewLayout } from '@reading/common';
import { WritingPrompt } from '@reading/common';
import { AppModal } from '@reading/common';
import { AddNewPanel } from '@reading/common';

import { bulkReplace, displaySafe } from '../../utils/stringUtils';
import { KnowledgeForReading } from '../WordCard/KnowledgeForReading';
import ActivityFrame from '../../containers/App/ActivityFrame';
import { Fragment } from 'react';
import { sleep } from '../../utils/sleep';
import useBatchedSetState from '../../utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
	'@keyframes goLeft': {
		'0%': {
			left: '0'
		},
		'100%': {
			left: '-168px'
		}
	},
	wrapper: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 24px'
	},
	boardWrapper: {
		position: 'relative',
		marginTop: '3rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	writingContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		'& .writing-acronym-board': {
			marginBottom: '60px'
		}
	},
	panelWrapper: {
		display: 'flex',
		columnGap: '12px',

		'& .writing-panel-container': {
			width: '648px',
			'&.animating': {
				animation: '$goLeft 0.5s ease'
			}
		}
	}
}));

const WritingActivity = props => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { setState } = useBatchedSetState();
	const { path: wzPath } = useRouteMatch();

	const ref = useRef();

	const { activityData, settings, user } = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			settings: state.session.session.settings,
			user: state.session.session.user
		};
	}, shallowEqual);

	// check if the activity is already charged
	let isActivityCharged = useSelector(state => {
		return state.ui.chargedActivities['writing'] === true;
	});

	const [contentData, setContentData] = useState({});

	const [renderData, setRenderData] = useState({});
	const [currentPanelIndex, setCurrentPanelIndex] = useState(0);
	const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
	const [isWritingComplete, setWritingComplete] = useState(false);
	const [selectedLetter, setSelectedLetter] = useState('');

	const [isPassageVisible, setPassageVisible] = useState(false);
	const [isScrachPadVisible, setScratchPadVisible] = useState(false);
	const [selectedPowerWord, setSelectedPowerWord] = useState({});
	const [activityState, setActivityState] = useState(Writing.ACTIVITY_STATE.INITIAL);

	const [wordCards, setWordCards] = useState([]);
	const [wordCardMap, setWordCardMap] = useState({});
	const [selectedWordCard, setSelectedWordCard] = useState({});
	const [powerWords, setPowerWords] = useState([]);
	const [videoInfo, setVideoInfo] = useState({});
	const [isVideoVisible, setVideoVisible] = useState(false);
	const [completedPanelData, setCompletedPanel] = useState({});
	const [showDraftPanel, setDraftPanel] = useState(false);
	const [panelsID, setPanelID] = useState([]);
	const [helpVO, setHelpVO] = useState(renderData.type ? Writing.SOUNDS[renderData.type].HELP : Writing.HELP);
	const [disableGoOn, setGoOnStatus] = useState(false);
	const [choiceSelected, updateSelection] = useState();
	const [isResetModalVisible, setResetModalVisible] = useState(false);
	const [resetSectionDetails, updateResetSection] = useState({});
	const [isResetClicked, setResetClicked] = useState(false);
	const [panelGOStatus, setPanelGOStatus] = useState([]);
	const [writingToolsSpellCheck, setWritingToolsSpellCheck] = useState({});
	const [writingToolsTiredWords, setWritingToolsTiredWords] = useState({});
	const [isSpellingCheckEnabled, setSpellingCheckEnabled] = useState(false);
	const [isTiredWordsEnabled, setTiredWordsEnabled] = useState(false);
	const [isSentenceSenseEnabled, setSentenceSenseEnabled] = useState(false);
	const [newPanelIndex, setNewPanelIndex] = useState(1);
	const [uniqueIDMap, setUniqueIDMap] = useState({});
	const [isAddPanelDisabled, setAddPanelDisabled] = useState(true);
	const [showLoader, setShowLoader] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [writingToolsInfo, setWritingToolsInfo] = useState({});
	const [paragraphs, setParagraphs] = useState();
	const [reviewLayoutInfo, setReviewLayoutInfo] = useState({});

	const loaderTitle =
		activityState === Writing.ACTIVITY_STATE.INITIAL ? Writing.PUBLISH_LOADER_TITLE : Writing.PROGRESS_LOADER_TITLE;
	const loaderMessage = activityState === Writing.ACTIVITY_STATE.INITIAL ? 'Sweet!' : 'One Second';
	const [activityServerId, setActivityServerId] = useState();
	const [isAnimatingReview, setAnimatingReview] = useState(false);
	const [scratchText, setScratchText] = useState('');

	useDeepCompareEffect(() => {
		const loadMedia = async () => {
			const activityName = Writing.getActivityName(wzPath);
			const _activityData = cloneDeep(activityData);
			_activityData.activityServerId = activityName;

			const { urlPath } = getContentInfo(_activityData, true);

			if (urlPath !== '/') {
				const mediaData = await media.getOne(urlPath);
				setContentData(mediaData);
			}
		};

		if (isEmpty(activityData) === false) {
			loadMedia();
		}
		if (isEmpty(activityData) || activityServerId !== activityData.activityServerId) {
			loadMedia(activityServerId, 'activityServerId');
		}
	}, [activityData.activityServerId]);

	useDeepCompareEffect(() => {
		const init = async () => {
			const {
				wordCards,
				newWordCardMap,
				renderData,
				videoUrl,
				videoCaptionInfo,
				choiceSelected,
				writingToolsSpellCheckObj,
				writingToolsTiredWordsObj,
				passageText
			} = await Writing.init(contentData, settings, isActivityCharged);

			//////////
			// Create the data object needed for the 'Power Words' dropdown
			//////////
			const challengeWords = Object.keys(newWordCardMap).map(word => {
				return {
					word: displaySafe(word),
					sound_name: word,
					sound_src: bulkReplace(KnowledgeForReading.WORD_SOUND_URL, {
						word: word,
						asset_id: Writing.getAssetId(contentData.contentId, false)
					})
				};
			});

			setState(() => {
				setWordCards(wordCards);
				setWordCardMap(newWordCardMap);
				setRenderData(renderData);
				setVideoInfo({ videoUrl, videoCaptionInfo });
				setPanelID(getPanelSeqence(renderData));
				setPanelGOStatus(goOnButtonStatus(renderData));

				setWritingToolsSpellCheck(writingToolsSpellCheckObj);
				setWritingToolsTiredWords(writingToolsTiredWordsObj);

				setParagraphs(passageText);
				setPowerWords(challengeWords);
			});

			isActivityCharged = true;
			dispatch(uiSlice.actions.setActivityCharged('writing'));
		};

		if (isEmpty(contentData) === false) {
			init();
		}
	}, [contentData]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	const resetClicked = flag => setResetClicked(flag);

	const updatePanelsGo = goOnStaus => setPanelGOStatus(goOnStaus);

	const updateWritingPrompt = () => {
		//setWritingPromptComplete(true);
		setGoOnStatus(true);
	};

	const getPanelSeqence = renderData => {
		const panelID = [];
		renderData.panels.forEach(i => {
			panelID.push(i.id);
			i.sections.length > 1 &&
				i.sections.forEach((item, index) => {
					index !== 0 && panelID.push(item.id);
				});
		});
		return panelID;
	};

	const goOnButtonStatus = renderData => {
		const finalObj = {};
		renderData.panels.forEach(i => {
			if (i.sections.length > 1) {
				i.sections.forEach((item, index) => {
					finalObj[`${i.id}${item.id}`] = true;
				});
			} else {
				finalObj[i.id] = true;
			}
		});
		return finalObj;
	};

	const getPanelAudio = (contentData, panelsID, sectionId, currentPanelIndex) => {
		switch (contentData.type) {
			case 'informative': {
				const panelIndex =
					sectionId === 'thesis' || sectionId === 'detail2' ? currentPanelIndex + 1 : currentPanelIndex;
				if (sectionId !== 'idea') {
					const panelVOSrc =
						Writing.WRITING_PANELS[contentData.type][`panel${panelIndex}`][
							panelsID[panelsID.indexOf(sectionId) + 1]
						].name;
					const panelHelpSrc =
						Writing.WRITING_PANELS.help[contentData.type][`panel${panelIndex}`][
						panelsID[panelsID.indexOf(sectionId) + 1]
						];
					return { panelHelpSrc, panelVOSrc };
				} else {
					const panelVOSrc =
						Writing.WRITING_PANELS[contentData.type][`panel${panelIndex}`][
							panelsID[panelsID.indexOf(`${sectionId}${currentPanelIndex}`) + 1]
						].name;
					const panelHelpSrc =
						Writing.WRITING_PANELS.help[contentData.type][`panel${panelIndex}`][
						panelsID[panelsID.indexOf(`${sectionId}${currentPanelIndex}`) + 1]
						];
					return { panelHelpSrc, panelVOSrc };
				}
			}
			case 'argument': {
				const panelIndex =
					sectionId === 'thesis' || sectionId === 'evidence2' ? currentPanelIndex + 1 : currentPanelIndex;
				const panelVOSrc =
					Writing.WRITING_PANELS[contentData.type][`panel${panelIndex}`][
						panelsID[panelsID.indexOf(sectionId) + 1]
					].name;

				const panelHelpSrc =
					Writing.WRITING_PANELS.help[contentData.type][`panel${panelIndex}`][
					panelsID[panelsID.indexOf(sectionId) + 1]
					];
				return { panelHelpSrc, panelVOSrc };
			}
			default:
				break;
		}
	};

	const handlePublish = async e => {
		AudioPlayer2.stopAll();
		setShowLoader(true);
		if (settings.autoPlayEnabled) {
			await AudioPlayer2.playSync(Writing.WRITING_PUBLISH_LOADER);
		}
		Writing.publishToServer(activityData, 'WZ_PublishInfo', 1, reviewLayoutInfo);
		try {
			await Writing.completeActivity(history, activityData);
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
		setShowLoader(false);
	};

	/* istanbul ignore next*/
	const handleGoOn = async e => {
		AudioPlayer2.stopAll();
		e.preventDefault();

		if (activityState === Writing.ACTIVITY_STATE.INITIAL) {
			setState(() => {
				setActivityState(Writing.ACTIVITY_STATE.SELECTOR);
				setGoOnStatus(false);
				setHelpVO(Writing.acronymBoard.help[contentData.type].source);
			});

			if (settings.autoPlayEnabled) {
				await AudioPlayer2.playSync(Writing.acronymBoard[contentData.type].name);
			}

			setGoOnStatus(true);
		} else if (activityState === Writing.ACTIVITY_STATE.SELECTOR) {
			const newRenderData =
				contentData.type === Writing.ARGUMENT
					? Writing.createArgumentData(contentData, choiceSelected)
					: renderData;

			newRenderData.panels[0].status = Writing.PANEL_STATUS.CURRENT;

			setState(() => {
				setHelpVO(Writing.WRITING_PANELS.help[contentData.type]['THESIS']);

				setSelectedLetter(Writing.acronymMap[contentData.type].acronym.charAt(0));
				setRenderData({ ...newRenderData });
				if (contentData.type === Writing.ARGUMENT) {
					setPanelGOStatus(goOnButtonStatus(newRenderData));
				}
				setActivityState(Writing.ACTIVITY_STATE.PLANNING_DRAFTING);
			});
			if (settings.autoPlayEnabled) {
				AudioPlayer2.play(Writing.WRITING_PANELS[contentData.type]['THESIS'].name);
			}
		} else if (activityState === Writing.ACTIVITY_STATE.PLANNING_DRAFTING) {
			setShowLoader(true);
			AudioPlayer2.loadSound(Writing.WRITING_REVIEW_LOADER);
			if (settings.autoPlayEnabled) {
				await AudioPlayer2.playSync(Writing.WRITING_REVIEW_LOADER);
				await sleep(1000);
			}
			setState(() => {
				setHelpVO(Writing.WRITING_PANELS.help[contentData.type]['revise']);
				setShowLoader(false);
				setActivityState(Writing.ACTIVITY_STATE.REVISION);
			});
			if (settings.autoPlayEnabled) {
				AudioPlayer2.play(Writing.WRITING_PANELS[contentData.type]['revise'].name);
			}
		} else if (activityState === Writing.ACTIVITY_STATE.REVISION) {
			setAnimatingReview(true);
			await sleep(500);
			setState(() => {
				setGoOnStatus(false);
				setActivityState(Writing.ACTIVITY_STATE.EDITING);
			});
		} else if (activityState === Writing.ACTIVITY_STATE.EDITING) {
			setState(() => {
				setActivityState(Writing.ACTIVITY_STATE.PUBLISHING);
			});
		}
	};

	const handleInstructions = () => {
		AudioPlayer2.stopAll();
		if (activityState < Writing.ACTIVITY_STATE.PLANNING_DRAFTING) {
			AudioPlayer2.play(Writing.WRITING_PANELS[contentData.type]['THESIS'].name);
		} else if (activityState === Writing.ACTIVITY_STATE.PLANNING_DRAFTING) {
			AudioPlayer2.play(Writing.WRITING_PANELS[contentData.type]['revise'].name);
		} else if (activityState === Writing.ACTIVITY_STATE.EDITING) {
			AudioPlayer2.play(Writing.WRITING_RATING_INTRO);
		}
	};

	const handlePassageClick = () => {
		setState(() => {
			setScratchPadVisible(false);
			setPassageVisible(true);
		});
	};

	const handlePassageClose = () => {
		setPassageVisible(false);
	};

	const handleScratchPadClick = () => {
		setScratchPadVisible(true);
	};

	/* istanbul ignore next */
	const handleScratchPadClose = text => {
		setScratchPadVisible(false);
	};

	/* istanbul ignore next */
	const handleScratchChange = text => {
		setScratchText(text);
	};

	/* istanbul ignore next*/
	const updateSelectedLetter = index => {
		setSelectedIndex(index);
	};

	const handlePowerWordsClick = word => {
		AudioPlayer2.stopAll();
		const word_card = wordCards.filter(card => {
			return card.word_card.word_text === word;
		})[0];
		setState(() => {
			setSelectedWordCard(word_card);
			setSelectedPowerWord(word);
			setScratchPadVisible(false);
		});
	};

	const handleWordCardClose = () => {
		AudioPlayer2.stopAll();
		setState(() => {
			setSelectedWordCard({});
			setSelectedPowerWord('');
		});
	};

	const handleVideoClick = () => {
		AudioPlayer2.stopAll();
		setState(() => {
			setScratchPadVisible(false);
			setVideoVisible(true);
		});
	};

	const handleVideoModalClose = () => {
		setVideoVisible(false);
	};

	const handleRevise = (id, sectionId, sectionIndex) => {
		AudioPlayer2.stopAll();
		const newRenderData = cloneDeep(renderData);
		let panelIndex = 0;
		const selectedPanel = newRenderData.panels.filter((panel, idx) => {
			if (panel.id === id) {
				panelIndex = idx;
			}
			return panel.id === id;
		});

		if (selectedPanel.length > 0) {
			// set the current panel to inactive
			newRenderData.panels[currentPanelIndex].status = Writing.PANEL_STATUS.COMPLETED;
			selectedPanel[0].status = Writing.PANEL_STATUS.CURRENT;
			setState(() => {
				setCurrentPanelIndex(panelIndex);
				setCurrentSectionIndex(sectionIndex);
				setRenderData(newRenderData);
			});
		}
	};

	const handleReset = (id, sectionId, sectionIndex) => {
		AudioPlayer2.stopAll();
		setResetModalVisible(true);
		const resetParams = {
			id,
			sectionId,
			sectionIndex
		};
		updateResetSection(resetParams);
	};

	const addPanelHandler = addPanelDisabled => {
		AudioPlayer2.stopAll();
		if (addPanelDisabled) {
			return false;
		}
		const newPanelStatus = {};
		if (renderData.panels.length >= Writing.ADDPANEL_MAX_LIMIT) {
			setAddPanelDisabled(true);
		}
		const newPanelData = Writing.addNewPanel(contentData, renderData.panels.length - 1);
		newPanelData.sections.forEach(i => (newPanelStatus[`${newPanelData.id}${i.id}`] = true));

		setState(() => {
			setNewPanelIndex(newPanelIndex + 1);
			setCurrentPanelIndex(renderData.panels.length - 1);
		});

		const renderedDataClone = { ...renderData };
		const targetIndex = renderedDataClone.panels.length - 1;
		renderedDataClone.panels.splice(targetIndex, 0, newPanelData);
		renderedDataClone.panels.forEach(i => {
			if (i.status === Writing.PANEL_STATUS.CURRENT) {
				if (i.sections.length > 1) {
					i.sections.forEach(item => {
						if (panelGOStatus[`${i.id}${item.id}`]) {
							i.status = Writing.PANEL_STATUS.COMPLETED;
						} else {
							i.status = Writing.PANEL_STATUS.PENDING;
						}
					});
				} else {
					const panelEdited = Object.keys(completedPanelData).filter(i => i.indexOf(i.id) > -1).length > 0;
					if (panelEdited) {
						i.status = Writing.PANEL_STATUS.COMPLETED;
					} else {
						i.status = Writing.PANEL_STATUS.PENDING;
					}
				}
			}
		});
		renderedDataClone.panels[targetIndex].status = Writing.PANEL_STATUS.CURRENT;

		setState(() => {
			setPanelID(getPanelSeqence(renderedDataClone));
			setPanelGOStatus({ ...panelGOStatus, ...newPanelStatus });
			setRenderData(renderedDataClone);
		});
	};

	const deletePanel = (id, idx, text, section, panelId) => {
		AudioPlayer2.stopAll();
		setNewPanelIndex(newPanelIndex - 1);
		const searchKey = contentData.type === Writing.ARGUMENT ? section[0].id : panelId;
		const trimIndex = section.findIndex(i => i.id === id);
		const trimmedSection = cloneDeep(section);
		trimmedSection.splice(trimIndex, 1);
		const clonedData = cloneDeep(renderData);
		const targetIndex = clonedData.panels.findIndex(i => i.id === searchKey);
		const finalData = { ...completedPanelData };
		const mapSet = { ...uniqueIDMap };
		if (idx !== 0) {
			clonedData.panels[targetIndex].sections = trimmedSection;
			let targetMapId;
			if (contentData.type === Writing.ARGUMENT) {
				targetMapId = mapSet[section[0].id][id].panelUniqueID;
			} else if (contentData.type === Writing.INFORMATIVE) {
				targetMapId = mapSet[panelId][id].panelUniqueID;
			}
			delete finalData[targetMapId];
			delete mapSet[targetMapId];
		} else {
			clonedData.panels.splice(targetIndex, 1);
			if (contentData.type === Writing.ARGUMENT) {
				Object.keys(mapSet[section[0].id]).forEach(i => {
					delete finalData[mapSet[section[0].id][i].panelUniqueID];
				});
				delete mapSet[section[0].id];
			} else if (contentData.type === Writing.INFORMATIVE) {
				Object.keys(mapSet[panelId]).forEach(i => {
					delete finalData[mapSet[panelId][i].panelUniqueID];
				});
				delete mapSet[panelId];
			}
			setCurrentPanelIndex(currentPanelIndex - 1);
		}

		const positiveChoiceSelected =
			choiceSelected === Writing.STRONGLY_AGREE_SELECTED || choiceSelected === Writing.AGREE_SELECTED;
		const keyPhrase = positiveChoiceSelected ? 'positive' : 'negative';

		if (contentData.type === Writing.ARGUMENT) {
			const endPanelEdited = Object.keys(finalData).filter(i => i.indexOf('End') > -1).length;

			const endPanelIdPrefix = positiveChoiceSelected ? Writing.POSITIVE_KEY : Writing.NEGATIVE_KEY;
			const endStatementKey =
				endPanelEdited && Object.entries(finalData).find(([k, v]) => k.startsWith(keyPhrase))[0];
			const endStatementValue =
				endPanelEdited && Object.entries(finalData).find(([k, v]) => k.startsWith(keyPhrase))[1];
			if (endPanelEdited) {
				delete finalData[endStatementKey];
				finalData[`${endPanelIdPrefix}${clonedData.panels.length - 1}`] = endStatementValue;
				mapSet[endPanelIdPrefix].panelUniqueID = `${endPanelIdPrefix}${clonedData.panels.length - 1}`;
			}
		} else if (contentData.type === Writing.INFORMATIVE) {
			const endPanelEdited = Object.keys(finalData).filter(i => i.indexOf('end') > -1).length;

			const endPanelIdPrefix = Writing.END;
			const endStatementKey =
				endPanelEdited && Object.entries(finalData).find(([k, v]) => k.startsWith(Writing.END))[0];
			const endStatementValue =
				endPanelEdited && Object.entries(finalData).find(([k, v]) => k.startsWith(Writing.END))[1];
			if (endPanelEdited) {
				delete finalData[endStatementKey];
				finalData[`${endPanelIdPrefix}${clonedData.panels.length - 1}`] = endStatementValue;
				mapSet[endPanelIdPrefix].panelUniqueID = `${endPanelIdPrefix}${clonedData.panels.length - 1}`;
			}
		}
		if (clonedData.panels.length - 1 < Writing.ADDPANEL_MAX_LIMIT) {
			setAddPanelDisabled(false);
		}

		setState(() => {
			setUniqueIDMap(mapSet);
			setCompletedPanel(finalData);
			setRenderData(clonedData);
			setPanelID(getPanelSeqence(clonedData));
		});
	};
	/* istanbul ignore next*/
	const resetText = () => {
		const newRenderData = cloneDeep(renderData);
		let currentSection;
		let selectedIndex = 0;
		const panelsSatatus = { ...panelGOStatus };

		if (newRenderData.panels.filter(item => item.id === resetSectionDetails.id)[0].sections.length > 1) {
			currentSection = newRenderData.panels
				.find((item, index) => {
					if (item.id === resetSectionDetails.id) {
						selectedIndex = index;
					}
					return item.id === resetSectionDetails.id;
				})
				.sections.map(i => {
					if (i.id === resetSectionDetails.sectionId) {
						i.text = i.originalText;
						return i;
					} else {
						return i;
					}
				});

			panelsSatatus[`${resetSectionDetails.id}${resetSectionDetails.sectionId}`] = true;

			newRenderData.panels[selectedIndex].sections = cloneDeep(currentSection);
		} else {
			currentSection = newRenderData.panels.map(item => {
				if (item.id === resetSectionDetails.id) {
					item.sections[0].text = item.sections[0].originalText;
					return item;
				} else {
					return item;
				}
			});
			panelsSatatus[resetSectionDetails.id] = true;
			newRenderData.panels = cloneDeep(currentSection);
		}
		updatePanelsGo(panelsSatatus);
		setState(() => {
			setRenderData({ ...newRenderData });
			setResetModalVisible(false);
		});
		resetClicked(true);
	};

	const updatePanelsVO = (contentData, panelsID, sectionId, currentPanelIndex) => {
		AudioPlayer2.stopAll();
		const mode = contentData.type;
		const panelsLength = renderData.panels.length;

		// eslint-disable-next-line default-case
		switch (mode) {
			case 'informative':
				if (currentPanelIndex < 3 && !(currentPanelIndex === 2 && sectionId === 'detail2')) {
					const { panelVOSrc, panelHelpSrc } = getPanelAudio(
						contentData,
						panelsID,
						sectionId,
						currentPanelIndex
					);

					if (settings.autoPlayEnabled) {
						AudioPlayer2.play(panelVOSrc);
					}
					setHelpVO(panelHelpSrc);
				} else {
					setHelpVO(Writing.WRITING_GOON);
				}

				if (currentPanelIndex + 1 === panelsLength - 1 && sectionId === 'detail2') {
					if (panelsLength < Writing.ADDPANEL_MAX_LIMIT) {
						setAddPanelDisabled(false);
					}
					if (settings.autoPlayEnabled) {
						AudioPlayer2.play(Writing.WRITING_PANELS[contentData.type]['end'].name);
					}
					setHelpVO(Writing.WRITING_PANELS.help[contentData.type]['end']);
				}

				if (currentPanelIndex === panelsLength - 1) {
					if (settings.autoPlayEnabled) {
						AudioPlayer2.play(Writing.WRITING_PANELS[contentData.type]['goon'].name);
					}

					setHelpVO(Writing.WRITING_GOON);
				}

				break;

			case 'argument':
				if (currentPanelIndex < 3 && !(currentPanelIndex === 2 && sectionId === 'evidence2')) {
					const { panelVOSrc, panelHelpSrc } = getPanelAudio(
						contentData,
						panelsID,
						sectionId,
						currentPanelIndex
					);
					if (settings.autoPlayEnabled) {
						AudioPlayer2.play(panelVOSrc);
					}
					setHelpVO(panelHelpSrc);
				} else {
					setHelpVO(Writing.WRITING_GOON);
				}

				if (currentPanelIndex + 1 === panelsLength - 1 && sectionId === 'evidence2') {
					if (panelsLength < Writing.ADDPANEL_MAX_LIMIT) {
						setAddPanelDisabled(false);
					}
					if (settings.autoPlayEnabled) {
						AudioPlayer2.play(Writing.WRITING_PANELS[contentData.type]['end'].name);
					}
					setHelpVO(Writing.WRITING_PANELS.help[contentData.type]['end']);
				}
				if (currentPanelIndex === panelsLength - 1) {
					if (settings.autoPlayEnabled) {
						AudioPlayer2.play(Writing.WRITING_PANELS[contentData.type]['goon'].name);
					}

					setHelpVO(Writing.WRITING_GOON);
				}

				break;
		}
	};

	const handlePanelGoOn = (id, sectionId, sectionIndex) => {
		AudioPlayer2.stopAll();
		const newRenderData = cloneDeep(renderData);
		const currentPanel = newRenderData.panels[currentPanelIndex];
		updatePanelsVO(contentData, panelsID, sectionId, currentPanelIndex);
		// if they're on the last section of the panel,

		if (sectionIndex === currentPanel.sections.length - 1) {
			currentPanel.status = Writing.PANEL_STATUS.COMPLETED;
			if (currentPanelIndex === newRenderData.panels.length - 1) {
				// end all the panels and enable the Go On button
				setWritingComplete(true);
				setGoOnStatus(true);
			} else {
				// go to the next panel
				newRenderData.panels[currentPanelIndex + 1].status = Writing.PANEL_STATUS.CURRENT;
				// set the letter
				setSelectedLetter(newRenderData.panels[currentPanelIndex].sections[0].letter);
				// change panels
				setCurrentPanelIndex(currentPanelIndex + 1);
				setCurrentSectionIndex(0);
				const $zf = document.getElementById('zone-frame');
				if ($zf) {
					$zf.scrollTop = $zf.scrollHeight;
				}
			}
		} else {
			// else the components handles the traversal itself and updates
			// this parent through handleTextChange
			// however we still need to update the acronym banner on the left side

			setSelectedLetter(newRenderData.panels[currentPanelIndex].sections[sectionIndex].letter);
		}
		setRenderData(newRenderData);
	};

	const handleInfo = (id, sectionId, sectionIndex, isSectionActive) => {
		AudioPlayer2.stopAll();

		let soundObject = Writing.WRITING_PANELS.help[contentData.type];

		let sound = '';
		if (sectionId === 'thesis') {
			sound = soundObject['THESIS'];
		} else if (sectionId === 'positiveEnd') {
			sound = soundObject['positiveEnd'];
		} else if (sectionId === 'negitiveEnd') {
			sound = soundObject['negitiveEnd'];
		} else if (sectionId === 'idea') {
			sound = soundObject['panel1']['idea1'];
		} else if (sectionId === 'end') {
			sound = soundObject['end'];
		} else {
			sound = soundObject['panel1'][sectionId];
		}
		AudioPlayer2.loadSound(sound);
		AudioPlayer2.play(sound);
	};

	const handleTextChange = (id, sectionId, sectionIndex, text) => {
		const newRenderData = cloneDeep(renderData);
		const selectedPanel = newRenderData.panels.filter((panel, idx) => {
			return panel.id === id;
		});
		selectedPanel[0].sections[sectionIndex].text = text;
		setRenderData(newRenderData);
	};

	const handleReviewPanelEdit = reviewText => {
		AudioPlayer2.stopAll();
		let newWritingToolsInfo = cloneDeep(writingToolsInfo);
		newWritingToolsInfo['reviewText'] = reviewText;
		setWritingToolsInfo(newWritingToolsInfo);
	};

	const handleStarsClicked = (stars, text) => {
		AudioPlayer2.stopAll();
		let newWritingToolsInfo = cloneDeep(writingToolsInfo);
		newWritingToolsInfo[text] = stars;
		setWritingToolsInfo(newWritingToolsInfo);
	};
	const handleWritingToolsCompletion = () => {
		setGoOnStatus(true);
	};

	const handleReviewLayoutComplete = (reviewText, layout, headerText, headerImage) => {
		let newReviewLayoutInfo = cloneDeep(reviewLayoutInfo);
		newReviewLayoutInfo.reviewText = reviewText;
		newReviewLayoutInfo.layout = layout;
		newReviewLayoutInfo.headerText = headerText;
		newReviewLayoutInfo.headerImage = headerImage;
		setState(() => {
			setReviewLayoutInfo(newReviewLayoutInfo);
			setGoOnStatus(true);
		});
	};

	const handleOnOffButtonClicked = data => {
		if (data.text === 'Spelling' && data.value) {
			setSpellingCheckEnabled(true);
		} else {
			setSpellingCheckEnabled(false);
		}
		if (data.text === 'Tired Words' && data.value) {
			setTiredWordsEnabled(true);
		} else {
			setTiredWordsEnabled(false);
		}

		if (data.text === 'Sentence Sense' && data.value) {
			setSentenceSenseEnabled(true);
		} else {
			setSentenceSenseEnabled(false);
		}
	};

	/* istanbul ignore next*/
	const updateCompletedPanel = async (panelData, panelUniqueID, panelName, sectionId, sectionIndex, rootID) => {
		const panelsData = { ...renderData };
		const uniqueIDMapping = { ...uniqueIDMap };
		let targetSection;
		renderData.panels.forEach(i => {
			i.sections.forEach(item => {
				if (contentData.type === Writing.ARGUMENT) {
					if (item.id === panelName) {
						targetSection = i;
					}
				} else if (contentData.type === Writing.INFORMATIVE) {
					if (sectionId === 'thesis' || sectionId === 'end') {
						if (item.id === sectionId) {
							targetSection = i;
						}
					} else {
						if (i.id === rootID && panelName === item.id) {
							targetSection = i;
						}
					}
				}
			});
		});
		let checkCriteria;

		panelsData.panels.forEach(i => {
			if (contentData.type === Writing.ARGUMENT) {
				checkCriteria = i.id === panelName;
			} else if (contentData.type === Writing.INFORMATIVE) {
				checkCriteria = i.id === targetSection.id;
			}
			if (checkCriteria) {
				if (i.sections.length > 1) {
					let panelSubsection;
					if (contentData.type === Writing.INFORMATIVE) {
						panelSubsection = i.id;
					} else if (contentData.type === Writing.ARGUMENT) {
						panelSubsection = panelName;
					}

					if (!uniqueIDMapping[panelSubsection]) {
						uniqueIDMapping[panelSubsection] = {};
					}
					if (!uniqueIDMapping[panelSubsection][sectionId]) {
						uniqueIDMapping[panelSubsection][sectionId] = {};
					}
					uniqueIDMapping[panelSubsection][sectionId]['panelUniqueID'] = panelUniqueID;
				} else {
					uniqueIDMapping[panelName] = { panelUniqueID };
					i.sections[0]['panelUniqueID'] = panelUniqueID;
				}
			}
		});
		setState(() => {
			setUniqueIDMap(uniqueIDMapping);
			setCompletedPanel(panelData);
		});
		await sleep(600);
		if (isEmpty(ref.current) === false) {
			window.scrollTo({
				left: 0,
				top: ref.current.offsetTop - ref.current.offsetHeight + currentSectionIndex * 100,
				behavior: 'smooth'
			});
		}
	};

	const handleExitActivity = isExit => {
		if (isExit) {
			Writing.sendProgressToServer(false, activityState, activityData.studentActivityId);
		}
	};

	const renderPanelContent = (panel, orderID) => {
		const isLastPanel = renderData.panels[renderData.panels.length - 1].id === panel.id;
		switch (panel.status) {
			case Writing.PANEL_STATUS.PENDING:
				return (
					<Fragment key={orderID}>
						{isLastPanel && (
							<AddNewPanel
								disabled={isAddPanelDisabled}
								key={`pending${panel.id}panel`}
								title={Writing.ADDMORE[contentData.type]}
								addPanelHandler={addPanelDisabled => addPanelHandler(addPanelDisabled)}
								renderData={renderData}
								data-testid="imaddNewPanel"
							/>
						)}
						<InactiveWritingPanel
							key={panel.id}
							id={panel.id}
							title={panel.sections[0].title}
							onInfo={handleInfo}
							data-testid="active-writing-panel"
						/>
					</Fragment>
				);

			case Writing.PANEL_STATUS.CURRENT:
				return (
					<Fragment key={orderID}>
						{isLastPanel && (
							<AddNewPanel
								disabled={isAddPanelDisabled}
								key={`current${panel.id}panel`}
								title={Writing.ADDMORE[contentData.type]}
								addPanelHandler={addPanelDisabled => addPanelHandler(addPanelDisabled)}
								renderData={renderData}
								data-testid="AddnewPanel-new"
							/>
						)}
						<WritingPanel
							key={panel.id}
							ref={ref}
							id={panel.id}
							data-testid="writePanel"
							activePanelIndex={currentSectionIndex}
							onReset={handleReset}
							onGoOn={handlePanelGoOn}
							onInfo={handleInfo}
							onTextChange={handleTextChange}
							sections={panel.sections}
							setCompletedPanel={(
								completedPanelData,
								panelUniqueID,
								panelName,
								sectionId,
								sectionIndex,
								rootID
							) =>
								updateCompletedPanel(
									completedPanelData,
									panelUniqueID,
									panelName,
									sectionId,
									sectionIndex,
									rootID
								)
							}
							orderID={orderID}
							completedPanelData={completedPanelData}
							resetClicked={resetClicked}
							isResetClicked={isResetClicked}
							panelGOStatus={panelGOStatus}
							updatePanelsGo={updatePanelsGo}
							setSelectedIndex={updateSelectedLetter}
							mode={contentData.type}
						/>
					</Fragment>
				);

			case Writing.PANEL_STATUS.COMPLETED:
				return (
					<Fragment key={orderID}>
						{isLastPanel && (
							<AddNewPanel
								disabled={isAddPanelDisabled}
								key={`completed${panel.id}panel`}
								title={Writing.ADDMORE[contentData.type]}
								data-testid="addPanel"
								addPanelHandler={addPanelDisabled => addPanelHandler(addPanelDisabled)}
								renderData={renderData}
							/>
						)}
						<CompletedWritingPanel
							key={panel.id}
							id={panel.id}
							data-testid="completeWritingPanel"
							onRevise={handleRevise}
							sections={panel.sections}
							setCompletedPanel={(
								completedPanelData,
								panelUniqueID,
								panelName,
								sectionId,
								sectionIndex,
								rootID
							) =>
								updateCompletedPanel(
									completedPanelData,
									panelUniqueID,
									panelName,
									sectionId,
									sectionIndex,
									rootID
								)
							}
							orderID={orderID}
							completedPanelData={completedPanelData}
							deletePanel={deletePanel}
						/>
					</Fragment>
				);
			default:
				return null;
		}
	};

	useIdleHelp(renderData.type ? Writing.SOUNDS[renderData.type].HELP : Writing.HELP);

	return (
		<>
			<Navbar helpSoundUrl={helpVO} data-testid="barNav" onZoneMenuClosed={handleExitActivity} onExitAppClosed={handleExitActivity} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false || showLoader}
				requiredRenderData={[contentData, renderData]}
				title={showLoader ? loaderTitle : Writing.SCREEN_LOADER_TITLE}
				message={showLoader ? loaderMessage : ''}
			>
				<div className={classes.wrapper}>
					{activityState === Writing.ACTIVITY_STATE.PLANNING_DRAFTING && (
						<AcronymBanner
							acronym={
								Writing.acronymMap[contentData.type] ? Writing.acronymMap[contentData.type].acronym : ''
							}
							letterIndex={selectedIndex}
							audioPaths={Writing.acronymBoard.info[contentData.type]}
						/>
					)}

					<div className={classes.writingContainer}>
						{activityState === Writing.ACTIVITY_STATE.INITIAL && contentData.type && (
							<ActivityFrame isWhiteBackground={true}>
								<WritingPrompt
									showFeedback={contentData.type === 'argument'}
									text={renderData}
									data-testid="promptWrite"
									choices={Writing.WRITING_PROMPT_CHOICES}
									backGroundImage={Writing.getWritingPromptImage(activityData, contentData)}
									audioPath={Writing.getWritingPrompt(activityData, contentData.type)}
									introAudio={Writing.WRITING_PROMPT_INTRO[contentData.type].name}
									setWritingStatus={updateWritingPrompt}
									updateSelection={updateSelection}
									autoPlay={settings.autoPlayEnabled}
								/>
							</ActivityFrame>
						)}
						{activityState === Writing.ACTIVITY_STATE.SELECTOR && contentData.type && (
							<div className={``} data-testid="instButton">
								<ActivityInstructionButton
									audioName={Writing.acronymBoard[contentData.type].name}
									white={true}
									withoutFrame={true}
									style={{ top: '180px', left: '180px' }}
								/>
								<WritingAcronymBoard
									abbreviations={Writing.acronymMap[contentData.type].abbreviations}
									introAudio={Writing.acronymBoard[contentData.type].name}
									acronym={Writing.acronymMap[contentData.type].acronym}
								/>
							</div>
						)}

						{activityState >= Writing.ACTIVITY_STATE.PLANNING_DRAFTING && (
							<div className={`${classes.panelWrapper}`}>
								{activityState < Writing.ACTIVITY_STATE.PUBLISHING && (
									<>
										<WritingPanelContainer
											onInstructionClick={handleInstructions}
											onPassageClick={handlePassageClick}
											onScratchPadClick={handleScratchPadClick}
											onPowerWordsClick={handlePowerWordsClick}
											onVideoClick={handleVideoClick}
											powerWords={powerWords}
											showDraftPanel={
												activityState === Writing.ACTIVITY_STATE.REVISION ||
												activityState === Writing.ACTIVITY_STATE.EDITING
											}
											choiceSelected={choiceSelected}
											key={'WritingPanelWrapper'}
											data-testid="containerPanel"
											narrow={
												isAnimatingReview || activityState === Writing.ACTIVITY_STATE.EDITING
											}
										>
											{isResetModalVisible && (
												<AppModal
													key={'WritingPanelModal'}
													isOpen={isResetModalVisible}
													onConfirm={resetText}
													onCancel={() => {
														setResetModalVisible(false);
													}}
													id="reset-modal"
													text={Writing.ERASE_TEXT_ALERT}
													showSpeakerIcon={false}
												/>
											)}
											{activityState === Writing.ACTIVITY_STATE.PLANNING_DRAFTING && (
												<>
													<StaticPanel
														id={'writing-prompt'}
														text={renderData.question.prompt}
														title={'Writing Prompt'}
														audioPath={Writing.getWritingPrompt(
															activityData,
															contentData.type
														)}
														data-testid="panelStatic"
													/>
													{renderData.panels.map((panel, orderID) =>
														renderPanelContent(panel, orderID)
													)}
												</>
											)}
											{(activityState === Writing.ACTIVITY_STATE.REVISION ||
												activityState === Writing.ACTIVITY_STATE.EDITING) && (
													<ReviewPanel
														uniqueIDMap={uniqueIDMap}
														completedPanelData={completedPanelData}
														mode={contentData.type}
														writingToolsSpellCheckObj={writingToolsSpellCheck}
														writingToolsTiredWordsObj={writingToolsTiredWords}
														spellCheckEnabled={isSpellingCheckEnabled}
														tiredWordsEnabled={isTiredWordsEnabled}
														sentenceSenseEnabled={isSentenceSenseEnabled}
														isWritingToolActive={
															activityState === Writing.ACTIVITY_STATE.EDITING
														}
														onReviewPanelEdit={handleReviewPanelEdit}
													/>
												)}
										</WritingPanelContainer>
									</>
								)}

								{activityState === Writing.ACTIVITY_STATE.EDITING && (
									<div>
										<WritingTools
											data-testid="writingTools"
											onStarsClicked={handleStarsClicked}
											onOffButtonClicked={handleOnOffButtonClicked}
											onCompletion={handleWritingToolsCompletion}
											autoPlay={settings.autoPlayEnabled}
										/>
									</div>
								)}

								{activityState === Writing.ACTIVITY_STATE.PUBLISHING && (
									<ReviewLayout
										data-testid="reviewLayoutPublish"
										reviewText={writingToolsInfo.reviewText}
										mode={contentData.type}
										images={Writing.getReviewLayoutImages(activityData, contentData)}
										author={user && user.firstName ? `${user.firstName} ${user.lastName}` : ''}
										onReviewLayoutComplete={handleReviewLayoutComplete}
										onPublish={handlePublish}
										autoPlay={settings.autoPlayEnabled}
									/>
								)}

								{isPassageVisible && (
									<PassageWindow
										isOpen={isPassageVisible}
										onModalClose={handlePassageClose}
										paragraphs={paragraphs}
									/>
								)}

								{isScrachPadVisible && (
									<Scratchpad
										onModalClose={handleScratchPadClose}
										scratchText={scratchText}
										onScratchChange={handleScratchChange}
									/>
								)}

								{isEmpty(selectedWordCard) === false && (
									<WordCardModal
										isOpen={isEmpty(selectedWordCard) === false}
										onModalClose={handleWordCardClose}
										wordCardData={wordCardMap[selectedPowerWord]}
										card={selectedWordCard}
										kill={false}
										taskData={[]}
										powerWordData={selectedWordCard}
										videoSrcURL={videoInfo.videoUrl}
									/>
								)}

								{isVideoVisible && (
									<VideoModal
										isOpen={isVideoVisible}
										onModalClose={handleVideoModalClose}
										videoSrcURL={videoInfo.videoUrl}
										videoTracks={videoInfo.videoCaptionInfo}
										start_time={0}
										end_time={0}
										hideCaptionBtn={false}
										hideReplayBtn={false}
									/>
								)}
							</div>
						)}

						{activityState !== Writing.ACTIVITY_STATE.PUBLISHING && (
							<Footer>
								<div style={{ float: 'right' }}>
									<ActivityButton
										icon={activityState === Writing.ACTIVITY_STATE.INITIAL && contentData.type === 'argument' ? 'check' : 'arrow-right'}
										id="actButton"
										onClick={handleGoOn}
										text={
											activityState === Writing.ACTIVITY_STATE.INITIAL &&
												contentData.type === 'argument'
												? 'Submit'
												: 'Go On'
										}
										disabled={!disableGoOn}
									/>
								</div>
							</Footer>
						)}
					</div>
				</div>
			</ActivitySuspense>
		</>
	);
};

WritingActivity.propTypes = {};

export default WritingActivity;
