const QuestionType = {
	SINGLE: 'SELECTED_RESPONSE_SINGLE_ANSWER',
	MULTIPLE: 'SELECTED_RESPONSE_MULTIPLE_ANSWERS',
	CHART: 'MATCHED_RESPONSE_GRID',
	MATCHING: 'MATCHED_RESPONSE_CONNECTED',
	ORDERING: 'ORDERING',
	HIGHLIGHT: 'HIGHLIGHTED_PASSAGE',
	SORTING: 'SORTING',
	RATE_RECORDING: 'RATE_RECORDING',
	FOCUS_ATTENTION: 'FOCUS_ATTENTION',
	CHALLENGE_ACCEPTANCE: 'CHALLENGE_ACCEPTANCE',
	TWO_PART:'two-part',//Super consistent API
};

export default QuestionType;
