import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {MEDIA} from '@reading/r180/src/activities/SegmentSelection/SegmentSelection';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';


const ClusterSounds = ({id}) => {
	const mediaConstants = {
		cluster: id
	};
	const titlePlayer = AudioPlayer2.load({
		name: `cluster_title_${id}`,
		src: `${MEDIA_SERVER_URL}${bulkReplace(
			MEDIA.clusterTitle,
			mediaConstants
		)}`
	});
	const introPlayer = AudioPlayer2.load({
		name: `cluster_summary_${id}`,
		src: `${MEDIA_SERVER_URL}${bulkReplace(
			MEDIA.clusterSummary,
			mediaConstants
		)}`
	});
	titlePlayer.onEnd = () => {
		AudioPlayer2.play(introPlayer.name);

	};
	const playSounds = event => {
		event.stopPropagation();
		AudioPlayer2.stopAll();
		AudioPlayer2.play(titlePlayer.name);
	};
	return playSounds;
};

export default ClusterSounds;
