import React, { useState, useContext, useEffect, useRef } from 'react';
import { DndContext } from './components/DndProvider';
import { DragZone } from './components/DnDZones';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    dragTiles: {
        '--animate-duration': '0.3s',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-around',
        gap: '22px',
    }
}));


//  ------------------------------------------------------------
//  DndQuestions Component
//  ------------------------------------------------------------

const DndQuestions = (props) => {
    const { tiles, type, meta, className, children, ...options } = props;
    const classes = useStyles();
    const tilesRef = useRef(null);
    const { dndTiles, dndState, methods } = useContext(DndContext);
    // --- xx -----------------------------------------------


    useEffect(() => {
        methods.setNode(type, tilesRef.current);
    }, [dndTiles])
    // --- xx -----------------------------------------------

    const handleChange = (elm, to) => {
        const toContainer = dndState.nodes[to];
        toContainer.appendChild(elm)

        const toItems = toContainer.querySelectorAll('[data-dnd-id]');
        const innerItems = tilesRef.current.querySelectorAll('[data-dnd-id]');

        const changeObj = {
            from: { id: type, items: Array.from(innerItems).map(i => i.dataset.dndId) },
            to: { id: to, items: Array.from(toItems).map(i => i.dataset.dndId) }
        }

        methods.updateMultiple(changeObj)
    }
    // --- xx -----------------------------------------------


    const component = React.isValidElement(children)
        ? children
        : null;
    // --- xx -----------------------------------------------

    if (dndTiles)
        return (
            <div
                className={
                    `${className} 
                        drag-tiles
                        ${classes.dragTiles} 
                        animate__animated animate__fadeIn`
                }
                ref={tilesRef}
            >
                {dndTiles.map((item) => {
                    return (
                        <DragZone
                            className="drag-wrapper"
                            type={type}
                            pull="answers"
                            meta={meta}
                            {...options}
                            key={item.id}
                        >
                            {item.target === type && (
                                (React.cloneElement(component,
                                    {
                                        ...component.props,
                                        onMenuChange: handleChange,
                                        item,
                                        meta: meta,
                                        type: type,
                                    }, null))
                            )}
                        </DragZone>
                    )
                })}
            </div>
        );
    else
        return null;
};

export default React.memo(DndQuestions);