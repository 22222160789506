import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {useStyles} from './ZoneButton.style.js';
import {string, shape, func, bool, number} from 'prop-types';
import {keyAndClick} from '../../../util/ui-a11y';

const ZoneButton = ({
	zoneState: {available},
	zoneDisplay: {displayName},
	zoneProgress: {current, max},
	zoneCode,
	onClick,
	onMouseOver
}) => {
	const classes = useStyles({zoneCode, available});
	const zoneIcons = {
		explore_zone: `&#xe915;`,
		reading_zone: '&#xe938;',
		language_zone: '&#xe921;',
		fluency_zone: '&#xe918;',
		writing_zone: '&#xe959;',
		success_zone: '&#xe947;'
	};
	const angle = 2* Math.PI * current/max - Math.PI/2;
	const radius = 46;
	return (
		<div
			className={classes.zoneButton}
			{...keyAndClick(() => {
				available && onClick();
			})}
			tabIndex={available?0:-1	}
			onMouseOver={onMouseOver}
			aria-label={displayName}
			role={'region'}

		>
			<div className={classes.progressContainer} >
				<div className={classes.progressBgArc}></div>
				<CircularProgress
					variant={'static'}
					key={`zonebutton_${zoneCode}`}
					value={(100.0 * current) / max}
					size={96}
					className={classes.progressArc}
					thickness={1}
					aria-valuenow={current}
					aria-valuemax={max}
					aria-valuemin={0}
					aria-label={`Progress: ${current}/${max}`}
				></CircularProgress>
			</div>
			{(current > 0 || !available) && (
				<div aria-hidden={true}
					className={classes.zoneProgressDot}
					style={{
						transform: `translate(${Math.cos(angle)*radius}px,${Math.sin(angle)*radius}px)`
					}}
					dangerouslySetInnerHTML={{
						__html:
							current == max
								? '&#xe944;'
								: !available
								? '&#xe922;'
								: '&#xe909;'
					}}
				></div>
			)}
			<div
				className={classes.zoneIcon}
				dangerouslySetInnerHTML={{__html: zoneIcons[zoneCode]}}
			></div>
			<div className={classes.zoneLabel} id={`label_${zoneCode}`} role="button">
				{displayName.replace(' Zone', '')}
			</div>
		</div>
	);
};

ZoneButton.propTypes = {
	zoneState: shape({available: bool}),
	zoneDisplay: shape({displayName: string}),
	zoneProgress: shape({current: number, max: number}),
	zoneCode: string,
	onClick: func,
	onMouseOver: func
};

export default React.memo(ZoneButton);
