import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ActivityButton from '../ActivityButton/ActivityButton';
import {SoundButton} from '../..';
import {isEmpty} from 'lodash';
import ChoiceQuestion from './ChoiceQuestion';
import HighlightQuestion from './HighlightQuestion';
import QuestionType from '../../constants/Languages/QuestionType';
import {isFirefox} from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'inline-block',
		'& .questionPanel': {
			background: theme.colors.white,
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			borderRadius: '8px',
			width: '480px',
			height: 'auto',
			maxHeight: '610px',
			overflowY: isFirefox() ? 'auto' : 'overlay',
			scrollbarWidth: isFirefox() && 'thin',
			'&::-webkit-scrollbar': {
				width: '6px'
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				padding: '10px 0'
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#CCC',
				borderRadius: '8px'
			},
			overflowX: 'hidden',
			'& .header': {
				background: props =>
					props.isSuccessZone
						? theme.colors.successZone.aqua5
						: theme.colors.readingZone.blue5,
				minHeight: '96px',
				fontFamily: theme.fonts.uiBold,
				fontSize: '18px',
				lineHeight: '22px',
				padding: '0 16px 0 12px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '8px 8px 0 0',
				'& .sound-button': {
					marginRight: '12px'
				}
			},
			'& .content': {
				padding: '40px',
				height: 'auto',
				'& .question-row': {
					marginBottom: '24px',
					'&:last-of-type': {
						marginBottom: '0'
					}
				}
			}
		},
		'& .button-row': {
			background: 'transparent',
			marginTop: '24px',
			textAlign: 'right',
			'& button.right': {
				float: 'right'
			},
			'& button.left': {
				float: 'left'
			}
		},
		'& p': {
			display: 'inline'
		}
	}
}));

const ReadingTwoQuestionPanel = props => {
	const {
		id,
		className,
		onChoiceAnswerChange,
		interactive,
		buttonText,
		buttonIcon,
		showResetButton,
		onSubmit,
		onReset,
		isSuccessZone,
		preSelectedIndex,
		question1,
		question2,
		handleDeleteIconClick,
		selectedHighlightedOptions,
		choiceOptions
	} = props;

	const classes = useStyles({isSuccessZone: isSuccessZone});

	const [isAnswered, setAnswered] = useState([false, false]);

	const handleAnswerChange = (answers, index) => {
		const question = index ? question2 : question1;
		const {type: questionType} = question;
		switch (questionType) {
			case QuestionType.HIGHLIGHT:
				handleDeleteIconClick(answers);
				break;
			default:
				onChoiceAnswerChange(answers, index);
				break;
		}
		let newAnswered = [...isAnswered];
		newAnswered[index] = isEmpty(answers) === false;
		setAnswered(newAnswered);
	};

	useEffect(() => {
		let newAnswered = [...isAnswered];
		if (question1.type === QuestionType.HIGHLIGHT) {
			if (
				selectedHighlightedOptions.length ===
				(question1.maximum_allowable_responses || 1)
			) {
				newAnswered[0] = true;
			}
		}
		if (question2.type === QuestionType.HIGHLIGHT) {
			if (
				selectedHighlightedOptions.length ===
				(question2.maximum_allowable_responses || 1)
			) {
				newAnswered[1] = true;
			}
		}
		setAnswered(newAnswered);
	}, [selectedHighlightedOptions]);

	const handleDisable = () => {
		return isAnswered.filter(a => a === false).length > 0;
	};

	const renderQuestion = index => {
		const question = index ? question2 : question1;
		const {type: questionType} = question;
		switch (questionType) {
			case QuestionType.SINGLE:
			case QuestionType.MULTIPLE:
				return (
					<ChoiceQuestion
						questionType={questionType}
						questions={choiceOptions[index]}
						interactive={interactive}
						onAnswerChange={answers => {
							handleAnswerChange(answers, index);
						}}
						preSelectedIndex={preSelectedIndex}
					/>
				);
			case QuestionType.HIGHLIGHT: {
				return (
					<HighlightQuestion
						question={selectedHighlightedOptions}
						interactive={interactive}
						onAnswerChange={answers => {
							handleAnswerChange(answers, index);
						}}
						displayOnlyWords={isSuccessZone}
					/>
				);
			}
		}
	};

	const titleSound = [
		{name: 'two-part-title-1', src: ''},
		{name: 'two-part-title-2', src: ''}
	];

	return (
		<div
			className={`${classes.wrapper} ${className}`}
			id={id}
			data-testid={id}
		>
			<div className={`questionPanel`}>
				<div className={`header`}>
					{isEmpty(titleSound[0]) === false && (
						<SoundButton
							border={false}
							sound={titleSound[0]}
							size={32}
							className={'transparent'}
							disabled={!interactive}
						/>
					)}

					<div
						dangerouslySetInnerHTML={{__html: question1.stem}}
					></div>
				</div>
				<div className={`content`}>{renderQuestion(0)}</div>
				<div className={`header`}>
					{isEmpty(titleSound[1]) === false && (
						<SoundButton
							border={false}
							sound={titleSound[1]}
							size={32}
							className={'transparent'}
							disabled={!interactive}
						/>
					)}

					<div
						dangerouslySetInnerHTML={{__html: question2.stem}}
					></div>
				</div>
				<div className={`content`}>{renderQuestion(1)}</div>
			</div>
			<div className={`button-row`}>
				{showResetButton && (
					<ActivityButton
						className="left"
						text={'Reset'}
						icon={'reset'}
						isPrimary={false}
						onClick={onReset}
					/>
				)}
				<ActivityButton
					className="right"
					text={buttonText}
					icon={buttonIcon}
					onClick={onSubmit}
					disabled={handleDisable()}
				/>
			</div>
		</div>
	);
};

ReadingTwoQuestionPanel.defaultProps = {
	id: '',
	className: '',
	title: '',
	titleSound: {},
	interactive: true,
	buttonText: 'Submit',
	buttonIcon: 'check',
	showResetButton: false,
	isSubmitEnabled: true,
	isSuccessZone: false,
	preSelectedIndex: -1
};
ReadingTwoQuestionPanel.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleSound: PropTypes.shape({
		name: PropTypes.string.isRequired,
		src: PropTypes.string.isRequired
	}),
	questionType: PropTypes.string,
	question: PropTypes.object,
	questions: PropTypes.array,
	onChoiceAnswerChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	interactive: PropTypes.bool,
	buttonText: PropTypes.string,
	buttonIcon: PropTypes.string,
	showResetButton: PropTypes.bool,
	onReset: PropTypes.func,
	isSubmitEnabled: PropTypes.bool,
	isSuccessZone: PropTypes.bool,
	preSelectedIndex: PropTypes.number
};

export default React.memo(ReadingTwoQuestionPanel);
