import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { getContentInfo } from '../../media/mediaUtil';
import { MEDIA_SERVER_URL } from '../../utils/constants';
import { bulkReplace } from '../../utils/stringUtils';
import { getPath } from '../../media/mediaUtil';
import { deepSet } from '../../utils/deep';
import media from '../../api/media';
import { cloneDeep } from 'lodash';
import api from '../../api/api';
import { getAudioExt } from '../../utils/audio';

const EXT = getAudioExt();

export const AnchorVideo = {
	// Sound Constants
	SOUND_AV_HELP_URL: `${MEDIA_SERVER_URL}assets/activi/anchorvideo/r180u_anchorvideo_help.${EXT}`,
	SOUND_AV_INTRO_URL: `${MEDIA_SERVER_URL}assets/activi/anchorvideo/r180u_anchorvideo_intro.${EXT}`,
	SOUND_AV_REPLAY_INTRO_URL: `${MEDIA_SERVER_URL}assets/activi/anchorvideo/r180u_anchorvideo_replayintro.${EXT}`,
	SOUND_AV_FIRST_SEGMENT_URL: `${MEDIA_SERVER_URL}assets/activi/anchorvideo/r180u_anchorvideo_firstsegment.${EXT}`,

	//VO Constants
	VO_REPLAY_INTO: 'anchor_video_replay_intro',

	//AV STATE
	STATE: {
		DEFAULT: 'DEFAULT',
		INTRO: 'INTRO',
		FIRST_SEGMENT: 'FIRST_SEGMENT'
	},

	TASKS_TO_ROUTE: {
		WORDCARD: 'word-card'
	},

	nextActivityPathName: (serverResponseNextActId, zone, studentActivityId) => {
		const route = AnchorVideo.TASKS_TO_ROUTE[serverResponseNextActId];
		return `/zone/${zone}/${route}`;
	},

	saveActivityState: async (datatoServer, bundledActivityData) => {
		// send it to the server, no need to await the response or if there's an error
		let newActivityData = {
			activity: {
				studentActivityId: bundledActivityData.activity.studentActivityId,
				isComplete: datatoServer.isComplete
			},
			introstatus: {
				activityId: bundledActivityData.introstatus.activityId,
				finished: datatoServer.isComplete
			}
		};
		// send it to the server, no need to await the response or if there's an error
		return await api.activity.updateActivity(newActivityData);
	},

	init: async activityData => {
		//////////////
		// pre-load the sounds
		//////////////

		AudioPlayer2.loadSound(AnchorVideo.SOUND_AV_INTRO_URL);
		AudioPlayer2.load({ name: 'anchor_video_help', src: AnchorVideo.SOUND_AV_HELP_URL });
		AudioPlayer2.load({ name: 'anchor_video_intro', src: AnchorVideo.SOUND_AV_INTRO_URL });
		AudioPlayer2.load({
			name: 'anchor_video_replay_intro',
			src: AnchorVideo.SOUND_AV_REPLAY_INTRO_URL
		});
		AudioPlayer2.load({
			name: 'anchor_video_first_segment',
			src: AnchorVideo.SOUND_AV_FIRST_SEGMENT_URL
		});

		//////////////
		// load the task data for these word cards
		//////////////
		let newActivityData = { ...activityData };
		newActivityData.activityServerId = 'anchor_video';
		const { activityAssetCode, assetId, urlPath } = getContentInfo(newActivityData, false);
		const modifiedActivityData = { ...activityData, activityAssetCode, assetId };
		const taskData = {};
		AnchorVideo.insertAssetUrl(taskData, modifiedActivityData);
		return { taskData };
	},

	insertAssetUrl: (contentData, modifiedActivityData) => {
		const assetsActivityTypeData =
			AnchorVideo.assetTypes['anchor_video'].tasks[modifiedActivityData.anchorVideoState];
		assetsActivityTypeData['fileMappings'].forEach(fileMapping => {
			const path = `${MEDIA_SERVER_URL}${getPath(fileMapping.location, modifiedActivityData.activityAssetCode)}/`;
			const constants = {
				activity_asset_code: modifiedActivityData.activityAssetCode,
				asset_id: modifiedActivityData.assetId
			};
			const assetFileName = bulkReplace(fileMapping.naming_rule, constants);
			const assetUrl = path + assetFileName;
			deepSet(contentData, fileMapping.fieldName + '_url', assetUrl);
		});
	},

	assetTypes: {
		anchor_video: {
			tasks: {
				DEFAULT: {
					fileMappings: [
						{
							fieldName: 'video',
							location: 'assets/video',
							naming_rule: 'r180u_video_{asset_id}.mp4'
						},
						{
							fieldName: 'video_poster',
							location: 'assets/activi/anchorvideo',
							naming_rule: 'r180u_anchorvideo_{asset_id}_banner.jpg'
						},
						{
							fieldName: 'video_thumb',
							location: 'assets/video',
							naming_rule: 'r180u_{asset_id}.png'
						},
						{
							fieldName: 'video_vtt',
							location: 'assets/video',
							naming_rule: 'r180u_{asset_id}.vtt'
						},
						{
							fieldName: 'video_summary',
							location: 'assets/video',
							naming_rule: `r180u_video_{asset_id}_summary_span.${EXT}`
						},
						{
							fieldName: 'help',
							location: 'assets/activi/anchorvideo',
							naming_rule: `r180u_anchorvideo_help.${EXT}`
						}
					]
				},
				INTRO: {
					fileMappings: [
						{
							fieldName: 'video',
							location: 'assets/activi/anchorvideo',
							naming_rule: `r180u_anchorvideo_intro.${EXT}`
						},
						{
							fieldName: 'video_vtt',
							location: 'assets/activi/anchorvideo',
							naming_rule: 'r180u_anchorvideo_intro.vtt'
						},
						{
							fieldName: 'help',
							location: 'assets/activi/anchorvideo',
							naming_rule: `r180u_anchorvideo_help.${EXT}`
						},
						{
							fieldName: 'video_poster',
							location: 'assets/activi/anchorvideo',
							naming_rule: 'r180u_anchorvideo_{asset_id}_banner.jpg'
						}
					]
				},
				FIRST_SEGMENT: {
					fileMappings: [
						{
							fieldName: 'video',
							location: 'assets/activi/anchorvideo',
							naming_rule: `r180u_anchorvideo_firstsegment.${EXT}`
						},
						{
							fieldName: 'video_vtt',
							location: 'assets/activi/anchorvideo',
							naming_rule: 'r180u_anchorvideo_firstsegment.vtt'
						},
						{
							fieldName: 'help',
							location: 'assets/activi/anchorvideo',
							naming_rule: `r180u_anchorvideo_help.${EXT}`
						},
						{
							fieldName: 'video_poster',
							location: 'assets/activi/anchorvideo',
							naming_rule: 'r180u_anchorvideo_{asset_id}_banner.jpg'
						}
					]
				}
			}
		}
	}
};
