import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
	spellingItem: {
		position: 'relative',
		width: '100%',
		display: 'inline-block',
		'& .correct-incorrect': {
			position: 'relative',
			marginRight: '11px'
		},
		'&.interactive': {
			'& .spell-entry': {
				// marginLeft: '35px'
			}
		},
		'&.incorrect': {
			position: 'relative',
			left: '90px'
		}
	},
	wrapper: {
		position: 'relative',
		display: 'inline-block',
		width: '60%',
		background: theme.colors.lightGrey,
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '4px',
		margin: 'auto',
		marginBottom: '8px',
		padding: '0px 18px 0px 50px',
		textAlign: 'left',
		transition: 'all 0.3s',
		'& .number': {
			fontFamily: theme.fonts.uiBold,
			position: 'absolute',
			left: '20px',
			top: '12px',
			fontSize: '18px',
			color: theme.colors.grey,
			transition: 'all 0.3s',
		},
		'& input': {
			width: '85%',
			textAlign: 'left',
			fontSize: '18px',
			padding: '12px 8px 12px 16px',
			transition: 'all 0.3s',
			color: theme.colors.grey,
			background: theme.colors.lightGrey,
			border: 'none',
			outline: 'none !important'
		},
		'&.hasFocus': {
			width: '100%',
			background: theme.colors.white,
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			border: 'none',
			borderBottom: `4px solid ${theme.colors.readingZone.blue2}`,
			marginBottom: '0',
			marginTop: '-8px',
			paddingLeft: '60px',
			'& .number': {
				top: '12px',
				fontSize: '32px',
				color: theme.colors.black,
			},
			'& input': {
				background: theme.colors.white,
				fontSize: '32px',
				color: theme.colors.black,

			}
		},
		'&.correct': {
			color: theme.colors.grey,
		},
		'&.incorrect': {
			'& input': {
				color: theme.colors.errorRed
			}
		}
	},
}));
