import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import {RadioCheck} from '../..';
import SoundButton from '../SoundButton';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: '-32px -40px -40px -40px',
		display: 'grid',
		gridTemplateRows: `1fr`,
		gridTemplateColumns: `1fr`,
		gridGap: '4px',
		justifyItems: 'stretch',
		alignItems: 'center',
		'& .spacer': {
			background: theme.colors.white
		},
		'& .th': {
			background: theme.colors.readingZone.blue5,
			fontSize: '16px',
			fontWeight: '400',
			lineHeight: '22px',
			padding: '8px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			'&.choice': {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
				minWidth: '90px'
			},
			'&.prompt': {
				width: '168px',
				fontSize: '16px',
				flex: 1,
				alignItems: 'flex-start'
			},

			'&.roundedBorders': {
				borderRadius: '0 0 0 8px'
			}
		},
		'& .radio': {
			background: theme.colors.paleGrey,
			height: '100%',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'&.roundedBottomRight': {
				borderRadius: '0 0 8px 0'
			}
		}
	},
	choices: {
		'& p': {
			fontSize: '16px'
		}
	},

	prompts: {
		marginLeft: '5px',
		'& p': {
			fontSize: '16px'
		}
	}
}));

const ChartQuestion = props => {
	const {id, className, onAnswerChange, question, interactive} = props;
	const classes = useStyles();

	const [selectedAnswers, setSelectedAnswers] = useState([]);

	const handleSelect = answer => {
		if (interactive === false) {
			return false;
		}

		// answers that have the same prompt_id are in the same row
		// and would be equivalent to the 'name' attribute in HTML
		let newAllAnswers = selectedAnswers.filter(a => {
			return a.prompt_id !== answer.prompt_id;
		});

		newAllAnswers.push(answer);
		setSelectedAnswers(newAllAnswers);
		onAnswerChange(newAllAnswers);
	};

	const rows = `repeat(${question.prompts.length + 1}, 1fr)`;
	const cols = `repeat(${question.choices.length + 1}, 1fr)`;
	const style = {
		gridTemplateRows: rows,
		gridTemplateColumns: cols
	};

	const colNum = question.choices.length;
	const answers = question.answers;

	return (
		<div
			className={`${classes.wrapper} ${className}`}
			id={id}
			style={style}
		>
			<div className="spacer"></div>
			{question.choices.map((choice, idx) => {
				return (
					<div className="th choice" key={idx}>
						<SoundButton
							border={false}
							sound={choice.sound || {name: '', src: ''}}
							size={32}
							className={'transparent'}
						/>
						<div
							className={classes.choices}
							dangerouslySetInnerHTML={{__html: choice.text}}
						></div>
					</div>
				);
			})}
			{question.prompts.map((prompt, idx) => {
				const thisRowsAnswers = answers.slice(
					idx * colNum,
					(idx + 1) * colNum
				);
				const roundedBorders =
					idx === question.prompts.length - 1 ? 'roundedBorders' : '';

				return (
					<React.Fragment key={idx}>
						<div className={`th prompt ${roundedBorders}`}>
							<SoundButton
								border={false}
								sound={prompt.sound || {name: '', src: ''}}
								size={32}
								className={'transparent'}
							/>
							<span
								className={classes.prompts}
								dangerouslySetInnerHTML={{__html: prompt.text}}
							></span>
						</div>
						{thisRowsAnswers.map((answer, k) => {
							const roundedBottomRight =
								idx === question.prompts.length - 1 &&
								thisRowsAnswers.length - 1 === k
									? 'roundedBottomRight'
									: '';
							return (
								<div
									className={`radio ${roundedBottomRight}`}
									key={k}
								>
									<RadioCheck
										className={'mr-0'}
										id={`${idx}-radio-check`}
										item={answer}
										isSelected={
											answer.status === 'selected'
										}
										type={'single'}
										status={answer.status}
										onSelect={() => {
											handleSelect(answer);
										}}
									/>
								</div>
							);
						})}
					</React.Fragment>
				);
			})}
		</div>
	);
};

ChartQuestion.defaultProps = {
	id: '',
	className: '',
	question: {},
	interactive: true
};
ChartQuestion.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	question: PropTypes.object.isRequired,
	onAnswerChange: PropTypes.func.isRequired,
	interactive: PropTypes.bool
};

export default ChartQuestion;
