import { getAudioExt } from "../../utils/audio";
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { MEDIA_SERVER_URL } from '../../utils/constants';

const EXT = getAudioExt();

export const FluencyAssessmentSelector = {
	HELP: `${MEDIA_SERVER_URL}assets/activi/assessselect/r180u_assessselect_help.${EXT}`,
	INTRO1: `${MEDIA_SERVER_URL}assets/activi/assessselect/r180u_assessselect_intro.${EXT}`,
	INTRO2: `${MEDIA_SERVER_URL}assets/activi/assessselect/r180u_assessselect_intro2.${EXT}`,
	MODAL: `${MEDIA_SERVER_URL}assets/activi/assessselect/r180u_assessselect_spell_modal.${EXT}`,
	MODAL_ACCEPT: `${MEDIA_SERVER_URL}assets/activi/assessselect/r180u_assessselect_spell_modalaccept.${EXT}`,
	SPELLING: `${MEDIA_SERVER_URL}assets/activi/assessselect/r180u_assessselect_spelling.${EXT}`,
	WORD: `${MEDIA_SERVER_URL}assets/activi/assessselect/r180u_assessselect_word.${EXT}`,

	init: async() => {
		AudioPlayer2.loadSound(FluencyAssessmentSelector.HELP);
		AudioPlayer2.loadSound(FluencyAssessmentSelector.INTRO1);
		AudioPlayer2.loadSound(FluencyAssessmentSelector.INTRO2);
		AudioPlayer2.loadSound(FluencyAssessmentSelector.MODAL);
		AudioPlayer2.loadSound(FluencyAssessmentSelector.MODAL_ACCEPT);
		AudioPlayer2.loadSound(FluencyAssessmentSelector.SPELLING);
		AudioPlayer2.loadSound(FluencyAssessmentSelector.WORD);
	}
};
