import React from 'react';
import PropTypes from 'prop-types';
import ImageChoice from '../../components/ImageChoice/ImageChoice';
import WordTitle from '../../components/WordTitle/WordTitle';
import ActivityDirections from '../../components/ActivityDirections';

const ImageMultipleChoice = (props) => {
	const { interactive, word, wordUrl, instructions, instructionsUrl, options, value, id, onSelection } = props;

    return (
		<div style={{textAlign: 'center'}}>

			<WordTitle
				className="mt-2 mb-3"
				word={word}
				src={wordUrl}
			/>

			<ActivityDirections
				style={{marginBottom: '24px'}}
				text={instructions}
				src={instructionsUrl}
			/>

			<ImageChoice
				interactive={interactive}
				id={id}
				options={options}
				onSelection={onSelection}
				value={value}
			/>
		</div>
	)
}

ImageMultipleChoice.defaultProps = {
	id: '',
	interactive: true,
	word: '',
	wordUrl: '',
	instructions: '',
	instructionsUrl: '',
	options: [],
	value: []
};

ImageMultipleChoice.propTypes = {
	id: PropTypes.string,
	interactive: PropTypes.bool,
	value: PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			])
		),
	word: PropTypes.string,
	wordUrl: PropTypes.string,
	instructions: PropTypes.string,
	instructionsUrl: PropTypes.string,
	options:  PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			id: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			]).isRequired,
			status: PropTypes.string.isRequired,
			src: PropTypes.string.isRequired
		})
	),
	onSelection: PropTypes.func.isRequired
};

export default React.memo(ImageMultipleChoice);
