import React from 'react';
import PropTypes from 'prop-types';
import {useStyle} from './MiniWordNSpeakerRow.style';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {displaySafe} from '@reading/r180/src/utils/stringUtils';

const MiniWordNSpeakerRow = props => {
	const {prefix, wordText, label, className, disabled} = props;
	const classes = useStyle();

	let adjustedLabel = label.replace(/<W>/g, '<b>').replace(/<\/W>/g, '</b>');

	const playAudio = () => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(`${prefix}-${wordText}`);
	};

	return (
		<div className={`${classes.wrapper}`}>
			<button
				className={`${classes.soundButton} ${disabled ? 'disabled' : ''}`}
				onClick={() => playAudio()}
				data-testid="speaker-button"
				disabled={disabled}
			>
				<i className="r180 speaker-center" />
			</button>
			<span className={`${classes.label} ${className}`}
				dangerouslySetInnerHTML={{__html: displaySafe(adjustedLabel)}}
			>
			</span>
		</div>
	);
};

MiniWordNSpeakerRow.defaultProps = {
	prefix: '',
	label: '',
	wordText: '',
	className: '',
	disabled: false
};

MiniWordNSpeakerRow.propTypes = {
	prefix: PropTypes.string,
	wordText: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	style: PropTypes.string,
	disabled: PropTypes.bool
};

export default React.memo(MiniWordNSpeakerRow);
