import React, {useState, useEffect} from 'react';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {useStyles} from './ReadingStrategySelector.style';
import {updateActivityAction} from '../../store/slices/activity';
import {useDispatch, useSelector} from 'react-redux';
import media from '../../api/media.js';
import {gotoNextActivity} from '../../utils/navigation';
import {useHistory} from 'react-router-dom';
import Navbar from '../../containers/App/Navbar';
import {createAssetId} from '../../media/mediaUtil';
import {getAudioExt} from '../../utils/audio';
import {MEDIA_SERVER_URL} from '../../utils/constants';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import {ActivityInstructionButton, InstructionsButton, SubzoneSelector} from '@reading/common';
import {uiSlice} from '../../store/slices/ui';
import {useUnmount} from 'react-use';
const EXT = getAudioExt();

const MEDIA = {
	SOUNDS: {
		help: {name: 'help', src: `${MEDIA_SERVER_URL}/assets/activi/readselect/r180u_readselect_help.${EXT}`},
		intro: {name: 'intro', src: `${MEDIA_SERVER_URL}/assets/activi/readselect/r180u_readselect_intro.${EXT}`},
		introComplete: {
			name: 'introComplete',
			src: `${MEDIA_SERVER_URL}/assets/activi/readselect/r180u_readselect_introstratscomplete.${EXT}`
		},
		recIncomplete: {
			name: 'introComplete',
			src: `${MEDIA_SERVER_URL}/assets/activi/readselect/r180u_readselect_recordingsincomplete.${EXT}`
		}
	}
};

const ReadingStrategySelector = ({cachedData = false}) => {
	const classes = useStyles();
	const [saveData, setSaveData] = useState(false);
	const [mediaData, setMediaData] = useState(false);
	const [isLoaded, setLoaded] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		activity: {activityData: activity, strategies},
		session: {
			session: {
				settings: {autoPlayEnabled}
			}
		}
	} = useSelector(state => {
		return {activity: state.activity, session: state.session};
	});
	useEffect(() => {
		const putActivityData = async () => {
			try {
				const result = await dispatch(updateActivityAction({data: saveData}));
				if (result.payload) {
					gotoNextActivity({history});
				} else {
					setSaveData(false);
				}
			} catch (err) {
				setSaveData(false);
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		};
		if (saveData) {
			putActivityData();
		}
	}, [saveData]);
	useEffect(() => {
		const getMediaData = async () => {
			const {stage, segment, level} = activity;
			try {
				const result = await media.getOne(
					`/content/activi/readselect/r180u_content_readselect_${createAssetId(
						stage,
						segment,
						0
					)}${level.toString().padStart(2, '0')}.json`
				);
				setMediaData(result);
				setLoaded(true);

			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		};
		if (activity && activity.activityServerId === 'reading_strategy_selector' && !mediaData) {
			getMediaData();
		}
	}, [activity, mediaData]);
	useEffect(() => {
		AudioPlayer2.loadSound(MEDIA.SOUNDS.introComplete.src);
		AudioPlayer2.loadSound(MEDIA.SOUNDS.intro.src);
	}, []);
	useEffect(()=>{
		if (isLoaded && autoPlayEnabled) {

			AudioPlayer2.play(
				strategies.completed.length === mediaData.availableStrategies.length
					? MEDIA.SOUNDS.introComplete.src
					: MEDIA.SOUNDS.intro.src
			);
		}
	},[isLoaded]);

	useUnmount(() => {
		dispatch(uiSlice.actions.setErrorMessage(''));
	});
	if (!strategies || !mediaData || !activity) {
		return null;
	}
	const {studentActivityId} = activity;
	const {completed} = strategies;
	const {availableStrategies} = mediaData;
	const setSelectedStrategy = selectedStrategy => {
		setSaveData({
			strategies: {...strategies, current: selectedStrategy},
			activity: {...activity, isComplete: true}
		});
	};

	return (
		<>
			<Navbar helpSoundUrl={MEDIA.SOUNDS.help.src} />
			<ActivitySuspense
				requiredRenderData={[{isLoaded}]}
				showSpinner={!isLoaded}
				title={'Reading Strategy Selector'}
			>
				<div className={`${classes.instructionsButton}`}>
					<ActivityInstructionButton
						white={true}
						audioSrc={
							completed.length === availableStrategies.length
								? MEDIA.SOUNDS.introComplete.src
								: MEDIA.SOUNDS.intro.src
						}
					/>
				</div>
				<div className={`${classes.wrapper} fill-app-frame`}>
					<div className={`${classes.optionsContainer}`}>
						{availableStrategies.map(strategy => {
							return (
								<div className={`${classes.optionContainer}`}>
									<SubzoneSelector
										className={`${classes.option}`}
										key={`strategy_button_${strategy}`}
										isComplete={completed.indexOf(strategy) > -1}
										onClick={() => {
											setSelectedStrategy(strategy);
										}}
										title={strategy}
										zone={'reading'}
										showInfobutton={false}
									/>
								</div>
							);
						})}
					</div>
				</div>
				<div
					className={`${classes.readRecord}`}
					onClick={() => {
						setSaveData({
							activity: {
								studentActivityId,
								isComplete: true,
								navigation: 'read_and_record'
							}
						});
					}}
				>
					<button className={`${classes.readRecordButton}`}>
						{' '}
						<i className="r180 mic" />
					</button>
					Read & Record
				</div>
			</ActivitySuspense>
		</>
	);
};

export default ReadingStrategySelector;
