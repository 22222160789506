import React from 'react';
import ReadingPassageSZActivity from '../activities/ReadingPassage/ReadingPassageSZActivity';
import SZFinalRecordingFluencyCheckActivity from '../activities/SZFinalRecordingFluencyCheck/SZFinalRecordingFluencyCheckActivity';
import ContextPassageActivity from '../activities/ContextPassage/ContextPassageActivity';
import DiscrepancyActivity from '../activities/Discrepancy/DiscrepancyActivity';
import SuccessScreen from '../activities/SuccessScreen/SuccessScreen';
const successZoneRoutes = [
	{
		route: 'sz_final_recording_fluency_check',
		serverRoute: 'final_recording',
		pageTitle: 'Fluency Check',
		component: <SZFinalRecordingFluencyCheckActivity />,
		author: ['Kiran']
	},
	{
		route: 'reading_passage_sz',
		serverRoute: 'reading_passage_sz',
		pageTitle: 'Stretch Read',
		component: <ReadingPassageSZActivity />,
		author: ['Luc']
	},
	{
		route: 'discrepancy_passages',
		pageTitle: 'Discrepancy Task',
		component: <DiscrepancyActivity />,
		author: ['Sumanth']
	},
	{
		route: 'context-passages',
		serverRoute: 'context_passages',
		pageTitle: 'Context Task',
		component: <ContextPassageActivity />,
		author: ['Sheik']
	},
	{
		route: 'success_screen',
		serverRoute: 'success_screen',
		pageTitle: 'Success Screen',
		component: <SuccessScreen />,
		author: ['Luc']
	}
];

export default successZoneRoutes;
