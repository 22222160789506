import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import media from '../../api/media';
import {getAudioExt} from '../../utils/audio';
import {KnowledgeForReading} from '../WordCard/KnowledgeForReading';
import {getContentInfo} from '../../media/mediaUtil';
import {MEDIA_SERVER_URL} from '../../utils/constants';
import {bulkReplace} from '../../utils/stringUtils';
import {isEmpty} from 'lodash';
import api from '../../api/api';
import {transitionToNextActivity} from '../../utils/navigation';
import { loadSleep } from '../../utils/ui';

const EXT = getAudioExt();

export const WordClinic = {
	wordsDataBaseURL: 'content/dictio/word/',
	MAX_WORDS: 5,
	INTRO_VO: `${MEDIA_SERVER_URL}/assets/activi/wordclinic/r180u_wordclinic_intro.${EXT}`,
	SCREEN_HELP_VO: `${MEDIA_SERVER_URL}/assets/activi/wordclinic/r180u_wordclinic_help.${EXT}`,
	WORD_BEFORE_LAST: `${MEDIA_SERVER_URL}/assets/activi/wordclinic/r180u_wordclinic_last_one.${EXT}`,

	WORD_CTXT_URL: `${MEDIA_SERVER_URL}/assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,
	WORD_DEF_URL: `${MEDIA_SERVER_URL}/assets/dictio/def/r180u_def_def-{word}.${EXT}`,
	WORD_CLINIC_COMPLETE: `${MEDIA_SERVER_URL}/assets/activi/wordclinic/r180u_wordclinic_finished.${EXT}`,
	WORD_CLINIC_NOVOICE: `${MEDIA_SERVER_URL}/assets/activi/wordclinic/r180u_wordclinic_recordinginaudible.${EXT}`,

	WORDS_VO: `${MEDIA_SERVER_URL}/assets/dictio/word/r180u_word_{currentWord}.${EXT}`,
	LAST_SCREEN: 15,
	LAST_BUT_SCREEN: 14,
	WORD_CARD_SCREEN: 1,
	RECORD_SCREEN: 2,
	RECORD_NEXT_SCREEN: 3,
	LANDING_SCREEN: 0,
	WORDCARD_COUNT: 5,
	WORDCARD_SCREEN_LIST: [1, 4, 7, 10, 13],
	WordClinicExplain: [
		{
			name: 'word_Explain',
			src: `${MEDIA_SERVER_URL}/assets/activi/wordclinic/r180u_wordclinic_explanation.${EXT}`
		},
		{
			name: 'word_Explain_1',
			src: `${MEDIA_SERVER_URL}/assets/activi/wordclinic/r180u_wordclinic_record_intro1.${EXT}`
		},
		{
			name: 'word_Explain_2',
			src: `${MEDIA_SERVER_URL}/assets/activi/wordclinic/r180u_wordclinic_record_intro2.${EXT}`
		}
	],

	loadAudioFiles: async(wordsList, activityData) => {
		const start = new Date().getTime();

		AudioPlayer2.load({name: 'WCIntro', src: WordClinic.INTRO_VO});
		AudioPlayer2.load({name: 'word_clinic_intro', src: WordClinic.INTRO_VO});
		AudioPlayer2.load({name: 'WordClinic_Complete', src: WordClinic.WORD_CLINIC_COMPLETE});
		AudioPlayer2.load({name: 'word_oneword_Left', src: WordClinic.WORD_BEFORE_LAST});

		// load all the sound effects
		Object.values(KnowledgeForReading.WORD_CARD_SFX).forEach(sfx => {
			AudioPlayer2.load({name: sfx.name, src: sfx.src});
		});

		AudioPlayer2.load({name: 'noVoice', src: WordClinic.WORD_CLINIC_NOVOICE});

		Object.values(WordClinic.WordClinicExplain).forEach(wordExplain => {
			AudioPlayer2.load({name: wordExplain.name, src: wordExplain.src});
		});

		wordsList.forEach(wordCard => {
			const word = wordCard.text.toLowerCase();
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, constants);
			const word_def_url = bulkReplace(WordClinic.WORD_DEF_URL, constants);
			const word_ctx_url = bulkReplace(WordClinic.WORD_CTXT_URL, constants);

			// the pronounced word
			AudioPlayer2.load({name: word, src: word_text_url});

			// the definition
			AudioPlayer2.load({name: `def-${word}`, src: word_def_url});

			// the context
			AudioPlayer2.load({name: `ctx-${word}`, src: word_ctx_url});
		});

		const end = new Date().getTime();
		await loadSleep(end - start);
	},

	initData: async apiData => {
		const wordsDataList = [];
		const promises = apiData.fluencyScores.map(async i => {
			let wordsData = await media.getOne(
				`${WordClinic.wordsDataBaseURL}r180u_content_${i.wordId.toLowerCase()}.json`
			);
			wordsData.contextualData[0] = {};
			wordsData.contextualData[0].segmentId = wordsData.id;
			wordsData.contextualData[0].contextualDefinition = wordsData.definition;
			wordsData.contextualData[0].contextualSentence = wordsData.sentence;
			wordsDataList.push(wordsData);
		});
		await Promise.all(promises);
		return wordsDataList;
	},

	// move to the next activity
	completeActivity: async (history, activityData) => {
		await transitionToNextActivity(history, {
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			}
		});
	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendProgressToServer: (activityData) => {
		if (isEmpty(activityData)) {
			return;
		}

		let savedActivityData = {
			activity: {
				studentActivityId: activityData.studentActivityId,
				isComplete: false
			}
		};

		api.activity.updateActivity(savedActivityData);
	}
};
