import React from 'react';
import {Slider} from '@material-ui/core';
import {useStyles} from './SegmentProgress.style';

const SegmentProgress = ({current, max, className}) => {
	const classes = useStyles();
	return (
		<div className={`${className}`}>
			<div className={`${classes.slider}`} id={'segment_progress'}>Segment Progress</div>
			<Slider
				value={(100 * current) / max}
				disabled
				track={false}
				classes={{rail: classes.blackRail}}
				className={`${classes.slider}`}
				role={'progressbar'}
				aria-valuemin={0}
				aria-valuemax={max}
				aria-valuenow={current}
				aria-labelledby={'segment_progress'}
			/>
		</div>
	);
};

export default React.memo(SegmentProgress);
