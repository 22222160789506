import {makeStyles} from '@material-ui/core';
import { isFirefox } from '@reading/r180/src/utils/browsers';

const layout = theme => ({
	'& .layout': {
		height: '100%',
		padding: '20px',
		display: 'block',

		'& .image-wrapper': {
			height: '192px',
			backgroundColor: '#CCC',
			marginBottom: '10px',

			backgroundRepeat: 'no-repeat',
			backgroundSize: '100% auto',
			backgroundPosition: 'center center'
		},

		'& .content-title': {
			height: '100px',
			padding: '0 20px',

			'& h3': {
				fontSize: '24px',
				fontWeight: '700',
				marginTop: '16px',
				marginBottom: '16px'
			},
			'& h5': {
				fontSize: '16px',
				fontWeight: '500',
				marginTop: 0,
				marginBottom: '20px'
			}
		},

		'& .content-wrapper': {
			background: theme.colors.white,
			height: props => `calc(100vh - ${props.headerHeight + 390}px)`,
			overflowY: isFirefox() ? 'auto' : 'overlay',
			marginTop: '-10px',

			'&::-webkit-scrollbar': {
				width: '6px'
			},

			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				padding: '10px 0'
			},

			'&::-webkit-scrollbar-thumb': {
				background: '#CCC',
				borderRadius: '8px'
			},

			'& .content': {
				height: '100%',
				padding: '0px 20px 20px 20px',

				'& p': {
					textIndent: '28px',
					fontSize: '16px',
					lineHeight: '30px'
				}
			}
		}
	}
});

const layout2 = theme => ({
	'& .layout.layout-2': {
		height: '100%',
		padding: '20px',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		alignItems: 'center',

		'& .image-wrapper': {
			position: 'absolute',
			left: 0,
			top: 0,
			width: 'calc(100% - 20px * 2)',
			height: '282px',
			backgroundColor: '#CCC',
			margin: '20px',
			backgroundSize: 'auto 100%',
		},

		'& .content-title': {
			padding: '0 40px',
			height: '100px',
			zIndex: '999',
			textAlign: 'center',
			width: '100%',

			'& h3': {
				fontSize: '24px',
				fontWeight: '700',
				marginTop: '40px',
				marginBottom: '16px',
				color: theme.colors.white,
				textShadow: '0px 0px 5px rgba(0, 0, 0, 0.8)',
				textAlign: 'left'
			},
			'& h5': {
				fontSize: '16px',
				fontWeight: '500',
				marginTop: 0,
				marginBottom: '30px',
				color: theme.colors.white,
				textShadow: '0px 0px 5px rgba(0, 0, 0, 0.8)',
				textAlign: 'left'
			}
		},

		'& .content-wrapper': {
			width: '85%',
			background: theme.colors.white,
			zIndex: 999,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			height: 'calc(100% - 180px)',
			position: 'relative',
			top: '80px'
		}
	}
});

const layout3 = theme => ({
	'& .layout.layout-3': {
		height: '100%',
		padding: '20px 20px',
		display: 'block',

		'& .image-wrapper': {
			height: '224px',
			backgroundColor: theme.colors.writingZone.primary,
			marginBottom: '10px',
			position: 'relative',

			backgroundPositionX: '-100px',
			backgroundPositionY: '0',
			backgroundRepeat: 'no-repeat',
			backgroundSize: '100% 100%',

			'&::after': {
				content: '""',
				display: 'block',
				position: 'absolute',
				height: '100%',
				width: '20px',
				right: '20%',
				top: 0,
				background: theme.colors.white
			}
		},

		'& .content-wrapper': {
			height: props => `calc(100vh - ${props.headerHeight + 410}px)`,
		}
	}
});

const layout4 = theme => ({
	'& .layout.layout-4': {
		height: '100%',
		padding: '20px',

		'& .image-wrapper': {
			height: '202px',
			backgroundColor: '#CCC',
			marginBottom: '10px',

			backgroundRepeat: 'no-repeat',
			backgroundSize: '100% auto',
			backgroundPosition: 'center center'
		},

		'& .content-title': {
			position: 'absolute',
			width: '130px',
			height: 'auto',
			padding: '0',

			'& h3, & h5': {
				overflow: 'hidden',
				textOverflow: 'ellipsis'
			}
		},

		'& .content-wrapper': {
			background: theme.colors.white,
			display: 'inline-block',
			width: '100%',
			height: props => `calc(100vh - ${props.headerHeight + 310}px)`,
			marginTop: '20px',
			paddingLeft: '120px'
		}
	}
});

export const useStyles = makeStyles(theme => {
	const LINE_HEIGHT = 42;
	return {
		wrapper: {
			width: '100%',
			overflow: 'visible',
			height: 'auto',
			padding: '10px 30px',

			'& button': {
				color: theme.colors.white
			},

			'& .review-layout': {
				display: 'flex',
				flexDirection: 'row',
				columnGap: '20px',
				padding: '10px 120px',
				alignItems: 'flex-start',
				justifyContent: 'center',

				'& .preview-panel': {
					height: props => `calc(100vh - ${props.headerHeight + 40}px)`,
					background: theme.colors.white,
					width: '100%',
					minWidth: '496px',
					maxWidth: '528px',

					// LAYOUTS
					...layout(theme),
					...layout2(theme),
					...layout3(theme),
					...layout4(theme)
				}
			},

			'& .control-panel': {
				background: 'transparent',
				marginTop: '20px',
				width: '488px',

				display: 'flex',
				flexDirection: 'column',

				'& .control-card': {
					flexGrow: 1,
					background: 'rgba(255, 255, 255, 0.8)',
					border: 'solid 2px rgba(255, 255, 255, 0.3)',
					borderRadius: '0',
					overflow: 'hidden',
					transition: 'background 0.3s ease',

					'&:first-of-type': {
						border: 'solid 2px rgba(255, 255, 255, 0.3)',
						borderRadius: '8px 8px 0 0'
					},

					'&:nth-of-type(3)': {
						borderRadius: '0 0 8px 8px'
					},

					'&:last-of-type': {
						background: 'transparent',
						border: 'none',
						borderRadius: '0',
						padding: '32px 0',
						overflow: 'visible',
						'& button': {
							float: 'right'
						}
					},

					'& .control-card-header': {
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						padding: '5px 10px',
						background: '#008500',
						color: theme.colors.white,

						'& h4': {
							flexGrow: 1,
							margin: '0 0 0 10px',
							fontSize: '18px',
							fontWeight: 700,
							position: 'relative',
							top: '-1.5px'
						},

						'& i': {
							width: '20px',
							padding: '3px',
							flexGrow: 0,
							cursor: 'pointer'
						}
					},

					'& .control-card-content': {
						padding: '24px 32px',

						'& div': {
							width: '100%'
						},

						'& .item-list': {
							display: 'flex',
							flexDirection: 'row',
							columnGap: '8px',
							overflowX: isFirefox() ? 'auto' : 'overlay',
							'&::-webkit-scrollbar': {
								width: '6px'
							},
							'&::-webkit-scrollbar-track': {
								background: 'transparent',
								padding: '10px 0'
							},
							'&::-webkit-scrollbar-thumb': {
								background: '#CCC',
								borderRadius: '8px'
							},
							justifyContent: 'space-between',

							'& img': {
								borderRadius: '2px',
								border: '4px solid transparent',
								height: 'auto',
								width: '96px',

								'&.selected': {
									border: `4px solid ${theme.colors.readingZone.background}`,
									borderRadius: '8px'
								}
							},

							'& img.thumb': {
								width: '130px',
								height: '80px',
								objectFit: 'cover',
								objectPosition: 'center',
								borderRadius: '8px'
							}
						},

						'& .textfield': {
							width: '100%',
							background: 'transparent',
							border: 'none',
							borderBottom: `2px solid ${theme.colors.softBlack}`,
							padding: '4px 10px',
							fontSize: '18px',
							fontFamily: theme.fonts.context,
							color: theme.colors.softBlack,
							transition: 'all ease 0.3s',
							'&.small': {
								fontSize: '13px'
							}
						}
					},

					'&.inactive': {
						background: 'rgba(255, 255, 255, 0.4)',
						'& button': {
							cursor: 'not-allowed'
						},
						'& .textfield': {
							cursor: 'not-allowed'
						}
					},
				}
			}
		}
	};
});
