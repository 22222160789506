import React, {useState, useRef} from 'react';
import {makeStyles} from '@material-ui/core';
import SegmentImageTile from '../SegmentImageTile/SegmentImageTile';
import ClusterSounds from '@reading/common/src/activityComponents/SegmentSelection/ClusterSounds/ClusterSounds';
import {keyAndClick} from '../../../util/ui-a11y';
import {clusterStyles} from "@reading/r180/src/utils/constants";

export const useStyles = makeStyles(theme => ({
	clusterRowWrapper: {
		width: '97%',
		marginTop: '24px',
		marginLeft: '24px',
	},
	clusterRowTitle: {
		fontWeight: 'bold',
		fontSize: '24px',
		display: 'flex',
		alignItems: 'center',
		'& span': {
			fontSize: '48px',
			marginRight: '24px',
			fontWeight: 'normal',
		}
	},
	clusterRowCarousel: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly'
	},
	clusterIcon: {
		fontFamily: 'r180',
		cursor: 'pointer',
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.3)'
		}
	},
	clusterRowCarouselContainer: {
		position: 'relative',
	},
	clusteRowCarouselScrollButton: {
		position: 'absolute',
		top: '50%',
		width: '32px',
		height: '32px',
		borderRadius: '16px',
		background: theme.colors.transWhite,
		color: theme.colors.white,
		fontSize: '16px',
		fontFamily: 'r180',
		zIndex: 1,
		textAlign: 'center',
		lineHeight: '32px',
		cursor: 'pointer',
	},
	buttonLeft: {
		left: '0%',
		transform: 'translate(50%,-50%)',
	},
	buttonRight: {
		right: '0%',
		transform: 'translate(-50%,-50%)',

	},
	spaceTaker: {
		minWidth: '240px',
		marginRight:'16px',
	},
	...clusterStyles,
}));

const SegmentClusterRow = (props) => {
	const { cluster, segments, stage, completedSegments, previewSegment } = props;
	const classes = useStyles();
	const scrollRef = useRef(null);

	const [scrollX, setScrollX] = useState(0);

	const mySegments = segments.filter(segment =>
		segment.clusters.find(c => c.id === cluster.id)
	);
	// const scrollDelta = 240;
	// const maxScroll = (mySegments.length - 3) * scrollDelta;

	// const handleScroll = (event, direction) => {
	// 	event.stopPropagation();
	// 	const newScrollX = Math.max(
	// 		0,
	// 		Math.min(maxScroll, scrollX + scrollDelta * direction)
	// 	);
	// 	setScrollX(newScrollX);
	// 	scrollRef.current.scrollLeft = newScrollX;
	// };

	const playSounds = ClusterSounds({id: cluster.id});

	return (
		<div
			className={classes.clusterRowWrapper}
			aria-label={`Segments in cluster: ${cluster.title}`}
			role={'group'}

		>
			<div className={classes.clusterRowTitle}>
				<span
					className={`${classes.clusterIcon} ${
						classes[`cluster_${cluster.id}`]
					}`}
					{...keyAndClick(playSounds)}
					tabIndex={0}
					aria-label={`${cluster.title} VO`}
					role={'button'}
				/>{' '}
				{cluster.title}
			</div>
			<div
				datatestid="carousel"
				className={classes.clusterRowCarouselContainer}
				scrollLeft={scrollX}

			>
				{/* {mySegments.length > 4 && scrollX > 0 && (
					<div
						datatestid="leftButton"
						className={`${classes.clusteRowCarouselScrollButton} ${classes.buttonLeft}`}
						{...keyAndClick(event => {
							handleScroll(event, -1);
						})}
						tabIndex={0}
						aria-label={'scroll left'}
					>
						&#xe902;
					</div>
				)} */}
				<div className={classes.clusterRowCarousel} ref={scrollRef}>
					{mySegments.map(segment => (
						<SegmentImageTile
							key={`segmentImageTile${segment.id}`}
							segment={segment}
							completedSegments={completedSegments}
							stage={stage}
							previewSegment={previewSegment}
						/>
					))}
				</div>
				{/* {mySegments.length - 4 > scrollX / scrollDelta && (
					<div
						datatestid="rightButton"
						className={`${classes.clusteRowCarouselScrollButton} ${classes.buttonRight}`}
						{...keyAndClick(event => {
							handleScroll(event, 1);
						})}
						tabIndex={0}
						aria-label={'scroll right'}

					>
						&#xe903;
					</div>
				)} */}
			</div>
		</div>
	);
};

export default React.memo(SegmentClusterRow);
