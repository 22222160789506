import React, {useState, useEffect, useMemo, useRef} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {useHistory} from 'react-router-dom';
import media from '../../api/media';
import {bulkReplace} from '../../utils/stringUtils';
import {createAssetId} from '../../media/mediaUtil';
import {isEmpty} from 'lodash';
import {MEDIA_SERVER_URL} from '../../utils/constants';
import {useStyles} from './ReadingPassageActivity.style';
import {
	ReadingPassage,
	ConnectMatchingQuestion,
	HighlightingPassage,
	MultiPart,
	OrderingSequence,
	Sorting,
	TabBar,
	ReadAloudControlBar,
	AnchorVideo,
	ActivityButton,
	SoundButton,
	QuestionType,
	ActivitySupportVideoSummaryButton,
	CaptionedAudioPlayer2 as AudioPlayer2,
	QuestionBreadcrumb
} from '@reading/common';
import RecordControlBar from '@reading/common/src/activityComponents/RecordControlBar/RecordControlBar';
import ReadingPassageReview from '@reading/common/src/activityComponents/ReadingPassageReview/ReadingPassageReview';

import Navbar from '../../containers/App/Navbar';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import WordCard from '@reading/common/src/components/WordCard/WordCard';
import {getAudioExt} from '../../utils/audio';
import AudioRecorder from '@reading/common/src/components/AudioRecorder/AudioRecorder';
import {Modal} from '@material-ui/core';
import useIdleHelp from '../../utils/useIdleHelp';
import {
	VO,
	MEDIA,
	STRATEGIES,
	MODES,
	WORD_DELAYS,
	tabs,
	MIN_REC_LENGTH_SEC,
	SEEKSTART,
	SEEKFORWARD,
	SEEKBACK,
	SEEKEND,
	NOISE_THRESHOLD,
	SILENCE_TIME,
	sendProgressToServer,
	completeActivityNoTransition
} from './ReadingPassageUtils';
import ConfirmationModal from '@reading/common/src/components/Modals/ConfirmationModal';
import {ReadingQuestionPanel} from '@reading/common';
import {uiSlice} from '../../store/slices/ui';
import ChartQuestion from '@reading/common/src/components/ChartQuestion';
import TwoPartQuestion from '@reading/common/src/components/TwoPartQuestion/TwoPartQuestion';
import {transitionFromResultsPageToNextActivity} from '../../utils/navigation';
import useBatchedSetState from '../../utils/useBatchedSetState';
import {getContentInfo} from '../../media/mediaUtil';
import {KnowledgeForReading} from '../WordCard/KnowledgeForReading';

const EXT = getAudioExt();

const ReadingPassageActivity = () => {
	const {setState} = useBatchedSetState();
	const audioRecorder = useMemo(() => {
		const audioRecorder = new AudioRecorder();
		audioRecorder.recordingReady = duration => {
			clearTimeout(saveDelayTimeout.current);
			if (duration >= MIN_REC_LENGTH_SEC) {
				setState(() => {
					setCanPlay(true);
					setCanSave(true);
				});
				if (autoPlayEnabled) {
					AudioPlayer2.play('mandrecordhelppost');
				}
			} else {
				AudioPlayer2.play('feedback_tooshort');
				setState(() => {
					setCanPlay(false);
					setCanSave(false);
				});
			}
		};

		return audioRecorder;
	}, []);
	const {
		activityData: {studentActivityId, activityServerId, stage, segment, level},
		passage: {
			mustRecord,
			mustReadAloud,
			modeledRead,
			readAloudCompleted,
			strategy,
			userRating: actUserRating,
			items: actItems,
			challengeAccepted
		},
		session: {
			session: {
				settings: {enableMicBypass, autoPlayEnabled}
			}
		},
		readingsettings,
		contextClueItems: {contextClueItems: actContextClueItems, studentSegmentId}
	} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			passage: state.activity.passage || {
				mustRecord: null,
				mustReadAloud: null,
				modeledRead: null,
				readAloudCompleted: null,
				strategy: null,
				userRating: null,
				items: [],
				challengeAccepted: null
			},
			session: state.session,
			readingsettings: state.activity.readingsettings,
			contextClueItems: state.activity.contextClueItems || {contexClueItems: [], studentSegmentId: ''}
		};
	}, shallowEqual);
	const [passageString, setPassageString] = useState(false);
	const [activeTab, setActiveTab] = useState(tabs.allTabs[0]);
	const [previousTab, setPreviousTab] = useState(tabs.allTabs[0]);
	const [segmentData, setSegmentData] = useState(false);
	const [passageQuestions, setPassageQuestions] = useState({});
	const [mainQuestions, setMainQuestions] = useState([]);
	const [ctxQuestions, setCtxQuestions] = useState([]);
	const [currentDOK, setCurrentDOK] = useState(0);
	const [fluencyQuestions, setFluencyQuestions] = useState([]);
	const [understandingRating, setUnderstandingRating] = useState(actUserRating);
	const [passageQuestionsIndex, setPassageQuestionsIndex] = useState(0); // TODO: change the question index based on flow
	const [correctiveFeedbackList, setCorrectiveFeedbackList] = useState([]);
	const [showCorrectiveFeedback, setShowCorrectiveFeedback] = useState(false);
	const [highlightQuestionOptions, setHighlightQuestionOptions] = useState([]);
	const [selectedHighlightedOptions, setSelectedHighlightedOptions] = useState([]);
	const [isHighlightQuestionActive, setHighlightQuestionActive] = useState(false);
	const [canClickPassageWord, setCanClickPassageWord] = useState(true);
	const [showRecordingTips, setShowRecordingTips] = useState(false);
	const [canPlay, setCanPlay] = useState(false);
	const [cardWord, setCardWord] = useState(false);
	const [wordCardData, setWordCardData] = useState(false);
	const [mode, setMode] = useState(MODES.RECORD);
	const [readAloudComplete, setReadAloudComplete] = useState(readAloudCompleted);
	const [readWords, setReadWords] = useState(false);
	const [readPhrases, setReadPhrases] = useState(false);
	const [readMode, setReadMode] = useState('word');
	const [wordDelay, setWordDelay] = useState(WORD_DELAYS[3]);
	const [playbackProgress, setPlaybackProgress] = useState(0);
	const [showRecordingSaveModal, setShowRecordingSaveModal] = useState(false);
	const [showRecordingSubmitModal, setShowRecordingSubmitModal] = useState(false);
	const [isChallengeAccepted, setChallengeAccepted] = useState(false);
	const [showRecordingKillModal, setShowRecordingKillModal] = useState(false);
	const [showMicBypassModal, setShowMicBypassModal] = useState(false);
	const [video, setVideo] = useState({pause: () => {}});
	const [loaded, setLoaded] = useState(false);
	const [items, setItems] = useState(actItems);
	const [contextClueItems, setContextClueItems] = useState(actContextClueItems);
	const [sendProgressFlag, setSendProgressFlag] = useState(false);
	const [selectedTabID, setSelectedTabID] = useState('');
	const [selectedTabIndex, setSelectedTabIndex] = useState('');
	const [twoPartQuestionIds, setTwoPartQuestionIds] = useState([]);
	const [startRecordCountDown, setStartRecordCountDown] = useState(false);
	const [tipsHaveBeenShown, setTipsHaveBeenShown] = useState(false);
	const [readAloudPaused, setReadAloudPaused] = useState(false);
	const [readType, setReadType] = useState('word');
	const [isPlayBackClicked, setPlayBackClicked] = useState(false);

	const classes = useStyles();
	const history = useHistory();
	const mediaConstants = {
		stage: stage ? stage.toLowerCase() : 'a',
		segment: segment ? segment.toString().padStart(2, '0') : '01',
		level: level ? level.toString().padStart(2, '0') : '01',
		strategy: strategy ? STRATEGIES[strategy] : 'Main Idea'
	};
	const posterSrc = `${MEDIA_SERVER_URL}${bulkReplace(MEDIA.segmentFeature, mediaConstants)}`;
	const videoSrc = `${MEDIA_SERVER_URL}${bulkReplace(MEDIA.segmentVideo, mediaConstants)}`;
	const videoCaptionSrc = `${MEDIA_SERVER_URL}${bulkReplace(MEDIA.segmentVideoCaptions, mediaConstants)}`;
	const dispatch = useDispatch();
	const saveDelayTimeout = useRef(0);
	const [canSave, setCanSave] = useState(false);
	const whichHelp = () => {
		if (mustReadAloud && !readAloudComplete) {
			if (activeTab.id === '1') {
				if (readAloudComplete) {
					return `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_mandreadhelppost.${EXT}`;
				}
				return `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_mandreadhelppre.${EXT}`;
			}
			if (activeTab.id === '2') {
				return `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_record.${EXT}`;
			}
		} else {
			if (activeTab.id === '1') {
				return `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_readaloud.${EXT}`;
			}
			if (activeTab.id === '2') {
				return `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_record.${EXT}`;
			}
		}
	};
	const handleGoOn = () => {
		if (!modeledRead && !(activeTab.id === '2' && canPlay)) {
			setMode(MODES.UNDERSTANDING);
			return;
		}
		if (activeTab.id === '1') {
			setActiveTab({id: '2'});
			if (enableMicBypass) {
				setShowMicBypassModal(true);
				setMode(MODES.RATE);
			}
			return;
		}
		if (activeTab.id === '2') {
			if (mode === MODES.RECORD) {
				if (mustRecord) {
					if (canPlay) {
						setShowRecordingSaveModal(true);
					}
				} else {
					if (canPlay) {
						setShowRecordingSaveModal(true);
					} else {
						setMode(MODES.UNDERSTANDING);
					}
				}
			}
			if (mode === MODES.UNDERSTANDING) {
				setMode(MODES.COMPREHENSION);
			}
		}
	};
	const isGoOnDisabled = () => {
		const DISABLED = true;
		const ENABLED = false; //I was getting confused

		if (activeTab.id === '1') {
			if (mustReadAloud && !readAloudComplete) {
				return DISABLED;
			}
			return ENABLED;
		}
		if (activeTab.id === '2') {
			if (mode === MODES.RECORD) {
				if (mustRecord && !canPlay) {
					return DISABLED;
				}
				return ENABLED;
			}
			if (mode === MODES.RATE) {
				return DISABLED;
			}
		}
	};

	const makeQuestionArray = (dok = currentDOK, mainQuestions, ctxQuestions, items) => {
		const questionArray = [];

		items.forEach(item => {
			const question = mainQuestions.find(question => question.id === item.id);
			if (question) {
				questionArray.push(question);
				if (item.attempts === 1 && item.isCorrect) {
					//If the resumed question is correct on the first try, then set the dok to one higher if possible
					if (mainQuestions.find(q2 => q2.dok === question.dok + 1)) {
						dok = question.dok + 1;
					}
				} else {
					//Otherwise set the dok lower if possible
					if (mainQuestions.find(q2 => q2.dok === question.dok - 1)) {
						dok = question.dok - 1;
					}
				}
			}
		});
		const numQ = modeledRead ? 2 : 3;
		let ignoreDok = false;
		let downDok = false;
		let maxTries = 10; //If server response is incorrect this loop may become infinite, this is to prevent that
		while (questionArray.length < numQ && maxTries-- > 0) {
			const nextQuestions = mainQuestions.filter(question => {
				if ((question.dok !== dok && !ignoreDok) || (question.dok > dok && downDok)) {
					return false;
				} //exclude if wrong dok
				if (items.find(item => item.id === question.id)) {
					return false;
				} //exclude if done before
				if (questionArray.find(arrayQ => arrayQ.id === question.id)) {
					return false;
				} //exclude if already in questionArray
				return true;
			});
			if (!nextQuestions.length) {
				if (!downDok) {
					downDok = true;
				} //If we don't have any results the first time thru, look for questions at a lower dok
				else {
					ignoreDok = true;
				} //If we still don't have any questions when downDok is true, ignore dok altogether
			} else {
				questionArray.push(nextQuestions[Math.floor(Math.random() * nextQuestions.length)]); //Add a random (never before seen) question of the current dok level
			}
		}

		if (questionArray.length < numQ + 1 && !modeledRead) {
			//Add context question
			const nextQuestions = ctxQuestions.filter(question => {
				if (items.find(item => item.id === question.id)) {
					return false;
				} //exclude if done before
				if (questionArray.find(arrayQ => arrayQ.id === question.id)) {
					return false;
				} //exclude if already in questionArray
				return true;
			});
			questionArray.push(nextQuestions[Math.floor(Math.random() * nextQuestions.length)]); //Add a random (never before seen) question of the current dok level
		}
		setPassageQuestions({questions: questionArray});
	};
	useIdleHelp(whichHelp());

	useEffect(() => {
		if (!isEmpty(passageQuestions)) {
			const questionIds = [];
			passageQuestions.questions.forEach(question => {
				if (question.type === QuestionType.TWO_PART) {
					questionIds.push([question.children[0].id, question.children[1].id]);
				}
			});
			setTwoPartQuestionIds(questionIds);
		}
	}, [passageQuestions]);

	useEffect(() => {
		if (sendProgressFlag) {
			let isLastQuestion = false;
			const questionCount = passageQuestions.questions.reduce((acc, question) => {
				if (question.type === QuestionType.TWO_PART) {
					return acc + 2;
				}
				return acc + 1;
			}, 0);
			if (
				(modeledRead && items.length >= questionCount) ||
				(modeledRead === false && items.length >= questionCount)
			) {
				isLastQuestion = true;
			}

			let dataToSend = {
				activity: {
					studentActivityId,
					isComplete: false
				},
				passage: {
					mustRecord,
					mustReadAloud,
					studentActivityId,
					readAloudCompleted: readAloudComplete,
					userRating: understandingRating,
					challengeAccepted: isChallengeAccepted,
					items
				},
				contextClueItems: {
					contextClueItems,
					studentSegmentId
				},
				readingsettings
			};

			if (isLastQuestion) {
				dataToSend.activity.isComplete = true;
				completeActivityNoTransition(dataToSend);
			} else {
				sendProgressToServer(dataToSend);
			}

			setSendProgressFlag(false);
		}
	}, [sendProgressFlag]);

	useEffect(() => {
		if (passageString && segmentData && strategy) {
			setLoaded(true);
		}
	}, [passageString, segmentData, strategy]);
	useEffect(() => {
		audioRecorder.progressCallback = (progress, isPlayBackCompleted) => {
			if (mode === MODES.RECORD && activeTab.id === '2' && canPlay) {
				setPlaybackProgress(progress);
			}
			if (isPlayBackCompleted) {
				setPlayBackClicked(false);
			}
		};
		audioRecorder.levelsCallback = level => {
			if (
				mode === MODES.RECORD &&
				activeTab.id === '2' &&
				!canPlay &&
				audioRecorder.micRecorder.state === 'recording'
			) {
				const stopElem = document.querySelector('.action-button');

				if (stopElem) {
					stopElem.style.borderWidth = `${3 + 8 * level}px`;
					const size = 36 + 8 * level * 2;
					stopElem.style.width = `${size}px`;
					stopElem.style.height = `${size}px`;
					stopElem.style.borderRadius = `${size / 2}px`;
				}

				if (level < NOISE_THRESHOLD) {
					if (!audioRecorder.silenceTimeout) {
						audioRecorder.silenceTimeout = setTimeout(silenceTimeoutHandler, SILENCE_TIME);
					}
				} else {
					clearTimeout(audioRecorder.silenceTimeout);
					audioRecorder.silenceTimeout = null;
				}

				return stopElem ? stopElem.style : {borderWidth: `${3 + 8 * level}px`};
			}
		};
	}, [mode, activeTab, canPlay]);

	useEffect(() => {
		const getPassageMedia = async () => {
			try {
				const payload = await media.getOne(
					bulkReplace(MEDIA.PASSAGE_TEXT, {asset_id: createAssetId(stage, segment, level, true)})
				);
				setPassageString(payload);
			} catch (e) {
				setPassageString(false);
				dispatch(uiSlice.actions.setErrorMessage(e));
			}
		};
		const preloadSound = () => {
			MEDIA.SOUNDS.forEach(sound => {
				AudioPlayer2.load(sound);
			});
			VO.forEach(name => {
				AudioPlayer2.load({
					name: name.replace('r180u_readpass_', ''),
					src: bulkReplace(`${MEDIA_SERVER_URL}/assets/activi/readpass/${name}.${EXT}`, {
						stage,
						segment,
						level,
						strategy: STRATEGIES[strategy]
					})
				});
			});
		};
		const getPassageQuestions = async () => {
			try {
				const {questions: mainQuestionsData} = await media.getOne(
					bulkReplace(MEDIA.PASSAGE_QUESTION, mediaConstants)
				);
				const {questions: ctxQuestionsData} = await media.getOne(
					bulkReplace(MEDIA.PASSAGE_CONTEXT_QUESTION, mediaConstants)
				);
				let questionIndex = 0;
				mainQuestionsData.forEach(question => {
					if (actItems.find(item => item.id === question.id && item.attempts)) {
						questionIndex += 1;
					}
				});
				let lowestDok = 999999;
				mainQuestionsData.forEach(question => {
					if (question.dok < lowestDok) {
						lowestDok = question.dok;
					}
				});
				ctxQuestionsData.forEach(question => {
					if (question.dok < lowestDok) {
						lowestDok = question.dok;
					}
				});
				if (challengeAccepted && mainQuestionsData.find(question => question.dok === lowestDok + 1)) {
					lowestDok += 1;
				}
				setMainQuestions(mainQuestionsData);
				setCtxQuestions(ctxQuestionsData);
				setCurrentDOK(lowestDok);
				makeQuestionArray(lowestDok, mainQuestionsData, ctxQuestionsData, actItems);
				setPassageQuestionsIndex(questionIndex);
				if (questionIndex > 0) {
					setMode(MODES.COMPREHENSION);
				}
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				setPassageQuestions([]);
			}
		};
		if (activityServerId === 'reading_passage_rz' && stage && segment && level) {
			getPassageMedia();
			preloadSound();
			if (strategy) {
				getPassageQuestions();
			}
		}
	}, [activityServerId, stage, segment, level, strategy]);

	useEffect(() => {
		const getContentData = async () => {
			try {
				const payload = await media.getOne(bulkReplace(MEDIA.SEGMENTS, mediaConstants));
				const segmentData = payload.segments.find(filterSegment => filterSegment.segment_number === segment);
				setSegmentData(segmentData);
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				setSegmentData({title: ''});
			}
		};
		if (stage) {
			getContentData();
		}
	}, [stage]);

	useEffect(() => {
		const getFluencyQuestions = async () => {
			try {
				const payload = await media.getOne(MEDIA.FLUENCY_RATING_QUESTION);
				setFluencyQuestions(payload.questions);
			} catch (e) {
				setFluencyQuestions([]);
			}
		};
		getFluencyQuestions();
	}, []);

	useEffect(() => {
		const getWordCardData = async () => {
			try {
				let wordCardData = await media.getOne(bulkReplace(MEDIA.WORD_CARD, {word: cardWord.word}));
				if (!wordCardData.contextualData[0]) {
					wordCardData.contextualData[0] = {
						contextualDefinition: wordCardData.definition,
						contextualSentence: wordCardData.sentence
					};
				}
				const data = {studentActivityId, activityServerId, stage, segment, level};
				const word = cardWord.word.toLowerCase();
				const constants = {
					word: word,
					asset_id: getContentInfo(data, false).assetId
				};

				const WORD_CTXT_URL = `${MEDIA_SERVER_URL}/assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`;
				const WORD_DEF_URL = `${MEDIA_SERVER_URL}/assets/dictio/def/r180u_def_def-{word}.${EXT}`;
				const word_text_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, constants);
				const word_def_url = bulkReplace(WORD_DEF_URL, constants);
				const word_ctx_url = bulkReplace(WORD_CTXT_URL, constants);
				// the pronounced word
				AudioPlayer2.load({name: word, src: word_text_url});

				// the definition
				AudioPlayer2.load({name: `def-${word}`, src: word_def_url});

				// the context
				AudioPlayer2.load({name: `ctx-${word}`, src: word_ctx_url});
				setWordCardData(wordCardData);
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				setWordCardData(false);
				setCardWord(false);
			}
		};
		if (cardWord && cardWord.word) {
			getWordCardData();
		}
	}, [cardWord]);

	useEffect(() => {
		setReadAloudComplete(readAloudCompleted);
	}, [readAloudCompleted]);
	useEffect(() => {
		setUnderstandingRating(actUserRating);
	}, [actUserRating]);
	useEffect(() => {
		setContextClueItems(actContextClueItems);
	}, [actContextClueItems]);
	useEffect(() => {
		setChallengeAccepted(challengeAccepted);
	}, [challengeAccepted]);
	useEffect(() => {
		setItems(actItems);
	}, [actItems]);

	useEffect(() => {
		if (loaded && autoPlayEnabled) {
			readAloudInstructions();
		}
	}, [loaded]);
	useEffect(() => {
		if (autoPlayEnabled) {
			if (activeTab.id === '1') {
				readAloudInstructions();
			}
			if (activeTab.id === '2') {
				recordInstructions();
			}
		}
	}, [activeTab]);
	useEffect(() => {
		if (autoPlayEnabled && showRecordingSubmitModal) {
			AudioPlayer2.play('submitrecording');
		}
	}, [showRecordingSubmitModal]);

	useEffect(() => {
		if (mode === MODES.COMPREHENSION && !isEmpty(passageQuestions)) {
			if (passageQuestions.questions[passageQuestionsIndex].type === QuestionType.HIGHLIGHT) {
				const questionData = passageQuestions.questions[passageQuestionsIndex];
				setState(() => {
					setHighlightQuestionActive(true);
					setHighlightQuestionOptions([...questionData.answers, ...questionData.distractors]);
				});
			} else {
				if (
					passageQuestions.questions[passageQuestionsIndex].children &&
					passageQuestions.questions[passageQuestionsIndex].children[0].type === QuestionType.HIGHLIGHT
				) {
					const questionData = passageQuestions.questions[passageQuestionsIndex].children[0];
					setState(() => {
						setHighlightQuestionActive(true);
						setHighlightQuestionOptions([...questionData.answers, ...questionData.distractors]);
					});
				} else {
					if (
						passageQuestions.questions[passageQuestionsIndex].children &&
						passageQuestions.questions[passageQuestionsIndex].children[1].type === QuestionType.HIGHLIGHT
					) {
						const questionData = passageQuestions.questions[passageQuestionsIndex].children[1];
						setState(() => {
							setHighlightQuestionActive(true);
							setHighlightQuestionOptions([...questionData.answers, ...questionData.distractors]);
						});
					} else {
						setState(() => {
							setHighlightQuestionActive(false);
							setHighlightQuestionOptions([]);
							setSelectedHighlightedOptions([]);
						});
					}
				}
			}
		}
	}, [mode, passageQuestions, passageQuestionsIndex]);

	const getTabs = () => {
		if (mustReadAloud && !readAloudComplete) {
			return tabs.noRecord;
		}
		return tabs.allTabs;
	};

	const silenceTimeoutHandler = () => {
		audioRecorder.stopRecord(false);
		setStartRecordCountDown(false);
		dispatch(uiSlice.actions.cancelRecording());
	};
	const onNextQuestion = () => {
		if (passageQuestionsIndex !== passageQuestions.questions.length - 1) {
			setPassageQuestionsIndex(prevState => prevState + 1);
		} else {
			setMode(MODES.DONE);
		}
	};

	const showOrHideFeedback = (showFeedBack, correctiveFeedbackList) => {
		setState(() => {
			setShowCorrectiveFeedback(showFeedBack);
			setCorrectiveFeedbackList(correctiveFeedbackList);
		});
	};
	const renderRateRecording = () => {
		return (
			<ReadingQuestionPanel
				onAnswerChange={() => {}} //Fun note, these answers are discarded!
				onSubmit={() => {
					AudioPlayer2.stopAll();
					if (autoPlayEnabled) {
						AudioPlayer2.play('selfcheckratingresults');
					}
					setMode(MODES.ATTENTION);
				}}
				questionType={QuestionType.RATE_RECORDING}
				title={'You recorded your reading. Now rate your recording.'}
				titleSound={{
					name: 'Rate_Recording_Title',
					src: ' ' // TODO : No audio exists in legacy as well as media repo but design has audio icon
				}}
				buttonText={'Go On'}
				questions={fluencyQuestions}
				buttonIcon="arrow-right"
			/>
		);
	};

	const renderFocusAttention = () => {
		return (
			<ReadingQuestionPanel
				title={'Where will you focus your effort next time you practice reading fluently.'}
				titleSound={{
					name: 'Focus_Attention_Title',
					src: MEDIA.Focus_Attention_Audio
				}}
				questionType={QuestionType.FOCUS_ATTENTION}
				questions={fluencyQuestions}
				onSubmit={() => setShowRecordingSubmitModal(true)}
				onAnswerChange={() => {}}
				buttonText="Go On"
				buttonIcon="arrow-right"
			/>
		);
	};

	const renderRateYourUnderstanding = () => {
		return (
			<ReadingQuestionPanel
				title={'How well do you understand the main ideas and details in this text?'}
				titleSound={{
					name: 'Rate_Understanding_Title',
					src: bulkReplace(MEDIA.Rate_Understanding_Audio, {strategy: STRATEGIES[strategy]})
				}}
				questionType={QuestionType.RATE_RECORDING}
				questions={[
					{
						id: 'rate-understanding',
						stem: '',
						value: null,
						checked: false
					}
				]}
				onSubmit={() => {
					if (understandingRating > 1) {
						setMode(MODES.CHALLENGE);
					} else {
						setMode(MODES.COMPREHENSION);
					}
					setSendProgressFlag(true);
				}}
				onAnswerChange={newChoices => {
					setUnderstandingRating(newChoices[0].value);
				}}
				buttonText="Go On"
				buttonIcon="arrow-right"
			/>
		);
	};

	const renderChallengeScreen = () => {
		return (
			<ReadingQuestionPanel
				title={"It looks like you feel pretty confident about this text. That's great!"}
				titleSound={{
					name: 'challenge_title',
					src: MEDIA.Challenge_Title_Audio
				}}
				questionType={QuestionType.CHALLENGE_ACCEPTANCE}
				questions={[
					{
						id: 'challenge',
						stem: 'Would you like to start with a more challenging question?',
						value: null,
						sound: {
							name: 'challenge_text',
							src: MEDIA.Challenge_Question_Title_Audio
						}
					}
				]}
				onSubmit={() => {
					if (isChallengeAccepted) {
						if (autoPlayEnabled) {
							AudioPlayer2.play('challengeaccepted');
						}
						setMode(MODES.COMPREHENSION);
						if (mainQuestions.find(question => question.dok === currentDOK + 1)) {
							makeQuestionArray(currentDOK + 1, mainQuestions, ctxQuestions, items);
							setCurrentDOK(currentDOK + 1);
						}
					} else {
						if (autoPlayEnabled) {
							AudioPlayer2.play('challengedeclined');
						}
						setMode(MODES.COMPREHENSION);
					}
					setSendProgressFlag(true);
				}}
				onAnswerChange={challengeOption => {
					setChallengeAccepted(challengeOption === 'YES');
				}}
				buttonText="Go On"
				buttonIcon="arrow-right"
			/>
		);
	};
	const handleHighlightOptionSelect = option => {
		const updatedOption = {...option, status: ''};
		const clonedSelectedHighlightedOptions = [...selectedHighlightedOptions];
		const isOptionSelected =
			clonedSelectedHighlightedOptions.filter(item => item.id === updatedOption.id).length > 0;
		if (!isOptionSelected) {
			const newHighlightQuestionOptions = highlightQuestionOptions.map(item => {
				if (item.id === updatedOption.id) {
					return {...item, applyBackground: true};
				} else {
					return item;
				}
			});
			clonedSelectedHighlightedOptions.push(updatedOption);
			setState(() => {
				setSelectedHighlightedOptions(clonedSelectedHighlightedOptions);
				setHighlightQuestionOptions(newHighlightQuestionOptions);
			});
		}
	};

	const handleDeleteIconClick = option => {
		const newSelectedHighlightedOptions = selectedHighlightedOptions.filter(item => item.id !== option.id);
		const newHighlightQuestionOptions = highlightQuestionOptions.map(item => {
			if (item.id === option.id) {
				return {...item, applyBackground: false};
			} else {
				return item;
			}
		});
		setState(() => {
			setHighlightQuestionOptions(newHighlightQuestionOptions);
			setSelectedHighlightedOptions(newSelectedHighlightedOptions);
		});
	};

	const recordAnswers = (attempts, index, isCorrect = true, nextQuestion) => {
		let question = passageQuestions.questions[index];
		if (question.type === QuestionType.TWO_PART) {
			let dok = question.dok;
			question = nextQuestion ? question.children[1] : question.children[0];
			question.dok = dok;
		}
		let newItems = items.map(item => {
			if (item.id === question.id) {
				return {id: item.id, attempts, isCorrect};
			} else {
				return item;
			}
		});
		if (!newItems.find(item => item.id === question.id)) {
			newItems.push({
				id: question.id,
				attempts,
				isCorrect: isCorrect
			});
		}

		if (index === 3) {
			//it's a context question
			setContextClueItems(oldItems => {
				if (oldItems.findIndex(item => item === question.id) === -1) {
					return oldItems.concat([question.id]);
				}
				return oldItems;
			});
		} else {
			if (isCorrect && attempts === 1) {
				//Increase level if right on first try
				if (mainQuestions.find(question => question.dok === currentDOK + 1)) {
					makeQuestionArray(currentDOK + 1, mainQuestions, ctxQuestions, newItems);
					setCurrentDOK(currentDOK + 1);
				}
			} else {
				//If it's not right on the first try, go down a level if possible
				if (mainQuestions.find(question => question.dok === currentDOK - 1)) {
					makeQuestionArray(currentDOK - 1, mainQuestions, ctxQuestions, newItems);
					setCurrentDOK(currentDOK - 1);
				}
			}
		}
		setState(() => {
			setItems(oldItems => {
				return oldItems.concat(
					newItems.filter(newItem => {
						return oldItems.findIndex(i => i.id === newItem.id) === -1; //Add on any items which are in new items but not in items already
					})
				);
			});
			setSendProgressFlag(true);
		});
	};

	// Render passage questions based on type

	const questionGoOnHandler = (attempts, isCorrect = true, nextQuestion = true) => {
		recordAnswers(attempts, passageQuestionsIndex, isCorrect, nextQuestion);
		setState(() => {
			setHighlightQuestionActive(false);
			setHighlightQuestionOptions([]);
			setSelectedHighlightedOptions([]);
		});

		if (attempts && nextQuestion) {
			onNextQuestion();
		}
	};

	const renderPassageQuestion = () => {
		if (!isEmpty(passageQuestions)) {
			switch (passageQuestions.questions[passageQuestionsIndex].type) {
				case QuestionType.TWO_PART: {
					return (
						<TwoPartQuestion
							data={passageQuestions.questions[passageQuestionsIndex]}
							stage={stage}
							segment={segment}
							level={level}
							onGoOnClicked={questionGoOnHandler}
							onDeleteIconClicked={option => handleDeleteIconClick(option)}
							selectedHighlightedOptions={selectedHighlightedOptions}
							onAnswerCheck={canClick => setCanClickPassageWord(canClick)}
							onShowFeedback={showOrHideFeedback}
						/>
					);
				}
				case QuestionType.HIGHLIGHT: {
					return (
						<HighlightingPassage
							data={passageQuestions.questions[passageQuestionsIndex]}
							stage={stage}
							segment={segment}
							level={level}
							onGoOnClicked={questionGoOnHandler}
							onDeleteIconClicked={option => handleDeleteIconClick(option)}
							selectedHighlightedOptions={selectedHighlightedOptions}
							onAnswerCheck={canClick => setCanClickPassageWord(canClick)}
						/>
					);
				}
				case QuestionType.MATCHING: {
					return (
						<ConnectMatchingQuestion
							data={passageQuestions.questions[passageQuestionsIndex]}
							stage={stage}
							segment={segment}
							level={level}
							onGoOnClicked={questionGoOnHandler}
							onShowFeedback={showOrHideFeedback}
						/>
					);
				}
				case QuestionType.SINGLE:
				case QuestionType.MULTIPLE: {
					return (
						<MultiPart
							data={passageQuestions.questions[passageQuestionsIndex]}
							stage={stage}
							segment={segment}
							level={level}
							onGoOnClicked={questionGoOnHandler}
							onShowFeedback={showOrHideFeedback}
						/>
					);
				}
				case QuestionType.ORDERING: {
					return (
						<OrderingSequence
							data={passageQuestions.questions[passageQuestionsIndex]}
							stage={stage}
							segment={segment}
							level={level}
							onGoOnClicked={questionGoOnHandler}
							onShowFeedback={showOrHideFeedback}
						/>
					);
				}
				case QuestionType.SORTING: {
					return (
						<Sorting
							data={passageQuestions.questions[passageQuestionsIndex]}
							stage={stage}
							segment={segment}
							level={level}
							onGoOnClicked={questionGoOnHandler}
							onShowFeedback={showOrHideFeedback}
						/>
					);
				}
				case QuestionType.CHART: {
					return (
						<ChartQuestion
							data={passageQuestions.questions[passageQuestionsIndex]}
							stage={stage}
							segment={segment}
							level={level}
							onGoOnClicked={questionGoOnHandler}
							onShowFeedback={showOrHideFeedback}
						/>
					);
				}

				default: {
					//onNextQuestion();  This breaks everything!
					break;
				}
			}
		} else {
			return null;
		}
	};
	const handleVideoPlay = video => {
		AudioPlayer2.stopAll();
		setVideo(video);
	};

	const doneReviewing = async () => {
		try {
			await transitionFromResultsPageToNextActivity(history);
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
	};
	const handleModeChange = type => {
		setState(() => {
			setSelectedTabID(`tab${type}`);
			setReadMode(type);
		});
	};
	const uploadRecording = () => {
		return audioRecorder.uploadRecording(studentActivityId);
	};
	const getRefactoredItems = allItems => {
		const twoPartIds = twoPartQuestionIds.map(que => que[1]);
		const newItems = allItems.filter(item => {
			if (twoPartIds.indexOf(item.id) !== -1) {
				return false;
			}
			return true;
		});
		return newItems;
	};

	const readAloudInstructions = async () => {
		AudioPlayer2.stopAll();
		if (mustReadAloud) {
			if (!readAloudComplete) {
				await AudioPlayer2.playSync('mandread');
				AudioPlayer2.play('passage_purpose_{strategy}');
			} else {
				AudioPlayer2.play('mandreadhelppost');
			}
		} else {
			if (!readAloudComplete) {
				await AudioPlayer2.playSync('readaloud');
				AudioPlayer2.play('passage_purpose_{strategy}');
			} else {
				AudioPlayer2.play('postreadaloud');
			}
		}
	};

	const recordInstructions = () => {
		AudioPlayer2.stopAll();
		if (!canPlay) {
			if (mustRecord) {
				AudioPlayer2.play('mandrecord');
			} else {
				AudioPlayer2.play('record');
			}
		} else {
			if (mustRecord) {
				AudioPlayer2.play('mandrecordhelppost');
			} else {
				AudioPlayer2.play('postrecord');
			}
		}
	};
	return (
		<div className={`${classes.wrapper}`}>
			<a
				className={`${classes.hiddenSkipLink}`}
				href={document.location.href.split('#')[0] + '#instructionsButton'}
			>
				Skip to instructions
			</a>
			<Navbar helpSoundUrl={whichHelp()} short={true} />
			<ActivitySuspense requiredRenderData={[{loaded}]} showSpinner={!loaded} title={'Anchor Text'}>
				<Modal
					className={`${classes.wordCardModal} r180-modal`}
					open={cardWord && cardWord.word && wordCardData && true}
					onClose={() => {
						setCardWord(false);
					}}
				>
					<div className={`${classes.wordCardModalBody} modal-window`} data-testid={'wordCardModal'}>
						<WordCard
							wordCardData={wordCardData}
							card={{
								progress: 0,
								maxProgress: 0,
								word_card: {
									word_card_activities: [],
									word_card_sentence: wordCardData.sentence,
									word_card_definition: wordCardData.definition,
									word_text: wordCardData.text
								}
							}}
							taskData={{video_hint: ''}}
							hideVideoTool={true}
							handleClose={() => {
								setCardWord(false);
							}}
							showExitButton={true}
						/>
					</div>
				</Modal>
				<Modal className={`${classes.wordCardModal} r180-modal`} open={activeTab.id === '3'}>
					<div className={`${classes.videoModalBody} modal-window`} data-testid={'videoModal'}>
						<AnchorVideo
							id="readingPassageVideo"
							containerStyle={{width: '865px'}}
							hideVideoTimeDuration
							poster={posterSrc}
							autoPlay={false}
							aria-label={'Anchor video'}
							onVideoPlay={handleVideoPlay}
							ended={true}
							hideCloseButton={false}
							handleCloseButton={() => {
								setActiveTab(previousTab);
							}}
						>
							<source src={videoSrc} type={'video/mp4'} />
							<track
								src={videoCaptionSrc}
								kind={'subtitles'}
								srcLang={'en'}
								label={'English subtitles'}
							/>
						</AnchorVideo>
						<ActivitySupportVideoSummaryButton
							soundUrl={bulkReplace(MEDIA.ELSummary, mediaConstants)}
							video={video}
						/>
					</div>
				</Modal>
				<Modal
					open={showRecordingTips}
					onClose={() => {
						setShowRecordingTips(false);
						setStartRecordCountDown(true);
					}}
					className={`${classes.wordCardModal} r180-modal`}
				>
					<div
						className={`${classes.recordingTipsModalBody} modal-window`}
						data-testid={'recordingTipsModal'}
					>
						<div className={classes.tipHeader}>
							<SoundButton
								border={false}
								sound={{name: 'recordingTips', src: 'placeholder'}}
								size={32}
								className={'transparent'}
							/>
							<span className={classes.contentRight}>As you record your reading remember to:</span>
						</div>
						<div className={classes.tipsEntry}>
							<div>
								<span className={classes.tipCheck}>
									<i className="r180 check" />
								</span>
							</div>
							Pronounce the words carefully.
						</div>
						<div className={classes.tipsEntry}>
							<div>
								<span className={classes.tipCheck}>
									<i className="r180 check" />
								</span>
							</div>
							Use punctuation like periods, commas, and exclamation points to help you read with
							expression.
						</div>
						<div className={classes.tipsEntry}>
							<div>
								<span className={classes.tipCheck}>
									<i className="r180 check" />
								</span>
							</div>
							Try to make your reading sound clear and natural like the modeled reading.
						</div>
						<div className={classes.tipsButton}>
							<ActivityButton
								onClick={() => {
									setShowRecordingTips(false);
									setStartRecordCountDown(true);
								}}
								text={'Go On'}
								icon={'arrow-right'}
							/>
						</div>
					</div>
				</Modal>
				<ConfirmationModal
					className={classes.recordingSaveConfirmModal}
					open={showRecordingSaveModal}
					onPrimaryButtonClicked={() => {
						AudioPlayer2.stopAll();
						if (autoPlayEnabled) {
							AudioPlayer2.play('selfcheckratingintro');
						}
						setMode(MODES.RATE);
						setShowRecordingSaveModal(false);
					}}
					onSecondaryButtonClicked={() => {
						setShowRecordingSaveModal(false);
					}}
					onCloseClicked={() => {
						setShowRecordingSaveModal(false);
					}}
					title={'Are you done listening to your recording and ready to start reviewing it?'}
					primaryButtonText={'Yes'}
					primaryButtonIcon={'check'}
					secondaryButtonIcon={'close'}
					secondaryButtonText={'No'}
					showCloseIcon={false}
				/>
				<ConfirmationModal
					className={classes.recordingKillConfirmModal}
					open={showRecordingKillModal}
					onSecondaryButtonClicked={() => {
						setState(() => {
							setCanPlay(false);
							setMode(MODES.RECORD);
							setShowRecordingKillModal(false);
							setCanSave(false);
						});
					}}
					onPrimaryButtonClicked={() => {
						setShowRecordingKillModal(false);
					}}
					onCloseClicked={() => {
						setShowRecordingKillModal(false);
					}}
					title={'Are you sure you want to start over?'}
					subTitle={'Your recording will be deleted!'}
					showWarningIcon={true}
					primaryButtonText={'No'}
					primaryButtonIcon={'close'}
					secondaryButtonIcon={'check'}
					secondaryButtonText={'Yes'}
					showCloseIcon={false}
				/>
				<ConfirmationModal
					open={showRecordingSubmitModal}
					className={classes.recordingSubmitConfirmModal}
					onPrimaryButtonClicked={async () => {
						if (await uploadRecording()) {
							setState(async () => {
								setMode(MODES.UNDERSTANDING);
								setShowRecordingSubmitModal(false);
								AudioPlayer2.stopAll();
								if (autoPlayEnabled) {
									await AudioPlayer2.playSync('passagecompunlocked');
								}
							});
						} else {
							dispatch(uiSlice.actions.setErrorMessage('Unable to upload recording'));
						}
					}}
					onSecondaryButtonClicked={() => {
						setShowRecordingSubmitModal(false);
						if (!modeledRead) {
							setMode(MODES.UNDERSTANDING);
							return;
						}
						setMode(MODES.RECORD);
					}}
					onCloseClicked={() => {
						setShowRecordingSubmitModal(false);
						if (!modeledRead) {
							setMode(MODES.UNDERSTANDING);
							return;
						}
						setMode(MODES.RECORD);
					}}
					title={'Do you want to submit your recording?'}
					titleSound={{
						name: 'Submit_Recording_Title',
						src: MEDIA.Submit_Recording_Title_Audio
					}}
					primaryButtonText={'Yes'}
					secondaryButtonText={'No'}
					primaryButtonIcon={'check'}
					secondaryButtonIcon={'x'}
					showCloseIcon={false}
				/>
				<Modal
					open={showMicBypassModal}
					className={`${classes.wordCardModal} r180-modal`}
					onClose={() => {
						setShowMicBypassModal(false);
					}}
				>
					<div className={`${classes.micBypassModalBody} modal-window`} data-testid={'micBypassModal'}>
						<button
							data-testid={'bypassClose'}
							className={classes.modalX}
							onClick={() => {
								setShowMicBypassModal(false);
							}}
						>
							<i className="r180 close" />
						</button>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in metus et tellus rutrum
						dignissim. Etiam porta gravida neque. Etiam at rutrum nunc, id scelerisque nisi.
					</div>
				</Modal>

				{mode === MODES.RECORD && (
					<ActivityButton
						onClick={handleGoOn}
						text={'Go On'}
						icon={'arrow-right'}
						disabled={isGoOnDisabled()}
						className={`${classes.goOn}`}
					/>
				)}
				<div className={`${classes.clearBoth}`} />
				<div className={`${classes.contentWrapper}`}>
					{mode === MODES.DONE ? (
						<ReadingPassageReview answers={getRefactoredItems(items)} onClick={doneReviewing} />
					) : (
						<>
							<div
								className={`${classes.leftColumn} ${mode !== MODES.RECORD &&
									mode !== MODES.DONE &&
									classes.leftNarrow}`}
							>
								<div className={`${classes.tabsAndControls}`}>
									<TabBar
										id={'tabs'}
										tabs={getTabs()}
										onClick={tab => {
											if (tab.id === '3') {
												setPreviousTab(activeTab);
											}
											setActiveTab(tab);
											if (tab.id === '2') {
												if (enableMicBypass) {
													setShowMicBypassModal(true);
													setMode(MODES.RATE);
												}
											}
											setState(() => {
												setSelectedTabIndex(tab.id);
												setReadWords(false);
												setReadPhrases(false);
											});
										}}
										selectedTab={activeTab.id}
									/>
									{activeTab.id === '1' && (
										<ReadAloudControlBar
											role={'tabpanel'}
											onPlay={() => {
												if (readMode === 'word') {
													setReadWords(true);
												} else {
													setReadPhrases(true);
												}
												setReadAloudPaused(false);
											}}
											onPause={() => {
												setState(() => {
													setReadWords(false);
													setReadPhrases(false);
													setReadAloudPaused(true);
												});
											}}
											onReadTypeChange={type => {
												handleModeChange(type);
												setReadMode(type);

												if (type === 'word' && readPhrases) {
													setReadWords(true);
													setReadPhrases(false);
												}
												if (type === 'phrase' && readWords) {
													setReadWords(false);
													setReadPhrases(true);
												}
												setReadType(type);
											}}
											onInstructions={readAloudInstructions}
											onSpeedChange={speed => {
												setWordDelay(WORD_DELAYS[speed]);
											}}
											selectedTabID={selectedTabID}
											setReadMode={setReadMode}
											readAloudComplete={readAloudComplete}
											setReadAloudComplete={setReadAloudComplete}
										/>
									)}
									{activeTab.id === '2' && (
										<RecordControlBar
											role={'tabpanel'}
											onInstructions={recordInstructions}
											onStartOver={() => {
												clearTimeout(audioRecorder.silenceTimeout);
												audioRecorder.stopPlay();
												setShowRecordingKillModal(true);
												setPlayBackClicked(false);
												audioRecorder.silenceTimeout = null;
											}}
											isSaveDisabled={!canSave || mode != MODES.RECORD}
											isStartOverDisabled={!canPlay}
											onSave={() => {
												setShowRecordingSaveModal(true);
											}}
											onRecord={() => {
												clearTimeout(audioRecorder.silenceTimeout);
												saveDelayTimeout.current = setTimeout(() => {
													setCanSave(true);
												}, MIN_REC_LENGTH_SEC * 1000);
												setCanSave(false);
												AudioPlayer2.stopAll();
												audioRecorder.record();
												setStartRecordCountDown(false);
												audioRecorder.silenceTimeout = null;
											}}
											onRecordButtonClick={() => {
												if (!tipsHaveBeenShown) {
													setState(() => {
														setShowRecordingTips(true);
														setTipsHaveBeenShown(true);
													});
												} else {
													setStartRecordCountDown(true);
												}
											}}
											startRecordCountDown={startRecordCountDown}
											onPause={() => {
												audioRecorder.stopRecord(false);
												audioRecorder.stopPlay();
												clearTimeout(audioRecorder.silenceTimeout);
												clearTimeout(saveDelayTimeout.current);
												setStartRecordCountDown(false);
											}}
											onDone={() => {
												audioRecorder.stopRecord(false);
												audioRecorder.stopPlay();
												clearTimeout(audioRecorder.silenceTimeout);
												clearTimeout(saveDelayTimeout.current);
												setStartRecordCountDown(false);
											}}
											onPlay={() => {
												AudioPlayer2.stopAll();
												audioRecorder.play();
												setPlayBackClicked(true);
											}}
											canPlayRecording={canPlay}
											playbackProgress={playbackProgress}
											onRecordingSkipBack={() => {
												audioRecorder.seek(SEEKSTART);
											}}
											onRecordingRewind={() => {
												audioRecorder.seek(SEEKBACK);
											}}
											onRecordingFastForward={() => {
												audioRecorder.seek(SEEKFORWARD);
											}}
											onRecordingSkipForward={() => {
												audioRecorder.seek(SEEKEND);
											}}
											onSeekPercent={seekPercent => {
												audioRecorder.seekPercent(seekPercent);
											}}
											pausePlayback={() => {
												AudioPlayer2.stopAll();
												audioRecorder.pause();
												setPlayBackClicked(false);
											}}
											isPlayBackClicked={isPlayBackClicked}
										/>
									)}
								</div>
								<div className={`${classes.passage}`}>
									<ReadingPassage
										passageString={passageString}
										title={segmentData.name}
										setCardWord={setCardWord}
										readWords={readWords}
										readPhrases={readPhrases}
										wordDelay={wordDelay}
										stage={stage}
										segment={segment}
										level={level}
										setReadAloudComplete={isComplete => {
											setReadAloudComplete(isComplete);
											if (autoPlayEnabled && isComplete) {
												if (mustReadAloud) {
													AudioPlayer2.play('mandreadhelppost');
												} else {
													AudioPlayer2.play('postreadaloud');
												}
											}
											setSendProgressFlag(true);
										}}
										correctiveFeedbackList={correctiveFeedbackList}
										showCorrectiveFeedback={showCorrectiveFeedback}
										isHighlightQuestionActive={isHighlightQuestionActive}
										highlightQuestionOptions={highlightQuestionOptions}
										onOptionSelect={option => handleHighlightOptionSelect(option)}
										canClickPassageWord={canClickPassageWord}
										selectedTabID={selectedTabID}
										selectedTabIndex={selectedTabIndex}
										isCompact={
											mode === MODES.RATE ||
											mode === MODES.ATTENTION ||
											mode === MODES.UNDERSTANDING ||
											mode === MODES.CHALLENGE ||
											mode === MODES.COMPREHENSION
										}
										readAloudComplete={readAloudComplete}
										readAloudPaused={readAloudPaused}
										readType={readType}
									/>
								</div>
							</div>
							<div className={`${classes.rightColumn}`} data-testid={'rightColumn'}>
								{mode === MODES.RATE && renderRateRecording()}
								{mode === MODES.ATTENTION && renderFocusAttention()}
								{mode === MODES.UNDERSTANDING && renderRateYourUnderstanding()}
								{mode === MODES.CHALLENGE && renderChallengeScreen()}
								{mode === MODES.COMPREHENSION && (
									<div
										key={`passageQuestion${passageQuestionsIndex}`}
										data-testid={'passageQuestions'}
									>
										<QuestionBreadcrumb
											totalQuestions={
												passageQuestions &&
												passageQuestions.questions &&
												passageQuestions.questions.length
											}
											selected={passageQuestionsIndex + 1}
											className={`${classes.breadcrumb}`}
											data-testid={'breadcrumb'}
										/>
										{renderPassageQuestion(passageQuestionsIndex)}
									</div>
								)}
							</div>
						</>
					)}
				</div>
			</ActivitySuspense>
		</div>
	);
};

export default ReadingPassageActivity;
