import React from 'react';
import Sortable from 'sortablejs'
import ReactUsePlugin from './PluginReactUse';
import DragZone from './DragZone';
import DropZone from './DropZone';

// Mount the plugin
Sortable.mount(ReactUsePlugin)


export {Sortable, ReactUsePlugin, DragZone, DropZone};