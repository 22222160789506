import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import WordFamilyLanding from './WordFamilyLanding';
import WordFamilyResults from './WordFamilyResults';
export const useStyles = makeStyles(theme => ({
	rootWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	}
}));

const WordFamily = props => {
	const {
		questionsList,
		pageType,
		correctResult,
		answeredChoice,
		updateSubmitStatus,
		curentQuestionIndex,
		isResetQuestion,
		setResetQuestion,
		setCorrectResults,
		setAnsweredChoice,
		stageDetails,
		introVO,
		screenViewIndex,
		commonQuestionTitle,
		isAnimPlayingScreen,
		isVoiceOverComplete,
		setForwardDisabled,
		setScreenViewIndex,
		setIntroVO,
		setInstructionDisabled,
		isZoneMenuModalOpen,
		isExitModalOpen
	} = props;
	const classes = useStyles();
	return (
		<div className={classes.rootWrapper}>
			{pageType === 'landing' ? (
				<WordFamilyLanding
					isResetQuestion={isResetQuestion}
					setResetQuestion={setResetQuestion}
					questionsList={questionsList}
					currentIndex={curentQuestionIndex}
					updateSubmitStatus={updateSubmitStatus}
					setCorrectResults={setCorrectResults}
					setAnsweredChoice={setAnsweredChoice}
					correctResult={correctResult}
					answeredChoice={answeredChoice}
					stageDetails={stageDetails}
					instructionVO={introVO}
					screenViewIndex={screenViewIndex}
					commonQuestionTitle={commonQuestionTitle}
					isAnimPlayingScreen={isAnimPlayingScreen}
					isVoiceOverComplete={isVoiceOverComplete}
					setForwardDisabled={setForwardDisabled}
					setScreenViewIndex={setScreenViewIndex}
					setInstructionDisabled={setInstructionDisabled}
					isZoneMenuModalOpen={isZoneMenuModalOpen}
					isExitModalOpen={isExitModalOpen}
				/>
			) : (
				<WordFamilyResults
					questionsList={questionsList}
					correctResult={correctResult}
					answeredChoice={answeredChoice}
					setIntroVO={setIntroVO}
				/>
			)}
		</div>
	);
};
WordFamily.propTypes = {
	questionsList: PropTypes.object.isRequired,
	pageType: PropTypes.string.isRequired,
	correctResult: PropTypes.array.isRequired,
	answeredChoice: PropTypes.array.isRequired,
	isResetQuestion: PropTypes.bool,
	setResetQuestion: PropTypes.func,
	setCorrectResults: PropTypes.func,
	setAnsweredChoice: PropTypes.func,
	stageDetails: PropTypes.object,
	introVO: PropTypes.string.isRequired,
	screenViewIndex: PropTypes.number.isRequired,
	commonQuestionTitle: PropTypes.string.isRequired,
	isAnimPlayingScreen: PropTypes.bool.isRequired,
	isVoiceOverComplete: PropTypes.bool
};

WordFamily.defaultProps = {
	questionsList: {},
	pageType: 'landing',
	correctResult: [],
	answeredChoice: [],
	introVO: '',
	questionTitle: false,
	commonQuestionTitle: '',
	isAnimPlayingScreen: true,
	screenViewIndex: 0
};
export default React.memo(WordFamily);
