import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
	videoButton: {
		background: 'transparent',
		color: theme.colors.white,
		width: 'auto',
		minWidth: '64px',
		height: 'auto',
		border: 'none',
		borderRadius: '8px',
		outline: 'none',
		textAlign: 'center',
		cursor: 'pointer',
		padding: '8px',
		transition: 'transform 0.3s ease',
		'&.disabled, &:disabled': {
			background: 'transparent !important',
			color: theme.colors.paleGrey,
			cursor: 'not-allowed'
		},
		'& .icon-wrapper': {
			height: '36px',
			width: 'auto',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& .circle': {
				height: '36px',
				width: '36px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '18px',
				'& i': {
					fontSize: '24px'
				}
			}
		},
		'& .text': {
			fontSize: '18px',
			fontFamily: theme.fonts.button,
			fontWeight: '600',
			marginTop: '4px',
			textShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'
		},
		'&:hover': {
			transform: 'scale(1.2)',
			'& .circle': {
				background: 'rgba(0, 0, 0, 0.3)'
			}
		},
		'&:focus': {
			'& .circle': {
				background: 'rgba(0, 0, 0, 0.3)'
			},
			'& .text': {
				textDecoration: 'underline',
				textUnderlineOffset: '3px'
			}
		}
	}
}));


const ActivitySupportVideoSummaryButton = (props) => {
	const {
		soundUrl,
		videoButtonClass,
		id,
		icon,
		text,
		iconSize,
		disabled,
		video } = props;

	const videoSummary = "video_summary";
	const [playing, setPlaying] = useState(false);
	const classes = useStyles();
	const iconSizePx = iconSize + 'px';
	const disabledClass = disabled ? 'disabled' : '';
	const videoButtonClassName = videoButtonClass ? videoButtonClass : classes.videoButton;

	useEffect(() => {
		if (isEmpty(soundUrl) === false) {
			const audioPlayer = AudioPlayer2.load({
				name: videoSummary,
				src: soundUrl
			});

            audioPlayer.onStop = () => {
                setPlaying(false);
            };

			return () => {
                delete audioPlayer.onStop;
            };
		}
	}, [soundUrl]);

	const handleVideoSummaryClick = async (e) => {
		const { onVideoSummaryClick } = props;
		e.stopPropagation();
		//video.play();
		if (typeof onVideoSummaryClick !== 'undefined') {
			await onVideoSummaryClick(e);
		}

		if (playing === false) {
			AudioPlayer2.stopAll();
			video.pause();
			setPlaying(true);
			await AudioPlayer2.playSync(text);
			setPlaying(false);
		}
		else {
			AudioPlayer2.stopAll();
			setPlaying(false);
		}
	}

	return (

		<button
			type="button"
			id={id}
			aria-describedby={id}
			aria-label={text}
			className={`${videoButtonClassName} ${disabledClass}`}
			onClick={handleVideoSummaryClick}
			video={video}
		>
			<div className='icon-wrapper'>
				<div className="circle">
				<i
					className={`r180 ${icon} ${classes.icon}`}
					style={{
						fontSize: iconSizePx
					}}
				/>
				</div>
			</div>
			<div className={`text`}>{text}</div>
		</button>
	);
}

ActivitySupportVideoSummaryButton.defaultProps = {
	id: 'videoSummaryButton',
	text: 'Español',
	icon: 'espanol',
	iconSize: 24,
	disabled: false,
	soundUrl: ''
};

ActivitySupportVideoSummaryButton.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string,
	icon: PropTypes.string,
	iconSize: PropTypes.number,
	disabled: PropTypes.bool,
	onVideoSummaryClick: PropTypes.func,
	soundUrl: PropTypes.string.isRequired,
	videoButtonClass: PropTypes.object
};

export default React.memo(ActivitySupportVideoSummaryButton);
