import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ActivityInstructionButton, SelfCheckContainer } from '@reading/common';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { isEmpty } from 'lodash';
import { SelfCheck } from './SelfCheck';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import useIdleHelp from '../../utils/useIdleHelp';
import { uiSlice } from '../../store/slices/ui';
import ActivityFrame from '../../containers/App/ActivityFrame';
import { transitionFromResultsPageToNextActivity } from '../../utils/navigation';
import useBatchedSetState from '../../utils/useBatchedSetState';
import { sleep } from '../../utils/sleep';

export default function SelfCheckActivity(props) {

	const history = useHistory();
	const dispatch = useDispatch();
	const {setState} = useBatchedSetState();

	const { studylist, activityData } = useSelector(state => {
		return {
			studylist: state.activity.studylist,
			activityData: state.activity.activityData
		};
	});

	const [activityWords, setActivityWords] = useState([]);
	const [isBtnDisabled, setBtnDisabled] = useState(true);
	const [activityAudio, setActivityAudio] = useState({ url: SelfCheck.TEST_MIC_PAGE, name: 'test_mic' });
	const [isForwardVisible, setForwardVisible] = useState(false);
	const [pageDetails, setPageDetails] = useState(SelfCheck.PAGEDETAILS.testMicPage);
	const [wordIndex, setWordIndex] = useState(0);
	const [progressValue, setProgressValue] = useState(0);
	const [isWhiteBackground, setWhiteBackground] = useState(true);

	// check if the activity is already charged
	const { isActivityCharged, settings } = useSelector(state => {
		return {
			isActivityCharged: state.ui.chargedActivities['self-check'] === true,
			settings: state.session.session.settings
		}
	});

	const isActivity = activityData ? activityData.activityServerId === 'self_check' : false;

	useDeepCompareEffect(() => {
		const init = async() => {
			const wordList = SelfCheck.initData(studylist);
			setActivityWords(wordList);
			if (isActivityCharged === false) {
				await SelfCheck.init();
				dispatch(uiSlice.actions.setActivityCharged('self-check'));
			}
		}

		if (isEmpty(studylist) === false && isActivity) {
			init();
		}

	}, [studylist]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	const handleTestMicGoOn = async(audioRecorder) => {
		setState(()=>{
			setPageDetails(SelfCheck.PAGEDETAILS.beginActivityPage);
			setActivityAudio({ url: SelfCheck.PROGRESS_ACTIVITY_PAGE, name: 'progress_activity' });
		});
		if (settings.autoPlayEnabled === true) {
			AudioPlayer2.play(SelfCheck.INTRO_VO);
		}
	};

	const handleStartActivity = async() => {
		await sleep(500);
		AudioPlayer2.stopAll();
		setState(()=>{
			setForwardVisible(true);
			setPageDetails(SelfCheck.PAGEDETAILS.progressPage);
			setActivityAudio({ url: SelfCheck.BEGIN_ACTIVITY_PAGE, name: 'begin_activity' });
		});
	};

	const handleSelfFeedback = (wordId, feedback) => {
		activityWords.forEach(obj => {
			if (obj.wordId === wordId && typeof obj.feedback === 'undefined') {
				obj.feedback = feedback;
			}
		});

		if (feedback === 'correct') {
			setBtnDisabled(false);
		} else {
			setProgressValue(prevValue => prevValue + 1);
		}
	};

	const handleForward = async() => {
		AudioPlayer2.stopAll();
		// if they're looking at the result page, that means they're done
		if (pageDetails === SelfCheck.PAGEDETAILS.resultPage) {
			// transition from the results page to the new activity
			try {
				await transitionFromResultsPageToNextActivity(history);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		}
		// they're not done yet, keep track of their progress
		else {
			// they finished the last screen before the summary
			if (wordIndex === activityWords.length - 1) {
				setState(() => {
					setPageDetails(SelfCheck.PAGEDETAILS.resultPage);
					setWhiteBackground(false);
				});
				// complete the activity, but don't transition
				SelfCheck.completeActivityNoTransition(activityData);
			}
			// they still have more screens to do
			else {
				setState(() => {
					setWordIndex(prevIndex => prevIndex + 1);
					setProgressValue(prevValue => prevValue + 1);
					setBtnDisabled(true);
				});
				SelfCheck.sendProgressToServer(activityData);
			}
		}
	};

	useIdleHelp(SelfCheck.TRIAL_HELP);

	return (
		<>
			<Navbar helpSoundUrl={SelfCheck.TRIAL_HELP} />
			<ActivitySuspense showSpinner={isActivityCharged === false} requiredRenderData={activityWords} title="Self Check">
				<ActivityFrame isWhiteBackground={isWhiteBackground}>
					<ActivityInstructionButton audioSrc={SelfCheck.INTRO_VO} white={isWhiteBackground === false} />

					<SelfCheckContainer
						id="selfCheck"
						wordList={activityWords}
						activityWord={activityWords[wordIndex]}
						pageDetails={pageDetails}
						progressValue={progressValue}
						wordIndex={wordIndex}
						activityAudio={activityAudio}
						feedbackInaudibleAudio={{ src: SelfCheck.FEEDBACK_INAUDIBLE, name: 'noVoice' }}
						activityPauseVO={{ src: SelfCheck.ACTIVITY_PAUSE, name: 'activityPause' }}
						activityContinueVO={{ src: SelfCheck.ACTIVITY_CONTINUE, name: 'activityContinue' }}
						activityNextVO={{ src: SelfCheck.ACTIVITY_NEXT, name: 'activityNext' }}
						activityFeedbackVO={{ src: SelfCheck.ACTIVITY_FEEDBACK, name: 'activityFeedback' }}
						onTestMicGoOn={handleTestMicGoOn}
						onStartActivity={handleStartActivity}
						onSelfFeedback={handleSelfFeedback}
					/>
				</ActivityFrame>

				<Footer>
					<FooterForwardBack
						onForward={handleForward}
						isBackVisible={false}
						isForwardVisible={isForwardVisible}
						isForwardDisabled={isBtnDisabled}
						status={FooterForwardBack.VALID}
					/>
				</Footer>
			</ActivitySuspense>
		</>
	);
}
