
class TestPlugin {
    constructor(sortable) {
        this.initDropOverEvents(sortable);
    }

    currentIndex = null
    dropParent = null;

    initDropOverEvents(sortable) {
        const containerClass = sortable.options.dropContainer;

        const addClass = (e, elm) => {
            e.preventDefault()
            elm.classList.add('drag-hover')
        }

        const removeClass = (_e, elm) => {
            elm.classList.remove('drag-hover')
        }

        if (containerClass) {
            const dropParent = sortable.el.closest(containerClass);
            dropParent.addEventListener('dragover', e => addClass(e, dropParent))
            dropParent.addEventListener('dragleave', e => removeClass(e, dropParent))
            dropParent.addEventListener('dragend', e => removeClass(e, dropParent))
        }
    }

    getTilesArray(sortable) {
        if (sortable.options.tilesParent) {
            const fromParent = sortable.el.closest(sortable.options.tilesParent);
            const fromTiles = Array.from(fromParent.querySelectorAll('[data-dnd-id]'));

            return fromTiles.map(i => i.dataset.dndId)
        }
        return sortable.toArray()
    }

    drop(args = {}) {
        const { parentEl, rootEl, dragEl, activeSortable, putSortable } = args

        if (activeSortable && putSortable && parentEl && rootEl) {

            this.currentIndex = {
                from: { items: this.getTilesArray(activeSortable), id: activeSortable.dndType },
                to: { items: this.getTilesArray(putSortable), id: putSortable.dndType }
            }

            if (this.options && this.options.onStateChanges)
                this.options.onStateChanges(this.currentIndex, args)
        }
    }

    defaults = {
        onStateChanges: null,
        tilesParent: false,
        dropContainer: '.drop-content'
    }
}
const Plugin = Object.assign(TestPlugin, { pluginName: 'useReact' })

export default Plugin;