import React from 'react';
import PropTypes from 'prop-types';
import { VideoButton } from '@reading/common';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';


const ActivitySupportVideoButton = (props) => {
	const {
		activity_id,
		activity_icon,
		activity_text,
		activity_iconSize,
		activity_videoVol,
		activity_videoSrcType,
		activity_videoTracks,
		activity_videoPlayOnLoad,
		activity_videoPoster,
		activity_hideFwdButton,
		activity_hideRewindButton,
		activity_hideCaptionButton,
		activity_hideReplayButton,
		activity_hideCloseButton,
		activity_videoContainerStyleClass,
		onActivitySupportVideoBtnClick,
		onActivitySupportVideoEnd,
		onActivitySupportVideoClose,
		video_hint,
		disabled
	} = props;

	const handleActivitySupportVideoBtnClick = (e) => {
		AudioPlayer2.stopAll();
		if (typeof onActivitySupportVideoBtnClick === 'function') {
			onActivitySupportVideoBtnClick(e);
		}
	}
	const handleActivitySupportVideoEnd = (e) => {
		if (typeof onActivitySupportVideoEnd === 'function') {
			onActivitySupportVideoEnd(e);
		}
	}

	const handleActivitySupportVideoClose = (e) => {
		if (typeof onActivitySupportVideoClose === 'function') {
			onActivitySupportVideoClose(e);
		}
	}


	return (
		<VideoButton
			videoButtonId={activity_id}
			icon={activity_icon}
			iconSize={activity_iconSize}
			text={activity_text}
			videoVol={activity_videoVol}
			videoSrcURL={video_hint.src_url}
			videoSrcType={activity_videoSrcType}
			videoTracks={activity_videoTracks}
			videoPlayOnLoad={activity_videoPlayOnLoad}
			videoPoster={activity_videoPoster}
			hideFwdBtn={activity_hideFwdButton}
			hideRewindBtn={activity_hideRewindButton}
			hideCaptionBtn={activity_hideCaptionButton}
			hideReplayBtn={activity_hideReplayButton}
			hideCloseBtn={activity_hideCloseButton}
			videoContainerStyle={activity_videoContainerStyleClass}
			onVideoBtnClick={handleActivitySupportVideoBtnClick}
			onVideoEnd={handleActivitySupportVideoEnd}
			onVideoClose={handleActivitySupportVideoClose}
			start_time={video_hint.start_time / 1000}
			end_time={video_hint.end_time / 1000}
			disabled={disabled}
		/>
	);
}

ActivitySupportVideoButton.defaultProps = {
	activity_id: 'videoHintContainer',
	activity_icon: 'anchor-video',
	activity_iconSize: 24,
	activity_text: 'Video',
	activity_videoVol: 0.7,
	activity_videoSrcType: 'video/mp4',
	activity_videoPlayOnLoad: true,
	activity_hideFwdButton: true,
	activity_hideRewindButton: true,
	activity_hideCaptionButton: true,
	activity_hideReplayButton: true,
	activity_hideCloseButton: false,
	video_hint: {},
	onActivitySupportVideoBtnClick: () => { },
	onActivitySupportVideoEnd: () => { },
	onActivitySupportVideoClose: () => { },
	disabled: false
};
ActivitySupportVideoButton.propTypes = {
	activity_id: PropTypes.string,
	activity_icon: PropTypes.string,
	activity_iconSize: PropTypes.number,
	activity_text: PropTypes.string,
	activity_videoVol: PropTypes.number,
	activity_videoSrcType: PropTypes.string,
	activity_videoTracks: PropTypes.arrayOf(PropTypes.object),
	activity_videoPlayOnLoad: PropTypes.bool,
	activity_hideFwdButton: PropTypes.bool,
	activity_hideRewindButton: PropTypes.bool,
	activity_hideCaptionButton: PropTypes.bool,
	activity_hideReplayButton: PropTypes.bool,
	activity_hideCloseButton: PropTypes.bool,
	video_hint: PropTypes.object.isRequired,
	activity_videoContainerStyleClass: PropTypes.object,
	onActivitySupportVideoBtnClick: PropTypes.func,
	onActivitySupportVideoEnd: PropTypes.func,
	onActivitySupportVideoClose: PropTypes.func,
	disabled: PropTypes.bool

};

export default React.memo(ActivitySupportVideoButton);
