/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import HMHRedux from '../redux-util';

// slice constants
const sliceName = 'kfr';


// slice reducers
export const kfrSlice = HMHRedux.createSlice({
	name: sliceName,
	logoutAction: 'session/logout',
	initialState: {
		kfrAnswer: {}, // stores the answers that aren't submitted yet in activities when moving around KFR
		currentWordCard: {}, // stores the current word card they've selected across screens
	},
	reducers: {
		storeCurrentWordCard: (state, action) => {
			state.currentWordCard = action.payload;
		},
		storeKFRAnswer: (state, action) => {
			const answer = action.payload;
			state.kfrAnswer[answer.id] = answer.answer;
		},
	},
	extraReducers: {
		'ui/zonemenu/fulfilled': (state, action) => {
			state.kfrAnswer = {};
			state.currentWordCard = null;
		}
	}
});

export default kfrSlice.reducer;
