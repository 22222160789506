import { makeStyles } from '@material-ui/styles';
import { isFirefox } from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '928px',
		background: theme.colors.white,
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		padding: '40px',
		overflowY: 'hidden',
		paddingBottom: '0px',
		lineHeight: '220%',
		overflowX: 'hidden'
	},
	scrollContainer: {
		overflowY: isFirefox() ? 'auto' : 'overlay',

		'&::-webkit-scrollbar': {
			width: '6px'
		},
		'&::-webkit-scrollbar-track': {
			background: 'transparent',
			padding: '10px 0'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#CCC',
			borderRadius: '8px'
		},
		display: 'flex',
		flexGrow: 1,
		flexShrink: 1,
		minWidth: '432px',
		height: '60vh',
		width: '592px'

	},
	textTitle: { fontSize: '48px', fontWeight: 700, marginBottom: '8px' },
	textAuthor: { fontSize: '16px', fontWeight: 700, marginBottom: '16px' },
	textBody: {
		fontFamily: theme.fonts.context,
		marginLeft: '16px',
		flexGrow: 1,
		marginTop: '24px',
		fontSize: '18px'
	},
	word: { display: 'inline' },
	wordHighlighted: {
		// background: theme.colors.highlightYellow, // Will use in future
		// textDecoration: 'underline'
	},
	wordBold: { fontWeight: 'bold' },
	wordItalic: { fontStyle: 'italic' },
	wordUnderline: { textDecoration: 'underline' },
	feedbackWord: {
		textDecoration: 'underline',
		textDecorationColor: theme.colors.errorRed
	},
	passageQuestionHighlight: {
		textDecoration: 'underline',
		textDecorationStyle: 'dashed',
		textDecorationColor: theme.colors.grey
	},
	passageQuestionHighlightBackground: {
		background: theme.colors.readingZone.blue5,
		textDecorationColor: theme.colors.readingZone.primary
	},
	space: { display: 'inline' },
	punc: { display: 'inline' },
	sentence: { display: 'inline' },
	phrase: { display: 'inline' },
	paragraph: {
		marginBottom: '2em', paddingRight: '2em'
	},
	header: { marginBottom: '2em' },
	paragraphNumber: {
		fontSize: '14px',
		marginRight: '48px',
		display: 'inline',
		marginLeft: '-16px',
		color: theme.colors.slate,
		fontFamily: theme.fonts.ui
	},
	paragraphIndent: { display: 'inline', width: '48px' },
	correctIncorrectWrapper: {
		width: '170px',
		height: '32px',
		display: 'inline-flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		border: '2px solid',
		borderRadius: 4,
		backgroundColor: theme.palette.background.paper,
		fontSize: '18px',
		'&.correct': {
			borderColor: theme.colors.successGreen
		},
		'&.incorrect': {
			borderColor: theme.colors.errorRed
		}
	},
	correctIncorrectTarget: {
		width: '126px',
		marginLeft: '6px'
	},
	correctIncorrectIcon: {
		display: 'flex',
		alignItems: 'center',
		width: '24px',
		'& .correct-incorrect': {
			left: '-4px !important'
		}
	},
	selectItem: {
		width: '190px',
		fontFamily: theme.fonts.context,
		fontSize: '18px',
		'&:hover,&:focus': {
			backgroundColor: theme.colors.readingZone.blue5
		}
	},
	selectControl: {
		width: '170px',
		height: '32px',
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid',
		borderColor: theme.colors.readingZone.primary,
		fontSize: '18px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		fontFamily: theme.fonts.context,
		'& .MuiSelect-selectMenu': {
			backgroundColor: theme.colors.white,
			borderRadius: '4px',
			height: 'auto',
			padding: '1px 1px 1px 6px',
			'&:focus': {
				height: '97%',
				width: '100%',
				border: '2px solid',
				display: 'flex',
				alignItems: 'center',
				borderColor: theme.colors.readingZone.primary
			}
		}
	},
	selectClass: {
		borderColor: theme.colors.paleGrey
	},
	preSelect: {
		color: theme.colors.slate
	},
	preSelectInCorrect: {
		backgroundColor: theme.colors.slate,
		marginRight: '3px'
	}
}));
