import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { stripHtmlTags } from '@reading/r180/src/utils/stringUtils';
import { isEmpty } from 'lodash';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import StarRating from '../../components/StarRating/StarRating';
import ActivityDirections from '../../components/ActivityDirections';
import { MEDIA_SERVER_URL } from '@reading/r180/src/utils/constants';
import { getAudioExt } from '@reading/r180/src/utils/audio';

const useStyles = makeStyles(theme => ({
	wrapper: {
		background: theme.colors.writingZone.green5,
		display: 'flex',
		justifyContent: 'flex-start',
		height: '86px',
		alignItems: 'center',
		'& span': {
			fontWeight: '600',
			fontFamily: theme.fonts.buttonBold
		},
		boxShadow: '-2px -3px 4px rgb(0 0 0 / 9%)',
		borderRadius: '8px 8px 0 0'
	},
	headingWrapper: {
		background: theme.colors.black,
		color: theme.colors.white,
		height: '30px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: theme.fonts.buttonBold,
		fontWeight: '400',
		letterSpacing: '0.5px'
	}
}));

const StarRatingList = (props) => {
	const { writingTools, onStarsClicked, onOffButtonClicked } = props;
	const classes = useStyles();
	const EXT = getAudioExt();
	const soundsMap = {
		spelling:
			MEDIA_SERVER_URL +
			`assets/activi/writing/r180u_writing_edit_spelling_lintro.${EXT}`,
		tired_words:
			MEDIA_SERVER_URL +
			`assets/activi/writing/r180u_writing_edit_tiredwords_lintro.${EXT}`,
		sentence_fragments:
			MEDIA_SERVER_URL +
			`assets/activi/writing/r180u_writing_edit_sentsense_lintro.${EXT}`
	};
	const introSpeakerAudio =
		MEDIA_SERVER_URL +
		`assets/activi/writing/r180u_writing_edit_rate.${EXT}`;

	const handleStarClicked = (stars, text) => {
		onStarsClicked(stars, text);
	};

	const handleOnOffClicked = value => {
		if (typeof onOffButtonClicked !== 'undefined') {
			onOffButtonClicked(value);
		}
	};

	return (
		<>
			<div className={classes.wrapper}>
				<ActivityDirections
					text={
						'Your draft is looking great! Time to rate your recording. '
					}
					src={introSpeakerAudio}
				/>
			</div>
			<div className={classes.headingWrapper}>Writing Tools</div>
			{writingTools.map((words, i) => (
				<div key={i}>
					<StarRating
						onStarClicked={handleStarClicked}
						onShowOnOffClicked={handleOnOffClicked}
						text={words.title}
						showOnAndOffButton={words.isOn}
						src={soundsMap[words.type]}
						showHelpIcon={false}
					/>
				</div>
			))}
		</>
	);
}

StarRatingList.propTypes = {
	writingTools: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			title: PropTypes.string,
			isOn: PropTypes.bool
		}).isRequired
	),
	onStarsClicked: PropTypes.func.isRequired,
	onOffButtonClicked: PropTypes.func
};

export default React.memo(StarRatingList);
