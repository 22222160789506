import {KnowledgeForReading} from '../KnowledgeForReading';
import {bulkReplace, displaySafe} from '../../../utils/stringUtils';
import {cloneDeep, shuffle} from 'lodash';
import { getContentInfo } from '../../../media/mediaUtil';

export const getModifiedContentData = (contentData, activityData) => {
	let modifiedContentData = cloneDeep(contentData);
	contentData.word_cards.forEach(wordCard => {
		if (displaySafe(wordCard.word_card.word_text) === displaySafe(contentData.power_word_focus)) {
			modifiedContentData.word_card_sentence = wordCard.word_card.word_card_sentence;
			modifiedContentData.word_card_sentence_url = getSentenceUrl(activityData, wordCard.word_card.word_text);
			modifiedContentData.power_word_focus_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, {
				word: contentData.power_word_focus
			});
		}
	});
	const answers = contentData.word_card_activityData.words_in_context.answers.map(answer => {
		return {
			...answer,
			answer: true
		};
	});

	const distractors = contentData.word_card_activityData.words_in_context.distractors.map(distractor => {
		return {
			...distractor,
			distractor: true
		};
	});

	let problemsData = answers.concat(distractors);

	const updatedProblemData = problemsData.map(problem => {
		return {
			...problem,
			text_url: getProblemUrl(activityData, contentData.word_card_activityData.words_in_context.id, problem.id)
		};
	});

	return {...modifiedContentData, problemsData: shuffle(updatedProblemData)};
};

const getSentenceUrl = (activityData, wordText) => {
	const constants = {
        word: wordText,
        asset_id: getContentInfo(activityData, false).assetId
    };
    const sentenceUrl = bulkReplace(KnowledgeForReading.WORD_CTX_URL, constants);
	return sentenceUrl;
};

const getProblemUrl = (activityData, activityId, problemId) => {
	const segment = activityData.segment.toString().padStart(2, '0');
	const stage = activityData.stage;
	const sentenceUrl = bulkReplace(KnowledgeForReading.WORDS_IN_CONTEXT_PROBLEM, {
		stage,
		segment,
		activityId,
		problemId
	});
	return sentenceUrl;
};
