import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SpellingClinicItem from '../SpellingClinic/SpellingClinicItem';
import PlayRecorded from './PlayRecorded';
import WordCard from '../WordCard/WordCard';

const useStyles = makeStyles(theme => ({
	holderClass: {
		position: 'relative',
		margin: 'auto',
		height: '100%'
	},
	animatedTiles: {
		height: '100%'
	}
}));

const ComponentSwitcher = props => {
	const {
		wordsList,
		studyWordIndex,
		wordCardIndex,
		setGoOnDisabled,
		handleForward,
		configData,
		screenIndex,
		isFinishedWithIntro,
		onRecording
	} = props;

	const classes = useStyles();
	const finalReport = [true, true, true, true, true];
	const MAX_STUDYWORDS = 5;
	const activityStatus = {completedActivity: 2, totalActivity: 3};
	const showWordCard = (screenIndex - 1) % 3 === 0;
	const showStaticScreen = screenIndex % 3 === 0 && wordCardIndex - 1 !== 5;

	const initailTaskData = {
		word_card_activities: []
	};

	const initialCardData = {
		word_card: {
			part_of_speech: null,
			word_card_activities: [],
			word_text:
				studyWordIndex === MAX_STUDYWORDS
					? wordsList[studyWordIndex - 1].text.toLowerCase()
					: wordsList[studyWordIndex].text.toLowerCase(),

			word_card_sentence:
				studyWordIndex === MAX_STUDYWORDS
					? wordsList[studyWordIndex - 1].sentence
					: wordsList[studyWordIndex].sentence,
			word_card_definition:
				studyWordIndex === MAX_STUDYWORDS
					? wordsList[studyWordIndex - 1].definition
					: wordsList[studyWordIndex].definition
		}
	};

	const hasWordCardEnabled =
		wordCardIndex * 2 + studyWordIndex - 1 === screenIndex ||
		screenIndex === 1;

	return (
		<div className={classes.holderClass}>
			{showStaticScreen ? (
				<div className={classes.animatedTiles}>
					<SpellingClinicItem
						wordsList={wordsList}
						configData={configData}
						staticMode={true}
						showIcon={false}
						activeIndex={wordCardIndex}
						finalScreen={false}
						showAudio={false}
						maxStudyWords={MAX_STUDYWORDS}
						enableGoOn={setGoOnDisabled}
					/>
				</div>
			) : (
				<>
					{showWordCard && studyWordIndex <= MAX_STUDYWORDS ? (
						<div className="animate__animated animate__fadeIn">
							{hasWordCardEnabled && (
								<WordCard
									isFirstStudyWord={screenIndex === 1}
									isTipsPreselected={true}
									isFinishedWithIntro={isFinishedWithIntro}
									key={`WORDCLINIC_CARD${wordsList[
										studyWordIndex
									].text.toLowerCase()}`}
									wordCardData={wordsList[studyWordIndex]}
									card={initialCardData}
									taskData={initailTaskData}
									powerWordData={activityStatus}
									onTipsComplete={() => {
										setGoOnDisabled(false);
									}}
								/>
							)}
						</div>
					) : wordCardIndex - 1 === MAX_STUDYWORDS ? (
						<SpellingClinicItem
							wordsList={wordsList}
							configData={configData}
							staticMode={true}
							finalScreen={true}
							finalReport={finalReport}
						/>
					) : (
						<PlayRecorded
							text={wordsList[studyWordIndex].text}
							showWordCard={showWordCard}
							setGoOnDisabled={setGoOnDisabled}
							handleForward={handleForward}
							screenIndex={screenIndex}
							onRecording={onRecording}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default React.memo(ComponentSwitcher);
