import {MEDIA_SERVER_URL} from '../../utils/constants';
import {getAudioExt} from '../../utils/audio';
import api from '../../api/api';
import {completeActivityAndGoToResultsPage, transitionToNextActivity} from '../../utils/navigation';
import {uiSlice} from '../../store/slices/ui';
import {cloneDeep} from 'lodash';
const EXT = getAudioExt();

export const VO = [
	'r180u_readpass_challenge',
	'r180u_readpass_challengeaccepted',
	'r180u_readpass_challengedeclined',
	'r180u_readpass_comp2part',
	'r180u_readpass_compchart',
	'r180u_readpass_compcorrattempt1',
	'r180u_readpass_compcorrattempt2',
	'r180u_readpass_compcorrattempt3',
	'r180u_readpass_comphighlight',
	'r180u_readpass_compincorrattempt1',
	'r180u_readpass_compincorrattempt2',
	'r180u_readpass_compincorrattempt3',
	'r180u_readpass_compmatch',
	'r180u_readpass_comporder',
	'r180u_readpass_comprehensionintro',
	'r180u_readpass_compselectrespmulti',
	'r180u_readpass_compselectrespsingle',
	'r180u_readpass_compsort',
	'r180u_readpass_feedback_tooshort',
	'r180u_readpass_intro_intro',
	'r180u_readpass_intro_introsz',
	'r180u_readpass_mandread',
	'r180u_readpass_mandreadhelppost',
	'r180u_readpass_mandreadhelppre',
	'r180u_readpass_mandrecord',
	'r180u_readpass_mandrecordhelppost',
	'r180u_readpass_mandrecordhelppre',
	'r180u_readpass_norecording_bypass',
	'r180u_readpass_passagecompunlocked',
	'r180u_readpass_passagecompunlockedhelp',
	'r180u_readpass_passagehelp',
	'r180u_readpass_postreadaloud',
	'r180u_readpass_postrecord',
	'r180u_readpass_powerwords',
	'r180u_readpass_readaloud',
	'r180u_readpass_record',
	'r180u_readpass_record_bypass',
	'r180u_readpass_results_0_2',
	'r180u_readpass_results_0_4',
	'r180u_readpass_results_1_2',
	'r180u_readpass_results_1_4',
	'r180u_readpass_results_2_2',
	'r180u_readpass_results_2_4',
	'r180u_readpass_results_3_4',
	'r180u_readpass_results_4_4',
	'r180u_readpass_selfcheckgate',
	'r180u_readpass_selfcheckhelp',
	'r180u_readpass_selfcheckhelp_bypass',
	'r180u_readpass_selfcheckintro',
	'r180u_readpass_selfcheckintro_bypass',
	'r180u_readpass_selfcheckpost',
	'r180u_readpass_selfcheckratinghelp_bypass',
	'r180u_readpass_selfcheckratingintro',
	'r180u_readpass_selfcheckratingintro_bypass',
	'r180u_readpass_selfcheckratingresults',
	'r180u_readpass_selfcheckresultsmodal',
	'r180u_readpass_submitrecording',
	'r180u_readpass_submitrecording_bypass',
	'r180u_readpass_submitrecordingaccepted',
	'r180u_readpass_submitrecordingaccepted_bypass',
	'r180u_readpass_submitrecordingrejected',
	'r180u_readpass_submitrecordingrejected_bypass',
	'r180u_readpass_twopart_question_stem',
	'r180u_readpass_passage_purpose_{strategy}',
	'r180u_readpass_rating_{strategy}'
];

export const MEDIA = {
	PASSAGE_TEXT: `/content/passages/r180u_passage_{asset_id}.html`,
	SEGMENTS: `/content/segments/r180u_segments_{stage}.json`,
	PASSAGE_QUESTION: `/content/activi/readpass/r180u_content_readpass_{stage}{segment}{level}_{strategy}.json`,
	PASSAGE_CONTEXT_QUESTION: `/content/activi/readpass/r180u_content_readpass_{stage}{segment}{level}_contextclues.json`,
	FLUENCY_RATING_QUESTION: '/content/fluencyrating/r180u_content_fluencyratingquestions.json',
	WORD_CARD: `/content/dictio/word/r180u_content_{word}.json`,
	WORD_CONTEXT: `/assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,
	WORD_DEFINITION: `/assets/dictio/def/r180u_def_{word}.${EXT}`,
	recordHelpSound: `/assets/activi/readrecord/r180u_readpass_record.${EXT}`,
	readHelpSound: `/assets/activi/readrecord/r180u_readpass_readaloud.${EXT}`,
	segmentFeature: `/assets/activi/segselect/r180u_segselect_feature_{stage}{segment}.jpg`,
	segmentVideo: `/assets/video/r180u_video_{stage}{segment}.mp4`,
	segmentVideoCaptions: `/assets/video/r180u_{stage}{segment}.vtt`,
	ELSummary: `${MEDIA_SERVER_URL}/assets/video/r180u_video_{stage}{segment}_summary_span.${EXT}`,
	Focus_Attention_Audio: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_selfcheckratingresults.${EXT}`,
	Rate_Understanding_Audio: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_rating_{strategy}.${EXT}`,
	Challenge_Title_Audio: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_challenge.${EXT}`,
	Challenge_Question_Title_Audio: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_challenge_short.${EXT}`,
	Submit_Recording_Title_Audio: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_submitrecording.${EXT}`,
	SOUNDS: [
		{name: 'clickFxSound', src: `${MEDIA_SERVER_URL}/assets/sfx/r180u_sfx_click.${EXT}`},
		{name: 'contractFxSound', src: `${MEDIA_SERVER_URL}/assets/sfx/r180u_sfx_contract.${EXT}`},
		{name: 'expandFxSound', src: `${MEDIA_SERVER_URL}/assets/sfx/r180u_sfx_expand.${EXT}`},
		{name: 'hideFxSound', src: `${MEDIA_SERVER_URL}/assets/sfx/r180u_sfx_hide.${EXT}`}
	]
};

export const STRATEGIES = {
	'Cause and Effect': 'causeeffect',
	'Compare and Contrast': 'comparecontrast',
	'Context Clues': 'contextclues',
	'Main Idea': 'mainidea',
	Sequence: 'sequence',
	Description: 'description',
	Summary: 'summary',
	'Problem and Solution': 'problemsolution',
	'Central Ideas and Details': 'centideasdetails',
	"Author's Purpose": 'authorpurpose',
	'Point of View': 'pointofview',
	"Author's Purpose and Point of View": 'authorspointofview'
};

export const MODES = {
	RECORD: 0,
	RATE: 1,
	ATTENTION: 2,
	UNDERSTANDING: 3,
	CHALLENGE: 4,
	COMPREHENSION: 5,
	DONE: 6
};

export const WORD_DELAYS = [0, 4000, 2000, 1000, 500, 250];

export const tabs = {
	allTabs: [
		{id: '1', icon: 'read-aloud', text: 'Read Aloud'},
		{id: '2', icon: 'mic', text: 'Record'},
		{id: '3', icon: 'anchor-video', text: 'Video'}
	],
	noRecord: [
		{id: '1', icon: 'read-aloud', text: 'Read Aloud'},
		{id: '2', icon: 'mic', text: 'Record', disabled: true},
		{id: '3', icon: 'anchor-video', text: 'Video'}
	]
};

export const MIN_REC_LENGTH_SEC = 10;
export const SEEKSTART = -86400;
export const SEEKBACK = -10;
export const SEEKFORWARD = 10;
export const SEEKEND = 86400;
export const NOISE_THRESHOLD = 0.25;
export const SILENCE_TIME = 5000;

export const sendProgressToServer = async postData => {
	api.activity.updateActivity(postData);
};

export const completeActivityNoTransition = postData => {
	completeActivityAndGoToResultsPage(postData);
};

export const completeActivity = async (postData, history, dispatch) => {
	try {
		await transitionToNextActivity(history, postData);
	} catch (err) {
		dispatch(uiSlice.actions.setErrorMessage(err));
	}
};
