import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import media from '../../api/media';
import { isEmpty, shuffle } from 'lodash';
import { getContentInfo } from '../../media/mediaUtil';
import { getAudioExt } from '../../utils/audio';
import { MEDIA_SERVER_URL } from '../../utils/constants';
import { bulkReplace } from '../../utils/stringUtils';
import { loadSleep } from '../../utils/ui';
import { completeActivityAndGoToResultsPage } from '../../utils/navigation';
import api from '../../api/api';

const EXT = getAudioExt();

// 1: fluent, 2: slow, 3: missed
const completionCriteria = ['11', '22', '23', '33', '32'];
const evaluationMap = {
	'110': 1,
	'121': 1,
	'122': 2,
	'123': 3,
	'131': 1,
	'132': 3,
	'133': 3,
	'211': 1,
	'212': 2,
	'213': 3,
	'311': 1,
	'312': 3,
	'313': 3,
	'220': 2,
	'230': 3,
	'330': 3,
	'320': 3
}

export const WordAssessment = {
	MAX_DISTRACTION_WORDS: 5,
	TRIAL_SIZE: 10,
	FLUENT: 'Fluent',
	MISSED: 'Missed',
	SLOW: 'Slow',
	TIME_OUT: 15,

	SOUND_INTRO: `${MEDIA_SERVER_URL}assets/activi/wordas/r180u_wordas_intro.${EXT}`,
	SOUND_COMPLETE: `${MEDIA_SERVER_URL}assets/activi/wordas/r180u_wordas_assessment_complete.${EXT}`,
	SOUND_HELP: `${MEDIA_SERVER_URL}assets/activi/wordas/r180u_wordas_trialhelp.${EXT}`,
	SOUND_PAUSE: `${MEDIA_SERVER_URL}assets/activi/wordas/r180u_wordas_pause.${EXT}`,
	SOUND_CONTINUE: `${MEDIA_SERVER_URL}assets/activi/wordas/r180u_wordas_continue.${EXT}`,
	SOUND_RESULTS_HELP: `${MEDIA_SERVER_URL}assets/activi/wordas/r180u_wordas_reporthelp.${EXT}`,
	SOUND_INPROGRESS_HELP: `${MEDIA_SERVER_URL}assets/activi/wordas/r180u_wordas_midroundhelp.${EXT}`,

	WORD_SOUND_URL: `${MEDIA_SERVER_URL}assets/dictio/word/r180u_word_{word}.${EXT}`,
	WORD_CTX_URL: `${MEDIA_SERVER_URL}assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,

	SFX_CORRECT: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_correct.${EXT}`,
	SFX_INCORRECT: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_incorrect.${EXT}`,
	SFX_CLICK: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_click.${EXT}`,
	SFX_SLOW: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_slow.${EXT}`,
	TRIAL_SCORE: {
		1: 'Fluent',
		2: 'Slow',
		3: 'Missed'
	},

	loadWordsList: async (activityData, wordFluency) => {
		const { urlPath } = getContentInfo(activityData, true);
		if (urlPath !== '/') {
			const mediaData = await media.getOne(urlPath);
			const assessmentWords = [];
			const scoreMap = {};
			const totalWordsToAssessCount = mediaData['assessment_words'].length;
			let pendingWordsToAssess = mediaData['assessment_words'].filter(wordObj => {
				const match = wordFluency.fluencyScores.filter(completedWord => {
					return completedWord.wordId === wordObj.text;
				});
				return match.length === 0;
			});


			if (!isEmpty(mediaData)) {
				pendingWordsToAssess.slice(0, WordAssessment.TRIAL_SIZE).forEach(word => {
					const wordZoneDistractors = [...new Set(word.wordZoneDistractors)];
					const words = [
						word.text,
						...shuffle(wordZoneDistractors).slice(0, WordAssessment.MAX_DISTRACTION_WORDS)
					];

					assessmentWords.push({
						name: word.text,
						words: words.map(w => ({ name: w })),
						trialNo: 1
					});
					scoreMap[word.text] = {
						1: 0,
						2: 0,
						3: 0
					}
				});
			}

			return { assessmentWords, scoreMap, totalWordsToAssessCount };
		}
		return [];
	},

	init: async (activityData, wordsList, isActivityCharged) => {
		const start = new Date().getTime();

		//////////////
		// load the activity sounds
		//////////////
		AudioPlayer2.loadSound(WordAssessment.SOUND_INTRO);
		AudioPlayer2.loadSound(WordAssessment.SOUND_PAUSE);
		AudioPlayer2.loadSound(WordAssessment.SOUND_CONTINUE);
		AudioPlayer2.loadSound(WordAssessment.SOUND_COMPLETE);
		AudioPlayer2.loadSound(WordAssessment.SOUND_HELP_TRIAL);
		AudioPlayer2.loadSound(WordAssessment.SOUND_RESULTS_HELP);
		AudioPlayer2.loadSound(WordAssessment.SOUND_INPROGRESS_HELP);

		AudioPlayer2.loadSound(WordAssessment.SFX_CORRECT);
		AudioPlayer2.loadSound(WordAssessment.SFX_INCORRECT);
		AudioPlayer2.load({ name: 'click', src: WordAssessment.SFX_CLICK });
		AudioPlayer2.loadSound(WordAssessment.SFX_SLOW);

		// load each of the words sounds and sentences
		wordsList.forEach(wordObj => {
			const word = wordObj.name;
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(WordAssessment.WORD_SOUND_URL, constants);
			const word_ctx_url = bulkReplace(WordAssessment.WORD_CTX_URL, constants);

			AudioPlayer2.load({ name: word, src: word_text_url });
			AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });
		});

		const end = new Date().getTime();
		if (isActivityCharged === false) {
			await loadSleep(end - start);
		}

		return wordsList;
	},

	isThirdRound: (trials) => {
		const score = `${trials[1]}${trials[2]}`;
		return !completionCriteria.includes(score);
	},

	evaluateResults: (scores) => {
		// Refer the logic table from './trialScoreLogic.md'
		const results = { fluent: [], slow: [], missed: [] };

		Object.keys(scores).forEach(item => {
			const score = `${scores[item][1]}${scores[item][2]}${scores[item][3]}`;

			switch (evaluationMap[score]) {
				case 1:
					results.fluent.push(item);
					break;

				case 2:
					results.slow.push(item);
					break;

				case 3:
					results.missed.push(item);
					break;

				default:
					results.missed.push(item);
					break;
			}
		});
		return results;
	},

	// mark the item as complete, but don't navigate away from page
	completeActivityNoTransition: (activityData, fluencyAssessment, fluencyScores, responseRateList, totalWordsToAssess) => {

		completeActivityAndGoToResultsPage({
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			},
			fluencyassessment: {
				studentSegmentId: fluencyAssessment.studentSegmentId,
				fluencyAssessmentZone: fluencyAssessment.fluencyAssessmentZone,
				totalWordsToAssess: totalWordsToAssess,
				responseRateMillis: responseRateList
			},
			wordfluency: {
				studentSegmentId: fluencyAssessment.studentSegmentId,
				assessmentZone: fluencyAssessment.fluencyAssessmentZone,
				fluencyScores: fluencyScores
			}
		});
	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendProgressToServer: (activityData, fluencyAssessment, fluencyScores, responseRateList) => {
		if (isEmpty(activityData)) {
			return;
		}

		const savedActivityData = {
			activity: {
				isComplete: false,
				studentActivityId: activityData.studentActivityId
			},
			fluencyassessment: {
				studentSegmentId: fluencyAssessment.studentSegmentId,
				fluencyAssessmentZone: fluencyAssessment.fluencyAssessmentZone,
				totalWordsToAssess: fluencyScores.length,
				responseRateMillis: responseRateList
			},
			wordfluency: {
				studentSegmentId: fluencyAssessment.studentSegmentId,
				assessmentZone: fluencyAssessment.fluencyAssessmentZone,
				fluencyScores: fluencyScores
			}
		};

		api.activity.updateActivity(savedActivityData);
	}
};

export default WordAssessment;
