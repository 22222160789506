import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Modal} from '@material-ui/core';
import WordCard from '../WordCard/WordCard';
import {isFirefox} from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
	modal: {
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
		position: 'absolute',
		width: 'auto',
		height: 'auto',
		backgroundColor: theme.colors.white,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px'
	},
	closeButton: {
		position: 'absolute',
		top: '24px',
		right: '10px',
		border: `1px solid ${theme.colors.black}`,
		fontSize: '12px',
		height: '24px',
		width: '24px',
		borderRadius: '12px',
		lineHeight: '24px',
		zIndex: '999',
		'& i': {
			position: 'relative',
			top: isFirefox() ? '-0.5px' : '0.5px'
		},
		'&:hover': {
			transform: 'scale(1.2)'
		}
	}
}));

const WordCardModal = props => {
	const {
		id,
		className,
		isOpen,
		onModalClose,
		wordCardData,
		card,
		powerWordData,
		videoSrcURL
	} = props;
	const classes = useStyles();

	const handleClose = () => {
		if (typeof onModalClose !== 'undefined') {
			onModalClose();
		}
	};

	return (
		<Modal
			disableBackdropClick
			disableEscapeKeyDown
			aria-labelledby="word-card-window"
			aria-describedby="word-card-window"
			open={isOpen}
			style={{zIndex: '99999'}}
			onClose={handleClose}
			className="r180-modal"
		>
			<div
				className={`${classes.modal} ${className} modal-window`}
				id={id}
			>
				<button
					className={`${classes.closeButton} word-card-modal-close`}
					onClick={handleClose}
				>
					<i className="r180 x" />
				</button>
				<WordCard
					wordCardData={wordCardData}
					card={card}
					kill={false}
					taskData={[]}
					powerWordData={powerWordData}
					videoSrcURL={videoSrcURL}

				/>
			</div>
		</Modal>
	);
};

WordCardModal.defaultProps = {
	id: '',
	className: '',
	isOpen: false,
	wordCardData: {},
	card: {},
	powerWorddData: {},
	videoSrcURL: ''
};

WordCardModal.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	wordCardData: PropTypes.object,
	card: PropTypes.object,
	powerWordData: PropTypes.object,
	videoSrcURL: PropTypes.string
};

export default React.memo(WordCardModal);
