import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	wordTile: {
		display: 'inline-block',
		background: theme.colors.white,
		width: 'auto',
		minWidth: '64px',
		height: '56px',
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '28px',
		outline: '1px solid transparent',
		textAlign: 'center',
		cursor: 'pointer',
		padding: '8px 16px',
		'&.no-border': {
			border: '1px solid transparent'
		},
		'&:hover': {
			background: theme.colors.readingZone.blue5
		},
		'&.selected': {
			background: theme.colors.readingZone.blue5,
			padding: '7px 15px',
			border: `2px solid ${theme.colors.readingZone.primary}`
		},
		'&.dragging': {
			borderRadius: '8px'
		}
	},
	text: {
		fontFamily: theme.fonts.context,
		fontSize: '32px',
		marginTop: '4px'
	},
	textHighlight: {
		background: '#FFFCB0',
		//borderBottom: '1px solid #131313',
		boxShadow: '0px 1px #131313',
		minWidth: '64px'
	},
	textSpacing: {
		textAlign: 'left',
		marginLeft: '-7px',
		lineHeight: '38px'
	}
}));

const WordAnimText = props => {
	const {
		text,
		id,
		selected,
		border,
		dragging,
		className,
		index,
		highlightedWordList
	} = props;
	const classes = useStyles();
	return (
		<div
			id={id}
			data-testid="testId"
			aria-describedby={id}
			aria-label={text}
			className={`${classes.wordAnimTile} ${selected ? 'selected' : ''} ${
				border ? '' : 'no-border'
			} ${dragging ? 'dragging' : ''} ${className}`}
		>
			<div
				className={`${classes.text} ${highlightedWordList &&
					classes.textHighlight} ${classes.textSpacing}`}
			>
				{text}
			</div>
		</div>
	);
};

WordAnimText.defaultProps = {
	id: '',
	text: '',
	icon: '',
	selected: false,
	border: true,
	dragging: false,
	className: ''
};
WordAnimText.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	selected: PropTypes.bool,
	border: PropTypes.bool,
	dragging: PropTypes.bool,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default React.memo(WordAnimText);
