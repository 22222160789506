let sessionCache = {};

export const getDeep = (obj, keyChain) => {
	const nextKey = keyChain.shift();
	const has = Reflect.has(obj, nextKey);
	const val = obj[nextKey];

	if (keyChain.length === 0) {
		return val;
	}

	if (has) {
		return getDeep(val, keyChain);
	}

	return undefined;
};

const SessionStorage = {
	wasRestored: null,
	sessionName: null,

	setSessionName: sessionName => {
		SessionStorage.sessionName = sessionName;
	},

	cacheSession: latestSessionData => {
		sessionCache = latestSessionData;
	},

	init: sessionName => {
		if (!sessionName) {
			throw new Error(
				`Invalid session name: "${SessionStorage.sessionName}"`
			);
		}

		SessionStorage.sessionName = sessionName;

		let prevSession;

		if (Reflect.has(window, 'sessionStorage')) {
			prevSession = window.sessionStorage[sessionName];
		}

		if (prevSession) {
			const prevSessionObj = JSON.parse(prevSession);

			SessionStorage.cacheSession(prevSessionObj);
			SessionStorage.store();
			SessionStorage.wasRestored = true;
		} else {
			SessionStorage.wasRestored = false;
		}

		return SessionStorage;
	},

	getSession() {
		return sessionCache;
	},

	get: key => {
		// Return whole session
		if (typeof key === 'undefined') {
			return SessionStorage.getSession();
		}

		// Return part of the session
		if (Reflect.has(sessionCache, key)) {
			return sessionCache[key];
		}

		// Return a deep property within the session
		if (key.includes('.')) {
			const keyChain = key.split('.');
			return getDeep(sessionCache, keyChain);
		}

		// Nothing was found
		return undefined;
	},

	store: () => {
		if (!SessionStorage.sessionName) {
			throw new Error(
				`Invalid session name: "${SessionStorage.sessionName}"`
			);
		}

		const {sessionName} = SessionStorage;
		window.sessionStorage[sessionName] = JSON.stringify(sessionCache);
	},

	set: (key, data) => {
		if (typeof key === 'undefined') {
			// Update whole session
			sessionCache = data;
		} else {
			// Update part of session
			sessionCache = Object.assign({}, sessionCache, {[key]: data});
		}

		// We save the state of the current session
		// every time we make an update
		SessionStorage.store();
	},

	clearSessionEntry: key => {
		if (Reflect.has(sessionCache, key)) {
			const deleted = delete sessionCache[key];
			SessionStorage.store();
			return deleted;
		}

		return undefined;
	},

	clearSession: () => {
		if (!SessionStorage.sessionName) {
			throw new Error(
				`Invalid session name: "${SessionStorage.sessionName}"`
			);
		}

		let sessionName = SessionStorage.sessionName;
		window.sessionStorage[sessionName] = '{}';
		sessionCache = {};
		return sessionCache;
	},

	clearAll: () => {
		SessionStorage.clearSession();
		SessionStorage.restored = null;
	}
};

// SessionStorage.restorePreviousSession();

export default SessionStorage;
