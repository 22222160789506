import React from 'react';
import WordTitle from '@reading/common/src/components/WordTitle';
import ActivityDirections from '@reading/common/src/components/ActivityDirections';
import { DndProvider, DndQuestions, DndAnswers, DndTiles } from '@reading/common/src/components/DragNDropPoc';
import useStyles from './SynonymsAndAntonyms.styles';

const SynonymsAndAntonyms = (props) => {
    const { dndData, metaData, onDndChange } = props;
    const classes = useStyles();
    const {
        dropBuckets = [],
        contentData: { stem, stem_url, power_word_focus, power_word_focus_url } = {}
    } = metaData;

    return (
        <div className={classes.synonymsAndAntonyms}>
            <div className="header">
                <div className="title">
                    <WordTitle className="mt-0 mb-0" word={power_word_focus} src={power_word_focus_url} />
                </div>
                <div className="text">
                    <ActivityDirections
                        className="mt-1 mb-1"
                        text={stem}
                        src={stem_url}
                    />
                </div>
            </div>

            <DndProvider metaData={metaData} dndData={dndData} onDndChange={onDndChange}>

                <DndQuestions meta={metaData} className="drag-container" type="questions">
                    <DndTiles />
                </DndQuestions>

                <div className="drop-container animate__animated animate__fadeIn">
                    {dropBuckets.map(item => (
                        <DndAnswers
                            key={item.title}
                            title={item.title}
                            type={item.id}
                            meta={metaData}
                            audioSrc={item.audioSrc}
                        >
                            <DndTiles />
                        </DndAnswers>
                    ))}
                </div>
            </DndProvider>
        </div>
    );
};

export default React.memo(SynonymsAndAntonyms);
