import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import SpellingChallengeItem from './SpellingChallengeItem';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {useUnmount} from 'react-use';
import {SpellingAssessment} from '@reading/r180/src/activities/SpellingAssessment/SpellingAssessment';

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		width: '600px',
		textAlign: 'center',
		margin: 'auto'
	},
	pauseOverlay: {
		width: '100%',
		height: '100%',
		background: 'transparent',
		zIndex: 99,
		display: 'flex',
		flexDirection: 'column',
		gap: '20px',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

export default function SpellingChallengeList(props) {
	const {
		id,
		wordObjList,
		interactive,
		onWordFocus,
		onWordBlur,
		onWordKeyPress,
		onTrialCompleted,
		selectedWord,
		trailCount,
		isPaused = false,
		isCompleted
	} = props;
	const classes = useStyles();
	const trialRoundCount = (trailCount - 1) * SpellingAssessment.TRIAL_SIZE;

	useUnmount(() => {
		AudioPlayer2.stopAll();
	});

	return (
		<div className={classes.wrapper} id={id}>
			{isPaused && (
				<div className={classes.pauseOverlay}>
					<h2>PAUSED</h2>
					<p>Press play button to resume</p>
				</div>
			)}
			<div
				className={classes.spellingList}
				style={{display: isPaused && 'none'}}
			>
				{wordObjList.map((wordObj, idx) => (
					<SpellingChallengeItem
						{...props}
						word={wordObj.wordId}
						soundName={wordObj.wordId}
						num={trialRoundCount + idx + 1}
						decodingInfo={wordObj.decodingInfo}
						key={idx}
						interactive={interactive[wordObj.wordId]}
						onFocus={onWordFocus}
						onBlur={onWordBlur}
						onCompleted={onTrialCompleted}
						onKeyPress={onWordKeyPress}
						selectedWord={selectedWord}
						wordList={wordObjList}
					/>
				))}
			</div>
		</div>
	);
}

SpellingChallengeList.defaultProps = {
	id: '',
	wordObjList: [],
	interactive: {},
	selectedWord: ''
};
SpellingChallengeList.propTypes = {
	id: PropTypes.string,
	trailCount: PropTypes.number,
	interactive: PropTypes.object,
	onWordFocus: PropTypes.func,
	onWordBlur: PropTypes.func,
	onWordKeyPress: PropTypes.func,
	onTrialCompleted: PropTypes.func,
	selectedWord: PropTypes.string
};
