import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {isEmpty} from 'lodash';
import SoundButton from '../../SoundButton';
import ActivityButton from '../../ActivityButton/ActivityButton';
import Close from '../../Close';

const useStyles = makeStyles(theme => ({
	contentWrapper: {
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
		position: 'absolute',
		backgroundColor: theme.colors.white,
		padding: '48px 100px 32px 56px',
		minWidth: '520px'
	},
	content: {
		display: 'flex',
		flexDirection: 'column'
	},
	closeIcon: {
		position: 'absolute',
		right: '16px',
		top: '16px'
	},
	title: {
		fontFamily: theme.fonts.uiSemibold,
		fontSize: '24px',
		lineHeight: '30px',
		textAlign: 'center',
		letterSpacing: '0.15px',
		color: theme.colors.black,
		display: 'flex'
	},
	titleWrapper: {
		display: 'flex',
		textAlign: 'left',
		marginLeft: '5px'
	},
	subtitle: {
		fontFamily: theme.fonts.uiBold,
		fontSize: '24px',
		lineHeight: '30px',
		textAlign: 'center',
		letterSpacing: '0.15px',
		color: theme.colors.black,
		marginTop: '20px',
		marginLeft: '32px',
		display: 'flex'
	},
	subTitleWrapper: {
		display: 'flex',
		textAlign: 'left'
	},
	buttonWrapper: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '40px',
		width: '100%',
		justifyContent: 'center'
	},

	buttonRow: {
		background: 'transparent',
		textAlign: 'right',
		display: 'flex'
	},
	leftButton: {
		margin: '0 40px'
	},
	warningIcon: {
		fontSize: '45px',
		color: theme.colors.warningRed,
		display: 'flex',
		alignItems: 'center',
		marginRight: '18px'
	}
}));

const ConfirmationModal = ({
	onCloseClicked,
	onPrimaryButtonClicked,
	onSecondaryButtonClicked,
	open,
	subTitle,
	title,
	titleSound,
	showCloseIcon,
	showSecondaryButton,
	showWarningIcon,
	primaryButtonText,
	secondaryButtonText,
	primaryButtonIcon,
	secondaryButtonIcon,
	className
}) => {
	const classes = useStyles({});

	const handleClose = event => {
		event.preventDefault();
		onCloseClicked();
	};

	const handleGoOnClicked = event => {
		event.preventDefault();
		onPrimaryButtonClicked();
	};

	const handleCancelClicked = event => {
		event.preventDefault();
		onSecondaryButtonClicked();
	};

	return (
		<>
			<Modal
				disableBackdropClick //prevent closing of modal on clicking outside
				disableEscapeKeyDown //prevent closing of modal on pressing ESC key
				open={open}
				onClose={onCloseClicked}
				style={{zIndex: '99999'}}
				className="r180-modal"
			>
				<div
					className={`${className} ${classes.contentWrapper} modal-window`}
				>
					{showCloseIcon && (
						<div className={classes.closeIcon}>
							<Close handleOnClose={handleClose} />
						</div>
					)}
					<div className={classes.content}>
						{title && (
							<div className={classes.title}>
								{isEmpty(titleSound) === false && (
									<SoundButton
										border={false}
										sound={titleSound}
										size={32}
										className={'transparent'}
									/>
								)}
								<div className={classes.titleWrapper}>
									{title}
								</div>
							</div>
						)}
						{subTitle && (
							<div className={classes.subtitle}>
								{showWarningIcon && (
									<div className={classes.warningIcon}>
										<i className="r180 warning" />
									</div>
								)}
								<div className={classes.subTitleWrapper}>
									{subTitle}
								</div>
							</div>
						)}
						<div
							className={`${classes.buttonWrapper} ${classes.buttonRow}`}
						>
							{showSecondaryButton && (
								<div className={classes.leftButton}>
									<ActivityButton
										text={secondaryButtonText}
										icon={secondaryButtonIcon || 'x'}
										isPrimary={false}
										onClick={e => handleCancelClicked(e)}
									/>
								</div>
							)}
							<ActivityButton
								text={primaryButtonText}
								icon={primaryButtonIcon}
								onClick={e => handleGoOnClicked(e)}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

ConfirmationModal.defaultProps = {
	onSecondaryButtonClicked: () => {},
	titleSound: {},
	primaryButtonText: 'Yes',
	secondaryButtonText: 'No',
	subTitle: '',
	title: '',
	showCloseIcon: true,
	showSecondaryButton: true,
	showWarningIcon: false,
	primaryButtonIcon: 'check',
	secondaryButtonIcon: 'x'
};

ConfirmationModal.propTypes = {
	open: PropTypes.bool.isRequired,
	onPrimaryButtonClicked: PropTypes.func.isRequired,
	onCloseClicked: PropTypes.func.isRequired,
	titleSound: PropTypes.object,
	onSecondaryButtonClicked: PropTypes.func,
	primaryButtonText: PropTypes.string,
	secondaryButtonText: PropTypes.string,
	subTitle: PropTypes.string,
	title: PropTypes.string,
	showCloseIcon: PropTypes.bool,
	showSecondaryButton: PropTypes.bool,
	showWarningIcon: PropTypes.bool,
	primaryButtonIcon: PropTypes.string,
	secondaryButtonIcon: PropTypes.string
};

export default React.memo(ConfirmationModal);
