import React, {useEffect, useState} from 'react';
import {ActivityInstructionButton, WordFamily} from '@reading/common';
import ActivitySuspense from '../../../containers/App/ActivitySuspense';
import Footer from '../../../containers/App/Footer';
import FooterForwardBack from '../../../containers/App/FooterForwardBack';
import {useHistory} from 'react-router-dom';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {KnowledgeForReading} from '../KnowledgeForReading';
import useIdleHelp from '../../../utils/useIdleHelp';
import {useSelector, useDispatch} from 'react-redux';
import ActivityFrame from '../../../containers/App/ActivityFrame';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import {uiSlice} from '../../../store/slices/ui';
import {cloneDeep, isEmpty, shuffle} from 'lodash';
import {LanguageZone} from '../LanguageZone';
import {displaySafe} from '../../../utils/stringUtils';
import Navbar from '../../../containers/App/Navbar';
import {sleep} from '../../../utils/sleep';
import useBatchedSetState from '../../../utils/useBatchedSetState';

export default function WordFamilyActivity(props) {
	const INTRO_TITLE = 'All of these words share the same base:';
	const audioConfig = {
		introAudio: KnowledgeForReading.WORD_FAMILY_INTRO,
		introVO: 'introVO',
		introVO1: 'introVO1'
	};
	const {setState} = useBatchedSetState();
	const history = useHistory();
	const dispatch = useDispatch();

	const {taskData, activityData, isZoneMenuModalOpen, isExitModalOpen, settings, ui} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			taskData: state.activity.taskData,
			isZoneMenuModalOpen: state.ui.isZoneMenuModalOpen,
			isExitModalOpen: state.ui.isExitModalOpen,
			settings: state.session.session.settings,
			ui: state.ui
		};
	});

	const contentData = taskData.content_data;

	const isActivity = activityData ? activityData.activityServerId === 'word_family' : false;

	let initialQuestionsList =
		contentData && contentData.word_cards.filter(i => i.word_card.word_text === taskData.power_word_focus)[0];

	const [pageType, setPageType] = useState('landing');
	const [submitStatus, updateSubmitStatus] = useState('valid');
	const [curentQuestionIndex, setQuestionIndex] = useState(0);
	const [isResetQuestion, setResetQuestion] = useState(false);
	const [correctResult, setCorrectResults] = useState([]);
	const [answeredChoice, setAnsweredChoice] = useState([]);
	const [trialsCount, setTrialsCount] = useState(0);
	const [isTrialMode, setTrialMode] = useState(true);
	const [screenViewIndex, setScreenViewIndex] = useState(0);
	const [isAnimPlayingScreen, setAnimPlayingScreen] = useState(true);
	const [isVoiceOverComplete, setVoiceOverComplete] = useState(false);
	const [isForwardDisabled, setForwardDisabled] = useState(true);

	const [introVO, setIntroVO] = useState(KnowledgeForReading.WORD_FAMILY_INTRO);
	const [questionsList, setQuestionsList] = useState(initialQuestionsList);
	const [correctCount, setCorrectCount] = useState(0);
	const [responseData, setResponseData] = useState([]);
	const [screenVO, setScreenVO] = useState(KnowledgeForReading.WORD_FAMILY_INTRO);
	const [instructionDisabled, setInstructionDisabled] = useState(true);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	useDeepCompareEffect(() => {
		const init = () => {
			// get all word-family questions and shuffle the answers/choices data
			let shuffleQuestionsList = contentData.word_cards.filter(
				i => displaySafe(i.word_card.word_text) === displaySafe(taskData.power_word_focus)
			)[0];

			let questions = shuffleQuestionsList.word_card.word_card_activities.filter(
				i => i.word_card_activity.activity_server_id === 'word_family'
			);

			questions = cloneDeep(questions);
			Object.keys(questions).forEach(item => {
				const shuffledAnswers = shuffle(questions[item].word_card_activity.answers);
				questions[item].word_card_activity.answers = shuffledAnswers;
			});

			shuffleQuestionsList = cloneDeep(shuffleQuestionsList);
			Object.keys(shuffleQuestionsList).forEach(question => {
				shuffleQuestionsList[question].word_card_activities = questions;
			});
			setQuestionsList(shuffleQuestionsList);
		};

		if (isEmpty(contentData) === false && isEmpty(taskData) === false && isActivity) {
			init();
		}
	}, [curentQuestionIndex, contentData, taskData]);

	const playVO = async (src, name) => {
		if (isActivity) {
			AudioPlayer2.stopAll();
			if (settings.autoPlayEnabled === true && ui.autoPlayedSounds.includes('word-family-' + taskData.power_word_focus) === false) {
				dispatch(uiSlice.actions.addAutoPlayedSound('word-family-' + taskData.power_word_focus));
				await sleep(1000);
				AudioPlayer2.load({
					src: src,
					name: name
				});
				await AudioPlayer2.playSync(name);
				await sleep(800);
			}
			setVoiceOverComplete(true);
		}
	};

	useEffect(() => {
		if (screenViewIndex === 0) {
			playVO(introVO, audioConfig.introVO);
		}
	}, [screenViewIndex]); // eslint-disable-line

	const updateAfterGuess = (correctCount, totalCount, responsesToServer) => {
		LanguageZone.sendProgressToServer(
			activityData,
			contentData.power_word_focus,
			correctCount,
			totalCount,
			responsesToServer
		);
	};

	const resetValues = () => {
		setState(() => {
			setCorrectResults([]);
			setAnsweredChoice([]);
			setQuestionIndex(0);
		});
	};

	const onSubmit = async () => {
		AudioPlayer2.stopAll();

		const questionsLength = questionsList.word_card.word_card_activities.filter(
			i => i.word_card_activity.activity_server_id === 'word_family'
		).length;

		let canRedirect = false;

		if (screenViewIndex < 2) {
			setState(() => {
				setForwardDisabled(true);
				updateSubmitStatus('submit');
			});
			const currentScreen = screenViewIndex + 1;
			setState(() => {
				setScreenViewIndex(currentScreen);
				setScreenVO(KnowledgeForReading.WORD_FAMILY_TRIAL_INTRO);
			});
			if (curentQuestionIndex === 0) {
				AudioPlayer2.play(KnowledgeForReading.WORD_FAMILY_TRIAL_INTRO);
			}
		}
		if (screenViewIndex === 2) {
			const allChoicesCorrect =
				correctResult.filter(i => i && i).length / questionsLength >= LanguageZone.COMPLETE_THRESHOLD;
			canRedirect =
				(allChoicesCorrect && submitStatus === 'valid') ||
				trialsCount === KnowledgeForReading.WORD_FAMILY_MAX_TRIALS;

			/* Redirect to WordCards page if all answers are correct or Maximum trails completed */
			if (canRedirect) {
				try {
					await LanguageZone.completeActivity(
						history,
						activityData,
						taskData.word_text,
						correctResult.filter(a => a).length,
						questionsLength
					);
				} catch (err) {
					dispatch(uiSlice.actions.setErrorMessage(err));
				}
				return;
			}
			if (!isTrialMode) {
				/* On Submit Or tryAgain  Reset  entered choices, questionIndex, correct List */
				resetValues();
				setState(() => {
					setPageType('landing');
					setForwardDisabled(true);
					updateSubmitStatus('submit');
					setTrialMode(true);
					setCorrectCount(0);
					setScreenVO(KnowledgeForReading.WORD_FAMILY_TRIAL_INTRO);
				});
			} else {
				/* Update the question Index on every Next Button  & if at last question Button naviagte to Result Page */
				if (curentQuestionIndex + 1 < questionsLength) {
					AudioPlayer2.stopAll();
					setQuestionIndex(curentQuestionIndex + 1);
				} else {
					updateSubmitStatus(
						allChoicesCorrect || trialsCount + 1 === KnowledgeForReading.WORD_FAMILY_MAX_TRIALS
							? 'valid'
							: 'invalid'
					);
					if (allChoicesCorrect) {
						if (correctResult.filter(i => i && i).length < questionsLength) {
							setScreenVO(KnowledgeForReading.WORD_FAMILY_ALMOST);
							AudioPlayer2.play(KnowledgeForReading.WORD_FAMILY_ALMOST);
						} else {
							setScreenVO(KnowledgeForReading.WORD_FAMILY_CORRECT);
							AudioPlayer2.play(KnowledgeForReading.WORD_FAMILY_CORRECT);
						}
					} else if (trialsCount === KnowledgeForReading.WORD_FAMILY_MAX_TRIALS) {
						setScreenVO(KnowledgeForReading.WORD_FAMILY_INCORRECT_FINAL);
						AudioPlayer2.play(KnowledgeForReading.WORD_FAMILY_INCORRECT_FINAL);
					} else {
						setScreenVO(KnowledgeForReading.WORD_FAMILY_TRY_AGAIN);
						AudioPlayer2.play(KnowledgeForReading.WORD_FAMILY_TRY_AGAIN);
					}
					correctResult.length === questionsLength && allChoicesCorrect
						? setTrialMode(true)
						: setTrialMode(false);
					setTrialsCount(trialsCount + 1);

					setPageType('results');
				}
			}

			if (correctResult.length !== questionsLength) {
				disableSubmit();
			}

			setResetQuestion(true);
		} else {
			canRedirect = false;
		}

		// find the answered choice's ID
		const question = questionsList.word_card.word_card_activities.filter(
			i => i.word_card_activity.activity_server_id === 'word_family'
		)[curentQuestionIndex];

		const answer = question.word_card_activity.answers.filter(a => {
			return a.answer_text === answeredChoice[curentQuestionIndex];
		})[0];

		if (isEmpty(answer)) {
			return;
		}

		if (answer.correct) {
			setCorrectCount(correctCount + 1);
		}

		const r = {
			answerId: answer.id,
			questionId: question.word_card_activity.id,
			isCorrect: answer.correct
		};
		let newResponseData = cloneDeep(responseData);
		newResponseData.push(r);

		setResponseData(newResponseData);

		updateAfterGuess(correctCount, questionsLength, newResponseData);
	};

	/* istanbul ignore next */
	const disableSubmit = () => updateSubmitStatus('initial');

	/* istanbul ignore next */
	const getHelpSound = () => {
		/* Results Screen dont have any Help VO, so returning empty string */
		if (pageType === 'results') {
			return '';
		}
		if (screenViewIndex < 2) {
			return KnowledgeForReading.WORD_FAMILY_STUDY_HELP;
		} else {
			return KnowledgeForReading.WORD_FAMILY_TRIAL_HELP;
		}
	};

	useIdleHelp(getHelpSound());

	return (
		<>
			<Navbar helpSoundUrl={getHelpSound()} />

			<ActivitySuspense requiredRenderData={contentData} title="Word Family">
				<ActivityFrame isWhiteBackground={true}>
					<ActivityInstructionButton audioSrc={screenVO} disabled={instructionDisabled} />
					<WordFamily
						questionsList={questionsList}
						correctResult={correctResult}
						answeredChoice={answeredChoice}
						updateSubmitStatus={updateSubmitStatus}
						curentQuestionIndex={curentQuestionIndex}
						setResetQuestion={setResetQuestion}
						isResetQuestion={isResetQuestion}
						pageType={pageType}
						setCorrectResults={setCorrectResults}
						setAnsweredChoice={setAnsweredChoice}
						stageDetails={activityData}
						introVO={introVO}
						screenViewIndex={screenViewIndex}
						commonQuestionTitle={INTRO_TITLE}
						isAnimPlayingScreen={isAnimPlayingScreen}
						isVoiceOverComplete={isVoiceOverComplete}
						setForwardDisabled={setForwardDisabled}
						setScreenViewIndex={setScreenViewIndex}
						setIntroVO={setIntroVO}
						setInstructionDisabled={setInstructionDisabled}
						isZoneMenuModalOpen={isZoneMenuModalOpen}
						isExitModalOpen={isExitModalOpen}
					/>
				</ActivityFrame>

				<Footer>
					<FooterForwardBack
						isForwardDisabled={isForwardDisabled}
						status={submitStatus}
						onForward={onSubmit}
						isBackVisible={false}
					/>
				</Footer>
			</ActivitySuspense>
		</>
	);
}
