import React from 'react';
import {makeStyles} from '@material-ui/core';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {keyAndClick} from '../../../util/ui-a11y';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';
import {MEDIA} from '@reading/r180/src/activities/SegmentSelection/SegmentSelection';

export const useStyles = makeStyles(theme => ({
	segmentImageContainer:{
		width: '260px',
		position: 'relative',
		marginRight: '16px',
		display: 'flex',
		justifyContent: 'center',
		borderRadius: '4px',
		overflow: 'hidden',
		'&.clickable': {
			cursor:'pointer',
		},
		transition: 'all 0.3s ease',
		'&:hover': {
			transform: 'scale(1.1)',
			boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
		}
	},
	segmentImageTitle:{
		position: 'absolute',
		bottom: '0px',
		left: '0',
		background: theme.colors.transBlack,
		color: theme.colors.white,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: window.innerWidth < 1199.9 ? '15px' : '18px',
		lineHeight: '40px',
		width: '100%',
		height: '40px'
	},
	segmentImage:{
		width: '260px',
		height: '168px',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundColor: theme.colors.grey,
	},
	segmentComplete: {
		position: 'absolute',
		height: '64px',
		width: '64px',
		borderRadius: '32px',
		top: `${(168-64)/2 - 16}px`,
		left: `50%`,
		transform: 'translateX(-50%)',
		background: theme.colors.cyan,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.6)',
	},
	offsetCheck: {
		position: 'absolute',
		height: '32px',
		width: '32px',
		borderRadius: '16px',
		background: theme.colors.white,
		border: `solid 1px ${theme.colors.cyan}`,
		top: '-8px',
		right: '-8px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.colors.cyan,
		fontFamily: 'r180',
	}
}));

const SegmentImageTile = (props) => {
	const { stage, segment, completedSegments, previewSegment } = props;
	const classes = useStyles();

	const mediaConstants = {
		stage: stage.toLowerCase(),
		segment: segment.segment_number.toString().padStart(2, '0')
	};

	const imgSrc = () =>
		`${MEDIA_SERVER_URL}${bulkReplace(MEDIA.segmentThumb, mediaConstants)}`;

	const completed = completedSegments.find(
		completedSegment => completedSegment.segmentName == segment.id
	);
	var isComplete = typeof completed !== 'undefined';

	const handleClick = (e) => {
		e.stopPropagation();
		if (!isComplete) {
			previewSegment(segment);
		}
	};

	//TODO: Find logic for levelHistory and offerChallenge

	return (
		<div
			className={`${classes.segmentImageContainer} ${isComplete === false ? 'clickable' : ''}`}
			{...keyAndClick(handleClick)}
			tabIndex={0}
			aria-label={segment.name}
			role={'button'}
		>
			<div
				style={{backgroundImage: `url(${imgSrc()})`}}
				className={classes.segmentImage}
			/>
			{completed && (
				<div className={classes.segmentComplete}>
					Level{' '}
					{completed.levelHistory[completed.levelHistory.length - 1]}
					<i className={`r180 check ${classes.offsetCheck}`} />
				</div>
			)}
			<div className={classes.segmentImageTitle}>{segment.name}</div>
		</div>
	);
};

export default React.memo(SegmentImageTile);
