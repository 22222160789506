import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { isFirefox } from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
	staticWrapper: {
		display: 'flex',
		'& .static-speaker-button': {
			position: 'relative',
			top: isFirefox() ? '1px' : '0.5px',
			left: '10px',
			color: theme.colors.slate,
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			},
			'&.playing': {
				color: theme.colors.readingZone.primary
			}
		},
		'&.root': {
			margin: 0
		},
		'& .title': {
			marginLeft: '25px',
			fontWeight: '700',
			fontFamily: theme.fonts.buttonBold
		}
	},
	description: {
		marginLeft: '44px',
		fontSize: '16px'
	}
}));

const StaticPanel = props => {
	const {text, title, audioPath, className, id} = props;

	const classes = useStyles({});

	useEffect(() => {
		AudioPlayer2.loadSound(audioPath);
	}, [audioPath]);

	const handleSpeaker = () => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(audioPath);
	};

	return (
		<>
			<div className={`${classes.staticWrapper} ${className} static-panel root`} id={id}>
				<button onClick={handleSpeaker} className={`static-speaker-button`}>
					<i className='r180 speaker-center' />
				</button>
				<div className={'title'}>{title}</div>
			</div>
			<div className={classes.description}>{text}</div>
		</>
	);
};

StaticPanel.defaultProps = {
    className: '',
	id: '',
	text: '',
	title: '',
	audioPath: ''
};
StaticPanel.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
	title: PropTypes.string,
	audioPath: PropTypes.string
};

export default React.memo(StaticPanel);
