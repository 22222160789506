import { makeStyles } from '@material-ui/styles';

export const useStyle = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		justifyContent: 'center'
	},
	normalPartsWrapper: {
		position: 'relative',
		margin: '0',
		textAlign: 'center',
		overflow: 'hidden',
		backgroundColor: props => props.normalColor,
		borderRadius: 4,
		userSelect: 'none',

		'& span': {
			fontSize: '32px',
			lineHeight: '48px',
			fontWeight: '400',
			color: theme.colors.successGreen,
			'&.correct': {
				color: theme.colors.successGreen,
			},
			'&.incorrect': {
				color: theme.colors.errorRed,
			}
		}
	},
	silentWrapper: {
		position: 'relative',
		margin: '0',
		textAlign: 'center',
		overflow: 'hidden',
		borderRadius: 4,
		padding: '0px 0px',
		backgroundColor: props => props.normalColor,
		userSelect: 'none',

		'& span': {
			fontFamily: theme.fonts.context,
			fontSize: '32px',
			lineHeight: '48px',
			fontWeight: '400',
			color: theme.colors.grey,
			letterSpacing: '1.9px',
			padding: '0px 0px'
		}
	},

	plusWrapper: {
		display: 'flex',
		flexShrink: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		textAlign: 'center',
		overflow: 'hidden',
		backgroundColor: '#d1d8e0',
		borderRadius: '50%',
		width: 20,
		height: 20,
		margin: '0px 4px',
		userSelect: 'none',

		'& span': {
			fontFamily: theme.fonts.context,
			fontSize: '18px',
			lineHeight: '20px',
			fontWeight: 'bold',
			color: '#4b6584',
			padding: '0px 0px'
		}
	},
	button: {
		padding: '4px 8px',
		backgroundColor: '#ff6600',
		cursor: 'pointer'
	},
	spriteWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: props => props.normalColor,
		borderRadius: 4,
		opacity: 0
	}
}));
