import React, { useState } from 'react';
import DndHolder from './DndHolder';
import PropTypes from 'prop-types';


const DropHolder = ({
	iterationIndex,
	words,
	name,
	destAudioMap,
	updatedLeftAudio,
	resultSet,
	activitySubmittedOnce,
	submitted,
	reset,
	wordMapAfterSubmit
}) => {
	return (
		<DndHolder
			dragEnabled={false}
			droppablePefix={'droppableLeft'}
			index={iterationIndex}
			draggablePrefix={'leftSideAudio'}
			data={words}
			name={name}
			isDroppable={true}
			resultSet={resultSet}
			submitted={submitted}
			destAudioMap={destAudioMap}
			activitySubmittedOnce={activitySubmittedOnce}
			updatedLeftAudio={updatedLeftAudio}
			reset={reset}
			wordMapAfterSubmit={wordMapAfterSubmit}
		>
		</DndHolder>
	)
};

DropHolder.defaultProps = {
	iterationIndex: 0,
	words: [],
	destAudioMap: {},
	isDroppable: true,
	dragEnabled: false,
	updatedLeftAudio: []
};
DropHolder.prototype = {
	dragEnabled: PropTypes.bool,
	isDroppable: PropTypes.bool,
	reset: PropTypes.bool,
	submitted: PropTypes.bool,
	activitySubmittedOnce: PropTypes.bool,
	index: PropTypes.string,
	droppablePefix: PropTypes.string,
	draggablePrefix: PropTypes.string,
	iterationIndex: PropTypes.string,
	words: PropTypes.array,
	destAudioMap: PropTypes.object,
	resultSet: PropTypes.object,
	wordMapAfterSubmit: PropTypes.object,
	data: PropTypes.array,
	name: PropTypes.string,
	updatedLeftAudio: PropTypes.array
};
export default DropHolder;
