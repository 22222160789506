import React from 'react';
import {ActivityInstructionButton, SoundButton} from '../../../index';
import {makeStyles} from '@material-ui/core';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import ClusterSounds from '../ClusterSounds/ClusterSounds';
import {keyAndClick} from '../../../util/ui-a11y';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';
import {MEDIA} from '@reading/r180/src/activities/SegmentSelection/SegmentSelection';
import {clusterStyles} from "@reading/r180/src/utils/constants";
import {MEDIA_ACTIVITY_ASSETS_URL_BASE} from '@reading/r180/src/utils/constants';

export const useStyles = makeStyles(theme=>({
	pictureContainer: {
		position: 'relative',
		minHeight: '412px',
		background: theme.colors.grey,
	},
	bigPicture: {
		width: '100%',
	},
	pictureOverlay: {
		width: '100%',
		height: 'auto',
		position: 'absolute',
		bottom: '0px',
		left: '0',
		background: theme.colors.transBlack,
		padding: '32px 64px 40px 96px'
	},
	overlayTitleRow: {
		fontSize: '60px',
		fontWeight: 'bold',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	titleSoundButton: {
		position: 'absolute',
		marginLeft: '-54px',
		background: 'none',
		color: theme.colors.white,
		border: 'none',
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.3)'
		}
	},
	overlayDescription: {
		fontWeight: 'bold',
		fontSize: '24px',
		letterSpacing: '0.15px',
		marginTop: '8px'
	},
	iconsButtonsRow: {
		marginTop: '48px',
		fontSize: '48px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	clusterIcons: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	clusterIcon: {
		background: theme.colors.white,
		color: theme.colors.black,
		width: '48px',
		height: '48px',
		borderRadius: '24px',
		fontSize: '32px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontFamily: 'r180',
		cursor: 'pointer',
		marginRight: '40px',
		'&:last-of-type': {
			marginRight: '0'
		},
		transition: '0.15s ease all',
		'&:hover': {
			transform: 'scale(1.2)'
		},
	},
	overlayButtons: {
		display: 'flex',
		flexDirection: 'row',
	},
	overlayButton: {
		height: '40px',
		width: '136px',
		borderRadius: '20px',
		background: theme.colors.white,
		color: theme.colors.black,
		padding: '12px 16px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginRight: '40px',
		fontFamily: theme.fonts.ui,
		fontSize: '16px',
		fontWeight: 'bold',
		transition: '0.15s ease all',
		border: '2px solid transparent',
		'&:hover:not(.disabled)': {
			transform: 'scale(1.2)'
		},
		'&:active:not(.disabled), &:focus:not(.disabled)': {
			boxShadow: `0 0 0 2px ${theme.colors.black}`,
			backgroundClip: 'padding-box',
		},
		textTransform: 'none',
		'&:last-of-type': {
			marginRight: '0'
		}
	},
	previewIcon: {
		fontFamily: 'r180',
		'&::before': {
			content:'"\\00E930"'
		}
	},
	submitIcon: {
		fontFamily: 'r180',
		'&::before': {
			content:'"\\00E909"'
		}
	},
	...clusterStyles

}))

const RecommendedSegment = (props) => {
	const {recommendedSegment, activityData, previewSegment, preSubmitSegment } = props;
	const classes = useStyles();

	const mediaConstants = {
		stage: activityData.stage.toLowerCase(),
		segment: recommendedSegment.segment_number.toString().padStart(2, '0')
	};

	const imgSrc = `${MEDIA_SERVER_URL}${bulkReplace(
		MEDIA.segmentImage,
		mediaConstants
	)}`;
	const recommendedSegmentSound = {
		name: recommendedSegment.id,
		src: `${MEDIA_SERVER_URL}${bulkReplace(
			MEDIA.segmentTitle,
			mediaConstants
		)}`
	};

	const playSounds = [];

	return (
		<div
			className={classes.pictureContainer}
			aria-label={'Recommended segment'}
			role={'region'}
		>
			<ActivityInstructionButton
				audioSrc={`${MEDIA_ACTIVITY_ASSETS_URL_BASE}segselect/r180u_segselect_intro.mp3`}
				white={true}
			/>
			<img className={classes.bigPicture} src={imgSrc} alt='banner' />
			<div className={classes.pictureOverlay}>
				<div className={classes.overlayTitleRow}>
					<SoundButton
						className={classes.titleSoundButton}
						size={48}
						sound={recommendedSegmentSound}
						aria-label={recommendedSegment.name}
					/>

					<div className={classes.overlayTitle} aria-label={'Title'}>
						{recommendedSegment.name}
					</div>
				</div>
				<div
					className={classes.overlayDescription}
					aria-label={'Description'}
				>
					{recommendedSegment.description}
				</div>
				<div className={classes.iconsButtonsRow}>
					<div
						className={classes.clusterIcons}
						aria-label={'Segment clusters'}
						role={'group'}
					>
						{recommendedSegment.clusters.map(cluster => {
							playSounds[cluster.id] = ClusterSounds(cluster);
							return (
								<div
									key={`clusterSound${cluster.id}`}
									size={48}
									className={`${classes.clusterIcon} ${
										classes[`cluster_${cluster.id}`]
									}`}
									alt={cluster.title}
									{...keyAndClick(playSounds[cluster.id])}
									tabIndex={0}
									aria-label={cluster.title}
									aria-role={'button'}
								/>
							);
						})}
					</div>
					<div className={classes.overlayButtons}>
						<button
							datatestid={'previewButton'}
							className={`${classes.overlayButton}`}
							{...keyAndClick(event => {
								event.stopPropagation();
								previewSegment(recommendedSegment);
							})}
						>
							Preview <i className="r180 preview" />
						</button>
						<button
							datatestid={'submitButton'}
							className={`${classes.overlayButton}`}
							{...keyAndClick(event => {
								event.stopPropagation();
								preSubmitSegment(recommendedSegment);
							})}
						>
							Begin <span className="r180 check" />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(RecommendedSegment);
