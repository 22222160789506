import React, {useEffect, useRef, useState} from 'react';
import {
	ActivitySupportButton,
	ImageMultipleChoice,
	ActivitySupportVideoButton,
	ActivityInstructionButton
} from '@reading/common';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {isEmpty, isEqual, shuffle} from 'lodash';
import {camelCaseToDefault, stripFileExtensions} from '../../../utils/stringUtils';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import ActivitySuspense from '../../../containers/App/ActivitySuspense';
import {KnowledgeForReading} from '../KnowledgeForReading';
import {useUnmount} from 'react-use';
import useIdleHelp from '../../../utils/useIdleHelp';
import ActivityFrame from '../../../containers/App/ActivityFrame';
import Navbar from '../../../containers/App/Navbar';
import Footer from '../../../containers/App/Footer';
import FooterForwardBack from '../../../containers/App/FooterForwardBack';
import {kfrSlice} from '../../../store/slices/kfr';
import useBatchedSetState from '../../../utils/useBatchedSetState';
import {uiSlice} from '../../../store/slices/ui';

export default function ImageMultipleChoiceActivity(props) {
	const history = useHistory();
	const dispatch = useDispatch();
	const startTime = useRef(new Date());
	const {setState} = useBatchedSetState();

	const contentData = history.location.state.taskActivity;
	const powerWordMapListData = history.location.state.powerWordMapData;

	const {bundledActivityData, powerWords, prevAnswer, settings, ui} = useSelector(state => {
		return {
			bundledActivityData: state.activity.bundledActivityData,
			powerWords: state.activity.powerwords,
			prevAnswer: state.kfr.kfrAnswer[contentData.id],
			settings: state.session.session.settings,
			ui: state.ui
		};
	});

	const isActivity = history.location.pathname.includes('image-choice');

	const [selected, setSelected] = useState(prevAnswer);
	const [status, setStatus] = useState(FooterForwardBack.INITIAL);
	const [answers, setAnswers] = useState([]);
	const [options, setOptions] = useState([]);
	const [attempts, setAttempts] = useState([]);
	const [interactive, setInteractive] = useState(true);

	// create the options, map the object to our components, randomize the array
	useEffect(() => {
		if (isEmpty(contentData) === false && isActivity) {
			const mapped = shuffle([...contentData.distractors, ...contentData.answers]).map(option => {
				return {
					id: option.id,
					src: option.text_url,
					title: camelCaseToDefault(stripFileExtensions(option.text)),
					status: ''
				};
			});

			const correctAnswers = contentData.answers.map(answer => {
				return answer.id;
			});

			setState(() => {
				setOptions(mapped);
				setAnswers(correctAnswers);
			});

			// if they selected an answer previously and didn't submit
			// the answer and are coming back to this question
			if (isEmpty(selected) === false) {
				setStatus(FooterForwardBack.SUBMIT);
			}

			AudioPlayer2.load({
				name: 'help_sound',
				src: contentData.help_url
			});
			AudioPlayer2.load({
				name: 'directions' + contentData.power_word_focus,
				src: contentData.stem_url
			});

			if (
				settings.autoPlayEnabled === true &&
				ui.autoPlayedSounds.includes('image-multiple-choice-' + contentData.power_word_focus) === false
			) {
				dispatch(uiSlice.actions.addAutoPlayedSound('image-multiple-choice-' + contentData.power_word_focus));
				AudioPlayer2.play('directions' + contentData.power_word_focus);
			}
		}
	}, [contentData]);

	/* istanbul ignore next */
	useUnmount(() => {
		AudioPlayer2.stopAll();
	});

	/* istanbul ignore next */
	const resetActivity = () => {
		setState(() => {
			setInteractive(true);
			setOptions(
				options.map(option => {
					option.status = '';
					return option;
				})
			);
			setSelected([]);
		});
	};

	const handleWordClick = e => {
		history.goBack();
	};

	/* istanbul ignore next */
	const updateAfterGuess = (isCorrect, answerId, isComplete) => {
		let attemptArray = attempts;
		attemptArray.push({
			correct: isCorrect,
			id: answerId,
			timestamp: new Date()
		});
		setAttempts(attemptArray);

		let datatoServer = {
			isComplete: isComplete,
			startTime: startTime.current,
			endTime: isComplete ? new Date() : ''
		};

		KnowledgeForReading.sendProgressToServer(
			datatoServer,
			attemptArray,
			contentData,
			bundledActivityData,
			powerWordMapListData,
			history,
			dispatch,
			powerWords
		);
	};

	const handleForward = e => {
		AudioPlayer2.stopAll();
		setInteractive(false);

		dispatch(
			kfrSlice.actions.storeKFRAnswer({
				id: contentData.id,
				answer: []
			})
		);

		const maxAttempts = KnowledgeForReading.MAX_ATTEMPTS;

		// they clicked Submit
		if (status === FooterForwardBack.SUBMIT) {
			// highlight the selected ones as correct or incorrect
			let newOptions = [...options];
			newOptions.forEach(option => {
				if (selected.includes(option.id)) {
					// it's included, now check if it was correct or incorrect
					if (answers.includes(option.id)) {
						option.status = 'correct';
					} else {
						option.status = 'incorrect';
					}
				}
			});

			setOptions(newOptions);

			// They got it correct
			if (isEqual(answers, selected)) {
				KnowledgeForReading.playCorrectSound();

				// update the attempts and the server
				updateAfterGuess(true, answers, true);

				setStatus(FooterForwardBack.VALID);
			}
			// They got it wrong again, but maxed out their attempts
			else if (attempts.length === maxAttempts - 1) {
				KnowledgeForReading.playIncorrectSound(attempts.length);

				// update the attempts and the server
				updateAfterGuess(false, selected[0], true);

				// show them the right answers
				let correctOptions = [...options];
				correctOptions.forEach(option => {
					if (answers.includes(option.id)) {
						option.status = 'correct';
					} else {
						option.status = '';
					}
				});
				setState(() => {
					setSelected([]);
					setOptions(correctOptions);
					setStatus(FooterForwardBack.VALID);
				});
			}
			// They got it wrong
			else {
				KnowledgeForReading.playIncorrectSound(attempts.length);

				// update the attempts and the server
				updateAfterGuess(false, selected[0], false);

				setStatus(FooterForwardBack.INVALID);
			}
		}
		// they clicked "Try Again"
		else if (status === FooterForwardBack.INVALID) {
			resetActivity();
			setStatus(FooterForwardBack.INITIAL);
		}
		// they got it right and are moving back to the Word Card
		else if (status === FooterForwardBack.VALID) {
			// this goBack() works in these "Tasks" only because the WordCard
			// will re-mount with the activityData stored in Redux.
			// This goBack() will not work in most other activities
			history.goBack();
		}
	};

	/* istanbul ignore next */
	const handleSelection = id => {
		let newSelected = [id];
		setState(() => {
			setSelected(newSelected);
			setStatus(FooterForwardBack.SUBMIT);
		});
		dispatch(
			kfrSlice.actions.storeKFRAnswer({
				id: contentData.id,
				answer: newSelected
			})
		);
	};

	useIdleHelp(contentData ? contentData.help_url : '');

	return (
		<>
			<Navbar helpSoundUrl={contentData ? contentData.help_url : ''} />

			<ActivitySuspense requiredRenderData={contentData}>
				<ActivityFrame isWhiteBackground={true}>
					<ActivityInstructionButton audioName={'directions' + contentData.power_word_focus} />
					<ImageMultipleChoice
						interactive={interactive}
						word={contentData.power_word_focus}
						wordUrl={contentData.power_word_focus_url}
						instructions={contentData.stem}
						instructionsUrl={contentData.stem_url}
						options={options}
						onSelection={handleSelection}
						value={selected}
					/>
				</ActivityFrame>

				<Footer>
					<ActivitySupportButton
						disabled={status === FooterForwardBack.VALID || status === FooterForwardBack.INVALID}
						icon="word"
						onClick={handleWordClick}
						text="Word Card"
					/>
					<ActivitySupportVideoButton
						disabled={status === FooterForwardBack.VALID || status === FooterForwardBack.INVALID}
						video_hint={contentData.video_hint}
					/>
					<FooterForwardBack status={status} onForward={handleForward} isBackVisible={false} />
				</Footer>
			</ActivitySuspense>
		</>
	);
}
