import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import { cloneDeep, shuffle } from 'lodash';
import { bulkReplace } from '../../../utils/stringUtils';
import {loadSleep} from '../../../utils/ui';
import {ReadAndThinkConstants} from './ReadAndThinkConstants';

export const ReadAndThinkUtil = {

	initialize: async params => {
		const {contentData} = params;

		const start = new Date().getTime();

		// re-format the contentData to data we can use in the UI
		let questionData = cloneDeep(contentData);
		questionData.rounds.forEach((round) => {
			 let answers = [];
			 answers.push({
				text: round.correct_answer,
				isCorrect: true,
				status: ''
			 });
			 answers.push({
				text: round.distractors[0],
				isCorrect: false,
				status: ''
			 });
			 answers.push({
				text: round.distractors[1],
				isCorrect: false,
				status: ''
			 });
			 round.answers = shuffle(answers);
		});

		// load the SFX
		AudioPlayer2.loadSound(ReadAndThinkConstants.COVER_UP);
		AudioPlayer2.loadSound(ReadAndThinkConstants.COVER_OFF);

		// load the sentences
		questionData.rounds.forEach((round) => {
			const idName = round.id.split('_');
			const questionCode = idName[1] + idName[3];
			const num = idName[6];
			round.src = bulkReplace(ReadAndThinkConstants.QUESTION, {questionCode, num});
			AudioPlayer2.load({name: round.id, src: bulkReplace(ReadAndThinkConstants.QUESTION, {questionCode, num})});
		});

		const end = new Date().getTime();
		await loadSleep(end - start);

		return { questionData };
	},
};
