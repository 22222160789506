import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ToolTipAnimation from './ToolTipAnimation';
import WordAnimText from '../../components/WordAnimText';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
	textAnim: {
		display: 'inline-block',
		minHeight: '55px'
	},
	choiceBox: {
		width: 'auto',
		marginBottom: '10px',
		marginLeft: '12px'
	},
	animation: {position: 'relative'}
}));

const WordAnimationHolder = props => {
	const {
		text,
		selected,
		index,
		item,
		showDescription,
		choicesData,
		showModal,
		setShowModal,
		handleAnimationComplete,
		isAnimPlayingScreen,
		highlightedWordList,
		setForwardDisabled,
		setScreenViewIndex,
		setInstructionDisabled,
		screenViewIndex,
		exitModalActive
	} = props;
	const classes = useStyles();
	const {setState} = useBatchedSetState();

	const [isShowingAnimation, setShowingAnimation] = useState(true);

	const onAnimateEnd = () => {
		setState(() => {
			showModal[index] = false;
			setShowModal([...showModal]);
			setShowingAnimation(false);
			/* istanbul ignore else */
			if (index === 2) {
				setScreenViewIndex(1);
				setForwardDisabled(false);
				setInstructionDisabled(false);
			}
			/* istanbul ignore else */
			if (isAnimPlayingScreen) {
				handleAnimationComplete();
			}
		});
	};

	return (
		<div className={`${classes.textAnim}`}>
			{showModal[index] && !exitModalActive ? (
				<div className={classes.animation}>
					<div>
						<ToolTipAnimation
							item={item}
							showDescription={showDescription}
							choicesData={choicesData}
							onAnimateEnd={onAnimateEnd}
							isShowingAnimation={isShowingAnimation}
							setForwardDisabled={setForwardDisabled}
							screenViewIndex={screenViewIndex}
						/>
					</div>
				</div>
			) : (
				<div className={classes.animation}>
					<WordAnimText
						className={classes.choiceBox}
						border
						dragging={false}
						selected={selected}
						text={text}
						index={index}
						highlightedWordList={highlightedWordList}
					/>
				</div>
			)}
		</div>
	);
};

WordAnimationHolder.defaultProps = {
	text: '',
	icon: '',
	selected: false,
	index: 0,
	item: '',
	showDescription: false,
	choicesData: {},
	showModal: [],
	isAnimPlayingScreen: true
};
WordAnimationHolder.propTypes = {
	text: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	index: PropTypes.number,
	item: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	choicesData: PropTypes.object,
	showModal: PropTypes.array,
	showDescription: PropTypes.bool,
	icon: PropTypes.string,
	isAnimPlayingScreen: PropTypes.bool
};

export default React.memo(WordAnimationHolder);
