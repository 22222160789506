import React from 'react';
import PropTypes from 'prop-types';
import ProgressCircle from './ProgressCircle';
import WordNSpeakerRow from './WordNSpeakerRow';
import { useStyle } from './MiniWordCard.style';
import { KnowledgeForReading } from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import useRouteInfo from '@reading/r180/src/utils/useRouteInfo';
import HoverTip from '../HoverTip/HoverTip';

const MiniWordCard = props => {
	const { id, label, progress, maxProgress, onEnterClick, isCompleted, className } = props;

	const classes = useStyle();

	const { zone } = useRouteInfo();

	const handleClick = () => {
		KnowledgeForReading.playClickSound();
		onEnterClick();
	};

	const buttonIcon = isCompleted ? 'check' : 'more';

	return (
		<div
			id={id}
			className={`${classes.wrapper} ${className} mini-word-card animate__animated animate__fadeIn`}
		>
			<div className={classes.headerWrapper}> </div>
			<div className={classes.contentWrapper}>
				<WordNSpeakerRow label={label} wordText={label} />
			</div>
			<div className={classes.footerWrapper}>
				<div className={classes.masterProgressAreaWrapper}>
					{(zone === 'explore' || zone === 'language') && (
						<>
							<div className={classes.progressDetailsWrapper}>
								{progress} of {maxProgress}
							</div>
							<ProgressCircle
								max={maxProgress}
								progress={progress}
							/>
						</>
					)}
				</div>
			</div>
			<HoverTip title="Click to choose this Word Card">
				<button
					className={`${classes.enterButton} ${isCompleted ? 'isCompleted' : ''}`}
					onClick={handleClick}
					data-testid="enter-button"
				>
					<i className={`r180 ${buttonIcon}`} />
				</button>
			</HoverTip>
		</div>
	);
};

MiniWordCard.defaultProps = {
	id: '',
	className: ''
};

MiniWordCard.propTypes = {
	id: PropTypes.string.isRequired,
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	progress: PropTypes.number.isRequired,
	maxProgress: PropTypes.number.isRequired,
	onEnterClick: PropTypes.func.isRequired,
	isCompleted: PropTypes.bool.isRequired
};

export default React.memo(MiniWordCard);
