import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import ActivityDirections from '../../components/ActivityDirections';
import { MEDIA_SERVER_URL } from '@reading/r180/src/utils/constants';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { getAudioExt } from '@reading/r180/src/utils/audio';

const EXT = getAudioExt();

export const useStyles = makeStyles(theme => ({
	tiredWordsListWrapper: {
		display: 'flex',
		alignItems: 'center',
		borderBottom: '1px solid',
		borderBottomColor: theme.colors.lightGrey,
		'&:hover': {
			backgroundColor: theme.colors.fluencyZone.blue5,
			cursor: 'pointer',
			fontWeight: '600'
		},
		'& span': {
			fontWeight: '400',
			fontSize: '18px',
			fontFamily: theme.fonts.context,
			lineHeight: '32px',
			color: theme.colors.black
		},
		'& i': {
			color: theme.colors.slate,
			top: '3px'
		},
	}
}));

const TiredWordsPopover = props => {
	const { writingToolsTiredWordsObj, onTiredWordSwap, onTiredWordsClose, onTiredWordClick } = props;

	const [anchorEl, setAnchorEl] = useState(null);
	const [tiredWordsReplacement, setTiredWordsReplacement] = useState([]);
	const [selectedTiredWordId, setSelectedTiredWordId] = useState({});

	const classes = useStyles();

	const handleTiredWordSwap = (swapWord) => {
		setAnchorEl(null);
		if (typeof onTiredWordSwap === 'function') {
			onTiredWordSwap(selectedTiredWordId, swapWord);
		}
	}

	const handleTiredWordsClick = (event) => {
		const selectedTiredWord = event.currentTarget.innerHTML.trim().toLowerCase();
		setSelectedTiredWordId(event.currentTarget.id);
		var selectedSpan = document.getElementById(event.currentTarget.id);
		selectedSpan.style.fontWeight = "bold";
		if (writingToolsTiredWordsObj[selectedTiredWord]) {
			setTiredWordsReplacement(writingToolsTiredWordsObj[selectedTiredWord].replacements);
			writingToolsTiredWordsObj[selectedTiredWord].replacements.forEach(item => {
				AudioPlayer2.loadSound(`${MEDIA_SERVER_URL}/assets/dictio/word/r180u_word_${item}.${EXT}`);
			})
		}
		setAnchorEl(anchorEl ? null : event.currentTarget);
		if (typeof onTiredWordClick === 'function') {
			onTiredWordClick(event);
		}
	};

	const handleTiredWordsClose = () => {
		setAnchorEl(null);
		var selectedSpan = document.getElementById(selectedTiredWordId);
		selectedSpan.style.fontWeight = "normal";
		if (typeof onTiredWordsClose === 'function') {
			onTiredWordsClose(selectedTiredWordId, tiredWordsReplacement);
		}
	};

	useEffect(() => {
		const addListener = async () => {
			const tiredWordsClass = document.getElementsByClassName('tiredWords');
			if (tiredWordsClass.length > 0) {
				[...tiredWordsClass].forEach(element => {
					element.addEventListener('click', handleTiredWordsClick);
				});
			}
		};
		addListener();
		return () => {
			const tiredWordsClass = document.getElementsByClassName('tiredWords');
			if (tiredWordsClass.length > 0) {
				[...tiredWordsClass].forEach(element => {
					element.removeEventListener('click', handleTiredWordsClick);
				});
			}
		};
	});

	const tiredWordsOpen = Boolean(anchorEl);
	const tiredWordsOpenId = tiredWordsOpen ? 'simple-popper' : undefined;

	return (
		<>
			<Popover
				data-testid={'popover'}
				id={tiredWordsOpenId}
				open={tiredWordsOpen}
				anchorEl={anchorEl}
				onClose={handleTiredWordsClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
			>
				{tiredWordsReplacement.map((word, idx) => (
					<div key={idx} className={classes.tiredWordsListWrapper} onClick={() => handleTiredWordSwap(word)}>
						<ActivityDirections
							style={{
								textAlign: 'left',
								width: 'auto',
								margin: '0px 12px',
								display: 'flex',
								alignItems: 'center',
								height: '48px',
								minWidth: '166px'
							}}
							key={word}
							text={word}
							src={`${MEDIA_SERVER_URL}/assets/dictio/word/r180u_word_${word}.${EXT}`} />
					</div>
				))
				}

			</Popover>
		</>
	);
};

TiredWordsPopover.defaultProps = {
	writingToolsTiredWordsObj: {}
};
TiredWordsPopover.propTypes = {
	writingToolsTiredWordsObj: PropTypes.object.isRequired,
	onTiredWordSwap: PropTypes.func.isRequired,
	onTiredWordsClose: PropTypes.func,
	onTiredWordClick: PropTypes.func,
};

export default React.memo(TiredWordsPopover);
