import { cloneDeep, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import media from '../../api/media';
import { getContentInfo } from '../../media/mediaUtil';
import { uiSlice } from '../../store/slices/ui';
import {
	AcronymBanner,
	ActivityButton,
	CaptionedAudioPlayer2 as AudioPlayer2,
	WritingAcronymBoard,
	VideoModal,
	ReviewLayout,
	WritingTools,
	ActivityInstructionButton
} from '@reading/common';
import useIdleHelp from '../../utils/useIdleHelp';
import Navbar from '../../containers/App/Navbar';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import { Writing } from './Writing';
import { makeStyles } from '@material-ui/core';
import { WritingPanelContainer } from '@reading/common';
import { WritingNotes, WritingNarratives, NotesPad } from '@reading/common';
import { Scratchpad } from '@reading/common';
import { PassageWindow } from '@reading/common';
import { WordCardModal } from '@reading/common';
import { StaticPanel } from '@reading/common';
import { ReviewPanel } from '@reading/common';
import { bulkReplace, displaySafe } from '../../utils/stringUtils';
import { KnowledgeForReading } from '../WordCard/KnowledgeForReading';
import useBatchedSetState from '../../utils/useBatchedSetState';
import { sleep } from '../../utils/sleep';

export const useStyles = makeStyles(theme => ({
	'@keyframes goLeft': {
		'0%': {
			left: '0'
		},
		'100%': {
			left: '-168px'
		}
	},
	wrapper: {
		display: 'flex',
		alignItems: 'flex-start',
		padding: '0 24px'
	},
	narrativeContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		'& .writing-acronym-board': {
			marginBottom: '60px'
		}
	},
	acronymContainer: {
		position: 'relative',
		display: 'flex',
		height: '100%',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center'
	},

	narrativeContent: {
		display: 'flex',
		columnGap: '12px',

		'& .writing-panel-container': {
			width: '648px',
			'&.animating': {
				animation: '$goLeft 0.5s ease'
			}
		}
	},
	writingPanelWithWritingTools: {
		width: '512px',
		marginRight: '10px'
	}
}));

export const segments = ['acronym', 'writing-notes', 'writing-narrative', 'writing-review', 'writing-rating', 'layout'];

const WritingActivityNarrative = props => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { path: wzPath } = useRouteMatch();
	const { setState } = useBatchedSetState();

	const { activityData, settings, user } = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			settings: state.session.session.settings,
			user: state.session.session.user
		};
	}, shallowEqual);

	// check if the activity is already charged
	let isActivityCharged = useSelector(state => {
		return state.ui.chargedActivities['writing'] === true;
	});

	const isAutoPlay = settings && settings.autoPlayEnabled;

	const [contentData, setContentData] = useState({});
	const [renderData, setRenderData] = useState({});
	const [isPassageVisible, setPassageVisible] = useState(false);
	const [isScrachPadVisible, setScratchPadVisible] = useState(false);
	const [selectedPowerWord, setSelectedPowerWord] = useState({});
	const [wordCards, setWordCards] = useState([]);
	const [wordCardMap, setWordCardMap] = useState({});
	const [selectedWordCard, setSelectedWordCard] = useState({});
	const [powerWords, setPowerWords] = useState([]);
	const [videoInfo, setVideoInfo] = useState({});
	const [isVideoVisible, setVideoVisible] = useState(false);
	const [uniqueIDMap, setUniqueIDMap] = useState({});
	const [helpVO, setHelpVO] = useState(Writing.HELP);
	const [completedNotes, setCompletedNotes] = useState({});
	const [completedNarratives, setCompletedNarratives] = useState([]);
	const [currentSegment, setCurrentSegment] = useState('acronym');
	const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
	const [acronymIndex, setAcronymIndex] = useState(0);
	const [disableGoOn, setDisableGoOn] = useState(true);
	const [showLoader, setShowLoader] = useState(false);
	const [paragraphs, setParagraphs] = useState([]);

	const [writingToolsSpellCheck, setWritingToolsSpellCheck] = useState({});
	const [writingToolsTiredWords, setWritingToolsTiredWords] = useState({});
	const [isSpellingCheckEnabled, setSpellingCheckEnabled] = useState(false);
	const [isTiredWordsEnabled, setTiredWordsEnabled] = useState(false);
	const [isSentenceSenseEnabled, setSentenceSenseEnabled] = useState(false);
	const [showWritingTools, setShowWritingTools] = useState(false);
	const [writingToolsInfo, setWritingToolsInfo] = useState({});
	const [reviewLayoutInfo, setReviewLayoutInfo] = useState({});
	const [activityState, setActivityState] = useState(Writing.ACTIVITY_STATE.INITIAL);
	const [isAnimatingReview, setAnimatingReview] = useState(false);
	const [scratchText, setScratchText] = useState('');

	const isReviewLayout = segments[currentSegmentIndex] === 'layout';
	const loaderTitle = isReviewLayout ? Writing.PUBLISH_LOADER_TITLE : Writing.PROGRESS_LOADER_TITLE;
	const loaderMessage = isReviewLayout ? 'Sweet!' : 'One Second';

	useDeepCompareEffect(() => {
		const loadMedia = async () => {
			const activityName = Writing.getActivityName(wzPath);
			const _activityData = cloneDeep(activityData);
			_activityData.activityServerId = activityName;

			const { urlPath } = getContentInfo(_activityData, true);

			if (urlPath !== '/') {
				const mediaData = await media.getOne(urlPath);
				setContentData(mediaData);
			}
		};

		if (isEmpty(activityData) === false) {
			loadMedia();
		}
	}, [activityData]);

	useDeepCompareEffect(() => {
		const initialize = async () => {
			const {
				wordCards,
				newWordCardMap,
				renderData,
				videoUrl,
				videoCaptionInfo,
				choiceSelected,
				writingToolsSpellCheckObj,
				writingToolsTiredWordsObj,
				passageText
			} = await Writing.init(contentData, settings, isActivityCharged);

			isActivityCharged = true;
			dispatch(uiSlice.actions.setActivityCharged('writing'));

			//////////
			// Create the data object needed for the 'Power Words' dropdown
			//////////
			const challengeWords = Object.keys(newWordCardMap).map(word => {
				return {
					word: displaySafe(word),
					sound_name: word,
					sound_src: bulkReplace(KnowledgeForReading.WORD_SOUND_URL, {
						word: word,
						asset_id: Writing.getAssetId(contentData.contentId, false)
					})
				};
			});

			setState(() => {
				setWordCards(wordCards);
				setWordCardMap(newWordCardMap);
				setRenderData(renderData);
				setVideoInfo({ videoUrl, videoCaptionInfo });
				setParagraphs(passageText);
				setHelpVO(renderData.type ? Writing.SOUNDS[renderData.type].HELP : Writing.HELP);
			});

			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				await AudioPlayer2.playSync(Writing.acronymBoard.narrative.name);
			}

			setState(() => {
				setDisableGoOn(false);
				setActivityState(Writing.ACTIVITY_STATE.SELECTOR);
				setWritingToolsSpellCheck(writingToolsSpellCheckObj);
				setWritingToolsTiredWords(writingToolsTiredWordsObj);
				setPowerWords(challengeWords);
			});
		};

		if (isEmpty(contentData) === false) {
			initialize();
		}

		/* eslint-disable */
	}, [contentData]);

	// stop any sounds
	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	/* istanbul ignore next */
	const handleReviewPanelEdit = reviewText => {
		AudioPlayer2.stopAll();
		let newWritingToolsInfo = cloneDeep(writingToolsInfo);
		newWritingToolsInfo['reviewText'] = reviewText;
		setWritingToolsInfo(newWritingToolsInfo);
	};

	/* istanbul ignore next */
	const handleStarsClicked = (stars, text) => {
		AudioPlayer2.stopAll();
		let newWritingToolsInfo = cloneDeep(writingToolsInfo);
		newWritingToolsInfo[text] = stars;
		setWritingToolsInfo(newWritingToolsInfo);
	};

	const handleWritingToolsCompletion = () => {
		AudioPlayer2.stopAll();
		setState(() => {
			setDisableGoOn(false);
			setActivityState(Writing.ACTIVITY_STATE.PUBLISHING);
		});
	};

	/* istanbul ignore next */
	const handleOnOffButtonClicked = data => {
		if (data.text === 'Spelling' && data.value) {
			setSpellingCheckEnabled(true);
		} else {
			setSpellingCheckEnabled(false);
		}
		if (data.text === 'Tired Words' && data.value) {
			setTiredWordsEnabled(true);
		} else {
			setTiredWordsEnabled(false);
		}

		if (data.text === 'Sentence Sense' && data.value) {
			setSentenceSenseEnabled(true);
		} else {
			setSentenceSenseEnabled(false);
		}
	};

	/* istanbul ignore next */
	const handleInstructions = () => {
		AudioPlayer2.stopAll();
		if (currentSegment === 'writing-notes') {
			AudioPlayer2.play('notes_intro');
		} else if (currentSegment === 'writing-narrative') {
			AudioPlayer2.play('narrative_beginning');
		} else if (currentSegment === 'writing-review') {
			AudioPlayer2.play('narrative_revise');
		} else if (currentSegment === 'writing-rating') {
			AudioPlayer2.play('narrative_rating');
		} else if (currentSegment === 'layout') {
			AudioPlayer2.play('narrative_publish');
		}
	};

	/* istanbul ignore next */
	const handlePassageClick = () => {
		setState(() => {
			setScratchPadVisible(false);
			setPassageVisible(true);
		});
	};

	/* istanbul ignore next */
	const handlePassageClose = () => {
		setPassageVisible(false);
	};

	/* istanbul ignore next */
	const handleScratchPadClick = () => {
		setScratchPadVisible(true);
	};

	/* istanbul ignore next */
	const handleScratchPadClose = text => {
		setScratchPadVisible(false);
	};

	/* istanbul ignore next */
	const handleScratchChange = text => {
		setScratchText(text);
	};

	const handlePowerWordsClick = word => {
		AudioPlayer2.stopAll();
		const word_card = wordCards.filter(card => {
			return card.word_card.word_text === word;
		})[0];
		setState(() => {
			setSelectedWordCard(word_card);
			setSelectedPowerWord(word);
			setScratchPadVisible(false);
		});
	};

	/* istanbul ignore next */
	const handleWordCardClose = () => {
		AudioPlayer2.stopAll();
		setState(() => {
			setSelectedWordCard({});
			setSelectedPowerWord('');
		});
	};

	/* istanbul ignore next */
	const handleVideoClick = () => {
		AudioPlayer2.stopAll();
		setState(() => {
			setScratchPadVisible(false);
			setVideoVisible(true);
		});
	};

	/* istanbul ignore next */
	const handleVideoModalClose = () => {
		setVideoVisible(false);
	};

	const handleNotesSectionCompleted = (section, nextIndex) => {
		setAcronymIndex(nextIndex);
	};

	const handleNarrativesSectionCompleted = (section, nextIndex) => {
		setAcronymIndex(nextIndex);
	};

	const handleNotesCompleted = notes => {
		setState(() => {
			setCompletedNotes(notes);
			setActivityState(Writing.ACTIVITY_STATE.EDITING);
			setDisableGoOn(false);
		});
	};

	const handleNarrativesCompleted = narratives => {
		setState(() => {
			setCompletedNarratives(narratives);
			setActivityState(Writing.ACTIVITY_STATE.REVISION);
			setDisableGoOn(false);
		});
	};

	const handleReviewLayoutComplete = (reviewText, layout, headerText, headerImage) => {
		setState(() => {
			setReviewLayoutInfo({ reviewText, layout, headerText, headerImage });
			setDisableGoOn(false);
		});
	};

	const handlePublish = async e => {
		AudioPlayer2.stopAll();
		setShowLoader(true);
		if (settings.autoPlayEnabled) {
			await AudioPlayer2.playSync(Writing.WRITING_PUBLISH_LOADER);
		}
		Writing.publishToServer(activityData, 'WZ_PublishInfo', 1, reviewLayoutInfo);
		try {
			await Writing.completeActivity(history, activityData);
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
		setShowLoader(false);
	};

	const handleGoOn = async () => {
		AudioPlayer2.stopAll();

		const nextIndex = currentSegmentIndex + 1;

		if (segments[nextIndex] !== 'writing-notes') {
			setActivityState(Writing.ACTIVITY_STATE.PLANNING_DRAFTING);
		}

		if (segments[nextIndex] !== 'writing-review') {
			setDisableGoOn(true);
		}

		if (segments[nextIndex] === 'writing-review') {
			setShowLoader(true);
			AudioPlayer2.loadSound(Writing.WRITING_REVIEW_LOADER);
			if (settings.autoPlayEnabled) {
				await AudioPlayer2.playSync(Writing.WRITING_REVIEW_LOADER);
				await sleep(1000);
			}
			setShowLoader(false);
		}

		if (segments[nextIndex] === 'writing-rating') {
			setAnimatingReview(true);
			await sleep(500);
		}

		if (segments[currentSegmentIndex] === 'layout') {
			if (settings.autoPlayEnabled) {
				await AudioPlayer2.playSync(Writing.WRITING_PUBLISH_LOADER);
			}
		}

		setState(() => {
			//setAnimatingReview(false);
			setCurrentSegmentIndex(nextIndex);
			setCurrentSegment(segments[nextIndex]);
			setAcronymIndex(0);
		});

		window.scrollTo(0, 0);
	};

	const SwitchDisplay = ({ segment = [], children }) => {
		const segmentArr = Array.isArray(segment) ? segment : [segment];

		if (segmentArr.includes(currentSegment)) {
			return children;
		}
		return null;
	};

	const handleExitActivity = isExit => {
		if (isExit) {
			Writing.sendProgressToServer(false, activityState, activityData.studentActivityId);
		}
	};

	useIdleHelp(Writing.SOUNDS['narrative'].HELP);

	return (
		<>
			<Navbar helpSoundUrl={helpVO} onZoneMenuClosed={handleExitActivity} onExitAppClosed={handleExitActivity} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false || showLoader}
				requiredRenderData={[contentData, renderData]}
				title={showLoader ? loaderTitle : Writing.SCREEN_LOADER_TITLE}
				message={showLoader ? loaderMessage : ''}
			>
				<div className={classes.wrapper}>
					<SwitchDisplay segment={['writing-notes', 'writing-narrative']}>
						<AcronymBanner
							acronym={Writing.acronymMap['narrative'].acronym}
							letterIndex={acronymIndex}
							audioPaths={Writing.acronymBoard.info[contentData.type]}
						/>
					</SwitchDisplay>

					<div className={classes.narrativeContainer}>
						<SwitchDisplay segment="acronym">
							<ActivityInstructionButton
								audioName={'acronym_narrative'}
								white={true}
								withoutFrame={true}
								style={{ top: '180px', left: '180px' }}
							/>
							<WritingAcronymBoard
								abbreviations={Writing.acronymMap['narrative'].abbreviations}
								introAudio={Writing.acronymBoard['narrative'].name}
								acronym={Writing.acronymMap['narrative'].acronym}
							/>
						</SwitchDisplay>

						{currentSegment.includes('writing') && (
							<div className={`${classes.narrativeContent}`}>
								<WritingPanelContainer
									className={`${isAnimatingReview ? 'animating' : ''}`}
									onInstructionClick={handleInstructions}
									onPassageClick={handlePassageClick}
									onScratchPadClick={handleScratchPadClick}
									onPowerWordsClick={handlePowerWordsClick}
									onVideoClick={handleVideoClick}
									powerWords={powerWords}
									showDraftPanel={
										currentSegment === 'writing-review' || currentSegment === 'writing-rating'
									}
									choiceSelected={2}
									narrow={isAnimatingReview || currentSegment === 'writing-rating'}
									key={'WritingPanelWrapper'}
								>
									<SwitchDisplay segment={['writing-notes', 'writing-narative']}>
										<StaticPanel
											id={'writing-prompt'}
											text={renderData.question}
											title={'Writing Prompt'}
											audioPath={Writing.getWritingPrompt(activityData, 'narrative')}
										/>
									</SwitchDisplay>

									{currentSegment === 'writing-notes' && (
										<WritingNotes
											sections={renderData.bme}
											onNotesCompleted={handleNotesCompleted}
											onSectionCompleted={handleNotesSectionCompleted}
											isAutoPlay={isAutoPlay}
										/>
									)}

									{currentSegment === 'writing-narrative' && (
										<WritingNarratives
											onSectionCompleted={handleNarrativesSectionCompleted}
											onNarrativesCompleted={handleNarrativesCompleted}
											isAutoPlay={isAutoPlay}
										/>
									)}

									{(currentSegment === 'writing-review' || currentSegment === 'writing-rating') && (
										<ReviewPanel
											uniqueIDMap={uniqueIDMap}
											completedPanelData={completedNarratives}
											mode={contentData.type}
											writingToolsSpellCheckObj={writingToolsSpellCheck}
											writingToolsTiredWordsObj={writingToolsTiredWords}
											spellCheckEnabled={isSpellingCheckEnabled}
											tiredWordsEnabled={isTiredWordsEnabled}
											sentenceSenseEnabled={isSentenceSenseEnabled}
											isWritingToolActive={showWritingTools}
											onReviewPanelEdit={handleReviewPanelEdit}
										/>
									)}
								</WritingPanelContainer>

								{currentSegment === 'writing-narrative' && (
									<NotesPad activeIndex={acronymIndex} notes={completedNotes} />
								)}

								{currentSegment === 'writing-rating' && (
									<div>
										<WritingTools
											onStarsClicked={handleStarsClicked}
											onOffButtonClicked={handleOnOffButtonClicked}
											onCompletion={handleWritingToolsCompletion}
											autoPlay={settings.autoPlayEnabled}
										/>
									</div>
								)}

								{isPassageVisible && (
									<PassageWindow
										isOpen={isPassageVisible}
										onModalClose={handlePassageClose}
										title="Test Title"
										paragraphs={paragraphs}
									/>
								)}

								{isScrachPadVisible && (
									<Scratchpad
										onModalClose={handleScratchPadClose}
										scratchText={scratchText}
										onScratchChange={handleScratchChange}
									/>
								)}

								{isEmpty(selectedWordCard) === false && (
									<WordCardModal
										isOpen={isEmpty(selectedWordCard) === false}
										onModalClose={handleWordCardClose}
										wordCardData={wordCardMap[selectedPowerWord]}
										card={selectedWordCard}
										kill={false}
										taskData={[]}
										powerWordData={selectedWordCard}
										videoSrcURL={videoInfo.videoUrl}
									/>
								)}

								{isVideoVisible && (
									<VideoModal
										isOpen={isVideoVisible}
										onModalClose={handleVideoModalClose}
										videoSrcURL={videoInfo.videoUrl}
										videoTracks={videoInfo.videoCaptionInfo}
										start_time={0}
										end_time={0}
										hideCaptionBtn={false}
										hideReplayBtn={false}
									/>
								)}
							</div>
						)}

						{currentSegment === 'layout' && (
							<ReviewLayout
								reviewText={writingToolsInfo.reviewText}
								mode={contentData.type}
								images={Writing.getReviewLayoutImages(activityData, contentData)}
								author={user && user.firstName ? `${user.firstName} ${user.lastName}` : ''}
								onReviewLayoutComplete={handleReviewLayoutComplete}
								onPublish={handlePublish}
								autoPlay={settings.autoPlayEnabled}
							/>
						)}

						{segments[currentSegmentIndex] !== 'layout' && (
							<Footer>
								<div style={{ float: 'right' }}>
									<ActivityButton
										icon="arrow-right"
										onClick={handleGoOn}
										text="Go On"
										disabled={disableGoOn}
									/>
								</div>
							</Footer>
						)}
					</div>
				</div>
			</ActivitySuspense>
		</>
	);
};

export default WritingActivityNarrative;
