import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import media from '../../api/media';
import { getAudioExt } from '../../utils/audio';
import { MEDIA_SERVER_URL } from '../../utils/constants';
import { shuffle } from 'lodash';
import { transitionToNextActivity } from '../../utils/navigation';
import { loadSleep } from '../../utils/ui';

const EXT = getAudioExt();
export const Discrepancy = {
	prevTabIndex: '3',
	choiceContent: [
		{
			sequence: 0,
			id: 0,
			text: 'Text 1',
			sound: {
				name: 'choice1VO',
				src: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_text1.${EXT}`
			}
		},
		{
			sequence: 1,
			id: 1,
			text: 'Text 2',
			sound: {
				name: 'choice2VO',
				src: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_text2.${EXT}`
			}
		},
		{
			sequence: 2,
			id: 2,
			text: 'Text 3',
			sound: {
				name: 'choice3VO',
				src: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_text3.${EXT}`
			}
		}
	],

	titleSound: {
		name: 'titleVO',
		src: `${MEDIA_SERVER_URL}assets/activi/discpass/r180u_discpass_staticques.${EXT}`
	},
	helpVO: `${MEDIA_SERVER_URL}assets/activi/discpass/r180u_discpass_trialhelp.${EXT}`,

	VO: [
		{
			name: 'loaderVO',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_intro.${EXT}`
		},
		{
			name: 'text1',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_text1.${EXT}`
		},
		{
			name: 'text2',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_text2.${EXT}`
		},
		{
			name: 'text2',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_text3.${EXT}`
		},
		{
			name: 'instructionsVO',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_instructions.${EXT}`
		},

		{
			name: 'incorrectTrail',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_feedbackhelp.${EXT}`
		},
		{
			name: 'correctFirstTrail',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_goodjobfirsttry.${EXT}`
		},
		{
			name: 'correctSecondTrail',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_goodjobsecondtry.${EXT}`
		}
	],
	tabHeaderContent: [
		{
			id: '1',
			text: 'Text 1'
		},
		{
			id: '2',
			text: 'Text 2',
			name: 'choice2VO',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_text2.${EXT}`
		},
		{
			id: '3',
			text: 'Text 3',
			name: 'choice3VO',
			url: `${MEDIA_SERVER_URL}/assets/activi/discpass/r180u_discpass_text3.${EXT}`
		},
		{
			id: '4',
			text: 'Video',
			icon: 'anchor-video'
		}
	],

	loadContent: async apiData => {
		const start = new Date().getTime();

		let wordsDataList = [];
		const stage = apiData.stage;
		const segment = `${apiData.segment.toString().padStart(2, '0')}`;
		const level = `${apiData.level.toString().padStart(2, '0')}`;
		const length = 3;
		const promises = Array.from({
			length
		}).map(async (i, j) => {
			let wordsData = await media.getOne(
				`content/activi/discpass/r180u_discpass_${stage}${segment}${level}_${j + 1}.html`
			);

			wordsDataList.push(wordsData);
		});
		const videoPath = `${MEDIA_SERVER_URL}assets/video/r180u_video_${stage}${segment}.mp4`;
		const videoCaptionPath = `${MEDIA_SERVER_URL}/assets/video/r180u_${stage}${segment}.vtt`;

		await Promise.all(promises);
		wordsDataList = shuffle(wordsDataList);
		const correctChoiceIndex = Discrepancy.getAnswerIndex(wordsDataList);

		Discrepancy.VO.forEach(element => {
			AudioPlayer2.load({ name: element.name, src: element.url });
		});

		const end = new Date().getTime();
		await loadSleep(end - start);

		return { wordsDataList, videoPath, videoCaptionPath, correctChoiceIndex };
	},

	getAnswerIndex: data => {
		return data.findIndex(item => item.indexOf('feedback') === -1);
	},

	completeActivity: async (history, activityData) => {
		await transitionToNextActivity(history, {
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			}
		});
	}
};
