import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	wordTile: {
		display: 'inline-block',
		background: theme.colors.white,
		width: 'auto',
		minWidth: '64px',
		height: '56px',
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '28px',
		outline: '1px solid transparent',
		textAlign: 'center',
		cursor: 'pointer',
		padding: '8px 16px',
		'&.no-border': {
			border: '1px solid transparent'
		},
		'&:hover, &:focus': {
			background: theme.colors.readingZone.blue5,
			fontWeight: '600'
		},
		'&.selected': {
			background: theme.colors.readingZone.blue5,
			padding: '7px 15px',
			border: `2px solid ${theme.colors.readingZone.primary}`
		},
		'&.dragging': {
			borderRadius: '8px'
		}
	},
	text: {
		fontFamily: theme.fonts.context,
		fontSize: '24px',
		marginTop: '4px'
	}
}));

const WordTile = props => {
	const {text, onClick, id, selected, border, dragging, className} = props;
	const classes = useStyles();

	return (
		<button
			id={id}
			data-testid="testId"
			aria-describedby={id}
			aria-label={text}
			className={`${classes.wordTile}
			            ${selected ? 'selected' : ''}
						${border ? '' : 'no-border'}
						${dragging ? 'dragging' : ''}
						${className}
						word-tile`
				    }
			onClick={onClick}
		>
			<span className={`${classes.text}`}>{text}</span>
		</button>
	);
};

WordTile.defaultProps = {
	id: '',
	text: '',
	icon: '',
	selected: false,
	border: true,
	dragging: false,
	className: ''
};
WordTile.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	selected: PropTypes.bool,
	border: PropTypes.bool,
	dragging: PropTypes.bool,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default React.memo(WordTile);
