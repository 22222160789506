import { isEmpty } from "lodash";

export const ExploreZone = {

	/* istanbul ignore next */
	isLastActivity: (powerWordMapListData) => {
		if (isEmpty(powerWordMapListData)) {
			return false;
		}
		let count = 0;
		Object.values(powerWordMapListData).forEach((wordData) => {
			if (wordData.isCompleted) {
				count++;
			}
		});
		return count >= 5;
	},

	/* istanbul ignore next */
	isGoOnVisible: (zone, selectedWordCard, powerWordMap, isAllMiniWordCardCompleted) => {
		return zone === 'explore' &&
		      (ExploreZone.isWordCardActivityCompleted(selectedWordCard, powerWordMap) === false || isAllMiniWordCardCompleted);
	},

	/* istanbul ignore next */
	isWordCardActivityCompleted: (selectedWordCard, powerWordMap) => {
		if (selectedWordCard) {
			return powerWordMap[selectedWordCard.word_card.word_text].completedActivity >= 1;
		}
		else {
			return false;
		}
	}
};
