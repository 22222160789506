import {makeStyles} from '@material-ui/styles';

export const useStyle = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		// padding: 8,
		// height: props => props.maxHeight,
		// overflow: 'hidden',
		boxSizing: 'border-box',
		// transition: 'all 2s',
		justifyContent: 'center'
	},
	normalPartsWrapper: {
		position: 'relative',
		margin: '0',
		textAlign: 'center',
		overflow: 'hidden',
		backgroundColor: props => props.normalColor,
		borderRadius: 4,
		paddingLeft: 2,
		paddingRight: 2,
		userSelect: 'none',
		// width: 24,
		// height: 24,

		'& span': {
			fontFamily: 'Merriweather',
			fontSize: '32px',
			lineHeight: '48px',
			fontWeight: '400',
			color: '#55565a',
			letterSpacing: '1.9px'
		}
	},
	silentWrapper: {
		position: 'relative',
		margin: '0',
		textAlign: 'center',
		overflow: 'hidden',
		borderRadius: 4,
		paddingLeft: 0,
		paddingRight: 0,
		padding: '0px 0px',
		backgroundColor: props => props.normalColor,
		userSelect: 'none',

		'& span': {
			fontFamily: 'Merriweather',
			fontSize: '32px',
			lineHeight: '48px',
			fontWeight: '400',
			color: '#55565a',
			letterSpacing: '1.9px',
			padding: '0px 0px'
		}
	},

	plusWrapper: {
		display: 'flex',
		flexShrink: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		textAlign: 'center',
		overflow: 'hidden',
		// background: 'linear-gradient(60deg, #3629a2, #00ceff)',
		backgroundColor: '#d1d8e0',
		borderRadius: '50%',
		width: 20,
		height: 20,
		margin: '0px 4px',
		userSelect: 'none',

		'& span': {
			fontFamily: 'Merriweather',
			fontSize: '18px',
			lineHeight: '20px',
			fontWeight: 'bold',
			color: '#4b6584',
			padding: '0px 0px'
		}
	},
	button: {
		padding: '4px 8px',
		backgroundColor: '#ff6600',
		cursor: 'pointer'
	},
	spriteWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: props => props.normalColor,
		borderRadius: 4,
		opacity: 0
	}
}));
