import React, { useState, useMemo, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AudioRecorder from '@reading/common/src/components/AudioRecorder/AudioRecorder';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import PlayIcon from './PlayIcon';
import { useUnmount } from 'react-use';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import { sleep } from '@reading/r180/src/utils/sleep';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		top: '261px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		'& .try-again': {
			fontSize: '16px',
			color: theme.colors.readingZone.background
		}
	},
	playButton: {
		height: '64px',
		width: '64px',
		borderRadius: '50%',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		color: theme.colors.readingZone.background,
		background: theme.colors.white,
		fontSize: '30px',
		opacity: 1,
		paddingLeft: '5px',
		paddingTop: '5px'
	},
	outerCircle: {
		width: '144px',
		backgroundColor: theme.colors.readingZone.blue5,
		borderRadius: '50%'
	},
	recordHolder: {
		'&.recordMode': {
			borderRadius: ' 50%',
			'& div': {
				transform: 'scale(1)',
				cursor: 'not-allowed'
			}
		}
	},

	tryAgainHolder: {
		position: 'relative',
		top: '112px',
		left: '-21px',
		width: '110px',
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.2)'
		},

		'& span': {
			size: '16px',
			color: theme.colors.black,
			marginLeft: '10px',
			fontFamily: theme.fonts.ui
		},
		'& button': {
			border: 0
		}
	},
	title: {
		fontSize: '32px',
		fontFamily: theme.fonts.context,
		top: '-97px',
		position: 'relative',
	},
	playAgain: {
		cursor: 'pointer'
	}
}));

const PlayRecorded = props => {
	const { setGoOnDisabled, text, onRecording } = props;
	const classes = useStyles();
	const { setState } = useBatchedSetState();
	const mounted = useRef(false);

	const [isPlaying, setPlaying] = useState(false);
	const [isActivelyPlaying, setActivelyPlaying] = useState(false);
	const [isRecording, setRecording] = useState(false);
	const [hasVoiceFound, setVoiceStatus] = useState(false);

	let counter = 0;

	const setTimer = async () => {
		if (isRecording) {
			await sleep(6000);
			if (mounted.current) {
				setState(() => {
					setPlaying(true);
					setActivelyPlaying(true);
					setRecording(false);
					setGoOnDisabled(false);
					onRecording(false);
				});
				audioRecorder.stopRecord(false);
				setVoiceStatus(false);
			}
			await sleep(1000);
			if (mounted.current) {
				if (checkVoiceLevel()) {
					onRecording(false);
					await AudioPlayer2.playSync(text);
					await sleep(600);
					audioRecorder.play();
					await sleep(6000);
					setActivelyPlaying(false);
				} else {
					AudioPlayer2.play('noVoice');
				}
			}
		}
	};

	const getButtonClass = () =>
		isPlaying ? 'roundedBorder' : isRecording ? 'disableMode' : '';

	const getIconClass = () =>
		isPlaying ? 'play' : isRecording ? 'mic disabled' : 'mic';

	const getPlayIconClass = () =>
		!isPlaying ? classes.plainCircle : classes.circleBorder;

	const audioRecorder = useMemo(() => {
		const audioRecorder = new AudioRecorder();
		return audioRecorder;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		mounted.current = true;
		setGoOnDisabled(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		audioRecorder.levelsCallback = async level => {
			if (hasVoiceFound && level > 0) {
				counter++;
			}
			const recElem = document.querySelector('.recordMode');
			const rHolder = document.querySelector('#recordHolder');
			if (recElem) {
				recElem.style.boxShadow = `0px 0px 0px ${36 *
					level}px rgba(102,190,240,.4)`;
				recElem.style.height = '64px';
				recElem.style.width = '64px';
			} else if (rHolder) {
				rHolder.style.boxShadow = `0px 0px 0px 0 rgba(255,255,255,0)`;
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRecording]);

	useUnmount(() => {
		mounted.current = false;
		const stopAudio = async () => {
			await audioRecorder.stopPlay();
		};
		stopAudio();
	});
	const getCircleClass = () => (isRecording ? 'recordMode' : '');

	const recordHandler = async () => {
		/* istanbul ignore next */
		if (isRecording) {
			return false;
		}
		AudioPlayer2.stopAll();

		if (isPlaying) {
			audioRecorder.play();
			setActivelyPlaying(true);
			await sleep(6000);
			setActivelyPlaying(false);
		} else {
			counter = 0;
			setRecording(true);
			onRecording(true);
			audioRecorder.record();
			setVoiceStatus(true);
		}
	};

	/* Check if  MIC has recorded any Sounds */

	const checkVoiceLevel = () => counter > 0;

	useEffect(() => {
		setTimer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRecording]);

	const tryAgainHandler = () => {
		audioRecorder.stopPlay();
		setState(() => {
			setGoOnDisabled(true);
			setPlaying(false);
			setActivelyPlaying(false);
		});
	};

	return (
		<div className={classes.wrapper}>
			<span className={classes.title}>{text}</span>
			<div className={classes.playrecord}>
				<div
					className={`${getPlayIconClass()} ${classes.recordHolder
						} ${getCircleClass()}`}
					id="recordHolder"
				>
					<PlayIcon
						id="playIconId"
						recordHandler={recordHandler}
						iconClass={getIconClass()}
						buttonClass={getButtonClass()}
						isActivelyPlaying={isActivelyPlaying}
					/>

					{isPlaying && (
						<div
							id="tryAgainBtn"
							className={`${classes.tryAgainHolder} animate__animated animate__fadeIn`}
							onClick={tryAgainHandler}
						>
							<button type="button" id="go-on">
								<i className={`r180 reset try-again`} />
							</button>
							<span className={classes.playAgain}>Try Again</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default React.memo(PlayRecorded);
