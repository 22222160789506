import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { WritingPanelNote } from '@reading/common';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { cloneDeep } from 'lodash';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
    wrapper: {},
}));

const WritingNotes = props => {
    const {
        className,
        sections,
        onSectionCompleted,
        onNotesCompleted,
		isAutoPlay
    } = props;

    const classes = useStyles();
	const {setState} = useBatchedSetState();

	const [panels, setPanels] = useState(sections);
    const [currentPanelIndex, setCurrentPanelIndex] = useState(0);
    const [notes, setNotes] = useState([]);

    useEffect(() => {
        if (currentPanelIndex >= 0 && currentPanelIndex < 100) {
            const audio = panels[currentPanelIndex].sections[0].audio.name;
			if (isAutoPlay) {
	            AudioPlayer2.play(audio);
			}
        }
    }, [currentPanelIndex, panels]);

    const handlePanelComplete = (data = []) => {
		AudioPlayer2.stopAll();
		const newPanels = cloneDeep(panels);
        const _panel = newPanels[currentPanelIndex];
        _panel.sections = [...data];
        const _notes = [...notes, _panel];
		setState(() => {
			setNotes(_notes);
			setPanels(newPanels);
		});

        if (currentPanelIndex < newPanels.length - 1) {
            setCurrentPanelIndex(currentPanelIndex + 1);

            if (typeof onSectionCompleted === 'function') {
                onSectionCompleted(_panel, currentPanelIndex + 1);
            }
        }
        else {
            setCurrentPanelIndex(999);
            if (typeof onNotesCompleted === 'function') {
                onNotesCompleted(_notes);
            }
        }
    }

    const handlePanelChange = async (panel, i) => {
		if (isAutoPlay) {
        	await AudioPlayer2.playSync(panel[i].audio.name);
		}
    }

	const handleRevise = (index) => {
		AudioPlayer2.stopAll();
		setCurrentPanelIndex(index);
	}

    return (
        <div className={`writing-notes ${classes.wrapper} ${className}`}>
            <div className={`writing-notes ${classes.wrapper} ${className}`}>
                {panels.map((panel, i) => {
                    return (
                        <WritingPanelNote
							key={`panel-${i}`}
                            activePanelIndex={currentPanelIndex}
                            onTextChange={() => { }}
                            title={panel.title}
                            letter={panel.key}
                            panels={panel.sections}
                            orderID={i}
                            isActive={i === currentPanelIndex}
							isCompleted={i < currentPanelIndex}
                            resetClicked={() => { }}
                            isResetClicked={false}
                            panelGOStatus={'VALID'}
                            onPanelCompleted={handlePanelComplete}
                            onPanelChange={handlePanelChange}
							onRevise={handleRevise}
                        />
                    )
                })}
            </div>
        </div>
    );
};

WritingNotes.defaultProps = {
    className: '',
    sections: [],
	isAutoPlay: false
};
WritingNotes.propTypes = {
    className: PropTypes.string,
    sections: PropTypes.array.isRequired,
    onSectionsCompleted: PropTypes.func,
	onNotesCompleted: PropTypes.func,
	isAutoPlay: PropTypes.bool
};

export default React.memo(WritingNotes);
