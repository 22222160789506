import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import StarRating from '../../components/StarRating/StarRating';
import ActivityDirections from '../../components/ActivityDirections';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

const useStyles = makeStyles(theme => ({
	wrapper: {
		width: '488px',
		height: '568px',
		marginTop: '40px'
	},
	activityDirectionWrapper: {
		background: theme.colors.writingZone.green5,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: '86px',
		padding: '0 12px',
		'& .activity-directions': {
			display: 'flex',
			textAlign: 'left'
		},
		'& span': {
			fontWeight: '700',
			fontSize: '18px',
			fontFamily: theme.fonts.buttonBold,
			lineHeight: '22px'
		},
		'& i': {
			color: `${theme.colors.slate} !important`,
			top: '2px'
		},
		boxShadow: '-2px -3px 4px rgba(0,0,0,0.09)',
		borderRadius: '8px 8px 0 0'
	},
	headingWrapper: {
		background: theme.colors.black,
		color: theme.colors.white,
		height: '24px',
		display: 'flex',
		justifyContent: 'center',
		fontWeight: '700',
		fontSize: '16px',
		fontFamily: theme.fonts.buttonBold,
		lineHeight: '22px',
		letterSpacing: '0.5px',
		alignItems: 'center'
	},
	spellingWrapper: {
		width: '488px',
		height: '152px'
	},
	tiredWords: {
		'& div': {
			backgroundColor: theme.colors.lightGrey
		}
	},
	sentenceSense: {
		'& div': {
			borderRadius: '0 0 8px 8px'
		}
	}
}));

const WritingTools = props => {
	const {
		className,
		id,
		onStarsClicked,
		onOffButtonClicked,
		onCompletion,
		autoPlay
	} = props;

	const classes = useStyles();
	const {setState} = useBatchedSetState();

	const EXT = getAudioExt();
	const introSpeakerAudio =
		MEDIA_SERVER_URL +
		`assets/activi/writing/r180u_writing_edit_rate.${EXT}`;
	const soundsMap = {
		spelling:
			MEDIA_SERVER_URL +
			`assets/activi/writing/r180u_writing_edit_spelling_lintro.${EXT}`,
		tired_words:
			MEDIA_SERVER_URL +
			`assets/activi/writing/r180u_writing_edit_tiredwords_lintro.${EXT}`,
		sentence_fragments:
			MEDIA_SERVER_URL +
			`assets/activi/writing/r180u_writing_edit_sentsense_lintro.${EXT}`
	};

	const [disableSpellingRating, setDisableSpellingRating] = useState(false);
	const [
		disableSpellingToggleSwitch,
		setDisableSpellingToggleSwitch
	] = useState(true);
	const [spellingButtonState, setSpellingButtonState] = useState(false);
	const [disableTiredWordsRating, setDisableTiredWordsRating] = useState(
		true
	);
	const [
		disableTiredWordsToggleSwitch,
		setDisableTiredWordsToggleSwitch
	] = useState(true);
	const [tiredWordsButtonState, setTiredWordsButtonState] = useState(false);
	const [disableSentenceRating, setDisableSentenceRating] = useState(true);
	const [
		disableSentenceToggleSwitch,
		setDisableSentenceToggleSwitch
	] = useState(true);
	const [sentenceButtonState, setSentenceButtonState] = useState(false);

	useEffect(() => {
		AudioPlayer2.loadSound(soundsMap['spelling']);
		AudioPlayer2.loadSound(soundsMap['tired_words']);
		AudioPlayer2.loadSound(soundsMap['sentence_fragments']);
		if (autoPlay) {
			AudioPlayer2.play(soundsMap['spelling']);
		}
	}, []);

	const handleStarClicked = (stars, text) => {
		AudioPlayer2.stopAll();
		if (text === 'Spelling') {
			if (autoPlay) {
				AudioPlayer2.play(soundsMap['tired_words']);
			}
			setState(() => {
				setDisableSpellingToggleSwitch(false);
				setDisableTiredWordsRating(false);
			});
		}
		if (text === 'Tired Words') {
			if (autoPlay) {
				AudioPlayer2.play(soundsMap['sentence_fragments']);
			}
			setState(() => {
				setDisableTiredWordsToggleSwitch(false);
				setDisableSentenceRating(false);
			});
		}
		if (text === 'Sentence Sense') {
			setDisableSentenceToggleSwitch(false);
			if (typeof onCompletion === 'function') {
				onCompletion();
			}
		}
		onStarsClicked(stars, text);
	};

	const handleOnOffClicked = value => {
		AudioPlayer2.stopAll();
		setState(() => {
			setSpellingButtonState(value.text === 'Spelling');
			setTiredWordsButtonState(value.text === 'Tired Words');
			setSentenceButtonState(value.text === 'Sentence Sense');
		});
		if (typeof onOffButtonClicked === 'function') {
			onOffButtonClicked(value);
		}
	};

	return (
		<div
			className={`${classes.wrapper} ${className} writing-tools animate__animated animate__fadeIn`}
			id={id}
		>
			<div className={classes.activityDirectionWrapper}>
				<ActivityDirections
					text={
						'Your draft is looking great! Time to rate your writing. '
					}
					src={introSpeakerAudio}
				/>
			</div>

			<div className={classes.headingWrapper}>Writing Tools</div>

			<div className={classes.spellingWrapper}>
				<StarRating
					data-testid={'spelling'}
					key={'spelling'}
					onStarClicked={handleStarClicked}
					onShowOnOffClicked={handleOnOffClicked}
					text={'Spelling'}
					showOnAndOffButton={true}
					src={soundsMap['spelling']}
					showHelpIcon={false}
					disableRating={disableSpellingRating}
					disableOnandOff={disableSpellingToggleSwitch}
					onAndOffButtonState={spellingButtonState}
				/>
			</div>
			<div className={`${classes.spellingWrapper} ${classes.tiredWords}`}>
				<StarRating
					data-testid={'tiredWords'}
					key={'tiredWords'}
					onStarClicked={handleStarClicked}
					onShowOnOffClicked={handleOnOffClicked}
					text={'Tired Words'}
					showOnAndOffButton={true}
					src={soundsMap['tired_words']}
					showHelpIcon={false}
					disableRating={disableTiredWordsRating}
					disableOnandOff={disableTiredWordsToggleSwitch}
					onAndOffButtonState={tiredWordsButtonState}
				/>
			</div>
			<div
				className={`${classes.spellingWrapper} ${classes.sentenceSense}`}
			>
				<StarRating
					data-testid={'sentenceSense'}
					key={'sentenceSense'}
					onStarClicked={handleStarClicked}
					onShowOnOffClicked={handleOnOffClicked}
					text={'Sentence Sense'}
					showOnAndOffButton={true}
					src={soundsMap['sentence_fragments']}
					showHelpIcon={false}
					disableRating={disableSentenceRating}
					disableOnandOff={disableSentenceToggleSwitch}
					onAndOffButtonState={sentenceButtonState}
				/>
			</div>
		</div>
	);
};

WritingTools.defaultProps = {
	className: '',
	id: '',
	autoPlay: false
};
WritingTools.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	onStarsClicked: PropTypes.func,
	onOffButtonClicked: PropTypes.func,
	onCompletion: PropTypes.func,
	autoPlay: PropTypes.bool
};

export default React.memo(WritingTools);
