import React, {useState, useEffect, useRef, useMemo} from 'react';
import Navbar from '../../../containers/App/Navbar';
import ActivitySuspense from '../../../containers/App/ActivitySuspense';
import ActivityFrame from '../../../containers/App/ActivityFrame';
import FSProgressBar from '../../../containers/App/FSProgressBar';
import {
	ActivityInstructionButton,
	ActivitySupportButton,
	SoundButton,
	WordBuilderTile,
	CorrectIncorrect,
	FoundationalIntro
} from '@reading/common';
import FoundationalIntroModal from '@reading/common/src/components/FoundationalIntro/FoundationIntroModal';
import Footer from '../../../containers/App/Footer';
import FooterForwardBack from '../../../containers/App/FooterForwardBack';
import useStyles from './WordBuilder.style';
import {useSelector} from 'react-redux';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useTheme} from '@material-ui/core/styles';
import {FoundationalUtil} from '../FoundationalUtil';
import {MAX_ATTEMPTS, MIN_DISABLED, PCT_DISABLED, INTRO_VIDEO, VO, MODE} from './WordBuilderConstants';
import {preloadImages, preloadAudio} from './WordBuilderUtil';
import {shuffle} from 'lodash';
import useBatchedSetState from '../../../utils/useBatchedSetState';
import {CaptionedAudioPlayer2} from '@reading/common';

const WordBuilderActivity = props => {
	const classes = useStyles();
	const [isModalOpen, setModalOpen] = useState(false);
	const [baseWord, setBaseWord] = useState('');
	const [targetWord, setTargetWord] = useState(false);
	const [targetWordIndex, setTargetWordIndex] = useState(0);
	const [targetTiles, setTargetTiles] = useState([]);
	const [sourceTiles, setSourceTiles] = useState([]);
	const [discardTiles, setDiscardTiles] = useState([]);
	const [dragTile, setDragTile] = useState(false);
	const theme = useTheme();
	const [forwardStatus, setForwardStatus] = useState(FooterForwardBack.START);
	const [saveResult, setSaveResult] = useState([]);
	const [latencyEpoch, setLatencyEpoch] = useState(0);
	const [wordAttempts, setWordAttempts] = useState(0);
	const [deadTiles, setDeadTiles] = useState([]);
	const isWordPauseDisabled = false;
	const [isForwardDisabled, setForwardDisabled] = useState(false);
	const [cachedImages, setCachedImages] = useState(null);
	const [cachedAudio, setCachedAudio] = useState(null);
	const [imagesLoaded, setImagesLoaded] = useState(false);
	const {setState} = useBatchedSetState();
	const startTimeMillis = useRef(Date.now());
	const [mode, setMode] = useState(MODE.START);
	const [wordSetIndex, setWordSetIndex] = useState(0);
	const [currentSourceTiles, setCurrentSourceTiles] = useState({});
	const {
		activityData,
		contentData: {intro_vo, word_sets},
		saveTemplate: {start_time},
		state: {
			status_bar: {activities_encountered, activities_total}
		},
		session: {
			session: {
				settings: {autoPlayEnabled}
			}
		}
	} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			contentData: state.activity.content || {
				intro_vo: '',
				word_sets: [
					{
						id: 1,
						base_word: '',
						activity_words: [],
						building_tiles: []
					}
				]
			},
			saveTemplate: state.activity.save_template || {start_time: ''},
			state: state.activity.state || {status_bar: {activities_total: 1, activities_encountered: 0}},
			session: state.session || {session: {settings: {autoPlayEnabled: null}}}
		};
	});

	const {id, base_word, activity_words, building_tiles} = word_sets[wordSetIndex];

	useEffect(() => {
		if (word_sets.length && activity_words.length) {
			setState(() => {
				setBaseWord(base_word);
				setCachedAudio(preloadAudio(word_sets));
				setCachedImages(preloadImages({word_sets, setImagesLoaded}));
			});
		}
	}, [word_sets, activity_words]);
	useEffect(() => {
		if (intro_vo === 'lintro' && autoPlayEnabled) {
			setModalOpen(true);
		}
	}, [intro_vo, autoPlayEnabled]);
	const handleHelpClick = () => {
		if (!wordAttempts) {
			CaptionedAudioPlayer2.play('acthelp1');
		} else {
			CaptionedAudioPlayer2.play('acthelp2');
		}
	};
	const getInstructionSound = () => {
		if (intro_vo === 'lintro') {
			return 'lintro';
		}
		return FoundationalUtil.getRandomSound(['sintro1', 'sintro2', 'sintro3', 'sintro4', 'sintro5']).name;
	};
	const isTileCorrect = tile => {
		if (targetWord.text.indexOf(tile.value) > -1) {
			return true; //This tile is part of the letters needed
		}
		return false;
	};
	const isTileNeeded = tile => {
		let lettersNeeded = targetWord.text;
		targetTiles.forEach(tile => {
			if (isTileCorrect(tile)) {
				lettersNeeded = lettersNeeded.replace(tile.value, '');
			}
		});
		//Take target word, remove correct filled in letters, the remaining letters are needed
		if (lettersNeeded.indexOf(tile.value) > -1) {
			return true; //This tile is part of the letters needed
		}
		return false;
	};

	const handleForward = () => {
		if (forwardStatus === FooterForwardBack.START) {
			setMode(MODE.START2MAIN);
			setForwardDisabled(true);
			setTimeout(() => {
				setState(async () => {
					setForwardDisabled(false);
					setForwardStatus(FooterForwardBack.SUBMIT);
					setMode(MODE.MAIN);
					await CaptionedAudioPlayer2.playSync(
						FoundationalUtil.getRandomSound(['changewordinto1', 'changewordinto2'])
					);
					CaptionedAudioPlayer2.play(`word_${targetWord.text}`);
				});
			}, 3000);
		}
		if (forwardStatus === FooterForwardBack.SUBMIT) {
			const wordAsSpelled = targetTiles.map(tile => tile.value).join('');
			if (wordAsSpelled.toLowerCase() === targetWord.text.toLowerCase()) {
				setCurrentSourceTiles([...sourceTiles, ...discardTiles]);
				if (!wordAttempts) {
					//If this is their first try, remember the result
					setSaveResult([
						...saveResult,
						{id: targetWord.id, score_first_attempt: 'correct', latency: Date.now() - latencyEpoch}
					]);
				}
				setForwardStatus(FooterForwardBack.VALID);
				if (targetWordIndex == activity_words.length - 1 && wordSetIndex === word_sets.length - 1) {
					//If this is the VERY last question
					const pCorrect =
						saveResult.reduce((acc, result) => {
							if (result.score_first_attempt === 'correct') {
								return acc + 1;
							}
						}, 0) / saveResult.length;
					if (pCorrect >= 0.8) {
						if (pCorrect == 1) {
							//All correct on first try
							CaptionedAudioPlayer2.play(
								FoundationalUtil.getRandomSound(['correct1', 'correct2', 'correct3'])
							);
						} else {
							//>=80% correct on first try
							CaptionedAudioPlayer2.play(
								FoundationalUtil.getRandomSound(['percatt1', 'percatt2', 'percatt3', 'percatt4'])
							);
						}
					} else {
						CaptionedAudioPlayer2.play(
							FoundationalUtil.getRandomSound(['incorrect1', 'incorrect2', 'incorrect3', 'incorrect4'])
						);
					}
				}
			} else {
				if (!wordAttempts) {
					//We only save the first attempt, even if it's wrong
					setSaveResult([
						...saveResult,
						{id: targetWord.id, score_first_attempt: 'missed', latency: Date.now() - latencyEpoch}
					]);
				}
				setForwardStatus(FooterForwardBack.INVALID);
			}
			setWordAttempts(wordAttempts + 1);
			return;
		}
		if (forwardStatus === FooterForwardBack.INVALID && wordAttempts < MAX_ATTEMPTS) {
			//SPECIAL TRIAL LOGIC
			if (wordAttempts === 1) {
				//On 2nd attempt, disable a portion of the wrong tiles
				const tilesToKill = Math.floor(Math.max(MIN_DISABLED, PCT_DISABLED * sourceTiles.length));
				const wrongTiles = sourceTiles.filter(tile => !isTileCorrect(tile));
				setDeadTiles(shuffle(wrongTiles.map(tile => tile.id)).slice(0, tilesToKill));
			}
			if (wordAttempts >= 2) {
				setForwardDisabled(true);
			}
			setForwardStatus(FooterForwardBack.SUBMIT);
			return;
		}
		if (
			forwardStatus === FooterForwardBack.VALID ||
			(forwardStatus === FooterForwardBack.INVALID && wordAttempts >= MAX_ATTEMPTS)
		) {
			//They go on if it's correct or if they get it wrong too many time
			if (targetWordIndex < activity_words.length - 1) {
				//If there are more words to do, send partial and get the next word in the array
				FoundationalUtil.sendProgressToServer({
					activity_words: saveResult,
					tracked_time: Date.now() - startTimeMillis.current,
					user_directed_navigation: null,
					start_time,
					has_partial: true
				});
				setMode(MODE.NEWWORD);
				resetTargetWord(targetWordIndex + 1);
				setTimeout(() => {
					setState(async () => {
						setForwardStatus(FooterForwardBack.SUBMIT);
						setDeadTiles([]);
						setLatencyEpoch(Date.now());
						setWordAttempts(0);
						setMode(MODE.MAIN);
						await CaptionedAudioPlayer2.playSync(
							FoundationalUtil.getRandomSound(['changewordinto1', 'changewordinto2'])
						);
						CaptionedAudioPlayer2.play(`word_${activity_words[targetWordIndex + 1].text}`);
					});
				}, 3000);
			} else {
				if (wordSetIndex < word_sets.length - 1) {
					//If there are more item sets go to the next one
					setState(() => {
						setWordSetIndex(wordSetIndex + 1);
						setMode(MODE.START);
						setForwardDisabled(true);
					});
					setTimeout(() => {
						setMode(MODE.START2MAIN);
					}, 3000);
					setTimeout(async () => {
						setMode(MODE.MAIN);
						await CaptionedAudioPlayer2.playSync(
							FoundationalUtil.getRandomSound(['changewordinto1', 'changewordinto2'])
						);
						CaptionedAudioPlayer2.play(`word_${word_sets[wordSetIndex + 1].activity_words[0].text}`);
						setForwardDisabled(false);
					}, 6000);
				} else {
					//Other wise send complete and go on

					FoundationalUtil.completeActivity({
						activity_words: saveResult,
						tracked_time: Date.now() - startTimeMillis.current,
						user_directed_navigation: null,
						start_time,
						has_partial: false
					});
				}
			}
		}
	};
	const handleSentence = () => {
		cachedAudio[`ctxt_${targetWord.text}`].play();
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};
	const handleInstructions = () => {
		setModalOpen(true);
	};

	const resetTargetWord = (targetWordIndex, newBuildingTiles = false) => {
		setState(() => {
			setTargetWord(activity_words[targetWordIndex]);
			setTargetWordIndex(targetWordIndex);
			const baseWord = targetWordIndex ? activity_words[targetWordIndex - 1].text : base_word;
			setBaseWord(baseWord);
			setTargetTiles(baseWord.split('').map((letter, index) => ({value: letter, id: `dest_${letter}_${index}`})));
			if (!newBuildingTiles) {
				setSourceTiles(currentSourceTiles);
			} else {
				setSourceTiles(newBuildingTiles);
				setCurrentSourceTiles(newBuildingTiles);
			}
			setDiscardTiles([]);
		});
	};

	useEffect(() => {
		if (activity_words.length) {
			resetTargetWord(
				0,
				building_tiles.map(({value}, index) => ({value, id: `src_${value}_${index}`}))
			);
		}
	}, [activity_words]);

	useEffect(() => {
		if (wordSetIndex > 0) {
			setForwardStatus(FooterForwardBack.SUBMIT);
		}
	}, [wordSetIndex]);
	useEffect(() => {
		if (autoPlayEnabled && !isModalOpen) {
			handleSoundButton({stopPropagation: () => {}});
		}
	}, [targetWord, isModalOpen]);

	const handleDragEnd = data => {
		const {destination, source} = data;
		if (!destination || !source) {
			setDragTile(false);
			return;
		}
		if (
			!isTileNeeded(dragTile) &&
			wordAttempts >= 2 &&
			destination.droppableId === 'target' &&
			source.droppableId !== 'target'
		) {
			//If attempting to move a wrong tile into the target on trial 3, don't allow it
			//rearranging in the target is ok
			setDragTile(false);
			return;
		}

		let newTargetTiles = [...targetTiles];
		let newSourceTiles = [...sourceTiles];
		let newDiscardTiles = [...discardTiles];
		switch (source.droppableId) {
			case 'target':
				newTargetTiles = targetTiles.filter(tile => tile.id !== dragTile.id);
				break;
			case 'source':
				newSourceTiles = sourceTiles.filter(tile => tile.id !== dragTile.id);
				break;
			case 'discard':
				newDiscardTiles = discardTiles.filter(tile => tile.id !== dragTile.id);
				break;
			default:
		}

		switch (destination.droppableId) {
			case 'target':
				newTargetTiles = [
					...newTargetTiles.slice(0, destination.index),
					dragTile,
					...newTargetTiles.slice(destination.index)
				];
				break;
			case 'source':
				newSourceTiles = [
					...newSourceTiles.slice(0, destination.index),
					dragTile,
					...newSourceTiles.slice(destination.index)
				];
				break;
			case 'discard':
				newDiscardTiles = [
					...newDiscardTiles.slice(0, destination.index),
					dragTile,
					...newDiscardTiles.slice(destination.index)
				];
				break;
			default:
		}
		if (isForwardDisabled) {
			const wordAsSpelled = newTargetTiles.map(tile => tile.value).join('');
			if (wordAsSpelled.toLowerCase() === targetWord.text.toLowerCase()) {
				setForwardDisabled(false);
			}
		}
		setState(() => {
			setTargetTiles(newTargetTiles);
			setSourceTiles(newSourceTiles);
			setDiscardTiles(newDiscardTiles);
		});
	};
	const handleDragStart = ({draggableId}) => {
		setDragTile({id: draggableId, value: draggableId.split('_')[1]});
	};
	const dropStyle = ({droppableProps: {style}}, {isDraggingOver}, doFeedback = false) => {
		if (forwardStatus === FooterForwardBack.SUBMIT || !doFeedback) {
			const bgColor = !isDraggingOver ? theme.colors.lightGrey : theme.colors.lightBlue;
			const dashColor = !isDraggingOver ? theme.colors.paleGrey : theme.colors.skyBlue;
			return {
				...style,
				backgroundColor: bgColor,
				border: `dashed 1px ${dashColor}`,
				boxShadow: `0px 0px 0px 4px ${bgColor}`
			};
		}
		if (forwardStatus === FooterForwardBack.VALID) {
			return {
				...style,
				backgroundColor: theme.colors.lightGrey,
				border: `none`,
				boxShadow: `0px 0px 0px 2px ${theme.colors.successGreen}`,
				padding: '1px'
			};
		}
		if (forwardStatus === FooterForwardBack.INVALID) {
			return {
				...style,
				backgroundColor: theme.colors.lightGrey,
				border: `none`,
				boxShadow: `0px 0px 0px 2px ${theme.colors.warningRed}`,
				padding: '1px'
			};
		}
	};

	const handleVideoComplete = () => {};
	const handleSoundButton = async () => {
		await CaptionedAudioPlayer2.playSync(FoundationalUtil.getRandomSound(['changewordinto1', 'changewordinto2']));
		CaptionedAudioPlayer2.play(`word_${targetWord.text}`);
	};
	const intro = useMemo(
		() => (
			<FoundationalIntro
				title="Word Builder"
				videoUrl={INTRO_VIDEO}
				icons={[
					{text: 'Listen', icon: 'listen'},
					{text: 'Find', icon: 'preview'},
					{text: 'Click', icon: 'spot'}
				]}
				onVideoComplete={handleVideoComplete}
				isFirstVisit={intro_vo === 'lintro'}
				instructionSoundSrc={intro_vo === 'lintro' ? VO.help1 : VO.help2}
			/>
		),
		[intro_vo]
	);
	const animClasses = elem => {
		if (mode === MODE.START) {
			if (elem === 'source' || elem === 'arrow' || elem === 'drag') {
				return classes.animInvisible;
			}
			return ''; //target stays the same
		}
		if (mode === MODE.START2MAIN) {
			if (elem === 'source' || elem === 'arrow' || elem === 'drag') {
				return classes.animInvisible;
			}
			return classes.animStartMoveLeftShrink;
		}
		if (mode === MODE.MAIN) {
			if (elem === 'source' || (targetWordIndex !== 0 && elem === 'drag')) {
				return '';
			}
			return classes.animFadeIn;
		}
		if (mode === MODE.NEWWORD) {
			if (elem === 'source' || elem === 'arrow') {
				return classes.animInvisible;
			}
			if (elem === 'dest') {
				return classes.animMoveLeftShrink;
			}
			return '';
		}
	};

	return (
		<>
			<Navbar helpSoundUrl={''} onHelp={handleHelpClick} />

			<ActivitySuspense
				showSpinner={!imagesLoaded}
				requiredRenderData={[activity_words, cachedImages]}
				title="Word Builder"
			>
				<ActivityFrame isWhiteBackground={true}>
					<>
						<FSProgressBar
							title="The Code"
							percentProgress={(100 * activities_encountered) / activities_total}
							taskNum={activities_encountered}
							totalTasks={activities_total}
						/>

						<ActivityInstructionButton
							audioName={getInstructionSound()}
							onSoundEnd={handleSoundButton}
							fs={true}
						/>
						<div className={classes.wrapper} id="wrapper">
							<div className={classes.imagesRow}>
								<div
									role={'img'}
									aria-label={`base word: ${baseWord}`}
									className={`${classes.sourceDiv} ${animClasses('source')}`}
								>
									<img
										src={cachedImages && cachedImages[baseWord].src}
										className={`${classes.sourceImg}`}
										alt={''}
									/>
									{baseWord}
								</div>
								<div className={`${classes.arrow} ${animClasses('arrow')}`}>
									<i className="r180 arrow-right" />
								</div>
								<div
									role={'img'}
									aria-label={`target word: ${mode === MODE.MAIN ? targetWord.text : baseWord}`}
									className={`${classes.destDiv} ${animClasses('dest')} ${mode === MODE.START &&
										classes.startImage}`}
								>
									{mode === MODE.MAIN && (
										<SoundButton
											className={classes.destSound}
											size={24}
											onClick={handleSoundButton}
											sound={{name: mode === MODE.MAIN ? targetWord.text : baseWord, src: ''}}
										/>
									)}
									<img
										src={
											cachedImages &&
											(mode === MODE.MAIN
												? cachedImages[targetWord.text].src
												: cachedImages[baseWord].src)
										}
										className={classes.destImg}
										alt={''}
									/>
									{mode !== MODE.MAIN && <div className={classes.bigWord}>{baseWord}</div>}
								</div>
							</div>
							<div className={animClasses('drag')}>
								<DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
									<Droppable droppableId={'target'} direction="horizontal" isCombineEnabled={false}>
										{(provided, snapshot) => (
											<div
												data-testid={'dragTarget'}
												className={classes.dragTarget}
												ref={provided.innerRef}
												style={dropStyle(provided, snapshot, true)}
												role={'region'}
												aria-label={'target'}
											>
												{targetTiles.map(({value, id}, index) => (
													<Draggable
														index={index}
														draggableId={id}
														key={id}
														isDragDisabled={
															forwardStatus !== FooterForwardBack.SUBMIT ||
															(isTileCorrect({value, id}) &&
																wordAttempts >= 2 &&
																baseWord.indexOf(value) > -1)
														}
													>
														{(provided, snapshot) => (
															<div
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={provided.draggableProps.style}
															>
																<WordBuilderTile text={value} />
															</div>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
									{forwardStatus === FooterForwardBack.VALID && (
										<div className={`${classes.feedbackIcon}`}>
											<CorrectIncorrect correct />
										</div>
									)}
									{forwardStatus === FooterForwardBack.INVALID && (
										<div className={`${classes.feedbackIcon}`}>
											<CorrectIncorrect correct={false} />
										</div>
									)}
									<div className={classes.dragSources}>
										<Droppable
											droppableId={'source'}
											direction="horizontal"
											isCombineEnabled={false}
											isDropDisabled={true}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													className={classes.wordTiles}
													style={provided.droppableProps.style}
													role={'region'}
													aria-label={'source'}
												>
													{sourceTiles.map(({value, id}, index) => (
														<Draggable
															index={index}
															draggableId={id}
															key={id}
															isDragDisabled={
																forwardStatus !== FooterForwardBack.SUBMIT ||
																deadTiles.includes(id)
															}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={provided.draggableProps.style}
																>
																	<WordBuilderTile
																		text={value}
																		disabled={deadTiles.includes(id)}
																	/>
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
										<Droppable
											droppableId={'discard'}
											direction="horizontal"
											isCombineEnabled={false}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													className={classes.discardPile}
													style={dropStyle(provided, snapshot)}
													role={'region'}
													aria-label={'discard'}
												>
													{discardTiles.map(({value, id}, index) => (
														<Draggable
															index={index}
															draggableId={id}
															key={id}
															isDragDisabled={forwardStatus !== FooterForwardBack.SUBMIT}
														>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={provided.draggableProps.style}
																>
																	<WordBuilderTile text={value} />
																</div>
															)}
														</Draggable>
													))}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</div>
								</DragDropContext>
							</div>
						</div>
						<div className={`${classes.reset} ${animClasses('arrow')}`}>
							<ActivitySupportButton
								icon={'reset'}
								text={'Reset'}
								onClick={() => {
									resetTargetWord(targetWordIndex);
								}}
							/>
						</div>
					</>
				</ActivityFrame>

				{isModalOpen && (
					<FoundationalIntroModal isOpen={isModalOpen} onClose={handleModalClose}>
						{intro}
					</FoundationalIntroModal>
				)}

				<Footer>
					<ActivitySupportButton icon="preview" onClick={handleInstructions} text="Instructions" />
					<ActivitySupportButton
						icon="sentence"
						onClick={handleSentence}
						text="Sentence"
						disabled={isWordPauseDisabled}
					/>

					<FooterForwardBack
						onForward={handleForward}
						isBackVisible={false}
						isForwardVisible={true}
						isForwardDisabled={isForwardDisabled}
						status={forwardStatus}
					/>
				</Footer>
			</ActivitySuspense>
		</>
	);
};

export default WordBuilderActivity;
