import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ActivityButton from '../ActivityButton';
import MagicTextArea from '../MagicTextArea/MagicTextArea';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {isFirefox} from '@reading/r180/src/utils/browsers';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import {sleep} from '@reading/r180/src/utils/sleep';
import {cloneDeep} from 'lodash';
import ConfirmationModal from '../Modals/ConfirmationModal';

export const useStyles = makeStyles(theme => ({
	'@keyframes grow': {
		'0%': {
			transform: 'scale(0.8)'
		},
		'100%': {
			transform: 'scale(1)'
		}
	},
	'@keyframes shrink': {
		'0%': {
			transform: 'scale(1)'
		},
		'100%': {
			transform: 'scale(0.8)'
		}
	},
	wrapper: {
		width: 'auto',

		'&.writing-panel-note': {
			width: 'auto',
			height: 'auto',
			left: '0',
			borderRadius: '4px',
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			background: '#FFFFD5',
			overflow: 'hidden',
			marginBottom: '24px',
			minHeight: '100px',
			position: 'relative',

			'&.selected': {
				clipPath: 'polygon(0 0, 0 86%, 5% 100%, 100% 100%, 100% 0)',
				width: '752px',
				marginLeft: '-76px',
				color: theme.colors.softBlack,
				animation: '$grow 0.6s ease-in',

				'&.shrinking': {
					animation: '$shrink 0.4s ease-in'
				},

				'& .dog-ear': {
					position: 'absolute',
					bottom: '0',
					left: '0',
					width: '42px',
					height: '43px',
					background:
						'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAArCAYAAAAOnxr+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAABDdEVYdFNvZnR3YXJlAFBob3RvIFBvcyBQcm8gdjMuNy4xLjAgT2ZmaWNpYWwgQnVpbGQgKDI0KSA2NCBCaXQgVmVyc2lvbgGUBuctAAAEhUlEQVRYR82Y6W7TQBDHnYR3oUeOPgr0GYBKiEMU2vIcLS+AxFfON0FQkEDirpOWgmhLG3oxzLHjnd1sXAhNkw9/2TuZtX8Zz8yunf3+vQFHR+usw8M2HByo8kJkt/K/W/9ehdcLx3Ku99Brd5CjA8fH60BcJICvrIycut0vsLv7CXZ2PsD2tup9oa2td+7oz61dz2Usc2X83l3T2v28+Ei+u7sfYW/vM+zv5wytsBkZyXFz8w10Oq+g3V6FtbWXkOeitbUXLB2ryE/P9Xfxld90nv0tz9UWXotEczqdVVhffw3fv79l6G73M0eaYLN7zy4CHN0fK/3aewrfvj2HHz/eMSylQjZ1tQorT8YP9vjwAT7l55wqlAbZ9I0qTM5VYfnx+MHudx9hZN9wDWWN+QpMX6/CxOUKrDyeTU4YlSiqGxuv4efPj5A1FzKoG9jlR+MVWSowKqysuVSB1mIVGvM1mLpWgfNjBttuv+TWhaBVaN2tQXOhgrDVsYssta4iohzVu1VoYmQpDaYUdgxylkCpzyNohqAkB7tAaYCwmAYTV7AbjDiyBOoePUVUQSW6zcUap8HUdYQdcRpEEXWADrrFsKjbNc5ZKrBRta4SUBIWGB0xDerzmaTBiCKbAPXAlAIMimpony1gLyQvOCz1AbXRxRSg4qLoUoFhGtRvnENYLLCHZweb56tx1cfAotZSjYFlUXAr2Bl2AxNRDydVr8DePlPkLKbCbewG3LpqZwJLoLTxDkAtoIcW+wytXnTOfVZXMNwiDhlWQLmP9kKG5yLtsbIo6HKre4PhtS4DauE8WAhrjyRZbnlRGHI3iECtYtBYProUWd4bMCwV2OlHtg+oABYgSWD5Iy2KKo1dGhDsMLaI9CJoqt5C2HMdSxR9gakPLQySsxLZ029dJcWkMLFN7eGYeyyet9xGptgbnFIaEGjUR/3N05BlQlgCjlrXaUQ2ArVSSJeDHDX7u1XvH2oUOZu5bvB/kS2p+lhxhPU8tlO+Uh67PnvLdoPBI5uIaL/IKZCV2HuLC2145OXWvdZozg4KG1V9eLNw3N+W6gIqfmF0i8JkkQb/DnvCo9ebK2AKxtp6fbTPSjfA9jVgZPsUk9wwTAEFSKVGCBeO5VzSgApssJwtiajApuxlj9rLzje+2LqavEXENMBF4W/fwSLQNFh4U7XZY+xn7SKOqLNTGvi9wd+lQfTo7WONbyw36VUqFVThPIHFIxYYvyngaw3vDS5lJ8JKRBnUgvQD0+afkvobsJ4UsT4os0U8qcBMRN3kQnRBArPjtDxQyi89l6LLbwsMi2lAHzlKcpY+mSf6aBmY9Un5laWCKppXRDbrG1kDaienINJQ5Ta9TuwXjuUrovk+y1vE8E2BPuT676PuEdLRRyV1k1TRxec61nP7e+hHaUB7g8adjCNLsJP0dms+09OncfniXFzIHq1SNrX384/TQP3iOeRH9YDiLaLL2Tnczz4VWKp4/oYfXshezNpCH30C4VhtYrc27x9LfGi5LXZduCjUb2Jkr1bg3rNZhjw4yOEPcsli1kOd1WoAAAAASUVORK5CYII=")',
					backgroundSize: '100% 100%'
				},

				'& .section': {
					background: '#FFFFD5',

					'& .magic-text': {
						'& textarea': {
							backgroundColor: 'transparent',
							color: theme.colors.black,
							'&:disabled': {
								cursor: 'not-allowed'
							},
							'&:not(:disabled)': {
								fontWeight: '600'
							}
						}
					},

					'& .notes': {
						margin: '5px 20px 0 0',
						lineHeight: '42px'
					},

					'& .notes, & h4': {
						lineHeight: '1.4',
						flexShrink: 0
					}
				}
			},

			'&.not-selected': {
				borderRadius: '8px',
				boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
				background: theme.colors.lightGrey,

				'& .title-bar': {
					height: 'auto',
					borderRadius: '8px 8px 0 0',
					background: '#D1D3D3',
					color: theme.colors.grey,

					'& .title': {
						fontSize: '18px',
						color: theme.colors.grey
					},

					'& .letter': {
						display: 'none'
					},

					'& .info, & .edit': {
						width: '16px',
						height: '16px',

						position: 'absolute',
						top: '13px',
						right: '12px',
						background: 'transparent',
						fontSize: '16px',
						color: theme.colors.grey
					},

					'& .edit': {
						top: '6px'
					}
				},

				'& .section': {
					background: theme.colors.lightGrey,

					'& .writing-content': {
						padding: '16px'
					},

					'& h4, & .notes': {
						lineHeight: '1.8',
						fontSize: '18px',
						marginTop: '0',
						fontFamily: theme.fonts.context,
						top: isFirefox() ? '0' : '-0.5px'
					}
				},

				'& .writing-area': {
					padding: '0px 20px 0 0'
				},

				'&.completed': {
					'& .title-bar': {
						background: '#FFFCA1',
						color: theme.colors.softBlack
					},
					'& .section': {
						background: '#FFFFD5'
					}
				}
			}
		}
	},

	titleBar: {
		position: 'relative',
		width: '100%',
		height: '48px',
		padding: '8px 16px',
		background: '#FFFCA1',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		alignItems: 'stretch',
		'& .letter': {
			display: 'inline-block',
			width: '32px',
			height: '32px',
			borderRadius: '16px',
			background: theme.colors.white,
			border: `2px solid ${theme.colors.writingZone.green3}`,
			boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.35)',
			color: theme.colors.writingZone.background,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '24px',
			textAlign: 'center',
			marginRight: '16px',
			lineHeight: isFirefox() ? '26px' : '28px'
		},
		'& .title': {
			display: 'inline-block',
			color: '#37383C',
			fontFamily: theme.fonts.buttonBold,
			fontSize: '22px',
			position: 'relative',
			top: '0.5px'
		},
		'& .info, & .edit': {
			position: 'absolute',
			top: '13px',
			right: '12px',
			width: '24px',
			height: '24px',
			background: '#FFFCA1',
			borderRadius: '12px',
			color: '#37383C',
			fontSize: '22px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	},
	inactiveTitleBar: {
		position: 'relative',
		width: '100%',
		height: '32px',
		padding: '4px 24px',
		background: theme.colors.writingZone.green4,
		marginTop: '3px',
		'& .title': {
			display: 'inline-block',
			color: theme.colors.writingZone.background,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '18px'
		}
	},
	completedWriting: {
		fontSize: '18px',
		fontFamily: theme.fonts.context,
		color: theme.colors.grey,
		width: '100%',
		lineHeight: `32px`,
		padding: '16px'
	},
	writingArea: {
		width: '100%',
		height: 'auto',
		padding: '10px 20px 0',
		background: 'transparent',

		display: 'flex',
		alignItems: 'flex-start',

		'& h4': {
			margin: '5px 20px 0 0',
			fontSize: '20px',
			lineHeight: '42px',
			position: 'relative',
			top: isFirefox() ? '5.5px' : '5px',
			fontWeight: '700',
			'& button': {
				transition: 'transform 0.3s ease',
				color: theme.colors.slate,
				marginRight: '6px',
				position: 'relative',
				top: isFirefox() ? '0' : '-0.5px',
				'&:hover': {
					transform: 'scale(1.2)'
				}
			}
		},

		'&.inactive': {
			'& h4': {
				top: isFirefox() ? '6px' : '5px',
				color: '#63757E'
			}
		}
	},

	buttonRow: {
		width: '100%',
		padding: '32px 16px',
		textAlign: 'right',
		background: 'transparent',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'& button:first-of-type': {
			marginRight: '32px'
		}
	}
}));

const WritingPanelNote = props => {
	const {
		className,
		panels,
		isActive,
		isCompleted,
		title,
		letter,
		resetClicked,
		isResetClicked,
		onTextChange,
		onPanelChange,
		onPanelCompleted,
		updatePanelsGo,
		onRevise,
		orderID
	} = props;

	const classes = useStyles();
	const {setState} = useBatchedSetState();

	const ref = useRef();

	let mappedSections = {};
	panels.forEach(p => {
		mappedSections[p.title] = 2;
	});

	const [sections, setSections] = useState(panels);
	const [selectedPanelIndex, setSelectedPanelIndex] = useState(0);
	const [currentText, setCurrentText] = useState('');
	const [enableGoOn, setEnableGoOn] = useState(false);
	const [enableReset, setEnableReset] = useState(true);
	const [isShrinking, setShrinking] = useState(false);
	const [rowCount, setRowCount] = useState(mappedSections);
	const [isReset, setReset] = useState(isResetClicked);
	const [isResetModalOpen, setResetModalOpen] = useState(false);

	useEffect(() => {
		if (isActive) {
			window.scrollTo({
				left: 0,
				top: ref.current.offsetTop - ref.current.offsetHeight,
				behavior: 'smooth'
			});
		}
	}, [isActive]);

	const handleTextChange = (text, rows) => {
		AudioPlayer2.stopAll();

		// calculate how tall to make the MagicTextArea
		const title = sections[selectedPanelIndex].title;
		let newRowCount = cloneDeep(rowCount);
		newRowCount[title] = Math.max(rows, newRowCount[title]);

		// update the text for this section
		const _sections = [...sections];
		_sections[selectedPanelIndex].notes = currentText;

		setState(() => {
			setSections(_sections);
			setCurrentText(text);
			setRowCount(newRowCount);
		});

		if (typeof onTextChange === 'function') {
			onTextChange(selectedPanelIndex, text);
		}
	};

	const handleNext = async () => {
		AudioPlayer2.stopAll();
		// the commented out code is when the user needs to press "Go On" to enable each section of a card
		// const _sections = [...sections];
		// _sections[selectedPanelIndex].notes = currentText;
		setState(() => {
			// setSections(_sections);
			setCurrentText('');
			setEnableGoOn(false);
		});

		if (selectedPanelIndex < sections.length - 1) {
			setSelectedPanelIndex(selectedPanelIndex + 1);

			if (typeof onPanelChange === 'function') {
				onPanelChange(sections, selectedPanelIndex + 1);
			}
		} else {
			setShrinking(true);
			setState(async () => {
				await sleep(400);
				setShrinking(false);
				setEnableReset(false);
				setSelectedPanelIndex(0);
			});
			if (typeof onPanelCompleted === 'function') {
				onPanelCompleted(sections, selectedPanelIndex);
			}
		}
	};

	const handleReset = () => {
		AudioPlayer2.stopAll();
		setResetModalOpen(true);
	};

	const handleConfirmReset = () => {
		setState(() => {
			setEnableGoOn(false);
			setReset(true);
			setResetModalOpen(false);
			setSections(panels);
			setSelectedPanelIndex(0);
		});
		setTimeout(() => {
			setReset(false);
		}, 600);
	}

	const handleCancelReset = () => {
		setResetModalOpen(false);
	}

	const updateGoOn = currID => {
		// the commented code is when the user needs to press the "Go On" button to enable each section
		// if (selectedPanelIndex.toString() === currID && !enableGoOn) {
		// 	setEnableGoOn(true);

		// 	if (typeof updatePanelsGo === 'function') {
		// 		updatePanelsGo(currID);
		// 	}
		// }
	};

	const handleMinimumCountMet = (id) => {
		setSelectedPanelIndex(Math.max(selectedPanelIndex, Math.min(parseInt(id) + 1, sections.length - 1)));
		if (parseInt(id) === sections.length - 1) {
			setEnableGoOn(true);
		}
	};

	const handleMinimumCountUnmet = (id) => {
		setEnableGoOn(false);
	}

	const handleInfo = () => {
		AudioPlayer2.stopAll();
		if (letter.toLowerCase() === 'b') {
			AudioPlayer2.play('narrative-beginning');
		} else if (letter.toLowerCase() === 'm') {
			AudioPlayer2.play('narrative-middle');
		} else if (letter.toLowerCase() === 'e') {
			AudioPlayer2.play('narrative-end');
		}
	};

	const handleRevise = () => {
		AudioPlayer2.stopAll();
		setState(() => {
			setSelectedPanelIndex(sections.length - 1);
			setEnableGoOn(true);
		});
		if (typeof onRevise === 'function') {
			onRevise(orderID);
		}
	};

	const handleSpeaker = title => {
		AudioPlayer2.stopAll();
		const sound = sections.filter(s => {
			return title === s.title;
		})[0];
		AudioPlayer2.play(sound.audio.name);
	};

	const renderActivePanel = sections => {
		return sections.map((section, idx) => {
			const {title, text, notes} = section;
			//const textHeight = text.match(/(?:^|\s)([0-9]+)\)(?=$|\s)/g) ? 'auto' : '42px';
			const textHeight = `${(rowCount[title] - 1) * 42}px`;
			const active = idx <= selectedPanelIndex;

			return (
				<React.Fragment key={idx}>
					<div
						className={`${
							classes.writingArea
						} writing-area ${!active && 'inactive'}`}
					>
						{isActive ? (
							<>
								<h4>
									<button
										onClick={() => handleSpeaker(title)}
										data-testid="speaker"
									>
										<i className="r180 speaker-center" />
									</button>
									{title}:
								</h4>
								<MagicTextArea
									className="magic-text"
									disabled={!active}
									textAreaHeight={textHeight}
									startText={notes || text}
									onTextChange={handleTextChange}
									isResetTriggered={isReset}
									resetClicked={resetClicked}
									updateGoOn={updateGoOn}
									id={idx.toString()}
									onMinimumCountMet={handleMinimumCountMet}
									onMinimumCountUnmet={handleMinimumCountUnmet}
								/>
							</>
						) : (
							<>
								{section.notes && (
									<>
										<h4>{title}: </h4>
										<span className="notes">{notes}</span>
									</>
								)}
							</>
						)}
					</div>
				</React.Fragment>
			);
		});
	};

	return (
		<div
			ref={ref}
			className={`writing-panel-note ${classes.wrapper} ${className}
            			${isActive ? 'selected' : 'not-selected'}
						${isCompleted ? 'completed' : ''}
						${isShrinking ? 'shrinking' : ''}
					  `}
		>
			<div className="dog-ear"></div>
			<div className={`section`}>
				<div className={`${classes.titleBar} title-bar`}>
					<div className={`letter`}>{letter}</div>
					<div className="title">{title} Notes</div>
					{isCompleted ? (
						<button className="edit" onClick={handleRevise}>
							<i className="r180 edit" />
						</button>
					) : (
						<button
							className="info"
							onClick={handleInfo}
							data-testid="revise"
						>
							<i className="r180 instructions" />
						</button>
					)}
				</div>

				<div className="writing-content">
					{renderActivePanel(sections)}
				</div>
			</div>

			{isActive && (
				<div className={`${classes.buttonRow}`}>
					{/* Using ActivityButton to be consistent with WritingActivity */}
					<ActivityButton
						onClick={handleReset}
						icon="reset"
						id="reset"
						isPrimary={false}
						text="Reset"
						disabled={enableReset === false}
					/>
					<ActivityButton
						onClick={handleNext}
						icon="arrow-right"
						id="go-on"
						isPrimary={true}
						text="Go On"
						disabled={enableGoOn === false}
					/>
				</div>
			)}

			{isResetModalOpen &&
				<ConfirmationModal
					open={isResetModalOpen}
					onPrimaryButtonClicked={handleConfirmReset}
					onCloseClicked={handleCancelReset}
					onSecondaryButtonClicked={handleCancelReset}
					primaryButtonText={"Yes"}
					secondaryButtonText={"No"}
					subTitle="Are you sure you want to reset?  All the text you typed in this card will be erased."

				/>
			}

		</div>
	);
};

WritingPanelNote.defaultProps = {
	className: '',
	sections: [],
	activePanelIndex: 0
};
WritingPanelNote.propTypes = {
	className: PropTypes.string,
	sections: PropTypes.array.isRequired,
	activePanelIndex: PropTypes.number
};

export default React.memo(WritingPanelNote);
