/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import HMHRedux from '../redux-util';
import sessionData from '../../mock/data/session.json';

// slice constants
const sliceName = 'session';

// slice actions
export const startSessionAction = HMHRedux.getOne(sliceName);
export const logout = HMHRedux.async(sliceName, 'logout');
export const setSettingAction = HMHRedux.patch(sliceName);

const fixSettings = (settings)=>{
	return Object.keys(settings).reduce((acc,key)=>{
		if(settings[key]==="true"){
			acc[key]=true;
			return acc;
		}
		if(settings[key]==="false"){
			acc[key]=false;
			return acc;
		}
		acc[key]=settings[key];
		return acc;
	},{});
}

// slice reducers
export const sessionSlice = HMHRedux.createSlice({
	name: sliceName,
	initialState: process.env.REACT_APP_ENV === 'dev' ? { session: {...sessionData,settings:fixSettings(sessionData.settings)} } : { session: { settings: {} }},
	reducers: {
		setSetting: (state, action)=>{
			state.session.settings[action.payload.variable] = action.payload.value;
		}
	},
	extraReducers: {
		[startSessionAction.fulfilled]: (state, action) => {
			const mappedSettings = fixSettings(action.payload.settings);
			state.session = {
				...action.payload,
				settings:mappedSettings
			};

		},
		[setSettingAction.fulfilled]: (state, action) => {
			// TODO add reducer code here when server team updates the response object
		},
		[logout.fulfilled]: (state, action) => {
			state = undefined;
		}
	}
});


export default sessionSlice.reducer;
