import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import {RadioCheck, SoundButton} from '@reading/common';
import Prompt from './Prompt';
import { WordMeaningMatchConstants } from './WordMeaningMatchConstants';
import { bulkReplace } from '../../../utils/stringUtils';
import { WordMeaningMatchUtil } from './WordMeaningMatchUtil';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& .speaker-center': {
			color: theme.colors.readingZone.background,
			fontSize: '24px',
			marginRight: '30px'
		},
		'& .picture-panel': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around',
			alignItems: 'center',
			'& .sentence': {
				fontSize: '48px',
				textAlign: 'center',
				height: '160px',
				width: '100%'
			},
			'& .picture': {
				display: 'flex',
				justifyContent: 'space-around',
				alignItems: 'center',
				'& > img': {
					width: '385px',
					height: 'auto',
					border: `2px solid ${theme.colors.paleGrey}`,
					borderRadius: '8px'
				},
				'& > div': {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-evenly',
					alignItems: 'center',
					width: '285px',
					paddingLeft: '100px',
					'& > div': {
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						marginBottom: '48px',
						'& > div': {
							'&.radio-check': {
								position: 'relative',
								top: '-5px',
								left: '-1px',
								marginRight: '34px',
							},
							'&.sound-button': {
								position: 'relative',
								top: '3px',
								marginRight: '24px',
							}
						},
						'& span': {
							fontSize: '32px',
							height: '50px'
						}
					}
				}
			}
		}
	},
}));

const ReadAloudPrompt = props => {
	const {id, className, question, onValidationReady, isGradable} = props;
	const classes = useStyles();

	const { setState } = useBatchedSetState();

	const [currentPanel, setCurrentPanel] = useState(0);
	const [isYesSelected, setYesSelected] = useState(false);
	const [isNoSelected, setNoSelected] = useState(false);
	const [isCorrect, setCorrect] = useState(false);

	const handleSelect = (selection) => {
		// ignore clicks when we're grading
		if (isGradable) {
			return;
		}
		setState(() => {
			setYesSelected(selection === true);
			setNoSelected(selection === false);
			setCorrect(selection === question.sentence.answer);
		});
		// callback to Activity to enable Submit button
		onValidationReady(selection === question.sentence.answer);
	}

	const handleWordComplete = async() => {
		setCurrentPanel(1);
	};

	const handleSentenceComplete = () => {
		setCurrentPanel(2);
	}

	const yesStatus = isYesSelected && isCorrect && isGradable ? 'correct' : isYesSelected && isCorrect === false && isGradable ? 'incorrect' : 'initial';
	const noStatus = isNoSelected && isCorrect && isGradable ? 'correct' : isNoSelected && isCorrect === false && isGradable  ? 'incorrect' : 'initial';

	const noVisible = yesStatus === 'initial';
	const yesVisible = noStatus === 'initial';

	return (
		<div className={`${classes.wrapper} ${className} read-aloud-prompt`} id={id}>

		{
			currentPanel === 0 && (
				<Prompt
					key={question.text}
					text={question.text}
					onComplete={handleWordComplete}
					isAllCaps={true}
				/>
			)
		}
		{
			currentPanel === 1 && (
				<Prompt
					key={question.sentence.text}
					text={WordMeaningMatchUtil.getRidOfBrackets(question.sentence.text)}
					onComplete={handleSentenceComplete}
					isAllCaps={false}
				/>
			)
		}
		{
			currentPanel === 2 && (
				<div className='picture-panel animate__animated animate__zoomIn'>
					<div className='sentence'>{WordMeaningMatchUtil.getRidOfBrackets(question.sentence.text)}</div>
					<div className='picture'>
						<img src={bulkReplace(WordMeaningMatchConstants.IMAGE_PATH, {word: question.text})} alt='Word Meaning Match' />
						<div>
							{
								yesVisible && (
									<div>
										<RadioCheck
											onSelect={() => handleSelect(true)}
											isSelected={isYesSelected}
											status={yesStatus}
										/>
										<SoundButton
											border={false}
											icon="speaker-center"
											size={32}
											sound={{
												name: 'Sound',
												src: 'https://file-examples-com.github.io/uploads/2017/11/file_example_OOG_1MG.ogg'
											}}
										/>
										<span>Yes</span>
									</div>
								)
							}
							{
								noVisible && (
									<div>
										<RadioCheck
											onSelect={() => handleSelect(false)}
											isSelected={isNoSelected}
											status={noStatus}
										/>
										<SoundButton
											border={false}
											icon="speaker-center"
											size={32}
											sound={{
												name: 'Sound',
												src: 'https://file-examples-com.github.io/uploads/2017/11/file_example_OOG_1MG.ogg'
											}}
										/>
										<span>No&nbsp;</span>
									</div>
								)
							}

						</div>
					</div>
				</div>
			)
		}

		</div>
	);
};

ReadAloudPrompt.defaultProps = {
	id: '',
	className: '',
	text: '',
	question: {},
	isGradable: false
};
ReadAloudPrompt.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	question: PropTypes.object.isRequired,
	onValidationReady: PropTypes.func.isRequired,
	isGradable: PropTypes.bool,
	onQuestionComplete: PropTypes.func.isRequired,
};

export default React.memo(ReadAloudPrompt);
