import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'stretch',
		background: theme.colors.white
	},
	playButton: {
		height: '64px',
		width: '64px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		color: theme.colors.fluencyZone.blue6,
		background: theme.colors.white,
		fontSize: '30px',
		opacity: 1,
		zIndex: 999,
		'&.isPlaying': {
			background: theme.colors.readingZone.blue5
		}
	},
	outerCircle: {
		width: '144px',
		height: '144px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		backgroundColor: 'transparent'
	},
	recordAnimate: {
		backgroundColor: theme.colors.fluencyZone.blue5,
		width: '70px',
		height: '70px',
		borderRadius: '50%',
		position: 'absolute',
		top: '37px',
		WebkitAnimationName: 'ripple 2s infinite',
		animation: '$record-animate 2s infinite'
	},
	recordAnimateProgress: {
		backgroundColor: theme.colors.fluencyZone.blue5,
		width: '70px',
		height: '70px',
		borderRadius: '50%',
		position: 'absolute',
		top: '37px',
		transition: `transform 0.2s ease`
	},
	showBackground: {
		backgroundColor: theme.colors.fluencyZone.blue5
	},
	circularProgressWrapper: {
		position: 'absolute',
		top: '37px',
		'& .MuiCircularProgress-root.MuiCircularProgress-colorPrimary': {
			color: theme.colors.fluencyZone.blue7
		}
	},
	'@keyframes record-animate': {
		'0%': {
			transform: 'scale(1)'
		},
		'50%': {
			transform: 'scale(2)',
			opacity: 0.8
		},
		'100%': {
			transform: 'scale(1)'
		}
	}
}));
const PlayProgress = props => {
	const {
		progress,
		isRecording,
		icon,
		handleRecording,
		animateVolume,
		volumeLevel,
		volumeAnimateScale,
		isPlaying
	} = props;
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<div
				className={`${classes.outerCircle}`}
			>
				{isRecording && <div className={
					animateVolume ? classes.recordAnimateProgress
						: classes.recordAnimate
				} style={{ transform: `scale(${volumeLevel * volumeAnimateScale})` }}></div>}
				<div className={classes.circularProgressWrapper}>
					<CircularProgress
						size={70}
						thickness={1.5}
						variant="static"
						value={progress}
					/>
				</div>

				<button
					type="button"
					data-testid={'playButton'}
					className={`${classes.playButton} ${isPlaying ? 'isPlaying' : ''}`}
					aria-label={icon}
					onClick={() => handleRecording(icon)}
				>
					<i className={`r180 ${icon}`} />
				</button>
			</div>
		</div>
	);
};

PlayProgress.defaultProps = {
	animateVolume: false,
	volumeLevel: 1,
	volumeAnimateScale: 0.5
};

export default React.memo(PlayProgress);
