import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

let perToFixBodyZoom = 0;
let zoomToFixDPI = 1;
if (window.devicePixelRatio < 2 && window.devicePixelRatio > 1 && window.innerHeight <= 720) {
	zoomToFixDPI = 100 * (1 / window.devicePixelRatio);
	perToFixBodyZoom = 100 - zoomToFixDPI;
}

const useStyles = makeStyles(theme => ({
	video: {
		width: '100%',
		height: '100%',
		minHeight: '250px',
		maxWidth: '100%',
	},
	videoZoom: {
		zoom: `${100 + perToFixBodyZoom}%`,
	}
}));

const Video = (props) => {
	const {
		children,
		video,
		onPlay,
		onPause,
		onEnded,
		onSeeking,
		onSeeked,
		onCanPlay,
		handleMetaData,
		handleTimeUpdate,
		handleContextMenu,
		poster,
		videoKey,
		autoPlay,
		isZoom
	} = props;

	const classes = useStyles();
	return (
		<video
			playsInline
			autoPlay={autoPlay}
			ref={video}
			controlsList="nodownload"
			id="v"
			width="100%"
			height="auto"
			crossOrigin="anonymous"
			onPlay={onPlay}
			onPause={onPause}
			onEnded={onEnded}
			onSeeking={onSeeking}
			onSeeked={onSeeked}
			onCanPlay={onCanPlay}
			onLoadedMetadata={handleMetaData}
			onTimeUpdate={handleTimeUpdate}
			onContextMenu={handleContextMenu}
			poster={poster}
			key={videoKey}
			className={`${classes.video} ${isZoom ? classes.videoZoom : ''}`}
		>
			{children}
		</video>
	);
}

export default Video;

Video.defaultProps = {
	children: React.node,
	onEnded: () => null,
	handleContextMenu: () => null,
	videoKey: '',
	autoPlay: true
};
Video.propTypes = {
	children: PropTypes.node,
	video: PropTypes.object.isRequired,
	onPlay: PropTypes.func.isRequired,
	onPause: PropTypes.func.isRequired,
	onEnded: PropTypes.func,
	onSeeking: PropTypes.func.isRequired,
	onSeeked: PropTypes.func.isRequired,
	onCanPlay: PropTypes.func.isRequired,
	handleMetaData: PropTypes.func.isRequired,
	handleTimeUpdate: PropTypes.func.isRequired,
	handleContextMenu: PropTypes.func,
	poster: PropTypes.string,
	videoKey: PropTypes.string,
	autoPlay: PropTypes.bool
};


