import {cloneDeep, isEmpty} from 'lodash';
import React, {useCallback, useMemo, useRef} from 'react';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useDeepCompareEffect, useUnmount} from 'react-use';
import ActivityFrame from '../../../containers/App/ActivityFrame';
import ActivitySuspense from '../../../containers/App/ActivitySuspense';
import Footer from '../../../containers/App/Footer';
import FooterForwardBack from '../../../containers/App/FooterForwardBack';
import Navbar from '../../../containers/App/Navbar';
import {
	SoundButton,
	RadioCheck,
	ActivityInstructionButton,
	FoundationalIntro,
	ActivitySupportButton
} from '@reading/common';
import {makeStyles} from '@material-ui/core';
import {ReadAndThinkUtil} from './ReadAndThinkUtil';
import useBatchedSetState from '../../../utils/useBatchedSetState';
import {useHistory} from 'react-router-dom';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {uiSlice} from '../../../store/slices/ui';
import FSProgressBar from '../../../containers/App/FSProgressBar';
import FoundationalIntroModal from '@reading/common/src/components/FoundationalIntro/FoundationIntroModal';
import {FoundationalUtil} from '../FoundationalUtil';
import {ReadAndThinkConstants} from './ReadAndThinkConstants';
import {sleep} from '../../../utils/sleep';

const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	questionText: {
		fontSize: '32px',
		fontWeight: '700'
	},
	questionWrapper: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	anchorSentence: {
		height: '60px',
		fontSize: '24px',
		fontWeight: '600',
		marginTop: '60px',
		marginBottom: '20px'
	},
	questionTextWrapper: {
		display: 'flex',
		alignItems: 'center',
		height: '120px',
		fontSize: '32px',
		fontWeight: 'bold',
		marginBottom: '30px',
		'& .sound-button': {
			marginRight: '24px'
		}
	},
	optionsContainer: {
		'& .option': {
			marginBottom: '60px',
			'&:last-of-type': {
				marginBottom: '0'
			},
			'& .radio-check': {
				display: 'inline-block',
				marginRight: '24px'
			},
			'& .text': {
				display: 'inline-block',
				fontSize: '20px',
				fontWeight: '600',
				position: 'relative',
				top: '6px'
			}
		}
	}
}));

export default function ReadAndThinkActivity(props) {
	const activityName = 'read_think';

	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const {setState} = useBatchedSetState();

	const questionTimer = useRef(Date.now());
	const questionTimeLimit = useRef(null);
	const interactive = useRef(true);

	const {activityData, contentData, saveTemplate, settings, isActivityCharged} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			contentData: state.activity.content,
			saveTemplate: state.activity.save_template,
			settings: state.activity.settings,
			isActivityCharged: state.ui.chargedActivities[activityName] === true
		};
	});

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	// settings common to all FS activities
	const {
		activityTimeLimit,
		isFirstVisit,
		isAccuracyOnly,
		isAutoplay,
		numVisits,
		isActivity
	} = FoundationalUtil.getActivitySettings(activityData, contentData, settings, activityName);

	// state variables/functions common to all FS activities
	const [currentScreen, setCurrentScreen] = useState(isFirstVisit ? 1 : 2);
	const [forwardStatus, setForwardStatus] = useState(FooterForwardBack.START);
	const [isForwardDisabled, setForwardDisabled] = useState(true);
	const [isPaused, setPaused] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [helpCount, setHelpCount] = useState(1);
	const [currentVO, setCurrentVO] = useState('real');
	const [isActvityButtonsDisabled, setActivityButtonsDisabled] = useState(false);

	// state variables/functions unique to this activity
	const [questionData, setQuestionData] = useState({});
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [currentQuestion, setCurrentQuestion] = useState({});
	const [answer, setAnswer] = useState({});
	const [panel, setPanel] = useState(1);
	const [selectedOption, setSelectedOption] = useState('');
	const [isGradeable, setGradeable] = useState(false);

	// all FS activities use this code to kick things off
	useDeepCompareEffect(() => {
		const init = async () => {
			if (isActivityCharged === false) {
				const data = await ReadAndThinkUtil.initialize({contentData});
				setQuestionData(data.questionData);
				setCurrentQuestion(data.questionData.rounds[currentQuestionIndex]);
				dispatch(uiSlice.actions.setActivityCharged(activityName));
			}

			if (currentScreen === 2) {
				await sleep(1600);
				startActivity();
			}
		};

		if (isEmpty(contentData) === false && isActivity) {
			init();
		}
	}, [contentData, isActivity]);

	// all FS activities will use this code to start the actual activity (not the start screen)
	const startActivity = () => {
		AudioPlayer2.stopAll();
		questionTimer.current = Date.now();
		setState(() => {
			setAnswer(saveTemplate);
			setForwardDisabled(true);
			setForwardStatus(FooterForwardBack.VALID);
		});
	};

	// all FS activities will have a pause button
	const handlePause = () => {
		if (isAutoplay) {
			AudioPlayer2.play(ReadAndThinkConstants.COVER_UP);
		}
		setPaused(true);
	};

	// all FS activities will have a Continue button (opposite "Pause")
	const handleContinue = async () => {
		if (isAutoplay) {
			await AudioPlayer2.playSync(ReadAndThinkConstants.COVER_OFF);
		}

		setState(() => {
			setPaused(false);
		});
		questionTimer.current = Date.now();
	};

	// all FS activities will handle the instructions button in the bottom left corner
	const handleInstructions = () => {
		setModalOpen(true);
		if (isEmpty(currentQuestion) === false) {
			handlePause();
		}
	};

	// all FS activities will handle the instruction icon in the upper left corner
	const handleInstructionClick = () => {
		AudioPlayer2.stopAll();
		if (isEmpty(currentQuestion) === false) {
			handlePause();
		}
	};

	// all FS activities have to determine the right instruction VO to play
	const getInstructionVOSrc = useCallback(() => {
		return ReadAndThinkUtil.getRandomizedSound(isFirstVisit, currentVO);
	}, [isFirstVisit, currentVO]);

	// all FS activities will handle the video completion
	const handleVideoComplete = useCallback(() => {
		if (currentScreen === 1) {
			setForwardDisabled(false);
		}
	}, [currentScreen]);

	// all FS activities will handle the close button
	const handleModalClose = () => {
		setModalOpen(false);
	};

	// all FS activities will display an Intro modal
	const intro = useMemo(
		() => (
			<FoundationalIntro
				title="Read and Think"
				videoUrl={''}
				icons={[
					{text: 'Listen', icon: 'listen'},
					{text: 'Find', icon: 'preview'},
					{text: 'Click', icon: 'spot'}
				]}
				onVideoComplete={handleVideoComplete}
				isFirstVisit={isFirstVisit}
				instructionSoundSrc={getInstructionVOSrc()}
			/>
		),
		[handleVideoComplete, isFirstVisit, getInstructionVOSrc]
	);

	// all FS activities will handle the help button (which may go away in the future)
	const handleHelpClick = () => {
		AudioPlayer2.stopAll();
		handlePause();
	};

	// all FS activities will handle the Go On button
	const handleForward = () => {
		// the intro panel is done, move to the question
		if (currentScreen === 1) {
			setState(() => {
				setCurrentScreen(2);
				startActivity();
			});
		} else if (currentScreen === 2) {
			// the screen showing the sentence by itself
			if (panel === 1) {
				setState(() => {
					setPanel(2);
					setForwardDisabled(true);
					setForwardStatus(FooterForwardBack.SUBMIT);
				});
			} else {
				// they've made their selection, time to grade it
				if (isGradeable === false) {
					let tempQuestion = cloneDeep(currentQuestion);
					tempQuestion.answers.forEach(a => {
						if (a.text === selectedOption.text) {
							a.status = selectedOption.isCorrect ? 'correct' : 'incorrect';
						}
					});

					// figure out how many they've gotten incorrect so far
					const incorrectCount = tempQuestion.answers.filter(a => {
						return a.status === 'incorrect';
					}).length;

					// if it's now 2, mark the other one as correct
					if (incorrectCount === 2) {
						tempQuestion.answers.filter(a => {
							return a.status === '';
						})[0].status = 'correct';
					}

					const isQuestionComplete = selectedOption.isCorrect || incorrectCount === 2;

					// update the answer
					let tempAnswer = cloneDeep(answer);
					const attempts = tempAnswer.results[currentQuestionIndex].attempts
						? tempAnswer.results[currentQuestionIndex].attempts
						: 0;
					tempAnswer.results[currentQuestionIndex].attempts = attempts + 1;
					tempAnswer.results[currentQuestionIndex].score = isQuestionComplete ? 'correct' : 'incorrect';
					tempAnswer.results[currentQuestionIndex].tracked_time = Date.now() - questionTimer.current;
					tempAnswer.has_partial = true;
					let trackedTime = 0;
					tempAnswer.results.forEach(s => {
						trackedTime += s.tracked_time;
					});
					tempAnswer.tracked_time = trackedTime;
					FoundationalUtil.sendProgressToServer(tempAnswer);

					setState(() => {
						setAnswer(tempAnswer);
						setGradeable(isQuestionComplete);
						setForwardStatus(isQuestionComplete ? FooterForwardBack.VALID : FooterForwardBack.SUBMIT);
						setForwardDisabled(isQuestionComplete === false);
						setCurrentQuestion(tempQuestion);
					});
				} else {
					// they're done with the questions
					if (currentQuestionIndex === questionData.rounds.length - 1) {
						setState(() => {
							setCurrentScreen(3);
							setForwardStatus(FooterForwardBack.VALID);
							setForwardDisabled(false);
						});

						let tempAnswer = cloneDeep(answer);
						tempAnswer.has_partial = false;

						try {
							FoundationalUtil.completeActivity(history, tempAnswer);
						} catch (err) {
							dispatch(uiSlice.actions.setErrorMessage(err));
						}
					}
					// time to move to the next question
					else {
						setState(() => {
							setPanel(1);
							setGradeable(false);
							setForwardDisabled(false);
							setCurrentQuestionIndex(currentQuestionIndex + 1);
							setCurrentQuestion(questionData.rounds[currentQuestionIndex + 1]);
						});
						questionTimer.current = Date.now();
					}
				}
			}
		} else {
		}
	};

	/******************
	 *
	 *   Custom code specific for your activity goes here
	 *
	 ******************/

	const handleRadioSelection = selection => {
		setState(() => {
			setSelectedOption(selection);
			setForwardDisabled(false);
		});
	};

	return (
		<>
			<Navbar helpSoundUrl={''} onHelpClick={handleHelpClick} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={[contentData, activityData]}
				title="Read and Think"
			>
				<ActivityFrame isWhiteBackground={true}>
					<>
						<FSProgressBar title="Smart Zone" />

						<ActivityInstructionButton fs={true} audioSrc={getInstructionVOSrc()} />

						<div className={classes.wrapper}>
							{currentScreen === 1 && intro}

							{isPaused && <div className="paused">&nbsp;</div>}

							{currentScreen === 2 && panel === 1 && isPaused === false && (
								<div className={classes.questionText}>{currentQuestion.anchor_sentence}</div>
							)}

							{currentScreen === 2 && panel === 2 && isPaused === false && (
								<div className={`${classes.questionWrapper} animate__animated animate__zoomIn`}>
									<div className={classes.anchorSentence}>{currentQuestion.anchor_sentence}</div>
									<div className={classes.questionTextWrapper}>
										<SoundButton
											sound={{name: currentQuestion.id, src: currentQuestion.src}}
											size={32}
											border={false}
										/>
										<div className={classes.question}>{currentQuestion.question}</div>
									</div>
									<div className={classes.optionsContainer}>
										<div className={`option`}>
											<RadioCheck
												id={`radio-check-0`}
												item={currentQuestion.answers[0]}
												isSelected={selectedOption.text === currentQuestion.answers[0].text}
												type={'single'}
												status={currentQuestion.answers[0].status}
												onSelect={handleRadioSelection}
											/>
											<div className={'text'}>{currentQuestion.answers[0].text}</div>
										</div>
										<div className={`option`}>
											<RadioCheck
												id={`radio-check-1`}
												item={currentQuestion.answers[1]}
												isSelected={selectedOption.text === currentQuestion.answers[1].text}
												type={'single'}
												status={currentQuestion.answers[1].status}
												onSelect={handleRadioSelection}
											/>
											<div className={'text'}>{currentQuestion.answers[1].text}</div>
										</div>
										<div className={`option`}>
											<RadioCheck
												id={`radio-check-2`}
												item={currentQuestion.answers[2]}
												isSelected={selectedOption.text === currentQuestion.answers[2].text}
												type={'single'}
												status={currentQuestion.answers[2].status}
												onSelect={handleRadioSelection}
											/>
											<div className={'text'}>{currentQuestion.answers[2].text}</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</>
				</ActivityFrame>

				{isModalOpen && (
					<FoundationalIntroModal isOpen={isModalOpen} onClose={handleModalClose}>
						{intro}
					</FoundationalIntroModal>
				)}

				<Footer>
					{currentScreen === 2 && isPaused === false && (
						<>
							<ActivitySupportButton
								icon="instructions"
								onClick={handleInstructions}
								text="Instructions"
							/>
							<ActivitySupportButton icon="pause" onClick={handlePause} text="Pause" disabled={false} />
						</>
					)}
					{currentScreen === 2 && isPaused === true && (
						<ActivitySupportButton icon="go-on" onClick={handleContinue} text="Continue" />
					)}
					<FooterForwardBack
						onForward={handleForward}
						isBackVisible={false}
						isForwardVisible={true}
						isForwardDisabled={isForwardDisabled}
						status={forwardStatus}
					/>
				</Footer>
			</ActivitySuspense>
		</>
	);
}
