import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import SoundButton from '../../components/SoundButton';
import ReadingSentenceWord from './ReadingSentenceWord';
import {CorrectIncorrect} from '../..';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
	iconsContainer: {
		display: 'inline-block',
		width: '50px',
		marginRight: '16px'
	},
	readSentenceContainer: {
		display: 'inline-block',
		width: '100%'
	},
	sentenceWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		margin: '20px 10px',
		padding: '20px 10px',
		width: '100%'
	},
	audioIcon: {
		marginRight: '10px',
		position: 'relative',
		top: '-3px',
		'& div': {
			border: 'none',
			'& i': {
				color: theme.colors.slate
			}
		}
	},
	checkmark: {
		borderRadius: '50%',
		color: theme.colors.pureWhite,
		fontSize: '16px',
		position: 'relative'
	},
	serialNumber: {
		marginRight: '10px',
		color: theme.colors.slate
	},
	problemDivider: {
		borderBottom: `1px solid ${theme.colors.paleGrey}`,
		width: '100%',
		'&:last-of-type': {
			border: 'none'
		}
	},
	wordContainer: {
		position: 'relative',
		fontFamily: theme.fonts.context,
		fontSize: '18px',
		lineHeight: '32px',
		letterSpacing: '0.15px'
	},
	sentenceWords: {
		fontFamily: theme.fonts.context,
		fontSize: '18px',
		lineHeight: '32px',
		letterSpacing: '0.15px',
		position: 'relative',
		top: '-3px',
		outline: 'none !important',
		'&:focus': {
			outline: 'none !important'
		}
	},
	disableClass: {
		pointerEvents: 'none',
		cursor: 'not-allowed'
	}
}));

const ReadingSentences = props => {
	const {sentences, result, onSentenceChange, isSelectionDisabled, onBtnDisabled} = props;
	const classes = useStyles();
	const { setState } = useBatchedSetState();

	const REGEX = /<W>(.*?)<\/W>/;
	const [selectedOption, setSeletedOption] = useState({});
	const [isWordsPanelOpen, setWordsPanelOpen] = useState(false);

	const handleHideWordsPanel = event => {
		if (event.key === 'Escape') {
			setWordsPanelOpen(false);
		}
	};
	const handleClickOutside = event => {
		if (
			isWordsPanelOpen &&
			event.target.getAttribute('data-id') !== 'sentenceWord'
		) {
			setWordsPanelOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleHideWordsPanel, true);
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('keydown', handleHideWordsPanel, true);
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	const disableEvents = (index) => {
		return result[index] || isSelectionDisabled ? classes.disableClass : ''
	};

	const handleWordChange = (wordId, word, distractors) => {
		sentences.forEach(sentence => {
			if (wordId === sentence.id) {
				sentence.showFeedBackIcon = false;
				sentence.isSelected = true;
			}
		});
		setState(() => {
			setWordsPanelOpen(true);
			setSeletedOption({
				word: word,
				wordId: wordId,
				distractors: distractors
			});
		});
	};

	const handleListItemClick = (event, wordId) => {
		if (isSelectionDisabled) {
			return;
		}

		event.preventDefault();
		const updateSentences = sentences.map(rowItem => {
			if (wordId === rowItem.id) {
				rowItem.sentence = rowItem.sentence.replace(
					REGEX,
					`<W>${event.target.innerText}</W>`
				);
				rowItem.attemptAgain = false;
			}
			return rowItem;
		});
		setWordsPanelOpen(false);
		onSentenceChange(updateSentences);
	};

	return (
		<>
			{sentences.map((item, index) => {
				const matched = REGEX.exec(item.sentence)[1];
				const sentenceWords = item.sentence.split(REGEX);
				const isCorrect = result[index];
				return (
					<div key={index} className={classes.problemDivider}>
						<div className={classes.readSentenceContainer}>
							<div className={classes.sentenceWrapper}>

								<span className={classes.audioIcon}>
									<SoundButton
										id={`proofreading-${item.text}`}
										className={'proofreading-sentences'}
										size={32}
										sound={{
											name: item.text,
											src: item.sentence_url
										}}
									/>
								</span>
								<span
									tabIndex={0}
									className={classes.serialNumber}
								>
									{index + 1}.
								</span>
								<span
									tabIndex={0}
									className={classes.sentenceWords}
								>
									{sentenceWords.map(word => {
										if (word.match(matched)) {
											return (
												<span
													key={word}
													className={`${
														classes.wordContainer
													} ${disableEvents(index)}`}
												>
													<ReadingSentenceWord
														data-testid={
															'ReadingSentenceWord'
														}
														wordId={item.id}
														word={word}
														spellingZoneDistractors={
															item.spellingZoneDistractors
														}
														isSelected={
															item.isSelected
														}
														selectedOption={
															selectedOption
														}
														isWordsPanelOpen={
															isWordsPanelOpen
														}
														itemIndex={index}
														handleWordChange={
															handleWordChange
														}
														handleListItemClick={
															handleListItemClick
														}
													/>
												</span>
											);
										}
										return word;
									})}
								</span>
								<div className={classes.iconsContainer}>
									{
										item.showFeedBackIcon
										?
											isCorrect
											?
												<CorrectIncorrect />
											:
												<CorrectIncorrect correct={false} />
										:
											null
									}
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

ReadingSentences.defaultProps = {
	sentences: [],
	result: []
};
ReadingSentences.prototype = {
	sentences: PropTypes.array.isRequired,
	result: PropTypes.array,
	onSentenceChange: PropTypes.func,
	onBtnDisabled: PropTypes.func
};

export default React.memo(ReadingSentences);
