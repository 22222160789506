import React from 'react';
import ToolTipDescription from './ToolTipDescription';
import ToolTipAnimation from './ToolTipAnimation';
import PropTypes from 'prop-types';
import {Modal} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
	modalWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	content: {
		background: theme.colors.white,
		borderRadius: '4px',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	closeButton: {
		position: 'absolute',
		top: '15px',
		right: '15px',
		fontSize: '24px',
		color: theme.colors.black,
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.2)'
		}
	}
}));

const ToolTipModal = props => {
	const {
		showDescription,
		showModal,
		index,
		setDescriptionStatus,
		choicesData,
		item,
		setShowModal,
		screenViewIndex,
		setForwardDisabled,
		answeredChoice,
		currentQuestionIndex
	} = props;

	const classes = useStyles();

	const {setState} = useBatchedSetState();

	const handleClose = index => {
		setState(() => {
			showModal[index] = false;
			setShowModal([...showModal]);
			setDescriptionStatus(false);
			if (typeof answeredChoice[currentQuestionIndex] !== 'undefined') {
				setForwardDisabled(false);
			}
		});
	};

	const onAnimateEnd = () => {
		setDescriptionStatus(true);
	};

	return (
		<Modal
			open={showModal[index] || false}
			className={`${classes.modalWrapper} r180-modal`}
		>
			<div
				className={`modal-window`}
				style={{position: 'absolute', top: '300px'}}
			>
				<div className={`${classes.content}`}>
					<ToolTipAnimation
						item={item}
						showDescription={showDescription}
						choicesData={choicesData}
						onAnimateEnd={onAnimateEnd}
						screenViewIndex={screenViewIndex}
						setForwardDisabled={setForwardDisabled}
						answeredChoice={answeredChoice}
					/>
					<ToolTipDescription
						item={item}
						showDescription={showDescription}
						index={index}
						choicesData={choicesData}
					/>
					<button
						className={classes.closeButton}
						onClick={() => handleClose(index)}
					>
						<i className="r180 close-alt" />
					</button>
				</div>
			</div>
		</Modal>
	);
};
ToolTipModal.prototype = {
	showDescription: PropTypes.bool,
	showModal: PropTypes.bool,
	index: PropTypes.number,
	setDescriptionStatus: PropTypes.func,
	choicesData: PropTypes.array,
	item: PropTypes.array,
	setShowModal: PropTypes.func
};

ToolTipModal.defaultProps = {
	showDescription: false,
	index: 0,
	item: {},
	choicesData: {}
};

export default React.memo(ToolTipModal);
