import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {isEmpty} from 'lodash';
import SoundButton from '../SoundButton';

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		width: '340px',
		padding: '8px',
		background: 'rgba(255, 255, 255, 0.3)',
		borderRadius: '8px',
		boxShadow: 'inset 0px -34px 42px rgba(0, 0, 0, 0.25)',
		'&.reading': {
			'& .title-card': {
				background: theme.colors.readingZone.blue5,
				color: theme.colors.readingZone.background
			}
		},
		'&.writing': {
			'& .title-card': {
				background: theme.colors.writingZone.green5,
				color: theme.colors.writingZone.background
			}
		},
		'&.fluency': {
			'& .title-card': {
				background: theme.colors.fluencyZone.purple5,
				color: theme.colors.fluencyZone.background
			}
		},
		'&.success': {
			'& .title-card': {
				background: theme.colors.successZone.aqua5,
				color: theme.colors.successZone.background
			}
		},
		'&.language': {
			'& .title-card': {
				background: theme.colors.languageZone.orange5,
				color: theme.colors.languageZone.background
			}
		},
		'&.explore': {
			'& .title-card': {
				background: theme.colors.exploreZone.red5,
				color: theme.colors.exploreZone.background
			}
		},
		'&.isComplete': {
			'& .title-card': {
				background: `${theme.colors.paleGrey} !important`,
				color: `${theme.colors.grey} !important`
			},
			'& .arrow-button': {
				pointer: 'not-allowed',
				border: `2px solid ${theme.colors.readingZone.primary}`,
				'& i': {
					color: theme.colors.readingZone.primary
				}
			}
		}
	},
	container: {
		position: 'relative',
		height: '100%',
		width: '100%',
		background: theme.colors.white,
		borderRadius: '8px',
		borderBottom: `16px solid ${theme.colors.softBlack}`,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		padding: '16px'
	},
	title: {
		position: 'relative',
		width: '100%',
		height: '72px',
		lineHeight: '30px',
		fontSize: '24px',
		fontFamily: theme.fonts.uiBold,
		background: theme.colors.fluencyZone.purple5,
		color: theme.colors.fluencyZone.background,
		borderRadius: '9px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		'& .sound-button': {
			position: 'relative',
			left: '-12px',
			top: '1px',
			background: 'transparent'
		}
	},
	text: {
		width: '100%',
		height: 'auto',
		minHeight: '70px',
		marginTop: '16px',
		fontSize: '16px',
		fontFamily: theme.fonts.ui,
		color: theme.colors.softBlack
	},
	button: {
		position: 'absolute',
		bottom: '-54px',
		left: '50%',
		transform: 'translateX(-50%) translateY(-50%)',
		background: theme.colors.white,
		border: `1px solid ${theme.colors.paleGrey}`,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		height: '40px',
		width: '40px',
		textAlign: 'center',
		borderRadius: '50%',
		transition: 'all 0.3s ease',
		'&.complete': {
			cursor: 'not-allowed'
		},
		'& i': {
			position: 'relative',
			top: '0px',
			color: theme.colors.softBlack
		},
		'&:hover:not(.complete), &:focus:not(.complete)': {
			width: 48,
			height: 48,
			bottom: '-62px',
			border: `1px solid ${theme.colors.readingZone.primary}`,
			'& i': {
				top: '0px'
			}
		},
		'&:active:not(.complete)': {
			width: 48,
			height: 48,
			bottom: '-62px',
			border: `1px solid ${theme.colors.readingZone.primary}`,
			'& i': {
				top: '0px',
				color: `${theme.colors.readingZone.primary}`
			}
		}
	}
}));

const SubzoneSelector = (props) => {
	const {
		id,
		title,
		text,
		onClick,
		zone,
		isComplete,
		className,
		showInfoButton,
		sound
	} = props;
	const classes = useStyles();

	const handleClick = e => {
		e.preventDefault();
		if (isComplete === false) {
			onClick();
		}
	};

	return (
		<div
			className={`${classes.wrapper} ${zone} ${
				isComplete ? 'isComplete' : ''
			} ${className}`}
			id={id}
		>
			<div className={`${classes.container} subzone-container`}>
				<div className={`${classes.title} title-card`}>
					{showInfoButton && (
						<SoundButton
							size={32}
							border={false}
							icon={'info'}
							sound={{
								name: sound.name,
								src: sound.src
							}}
						/>
					)}
					{title}
				</div>
				{isEmpty(text) === false && (
					<div className={classes.text}>{text}</div>
				)}
				<button
					type="button"
					className={`${classes.button} arrow-button ${
						isComplete ? 'complete' : ''
					} ${isEmpty(text) === false ? 'with-text' : ''}`}
					onClick={handleClick}
				>
					<i className={`r180 ${isComplete ? 'check' : 'more'}`} />
				</button>
			</div>
		</div>
	);
}

SubzoneSelector.defaultProps = {
	id: '',
	className: '',
	title: '',
	text: '',
	zone: '',
	isComplete: false,
	showInfoButton: false,
	sound: {}
};
SubzoneSelector.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	zone: PropTypes.string.isRequired,
	isComplete: PropTypes.bool,
	showInfobutton: PropTypes.bool,
	sound: PropTypes.shape({
		name: PropTypes.string,
		src: PropTypes.string
	})
};

export default React.memo(SubzoneSelector);
