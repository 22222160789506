import {useIdleTimer} from 'react-idle-timer';
import React, {useState, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {CLIENT_BASE_URL, TIMEOUT} from './constants';
import {AppModal} from '@reading/common';
import {logout} from '../store/slices/session.js';
import { useHistory } from 'react-router';

const UseIdleTimeout = soundUrl => {
	const dispatch = useDispatch();
	const history = useHistory();
	const timeout = useRef(null);

	const [isOpenIdleTimeoutModal, setIsOpenIdleTimeoutModal] = useState(false);

	const handleActive = () => {};
	const handleAction = () => {};

	const handleIdleTimeoutConfirm = () => {
		setIsOpenIdleTimeoutModal(false);
		clearTimeout(timeout.current);
	};

	const handleIdleTimeoutCancel = () => {
		setIsOpenIdleTimeoutModal(false);
		clearTimeout(timeout.current);
	};

	const handleIdle = () => {
		setIsOpenIdleTimeoutModal(true);

		timeout.current = setTimeout(async() => {
			try {
				setIsOpenIdleTimeoutModal(false);
				await dispatch(logout());
			}
			finally {
				history.push(`${CLIENT_BASE_URL}/logout`);
			}
		}, TIMEOUT.INACTIVITY_NO_RESPONSE);
	};

	useIdleTimer({
		timeout: TIMEOUT.INACTIVITY_PROMPT,
		onIdle: handleIdle,
		onActive: handleActive,
		onAction: handleAction,
		debounce: 500
	});

	return (
		<>
			<AppModal
				isOpen={isOpenIdleTimeoutModal}
				onConfirm={handleIdleTimeoutConfirm}
				onCancel={handleIdleTimeoutCancel}
				text={`Are you still there?`}
				showCancel={false}
				showSpeakerIcon={true}
			/>
		</>
	);
};

export default UseIdleTimeout;
