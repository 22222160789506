import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import DragAndDrop from '../ClickAndStick/DragAndDrop';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import SoundButton from '../SoundButton';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: '-32px -40px -40px -40px',
		display: 'grid',
		gridTemplateRows: `1fr`,
		gridTemplateColumns: `1fr`,
		gridGap: '4px',
		justifyItems: 'stretch',
		alignItems: 'center',
		'& .spacer': {
			background: theme.colors.white
		},
		'& p': {
			fontSize: '16px'
		}
	},
	dragAndDropContainer: {
		position: 'relative',
		display: 'flex',
		height: '450px'
	},
	optionsContainer: {
		background: theme.colors.white,
		height: '100px',
		width: '220px',
		padding: '18px'
	},
	optionsText: {
		position: 'relative',
		display: 'block',
		textAlign: 'left',
		marginLeft: '10px',
		marginTop: '-10px',
		width: '170px',
		height: '88px',
		left: '5px',
		color: theme.colors.black,
		fontFamily: theme.fonts.ui,
		fontSize: '16px',
		lineHeight: '22px',
		fontWeight: 600
	},
	orderingSequences: {
		position: 'relative',
		margin: '-12px',
		top: '2px'
	},
	circleWrapper: {
		position: 'absolute',
		left: '212px',
		top: '40px',
		background: '#FBFCFD',
		display: 'inline-block',
		height: '24px',
		width: '22px',
		borderRadius: '24px',
		zIndex: 1
	},

	semiCircleMark: {
		position: 'absolute',
		display: 'inline-block',
		border: '1px solid #D1D3D3',
		borderLeft: 0,
		height: '24px',
		width: '12px',
		borderBottomRightRadius: '24px',
		borderTopRightRadius: '24px',
		top: '0px',
		left: '10px'
	},
	circleMark: {
		position: 'relative',
		display: 'flex',
		width: '16px',
		height: '16px',
		borderRadius: '16px',
		background: '#0093E6',
		top: '4px'
	},
	fullCircleWrapper: {
		position: 'absolute',
		left: '210px',
		top: '40px',
		background: '#CCE9FA',
		display: 'inline-block',
		height: '24px',
		width: '24px',
		borderRadius: '24px',
		zIndex: 1,
		border: '1px solid #0093E6'
	},
	fullCircleMark: {
		position: 'relative',
		display: 'flex',
		width: '16px',
		height: '16px',
		borderRadius: '16px',
		background: '#0093E6',
		top: '3px',
		left: '3px'
	},
	choicesWrapper: {
		position: 'absolute',
		width: '230px',
		height: '460px',
		top: '4px',
		left: '250px',
		display: 'flex',
		flexDirection: 'column'
	},
	choicesContainer: {
		position: 'relative',
		background: '#CCE9FA',
		height: '102px',
		width: '220px',
		display: 'flex',
		flexDirection: 'row',
		border: '1px solid #D1D3D3',
		borderRadius: '8px',
		margin: '8px 0 6px 0',
		color: theme.colors.black,
		fontFamily: theme.fonts.ui,
		fontSize: '16px',
		lineHeight: '22px'
	},

	soundIcon: {
		position: 'relative',
		left: '-4px',
		top: '2px',
		height: '50px',
		width: '24px',
		zIndex: '10'
	},

	choicesText: {
		position: 'relative',
		display: 'block',
		textAlign: 'left',
		marginLeft: '5px',
		marginTop: '10px',
		width: '180px',
		height: '88px',
		lineHeight: '22px',
		fontSize: '16px',
		overflow: 'hidden',
		fontWeight: 600
	},
	matchingMarkWrapper: {
		position: 'absolute',
		display: 'inline-block',
		border: '1px solid #D1D3D3',
		borderLeft: 0,
		height: '24px',
		width: '12px',
		borderBottomRightRadius: '24px',
		borderTopRightRadius: '24px',
		top: '40px',
		left: '-1px',
		background: '#fbfcfd'
	},
	choiceNumber: {
		color: theme.colors.black,
		fontFamily: theme.fonts.ui,
		fontSize: '16px',
		lineHeight: '22px',
		fontWeight: 800
	}
}));

const MatchingQuestion = props => {
	const {id, className, onAnswerChange, question, interactive} = props;
	const {draggablePrompts, fixedChoices} = question;
	const classes = useStyles();
	const items = draggablePrompts.map(prompt => {
		return {
			id: `${prompt.id}`,
			text: prompt.text,
			content: (
				<div className={classes.optionsContainer}>
					<span
						className={classes.optionsText}
						dangerouslySetInnerHTML={{__html: prompt.text}}
					></span>
					{prompt.connected ? (
						<span className={classes.fullCircleWrapper}>
							<span className={classes.fullCircleMark}></span>
						</span>
					) : (
						<span className={classes.circleWrapper}>
							<span className={classes.semiCircleMark}></span>
							<span className={classes.circleMark}></span>
						</span>
					)}
				</div>
			),
			audio: prompt.audio,
			status: prompt.status,
			connected: prompt.connected,
			style: {marginBottom: '13px'}
		};
	});
	const wrapperStyles = [];
	const outerBoxStyles = [];
	const bucketItems = [];
	draggablePrompts.forEach((_, index) => {
		if (index === 0) {
			bucketItems.push(items);
			outerBoxStyles.push({
				width: 150,
				height: 460
			});
			wrapperStyles.push({
				position: 'absolute',
				top: '0%',
				left: '12px'
			});
			wrapperStyles.push({
				position: 'absolute',
				top: '16px',
				left: '230px'
			});
		}
		bucketItems.push([{id: 'empty'}]);
		outerBoxStyles.push({
			width: 240,
			height: 88
		});
		wrapperStyles.push({
			position: 'absolute',
			top: `${(index + 1) * 106 + 16}px`, // 88+18 = 106 (88 is height and 18 is margin) and 16 is top distance of first tile
			left: '230px'
		});
	});

	const generateFixedChoices = () => {
		return fixedChoices.map((choice, index) => {
			return (
				<div key={index} className={classes.choicesContainer}>
					<div className={classes.soundIcon}>
						<SoundButton
							border={false}
							sound={choice.audio}
							size={32}
							className={'transparent'}
						/>
					</div>
					<div className={classes.choicesText}>
						<span className={classes.choiceNumber}>
							{index + 1}.{' '}
						</span>
						{stripHtmlTags(choice.text, false, true)}
					</div>
					<div className={classes.matchingMarkWrapper}></div>
				</div>
			);
		});
	};

	return (
		<div className={`${classes.wrapper} ${className}`} id={id}>
			<div className="spacer"></div>
			<div className={classes.dragAndDropContainer}>
				<div className={classes.choicesWrapper}>
					{generateFixedChoices()}
				</div>
				<div className={classes.orderingSequences}>
					<DragAndDrop
						disableHelperBar={false}
						showSoundButton={true}
						isMatchingQuestion={true}
						onDragDrop={items => onAnswerChange(items)}
						bucketInfoList={[
							{
								type: 'source',
								header: '',
								menuText: 'Source 1'
							},
							{
								type: 'destination1',
								header: '',
								menuText: 'Destination1',
								status: ''
							},
							{
								type: 'destination2',
								header: '',
								menuText: 'Destination2',
								status: ''
							},
							{
								type: 'destination3',
								header: '',
								menuText: 'Destination3',
								status: ''
							},
							{
								type: 'destination4',
								header: '',
								menuText: 'Destination4',
								status: ''
							}
						]}
						bucketItemList={bucketItems}
						bucketStyleList={outerBoxStyles}
						droppableStyleList={wrapperStyles}
						interactive={interactive}
					></DragAndDrop>
				</div>
			</div>
		</div>
	);
};

MatchingQuestion.defaultProps = {
	id: '',
	className: '',
	question: {},
	interactive: true
};
MatchingQuestion.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	question: PropTypes.object.isRequired,
	onAnswerChange: PropTypes.func.isRequired,
	interactive: PropTypes.bool
};

export default MatchingQuestion;
