import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		width: '100%',
		height: 'auto',
		borderRadius: '8px',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		overflow: 'hidden'
	},
	titleBar: {
		position: 'relative',
		width: '100%',
		height: '32px',
		padding: '3px 11px',
		background: theme.colors.paleGrey,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		alignItems: 'stretch',

		'& .title': {
			display: 'inline-block',
			color: theme.colors.grey,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '18px',
			position: 'relative',
		},
		'& .info': {
			position: 'absolute',
			top: '6px',
			right: '8px',
			width: '16px',
			height: '16px',
			background: theme.colors.paleGrey,
			borderRadius: '8px',
			color: theme.colors.grey,
			fontSize: '16px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	},
	writingArea: {
		width: '100%',
		height: '68px',
		borderRadius: '0px 0px 8px 8px',
		background: theme.colors.lightGrey,
	}
}));

const InactiveWritingPanel = props => {
	const { id, className, title, onInfo } = props;
	const classes = useStyles();

	const handleInfo = () => {
		onInfo(id);
	}

	return (
		<div id={id} className={`${classes.wrapper} ${className}`}>
			<div className={`${classes.titleBar}`}>
				<div className='title'>
					{title}
				</div>
				<button className='info' onClick={handleInfo}>
					<i className='r180 instructions' />
				</button>
			</div>
			<div className={`${classes.writingArea}`}>

			</div>
		</div>
	);
}

InactiveWritingPanel.defaultProps = {
	id: '',
	className: '',
	title: '',
};
InactiveWritingPanel.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	title: PropTypes.string.isRequired
};

export default React.memo(InactiveWritingPanel);
