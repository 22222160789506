import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	activitySupportButton: {
		background: 'transparent',
		color: theme.colors.white,
		width: 'auto',
		minWidth: '64px',
		height: 'auto',
		border: 'none',
		borderRadius: '8px',
		outline: 'none',
		textAlign: 'center',
		cursor: 'pointer',
		padding: '8px',
		transition: 'transform 0.3s ease',
		'&.disabled, &:disabled': {
			background: 'transparent !important',
			color: theme.colors.paleGrey,
			cursor: 'not-allowed'
		},
		'& .icon-wrapper': {
			height: '36px',
			width: 'auto',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& .circle': {
				height: '36px',
				width: '36px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '18px',
				'& i': {
					fontSize: '24px'
				}
			}
		},
		'& .text': {
			fontSize: '18px',
			fontFamily: theme.fonts.button,
			fontWeight: '600',
			marginTop: '4px',
			textShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'
		},
		'&:hover': {
			transform: 'scale(1.2)',
			'& .circle': {
				background: 'rgba(0, 0, 0, 0.3)'
			}
		},
		'&:focus': {
			'& .circle': {
				background: 'rgba(0, 0, 0, 0.3)'
			},
			'& .text': {
				textDecoration: 'underline',
				textUnderlineOffset: '3px'
			}
		},
	}
}));

const ActivitySupportButton = (props) => {
	const {disabled, icon, text, onClick, id} = props;
	const classes = useStyles();

	return (
		<button
			type="button"
			id={id}
			aria-describedby={id}
			aria-label={text}
			disabled={disabled}
			className={`${classes.activitySupportButton} ${
				disabled ? 'disabled' : ''
			} activity-support-button` }
			onClick={onClick}
		>
			<div className='icon-wrapper'>
				<div className="circle">
					<i className={`r180 ${icon} ${classes.icon}`} />
				</div>
			</div>
			<div className={`text`}>{text}</div>
		</button>
	);
}

ActivitySupportButton.defaultProps = {
	id: '',
	text: '',
	icon: '',
	disabled: false
};
ActivitySupportButton.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired
};

export default React.memo(ActivitySupportButton);
