import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		width: 'auto',
		height: '16px',
		display: 'inline-block',
		'& > .mark': {
			position: 'absolute',
			top: '-4px',
			display: 'inline-block',
			height: '24px',
			width: '24px',
			border: `4px solid ${theme.colors.paleGrey}`,
			borderRadius: '12px',
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			zIndex: '10'
		}
	},
	progressMeter: {
		background: theme.colors.white,
		width: '100%',
		height: '100%',
		borderRadius: '24px',
		outline: 'none',
		overflowX: 'hidden',
		'& > .slider': {
			width: '100%',
			height: '100%',
			position: 'relative',
			left: '50%'
		}
	}
}));

const ProgressMeter = (props) => {
	const {id, color, progress} = props;
	const classes = useStyles();

	return (
		<div className={`${classes.wrapper} progress-meter`}>
			<div
				className="mark"
				style={{left: `calc(${progress}% - 8px`, background: color}}
			></div>
			<div className={classes.progressMeter} id={id} key={id}>
				<div
					className={`slider`}
					style={{left: `-${100 - progress}%`, background: color}}
				></div>
			</div>
		</div>
	);
}

ProgressMeter.defaultProps = {
	id: '',
	color: '',
	progress: 0
};
ProgressMeter.propTypes = {
	id: PropTypes.string,
	color: PropTypes.string.isRequired,
	progress: PropTypes.number
};

export default React.memo(ProgressMeter);
