import { Grid, Modal } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import ActivityButton from '../ActivityButton';
import ActivityDirections from '../../components/ActivityDirections';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    content: {
        top: '50%',
        outline: 'none',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        position: 'absolute',
        backgroundColor: theme.colors.white,
        padding: "48px 48px 32px",
        maxWidth: '540px',
        width: '70%',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.35), 0px 0px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px'
    },
    subHeaderText: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '34px',
        '& .activity-button-speaker': {
            position: 'relative',
            top: '-1px'
        }
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '36px',
        justifyContent: 'space-evenly',
        width: '100%'
    },
    closeButton: {
        fontSize: '24px',
        position: 'absolute',
        right: '16px',
        top: '16px',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.2)'
        }
    },
    header: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '32px',
        lineHeight: '38px',
        '& .activity-button-speaker': {
            position: 'relative',
            top: '-4px'
        }
    }
}));

const TipModal = (props) => {

    const {
        isOpen,
        onConfirm,
        onCancel,
        confirmText,
        cancelText,
        showCancel,
        showClose,
        word,
        wordUrl,
        tip,
        tipUrl,
        showActivityButton
    } = props;

    const classes = useStyles();

    return (
        <>
            <Modal
                disableBackdropClick //prevent closing of modal on clicking outside
                disableEscapeKeyDown //prevent closing of modal on pressing ESC key
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={isOpen}
                style={{ zIndex: '99999' }}
                className='r180-modal'
            >
                <Fade in={isOpen} timeout={200}>
                    <div className={`${classes.content} modal-window`}>

                        {showClose && (
                            <button
                                className={classes.closeButton}
                                onClick={onCancel}
                                data-testid="closeButton"
                                aria-label="Close"
                            >
                                <i className="r180 close-alt" />
                            </button>
                        )}

                        <ActivityDirections
                            className={`mb-4 ${classes.header}`}
                            text={word}
                            src={wordUrl}
                        />
                        <Divider variant="middle" />
                        <ActivityDirections
                            className={`mt-4 mb-1 ${classes.subHeaderText}`}
                            text={tip}
                            src={tipUrl}
                        />

                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            direction="row"
                            display="flex"
                        >
                            <Grid item className={classes.buttonWrapper}>
                                {
                                    showCancel &&
                                    <ActivityButton
                                        data-testid="secondaryButton"
                                        id="secondaryButton"
                                        text={cancelText}
                                        icon="arrow-right"
                                        onClick={onCancel}
                                        isSecondary={true}
                                    />
                                }

                                {
                                    showActivityButton &&
                                    <ActivityButton
                                        data-testid="primaryButton"
                                        id="primaryButton"
                                        text={confirmText}
                                        icon="arrow-right"
                                        onClick={onConfirm}
                                    />
                                }

                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </>
    )
};

TipModal.defaultProps = {
    isOpen: true,
    text: "",
    confirmText: "Go On",
    cancelText: "No",
    showCancel: false,
    showClose: true,
    showActivityButton: true
};

TipModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    showCancel: PropTypes.bool,
    showClose: PropTypes.bool,
    showActivityButton: PropTypes.bool,
    word: PropTypes.string,
    wordUrl: PropTypes.string,
    tip: PropTypes.string,
    tipUrl: PropTypes.string,
};

export default React.memo(TipModal);


