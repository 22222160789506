import {MEDIA_SERVER_URL} from '../../utils/constants';
import {getAudioExt} from '../../utils/audio';
import api from '../../api/api';
import {transitionToNextActivity} from '../../utils/navigation';
import {uiSlice} from '../../store/slices/ui';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {bulkReplace} from '../../utils/stringUtils';
const EXT = getAudioExt();

export const ReadAndRecord = {
	VO: [
		'r180u_readrecord_feedback_tooshort',
		'r180u_readrecord_help',
		'r180u_readrecord_intro',
		'r180u_readrecord_mandrecord',
		'r180u_readrecord_mandrecordhelppost',
		'r180u_readrecord_mandrecordhelppre',
		'r180u_readrecord_norecording_bypass',
		'r180u_readrecord_postrecord',
		'r180u_readrecord_powerwords',
		'r180u_readrecord_readaloud',
		'r180u_readrecord_record',
		'r180u_readrecord_record_bypass',
		'r180u_readrecord_selfcheck',
		'r180u_readrecord_selfcheckhelp',
		'r180u_readrecord_selfcheckhelp_bypass',
		'r180u_readrecord_selfcheckintro_bypass',
		'r180u_readrecord_selfcheckpost',
		'r180u_readrecord_selfcheckratinghelp_bypass',
		'r180u_readrecord_selfcheckratingintro',
		'r180u_readrecord_selfcheckratingintro_bypass',
		'r180u_readrecord_selfcheckratingresults',
		'r180u_readrecord_selfcheckresultsmodal',
		'r180u_readrecord_submitrecording',
		'r180u_readrecord_submitrecording_bypass',
		'r180u_readrecord_submitrecordingaccepted',
		'r180u_readrecord_submitrecordingaccepted_bypass',
		'r180u_readrecord_submitrecordingrejected',
		'r180u_readrecord_submitrecordingrejected_bypass'
	],
	PASSAGE_TEXT: `/content/passages/r180u_passage_{asset_id}.html`,
	SEGMENTS: `/content/segments/r180u_segments_{stage}.json`,
	PASSAGE_QUESTION: `/content/activi/readpass/r180u_content_readpass_{stage}{segment}{level}.json`,
	FLUENCY_RATING_QUESTION: '/content/fluencyrating/r180u_content_fluencyratingquestions.json',
	WORD_CARD: `/content/dictio/word/r180u_content_{word}.json`,
	WORD_CONTEXT: `/assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,
	WORD_DEFINITION: `/assets/dictio/def/r180u_def_{word}.${EXT}`,
	recordHelpSound: `${MEDIA_SERVER_URL}/assets/activi/readrecord/r180u_readrecord_help.${EXT}`,
	readHelpSound: `${MEDIA_SERVER_URL}/assets/activi/readrecord/r180u_readrecord_readaloud.${EXT}`,
	segmentFeature: `${MEDIA_SERVER_URL}/assets/activi/segselect/r180u_segselect_feature_{asset_id}.jpg`,
	segmentVideo: `${MEDIA_SERVER_URL}/assets/video/r180u_video_{asset_id}.mp4`,
	segmentVideoCaptions: `${MEDIA_SERVER_URL}/assets/video/r180u_{asset_id}.vtt`,
	ELSummary: `${MEDIA_SERVER_URL}/assets/video/r180u_video_{asset_id}_summary_span.${EXT}`,
	Focus_Attention_Audio: `${MEDIA_SERVER_URL}/assets/activi/readrecord/r180u_readrecord_selfcheckratingresults.${EXT}`,
	Rate_Understanding_Audio: `${MEDIA_SERVER_URL}/assets/activi/readrecord/r180u_readrecord_rating.${EXT}`,
	Challenge_Title_Audio: `${MEDIA_SERVER_URL}/assets/activi/readrecord/r180u_readrecord_challenge.${EXT}`,
	Submit_Recording_Title_Audio: `${MEDIA_SERVER_URL}/assets/activi/readrecord/r180u_readrecord_submitrecording.${EXT}`,
	SOUNDS: [
		{name: 'clickFxSound', src: `${MEDIA_SERVER_URL}/assets/sfx/r180u_sfx_click.${EXT}`},
		{name: 'contractFxSound', src: `${MEDIA_SERVER_URL}/assets/sfx/r180u_sfx_contract.${EXT}`},
		{name: 'expandFxSound', src: `${MEDIA_SERVER_URL}/assets/sfx/r180u_sfx_expand.${EXT}`},
		{name: 'hideFxSound', src: `${MEDIA_SERVER_URL}/assets/sfx/r180u_sfx_hide.${EXT}`}
	],
	MODES: {
		RECORD: 0,
		RATE: 1,
		ATTENTION: 2,
		DONE: 3
	},
	WORD_DELAYS: [0, 4000, 2000, 1000, 500, 250],
	tabs: {
		allTabs: [
			{id: '1', icon: 'read-aloud', text: 'Read Aloud'},
			{id: '2', icon: 'mic', text: 'Record'},
			{id: '3', icon: 'anchor-video', text: 'Video'}
		],
		noRecord: [
			{id: '1', icon: 'read-aloud', text: 'Read Aloud'},
			{id: '2', icon: 'mic', text: 'Record', disabled: true},
			{id: '3', icon: 'anchor-video', text: 'Video'}
		],
		onlyRecordActive: [
			{id: '1', icon: 'read-aloud', text: 'Read Aloud', disabled: true},
			{id: '2', icon: 'mic', text: 'Record'},
			{id: '3', icon: 'anchor-video', text: 'Video', disabled: true}
		]
	},

	MIN_REC_LENGTH_SEC: 10,
	SEEKSTART: -30,
	SEEKBACK: -10,
	SEEKFORWARD: 10,
	SEEKEND: 30,
	NOISE_THRESHOLD: 0.25,
	SILENCE_TIME: 5000,
	sendProgressToServer: async postData => {
		api.activity.updateActivity(postData);
	},

	completeActivity: async (postData, history, dispatch) => {
		try {
			await transitionToNextActivity(history, postData);
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
	},
	preloadSound: (stage, segment, level) => {
		ReadAndRecord.SOUNDS.forEach(sound => {
			AudioPlayer2.load(sound);
		});
		ReadAndRecord.VO.forEach(name => {
			AudioPlayer2.load({
				name: name.replace('r180u_readrecord_', ''),
				src: bulkReplace(`${MEDIA_SERVER_URL}/assets/activi/readrecord/${name}.${EXT}`, {
					stage,
					segment,
					level
				})
			});
		});
	}
};
