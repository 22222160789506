import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		zIndex: '10'
	},
	leftColumn: {
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		position: 'absolute',
		top: '80px',
		bottom: '0px',
		maxWidth: '648px',
		minWidth: '512px'
	},
	leftNarrow: {width: '512px', marginRight: '512px'},
	rightColumn: {
		display: 'flex',
		alignItems: 'stretch',
		flexDirection: 'column',
		position: 'absolute',
		top: '80px',
		width: '488px',
		marginLeft: '512px'
	},
	passage: {display: 'flex', flexDirection: 'column', flexGrow: 1},
	wordCardModal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	wordCardModalBody: {},
	videoModalBody: {
		background: theme.colors.black,
		'& #videoSummaryButton': {
			margin: '10px'
		}
	},
	recordingTipsModalBody: {
		width: '520px',
		height: '440px',
		background: theme.colors.white,
		position: 'relative',
		paddingLeft: '68px',
		paddingRight: '68px',
		paddingBottom: '48px',
		paddingTop: '48px',
		borderRadius: '8px',
		boxShadow: '0px 0px 5px rgb(0 0 0 / 35 %)'
	},
	modalX: {
		position: 'absolute',
		top: '8px',
		right: '8px',
		cursor: 'pointer'
	},
	tipsTitle: {
		fontSize: '24px',
		lineHeight: '30px',
		marginBottom: '8px',
		fontFamily: theme.fonts.uiSemibold
	},
	tipHeader: {
		display: 'flex',
		fontFamily: theme.fonts.ui,
		fontSize: '24px',
		lineHeight: '30px',
		fontWeight: '600',
		fontStyle: 'normal',
		color: theme.colors.black
	},
	contentRight: {
		marginLeft: '16px'
	},
	tipsEntry: {
		marginTop: '24px',
		fontFamily: theme.fonts.uiBold,
		fontSize: '18px',
		display: 'flex'
	},
	tipCheck: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		float: 'left',
		textAlign: 'center',
		lineHeight: '24px',
		marginRight: '16px',
		fontSize: '16px',
		width: '24px',
		height: '24px',
		borderRadius: '12px',
		color: theme.colors.white,
		background: theme.colors.successGreen
	},
	tipsButton: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		marginTop: '30px'
	},
	goOn: {
		position: 'absolute',
		bottom: '24px',
		right: '32px'
	},
	recordingSaveConfirmModal: {
		maxWidth: '520px'
	},
	recordingKillConfirmModal: {
		maxWidth: '520px'
	},
	recordingSubmitConfirmModal: {
		maxWidth: '520px'
	},
	recordingConfirmationModal: {
		maxWidth: '520px'
	},
	micBypassModalBody: {
		maxWidth: '520px',
		background: theme.colors.white,
		padding: '16px',
		position: 'relative'
	},
	hiddenSkipLink: {
		width: '0px',
		height: '0px',
		fontSize: '0px',
		display:'block'
	},
	clearBoth: {
		clear: 'both'
	}
}));
