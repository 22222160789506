import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center',
		padding:'48px',
	},
	optionsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection:'row',
		width:'768px',
		flexWrap:'wrap',
		marginTop:'100px',
	},
	optionContainer:{
		width:'320px',
		marginRight:'56px',
		marginBottom:'32px',
	},
	instructionsButton:{
		position:'relative',
		left:'48px',
		top:'-48px',
		float:'left',
	},
	readRecord:{
		position:'absolute',
		left:'48px',
		bottom:'48px',
		color:theme.colors.white,
		display:'flex',
		flexDirection:'column',
		alignItems:'center',
		justifyContent:'center',
		width:'96px',
		fontSize:'16px',
		textAlign:'center',
		fontWeight:'700',
	},
	readRecordButton:{
		width:'40px',
		height:'40px',
		borderRadius:'20px',
		color:theme.colors.cyan,
		background:theme.colors.white,
		marginBottom:'8px',
		fontSize:'18px',
	},
	disabledButton:{
		color:theme.colors.grey,
	}

}));
