import { isFirefox } from '@reading/r180/src/utils/browsers';
export const styles = theme => {
	return {
		root: {
			flexGrow: 1,
			alignItems: 'center',
			justifyContent: 'center'
		},
		iconButton: {
			color: theme.palette.common.white
		},
		leftVideoTime: {
			marginBottom: '5px'
		},
		rightVideoTime: {
			marginBottom: '5px'
		},
		timeFont: {
			fontFamily: theme.fonts.buttonBold,
			marginTop: '4px',
			fontSize: '18px',
			fontWeight: '700',
			fontStyle: 'normal',
			color: theme.colors.paleGrey
		},
		silder: {
			marginBottom: '14px'
		},
		videoContainer: {
			position: 'relative',
			display: 'flex',
			justifyContent: 'center',
			overflowY: 'hidden',

			'@media (min-width: 800px)': { width: !isFirefox() ? '93%' : '93%', marginLeft: '30px' },
			'@media (min-width: 700px) and (max-width: 799px)': { width: !isFirefox() ? '96%' : '93%', marginLeft: '30px' },
			'@media (min-width: 500px) and (max-width: 699px)': { width: !isFirefox() ? '99%' : '93%', marginLeft: '30px' },

			'@media (min-width: 1200px) and (max-width: 1300px)': {
				width: isFirefox() ? `93%` : `93%`, marginLeft: '30px' //Firefox - 100%
			},
			'@media (min-width: 1100px) and (max-width: 1199px)': {
				width: isFirefox() ? `94%` : `93%`, marginLeft: '30px' //Firefox - 110%
			},
			'@media (min-width: 1000px) and (max-width: 1099px)': {
				width: isFirefox() ? `98%` : `93%`, marginLeft: '0px' //Firefox - 120%
			},
			'@media (min-width: 500px) and (max-width: 999px)': {
				width: isFirefox() ? `99%` : ``, marginLeft: '0px'
			},
		},
		videoContainerFullScreen: {
			width: '100%',
			alignContent: 'center'
		},
		controllerBG: {
			backgroundColor: theme.colors.softBlack,
			color: theme.palette.common.white,
			height: theme.spacing(7)
		},
		controllerFullScreenBG: {
			backgroundColor: theme.colors.softBlack,
			color: theme.palette.common.white,
			height: theme.spacing(7),
			position: 'relative',
			bottom: '30px'
		},
		videoHintContainer: {
			position: 'relative',
			backgroundColor: theme.colors.softBlack
		},
		videoHintContainerFullScreen: {
			width: '100%'
		},
		controllerHintFullScreenBG: {
			backgroundColor: theme.colors.softBlack,
			color: theme.palette.common.white,
			height: theme.spacing(7),
			position: 'relative',
			bottom: '56px'
		},
		container: {
			display: 'flex'
		},
		closeButton: {
			position: 'absolute',
			top: '10px',
			right: '10px',
			background: 'transparent',
			color: theme.colors.white,
			fontSize: '24px',
			'&:focus': {
				color: theme.colors.readingZone.primary
			}
		}
	};
};
