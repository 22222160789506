import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FastForward, FastRewind } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
	root: {
		// padding: '12px',
		color: theme.colors.paleGrey,
		'&:hover,&:focus': {
			color: theme.colors.readingZone.primary
		}
	},
	fastRewindStyle: {
		marginRight: '0px'
	}
});

function RewindSeekButton({
	classes,
	containerStyle,
	iconButtonStyle,
	iconDefaultStyle,
	iconStyle,
	handleFastRewind
}) {
	return (
		<div style={containerStyle}>
			<IconButton
				aria-label="FastRewind"
				className={classes.root}
				color="inherit"
				style={iconButtonStyle}
				onClick={handleFastRewind}
			>
				<i className="r180 rewind" style={{ fontSize: '24px' }}></i>
			</IconButton>
		</div>
	);
}

RewindSeekButton.defaultProps = {
	classes: {},
	containerStyle: {},
	iconStyle: {},
	iconButtonStyle: {},
	handleFastRewind: () => { }
};
RewindSeekButton.propTypes = {
	classes: PropTypes.object,
	containerStyle: PropTypes.object,
	iconStyle: PropTypes.object,
	iconButtonStyle: PropTypes.object,
	handleFastRewind: PropTypes.func.isRequired
};

export default memo(withStyles(styles)(RewindSeekButton));
