import React from 'react';
import { WordList } from '@reading/common';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	resultsBlock: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',

		'& .listContainer': {
			marginTop: '40px',
			marginRight: '40px',
			'&:last-of-type': {
				marginRight: '0'
			}
		}
	}
}));

const ResultsDisplay = props => {
	const {
		fluentWords = [],
		slowWords = [],
		missedWords = [],
		showSlowWords = false,
		slowWordsStatus = false,
		className = '',
		style = {},
		onFluentHeaderIconClick,
		onSlowHeaderIconClick,
		onMissedHeaderIconClick

	} = props;
	const classes = useStyles();

	return (
		<div className={`${classes.resultsBlock} ${className}`} style={style}>
			<div className="listContainer" >
				<WordList isCorrect={true} wordList={fluentWords} onHeaderIconClick={onFluentHeaderIconClick} />
			</div>

			{showSlowWords && (
				<div className="listContainer">
					<WordList isCorrect={slowWordsStatus} isTimedOut={true} title="Slow" wordList={slowWords} onHeaderIconClick={onSlowHeaderIconClick} />
				</div>
			)}

			<div className="listContainer">
				<WordList isCorrect={false} wordList={missedWords} onHeaderIconClick={onMissedHeaderIconClick} />
			</div>

		</div>
	);
};

export { ResultsDisplay };
export default React.memo(ResultsDisplay);
