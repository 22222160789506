import React, {useState, useEffect} from 'react';
import {
	QuestionType,
	CaptionedAudioPlayer2 as AudioPlayer2
} from '@reading/common';
import {bulkReplace, stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import {createAssetId} from '@reading/r180/src/media/mediaUtil';
import {MEDIA} from '../HighlightingPassage/HighlightingPassage';
import ReadingTwoQuestionPanel from '../ReadingQuestionPanel/ReadingTwoQuestionPanel';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

const TwoPartQuestion = props => {
	const {setState} = useBatchedSetState();
	const {
		data: {
			children: [question1, question2]
		},
		stage,
		segment,
		level,
		onDeleteIconClicked,
		selectedHighlightedOptions,
		setCanClickPassageWord,
		onGoOnClicked,
		showOrHideFeedback,
		onAnswerCheck,
		onShowFeedback
	} = props;
	const MAX_ATTEMPTS = 3;
	const BUTTON_NAMES = {
		SUBMIT: 'Submit',
		GOON: 'Go On',
		TRYAGAIN: 'Try Again'
	};
	const BUTTON_ICONS = {
		CHECK: 'check',
		ARROW_RIGHT: 'arrow-right',
		RESET: 'reset'
	};
	const [isCorrects, setIsCorrects] = useState([false, false]);
	const [questionAttempts, setQuestionAttempts] = useState(0);
	const [interactive, setInteractive] = useState(true);
	const [highlightOptions, setHighlightOptions] = useState([]);
	const [choiceOptions, setChoiceOptions] = useState([[]]);
	const [buttonName, setButtonName] = useState(BUTTON_NAMES.SUBMIT);
	const [buttonIcon, setButtonIcon] = useState(BUTTON_ICONS.CHECK);

	useEffect(() => {
		setHighlightOptions(selectedHighlightedOptions);
	}, [selectedHighlightedOptions]);
	useEffect(() => {
		setChoiceOptions([
			[...question1.answers, ...question1.distractors],
			[...question2.answers, ...question2.distractors]
		]);
	}, [question1, question2]);

	const onChoiceAnswerChange = (selectedOptions, index) => {
		const selectedAnswerIds = selectedOptions.map(option => option.id);
		const newOptions = choiceOptions[index].map(option => {
			if (selectedAnswerIds.includes(option.id)) {
				return {...option, status: 'selected'};
			} else {
				return {...option, status: ''};
			}
		});
		setChoiceOptions(choiceOptions => {
			choiceOptions[index] = newOptions;
			return choiceOptions;
		});
	};
	const onSubmit = async () => {
		if (buttonName === BUTTON_NAMES.TRYAGAIN) {
			setState(() => {
				onShowFeedback(false, []);
				onAnswerCheck(true);
				setHighlightOptions(highlightOptions => {
					return highlightOptions.map(option => {
						option.status = '';
						return option;
					});
				});
				setChoiceOptions(choiceOptions => {
					return choiceOptions.map(options => {
						return options.map(option => {
							if (option.status) {
								option.status =
									option.status.length > 0 ? 'selected' : '';
							}
							return option;
						});
					});
				});
				setButtonName(BUTTON_NAMES.SUBMIT);
				setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
			});
			return;
		}
		if (buttonName === BUTTON_NAMES.GOON) {
			onGoOnClicked(questionAttempts, isCorrects[0], false);
			onGoOnClicked(questionAttempts, isCorrects[1], true);
			return;
		}
		let question = null;
		let index = null;
		let attempts = questionAttempts + 1;
		let newIsCorrects = [...isCorrects];
		let newChoiceOptions = [...choiceOptions];
		//Check for a highlight question and do that, only one of the questions can be a highlight question
		if (question1.type === QuestionType.HIGHLIGHT) {
			question = question1;
			index = 0;
		}
		if (question2.type === QuestionType.HIGHLIGHT) {
			question = question2;
			index = 1;
		}
		if (question) {
			const {answers} = question;
			const newOptions = highlightOptions.map(option => {
				const isCorrectAnswer =
					answers.filter(answer => answer.id === option.id).length >
					0;
				if (isCorrectAnswer) {
					return {...option, status: 'correct'};
				} else {
					return {...option, status: 'incorrect'};
				}
			});
			setHighlightOptions(newOptions);
			const correctOptionsLength = newOptions.filter(
				option => option.status === 'correct'
			).length;
			const maximumAllowableResponses =
				question.maximum_allowable_responses === null
					? answers.length
					: question.maximum_allowable_responses;

			// All answers are correct
			if (correctOptionsLength === maximumAllowableResponses) {
				newIsCorrects[index] = true;
				onAnswerCheck(false);
			} else {
				newIsCorrects[index] = false;
			}
		}
		//Now do single/multiple on each question (if applicable)
		let correctiveFeedback = [];
		[question1, question2].forEach((question, index) => {
			if (
				question.type === QuestionType.SINGLE ||
				question.type === QuestionType.MULTIPLE
			) {
				const answers = question.answers.map(answer => answer.id);
				const newOptions = choiceOptions[index].map(option => {
					if (option.status === 'selected') {
						if (answers.includes(option.id)) {
							return {...option, status: 'correct'};
						} else {
							return {...option, status: 'incorrect'};
						}
					} else {
						return {...option, status: ''};
					}
				});
				newChoiceOptions[index] = newOptions;
				const correctOptionsLength = newOptions.filter(
					option => option.status === 'correct'
				).length;
				if (correctOptionsLength === answers.length) {
					newIsCorrects[index] = true;
					setInteractive(false);
				} else {
					correctiveFeedback = question.corrective_feedback;
					newIsCorrects[index] = false;
				}
			}
		});

		//Now show feedback and play audio
		setState(() => {
			setInteractive(false);
			setIsCorrects(newIsCorrects);
			setQuestionAttempts(attempts);
			setChoiceOptions(newChoiceOptions);
		});
		if (newIsCorrects[0] === true && newIsCorrects[1] === true) {
			setButtonName(BUTTON_NAMES.GOON);
			setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
			await AudioPlayer2.playSync(`compcorrattempt${attempts}`);
		} else {
			await AudioPlayer2.playSync(`compincorrattempt${attempts}`);
			if (attempts >= MAX_ATTEMPTS) {
				setButtonName(BUTTON_NAMES.GOON);
				setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
			} else {
				onShowFeedback(true, correctiveFeedback);
				setButtonName(BUTTON_NAMES.TRYAGAIN);
				setButtonIcon(BUTTON_ICONS.RESET);
			}
		}
		setInteractive(true);
	};

	return (
		<>
			<ReadingTwoQuestionPanel
				title={stripHtmlTags(question1.stem)}
				titleSound={{
					name: `Title_${question1.id}`,
					src: bulkReplace(MEDIA.TITLE_SOUND, {
						asset_id: createAssetId(stage, segment, level, true),
						dataId: question1.id
					})
				}}
				question1={question1}
				question2={question2}
				onChoiceAnswerChange={onChoiceAnswerChange}
				onSubmit={onSubmit}
				onReset={() => {}}
				interactive={interactive}
				selectedHighlightedOptions={highlightOptions}
				choiceOptions={choiceOptions}
				handleDeleteIconClick={onDeleteIconClicked}
				setCanClickPassageWord={setCanClickPassageWord}
				showOrHideFeedback={showOrHideFeedback}
				buttonText={buttonName}
				buttonIcon={buttonIcon}
			/>
		</>
	);
};

export default TwoPartQuestion;
