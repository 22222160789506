import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import WordCard from '../WordCard/WordCard';
import InputContainer from './InputContainer';
import SpellingClinicItem from './SpellingClinicItem';

const useStyles = makeStyles(theme => ({
	wordCardWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%'
	}
}));

const ComponentLoader = props => {
	const {
		wordsList,
		studyWordIndex,
		wordCardIndex,
		setGoOnDisabled,
		handleForward,
		configData
	} = props;
	const classes = useStyles();
	const [finalReport, setFinalReport] = useState([]);

	const activityStatus = {completedActivity: 2, totalActivity: 3};
	const showWordCard = studyWordIndex % 2 === 0;
	const initailTaskData = {
		word_card_activities: []
	};

	const initialCardData = {
		word_card: {
			part_of_speech: null,
			word_card_activities: [],
			word_text:
				wordCardIndex <= 3 &&
				wordsList[Math.max(0, wordCardIndex - 1)].text,
			word_card_sentence:
				wordCardIndex <= 3 &&
				wordsList[Math.max(0, wordCardIndex - 1)].sentence,

			word_card_definition:
				wordCardIndex <= 3 &&
				wordsList[Math.max(0, wordCardIndex - 1)].definition
		}
	};

	const handleSpellComplete = () => {
		setGoOnDisabled(false);
	};

	return (
		<div className={`${classes.wordCardWrapper} `}>
			{showWordCard && wordCardIndex <= 3 ? (
				<WordCard
					isSpellPreselected={true}
					key={`${wordsList[wordCardIndex - 1].text}`}
					wordCardData={wordsList[wordCardIndex - 1]}
					card={initialCardData}
					taskData={initailTaskData}
					powerWordData={activityStatus}
					onSpellComplete={handleSpellComplete}
				/>
			) : wordCardIndex > 3 ? (
				<div>
					<SpellingClinicItem
						wordsList={wordsList}
						configData={configData}
						staticMode={true}
						finalScreen={true}
						finalReport={finalReport}
					/>
				</div>
			) : (
				<div>
					<InputContainer
						text={wordsList[wordCardIndex - 1].text}
						showWordCard={showWordCard}
						index={wordCardIndex - 1}
						setGoOnDisabled={setGoOnDisabled}
						handleForward={handleForward}
						wordsList={wordsList}
						configData={configData}
						setFinalReport={setFinalReport}
						finalReport={finalReport}
					/>
				</div>
			)}
		</div>
	);
};

export default React.memo(ComponentLoader);
