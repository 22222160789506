import axios from 'axios';

import {userContextData} from '../mock/data/user_context.js';

export default baseEndpoint => ({
	getUserContext: data => {
		const {correlationIdParam} = data;

		if (correlationIdParam === 'local') {
			return new Promise(resolve =>
				setTimeout(() => {
					resolve({data: userContextData});
				}, 2000)
			);
		}

		const contentProviderURI =
			process.env.REACT_APP_CONTENT_PROVIDER_URI ||
			process.env.REACT_APP_PRODUCTION_PROXY_PROVIDER_URI ||
			'/api/provider/v1/callback/correlationid/';

		const url = contentProviderURI + correlationIdParam;

		return axios.get(`${url}`).then(response => response.data);
	},

	storeUserContext: data => {
		// TODO
	}
});
