import {makeStyles} from '@material-ui/styles';

export default makeStyles(theme => ({
	wrapper: {},
	feedbackCols: {
		minHeight: '512px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		'& .word-list': {
			marginLeft: '12px',
			marginRight: '12px',
			height: '232px',
			textAlign: 'center',
			fontWeight: 'bold',
			fontSize: '32px',
			minWidth: '261px'
		}
	},
	goOnButton: {
		position: 'absolute',
		bottom: '15%',
		right: '25%'
	}
}));
