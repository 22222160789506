import { withStyles } from '@material-ui/core/styles';
import { isFirefox } from '@reading/r180/src/utils/browsers';
import { exploreBG, readingBG, languageBG, fluencyBG, writingBG, successBG } from './R180Backgrounds';
let zoomToFixDPI = 1;
if (window.devicePixelRatio < 2 && window.devicePixelRatio > 1 && window.innerHeight <= 720) {
	zoomToFixDPI = 1 / window.devicePixelRatio;
}

const styles = theme => ({
	'@global': {
		'*': {
			boxSizing: 'border-box',
			'&:focus': {
				outline: '4px dotted rgba(200, 200, 200, 0.4) !important',
				outlineOffset: '3px'
			}
		},
		html: {
			fontSize: '18px',
			background: '#1F252B'
		},
		body: {
			margin: '0',
			padding: '0',
			minHeight: '100vh',
			zoom: `${100 * zoomToFixDPI}%`,
			border: '0',
			outline: '0',
			verticalAlign: 'baseline',
			fontFamily: theme.fonts.ui,
			lineHeight: '1.4',
			color: theme.colors.black,
			background: '#fff',
			'-webkit-font-smoothing': 'antialiased',
			'-moz-osx-font-smoothing': 'grayscale'
		},
		h1: {
			fontSize: '48px',
			'&.st, &.segment-title': {
				fontSize: '60px'
			}
		},
		h2: {
			fontSize: '32px'
		},
		h3: {
			fontSize: '24px'
		},
		p: {
			fontSize: '18px'
		},
		button: {
			border: 'none',
			outline: '0',
			margin: '0',
			padding: '0',
			cursor: 'pointer',
			fontSize: '16px',
			background: 'inherit',
			fontFamily: theme.fonts.ui,
			'&::-moz-focus-inner': {
				border: 0
			}
		},
		'.pointer': {
			cursor: 'pointer'
		},
		'.centeredChild': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		},
		'.ellipsis': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		},
		'.fill-app-frame': {
			display: 'flex',
			flexDirection: 'column',
			flex: '1'
		},
		'.scroll-app-frame': {
			height: 'auto',
			minHeight: '100vh',
			overflowY: isFirefox() ? 'auto' : 'overlay',

			'&::-webkit-scrollbar': {
				width: '6px'
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				padding: '10px 0'
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#CCC',
				borderRadius: '8px'
			},
		},
		'.app-frame-container': {
			padding: '80px 20px 20px 20px'
		},
		'.r180': {
			'&.big': {
				fontSize: '24px'
			},
			'&.huge': {
				fontSize: '32px'
			}
		},
		'.zone_menu-container': {
			background: 'linear-gradient(#000,#444)'
		},
		'.segment_selection-container': {
			background: 'linear-gradient(#000,#444)'
		},
		'.reading-container': {
			background: `url(${readingBG}) no-repeat fixed center`,
			backgroundSize: '100% 100%',
			'& header, & footer': {
				background: 'transparent'
			}
		},
		'.language-container': {
			background: `url(${languageBG}) no-repeat fixed center`,
			backgroundSize: '100% 100%',
			'& header, & footer': {
				background: 'transparent'
			}
		},
		'.explore-container': {
			background: `url(${exploreBG}) no-repeat fixed center`,
			backgroundSize: '100% 100%',
			'& header, & footer': {
				background: 'transparent'
			}
		},
		'.success-container': {
			background: `url(${successBG}) no-repeat fixed center`,
			backgroundSize: '100% 100%',
			'& header, & footer': {
				background: 'transparent'
			}
		},
		'.fluency-container': {
			background: `url(${fluencyBG}) no-repeat fixed center`,
			backgroundSize: '100% 100%',
			'& header, & footer': {
				background: 'transparent'
			}
		},
		'.writing-container': {
			background: `url(${writingBG}) no-repeat fixed center`,
			backgroundSize: '100% 100%',
			'& header, & footer': {
				background: 'transparent'
			}
		},
		'.skills-container': {
			background: `url(${readingBG}) no-repeat fixed center`,
			backgroundSize: '100% 100%',
			'& header, & footer': {
				background: 'transparent'
			}
		},
		'.transparent': {
			background: 'transparent !important'
		}
	}
});

function R180ThemeCss() {
	return null;
}

export default withStyles(styles)(R180ThemeCss);
