import React from 'react';
import FluencyAssessmentSelectorActivity from '../activities/FluencyAssessment/FluencyAssessmentSelectorActivity';
import SpellingAssessmentActivity from '../activities/SpellingAssessment/SpellingAssessmentActivity';
import SpellingChallengeActivity from '../activities/SpellingChallenge/SpellingChallengeActivity';
import SpeedChallengeActivity from '../activities/SpeedChallenge/SpeedChallengeActivity';
import ProofreadingActivity from '../activities/Proofreading/ProofreadingActivity';
import SelfCheckActivity from '../activities/SelfCheck/SelfCheckActivity';
import WordAssessmentActivity from '../activities/WordAssessment/WordAssessmentActivity';
import WordMatchActivity from '../activities/WordMatch/WordMatchActivity';
import SpellingClinic from '../activities/SpellingClinic/SpellingClinicActivity';
import WordClinicActivity from '../activities/WordClinic/WordClinicActivity';

const fluencyZoneRoutes = [
	{
		route: 'fluency-selector',
		serverRoute: 'fluency_assessment_selector',
		pageTitle: 'Fluency Selector',
		component: <FluencyAssessmentSelectorActivity />,
		author: ['Mike']
	},
	{
		route: 'word-assessment',
		pageTitle: 'Word Assessment',
		serverRoute: 'word_assessment',
		component: <WordAssessmentActivity />,
		author: ['Ramkumar']
	},
	{
		route: 'word-clinic',
		serverRoute: 'word_clinic',
		pageTitle: 'Word Clinic',
		component: <WordClinicActivity />,
		author: ['Sumanth']
	},
	{
		route: 'word-match',
		serverRoute: 'word_match',
		pageTitle: 'Word Match',
		component: <WordMatchActivity />,
		author: ['Sheik']
	},
	{
		route: 'speed-challenge',
		serverRoute: 'speed_challenge',
		pageTitle: 'Speed Challenge',
		component: <SpeedChallengeActivity />,
		author: ['Sheik']
	},
	{
		route: 'self-check',
		serverRoute: 'self_check',
		pageTitle: 'Self Check',
		component: <SelfCheckActivity />,
		author: ['Mike']
	},
	{
		route: 'spelling-assessment',
		serverRoute: 'spelling_assessment',
		pageTitle: 'Spelling Assessment',
		component: <SpellingAssessmentActivity />,
		author: ['Sheik']
	},
	{
		route: 'spelling-clinic',
		serverRoute: 'spelling_clinic',
		pageTitle: 'Spelling Clinic',
		component: <SpellingClinic />,
		author: ['Sumanth']
	},
	{
		route: 'spelling-challenge',
		serverRoute: 'spelling_challenge',
		pageTitle: 'Spelling Challenge',
		component: <SpellingChallengeActivity />,
		author: ['Ramkumar']
	},
	{
		route: 'proofreading',
		serverRoute: 'proofreading',
		pageTitle: 'Proofreading',
		component: <ProofreadingActivity />,
		author: ['Mike']
	}
];

export default fluencyZoneRoutes;
