import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	checkmark: {
		position: 'relative',
		display: 'inline-block',
		border: 'none',
		borderRadius: '50%',
		color: theme.colors.white,
		textAlign: 'center',
		'&.correct': {
			background: theme.colors.successGreen
		},
		'&.incorrect': {
			background: theme.colors.errorRed
		}
	}
}));

const CorrectIncorrect = (props) => {
	const {id, correct, size, style} = props;
	const classes = useStyles();

	const sizePx = size + 'px';
	const lineHeight = size * 1.1 + 'px';
	const fontSize = Math.floor(size / 2) + 'px';

	return (
		<div
			id={id}
			className={`${classes.checkmark} ${
				correct ? 'correct' : 'incorrect'
			} correct-incorrect`}
			style={{
				...style,
				width: sizePx,
				height: sizePx,
				lineHeight: lineHeight,
				fontSize: fontSize,
				left: sizePx
			}}
		>
			<i
				className={`r180 ${correct ? 'check' : 'x'}`}
				role={'img'}
				aria-label={correct ? 'correct' : 'incorrect'}
			/>
		</div>
	);
}

CorrectIncorrect.defaultProps = {
	id: '',
	correct: true,
	size: 24,
	style: {}
};
CorrectIncorrect.propTypes = {
	id: PropTypes.string,
	correct: PropTypes.bool,
	size: PropTypes.number,
	style: PropTypes.object
};

export default React.memo(CorrectIncorrect);
