import { cloneDeep, isEmpty, shuffle } from 'lodash';
import React, { useRef, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import ActivityFrame from '../../../containers/App/ActivityFrame';
import ActivitySuspense from '../../../containers/App/ActivitySuspense';
import Footer from '../../../containers/App/Footer';
import FooterForwardBack from '../../../containers/App/FooterForwardBack';
import FSProgressBar from '../../../containers/App/FSProgressBar';
import Navbar from '../../../containers/App/Navbar';
import { WordTile, ActivitySupportButton, FoundationalIntro, ActivityInstructionButton, WordAssessment as WordAssessmentComponent } from '@reading/common';
import { makeStyles } from '@material-ui/core';
import { ProgressMonitorUtil } from './ProgressMonitorUtil';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { sleep } from '../../../utils/sleep';
import { ProgressMonitorConstants } from './ProgressMonitorConstants';
import FoundationalIntroModal from '@reading/common/src/components/FoundationalIntro/FoundationIntroModal';
import { FoundationalUtil } from '../FoundationalUtil';
import useBatchedSetState from '../../../utils/useBatchedSetState';
import { ResultsDisplay } from './ResultsDisplay';
import { uiSlice } from '../../../store/slices/ui';

const useStyles = makeStyles(theme => ({
	wrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		alignItems: 'center'
	}
}));

export default function ProgressMonitorActivity(props) {

	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { setState } = useBatchedSetState();
	const currentIndex = useRef(0);
	const { activityData, contentData, saveTemplate, settings } = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			contentData: state.activity.content,
			saveTemplate: state.activity.save_template,
			settings: state.activity.settings
		};
	});



	const FLUENT_CORRECT_TIMEOUT = ProgressMonitorUtil.getFluencyCorrectTimeout(contentData);
	const TRIAL_TIMEOUT = contentData && contentData.response_time_limit && contentData.fluency_threshold && contentData.response_time_limit / 1000 + contentData.fluency_threshold / 1000 + 2;
	const activityNum = activityData.status_bar ? activityData.status_bar.activities_encountered : 0;
	const activityTotal = activityData.status_bar ? activityData.status_bar.activities_total : 1;
	const activityPercent = (activityNum / activityTotal) * 100;

	const isFirstVisit = contentData ? contentData.intro_vo === 'lintro' : true;

	const isAutoplay = settings && settings.autoplay === true; // TODO This field not in server code yet, proper name is unknown
	const isAccuracyOnly = settings && settings.accuracy_only === true;  // TODO This field not in server code yet, proper name is unknown
	const introType = contentData ? contentData.intro_vo : ProgressMonitorConstants.DEFAULT_INTRO_VO;

	const [currentScreen, setCurrentScreen] = useState(isFirstVisit ? 1 : 2);
	const [isForwardDisabled, setForwardDisabled] = useState(true);
	const [isModalOpen, setModalOpen] = useState(false);
	const [forwardStatus, setForwardStatus] = useState(FooterForwardBack.START);
	const [trailWordsList, setTrailWordsList] = useState({ 1: [], 2: [], 3: [] });
	const [currentWord, setCurrentWord] = useState({});
	const [feedbackResults, setFeedbackResults] = useState({ fluent: [], slow: [], missed: [] });
	const [isComplete, setComplete] = useState(false);
	const [isPaused, setPaused] = useState(false);
	const [showResult, setShowResult] = useState(false);
	const [disableActivity, setDisableActivity] = useState(false);
	const [timer, setTimer] = useState(0);
	const [showSlowWords, setShowSlowWords] = useState(true);
	const [scoreMap, setScoreMap] = useState({});
	const [trailCount, setTrailCount] = useState(1);
	const [helpCount, setHelpCount] = useState(1);
	const [answers, setAnswers] = useState([]);

	// check if the activity is already charged
	const isActivityCharged = useSelector(state => {
		return state.ui.chargedActivities['progress-monitor'] === true;
	});

	// stop any sounds
	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	useDeepCompareEffect(() => {
		const progressTimeout = TRIAL_TIMEOUT ? TRIAL_TIMEOUT : ProgressMonitorConstants.DEFAULT_TIME_OUT;
		setTimer(isAccuracyOnly ? 0 : progressTimeout);
		const loadMedia = async () => {
			if (isActivityCharged === false) {
				dispatch(uiSlice.actions.setActivityCharged('progress-monitor'));
			}

			const { assessmentWords, scoreMap: scores } = await ProgressMonitorUtil.initialize({ contentData, isActivityCharged });

			setState(() => {
				setTrailWordsList({ 1: assessmentWords, 2: [], 3: [] });
				setScoreMap(scores);
				setCurrentWord(assessmentWords[currentIndex.current || 0]);
			});

			if (currentScreen === 2) {
				if (isFirstVisit) {
					initActivity();
				}
				else {
					startActivity(assessmentWords[currentIndex.current || 0].name);
				}
			}
		};


		if (isEmpty(contentData) === false) {
			loadMedia();
		}
	}, [contentData]);

	const initActivity = () => {
		AudioPlayer2.stopAll();
		setState(() => {
			setForwardDisabled(false);
			setForwardStatus(FooterForwardBack.START);

		});
	};

	const startActivity = (word) => {
		AudioPlayer2.stopAll();
		setState(() => {
			setForwardDisabled(true);
			setForwardStatus(FooterForwardBack.VALID);
		});
		AudioPlayer2.play(word);
	};

	const handleForward = async () => {
		AudioPlayer2.stopAll();

		// the foundational intro
		if (currentScreen === 1) {
			setCurrentScreen(2);
			startActivity(currentWord.name);
		}
		else if (currentScreen === 2 && showResult === true) {
			// the activity is complete, send it to the server
			const serverData = createServerData(answers, true);
			FoundationalUtil.completeActivity(history, serverData);
		}
	};

	const handleWordClick = async (e) => {
		setDisableActivity(true);
		AudioPlayer2.stopAll();
		await AudioPlayer2.playSync(currentWord.name);
		setDisableActivity(false);
	};

	const handleSentenceClick = async (e) => {
		setDisableActivity(true);
		AudioPlayer2.stopAll();
		await AudioPlayer2.playSync(`ctx-${currentWord.name}`);
		setDisableActivity(false);
	};

	const handlePauseClick = e => {
		AudioPlayer2.stopAll();
		if (isAutoplay) {
			AudioPlayer2.play(ProgressMonitorConstants.PAUSE);
		}
		setPaused(!isPaused);
	};


	const handleContinue = async () => {
		AudioPlayer2.stopAll();
		setPaused(!isPaused);
		if (isAutoplay) {
			await AudioPlayer2.playSync(ProgressMonitorConstants.CONTINUE);
		}
		AudioPlayer2.play(currentWord.name);

	}

	const handleInstructions = () => {
		setModalOpen(true);
		handlePauseClick();
	}

	const handleInstructionClick = () => {
		handlePauseClick();
	}

	const handleHelpClick = () => {
		AudioPlayer2.stopAll();
		if (!showResult && currentScreen === 2) {
			if (!isPaused) {
				AudioPlayer2.play(ProgressMonitorUtil.getHelpSound(helpCount));
			} else {
				AudioPlayer2.play(ProgressMonitorUtil.getHelpSound(0));
			}
			setHelpCount(helpCount + 1);
		}
		else if (showResult) {
			AudioPlayer2.play(ProgressMonitorConstants.FEEDBACK_HELP);
		}
	}

	const handleVideoComplete = useCallback(() => {
		if (currentScreen === 1) {
			setForwardDisabled(false);
		}
	}, [currentScreen]);

	const handleModalClose = () => {
		AudioPlayer2.stopAll();
		setModalOpen(false);
	}

	const intro = useMemo(() => <FoundationalIntro
		title={ProgressMonitorConstants.ACTIVITY_TITLE}
		videoUrl={ProgressMonitorConstants.INTRO_VIDEO}
		icons={[
			{ text: 'Listen', icon: 'wild-things' }, // TODO - need to update the icons in the future.
			{ text: 'Find', icon: 'tomorrowland' },
			{ text: 'Click', icon: 'play' },
		]}
		onVideoComplete={handleVideoComplete}
		isFirstVisit={isFirstVisit}
		instructionSoundSrc={ProgressMonitorUtil.getRandomizedSound(introType, isAccuracyOnly)}
	/>, [handleVideoComplete, isFirstVisit]);


	const getScore = (isCorrect, timeS) => {
		// 1: fluent, 2: correct, 3: missed 4.out of time 	
		if (isCorrect) {
			if (timeS < FLUENT_CORRECT_TIMEOUT) {
				return 1;
			}
			else if (timeS >= FLUENT_CORRECT_TIMEOUT) {
				return 2;
			}
		} else {
			if (timeS === -1) {
				return 4;
			}
		}
		return 3;
	}

	const evaluateResult = (isComplete) => {
		const evalResults = ProgressMonitorUtil.evaluateResults(scoreMap);
		setFeedbackResults(evalResults);


		if (isComplete) {
			setState(() => {
				setComplete(true);
				setForwardDisabled(false);
			});
			handleTrialComplete();
		}
	}



	const validate = (selectdWord, isCorrect, timeS) => {

		const wordObj = currentWord;
		const scoreItem = scoreMap[currentWord.name];
		scoreItem[wordObj.trialNo] = getScore(isCorrect, timeS);
		const newScoreMap = { ...scoreMap, [currentWord.name]: scoreItem };
		setScoreMap(newScoreMap);

		if (wordObj.trialNo < 3) {
			wordObj.trialNo++;
			if (ProgressMonitorUtil.isThirdRound(scoreItem) && wordObj.type === ProgressMonitorConstants.TARGET) {
				trailWordsList[wordObj.trialNo].push(wordObj);
				setTrailWordsList(trailWordsList)
			}
		}

		// if trailcount greater then maxtrail count 3 , then activity is completed
		// else if trail count is 3 and processing the last word in the list , then  activity is completed

		const isActivityCompleted = trailCount > ProgressMonitorConstants.MAX_TRAIL_COUNT ? true : trailCount === ProgressMonitorConstants.MAX_TRAIL_COUNT && trailWordsList[trailCount].length - 1 === currentIndex.current;

		if (isActivityCompleted) {
			// Words list done		
			evaluateResult(true);
		} else {
			if (trailWordsList[trailCount].length > 0) {
				evaluateResult(false);
				loadNextWord(trailWordsList);
			} else {
				// Words list done
				evaluateResult(true);
			}
		}

		sendActivityProgressToServer(selectdWord, currentWord, isCorrect, timeS)

	};

	const sendActivityProgressToServer = (selectdWord, currentWord, isCorrect, timeS) => {
		// construct the answer object and add it to state
		let newAnswers = cloneDeep(answers);
		newAnswers.push({
			id: currentWord.id,
			type: currentWord.type,
			score: ProgressMonitorConstants.TRIAL_SCORE[getScore(isCorrect, timeS)],
			trial_count: currentWord.trialNo,
			response_time: timeS * 1000,
			answer_id: selectdWord

		});
		setAnswers(newAnswers);

		// send the progress to the server
		const serverData = createServerData(newAnswers, false);
		FoundationalUtil.sendProgressToServer(serverData);
	};


	const createServerData = (answers, isComplete) => {

		let totalTime = 0;
		answers.forEach((a) => {
			totalTime += a.response_time;
		});

		const updatedSaveTemplate = cloneDeep(saveTemplate);
		updatedSaveTemplate.tracked_time = totalTime;
		updatedSaveTemplate.user_directed_navigation = null;
		updatedSaveTemplate.has_partial = isComplete === false;
		updatedSaveTemplate.activity_words = answers;

		return updatedSaveTemplate;
	};

	const loadNextWord = async (currentTrailWordList) => {
		AudioPlayer2.stopAll();
		currentIndex.current++;
		let nextWord = currentTrailWordList[trailCount][currentIndex.current];
		nextWord.words = shuffle(nextWord.words);
		//  before the end of current trail increase trailcount and clear the currentindex
		if (currentIndex.current === currentTrailWordList[trailCount].length - 1) {
			const nextTrailCount = trailCount + 1;
			currentIndex.current = -1;
			setTrailCount(nextTrailCount);
		}
		setCurrentWord(nextWord);
		await sleep(600);
		AudioPlayer2.play(nextWord.name);
	};

	const handleWordTileClick = (word, isCorrect, timeS) => {
		// Stamp the times as the timeTaken for the answer
		AudioPlayer2.stopAll();
		validate(word, isCorrect, timeS);
	};


	const handleTimedOut = (word, timeS) => {
		if (!isComplete) {
			AudioPlayer2.stopAll();
			validate(word, false, -1);
		}
	};

	const handleTrialComplete = async () => {
		AudioPlayer2.stopAll();
		setState(() => {
			setShowResult(true);
			setForwardStatus(FooterForwardBack.VALID);
		});
		ProgressMonitorUtil.feedbackScreenVO(scoreMap, trailCount - 1, isAutoplay);
		await sleep(800);
	};

	const handleFluentHeaderIconClick = () => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(ProgressMonitorConstants.FEEDBACK_FLUENT);
	};
	const handleSlowHeaderIconClick = () => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(ProgressMonitorConstants.FEEDBACK_SLOW);
	};
	const handleMissedHeaderIconClick = () => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(ProgressMonitorConstants.FEEDBACK_MISSED);
	};

	return (
		<>
			<Navbar helpSoundUrl={''}
				onHelp={handleHelpClick} />

			<ActivitySuspense showSpinner={isActivityCharged === false} requiredRenderData={[currentWord]} title="Progress Monitor">

				<ActivityFrame isWhiteBackground={showResult === false}>
					<>
						{showResult === false && forwardStatus === FooterForwardBack.VALID &&
							<>
								<FSProgressBar
									title="Smart Zone"
									percentProgress={activityPercent}
									taskNum={activityNum}
									totalTasks={activityTotal}
								/>
								<ActivityInstructionButton audioSrc={ProgressMonitorUtil.getRandomizedSound(introType, isAccuracyOnly)} fs={true} onSoundStart={handleInstructionClick} />
							</>
						}

						<div className={classes.wrapper}>

							{
								currentScreen === 1
									?
									intro
									:
									showResult === false
										?
										<div style={{ position: 'relative', height: '100%' }}>
											<WordAssessmentComponent
												id={`word-assessment-${currentIndex.current}`}
												showFeedBack={false}
												timeOut={timer}
												showTimer={true}
												words={currentWord.words}
												correctWord={{ name: currentWord.name }}
												isPaused={isPaused}
												isDisabled={disableActivity}
												onTimeOut={handleTimedOut}
												onWordClick={handleWordTileClick}
											/>
										</div>
										:
										<ResultsDisplay
											className="mt-4"
											fluentWords={feedbackResults.fluent}
											slowWords={feedbackResults.slow}
											missedWords={feedbackResults.missed}
											showSlowWords={showSlowWords}
											onFluentHeaderIconClick={handleFluentHeaderIconClick}
											onSlowHeaderIconClick={handleSlowHeaderIconClick}
											onMissedHeaderIconClick={handleMissedHeaderIconClick}

										/>
							}

						</div>
					</>
				</ActivityFrame>

				{
					isModalOpen && (
						<FoundationalIntroModal
							isOpen={isModalOpen}
							onClose={handleModalClose}
						>
							{intro}
						</FoundationalIntroModal>
					)
				}

				<Footer>
					{
						isPaused === false && currentScreen === 2 && showResult === false &&
						<>
							<ActivitySupportButton
								icon="instructions"
								onClick={handleInstructions}
								text="Instructions"
								disabled={isComplete || isPaused}
							/>

							<ActivitySupportButton
								icon="word"
								onClick={handleWordClick}
								text="Word"
								disabled={isComplete || isPaused}
							/>
							<ActivitySupportButton
								icon="sentence"
								onClick={handleSentenceClick}
								text="Sentence"
								disabled={isComplete || isPaused}
							/>
							<ActivitySupportButton
								icon={isPaused ? 'play' : 'pause'}
								onClick={handlePauseClick}
								text={isPaused ? 'Resume' : 'Pause'}
								disabled={isComplete}
							/>

						</>
					}
					{
						isPaused &&
						<ActivitySupportButton
							icon="go-on"
							onClick={handleContinue}
							text="Continue"
						/>
					}
					<FooterForwardBack
						onForward={handleForward}
						isBackVisible={false}
						isForwardVisible={true}
						isForwardDisabled={isForwardDisabled}
						status={forwardStatus}
					/>
				</Footer>
			</ActivitySuspense >
		</>
	);
}

