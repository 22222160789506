import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CorrectIncorrect from '../CorrectIncorrect/CorrectIncorrect';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { useStyles } from './SpellingItem.style';
import { SpellingAssessment } from '@reading/r180/src/activities/SpellingAssessment/SpellingAssessment';
import { cloneDeep } from 'lodash';
import { sleep } from '@reading/r180/src/utils/sleep';

export default function SpellingItem(props) {
	const {
		id,
		word,
		num,
		onTextChange,
		interactive,
		onFocus,
		onBlur,
		onCompleted,
		onKeyPress,
		selectedWord,
		wordList,
		activeItem,
		nextWord
	} = props;

	const classes = useStyles();
	const ref = useRef(null);

	const [isFocused, setFocused] = useState(false);
	const [typedWord, setTypedWord] = useState('');
	const [isCorrect, setCorrect] = useState(true);
	const [homophonesWordAttemptMap, setHomophonesWordAttemptMap] = useState({});

	useEffect(() => {
		if (selectedWord !== word) {
			setFocused(false);
		}
	}, [selectedWord]);

	useEffect(() => {
		if (activeItem) {
			AudioPlayer2.stopAll();
			setFocused(true);
			const playSound = async () => {
				await sleep(400);
				await AudioPlayer2.playSync(word);
				AudioPlayer2.play(`ctx-${word}`);
				onFocus(word, id);
			};
			playSound();
		}
	}, [activeItem]);

	const handleTextChange = e => {
		let val = e.target.value.toLowerCase();
		val = val.replace(/[^a-zA-Z.\-/ ]/g, '');

		setTypedWord(val);
		if (typeof onTextChange !== 'undefined') {
			onTextChange(val);
		}
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			if (!e.target.value.trim().length) {
				return;
			}
			AudioPlayer2.stopAll();

			const isHomophoneWord = isHomophone(word, e.target.value);

			if (!isHomophoneWord) {
				const parentItem = ref.current.parentNode.parentNode;
				const nextItem = parentItem.nextSibling;
				setFocused(false);

				const moveToNextItem = async value => {
					await AudioPlayer2.playSync(SpellingAssessment.SOUND_CONTINUE);
					await AudioPlayer2.playSync(nextWord);
					AudioPlayer2.play(`ctx-${nextWord}`);
					if (nextItem) {
						const nextInput = nextItem.getElementsByClassName(
							'spell-input'
						);
						if (nextInput && nextInput.length > 0) {
							nextInput[0].disabled = false;
							nextInput[0].focus();
						}
					} else {
						if (typeof onCompleted !== 'undefined') {
							onCompleted(word, value);
							ref.current.blur();
						}
					}
				};
				const parentInput = parentItem.getElementsByClassName(
					'spell-input'
				);
				parentInput[0].disabled = true;
				moveToNextItem(e.target.value);
			} else {
				const homophonesItem = async e => {
					await sleep(200);
					setTypedWord('');
					setFocused(true);
				};
				homophonesItem();
			}
			setCorrect(word === typedWord);
			if (typeof onKeyPress !== 'undefined') {
				onKeyPress(word, e.target.value, isHomophoneWord);
			}
		}
	};

	const handleFocus = async e => {
		e.preventDefault();
		setFocused(true);
		await sleep(600);
		if (typeof onFocus !== 'undefined') {
			onFocus(word, id);
		}
	};

	const handleBlur = e => {
		e.preventDefault();
		if (typeof onBlur !== 'undefined') {
			onBlur(word, e.target.value, e.target);
		}
	};

	const isHomophone = (word, typedWord) => {
		let isHomophoneWord = false;
		wordList.forEach(wordObj => {
			if (word === wordObj.text) {
				if (wordObj.homophones.length) {
					wordObj.homophones.forEach(homephoneObj => {
						isHomophoneWord = typedWord === homephoneObj.text;
						if (isHomophoneWord) {
							let newHomophonesWordAttemptMap = cloneDeep(
								homophonesWordAttemptMap
							);
							if (newHomophonesWordAttemptMap[word]) {
								newHomophonesWordAttemptMap[word] =
									newHomophonesWordAttemptMap[word] +
									SpellingAssessment.HOMOPHONE_ATTEMPT_START_COUNT;
							} else {
								newHomophonesWordAttemptMap[word] =
									SpellingAssessment.HOMOPHONE_ATTEMPT_START_COUNT;
							}
							setHomophonesWordAttemptMap(
								newHomophonesWordAttemptMap
							);
							if (
								newHomophonesWordAttemptMap[word] >=
								SpellingAssessment.HOMOPHONE_ATTEMPT_MAX_COUNT
							) {
								isHomophoneWord = false;
							}
						}
					});
				}
			}
		});
		return isHomophoneWord;
	};

	return (
		<div
			className={`${classes.spellingItem} ${interactive ? 'interactive' : ''
				} ${isCorrect === false && interactive === false ? 'incorrect' : ''
				}`}
		>
			<div
				className={`${classes.wrapper} ${isFocused ? 'hasFocus' : ''} ${isCorrect === false && interactive === false
					? 'incorrect'
					: 'correct'
					} spell-entry`}
				id={id}
			>
				<span className={`number`}>{num}.</span>
				<input
					ref={ref}
					type="text"
					className={`spell-input `}
					onPaste={e => e.preventDefault()}
					onDrop={e => e.preventDefault()}
					autoComplete={'off'}
					onBlur={handleBlur}
					onFocus={handleFocus}
					onChange={handleTextChange}
					onKeyPress={handleKeyPress}
					disabled={interactive === false || activeItem === false}
					value={typedWord}
					maxLength={20}
					spellCheck={false}
					autoCorrect={'off'}
				/>
				{interactive === false && isCorrect && (
					<CorrectIncorrect correct={true} />
				)}
				{interactive === false && isCorrect === false && (
					<CorrectIncorrect correct={false} />
				)}
			</div>
			{interactive === false && isCorrect === false && (
				<div className={classes.correctSpelling}>
					<i className="r180 arrow-right" />
					<span className="correct-spelling">{word}</span>
				</div>
			)}
		</div>
	);
}

SpellingItem.defaultProps = {
	id: '',
	word: '',
	num: 0,
	interactive: true,
	selectedWord: '',
	wordList: [],
	activeItem: false,
	nextWord: ''
};
SpellingItem.propTypes = {
	id: PropTypes.string,
	word: PropTypes.string.isRequired,
	num: PropTypes.number.isRequired,
	onTextChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onKeyPress: PropTypes.func,
	interactive: PropTypes.bool,
	onCompleted: PropTypes.func,
	selectedWord: PropTypes.string,
	activeItem: PropTypes.bool,
	nextWord: PropTypes.string
};
