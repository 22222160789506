import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SoundButton from '../SoundButton';
import PropTypes from 'prop-types';
import { isFirefox } from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
	addWrapper: {
		width: '100%',
		height: 'auto',
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		fontFamily: theme.fonts.uiBold,
		fontSize: '18px',
		color: theme.colors.writingZone.background
	},
	audioIconStyle: {
		color: theme.colors.writingZone.background,
		top: isFirefox() ? '-3.5px' : ' -2px',
		marginRight: '13px',
		position: 'relative',
		transition: 'transform 0.3s ease',
		'&:not(.disabled):hover': {
			transform: 'scale(1.2)'
		}
	},
	addIcon: {
		marginLeft: '16px',
		top: isFirefox() ? '-3px' : '-1px',
		position: 'relative',
		fontSize: '24px',
		cursor: 'pointer',
		color: theme.colors.writingZone.background,
		transform: 'rotate(45deg)',
	},
	disableMode: {
		cursor: 'not-allowed',
		color: theme.colors.grey,
		'& button': {
			cursor: 'not-allowed',
			color: theme.colors.grey
		}
	}
}));

const AddNewPanel = props => {
	const {title, addPanelHandler, disabled, renderData, id, className} = props;
	const classes = useStyles();

	const isNewpanelOpen =
		renderData.panels
			.filter(
				i =>
					i.id.toLowerCase().indexOf('end') === -1 &&
					i.id !== 'thesis'
			)
			.filter((item, index) => index > 1 && item.status === 'current')
			.length > 0;

	const addPanelDisabled = isNewpanelOpen || disabled;

	return (
		<div
			id={id}
			className={`${classes.addWrapper} ${addPanelDisabled ? classes.disableMode : ''} ${className} add-new-panel`}
		>
			<SoundButton
				icon="speaker-center"
				size={32}
				disabled={addPanelDisabled}
				transparentBackGround={true}
				className={classes.audioIconStyle}
				sound={{
					name: 'addPanel',
					src: ''
				}}
			/>
			<span id="addPanel-button" onClick={() => addPanelHandler(addPanelDisabled)}>
				{title}
			</span>
			<button
				className={classes.addIcon}
				onClick={() => addPanelHandler(addPanelDisabled)}
			>
				<i className="r180 close add" />
			</button>
		</div>
	);
};

AddNewPanel.defaultProps = {
	id: '',
	className: '',
	title: '',
	disabled: false,
	renderData: {
		panels: []
	}
};
AddNewPanel.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	renderData: PropTypes.object.isRequired,
	addPanelHandler: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

export default React.memo(AddNewPanel);
