import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    multipleChoiceContainer: {
		margin: 'auto',
		position: 'relative',
		width: '500px',
		height: '360px',
		'& button': {
			position: 'absolute',

			'&#button-0': {
				right: 'calc(50% + 12px)',
				bottom: 'calc(50% + 24px)'
			},
			'&#button-1': {
				left: 'calc(50% + 12px)',
				bottom: 'calc(50% + 24px)'
			},
			'&#button-2': {
				right: 'calc(50% + 12px)',
				top: 'calc(50% - 12px)'
			},
			'&#button-3': {
				left: 'calc(50% + 12px)',
				top: 'calc(50% - 12px)'
			},

			'&:hover, &:focus': {
				'& img': {
					width: '212px',
					height: '165.625px'
				}
			}
		},
		'& img': {
			width: '192px',
			height: '150px',
			borderRadius: '8px',
			border: '4px solid transparent',
			transition: 'all 0.3s ease',
			'&.selected': {
				border: `4px solid ${theme.colors.readingZone.primary}`,
				width: '212px',
				height: '165.625px',
			},
			'&.correct': {
				border: `4px solid ${theme.colors.successGreen}`
			},
			'&.incorrect': {
				border: `4px solid ${theme.colors.errorRed}`
			}
		},
		'& i': {
			position: 'absolute',
			left: '10px',
			bottom: '18px',
			color: theme.colors.white,
			borderRadius: '50%',
			padding: '6px 6px 4px 5px',
			'&.check': {
				background: theme.colors.successGreen
			},
			'&.x': {
				background: theme.colors.errorRed
			}
		}
    }
}));

const ImageChoice = (props) => {
	const { interactive, options, value } = props;

	const classes = useStyles();

	const handleClick = (e, id) => {
		const { onSelection } = props;
		e.preventDefault();
		if (interactive === false) {
			return;
		}
		onSelection(id);
	}

    return (
        <div className={classes.multipleChoiceContainer}>
			{
				options.map((option, idx) => {
					return <button id={`button-${idx}`} key={option.src} onClick={(e) => handleClick(e, option.id)}>
							<img
								className={`${value.includes(option.id) ? 'selected': ''} ${option.status} ${interactive ? 'pointer' : ''}`}
								src={option.src}
								title={option.title}
								alt={option.title}
							/>
							{
								option.status === 'correct' &&
								<i className='r180 check' />
							}
							{
								option.status === 'incorrect' &&
								<i className='r180 x' />
							}
						</button>
				})
			}
        </div>
    )

}

ImageChoice.defaultProps = {
	id: '',
	interactive: true,
	options: [],
	value: []
};
ImageChoice.propTypes = {
	id: PropTypes.string,
	interactive: PropTypes.bool,
	value: PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			])
		),
	options:  PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			id: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			]).isRequired,
			status: PropTypes.string.isRequired,
			src: PropTypes.string.isRequired
		})
	),
	onSelection: PropTypes.func.isRequired
};

export default React.memo(ImageChoice);
