import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		height: '40px',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'stretch',
		'& .tab': {
			width: props => `${100 / props.tabCount}%`,
			height: '100%',
			background: props =>
				props.isSuccessZone
					? theme.colors.successZone.aqua5
					: theme.colors.readingZone.blue5,
			borderRadius: '8px 8px 0 0',
			textAlign: 'center',
			lineHeight: '20px',
			fontSize: '16px',
			color: theme.colors.softBlack,
			transition: 'all 0.2s',
			boxShadow: 'inset 2px 0px 7px rgba(0,0,0,0.2)',
			'& i': {
				marginRight: '8px',
				position: 'relative',
				top: '1px'
			},
			'&.selected': {
				background: theme.colors.white,
				color: theme.colors.black,
				fontSize: '18px',
				fontWeight: '700',
				boxShadow: 'none'
			},
			'&:disabled': {
				background: theme.colors.paleGrey,
				boxShadow: 'none',
				cursor: 'not-allowed'
			}
		}
	}
}));

const TabBar = props => {
	const {id, className, tabs, onClick, selectedTab, isSuccessZone} = props;
	const classes = useStyles({
		tabCount: tabs.length,
		isSuccessZone: isSuccessZone
	});
	const handleClick = (e, tab) => {
		e.preventDefault();
		onClick(tab);
	};

	return (
		<div
			className={`${classes.wrapper} ${className}`}
			id={id}
			role={'tablist'}
		>
			{tabs.map(tab => {
				const isSelected = tab.id === selectedTab;
				return (
					<button
						key={tab.id}
						className={`tab ${isSelected ? 'selected' : ''}`}
						onClick={e => handleClick(e, tab)}
						disabled={tab.disabled === true}
						role={'tab'}
					>
						{isSelected === false && (
							<i className={`r180 ${tab.icon}`} />
						)}
						{tab.text}
					</button>
				);
			})}
		</div>
	);
};

TabBar.defaultProps = {
	id: '',
	className: '',
	tabs: [],
	isSuccessZone: false
};
TabBar.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	tabs: PropTypes.array,
	onClick: PropTypes.func.isRequired,
	isSuccessZone: PropTypes.bool
};

export default React.memo(TabBar);
