import { sleep } from './sleep';

export const loadSleep = (loadingTime = 0) => {
	return sleep(5000 - loadingTime);
};

export const isDemoMode = () => {
	return window.sessionStorage.getItem('isDemoMode') === 'true';
};

export const setDemoMode = (isDemo) => {
	window.sessionStorage.setItem('isDemoMode', isDemo);
}
