import {sample, shuffle} from 'lodash';
import media from '../../api/media';
import {MEDIA_SERVER_URL} from '../../utils/constants';
import {bulkReplace} from '../../utils/stringUtils';
import {getAudioExt} from '../../utils/audio';
import api from '../../api/api';
import { transitionToNextActivity } from '../../utils/navigation';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { loadSleep } from '../../utils/ui';

const EXT = getAudioExt();

export const Proofreading = {
	SENTENCE_BASE_URL: 'content/dictio/word/r180u_content_',
	SENTENCE_URL: `${MEDIA_SERVER_URL}/assets/dictio/ctxt/r180u_ctxt_{text}.${EXT}`,

	INTRO_VO: `${MEDIA_SERVER_URL}/assets/activi/proofread/r180u_proofread_intro.${EXT}`,
	TRIAL_HELP: `${MEDIA_SERVER_URL}/assets/activi/proofread/r180u_proofread_trialhelp.${EXT}`,
	MIDDLE_HELP: `${MEDIA_SERVER_URL}/assets/activi/proofread/r180u_proofread_midroundhelp.${EXT}`,
	TRY_AGAIN: `${MEDIA_SERVER_URL}/assets/activi/proofread/r180u_proofread_try_again.${EXT}`,
	FEEDBACK_HELP: `${MEDIA_SERVER_URL}/assets/activi/proofread/r180u_proofread_feedbackhelp.${EXT}`,
	DONE: `${MEDIA_SERVER_URL}/assets/activi/proofread/r180u_proofread_done.${EXT}`,
	DONE2: `${MEDIA_SERVER_URL}/assets/activi/proofread/r180u_proofread_done2.${EXT}`,

	IS_ALL_CORRECT_LENGTH: 3,

	initData: async(activityStudyDetails) => {
		const start = new Date().getTime();

		// preload the sounds used in this activity
		AudioPlayer2.loadSound(Proofreading.INTRO_VO);
		AudioPlayer2.loadSound(Proofreading.TRIAL_HELP);
		AudioPlayer2.loadSound(Proofreading.MIDDLE_HELP);
		AudioPlayer2.loadSound(Proofreading.TRY_AGAIN);
		AudioPlayer2.loadSound(Proofreading.FEEDBACK_HELP);
		AudioPlayer2.loadSound(Proofreading.DONE);
		AudioPlayer2.loadSound(Proofreading.DONE2);

		// format the data from the server into a format the UI can work with
		const sentencesDataList = [];
		activityStudyDetails.fluencyScores.map(async word => {
			let sentenceData = await media.getOne(`${Proofreading.SENTENCE_BASE_URL}${word.wordId}.json`);
			sentenceData.sentence_url = bulkReplace(Proofreading.SENTENCE_URL, {
				text: sentenceData.text
			});
			sentenceData.spellingZoneDistractors.push({
				dictionaryWordId: sentenceData.id,
				zone: 'spelling_zone',
				index: sentenceData.spellingZoneDistractors.length + 1,
				text: sentenceData.text
			});
			const shuffledArray = shuffle(sentenceData.spellingZoneDistractors);
			sentenceData.spellingZoneDistractors = shuffledArray;
			let randomizedWord = sample(sentenceData.spellingZoneDistractors);
			sentenceData.sentence = sentenceData.sentence.replace(/<W>(.*?)<\/W>/, `<W>${randomizedWord.text}</W>`);
			sentenceData.showFeedBackIcon = false;

			sentencesDataList.push(sentenceData);
		});

		const end = new Date().getTime();
		await loadSleep(end - start);

		return sentencesDataList;
	},

	// move to the next activity
	completeActivity: async(history, activityData) => {
		await transitionToNextActivity(history, {
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			}
		});
	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendProgressToServer: (activityData) => {
		let savedActivityData = {
			activity: {
				studentActivityId: activityData.studentActivityId,
				isComplete: false
			}
		};

		api.activity.updateActivity(savedActivityData);
	}
};
