import React from 'react';
import PropTypes from 'prop-types';
import MagicTextArea from '../MagicTextArea/MagicTextArea';
import {withStyles} from '@material-ui/styles';
import {DraggableCore} from 'react-draggable';
import { isFirefox } from '@reading/r180/src/utils/browsers';

const useStyles = theme => ({
	modal: {
		position: 'absolute',
		width: '350px',
		height: 'auto',
		backgroundColor: "#fff",
		border: `2px solid ${theme.colors.paleGrey}`,
		boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px',
		zIndex: '9999'
	},
	toolbar: {
		position: 'relative',
		width: '100%',
		height: '40px',
		paddingLeft: '6px',
		paddingRight: '12px',
		textAlign: 'center',
		background: theme.colors.readingZone.blue5,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		'& .dots': {
			cursor: 'pointer',
			width: '23px',
			height: '30px',
			background:
				'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAeCAYAAADHJYVoAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABeSURBVEhLYzjz8td/WuFRw7HiYWh404TZ/80tbP43AmlS5JAxTsNBmmGYFDlkjNvlE6GuA9KkyCHjYRih1MADY/hoUsQqQQ08ajhWjDtCR5MirfCo4VjxqOFY8K//AC6+lty50qJBAAAAAElFTkSuQmCC)'
		},
		'& .scratchpad': {
			fontFamily: theme.fonts.uiBold,
			color: theme.colors.softBlack,
			fontSize: '16px',
			width: '200px',
			margin: 'auto'
		},
		'& .close-button': {
			fontSize: '16px',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	},
	content: {
		padding: '0px 24px',
		marginBottom: '32px',
		height: 'auto',
		maxHeight: '360px',
	},
	autoMagic: {
		width: 'auto',
		'& textarea': {
			overflowY: isFirefox() ? 'auto' : 'overlay',

			'&::-webkit-scrollbar': {
				width: '6px'
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				padding: '10px 0'
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#CCC',
				borderRadius: '8px'
			}
		}
	}
});

class Scratchpad extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			x: 360,
			y: 150
		};
	}

	handleClose = () => {
		const {onModalClose, scratchText} = this.props;
		onModalClose(scratchText);
	};

	handleTextChange = text => {
		const {onScratchChange} = this.props;
		if (typeof onScratchChange === 'function') {
			onScratchChange(text);
		}
	};

	handleDrag = e => {
		const x = e.x - 0;
		const y = e.y + 0;
		this.setState({x, y});
	};

	render() {
		const {id, className, classes, scratchText} = this.props;
		const {x, y} = this.state;

		return (
			<DraggableCore handle=".dots" onDrag={this.handleDrag}>
				<div
					style={{position: 'absolute', left: x, top: y}}
					id="scratchpad"
				>
					<div className={`${className} ${classes.modal}`} id={id}>
						<div className={`${classes.toolbar}`}>
							<div className="dots"></div>
							<div className="scratchpad">Scratchpad</div>
							<button
								id="scratchpad-close"
								className="close-button"
								onClick={this.handleClose}
							>
								<i className="r180 x" />
							</button>
						</div>
						<div className={`${classes.content}`}>
							<MagicTextArea
								className={`${classes.autoMagic}`}
								startText={scratchText}
								onTextChange={this.handleTextChange}
								textAreaHeight={'300px'}
							/>
						</div>
					</div>
				</div>
			</DraggableCore>
		);
	}
}

Scratchpad.defaultProps = {
	id: '',
	className: '',
	scratchText: ''
};
Scratchpad.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	scratchText: PropTypes.string,
	onModalClose: PropTypes.func.isRequired,
	onScratchChange: PropTypes.func
};

export default withStyles(useStyles, {withTheme: true})(Scratchpad);
