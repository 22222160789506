import React, {useEffect, useState, useRef} from 'react';
import {useStyles} from './ReadingPassage.style';
import {keyAndClick} from '../../util/ui-a11y';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';
import {createAssetId} from '@reading/r180/src/media/mediaUtil';
import {sleep} from '@reading/r180/src/utils/sleep';

const EXT = getAudioExt();

const ReadingPassage = ({
	passageString,
	title,
	setCardWord,
	dontSpeakWords,
	readWords,
	readPhrases,
	wordDelay,
	stage,
	segment,
	level,
	setReadAloudComplete,
	correctiveFeedbackList,
	showCorrectiveFeedback,
	isHighlightQuestionActive,
	highlightQuestionOptions,
	onOptionSelect,
	canClickPassageWord,
	isFinalRecordingFluencyCheck,
	fluencyCheckOptions,
	onWordAdded,
	enableFeedback = false,
	selectedTabID = '',
	selectedTabIndex = '',
	isCompact = false,
	readAloudComplete,
	readAloudPaused = false,
	readType = ''
}) => {
	passageString = passageString.replace(/&nbsp;/g, ' ');
	const [activeWord, setActiveWord] = useState({word: false, uid: false});
	const [wordIndex, setWordIndex] = useState(0);
	const [phraseIndex, setPhraseIndex] = useState(0);
	const timeoutHandler = useRef();
	const classes = useStyles();

	let paragraphCount = 1;

	//paragraph, sentence, phrase, word, space, punc
	const wordClick = async ({word, uid, dataId, startIndex, endIndex}) => {
		if (readWords || readPhrases) {
			return;
		} // Do not highlight if the passage is being read aloud.
		if (isHighlightQuestionActive) {
			if (!canClickPassageWord) {
				return;
			}
			const selectedOption = highlightQuestionOptions.filter(
				item =>
					item.highlight_start_offset <= startIndex &&
					item.highlight_end_index >= endIndex
			);
			if (selectedOption.length > 0) {
				onOptionSelect(selectedOption[0]);
			}
		} else if (isFinalRecordingFluencyCheck) {
			onOptionSelect({
				word,
				uid,
				dataId,
				startIndex,
				endIndex,
				text: word
			});
		} else if (
			word === activeWord.word &&
			uid === activeWord.uid &&
			setCardWord
		) {
			setCardWord({word: dataId, uid});
		} else {
			setActiveWord({word, uid});
			if (!dontSpeakWords) {
				await AudioPlayer2.playSync(`word_${dataId}`, false, true);
				setActiveWord({word: false, uid: false});
			}
		}
	};
	let wordId = 0;
	let phraseCount = 0;
	let letterIndex = 0;
	const assetId = createAssetId(stage, segment, level, true);
	let correctiveFlag = false;

	const addHighlightPassageClasses = (
		classes,
		startIndex,
		endIndex,
		isWord = false
	) => {
		let highlightPassageOption = false;
		let shouldAppplyBackground = false;
		let newClasses = [...classes];
		const highlightOptionsList = highlightQuestionOptions.filter(
			item =>
				item.highlight_start_offset <= startIndex &&
				item.highlight_end_index >= endIndex
		);
		highlightPassageOption = highlightOptionsList.length > 0;
		shouldAppplyBackground =
			highlightPassageOption && highlightOptionsList[0].applyBackground;
		if (highlightPassageOption && isWord) {
			newClasses.push('passageQuestionHighlight');
		}
		if (shouldAppplyBackground) {
			newClasses.push('applyBackground');
		}
		return newClasses;
	};

	const parseNode = (node, highlightFeedbackWord = false) => {
		if (node.nodeName === 'WORD') {
			const startIndex = letterIndex;
			letterIndex += node.innerHTML.length;
			const endIndex = letterIndex;
			const uid = wordId++;
			const word = node.innerHTML;
			const dataId = node.getAttribute('data-id');
			let wordClasses = node.getAttribute('class');
			if (wordClasses) {
				wordClasses = wordClasses.split(' ');
			} else {
				wordClasses = [];
			}
			if (highlightFeedbackWord) {
				wordClasses.push('feedback');
			} else {
				wordClasses = wordClasses.filter(item => item !== 'feedback');
			}

			if (isHighlightQuestionActive) {
				wordClasses = addHighlightPassageClasses(
					wordClasses,
					startIndex,
					endIndex,
					true
				);
			}
			if (isFinalRecordingFluencyCheck) {
				const highlightFluencyCheckOptions =
					fluencyCheckOptions.filter(
						item =>
							item.startIndex <= startIndex &&
							item.endIndex >= endIndex
					).length > 0;
				if (highlightFluencyCheckOptions) {
					wordClasses.push('highlightFluencyCheckOptions');
				}
			}

			if (!dontSpeakWords) {
				//Preload word audio if not yet already
				AudioPlayer2.load({
					name: `word_${dataId}`,
					src: `${MEDIA_SERVER_URL}/assets/dictio/word/r180u_word_${dataId}.${EXT}`
				});
			}
			return (
				<Word
					key={`word${uid}`}
					onClick={wordClick}
					word={word}
					dataId={dataId}
					activeWord={activeWord}
					uid={uid}
					wordClasses={wordClasses}
					startIndex={startIndex}
					endIndex={endIndex}
				/>
			);
		}
		if (node.nodeName === 'PUNC') {
			letterIndex += node.innerHTML.length;
			const punctClasses = isHighlightQuestionActive
				? addHighlightPassageClasses(
						[],
						letterIndex,
						letterIndex,
						false
				  )
				: [];
			return (
				<Punc
					punc={node.innerHTML}
					key={`punc${letterIndex}`}
					punctClasses={punctClasses}
				/>
			);
		}
		if (node.nodeName === 'SPACE') {
			letterIndex += node.innerHTML.length;
			const spaceClasses = isHighlightQuestionActive
				? addHighlightPassageClasses(
						[],
						letterIndex,
						letterIndex,
						false
				  )
				: [];
			return (
				<Space
					key={`space${letterIndex}`}
					spaceClasses={spaceClasses}
				/>
			);
		}
		if (node.nodeName === 'PHRASE') {
			Array.from(node.attributes).forEach(i => {
				if (i.name.indexOf('data-discrepancy-feedback') > -1) {
					correctiveFlag = true;
				} else {
					correctiveFlag = false;
				}
			});
			phraseCount++;

			AudioPlayer2.load({
				name: `phrase${phraseCount}`,
				src: `${MEDIA_SERVER_URL}/assets/passages/${stage}/${segment
					.toString()
					.padStart(2, '0')}/${level
					.toString()
					.padStart(
						2,
						'0'
					)}/r180u_${assetId}_${phraseCount
					.toString()
					.padStart(3, '0')}.mp3`
			}); //Fun fact, no oggs are available for these phrases

			return (
				<Phrase
					index={phraseCount}
					key={`phrase${phraseCount}`}
					correctiveFlag={correctiveFlag && enableFeedback}
				>
					{Array.from(node.children).map(node =>
						parseNode(node, highlightFeedbackWord)
					)}
				</Phrase>
			);
		}
		if (node.nodeName === 'SENTENCE') {
			const highlightFeedbackWord =
				showCorrectiveFeedback &&
				correctiveFeedbackList &&
				correctiveFeedbackList.filter(
					item => item.text.trim() === node.textContent.trim()
				).length > 0;
			return (
				<Sentence key={`sent${letterIndex}`}>
					{Array.from(node.children).map(node =>
						parseNode(node, highlightFeedbackWord)
					)}
				</Sentence>
			);
		}
		if (node.nodeName === 'PARAGRAPH') {
			return (
				<Paragraph
					index={paragraphCount++}
					key={`para${paragraphCount}`}
				>
					{Array.from(node.children).map(node => parseNode(node))}
				</Paragraph>
			);
		}
		if (node.nodeName === 'HEADERTEXT') {
			return (
				<HeaderText>
					{Array.from(node.children).map(node => parseNode(node))}
				</HeaderText>
			);
		}
	};
	const parser = new DOMParser();
	const passageData = Array.from(
		parser.parseFromString(passageString, 'text/html').documentElement
			.children[1].children[0].children
	);

	const parsedPassage = passageData.map(node => parseNode(node));

	useEffect(() => {
		/* Triggers when paue/Play is clicked in realous */
		const words = Array.from(
			document.querySelectorAll("[data-type='word']")
		);
		const phrases = Array.from(
			document.querySelectorAll("[data-type='phrase']")
		);

		const highlightPasued = async () => {
			/* Highlight the word when pause is clicke */
			await sleep(wordDelay + 5);
			if (readType === 'word') {
				words[wordIndex].classList.add(classes.wordHighlighted);
			} else {
				phrases[phraseIndex].classList.add(classes.wordHighlighted);
			}
		};

		/* istanbul ignore next */
		const clearPreviousHighlight = () => {
			/* Remove the previous Highlighted word when read aloud is resume */
			wordIndex > 0 &&
				words[wordIndex - 1].classList.remove(classes.wordHighlighted);
			phraseIndex > 0 &&
				phrases[phraseIndex - 1].classList.remove(
					classes.wordHighlighted
				);
		};

		readAloudPaused ? highlightPasued() : clearPreviousHighlight();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readAloudPaused]);

	useEffect(() => {
		const words = Array.from(
			document.querySelectorAll("[data-type='word']")
		);
		if (typeof onWordAdded === 'function') {
			onWordAdded(words.length);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (readWords) {
			readWord(wordIndex, wordDelay);
		}
	}, [wordIndex]);

	useEffect(() => {
		if (readPhrases) {
			readPhrase(phraseIndex, wordDelay);
		}
	}, [phraseIndex]);

	const readWord = async (index, wordDelay) => {
		const words = Array.from(
			document.querySelectorAll("[data-type='word']")
		);
		const word = words[index];
		if (!word || !word.classList) {
			return;
		}
		/* Read only when  Readloud is not completed*/
		if (!readAloudComplete) {
			word.classList.add(classes.wordHighlighted);
			document.querySelector("[data-id='scrollContainer']").scrollTop =
				word.offsetTop -
				document.querySelector("[data-id='scrollContainer']")
					.offsetHeight;
			await AudioPlayer2.playSync(`word_${word.dataset.dataid}`);
			if (readWords && index < words.length - 1) {
				setPhraseIndex(0);
				timeoutHandler.current = setTimeout(() => {
					word.classList.remove(classes.wordHighlighted);
					setWordIndex(index + 1);
				}, wordDelay);
			} else {
				setReadAloudComplete(true);
				setWordIndex(0);
			}
		}
	};

	const readPhrase = async (index, wordDelay) => {
		const phrases = Array.from(
			document.querySelectorAll("[data-type='phrase']")
		);
		const phrase = phrases[index];
		if (!phrase || !phrase.classList) {
			return;
		}
		/* Read only when  Readloud is not completed*/
		if (!readAloudComplete) {
			phrase.classList.add(classes.wordHighlighted);
			document.querySelector("[data-id='scrollContainer']").scrollTop =
				phrase.offsetTop -
				document.querySelector("[data-id='scrollContainer']")
					.offsetHeight;
			await AudioPlayer2.playSync(`phrase${index + 1}`);

			if (readPhrases && index < phrases.length - 1) {
				setWordIndex(0);
				timeoutHandler.current = setTimeout(() => {
					setPhraseIndex(index + 1);
					phrase.classList.remove(classes.wordHighlighted);
				}, wordDelay);
			} else {
				setReadAloudComplete(true);
				setPhraseIndex(0);
			}
		}
	};

	/* Look for change in tab or change in the
	    phase/word Switch & reset the play index */
	useEffect(() => {
		const resetPassageIndex = () => {
			setWordIndex(0);
			setPhraseIndex(0);
		};
		if (selectedTabID.length > 0 && selectedTabIndex !== -1)
			resetPassageIndex();
	}, [selectedTabID, selectedTabIndex]);
	useEffect(() => {
		/*Clear highlighting from previous playback*/
		Array.from(
			document.querySelectorAll("[data-type='word']")
		).forEach(word => word.classList.remove(classes.wordHighlighted));
		Array.from(
			document.querySelectorAll("[data-type='phrase']")
		).forEach(word => word.classList.remove(classes.wordHighlighted));
	}, [readType]);

	useEffect(() => {
		//Stop previous playback
		AudioPlayer2.stopAll();
		clearTimeout(timeoutHandler.current);

		//Trigger first playback event if necessary
		if (readWords) {
			setReadAloudComplete(false);
			timeoutHandler.current = setTimeout(() => {
				readWord(wordIndex, wordDelay);
			}, wordDelay);
		}
		if (readPhrases) {
			setReadAloudComplete(false);
			timeoutHandler.current = setTimeout(() => {
				readPhrase(phraseIndex, wordDelay);
			}, wordDelay);
		}
	}, [readWords, readPhrases]);

	return (
		<div className={`${classes.wrapper}`}>
			<div
				className={`${classes.scrollContainer} ${isCompact &&
					classes.compactScrollContainer}`}
				data-id={'scrollContainer'}
			>
				<div className={`${classes.textBody}`}>
					<div className={`${classes.textTitle}`} role={'heading'}>
						{title}
					</div>

					{parsedPassage}
				</div>
			</div>
		</div>
	);
};

const Paragraph = props => {
	const {children, index} = props;
	const classes = useStyles();
	return (
		<div className={classes.paragraph}>
			<div className={classes.paragraphNumber}>{index}</div>
			<div className={classes.paragraphIndent}></div>
			{children}
		</div>
	);
};

const Sentence = props => {
	const {children} = props;
	const classes = useStyles();
	return <div className={classes.sentence}>{children}</div>;
};

const HeaderText = props => {
	const {children} = props;
	const classes = useStyles();
	return (
		<div className={classes.header} role={'heading'}>
			{children}
		</div>
	);
};
const Phrase = props => {
	const {children, index, correctiveFlag} = props;
	const classes = useStyles();
	return (
		<div
			className={
				correctiveFlag
					? classes.correctiveFeedbackEnabled
					: classes.phrase
			}
			data-phraseindex={index}
			data-type="phrase"
		>
			{children}
		</div>
	);
};
/*

 */
const Word = props => {
	const {
		word,
		dataId,
		onClick,
		uid,
		activeWord,
		wordClasses,
		startIndex,
		endIndex
	} = props;
	const classes = useStyles();
	return (
		<div
			dangerouslySetInnerHTML={{__html: word}}
			{...keyAndClick(() => {
				onClick({word, uid, dataId, startIndex, endIndex});
			})}
			className={`${classes.word} ${activeWord.word === word &&
				activeWord.uid === uid &&
				classes.wordHighlighted} ${wordClasses.indexOf('bold') > -1 &&
				classes.wordBold} ${wordClasses.indexOf('italic') > -1 &&
				classes.wordItalic} ${wordClasses.indexOf('underline') > -1 &&
				classes.wordUnderline} ${wordClasses.indexOf('feedback') > -1 &&
				classes.feedbackWord} ${wordClasses.indexOf(
				'passageQuestionHighlight'
			) > -1 && classes.passageQuestionHighlight} ${wordClasses.indexOf(
				'applyBackground'
			) > -1 &&
				classes.passageQuestionHighlightBackground} ${wordClasses.indexOf(
				'highlightFluencyCheckOptions'
			) > -1 && classes.highlightFluencyCheckOptions}`}
			tabIndex={0}
			data-dataid={dataId}
			data-uid={uid}
			data-type="word"
		></div>
	);
};

const Punc = props => {
	const {punc, punctClasses} = props;
	const classes = useStyles();
	return (
		<div
			className={`${classes.punc} ${punctClasses.indexOf(
				'applyBackground'
			) > -1 && classes.passageQuestionHighlightBackground}`}
		>
			{punc}
		</div>
	);
};

const Space = props => {
	const {spaceClasses} = props;
	const classes = useStyles();
	return (
		<div
			className={`${classes.space} ${spaceClasses.indexOf(
				'applyBackground'
			) > -1 && classes.passageQuestionHighlightBackground}`}
		>
			{' '}
		</div>
	);
};
export default ReadingPassage;
