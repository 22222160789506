import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Modal} from '@material-ui/core';
import { isFirefox } from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
	modal: {
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
		position: 'absolute',
		width: '640px',
		height: 'auto',
		backgroundColor: theme.colors.white,
		border: `3px solid ${theme.colors.paleGrey}`,
		boxShadow: '4px 4px 16px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px'
	},
	toolbar: {
		position: 'relative',
		width: '100%',
		height: '64px',
		lineHeight: '64px',
		paddingLeft: '40px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		'& .highlight-button': {
			display: 'inline-block',
			color: theme.colors.grey,
			fontSize: '32px',
			marginRight: '15px'
		},
		'& .yellow-button': {
			display: 'inline-block',
			background: theme.colors.highlightYellow,
			border: `1px solid ${theme.colors.paleGrey}`,
			height: '24px',
			width: '24px',
			borderRadius: '12px',
			marginRight: '15px',
			'&.selected, &:active': {
				borderWidth: '2px'
			}
		},
		'& .blue-button': {
			display: 'inline-block',
			background: theme.colors.readingZone.blue5,
			border: `1px solid ${theme.colors.readingZone.background}`,
			height: '24px',
			width: '24px',
			borderRadius: '12px',
			marginRight: '15px',
			'&.selected, &:active': {
				borderWidth: '2px'
			}
		}
	},
	closeButton: {
		position: 'absolute',
		top: '8px',
		right: '6px',
		border: `1px solid ${theme.colors.black}`,
		fontSize: '12px',
		height: '24px',
		width: '24px',
		borderRadius: '12px',
		'& i': {
			position: 'relative',
			top: isFirefox() ? '0px' : '0.5px'
		},
		'&:hover': {
			transform: 'scale(1.2)'
		}
	},
	content: {
		padding: '46px 10px  0 40px',
		marginBottom: '32px',
		'& .title': {
			color: theme.colors.black,
			fontSize: '48px',
			lineHeight: '54px',
			marginBottom: '32px',
			fontFamily: theme.fonts.uiBold
		},
		'& .paragraphs': {
			maxHeight: '600px',

			marginRight: '12px',
			overflowY: isFirefox() ? 'auto' : 'overlay',
			'&::-webkit-scrollbar': {
				width: '6px'
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				padding: '10px 0'
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#CCC',
				borderRadius: '8px'
			}
		},
		'& .text': {
			position: 'relative',
			fontFamily: theme.fonts.context,
			fontSize: '18px',
			lineHeight: '36px',
			marginBottom: '24px',
			textIndent: '32px',
			paddingRight: '6px',
			'&:last-of-type': {
				marginBottom: 0
			},
			'& .ordinal': {
				position: 'absolute',
				fontFamily: theme.fonts.uiBold,
				color: theme.colors.slate,
				fontSize: '14px',
				left: '-30px',
				top: '1px'
			}
		}
	}
}));

const PassageWindow = props => {
	const {
		id,
		className,
		isOpen,
		paragraphs,
		onModalClose,
		showToolBar
	} = props;

	const classes = useStyles();

	const handleClose = () => {
		if (typeof onModalClose !== 'undefined') {
			onModalClose();
		}
	};

	return (
		<Modal
			disableBackdropClick
			disableEscapeKeyDown
			aria-labelledby="passage-window"
			aria-describedby="passage-window"
			open={isOpen}
			style={{zIndex: '99999'}}
			onClose={handleClose}
			className={`r180-modal`}
		>
			<div
				className={`${className} ${classes.modal} modal-window`}
				id={id}
			>
				<button
				    id="passage-close"
					className={`${classes.closeButton}`}
					onClick={handleClose}
				>
					<i className="r180 x" />
				</button>

				{showToolBar && (
					<div className={`${classes.toolbar}`}>
						<button className="highlight-button">
							<i className="r180 highlight" />
						</button>
						<button className="yellow-button"></button>
						<button className="blue-button"></button>
					</div>
				)}
				<div className={`${classes.content}`}>
					<div className={`paragraphs`}>
						{paragraphs.map((p, idx) => {
							return (
								<div key={`p-${idx}`} className={`text`}>
									<span className='ordinal'>{idx + 1}</span>
									{p}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</Modal>
	);
};

PassageWindow.defaultProps = {
	id: '',
	className: '',
	isOpen: false,
	paragraphs: [],
	showToolBar: false
};
PassageWindow.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	paragraphs: PropTypes.array,
	onModalClose: PropTypes.func,
	showToolBar: PropTypes.bool
};

export default React.memo(PassageWindow);
