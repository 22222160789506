/* eslint-disable no-cond-assign, no-loop-func */
/**
 * A typical deepSet you see in JavaScript, but this one supports
 * Arrays and multiple levels of Arrays.
 * For example:
 * var obj = { one: { two: [{three: [{four:'a'}, {four:'b'}]}, {three: [{four:'a'}, {four:'b'}]}]}};
 * deepSet(obj, 'one.two[].three[].four', 'yay');
 * Output = { one: { two: [{three: [{four:'yay'}, {four:'yay'}]}, {three: [{four:'yay'}, {four:'yay'}]}]}};
 * @param {*} obj  the object being transformed - it will be mutated
 * @param {*} path  a String path where the value should be set
 * @param {*} value the object to set upon each value
 */
export const deepSet = (obj, path, value) => {
	const re = /(\.|\[\]\.)/g;
	let i = 0, match = null;
	while (match = re.exec(path)) {
	  const sep = match[0];
	  const {length} = sep;
	  const {index} = match;
	  obj = obj[path.slice(i, index)];
	  i = index + length;
	  if (1 < length) {
		path = path.slice(i);
		obj.forEach(obj => {
		  deepSet(obj, path, value);
		});
		return;
	  }
	}
	obj[path.slice(i)] = value;
  };
