import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {getResultsQuestion} from './WordFamilyUtils';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import CorrectIncorrect from '../../components/CorrectIncorrect';

export const useStyles = makeStyles(theme => ({
	checkmark: {
		borderRadius: '50%',
		color: theme.colors.pureWhite,
		fontSize: '16px',
		position: 'relative'
	},
	questionTitle: {
		width: '80%',
		height: '64px',
		lineHeight: '32px',
		fontSize: '18px',
		letterSpacing: '0.15px',
		margin: '24px 0 16px 0',
		display: 'inline-block'
	},
	questionSection: {
		height: '104px',
		width: '100%',
		borderBottom: `1px solid ${theme.colors.paleGrey}`,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		'&:last-of-type': {
			border: 'none'
		},
		'& .correct-incorrect': {
			marginRight: '40px',
			top: '29px'
		}
	}
}));

const ResultRows = (props) => {
	const {
		questionsSet,
		correctResult,
		answeredChoice
	} = props;

	const classes = useStyles();

	return (
		<>
			{questionsSet.map((item, index) => (
				<div className={classes.questionSection} key={index}>
					<CorrectIncorrect correct={correctResult[index]} />
					<span
						className={classes.questionTitle}
						dangerouslySetInnerHTML={{
							__html: getResultsQuestion(
								stripHtmlTags(item),
								answeredChoice[index],
								correctResult[index]
							)
						}}
					></span>
				</div>
			))}
		</>
	);
};
ResultRows.prototype = {
	questionsList: PropTypes.object,
	totalQuestions: PropTypes.number,
	correctResult: PropTypes.array,
	questionsSet: PropTypes.array
};

ResultRows.defaultProps = {
	questionsList: {},
	totalQuestions: 0,
	correctResult: [],
	questionsSet: []
};
export default React.memo(ResultRows);
