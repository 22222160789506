import shortid from 'shortid';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {WordCardPreloaderStatus, WordCardText} from './constants';
import {DecodingTipConfig} from './data/decodingTipConstants';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import { isEmpty } from 'lodash';
import { isLetter } from '@reading/r180/src/utils/stringUtils';

const getWordParts = decodingInfo => {
	const {segments, parts} = decodingInfo;

	const wordSegments = segments.replace('$', '/').split('/');

	const wordParts = wordSegments.reduce((flat, x, i) => {
		const sep = {text: '+', type: WordCardText.SEPARATOR};
		let wds;

		const regExp = /\(([^)]+)\)/;
		const matches = regExp.exec(x);
		if (matches) {
			wds = x.split(matches[0]);
			wds = wds.map(c =>
				c === ''
					? {
							text: matches[1],
							type: WordCardText.SILENT,
							id: `sprite-${shortid.generate()}`
					  }
					: {
							text: c,
							type: WordCardText.NORMAL,
							id: `sprite-${shortid.generate()}`
					  }
			);
		} else {
			wds = [
				{
					text: x,
					type: WordCardText.NORMAL,
					id: `sprite-${shortid.generate()}`
				}
			];
		}

		const wSep = flat.length
			? [{...sep, id: `sprite-${shortid.generate()}`}, ...wds]
			: [...wds];

		return [...flat, ...wSep];
	}, []);

	return wordParts;
};

const getWordSpell = decodingInfo => {
	const {segments} = decodingInfo;

	const parts = segments
		.replace('$', '/')
		.replace(/\(/, '')
		.replace(/\)/, '')
		.split('/');

	const nList = parts.reduce((flat, x, i) => {
		const sep = {text: '+', type: WordCardText.SEPARATOR};
		let segs = null;
		const wds = x.split('').map(c => ({
			text: c,
			type: WordCardText.NORMAL,
			id: `sprite-${shortid.generate()}`
		}));

		segs = flat.length
			? [{...sep, id: `sprite-${shortid.generate()}`}, ...wds]
			: [...wds];

		return [...flat, ...segs];
	}, []);

	return nList;
};

const getWordTips = ({
	baseWord,
	element,
	segments,
	markup,
	baseChange,
	id,
	wordText,
	varCode
}) => {
	switch (id) {
		case '1':
		case '2': {
			return wordText.split('').map(letterText => ({
				text: letterText,
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			}));
		}
		case '3': {
			const initialWords = {
				text: segments.replace('$', '/').split('/')[0],
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			const middleWords = {
				text: '+',
				type: WordCardText.SEPARATOR,
				id: `sprite-${shortid.generate()}`
			};
			const lastWords = {
				text: segments.replace('$', '/').split('/')[1],
				type: WordCardText.TIPSFOCUS,
				id: `sprite-${shortid.generate()}`
			};
			return [initialWords, middleWords, lastWords];
		}
		case '4': {
			const initialWords = getWordSegmentsWithSeperator(
				markup.split('<')[0].split('/')
			);
			const middleWords = {
				text: markup.split('<')[1].split('>')[0],
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			const lastWords = getWordSegmentsWithSeperator(
				markup.split('>')[1].split('/')
			);
			return [...initialWords, middleWords, ...lastWords];
		}
		case '5':
		case '7':
		case '8':
		case '9':
		case '10':
		case '11': {
			const updatedMarkUp = markup.replace('$', '/');
			const initialWords = getWordSegmentsWithSeperator(
				updatedMarkUp.split('<')[0].split('/')
			);
			const middleWords = {
				text: element,
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			const lastWords = getWordSegmentsWithSeperator(
				updatedMarkUp.split('>')[1].split('/')
			);
			return [...initialWords, middleWords, ...lastWords];
		}

		case '12':
		case '16':
		case '17': {
			const initialWords = {
				text: baseWord,
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			const middleWords = {
				text: '+',
				type: WordCardText.SEPARATOR,
				id: `sprite-${shortid.generate()}`
			};
			const lastWords = {
				text: element,
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			return [initialWords, middleWords, lastWords];
		}

		case '13': {
			let initialWords = [];
			let middleWords = [];
			let lastWords = [];
			switch (baseChange) {
				case 1: {
					initialWords = baseWord
						.split('')
						.map((letter, index, arr) => {
							if (index === arr.length - 1) {
								return {
									text: letter,
									type: WordCardText.TIPHIGHLIGHT,
									id: `sprite-${shortid.generate()}`
								};
							}
							return {
								text: letter,
								type: WordCardText.TIPSFOCUS,
								id: `sprite-${shortid.generate()}`
							};
						});
					middleWords = [
						{
							text: 'i',
							type: WordCardText.TIPREPLACE,
							id: `sprite-${shortid.generate()}`
						},
						{
							text: '+',
							type: WordCardText.SEPARATOR,
							id: `sprite-${shortid.generate()}`
						}
					];
					lastWords = {
						text: element,
						type: WordCardText.TIPS,
						id: `sprite-${shortid.generate()}`
					};
					break;
				}
				case 2: {
					initialWords = baseWord
						.split('')
						.map((letter, index, arr) => {
							if (index === arr.length - 1) {
								return {
									text: letter,
									type: WordCardText.TIPHIGHLIGHT,
									id: `sprite-${shortid.generate()}`
								};
							}
							return {
								text: letter,
								type: WordCardText.TIPSFOCUS,
								id: `sprite-${shortid.generate()}`
							};
						});
					middleWords = [
						{
							text: '+',
							type: WordCardText.SEPARATOR,
							id: `sprite-${shortid.generate()}`
						}
					];
					lastWords = {
						text: element,
						type: WordCardText.TIPS,
						id: `sprite-${shortid.generate()}`
					};
					break;
				}
				case 3: {
					const wTips = baseWord.split('').map(letter => {
						return {
							text: letter,
							type: WordCardText.TIPSFOCUS,
							id: `sprite-${shortid.generate()}`
						};
					});
					const highlightorWord = {
						text: baseWord.slice(-1),
						type: WordCardText.TIPHIGHLIGHT,
						id: `sprite-${shortid.generate()}`
					};

					initialWords = [...wTips, highlightorWord];
					middleWords = [
						{
							text: '+',
							type: WordCardText.SEPARATOR,
							id: `sprite-${shortid.generate()}`
						}
					];
					lastWords = {
						text: element,
						type: WordCardText.TIPS,
						id: `sprite-${shortid.generate()}`
					};
					break;
				}

				case 5: {
					initialWords = baseWord
						.split('')
						.map((letter, index, arr) => {
							return {
								text: letter,
								type: WordCardText.TIPSFOCUS,
								id: `sprite-${shortid.generate()}`
							};
						});
					middleWords = [
						{
							text: wordText
								.replace(baseWord, '')
								.replace(element, ''),
							type: WordCardText.TIPREPLACE,
							id: `sprite-${shortid.generate()}`
						},
						{
							text: '+',
							type: WordCardText.SEPARATOR,
							id: `sprite-${shortid.generate()}`
						}
					];
					lastWords = {
						text: element,
						type: WordCardText.TIPS,
						id: `sprite-${shortid.generate()}`
					};
					break;
				}
				default:
					break;
			}
			return [...initialWords, ...middleWords, lastWords];
		}

		case '14': {
			const initialWords = {
				text: element,
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			const middleWords = {
				text: '+',
				type: WordCardText.SEPARATOR,
				id: `sprite-${shortid.generate()}`
			};
			const lastWords = {
				text: baseWord,
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			return [initialWords, middleWords, lastWords];
		}
		case '19': {
			switch (varCode) {
				case 2: {
					if (baseChange === 2) {
						const initialWords = {
							text: baseWord.slice(0, baseWord.length - 1),
							type: WordCardText.TIPS,
							id: `sprite-${shortid.generate()}`
						};
						const replaceWords = {
							text: baseWord.slice(-1),
							type: WordCardText.TIPHIGHLIGHT,
							id: `sprite-${shortid.generate()}`
						};
						const middleWords = {
							text: '+',
							type: WordCardText.SEPARATOR,
							id: `sprite-${shortid.generate()}`
						};

						const lastWords = getWordSegmentsWithSeperator(
							markup
								.split('>')[1]
								.split('+')
								.slice(1)
						);
						return [
							initialWords,
							replaceWords,
							middleWords,
							...lastWords
						];
					} else {
						const initialWords = {
							text: baseWord,
							type: WordCardText.TIPS,
							id: `sprite-${shortid.generate()}`
						};
						const middleWords = {
							text: '+',
							type: WordCardText.SEPARATOR,
							id: `sprite-${shortid.generate()}`
						};

						const lastWords = getWordSegmentsWithSeperator(
							markup
								.split('>')[1]
								.split('+')
								.slice(1)
						);
						return [initialWords, middleWords, ...lastWords];
					}
				}
				default: {
					const initialWords = getWordSegmentsWithSeperator(
						markup
							.split('<')[0]
							.split('+')
							.slice(0, 1)
					);

					const seperatorWords1 = {
						text: '+',
						type: WordCardText.SEPARATOR,
						id: `sprite-${shortid.generate()}`
					};

					const middleWords = {
						text: baseWord.slice(0, baseWord.length - 1),
						type: WordCardText.TIPS,
						id: `sprite-${shortid.generate()}`
					};
					const replaceWords = {
						text: baseWord.slice(-1),
						type: WordCardText.TIPHIGHLIGHT,
						id: `sprite-${shortid.generate()}`
					};

					const seperatorWords2 = {
						text: '+',
						type: WordCardText.SEPARATOR,
						id: `sprite-${shortid.generate()}`
					};

					const lastWords = getWordSegmentsWithSeperator(
						markup
							.split('>')[1]
							.split('+')
							.slice(1)
					);
					return [
						...initialWords,
						seperatorWords1,
						middleWords,
						replaceWords,
						seperatorWords2,
						...lastWords
					];
				}
			}
		}

		case '24': {
			const initialWords = getWordSegmentsWithSeperator(
				markup.split('<')[0].split('/')
			);
			const middleWords = getWordSegmentsWithSeperator(
				markup
					.split('<')[1]
					.split('>')[0]
					.split('/')
			).map(word => {
				if (word.type === WordCardText.TIPSFOCUS) {
					return {...word, type: WordCardText.TIPS};
				} else {
					return word;
				}
			});

			const lastWords = getWordSegmentsWithSeperator(
				markup.split('>')[1].split('/')
			);
			return [...initialWords, ...middleWords, ...lastWords];
		}

		case '18': {
			let initialWords = [];
			let middleWords = [];
			let lastWords = [];

			initialWords = baseWord.split('').map((letter, index, arr) => {
				if (index === arr.length - 1) {
					return {
						text: letter,
						type: WordCardText.TIPHIGHLIGHT,
						id: `sprite-${shortid.generate()}`
					};
				}
				return {
					text: letter,
					type: WordCardText.TIPSFOCUS,
					id: `sprite-${shortid.generate()}`
				};
			});
			middleWords = [
				{
					text: '+',
					type: WordCardText.SEPARATOR,
					id: `sprite-${shortid.generate()}`
				}
			];
			lastWords = {
				text: element,
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			return [...initialWords, ...middleWords, lastWords];
		}

		case '25':
		case '26':
		case '30': {
			const initialWords = {
				text: markup.split('<')[0],
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			const middleWords = getWordSegmentsWithSeperator(
				markup
					.split('<')[1]
					.split('>')[0]
					.split('/')
			);

			const lastWords = {
				text: markup.split('>')[1],
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			return [initialWords, ...middleWords, lastWords];
		}
		case '6':
		case '27': {
			const initialWords = getWordSegmentsWithSeperator(
				markup
					.replace('$', '/')
					.split('<')[0]
					.split('/')
			);
			const lastWords = {
				text: markup
					.replace('$', '/')
					.split('<')[1]
					.split('>')[0],
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			return [...initialWords, lastWords];
		}
		case '28':
		case '29': {
			const initialWords = getWordSegmentsWithSeperator(
				markup.split('<')[0].split('/')
			);
			const lastWords = {
				text: markup.split('<')[1].split('>')[0],
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			};
			return [...initialWords, lastWords];
		}
		case '23':
		case '31': {
			return getWordSegmentsWithSeperator(segments.split('/'));
		}

		case '32': {
			return wordText.split('').map(letterText => ({
				text: letterText,
				type: WordCardText.TIPS,
				id: `sprite-${shortid.generate()}`
			}));
		}
		default:
			return [];
	}
};

const getWordPartsAudioForTips = decodingInfo => {
	const partsAudioPath = '/parts/r180u_wp_';
	const audioFormat = 'mp3';
	const audioPath = [partsAudioPath, null, audioFormat];
	let wPartsWithSound = [];
	switch (decodingInfo.id) {
		case '12':
		case '13':
		case '14':
		case '16':
		case '17':
		case '18':
		case '19': {
			wPartsWithSound = [
				{
					type: WordCardText.PARTS,
					audioName: decodingInfo.baseWord,
					audioUrl: WordCardUtil.getAudioUrl(
						audioPath,
						decodingInfo.baseWord
					)
				}
			];
			break;
		}
		default: {
			wPartsWithSound = decodingInfo.parts.map(word => ({
				type: WordCardText.PARTS,
				audioName: word ? word.mediaName : '',
				audioUrl: word
					? WordCardUtil.getAudioUrl(audioPath, word.mediaName)
					: ''
			}));
			break;
		}
	}

	return wPartsWithSound;
};

const getWordTipsAudio = decodingTipId => {
	const decodingTipsAudio = DecodingTipConfig.audio;
	const tipKeys = Object.keys(decodingTipsAudio).filter(audio =>
		audio.includes(`TIP_${decodingTipId}_WORD_ZONE_AUDIO`)
	);
	return tipKeys.map(item => ({
		type: WordCardText.TIPS,
		audioName: decodingTipsAudio[item],
		audioUrl: `/decodingtips/r180u_${decodingTipsAudio[item]}.mp3`
	}));
};

const getWordAlphAudio = element => {
	if (element && element.length > 0) {
		return element.split('').map(item => ({
			type: WordCardText.TIPSFOCUS,
			audioName: `alph-${item}`,
			audioUrl: `/alph/r180u_alph_${item}.mp3`
		}));
	}
	return [];
};

const getWordBaseChangeAudio = baseChange => {
	const baseAudioId = `BASE_CHANGE_${baseChange}`;
	if (baseChange !== 0) {
		return [
			{
				type: WordCardText.TIPS,
				audioName: DecodingTipConfig.audio[baseAudioId],
				audioUrl: `/decodingtips/r180u_${DecodingTipConfig.audio[baseAudioId]}.mp3`
			}
		];
	}
	return [];
};

const getWordSegmentsWithSeperator = segments =>
	segments.reduce((flat, x, i) => {
		const sep = {text: '+', type: WordCardText.SEPARATOR};
		let wds;

		const regExp = /\(([^)]+)\)/;
		const matches = regExp.exec(x);
		if (matches) {
			if (x.length === 3) {
				wds = [
					{
						text: matches[1],
						type: WordCardText.SILENT,
						id: `sprite-${shortid.generate()}`
					}
				];
			} else {
				wds = x.split(matches[0]);
				wds = wds.map(c =>
					c === ''
						? {
								text: matches[1],
								type: WordCardText.SILENT,
								id: `sprite-${shortid.generate()}`
						  }
						: {
								text: c,
								type: WordCardText.TIPSFOCUS,
								id: `sprite-${shortid.generate()}`
						  }
				);
			}
		} else {
			wds = [
				{
					text: x,
					type: WordCardText.TIPSFOCUS,
					id: `sprite-${shortid.generate()}`
				}
			];
		}
		const wSep = flat.length
			? [{...sep, id: `sprite-${shortid.generate()}`}, ...wds]
			: [...wds];

		return [...flat, ...wSep];
	}, []);

const getAudioUrl = (path, word) => {
	return path[1] > 1
		? `${path[0]}${word}_${path[1]}.${path[2]}`
		: `${path[0]}${word}.${path[2]}`;
};

const getAudioSpellUrl = (path, word) => {
	return `${path[0]}${word}.${path[2]}`;
};

const attachSoundForWordParts = (wordParts, parts, path, wordText) => {
	const wordPartsMap = wordParts.reduce(
		(flat, x) => ({...flat, [x.id]: x}),
		{}
	);
	const wsl = wordParts
		.filter(x => x.type === WordCardText.NORMAL)
		.map((w, i) => {
			const audioNameFilter = parts.filter(p => p.index === i + 1);
			return {
				...w,
				audioName: audioNameFilter.length
					? audioNameFilter[0].mediaName
					: wordText
			};
		})
		.reduce((flat, m) => ({...flat, [m.id]: m}), {});

	let nList = Object.values({...wordPartsMap, ...wsl});

	nList = nList.map(x => {
		const {audioName} = x;

		return audioName
			? {...x, audioUrl: WordCardUtil.getAudioSpellUrl(path, audioName)}
			: {...x};
	});

	return nList;
};

const attachSoundForWordSpell = (wordSpell, path) => {
	const wordSpellMap = wordSpell.reduce(
		(flat, x) => ({...flat, [x.id]: x}),
		{}
	);

	const wsl = wordSpell
		.filter(x => x.type === WordCardText.NORMAL)
		.map((w, i) => ({
			...w,
			audioName: `alphabet-${w.text}`
		}))
		.reduce((flat, m) => ({...flat, [m.id]: m}), {});

	let nList = Object.values({...wordSpellMap, ...wsl});

	nList = nList.map(x => {
		const {audioName} = x;

		return audioName
			? {...x, audioUrl: WordCardUtil.getAudioSpellUrl(path, x.text)}
			: {...x};
	});

	return nList;
};

const loadAllSounds = assets => {
	assets.forEach((x) => {

		if (isEmpty(x.text) === false && isLetter(x.text) === false) {
			return;
		}

		if (isEmpty(AudioPlayer2.assets[x.audioName])) {
			AudioPlayer2.load({
				name: x.audioName,
				src:
					x.type === WordCardText.TIPS
						? `${MEDIA_SERVER_URL}assets/activi` + x.audioUrl
						: `${MEDIA_SERVER_URL}assets/dictio` + x.audioUrl
			});
		}
	});
};

const loadSound = asset => {
	!AudioPlayer2.assets[asset.audioName] &&
		AudioPlayer2.load({name: asset.audioName, src: asset.audioUrl});
};

const getDuration = name => {
	const sound = AudioPlayer2.assets[name];

	return sound ? sound.howl._duration : 0;
};

export const preloadAssets = (assets, name) => {
	return new Promise((resolve, reject) => {
		try {
			WordCardUtil.loadAllSounds(assets);
			const counter = setInterval(() => {
				const loaded = WordCardUtil.hasPreloaded(assets);

				if (loaded) {
					clearInterval(counter);

					return resolve({
						name,
						status: WordCardPreloaderStatus.COMPLETED
					});
				}
			}, 200);

			setTimeout(() => {
				clearInterval(counter);

				return resolve({
					name,
					status: WordCardPreloaderStatus.COMPLETED
				});
			}, 2000);
		} catch (error) {
			return reject(error);
		}
	});
};

const hasPreloaded = sounds => {
	for (let i = 0; i < sounds.length; i++) {
		const sound = AudioPlayer2.assets[sounds[i].audioName];
		if (!sound) return false;
		if (!sound.loaded) return false;
	}

	return true;
};

export const WordCardUtil = {
	getWordParts,
	getWordSpell,
	getWordTips,
	getAudioUrl,
	getAudioSpellUrl,
	attachSoundForWordParts,
	attachSoundForWordSpell,
	getWordPartsAudioForTips,
	getWordTipsAudio,
	getWordAlphAudio,
	getWordBaseChangeAudio,
	getWordSegmentsWithSeperator,
	hasPreloaded,
	preloadAssets,
	getDuration,
	loadSound,
	loadAllSounds
};
