import AudioRecorder from '@reading/common/src/components/AudioRecorder/AudioRecorder';
import {throttle} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

const useUtterance = (options = {}) => {
	const noiseThreshold = options.noiseThreshold || 2;
	const silenceTime = options.silenceTime || 3000;

	const silenceTimeout = useRef(null);

	const [volumeLevel, setVolumeLevel] = useState(0);
	const [status, setStatus] = useState('off');
	const [isOnlySilence, setOnlySilence] = useState(true);

	const audioRecorder = useMemo(() => {
		return new AudioRecorder({
			onMediaAccept: () => {
				if (typeof options.onMicAccept === 'function') {
					options.onMicAccept();
				}
			},
			onMediaReject: () => {
				if (typeof options.onMicReject === 'function') {
					options.onMicReject();
				}
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // this is intentionally an empty array

	const record = useCallback(() => {
		setStatus('recording');
		audioRecorder.record();

		audioRecorder.levelsCallback = throttle(level => {
			setStatus(audioRecorder.micRecorder.state);
			if (audioRecorder.micRecorder.state === 'recording') {
				setVolumeLevel(parseInt(level * 10));
			}
		}, 200);
	}, [audioRecorder]);

	const stopRecord = useCallback(() => {
		setStatus('stopping');
		audioRecorder.stopRecord(false);
		clearTimeout(silenceTimeout.current);
		silenceTimeout.current = null;
		setStatus(isOnlySilence ? 'silence' : 'sound');
	}, [audioRecorder, isOnlySilence]);

	useEffect(() => {
		if (status === 'recording') {
			if (volumeLevel < noiseThreshold) {
				if (!silenceTimeout.current) {
					silenceTimeout.current = setTimeout(() => {
						stopRecord();
					}, silenceTime);
				}
			} else {
				setOnlySilence(false);
				clearTimeout(silenceTimeout.current);
				silenceTimeout.current = null;
			}
		}
	}, [volumeLevel, status, noiseThreshold, silenceTime, audioRecorder, silenceTimeout, stopRecord]);

	return {volumeLevel, status, record, stopRecord};
};

export default useUtterance;
