export const zoneMenuData = {
	zone_menu: {
		contents: {
			activity: '/api/v1/activity/1450024',
			zonestatus: '/api/v1/zonestatus/131786'
		},
		'/api/v1/zonestatus/131786': {
			state: {
				explore_zone: {progress: 100, available: false, enabled: true},
				reading_zone: {progress: 50, available: true, enabled: true},
				language_zone: {progress: 0, available: false, enabled: true},
				fluency_zone: {progress: 0, available: false, enabled: true},
				writing_zone: {progress: 0, available: false, enabled: false},
				success_zone: {progress: 0, available: false, enabled: true}
			},
			progress: {
				fluency_zone: {current: 0, max: 6, completedOptional: false},
				writing_zone: {current: 0, max: 5, completedOptional: false},
				explore_zone: {current: 3, max: 3, completedOptional: false},
				reading_zone: {current: 0, max: 11, completedOptional: false},
				language_zone: {current: 0, max: 6, completedOptional: false},
				success_zone: {current: 0, max: 3, completedOptional: false}
			},
			display: {
				fluency_zone: {displayName: 'Fluency Zone', displayChar: 'F'},
				writing_zone: {displayName: 'Writing Zone', displayChar: 'W'},
				explore_zone: {displayName: 'Explore Zone', displayChar: 'E'},
				reading_zone: {displayName: 'Reading Zone', displayChar: 'R'},
				language_zone: {displayName: 'Language Zone', displayChar: 'L'},
				success_zone: {displayName: 'Success Zone', displayChar: 'S'}
			}
		},
		'/api/v1/activity/1450024': {
			activityServerId: 'zone_menu',
			isComplete: false,
			navigation: null,
			stage: 'c',
			segment: 1,
			level: 1,
			endTime: null,
			elapsedSecs: null,
			startTime: '2020-10-26T17:02:46.000+0000'
		}
	},
	reading_strategy_selector: {
		'/api/v1/activity/1450085': {
			activityServerId: 'reading_strategy_selector',
			isComplete: false,
			navigation: null,
			stage: 'a',
			segment: 1,
			level: 1,
			endTime: null,
			elapsedSecs: null,
			startTime: '2020-10-28T19:06:13.000+0000'
		},
		contents: {
			activity: '/api/v1/activity/1450085',
			strategies: '/api/v1/strategies/ae4320f9770c45ee9e7ec4fabb689d14'
		},
		'/api/v1/strategies/ae4320f9770c45ee9e7ec4fabb689d14': {
			current: null,
			completed: ['Main Idea'],
			mandatoryRecordingsComplete: false
		}
	}
};
