import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

	Navbar: {
		width: '100%',
		height: theme.navbarHeight,
		minHeight: theme.navbarMinHeight,
		maxHeight: theme.navbarMaxHeight,
		position: 'relative',
		marginBottom: '0',
		zIndex: '1',
		transition: 'background 0.5s',
		fontFamily: theme.fonts.uiBold,

		'&.short': {
			height: '72px',
			minHeight: '72px',
			maxHeight: '72px'
		},

		'& .container': {
			padding: '0 20px',
			height: '100%',
			margin: 'auto',
			lineHeight: '62px',
			fontSize: '24px',
			color: theme.colors.white,
			textShadow: '0 1px 0 rgba(0,0,0,.2)',

			'& button': {
				background: 'transparent',
				color: theme.colors.white,
				fontSize: '24px',
				fontFamily: theme.fonts.ui,
				transition: 'transform 0.3s ease',
			},

			'& .left': {
				float: 'left',
				textAlign: 'left',
				'& .zoneName': {
					marginLeft: '20px',
					marginRight: '13px'
				},
				'& .activityName': {
					marginLeft: '13px'
				},
				'& i.zone-menu': {
					fontSize: '32px',
					position: 'relative',
					top: '5.5px'
				},
				'& i.arrow-right': {
					fontSize: '16px'
				}
			},

			'& .right': {
				float: 'right',
				textAlign: 'right',
				'& .r180': {
					position: 'relative',
					top: '2px'
				},
				'& .settings-button': {
					marginLeft: '28px',
					'& .arrow-up, & .arrow-down': {
						fontSize: '14px',
						marginLeft: '4px',
						top: '-2px'
					}
				},
				'& button': {
					transition: 'transform 0.3s ease',
					'&:hover': {
						transform: 'scale(1.2)'
					}
				},
				'& .navbar-help-button': {
					marginLeft: '32px',
					marginRight: '32px'
				}
			}
		}
	}
}));
