import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FeedbackBar from './FeedbackBar';
import SoundButton from '../SoundButton';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import ActivityInstructionButton from '../ActivityInstructionButton';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		padding: '64px 48px'
	},
	rightSide: {
		width: '100%',
		paddingLeft: '300px',
		'& .sound-button': {
			marginRight: '0px',
			top: '4px',
			float: 'left',
			transition: 'transform 0.3s ease',
			position: 'relative',
			left: '-10px',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		},
		'& .description': {
			fontSize: '24px',
			lineHeight: '39px',
			color: theme.colors.black,
			fontFamily: theme.fonts.ui,
			paddingLeft: '40px'
		}
	},
	bgImg: ({backGroundImage}) => ({
		backgroundImage: `url(${backGroundImage})`,
		backgroundSize: 'cover',
		height: '248px',
		width: '248px',
		backgroundPosition: '-64% 0',
		borderRadius: '4px',
		marginRight: '24px',
		float: 'left'
	})
}));

const WritingPrompt = props => {
	const {
		text,
		choices,
		backGroundImage,
		audioPath,
		showFeedback,
		introAudio,
		setWritingStatus,
		updateSelection,
		autoPlay
	} = props;

	const classes = useStyles({backGroundImage, showFeedback});
	const [voComplete, setVoCompleteStatus] = useState(false);

	useEffect(() => {
		const updateWritingPromptVO = async () => {
			/*istanbul ignore next */

			if (autoPlay) {
				await AudioPlayer2.playSync(introAudio);
			}
			if (!showFeedback) {
				setWritingStatus();
				setVoCompleteStatus(true);
			} else {
				setVoCompleteStatus(true);
			}
		};

		updateWritingPromptVO();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<ActivityInstructionButton audioSrc={introAudio} />
			<div className={classes.wrapper}>
				<div>
					<div className={classes.bgImg}></div>

					<div className={classes.rightSide}>
						<SoundButton
							icon="speaker-center"
							size={32}
							transparentBackGround={true}
							sound={{
								name: 'FeedBackPanel',
								src: audioPath
							}}
							disabled={!voComplete}
						/>

						<div className={'description'}>
							{text.question.prompt.trim()}
						</div>
					</div>
				</div>

				{showFeedback && (
					<FeedbackBar
						updateSelection={updateSelection}
						choices={choices}
						setWritingStatus={setWritingStatus}
						voComplete={voComplete}
					/>
				)}
			</div>
		</div>
	);
};

export default React.memo(WritingPrompt);
