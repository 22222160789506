import React, { useRef, useState } from 'react';
import SoundButton from '../SoundButton';
import DropSwitch from './components/DropSwitch';
import { ReactComponent as DragIcon } from './images/dragHandle.svg';
import { TipModal } from '@reading/common';
import CorrectIncorrect from '../CorrectIncorrect';
import useStyles from './DndTiles.styles';


const DndTiles = (props) => {
    const { item = {}, meta, type, className, onMenuChange, ...restProps } = props;
    const classes = useStyles();
    const helperIconSize = 30;
    const { dropBuckets } = meta;
    const [tipOpen, setTipOpen] = useState(false);
    const elm = useRef(null);
    const interactive = item.interactive

    const ResultFeedback = React.memo(({ validation }) => {
        if (validation && !interactive)
            return <CorrectIncorrect size={20} correct={validation === 'correct'} />
        else
            return <DropSwitch
                onMenuChange={(to) => onMenuChange(elm.current, to, item)}
                menuItems={dropBuckets} />
    });
    // ---------------------------------------------------


    const WordTile = React.memo(() => (
        <div
            className={`${classes.wordTile} ${item.validation} ${!interactive && 'completed'} word-tile`}>
            {interactive && (
                <div className="drag-helper" >
                    <DragIcon className={`drag-handle-icon`} />
                </div>
            )}
            <div title={item.text} draggable={false} className="tile">
                {item.text}
            </div>
            <div className="feedback">
                <ResultFeedback validation={item.validation} />
            </div>
        </div>
    ));
    // ---------------------------------------------------

    const ImageTile = React.memo(() => (
        <div
            className={`${classes.imageTile} ${item.validation} ${!interactive && 'completed'} image-tile`}>
            {interactive && (
                <div className="drag-helper" >
                    <DragIcon className={`drag-handle-icon`} />
                    <DropSwitch
                        icon="arrow-right"
                        onMenuChange={(to) => onMenuChange(elm.current, to, item)}
                        menuItems={dropBuckets} />
                </div>
            )}
            <img draggable={false} className="tile" src={item.text_url} alt={item.text} />
            {item.validation && (
                <div className="feedback">
                    <CorrectIncorrect size={20} correct={item.validation === 'correct'} />
                </div>
            )}
        </div>
    ));
    // ---------------------------------------------------

    return (
        <div
            ref={elm}
            data-dnd-id={item._dndIndex}
            className={`drag-item ${className} ${classes.tilesContainer} ${item.validation}`}
            {...restProps}
        >
            {item.tipUrl && (
                <div className="helpers">
                    <i className="r180 info" onClick={() => setTipOpen(true)} />
                    <SoundButton
                        className={classes.soundButton}
                        size={helperIconSize}
                        sound={{ src: item.textUrl, name: item.text }} />
                </div>
            )}
            <div className={`tile-item ${item.validation}`}>
                {meta.answerType === 'IMAGE' ? <ImageTile /> : <WordTile />}
            </div>
            <TipModal
                isOpen={tipOpen}
                word={item.text}
                wordUrl={item.textUrl}
                tip={item.tip}
                tipUrl={item.tipUrl}
                showClose={false}
                onConfirm={() => setTipOpen(false)}
                onCancel={() => setTipOpen(false)}
            />
        </div>
    );
};

export default React.memo(DndTiles);
