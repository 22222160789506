import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SpellingItem from './SpellingItem';
import { AudioPlayer2 } from '@shared/core';
import { useUnmount } from 'react-use';
import { SpellingAssessment } from '@reading/r180/src/activities/SpellingAssessment/SpellingAssessment';

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		width: '600px',
		textAlign: 'center',
		margin: 'auto'
	}
}));

export default function SpellingList(props) {
	const { id, wordObjList, interactive, onWordFocus, onWordBlur, onWordKeyPress, onTrialCompleted, selectedWord, trailCount } = props;
	const classes = useStyles();
	const trialRoundCount = (trailCount - 1) * SpellingAssessment.TRIAL_SIZE;

	useUnmount(() => {
		AudioPlayer2.stopAll();
	});

	return (
		<div className={classes.wrapper} id={id}>
			{
				wordObjList.map((wordObj, idx) => (
					<SpellingItem
						id={idx}
						word={wordObj.text}
						soundName={wordObj.text}
						num={((trialRoundCount + idx) + 1)}
						key={wordObj.wordIndex}
						interactive={interactive[wordObj.text]}
						onFocus={onWordFocus}
						onBlur={onWordBlur}
						onCompleted={onTrialCompleted}
						onKeyPress={onWordKeyPress}
						selectedWord={selectedWord}
						wordList={wordObjList}
						activeItem={idx === 0 ? true : false}
						nextWord={idx + 1 === wordObjList.length ? '' : wordObjList[idx + 1].text}
					/>
				))
			}
		</div>
	);
}

SpellingList.defaultProps = {
	id: '',
	wordObjList: [],
	interactive: {},
	selectedWord: ''
};
SpellingList.propTypes = {
	id: PropTypes.string,
	trailCount: PropTypes.number,
	interactive: PropTypes.object,
	onWordFocus: PropTypes.func,
	onWordBlur: PropTypes.func,
	onWordKeyPress: PropTypes.func,
	onTrialCompleted: PropTypes.func,
	selectedWord: PropTypes.string,

};
