import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import useUtterance from '@reading/r180/src/utils/useUtterance';
import {Modal} from '@material-ui/core';
import ActivityButton from '../ActivityButton';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import SoundButton from '../SoundButton';

export const useStyles = makeStyles(theme => ({
	contentWrapper: {
		zIndex: '99999',
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
		position: 'absolute',
		backgroundColor: theme.colors.white,
		height: '360px',
		width: '520px',
		padding: '0'
	},
	content: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
		'& h2': {
			fontSize: '32px',
			color: theme.colors.black,
			fontWeight: '700',
			zIndex: '10',
			margin: '0',
			'& .sound-button': {
				marginRight: '16px',
				position: 'relative',
				top: '-4px'
			}
		},
		'& h4': {
			margin: '0',
			marginBottom: '9px'
		},
		'& .mic-button': {
			height: '64px',
			width: '64px',
			borderRadius: '32px',
			fontSize: '32px',
			color: theme.colors.readingZone.background,
			background: theme.colors.white,
			textAlign: 'center',
			border: `2px solid ${theme.colors.readingZone.blue5}`,
			boxShadow: `0px 0px 0px 2px ${theme.colors.readingZone.blue5}`,
			transition: 'box-shadow 0.3s linear',
			'&.recording': {
				border: `4px solid ${theme.colors.readingZone.background}`
			},
			'&.disabled': {
				color: theme.colors.grey,
				background: theme.colors.lightGrey,
				cursor: 'not-allowed',
				border: `2px solid ${theme.colors.paleGrey}`
			}
		},
		'& .activity-button': {
			zIndex: '10'
		}
	}
}));

const MicCheck = props => {
	const {id, className, onMicCheckFinished} = props;
	const classes = useStyles();

	const {volumeLevel, status, record} = useUtterance({
		onMicReject: () => {
			// TODO
		}
	});
	const { setState } = useBatchedSetState();

	const [isOpen, setOpen] = useState(true);
	const [isGoOnDisabled, setGoOnDisabled] = useState(true);
	const [isSoundButtonDisabled, setSoundButtonDisabled] = useState(false);
	const [test, setTest] = useState(true);
	const [isRecording, setRecording] = useState(false);

	useEffect(() => {
		if (status === 'sound') {
			setState(() => {
				setRecording(false);
				setSoundButtonDisabled(true);
				setGoOnDisabled(false);
			});
		}
		else if (status === 'silence') {
			setState(() => {
				setRecording(false);
				setTest(false);
				setSoundButtonDisabled(true);
				setTimeout(() => {
					setTest(true);
					setSoundButtonDisabled(false);
				}, 5000);
			});
		}
	}, [status, setState]);

	const handleClose = () => {
		setOpen(false);
		onMicCheckFinished('success');
	};

	const handleMicClick = () => {
		setTimeout(() => {
			setRecording(true);
			record();
		}, 500);
	};

	return (
		<>
			<Modal
				disableBackdropClick
				disableEscapeKeyDown
				id={id}
				open={isOpen}
				onClose={handleClose}
				className={`${className} r180-modal`}
			>
				<div
					className={`${className} ${classes.contentWrapper} modal-window`}
				>
					<div className={classes.content}>
						{test ? (
							<h2>
								<SoundButton
									border={false}
									icon="speaker-center"
									size={32}
									sound={{
										name: 'TODO',
										src: 'TODO'
									}}
								/>
								Test your mic
							</h2>
						) : (
							<h4>
								<SoundButton
									border={false}
									icon="speaker-center"
									size={32}
									sound={{
										name: 'TODO',
										src: 'TODO'
									}}
								/>
								We didn't catch that. Speak louder into the mic.
							</h4>
						)}

						<button
							className={`mic-button ${
								isSoundButtonDisabled ? 'disabled' : ''
							} ${isRecording ? 'recording' : ''}`}
							onClick={handleMicClick}
							style={{
								boxShadow: isSoundButtonDisabled
									? 'none'
									: `0px 0px 0px ${Math.max(
											2,
											(volumeLevel - 1) * 20
									  )}px rgb(204, 233, 250)`
							}}
						>
							<i className="r180 mic" />
						</button>

						<ActivityButton
							icon="go-on"
							disabled={isGoOnDisabled}
							iconPosition="right"
							isPrimary
							onClick={handleClose}
							text="Go On"
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};

MicCheck.defaultProps = {
	id: '',
	className: ''
};
MicCheck.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	onMicCheckFinished: PropTypes.func
};

export default React.memo(MicCheck);
