import React, { useState, useEffect, useRef } from 'react';
import media from '@reading/r180/src/api/media';
import { sleep } from '@reading/r180/src/utils/sleep';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => {
	return {
		captionPlayer: {
			textAlign: 'center',
			padding: '8px',
			background: theme.colors.transBlack,
			color: theme.colors.white,
			borderRadius:'8px',
		},
		captionContainer: {
			position: 'fixed',
			width: '100%',
			top: '64px',
			left: '0px',
			zIndex: '100',
			display:'flex',
			justifyContent:'center',
		}
	};
});

const ClosedCaption = () => {
	const classes = useStyles();
	const displayedVtt = useRef('');
	const [activeCue, setActiveCue] = useState(false);

	const {vttSrc, showCaptions} = useSelector(state => {
		return {
			vttSrc: state.ui.captionSrc,
			showCaptions: state.session.session.settings.captioningEnabled
		}
	});

	const parseVtt = vttData => {
		const lines = vttData.split('\n');
		let cues = [];
		let currentCue = null;
		for (let i = 0; i < lines.length; i++) {
			const line = lines[i];
			if (line === 'WEBVTT') {
				continue;
			} //Skip header line
			if (line.match(/^[0-9]+$/)) {
				continue;
			} //Skip cue numbers
			if (line.substr(0, 4) === 'NOTE') {
				continue;
			} //Skip comments
			if (line.length === 0) {
				//Blank line, push currentCue if it exists
				if (currentCue) {
					cues.push(currentCue);
				}
				continue;
			}
			const matches = line.match(
				/([0-9]{2})\:([0-9]{2})\:([0-9]{2})\.([0-9]+) \-\-\> ([0-9]{2})\:([0-9]{2})\:([0-9]{2})\.([0-9]+)/
			);
			if (matches && matches.length === 9) {
				//Make a new cue if it's a cue line
				const [
					fullMatch,
					startH,
					startM,
					startS,
					startMS,
					endH,
					endM,
					endS,
					endMS
				] = matches;
				currentCue = {
					start:
						startH * 3600000 +
						startM * 60000 +
						startS * 1000 +
						startMS * 1,
					end:
						endH * 3600000 + endM * 60000 + endS * 1000 + endMS * 1,
					text: ''
				};
				continue;
			}
			//If we've made it this far, it's not a cue id, a cue time, a blank line or a header, so it must be text
			if (currentCue) {
				currentCue.text += line;
			}
		}
		if (currentCue) {
			cues.push(currentCue);
		}
		return cues;
	};

	const displayCaptions = (cues, vttSrc) => {
		cues.forEach(async(cue) => {
			await sleep(Math.min(cue.start, cue.end - 500));
			if (displayedVtt.current === vttSrc) {
				setActiveCue(cue);
			}
			await sleep(Math.max(cue.end, cue.start + 500));
			if (displayedVtt.current === vttSrc) {
				setActiveCue(false);
			}
		});
	};

	useEffect(() => {
		const getVttData = async () => {
			displayedVtt.current = vttSrc;
			setActiveCue(false);
			try {
				const result = await media.getOne(vttSrc);
				const cues = parseVtt(result);
				displayCaptions(cues, vttSrc);
			} catch (e) {
				// audio files witout VTT will throw a 404, so don't log those errors to the console
				if (e.response && e.response.status !== 404) {
					console.error(e);
				}
			}
		};
		if (isEmpty(vttSrc) === false && showCaptions) {
			getVttData();
		}
	}, [vttSrc]);

	if (!vttSrc) {
		return null;
	}

	//If we have no data, don't render
	if (!activeCue) {
		return null;
	}

	//if there is no active cue, don't render
	if (!activeCue.text.trim().length) {
		return null;
	}

	//If the caption is blank, don't render
	if (!showCaptions){
		return null;
	}

	return (
		<div className={classes.captionContainer}>
			<div className={classes.captionPlayer} dangerouslySetInnerHTML={{ __html: activeCue.text }}></div>
		</div>
	);
};

export default ClosedCaption;
