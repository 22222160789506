import { makeStyles } from '@material-ui/styles'

export const useStyle = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},

	soundButton: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		cursor: 'pointer',
		borderRadius: '50%',
		marginTop: 8,
		width: 16,
		height: 16,
		top: '-2.5px',
		color: theme.colors.slate,
		transition: 'transform 0.3s ease',
		'&.disabled': {
			color: theme.colors.paleSlate,
			cursor: 'not-allowed'
		},
		'&:hover:not(.disabled)': {
			transform: 'scale(1.3)'
		},
	},

	label: {
		display: 'block',
		fontFamily: theme.fonts.context,
		fontSize: '18px',
		letterSpacing: '0.15px',
		color: theme.colors.black,
		fontStyle: 'normal',
		margin: '0 8px',
		textAlign: 'start',
		userSelect: 'none',
		'&.emphasis': {
			color: `${theme.colors.softBlack}`,
			fontStyle: 'italic'
		}
	}
}));
