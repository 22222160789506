import React, { useRef, useState } from 'react';
import { ActivityInstructionButton, WordMatch as WordMatchComponent, StartActivity } from '@reading/common';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import ActivityFrame from '../../containers/App/ActivityFrame';
import { WordMatch } from './WordMatch';
import { isEmpty } from 'lodash';
import { uiSlice } from '../../store/slices/ui';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import useBatchedSetState from '../../utils/useBatchedSetState';
import { sleep } from '../../utils/sleep';

export default function WordMatchActivity(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { setState } = useBatchedSetState();
	const helpSound = useRef(WordMatch.TRIAL_HELP);

	const [status, setStatus] = useState(FooterForwardBack.INITIAL);
	const [isActivitySubmitted, setActivitySubmitted] = useState(false);
	const [wordsMap, setWordsMap] = useState([]);
	const [attempts, setAttempts] = useState(0);
	const [wordsResultMap, setWordsResultMap] = useState({});
	const [isActivitySubmittedOnce, setActivitySubmittedOnce] = useState(false);
	const [isAutoCorrect, setAutoCorrect] = useState(false);
	const [resetStatus, setResetStatus] = useState('Initial');
	const [showIntroPage, setShowIntroPage] = React.useState(true);

	const { activityData, studylist } = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			studylist: state.activity.studylist
		};
	});

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	// check if the activity is already charged
	const { isActivityCharged, settings } = useSelector(state => {
		return {
			isActivityCharged: state.ui.chargedActivities['word-match'] === true,
			settings: state.session.session.settings
		}
	});

	const isActivity = activityData ? activityData.activityServerId === 'word_match' : false;

	useDeepCompareEffect(() => {
		const initialize = async () => {

			if (isActivityCharged === false) {
				dispatch(uiSlice.actions.setCharging());
			}

			const words = await WordMatch.initData(studylist, isActivityCharged);
			setWordsMap(words);
			dispatch(uiSlice.actions.setActivityCharged('word-match'));
			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				AudioPlayer2.play(WordMatch.INSTRUCTIONS);
			}
		};

		if (isEmpty(studylist) === false && isActivity) {
			initialize();
		}
	}, [studylist]);

	const handleSubmit = async () => {
		AudioPlayer2.stopAll();
		setActivitySubmittedOnce(true);

		let isWordMissed = false;
		for (let key in wordsResultMap) {
			if (wordsResultMap[key] === false) {
				isWordMissed = true;
			}
		}

		if (status === FooterForwardBack.VALID) {
			// we're done with the activity, go to the next one
			try {
				await WordMatch.completeActivity(history, activityData);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
			return;
		} else if (status === FooterForwardBack.INVALID) {
			setState(() => {
				setActivitySubmitted(false);
				setResetStatus(true);
				setStatus(FooterForwardBack.INITIAL);
			});
			return;
		} else if (status === FooterForwardBack.SUBMIT) {
			setState(() => {
				setActivitySubmitted(true);
				setResetStatus(false);
				setAttempts(prevState => prevState + 1);
			});
			WordMatch.sendProgressToServer(activityData);
		}
		if (isWordMissed) {
			if (attempts === WordMatch.MAX_ATTEMPS - 1) {
				helpSound.current = WordMatch.FEEDBACK_HELP;
				AudioPlayer2.play(WordMatch.CORRECT_FEEDBACK);
				setStatus(FooterForwardBack.VALID);
				WordMatch.sendProgressToServer(activityData);
				return;
			}
			helpSound.current = WordMatch.INTERROUND_HELP;
			AudioPlayer2.play(WordMatch.INCORRECT_FEEDBACK);
			setStatus(FooterForwardBack.INVALID);
		} else {
			helpSound.current = WordMatch.FEEDBACK_HELP;
			if (attempts > 1) {
				AudioPlayer2.play(WordMatch.CORRECT_FEEDBACK);
			}
			else {
				AudioPlayer2.play(WordMatch.GO_ON_FEEDBACK);
			}
			setStatus(FooterForwardBack.VALID);
			WordMatch.sendProgressToServer(activityData);
		}
	};

	const enableSubmit = (submitStatus, wordValues) => {
		setWordsResultMap({ ...wordsResultMap, ...wordValues });

		if (submitStatus === 'Submit') {
			setStatus(FooterForwardBack.SUBMIT);
		} else if (submitStatus === 'Initial') {
			setState(() => {
				setStatus(FooterForwardBack.INITIAL);
				setResetStatus('Initial');
				setActivitySubmitted(false);
			});
		} else if (submitStatus === 'Correct') {
			setStatus(FooterForwardBack.VALID);
		}
	};

	const handleStartActivity = () => {
		AudioPlayer2.stopAll();
		setShowIntroPage(false);
	};

	return (
		<>
			<Navbar helpSoundUrl={helpSound.current} />

			<ActivitySuspense showSpinner={isActivityCharged === false} requiredRenderData={[wordsMap]} title="Word Match">

				<ActivityFrame isWhiteBackground={true}>
					<>
						<ActivityInstructionButton audioSrc={WordMatch.INSTRUCTIONS} />
						{showIntroPage ?
							<StartActivity
								id={'StartActivityPage'}
								onStartActivity={handleStartActivity}
								startActivityAudioSrc={WordMatch.SOUND_INTRO}
							/> :
							<WordMatchComponent
								id="wordMatch"
								words={wordsMap}
								submitFlag={isActivitySubmitted}
								enableSubmit={enableSubmit}
								activitySubmittedOnce={isActivitySubmittedOnce}
								reset={resetStatus}
								autoCorrect={isAutoCorrect}
							/>
						}
					</>
				</ActivityFrame>

				<Footer>
					<FooterForwardBack status={status} isBackVisible={false} onForward={handleSubmit} />
				</Footer>
			</ActivitySuspense>
		</>
	);
}
