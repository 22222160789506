import {activityConstants} from './mediaConstants';

export const createAssetId = (stage, segment, level, useExtendedAsset) => {
	let assetId = `${stage}${segment.toString().padStart(2, '0')}`;
	if (useExtendedAsset) {
		assetId += `${level.toString().padStart(2, '0')}`;
	}
	return assetId;
};

export const getPath = (path, activityAssetCode) => {
	return path.replace(/{activity_asset_code}/g, activityAssetCode);
};

export const getFilename = (fileName, activityAssetCode, assetId) => {
	let newFileName = fileName.replace(/{activity_asset_code}/g, activityAssetCode);
	return newFileName.replace(/{asset_id}/g, assetId);
};

export const getContentInfo = (bundledActivityData, useExtendedAsset = true) => {
	// start to gather the activity information from the bundledActivityData
	const activityServerId = bundledActivityData.activityServerId;
	const currentActivityConstants = activityConstants[activityServerId];

	// get the activityAssetCode, e.g. 'kfr'
	const activityAssetCode = currentActivityConstants.assetCode;

	let urlPath = null;
	let assetId = null;

	const path = getPath(currentActivityConstants.location, activityAssetCode);

	// get the assetId info
	if (bundledActivityData.segment && bundledActivityData.stage) {
		const stage = bundledActivityData.stage;
		const segment = bundledActivityData.segment;
		const level = bundledActivityData.level;

		assetId = createAssetId(stage, segment, level, useExtendedAsset);

		// get the contentData
		const fileName = getFilename(currentActivityConstants.naming_rule, activityAssetCode, assetId);

		// calculate the URL
		urlPath = path + '/' + fileName;
	} else {
		//No segment, must be segment selection screen
		urlPath = `${path}/r180u_segments_${bundledActivityData.stage}.json`;
		assetId = '';
	}

	return {activityAssetCode, assetId, urlPath};
};
