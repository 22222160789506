import React from 'react';
import reduxStore from '../../store/redux-setup';
import { seedInitialDataAction } from '../../store/slices/activity';
import useRouteInfo from '../../utils/useRouteInfo';
import { useLocation } from 'react-use';
import { makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import StartSession from '../../pages/StartSession/StartSession';
import { isDemoMode } from '../../utils/ui';
import { IS_PRODUCTION_ENV } from '../../utils/constants.js';
import DemoMode from './DemoMode.js';
import ClosedCaption from './ClosedCaption.js';

let zoomToFixDPI = 1;
if (window.devicePixelRatio < 2 && window.devicePixelRatio > 1 && window.innerHeight <= 720) {
	zoomToFixDPI = 1 / window.devicePixelRatio;
}
const useStyles = makeStyles(theme => ({
	appContainer: {
		minHeight: `${100 / zoomToFixDPI}vh`
	}
}));

const AppContainer = (props) => {
	const classes = useStyles();
	const { container, zone } = useRouteInfo(useLocation());
	let containerName = `${container}-container`;
	if (container === 'zone') {
		containerName = `${zone}-container`;
	}

	const { session, userContext } = reduxStore.getState();

	let isSessionEmpty = false;

	//////////////
	// DEMO MODE
	//////////////
	if (process.env.REACT_APP_PRELOAD_MOCK_ACTIVITY_DATA === 'true' || isDemoMode()) {
		console.log('MOCK DATA MODE');
		reduxStore.dispatch(seedInitialDataAction());
	}
	else {
		isSessionEmpty =
			isEmpty(session) ||
			isEmpty(session.session) ||
			isEmpty(session.session.user) ||
			isEmpty(session.session.user.r180uSessionId);

		// if their session is empty, go get the session from the server
		if (isSessionEmpty) {
			StartSession(userContext.correlationId);
		}

		// if the user presses the back button, we have to go back to the Zone Menu
		// because their activity state will be off
		window.addEventListener('popstate', function () {
			// KFR is the only place where the "Back" button might be used validly
			if (window.location.pathname.indexOf('knowledge_for_reading') === -1) {
				window.location.reload();
			}
		});
	}

	if (isSessionEmpty) {
		return null; // TODO Make some "redirecting" or loading screen here
	} else {
		return (
			<div className={`${classes.appContainer} ${containerName}`}>
				{IS_PRODUCTION_ENV === false && <DemoMode />}
				<ClosedCaption />
				{props.children}
			</div>
		);
	}
}

export default React.memo(AppContainer);
