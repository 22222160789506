const isValidUserContext = context => {
	if (!context) {
		throw new Error('No UserContext was passed');
	}

	if (!Reflect.has(context, 'sifToken')) {
		throw new Error('UserContext has no sifToken');
	}

	if (!Reflect.has(context, 'activityId')) {
		throw new Error('UserContext has no activityId');
	}

	if (!Reflect.has(context, 'correlationId')) {
		throw new Error('UserContext has no correlationId');
	}

	if (typeof context.correlationId !== 'string') {
		throw new Error('UserContext.correlationId is not a string');
	}
	// commenting below code - causing failure on PROD (activityId: undefined)
	// if (typeof context.activityId !== 'string') {
	// 	throw new Error('UserContext.activityId is not a string');
	// }

	const sifToken = context.sifToken;

	if (typeof sifToken !== 'string') {
		throw new Error('UserContext.sifToken is not a string');
	}

	if (sifToken.slice(0, 15) !== 'SIF_HMACSHA256 ') {
		throw new Error('UserContext.sifToken format invalid');
	}

	return true;
};

export default isValidUserContext;
