import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	blackRail:{
		background:theme.colors.black,
		height:'3px',
	},
	slider:{
		width:'200px',
		textAlign:'center',
		color:theme.colors.white,
	},
}))
