import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
	spellingFeedbackItem: {
		position: 'relative',
		// width: '100%',
		display: 'inline-block',
		'& .correct-incorrect': {
			position: 'relative',
			marginRight: '11px'
		},
		'&.interactive': {
			'& .spell-entry': {
				// marginLeft: '35px'
			}
		},
		'&.incorrect': {
			position: 'relative',
			left: '90px'
		}
	},
	correctSpelling: {
		position: 'relative',
		display: 'block',
		width: '496px',
		textAlign: 'left',

		'& .correct-spelling': {
			position: 'relative',
			top: '-5px',
			fontSize: '32px',
			fontWeight: 'normal',
			fontFamily: theme.fonts.context,
			'&.correct': {
				color: theme.colors.successGreen,
			},
			'&.incorrect': {
				color: theme.colors.errorRed
			},
		},
		'& .wrapper': {
			display: 'flex',
			justifyContent: 'center',
			alignContent: 'center',
			alignItems: 'center',
			'& div': {
				//left: '10px !important'
			}
		},
		'& .divider': {
			width: '480px',
			margin: '8px',
			'& .r180': {
				color: theme.colors.slate
			}
		},
		'& .line': {
			width: '200px',
			height: '0px',
			border: `1px solid ${theme.colors.paleGrey}`,
			transform: 'matrix(1, 0, 0, -1, 0, 0)',
			display: 'inline-flex',
			marginLeft: '15px',
			marginRight: '15px',
			marginBottom: '8px'
		}
	},
	spacer: {
		position: 'relative',
		display: 'inline-block',
		width: '180px',
	},
	wrapper: {
		position: 'relative',
		display: 'inline-block',
		width: '60%',
		background: theme.colors.lightGrey,
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '4px',
		padding: '0px 18px 0px 50px',
		textAlign: 'left',
		transition: 'all 0.3s',
		'& .number': {
			fontFamily: theme.fonts.uiBold,
			position: 'absolute',
			left: '20px',
			top: '12px',
			fontSize: '18px',
			color: theme.colors.paleSlate,
			transition: 'all 0.3s',
		},
		'& input': {
			width: '85%',
			textAlign: 'left',
			fontSize: '18px',
			padding: '12px 8px 12px 16px',
			transition: 'all 0.3s',
			color: theme.colors.grey,
			background: theme.colors.lightGrey,
			border: 'none',
			height: '60px',
			outline: 'none !important'
		},
		'&.hasFocus': {
			width: '496px',
			height: '64px',
			background: theme.colors.white,
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			border: 'none',
			borderBottom: `4px solid ${theme.colors.readingZone.blue2}`,
			marginBottom: '0',
			marginTop: '30px',
			paddingLeft: '60px',
			'& .number': {
				top: '12px',
				fontSize: '32px',
				color: theme.colors.black,
			},
			'& input': {
				background: theme.colors.white,
				fontSize: '32px',
				color: theme.colors.black,

			}
		},
		'&.correct': {
			color: theme.colors.grey,
		},
		'&.incorrect': {
			'& input': {
				color: theme.colors.errorRed
			}

		}
	},
}));
