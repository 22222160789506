import React, {useEffect, useState, useRef} from 'react';
import {useUnmount} from 'react-use';
import PropTypes from 'prop-types';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';
import ReadingQuestionPanel from '../ReadingQuestionPanel/ReadingQuestionPanel';
import {bulkReplace, stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import {createAssetId} from '@reading/r180/src/media/mediaUtil';
import {isEmpty, cloneDeep} from 'lodash';

const EXT = getAudioExt();

const BUTTON_NAMES = {
	SUBMIT: 'Submit',
	GOON: 'Go On',
	TRYAGAIN: 'Try Again'
};
const BUTTON_ICONS = {
	CHECK: 'check',
	ARROW_RIGHT: 'arrow-right',
	RESET: 'reset'
};
const MAX_ATTEMPTS = 3;
export const MEDIA = {
	TITLE_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_sorting_q{dataId}_question.${EXT}`,
	OPTION_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_sorting_q{dataId}_{optionId}.${EXT}`
};

const Sorting = props => {
	const {data, stage, segment, level, onGoOnClicked, onShowFeedback} = props;
	const [bucketHeaderList, setBucketHeaderList] = useState([]);
	const [bucketHeaders, setBucketHeaders] = useState([]);
	const [sortingOptions, setSortingOptions] = useState({
		headerList: [],
		bucketItemsList: []
	});
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
	const [attempts, setAttempts] = useState(0);
	const [isCorrect, setCorrect] = useState(true);
	const [interactive, setInteractive] = useState(true);
	const [buttonName, setButtonName] = useState(BUTTON_NAMES.SUBMIT);
	const [buttonIcon, setButtonIcon] = useState(BUTTON_ICONS.CHECK);
	let timeout = useRef(false);

	// create the options, map the object to our components
	useEffect(() => {
		if (isEmpty(data) === false) {
			const bucketItems = cloneDeep(data.buckets)
				.map(option => {
					return option.items.map(item => {
						return {
							id: item.id,
							text: item.text,
							audio: {
								name: `Sorting_${item.id}`,
								src: bulkReplace(MEDIA.OPTION_SOUND, {
									asset_id: createAssetId(
										stage,
										segment,
										level,
										true
									),
									dataId: data.id,
									optionId: item.id
								})
							},
							status: '',
							bucketName: option.text
						};
					});
				})
				.reduce((a, b) => a.concat(b), []);
			const headerList = [];
			data.buckets.forEach((option, index) => {
				headerList.push({
					type: 'destination',
					id: option.id,
					text: option.text,
					menuText: option.text,
					status: ''
				});
				if (index === data.buckets.length - 1) {
					headerList.push({
						type: 'source',
						menuText: 'Source',
						status: ''
					});
				}
			});
			const bucketNames = data.buckets.map(option => option.text);
			setSortingOptions({
				headerList: headerList,
				bucketItemsList: [[], [], bucketItems]
			});
			setBucketHeaderList(bucketNames);
			setBucketHeaders(headerList);
		} else {
			setSortingOptions({
				headerList: [],
				bucketItemsList: []
			});
			setBucketHeaderList([]);
			setBucketHeaders([]);
		}
	}, [data]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		clearTimeout(timeout.current);
	});

	const handleAnswerChange = selectedOptions => {
		const newOptions = selectedOptions.map(option => {
			return option.map(item => {
				return {...item, status: ''};
			});
		});
		setIsSubmitEnabled(selectedOptions[2].length === 0);
		setSortingOptions({
			headerList: bucketHeaders,
			bucketItemsList: newOptions
		});
	};
	const handleSelect = () => {
		if (!isSubmitEnabled) {
			return;
		}
		AudioPlayer2.stopAll();
		switch (buttonName) {
			case BUTTON_NAMES.SUBMIT: {
				const newOptions = sortingOptions.bucketItemsList.map(
					(option, index) => {
						return option.map(item => {
							if (item.bucketName === bucketHeaderList[index]) {
								return {...item, status: 'correct'};
							} else {
								return {...item, status: 'incorrect'};
							}
						});
					}
				);
				const simplifiedNewOptions = newOptions.reduce(
					(a, b) => a.concat(b),
					[]
				);
				const correctOptionsLength = simplifiedNewOptions.filter(
					option => option.status === 'correct'
				).length;

				// All answers are correct
				if (correctOptionsLength === simplifiedNewOptions.length) {
					AudioPlayer2.play(`compcorrattempt${attempts + 1}`);
					onShowFeedback(false, []);
					setIsSubmitEnabled(true);
					setButtonName(BUTTON_NAMES.GOON);
					setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
					setAttempts(attempts => attempts + 1);
					setCorrect(true);
					setInteractive(false);
					setSortingOptions({
						headerList: bucketHeaders,
						bucketItemsList: newOptions
					});
				} else {
					AudioPlayer2.play(`compincorrattempt${attempts + 1}`);
					if (attempts === MAX_ATTEMPTS - 1) {
						timeout.current = setTimeout(() => {
							onShowFeedback(false, []);
						}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 2000);
						setSortingOptions({
							headerList: bucketHeaders,
							bucketItemsList: newOptions
						});
						setInteractive(false);
						setIsSubmitEnabled(true);
						setButtonName(BUTTON_NAMES.GOON);
						setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
					} else {
						timeout.current = setTimeout(() => {
							onShowFeedback(true, data.corrective_feedback);
							setInteractive(true);
							setButtonName(BUTTON_NAMES.SUBMIT);
							setButtonIcon(BUTTON_ICONS.CHECK);
							setIsSubmitEnabled(false);
						}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 1000);
						setInteractive(false);
						setButtonName(BUTTON_NAMES.TRYAGAIN);
						setButtonIcon(BUTTON_ICONS.RESET);
						setIsSubmitEnabled(true);
						setSortingOptions({
							headerList: bucketHeaders,
							bucketItemsList: newOptions
						});
					}
					setCorrect(false);
					setAttempts(attempts => attempts + 1);
				}
				break;
			}
			case BUTTON_NAMES.TRYAGAIN: {
				const correctOptions = sortingOptions.bucketItemsList.map(
					option => {
						return option.filter(item => item.status === 'correct');
					}
				);
				const inCorrectOptions = sortingOptions.bucketItemsList
					.map(option => {
						return option
							.filter(item => item.status !== 'correct')
							.map(item => ({...item, status: ''}));
					})
					.reduce((a, b) => a.concat(b), []);
				correctOptions.splice(
					correctOptions.length - 1,
					1,
					inCorrectOptions
				);
				setInteractive(true);
				setSortingOptions({
					headerList: bucketHeaders,
					bucketItemsList: correctOptions
				});
				setButtonName(BUTTON_NAMES.SUBMIT);
				setButtonIcon(BUTTON_ICONS.CHECK);
				setIsSubmitEnabled(false);
				break;
			}
			default: {
				onShowFeedback(false, []);
				onGoOnClicked(attempts, isCorrect);
				break;
			}
		}
	};

	return (
		<ReadingQuestionPanel
			title={stripHtmlTags(data.stem)}
			titleSound={{
				name: `Title_${data.id}`,
				src: bulkReplace(MEDIA.TITLE_SOUND, {
					asset_id: createAssetId(stage, segment, level, true),
					dataId: data.id
				})
			}}
			questionType={data.type}
			question={sortingOptions}
			onAnswerChange={handleAnswerChange}
			onSubmit={handleSelect}
			isSubmitEnabled={isSubmitEnabled}
			buttonText={buttonName}
			buttonIcon={buttonIcon}
			showResetButton={false}
			interactive={interactive}
		/>
	);
};

Sorting.propTypes = {
	data: PropTypes.object.isRequired,
	stage: PropTypes.string.isRequired,
	segment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onGoOnClicked: PropTypes.func.isRequired,
	onShowFeedback: PropTypes.func.isRequired
};

export default React.memo(Sorting);
