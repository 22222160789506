import React from 'react';
import useStyles from './WordBuilderTile.style';

const WordBuilderTile = props => {
	const {
		text,
		onMouseDown,
		onMouseUp,
		id,
		className,
		disabled = false
	} = props;
	const classes = useStyles();
	return (
		<div
			className={`${classes.wrapper} ${className} ${disabled &&
				classes.disabled} wordBuilderTile ${disabled && 'wbtDisabled'}`}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			id={id}
			disabled={disabled}
		>
			{text}
		</div>
	);
};

export default React.memo(WordBuilderTile);
