import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => {
	const colors = {
		explore_zone:theme.colors.exploreZone,
		reading_zone:theme.colors.readingZone,
		writing_zone:theme.colors.writingZone,
		language_zone:theme.colors.languageZone,
		fluency_zone:theme.colors.fluencyZone,
		success_zone:theme.colors.successZone,

	};
	return {
		zoneButton: ({zoneCode='explore_zone', available=true}) => ({
			position: 'relative',
			cursor: (available?'pointer':'auto'),
			'&:hover': available?{
				color: theme.colors.white,
				borderBottom: `solid ${theme.colors.white} 2px`,
				marginBottom: '16px',
			}:{},
			margin: '16px',
			marginBottom: '18px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			alignContent: 'center',
			justifyContent: 'center',
			justifyItems: 'center',
		}),
		zoneLabel: {
			width: '100%',
			textAlign: 'center',
			color: theme.colors.white,
		},
		zoneIcon: ({zoneCode='explore_zone', available=true}) => {
			if(typeof zoneCode !='string'){zoneCode='explore_zone';}
			return {
				width: '104px',
				height:'104px',
				textAlign: 'center',
				fontFamily: 'r180',
				fontSize: '45px',
				position: 'absolute',
				top: '-6px',
				left:'-3px',
				borderRadius: '50%',
				color: (available?theme.colors.white:theme.colors.transWhite),
				background: zoneCode&&colors[zoneCode].primary,
				display:'flex',
				justifyContent:'center',
				alignItems:'center',


		}},
		zoneProgressDot: ({zoneCode='explore_zone'}) => ({
			position:'absolute',
			width:'12px',
			height:'12px',
			borderRadius:'6px',
			border: `solid 1px ${theme.colors.white}`,
			zIndex:'2',
			fontSize:'7px',
			left:'42px',
			top:'42px',
			background: zoneCode&&colors[zoneCode].primary,
			color:theme.colors.white,
			textAlign:'center',
			fontFamily: 'r180',

		}),
		progressBgArc: {
			width:'96px',
			height:'96px',
			position:'absolute',
			borderRadius:'48px',
			border:`solid 2px ${theme.colors.transWhite}`,
			zIndex:'2',
		},
		progressContainer: {
			width: '104px',
			height: '104px',
			marginLeft: '1px',
			marginTop: '-2px',
			borderRadius: '50%'
		},
		progressArc: {
			color: theme.colors.white,
			position: 'relative',
			zIndex: '1'
		},
		progressExplore: {
			background: theme.colors.exploreZone.primary
		},
		progressReading: {
			background: theme.colors.readingZone.primary
		},
		progressWriting: {
			background: theme.colors.writingZone.primary
		},
		progressLanguage: {
			background: theme.colors.languageZone.primary
		},
		progressFluency: {
			background: theme.colors.fluencyZone.primary
		},
		progressSuccess: {
			background: theme.colors.successZone.primary
		},
		iconDisabled: {
			color: theme.colors.palegrey,
			cursor: 'auto'
		}
	};
});
