// import React from 'react';
import PropTypes from 'prop-types';
import WordTitle from '../../components/WordTitle/WordTitle';
import ActivityDirections from '../../components/ActivityDirections';
import React, { useState, useEffect } from 'react';
import { HoverTip, TipModal } from '@reading/common';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { displaySafe } from '@reading/r180/src/utils/stringUtils';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

const useStyles = makeStyles(theme => ({
	tipButton: {
		position: 'relative',
		top: '2px',
		fontSize: '24px',
		marginRight: '18px',
		'& i': {
			color: theme.colors.paleSlate,
		},
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.2)'
		}
	},
	header: {
		fontSize: '18px',
		'& i': {
			display: 'none'
		}
	},
	subHeader: {
		fontSize: '24px',
		'& i': {
			position: 'relative',
			color: theme.colors.slate,
			fontSize: '18px',
			marginRight: '12px',
			cursor: 'pointer'
		},
		'& span': {
			position: 'relative',
			top: '0.5px',
			color: theme.colors.black,
			fontSize: '24px',
			lineHeight: '34px',
			marginRight: '12px',
			cursor: 'pointer'
		}
	},
	outerContainer: {
		textAlign: 'center'
	},
	innerContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	subHeader_Highlight: {
		fontSize: '24px',
		'& i': {
			position: 'relative',
			color: theme.colors.paleSlate,
			fontSize: '18px',
			marginRight: '12px',
			cursor: 'pointer'
		},
		'& span': {
			position: 'relative',
			top: '0.5px',
			color: theme.colors.black,
			backgroundColor: theme.colors.highlightYellow,
			textDecoration: 'underline',
			fontSize: '24px',
			lineHeight: '34px',
			marginRight: '12px',
			cursor: 'pointer'
		}
	}
}));



export const timeoutFunc = ({ wordTimeout, item, synonymsAndAntonymsWordList, handleVOEnd, lastWordTimeout, applyHighlightText, defaultInterval, interval, ind }) => {
	wordTimeout = setTimeout(function () {
		AudioPlayer2.play(item.word);
		applyHighlightText(item.word);
		if (synonymsAndAntonymsWordList.length - 1 === ind) {
			if (typeof handleVOEnd === 'function') {
				handleVOEnd();
				lastWordTimeout = setTimeout(() => {
					applyHighlightText('');
				}, defaultInterval);
			}
		}
	}, interval);

	return () => {
		clearTimeout(wordTimeout);
		clearTimeout(lastWordTimeout);
	};
}




export const handleTimeout = (synonymsAndAntonymsWordList, defaultInterval, applyHighlightText, handleVOEnd, headerTimeout, interval) => {

	let wordTimeout = null;
	let lastWordTimeout = null;

	synonymsAndAntonymsWordList &&
		synonymsAndAntonymsWordList.forEach((item, ind) => {
			interval = (ind + 1) * defaultInterval;
			const obj = {
				wordTimeout,
				item,
				synonymsAndAntonymsWordList,
				handleVOEnd,
				lastWordTimeout,
				applyHighlightText,
				defaultInterval,
				interval,
				ind
			};
			timeoutFunc(obj);

		});
	return () => {
		clearTimeout(headerTimeout);
	};
}

const SynonymsAndAntonymsIntro = (props) => {
	const {
		word,
		wordUrl,
		instructions,
		instructionsUrl,
		handleVOEnd,
		synonymsAndAntonymsWordList,
		isAutoplay,
		introSound
	} = props;

	const classes = useStyles();
	const { setState } = useBatchedSetState();

	const [isOpenTipModal, setOpenTipModal] = useState(false);
	const [selectedWord, setSelectedWord] = useState();

	const handleTipClick = async selectedWord => {
		AudioPlayer2.stopAll();
		setState(() => {
			setSelectedWord(selectedWord);
			setOpenTipModal(true);
		});
	};

	const handleTipModalGoOn = e => {
		AudioPlayer2.stopAll();
		setOpenTipModal(false);
	};

	const handleCancelExit = e => {
		AudioPlayer2.stopAll();
		setOpenTipModal(false);
	};

	useEffect(() => {
		const init = async () => {
			const defaultInterval = 2000;
			let interval = 1;

			if (isAutoplay) {
				await AudioPlayer2.playSync(introSound);
			}

			const headerTimeout = setTimeout(() => {
				AudioPlayer2.play(word);
			}, interval);

			handleTimeout(synonymsAndAntonymsWordList, defaultInterval, applyHighlightText, handleVOEnd, headerTimeout, interval);
		}

		init();
	}, []);

	const applyHighlightText = word => {
		synonymsAndAntonymsWordList.forEach((item, ind) => {
			const el = document.getElementById(item.word);
			if (el) {
				el.classList.remove(classes.subHeader_Highlight);
				if (word === item.word) {
					el.classList.add(classes.subHeader_Highlight);
				}
			}
		});
	};

	return (
		<div className={classes.outerContainer}>
			<ActivityDirections
				className={`mt-3 mb-5 ${classes.header}`}
				text={instructions}
				src={instructionsUrl}
			/>

			<WordTitle className="mb-5" word={word} src={wordUrl} />

			<Divider variant="middle" className="mb-5" />

			{synonymsAndAntonymsWordList &&
				synonymsAndAntonymsWordList.map((sa, idx) => {
					/* istanbul ignore next */
					return (
						<div
							key={idx}
							className={classes.innerContainer}
						>
							{sa['tip'] && (
								<div>
									<HoverTip title="Click the tip icon">
										<button
											className={`${classes.tipButton}`}
											type="button"
											onClick={() => handleTipClick(sa)}
											aria-label="Tip"
										>
											<i className={`r180 tip`} />
										</button>
									</HoverTip>
								</div>
							)}
							<div>
								<ActivityDirections
									key={sa.word}
									id={sa.word}
									className={`mb-5 ${classes.subHeader} `}
									text={sa.word}
									src={sa.wordUrl}
								/>
							</div>
						</div>
					);
				})}

			{selectedWord && (
				<TipModal
					isOpen={isOpenTipModal}
					onConfirm={handleTipModalGoOn}
					onCancel={handleCancelExit}
					showActivityButton={false}
					word={displaySafe(selectedWord.word)}
					wordUrl={selectedWord.wordUrl}
					tip={selectedWord.tip}
					tipUrl={selectedWord.tipUrl}
				/>
			)}
		</div>
	);
};

SynonymsAndAntonymsIntro.defaultProps = {
	word: '',
	wordUrl: '',
	instructions: '',
	instructionsUrl: '',
	handleVOEnd: () => { }
};

SynonymsAndAntonymsIntro.propTypes = {
	word: PropTypes.string,
	wordUrl: PropTypes.string,
	instructions: PropTypes.string,
	instructionsUrl: PropTypes.string,
	handleVOEnd: PropTypes.func,
	synonymsAndAntonymsWordList: PropTypes.arrayOf(
		PropTypes.exact({
			tip: PropTypes.string,
			tipUrl: PropTypes.string,
			word: PropTypes.string,
			wordUrl: PropTypes.string
		})
	)
};

export default React.memo(SynonymsAndAntonymsIntro);
