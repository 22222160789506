import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {cloneDeep} from 'lodash';
import ChoiceResponseQuestionItem from './ChoiceResponseQuestionItem';
import QuestionType from '../../constants/Languages/QuestionType';

const ChoicePanel = props => {
	const {
		questionType,
		questions,
		onAnswerChange,
		interactive,
		preSelectedIndex,
		correctAnswers,
		correctiveFeedback
	} = props;

	const [selectedQuestions, setSelectedQuestions] = useState([]);

	const handleSelect = selectedQuestion => {
		if (interactive === false && preSelectedIndex === -1) {
			return;
		}

		let newSelectedQuestions = cloneDeep(selectedQuestions);

		if (questionType === QuestionType.SINGLE) {
			newSelectedQuestions = [selectedQuestion];
		} else {
			if (selectedQuestion.status === 'selected') {
				newSelectedQuestions = newSelectedQuestions.filter(selQ => {
					return selQ.id !== selectedQuestion.id;
				});
			} else {
				newSelectedQuestions.push(selectedQuestion);
			}
		}

		setSelectedQuestions(newSelectedQuestions);
		onAnswerChange(newSelectedQuestions);
	};

	const isQuestionSelected = question => {
		return (
			selectedQuestions.filter(selectedQ => {
				return selectedQ.id === question.id;
			}).length > 0
		);
	};

	useEffect(() => {
		/* Select the Radio Button if PreselectIndex is passed */
		if (preSelectedIndex > -1 && questionType === QuestionType.SINGLE) {
			handleSelect(questions[preSelectedIndex]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preSelectedIndex]);

	const renderQuestions = question => {
		switch (questionType) {
			case QuestionType.SINGLE:
				return (
					<ChoiceResponseQuestionItem
						question={question}
						onSelect={!interactive ? () => {} : handleSelect}
						isSelected={isQuestionSelected(question)}
						type="single"
						interactive={interactive}
						correctAnswers={correctAnswers}
						correctiveFeedback={correctiveFeedback}
					/>
				);
			case QuestionType.MULTIPLE:
				return (
					<ChoiceResponseQuestionItem
						question={question}
						onSelect={handleSelect}
						isSelected={question.status === 'selected'}
						type="multiple"
					/>
				);
			default:
				return null;
		}
	};

	return (
		<>
			{questions.map((question, idx) => {
				return (
					<div key={idx} className="question-row">
						{renderQuestions(question)}
					</div>
				);
			})}
		</>
	);
};

ChoicePanel.defaultProps = {
	id: '',
	className: '',
	questionType: QuestionType.SINGLE,
	questions: [],
	interactive: true,
	preSelectedIndex: -1
};
ChoicePanel.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	questionType: PropTypes.string,
	questions: PropTypes.array,
	onAnswerChange: PropTypes.func.isRequired,
	interactive: PropTypes.bool,
	preSelectedIndex: PropTypes.number
};

export default ChoicePanel;
