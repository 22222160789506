import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
	iconButton: {
		color: theme.colors.paleGrey,
		'&:hover,&:focus': {
			color: theme.colors.readingZone.primary
		}
	}
});

const CaptionButton = React.forwardRef(
	(
		{ caption, classes, handleCaptions, isCaptionToggleOn, iconButtonStyle },
		ref
	) => {
		const id = isCaptionToggleOn ? 'simple-popper' : null;

		return (
			<IconButton
				ref={ref}
				aria-describedby={id}
				aria-label="Open Captions"
				aria-owns={isCaptionToggleOn ? 'simple-popper' : undefined}
				className={classes.iconButton}
				aria-haspopup="true"
				variant="contained"
				onClick={handleCaptions}
				style={iconButtonStyle}
			>
				{caption === 'off' ? (
					<i
						className="r180 closed-captions"
						style={{ fontSize: '24px' }}
					></i>
				) : (
						<i
							className="r180 closed-captions"
							style={{ fontSize: '24px' }}
						></i>
					)}
			</IconButton>
		);
	}
);

CaptionButton.defaultProps = {
	caption: 'off',
	isCaptionToggleOn: false,
	iconButtonStyle: {}
};
CaptionButton.propTypes = {
	caption: PropTypes.string,
	classes: PropTypes.object.isRequired,
	isCaptionToggleOn: PropTypes.bool,
	handleCaptions: PropTypes.func.isRequired,
	iconButtonStyle: PropTypes.object
};

export default memo(withStyles(styles)(CaptionButton));
