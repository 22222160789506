import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Fragment} from 'react';
import {convertTagsToText, formattedText} from './WritingUtil';
import {AppModal} from '@reading/common';
import { isFirefox } from '@reading/r180/src/utils/browsers';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		width: '100%',
		height: 'auto',
		borderRadius: '8px',
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		overflow: 'hidden',
		background: theme.colors.lightGrey
	},
	titleBar: {
		position: 'relative',
		width: '100%',
		height: '32px',
		padding: '3px 11px',
		background: theme.colors.writingZone.green5,

		'& .title': {
			display: 'inline-block',
			color: theme.colors.writingZone.background,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '18px',
			position: 'relative'
		},
		'& .revise': {
			float: 'right',
			color: theme.colors.writingZone.background,
			fontFamily: theme.fonts.buttonBold,
			fontSize: '16px',
			marginRight: '8px',
			position: 'relative',
			top: '1.5px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	},
	writingArea: {
		width: '100%',
		height: 'auto',
		maxHeight: '128px',
		overflowY: isFirefox() ? 'auto' : 'overlay',

        '&::-webkit-scrollbar': {
            width: '6px'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            padding: '10px 0'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#CCC',
            borderRadius: '8px'
        },
		background: theme.colors.lightGrey,
		fontFamily: theme.fonts.context,
		color: theme.colors.grey,
		fontSize: '18px',
		padding: '16px',
		lineHeight: '32px',
		marginBottom: '24px',
		'&:last-of-type': {
			borderRadius: '0px 0px 8px 8px',
			marginBottom: '0'
		}
	}
}));

const CompletedWritingPanel = props => {
	const {
		id,
		className,
		sections,
		setCompletedPanel,
		completedPanelData,
		orderID,
		deletePanel
	} = props;

	const classes = useStyles();

	const panelName = sections[0].id;

	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [selectedParams, setSelectedParams] = useState({});

	const REMOVE_PANEL = 'Are you sure you want to remove? Select your choice.';

	const handleRevise = (e, sectionId, sectionIndex, text) => {
		const {onRevise} = props;
		onRevise(id, sectionId, sectionIndex);
		completedPanelData[`${sectionId}${orderID}`] = formattedText(text);

		const panelUniqueID = `${sectionId}${orderID}`;
		completedPanelData[panelUniqueID] = formattedText(text);
		setCompletedPanel(
			completedPanelData,
			panelUniqueID,
			panelName,
			sectionId,
			sectionIndex,
			id
		);
	};

	const secondIdeaRemoved = sections.length === 2;
	const removeIdeaRemoveIcon = i => secondIdeaRemoved && i === 1;
	const showDeleteIcon =
		orderID > 2 && panelName.toLocaleLowerCase().indexOf('end') === -1;

	const removePanel = () => {
		deletePanel(
			selectedParams.id,
			selectedParams.idx,
			selectedParams.text,
			selectedParams.sections,
			selectedParams.rootId
		);
		setShowRemoveModal(false);
	};

	const showModal = (id, idx, text, rootId) => {
		setShowRemoveModal(true);
		// eslint-disable-next-line no-const-assign
		const newSelectedParams = {
			...selectedParams,
			...{id},
			...{idx},
			...{text},
			...{sections},
			...{rootId}
		};

		setSelectedParams(newSelectedParams);
	}

	return (
		<div id={id} className={`${classes.wrapper} ${className}`}>
			{showRemoveModal && (
				<AppModal
					key={'WritingNewPanelModal'}
					isOpen={showRemoveModal}
					onConfirm={removePanel}
					onCancel={() => setShowRemoveModal(false)}
					text={REMOVE_PANEL}
					id="remove-modal"
					showSpeakerIcon={false}
				/>
			)}
			{sections.map((section, idx) => {
				const {id, title, text} = section;
				return (
					<Fragment key={`section-${id}`}>
						<div className={`${classes.titleBar}`}>
							<div className="title">{title}</div>
							<button
								className="revise"
								onClick={(e) => handleRevise(e, id, idx, text)}
								id="revise-data"
							>
								<i className="r180 edit" />
							</button>

							{showDeleteIcon && !removeIdeaRemoveIcon(idx) && (
								<button
									className="revise"
									onClick={() => showModal(id, idx, text, props.id)}
								>
									<i className="r180 delete" />
								</button>
							)}
						</div>
						<div className={`${classes.writingArea}`}>
							{convertTagsToText(text)}
						</div>
					</Fragment>
				);
			})}
		</div>
	);
};

CompletedWritingPanel.defaultProps = {
	id: '',
	className: '',
	sections: []
};
CompletedWritingPanel.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	sections: PropTypes.array.isRequired,
	onRevise: PropTypes.func.isRequired
};

export default React.memo(CompletedWritingPanel);
