import { getAudioExt } from "../../../utils/audio";
import { MEDIA_SERVER_URL } from "../../../utils/constants";

const EXT = getAudioExt();

export const FastTrackConstants = {
	INTRO_VIDEO: `${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realsecmintro.mp4`,

	INTRO_HELP: `${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_introhelp_ha.${EXT}`,
	HELP_1: `${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_acthelp1_ha.${EXT}`,
	HELP_2: `${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_acthelp2_ha.${EXT}`,
	HELP_3: `${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_acthelp3_ha.${EXT}`,

	WORD_PATH: `${MEDIA_SERVER_URL}assets/fs/dictio/word/fs_word_{word}.${EXT}`,

	CORRECT: `${MEDIA_SERVER_URL}assets/fs/audio/fx/fs_fx_correct.${EXT}`,
	INCORRECT: `${MEDIA_SERVER_URL}assets/fs/audio/fx/fs_fx_wrong.${EXT}`,
	NO_RESPONSE: `${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_noresponse_ha.${EXT}`,
	COVER_UP: `${MEDIA_SERVER_URL}assets/fs/audio/fx/fs_fx_screen_coverup_on.${EXT}`,
	COVER_OFF: `${MEDIA_SERVER_URL}assets/fs/audio/fx/fs_fx_screen_coverup_off.${EXT}`,

	NONSENSE_LONG_INTRO: [
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonsseclintro_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonsseclintro1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonsseclintro2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonsseclintro3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonsseclintro4_ha.${EXT}`
	],

	NONSENSE_MED_INTRO: [
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonssecmintro_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonssecmintro1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonssecmintro2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonssecmintro3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_nonssecmintro4_ha.${EXT}`
	],

	REAL_LONG_INTRO: [
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realseclintro_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realseclintro1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realseclintro2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realseclintro3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realseclintro4_ha.${EXT}`
	],

	REAL_MED_INTRO: [
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realsecmintro_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realsecmintro1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realsecmintro2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/ftrk/fs_ftrk_realsecmintro3_ha.${EXT}`
	]
}
