import React, { useEffect, useState } from 'react';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import useIdleHelp from '../../utils/useIdleHelp';
import { bulkReplace } from '../../utils/stringUtils';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { uiSlice } from '../../store/slices/ui';
import { ContextPassageUtil } from './ContextPassageUtil';
import { cloneDeep, isEmpty, shuffle } from 'lodash';
import { getContentInfo } from '../../media/mediaUtil';
import media from '../../api/media';
import { ContextPassageContainer, ActivityButton, ContextPassage, VideoModal } from '@reading/common';
import { makeStyles } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import WordCard from '@reading/common/src/components/WordCard/WordCard';
import useBatchedSetState from '../../utils/useBatchedSetState';

import { successSlice } from '../../store/slices/success';
import { sleep } from '../../utils/sleep';

export const useStyles = makeStyles(theme => ({
	contentWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		zIndex: '10'
	},
	goOn: {
		position: 'absolute',
		bottom: '24px',
		right: '32px'
	},
	wordCardModal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	wordCardModalBody: {}
}));

export const isTrialCompleted = (trialMapList, contentData, trialCount, validate) => {
	let isCompleted = false;
	let selectionCount = 0;
	if (validate) {
		selectionCount = trialMapList.filter(n => n == true).length;
	} else {
		selectionCount = trialMapList.filter(n => n == true || n == false).length;
	}
	const { omissions } = contentData[trialCount];
	if (omissions.length === selectionCount) {
		isCompleted = true;
	}
	return isCompleted;
};

export const sendTrailProgressToServer = (
	selectValueList,
	trailMapList,
	attemptCount,
	isCorrect,
	attemptInfo,
	setAttemptInfo,
	activityData,
	passage
) => {
	let wordInfoArray = [];
	let attemptInfoArray = attemptInfo;

	selectValueList.forEach((item, idx) => {
		wordInfoArray.push({
			wordId: idx + 1,
			wordAttempts: ContextPassageUtil.getWordAttempt(attemptInfoArray, idx, isCorrect),
			wordSelected: item,
			isCorrect: trailMapList[idx]
		});
	});

	attemptInfoArray.push({
		attempts: attemptCount,
		correct: isCorrect,
		words: wordInfoArray
	});
	setAttemptInfo(attemptInfoArray);
	ContextPassageUtil.sendProgressToServer(activityData, attemptInfoArray, passage.studentSegmentId);
};

export const moveToNextPassage = moveNextTrailData => {
	const {
		currentTrialCount,
		attempt,
		setTrialCount,
		setStatus,
		setSelectValue,
		setTrialValidationMap,
		setTrialMap,
		setPrevSelectionMap,
		setAttempts,
		setSubmitStatus,
		setAttemptInfo,
		batched,
		dispatch,
		activityData
	} = moveNextTrailData;

	let newTrialCount = currentTrialCount + 1;

	//when the user completes the first passage, if they gave the right answers for all the questions in their first attempt then we will skip passage 2 and take them directly to passage 3 else we will show passage 2.
	if (currentTrialCount === 0 && attempt === 1) {
		newTrialCount = 2;
	}
	setTrialCount(newTrialCount);

	dispatch(successSlice.actions.storeActivityAnswer({
		id: `${activityData.studentActivityId}_${ContextPassageUtil.ACTIVITY_NAME}`,
		answer: { trialCount: newTrialCount }
	}));

	batched.setState(() => {
		setStatus(FooterForwardBack.SUBMIT);
		setSelectValue([]);
		setTrialValidationMap([]);
		setTrialMap([]);
		setPrevSelectionMap([]);
		setAttempts(0);
		setSubmitStatus(true);
		setAttemptInfo([]);
	});
};

export const handleForwardClick = async forwardData => {
	const {
		attempts,
		setAttempts,
		trialMap,
		validateTrailSelection,
		selectValue,
		prevSelectionMap,
		setPrevSelectionMap,
		setSelectValue,
		setTrialValidationMap,
		isTrialCompleted,
		contentData,
		trialCount,
		setStatus,
		setSubmitStatus,
		sendTrailProgressToServer,
		moveToNextTrail,
		activityData,
		attemptInfo,
		passage,
		dispatch,
		status,
		history,
		setTrialCount,
		setTrialMap,
		setAttemptInfo,
		batched
	} = forwardData;

	AudioPlayer2.stopAll();

	let newAttempt = attempts + 1;
	setAttempts(newAttempt);
	let newTrialMap = cloneDeep(trialMap);
	let isValid = false;

	if (status === FooterForwardBack.SUBMIT) {
		//Check Trail
		validateTrailSelection(
			selectValue,
			newTrialMap,
			newAttempt,
			prevSelectionMap,
			setPrevSelectionMap,
			setSelectValue,
			setTrialValidationMap,
			batched
		);
		isValid = isTrialCompleted(newTrialMap, contentData, trialCount, true);

		// They got it correct
		if (isValid) {
			// update the attempts and the server

			if (newAttempt === 1) {
				AudioPlayer2.play(ContextPassageUtil.SOUND_FIRSTTRY);
			} else {
				AudioPlayer2.play(ContextPassageUtil.SOUND_SECONDTRY);
			}
			setStatus(FooterForwardBack.VALID);
		}
		// They got it wrong again, but maxed out their attempts
		else if (newAttempt === ContextPassageUtil.MAX_ATTEMPTS) {
			AudioPlayer2.play(ContextPassageUtil.SOUND_INCORRECT);
			setStatus(FooterForwardBack.VALID);
		}
		// They got it wrong
		else {
			AudioPlayer2.play(ContextPassageUtil.SOUND_INCORRECT);
			batched.setState(() => {
				setStatus(FooterForwardBack.SUBMIT);
				setSubmitStatus(true);
			});
		}

		sendTrailProgressToServer(
			selectValue,
			newTrialMap,
			newAttempt,
			isValid,
			attemptInfo,
			setAttemptInfo,
			activityData,
			passage
		);
	} else if (status === FooterForwardBack.VALID) {
		moveToNextTrail(trialCount, attempts);
		if (trialCount >= ContextPassageUtil.MAX_TRIAL) {
			try {
				await ContextPassageUtil.completeActivity(history, activityData, attemptInfo, passage.studentSegmentId);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		}
	}
};

export const validateTrailSelection = (
	selectValueList,
	trailMapList,
	attemptCount,
	prevSelectionMap,
	setPrevSelectionMap,
	setSelectValue,
	setTrialValidationMap,
	batched
) => {
	let newSelectValue = cloneDeep(selectValueList);
	let newPrevSelectionMap = cloneDeep(prevSelectionMap);

	trailMapList.forEach((item, idx) => {
		let newPrevSelection = [];
		if (newPrevSelectionMap[idx]) {
			newPrevSelectionMap[idx].push(newSelectValue[idx]);
			newPrevSelection = newPrevSelectionMap[idx];
		} else {
			newPrevSelection.push(newSelectValue[idx]);
		}

		if (!item) {
			newPrevSelectionMap[idx] = newPrevSelection;
			newSelectValue[idx] = null;
		} else {
			newPrevSelectionMap[idx] = null;
		}
	});

	batched.setState(() => {
		setPrevSelectionMap(newPrevSelectionMap);
		setSelectValue(newSelectValue);
	});

	const newTrialMap = trailMapList.map((item, idx) => {
		if ((attemptCount === 1 || attemptCount === 2) && !item) {
			return null;
		} else {
			return item;
		}
	});

	setTrialValidationMap(newTrialMap);
};

export default function ContextPassageActivity(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const batched = useBatchedSetState();

	const {
		activityData,
		activityData: { studentActivityId, activityServerId, stage, segment, level },
		settings,
		passage,
		prevAnswer
	} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			settings: state.session.session.settings,
			passage: state.activity.contextpassage,
			prevAnswer: state.success.prevAnswer[`${state.activity.activityData.studentActivityId}_${ContextPassageUtil.ACTIVITY_NAME}`]
		};
	});

	const [contentData, setContentData] = useState([]);
	const [passageData, setPassageData] = useState([]);
	const [segmentData, setSegmentData] = useState(false);
	const [trialCount, setTrialCount] = useState(prevAnswer ? prevAnswer.trialCount : 0);
	const [selectValue, setSelectValue] = useState([]);
	const [trialValidationMap, setTrialValidationMap] = useState([]);
	const [trialMap, setTrialMap] = useState([]);
	const [prevSelectionMap, setPrevSelectionMap] = useState([]);
	const [attempts, setAttempts] = useState(0);
	const [disableSubmit, setSubmitStatus] = useState(true);
	const [status, setStatus] = useState(FooterForwardBack.SUBMIT);
	const [attemptInfo, setAttemptInfo] = useState([]);
	const [isVideoVisible, setVideoVisible] = useState(false);
	const [videoInfo, setVideoInfo] = useState({});
	const [cardWord, setCardWord] = useState(false);
	const [wordCardData, setWordCardData] = useState(false);

	const mediaConstants = {
		stage: stage ? stage.toLowerCase() : 'a',
		segment: segment ? segment.toString().padStart(2, '0') : '01',
		level: level ? level.toString().padStart(2, '0') : '01'
	};

	const isActivity = activityData ? activityData.activityServerId === 'context_passages' : false;

	useDeepCompareEffect(() => {
		const loadMedia = async () => {
			const { urlPath } = getContentInfo(activityData, true);
			if (urlPath !== '/') {
				const contentDataArray = await ContextPassageUtil.fetchPassageContent(urlPath, 'json');
				const passageDataArray = await ContextPassageUtil.fetchPassageContent(urlPath, 'html');
				batched.setState(() => {
					setContentData(contentDataArray);
					setPassageData(passageDataArray);
				});
			}
		};

		if (isEmpty(activityData) === false && isActivity) {
			loadMedia();
		}
	}, [activityData]);

	// check if the activity is already charged
	const isActivityCharged = useSelector(state => {
		return state.ui.chargedActivities[ContextPassageUtil.ACTIVITY_NAME] === true;
	});

	useDeepCompareEffect(() => {
		const initialize = async () => {

			const { videoUrl, videoCaptionInfo } = await ContextPassageUtil.init(mediaConstants);

			setVideoInfo({ videoUrl, videoCaptionInfo });

			if (settings.autoPlayEnabled) {
				await sleep(1000);
				AudioPlayer2.play(ContextPassageUtil.SOUND_INSTRUCTION);
			}

			dispatch(uiSlice.actions.setActivityCharged(ContextPassageUtil.ACTIVITY_NAME));
		};

		if (contentData) {
			initialize();
		}

		/* eslint-disable */
	}, [activityData, contentData, passageData]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	useEffect(() => {
		const getContentData = async () => {
			try {
				const payload = await media.getOne(bulkReplace(ContextPassageUtil.SEGMENTS, mediaConstants));
				const segmentData = payload.segments.find(filterSegment => filterSegment.segment_number === segment);
				setSegmentData(segmentData);
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				setSegmentData({ title: '' });
			}
		};
		if (stage) {
			getContentData();
		}
	}, [stage]);

	useEffect(() => {
		const getWordCardData = async () => {
			try {
				let wordCardData = await media.getOne(bulkReplace(ContextPassageUtil.WORD_CARD, { word: cardWord.word }));
				if (!wordCardData.contextualData[0]) {
					wordCardData.contextualData[0] = {
						contextualDefinition: wordCardData.definition,
						contextualSentence: wordCardData.sentence
					};
				}
				setWordCardData(wordCardData);
				ContextPassageUtil.loadAudioForSelectedWord(cardWord.word, activityData);
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				batched.setState(() => {
					setWordCardData(false);
					setCardWord(false);
				});
			}
		};
		if (cardWord && cardWord.word) {
			getWordCardData();
		}
	}, [cardWord]);

	const handleVideoClick = () => {
		AudioPlayer2.stopAll();
		setVideoVisible(true);
	};

	const handleVideoModalClose = () => {
		setVideoVisible(false);
	};

	const handleInstructions = () => {
		AudioPlayer2.stopAll();
		AudioPlayer2.play(ContextPassageUtil.SOUND_INSTRUCTION);
	};

	const handleForward = async e => {
		const forwardData = {
			attempts,
			setAttempts,
			trialMap,
			validateTrailSelection,
			selectValue,
			prevSelectionMap,
			setPrevSelectionMap,
			setSelectValue,
			setTrialValidationMap,
			isTrialCompleted,
			contentData,
			trialCount,
			setStatus,
			setSubmitStatus,
			sendTrailProgressToServer,
			moveToNextTrail,
			activityData,
			attemptInfo,
			passage,
			dispatch,
			status,
			history,
			setTrialCount,
			setTrialMap,
			setAttemptInfo,
			batched
		};

		handleForwardClick(forwardData);
	};

	const moveToNextTrail = (currentTrialCount, attempt) => {
		const moveNextTrailData = {
			currentTrialCount,
			attempt,
			setTrialCount,
			setStatus,
			setSelectValue,
			setTrialValidationMap,
			setTrialMap,
			setPrevSelectionMap,
			setAttempts,
			setSubmitStatus,
			setAttemptInfo,
			batched,
			dispatch,
			activityData
		};
		moveToNextPassage(moveNextTrailData);
	};

	const handleSelectChange = (currentSelectValue, id, isCorrect) => {
		let newTrailMap = cloneDeep(trialMap);
		newTrailMap[id - 1] = isCorrect;
		setTrialMap(newTrailMap);
		setSelectValue(currentSelectValue);

		if ((attempts === 1 || attempts === 2) && currentSelectValue.indexOf(null) === -1) {
			// on second and third attempt, we need to enable submit based on select value "null" count
			setSubmitStatus(false);
		} else if (attempts === 0) {
			// on first attempt, we need to enable submit based on trailmap list count. 
			if (isTrialCompleted(newTrailMap, contentData, trialCount, false)) {
				setSubmitStatus(false);
			}
		}
	};
	const handleExitButton = () => {
		AudioPlayer2.stopAll();
		batched.setState(() => {
			setCardWord(false);
			setWordCardData(false);
		});
	};

	useIdleHelp(ContextPassageUtil.SOUND_HELP_TRIAL);

	const isModalOpen =
		cardWord &&
		cardWord.word &&
		wordCardData &&
		wordCardData.sentence &&
		wordCardData.decodingInfo.parts.length > 0;

	return (
		<>
			<Navbar helpSoundUrl={ContextPassageUtil.SOUND_HELP_TRIAL} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={[contentData, passageData]}
				title="Context Task"
			>
				<>
					<Modal
						className={`${classes.wordCardModal} r180-modal`}
						open={isModalOpen}
						onClose={handleExitButton}
					>
						<div className={`${classes.wordCardModalBody} modal-window`} data-testid={'wordCardModal'}>
							<WordCard
								wordCardData={wordCardData}
								card={{
									progress: 0,
									maxProgress: 0,
									word_card: {
										word_card_activities: [],
										word_card_sentence: wordCardData.sentence,
										word_card_definition: wordCardData.definition,
										word_text: cardWord.word
									}
								}}
								taskData={{ video_hint: '' }}
								hideVideoTool={true}
								handleClose={handleExitButton}
								showExitButton={true}
							/>
						</div>
					</Modal>
					<div className={`${classes.contentWrapper}`}>
						<ContextPassageContainer
							onInstructionClick={handleInstructions}
							onVideoClick={handleVideoClick}
							key={'ContextPassageWrapper'}
						>
							<ContextPassage
								passageString={passageData[trialCount]}
								contentData={contentData[trialCount]}
								title={segmentData.name}
								preSelectValue={selectValue}
								preTrailValidationMap={trialValidationMap}
								onSelectChange={handleSelectChange}
								prevSelection={prevSelectionMap}
								setCardWord={setCardWord}
							/>
						</ContextPassageContainer>
						<VideoModal
							isOpen={isVideoVisible}
							onModalClose={handleVideoModalClose}
							videoSrcURL={videoInfo.videoUrl}
							videoTracks={videoInfo.videoCaptionInfo}
							start_time={0}
							end_time={0}
							hideCaptionBtn={false}
							hideReplayBtn={false}
						/>
					</div>
					<Footer>
						<FooterForwardBack
							status={status}
							isForwardDisabled={disableSubmit}
							onForward={handleForward}
							isBackVisible={false}
						/>
					</Footer>
				</>
			</ActivitySuspense>
		</>
	);
}
