import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
	highlightWord: {
		background: theme.colors.fluencyZone.blue5,
		borderBottom: `2px solid ${theme.colors.skyBlue}`,
		cursor: 'pointer',
		fontFamily: theme.fonts.context,
		fontSize: '18px',
		lineHeight: '32px',
		letterSpacing: '0.15px'
	},
	notHighlight: {
		cursor: 'text',
		fontFamily: theme.fonts.context,
		fontSize: '18px',
		lineHeight: '32px',
		letterSpacing: '0.15px',
		borderBottom: '2px solid transparent'
	},
	wordListWrapper: {
		position: 'absolute',
		background: theme.colors.white,
		border: `1px solid ${theme.colors.paleGrey}`,
		boxShadow: `0px 0px 4px ${theme.colors.paleGrey}`,
		borderRadius: '4px',
		left: '0px',
		top: '30px',
		zIndex: 99,
		minWidth: '150px',
		'& ul': {
			margin: '0',
			listStyleType: 'none',
			padding: '0px',
			textAlign: 'center',
			'& li': {
				cursor: 'pointer',
				padding: '10px',
				'&:hover': {
					background: theme.colors.fluencyZone.blue5
				}
			}
		}
	},
	lastChildWordListWrapper: {
		top: '-270px'
	}
}));

const ReadingSentenceWord = props => {
	const {
		wordId,
		word,
		spellingZoneDistractors,
		isSelected,
		selectedOption,
		isWordsPanelOpen,
		itemIndex,
		handleWordChange,
		handleListItemClick
	} = props;
	const classes = useStyles();

	return (
		<>
			<button
				data-testid={'highlightWord'}
				className={isSelected ? classes.highlightWord : classes.notHighlight}
				onClick={() =>
					handleWordChange(wordId, word, spellingZoneDistractors)
				}
			>
				{word}
			</button>
			{wordId === selectedOption.wordId && isWordsPanelOpen && (
				<div
					className={`${itemIndex === 2 &&
						classes.lastChildWordListWrapper} ${
						classes.wordListWrapper
					}`}
				>
					<ul>
						{selectedOption.distractors.map(distractor => (
							<li
								data-id="sentenceWord"
								key={distractor.index}
								value={distractor.text}
								onClick={event =>
									handleListItemClick(event, wordId)
								}
							>
								{distractor.text}
							</li>
						))}
					</ul>
				</div>
			)}
		</>
	);
};

ReadingSentenceWord.prototype = {
	wordId: PropTypes.string.isRequired,
	word: PropTypes.string.isRequired,
	spellingZoneDistractors: PropTypes.array.isRequired,
	isSelected: PropTypes.bool,
	selectedOption: PropTypes.object,
	isWordsPanelOpen: PropTypes.bool,
	handleWordChange: PropTypes.func,
	handleListItemClick: PropTypes.func
};

export default React.memo(ReadingSentenceWord);
