import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { getContentInfo } from '../../media/mediaUtil';
import { MEDIA_SERVER_URL } from '../../utils/constants';
import { bulkReplace } from '../../utils/stringUtils';
import { getPath } from '../../media/mediaUtil';
import { deepSet } from '../../utils/deep';
import media from '../../api/media';
import { cloneDeep, isEmpty, shuffle } from 'lodash';
import api from '../../api/api';
import { getAudioExt } from '../../utils/audio';
import { loadSleep } from '../../utils/ui';
import { activitySlice } from '../../store/slices/activity';
import { transitionToNextActivity } from '../../utils/navigation';

const EXT = getAudioExt();

let correctIdx = 1;

export const KnowledgeForReading = {
	MAX_ATTEMPTS: 3,

	WORD_FAMILY_MAX_TRIALS: 3,

	WORDS_IN_CONTEXT_CORRECT_ANSWERS_LENGTH: 4,
	WORDS_IN_CONTEXT_ATTEMPTS_COUNT: 5,
	WORDS_IN_CONTEXT_REDIRECT_TO_FINAL_ATTEMPT: 6,

	// URLs mapped to the media server
	WORD_CARD_URL: 'content/dictio/word/r180u_content_{word}.json',
	WORD_SOUND_URL: `${MEDIA_SERVER_URL}assets/dictio/word/r180u_word_{word}.${EXT}`,
	TASK_CARD_URL: 'content/activi/kfr/r180u_content_kfr_{asset_id}.json',

	WORD_DEF_URL: `${MEDIA_SERVER_URL}assets/dictio/defwc/r180u_defwc_{asset_id}_{word}.${EXT}`,
	WORD_CTX_URL: `${MEDIA_SERVER_URL}assets/dictio/ctxtwc/r180u_ctxtwc_{asset_id}_{word}.${EXT}`,

	WORD_CARD_SFX: {
		SHOW_SOUND: { name: 'clickFxSound', src: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_click.${EXT}` },
		CONTRACT_SOUND: { name: 'contractFxSound', src: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_contract.${EXT}` },
		EXPAND_SOUND: { name: 'expandFxSound', src: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_expand.${EXT}` },
		HIDE_SOUND: { name: 'hideFxSound', src: `${MEDIA_SERVER_URL}assets/sfx/r180u_sfx_hide.${EXT}` }
	},

	WORD_CARD_FOREIGN_SOUND: `${MEDIA_SERVER_URL}assets/dictio/{lang}/r180u_{lang}_{word}.${EXT}`,

	LANG_ACTIVITY_TIP_URL: `${MEDIA_SERVER_URL}assets/dictio/def/r180u_def_def-{word}.${EXT}`,

	////////////////////
	// Explore Zone Sounds
	////////////////////
	SOUND_INTRO: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_intro.${EXT}`,
	SOUND_INTRO_AFTER_VID: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_intro_intro.${EXT}`,

	SOUND_CORRECT_1_URL: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_correct_1.${EXT}`,
	SOUND_CORRECT_2_URL: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_correct_2.${EXT}`,
	SOUND_CORRECT_3_URL: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_correct_3.${EXT}`,
	SOUND_CORRECT_4_URL: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_correct_4.${EXT}`,
	SOUND_INCORRECT0_URL: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_attempt1.${EXT}`,
	SOUND_INCORRECT1_URL: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_attempt2.${EXT}`,

	SOUND_ALL_COMPLETE: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_allcardcomplete_help.${EXT}`,

	SOUND_COMPLETION_PERFECT_SCORE: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_completion{i}_perfect{i}.${EXT}`,
	SOUND_COMPLETION_GREATERTHAN50_SCORE: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_completion{i}_greaterthan50.${EXT}`,
	SOUND_COMPLETION_LESSTHAN50_SCORE: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_completion{i}_lessthan50.${EXT}`,
	SOUND_COMPLETION_SCORE: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_completion6_score{i}.${EXT}`,

	SOUND_HELP_WORD_CARD: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_wordcardselector_help.${EXT}`,
	SOUND_HELP_WORD_CARD_DETAIL: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_detail_help.${EXT}`,
	SOUND_HELP_IMAGE_CHOICE_URL: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_selectedrespimage_help.${EXT}`,
	SOUND_HELP_IMAGE_HOT_SPOT_URL: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_hotspot_help.${EXT}`,
	SOUND_HELP_IMAGE_MATCH_URL: `${MEDIA_SERVER_URL}assets/activi/kfr/r180u_kfr_sorting_help.${EXT}`,

	//WordCard Audio Mapping Constants
	INTRO_AUDIO_MAP: {
		PERFECT_5_REMAIN: 'completion1_perfect1',
		PERFECT_4_REMAIN: 'completion2_perfect2',
		PERFECT_3_REMAIN: 'completion3_perfect3',
		PERFECT_2_REMAIN: 'completion4_perfect4',
		PERFECT_1_REMAIN: 'completion5_perfect5',
		GREATER50_4_REMAIN: 'completion2_greaterthan50',
		GREATER50_3_REMAIN: 'completion3_greaterthan50',
		GREATER50_2_REMAIN: 'completion4_greaterthan50',
		GREATER50_1_REMAIN: 'completion5_greaterthan50',
		LESSER50_5_REMAIN: 'completion1_lessthan50',
		LESSER50_4_REMAIN: 'completion2_lessthan50',
		LESSER50_3_REMAIN: 'completion3_lessthan50',
		LESSER50_2_REMAIN: 'completion4_lessthan50',
		LESSER50_1_REMAIN: 'completion5_lessthan50',
		FINALSCORE_6_6: 'completion6_score6',
		FINALSCORE_5_6: 'completion6_score5',
		FINALSCORE_4_6: 'completion6_score4',
		FINALSCORE_3_6: 'completion6_score3',
		FINALSCORE_2_6: 'completion6_score2',
		FINALSCORE_1_6: 'completion6_score1',
		FINALSCORE_0_6: 'completion6_score0'
	},

	////////////////////
	// Language Zone Sounds
	////////////////////
	LZ_WORDCARD_VIDEO_SNIPPET: `${MEDIA_SERVER_URL}/assets/video/r180u_video_{stage}{segment}.mp4`,

	SOUND_LANG_HELP: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_help.${EXT}`,
	// these intro sounds are all the same, we'll randomly pick one to play each time the "i" is pressed
	SOUND_LANG_INTRO1: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_intro.${EXT}`,
	SOUND_LANG_INTRO2: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_intro2.${EXT}`,
	SOUND_LANG_INTRO3: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_intro3.${EXT}`,
	// these sounds only should play when they've finished all but 1 card
	SOUND_LANG_INTRO_ONE_MORE1: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_intro4.${EXT}`,
	SOUND_LANG_INTRO_ONE_MORE2: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_intro5.${EXT}`,
	SOUND_LANG_INTRO_ONE_MORE3: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_intro6.${EXT}`,
	// this sound will play when all the word cards are complete
	SOUND_LANG_INTRO_DONE: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_intro7.${EXT}`,
	// this sound should play when they reach max attempts in the activities
	SOUND_LANG_MAX_ATTEMPTS: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_detail_maxattemptsreached.${EXT}`,

	// this is the help on the single word card screen
	SOUND_LANG_HELP_WORD_CARD: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_detail_help.${EXT}`,
	// these are the sounds when no LZ activities are done yet
	SOUND_LANG_NONE_COMPLETE1: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_detail_2mandactivities1.${EXT}`,
	SOUND_LANG_NONE_COMPLETE2: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_detail_2mandactivities2.${EXT}`,
	// these are the sounds when 1 LZ activity is complete
	SOUND_LANG_ONE_COMPLETE1: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_detail_1mandactivities1.${EXT}`,
	SOUND_LANG_ONE_COMPLETE2: `${MEDIA_SERVER_URL}assets/activi/wordcardselect/r180u_wordcardselect_detail_1mandactivities2.${EXT}`,

	// Synonyms & Antonyms
	SOUND_SYNANT_ANT_TIP: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_antonym.${EXT}`,
	SOUND_SYNANT_CORRECT_FIRST: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_correctfirsttry.${EXT}`,
	SOUND_SYNANT_CORRECT_SECOND: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_correctsecondtry.${EXT}`,
	SOUND_SYNANT_GREAT: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_great.${EXT}`,
	SOUND_SYNANT_INCORRECT_FINAL: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_incorrectfinalattempt.${EXT}`,
	SOUND_SYNANT_INCORRECT_FIRST: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_incorrectfirsttry.${EXT}`,
	SOUND_SYNANT_INCORRECT_SECOND: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_incorrectsecondtry.${EXT}`,
	SOUND_SYNANT_INTRO: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_intro.${EXT}`,
	SOUND_SYNANT_RESULTS_HELP: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_results_help.${EXT}`,
	SOUND_SYNANT_INTRO_HELP: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_study_help.${EXT}`,
	SOUND_SYNANT_SYN_TIP: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_synonym.${EXT}`,
	SOUND_SYNANT_TRIAL_INTRO: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_trials.${EXT}`,
	SOUND_SYNANT_TRIAL_HELP: `${MEDIA_SERVER_URL}assets/activi/synant/r180u_synant_trial_help.${EXT}`,

	// Examples/Non-Examples
	SOUND_EXAMP_EXAM_TIP: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_example.${EXT}`,
	SOUND_EXAMP_CORRECT_FIRST: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_correctfirsttry.${EXT}`,
	SOUND_EXAMP_CORRECT_SECOND: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_correctsecondtry.${EXT}`,
	SOUND_EXAMP_GREAT: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_great.${EXT}`,
	SOUND_EXAMP_INCORRECT_FINAL: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_incorrectfinalattempt.${EXT}`,
	SOUND_EXAMP_INCORRECT_FIRST: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_incorrectfirsttry.${EXT}`,
	SOUND_EXAMP_INCORRECT_SECOND: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_incorrectsecondtry.${EXT}`,
	SOUND_EXAMP_INTRO: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_intro.${EXT}`,
	SOUND_EXAMP_RESULTS_HELP: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_results_help.${EXT}`,
	SOUND_EXAMP_INTRO_HELP: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_study_help.${EXT}`,
	SOUND_EXAMP_NONEXAM_TIP: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_non_example.${EXT}`,
	SOUND_EXAMP_TRIAL_INTRO: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_question.${EXT}`,
	SOUND_EXAMP_TRIAL_HELP: `${MEDIA_SERVER_URL}assets/activi/exnonex/r180u_exnonex_trial_help.${EXT}`,
	EXP_NONEXP_WORD_SOUND_URL: `${MEDIA_SERVER_URL}assets/activi/exnonex/{stage}/r180u_exnonex_{stage}{segment}_q{word}.${EXT}`,

	// Word Family
	WORD_FAMILY_ALMOST: `${MEDIA_SERVER_URL}assets/activi/wordfamily/r180u_wordfamily_almost.${EXT}`,
	WORD_FAMILY_CORRECT: `${MEDIA_SERVER_URL}/assets/activi/wordfamily/r180u_wordfamily_correct_trial_complete.${EXT}`,
	WORD_FAMILY_TRY_AGAIN: `${MEDIA_SERVER_URL}/assets/activi/wordfamily/r180u_wordfamily_thats_incorrect_tryagain.${EXT}`,
	WORD_FAMILY_INCORRECT_FINAL: `${MEDIA_SERVER_URL}/assets/activi/wordfamily/r180u_wordfamily_incorrectfinalattempt.${EXT}`,
	WORD_FAMILY_INTRO: `${MEDIA_SERVER_URL}assets/activi/wordfamily/r180u_wordfamily_intro.${EXT}`,
	WORD_FAMILY_STUDY_HELP: `${MEDIA_SERVER_URL}/assets/activi/wordfamily/r180u_wordfamily_study_help.${EXT}`,
	WORD_FAMILY_TRIAL_HELP: `${MEDIA_SERVER_URL}/assets/activi/wordfamily/r180u_wordfamily_trial_help.${EXT}`,
	WORD_FAMILY_TRIAL_INTRO: `${MEDIA_SERVER_URL}assets/activi/wordfamily/r180u_wordfamily_trial_intro.${EXT}`,
	WORD_FAMILY_INCORRECT: `${MEDIA_SERVER_URL}/assets/activi/wordfamily/r180u_wordfamily_incorrectfinalattempt.${EXT}`,

	WORD_FAMILY_LANDING_VO: `${MEDIA_SERVER_URL}/assets/dictio/word/r180u_word_{word}.${EXT}`,
	WORD_FAMILY_MORPH: `${MEDIA_SERVER_URL}/assets/dictio/morph/r180u_morph_{item}.${EXT}`,
	WORD_FAMILY_QUESTION_VO: `${MEDIA_SERVER_URL}/assets/activi/wordfamily/{stage}/r180u_wordfamily_{stage}{segment}_q{questionID}_{choiceID}.${EXT}`,

	// Words in Context
	SOUND_WORD_FAM_ALMOST: `${MEDIA_SERVER_URL}assets/activi/wordscontext/r180u_wordscontext_almost.${EXT}`,
	SOUND_WORD_FAM_CORRECT: `${MEDIA_SERVER_URL}assets/activi/wordscontext/r180u_wordscontext_correct.${EXT}`,
	SOUND_WORD_FAM_TRY_AGAIN: `${MEDIA_SERVER_URL}/assets/activi/wordscontext/r180u_wordscontext_incorrect.${EXT}`,
	SOUND_WORD_FAM_INCORRECT_FINAL: `${MEDIA_SERVER_URL}/assets/activi/wordscontext/r180u_wordscontext_incorrectfinalattempt.${EXT}`,
	SOUND_WORD_FAM_INTRO: `${MEDIA_SERVER_URL}/assets/activi/wordscontext/r180u_wordscontext_intro.${EXT}`,
	SOUND_WORD_FAM_RESULTS_HELP: `${MEDIA_SERVER_URL}/assets/activi/wordscontext/r180u_wordscontext_results_help.${EXT}`,
	SOUND_WORD_FAM_STUDY_HELP: `${MEDIA_SERVER_URL}/assets/activi/wordscontext/r180u_wordscontext_study_help.${EXT}`,
	SOUND_WORD_FAM_TRIAL_HELP: `${MEDIA_SERVER_URL}/assets/activi/wordscontext/r180u_wordscontext_trial_help.${EXT}`,
	SOUND_WORD_FAM_TRIAL_INTRO: `${MEDIA_SERVER_URL}/assets/activi/wordscontext/r180u_wordscontext_trialintro.${EXT}`,

	WORDS_IN_CONTEXT_PROBLEM: `${MEDIA_SERVER_URL}assets/activi/wordscontext/{stage}/r180u_wordscontext_{stage}{segment}_q{activityId}_{problemId}.${EXT}`,
	WORDS_IN_CONTEXT_COMMON_AUDIO: `${MEDIA_SERVER_URL}assets/activi/wordscontext/r180u_wordscontext_{fileName}.${EXT}`,

	ANCHOR_VIDEO: 'anchorvideo',

	//PowerWords Constants
	TOTAL_ACTIVITY_COUNT: 3,
	TOTAL_ACTIVITY_OPTIONAL_COUNT: 2,
	COMPLETED_ACTIVITY_COUNT: 1,
	INCOMPLETE_ACTIVITY_COUNT: 0,
	TOTAL_COMPLETED_ACTIVITY_COUNT: 6,

	POWER_WORD_STATUS: {
		CORRECT: 'Correct',
		INCORRECT: 'Incorrect',
		UNANSWERED: 'Unanswered',
		PARTIAL: 'Partial'
	},

	SYN_ANT_KEYS: ['synonyms', 'antonyms'],
	SYN_ANT_TEXTS: ['Synonyms', 'Antonyms'],
	EXAMPLES_NONEXAMPLES_KEYS: ['examples', 'non_examples'],
	EXAMPLES_NONEXAMPLES_TEXTS: ['Examples', 'Non-Examples'],

	TASKS_TO_ROUTE: {
		SORTING: 'image-drag-drop',
		SELECTED_RESPONSE_SINGLE_ANSWER: 'image-choice',
		IMAGE_HOT_SPOT: 'image-hot-spot'
	},

	TASKS_ALIAS_NAME: {
		synonyms_and_antonyms: 'Synonyms & Antonyms',
		word_family: 'Word Family',
		words_in_context: 'Words In Context',
		example_non_example: 'Examples & Non-Examples'
	},

	init: async (
		activityData,
		wordCards,
		powerWordData,
		languageZoneWordCards,
		prevActivity,
		settings,
		zone,
		isActivityCharged
	) => {
		const start = new Date().getTime();

		//////////////
		// pre-load the sounds
		//////////////
		AudioPlayer2.loadSound(KnowledgeForReading.SOUND_HELP_WORD_CARD);
		AudioPlayer2.loadSound(KnowledgeForReading.SOUND_HELP_WORD_CARD_DETAIL);

		// the language zone uses different sounds than the explore zone
		if (zone === 'language') {
			// LZ word card selector and word card sounds
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_INTRO1);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_INTRO2);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_INTRO3);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_INTRO_ONE_MORE1);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_INTRO_ONE_MORE2);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_INTRO_ONE_MORE3);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_INTRO_DONE);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_HELP_WORD_CARD);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_MAX_ATTEMPTS);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_NONE_COMPLETE1);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_NONE_COMPLETE2);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_ONE_COMPLETE1);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_LANG_ONE_COMPLETE2);

			// Syn & Antonyms
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_CORRECT_FIRST);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_CORRECT_SECOND);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_GREAT);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_INCORRECT_FINAL);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_INCORRECT_FIRST);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_INCORRECT_SECOND);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_INTRO);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_RESULTS_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_INTRO_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_TRIAL_INTRO);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_SYNANT_TRIAL_HELP);
			AudioPlayer2.load({ name: 'synonyms_tip', src: KnowledgeForReading.SOUND_SYNANT_SYN_TIP });
			AudioPlayer2.load({ name: 'antonyms_tip', src: KnowledgeForReading.SOUND_SYNANT_ANT_TIP });

			// Examples/Non-Examples
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_CORRECT_FIRST);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_CORRECT_SECOND);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_GREAT);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_INCORRECT_FINAL);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_INCORRECT_FIRST);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_INCORRECT_SECOND);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_INTRO);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_RESULTS_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_INTRO_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_TRIAL_INTRO);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_EXAMP_TRIAL_HELP);
			AudioPlayer2.load({ name: 'example_tip', src: KnowledgeForReading.SOUND_EXAMP_EXAM_TIP });
			AudioPlayer2.load({ name: 'nonexample_tip', src: KnowledgeForReading.SOUND_EXAMP_NONEXAM_TIP });

			// Word Family
			AudioPlayer2.loadSound(KnowledgeForReading.WORD_FAMILY_ALMOST);
			AudioPlayer2.loadSound(KnowledgeForReading.WORD_FAMILY_CORRECT);
			AudioPlayer2.loadSound(KnowledgeForReading.WORD_FAMILY_TRY_AGAIN);
			AudioPlayer2.loadSound(KnowledgeForReading.WORD_FAMILY_INCORRECT_FINAL);
			AudioPlayer2.loadSound(KnowledgeForReading.WORD_FAMILY_INTRO);
			AudioPlayer2.loadSound(KnowledgeForReading.WORD_FAMILY_STUDY_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.WORD_FAMILY_TRIAL_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.WORD_FAMILY_TRIAL_INTRO);
			AudioPlayer2.loadSound(KnowledgeForReading.WORD_FAMILY_INCORRECT);

			// Words in Context
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_WORD_FAM_ALMOST);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_WORD_FAM_CORRECT);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_WORD_FAM_TRY_AGAIN);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_WORD_FAM_INCORRECT_FINAL);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_WORD_FAM_INTRO);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_WORD_FAM_RESULTS_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_WORD_FAM_STUDY_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_WORD_FAM_TRIAL_HELP);
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_WORD_FAM_TRIAL_INTRO);
		}
		// the explore zone uses different sounds
		else {
			AudioPlayer2.loadSound(KnowledgeForReading.SOUND_INTRO);
			AudioPlayer2.load({ name: 'intro_vid', src: KnowledgeForReading.SOUND_INTRO_AFTER_VID });
			AudioPlayer2.load({ name: 'all_complete', src: KnowledgeForReading.SOUND_ALL_COMPLETE });

			AudioPlayer2.load({ name: 'incorrect0', src: KnowledgeForReading.SOUND_INCORRECT0_URL });
			AudioPlayer2.load({ name: 'incorrect1', src: KnowledgeForReading.SOUND_INCORRECT1_URL });

			AudioPlayer2.load({ name: 'correct1', src: KnowledgeForReading.SOUND_CORRECT_1_URL });
			AudioPlayer2.load({ name: 'correct2', src: KnowledgeForReading.SOUND_CORRECT_2_URL });
			AudioPlayer2.load({ name: 'correct3', src: KnowledgeForReading.SOUND_CORRECT_3_URL });
			AudioPlayer2.load({ name: 'correct4', src: KnowledgeForReading.SOUND_CORRECT_4_URL });

			AudioPlayer2.load({ name: 'help_image_choice', src: KnowledgeForReading.SOUND_HELP_IMAGE_CHOICE_URL });
			AudioPlayer2.load({ name: 'help_hot_spot', src: KnowledgeForReading.SOUND_HELP_IMAGE_HOT_SPOT_URL });
			AudioPlayer2.load({ name: 'help_image_match', src: KnowledgeForReading.SOUND_HELP_IMAGE_MATCH_URL });

			AudioPlayer2.load({ name: 'example_tip', src: KnowledgeForReading.SOUND_EXAMP_EXAM_TIP });
			AudioPlayer2.load({ name: 'nonexample_tip', src: KnowledgeForReading.SOUND_EXAMP_NONEXAM_TIP });

			// load all the completion/score sounds
			for (let i = 0; i <= 6; i++) {
				if (i === 0 || i === 6) {
					const constants = { i };
					AudioPlayer2.load({
						name: `completion6_score${i}`,
						src: bulkReplace(KnowledgeForReading.SOUND_COMPLETION_SCORE, constants)
					});
				} else {
					const constants = { i };
					AudioPlayer2.load({
						name: `completion6_score${i}`,
						src: bulkReplace(KnowledgeForReading.SOUND_COMPLETION_SCORE, constants)
					});

					AudioPlayer2.load({
						name: `completion${i}_perfect${i}`,
						src: bulkReplace(KnowledgeForReading.SOUND_COMPLETION_PERFECT_SCORE, constants)
					});

					if (i !== 1) {
						AudioPlayer2.load({
							name: `completion${i}_greaterthan50`,
							src: bulkReplace(KnowledgeForReading.SOUND_COMPLETION_GREATERTHAN50_SCORE, constants)
						});
					}

					AudioPlayer2.load({
						name: `completion${i}_lessthan50`,
						src: bulkReplace(KnowledgeForReading.SOUND_COMPLETION_LESSTHAN50_SCORE, constants)
					});
				}
			}
		}

		// load all the sound effects
		KnowledgeForReading.loadSfxAudios();

		//////////////
		// load the sounds for each word in the word card
		//////////////
		if (wordCards) {
			wordCards.forEach(wordCard => {
				const word = wordCard.word_card.word_text;
				const constants = {
					word: word,
					asset_id: getContentInfo(activityData, false).assetId
				};
				const word_text_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, constants);
				const word_def_url = bulkReplace(KnowledgeForReading.WORD_DEF_URL, constants);
				const word_ctx_url = bulkReplace(KnowledgeForReading.WORD_CTX_URL, constants);

				// the pronounced word
				AudioPlayer2.load({ name: word, src: word_text_url });

				// the definition
				AudioPlayer2.load({ name: `def-${word}`, src: word_def_url });

				// the context
				AudioPlayer2.load({ name: `ctx-${word}`, src: word_ctx_url });

				// load the foreign language sound file if necessary
				if (settings.language !== 'none') {
					const newWordUrl = word_text_url.replace(/word/g, settings.language);
					AudioPlayer2.load({ name: word + '-foreign', src: newWordUrl });
				}
			});
		}

		//////////////
		// now we have to loop through the 6 or 9 word_card objects and load in the word-specific data
		//////////////
		let newWordCardMap = {};
		if (wordCards) {
			await Promise.all(
				wordCards.map(async wordCard => {
					const word = wordCard.word_card.word_text;
					const constants = { word };
					const wordContent = await media.getOne(bulkReplace(KnowledgeForReading.WORD_CARD_URL, constants));
					newWordCardMap[word] = wordContent;
				})
			);
		}

		//////////////
		// load the task data for these word cards
		//////////////
		let newActivityData = { ...activityData };
		newActivityData.activityServerId =
			zone === 'explore' ? 'knowledge_for_reading_tasks' : 'word_card_selector_tasks';

		const { activityAssetCode, assetId, urlPath } = getContentInfo(newActivityData, zone === 'language');

		let taskContentData = await media.getOne(urlPath);

		const modifiedActivityData = { ...activityData, activityAssetCode, assetId };
		const taskData = cloneDeep(taskContentData);

		if (zone === 'explore') {
			KnowledgeForReading.insertAssetUrl(taskData, modifiedActivityData);
			KnowledgeForReading.preloadImages(taskData.questions);
		}

		if (zone === 'language') {
			KnowledgeForReading.insertLanguageAssetUrl(taskData, modifiedActivityData);
		}

		let powerWordMapData = {};

		if (wordCards) {
			powerWordMapData = KnowledgeForReading.buildPowerWordMap(
				zone,
				wordCards,
				powerWordData,
				languageZoneWordCards
			);
		}

		// calculate intro id based on remainingQuestions count , correctPercentage and finalCorrect value.
		if (powerWordData && isActivityCharged === false) {
			if (powerWordData.length) {
				if (prevActivity !== KnowledgeForReading.ANCHOR_VIDEO) {
					const introAudioId = KnowledgeForReading.getIntroAudioId(powerWordMapData);
					AudioPlayer2.play(introAudioId);
				}
			}
		}

		const end = new Date().getTime();
		if (isActivityCharged === false) {
			await loadSleep(end - start);
		}

		return { taskData, newWordCardMap, powerWordMapData, wordCards };
	},

	getDropZoneAudio: id => {
		switch (id) {
			case 'examples':
				return KnowledgeForReading.SOUND_EXAMP_EXAM_TIP;
			case 'non_examples':
				return KnowledgeForReading.SOUND_EXAMP_NONEXAM_TIP;
			case 'synonyms':
				return KnowledgeForReading.SOUND_SYNANT_SYN_TIP;
			case 'antonyms':
				return KnowledgeForReading.SOUND_SYNANT_ANT_TIP;

			default:
				return '';
		}
	},

	playCorrectSound: () => {
		AudioPlayer2.play(`correct${correctIdx}`);
		correctIdx++;
		if (correctIdx === 5) {
			correctIdx = 1;
		}
	},

	playLanguageCorrectSound: (activity_server_id, correctGuessesSoFar) => {
		correctGuessesSoFar++;

		if (correctGuessesSoFar === 1) {
			if (activity_server_id === 'example_non_example') {
				AudioPlayer2.play(`examcorrect${correctGuessesSoFar}`);
			} else if (activity_server_id === 'synonyms_and_antonyms') {
				AudioPlayer2.play(`syncorrect${correctGuessesSoFar}`);
			}
		} else {
			AudioPlayer2.play(`correct${correctGuessesSoFar}`);
		}
	},

	playIncorrectSound: incorrectGuessesSoFar => {
		if (incorrectGuessesSoFar === KnowledgeForReading.MAX_ATTEMPTS - 1) {
			AudioPlayer2.play('incorrect1');
		} else {
			AudioPlayer2.play('incorrect0');
		}
	},

	playClickSound: () => {
		AudioPlayer2.play('clickFxSound');
	},

	playContractSounds: () => {
		AudioPlayer2.play('contractFxSound');
	},

	loadSfxAudios: () => {
		// load all the sound effects
		Object.values(KnowledgeForReading.WORD_CARD_SFX).forEach(sfx => {
			AudioPlayer2.load({ name: sfx.name, src: sfx.src });
		});
	},

	correctAnswerCount: attempts => {
		let ansCount = 0;
		attempts.forEach(attempt => {
			if (attempt.correct) {
				ansCount = attempts.length;
			}
		});
		return ansCount;
	},

	getWordStatus: attempts => {
		let wordStatus = KnowledgeForReading.POWER_WORD_STATUS.UNANSWERED;
		if (attempts.length > 0) {
			wordStatus =
				KnowledgeForReading.correctAnswerCount(attempts) > 0
					? KnowledgeForReading.POWER_WORD_STATUS.CORRECT
					: KnowledgeForReading.POWER_WORD_STATUS.INCORRECT;
		}
		return wordStatus;
	},

	getActivityStatus: (powerWordMapData, isComplete, currentWord) => {
		const completeActivityList = [];
		let activityStatus = false;

		if (isEmpty(powerWordMapData) === false) {
			Object.keys(powerWordMapData).forEach(function (key) {
				if (powerWordMapData[key].wordId !== currentWord) {
					if (powerWordMapData[key].isCompleted) {
						completeActivityList.push(powerWordMapData[key]);
					}
				}
			});
			if (
				Object.keys(powerWordMapData).length - KnowledgeForReading.COMPLETED_ACTIVITY_COUNT ===
				completeActivityList.length &&
				isComplete
			) {
				//completeActivityList.length should be 5
				activityStatus = true;
			}
		}
		return activityStatus;
	},

	getBucketHeaderTipName: headerText => {
		const nonExampleList = ['Non', 'No', 'Not'];
		let headerTipName = 'example_tip';
		if (nonExampleList.some(substring => headerText.includes(substring))) {
			headerTipName = 'nonexample_tip';
		} else {
			headerTipName = 'example_tip';
		}
		if (headerText === 'Synonyms') {
			headerTipName = 'synonyms_tip';
		}
		if (headerText === 'Antonyms') {
			headerTipName = 'antonyms_tip';
		}

		return headerTipName;
	},

	completeActivity: async (history, activityData, navigation, contentData, selectedTask) => {
		await transitionToNextActivity(history, {
			activity: {
				isComplete: true,
				navigation: navigation,
				studentActivityId: activityData.studentActivityId
			},
			wordcards: {
				totalWords: contentData.word_cards.length,
				selectedWord: selectedTask.word_text,
				studentActivityId: activityData.studentActivityId
			}
		});
	},

	completeKFRActivity: async (history, activityData, powerwords) => {
		await transitionToNextActivity(history, {
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			},
			powerwords: {
				powerwords: powerwords,
				studentActivityId: activityData.studentActivityId
			}
		});
	},

	sendProgressToServer: async (
		datatoServer,
		attempts,
		contentData,
		bundledActivityData,
		powerWordMapData,
		history,
		dispatch,
		powerWords
	) => {
		// if this is empty, it's language zone, and the click through's are Activities (which send their
		// data to the server) and are NOT tasks (which need to send data to the server on their own)
		if (
			isEmpty(bundledActivityData) ||
			isEmpty(bundledActivityData.activity) ||
			isEmpty(bundledActivityData.powerwords)
		) {
			return;
		}

		// the data getting sent to the server in the POST /activity
		let savedActivityData = {
			activity: {
				studentActivityId: bundledActivityData.activity.studentActivityId,
				isComplete: false
			},
			powerwords: {
				studentActivityId: bundledActivityData.activity.studentActivityId
			}
		};

		// This power word data is sent to the server as part of the POST to activity
		// and is NOT used in the app's progress calculations
		let powerWordData = cloneDeep(bundledActivityData.powerwords.powerwords);
		let newPowerWordData = [];
		let existingTotalData = 0;
		let existingCorrectData = 0;
		powerWordData.forEach(word => {
			if (word.wordId !== contentData.power_word_focus) {
				newPowerWordData.push({
					wordId: word.wordId,
					correct: word.correct,
					total: word.total
				});
			} else {
				existingTotalData = word.correct;
				existingCorrectData = word.total;
			}
		});

		newPowerWordData.push({
			wordId: contentData.power_word_focus,
			correct: datatoServer.isComplete ? 1 : 0,
			total: attempts.length + existingCorrectData
		});

		savedActivityData.powerwords.powerwords = newPowerWordData;

		// To get the 3 tasks working properly, we need to update the Redux store's version of the power word data
		// we need to save it in the server format because our utility function below converts it into a data format we can use in the app
		const newPowerWords = cloneDeep(powerWords);
		let foundRecord = false;
		newPowerWords &&
			newPowerWords.powerwords.forEach(word => {
				if (word.wordId === contentData.power_word_focus) {
					foundRecord = true;
					word.wordStatus = datatoServer.isComplete
						? KnowledgeForReading.POWER_WORD_STATUS.CORRECT
						: KnowledgeForReading.POWER_WORD_STATUS.INCORRECT;
				}
			});

		if (foundRecord === false) {
			newPowerWords &&
				newPowerWords.powerwords.push({
					correct: datatoServer.isComplete ? 1 : 0,
					studentActivityId: bundledActivityData.activity.studentActivityId,
					total: attempts.length + existingCorrectData,
					wordId: contentData.power_word_focus,
					wordStatus: datatoServer.isComplete
						? KnowledgeForReading.POWER_WORD_STATUS.CORRECT
						: KnowledgeForReading.POWER_WORD_STATUS.INCORRECT
				});
		}

		dispatch(activitySlice.actions.setPowerWordsData(newPowerWords));

		api.activity.updateActivity(savedActivityData);
	},

	loadSynonymData: async synonym_antonym_Data => {
		let synonymsAndAntonymsWordList = [];
		let buckets = [];
		let id = 2012;
		for (let i = 0; i < KnowledgeForReading.SYN_ANT_KEYS.length; i++) {
			let activitySubWord = KnowledgeForReading.SYN_ANT_KEYS[i];
			if (synonym_antonym_Data.hasOwnProperty(activitySubWord)) {
				let itemList = [];
				for (let j = 0; j < synonym_antonym_Data[activitySubWord].length; j++) {
					let activitySubWordData = synonym_antonym_Data[activitySubWord];
					const constants = {
						word: activitySubWordData[j].text
					};
					const wordContent = await media.getOne(bulkReplace(KnowledgeForReading.WORD_CARD_URL, constants));
					const word_tip_url = bulkReplace(KnowledgeForReading.LANG_ACTIVITY_TIP_URL, constants);
					const word_sound_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, constants);
					synonymsAndAntonymsWordList.push({
						word: activitySubWordData[j].text,
						wordUrl: word_sound_url,
						tipUrl: word_tip_url,
						tip: wordContent.definition
					});
					itemList.push({
						id: activitySubWordData[j].id,
						suppressed: false,
						text: activitySubWordData[j].text,
						textUrl: word_sound_url,
						tipUrl: word_tip_url,
						tip: wordContent.definition
					});
				}
				buckets.push({
					id: id,
					text: KnowledgeForReading.SYN_ANT_TEXTS[i],
					items: itemList
				});
				id++;
			}
		}

		return { synonymsAndAntonymsWordList, buckets };
	},

	loadExampleData: (synonym_antonym_Data, activityData) => {
		let synonymsAndAntonymsWordList = [];
		let buckets = [];
		// NOTE::::  this bucket id needs to be coming from server till then it is hardcoded
		let id = 2012;
		for (let i = 0; i < KnowledgeForReading.EXAMPLES_NONEXAMPLES_KEYS.length; i++) {
			let activitySubWord = KnowledgeForReading.EXAMPLES_NONEXAMPLES_KEYS[i];
			if (synonym_antonym_Data.hasOwnProperty(activitySubWord)) {
				let itemList = [];
				for (let j = 0; j < synonym_antonym_Data[activitySubWord].length; j++) {
					let activitySubWordData = synonym_antonym_Data[activitySubWord];
					let constants = {};
					let wordUrl = '';
					if (activitySubWordData[j].text.includes(' ')) {
						constants = {
							word: synonym_antonym_Data.id + '_' + activitySubWordData[j].id,
							stage: activityData.stage,
							segment: activityData.segment.toString().padStart(2, '0')
						};
						wordUrl = KnowledgeForReading.EXP_NONEXP_WORD_SOUND_URL;
					} else {
						constants = {
							word: activitySubWordData[j].text
						};
						wordUrl = KnowledgeForReading.WORD_SOUND_URL;
					}
					const word_sound_url = bulkReplace(wordUrl, constants);
					synonymsAndAntonymsWordList.push({
						word: activitySubWordData[j].text,
						wordUrl: word_sound_url
					});
					itemList.push({
						id: activitySubWordData[j].id,
						suppressed: false,
						text: activitySubWordData[j].text,
						textUrl: word_sound_url
					});
				}
				buckets.push({
					id: id,
					text: KnowledgeForReading.EXAMPLES_NONEXAMPLES_TEXTS[i],
					items: itemList
				});
				id++;
			}
		}

		return { synonymsAndAntonymsWordList, buckets };
	},

	getIntroAudioId: powerWordMapData => {
		const wordCardStatusInfo = KnowledgeForReading.getWordCardStatusInfo(powerWordMapData);
		const remainingQuestions = wordCardStatusInfo.remainingQuestions;
		const correctPercentage = wordCardStatusInfo.correctPercentage;
		const finalCorrect = wordCardStatusInfo.finalCorrect;
		let audioId = '';

		if (remainingQuestions === 0) {
			audioId = KnowledgeForReading.genAudioId('final', finalCorrect);
		} else if (remainingQuestions === 6) {
			audioId = 'intro';
		} else if (correctPercentage === 100) {
			audioId = KnowledgeForReading.genAudioId('perfect', remainingQuestions);
		} else if (remainingQuestions === 5) {
			if (correctPercentage < 50) {
				audioId = KnowledgeForReading.genAudioId('lesser', remainingQuestions);
			}
		} else if (remainingQuestions < 5 && remainingQuestions > 0) {
			if (correctPercentage >= 50) {
				audioId = KnowledgeForReading.genAudioId('greater', remainingQuestions);
			} else if (correctPercentage <= 50) {
				audioId = KnowledgeForReading.genAudioId('lesser', remainingQuestions);
			}
		}
		return audioId;
	},

	getWordCardStatusInfo: powerWordMapData => {
		let remainingQuestions = KnowledgeForReading.TOTAL_COMPLETED_ACTIVITY_COUNT;
		let correctPercentage = 0;
		let finalCorrect = 0;
		let evaluatedCount = 0;
		let completedQuestions = 0;

		Object.keys(powerWordMapData).forEach(function (key) {
			if (powerWordMapData[key].isCompleted) {
				remainingQuestions--;
				completedQuestions++;
			}
			if (powerWordMapData[key].isEvaluated) {
				evaluatedCount++;
			}
		});

		if (remainingQuestions === 0) {
			finalCorrect = completedQuestions;
		}

		if (evaluatedCount === 0) {
			correctPercentage = 0;
		} else {
			correctPercentage = (completedQuestions / evaluatedCount) * 100;
		}
		return { remainingQuestions, correctPercentage, finalCorrect, completedQuestions };
	},

	genAudioId: (audioFamily, key) => {
		// audioFamily is "perfect", "greater", "less" or "final"
		var audioId = '';

		switch (audioFamily) {
			case 'perfect':
				audioId = KnowledgeForReading.INTRO_AUDIO_MAP['PERFECT_' + key + '_REMAIN'];
				break;
			case 'greater':
				audioId = KnowledgeForReading.INTRO_AUDIO_MAP['GREATER50_' + key + '_REMAIN'];
				break;
			case 'lesser':
				audioId = KnowledgeForReading.INTRO_AUDIO_MAP['LESSER50_' + key + '_REMAIN'];
				break;
			case 'final':
				audioId = KnowledgeForReading.INTRO_AUDIO_MAP['FINALSCORE_' + key + '_6'];
				break;
			default:
				audioId = '';
		}

		return audioId;
	},

	// the UI uses this to convert the powerworddata in the server response
	// into progress data we can use in the UI.  It also normalizes the data differences
	// between Explore zone and Language zone
	buildPowerWordMap: (zone, wordCards, powerWordData = [], languageZoneWordCards = []) => {
		let power_word_map = {};
		wordCards.forEach(w => {
			const word = w.word_card ? w.word_card.word_text : '';
			const isMandatory = w.word_card ? w.word_card.mandatory : false;
			const totalActivity = isMandatory
				? KnowledgeForReading.TOTAL_ACTIVITY_COUNT
				: KnowledgeForReading.TOTAL_ACTIVITY_OPTIONAL_COUNT;

			// explore zone and language zone handle completion data differently
			// let's normalize the language zone data into the explore zone's data structure

			if (zone === 'explore') {
				const power = powerWordData.filter(p => {
					return word === p.wordId;
				});

				let completedActivity = KnowledgeForReading.INCOMPLETE_ACTIVITY_COUNT;
				let isCompleted = false;
				let isEvaluated = false;
				if (power.length) {
					for (let i = 0; i < power.length; i++) {
						if (
							power[i].correct === 1 ||
							power[i].wordStatus === KnowledgeForReading.POWER_WORD_STATUS.CORRECT
						) {
							completedActivity = KnowledgeForReading.COMPLETED_ACTIVITY_COUNT;
							isCompleted = true;
							isEvaluated = true;
						}
						if (power[i].wordStatus === KnowledgeForReading.POWER_WORD_STATUS.INCORRECT) {
							isEvaluated = true;
						}
					}
				}
				power_word_map[word] = {
					wordId: word,
					completedActivity: completedActivity,
					totalActivity: totalActivity,
					isCompleted: isCompleted,
					isEvaluated: isEvaluated
				};
			} else {
				const completed = languageZoneWordCards.filter(wc => {
					return wc.wordId === word;
				});

				let completedActivity = 1;

				if (completed.length > 0) {
					completedActivity = completed[0].completedActivities.length;
				}

				power_word_map[word] = {
					wordId: word,
					completedActivity: completedActivity,
					completedActivityList: completed.length > 0 ? completed[0].completedActivities : [],
					totalActivity: totalActivity,
					isCompleted: completedActivity === KnowledgeForReading.TOTAL_ACTIVITY_COUNT,
					isEvaluated: true
				};
			}
		});

		return power_word_map;
	},

	shuffleBucketInfoList: bucketInfoList => {
		let newBucketInfoList = [];
		try {
			const bucketInfoSourceItem = bucketInfoList.filter(bucketInfo => {
				return bucketInfo.type === 'source';
			});
			const shuffledBucketInfoSourceItem = shuffle(bucketInfoSourceItem);
			const bucketInfoDestinationItem = bucketInfoList.filter(bucketInfo => {
				return bucketInfo.type === 'destination';
			});
			newBucketInfoList = [...shuffledBucketInfoSourceItem, ...bucketInfoDestinationItem];
		} catch (e) {
			newBucketInfoList = bucketInfoList;
		}

		return newBucketInfoList;
	},

	shuffleBucketItemListData: bucketItemDataList => {
		let newBucketItemDataList = [];
		try {
			const bucketItemDataSourceList = bucketItemDataList.filter(bucketItemData => {
				return bucketItemData[0].id !== 'empty';
			});
			const shuffledBucketItemDataSourceList = shuffle(bucketItemDataSourceList);
			const bucketItemDataDestinatioList = bucketItemDataList.filter(bucketItemData => {
				return bucketItemData[0].id === 'empty';
			});
			newBucketItemDataList = [...shuffledBucketItemDataSourceList, ...bucketItemDataDestinatioList];
		} catch (e) {
			newBucketItemDataList = bucketItemDataList;
		}
		return newBucketItemDataList;
	},

	preloadImages: questions => {
		// wait 5 seconds so the images don't compete with the loading of the sounds
		setTimeout(() => {
			Array.isArray(questions) &&
				questions.forEach(ques => {
					if (ques.type === 'SORTING') {
						ques.buckets.forEach(bucket => {
							bucket.items.forEach(item => {
								let i = new Image();
								i.src = item.text_url;
							});
						});
					} else if (ques.type === 'IMAGE_HOT_SPOT') {
						let i = new Image();
						i.src = ques.question_image_file_name_url;
					} else {
						ques.answers.forEach(answer => {
							let i = new Image();
							i.src = answer.text_url;
						});
						ques.distractors.forEach(dis => {
							let i = new Image();
							i.src = dis.text_url;
						});
					}
				});
		}, 5000);
	},

	insertLanguageAssetUrl: (contentData, modifiedActivityData) => {
		contentData.word_cards.forEach(word_card => {
			word_card.word_card.word_card_activities.forEach(activity => {
				const stage = modifiedActivityData.stage;
				const segment = `${modifiedActivityData.segment.toString().padStart(2, '0')}`;
				const videoSnippetUrl = bulkReplace(KnowledgeForReading.LZ_WORDCARD_VIDEO_SNIPPET, { stage, segment });
				word_card.word_card.video_hint = { start_time: 0, end_time: 0, src_url: videoSnippetUrl };

				switch (activity.word_card_activity.activity_server_id) {
					case 'synonyms_and_antonyms': {
						const assetsActivityTypeData =
							KnowledgeForReading.assetTypes['word_card_selector_tasks'].tasks[
							`${activity.word_card_activity.type}_${activity.word_card_activity.answer_type}_SYNANT`
							];
						assetsActivityTypeData['fileMappings'].forEach(fileMapping => {
							const path = `${MEDIA_SERVER_URL}${getPath(
								fileMapping.location,
								modifiedActivityData.activityAssetCode
							)}/`;
							const constants = {
								activity_asset_code: modifiedActivityData.activityAssetCode,
								asset_id: modifiedActivityData.assetId,
								word: word_card.word_card.word_text
							};
							const assetFileName = bulkReplace(fileMapping.naming_rule, constants);
							const assetUrl = path + assetFileName;
							deepSet(activity.word_card_activity, fileMapping.fieldName + '_url', assetUrl);
						});
						break;
					}

					case 'word_family': {
						// TODO
						break;
					}
					case 'words_in_context': {
						// TODO
						break;
					}
					case 'example_non_example': {
						const assetsActivityTypeData =
							KnowledgeForReading.assetTypes['word_card_selector_tasks'].tasks[
							`${activity.word_card_activity.type}_${activity.word_card_activity.answer_type}_EXNONEX`
							];
						assetsActivityTypeData['fileMappings'].forEach(fileMapping => {
							const path = `${MEDIA_SERVER_URL}${getPath(
								fileMapping.location,
								modifiedActivityData.activityAssetCode
							)}/`;
							//const path = getPath(fileMapping.location, modifiedActivityData.activityAssetCode);
							const constants = {
								activity_asset_code: modifiedActivityData.activityAssetCode,
								asset_id: modifiedActivityData.assetId,
								// question_id: question.id,
								word: word_card.word_card.word_text
							};
							const assetFileName = bulkReplace(fileMapping.naming_rule, constants);
							const assetUrl = path + assetFileName;
							deepSet(activity.word_card_activity, fileMapping.fieldName + '_url', assetUrl);
						});
						break;
					}
					default: {
						return;
					}
				}
			});
		});
	},

	insertAssetUrl: (contentData, modifiedActivityData) => {
		contentData.questions.forEach(question => {
			const assetsActivityTypeData =
				KnowledgeForReading.assetTypes['knowledge_for_reading_tasks'].tasks[
				question.type + '_' + question.answer_type
				];

			assetsActivityTypeData['fileMappings'].forEach(fileMapping => {
				const path = `${MEDIA_SERVER_URL}${getPath(
					fileMapping.location,
					modifiedActivityData.activityAssetCode
				)}/`;
				const constants = {
					activity_asset_code: modifiedActivityData.activityAssetCode,
					asset_id: modifiedActivityData.assetId,
					question_id: question.id,
					word: question.power_word_focus
				};
				const assetFileName = bulkReplace(fileMapping.naming_rule, constants);
				const assetUrl = path + assetFileName;
				deepSet(question, fileMapping.fieldName + '_url', assetUrl);

				Array.isArray(question.answers) &&
					question.answers.forEach(answer => {
						const qaConstants = {
							answer_id: answer.id
						};
						answer.text_url = bulkReplace(answer.text_url, qaConstants);
					});
				Array.isArray(question.distractors) &&
					question.distractors.forEach(distractor => {
						const qaConstants = {
							answer_id: distractor.id
						};
						distractor.text_url = bulkReplace(distractor.text_url, qaConstants);
					});
				Array.isArray(question.buckets) &&
					question.buckets.forEach(bucket => {
						Array.isArray(bucket.items) &&
							bucket.items.forEach(item => {
								const itemConstants = {
									answer_id: item.id
								};
								item.text_url = bulkReplace(item.text_url, itemConstants);
							});
					});
			});
		});
	},

	assetTypes: {
		knowledge_for_reading_tasks: {
			tasks: {
				SELECTED_RESPONSE_SINGLE_ANSWER_IMAGE: {
					fileMappings: [
						{
							fieldName: 'answers[].text',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: 'r180u_{activity_asset_code}_{asset_id}_selresp_q{question_id}_{answer_id}.jpg'
						},
						{
							fieldName: 'distractors[].text',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: 'r180u_{activity_asset_code}_{asset_id}_selresp_q{question_id}_{answer_id}.jpg'
						},
						{
							fieldName: 'stem',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_{asset_id}_selresp_q{question_id}_question.${EXT}`
						},
						{
							fieldName: 'power_word_focus',
							location: 'assets/dictio/word',
							naming_rule: `r180u_word_{word}.${EXT}`
						},
						{
							fieldName: 'help',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_selectedrespimage_help.${EXT}`
						},
						{
							fieldName: 'video_hint.src',
							location: 'assets/video',
							naming_rule: 'r180u_video_{asset_id}.mp4'
						}
					]
				},
				SELECTED_RESPONSE_SINGLE_ANSWER_TEXT: {
					fileMappings: [
						{
							fieldName: 'answers[].text',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: 'r180u_{activity_asset_code}_{asset_id}_selresp_q{question_id}_{answer_id}.jpg'
						},
						{
							fieldName: 'distractors[].text',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: 'r180u_{activity_asset_code}_{asset_id}_selresp_q{question_id}_{answer_id}.jpg'
						},
						{
							fieldName: 'stem',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_{asset_id}_selresp_q{question_id}_question.${EXT}`
						},
						{
							fieldName: 'power_word_focus',
							location: 'assets/dictio/word',
							naming_rule: `r180u_word_{word}.${EXT}`
						},
						{
							fieldName: 'help',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_selectedrespimage_help.${EXT}`
						},
						{
							fieldName: 'video_hint.src',
							location: 'assets/video',
							naming_rule: 'r180u_video_{asset_id}.mp4'
						}
					]
				},
				SELECTED_RESPONSE_SINGLE_ANSWER_VIDEO_CLIP: {
					fileMappings: [
						{
							fieldName: 'answers[].text',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: 'r180u_{activity_asset_code}_{asset_id}_selresp_q{question_id}_{answer_id}.jpg'
						},
						{
							fieldName: 'distractors[].text',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: 'r180u_{activity_asset_code}_{asset_id}_selresp_q{question_id}_{answer_id}.jpg'
						},
						{
							fieldName: 'stem',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_{asset_id}_selresp_q{question_id}_question.${EXT}`
						},
						{
							fieldName: 'power_word_focus',
							location: 'assets/dictio/word',
							naming_rule: `r180u_word_{word}.${EXT}`
						},
						{
							fieldName: 'help',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_selectedrespimage_help.${EXT}`
						},
						{
							fieldName: 'video_hint.src',
							location: 'assets/video',
							naming_rule: 'r180u_video_{asset_id}.mp4'
						}
					]
				},
				SORTING_TEXT: {
					fileMappings: [
						{
							fieldName: 'buckets[].items[].text',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_{asset_id}_sorting_q{question_id}_{answer_id}.${EXT}`
						},
						{
							fieldName: 'stem',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_{asset_id}_sorting_q{question_id}_question.${EXT}`
						},
						{
							fieldName: 'power_word_focus',
							location: 'assets/dictio/word',
							naming_rule: `r180u_word_{word}.${EXT}`
						},
						{
							fieldName: 'help',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_sorting_help.${EXT}`
						},
						{
							fieldName: 'video_hint.src',
							location: 'assets/video',
							naming_rule: 'r180u_video_{asset_id}.mp4'
						}
					]
				},
				SORTING_IMAGE: {
					fileMappings: [
						{
							fieldName: 'buckets[].items[].text',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: 'r180u_{activity_asset_code}_{asset_id}_sorting_q{question_id}_{answer_id}.jpg'
						},
						{
							fieldName: 'stem',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_{asset_id}_sorting_q{question_id}_question.${EXT}`
						},
						{
							fieldName: 'power_word_focus',
							location: 'assets/dictio/word',
							naming_rule: `r180u_word_{word}.${EXT}`
						},
						{
							fieldName: 'help',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_sorting_help.${EXT}`
						},
						{
							fieldName: 'video_hint.src',
							location: 'assets/video',
							naming_rule: 'r180u_video_{asset_id}.mp4'
						}
					]
				},
				IMAGE_HOT_SPOT_TEXT: {
					fileMappings: [
						{
							fieldName: 'question_image_file_name',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: 'r180u_{activity_asset_code}_{asset_id}_hotspot_q{question_id}.jpg'
						},
						{
							fieldName: 'stem',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_{asset_id}_hotspot_q{question_id}_question.${EXT}`
						},
						{
							fieldName: 'power_word_focus',
							location: 'assets/dictio/word',
							naming_rule: `r180u_word_{word}.${EXT}`
						},
						{
							fieldName: 'help',
							location: 'assets/activi/{activity_asset_code}',
							naming_rule: `r180u_{activity_asset_code}_hotspot_help.${EXT}`
						},
						{
							fieldName: 'video_hint.src',
							location: 'assets/video',
							naming_rule: 'r180u_video_{asset_id}.mp4'
						}
					]
				}
			}
		},
		word_card_selector_tasks: {
			tasks: {
				SORTING_TEXT_SYNANT: {
					fileMappings: [
						{
							fieldName: 'stem',
							location: 'assets/activi/synant',
							naming_rule: `r180u_synant_intro.${EXT}`
						},
						{
							fieldName: 'help',
							location: 'assets/activi/synant',
							naming_rule: `r180u_synant_study_help.${EXT}`
						},
						{
							fieldName: 'power_word_focus',
							location: 'assets/dictio/word',
							naming_rule: `r180u_word_{word}.${EXT}`
						}
					]
				},
				SORTING_TEXT_EXNONEX: {
					fileMappings: [
						{
							fieldName: 'stem',
							location: 'assets/activi/exnonex',
							naming_rule: `r180u_exnonex_intro.${EXT}`
						},
						{
							fieldName: 'help',
							location: 'assets/activi/exnonex',
							naming_rule: `r180u_exnonex_study_help.${EXT}`
						},
						{
							fieldName: 'power_word_focus',
							location: 'assets/dictio/word',
							naming_rule: `r180u_word_{word}.${EXT}`
						}
					]
				}
			}
		}
	}
};
