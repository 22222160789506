import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
	close: {
		position: 'relative',
		display: 'inline-block',
		color: theme.colors.black,
		cursor: 'pointer',
		fontSize: '24px',
		width: '30px',
		height: '30px'
	}
}));

const Close = ({ handleOnClose }) => {
	const classes = useStyles();
	const handleClose = e => {
		handleOnClose(e);
	};

	return (
		<button onClick={handleClose} className={`${classes.close} close-button`} aria-label={'Close'}>
			<i className="r180 close-alt" />
		</button>
	);
}

Close.propTypes = {
	handleOnClose: PropTypes.func.isRequired
};

export default React.memo(Close);
