export const WordCardDecoder = {
	PARTS: 'parts',
	SPELL: 'spell',
	TIPS: 'tips'
};

export const WordCardPreloaderStatus = {
	UNINITIALIZED: 'uninitialized',
	LOADING: 'loading',
	COMPLETED: 'completed'
};

export const WordCardText = {
	NORMAL: 'normal',
	SILENT: 'silent',
	SEPARATOR: 'separator',
	TIPS: 'tips',
	PARTS: 'parts',
	TIPSFOCUS: 'tipsFocus',
	TIPHIGHLIGHT: 'tipHiglight',
	TIPREPLACE: 'tipReplace'
};
