import React from 'react';
import PropTypes from 'prop-types';
import ImageWithHotSpot from '../../components/ImageWithHotSpot/ImageWithHotSpot';
import WordTitle from '../../components/WordTitle/WordTitle';
import ActivityDirections from '../../components/ActivityDirections';

const ImageHotSpot = (props) => {

	const { word,
		wordUrl,
		instructions,
		instructionsUrl,
		questionImage,
		hotspotOptions,
		interactive,
		value,
		onSelection} = props;

	return (
		<div style={{textAlign: 'center'}}>

			<WordTitle
				className="mt-2 mb-3"
				word={word}
				src={wordUrl}
			/>

			<ActivityDirections
				style={{marginBottom: '24px'}}
				text={instructions}
				src={instructionsUrl}
			/>

			<ImageWithHotSpot
				questionImage={questionImage}
				hotspotOptions={hotspotOptions}
				interactive={interactive}
				value={value}
				onSelection={onSelection}
			/>
		</div>
	);
};

ImageHotSpot.defaultProps = {
	interactive: true,
	word: '',
	wordUrl: '',
	instructions: '',
	instructionsUrl: '',
	hotspotOptions: [],
	value: []
};

ImageHotSpot.propTypes = {
	questionImage: PropTypes.string.isRequired,
	onSelection: PropTypes.func.isRequired,
	value: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	),
	hotspotOptions: PropTypes.arrayOf(
		PropTypes.shape({
			image_coord_x: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			]),
			image_coord_y: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.number
			]),
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			status: PropTypes.string
		})
	),
	word: PropTypes.string,
	wordUrl: PropTypes.string,
	instructions: PropTypes.string,
	instructionsUrl: PropTypes.string,
	interactive: PropTypes.bool
};

export default React.memo(ImageHotSpot);
