import React from 'react';
import {WordList, ActivityButton} from '@reading/common';
import useStyles from './ReadingPassageReview.style';

export default props => {
	const classes = useStyles();
	const {answers, onClick} = props;
	return (
		<>
			<div className={`${classes.feedbackCols}`}>
				<WordList
					title={'Correct on First Try'}
					isCorrect={true}
					wordList={[
						answers.reduce(
							(acc, answer) =>
								answer.attempts === 1 ? acc + 1 : acc,
							0
						)
					]}
				/>
				<WordList
					title={'Correct after First Try'}
					isCorrect={false}
					isTimedOut={true}
					wordList={[
						answers.reduce(
							(acc, answer) =>
								answer.attempts > 1 && answer.isCorrect
									? acc + 1
									: acc,
							0
						)
					]}
					spacing={true}
				/>
				<WordList
					title={'Missed'}
					isCorrect={false}
					wordList={[
						answers.reduce(
							(acc, answer) => (answer.isCorrect ? acc : acc + 1),
							0
						)
					]}
				/>
			</div>
			<div style={{clear: 'both'}}> </div>
			<div className={`${classes.goOnButton}`}>
				<ActivityButton onClick={onClick} text={'Go On'} />
			</div>
		</>
	);
};
