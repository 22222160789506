import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import SoundButton from '../../../components/SoundButton';
import QuestionMark from '../../../components/QuestionMark';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const getStyle = (style, snapshot) => {
	if (!snapshot.isDragging) return {};
	if (!snapshot.isDropAnimating) {
		return style;
	}


	return {
		...style,
		transitionDuration: `0.001s`
	};
};

const useStyles = makeStyles(theme => {
	theme.colors.writingZone= theme.colors.readingZone? theme.colors.readingZone:{};
	return {
	hoverButton: {
		"&>div": {
			width: '74px !important',
			height: '74px !important',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			border: `2px solid ${theme.colors.readingZone.primary}`,
			"& i": {
				color: theme.colors.readingZone.primary
			}
		}
	},
	feedbackCheckIcon: {
		position: 'absolute',
		top: '-15px',
		right: '-9px',
		"&>i": {
			border: '2px solid transparent',
			borderRadius: '50%',
			color: theme.colors.white,
			fontSize: '15px',
			background: 'green',
			padding: '3px 3px',

		}
	},
	feedbackCrossIcon: {
		position: 'absolute',
		top: '-15px',
		right: '-9px',
		"&>i": {
			border: '2px solid transparent',
			borderRadius: '50%',
			color: theme.colors.white,
			fontSize: '15px',
			background: 'red',
			padding: '3px 3px',

		}
	},
	hideCrossIcon: {
		"&>i": {
			display: 'none'
		}
	},

	feedbackCheckBorderColor: {
		"&>div": {
			border: `3px solid ${theme.colors.writingZone.background}`,
		}
	},
	feedbackCrossBorderColor: {
		"&>div": {
			border: `3px solid ${theme.colors.exploreZone.primary}`,

		}
	},
	hideCrossBorderColor: {
		"&>div": {
			display: 'none'

		}
	},
	soundButton: {
		color: theme.colors.grey,
		'&:hover': {
			borderColor: theme.colors.readingZone.primary,
			color: theme.colors.readingZone.primary
		}
	}
}});


const DndHolder = ({
	droppablePefix,
	draggablePrefix,
	index,
	data,
	word,
	dragEnabled,
	name,
	isDroppable,
	destAudioMap,
	updatedLeftAudio,
	submitted,
	resultSet,
	dragName,
	activitySubmittedOnce,
	reset,
	children,
	wordMapAfterSubmit
}) => {
	const classes = useStyles();

	return (
		<Droppable key={index} droppableId={`${droppablePefix}_${name}`}>
			{(provided, droppableSnapshot) => (
				<div
					{...provided.droppableProps}
					ref={provided.innerRef}
					style={{
						display: 'inline-block'
					}}
				>
					<Draggable
						draggableId={`${draggablePrefix}_${name}`}
						index={index}
						key={index}
						word={data[index].name}
						isDragDisabled={
							!dragEnabled
						}
					>
						{(provided, snapshot) => (
							<div
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								ref={provided.innerRef}
								word={data[index]}
								style={getStyle(
									provided.draggableProps.style,
									snapshot
								)}
							>
								{/* {children} */}

								{reset === "Initial" && !submitted && isDroppable === true && (destAudioMap && destAudioMap.hasOwnProperty(index)) && !(wordMapAfterSubmit && wordMapAfterSubmit[name] === true) && (
									<SoundButton
										id={`word-match-dropped${index}`}
										size={56}
										sound={updatedLeftAudio[index]}
									/>
								)}

								{reset === "Initial" && !submitted && isDroppable === true && !(destAudioMap && destAudioMap.hasOwnProperty(index)) && !(wordMapAfterSubmit && wordMapAfterSubmit[name] === true) && (
									<QuestionMark snapshot={droppableSnapshot} size={56} />
								)}

								{(submitted || (wordMapAfterSubmit && wordMapAfterSubmit[name] === true)) && (isDroppable === true) && (
									<div className={clsx({
										[classes.feedbackCheckBorderColor]: (resultSet && resultSet[name] === true && (activitySubmittedOnce && wordMapAfterSubmit[name] === true)),
										[classes.feedbackCrossBorderColor]: ((resultSet && resultSet[name] === false && submitted)),
										[classes.hideCrossBorderColor]: ((resultSet && resultSet[name] === false && (reset === true)))
									})}>
										<SoundButton
											id={`word-match-dropped${index}`}
											size={56}
											sound={updatedLeftAudio[index]}
										/>

										<span className={clsx({
											[classes.feedbackCheckIcon]: (resultSet && resultSet[name] === true && (activitySubmittedOnce && wordMapAfterSubmit[name] === true)),
											[classes.feedbackCrossIcon]: ((resultSet && resultSet[name] === false && submitted)),
											[classes.hideCrossIcon]: ((resultSet && resultSet[name] === false && (reset === true)))
										})}>
											{(resultSet && resultSet[name] === true && activitySubmittedOnce) && (<i className="r180 check" />)}
											{(resultSet && resultSet[name] === false && reset !== "Initial") && (<i className="r180 x" />)}

										</span>
									</div>
								)}
								{!submitted && reset === "Initial" && isDroppable === false && (
									<div id={index} className={snapshot.isDragging ? `${classes.hoverButton}` : ''} >
										<SoundButton
											id={`word-match-drag${index}`}
											size={56}
											sound={word}
											className={classes.soundButton}
										/>
									</div>
								)}
							</div>
						)}
					</Draggable>
					<div style={{ display: 'none' }}>{provided.placeholder}</div>
				</div>
			)
			}
		</Droppable >
	);
};
DndHolder.defaultProps = {
	dragEnabled: true
};
DndHolder.propTypes = {
	dragEnabled: PropTypes.bool
};

export default DndHolder;
