import React, {useState} from 'react';
import PropTypes from 'prop-types';
import WordTitle from '../../components/WordTitle';
import QuestionContent from './QuestionContent';
import {makeStyles} from '@material-ui/core';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';

export const useStyles = makeStyles(theme => ({
	titleWrapper: {display: 'flex', justifyContent: 'center'},
	genericTitle: {
		textAlign: 'center',
		marginTop: '32px',
		fontWeight: 'bold',
		fontSize: '18px',
		width: '70%',
		borderBottom: `1px solid ${theme.colors.paleGrey}`,
		paddingBottom: '25px',
		'& .question-title': {
			marginBottom: '24px'
		}
	}
}));

const WordFamilyLanding = props => {
	const {
		questionsList,
		currentIndex,
		updateSubmitStatus,
		isResetQuestion,
		setResetQuestion,
		setAnsweredChoice,
		setCorrectResults,
		correctResult,
		answeredChoice,
		stageDetails,
		screenViewIndex,
		commonQuestionTitle,
		isAnimPlayingScreen,
		isVoiceOverComplete,
		setForwardDisabled,
		setScreenViewIndex,
		setInstructionDisabled,
		isZoneMenuModalOpen,
		isExitModalOpen
	} = props;

	const classes = useStyles();

	const [isSpecificWordStatus, setSpecificWordStatus] = useState(false);

	const specificWordComplete = () => {
		setSpecificWordStatus(true);
	};

	return (
		<>
			<>
				{screenViewIndex !== 2 ? (
					<div className={classes.genericTitle}>
						<div className="question-title">
							{commonQuestionTitle}
						</div>
						<WordTitle
							className={classes.titleStyles}
							word={questionsList.word_card.word_text}
							commonText={true}
							staticMode={screenViewIndex === 0}
							commonTextSpeaker={screenViewIndex === 1}
							isVoiceOverComplete={isVoiceOverComplete}
							specificWordComplete={specificWordComplete}
							src={bulkReplace(
								KnowledgeForReading.WORD_FAMILY_LANDING_VO,
								{
									word: questionsList.word_card.word_text
								}
							)}
						/>
					</div>
				) : (
					<WordTitle
						className={'mt-3 mb-2'}
						word={questionsList.word_card.word_text}
						commonText={false}
						commonTextSpeaker={true}
						isVoiceOverComplete={isVoiceOverComplete}
						src={bulkReplace(
							KnowledgeForReading.WORD_FAMILY_LANDING_VO,
							{
								word: questionsList.word_card.word_text
							}
						)}
					/>
				)}
			</>
			<QuestionContent
				isResetQuestion={isResetQuestion}
				setResetQuestion={setResetQuestion}
				currentIndex={currentIndex}
				questionsList={questionsList}
				updateSubmitStatus={updateSubmitStatus}
				setCorrectResults={setCorrectResults}
				setAnsweredChoice={setAnsweredChoice}
				correctResult={correctResult}
				answeredChoice={answeredChoice}
				stageDetails={stageDetails}
				screenViewIndex={screenViewIndex}
				isAnimPlayingScreen={isAnimPlayingScreen}
				isVoiceOverComplete={isVoiceOverComplete}
				isSpecificWordStatus={isSpecificWordStatus}
				setForwardDisabled={setForwardDisabled}
				setScreenViewIndex={setScreenViewIndex}
				setInstructionDisabled={setInstructionDisabled}
				isZoneMenuModalOpen={isZoneMenuModalOpen}
				isExitModalOpen={isExitModalOpen}
			/>
		</>
	);
};
WordFamilyLanding.propTypes = {
	questionsList: PropTypes.object.isRequired,
	currentIndex: PropTypes.number.isRequired,
	screenViewIndex: PropTypes.number.isRequired,
	commonQuestionTitle: PropTypes.string.isRequired,
	isAnimPlayingScreen: PropTypes.bool,
	isVoiceOverComplete: PropTypes.bool
};

WordFamilyLanding.defaultProps = {
	questionsList: {},
	currentIndex: 0,
	screenViewIndex: 0,
	commonQuestionTitle: '',
	isAnimPlayingScreen: true,
	isVoiceOverComplete: false
};
export default React.memo(WordFamilyLanding);
