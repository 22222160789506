import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {isFirefox} from '@reading/r180/src/utils/browsers';

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		width: '256px',
		background: theme.colors.white,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px 8px 0px 0px',
		'& .icon': {
			height: 32,
			width: 32,
			border: `1px solid ${theme.colors.white}`,
			color: theme.colors.white,
			fontSize: '20px',
			borderRadius: 16,
			lineHeight: '32px',
			textAlign: 'center',
			position: 'absolute',
			top: '-16px',
			left: 'calc(50% - 16px)',
			'& .iconButton': {
				color: theme.colors.white
			}
		},
		'& .title': {
			height: '64px',
			fontFamily: theme.fonts.ui,
			fontWeight: 600,
			fontSize: '24px',
			textAlign: 'center',
			lineHeight: '70px'
		},
		'& .word-list': {
			padding: '20px 40px 40px 16px',
			overflowY: isFirefox() ? 'auto' : 'overlay',

			'&::-webkit-scrollbar': {
				width: '6px'
			},
			'&::-webkit-scrollbar-track': {
				background: 'transparent',
				padding: '10px 0'
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#CCC',
				borderRadius: '8px'
			},
			height: '400px',
			textAlign: 'left',
			'& .word': {
				fontFamily: theme.fonts.context,
				marginBottom: '16px'
			}
		},
		'&.correct': {
			borderTop: `24px solid ${theme.colors.successGreen}`,
			borderBottom: `4px solid ${theme.colors.successGreen}`,
			'& .icon': {
				background: theme.colors.successGreen
			},
			'& .title': {
				borderBottom: `2px solid ${theme.colors.successGreen}`
			}
		},
		'&.incorrect': {
			borderTop: `24px solid ${theme.colors.errorRed}`,
			borderBottom: `4px solid ${theme.colors.errorRed}`,
			'& .icon': {
				background: theme.colors.errorRed
			},
			'& .title': {
				borderBottom: `2px solid ${theme.colors.errorRed}`
			}
		},
		'&.incorrect.timedout': {
			borderTop: `24px solid ${theme.colors.skyBlue}`,
			borderBottom: `4px solid ${theme.colors.skyBlue}`,
			'& .icon': {
				background: theme.colors.skyBlue
			},
			'& .title': {
				borderBottom: `2px solid ${theme.colors.skyBlue}`
			}
		},
		'&.spacing': {
			width: '270px'
		}
	}
}));

const WordList = props => {
	const {
		id,
		wordList,
		isCorrect,
		isTimedOut,
		title,
		onHeaderIconClick,
		spacing
	} = props;
	const classes = useStyles();
	const displayTitle = title ? title : isCorrect ? 'Correct' : 'Needs Work';

	return (
		<div
			className={`word-list
					${classes.wrapper}
					${isCorrect ? 'correct' : 'incorrect'}
					${isTimedOut && 'timedout'}
					${spacing && 'spacing'}`}
			id={id}
		>
			<div className="icon">
				<button
					data-testid={id}
					onClick={onHeaderIconClick}
					className="iconButton"
				>
					<i
						className={`r180 ${
							isCorrect ? 'check' : isTimedOut ? 'slow' : 'x'
						}`}
					/>
				</button>
			</div>
			<div className="title">{displayTitle}</div>
			<div className="word-list">
				{wordList.map((word, idx) => {
					return (
						<div className="word" key={`${word}${idx}`}>
							{word}
						</div>
					);
				})}
			</div>
		</div>
	);
};

WordList.defaultProps = {
	id: '',
	wordList: [],
	isCorrect: true,
	isTimedOut: false,
	spacing: false
};
WordList.propTypes = {
	id: PropTypes.string,
	isCorrect: PropTypes.bool.isRequired,
	isTimedOut: PropTypes.bool,
	spacing: PropTypes.bool
};

export default React.memo(WordList);
