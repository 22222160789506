import axios from 'axios';
import {MEDIA_SERVER_URL} from '../utils/constants';
import {isDemoMode} from '../utils/ui';

export const mediaAxios = axios.create();
const getReal = path => mediaAxios.get(`${MEDIA_SERVER_URL}${path}`).then(response => response.data)

export default {
	getOne: path => {
		try {
			if (!isDemoMode()) {
				//If we're not in demo mode, get real media always
				return getReal(path);
			}
			let result = null;
			if (path.substr(path.length - 4) === 'html') {
				return require(`../mock/media/${path}.json`).html;
			} else {
				return require(`../mock/media/${path}`);
			}
		} catch (e) {
			//If there's no mock media found, get it for real
			return getReal(path);
		}
	},
};
