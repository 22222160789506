import React, {useState} from 'react';
import WordCardTips from '../../components/WordCard/Tips/WordCardTips';
import SoundButton from '../../components/SoundButton';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';
import {makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
	toolTip: ({screenViewIndex}) => ({
		position: 'relative',
		width: screenViewIndex === 2 ? '600px' : 'auto',
		height:
			screenViewIndex === 0 || screenViewIndex === 1 ? '50px' : '120px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}),
	toolTipStatic: {
		fontSize: '24px',
		fontFamily: theme.fonts.context,
		color: theme.colors.slate,
		marginLeft: '160px',
		width: '100%',
		'& .tip': {
			cursor: 'pointer',
			position: 'relative',
			top: '-1px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	},
	descSpeaker: {
		fontSize: '18px',
		fontFamily: theme.fonts.context,
		margin: '0 14px 0 2px',
		color: theme.colors.slate,
		'& .sound-button': {
			border: 'none',
			position: 'relative',
			top: '-3px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			}
		}
	},
	staticText: {
		color: theme.colors.black,
		fontSize: '32px'
	},
	wordAnimation: {
		display: 'inline-flex'
	}
}));

const ToolTipAnimation = props => {
	const {
		showDescription,
		item,
		choicesData,
		onAnimateEnd,
		screenViewIndex,
		setForwardDisabled
	} = props;

	const classes = useStyles({screenViewIndex});
	const {setState} = useBatchedSetState();

	const [isWordAnimation, setWordAnimation] = useState(false);

	const onToolTipClick = () => {
		setWordAnimation(true);
	};

	const onCompleteAnimation = () => {
		setState(() => {
			if (isWordAnimation) {
				setWordAnimation(false);
			}
			if (screenViewIndex === 1) {
				setForwardDisabled(false);
			}
			onAnimateEnd();
		});
	};

	return (
		<div className={classes.toolTip}>
			{!showDescription &&
				choicesData &&
				choicesData[item.answer_text] && (
					<WordCardTips
						decodingInfo={
							choicesData[item.answer_text]['decodingInfo']
						}
						onComplete={() => onCompleteAnimation()}
						isTipsHighlightWithSameColor={true}
					/>
				)}
			{showDescription && (
				<div className={classes.toolTipStatic}>
					<i
						className="r180 tip "
						data-testid="tipIcon"
						onClick={onToolTipClick}
					/>
					<span className={classes.descSpeaker}>
						<SoundButton
							className={classes.titleSoundButton}
							icon="speaker-center"
							id={`${item.answer_text}VO`}
							size={32}
							sound={{
								name: `${item.answer_text}-Sound`,
								src: bulkReplace(
									KnowledgeForReading.WORD_FAMILY_LANDING_VO,
									{word: item.answer_text}
								)
							}}
						/>
					</span>
					{isWordAnimation ? (
						<span className={classes.wordAnimation}>
							<WordCardTips
								decodingInfo={
									choicesData[item.answer_text][
										'decodingInfo'
									]
								}
								onComplete={() => onCompleteAnimation()}
							/>
						</span>
					) : (
						<span className={classes.staticText}>
							{item.answer_text}
						</span>
					)}
				</div>
			)}
		</div>
	);
};
ToolTipAnimation.prototype = {
	showDescription: PropTypes.bool,
	index: PropTypes.number,
	item: PropTypes.object,
	choicesData: PropTypes.object,
	screenViewIndex: PropTypes.number
};

ToolTipAnimation.defaultProps = {
	showDescription: false,
	index: 0,
	item: {},
	choicesData: {},
	screenViewIndex: 0
};

export default React.memo(ToolTipAnimation);
