import React from 'react';
import SpellingChallenge from '../SpellingCorrectiveFeedback';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';

const FeedBackHolder = (props) => {
	const {
		index,
		setStudyWord,
		text,
		wordsList,
		inputRef,
		showFeedback,
		updateFeedBackWord,
		showCorrectiveFeedback } = props;

	const hideCorrectFeedback = (word, typeWord, isCorrect) => {
		if (isCorrect) {
			inputRef.current.value = '';
			inputRef.current.parentElement.blur();
			AudioPlayer2.play(word);
			showFeedback(false);
			setStudyWord(true);
		} else {
			updateFeedBackWord(typeWord);
			showFeedback(true);
		}
	};

	return (
		<>
			{showCorrectiveFeedback && (
				<SpellingChallenge
					correctWord={text}
					feedbackWord={inputRef.current.value}
					decodeInfoData={wordsList[index - 1].decodingInfo}
					feedbackInstanceNo={index}
					isIncrementFeedbackInstance={false}
					onCorrectiveFeedbackKeyPress={hideCorrectFeedback}
				/>
			)}
		</>
	);
};

export default FeedBackHolder;
