import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import WordTitle from '../../components/WordTitle';
import DragAndDrop from '../../components/ClickAndStick';
import ActivityDirections from '../../components/ActivityDirections';

const useStyles = makeStyles(theme => ({
	dragAndDropContainer: {
		position: 'relative',
		minHeight: '620px'
	},
	header: {
		fontSize: '18px',
		'& i': {
			display: 'none'
		}
	},
}));
export const handleDesign = count => {
	let styleArray = [];
	let droppableStyleArray = [];
	const styleSource = {
		background: '#FBFCFD',
		width: 219,
		height: 75,
	};
	const styleDestination = {
		background: '#FBFCFD',
		width: 426,
		height: 256,
		boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.3)'
	};
	const topArray = ['100px', '156px', '212px'];

	const droppableDestinationStyleFirst = {
		position: 'absolute',
		minHeight: '300px',
		minWidth: '300px',
		marginRight: '450px'
	};
	const droppableDestinationStyleSecond = {
		position: 'absolute',
		minHeight: '300px',
		minWidth: '300px',
		marginLeft: '450px'
	};
	if (count >= 2) {
		for (let i = 0; i < count; i++) {
			styleArray.push(styleSource);
			const droppableSourceStyle = {
				minHeight: '40px',
				minWidth: '270px',
				display: 'flex',
				alignItems: 'center'
			};
			if (i <= 1 && i <= 2) {
				droppableDestinationStyleFirst.top = topArray[0];
				droppableDestinationStyleSecond.top = topArray[0];
			} else if (i >= 3 && i <= 5) {
				droppableDestinationStyleFirst.top = topArray[1];
				droppableDestinationStyleSecond.top = topArray[1];
			}
			droppableStyleArray.push(droppableSourceStyle);
		}
		styleArray.push(styleDestination, styleDestination);
		droppableStyleArray.push(
			droppableDestinationStyleFirst,
			droppableDestinationStyleSecond
		);
	}
	return { styleArray, droppableStyleArray };
};

export default function SynonymsAndAntonymsBody(props) {
	const {
		word,
		wordUrl,
		onDragDropCallback,
		bucketInfoList,
		bucketItemListData,
		interactive,
		instructions,
		instructionsUrl
	} = props;
	let count = bucketInfoList.length - 2;
	const { styleArray, droppableStyleArray } = handleDesign(count);

	const classes = useStyles();

	const handleDragDrop = data => {
		if (typeof onDragDropCallback == 'function') {
			onDragDropCallback(data);
		}
	};

	return (
		<div style={{ textAlign: 'center' }}>
			<ActivityDirections
				className={`mt-3 mb-1 ${classes.header}`}
				text={instructions}
				src={instructionsUrl}
			/>
			<WordTitle className="mt-2 mb-2" word={word} src={wordUrl} />

			<div className={classes.dragAndDropContainer}>
				<DragAndDrop
					onDragDrop={state => {
						handleDragDrop(state);
					}}
					bucketInfoList={bucketInfoList}
					bucketItemList={bucketItemListData}
					bucketStyleList={styleArray}
					droppableStyleList={droppableStyleArray}
					disableHelperBar={false}
					interactive={interactive}
				/>
			</div>
		</div>
	);
}

SynonymsAndAntonymsBody.propTypes = {
	bucketInfoList: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			menuText: PropTypes.string,
			status: PropTypes.string
		})
	),
	bucketItemListData: PropTypes.array,
	word: PropTypes.string,
	wordUrl: PropTypes.string,
	instructions: PropTypes.string,
	instructionsUrl: PropTypes.string,
	interactive: PropTypes.bool
};

SynonymsAndAntonymsBody.defaultProps = {
	word: '',
	wordUrl: '',
	instructions: '',
	instructionsUrl: '',
	bucketInfoList: [],
	bucketItemListData: [],
	interactive: true
};
