import {getAudioExt} from '../../../utils/audio';
import { MEDIA_SERVER_URL } from '../../../utils/constants';

const EXT = getAudioExt();

export const ReadAndThinkConstants = {
	QUESTION_TIME_LIMIT: 1000 * 10,

	COVER_UP: `${MEDIA_SERVER_URL}assets/fs/audio/fx/fs_fx_screen_coverup_on.${EXT}`,
	COVER_OFF: `${MEDIA_SERVER_URL}assets/fs/audio/fx/fs_fx_screen_coverup_off.${EXT}`,

	QUESTION: `${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_{questionCode}_{num}.${EXT}`,

	MISSED: [
		`${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_0missed_1_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_0missed_1_hb.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_0missed_1_hc.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_0missed_2_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_0missed_2_hb.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_0missed_2_hc.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_0missed_3_ha.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_0missed_3_hb.${EXT}`,
		`${MEDIA_SERVER_URL}assets/fs/activi/flrt/fs_flrt_0missed_3_hc.${EXT}`
	]
};
