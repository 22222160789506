export const keyAndClick = (handler)=>{
	return {
		onClick:handler,
		onKeyPress:(event)=>{
			if(event.key === 'Enter' || event.key=== ' ' || event.key === 'Spacebar'){
				event.preventDefault();
				handler(event);
			}
		}
	}
};
