import {MEDIA_SERVER_URL} from '../../utils/constants';
import {bulkReplace} from '../../utils/stringUtils';
import {getAudioExt} from '../../utils/audio';
import {isEmpty} from 'lodash';
import api from '../../api/api';
import { completeActivityAndGoToResultsPage } from '../../utils/navigation';
import { loadSleep } from '../../utils/ui';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';

const EXT = getAudioExt();

export const SelfCheck = {
	PAGEDETAILS: {
		testMicPage: 'testMicPage',
		beginActivityPage: 'beginActivityPage',
		progressPage: 'progressPage',
		resultPage: 'resultPage'
	},

	ACTIVITY_WORD_URL: `${MEDIA_SERVER_URL}/assets/dictio/word/r180u_word_{activityWord}.${EXT}`,
	INTRO_VO: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_intro.${EXT}`,
	TRIAL_HELP: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_trialhelp.${EXT}`,
	TEST_MIC_PAGE: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_intro.${EXT}`,
	BEGIN_ACTIVITY_PAGE: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_trialhelp.${EXT}`,
	PROGRESS_ACTIVITY_PAGE: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_intro.${EXT}`,

	FEEDBACK_INAUDIBLE: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_feedback_inaudible.${EXT}`,
	ACTIVITY_PAUSE: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_pause.${EXT}`,
	ACTIVITY_CONTINUE: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_continue.${EXT}`,
	ACTIVITY_NEXT: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_next.${EXT}`,
	ACTIVITY_FEEDBACK: `${MEDIA_SERVER_URL}/assets/activi/selfcheck/r180u_selfcheck_interroundhelp.${EXT}`,

	IS_ALL_CORRECT_LENGTH: 3,

	init: async() => {
		const start = new Date().getTime();

		AudioPlayer2.loadSound(SelfCheck.INTRO_VO);

		const end = new Date().getTime();
		await loadSleep(end - start);
	},

	initData: (activityStudyDetails) => {

		const updatedWordList = activityStudyDetails.fluencyScores.map(word => ({
			...word,
			word_url: bulkReplace(SelfCheck.ACTIVITY_WORD_URL, {
				activityWord: word.wordId
			})
		}));

		return updatedWordList;
	},

	// mark the item as complete, but don't navigate away from page
	completeActivityNoTransition: (activityData) => {
		completeActivityAndGoToResultsPage({
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			}
		});
	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendProgressToServer: (activityData) => {
		if (isEmpty(activityData)) {
			return;
		}

		let savedActivityData = {
			activity: {
				studentActivityId: activityData.studentActivityId,
				isComplete: false
			}
		};

		api.activity.updateActivity(savedActivityData);
	}
};
