import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import WordTitle from '../../components/WordTitle';
import DragAndDrop from '../../components/ClickAndStick';
import ActivityDirections from '../../components/ActivityDirections';

const useStyles = makeStyles(theme => ({
	dragAndDropContainer: {
		position: 'relative',
		minHeight: '600px'
	}
}));

export default function ImageDragDrop(props) {
	const {
		word,
		wordUrl,
		instructions,
		instructionsUrl,
		onDragDropCallback,
		bucketInfoList,
		bucketItemListData,
		dataTypes,
		interactive,
	} = props;


	const styleSource = {
		background: '#FBFCFD',

	};
	const styleDestination = {
		background: '#FBFCFD',

		boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.3)'
	};

	const textTopArray = ['95px', '148px'];

	const droppableDestinationStyleFirst = {
		position: 'absolute',
		minHeight: '300px',
		minWidth: '300px'
	};
	const droppableDestinationStyleSecond = {
		position: 'absolute',
		minHeight: '300px',
		minWidth: '300px'
	};

	let styleArray = [];
	let droppableStyleArray = [];
	let count = bucketInfoList.length - 2;
	if (count >= 2) {
		for (let i = 0; i < count; i++) {
			const droppableSourceStyle = {
				display: 'flex',
				alignItems: 'center'
			};
			if (
				dataTypes.type === 'SORTING' &&
				dataTypes.answer_type === 'IMAGE'
			) {
				styleSource.marginRight = 15;
				styleSource.height = 117;
				styleSource.width = 158;
				styleDestination.width = 310;
				styleDestination.height = 235;
				droppableDestinationStyleFirst.marginRight = 352;
				droppableDestinationStyleSecond.marginLeft = 325;
				droppableDestinationStyleFirst.top = 126;
				droppableDestinationStyleSecond.top = 126;

			} else if (
				dataTypes.type === 'SORTING' &&
				dataTypes.answer_type === 'TEXT'
			) {
				styleSource.height = 75;
				styleSource.width = 219;
				styleDestination.width = 426;
				styleDestination.height = 240;
				droppableDestinationStyleFirst.marginRight = 450;
				droppableDestinationStyleSecond.marginLeft = 450;

				if (i <= 1 && i <= 2) {
					droppableDestinationStyleFirst.top = textTopArray[0];
					droppableDestinationStyleSecond.top = textTopArray[0];
				} else if (i >= 3 && i <= 5) {
					droppableDestinationStyleFirst.top = textTopArray[1];
					droppableDestinationStyleSecond.top = textTopArray[1];
				}
			}
			styleArray.push(styleSource);
			droppableStyleArray.push(droppableSourceStyle);
		}
		styleArray.push(styleDestination, styleDestination);
		droppableStyleArray.push(
			droppableDestinationStyleFirst,
			droppableDestinationStyleSecond
		);
	}

	const classes = useStyles();

	const handleDragDrop = data => {
		if (typeof onDragDropCallback === 'function') {
			onDragDropCallback(data);
		}
	};

	return (
		<div style={{ textAlign: 'center' }}>
			<WordTitle className="mt-1 mb-1" word={word} src={wordUrl} />

			<ActivityDirections
				className="mb-1"
				text={instructions}
				src={instructionsUrl}
			/>

			<div className={classes.dragAndDropContainer}>
				<DragAndDrop
					onDragDrop={state => {
						handleDragDrop(state);
					}}
					bucketInfoList={bucketInfoList}
					bucketItemList={bucketItemListData}
					bucketStyleList={styleArray}
					droppableStyleList={droppableStyleArray}
					disableHelperBar={false}
					interactive={interactive}
					dragDisableId="source"
				/>
			</div>
		</div>
	);
}

ImageDragDrop.propTypes = {
	bucketInfoList: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string,
			menuText: PropTypes.string,
			status: PropTypes.string
		})
	),
	bucketItemListData: PropTypes.array,
	word: PropTypes.string,
	wordUrl: PropTypes.string,
	instructions: PropTypes.string,
	instructionsUrl: PropTypes.string,
	dataTypes: PropTypes.object,
	interactive: PropTypes.bool
};

ImageDragDrop.defaultProps = {
	word: '',
	wordUrl: '',
	instructions: '',
	instructionsUrl: '',
	bucketInfoList: [],
	bucketItemListData: [],
	dataTypes: {},
	interactive: true
};
