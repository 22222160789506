import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useUnmount } from 'react-use';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		height: '72px',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'stretch',
		background: theme.colors.white,
		borderBottom: `2px solid ${theme.colors.lightGrey}`,
		'& .panel': {
			textAlign: 'center',
			position: 'relative',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: '16px',
			fontWeight: '400',
			'&.left-panel': {
				width: '28%'
			},
			'&.center-panel': {
				width: '35%'
			},
			'&.right-panel': {
				width: '37%'
			}
		},
		'& .play-button-wrapper': {
			width: '104px',
			height: '40px',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			marginLeft: '42px'
		},
		'& .play-button': {
			background: theme.colors.white,
			color: theme.colors.readingZone.background,
			border: `2px solid ${theme.colors.readingZone.blue5}`,
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			height: '32px',
			width: '32px',
			borderRadius: '16px',
			marginRight: '12px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		'& .instructions': {
			position: 'absolute',
			top: '1px',
			left: '1px',
			color: theme.colors.softBlack,
			fontSize: '24px',
			marginLeft: '2px',
			marginTop: '3px',
			height: '28px',
			width: '24px'
		},
		'& .hiddenSkipLink': {
			width: '0px',
			height: '0px',
			fontSize: '0px'
		},
		'& .toggle-text': {
			display: 'inline-block',
			padding: '3px 5px',
			color: theme.colors.readingZone.background,
			fontFamily: theme.fonts.button,
			marginRight: '8px',
			borderRadius: '4px',
			'&:last-of-type': {
				marginRight: '0'
			},
			'&.selected': {
				background: theme.colors.readingZone.background,
				color: theme.colors.white,
				fontWeight: '700'
			}
		},
		'& .speed-button': {
			color: theme.colors.softBlack,
			fontSize: '16px',
			'& i': {
				display: 'block',
				color: theme.colors.readingZone.background,
				fontSize: '24px',
				marginBottom: '2px'
			},
			'&:disabled': {
				color: theme.colors.softGrey,
				cursor: 'not-allowed',
				'& i': {
					color: theme.colors.softGrey
				}
			}
		},
		'& .speed-row': {
			display: 'flex',
			justifyContent: 'center',
			marginRight: '6px',
			marginLeft: '6px',
			marginBottom: '16px',
			position: 'relative',
			'& .speed-bar': {
				position: 'absolute',
				left: '0',
				height: '8px',
				width: '100%',
				background: theme.colors.readingZone.blue5,
				borderRadius: '8px'
			}
		},
		'& .speed-dot': {
			position: 'relative',
			height: '8px',
			width: '8px',
			borderRadius: '4px',
			background: theme.colors.readingZone.blue4,
			marginRight: '8px',
			transition: 'all 0.2s',
			'&:last-of-type': {
				marginRight: '0'
			},
			'&.selected': {
				background: theme.colors.white,
				border: `1px solid ${theme.colors.readingZone.blue1}`,
				boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)'
			}
		}
	},
	buttonLabel: { cursor: 'pointer' }
}));

const ReadAloudControlBar = props => {
	const {
		id,
		className,
		onPlay,
		onPause,
		onReadTypeChange,
		onInstructions,
		onSpeedChange,
		selectedTabIndex,
		setReadMode,
		readAloudComplete,
		setReadAloudComplete
	} = props;
	const classes = useStyles();

	const [isPlaying, setPlaying] = useState(false);
	const [selectedRead, setSelectedRead] = useState('word');
	const [speed, setSpeed] = useState(3);

	const handlePlay = e => {
		setReadAloudComplete(false);
		e.preventDefault();
		if (isPlaying) {
			setPlaying(false);
			onPause();
		} else {
			setPlaying(true);
			onPlay();
		}
	};
	useEffect(() => {
		if (selectedTabIndex.length > 0) {
			setPlaying(false);
		}
	}, [selectedTabIndex]);

	useEffect(() => {
		if (readAloudComplete) {
			/* To enable Play Button after reading is complete */
			setPlaying(false);
			onPause();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readAloudComplete]);

	const handleReadTypeClick = (e, type) => {
		e.preventDefault();
		setSelectedRead(type);

		onReadTypeChange(type);
	};

	/* istanbul ignore next */
	useUnmount(() => {
		/* Reset the Read Mode Switch to word on tab switch */
		setReadMode('word');
	});

	const handleSpeedChange = (e, speed) => {
		e.preventDefault();
		setSpeed(speed);
		onSpeedChange(speed);
	};

	const handleFaster = e => {
		e.preventDefault();
		const newSpeed = Math.min(5, speed + 1);
		setSpeed(newSpeed);
		onSpeedChange(newSpeed);
	};

	const handleSlower = e => {
		e.preventDefault();
		const newSpeed = Math.max(1, speed - 1);
		setSpeed(newSpeed);
		onSpeedChange(newSpeed);
	};

	return (
		<div className={`${classes.wrapper} ${className}`} id={id}>
			<div
				className={`panel left-panel`}
				role={'region'}
				aria-label={'Reading playback'}
			>
				<button
					type="button"
					className="instructions"
					aria-label={'instructions'}
					data-testid={'readAloudInstructions'}
					id="instructionsButton"
					onClick={onInstructions}
				>
					<i className="r180 instructions" />
				</button>
				<a
					className={`hiddenSkipLink`}
					href={document.location.href.split('#')[0] + '#tabs'}
				>
					Skip to main content
				</a>
				<div className="play-button-wrapper">
					<button
						type="button"
						className={`play-button`}
						aria-label={`${isPlaying ? 'pause' : 'play'}`}
						onClick={handlePlay}
					>
						<i className={`r180 ${isPlaying ? 'pause' : 'play'}`} />
					</button>
					<span onClick={handlePlay} className={classes.buttonLabel}>
						{isPlaying ? 'Pause' : 'Play '}
					</span>
				</div>
			</div>
			<div
				className={`panel center-panel`}
				role={'region'}
				aria-label={'Reading mode'}
			>
				<button
					type="button"
					className={`toggle-text ${selectedRead === 'word' ? 'selected' : ''
						}`}
					onClick={e => handleReadTypeClick(e, 'word')}
				>
					Word
				</button>
				<button
					type="button"
					className={`toggle-text ${selectedRead === 'phrase' ? 'selected' : ''
						}`}
					onClick={e => handleReadTypeClick(e, 'phrase')}
				>
					Phrase
				</button>
			</div>
			<div
				className={`panel right-panel`}
				aria-label={'Reading speed'}
				role={'region'}
			>
				<button
					type="button"
					className={`speed-button`}
					onClick={handleSlower}
				>
					<i className="r180 slower" />
					Slower
				</button>
				<div className="speed-row">
					<div className="speed-bar"></div>
					<button
						type="button"
						className={`speed-dot ${speed === 1 ? 'selected' : ''}`}
						onClick={e => handleSpeedChange(e, 1)}
						aria-label={`slowest ${speed === 1 ? '(selected)' : ''
							}`}
					></button>
					<button
						type="button"
						className={`speed-dot ${speed === 2 ? 'selected' : ''}`}
						onClick={e => handleSpeedChange(e, 2)}
						aria-label={`slow ${speed === 2 ? '(selected)' : ''}`}
					></button>
					<button
						type="button"
						className={`speed-dot ${speed === 3 ? 'selected' : ''}`}
						onClick={e => handleSpeedChange(e, 3)}
						aria-label={`medium ${speed === 3 ? '(selected)' : ''}`}
					></button>
					<button
						type="button"
						className={`speed-dot ${speed === 4 ? 'selected' : ''}`}
						onClick={e => handleSpeedChange(e, 4)}
						aria-label={`fast ${speed === 4 ? '(selected)' : ''}`}
					></button>
					<button
						type="button"
						className={`speed-dot ${speed === 5 ? 'selected' : ''}`}
						onClick={e => handleSpeedChange(e, 5)}
						aria-label={`fastest ${speed === 5 ? '(selected)' : ''
							}`}
					></button>
				</div>
				<button
					type="button"
					className={`speed-button`}
					onClick={handleFaster}
				>
					<i className="r180 faster" />
					Faster
				</button>
			</div>
		</div>
	);
};

ReadAloudControlBar.defaultProps = {
	id: '',
	className: '',
	setReadMode: () => { },
	selectedTabIndex: '',
	setReadAloudComplete: () => { }
};
ReadAloudControlBar.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	onPlay: PropTypes.func.isRequired,
	onPause: PropTypes.func.isRequired,
	onReadTypeChange: PropTypes.func.isRequired,
	onInstructions: PropTypes.func.isRequired,
	onSpeedChange: PropTypes.func.isRequired,
	setReadMode: PropTypes.func,
	selectedTabIndex: PropTypes.string,
	readAloudComplete: PropTypes.bool
};

export default React.memo(ReadAloudControlBar);
