import {React} from 'react';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import media from '@reading/r180/src/api/media';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';

export const questionSet = questionData =>
	questionData.word_card.word_card_activities.filter(
		i => i.word_card_activity.question_excerpt.length > 0
	);

export const getCurrentQuestionTitle = (
	currentIndex,
	questionData,
	selectedText
) => {
	const validQuestionSet = questionSet(questionData);

	let questionString =
		validQuestionSet[currentIndex].word_card_activity.question_excerpt;

	questionString = questionString.replace(
		/<div.*>.*?<\/div>/gi,
		`<div class='omission' data-number='1'>${selectedText}</div>`
	);

	return questionString;
};

export const getCurentQuestionChice = (currentIndex, questionData) => {
	const validQuestionSet = questionSet(questionData);
	return validQuestionSet[currentIndex].word_card_activity.answers;
};

export const getChoiceSequence = choiceList =>
	choiceList.map(i => i.answer_text);

export const getAllQuestions = questionData => {
	const validQuestionSet = questionSet(questionData);
	return validQuestionSet.map(
		item => item.word_card_activity.question_excerpt
	);
};

export const getResultsQuestion = (questionText, selectedText, isCorrect) => {
	const choiceClass = isCorrect ? 'correct' : 'incorrect';
	let questionString = questionText.replace(
		/<div.*>.*?<\/div>/gi,
		`<div class='omission ${choiceClass}' data-number='1'>${selectedText}</div>`
	);

	return questionString;
};

export const playQuestionVO = (
	selectedText,
	questionsList,
	currentIndex,
	stageDetails
) => {
	const {segment, stage} = stageDetails;

	const validQuestionSet = questionSet(questionsList);
	const questionID = validQuestionSet[currentIndex].word_card_activity.id;
	const choiceID = validQuestionSet[
		currentIndex
	].word_card_activity.answers.filter(i => i.answer_text === selectedText)[0]
		.id;
	const voiceOver = bulkReplace(KnowledgeForReading.WORD_FAMILY_QUESTION_VO, {
		stage,
		segment: segment.toString().padStart(2, '0'),
		questionID,
		choiceID
	});
	return {voiceOver, questionID, choiceID};
};

export const playResultVO = (
	totalQuestions,
	totalCorrect,
	audioConfig,
	setIntroVO
) => {
	if (totalQuestions === totalCorrect) {
		AudioPlayer2.load({
			src: audioConfig.correctTrailVO,
			name: audioConfig.correctTrailName
		});
		setIntroVO(audioConfig.correctTrailVO);
		AudioPlayer2.play(audioConfig.correctTrailName);
	} else {
		AudioPlayer2.load({
			src: audioConfig.inCorrectTrailVO,
			name: audioConfig.inCorrectTrailName
		});
		setIntroVO(audioConfig.inCorrectTrailVO);

		AudioPlayer2.play(audioConfig.inCorrectTrailName);
	}
};

export const loadData = async (choiceList, setChoiceData) => {
	let decodingData = {};
	choiceList.map(async wordCard => {
		const newWrd = bulkReplace(KnowledgeForReading.WORD_CARD_URL, {
			word: wordCard.answer_text
		});
		const wordContent = await media.getOne(newWrd);
		decodingData[wordCard.answer_text] = wordContent;
		setChoiceData(decodingData);
	});
};
