import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import SoundButton from '../SoundButton';
import { isFirefox } from '@reading/r180/src/utils/browsers';

const useStyles = makeStyles(theme => ({
	container: {
		position: 'relative',
		width: '100%',
		height: '56px',
		background: theme.colors.writingZone.green5,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px 8px 0 0',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: '16px',
		paddingRight: '40px',

		'& button': {
			fontFamily: theme.fonts.button,
			fontSize: '16px',
			fontWeight: '400',
			color: theme.colors.softBlack,
			padding: '6px 16px',
			transition: 'transform 0.3s ease',
			'&:not(.instruction-button)': {
				height: '100%'
			},
			'&.instruction-button': {
				position: 'relative',
				top: '1px',
				marginRight: '6px',
				paddingLeft: '4px',
				paddingRight: '4px',
				borderRight: `1px solid ${theme.colors.slate}`,
				'& i': {
					fontSize: '24px'
				}
			},
			'&:hover:not(.instruction-button)': {
				color: theme.colors.black,
				fontWeight: isFirefox() ? '400' : '700',
				background: theme.colors.writingZone.green4
			},
			'&:focus:not(.instruction-button)': {
				color: theme.colors.black,
				fontWeight: isFirefox() ? '400' : '700',
				background: theme.colors.writingZone.green4
			},
			'&.instruction-button:hover': {
				'& i': {
					textShadow: `0px 0px 5px ${theme.colors.writingZone.green4}`
				}
			},
			'&.instruction-button:focus': {
				'& i': {
					color: theme.colors.black,
				}
			},
			'& i': {
				position: 'relative',
				top: '1px',
				fontSize: '16px',
				marginRight: '9px'
			}
		},
		'& .MuiListItem-button': {
			'& i': {
				position: 'relative',
				top: '4px'
			}
		},
		'&.narrow': {
			'& button:not(.instruction-button)': {
				textAlign: 'center',
				position: 'relative',
				top: '0px',
				padding: '6px 12px',
				'& i': {
					display: 'block',
					margin: 'auto',
					top: '0',
					marginBottom: '2px',
					marginTop: '3px'
				}
			}
		}
	},
	menuItem: {
		background: 'transparent',
		minWidth: '240px',
		'& .menu-item-text': {
			position: 'relative',
			top: isFirefox() ? '-1.5px' : '-1px',
			marginLeft: '8px'
		},
		'&:hover': {
			background: theme.colors.readingZone.blue5,
			'& .menu-item-text': {
				fontWeight: '600'
			}
		},
		'& .MuiTouchRipple-root': {
			display: 'none'
		}
	}
}));

const WritingSupportTray = (props) => {
	const {
		id,
		className,
		onInstructionClick,
		onPassageClick,
		onScratchPadClick,
		onPowerWordsClick,
		onVideoClick,
		powerWords,
		narrow
	} = props;

	const classes = useStyles();

	const [anchorButton, setAnchorButton] = useState(null);

	const handleInstructionClick = e => {
		e.preventDefault();
		onInstructionClick();
	};

	const handlePassageClick = e => {
		e.preventDefault();
		onPassageClick();
	};

	const handleScratchPadClick = e => {
		e.preventDefault();
		onScratchPadClick();
	};

	const handlePowerWordsClick = e => {
		e.preventDefault();
		setAnchorButton(e.currentTarget);
	};

	const handleVideoClick = e => {
		e.preventDefault();
		onVideoClick();
	};

	const handleMenuClose = (word) => {
		setAnchorButton(null);
		onPowerWordsClick(word);
	};

	return (
		<div id={id} className={`${classes.container} ${narrow ? 'narrow' : ''} ${className} writing-support-tray`}>
			<button
				id="instruction-button"
				className="instruction-button"
				onClick={handleInstructionClick}
			>
				<i className="r180 instructions" />
			</button>

			<button
				className="passage-button"
				id="passage-button"
				onClick={handlePassageClick}
			>
				<i className="r180 passage" />
				Passage
			</button>

			<button
				className="scratch-bad-button"
				id="scratch-pad-button"
				onClick={handleScratchPadClick}
			>
				<i className="r180 scratch-pad" />
				Scratch Pad
			</button>

			<button
				className="power-words-button"
				id="power-words-button"
				onClick={handlePowerWordsClick}
				aria-controls="power-word-menu"
				aria-haspopup="true"
			>
				<i className="r180 power-words" />
				Power Words
			</button>
			{powerWords.length > 0 && (
				<Menu
					id="power-word-menu"
					getContentAnchorEl={null}
					anchorEl={anchorButton}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
					keepMounted={true}
					open={Boolean(anchorButton)}
					onClose={() => handleMenuClose(null)}
				>
					{powerWords.map(powerword => {
						return (
							<MenuItem
								classes={{root: classes.menuItem}}
								onClick={() => handleMenuClose(powerword.word)}
								key={powerword.word}
							>
								<SoundButton
									size={32}
									border={false}
									sound={{
										name: powerword.sound_name,
										src: powerword.sound_src
									}}
									style={{background: 'transparent'}}
								/>
								<span className="menu-item-text">
									{powerword.word}
								</span>
							</MenuItem>
						);
					})}
				</Menu>
			)}

			<button
				className="anchor-video-button"
				id="anchor-video-button"
				onClick={handleVideoClick}
			>
				<i className="r180 anchor-video" />
				Video
			</button>
		</div>
	);
}

WritingSupportTray.defaultProps = {
	id: '',
	className: '',
	narrow: false
};
WritingSupportTray.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	narrow: PropTypes.bool,
	onInstructionClick: PropTypes.func.isRequired,
	onPassageClick: PropTypes.func.isRequired,
	onScratchPadClick: PropTypes.func.isRequired,
	onPowerWordsClick: PropTypes.func.isRequired,
	onVideoClick: PropTypes.func.isRequired,
	powerWords: PropTypes.arrayOf(PropTypes.shape({
		word: PropTypes.string.isRequired,
		sound_name: PropTypes.string.isRequired,
		sound_src: PropTypes.string.isRequired
	})).isRequired
};

export default React.memo(WritingSupportTray);
