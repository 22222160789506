import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal} from '@material-ui/core';
import Close from '../Close';
import PropTypes from 'prop-types';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';

export const useStyles = makeStyles(theme => ({
	contentWrapper: {
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
		position: 'absolute',
		backgroundColor: theme.colors.white,
		width: `calc(${theme.activityFrameWidth}-20px)`,
		maxWidth: `calc(${theme.activityFrameMaxWidth}-20px)`,
		minWidth: '926px',
		height: '580px',
		padding: '0'
	},
	content: {
		display: 'flex',
		flexDirection: 'column'
	},
	closeIcon: {
		position: 'absolute',
		right: '16px',
		top: '16px',
		zIndex: '999'
	}
}));

const FoundationalIntroModal = props => {
	const {id, className, children, isOpen, onClose} = props;
	const classes = useStyles();

	const handleCloseButton = () => {
		AudioPlayer2.stopAll();
		if (typeof onClose === 'function') {
			onClose();
		}
	}

	return (
		<>
			<Modal
				aria-modal
				data-testid={'introModal'}
				disableBackdropClick //prevent closing of modal on clicking outside
				disableEscapeKeyDown //prevent closing of modal on pressing ESC key
				id={id}
				open={isOpen}
				onClose={onClose}
				style={{zIndex: '99999'}}
				className={`${className} r180-modal`}
			>
				<div className={`${className} ${classes.contentWrapper} modal-window`}>
					<div className={classes.closeIcon}>
						<Close handleOnClose={handleCloseButton} />
					</div>
					<div className={classes.content}>
						{children}
					</div>
				</div>
			</Modal>
		</>
	);
};

FoundationalIntroModal.defaultProps = {
	id: '',
	className: '',
	isOpen: false
};
FoundationalIntroModal.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func
};

export default React.memo(FoundationalIntroModal);
