import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SpellingInput from './SpellingClinicInput';
import SpellingClinicItem from './SpellingClinicItem';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
	wordCardContainer: {
		position: 'relative',
		bottom: '-211px'
	},
	studyWord: {
		fontSize: '32px',
		fontFamily: theme.fonts.context,
		position: 'relative',
		top: '-50px'
	},
	userInput: {
		position: 'relative',
		textAlign: 'center'
	}
}));

export const InputMarkup = props => {
	const {
		showStudyWord,
		index,
		setGoOnDisabled,
		setStudyWord,
		setFinalReport,
		finalReport,
		updateReportBoard,
		handleForward,
		text,
		configData,
		classes,
		wordsList,
		showReportBoard
	} = props;

	return (
		<>
			<div
				className={classes.studyWord}
				dangerouslySetInnerHTML={{
					__html: showStudyWord ? text : '&nbsp;'
				}}
			></div>
			{!showReportBoard && (
				<SpellingInput
					text={text}
					index={index}
					simpleInput={true}
					setStudyWord={setStudyWord}
					setGoOnDisabled={setGoOnDisabled}
					handleForward={handleForward}
					updateReportBoard={updateReportBoard}
					setFinalReport={setFinalReport}
					finalReport={finalReport}
					wordsList={wordsList}
				/>
			)}
			{showReportBoard && (
				<SpellingClinicItem
					wordsList={wordsList}
					configData={configData}
					staticMode={true}
					activeIndex={index + 1}
					finalReport={finalReport}
					showIcon={true}
				/>
			)}
		</>
	);
};

const InputContainer = (props) => {
	const {
		showWordCard,
		text,
		index,
		setGoOnDisabled,
		handleForward,
		wordsList,
		configData,
		setFinalReport,
		finalReport
	 } = props;

	const [showStudyWord, setStudyWord] = useState(true);
	const [showReportBoard, setReportBoard] = useState(false);
	const classes = useStyles();

	const updateReportBoard = showReport => setReportBoard(showReport);

	return (
		<div
			className={showWordCard ? classes.wordCardContainer : classes.userInput}
		>
			<InputMarkup
				showStudyWord={showStudyWord}
				index={index}
				setGoOnDisabled={setGoOnDisabled}
				setStudyWord={setStudyWord}
				setFinalReport={setFinalReport}
				finalReport={finalReport}
				updateReportBoard={updateReportBoard}
				handleForward={handleForward}
				text={text}
				classes={classes}
				configData={configData}
				wordsList={wordsList}
				showReportBoard={showReportBoard}
			/>
		</div>
	);
};

InputContainer.defultProps = {
	finalReport: [true, true, false]
};

InputContainer.propTypes = {
	finalReport: PropTypes.array
};

export default React.memo(InputContainer);
