import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import SoundButton from '../../components/SoundButton';
import WordTile from '../../components/WordTile';
import {loadData} from './WordFamilyUtils';
import ToolTipModal from './ToolTipModal';
import {WordCardProvider} from '../../components/WordCard/WordCardContext';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import {bulkReplace} from '@reading/r180/src/utils/stringUtils';
import WordAnimationHolder from './WordAnimationHolder';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {isFirefox} from '@reading/r180/src/utils/browsers';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

export const useStyles = makeStyles(theme => ({
	choiceWrapper: {
		display: 'flex',
		flexDirection: 'column',
		fontFamily: theme.fonts.context,
		fontSize: '24px',
		marginTop: '25px',
		color: theme.colors.black
	},
	choiceBox: {
		width: 'auto',
		minWidth: '150px',
		height: '56px',
		marginBottom: '32px',
		marginLeft: '12px',
		'&:hover': {
			fontWeight: 600
		}
	},
	selectedChoice: {
		background: theme.colors.readingZone.blue5,
		border: `1px solid ${theme.colors.readingZone.primary}`
	},
	buttons: {
		color: theme.colors.slate,
		height: '32px',
		position: 'relative',
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.2)'
		},
		'&.tip-button': {
			top: isFirefox() ? '7.5px' : '6px',
			'& .tip': {
				fontSize: '20px'
			}
		}
	},
	titleSoundButton: {
		color: theme.colors.slate,
		border: '0px',
		position: 'relative',
		top: '12px',
		transition: 'all 0.3s ease'
	},
	iconWrapper: {
		display: 'flex',
		'& .sound-button': {
			top: isFirefox() ? '8px' : '7px'
		}
	},
	subWrapper: {
		margin: '10px',
		display: 'flex',
		justifyContent: 'center'
	},
	questionMainWrapper: {
		marginTop: '32px'
	}
}));
const Choices = props => {
	const {
		choiceList,
		onChoiceSelect,
		choiceIndex,
		screenViewIndex,
		isAnimPlayingScreen,
		isVoiceOverComplete,
		isSpecificWordStatus,
		setForwardDisabled,
		setScreenViewIndex,
		setInstructionDisabled,
		answeredChoice,
		currentIndex,
		isZoneMenuModalOpen,
		isExitModalOpen
	} = props;

	const classes = useStyles();
	const {setState} = useBatchedSetState();

	const [showModal, setShowModal] = useState([]);
	const [showDescription, setDescriptionStatus] = useState(false);
	const [choicesData, setChoiceData] = useState({});
	const [animIndex, setAnimIndex] = useState(0);
	const [highlightedWordList, setHighlightedWordList] = useState([]);
	const [exitModalStatus, setExitModalStatus] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(false);
	const audioConfig = {
		wordAudio: KnowledgeForReading.WORD_FAMILY_LANDING_VO,
		wordVO: 'wordVO'
	};

	const initialStateData = {
		text: '',
		instanceNumber: 0,
		decodingInfo: {
			wordText: '',
			segments: '',
			markup: '',
			parts: [
				{
					index: 0,
					text: '',
					mediaName: ''
				}
			]
		},
		contextualData: [
			{
				segmentId: '',
				contextualSentence: '',
				contextualDefinition: ''
			}
		]
	};

	useEffect(() => {
		if (isZoneMenuModalOpen || isExitModalOpen) {
			setExitModalStatus(true);
			AudioPlayer2.stopAll();
			choiceList.forEach((element, ind) => {
				showModal[ind] = false;
			});
		} else {
			/* istanbul ignore next */
			if (selectedIndex !== false) {
				showModal[selectedIndex] = true;
				setShowModal([...showModal]);
			}

			/* istanbul ignore next */
			if (exitModalStatus && selectedIndex === false) {
				showModal[animIndex] = true;
				setExitModalStatus(false);
				setShowModal([...showModal]);
			}
		}
	}, [isZoneMenuModalOpen, isExitModalOpen]);

	useEffect(() => {
		loadData(choiceList, setChoiceData);
	}, []); // eslint-disable-line

	useEffect(() => {
		if (isSpecificWordStatus) {
			/* istanbul ignore else */
			if (isAnimPlayingScreen) {
				textHighLight(animIndex, false);
				wordVO(choiceList[animIndex].answer_text);
			}
		}
	}, [animIndex, isSpecificWordStatus, isAnimPlayingScreen]); // eslint-disable-line

	const handleAnimationComplete = () => {
		/* istanbul ignore else */
		if (animIndex < choiceList.length - 1) {
			setAnimIndex(animIndex => animIndex + 1);
		}
	};

	const textHighLight = (index, removeTextHighLiter) => {
		choiceList.forEach((element, ind) => {
			if (ind === index) {
				highlightedWordList[ind] = removeTextHighLiter ? false : true;
			} else {
				highlightedWordList[ind] = false;
			}
		});
		setHighlightedWordList([...highlightedWordList]);
	};

	const openModal = index => {
		choiceList.forEach((element, ind) => {
			if (ind === index) {
				showModal[ind] = true;
			} else {
				showModal[ind] = false;
			}
		});
		setState(() => {
			setForwardDisabled(true);
			setShowModal([...showModal]);
		});
	};

	const handleTipClick = index => {
		setSelectedIndex(index);
		openModal(index);
	};
	const wordVO = async word => {
		const audioPlayer = AudioPlayer2.load({
			src: bulkReplace(KnowledgeForReading.WORD_FAMILY_LANDING_VO, {
				word: word.toLowerCase()
			}),
			name: word.toLowerCase()
		});
		AudioPlayer2.stopAll();
		await AudioPlayer2.playSync(word);
		textHighLight(animIndex, true);
		openModal(animIndex);
		AudioPlayer2.stopAll();
	};
	return (
		<WordCardProvider
			wordCardData={choicesData[choiceIndex] || initialStateData}
		>
			<div
				className={`${classes.choiceWrapper} ${
					screenViewIndex === 2 ? classes.questionMainWrapper : ''
				}`}
			>
				{choiceList.map((item, index) => (
					<div key={index} className={classes.subWrapper}>
						<div className={classes.iconWrapper}>
							{screenViewIndex > 0 && (
								<button
									id="myBtn"
									className={`${classes.buttons} tip-button`}
									onClick={() => handleTipClick(index)}
								>
									<i className="r180 tip" />
								</button>
							)}
							{screenViewIndex === 2 && (
								<ToolTipModal
									showDescription={showDescription}
									showModal={showModal}
									index={index}
									setShowModal={setShowModal}
									setDescriptionStatus={setDescriptionStatus}
									choicesData={choicesData}
									item={item}
									screenViewIndex={screenViewIndex}
									setForwardDisabled={setForwardDisabled}
									answeredChoice={answeredChoice}
									currentQuestionIndex={currentIndex}
								/>
							)}

							{screenViewIndex > 0 && (
								<SoundButton
									className={classes.titleSoundButton}
									icon="speaker-center"
									id={`${item.answer_text}${index}VO`}
									size={32}
									sound={{
										name: `Sound${item.answer_text.toLowerCase()}`,
										src: bulkReplace(
											KnowledgeForReading.WORD_FAMILY_LANDING_VO,
											{
												word: item.answer_text.toLowerCase()
											}
										)
									}}
									onSoundCompleted={() => {
										AudioPlayer2.stopAll();
									}}
								/>
							)}
						</div>
						{screenViewIndex === 2 ? (
							<WordTile
								className={classes.choiceBox}
								border
								dragging={false}
								onClick={onChoiceSelect}
								selected={index === parseInt(choiceIndex)}
								text={item.answer_text}
							/>
						) : (
							<WordAnimationHolder
								selected={index === parseInt(choiceIndex)}
								text={item.answer_text}
								index={index}
								item={item}
								choicesData={choicesData}
								showModal={showModal}
								setShowModal={setShowModal}
								isAnimPlayingScreen={isAnimPlayingScreen}
								handleAnimationComplete={
									handleAnimationComplete
								}
								highlightedWordList={highlightedWordList[index]}
								setForwardDisabled={setForwardDisabled}
								setScreenViewIndex={setScreenViewIndex}
								setInstructionDisabled={setInstructionDisabled}
								screenViewIndex={screenViewIndex}
								exitModalActive={
									isZoneMenuModalOpen || isExitModalOpen
								}
							/>
						)}
					</div>
				))}
			</div>
		</WordCardProvider>
	);
};
Choices.propTypes = {
	choiceList: PropTypes.array,
	onChoiceSelect: PropTypes.func,
	choiceIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isSpecificWordStatus: PropTypes.bool,
	screenViewIndex: PropTypes.number,
	isAnimPlayingScreen: PropTypes.bool
};
Choices.defaultProps = {
	choiceList: [],
	choiceIndex: 0,
	isSpecificWordStatus: false,
	screenViewIndex: 0,
	isAnimPlayingScreen: true
};
export default React.memo(Choices);
