import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => {
	theme.colors.writingZone= theme.colors.writingZone? theme.colors.writingZone:{};
	return {
	board: {
		position: 'relative',
		width: '480px',
		height: 'auto',
		background: theme.colors.white,
		borderRadius: '8px',
		boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.35)',
		padding: '16px'
	},
	title: {
		width: '100%',
		height: '112px',
		borderRadius: '8px',
		background: theme.colors.writingZone.primary,
		textAlign: 'center',
		lineHeight: '112px',
		marginBottom: '16px',

		'& h2': {
			fontFamily: theme.fonts.uiBold,
			color: theme.colors.white,
			textTransform: 'uppercase',
			margin: '0',
			fontSize: '48px'
		}
	},
	details: {
		position: 'relative',
		width: '100%',
		height: 'auto',
		background: theme.colors.writingZone.green5,
		border: `1px solid ${theme.colors.writingZone.green4}`,
		borderRadius: '8px',
		padding: '24px 24px 24px 76px',

		'& .dotted': {
			position: 'absolute',
			left: '95px',
			top: '44px',
			bottom: '44px',
			width: '1px',
			border: `1px dashed ${theme.colors.grey}`,
			zIndex: '1,'
		},

		'& .abbr-row': {
			marginBottom: '48px',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			zIndex: 10,

			'&:last-of-type': {
				marginBottom: '0'
			},

			'& .letter': {
				width: '40px',
				height: '40px',
				borderRadius: '20px',
				background: theme.colors.white,
				border: `2px solid ${theme.colors.writingZone.green3}`,
				boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.35)',
				textAlign: 'center',
				lineHeight: '36px',
				fontFamily: theme.fonts.uiBold,
				fontSize: '24px',
				color: theme.colors.writingZone.background,
				textTransform: 'uppercase',
				marginRight: '14px',
				zIndex: 10,
				'&.tree': {
					border: 'none',
					borderRadius: '0',
					boxShadow: 'none',
					background: 'none',
					backgroundImage: 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2MyA2MyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjMgNjM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkJGQ0ZEO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojNzFEQzQ3O3N0cm9rZS13aWR0aDoyO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9Cjwvc3R5bGU+CjxnPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTMxLjUsOGwyMi45LDM5LjhIOC42TDMxLjUsOHoiLz4KCTxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0zMi40LDcuNUMzMi4yLDcuMiwzMS45LDcsMzEuNSw3cy0wLjcsMC4yLTAuOSwwLjVMNy43LDQ3LjJjLTAuMiwwLjMtMC4yLDAuNywwLDFzMC41LDAuNSwwLjksMC41aDQ1LjkKCQljMC40LDAsMC43LTAuMiwwLjktMC41czAuMi0wLjcsMC0xTDMyLjQsNy41eiIvPgo8L2c+Cjwvc3ZnPgo=")',
					backgroundSize: '130% 130%',
					backgroundPosition: '-6px -6px',
					'& span': {
						position: 'relative',
						top: '3px'
					}
				}
			},

			'& .abbr': {
				fontFamily: theme.fonts.uiBold,
				fontSize: '24px',
				color: theme.colors.black,
				position: 'relative',
				top: '-1px'
			}
		}
	}
}});

const WritingAcronymBoard = props => {
	const {acronym, abbreviations, id, className} = props;
	const classes = useStyles();

	const isTree = acronym.toLowerCase() === 'tree';

	return (
		<div className={`${classes.board} ${className} writing-acronym-board`} id={id}>
			<div className={`${classes.title}`}>
				<h2>{acronym}</h2>
			</div>
			<div className={`${classes.details}`}>
				<div className="dotted"></div>
				{abbreviations.map((abbr, idx) => {
					return (
						<div key={`abbr-${idx}`} className={`abbr-row`}>
							<div className={`letter ${isTree ? 'tree' : ''}`}><span>{acronym.charAt(idx)}</span></div>
							<div className="abbr">{abbr}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

WritingAcronymBoard.defaultProps = {
	id: '',
	className: '',
	acronym: '',
	abbreviations: []
};
WritingAcronymBoard.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	acronym: PropTypes.string.isRequired,
	abbreviations: PropTypes.array.isRequired
};

export default React.memo(WritingAcronymBoard);
