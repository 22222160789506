import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => {
    return {
        tilesContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',

            '& .drag-handle-icon': {
                width: '16px',
                height: '26px',
                display: 'inline-block',
            },

            '& .helpers': {
                color: theme.colors.slate,

                '& i': {
                    fontSize: '16px',
                    width: '24px',
                    height: '24px',
                    padding: '0 5px',
                    cursor: 'pointer',
                }
            },

            '&.ghost': {
                transition: 'none !important',

                '& .word-tile, & .image-tile': {
                    background: 'transparent',
                    border: `dashed 2px ${theme.colors.skyBlue}`,
                    borderRadius: '8px',

                    '& *': {
                        opacity: 0
                    }
                },

                '& .helpers': {
                    opacity: 0
                }
            },

            '&.selected': {
                zIndex: 999,

                '& .word-tile': {
                    border: `solid 2px ${theme.colors.skyBlue}`,
                    borderRadius: '8px'
                },
                '& .helpers': {
                    opacity: 0.1
                }
            },

            '&.dragging': {
                zIndex: 999,
                '& .helpers': {
                    opacity: 0
                }
            }
        },
        wordTile: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: '168px',
            maxWidth: '192px',
            width: 'auto',
            height: '56px',
            maxHeight: '90%',
            padding: '0 10px',
            border: `solid 2px ${theme.colors.paleGrey}`,
            borderRadius: '8px',
            background: theme.colors.white,

            '&.correct': {
                border: `solid 2px ${theme.colors.successGreen}`,
            },
            '&.incorrect': {
                border: `solid 2px ${theme.colors.errorRed}`,
            },

            // '&:focus, &:focus-visible, &:active': {
            //     border: `solid 2px ${theme.colors.skyBlue}`
            // },

            '& .drag-handle-icon': {
                fill: theme.colors.slate
            },

            '&.completed': {
                '& .tile': {
                    flexGrow: 0,
                    paddingLeft: '24px'
                },
                '& .feedback': {
                    paddingRight: '6px',
                    marginRight: 20,
                }
            },

            '& .drag-helper': {
                flexGrow: 0,
                paddingRight: '10px',
                color: theme.colors.grey,
                fontSize: '16px',
                lineHeight: '16px',
                cursor: 'move',

                '& i': {
                    color: theme.colors.grey,
                }
            },

            '& .feedback': {
                flexGrow: 0,
                fontSize: '16px',
                lineHeight: '16px',


                '& .feedback-icon': {
                    position: 'relative',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                    borderRadius: '50%',
                    padding: '1px',
                    color: theme.colors.white,
                    textAlign: 'center',
                    fontSize: '9px',
                    color: theme.colors.black,

                    '&.correct': {
                        background: theme.colors.successGreen,
                        color: theme.colors.white,
                    },
                    '&.incorrect': {
                        background: theme.colors.errorRed,
                        color: theme.colors.white,
                    }
                }
            },

            '& .tile': {
                maxHeight: '100%',
                flexGrow: 1,
                fontFamily: theme.fonts.uiSemibold,
                fontWeight: '600',
                color: theme.colors.black,
                fontSize: '16px',
                whiteSpace: 'break-spaces',
                userSelect: 'none',
                display: "-webkit-box",
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },

        imageTile: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 'auto',
            height: 'max(92px, 14vh)',
            border: `solid 3px transparent`,
            borderRadius: '12px',
            background: theme.colors.white,

            '&.correct': {
                border: `solid 3px ${theme.colors.successGreen}`,
            },
            '&.incorrect': {
                border: `solid 3px ${theme.colors.errorRed}`,
            },

            '&.completed': {
                width: '132px',

                '& .tile': {
                    borderRadius: '8px'
                }
            },

            '&:focus, &:focus-visible, &:active': {
                border: `solid 3px ${theme.colors.skyBlue}`
            },

            '& .drag-handle-icon': {
                fill: theme.colors.white,
                margin: '0 4px',
            },

            '& .drag-helper': {
                flexGrow: 0,
                background: theme.colors.softBlack,
                color: theme.colors.white,
                fontSize: '16px',
                lineHeight: '16px',
                padding: '4px 2px',
                cursor: 'move',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '8px 0 0 8px',

                '& i': {
                    color: theme.colors.white,
                    padding: '4px',
                }
            },

            '& .feedback': {
                top: "0",
                right: "30px",
                position: "absolute",
                flexGrow: 0,
                fontSize: "24px",
                lineHeight: "normal",

                '& .feedback-icon': {
                    position: 'relative',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                    borderRadius: '50%',
                    color: theme.colors.white,
                    textAlign: 'center',
                    fontSize: '9px',
                    color: theme.colors.black,
                    boxShadow: '0 0 6px 1px rgba(0,0,0,0.2)',

                    '&.correct': {
                        background: theme.colors.successGreen,
                        color: theme.colors.white,
                    },
                    '&.incorrect': {
                        background: theme.colors.errorRed,
                        color: theme.colors.white,
                    }
                }
            },

            '& .tile': {
                flexGrow: 1,
                width: 'auto',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '0 8px 8px 0',
                overflow: 'hidden'
            },
        },
        soundButton: {
            border: 'none'
        }
    }
});
