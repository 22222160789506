import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import CorrectIncorrect from '../CorrectIncorrect/CorrectIncorrect';
import {AudioPlayer2} from '@shared/core';
import {useStyles} from './SpellingFeedbackItem.style';
import {cloneDeep, isEmpty} from 'lodash';
import FeedbackSpell from './FeedbackSpell';
import {SpellingCorrectiveFeedbackProvider} from './SpellingCorrectiveFeedbackContext';
import {FeedbackSpellUtil} from './FeedbackSpellUtil';
import {getAudioExt} from '@reading/r180/src/utils/audio';
import api from '@reading/r180/src/api/api';

export default function SpellingFeedbackItem(props) {
	const {
		id,
		word,
		instanceNo,
		isIncrementInstance,
		onTextChange,
		onFocus,
		onBlur,
		onKeyPress,
		selectedWord,
		decodingInfoData
	} = props;
	const defaultHighlightSpecificWord = {
		isTrue: false,
		playHighlightSpecificWord: false,
		useIncorrectMarkupObj: true,
		errorCodeIndex: 0
	};
	const classes = useStyles();
	const ref = useRef(null);
	const maximumAttemptCount = 3;
	const defaultErrorCode = 31;

	const [isFocused, setFocused] = useState(true);
	const [feedbackWord, setFeedbackWord] = useState(selectedWord);
	const [typedWord, setTypedWord] = useState(selectedWord);
	const [isCorrect, setCorrect] = useState(false);
	const [instanceNumber, setInstanceNumber] = useState(instanceNo);
	const [playFeedbackWordAnimation, setPlayFeedbackWordAnimation] = useState(
		false
	);
	const [playCorrectWordAnimation, setPlayCorrectWordAnimation] = useState(
		false
	);
	const [isReadWordOnly, setReadWordOnly] = useState(false);
	const [isReadLetterOnly, setReadLetterOnly] = useState(false);
	const [highlightSpecificWordOnly, setHighlightSpecificWordOnly] = useState(
		defaultHighlightSpecificWord
	);
	const [
		playHighlightSpecificWordOnly,
		setPlayHighlightSpecificWordOnly
	] = useState(false);
	const [feedbackTimeout, setFeedbackTimeout] = useState([]);
	const EXT = getAudioExt();
	const [serverData, setServerData] = useState({});

	useEffect(() => {
		FeedbackSpellUtil.loadAllSpellingFeedbackSounds();
		correctFeedbackProcessInit(selectedWord);
	}, []);

	const handleTextChange = e => {
		let val = e.target.value;
		val = val.replace(/[^a-zA-Z.\-/ ]/g, '');
		setTypedWord(val);
		if (typeof onTextChange !== 'undefined') {
			onTextChange(word, val, isCorrect);
		}
	};

	const clearTimeouts = () => {
		feedbackTimeout.forEach(timeout => {
			clearTimeout(timeout);
		});
	};

	const handleKeyPress = e => {
		const reg = /^[a-zA-Z\b]+$/;
		const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
		if (!reg.test(key)) {
			e.preventDefault();
		}

		if (e.key === 'Enter') {
			correctFeedbackProcessInit(e.target.value);
			if (typeof onKeyPress !== 'undefined') {
				const isValid = word === e.target.value;
				onKeyPress(word, e.target.value, isValid);
			}
		} else {
			resetAnimationConfig();
		}
	};

	const correctFeedbackProcessInit = async wordToValidate => {
		let reponseData = null;
		AudioPlayer2.stopAll();
		clearTimeouts();
		resetAnimationConfig();
		setInstanceNumber(instanceNumber + 1);
		setCorrect(word === wordToValidate);
		setTypedWord(wordToValidate);
		setFeedbackWord(wordToValidate);
		try {
			reponseData = await api.spelling.getCorrectiveFeedbackData(
				word,
				wordToValidate
			);
		} catch (e) {
			reponseData = {errors: [{errorCode: 31}]};
		}
		setServerData(reponseData);
		if (word !== wordToValidate) {
			setTypedWord('');
			spellingCorrectiveFeedbackSequence(reponseData);
		}
	};

	const handleFocus = e => {
		AudioPlayer2.stopAll();
		e.preventDefault();

		if (typeof onFocus !== 'undefined') {
			onFocus(word);
		}
	};

	const handleBlur = e => {
		if (typeof onBlur !== 'undefined') {
			onBlur(word, e.target.value, isCorrect);
		}
	};
	const renderFeedbackWord = () => {
		return (
			<FeedbackSpell
				onComplete={() => {}}
				highlightSpecificWord={highlightSpecificWordOnly}
				spellLetter={isReadLetterOnly}
				spellWord={isReadWordOnly}
				feedbackData={serverData}
				IsCorrect={false}
				decodingInfo={decodingInfoData}
				wordText={feedbackWord}
			/>
		);
	};
	const renderCorrectWord = () => {
		return (
			<FeedbackSpell
				onComplete={() => {}}
				highlightSpecificWord={highlightSpecificWordOnly}
				spellLetter={isReadLetterOnly}
				spellWord={isReadWordOnly}
				feedbackData={serverData}
				IsCorrect={true}
				decodingInfo={decodingInfoData}
				wordText={word}
			/>
		);
	};

	const spellingCorrectiveFeedbackSequence = responseFromServer => {
		// TODO This isn't clean at all, and with small words
		// the delays between sounds is way too long
		// We need a smarter way to play a chain of sounds
		// that listens for when one sound ends and we can begin the next one
		const prevFeedbackValue = feedbackWord;
		let newFeedbackTimeout = feedbackTimeout;

		resetAnimationConfig();
		let timeoutValue = 1000;

		// start VO
		const openCommentTimeout = setTimeout(() => {
			AudioPlayer2.play('opencomment');
		}, timeoutValue);

		newFeedbackTimeout.push(openCommentTimeout);
		timeoutValue = timeoutValue + 1500;

		// play initial feedback word animation with VO
		const feedbackWordTimeout = setTimeout(() => {
			setReadWordOnly(true);
			setPlayFeedbackWordAnimation(true);
		}, timeoutValue);
		newFeedbackTimeout.push(feedbackWordTimeout);
		timeoutValue = timeoutValue + 3000;

		// play initial correct word animation with VO
		const correctWordTimeout = setTimeout(() => {
			setPlayFeedbackWordAnimation(false);
			setReadWordOnly(true);
			setPlayCorrectWordAnimation(true);
		}, timeoutValue);
		newFeedbackTimeout.push(correctWordTimeout);
		timeoutValue = timeoutValue + 3500;

		// play postpronunc VO
		const postpronuncTimeout = setTimeout(() => {
			AudioPlayer2.play('postpronunc');
		}, timeoutValue);
		newFeedbackTimeout.push(postpronuncTimeout);
		timeoutValue = timeoutValue + 1500;

		// play animation and VO related to specific feedback.
		responseFromServer.errors.forEach((errorCodeObj, errorCodeIndex) => {
			const specificErrorTimeout = setTimeout(() => {
				resetAnimationConfig();
				setFeedbackWord(typedWord);
				const errorCode = getErrorCode(
					responseFromServer,
					errorCodeIndex
				);
				FeedbackSpellUtil.playSpecificFeedbackSound(errorCode);
				playSpecificErrorType(errorCode, errorCodeIndex);
			}, timeoutValue);
			newFeedbackTimeout.push(specificErrorTimeout);

			const errorCode = getErrorCode(responseFromServer, errorCodeIndex);
			const feedbackWordDuration = FeedbackSpellUtil.getFeedbackWordDuration(
				word
			);
			timeoutValue =
				timeoutValue +
				(errorCode === defaultErrorCode ? feedbackWordDuration : 6000);
		});

		// toggle feedback word with correct word
		const setCorrectWordTimeout = setTimeout(() => {
			resetAnimationConfig();
			setFeedbackWord(word);
		}, timeoutValue);
		newFeedbackTimeout.push(setCorrectWordTimeout);
		timeoutValue = timeoutValue + 1000;

		// reset feedback word with old value.
		const resetFeedbackWordTimeout = setTimeout(() => {
			setFeedbackWord(typedWord);
		}, timeoutValue);
		newFeedbackTimeout.push(resetFeedbackWordTimeout);

		// play posttip vo
		const postTip1Timeout = setTimeout(() => {
			AudioPlayer2.play('posttip1');
		}, timeoutValue);
		newFeedbackTimeout.push(postTip1Timeout);

		setFeedbackTimeout(newFeedbackTimeout);
	};

	const getErrorCode = (responseFromServer, errorCodeIndex) => {
		let errorCode = responseFromServer.errors[errorCodeIndex].errorCode;
		if (instanceNumber > maximumAttemptCount && isIncrementInstance) {
			errorCode = defaultErrorCode;
		}
		return errorCode;
	};

	const playSpecificErrorType = (errorCode, index) => {
		let newFeedbackTimeout = feedbackTimeout;
		resetAnimationConfig();
		switch (errorCode) {
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 27:
			case 29:
			case 30:
				setHighlightSpecificWordOnly({
					isTrue: true,
					playHighlightSpecificWord: false,
					useIncorrectMarkupObj: true,
					errorCodeIndex: index
				});
				setPlayFeedbackWordAnimation(true);
				break;
			case 6:
			case 8:
				// letters not in correct order and pointout individual letters, this letter should be "a" and this letter should be "s"
				const errorcodetimeout6_8 = setTimeout(() => {
					setFeedbackWord(word);
					setHighlightSpecificWordOnly({
						isTrue: true,
						playHighlightSpecificWord: true,
						useIncorrectMarkupObj: true,
						errorCodeIndex: index
					});
				}, 2000);
				newFeedbackTimeout.push(errorcodetimeout6_8);
				setPlayFeedbackWordAnimation(true);
				setHighlightSpecificWordOnly({
					isTrue: true,
					playHighlightSpecificWord: false,
					useIncorrectMarkupObj: true,
					errorCodeIndex: index
				});
				break;
			case 12:
			case 16:
				// Vowel Substitution
				const errorcodetimeout12_16 = setTimeout(() => {
					setPlayFeedbackWordAnimation(false);
					setPlayCorrectWordAnimation(true);
					setHighlightSpecificWordOnly({
						isTrue: true,
						playHighlightSpecificWord: true,
						useIncorrectMarkupObj: true,
						errorCodeIndex: index
					});
				}, 2000);
				newFeedbackTimeout.push(errorcodetimeout12_16);
				setPlayFeedbackWordAnimation(true);
				setHighlightSpecificWordOnly({
					isTrue: true,
					playHighlightSpecificWord: false,
					useIncorrectMarkupObj: true,
					errorCodeIndex: index
				});
				break;

			case 17:
			case 18:
				// TODO
				//The user’s spelling is missing a consonants after consonants - know , kow (missing "n") - similar to errorcode 21
				//The user’s spelling is missing a consonants after consonants - challenges , callenges (missing "h") - similar to errorcode 21
				// used logic to treat empty [] in incorrectMarkup
				const errorcodetimeout17_18 = setTimeout(() => {
					setPlayFeedbackWordAnimation(false);
					setPlayCorrectWordAnimation(true);
					setHighlightSpecificWordOnly({
						isTrue: true,
						playHighlightSpecificWord: true,
						useIncorrectMarkupObj: false,
						errorCodeIndex: index
					});
				}, 1000);
				newFeedbackTimeout.push(errorcodetimeout17_18);
				setFeedbackWord(word);
				setPlayFeedbackWordAnimation(true);
				setHighlightSpecificWordOnly({
					isTrue: true,
					playHighlightSpecificWord: true,
					useIncorrectMarkupObj: true,
					errorCodeIndex: index
				});
				break;
			case 19:
				// TODO - FeedbackWord updated with space [] for missing letter
				setPlayCorrectWordAnimation(true);
				setHighlightSpecificWordOnly({
					isTrue: true,
					playHighlightSpecificWord: true,
					useIncorrectMarkupObj: false,
					errorCodeIndex: index
				});
				break;
			case 21:
				// TOD0 - FeedbackWord updated with space [] for missing letter
				// used logic to treat empty [] in incorrectMarkup

				const errorcodetimeout21 = setTimeout(() => {
					setPlayFeedbackWordAnimation(false);
					setHighlightSpecificWordOnly({
						isTrue: false,
						playHighlightSpecificWord: false,
						useIncorrectMarkupObj: true,
						errorCodeIndex: index
					});
					setReadWordOnly(true);
					setPlayCorrectWordAnimation(true);
				}, 1000);
				// const errorcodetimeout21_2 = setTimeout(() => {
				// 	setFeedbackWord(typedWord);
				// }, 2000);
				newFeedbackTimeout.push(errorcodetimeout21);
				//newFeedbackTimeout.push(errorcodetimeout21_2);
				setFeedbackWord(word);
				setPlayFeedbackWordAnimation(true);
				setHighlightSpecificWordOnly({
					isTrue: true,
					playHighlightSpecificWord: true,
					useIncorrectMarkupObj: true,
					errorCodeIndex: index
				});

				break;
			case 26:
				//dont forgot to double the letter , tell , tel(missing the second "l")
				//dont forgot to double the letter , effort , efort(missing the second "f")
				// used logic to treat empty [] in incorrectMarkup
				const errorcodetimeout26 = setTimeout(() => {
					setFeedbackWord(word);
					setPlayFeedbackWordAnimation(true);
					setHighlightSpecificWordOnly({
						isTrue: true,
						playHighlightSpecificWord: true,
						useIncorrectMarkupObj: true,
						errorCodeIndex: index
					});
				}, 2000);
				newFeedbackTimeout.push(errorcodetimeout26);
				setPlayCorrectWordAnimation(true);
				setHighlightSpecificWordOnly({
					isTrue: true,
					playHighlightSpecificWord: true,
					useIncorrectMarkupObj: false,
					errorCodeIndex: index
				});
				break;
			case 28:
				// Vowel Digraph Extra
				// use errorcode 27 logic and vo
				const errorcodetimeout28 = setTimeout(() => {
					setPlayCorrectWordAnimation(false);
					setPlayFeedbackWordAnimation(true);
					AudioPlayer2.play(
						'decoding_spelling_feedback_corrtype27_1'
					);
					setHighlightSpecificWordOnly({
						isTrue: true,
						playHighlightSpecificWord: false,
						useIncorrectMarkupObj: true,
						errorCodeIndex: index
					});
				}, 4000);
				newFeedbackTimeout.push(errorcodetimeout28);
				setPlayCorrectWordAnimation(true);
				setHighlightSpecificWordOnly({
					isTrue: true,
					playHighlightSpecificWord: false,
					useIncorrectMarkupObj: false,
					errorCodeIndex: index
				});
				break;
			case 25:
			case 31:
				setReadLetterOnly(true);
				setReadWordOnly(true);
				setPlayCorrectWordAnimation(true);
				break;
			case 32:
			case 33:
				// 32 - Consonant Blend Remediation , sn sp st sw tw
				// 33 - Consonant Diagraph Remediation , ch sh th wh ck
				// use errorcode 17 logic and vo
				const errorcodetimeout32_33 = setTimeout(() => {
					setFeedbackWord(word);
					setPlayFeedbackWordAnimation(true);
					AudioPlayer2.play('decoding_spelling_feedback_corrtype17');
					setHighlightSpecificWordOnly({
						isTrue: true,
						playHighlightSpecificWord: false,
						useIncorrectMarkupObj: true,
						errorCodeIndex: index
					});
				}, 4000);
				newFeedbackTimeout.push(errorcodetimeout32_33);
				setPlayCorrectWordAnimation(true);
				setHighlightSpecificWordOnly({
					isTrue: true,
					playHighlightSpecificWord: false,
					useIncorrectMarkupObj: false,
					errorCodeIndex: index
				});
				break;
			default:
				break;
		}
	};

	const resetAnimationConfig = () => {
		//reset config
		setPlayCorrectWordAnimation(false);
		setPlayFeedbackWordAnimation(false);
		setReadWordOnly(false);
		setReadLetterOnly(false);
		setHighlightSpecificWordOnly(defaultHighlightSpecificWord);
	};

	return (
		<SpellingCorrectiveFeedbackProvider>
			<div className={`${classes.spellingFeedbackItem}`}>
				<div className={classes.correctSpelling}>
					<div className="wrapper">
						{playFeedbackWordAnimation ? (
							renderFeedbackWord()
						) : (
							<>
								<span
									className={`correct-spelling ${
										isCorrect ? 'correct' : 'incorrect'
									}`}
								>
									{feedbackWord}
								</span>
								<CorrectIncorrect
									correct={false}
									style={{top: '-3px'}}
								/>
							</>
						)}
					</div>
					<div className="divider">
						<span className="line"></span>
						<i className="r180 arrow-down" />
						<span className="line"></span>
					</div>
					<div className="wrapper">
						{playCorrectWordAnimation ? (
							renderCorrectWord()
						) : (
							<>
								<span className="correct-spelling correct">
									{word}
								</span>
								<CorrectIncorrect style={{top: '-3px'}} />
							</>
						)}
					</div>
				</div>

				<div
					className={`${classes.wrapper} ${
						isFocused ? 'hasFocus' : ''
					} ${
						isCorrect === false ? 'incorrect' : 'correct'
					} spell-entry`}
					id={id}
				>
					<span className={`number`}>
						{isIncrementInstance ? instanceNumber : instanceNo}.
					</span>
					<input
						ref={ref}
						type="text"
						className={`spell-input `}
						onPaste={e => e.preventDefault()}
						onDrop={e => e.preventDefault()}
						autoComplete={'off'}
						onBlur={handleBlur}
						onFocus={handleFocus}
						onChange={handleTextChange}
						onKeyPress={handleKeyPress}
						value={typedWord}
						maxLength={20}
						spellCheck={false}
						autoCorrect={'off'}
					/>
				</div>
			</div>
		</SpellingCorrectiveFeedbackProvider>
	);
}

SpellingFeedbackItem.defaultProps = {
	id: '',
	word: '',
	instanceNo: 1,
	selectedWord: '',
	isIncrementInstance: true
};
SpellingFeedbackItem.propTypes = {
	id: PropTypes.string,
	word: PropTypes.string.isRequired,
	instanceNo: PropTypes.number,
	isIncrementInstance: PropTypes.bool,
	selectedWord: PropTypes.string,
	onTextChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onKeyPress: PropTypes.func
};
