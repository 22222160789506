import React from 'react';
import PropTypes from 'prop-types';
import CorrectIncorrect from '../CorrectIncorrect/CorrectIncorrect';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {useStyles} from './SpellingChallengeItem.style';
import {SpellingAssessment} from '@reading/r180/src/activities/SpellingAssessment/SpellingAssessment';
import SpellingCorrectiveFeedback from '../SpellingCorrectiveFeedback/SpellingCorrectiveFeedback';
import {cloneDeep} from 'lodash';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import {sleep} from '@reading/r180/src/utils/sleep';

const {useEffect, useRef, useState} = React;

export default function SpellingChallengeItem(props) {
	const attempts = React.useRef(1);
	const MAX_ATTEMPTS = 3;
	const {
		id,
		word,
		num,
		onTextChange,
		interactive,
		decodingInfo,
		onFocus,
		onBlur,
		onCompleted,
		onKeyPress,
		selectedWord,
		onIncorrectWord,
		wordList,
		correctWordList,
		studyList,
		isCompleted
	} = props;

	const classes = useStyles();
	const ref = useRef(null);

	const batchedState = useBatchedSetState();
	const [isFocused, setFocused] = useState(false);
	const [typedWord, setTypedWord] = useState('');
	const [isCorrect, setCorrect] = useState(false);
	const [showFeedback, setShowFeedback] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
	const [homophonesWordAttemptMap, setHomophonesWordAttemptMap] = useState(
		{}
	);

	useEffect(() => {
		if (selectedWord !== word) {
			setFocused(false);
		}
	}, [selectedWord]);

	useEffect(() => {
		/* Make the first input Field selected default */
		if (num === 1) {
			ref.current.focus();
		}
	}, []);

	useEffect(() => {
		if (num !== 1) {
			setFocused(false);
		}
		batchedState.setState(() => {
			setIsDone(false);
			setShowFeedback(false);
			setTypedWord('');
		});
	}, [word]);

	const handleTextChange = e => {
		let val = e.target.value.toLowerCase();
		val = val.replace(/[^a-zA-Z.\-/ ]/g, '');

		setTypedWord(val);
		if (typeof onTextChange !== 'undefined') {
			onTextChange(val);
		}
	};

	const checkInput = (word, typedWord, nextItem) => {
		const nextInput = nextItem
			? nextItem.getElementsByClassName('spell-input')
			: null;

		if (word === typedWord) {
			setCorrect(true);

			if (attempts.current === 1) {
				setTypedWord('');
				setShowFeedback(true);
			} else {
				if (attempts.current === MAX_ATTEMPTS) {
					setCorrect(false);
					setShowFeedback(true);
					setTypedWord(word);
				} else {
					setShowFeedback(false);
					setTypedWord('');
				}
			}
			AudioPlayer2.play('continue');
			setFocused(false);
			setIsDone(true);

			completeTask(nextInput, word, typedWord);
		} else {
			if (attempts.current < MAX_ATTEMPTS) {
				// -----------------------------------
				setShowFeedbackDialog(true);
				// -----------------------------------

				attempts.current++;
			} else {
				attempts.current = 1;
				AudioPlayer2.stopAll();
				setCorrect(false);
				setShowFeedback(true);
				setTypedWord(word);
				setIsDone(true);

				// Callback when the max attempts are run out
				if (typeof onIncorrectWord === 'function') {
					onIncorrectWord(word);
				}

				completeTask(nextInput, word, typedWord);
			}
		}
	};

	const onFeedbackClose = (word, typeWord, isCorrect) => {
		AudioPlayer2.stopAll();
		if (isCorrect) {
			setTypedWord('');
			setShowFeedbackDialog(false);
		} else {
			setShowFeedbackDialog(true);
		}
	};

	const completeTask = (nextInput, word, typedWord) => {
		/* stops next input focus in case study word list length and correct list length are equal */
		let completionStatus = false;
		if (word === typedWord && studyList.find(w => w.wordId === word)) {
			if (correctWordList.length + 1 === studyList.length) {
				completionStatus = true;
			}
		}
		if (nextInput && nextInput.length > 0 && !completionStatus) {
			nextInput[0].focus();
		} else {
			if (typeof onCompleted !== 'undefined') {
				ref.current.blur();
				setFocused(false);
				AudioPlayer2.stopAll();
				onCompleted();
			}
		}
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			AudioPlayer2.stopAll();

			const parentItem = ref.current
				? ref.current.parentNode.parentNode
				: null;
			const nextItem = parentItem ? parentItem.nextSibling : null;

			checkInput(word, typedWord, nextItem);

			if (typeof onKeyPress !== 'undefined') {
				onKeyPress(e.key, word, e.target.value, false);
			}
		}
	};

	const handleFocus = e => {
		AudioPlayer2.stopAll();
		e.preventDefault();
		setFocused(true);
		if (typeof onFocus !== 'undefined') {
			onFocus(word);
		}
	};

	const handleBlur = e => {
		AudioPlayer2.stopAll();
		e.preventDefault();

		if (typeof onBlur !== 'undefined') {
			onBlur(word, e.target.value, e.target);
		}
	};

	return (
		<div className={`${classes.spellingItem} ${isCorrect && 'correct'}`}>
			<div
				className={`${classes.wrapper} ${
					isFocused ? 'hasFocus' : ''
				} ${isCorrect && 'correct'} spell-entry`}
				id={id}
			>
				<span className={`number`}>{num}.</span>
				<input
					ref={ref}
					type="text"
					className={`spell-input `}
					onPaste={e => e.preventDefault()}
					onDrop={e => e.preventDefault()}
					autoComplete={'off'}
					onBlur={handleBlur}
					onFocus={handleFocus}
					onChange={handleTextChange}
					onKeyPress={handleKeyPress}
					disabled={interactive === false || isDone || isCompleted}
					value={typedWord}
					maxLength={20}
					spellCheck={false}
					autoCorrect={'off'}
				/>
				{showFeedback && <CorrectIncorrect correct={isCorrect} />}
			</div>
			{showFeedbackDialog && (
				<SpellingCorrectiveFeedback
					correctWord={word}
					feedbackWord={typedWord}
					decodeInfoData={decodingInfo}
					feedbackInstanceNo={num}
					isIncrementFeedbackInstance={false}
					onCorrectiveFeedbackKeyPress={onFeedbackClose}
					onCorrectiveFeedbackBlur={() => {
						AudioPlayer2.stopAll();
					}}
				/>
			)}
		</div>
	);
}

SpellingChallengeItem.defaultProps = {
	id: '',
	word: '',
	num: 0,
	interactive: true,
	selectedWord: '',
	wordList: []
};
SpellingChallengeItem.propTypes = {
	id: PropTypes.string,
	word: PropTypes.string.isRequired,
	num: PropTypes.number.isRequired,
	onTextChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onKeyPress: PropTypes.func,
	interactive: PropTypes.bool,
	onCompleted: PropTypes.func,
	selectedWord: PropTypes.string,
	onIncorrectWord: PropTypes.func
};
