import queryString from 'query-string';

const getCorrelationId = location => {
	if (!location) {
		return false;
	}

	if (typeof location.search !== 'string') {
		return false;
	}

	const search = queryString.parse(location.search);

	if (location.search.length === 0) {
		return false;
	}

	if (!Reflect.has(search, 'custom_correlation_id')) {
		return false;
	}

	return search.custom_correlation_id;
};

export default getCorrelationId;
