import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DropZone } from './components/DnDZones';
import SoundButton from '../SoundButton';
import { displaySafe } from '@reading/r180/src/utils/stringUtils';

const useStyles = makeStyles(theme => ({
    dropContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: 'auto',
        border: `solid 1px ${theme.colors.paleGrey}`,
        borderRadius: '8px',

        '& .drop-header': {
            background: theme.colors.softBlack,
            color: theme.colors.white,
            padding: '3px 8px',
            margin: '0 -1px',
            borderRadius: '8px 8px 0 0',
            border: `solid 1px ${theme.colors.softBlack}`,


            '& .title': {
                color: theme.colors.white,
                marginLeft: '8px',
                fontFamily: theme.fonts.uiSemibold,
                fontSize: '16px',
            },
        },

        '& .drop-content': {
            position: 'relative',
            display: 'flex',
            flexGrow: 1,
            padding: '8px',
            boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.15)',

            '&::before': {
                content: "'drop here'",
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 0,
                color: theme.colors.grey,
                fontFamily: theme.fonts.ui,
                fontStyle: 'italic',
                textAlign: 'center'
            },

            '&.drag-hover': {
                background: '#CCE9FA',

                '& .tile-item:hover': {
                    '& .image-tile': {
                        // pointerEvents: 'none',

                        '& .drag-helper': {
                            // pointerEvents: 'all',
                        }
                    }
                },

                '& .drop-zone': {
                    background: 'transparent',
                    borderColor: theme.colors.skyBlue,
                    color: theme.colors.skyBlue,
                }
            }
        }
    },
    soundButton: {
        border: 'none',
        background: 'transparent',
        fontSize: '16px',
        color: theme.colors.white,

        '& i': {
            fontSize: '16px',
        }
    }
}));

const DndAnswers = (props) => {
    const { children, type, meta, title = "Examples", audioSrc = "" } = props;
    const classes = useStyles();

    return (
        <div className={`drop-container ${classes.dropContainer}`}>
            <div className='drop-header'>
                <SoundButton
                    className={classes.soundButton}
                    icon="info"
                    size={15}
                    sound={{ name: `${title}-tip`, src: audioSrc }}
                />
                <span className="title animate__animated animate__fadeIn">{displaySafe(title)}</span>
            </div>

            <div className={`drop-content`}>
                <DropZone type={type} meta={meta}>
                    {children}
                </DropZone>
            </div>
        </div>
    );
};

export default React.memo(DndAnswers);