import React from 'react';
import {WordList} from '@reading/common';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	resultsBlock: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',

		'& .listContainer': {
			marginTop: '40px',
			marginRight: '40px',
			'&:last-of-type': {
				marginRight: '0'
			}
		}
	}
}));

const ResultsDisplay = props => {
	const {
		correctWords=[],
		timedOutWords = [],
		inCorrectWords=[],
		showTimedOutWords = false,
		timedOutWordsStatus = false,
		className = '',
		style = {}
	} = props;
	const classes = useStyles();

	return (
		<div className={`${classes.resultsBlock} ${className}  animate__animated animate__fadeIn`} style={style}>
			<div className="listContainer">
				<WordList isCorrect={true} wordList={correctWords} />
			</div>

			{showTimedOutWords && (
				<div className="listContainer">
					<WordList isCorrect={timedOutWordsStatus} isTimedOut={true} title="Slow" wordList={timedOutWords} />
				</div>
			)}

			<div className="listContainer">
				<WordList isCorrect={false} wordList={inCorrectWords} />
			</div>
		</div>
	);
};

//export {ResultsDisplay};
export default React.memo(ResultsDisplay);
