import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import SoundButton from '../SoundButton';

const useStyles = makeStyles(theme => ({
	container: {
		position: 'relative',
		width: '100%',
		height: '56px',
		background: theme.colors.readingZone.blue5,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		borderRadius: '8px 8px 0 0',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '0 40px 0 20px',
		alignItems: 'center',

		'& button': {
			fontFamily: theme.fonts.button,
			fontSize: '16px',
			fontWeight: '400',
			color: theme.colors.softBlack,
			padding: '6px 0',
			'& i': {
				position: 'relative',
				top: '1px',
				fontSize: '16px',
				color: theme.colors.black,
				marginRight: '9px'
			},
			'&.instruction-button': {
				position: 'relative',
				top: '1px',
				'& i': {
					fontSize: '24px'
				}
			},
			'&.anchor-video-button': {
				borderLeft: `1px solid ${theme.colors.slate}`,
				paddingLeft: '35px',
			}
		},
		'& .MuiListItem-button': {
			'& i': {
				position: 'relative',
				top: '4px'
			}
		}
	}
}));

export default function ContextPassageSupportTray(props) {
	const {
		id,
		className,
		onInstructionClick,
		onVideoClick,
	} = props;
	const classes = useStyles();

	const handleInstructionClick = e => {
		e.preventDefault();
		onInstructionClick();
	};

	const handleVideoClick = e => {
		e.preventDefault();
		onVideoClick();
	};

	return (
		<div id={id} className={`success-support-tray ${classes.container} ${className}`}>
			<button
				data-testid={'instruction-button'}
				className="instruction-button"
				onClick={handleInstructionClick}
			>
				<i className="r180 instructions" />
			</button>

			<button className='anchor-video-button'
				data-testid={'anchor-video-button'}
				onClick={handleVideoClick}
			>
				<i className="r180 anchor-video" />
				Video
			</button>
		</div>
	);
}

ContextPassageSupportTray.defaultProps = {
	id: '',
	className: ''
};
ContextPassageSupportTray.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	onInstructionClick: PropTypes.func.isRequired,
	onVideoClick: PropTypes.func.isRequired
};
