import React from 'react';
import PropTypes from 'prop-types';
import {Container, Provider, Subscribe} from 'unstated';

import SessionStorage from '../SessionStorage';
import UserContext from '../UserContext';
import PlatformHelper from '../PlatformHelper';
import Logger from '../Logger';
import getCorrelationId from './lib/get-correlation-id';

const getDeep = (obj, keyChain) => {
	const nextKey = keyChain.shift();
	const has = Reflect.has(obj, nextKey);
	const val = obj[nextKey];

	if (keyChain.length === 0) {
		return val;
	}

	if (has) {
		return getDeep(val, keyChain);
	}

	Logger.warn(`DataStore: Could not find "${keyChain}" in object:`, obj);
	return undefined;
};

const hasDeep = (obj, keyChain) => {
	const nextKey = keyChain.shift();
	const has = Reflect.has(obj, nextKey);
	const val = obj[nextKey];

	if (keyChain.length === 0) {
		return has;
	}

	if (has) {
		return hasDeep(val, keyChain);
	}

	return undefined;
};

const asyncForEach = async (array, callback) => {
	for (let index = 0; index < array.length; index++) {
		// We intend the following line to be synchronous
		// as one request's success may depend on input
		// from a preceding request. The desired behavior
		// invalidates the "no-await-in-loop" rule
		// eslint-disable-next-line no-await-in-loop
		await callback(array[index], index, array);
	}
};

export const syncData = async (required, store) => {
	const tasks = [];

	required.forEach(item => {
		let needsData = false;

		item.store.some(() => {
			const notFoundInStore = !store.has(item.store);

			if (notFoundInStore) {
				needsData = true;
				return false;
			} else {
				return true;
			}
		});

		const hasApi = Reflect.has(item, 'api');

		if (needsData && hasApi) {
			tasks.push({apiCall: item.api, store: item.store});
		}
	});

	await asyncForEach(tasks, async task => {
		let taskName;

		try {
			taskName = String(task.apiCall)
				.split('function ')[1]
				.split('()')[0];
		} catch (e) {
			taskName = task.apiCall.name;
		}

		const result = await task.apiCall();

		for (let key in result) {
			if (key === 'error') {
				Logger.error(result.error);

				throw new Error(
					`DataStore: Error object returned while restoring "${taskName}" from sessionConfig.`
				);
			}

			if (!task.store.includes(key)) {
				throw new Error(
					`DataStore: Cannot find store key "${key}" in "${taskName}" while restoring sessionConfig.`
				);
			}

			await store.set(key, result[key]);
		}
	});
};

/**
 * Iterates over an array of async functions and executes them in order.
 *
 * @param startupFunctions the async functions to execute
 * @returns {Promise<void>}
 */
const invokeStartupFunctions = async startupFunctions => {
	if (!startupFunctions) {
		return;
	}
	if (!Array.isArray(startupFunctions)) {
		Logger.error(
			'Invalid argument passed as startupFunctions',
			startupFunctions
		);
		return;
	}

	for (let i = 0; i < startupFunctions.length; i++) {
		const startupFunction = startupFunctions[i];
		if (typeof startupFunction === 'function') {
			try {
				await startupFunction();
			} catch (e) {
				Logger.error('startup function threw an error: ', e);
			}
		}
	}
};

export class DataStoreContainer extends Container {
	state = {};

	async setDataFromSession(prevSession) {
		if (!prevSession) {
			throw new Error(
				'No previous session passed to DataStore.setDataFromSession'
			);
		}

		await this.setState(prevSession);
		return this;
	}

	set(key, data) {
		// Logger.log('DataStore.set', key);
		return new Promise(resolve => {
			// Use a simple data-key store
			this.setState({[key]: data}, () => {
				// Save the information to sessionStorage
				SessionStorage.set(key, data);
				// Pass back stored reference to data passed in
				resolve(this.state[key]);
			});
		});
	}

	get(key) {
		if (Reflect.has(this.state, key)) {
			return this.state[key];
		}

		if (key.includes('.')) {
			const keyChain = key.split('.');
			return getDeep(this.state, keyChain);
		}

		Logger.warn(`Can not find key ${key} in DataStore.`);

		return undefined;
	}

	has(key) {
		if (Reflect.has(this.state, key)) {
			return true;
		}

		if (key.includes('.')) {
			const keyChain = key.split('.');
			return hasDeep(this.state, keyChain);
		}

		return false;
	}

	initialized = false;

	readyCallbacks = [];

	whenReady(callback) {
		if (this.initialized) {
			callback();
		} else {
			this.readyCallbacks.push(callback);
		}
	}

	fireReadyCallbacks() {
		this.readyCallbacks.forEach(callback => {
			callback();
		});

		this.readyCallbacks = [];
	}

	async initialize(props) {
		if (this.initialized) {
			return this;
		}

		const {history, sessionConfig} = props;

		const correlationId = getCorrelationId(window.location);
		SessionStorage.init(sessionConfig.name);
		const activeSession = SessionStorage.getSession();

		const hasRestoredCorrelationId = Reflect.has(
			activeSession,
			'correlationId'
		);
		const restoredCorrelationId = activeSession.correlationId;
		const isNewCorrelationId =
			hasRestoredCorrelationId &&
			correlationId &&
			restoredCorrelationId !== correlationId;

		if (isNewCorrelationId) {
			Logger.log(
				'DataStore.initialize: new correlation ID detected: ',
				correlationId
			);
			SessionStorage.clearAll();
		} else {
			Logger.log(
				'DataStore.initialize: setting data from active session: ',
				activeSession
			);
			await this.setDataFromSession(activeSession);
		}

		await UserContext.init(this.state, correlationId);

		if (PlatformHelper.isM180OnSAM(process.env)) {
			// always read the SID from the cookie to avoid reusing an invalid SID from a preexisting session
			const sid = PlatformHelper.getSIDFromCookie();
			if (sid != null) {
				// in local development, where no cookie will be present, the SID is instead set by a login page
				await this.set('sid', sid);
			}
		}

		await syncData(sessionConfig.requiredData, this);

		// DataStore & Session should now be synced.
		// Ie: The State "is identical to" The Session.
		const restored =
			SessionStorage &&
			Reflect.has(SessionStorage, 'wasRestored') &&
			SessionStorage.wasRestored;

		const restoredRoute = restored && this.state.route;
		const isRestorableRoute =
			restoredRoute &&
			!sessionConfig.doNotRestoreRoutes.includes(restoredRoute);

		if (isRestorableRoute) {
			Logger.log(
				'DataStore.initialize: restoring route: ',
				restoredRoute
			);
			await this.set('restoredRoute', restoredRoute);
		}
		await sessionConfig.resolveRoute(history, restoredRoute);

		this.initialized = true;
		await invokeStartupFunctions(sessionConfig.startupFunctions);
		this.fireReadyCallbacks();
		return this;
	}
}

const DataStore = new DataStoreContainer();

class DataStoreProvider extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.func,
			PropTypes.array
		]).isRequired,
		sessionConfig: PropTypes.object,
		history: PropTypes.object
	};

	static defaultProps = {
		inject: null
	};

	constructor(props) {
		super(props);
		DataStore.initialize(this.props);
	}

	render() {
		return (
			<Provider inject={this.props.inject || [DataStore]}>
				{this.props.children}
			</Provider>
		);
	}
}

class DataStoreSubscribe extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.func,
			PropTypes.array
		]).isRequired,
		to: PropTypes.array
	};

	static defaultProps = {
		to: null
	};

	render() {
		return (
			<Subscribe to={this.props.to || [DataStore]}>
				{this.props.children}
			</Subscribe>
		);
	}
}

DataStore.Provider = DataStoreProvider;
DataStore.Subscribe = DataStoreSubscribe;

export default DataStore;
