class Logger {
	constructor() {
		this.strap = 'RCC:';
	}

	decorate(type, msg) {
		// eslint-disable-next-line no-console
		console[type](...[this.strap, ...msg]);
	}

	info(...msg) {
		this.decorate('info', msg);
	}

	debug(...msg) {
		this.decorate('debug', msg);
	}

	log(...msg) {
		this.decorate('log', msg);
	}

	warn(...msg) {
		this.decorate('warn', msg);
	}

	error(...msg) {
		this.decorate('error', msg);
	}

	fatal(...msg) {
		this.decorate('fatal', msg);
	}
}

export default new Logger();
