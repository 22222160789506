import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ProgressSymbolOn } from './svg/progress-symbol-on.svg'
import { ReactComponent as ProgressSymbolOff } from './svg/progress-symbol-off.svg'
import { makeStyles } from '@material-ui/styles'

export const useStyle = makeStyles(theme => ({
	progressSymbol: {
		display: 'inline-flex',
		position: 'relative',
		borderRadius: '50%',
		opacity: 1,
		width: 24,
		height: 24,
		top: '2px',

		'& svg': {
			display: 'inline-flex',
			verticalAlign: 'top',
			width: 18,
			height: 18,
		},
		animation: 'show 6s linear 1',
	},
	show: {
		opacity: 1,
	}
}));

const ProgressCircle = ({max, progress }) => {

	const classes = useStyle();

	const renderProgressSymbol = () => {
		return [...Array(max).keys()].map((x, i) => {
			return (
				<div
					className={classes.progressSymbol}
					style={{ animationDelay: `${i}s` }}
					key={`star-${i}`}
				>
					{i < progress ? (
						<ProgressSymbolOn />
					) : (
						<ProgressSymbolOff />
					)}
				</div>
			)
		})
	}

	return <div>{renderProgressSymbol()}</div>
};

ProgressCircle.defaultProps = {
};

ProgressCircle.propTypes = {
	max: PropTypes.number.isRequired,
	progress: PropTypes.number.isRequired
};

export default React.memo(ProgressCircle);
