import {startSessionAction} from '../../store/slices/session';
import {useHistory} from 'react-router-dom';
import {gotoNextActivity} from '../../utils/navigation';
import {unwrapResult} from '@reduxjs/toolkit';
import reduxStore from '../../store/redux-setup';

function useQuery() {
	return new URLSearchParams(window.location.search);
}

const StartSession = correlationId => {
	const history = useHistory();
	const dispatch = reduxStore.dispatch;
	const query = useQuery();
	const stage = query.get('stage');

	const startSession = async () => {
		try {
			const result = await dispatch(startSessionAction(stage));
			const payload = unwrapResult(result);
			if (payload.user && payload.user.r180uSessionId) {
				gotoNextActivity({history});
			}
		} catch (err) {
			console.error(err);
		}
	};
	startSession(stage);

	return null;
};

export default StartSession;
