import React, { useState, useEffect, useRef } from 'react';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { shuffle } from 'lodash';
import PropTypes from 'prop-types';
import WordTile from '../../components/WordTile';
import CorrectIncorrect from '../../components/CorrectIncorrect/CorrectIncorrect';
import CircularTimer from './CircularTimer';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	wordAssessment: {
		color: theme.colors.black,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		margin: '25px'
	},
	circularProgress: {
		position: 'absolute',
		right: '40px',
		top: '20px',
		transform: 'scaleX(-1)'
	},
	feedback: {
		position: 'relative'
	},
	pauseContainer: {
		width: '100%',
		height: '100%',
		background: theme.colors.white,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		fontFamily: theme.fonts.uiBold
	},
	wordContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));

const pausableTimer = (callback, delay) => {
	let timerId,
		start,
		remaining = delay;

	const pause = () => {
		if (delay) {
			clearTimeout(timerId);
			remaining -= Date.now() - start;
		}
	};

	const resume = () => {
		if (delay) {
			start = Date.now();
			clearTimeout(timerId);
			timerId = setTimeout(callback, remaining);
		}
	};

	const stop = () => {
		if (delay) {
			clearTimeout(timerId);
		}
	};
	resume();

	return { pause, resume, stop };
};

const WordAssessment = props => {
	const {
		id,
		words,
		correctWord,
		timeOut = 10,
		showTimer = true,
		showFeedBack = false,
		isPaused = false,
		onTimeOut,
		isDisabled = false,
		onWordClick,
	} = props;
	const classes = useStyles();
	const timerRef = useRef(null);
	const { setState } = useBatchedSetState();

	const [selected, setSelected] = useState(null);
	const [isCorrect, setIsCorrect] = useState(false);
	const [isComplete, setIsComplete] = useState(false);
	const [shuffledWords, setShuffledWords] = useState(words);
	const [countDown, setCountDown] = useState(0);
	const [timeStamp, setTimeStamp] = useState(0);

	// Reset Component on word change
	useEffect(() => {
		setState(() => {
			setIsCorrect(false);
			setIsComplete(false);
			setSelected(null);
			setShuffledWords(shuffle(words));
			setCountDown(timeOut);
		});
		const dataTimer = setInterval(() => {
			setTimeStamp(timeStamp => timeStamp + 1);
		}, 1000);

		timerRef.current = pausableTimer(() => {
			setState(() => {
				setIsComplete(true);
				setIsCorrect(false);
				setCountDown(0);
				handleTimedOut();
				setTimeStamp(0);
			});
		}, timeOut * 1000);

		return () => {
			timerRef.current = null;
			setTimeStamp(0);
			clearInterval(dataTimer);
		};
	}, [words]);


	useEffect(() => {
		if (isDisabled || isPaused) {
			timerRef && timerRef.current && timerRef.current.stop();
			setState(() => {
				setCountDown(0);
				setShuffledWords([]);
			});
		} else {
			timerRef && timerRef.current && timerRef.current.resume();
			setState(() => {
				setCountDown(timeOut);
				setShuffledWords(shuffle(words));
			});
		}
	}, [isDisabled, isPaused]);


	const handleTimedOut = () => {
		if (showFeedBack) {
			setState(() => {
				setSelected(correctWord.name);
				setCountDown(0);
			});

			setTimeout(() => {
				timerRef && timerRef.current && timerRef.current.stop();

				if (typeof onTimeOut === 'function') {
					onTimeOut(correctWord.name);
				}
			}, 1000);
		} else {
			timerRef && timerRef.current && timerRef.current.stop();
			if (typeof onTimeOut === 'function') {
				onTimeOut(correctWord.name, timeStamp);
			}
		}
	};


	const handleWordClick = (word, isCorrect, time) => {
		setCountDown(0);
		setTimeout(() => {
			if (!isComplete && typeof onWordClick === 'function') {
				onWordClick(word, isCorrect, time);
			}
		}, 1000);
	};


	const handleClick = (ev, selectedWord) => {
		if (!isComplete && !isDisabled) {
			AudioPlayer2.play('click');
			const isCorrect = selectedWord === correctWord.name;

			timerRef && timerRef.current && timerRef.current.stop();
			setState(() => {
				setIsComplete(true);
				setSelected(selectedWord);
				setIsCorrect(isCorrect);
			});
			handleWordClick(selectedWord, isCorrect, timeStamp);
		}
	};


	return (
		<div id={id} style={{ height: '100%' }}>
			{
				isPaused
					?
					<div className={`${classes.pauseContainer} animate__animated animate__zoomIn`}>
						<h2>PAUSED</h2>
					</div>
					:
					<div className={`${classes.wordContainer}`}>
						{
							id.includes('SpeedChallenge') &&
							<div className={classes.circularProgress}>
								{Boolean(showTimer && countDown) && (
									<CircularTimer
										size={50}
										strokeWidth={16.5}
										circleStroke="#3e98c7"
										countDownTimer={countDown}
									/>
								)}
							</div>
						}

						<div className="wordRows" style={{ marginLeft: '24px' }}>
							{shuffledWords.map((word, i) => {
								return (
									<div
										className={classes.wordAssessment}
										key={word.name}
									>
										<WordTile
											id={word.name}
											text={word.name}
											border={false}
											selected={selected === word.name}
											onClick={ev => handleClick(ev, word.name)}
											key={`${word.name}-${id}`}
										/>
										<div
											className="feedback"
											style={{ width: '24px' }}
										>
											{selected === word.name && (
												<CorrectIncorrect
													correct={isCorrect}
												/>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
			}
		</div>
	);
};

WordAssessment.defaultProps = {
	id: '',
	words: []
};

WordAssessment.propTypes = {
	id: PropTypes.string,
	words: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired
		})
	)
};

export default React.memo(WordAssessment);
