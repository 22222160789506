import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { stripHtmlTags } from '@reading/r180/src/utils/stringUtils';

export const useStyle = makeStyles(theme => ({
	wrapper: {
		width: '80%',
		minHeight: '220px',
		margin: 'auto',
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '8px',
		background: theme.colors.white,
		'& > .header': {
			background: theme.colors.lightGrey,
			padding: '18px 32px',
			borderBottom: `1px solid ${theme.colors.paleGrey}`,
			color: theme.colors.softBlack,
			letterSpacing: '0.02em',
			fontSize: '18px',
			borderTopRightRadius: '8px',
			borderTopLeftRadius: '8px',
			fontFamily: theme.fonts.buttonBold,
			'& i': {
				position: 'relative',
				top: '1px'
			}
		},
		'& .content > div': {
			display: 'block',
			textAlign: 'left',
			color: theme.colors.grey,
			fontFamily: theme.fonts.context,
			marginTop: '12px',
			padding: '0 12px',
			'&:last-of-type': {
				marginBottom: '16px'
			}
		}
	}
}));

const WordCardInContext = (props) => {
	const { wordCard, onComplete } = props;
	const classes = useStyle();

	const contextActivity = wordCard.word_card_activities.filter((act) => {
		return act.word_card_activity.activity_server_id === 'words_in_context';
	})[0];

	const contextWords = contextActivity.word_card_activity.answers.map((a) => {
		return a.text;
	});

	return (
		<div className={`${classes.wrapper} animate__animated animate__bounceIn`}>
			<div className='header'>
				<i className='r180 wordincontext mr-1' />
				Words in Context
			</div>
			<div className='content'>
				{
					contextWords.map((s) => {
						return <div key={s} className='word-entry' dangerouslySetInnerHTML={{ __html: stripHtmlTags(s)}}></div>
					})
				}
			</div>
		</div>
	)
}


WordCardInContext.defaultProps = {
	onComplete: () => {}
};

WordCardInContext.propTypes = {
	onComplete: PropTypes.func
};

export default React.memo(WordCardInContext);
