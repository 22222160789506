import React, { useState } from 'react';
import { ActivityInstructionButton, ActivitySupportButton, SpellingList, WordList, StartActivity } from '@reading/common';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import useIdleHelp from '../../utils/useIdleHelp';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { uiSlice } from '../../store/slices/ui';
import { SpellingAssessment } from './SpellingAssessment';
import { cloneDeep, isEmpty } from 'lodash';
import { getContentInfo } from '../../media/mediaUtil';
import media from '../../api/media';
import ActivityFrame from '../../containers/App/ActivityFrame';
import { sleep } from '../../utils/sleep';
import { transitionFromResultsPageToNextActivity } from '../../utils/navigation';
import useBatchedSetState from '../../utils/useBatchedSetState';

export const renderView = renderItems => {
	const {
		correctWordList,
		incorrectWordList,
		showReviewPage,
		interactive,
		handleWordFocus,
		handleKeyPress,
		wordList,
		handleTrialCompleted,
		trailRoundCount,
		selectedWord,
		showIntroPage,
		handleStartActivity
	} = renderItems;
	return showReviewPage ? (
		<div className="mt-4" style={{ margin: 'auto', textAlign: 'center' }}>
			<div style={{ display: 'inline-block', marginRight: '56px' }}>
				<WordList
					wordList={correctWordList.map(word => {
						return word;
					})}
				/>
			</div>
			<div style={{ display: 'inline-block' }}>
				<WordList
					isCorrect={false}
					wordList={incorrectWordList.map(word => {
						return word;
					})}
				/>
			</div>
		</div>
	) : (
		<div style={{ position: 'relative' }} className='fill-app-frame'>
			{showIntroPage ?
				<StartActivity
					id={'StartActivityPage'}
					onStartActivity={handleStartActivity}
					startActivityAudioSrc={SpellingAssessment.SOUND_INTRO}
				/> :
				<SpellingList
					interactive={interactive}
					onWordFocus={handleWordFocus}
					onWordKeyPress={handleKeyPress}
					wordObjList={wordList}
					onTrialCompleted={handleTrialCompleted}
					selectedWord={selectedWord}
					trailCount={trailRoundCount}
				/>
			}
		</div>
	);
};

export const handleForwardClick = async (forwardData) => {
	const {
		status,
		setSelectedWord,
		isCompleted,
		setAllInteractive,
		setStatus,
		loadNextRoundOfTrail,
		prepareReviewPageData,
		showReviewPage,
		history,
		dispatch,
		activityData,
		wordfluency,
		totalWordsToAssess,
		typedWordMap
	} = forwardData;

	AudioPlayer2.stopAll();
	setSelectedWord('');

	// start the activity over
	if (status === FooterForwardBack.VALID && !isCompleted) {
		setAllInteractive(true);
		setStatus(FooterForwardBack.INITIAL);
		loadNextRoundOfTrail();
	}
	// we're done with the activity, go to the next one
	else if (showReviewPage && isCompleted) {
		try {
			await transitionFromResultsPageToNextActivity(history);
		} catch (err) {
			dispatch(uiSlice.actions.setErrorMessage(err));
		}
	}
	// they're looking at 10 rows and ready to move to the 2 tables showing incorrect and correct
	else if (status === FooterForwardBack.VALID && isCompleted) {
		const fluencyUpdate = Object.keys(typedWordMap).map((key) => {
			return {
				wordId: key,
				score: typedWordMap[key] ? SpellingAssessment.FLUENT : SpellingAssessment.MISSED
			}
		});

		const newWordFluency = {
			studentSegmentId: wordfluency.studentSegmentId,
			assessmentZone: 'spelling_zone',
			fluencyScores: fluencyUpdate
		};

		const newFluencyAssessment = {
			totalWordsToAssess: totalWordsToAssess,
			studentSegmentId: wordfluency.studentSegmentId,
			fluencyAssessmentZone: "spelling_zone"
		};

		prepareReviewPageData();

		SpellingAssessment.completeActivityNoTransition(activityData, newFluencyAssessment, newWordFluency);
	}
};

export const isActivityCompleted = (newTotalIncorrectCount, trailRoundCount, wordMap, wordfluency, contentData) => {
	let isCompleted = false;
	let totalTrailCount = wordfluency.fluencyScores.length + Object.keys(wordMap).length;

	if (newTotalIncorrectCount >= SpellingAssessment.MAX_INCORRECT_TRIALS) {
		isCompleted = true;
	}
	if (trailRoundCount >= SpellingAssessment.MAX_TRIAL_ROUND_COUNT && newTotalIncorrectCount === 0) {
		isCompleted = true;
	}
	if (trailRoundCount > SpellingAssessment.MAX_TRIAL_ROUND_COUNT) {
		isCompleted = true;
	}
	if (totalTrailCount >= contentData.assessment_words.length) {
		isCompleted = true;
	}

	return isCompleted;
};

export default function SpellingAssessmentActivity(props) {

	const dispatch = useDispatch();
	const history = useHistory();
	const { setState } = useBatchedSetState();

	const { activityData, powerWordData, settings, wordfluency } = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			powerWordData: state.activity.powerwords,
			settings: state.session.session.settings,
			wordfluency: state.activity.wordfluency
		};
	});

	const [contentData, setContentData] = useState({});

	useDeepCompareEffect(() => {
		const loadMedia = async () => {
			const { urlPath } = getContentInfo(activityData, true);

			if (urlPath !== '/') {
				const mediaData = await media.getOne(urlPath);
				setContentData(mediaData);
			}
		};

		if (isEmpty(activityData) === false) {
			loadMedia();
		}
	}, [activityData]);


	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	// check if the activity is already charged
	const isActivityCharged = useSelector(state => {
		return state.ui.chargedActivities['spelling-assessment'] === true;
	});

	const isActivity = activityData ? activityData.activityServerId === 'spelling_assessment' : false;

	const [status, setStatus] = useState(FooterForwardBack.INITIAL);
	const [interactive, setInteractive] = useState({});
	const [wordList, setWordList] = useState([]);
	const [selectedWord, setSelectedWord] = useState('');
	const [typedWordMap, setTypedWordMap] = useState({});
	const [totalInCorrectCount, setTotalInCorrectCount] = useState(0);
	const [isCompleted, setIsCompleted] = useState(false);
	const [trailRoundCount, setTrailRoundCount] = useState(1);
	const [totalSpellingWordList, setTotalSpellingWordList] = useState([]);
	const [showReviewPage, setShowReviewPage] = useState(false);
	const [correctWordList, setCorrectWordList] = useState([]);
	const [incorrectWordList, setIncorrectWordList] = useState([]);
	const [isGrading, setGrading] = useState(false);
	const [showIntroPage, setShowIntroPage] = React.useState(true);
	const [selectedWordId, setSelectedWordId] = useState('');

	useDeepCompareEffect(() => {
		const initialize = async () => {
			if (isActivityCharged === false) {
				dispatch(uiSlice.actions.setCharging());
			}

			const { spellingWords, previousIncorrectWordsCount, pendingSpellingWordList } = await SpellingAssessment.init(
				activityData,
				contentData,
				wordfluency,
				isActivityCharged,
				settings
			);

			let interactiveMap = {};
			wordList.forEach(word => {
				interactiveMap[word.text] = true;
			});

			setState(() => {
				setWordList(spellingWords);
				setTotalInCorrectCount(previousIncorrectWordsCount);
				setTotalSpellingWordList(pendingSpellingWordList);
				setInteractive(interactiveMap);
			});

			dispatch(uiSlice.actions.setActivityCharged('spelling-assessment'));
			if (settings.autoPlayEnabled === true) {
				await sleep(1000);
				AudioPlayer2.play(SpellingAssessment.SOUND_INTRO);
			}
		};

		if (contentData && contentData.assessment_words && isActivity) {
			initialize();
		}

		/* eslint-disable */
	}, [activityData, contentData, powerWordData]);

	const setAllInteractive = isInteractive => {
		let newInteractive = cloneDeep(interactive);
		Object.keys(newInteractive).forEach(word => {
			newInteractive[word] = isInteractive;
		});
		setInteractive(newInteractive);
	};

	const handleForward = e => {
		const totalWordsToAssess = contentData.assessment_words.length;
		const forwardData = {
			status,
			setSelectedWord,
			isCompleted,
			setAllInteractive,
			setStatus,
			loadNextRoundOfTrail,
			prepareReviewPageData,
			showReviewPage,
			history,
			dispatch,
			activityData,
			wordfluency,
			totalWordsToAssess,
			typedWordMap
		};

		handleForwardClick(forwardData);
	};

	const prepareReviewPageData = () => {
		let correctwordList = [];
		let incorrectwordList = [];

		let previousTypedWordMap = {};
		let totalWordMap = {};

		wordfluency.fluencyScores.forEach(word => {
			if (word.score === 'Fluent') {
				previousTypedWordMap[word.wordId] = true;
			} else {
				previousTypedWordMap[word.wordId] = false;
			}

		});


		totalWordMap = { ...previousTypedWordMap, ...typedWordMap }
		contentData.assessment_words.forEach(word => {
			if (totalWordMap[word.text] === true) {
				correctwordList.push(word.text);
			}
			if (totalWordMap[word.text] === false) {
				incorrectwordList.push(word.text);
			}
		});
		setState(() => {
			setCorrectWordList(correctwordList);
			setIncorrectWordList(incorrectwordList);
			setShowReviewPage(true);
		});
	};

	const validate = async (wordMap) => {
		AudioPlayer2.stopAll();
		// wait 700ms to start grading it
		await sleep(700);

		const updatedI = {};
		let newTotalIncorrectCount = totalInCorrectCount;
		wordList.forEach(async (word, idx) => {
			// sleep for 1 second x the word order
			await sleep(1000 * idx);

			updatedI[word.text] = false;
			if (wordMap[word.text]) {
				AudioPlayer2.play(SpellingAssessment.SFX_CORRECT);
			} else {
				AudioPlayer2.play(SpellingAssessment.SFX_INCORRECT);
				newTotalIncorrectCount = newTotalIncorrectCount + 1;
			}
			setState(() => {
				setInteractive({ ...interactive, ...updatedI });
				setTotalInCorrectCount(newTotalIncorrectCount);
			});
		});

		// sleep to let the grading finish
		await sleep(wordList.length * 1000 + 500);

		setGrading(false);

		if (isActivityCompleted(newTotalIncorrectCount, trailRoundCount, wordMap, wordfluency, contentData)) {
			AudioPlayer2.play(SpellingAssessment.SOUND_FEEDBACK);
			setIsCompleted(true);

			SpellingAssessment.sendProgressToServer(activityData,
				wordfluency,
				wordMap,
				contentData.assessment_words.length);

			setStatus(FooterForwardBack.VALID);
		} else {
			AudioPlayer2.play(SpellingAssessment.SOUND_FEEDBACK);
			setIsCompleted(false);

			SpellingAssessment.sendProgressToServer(activityData,
				wordfluency,
				wordMap,
				contentData.assessment_words.length);

			setStatus(FooterForwardBack.VALID);
		}
	};

	const loadNextRoundOfTrail = () => {
		const trailroundStartIndex = trailRoundCount * SpellingAssessment.TRIAL_SIZE;
		const newTrailRoundCount = trailRoundCount + 1;
		const nextRoundWordList = totalSpellingWordList.slice(
			trailroundStartIndex,
			Math.min(SpellingAssessment.TRIAL_SIZE, totalSpellingWordList.length) * newTrailRoundCount
		);
		SpellingAssessment.loadNextRoundWordSound(nextRoundWordList, activityData);
		setState(() => {
			setTrailRoundCount(newTrailRoundCount);
			setWordList(nextRoundWordList);
		});
	};

	const handleWordFocus = (word, id) => {
		setSelectedWord(word);
		setSelectedWordId(id);
		document.getElementsByClassName('spell-input')[id].focus();
	};

	const handleKeyPress = async (word, typedWord, isHomophoneWord) => {
		AudioPlayer2.stopAll();
		setSelectedWord('');
		if (isHomophoneWord) {
			AudioPlayer2.play(SpellingAssessment.SOUND_WRONG_HOMOPHONE);
		}

		// figure out if what they typed is correct
		const isCorrect = word === typedWord;
		let newWordMap = cloneDeep(typedWordMap);
		newWordMap[word] = isCorrect;
		setTypedWordMap(newWordMap);

		await sleep(300)
		if (typedWord) {
			SpellingAssessment.sendWordResultToServer(activityData, isCorrect, word);
		}
	};

	const handleWordClick = e => {
		AudioPlayer2.stopAll();
		if (isEmpty(selectedWord) === false) {
			AudioPlayer2.play(selectedWord);
			document.getElementsByClassName('spell-input')[selectedWordId].focus();
		}
	};

	const handleSentenceClick = e => {
		AudioPlayer2.stopAll();
		if (isEmpty(selectedWord) === false) {
			AudioPlayer2.play(`ctx-${selectedWord}`);
			document.getElementsByClassName('spell-input')[selectedWordId].focus();
		}
	};

	const handleTrialCompleted = (word, typedWord) => {
		setGrading(true);
		const isCorrect = word === typedWord;
		let newWordMap = cloneDeep(typedWordMap);
		newWordMap[word] = isCorrect;
		setTypedWordMap(newWordMap);

		if (trailRoundCount <= SpellingAssessment.MAX_TRIAL_ROUND_COUNT) {
			if (Object.keys(newWordMap).length >= wordList.length * trailRoundCount) {
				validate(newWordMap);
			}
		} else {
			if (Object.keys(newWordMap).length - SpellingAssessment.MAX_TRIAL_COUNT >= wordList.length) {
				validate(newWordMap);
			}
		}
	};

	const renderViewBasedOnCondition = () => {
		const renderItems = {
			correctWordList,
			incorrectWordList,
			showReviewPage,
			interactive,
			handleWordFocus,
			handleKeyPress,
			wordList,
			handleTrialCompleted,
			trailRoundCount,
			selectedWord,
			showIntroPage,
			handleStartActivity
		};

		return renderView(renderItems);
	};

	const handleStartActivity = async () => {
		AudioPlayer2.stopAll();
		setShowIntroPage(false);
		await sleep(600);
	}


	const helpSound = showReviewPage === false ? SpellingAssessment.SOUND_HELP_TRIAL : SpellingAssessment.SOUND_HELP_FEEDBACK;
	const instructionSound = showReviewPage === false ? SpellingAssessment.SOUND_INTRO : SpellingAssessment.SOUND_FEEDBACK;

	useIdleHelp(SpellingAssessment.SOUND_HELP_TRIAL);

	// workaround because setting the status to VALID causes errors
	let buttonStatus = status;
	if (buttonStatus === FooterForwardBack.INTIAL || FooterForwardBack.VALID) {
		buttonStatus = FooterForwardBack.VALID;
	}

	return (
		<>
			<Navbar helpSoundUrl={helpSound} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={[wordList]}
				title="Spelling Assessment"
			>

				<ActivityFrame isWhiteBackground={showReviewPage === false}>
					<>
						<ActivityInstructionButton audioSrc={instructionSound} white={showReviewPage} />
						{renderViewBasedOnCondition()}
					</>
				</ActivityFrame>

				<Footer>
					{
						showReviewPage === false &&
						<>
							<ActivitySupportButton
								icon="word"
								onClick={handleWordClick}
								text="Word"
								disabled={isEmpty(selectedWord) || status !== FooterForwardBack.INITIAL || isGrading}
							/>
							<ActivitySupportButton
								icon="sentence"
								onClick={handleSentenceClick}
								text="Sentence"
								disabled={isEmpty(selectedWord) || status !== FooterForwardBack.INITIAL || isGrading}
							/>
						</>
					}

					<FooterForwardBack
						status={buttonStatus}
						isForwardDisabled={status === FooterForwardBack.INITIAL}
						onForward={handleForward}
						isBackVisible={false}
					/>
				</Footer>

			</ActivitySuspense>
		</>
	);
}
