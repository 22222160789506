import React, { useRef, createRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import FeedBackHolder from './FeedBackHolder';
import { CorrectIncorrect } from '../..';

const useStyles = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		width: '336px',
		height: '42px',
		background: theme.colors.lightGrey,
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '4px',
		marginBottom: '8px',
		textAlign: 'left',
		transition: 'all 0.2s',
		'& span': {
			fontFamily: theme.fonts.ui,
			lineHeight: '35px',
			fontWeight: 'bold',
			color: theme.colors.slate,
			fontSize: '18px'
		},
		'& input': {
			width: 'auto',
			textAlign: 'left',
			fontSize: '18px',
			padding: '3px 8px 3px 13px',
			transition: 'all 0.2s',
			color: theme.colors.grey,
			background: theme.colors.lightGrey,
			border: 'none',
			fontFamily: theme.fonts.context,
			marginLeft: '30px',
			height: '40px',
			lineHeight: '40px',
			'&:focus': {
				outline: 'none'
			}
		},
		'&.hasFocus': {
			width: '416px',
			height: '56px',
			display: 'flex',
			alignItems: 'center',
			background: theme.colors.white,
			boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
			border: `4px solid ${theme.colors.readingZone.blue2}`,
			marginBottom: '6px',
			marginTop: '-2px',
			paddingLeft: '60px',
			'& input': {
				background: theme.colors.white,
				fontSize: '32px',
				color: theme.colors.black,
				marginLeft: '-30px',
				width: '85%'
			},
			'& span': {
				position: 'absolute',
				left: '10px',
				background: theme.colors.white,
				fontSize: '32px',
				color: theme.colors.slate,
				fontFamily: theme.fonts.ui,
				lineHeight: '35px'
			},
			'& span.audio': {
				display: 'block',
				right: '30px',
				position: 'absolute',
				color: theme.colors.slate,
				left: ' -45px',
				fontSize: ' 20px',
				width: '20px',
				height: '20px',
				top: '9px'
			}
		}
	},
	serialIndex: {
		position: 'absolute',
		left: '10px',
		fontFamily: theme.fonts.context,
		fontSize: '18px',
		color: theme.colors.grey
	},
	inputBox: {
		background: theme.colors.white,
		boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
		width: '396px',
		height: '64px',
		position: 'relative',
		borderBottom: `4px solid ${theme.colors.readingZone.blue2}`,
		padding: '9px 0px',
		'& input': {
			cursor: 'auto',
			background: theme.colors.white,
			fontSize: '32px',
			color: theme.colors.black,
			width: '85%',
			marginLeft: '0',
			paddingLeft: '60px',
			height: 'auto'
		},
		'& span': {
			background: theme.colors.white,
			fontSize: '32px',
			color: theme.colors.slate,
			fontFamily: theme.fonts.ui,
			lineHeight: '35px',
			fontWeight: 'bold'
		}
	},
	iconSection: {
		width: '24px',
		height: '24px',
		display: ' inline-block',
		margin: '30px 24px 0 0',
		verticalAlign: 'top',
		borderRadius: '50%',
		marginRight: '24px',
		position: 'absolute',
		right: ' 8px',
		top: '-30px'
	},
	audioIcon: {
		display: 'none'
	}
}));
const SpellingClinicInput = ({
	item,
	index,
	simpleInput,
	setStudyWord,
	text,
	setGoOnDisabled,
	handleForward,
	updateReportBoard,
	setFinalReport,
	finalReport,
	staticMode,
	wordsList,
	showIcon,
	showAudio,
	activeIndex,
	finalScreen
}) => {
	const classes = useStyles();
	const handleFocus = e => {
		if (e.currentTarget.value !== '') {
			setStudyWord(false);
		} else {
			setStudyWord(true);
		}
	};
	let [trailCount, setTrailCount] = useState(0);
	const [feedbackWord, setFeedbackWord] = useState('');
	const [showCorrectiveFeedback, setCorrectiveFeedback] = useState(false);

	const inputRef = useRef();

	useEffect(() => {
		inputRef && inputRef.current && inputRef.current.focus();
	}, []);

	const keyPressHandler = e => {
		AudioPlayer2.stopAll();

		const reg = /^[a-zA-Z\b]+$/;
		const key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
		if (!reg.test(key)) {
			e.preventDefault();
		}

		/*istanbul ignore else*/
		if (e.keyCode === 13) {
			if (e.currentTarget.value === text) {
				const report = [...finalReport];
				/*istanbul ignore next*/
				trailCount === 0
					? (report[index - 1] = true)
					: (report[index - 1] = false);
				setTimeout(() => {
					handleForward();
					setTrailCount(0);
				}, 800);
				setFinalReport(report);
				updateReportBoard(true);
				setGoOnDisabled(false);
			} else {
				const trailCounter = trailCount + 1;
				setTrailCount(trailCounter);
				setCorrectiveFeedback(true);
			}
		}
	};

	const handleStudyWord = hasStudyWord => {
		setStudyWord(hasStudyWord);
	};

	const showFeedback = showFeedbackPopUp =>
		setCorrectiveFeedback(showFeedbackPopUp);

	const updateFeedBackWord = word => setFeedbackWord(word);
	const showCorrectiveIcon = (staticMode && showIcon) || finalScreen;

	return (
		<div
			className={`${classes.wrapper} ${simpleInput ? classes.inputBox : ''
				} input-box`}
			key={index}
			tabIndex={0}
		>
			<span className={classes.serialIndex}>{++index}.</span>
			{showCorrectiveIcon && (
				<span className={classes.iconSection}>
					{/* {finalReport && finalReport[index - 1] ? ( */}
					<CorrectIncorrect />
					{/* ) : (
						<CorrectIncorrect correct={false} />
					)} */}
				</span>
			)}
			{showAudio && (
				<span className={`${classes.audioIcon} audio`}>
					<i className="r180 speaker-center" />
				</span>
			)}
			<FeedBackHolder
				index={index}
				setStudyWord={handleStudyWord}
				text={text}
				wordsList={wordsList}
				inputRef={inputRef}
				showFeedback={showFeedback}
				updateFeedBackWord={updateFeedBackWord}
				showCorrectiveFeedback={showCorrectiveFeedback}
			/>
			{simpleInput ? (
				<input
					disabled={false}
					type="text"
					key={index}
					autoComplete={'off'}
					maxLength={20}
					placeholder="Type word above"
					onKeyUp={handleFocus}
					onKeyDown={keyPressHandler}
					ref={inputRef}
					spellCheck={false}
					autoCorrect={'off'}
				/>
			) : (
				<input
					disabled={true}
					type="text"
					key={index}
					autoComplete={'off'}
					value={item.text}
					maxLength={20}
					spellCheck={false}
					autoCorrect={'off'}
				/>
			)}
		</div>
	);
};

SpellingClinicInput.defaultProps = {
	staticMode: false,
	showIcon: false,
	showAudio: false
};
SpellingClinicInput.propTypes = {
	staticMode: PropTypes.bool,
	showIcon: PropTypes.bool,
	showAudio: PropTypes.bool
};

export default SpellingClinicInput;
