import React, {useEffect} from 'react';
import reduxStore from './store/redux-setup'; // this must be the 2nd import
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import {isEmpty} from 'lodash';

import {RouteMap} from './routes/routes.js';
import {getUserContextAction} from './store/slices/userContext';
import ActivityRouter from './containers/App/ActivityRouter';
import AppContainer from './containers/App/AppContainer';

import {R180Theme} from '@reading/common';
import R180ThemeCss from '@reading/common/src/themes/R180Theme/R180ThemeCss';
import 'animate.css/animate.css';
import UseIdleTimeout from './utils/useIdleTimeout';
import {CLIENT_BASE_URL} from './utils/constants';

const App = props => {
	const {userContext} = reduxStore.getState();

	useEffect(() => {
		if (isEmpty(userContext.sifToken)) {
			const getUserContext = async () => {
				console.log('UserContext not found, retrieving from server');
				await reduxStore.dispatch(getUserContextAction({correlationIdParam: 'local'}));
			};
			getUserContext();
		}
	});

	return (
		<R180Theme>
			<R180ThemeCss />
			<Provider store={reduxStore}>
				<Router>
					<AppContainer>
						<UseIdleTimeout />
						<Switch>
							<Route path={`${CLIENT_BASE_URL}/zone`}>
								<ActivityRouter />
							</Route>
							{RouteMap.map(({exact, path, component}) => (
								<Route key={`route_${path}`} exact={exact} path={path}>
									{component({})}
								</Route>
							))}
						</Switch>
					</AppContainer>
				</Router>
			</Provider>
		</R180Theme>
	);
};

export default App;
