import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {isEmpty} from 'lodash';
import {displaySafe} from '@reading/r180/src/utils/stringUtils';

const useStyles = makeStyles(theme => ({
	wordTitle: {
		position: 'relative',
		height: 'auto',
		width: 'auto',
		display: 'inline-flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		background: theme.colors.softBlack,
		borderRadius: '8px',
		padding: '16px 32px',
		textAlign: 'center',
		'& span': {
			fontSize: '32px',
			color: theme.colors.white,
			fontWeight: '600',
			lineHeight: '26px',
			fontFamily: theme.fonts.context
		},
		'& .word-title-speaker': {
			position: 'relative',
			marginRight: '20px',
			transition: 'transform 0.3s ease',
			'&:hover': {
				transform: 'scale(1.2)'
			},
			'& i': {
				color: theme.colors.slate,
				fontSize: '20px',
				'&.playing': {
					color: theme.colors.readingZone.primary
				}
			}
		},
		'& .title-text': {
			padding: '6px 0'
		}
	},
	commonQuestionTitle: {
		background: theme.colors.white,
		'& span': {
			color: theme.colors.black
		}
	},
	commonWordAnim: {
		backgroundColor: '#FFFCB0',
		borderBottom: '1px solid #131313'
	}
}));

const WordTitle = props => {
	const {
		id,
		className,
		style,
		word,
		src,
		commonText,
		isVoiceOverComplete,
		specificWordComplete,
		commonTextSpeaker,
		staticMode,
		onSpeakerComplete
	} = props;
	const classes = useStyles();

	const [isCommonTextAnim, setCommonTextAnim] = useState(false);
	const [playing, setPlaying] = useState(false);

	/* istanbul ignore next */
	useEffect(() => {
		if (isEmpty(word) === false) {
			const audioPlayer = AudioPlayer2.load({
				name: word,
				src: src
			});

			audioPlayer.onStop = () => {
				setPlaying(false);
				if (typeof onSpeakerComplete === 'function') {
					onSpeakerComplete();
				}
			};

			return () => {
				delete audioPlayer.onStop;
			};
		}
	}, [word]);

	useEffect(() => {
		if (commonText) {
			/* istanbul ignore else */
			if (isVoiceOverComplete) {
				const audioPlayer = AudioPlayer2.load({
					src: src,
					name: word
				});
				AudioPlayer2.stopAll();
				setCommonTextAnim(true);
				audioPlayer.play(word);
				/* istanbul ignore next */
				audioPlayer.onEnd = () => {
					setCommonTextAnim(false);
					specificWordComplete();
					if (typeof onSpeakerComplete === 'function') {
						onSpeakerComplete();
					}
				};
			}
		}
	}, [commonText, isVoiceOverComplete]);

	const handleClick = async e => {
		if (staticMode) {
			return false;
		}
		e.stopPropagation();
		/* istanbul ignore next */
		if (playing === false) {
			AudioPlayer2.stopAll();
			setPlaying(true);
			await AudioPlayer2.playSync(word);
			setPlaying(false);
			if (typeof onSpeakerComplete === 'function') {
				onSpeakerComplete();
			}
		} else {
			AudioPlayer2.stopAll();
			setPlaying(false);
		}
	};

	return (
		<div
			id={id}
			className={`${classes.wordTitle} ${className} ${commonText &&
				classes.commonQuestionTitle} word-title`}
			style={style}
		>
			{commonTextSpeaker && (
				<button
					className="word-title-speaker"
					data-testid="word-title"
					onClick={e => {
						handleClick(e);
					}}
				>
					<i
						className={`r180 speaker-center ${
							playing ? 'playing' : ''
						}`}
					/>
				</button>
			)}
			<button onClick={handleClick} className={`title-text`}>
				<span
					className={`${
						isCommonTextAnim ? classes.commonWordAnim : ''
					}`}
				>
					{displaySafe(word)}
				</span>
			</button>
		</div>
	);
};

WordTitle.defaultProps = {
	id: '',
	className: '',
	style: {},
	word: '',
	src: '',
	commonTextSpeaker: true,
	voiceOverComplete: false,
	commonText: false,
	specificWordComplete: () => {},
	staticMode: false
};
WordTitle.propTypes = {
	id: PropTypes.string,
	className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	style: PropTypes.object,
	word: PropTypes.string.isRequired,
	src: PropTypes.string.isRequired,
	commonTextSpeaker: PropTypes.bool,
	voiceOverComplete: PropTypes.bool,
	commonText: PropTypes.bool,
	specificWordComplete: PropTypes.func,
	staticMode: PropTypes.bool,
	onSpeakerComplete: PropTypes.func
};

export default React.memo(WordTitle);
