import React, { useRef, useState, useEffect } from 'react';
import { ActivityInstructionButton, ActivitySupportButton, StartActivity, WordAssessment as WordAssessmentComponent } from '@reading/common';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import { useDeepCompareEffect, useUnmount } from 'react-use';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import useIdleHelp from '../../utils/useIdleHelp';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import { uiSlice } from '../../store/slices/ui';
import { WordAssessment } from './WordAssessment';
import ResultsDisplay from './ResultsDisplay';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import ActivityFrame from '../../containers/App/ActivityFrame';
import { useHistory } from 'react-router';
import { sleep } from '../../utils/sleep';
import { transitionFromResultsPageToNextActivity } from '../../utils/navigation';
import useBatchedSetState from '../../utils/useBatchedSetState';

export default function WordAssessmentActivity(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { setState } = useBatchedSetState();

	const currentIndex = useRef(0);
	const timer = useRef(0);

	const { isActivityCharged, settings, isZoneMenuModalOpen, isExitModalOpen } = useSelector(state => {
		return {
			isActivityCharged: state.ui.chargedActivities['word-assessment'] === true,
			settings: state.session.session.settings,
			isZoneMenuModalOpen: state.ui.isZoneMenuModalOpen,
			isExitModalOpen: state.ui.isExitModalOpen
		};
	});

	const { activityData, fluencyAssessment, wordFluency } = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			fluencyAssessment: state.activity.fluencyassessment,
			wordFluency: state.activity.wordfluency
		};
	});

	const isActivity = activityData ? activityData.activityServerId === 'word_assessment' : false;

	const [wordsList, setWordsList] = useState([]);
	const [currentWord, setCurrentWord] = useState({});
	const [results, setResults] = useState({ fluent: [], slow: [], missed: [] });
	const [timedOutWords, setTimedOutWords] = useState([]);
	const [isComplete, setComplete] = useState(false);
	const [status, setStatus] = useState(FooterForwardBack.VALID);
	const [isPaused, setPaused] = useState(false);
	const [showResult, setShowResult] = useState(false);
	const [disableActivity, setDisableActivity] = useState(false);
	const [showSlowWords, setShowSlowWords] = useState(false);
	const [scoreMap, setScoreMap] = useState({});
	const [isStarted, setStarted] = useState(false);
	const [responseRateList, setResponseRateList] = useState([]);
	const [fluencyScore, setFluencyScore] = useState([]);
	const totalWordsToAssess = useRef(0);
	const [isActivityButtonsDisabled, setActivityButtonsDisabled] = useState(false);

	useDeepCompareEffect(() => {
		if (!isEmpty(fluencyAssessment)) {
			timer.current = fluencyAssessment.accuracyOnly ? 0 : WordAssessment.TIME_OUT;
			setShowSlowWords(!Boolean(fluencyAssessment.accuracyOnly));
		}

		const loadMedia = async () => {
			const { assessmentWords, scoreMap: scores, totalWordsToAssessCount } = await WordAssessment.loadWordsList(activityData, wordFluency);
			totalWordsToAssess.current = totalWordsToAssessCount;
			setState(() => {
				setWordsList(assessmentWords);
				setScoreMap(scores);
				setCurrentWord(assessmentWords[currentIndex.current || 0]);
			});
		};

		if (isActivity && isEmpty(activityData) === false) {
			loadMedia();
		}
	}, [activityData]);


	useDeepCompareEffect(() => {
		const initialize = async () => {
			await WordAssessment.init(activityData, wordsList, isActivityCharged);
			if (isActivityCharged === false) {
				dispatch(uiSlice.actions.setActivityCharged('word-assessment'));
				if (settings.autoPlayEnabled === true) {
					await sleep(1000);
					AudioPlayer2.play(WordAssessment.SOUND_INTRO);
				}
			}
		};

		if (isActivity && Array.isArray(wordsList)) {
			initialize();
		}
	}, [wordsList]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});

	const loadNextWord = async () => {
		AudioPlayer2.stopAll();
		currentIndex.current++;
		const nextWord = wordsList[currentIndex.current];
		setCurrentWord(nextWord);
		await sleep(600);
		AudioPlayer2.play(nextWord.name);
	};

	const getScore = (isCorrect, timeS) => {
		const thersold = fluencyAssessment.medianResposeRateMillis / 1000;
		// 1: fluent, 2: slow, 3: missed
		if (isCorrect) {
			if (timeS <= thersold) {
				return 1;
			}
			else {
				return 2;
			}
		}
		return 3;
	}

	const evaluateResult = (isCorrect, isComplete, timeS) => {
		setState(() => {
			const evalResults = WordAssessment.evaluateResults(scoreMap);
			setResults(evalResults);

			let newFluencyScore = cloneDeep(fluencyScore);
			newFluencyScore.push({
				wordId: currentWord.name,
				score: WordAssessment.TRIAL_SCORE[getScore(isCorrect, timeS)]
			});
			setFluencyScore(newFluencyScore);

			const newResponseList = [...responseRateList, timeS * 1000];
			setResponseRateList(newResponseList);

			//WordAssessment.sendProgressToServer(activityData, fluencyAssessment, newFluencyScore, newResponseList);
		});

		if (isComplete) {
			setComplete(true);
			handleTrialComplete();
		}
	}

	const validate = (isCorrect, timeS) => {
		const wordObj = currentWord;
		const scoreItem = scoreMap[currentWord.name];

		scoreItem[wordObj.trialNo] = getScore(isCorrect, timeS);
		const newScoreMap = { ...scoreMap, [currentWord.name]: scoreItem };
		setScoreMap(newScoreMap);

		if (wordObj.trialNo < 3) {
			wordObj.trialNo++;

			if (WordAssessment.isThirdRound(scoreItem)) {
				setWordsList([...wordsList, wordObj]);
			}
		}

		if (wordsList.length - 1 === currentIndex.current) {
			// Words list done
			evaluateResult(isCorrect, true, timeS);
		} else {
			evaluateResult(isCorrect, false, timeS);
			loadNextWord();
		}
	};


	const handleWordTileClick = (word, isCorrect, timeS) => {
		// Stamp the times as the timeTaken for the answer
		AudioPlayer2.stopAll();
		if (isCorrect) {
			AudioPlayer2.play(WordAssessment.SFX_CORRECT);
		} else {
			AudioPlayer2.play(WordAssessment.SFX_INCORRECT);
		}
		validate(isCorrect, timeS);
	};


	const handleTimedOut = (word, timeS) => {
		if (!isComplete) {
			AudioPlayer2.stopAll();
			setTimedOutWords([...timedOutWords, word]);
			AudioPlayer2.play(WordAssessment.SFX_SLOW);
			validate(false, timer.current * 1000);
		}
	};


	const handleWordClick = async (e) => {
		AudioPlayer2.stopAll();
		setState(() => {
			setActivityButtonsDisabled(true);
			setDisableActivity(true);
		});
		await AudioPlayer2.playSync(currentWord.name);
		setState(() => {
			setActivityButtonsDisabled(false);
			setDisableActivity(false);
		});
	};


	const handleSentenceClick = async (e) => {
		AudioPlayer2.stopAll();
		setState(() => {
			setActivityButtonsDisabled(true);
			setDisableActivity(true);
		});
		await AudioPlayer2.playSync(`ctx-${currentWord.name}`);
		setState(() => {
			setActivityButtonsDisabled(false);
			setDisableActivity(false);
		});
	};


	const handlePauseClick = e => {
		AudioPlayer2.stopAll();
		if (isPaused) {
			setPaused(false);
			AudioPlayer2.play(currentWord.name);
		}
		else {
			setPaused(true);
			AudioPlayer2.play(WordAssessment.SOUND_PAUSE);
		}
	};

	useEffect(() => {
		if (isZoneMenuModalOpen) {
			setState(() => {
				setPaused(true);
				setActivityButtonsDisabled(true);
				setDisableActivity(true);
			});
		}
		else {
			setState(() => {
				setPaused(false);
				setActivityButtonsDisabled(false);
				setDisableActivity(false);
				AudioPlayer2.play(currentWord.name);
			});
		}
	}, [isZoneMenuModalOpen]);

	useEffect(() => {
		if (isExitModalOpen) {
			setState(() => {
				setPaused(true);
				setActivityButtonsDisabled(true);
				setDisableActivity(true);
			});
		}
		else {
			setState(() => {
				setPaused(false);
				setActivityButtonsDisabled(false);
				setDisableActivity(false);
				AudioPlayer2.play(currentWord.name);
			});
		}
	}, [isExitModalOpen]);

	const handleForward = async (e) => {
		if (showResult === false) {
			AudioPlayer2.stopAll();
			setState(() => {
				setShowResult(true);
				setStatus(FooterForwardBack.VALID);
			});
			await sleep(800);
			AudioPlayer2.play(WordAssessment.SOUND_COMPLETE);

			// complete the activity without navigating, no need to await the response
			WordAssessment.completeActivityNoTransition(activityData, fluencyAssessment, convertFluencyScores(results), responseRateList, totalWordsToAssess.current);
		}
		else {
			try {
				await transitionFromResultsPageToNextActivity(history);
			} catch (err) {
				dispatch(uiSlice.actions.setErrorMessage(err));
			}
		}
	};

	const convertFluencyScores = (scores) => {
		let calcScores = [];
		scores.fluent.forEach((word) => {
			calcScores.push({
				wordId: word,
				score: WordAssessment.FLUENT
			});
		});
		scores.missed.forEach((word) => {
			calcScores.push({
				wordId: word,
				score: WordAssessment.MISSED
			});
		});
		scores.slow.forEach((word) => {
			calcScores.push({
				wordId: word,
				score: WordAssessment.SLOW
			});
		});
		return calcScores;
	};

	const handleTrialComplete = () => {
		AudioPlayer2.stopAll();
	};

	const handleStartActivity = async () => {
		AudioPlayer2.stopAll();
		await sleep(300);
		setStarted(true);
		await sleep(400);
		AudioPlayer2.play(currentWord.name);
	}

	const helpSound = showResult ? WordAssessment.SOUND_RESULTS_HELP : isStarted ? WordAssessment.SOUND_INPROGRESS_HELP : WordAssessment.SOUND_HELP;

	useIdleHelp(helpSound);

	return (
		<>
			<Navbar helpSoundUrl={helpSound} />

			<ActivitySuspense
				showSpinner={isActivityCharged === false}
				requiredRenderData={[wordsList, currentWord]}
				title="Word Assessment"
			>
				<ActivityFrame isWhiteBackground={showResult === false}>
					<>
						<ActivityInstructionButton audioSrc={WordAssessment.SOUND_INTRO} white={showResult} disabled={isActivityButtonsDisabled}/>
						{
							isStarted === false
								?
								<StartActivity
									onStartActivity={handleStartActivity}
									startActivityAudioSrc={WordAssessment.SOUND_INTRO}
								/>
								:
								showResult === false
									?
									<div style={{ position: 'relative', height: '100%' }}>
										<WordAssessmentComponent
											id={`word-assessment-${currentIndex.current}`}
											showFeedBack={true}
											timeOut={timer.current}
											showTimer={true}
											words={currentWord.words}
											correctWord={{ name: currentWord.name }}
											isPaused={isPaused}
											isDisabled={disableActivity}
											onTimeOut={handleTimedOut}
											onWordClick={handleWordTileClick}
										/>
									</div>
									:
									<ResultsDisplay
										className="mt-2"
										correctWords={results.fluent}
										timedOutWords={results.slow}
										inCorrectWords={results.missed}
										showTimedOutWords={showSlowWords}
									/>
						}
					</>
				</ActivityFrame>

				{
					isStarted === true &&
					<Footer>
						{
							showResult === false &&
							<>
								<ActivitySupportButton
									icon="word"
									onClick={handleWordClick}
									text="Word"
									disabled={isComplete || isPaused || isActivityButtonsDisabled}
								/>
								<ActivitySupportButton
									icon="sentence"
									onClick={handleSentenceClick}
									text="Sentence"
									disabled={isComplete || isPaused || isActivityButtonsDisabled}
								/>
								<ActivitySupportButton
									icon={isPaused ? 'play' : 'pause'}
									onClick={handlePauseClick}
									text={isPaused ? 'Resume' : 'Pause'}
									disabled={isComplete || isActivityButtonsDisabled}
								/>
							</>
						}

						<FooterForwardBack
							status={status}
							onForward={handleForward}
							isBackVisible={false}
							isForwardDisabled={(isComplete || showResult) === false}
						/>
					</Footer>
				}

			</ActivitySuspense>
		</>
	);
}
