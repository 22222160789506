import cookie from 'react-cookies';
import {getMediaServerURL} from './utils/mediaServerHelper';

const M180_DEFAULT_API_URI = '/api/m180/';
const BRAINARCADE_DEFAULT_API_URI = '/api/brainarcade/';
const ASSESSMENTS_DEFAULT_API_URI = '/api/assessments/';
const M180Y2_COMMUNITY_ID = 'm180y2';

class PlatformHelper {
	M180_DEFAULT_API_URI = '/api/m180/';
	M180Y2_COMMUNITY_ID = 'm180y2';
	ASSESSMENTS_DEFAULT_API_URI = '/api/assessments/';

	isM180OnSAM = env => {
		const sid = this.getSIDFromCookie();
		return sid || env.REACT_APP_USE_M180_ON_SAM === 'true';
	};

	isM180OnSAMLocally = env => {
		return env.REACT_APP_USE_M180_ON_SAM === 'true';
	};

	//ex. m180y2
	getCommunityIdFromCookie = () => cookie.load('community_id');

	getSIDFromCookie = () => {
		const communityID = this.getCommunityIdFromCookie();
		if (communityID) {
			const sidCookieName = `${communityID}client_sid`; // ex. m180y2client_sid
			return cookie.load(sidCookieName);
		}
		return null;
	};

	getPhaseIdFromCookie = () => {
		const communityID = this.getCommunityIdFromCookie();
		if (M180Y2_COMMUNITY_ID === communityID) {
			return 2;
		}
		return 1;
	};

	//TODO: this needs to be reevaluated by Andrew
	invalidateSIDCookie = () => {
		const communityID = this.getCommunityIdFromCookie();
		if (communityID) {
			const sidCookieName = `${communityID}client_sid`;
			cookie.remove(sidCookieName);
		}
	};

	getReferrerFromCookie = () => {
		const communityID = this.getCommunityIdFromCookie();
		if (communityID) {
			const referrerCookieName = `${communityID}client_referrer`; // ex. m180y2client_referrer
			const referrer = cookie.load(referrerCookieName);
			if (referrer) {
				return referrer.endsWith('/') ? referrer : `${referrer}/`;
			}
		}
		return null;
	};

	getApiUri = (appName = 'm180') => {
		const communityId = this.getCommunityIdFromCookie();
		switch (appName) {
			case 'm180':
			case 'tvss':
				return communityId
					? `${communityId}-api/`
					: M180_DEFAULT_API_URI;
			case 'mskills':
			case 'baseline':
				return communityId
					? `${communityId}-assessments/`
					: ASSESSMENTS_DEFAULT_API_URI;
			case 'dtm-ps':
			case 'dtm-ps-reports':
				return '/dtm-assessments/';
			case 'brainarcade':
				return this.isM180OnSAM(process.env)
					? this.getApiUri()
					: BRAINARCADE_DEFAULT_API_URI;
			default:
				return M180_DEFAULT_API_URI;
		}
	};

	getOverrideApiUri = () => {
		const communityId = this.getCommunityIdFromCookie();
		return communityId
			? `/${communityId}-assessments/`
			: ASSESSMENTS_DEFAULT_API_URI;
	};

	getApiHost = () => {
		const referrerHost = this.getReferrerFromCookie(); // ex. https://h511000002.education.scholastic.com/
		return referrerHost || '';
	};

	getCurrentHost = () => {
		if (window && window.location) {
			return window.location.origin;
		}
		return '';
	};

	getDashboardURL = () => {
		const referrer = this.getReferrerFromCookie();
		const communityID = this.getCommunityIdFromCookie();
		if (referrer && communityID) {
			return `${referrer}${communityID}/client`;
		}
		return '';
	};

	getMSkillsURL = assessmentId => {
		const dashboardURL = this.getDashboardURL();
		if (dashboardURL) {
			return `${dashboardURL}?app=mskills&assessmentId=${assessmentId}`;
		}
		return '';
	};

	getBaselineURL = assessmentId => {
		const dashboardURL = this.getDashboardURL();
		if (dashboardURL) {
			return `${dashboardURL}?app=baseline&assessmentId=${assessmentId}`;
		}
		return '';
	};

	getLogoutUrl = () => {
		const dashboardURL = this.getDashboardURL();
		if (dashboardURL) {
			return `${dashboardURL}/logout`;
		}
		return '';
	};

	/**
	 * Returns the relative location of media for a given application.
	 *
	 * If the application is running on a deployed SAM environment, the media path is retrieved from a cookie. In this
	 * case, the appName argument is ignored.
	 *
	 * If the application is running on Ed or a local SAM-configured deployment, the media path is retrieved in
	 * the form of '/math180/${appName}/media'.
	 *
	 * If the environment has specified a REACT_APP_MEDIA_URI, that URI takes priority and is returned.
	 *
	 * @param env the current environment
	 * @param appName the name of the application requesting the media.
	 * @returns {string|*} a relative location of the media
	 */

	getMediaPath = (env, appName = 'm180') => {
		const communityId = this.getCommunityIdFromCookie();
		if (communityId) {
			const mediaPathCookieName = `${communityId}client_mediapath`; // ex. m180y2client_mediapath
			return cookie.load(mediaPathCookieName);
		}
		const RELATIVE_PATH_LOCAL_OVERRIDE = env.REACT_APP_MEDIA_URI;
		switch (appName) {
			case 'mskills':
			case 'baseline':
				return (
					RELATIVE_PATH_LOCAL_OVERRIDE || `/math180/${appName}/media/`
				);
			case 'iwb':
				return (
					RELATIVE_PATH_LOCAL_OVERRIDE || '/math180/teacher/media/'
				);
			case 'dtm-ps':
			case 'dtm-ps-reports':
				return RELATIVE_PATH_LOCAL_OVERRIDE || '/math180/dtm-ps/media/';
			case 'tvss':
			case 'm180':
			default:
				return (
					RELATIVE_PATH_LOCAL_OVERRIDE || '/math180/student/media/'
				);
		}
	};

	/**
	 * Returns the relative location of media for a given application module
	 *
	 * This method is to be used by applications such as TVSS that needs to access multiple
	 * media repositories for its different modules
	 *
	 * If the application is running on a deployed SAM environment, the media path is
	 * retrieved from a cookie based on the module name.
	 *
	 * Examples:
	 * baseline_mediapath	    /math180/baseline/media/0.1.11-9/
	 * brainarcade_mediapath	/math180/brainarcade/media/0.1.11-23/
	 * mskills_mediapath		/math180/mskills/media/0.1.11-22/
	 * m180client_mediapath		/math180/student/media/0.1.40-121/
	 * teacher_mediapath		/math180/teacher/media/0.1.16-75/
	 *
	 * If the application is running on Ed or a local SAM-configured deployment, the media path is retrieved in
	 * the form of '/math180/${moduleName}/media'.
	 *
	 * If the environment has specified a REACT_APP_MEDIA_URI, that URI takes priority and is returned.
	 *
	 * Examples:
	 * REACT_APP_MEDIA_URI
	 * MSKILLS_REACT_APP_MEDIA_URI
	 * BA_REACT_APP_MEDIA_URI
	 *
	 * @param env the current environment
	 * @param appName the name of the application requesting the media.
	 * @returns {string|*} a relative location of the media
	 */

	getModuleMediaPath = (env, moduleName = 'student') => {
		const communityId = this.getCommunityIdFromCookie();
		if (communityId) {
			if (moduleName !== 'student') {
				return cookie.load(`${moduleName}_mediapath`); // ex : mskills_mediapath
			}
			return cookie.load(`${communityId}client_mediapath`); // ex: m180client_mediapath
		}
		switch (moduleName) {
			case 'mskills':
				return (
					env.MSKILLS_REACT_APP_MEDIA_URI || `/math180/mskills/media/`
				);
			case 'brainarcade':
				return env.BA_REACT_APP_MEDIA_URI || '/brainarcade/media/';
			case 'student':
			default:
				return env.REACT_APP_MEDIA_URI || '/math180/student/media/';
		}
	};

	/**
	 * Retrieves the URL of a media server - either a CDN or an MMA - for the given user.
	 *
	 * If the app is running on Ed, or a local SAM-configured deployment, it returns an empty string.
	 *
	 * @param userId the user that will be requesting media
	 * @returns {Promise<*[]>|string} the URL of a media server to use
	 */
	getMediaServer = userId => {
		if (this.isM180OnSAM(process.env)) {
			const apiHost = this.getApiHost();
			if (apiHost) {
				const apiURL = `${apiHost}/slms-api/media_servers?userId=${userId}`;
				return getMediaServerURL(apiURL);
			}
		}
		return '';
	};
}

const platformHelper = new PlatformHelper();

export default platformHelper;
