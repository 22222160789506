import React, {useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {TimelineMax, Power4} from 'gsap';
import clsx from 'clsx';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';

import {useStyle} from './WordCardParts.style';
import {
	useWordPartsDecoder,
	UseWordCardDispatch,
	usePreloader,
	useWordTextSound
} from '../WordCardContext';

import {
	WordCardPreloaderStatus,
	WordCardText,
	WordCardDecoder
} from '../constants';

import {WordCardUtil} from '../WordCardUtil';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';

const WordCardParts = ({onComplete, decodingInfo}) => {
	const wordParts = useWordPartsDecoder();
	const wordCardDispatch = UseWordCardDispatch();
	const preloader = usePreloader(WordCardDecoder.PARTS);
	const wordTextSound = useWordTextSound();

	const normalColor = 'rgba(255, 175, 102, 0)';
	const highlightColor = 'rgba(255, 175, 102, 1)';

	const timelineRef = useRef(null);
	const spritesRef = useRef(null);

	const classes = useStyle({normalColor});

	const init = useCallback(() => {
		timelineRef.current && timelineRef.current.pause();
		timelineRef.current && timelineRef.current.clear();
		timelineRef.current && timelineRef.current.kill();

		const partsSounds = wordParts.filter(
			w => w.type === WordCardText.NORMAL
		);

		if (!preloader) {
			wordCardDispatch({
				type: '@@word-card/preload-sound',
				payload: {
					name: WordCardDecoder.PARTS,
					assets: [wordTextSound, ...partsSounds]
				}
			});
		}
	}, [wordParts, preloader, wordCardDispatch, wordTextSound]);

	const start = useCallback(() => {
		timelineRef.current.play();
	}, []);

	const stop = useCallback(() => {
		AudioPlayer2.stopAll();
		onComplete();
	}, [onComplete]);

	const playSound = useCallback(
		sound => () => {
			AudioPlayer2.play(sound);
		},
		[]
	);

	const destroy = useCallback(() => {
		AudioPlayer2.stopAll();
		timelineRef.current && timelineRef.current.pause();
		timelineRef.current && timelineRef.current.clear();
		timelineRef.current && timelineRef.current.kill();
		timelineRef.current = null;
	}, []);

	const addEnterStageScene = useCallback(
		timeline => {
			const sprites = wordParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline.set(spritesRef.current, {opacity: 1}).staggerFrom(
				sprites,
				1,
				{
					transformOrigin: '50% 50%',
					y: -50,
					opacity: 0,
					ease: Power4.easeOut,
					autoAlpha: 0,
					onStart: playSound(
						KnowledgeForReading.WORD_CARD_SFX.SHOW_SOUND.name
					)
				},
				0.2
			);
		},
		[playSound, wordParts]
	);

	const addHighlightPartsScene = useCallback(
		timeline => {
			const normalWordParts = wordParts.filter(
				x => x.type === WordCardText.NORMAL
			);

			const delay = 0.1;

			normalWordParts.forEach(x => {
				const sprite = spritesRef.current.querySelector(`#${x.id}`);
				timeline.to(
					sprite,
					{
						duration: WordCardUtil.getDuration(x.audioName),
						delay,
						'background-color': highlightColor,
						onStart: playSound(x.audioName)
					},
					x.id
				);
			});
		},
		[highlightColor, playSound, wordParts]
	);

	const addConnectPartsScene = useCallback(
		timeline => {
			const normalWordParts = wordParts.filter(
				x => x.type === WordCardText.NORMAL
			);

			const separatorParts = wordParts.filter(
				x => x.type === WordCardText.SEPARATOR
			);

			const wordSprites = normalWordParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			const separatorSprites = separatorParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline
				.add('ConnectPartsScene', '+=0.35')
				.to(
					wordSprites,
					{
						duration: 0.35,
						delay: 1,
						'background-color': normalColor
					},
					'ConnectPartsScene'
				)
				.to(
					separatorSprites,
					{
						transformOrigin: '50% 50%',
						duration: 0.35,
						delay: 1,
						width: 0,
						height: 0,
						scale: 0,
						'margin-left': 0,
						'margin-right': 0,
						opacity: 0,
						onStart: playSound(
							KnowledgeForReading.WORD_CARD_SFX.CONTRACT_SOUND
								.name
						)
					},
					'ConnectPartsScene'
				);
		},
		[normalColor, playSound, wordParts]
	);

	const addReadWordTextScene = useCallback(
		timeline => {
			timeline
				.add(
					'ReadWordTextScene',
					`+=${WordCardUtil.getDuration(wordTextSound.audioName)}`
				)
				.set(spritesRef.current, {paddingLeft: 4, paddingRight: 4})
				.to(
					spritesRef.current,
					{
						duration: WordCardUtil.getDuration(
							wordTextSound.audioName
						),
						'background-color': highlightColor,
						onStart: playSound(wordTextSound.audioName)
					},
					'ReadWordTextScene'
				);
		},
		[highlightColor, playSound, wordTextSound]
	);

	const addNormalizeWordTextScene = useCallback(
		timeline => {
			timeline.add('NormalizeWordTextScene', '+=0.2').to(
				spritesRef.current,
				{
					duration: 0.2,
					delay: 0.2,
					'background-color': normalColor
				},
				'NormalizeWordTextScene'
			);
		},
		[normalColor]
	);

	const addLeaveStageScene = useCallback(
		timeline => {
			timeline.add('LeaveStageScene', '+=0.2').to(
				spritesRef.current,
				{
					duration: 0.2,
					delay: 1,
					opacity: 0,
					onStart: playSound(
						KnowledgeForReading.WORD_CARD_SFX.HIDE_SOUND.name
					),
					onComplete: () => stop()
				},
				'LeaveStageScene'
			);
		},
		[playSound, stop]
	);

	const setup = useCallback(
		decodingInfo => {
			const timeline = new TimelineMax({paused: true});
			/* Check if the Word  has split parts or single animation Word */
			const hasWordSplit = decodingInfo.parts.length > 0;

			addEnterStageScene(timeline);
			/* Ignore the split animation in case of single words like this, these,know */
			if (hasWordSplit) {
				addHighlightPartsScene(timeline);
				addConnectPartsScene(timeline);
			}
			addReadWordTextScene(timeline);
			addNormalizeWordTextScene(timeline);
			addLeaveStageScene(timeline);

			timelineRef.current = timeline;
		},
		[
			addEnterStageScene,
			addHighlightPartsScene,
			addConnectPartsScene,
			addReadWordTextScene,
			addNormalizeWordTextScene,
			addLeaveStageScene
		]
	);

	useEffect(() => {
		if (wordParts) {
			init();

			if (
				preloader &&
				preloader.status === WordCardPreloaderStatus.COMPLETED
			) {
				setup(decodingInfo);
				start();
			}
		}

		return () => {
			destroy();
		};
	}, [wordParts]);

	const renderWordParts = () => {
		return (
			<div className="sprites">
				<div ref={spritesRef} className={classes.spriteWrapper}>
					{wordParts &&
						wordParts.map(x => (
							<div
								className={clsx({
									[classes.normalPartsWrapper]:
										x.type === WordCardText.NORMAL,
									[classes.silentWrapper]:
										x.type === WordCardText.SILENT,
									[classes.plusWrapper]:
										x.type === WordCardText.SEPARATOR
								})}
								key={x.id}
								id={x.id}
							>
								<span>{x.text}</span>
							</div>
						))}
				</div>
			</div>
		);
	};

	return <div className={classes.wrapper}>{renderWordParts()}</div>;
};

WordCardParts.defaultProps = {
	onComplete: () => {}
};

WordCardParts.propTypes = {
	onComplete: PropTypes.func,
	decodingInfo: PropTypes.object
};

export default React.memo(WordCardParts);
