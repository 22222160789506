import DataStore from '../DataStore';
import Logger from '../Logger';

const STORE_KEY_CURRENT_ZONE = 'timeTrackerCurrentZone';
const STORE_KEY_DURATIONS = 'timeTrackerDurations';
const STORE_KEY_TASK_TIMERS = 'timeTrackerTaskTimers';

/**
 * Allows an app to measure the duration a user spends in particular areas of the app or on specific independent tasks.
 */
const TimeTracker = {
	durations: {},
	currentZone: null,
	taskTimers: {},
	initialized: false,

	/**
	 * Restores the TimeTracker with existing values from the DataStore
	 */
	initialize: () => {
		if (!DataStore.initialized) {
			throw new Error(
				'TimeTracker can only be initialized after the DataStore is initialized'
			);
		}
		TimeTracker.durations = DataStore.get(STORE_KEY_DURATIONS) || {};
		TimeTracker.taskTimers = DataStore.get(STORE_KEY_TASK_TIMERS) || {};
		TimeTracker.currentZone = DataStore.get(STORE_KEY_CURRENT_ZONE);
		TimeTracker.initialized = true;
	},

	/**
	 * Nullifies all data being held by the TimeTracker and updates the DataStore.
	 *
	 * @returns {Promise<void>}
	 */
	clear: async () => {
		TimeTracker.durations = {};
		TimeTracker.taskTimers = {};
		TimeTracker.currentZone = null;
		await DataStore.set(STORE_KEY_DURATIONS, {});
		await DataStore.set(STORE_KEY_TASK_TIMERS, {});
		await DataStore.set(STORE_KEY_CURRENT_ZONE, null);
	},

	/**
	 * Flushes the current state into the DataStore
	 */
	flush: async () => {
		if (!TimeTracker.initialized) {
			throw new Error(
				'TimeTracker can only be flushed after it has been initialized'
			);
		}
		Logger.debug('TimeTracker: flushing to DataStore');
		const currentZone = TimeTracker.currentZone;
		if (currentZone != null) {
			// update the duration for the zone that we have been in up to this point
			TimeTracker.__updateCurrentZoneDuration();
			// reestablish and start tracking the current zone
			const now = new Date();
			TimeTracker.currentZone = {
				zone: currentZone.zone,
				startTime: now.getTime()
			};
		}

		await DataStore.set(STORE_KEY_CURRENT_ZONE, TimeTracker.currentZone);
		await DataStore.set(STORE_KEY_DURATIONS, TimeTracker.durations);
	},

	/**
	 * Updates the TimeTracker to reflect the given session. Only the properties specified by the zoneKeys will be
	 * copied from the session, others are ignored.
	 *
	 * @param session The session to reflect
	 * @param zoneKeys The specific session properties to copy
	 */
	syncWithSession: async (session, zoneKeys, resetUsage) => {
		Logger.debug('TimeTracker: syncWithSession: ', session, zoneKeys);
		TimeTracker.durations = {};
		TimeTracker.currentZone = null;
		Object.keys(zoneKeys).forEach(key => {
			if (resetUsage) {
				TimeTracker.durations[zoneKeys[key]] = 0;
			} else {
				TimeTracker.durations[zoneKeys[key]] = session[zoneKeys[key]];
			}
		});
		await DataStore.set(STORE_KEY_DURATIONS, TimeTracker.durations);
	},

	/**
	 * Transition the TimeTracker from its current zone to the specified new zone.
	 *
	 * When the tracker moves to a new zone, it first retroactively calculates the amount of time that was spent
	 * in the current zone, and updates its internal durations object accordingly.
	 *
	 * @param zoneKey The zone to move to
	 */
	moveToZone: async zoneKey => {
		if (!TimeTracker.initialized) {
			Logger.warn(
				`Unable to move to zone ${zoneKey}, TimeTracker is not initialized.`
			);
			return;
		}
		const currentZoneName = TimeTracker.currentZone
			? TimeTracker.currentZone.zone
			: 'none';
		Logger.debug(
			`TimeTracker: Moving from ${currentZoneName} to ${zoneKey}`
		);
		if (TimeTracker.currentZone != null) {
			// update the duration for the zone that we have been in up to this point
			TimeTracker.__updateCurrentZoneDuration();
		}

		//set the current zone to the new one
		const now = new Date();
		TimeTracker.currentZone = {zone: zoneKey, startTime: now.getTime()};

		//persist the current state
		await DataStore.set(STORE_KEY_CURRENT_ZONE, TimeTracker.currentZone);
		await DataStore.set(STORE_KEY_DURATIONS, TimeTracker.durations);
	},

	__updateCurrentZoneDuration: () => {
		const now = new Date();
		const existingDuration =
			TimeTracker.durations[TimeTracker.currentZone.zone] || 0;
		TimeTracker.durations[TimeTracker.currentZone.zone] =
			existingDuration +
			(now.getTime() - TimeTracker.currentZone.startTime);
	},

	/**
	 * Returns the up-to-date durations for each of the zones that have been tracked so far.
	 *
	 * Useful for composing a request body to update an API server with current durations.
	 *
	 * @returns {{}} An object of the form {zoneKey: duration}
	 */
	getCurrentDurations: () => {
		const now = new Date();
		if (TimeTracker.currentZone != null) {
			// update the duration for the zone that we have been in up to this point
			TimeTracker.__updateCurrentZoneDuration();

			//change the current zone start time to now, since the duration was just updated
			TimeTracker.currentZone.startTime = now.getTime();
		}

		return TimeTracker.durations || {};
	},

	/**
	 * Prints the current zone durations
	 */
	prettyPrintDurations: () => {
		//courtesy of https://stackoverflow.com/questions/21294302/converting-milliseconds-to-minutes-and-seconds-with-javascript
		const millisToMinutesAndSeconds = millis => {
			const minutes = Math.floor(millis / 60000);
			const seconds = ((millis % 60000) / 1000).toFixed(0);
			return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
		};
		const durations = TimeTracker.getCurrentDurations();
		Object.keys(durations).forEach(zone => {
			Logger.log(
				`TimeTracker: Zone=${zone}, duration: ${millisToMinutesAndSeconds(
					durations[zone]
				)}`
			);
		});
	},

	/**
	 * Starts tracking a particular task. Can be invoked multiple times, but requires a unique key.
	 *
	 * @param taskKey A unique key for the task
	 * @returns {Promise<void>}
	 */
	startTracking: async taskKey => {
		Logger.debug(`Starting to track task: ${taskKey}`);
		//persist a start time for the given task
		const now = new Date();
		TimeTracker.taskTimers[taskKey] = now.getTime();
		await DataStore.set(STORE_KEY_TASK_TIMERS, TimeTracker.taskTimers);
	},

	/**
	 * Stops tracking a particular task.
	 *
	 * @param taskKey The unique key identifying the task
	 * @returns {Promise<number>} a resolved promise with the elapsed duration for that task
	 */
	stopTracking: async taskKey => {
		//calculate the amount of time that has elapsed for this task
		const now = new Date();
		const startTime = TimeTracker.taskTimers[taskKey];
		const elapsed = now.getTime() - startTime;
		Logger.debug(`Stop tracking task: ${taskKey}, elapsed: ${elapsed}`);

		//clean out the task from the internal struct
		TimeTracker.taskTimers[taskKey] = undefined;
		await DataStore.set(STORE_KEY_TASK_TIMERS, TimeTracker.taskTimers);

		return elapsed;
	},

	millisToMinutes: millis => Math.floor(millis / 60000),

	/**
	 * Converts milliseconds into a String in the form of '1h 13m'
	 *
	 * @param timeInMillis the number to convert
	 * @returns {string} the converted number
	 */
	elapsedMsToString: timeInMillis => {
		let totalTimeRaw = timeInMillis;
		const ms = totalTimeRaw % 1000;
		totalTimeRaw = (totalTimeRaw - ms) / 1000;
		const seconds = totalTimeRaw % 60;
		totalTimeRaw = (totalTimeRaw - seconds) / 60;
		const minutes = totalTimeRaw % 60;
		const hours = (totalTimeRaw - minutes) / 60;

		return `${hours}h ${minutes}m`;
	},

	/**
	 * Converts minutes into a String in the form of '1h 13m'
	 *
	 * @param timeInMinutes the number to convert
	 * @returns {string} the converted number
	 */
	elapsedMinutesToString: timeInMinutes => {
		const minutesDisplay = timeInMinutes % 60;
		const hoursDisplay = (timeInMinutes - minutesDisplay) / 60;

		return `${hoursDisplay}h ${minutesDisplay}m`;
	}
};

export default TimeTracker;
