import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import reduxStore from '@reading/r180/src/store/redux-setup';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	tooltip: {
		'& .MuiTooltip-tooltip': {
			background: theme.colors.black,
			fontSize: '20px',
			fontFamily: theme.fonts.button,
			padding: '10px 12px',
			borderRadius: '6px',
			marginTop: '6px',
			'& .MuiTooltip-arrow': {
				color: theme.colors.black,
			}
		}
	}
}));

const HoverTip = (props) => {
	const { children, title, placement } = props;
	const classes = useStyles();

	const sessionSlice = reduxStore.getState().session;
	const showButtonRollover = useMemo(() => {
		const rollover = sessionSlice.session.settings.buttonRolloverEnabled;
		return rollover === 'true' || rollover === true;
	}, [sessionSlice]);

    return (
		showButtonRollover
			?
				<Tooltip title={title} leaveDelay={200} classes={{popper: classes.tooltip}} arrow placement={placement}>
					{children}
				</Tooltip>
			:
				<>{children}</>
	);
}

HoverTip.defaultProps = {
	title: '',
	placement: 'bottom'
};

HoverTip.propTypes = {
	title: PropTypes.string.isRequired,
	placement: PropTypes.string
};

export default React.memo(HoverTip);
