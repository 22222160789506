import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import StarRating from '../../components/StarRating/StarRating';
import {isEmpty} from 'lodash';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: '-32px -40px -40px -40px',
		display: 'grid',
		gridTemplateRows: `1fr`,
		gridTemplateColumns: `1fr`,
		gridGap: '4px',
		justifyItems: 'stretch',
		alignItems: 'center'
	},
	choicesWrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: '8px 60px'
	},
	even: {
		'& div': {
			backgroundColor: theme.colors.white
		}
	},
	odd: {
		'& div': {
			backgroundColor: theme.colors.lightGrey
		}
	},
	questionWrapper: {
		display: 'flex',
		flexDirection: 'row',
		fontSize: '16px',
		fontWeight: '400',
		lineHeight: '22px',
		marginBottom: '10px'
	},
	stemContent: {
		color: theme.colors.grey,
		fontFamily: theme.fonts.uiBold,
		marginLeft: '4px'
	},
	ratingsWrapper: {
		display: 'flex',
		marginLeft: '20px'
	},
	soundButton: {
		width: '32px'
	}
}));

const RateRecording = props => {
	const {onAnswerChange, questions} = props;
	const classes = useStyles();

	const [selectedChoices, setSelectedChoices] = useState(questions);

	const handleSelect = (id, score) => {
		const newChoices = selectedChoices.map(answer => {
			if (id === answer.id) {
				answer.value = score;
			}
			return answer;
		});
		const checkedChoices = newChoices.filter(
			choice => choice.value !== null
		);
		if (checkedChoices.length === selectedChoices.length) {
			onAnswerChange(newChoices);
		}
		setSelectedChoices(newChoices);
	};

	return (
		<div className={classes.wrapper}>
			{questions.map((choice, index) => {
				return (
					<div
						key={`rating_${index}`}
						className={` ${index % 2 ? classes.odd : classes.even}`}
					>
						<StarRating
							onStarClicked={(score, text) =>
								handleSelect(choice.id, score, text)
							}
							text={stripHtmlTags(choice.stem)}
							src={isEmpty(choice.sound) ? '' : choice.sound.src}
							showOnAndOffButton={false}
							showDontKnow={true}
						/>
					</div>
				);
			})}
		</div>
	);
};

RateRecording.propTypes = {
	questions: PropTypes.array.isRequired,
	onAnswerChange: PropTypes.func.isRequired
};

export default RateRecording;
