import React from 'react';
import FastTrackActivity from '../activities/Foundational/FastTrack/FastTrackActivity';
import ReadAndThinkActivity from '../activities/Foundational/ReadAndThink/ReadAndThinkActivity';
import ProgressMonitorActivity from '../activities/Foundational/ProgressMonitor/ProgressMonitorActivity';
import WordBuilderActivity from '../activities/Foundational/WordBuilder/WordBuilderActivity';
import WordMeaningMatchActivity from '../activities/Foundational/WordMeaningMatch/WordMeaningMatchActivity';

const foundationalRoutes = [
	{
		route: 'fast-track',
		serverRoute: 'fast_track',
		pageTitle: 'Fast Track',
		component: <FastTrackActivity />,
		author: ['Mike']
	},
	{
		route: 'read-and-think',
		serverRoute: 'read_think',
		pageTitle: 'Read & Think',
		component: <ReadAndThinkActivity />,
		author: ['Mike']
  },
  {
		route: 'progress-monitor',
		serverRoute: 'progress_monitor',
		pageTitle: 'Progress Monitor',
		component: <ProgressMonitorActivity />,
		author: ['Sheik']
	},
	{
		route: 'word-builder',
		serverRoute: 'word_builder',
		pageTitle: 'Word Builder',
		component: <WordBuilderActivity />,
		author: ['Luc']
	},
	{
		route: 'word-meaning-match',
		serverRoute: 'word_meaning_match',
		pageTitle: 'Word Meaning Match',
		component: <WordMeaningMatchActivity />,
		author: ['Mike']
	}
];

export default foundationalRoutes;
