import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '50px',
		fontFamily: theme.fonts.ui
	},
	header: {
		position: 'absolute',
		top: '0',
		backgroundColor: '#54585B',
		height: '75px',
		width: '100%'
	},
	subHeaderRibbon: {
		position: 'relative',
		marginTop: '35px',
		backgroundColor: '#F2A901',
		height: '5px',
		width: '100%'
	},
	text: {
		position: 'relative',
		marginTop: '125px',
		textAlign: 'center',
		borderStyle: 'solid',
		borderColor: '#54585B',
		borderRadius: '10px',
		padding: '25px'
	},
	footer: {
		position: 'absolute',
		bottom: '0',
		marginBottom: '35px'
	},
	logo: {
		width: 'auto',
		height: 'auto',
		maxWidth: '300px',
		maxHeight: '200px'
	}
}));

const Logout = () => {
	const classes = useStyles({});

	return (
		<div className={classes.content}>
			<div className={classes.header} />
			<div className={classes.subHeaderRibbon} />
			<div className={classes.text}>
				<div>
					Thank you for using <strong>READING 180</strong>.
				</div>
				<div>Please close this window.</div>
			</div>
		</div>
	);
};

export default React.memo(Logout);
