
import React, { useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use'
import { useMethods } from 'react-use';
import { produce } from 'immer';
import { isEmpty, cloneDeep } from 'lodash';

const groupBy = (array, prop) => {
    const groupMap = array.reduce(
        (entryMap, e) => entryMap.set(e[prop], [...entryMap.get(e[prop]) || [], e]),
        new Map()
    );

    return Object.fromEntries(groupMap);
}
// --- xx -----------------------------------------------

const initState = { dndMap: {}, nodes: {} }
const createMethods = (state, callback) => {
    return {
        setDndTiles(dndData) {
            let newState = cloneDeep(initState)

            if (Array.isArray(dndData)) {
                dndData.forEach(item => {
                    newState.dndMap[item._dndIndex] = item
                })
                newState = { ...newState, ...groupBy(dndData, 'target') }
            }
            return newState
        },
        // --- xx --------

        setNode(type, node) {
            return produce(state, draft => {
                draft.nodes[type] = node
                return draft
            })
        },
        // --- xx --------

        update(type, items) {
            const newState = cloneDeep(state)
            const newArr = items.map(dndIndex => {
                const newItem = newState.dndMap[dndIndex]
                newItem.target = type
                return newItem
            })
            newState[type] = newArr
            callback(newState, 'update')
            return newState
        },
        // --- xx --------

        updateMultiple(changes) {
            const { from, to } = changes;
            const newState = cloneDeep(state)

            // From Array
            const fromArr = from.items.map(dndIndex => {
                const newItem = newState.dndMap[dndIndex]
                newItem.target = from.id
                return newItem
            })
            newState[from.id] = fromArr

            // To Array
            const toArr = to.items.map(dndIndex => {
                const newItem = newState.dndMap[dndIndex]
                newItem.target = to.id
                return newItem
            })
            newState[to.id] = toArr

            callback(newState, 'update_multiple')
            return newState
        }
    }
}

// --- xx -----------------------------------------------
//  USE DND HOOK
// --- xx -----------------------------------------------
const useDnd = (...args) => {
    const [dndData, defaultType = 'questions', changeCallback] = args;
    const [state, methods] = useMethods((s) => createMethods(s, changeCallback), initState);
    const [dndSource, setDndSource] = useState([]);
    const prevSource = useRef();

    const getInitTiles = (data) => {
        const tiles = Array.isArray(data) ? data : Object.values(data)
        const withTarget = tiles.map(tile => {
            const newTile = { ...tile }

            if (!tile._dndIndex) {
                newTile._dndIndex = tile.id.toString();
            }

            if (!tile.target) {
                newTile.target = defaultType;
            }

            if (tile.interactive === undefined) {
                newTile.interactive = true;
            }
            return newTile
        });
        return withTarget
    }
    // --- xx -----------------------------------------------

    useDeepCompareEffect(() => {
        if (!isEmpty(dndData)) {
            prevSource.current = dndSource
            setDndSource(null)
        }
    }, [dndData])
    // --- xx -----------------------------------------------

    useDeepCompareEffect(() => {
        if (!isEmpty(dndData) && isEmpty(dndSource)) {
            const modDndData = getInitTiles(dndData);
            setDndSource(modDndData);
            methods.setDndTiles(modDndData)
        }
    }, [dndSource])
    // --- xx -----------------------------------------------

    return Object.assign([dndSource, state, methods], { dndSource, state, methods })
}

export { useDnd }
export default useDnd;