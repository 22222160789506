import shortid from 'shortid';
import {AudioPlayer2} from '@shared/core';
import {
	SpellingCorrectiveFeedbackPreloaderStatus,
	SpellingCorrectiveFeedbackText
} from './constants';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';

const getWordParts = decodingInfo => {
	const {segments, parts} = decodingInfo;

	const wordSegments = segments.split('/');

	const wordParts = wordSegments.reduce((flat, x, i) => {
		const sep = {text: '+', type: SpellingCorrectiveFeedbackText.SEPARATOR};
		let wds;

		const regExp = /\(([^)]+)\)/;
		const matches = regExp.exec(x);
		if (matches) {
			wds = x.split(matches[0]);
			wds = wds.map(c =>
				c === ''
					? {
							text: matches[1],
							type: SpellingCorrectiveFeedbackText.SILENT,
							id: `sprite-${shortid.generate()}`
					  }
					: {
							text: c,
							type: SpellingCorrectiveFeedbackText.NORMAL,
							id: `sprite-${shortid.generate()}`
					  }
			);
		} else {
			wds = [
				{
					text: x,
					type: SpellingCorrectiveFeedbackText.NORMAL,
					id: `sprite-${shortid.generate()}`
				}
			];
		}

		const wSep = flat.length
			? [{...sep, id: `sprite-${shortid.generate()}`}, ...wds]
			: [...wds];

		return [...flat, ...wSep];
	}, []);

	return wordParts;
};
const getWordSpell = decodingInfo => {
	const {segments} = decodingInfo;

	const parts = segments
		.replace(/\(/, '')
		.replace(/\)/, '')
		.split('/');

	const nList = parts.reduce((flat, x, i) => {
		const sep = {text: '+', type: SpellingCorrectiveFeedbackText.SEPARATOR};
		let segs = null;
		const wds = x.split('').map(c => ({
			text: c,
			type: SpellingCorrectiveFeedbackText.NORMAL,
			id: `sprite-${shortid.generate()}`
		}));

		segs = flat.length
			? [{...sep, id: `sprite-${shortid.generate()}`}, ...wds]
			: [...wds];

		return [...flat, ...segs];
	}, []);

	return nList;
};

const getWordSpellWithoutSeperator = decodingInfo => {
	const {segments} = decodingInfo;

	const parts = segments
		.replace(/\(/, '')
		.replace(/\)/, '')
		.split('/');

	const nList = parts.reduce((flat, x, i) => {
		const sep = {text: '+', type: SpellingCorrectiveFeedbackText.SEPARATOR};
		let segs = null;
		const wds = x.split('').map(c => ({
			text: c,
			type: SpellingCorrectiveFeedbackText.NORMAL,
			id: `sprite-${shortid.generate()}`
		}));
		segs = [...wds];
		return [...flat, ...segs];
	}, []);

	return nList;
};

const getAudioUrl = (path, word) => {
	return path[1] > 1
		? `${path[0]}${word}_${path[1]}.${path[2]}`
		: `${path[0]}${word}.${path[2]}`;
};

const attachSoundForWordParts = (wordParts, parts, path) => {
	const wordPartsMap = wordParts.reduce(
		(flat, x) => ({...flat, [x.id]: x}),
		{}
	);

	const wsl = wordParts
		.filter(x => x.type === SpellingCorrectiveFeedbackText.NORMAL)
		.map((w, i) => ({
			...w,
			audioName: parts.filter(p => p.index === i + 1)[0].mediaName
		}))
		.reduce((flat, m) => ({...flat, [m.id]: m}), {});

	let nList = Object.values({...wordPartsMap, ...wsl});

	nList = nList.map(x => {
		const {audioName} = x;

		return audioName
			? {...x, audioUrl: FeedbackSpellUtil.getAudioUrl(path, audioName)}
			: {...x};
	});

	return nList;
};

const attachSoundForWordSpell = (wordSpell, path) => {
	const wordSpellMap = wordSpell.reduce(
		(flat, x) => ({...flat, [x.id]: x}),
		{}
	);

	const wsl = wordSpell
		.filter(x => x.type === SpellingCorrectiveFeedbackText.NORMAL)
		.map((w, i) => ({
			...w,
			audioName: `alphabet-${w.text}`
		}))
		.reduce((flat, m) => ({...flat, [m.id]: m}), {});

	let nList = Object.values({...wordSpellMap, ...wsl});

	nList = nList.map(x => {
		const {audioName} = x;

		return audioName
			? {...x, audioUrl: FeedbackSpellUtil.getAudioUrl(path, x.text)}
			: {...x};
	});

	return nList;
};

const loadAllSounds = assets => {
	assets.forEach(x => {
		if (Object.keys(x).length > 0 && !AudioPlayer2.assets[x.audioName]) {
			AudioPlayer2.load({
				name: x.audioName,
				src: `${MEDIA_SERVER_URL}assets/dictio` + x.audioUrl
			});
		}
	});
};
const loadAllSpellingFeedbackSounds = assets => {
	const EXT = getAudioExt();

	const feedback_audio = [
		{
			audioName: 'opencomment',
			audioUrl: `/r180u_spellingfeedback_opencomment.${EXT}`
		},
		{
			audioName: 'postpronunc',
			audioUrl: `/r180u_spellingfeedback_postpronunc.${EXT}`
		},
		{
			audioName: 'posttip1',
			audioUrl: `/r180u_spellingfeedback_posttip1.${EXT}`
		},
		{
			audioName: 'posttip2',
			audioUrl: `/r180u_spellingfeedback_posttip2.${EXT}`
		},
		{
			audioName: 'returnfromfdbk',
			audioUrl: `/r180u_spellingfeedback_returnfromfdbk.${EXT}`
		},
		{
			audioName: 'posttip2',
			audioUrl: `/r180u_spellingfeedback_posttip2.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype01_1',
			audioUrl: `/r180u_spellingfeedback_corrtype01_1.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype01_2',
			audioUrl: `/r180u_spellingfeedback_corrtype01_2.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype02_1',
			audioUrl: `/r180u_spellingfeedback_corrtype02_1.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype02_2',
			audioUrl: `/r180u_spellingfeedback_corrtype02_2.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype03_1',
			audioUrl: `/r180u_spellingfeedback_corrtype03_1.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype03_2',
			audioUrl: `/r180u_spellingfeedback_corrtype03_2.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype04_1',
			audioUrl: `/r180u_spellingfeedback_corrtype04_1.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype04_2',
			audioUrl: `/r180u_spellingfeedback_corrtype04_2.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype05_1',
			audioUrl: `/r180u_spellingfeedback_corrtype05_1.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype05_2',
			audioUrl: `/r180u_spellingfeedback_corrtype05_2.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype06',
			audioUrl: `/r180u_spellingfeedback_corrtype06.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype08',
			audioUrl: `/r180u_spellingfeedback_corrtype08.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype12',
			audioUrl: `/r180u_spellingfeedback_corrtype12.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype16',
			audioUrl: `/r180u_spellingfeedback_corrtype16.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype17',
			audioUrl: `/r180u_spellingfeedback_corrtype17.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype18',
			audioUrl: `/r180u_spellingfeedback_corrtype18.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype19',
			audioUrl: `/r180u_spellingfeedback_corrtype19.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype20',
			audioUrl: `/r180u_spellingfeedback_corrtype20.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype21',
			audioUrl: `/r180u_spellingfeedback_corrtype21.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype21b',
			audioUrl: `/r180u_spellingfeedback_corrtype21b.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype25',
			audioUrl: `/r180u_spellingfeedback_corrtype25.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype26',
			audioUrl: `/r180u_spellingfeedback_corrtype26.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype27_1',
			audioUrl: `/r180u_spellingfeedback_corrtype27_1.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype27_2',
			audioUrl: `/r180u_spellingfeedback_corrtype27_2.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype28',
			audioUrl: `/r180u_spellingfeedback_corrtype28.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype29',
			audioUrl: `/r180u_spellingfeedback_corrtype29.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype30',
			audioUrl: `/r180u_spellingfeedback_corrtype30.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype32',
			audioUrl: `/r180u_spellingfeedback_corrtype32.${EXT}`
		},
		{
			audioName: 'decoding_spelling_feedback_corrtype33',
			audioUrl: `/r180u_spellingfeedback_corrtype33.${EXT}`
		}
	];

	feedback_audio.forEach(
		x =>
			!AudioPlayer2.assets[x.audioName] &&
			AudioPlayer2.load({
				name: x.audioName,
				src:
					`${MEDIA_SERVER_URL}assets/activi/spellingfeedback` +
					x.audioUrl
			})
	);
};

const loadSound = asset => {
	!AudioPlayer2.assets[asset.audioName] &&
		AudioPlayer2.load({name: asset.audioName, src: asset.audioUrl});
};

const getDuration = name => {
	const sound = AudioPlayer2.assets[name];

	return sound ? sound.howl._duration : 0;
};

const getFeedbackWordDuration = word => {
	let duration = word.length * 1000;
	duration = duration < 6000 ? 6000 : duration;
	return duration;
};

export const preloadAssets = (assets, name) => {
	return new Promise((resolve, reject) => {
		try {
			FeedbackSpellUtil.loadAllSounds(assets);
			const counter = setInterval(() => {
				const loaded = FeedbackSpellUtil.hasPreloaded(assets);

				if (loaded) {
					clearInterval(counter);

					return resolve({
						name,
						status:
							SpellingCorrectiveFeedbackPreloaderStatus.COMPLETED
					});
				}
			}, 200);

			setTimeout(() => {
				clearInterval(counter);

				return reject(
					new Error(`Sound Loading Timeout Error ( ${name} )`)
				);
			}, 5000);
		} catch (error) {
			return reject(error);
		}
	});
};

const hasPreloaded = sounds => {
	for (let i = 0; i < sounds.length; i++) {
		const sound = AudioPlayer2.assets[sounds[i].audioName];
		if (!sound) return false;
		if (!sound.loaded) return false;
	}

	return true;
};

const wordSpellDecoderForSCF = decodingInfo => {
	const audioPath = ['/alph/r180u_alph_', '1', 'mp3'];

	const wSpell = FeedbackSpellUtil.getWordSpellWithoutSeperator(decodingInfo);

	const wSpellWithSound = FeedbackSpellUtil.attachSoundForWordSpell(
		wSpell,
		audioPath
	);
	return wSpellWithSound;
};

const wordTextAudio = decodingInfo => {
	const EXT = getAudioExt();
	let wordTextSound = {};
	if (decodingInfo && decodingInfo.wordText) {
		const {wordText} = decodingInfo;
		wordTextSound = {
			audioName: `wordText-${wordText}`,
			audioUrl: `/word/r180u_word_${wordText}.${EXT}`
		};
	}
	return wordTextSound;
};

const getSpellingCorrectiveFeedback = decodingInfo => {
	const payload = {
		id: decodingInfo.wordText + '_1',
		wordText: decodingInfo.wordText,
		segmentation: decodingInfo.segments,
		wordBase: decodingInfo.baseWord,
		tipCode: decodingInfo.id,
		baseChange: decodingInfo.baseChange
	};
	//const response = api.activity.updateStudentActivity(`spellingcf/word/${decodingInfo.wordText}/spelling/${decodingInfo.wordText}`, payload);
	const response = mockServerResponse();
	return response;
};

const playSpecificFeedbackSound = errorCode => {
	//const errorCode = responseFromServer.errors[errorCodeIndex].errorCode;
	switch (errorCode) {
		case 1:
			//First Letter of the word is capitalize
			AudioPlayer2.play('decoding_spelling_feedback_corrtype01_1');
			break;
		case 2:
			//First Letter of the word is not capitalize
			AudioPlayer2.play('decoding_spelling_feedback_corrtype02_1');
			break;
		case 3:
			//Dont use any captial letter
			AudioPlayer2.play('decoding_spelling_feedback_corrtype03_1');
			break;
		case 4:
			//Only the first word need to be capitalize
			AudioPlayer2.play('decoding_spelling_feedback_corrtype04_1');
			break;
		case 5:
			//letters not in correct order
			AudioPlayer2.play('decoding_spelling_feedback_corrtype05_2');
			break;
		case 6:
			//letters not in correct order and pointout individual letters, this letter should be "a" and this letter should be "s"
			AudioPlayer2.play('decoding_spelling_feedback_corrtype06');
			break;
		case 8:
			//letters not in correct order and pointout individual letters, this letter should be "a" and this letter should be "s"
			AudioPlayer2.play('decoding_spelling_feedback_corrtype08');
			break;
		case 12:
			//Vowel Substitution
			AudioPlayer2.play('decoding_spelling_feedback_corrtype12');
			break;
		case 16:
			//Vowel Substitution
			AudioPlayer2.play('decoding_spelling_feedback_corrtype16');
			break;
		case 17:
			//The user’s spelling is missing a consonants after consonants - know , kow (missing "n") - similar to errorcode 21
			//The user’s spelling is missing a consonants after consonants - challenges , callenges (missing "h") - similar to errorcode 21
			AudioPlayer2.play('decoding_spelling_feedback_corrtype17');
			break;
		case 18:
			//The user’s spelling is missing a consonants after consonants - know , kow (missing "n") - similar to errorcode 21
			//The user’s spelling is missing a consonants after consonants - challenges , callenges (missing "h") - similar to errorcode 21
			AudioPlayer2.play('decoding_spelling_feedback_corrtype18');
			break;
		case 19:
			//The user’s spelling is missing a vowel that is followed by a vowel
			AudioPlayer2.play('decoding_spelling_feedback_corrtype19');
			break;
		case 21:
			//The user’s spelling is missing a R that is preceded by a single vowel
			AudioPlayer2.play('decoding_spelling_feedback_corrtype21b');
			break;
		case 26:
			//dont forgot to double the letter , tell , tel(missing the second "l")
			//dont forgot to double the letter , effort , efort(missing the second "f")
			AudioPlayer2.play('decoding_spelling_feedback_corrtype26');
			break;
		case 27:
			//Extra letters ee
			AudioPlayer2.play('decoding_spelling_feedback_corrtype27_1');
			break;
		case 28:
			// Vowel Digraph Extra
			//   Correct [e]ffort
			//   Incorrect [a]effort
			AudioPlayer2.play('decoding_spelling_feedback_corrtype28');
			break;
		case 29:
			//Extra Final e
			AudioPlayer2.play('decoding_spelling_feedback_corrtype29');
			break;
		case 30:
			//Drop Final e
			AudioPlayer2.play('decoding_spelling_feedback_corrtype30');
			break;
		case 32:
			// Consonant Blend Remediation , sn sp st sw tw
			AudioPlayer2.play('decoding_spelling_feedback_corrtype32');
			break;
		case 33:
			// Consonant Diagraph Remediation , ch sh th wh ck
			AudioPlayer2.play('decoding_spelling_feedback_corrtype33');
			break;
	}
};

const mockServerResponse = () => {
	const response = {
		wordId: 'challenges',
		wordText: 'challenges',
		errors: [
			{
				errorCode: 31,
				correctMarkup: 'challenges[]',
				incorrectMarkup: 'challengess'
			}
		]
	};
	return response;
};

export const FeedbackSpellUtil = {
	getWordParts,
	getWordSpell,
	getWordSpellWithoutSeperator,
	getAudioUrl,
	attachSoundForWordParts,
	attachSoundForWordSpell,
	hasPreloaded,
	preloadAssets,
	getDuration,
	loadSound,
	loadAllSounds,
	loadAllSpellingFeedbackSounds,
	wordSpellDecoderForSCF,
	wordTextAudio,
	getSpellingCorrectiveFeedback,
	playSpecificFeedbackSound,
	getFeedbackWordDuration
};
