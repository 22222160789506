import React from 'react';
import PropTypes from 'prop-types';
import {Container, Provider, Subscribe} from 'unstated';
import {List as list} from 'immutable';

export class ErrorStoreContainer extends Container {
	state = {
		errors: list([])
	};

	push(newError) {
		return new Promise(resolve => {
			this.setState(
				prevState => ({
					errors: prevState.errors.push(newError)
				}),
				resolve(newError)
			);
		});
	}

	close(index) {
		return new Promise(resolve => {
			this.setState(
				prevState => ({
					errors: prevState.errors.delete(index)
				}),
				resolve(index)
			);
		});
	}

	/**
	 * Restores the ErrorStore to its initial state.
	 *
	 * @returns {Promise<void>}
	 */
	clearAll() {
		return this.setState({errors: list([])});
	}
}

const ErrorStore = new ErrorStoreContainer();

class ErrorStoreProvider extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.func,
			PropTypes.array
		]).isRequired,
		inject: PropTypes.array
	};

	static defaultProps = {
		inject: null
	};

	render() {
		return (
			<Provider inject={this.props.inject || [ErrorStore]}>
				{this.props.children}
			</Provider>
		);
	}
}

class ErrorStoreSubscribe extends React.Component {
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.func,
			PropTypes.array
		]).isRequired,
		to: PropTypes.array
	};

	static defaultProps = {
		to: null
	};

	render() {
		return (
			<Subscribe to={this.props.to || [ErrorStore]}>
				{this.props.children}
			</Subscribe>
		);
	}
}

ErrorStore.Provider = ErrorStoreProvider;
ErrorStore.Subscribe = ErrorStoreSubscribe;

export default ErrorStore;
