import React, {useEffect, useState, useRef} from 'react';
import {useUnmount} from 'react-use';
import PropTypes from 'prop-types';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';
import ReadingQuestionPanel from '../ReadingQuestionPanel/ReadingQuestionPanel';
import {bulkReplace, stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import {createAssetId} from '@reading/r180/src/media/mediaUtil';
import {isEmpty, cloneDeep} from 'lodash';
import useBatchedSetState from '@reading/r180/src/utils/useBatchedSetState';

const EXT = getAudioExt();

const BUTTON_NAMES = {
	SUBMIT: 'Submit',
	GOON: 'Go On',
	TRYAGAIN: 'Try Again'
};
const BUTTON_ICONS = {
	CHECK: 'check',
	ARROW_RIGHT: 'arrow-right',
	RESET: 'reset'
};
const MAX_ATTEMPTS = 3;
export const MEDIA = {
	TITLE_SOUND: `${MEDIA_SERVER_URL}/assets/activi/readpass/r180u_readpass_{asset_id}_highlight_q{dataId}_question.${EXT}`
};

const ChartQuestion = props => {
	const {data, stage, segment, level, onGoOnClicked, onShowFeedback} = props;
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
	const [attempts, setAttempts] = useState(0);
	const [isCorrect, setCorrect] = useState(true);
	const [interactive, setInteractive] = useState(true);
	const [options, setOptions] = useState({
		prompts: [],
		choices: [],
		answers: []
	});
	const [answers, setAnswers] = useState([]);
	const [buttonName, setButtonName] = useState(BUTTON_NAMES.SUBMIT);
	const [buttonIcon, setButtonIcon] = useState(BUTTON_ICONS.CHECK);
	const timeout = useRef(false);
	const {setState} = useBatchedSetState();

	useEffect(() => {
		if (isEmpty(data) === false) {
			const prompts = [...data.prompts];
			const choices = [...data.choices];
			const orderedAnswers = [...data.answers].map(option => {
				return {
					id: option.id,
					correct: option.correct,
					prompt_id: option.prompt_id,
					choice_id: option.choice_id,
					status: ''
				};
			});
			const correctAnswers = data.answers.filter(answer => {
				return answer.correct;
			});
			setState(() => {
				setOptions({prompts, choices, answers: orderedAnswers});
				setAnswers(correctAnswers);
			});
		} else {
			setOptions({prompts: [], choices: [], answers: []});
		}
	}, [data]);

	useUnmount(() => {
		AudioPlayer2.stopAll();
		clearTimeout(timeout.current);
	});
	const handleSelect = async () => {
		if (!isSubmitEnabled) {
			return;
		}
		AudioPlayer2.stopAll();
		switch (buttonName) {
			case BUTTON_NAMES.SUBMIT: {
				const correctAnswerIds = answers.map(ans => ans.id);
				const newOptions = cloneDeep(options.answers).map(ans => {
					if (ans.status === 'selected' || ans.status === 'correct') {
						if (correctAnswerIds.includes(ans.id)) {
							return {...ans, status: 'correct'};
						} else {
							return {...ans, status: 'incorrect'};
						}
					} else {
						return {...ans, status: ''};
					}
				});
				const correctOptionsLength = newOptions.filter(
					option => option.status === 'correct'
				).length;
				// All answers are correct
				if (correctOptionsLength === answers.length) {
					AudioPlayer2.play(`compcorrattempt${attempts + 1}`);
					onShowFeedback(false, []);
					setState(() => {
						setButtonName(BUTTON_NAMES.GOON);
						setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
						setAttempts(attempts => attempts + 1);
						setCorrect(true);
						setInteractive(false);
						setOptions({...options, answers: newOptions});
					});
				} else {
					AudioPlayer2.play(`compincorrattempt${attempts + 1}`);
					if (attempts === MAX_ATTEMPTS - 1) {
						const correctOptions = cloneDeep(options.answers).map(
							ans => {
								if (correctAnswerIds.includes(ans.id)) {
									return {...ans, status: 'correct'};
								} else {
									return {...ans, status: ''};
								}
							}
						);
						timeout.current = setTimeout(() => {
							onShowFeedback(false, []);
						}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 2000); // wait to show correct answers before the app moves to next question
						setState(() => {
							setInteractive(false);
							setButtonName(BUTTON_NAMES.GOON);
							setButtonIcon(BUTTON_ICONS.ARROW_RIGHT);
							setOptions({...options, answers: correctOptions});
						});
					} else {
						timeout.current = setTimeout(() => {
							const updatedOptions = cloneDeep(newOptions).map(
								option => {
									if (option.status === 'incorrect') {
										return {...option, status: 'selected'};
									} else {
										return option;
									}
								}
							);

							onShowFeedback(true, data.corrective_feedback);
							setState(() => {
								setInteractive(true);
								setButtonName(BUTTON_NAMES.SUBMIT);
								setButtonIcon(BUTTON_ICONS.CHECK);
								setIsSubmitEnabled(true);
								setOptions({
									...options,
									answers: updatedOptions
								});
							});
						}, AudioPlayer2.assets[`compincorrattempt${attempts + 1}`].howl._duration * 1000);
						setState(() => {
							setInteractive(false);
							setButtonName(BUTTON_NAMES.SUBMIT);
							setButtonIcon(BUTTON_ICONS.CHECK);
							setIsSubmitEnabled(false);
							setOptions({...options, answers: newOptions});
						});
					}
					setState(() => {
						setCorrect(false);
						setAttempts(attempts => attempts + 1);
					});
				}
				break;
			}
			default: {
				onShowFeedback(false, []);
				onGoOnClicked(attempts, isCorrect);
				break;
			}
		}
	};

	const handleAnswerChange = newAnswers => {
		const selectedAnswers = newAnswers.map(option => option.id);
		const newOptionAnswers = cloneDeep(options.answers).map(option => {
			if (selectedAnswers.includes(option.id)) {
				return {
					...option,
					status: option.status === 'correct' ? 'correct' : 'selected'
				};
			} else {
				return {...option, status: ''};
			}
		});
		setState(() => {
			setIsSubmitEnabled(
				selectedAnswers.length === options.prompts.length
			);
			setOptions({...options, answers: newOptionAnswers});
		});
	};

	return (
		<ReadingQuestionPanel
			title={stripHtmlTags(data.stem)}
			titleSound={{
				name: `Title_${data.id}`,
				src: bulkReplace(MEDIA.TITLE_SOUND, {
					asset_id: createAssetId(stage, segment, level, true),
					dataId: data.id
				})
			}}
			questionType={data.type}
			question={options}
			onAnswerChange={handleAnswerChange}
			onSubmit={handleSelect}
			isSubmitEnabled={isSubmitEnabled}
			buttonText={buttonName}
			buttonIcon={buttonIcon}
			showResetButton={false}
			interactive={interactive}
		/>
	);
};

ChartQuestion.propTypes = {
	data: PropTypes.object.isRequired,
	stage: PropTypes.string.isRequired,
	segment: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onGoOnClicked: PropTypes.func.isRequired
};

export default React.memo(ChartQuestion);
