import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import ZoneMenu from '../../activities/ZoneMenu/ZoneMenu';
import ReadingStrategySelector from '../../activities/ReadingStrategySelector/ReadingStrategySelector';
import {zoneMenuData} from '../../mock/data/zone_menu';

const zoneMappings = {
	zone_menu: {
		component: <ZoneMenu cachedData={zoneMenuData['zone_menu']} />
	},
	reading_strategy_selector: {
		component: (
			<ReadingStrategySelector
				cachedData={zoneMenuData['reading_strategy_selector']}
				userContext={{sif: 'mock'}}
			/>
		)
	}
};
const DevTool = props => {
	const [selectedPage, setSelectedPage] = useState(false);
	if (!selectedPage) {
		return (
			<>
				<h1>Dev Tool</h1>
				<Link to="/">Home</Link>
				<br />
				<br />
				<Link
					to={{
						pathname: '/zone-menu',
						state: {
							cachedData: zoneMenuData['zone_menu']
						}
					}}
				>
					Zone Menu
				</Link>
				<br />
				<br />
				<select
					onChange={e => {
						setSelectedPage(e.target.value);
					}}
				>
					<option value={false} key="A">
						Choose Page
					</option>
					{Object.keys(zoneMappings).map(key => (
						<option value={key} key={key}>
							{key}
						</option>
					))}
				</select>
			</>
		);
	} else {
		// use React helmet here
		//window.history.replaceState(null,"Dev Tool",`${selectedPage}/${Math.floor(Math.random()*10000)}`);
		return zoneMappings[selectedPage].component;
	}
};

export default DevTool;
