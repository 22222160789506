import * as React from 'react';
import PropTypes from 'prop-types';

import {
	createContext,
	useContext,
	useEffect,
	useState,
	useReducer
} from 'react';

import {WordCardPreloaderStatus, WordCardText} from './constants';

import {WordCardUtil} from './WordCardUtil';
import {MEDIA_SERVER_URL} from '@reading/r180/src/utils/constants';
import {getAudioExt} from '@reading/r180/src/utils/audio';

export const WordCardActionMap = {
	UPDATE_WORD_CARD: '@@word-card/update',
	SET_WORD_CARD: '@@word-card/set-word-card',
	PRELOAD_SOUND: '@@word-card/preload-sound',
	PRELOAD_SOUND_SUCCESS: '@@word-card/preload-sound-success',
	PRELOAD_SOUND_ERROR: '@@word-card/preload-sound-error'
};

const initialState = {
	audioBaseUrl: `${MEDIA_SERVER_URL}assets/dictio`,
	partsAudioPath: '/parts/r180u_wp_',
	tipsAudioPath: '/decodingtips/r180u_',
	spellAudioPath: '/alph/r180u_alph_',
	wordTextAudioPath: '/word/r180u_word_',
	sfxAudioPath: '/sfx/r180u_sfx_',
	audioFormat: 'mp3',
	data: null,
	error: null,
	loading: false,
	preloader: {}
};

export const WordCardContext = createContext(initialState);

export const preloaderMiddleWare = (dispatch, action) => {
	if (action.type === WordCardActionMap.PRELOAD_SOUND) {
		WordCardUtil.preloadAssets(
			action.payload.assets,
			action.payload.name
		).then(result => {
			dispatch({
				type: WordCardActionMap.PRELOAD_SOUND_SUCCESS,
				payload: result
			});
		});
	}
};

export const applyMiddleWare = dispatch => action => {
	dispatch(action);
	preloaderMiddleWare(dispatch, action);
};

export const wordCardReducer = (state = initialState, action) => {
	let newState;

	switch (action.type) {
		case WordCardActionMap.UPDATE_WORD_CARD:
			newState = {...state};
			break;

		case WordCardActionMap.SET_WORD_CARD:
			newState = {...state, data: action.payload.wordCardData};

			break;

		case WordCardActionMap.PRELOAD_SOUND:
			newState = {
				...state,
				preloader: {
					...state.preloader,
					[action.payload.name]: {
						name: action.payload.name,
						assets: action.payload.assets,
						status: WordCardPreloaderStatus.LOADING
					}
				}
			};

			break;
		case WordCardActionMap.PRELOAD_SOUND_SUCCESS:
			newState = {
				...state,
				preloader: {
					...state.preloader,
					[action.payload.name]: {
						...state.preloader[action.payload.name],
						status: WordCardPreloaderStatus.COMPLETED
					}
				}
			};

			break;
		default:
			newState = {...state};
			break;
	}

	return newState;
};

export const WordCardProvider = ({children, wordCardData}) => {
	const [state, dispatch] = useReducer(wordCardReducer, initialState);
	const {Provider} = WordCardContext;

	const enhancedDispatcher = applyMiddleWare(dispatch);

	useEffect(() => {
		if (
			wordCardData &&
			(!state.data ||
				(state.data && state.data.text !== wordCardData.text))
		) {
			dispatch({
				type: WordCardActionMap.SET_WORD_CARD,
				payload: {wordCardData}
			});
		}
	}, [wordCardData, state]);

	return (
		<Provider value={{state, dispatch, enhancedDispatcher}}>
			{children}
		</Provider>
	);
};

WordCardProvider.propTypes = {
	children: PropTypes.node.isRequired,
	wordCardData: PropTypes.shape({
		text: PropTypes.string.isRequired,
		instanceNumber: PropTypes.number.isRequired,
		decodingInfo: PropTypes.shape({
			wordText: PropTypes.string.isRequired,
			segments: PropTypes.string.isRequired,
			markup: PropTypes.string.isRequired,
			parts: PropTypes.arrayOf(
				PropTypes.shape({
					index: PropTypes.number.isRequired,
					text: PropTypes.string.isRequired,
					mediaName: PropTypes.string.isRequired
				})
			)
		}),
		contextualData: PropTypes.arrayOf(
			PropTypes.shape({
				segmentId: PropTypes.string.isRequired,
				contextualSentence: PropTypes.string.isRequired,
				contextualDefinition: PropTypes.string.isRequired
			})
		)
	}).isRequired
};

export const UseWordCardDispatch = () => {
	const {enhancedDispatcher} = useContext(WordCardContext);

	return enhancedDispatcher;
};
/* istanbul ignore next */
export const useWordCardSelector = d => {
	const ctx = useContext(WordCardContext);

	return d(ctx.state);
};

/* istanbul ignore next */
export const usePreloader = name => {
	const {state} = useContext(WordCardContext);
	const [preloader, setPreloader] = useState(null);
	useEffect(() => {
		state &&
			state.preloader &&
			state.preloader[name] &&
			setPreloader(state.preloader[name]);
	}, [name, state]);

	return preloader;
};
/* istanbul ignore next */
export const useWordTextSound = () => {
	const {state} = useContext(WordCardContext);
	const [sound, setSound] = useState(null);

	const EXT = getAudioExt();

	useEffect(() => {
		if (
			state &&
			state.data &&
			state.data.decodingInfo &&
			state.data.decodingInfo.wordText
		) {
			const {wordText} = state.data.decodingInfo;
			const wordTextSound = {
				audioName: `wordText-${wordText}`,
				audioUrl: `/word/r180u_word_${wordText}.${EXT}`
			};

			setSound(wordTextSound);
		}
	}, [state]);

	return sound;
};
/* istanbul ignore next */
export const useWordPartsDecoder = () => {
	const {state} = useContext(WordCardContext);
	const [wordParts, setWordParts] = useState(null);

	const {getWordParts} = WordCardUtil;

	const {attachSoundForWordParts} = WordCardUtil;

	useEffect(() => {
		if (
			state &&
			state.data &&
			state.data.decodingInfo &&
			state.data.decodingInfo.parts
		) {
			const audioPath = [
				state.partsAudioPath,
				state.data.instanceNumber,
				state.audioFormat
			];

			const wParts = getWordParts(state.data.decodingInfo);

			const wPartsWithSound = attachSoundForWordParts(
				wParts,
				state.data.decodingInfo.parts,
				audioPath,
				state.data.decodingInfo.wordText
			);

			setWordParts(wPartsWithSound);
		}
	}, [state, getWordParts, attachSoundForWordParts]);

	return wordParts;
};

/* istanbul ignore next */
export const useWordSpellDecoder = () => {
	const {state} = useContext(WordCardContext);
	const [wordSpell, setWordSpell] = useState(null);

	useEffect(() => {
		if (state && state.data && state.data.decodingInfo) {
			const audioPath = [
				state.spellAudioPath,
				state.data.instanceNumber,
				state.audioFormat
			];

			const wSpell = WordCardUtil.getWordSpell(state.data.decodingInfo);

			const wSpellWithSound = WordCardUtil.attachSoundForWordSpell(
				wSpell,
				audioPath
			);

			setWordSpell(wSpellWithSound);
		}
	}, [state]);

	return wordSpell;
};

export const UsePartsAudioPath = () => {
	const ctx = useContext(WordCardContext);
	const [path, setPath] = useState(null);

	useEffect(() => {
		ctx &&
			ctx.state &&
			ctx.state.data &&
			setPath([
				ctx.state.partsAudioPath,
				ctx.state.data.instanceNumber,
				ctx.state.audioFormat
			]);
	}, [ctx]);

	return path;
};

export const UseSpellAudioPath = () => {
	const ctx = useContext(WordCardContext);
	const [path, setPath] = useState(null);

	useEffect(() => {
		ctx &&
			ctx.state &&
			setPath([
				ctx.state.spellAudioPath,
				ctx.state.data.instanceNumber,
				ctx.state.audioFormat
			]);
	}, [ctx]);

	return path;
};

export const UseWordTextAudioPath = () => {
	const ctx = useContext(WordCardContext);
	const [path, setPath] = useState(null);
	useEffect(() => {
		ctx &&
			ctx.state &&
			setPath([
				ctx.state.wordTextAudioPath,
				ctx.state.data.instanceNumber,
				ctx.state.audioFormat
			]);
	}, [ctx]);

	return path;
};
