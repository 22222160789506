import queryString from 'query-string';

import transport from '../Transport';
import DataStore from '../DataStore';
import ErrorStore from '../ErrorStore';

const NO_API_URI = '/api/no-api-uri-provided/';

const defaults = {
	headers: {
		Accept: 'application/json'
	}
};

const request = {
	apiUri: NO_API_URI,
	overrideApiUri: NO_API_URI,
	apiHost: '',

	globalErrorHandler: null,

	userContext: {
		sifToken: null,
		correlationId: null,
		activityId: null
	},

	setApiHost: host => {
		request.apiHost = host;
	},

	getApiHost: () => request.apiHost,

	setApiUri: apiUri => {
		request.apiUri = apiUri;
	},

	getApiUri: () => request.apiUri,

	setOverrideApiUri: uri => {
		request.overrideApiUri = uri;
	},

	getOverrideApiUri: () => request.overrideApiUri,

	setUserContext: context => {
		request.userContext = context;
	},

	registerGlobalErrorHandler: errorHandler => {
		request.globalErrorHandler = errorHandler;
	},

	fetch: options => {
		DataStore.set('networkBusy', true);

		return new Promise(resolve => {
			const {
				method,
				body,
				query,
				endpoint,
				contentType,
				accept,
				credentials,
				ignoreCredentials,
				mode,
				useApiUriOverride
			} = options;

			const sid = DataStore.get('sid');
			const session = DataStore.get('m180Session');

			let url = request.apiHost;
			url += useApiUriOverride
				? request.overrideApiUri + endpoint
				: request.apiUri + endpoint;

			console.warn(`sid`, sid, typeof sid);
			const headers = {
				Authorization: sid ? '' : request.userContext.sifToken,
				CorrelationId: request.userContext.correlationId,
				ActivityId: request.userContext.activityId,
				'session-id': session ? session.sessionId : null,
				'Access-Control-Expose-Headers': 'x-b3-traceid'
			};

			if (process.env.REACT_APP_SAM_SITE_ID_HEADER) {
				headers['X-SITE-ID'] = process.env.REACT_APP_SAM_SITE_ID_HEADER;
			}

			const fetchOptions = {
				url,
				method,
				headers,
				mode: mode ? mode : 'same-origin'
			};

			if (ignoreCredentials === undefined || !ignoreCredentials) {
				fetchOptions.credentials = credentials
					? credentials
					: 'include';
			}

			if (accept) {
				headers.Accept = accept;
			} else {
				defaults.headers.Accept = 'application/json';
			}

			if (typeof contentType === 'string') {
				headers['Content-Type'] = contentType;
			}

			if (typeof body === 'object') {
				fetchOptions.body = JSON.stringify(body);
			}

			if (typeof body === 'string') {
				fetchOptions.body = body;
			}

			const hasQuery = typeof query === 'object';
			if (hasQuery) {
				fetchOptions.url += `?${queryString.stringify(query)}`;
			}

			if (sid) {
				fetchOptions.url += `${hasQuery ? '&' : '?'}sid=${sid}`;
			}

			transport(fetchOptions)
				.then(result => {
					DataStore.set('networkBusy', false);
					resolve(result);
				})
				.catch(error => {
					DataStore.set('networkBusy', false);
					if (typeof request.globalErrorHandler === 'function') {
						// note that the resolve function is never invoked in this case
						request.globalErrorHandler(error, options);
					} else {
						const errorModal = {
							title: 'Request Error',
							action: method,
							caller: 'Request.fetch()',
							link: url,
							error
						};

						// TECH DEBT
						// This line can be removed.
						ErrorStore.push(errorModal);

						// TECH DEBT
						// This should be a reject
						// Would require a rewrite of all our apis
						resolve(errorModal);
					}
				});
		});
	}
};

export default request;
