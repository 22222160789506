import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import ChoiceResponseQuestionItem from './ChoiceResponseQuestionItem';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		margin: '-32px -40px -40px -40px',
		display: 'grid',
		gridTemplateRows: `1fr`,
		gridTemplateColumns: `1fr`,
		gridGap: '4px',
		justifyItems: 'stretch',
		alignItems: 'center'
	},
	choicesWrapper: {
		display: 'flex',
		flexDirection: 'row',
		padding: '8px 60px',
		fontSize: '16px',
		fontWeight: '400',
		lineHeight: '22px',
		marginBottom: '10px'
	}
}));

const FocusAttention = props => {
	const {onAnswerChange, questions} = props;
	const classes = useStyles();
	const getOptions = () =>
		questions.map(question => ({...question, text: question.prompt}));
	const [options, setOptions] = useState(getOptions());

	const handleSelect = choice => {
		const newOptions = options.map(option => {
			if (option.id === choice.id) {
				option.checked = !choice.checked;
			}
			return option;
		});
		const newQuestions = newOptions.map(option => {
			const updatedOption = {...option};
			delete updatedOption.text;
			return updatedOption;
		});
		setOptions(newOptions);
		onAnswerChange(newQuestions);
	};

	return (
		<div className={classes.wrapper}>
			{options.map((choice, index) => {
				return (
					<div className={classes.choicesWrapper} key={index}>
						<ChoiceResponseQuestionItem
							question={choice}
							onSelect={handleSelect}
							isSelected={choice.checked}
							type="multiple"
							interactive={true}
						/>
					</div>
				);
			})}
		</div>
	);
};

FocusAttention.propTypes = {
	questions: PropTypes.array.isRequired,
	onAnswerChange: PropTypes.func.isRequired
};

export default FocusAttention;
