import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Loader from '../../../gif/Loading.gif'

const useStyles = makeStyles(() => ({
	loaderGrayBg: {
		display: 'block',
		width: '100%',
		height: '100%',
		top: '0',
		left: '0',
		backgroundColor: 'rgba(0,0,0,0.5)',
		zIndex: '999',
		cursor: 'pointer'
	},
	component: {
		position: 'absolute'
	},
	loader: {
		position: 'absolute',
		top: '35%',
		left: '40%',
		zIndex: '9999'
	},
	pageLoader: {
		background: `url(${Loader}) left top no-repeat transparent`,
		width: 252,
		height: 75,
		backgroundSize: '100%',
		'& p': {
			position: 'relative',
			top: 105,
			textAlign: 'center'
		}
	}
}));

const ScreenLoader = () => {
	const classes = useStyles();

	return (
		<div className={`${classes.loaderGrayBg} ${classes.component}`}>
			<div className={classes.loader}>
				<div className={classes.pageLoader}>
					<p>Loading</p>
				</div>
			</div>
		</div>
	);
};

export default ScreenLoader;
