import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AnchorVideo } from '@reading/common';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(theme => ({
	videoButton: {
		background: 'transparent',
		color: theme.colors.white,
		width: 'auto',
		minWidth: '64px',
		height: 'auto',
		border: 'none',
		borderRadius: '8px',
		outline: 'none',
		textAlign: 'center',
		cursor: 'pointer',
		padding: '8px',
		transition: 'transform 0.3s ease',
		'&.disabled, &:disabled': {
			background: 'transparent !important',
			color: theme.colors.paleGrey,
			cursor: 'not-allowed'
		},
		'& .icon-wrapper': {
			height: '36px',
			width: 'auto',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'& .circle': {
				height: '36px',
				width: '36px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: '18px',
				'& i': {
					fontSize: '24px'
				}
			}
		},
		'& .text': {
			fontSize: '18px',
			fontFamily: theme.fonts.button,
			fontWeight: '600',
			marginTop: '4px',
			textShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'
		},
		'&:hover': {
			transform: 'scale(1.2)',
			'& .circle': {
				background: 'rgba(0, 0, 0, 0.3)'
			}
		},
		'&:focus': {
			'& .circle': {
				background: 'rgba(0, 0, 0, 0.3)'
			},
			'& .text': {
				textDecoration: 'underline',
				textUnderlineOffset: '3px'
			}
		}
	},
	dialogPaper: {
		overflow: 'inherit',
		justifyContent: 'center'
	}
}));

const VideoButton = (props) => {
	const {
		videoButtonId,
		icon,
		text,
		iconSize,
		disabled,
		onVideoBtnClick,
		onVideoEnd,
		onVideoClose,
		videoContainerStyle,
		videoVol,
		start_time,
		end_time,
		videoSrcURL,
		videoSrcType,
		videoTracks,
		videoPlayOnLoad,
		videoPoster,
		hideFwdBtn,
		hideRewindBtn,
		hideCaptionBtn,
		hideReplayBtn,
		hideCloseBtn,
		videoButtonClass
	} = props;
	const classes = useStyles();
	const iconSizePx = iconSize + 'px';
	const disabledClass = disabled ? 'disabled' : '';
	const [showVideoPlayer, setShowVideoPlayer] = useState(false);

	const anchorVideoStyle = {
		...videoContainerStyle
	};

	const videoButtonClassName = videoButtonClass
		? videoButtonClass
		: classes.videoButton;

	const handleClick = e => {
		e.stopPropagation();
		if (disabled) {
			return;
		}
		setShowVideoPlayer(true);
		onVideoBtnClick(e, true);
	};

	/* istanbul ignore next */
	const handleVideoEnd = (e, video) => {
		setShowVideoPlayer(false);
		if (typeof onVideoEnd === 'function') {
			onVideoEnd(e, video);
		}
	};

	/* istanbul ignore next */
	const handleCloseBtn = (e, video) => {
		setShowVideoPlayer(false);
		if (typeof onVideoClose === 'function') {
			onVideoClose(e, video);
		}
	};

	return (
		<>
			<button
				type="button"
				id={videoButtonId}
				aria-describedby={videoButtonId}
				aria-label={text}
				className={`${videoButtonClassName} ${disabledClass}`}
				disabled={disabled}
				onClick={handleClick}
			>
				<div className='icon-wrapper'>
					<div className="circle">
					<i
						className={`r180 ${icon} ${classes.icon}`}
						style={{
							fontSize: iconSizePx
						}}
					/>
					</div>
				</div>
				<div className={`text`}>{text}</div>
			</button>

			<Dialog
				aria-labelledby="simple-dialog-title"
				open={showVideoPlayer}
				classes={{ paper: classes.dialogPaper }}
				maxWidth={'lg'}
			>
				<AnchorVideo
					id={videoButtonId}
					autoPlay={videoPlayOnLoad}
					time={start_time}
					video_end_time={end_time}
					vol={videoVol}
					containerStyle={anchorVideoStyle}
					poster={videoPoster}
					handleVideoEnded={handleVideoEnd}
					handleCloseButton={handleCloseBtn}
					hideFwdButton={hideFwdBtn}
					hideRewindButton={hideRewindBtn}
					hideCaptionButton={hideCaptionBtn}
					hideReplayButton={hideReplayBtn}
					hideCloseButton={hideCloseBtn}
					hideVideoTimeDuration={true}
				>
					<source src={videoSrcURL} type={videoSrcType} />
					{videoTracks.map((track, index) => (
						<track
							key={index}
							src={track.src}
							label={track.label}
							kind={track.kind}
							srcLang={track.srcLang}
						/>
					))}
				</AnchorVideo>
			</Dialog>
		</>
	);
}

VideoButton.defaultProps = {
	videoButtonId: 'videoHintContainer',
	text: '',
	icon: 'anchor-video',
	iconSize: 24,
	disabled: false,
	onVideoBtnClick: () => { },
	onVideoEnd: () => { },
	onVideoClose: () => { },
	videoContainerStyle: {
		width: '100%',
		height: '100%'
	},
	videoVol: 0.1,
	videoSrcURL:
		'',
	videoSrcType: 'video/mp4',
	videoTracks: [
		{
			src: '',
			label: 'English subtitles',
			kind: 'subtitles',
			srcLang: 'en'
		},
		{
			src: '',
			label: 'English subtitles',
			kind: 'subtitles',
			srcLang: 'de'
		},
		{
			src: '',
			label: 'Spanish subtitles',
			kind: 'subtitles',
			srcLang: 'es'
		}
	],
	videoPlayOnLoad: true,
	videoPoster: '',
	hideFwdBtn: false,
	hideRewindBtn: false,
	hideCaptionBtn: true,
	hideReplayBtn: true,
	hideCloseBtn: false
};
VideoButton.propTypes = {
	videoButtonId: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	start_time: PropTypes.number.isRequired,
	end_time: PropTypes.number.isRequired,
	icon: PropTypes.string.isRequired,
	iconSize: PropTypes.number.isRequired,
	disabled: PropTypes.bool,
	onVideoBtnClick: PropTypes.func,
	onVideoEnd: PropTypes.func,
	onVideoClose: PropTypes.func,
	videoContainerStyle: PropTypes.object.isRequired,
	videoVol: PropTypes.number,
	videoSrcURL: PropTypes.string.isRequired,
	videoSrcType: PropTypes.string,
	videoTracks: PropTypes.arrayOf(PropTypes.object).isRequired,
	videoPlayOnLoad: PropTypes.bool,
	videoPoster: PropTypes.string,
	hideFwdBtn: PropTypes.bool,
	hideRewindBtn: PropTypes.bool,
	hideCaptionBtn: PropTypes.bool,
	hideReplayBtn: PropTypes.bool,
	hideCloseBtn: PropTypes.bool,
	videoButtonClass: PropTypes.object,
};

export default React.memo(VideoButton);
