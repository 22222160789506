import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';
import media from '../../api/media';
import {getAudioExt} from '../../utils/audio';
import {KnowledgeForReading} from '../WordCard/KnowledgeForReading';
import {getContentInfo} from '../../media/mediaUtil';
import {MEDIA_SERVER_URL} from '../../utils/constants';
import {bulkReplace} from '../../utils/stringUtils';
import {isEmpty} from 'lodash';
import api from '../../api/api';
import {transitionToNextActivity} from '../../utils/navigation';
import { loadSleep } from '../../utils/ui';

const EXT = getAudioExt();

export const SpellingClinic = {
	wordsDataBaseURL: 'content/dictio/word/',
	MAX_WORDS: 3,
	INTRO_VO: `${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_intro.${EXT}`,
	SCREEN_HELP_VO: [
		`${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_studyhelp.${EXT}`,
		`${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_wordcardhelp.${EXT}`,
		`${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_spellingtrialhelp.${EXT}`,
		`${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_wordcardhelp.${EXT}`,
		`${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_spellingtrialhelp.${EXT}`,
		`${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_wordcardhelp.${EXT}`,
		`${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_spellingtrialhelp.${EXT}`,
		`${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_finished.${EXT}`
	],
	WORD_SPELL_EXPLAIN: `${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_explanation.${EXT}`,
	WORD_CTXT_URL: `${MEDIA_SERVER_URL}/assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`,
	WORD_DEF_URL: `${MEDIA_SERVER_URL}/assets/dictio/def/r180u_def_def-{word}.${EXT}`,
	SPELLCLINIC_COMPLETE: `${MEDIA_SERVER_URL}/assets/activi/spellclinic/r180u_spellclinic_finished.${EXT}`,
	WORDS_VO: `${MEDIA_SERVER_URL}/assets/dictio/word/r180u_word_{currentWord}.${EXT}`,
	LAST_SCREEN: 7,
	WORD_CARD_SCREEN: 2,
	LANDING_SCREEN: 0,
	WORDCARD_COUNT: 3,
	WORDCARD_SCREEN_LIST: [1, 3, 5],

	loadAudioFiles: (wordsList, activityData) => {
		// load all the sound effects
		Object.values(KnowledgeForReading.WORD_CARD_SFX).forEach(sfx => {
			AudioPlayer2.load({name: sfx.name, src: sfx.src});
		});

		AudioPlayer2.loadSound(SpellingClinic.INTRO_VO);
		AudioPlayer2.loadSound(SpellingClinic.SPELLCLINIC_COMPLETE);
		AudioPlayer2.loadSound(SpellingClinic.WORD_SPELL_EXPLAIN);

		wordsList.forEach(wordCard => {
			const word = wordCard.text;
			const constants = {
				word: word,
				asset_id: getContentInfo(activityData, false).assetId
			};
			const word_text_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, constants);
			const word_def_url = bulkReplace(SpellingClinic.WORD_DEF_URL, constants);
			const word_ctx_url = bulkReplace(SpellingClinic.WORD_CTXT_URL, constants);

			// the pronounced word
			AudioPlayer2.load({name: word, src: word_text_url});

			// the definition
			AudioPlayer2.load({name: `def-${word}`, src: word_def_url});

			// the context
			AudioPlayer2.load({name: `ctx-${word}`, src: word_ctx_url});
		});
	},

	initData: async(apiData, activityData, isActivityCharged) => {
		const start = new Date().getTime();

		const wordsDataList = [];
		const promises = apiData.fluencyScores.map(async i => {
			let wordsData = await media.getOne(`${SpellingClinic.wordsDataBaseURL}r180u_content_${i.wordId}.json`);
			wordsData.contextualData[0] = {};
			wordsData.contextualData[0].segmentId = wordsData.id;
			wordsData.contextualData[0].contextualDefinition = wordsData.definition;
			wordsData.contextualData[0].contextualSentence = wordsData.sentence;
			wordsDataList.push(wordsData);
		});
		await Promise.all(promises);

		if (isActivityCharged === false) {
			SpellingClinic.loadAudioFiles(wordsDataList, activityData);
		}

		const end = new Date().getTime();
		if (isActivityCharged === false) {
			await loadSleep(end - start);
		}

		return wordsDataList;
	},

	// move to the next activity
	completeActivity: async (history, activityData) => {
		await transitionToNextActivity(history, {
			activity: {
				isComplete: true,
				studentActivityId: activityData.studentActivityId
			}
		});
	},

	// no need to make this async or await it since these server calls
	// can happen in the background without blocking the UI
	sendProgressToServer: (activityData) => {
		if (isEmpty(activityData)) {
			return;
		}

		let savedActivityData = {
			activity: {
				studentActivityId: activityData.studentActivityId,
				isComplete: false
			}
		};

		api.activity.updateActivity(savedActivityData);
	}
};
