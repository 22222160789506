import React, { useEffect, useState, useRef } from 'react';
import { ActivityInstructionButton, ActivitySupportButton, ActivitySupportVideoButton, SynonymsAndAntonyms } from '@reading/common';
import Footer from '../../containers/App/Footer';
import FooterForwardBack from '../../containers/App/FooterForwardBack';
import Navbar from '../../containers/App/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { useHistory } from 'react-router-dom';
import { isEmpty, shuffle } from 'lodash';
import { KnowledgeForReading } from './KnowledgeForReading';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import { useUnmount } from 'react-use';
import useIdleHelp from '../../utils/useIdleHelp';
import ActivityFrame from '../../containers/App/ActivityFrame';
import { uiSlice } from '../../store/slices/ui';
import { kfrSlice } from '../../store/slices/kfr';
import { LanguageZone } from './LanguageZone';
import useBatchedSetState from '../../utils/useBatchedSetState';
import useRouteInfo from '../../utils/useRouteInfo';

// const actState = getMockData('kfr');
export default function DragDropActivity(props) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { setState } = useBatchedSetState();
	const actState = history.location.state || {};
	const { zone } = useRouteInfo();

	const contentData = actState.taskActivity;
	const activityData = actState.activityData;
	const powerWordMapListData = actState.powerWordMapData;
	const wordText = actState.taskActivity.power_word_focus;
	const maxAttempts = KnowledgeForReading.MAX_ATTEMPTS;
	const isActivity = history.location.pathname.includes('image-drag-drop')
		|| history.location.pathname.includes('synonym-antonym')
		|| history.location.pathname.includes('example-nonexample')

	const { kfrAnswer, bundledActivityData, powerWords, settings, ui } = useSelector(state => {
		return {
			kfrAnswer: state.kfr.kfrAnswer,
			bundledActivityData: state.activity.bundledActivityData,
			powerWords: state.activity.powerwords,
			settings: state.session.session.settings,
			ui: state.ui
		};
	});

	const [status, setStatus] = useState(FooterForwardBack.INITIAL);
	const [activityMeta, setActivityMeta] = useState({});
	const [dndTiles, setDndTiles] = useState([]);
	const [answers, setAnswers] = useState({});
	const [interactive, setInteractive] = useState(true);
	const [attemptGuesses, setAttemptGuesses] = useState([]);
	const attempts = useRef(0);


	const initActivity = () => {
		const { type, answer_type: answerType } = contentData;
		const positiveTitle = contentData.buckets[0].text;
		const negativeTitle = contentData.buckets[1].text;
		const positiveId = positiveTitle.toLowerCase().replace(/[^A-Z0-9]/ig, "_");
		const negativeId = negativeTitle.toLowerCase().replace(/[^A-Z0-9]/ig, "_");

		const dropBuckets = [
			{
				title: positiveTitle,
				id: positiveId,
				audioSrc: KnowledgeForReading.getDropZoneAudio(positiveId)
			},
			{
				title: negativeTitle,
				id: negativeId,
				audioSrc: KnowledgeForReading.getDropZoneAudio(negativeId)
			}
		]

		if (isEmpty(kfrAnswer) || isEmpty(kfrAnswer[wordText])) {
			const dndItems = contentData.buckets.map(i => i.items).flat();
			setDndTiles(shuffle(dndItems))
		}
		else {
			setDndTiles(kfrAnswer[wordText])
			handleDndChange({ dndMap: kfrAnswer[wordText] })
		}

		setActivityMeta({ dropBuckets, type, answerType, contentData });
	}
	// ---------------------------------------------------


	useEffect(() => {
		if (isEmpty(contentData) === false && isActivity) {
			AudioPlayer2.load({
				name: 'help_sound',
				src: contentData.help_url
			});
			AudioPlayer2.load({
				name: 'directions' + contentData.power_word_focus,
				src: contentData.stem_url
			});

			if (settings.autoPlayEnabled === true && ui.autoPlayedSounds.includes('image-drag-drop-' + contentData.power_word_focus) === false) {
				dispatch(uiSlice.actions.addAutoPlayedSound('image-drag-drop-' + contentData.power_word_focus));
				AudioPlayer2.play('directions' + contentData.power_word_focus);
			}

			initActivity();
		}
	}, [contentData]);
	// ---------------------------------------------------


	const updateAfterGuessKFR = (isCorrect, answerId, isComplete) => {
		let attemptArray = attemptGuesses;
		attemptArray.push({
			correct: isCorrect,
			id: answerId,
			timestamp: new Date()
		});
		setAttemptGuesses(attemptArray);

		let datatoServer = {
			isComplete: isComplete,
			endTime: isComplete ? new Date() : ''
		};

		KnowledgeForReading.sendProgressToServer(
			datatoServer,
			attemptArray,
			contentData,
			bundledActivityData,
			powerWordMapListData,
			history,
			dispatch,
			powerWords
		);
	};
	// ---------------------------------------------------

	const updateAfterGuessLanguage = (correctCount, totalCount, responsesToServer = []) => {
		LanguageZone.sendProgressToServer(activityData, contentData.power_word_focus, correctCount, 5, responsesToServer);
	}

	const handleDndChange = (state = {}) => {
		const { dndMap } = state;

		if (!isEmpty(dndMap)) {
			const dndList = Object.values(dndMap);
			const questions = dndList.filter(i => i.target === 'questions')

			if (questions.length === 0) {
				setState(() => {
					setStatus(FooterForwardBack.SUBMIT);
					setAnswers(dndList);
				});
			}
			else {
				setStatus(FooterForwardBack.INITIAL);
			}

			// setDndTiles(dndState['dndList']);

			if (zone === 'explore') {
				dispatch(kfrSlice.actions.storeKFRAnswer({
					id: wordText,
					answer: { ...dndMap }
				}));
			}
		}
	}

	/* istanbul ignore next */
	const isSyn = () => {
		return activityData && activityData.activity_server_id !== 'example_non_example';
	}
	// ---------------------------------------------------


	const validateAttempts = (isValid, correctCount, totalCount) => {
		const validationText = isValid ? 'valid' : 'inValid';
		const type = isSyn() ? 'synAnt' : 'exmNonExm';
		let audioName = '';

		const validation = {
			valid: {
				0: {
					synAnt: KnowledgeForReading.SOUND_SYNANT_CORRECT_FIRST,
					exmNonExm: KnowledgeForReading.SOUND_EXAMP_CORRECT_FIRST
				},
				1: {
					synAnt: KnowledgeForReading.SOUND_SYNANT_CORRECT_SECOND,
					exmNonExm: KnowledgeForReading.SOUND_EXAMP_CORRECT_SECOND
				},
				2: {
					synAnt: KnowledgeForReading.SOUND_SYNANT_GREAT,
					exmNonExm: KnowledgeForReading.SOUND_EXAMP_GREAT
				}
			},
			inValid: {
				0: {
					synAnt: KnowledgeForReading.SOUND_SYNANT_INCORRECT_FIRST,
					exmNonExm: KnowledgeForReading.SOUND_EXAMP_INCORRECT_FIRST
				},
				1: {
					synAnt: KnowledgeForReading.SOUND_SYNANT_INCORRECT_SECOND,
					exmNonExm: KnowledgeForReading.SOUND_EXAMP_INCORRECT_SECOND
				},
				2: {
					synAnt: KnowledgeForReading.SOUND_SYNANT_INCORRECT_FINAL,
					exmNonExm: KnowledgeForReading.SOUND_EXAMP_INCORRECT_FINAL
				}
			}
		}

		audioName = `${validationText}-${attempts.current}-${type}`;
		AudioPlayer2.load({
			name: audioName,
			src: validation[validationText][attempts.current][type]
		})
		AudioPlayer2.play(audioName);

		if (attempts.current < maxAttempts - 1) {
			// Not yet maxed out
			attempts.current++;

			if (isValid) {
				setStatus(FooterForwardBack.VALID);

				if (zone === 'explore') {
					updateAfterGuessKFR(true, {}, true);
				}
				else {
					updateAfterGuessLanguage(correctCount, totalCount);
				}
			}
			else {
				setStatus(FooterForwardBack.INVALID);

				if (zone === 'explore') {
					updateAfterGuessKFR(false, {}, false);
				}
				else {
					updateAfterGuessLanguage(correctCount, totalCount);
				}
			}
			return false; //Is last attempt
		}
		else {
			// All attempts failed. Go back.
			setStatus(FooterForwardBack.VALID);
			return true; //Is last attempt
		}
	}
	// ---------------------------------------------------

	const isValidOutcome = (positiveCorrect, negativeCorrect, totalWords) => {
		const correctPercent = (positiveCorrect.length + negativeCorrect.length) / totalWords;
		// Completion criteria:: Explore Zone:100%, language zone: 75%
		const COMPLETE_THRESHOLD = zone === 'explore' ? 1 : 0.75;

		return correctPercent >= COMPLETE_THRESHOLD;
	}
	// ---------------------------------------------------


	const validateAnswers = () => {
		const { dropBuckets } = activityMeta;
		const positiveLabel = dropBuckets[0].id;
		const negativeLabel = dropBuckets[1].id;

		const synonyms = answers.filter(i => i.target === positiveLabel).map(i => i.id);
		const antonyms = answers.filter(i => i.target === negativeLabel).map(i => i.id);

		const positiveList = contentData.buckets.find(i => i.text === dropBuckets[0].title);
		const negativeList = contentData.buckets.find(i => i.text === dropBuckets[1].title);
		const totalWords = synonyms.length + antonyms.length;

		const positiveCorrect = positiveList.items.filter(i => synonyms.includes(i.id));
		const negativeCorrect = negativeList.items.filter(i => antonyms.includes(i.id));
		const isValid = isValidOutcome(positiveCorrect, negativeCorrect, totalWords);

		const isLastAttempt = validateAttempts(isValid, positiveCorrect.length, totalWords);
		// -------------

		let validationResults = [];
		if (isLastAttempt) {
			// Last attempt. So show the right answers in right buckets
			validationResults = [
				...positiveList.items.map(i => ({ ...i, target: positiveLabel, validation: 'correct', interactive: false })),
				...negativeList.items.map(i => ({ ...i, target: negativeLabel, validation: 'correct', interactive: false })),
			];
		}
		else {
			// Not the last attempt. So validate the answers
			validationResults = answers.map(item => {
				const itemTitle = activityMeta.dropBuckets.find(i => i.id === item.target).title;
				const validationData = contentData.buckets.find(i => i.text === itemTitle).items;

				if (validationData.find(ans => ans.id === item.id)) {
					return { ...item, validation: 'correct', interactive: false }
				}
				else {
					return { ...item, validation: 'incorrect', interactive: false }
				}
			});
		}

		setState(() => {
			setDndTiles(validationResults);
			setInteractive(false);
		});

		if (zone === 'explore') {
			dispatch(kfrSlice.actions.storeKFRAnswer({
				id: wordText,
				answer: null
			}));
		}
	}
	// ---------------------------------------------------


	const handleForward = async () => {
		AudioPlayer2.stopAll();

		switch (status) {
			case FooterForwardBack.SUBMIT:
				// Validate correct answers
				validateAnswers();
				break;

			case FooterForwardBack.INVALID:
				// Reset Activity
				initActivity();
				setState(() => {
					setInteractive(true);
					setStatus(FooterForwardBack.INITIAL);
				})
				break;

			case FooterForwardBack.VALID:
				// Completed. Go back to word cards
				if (zone === 'explore') {
					history.goBack();
				}
				else {
					try {
						await LanguageZone.completeActivity(history, activityData, contentData.power_word_focus, 2, 2);
					}
					catch (err) {
						dispatch(uiSlice.actions.setErrorMessage(err));
					}
				}
				break;

			default:
				break;
		}
	}
	// ---------------------------------------------------


	const handleWordClick = e => {
		history.goBack();
	};
	// ---------------------------------------------------


	// stop any sounds
	useUnmount(() => {
		AudioPlayer2.stopAll();
		dispatch(uiSlice.actions.setErrorMessage(''));
	});
	// ---------------------------------------------------


	/* istanbul ignore next */
	const getIntroSound = () => {
		return 'directions' + wordText;
	}
	// ---------------------------------------------------

	useIdleHelp(contentData.help_url);
	return (
		<>
			<Navbar helpSoundUrl={contentData.help_url} />
			<ActivitySuspense requiredRenderData={[contentData, dndTiles]}>
				<ActivityFrame isWhiteBackground={true}>
					<ActivityInstructionButton audioName={getIntroSound()} />
					<SynonymsAndAntonyms
						dndData={dndTiles}
						metaData={activityMeta}
						onDndChange={handleDndChange}
					/>
				</ActivityFrame>

				<Footer>
					{zone === 'explore' && (
						<>
							<ActivitySupportButton
								disabled={status === FooterForwardBack.VALID || status === FooterForwardBack.INVALID}
								icon="word"
								onClick={handleWordClick}
								text="Word Card"
							/>
							<ActivitySupportVideoButton
								disabled={status === FooterForwardBack.VALID || status === FooterForwardBack.INVALID}
								video_hint={contentData.video_hint}
							/>
						</>
					)}
					<FooterForwardBack status={status} isBackVisible={false} onForward={handleForward} />
				</Footer>
			</ActivitySuspense>
		</>
	);
}
