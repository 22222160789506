import React, {useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {TimelineMax, Power4} from 'gsap';
import clsx from 'clsx';
import {CaptionedAudioPlayer2 as AudioPlayer2} from '@reading/common';

import {useStyle} from './WordCardTips.style';
import {UseWordCardDispatch, usePreloader} from '../WordCardContext';
import {
	WordCardPreloaderStatus,
	WordCardText,
	WordCardDecoder
} from '../constants';

import {WordCardUtil} from '../WordCardUtil';
import {KnowledgeForReading} from '@reading/r180/src/activities/WordCard/KnowledgeForReading';
import {getAudioExt} from '@reading/r180/src/utils/audio';

const WordCardTips = ({
	onComplete,
	decodingInfo,
	isTipsHighlightWithSameColor
}) => {
	const wordTips = WordCardUtil.getWordTips(decodingInfo);
	const wordPartsAudio = WordCardUtil.getWordPartsAudioForTips(decodingInfo);
	const wordTipsAudio = WordCardUtil.getWordTipsAudio(decodingInfo.id);
	const getAlphWords = () => {
		if (decodingInfo.id === '19') {
			if (decodingInfo.varCode === 1) {
				return [
					decodingInfo.markup
						.split('<')[0]
						.split('+')
						.join(''),
					decodingInfo.markup
						.split('>')[1]
						.split('+')
						.join('')
				].join('');
			} else {
				return decodingInfo.markup
					.split('>')[1]
					.split('+')
					.join('');
			}
		} else {
			return decodingInfo.element;
		}
	};
	const wordAlphAudio = WordCardUtil.getWordAlphAudio(getAlphWords());
	const wordBaseChangeAudio = WordCardUtil.getWordBaseChangeAudio(
		decodingInfo.baseChange
	);
	const wordCardDispatch = UseWordCardDispatch();
	const preloader = usePreloader(WordCardDecoder.TIPS);
	const wordTextSound = {
		audioName: `wordText-${decodingInfo.wordText}`,
		audioUrl: `/word/r180u_word_${decodingInfo.wordText}.${getAudioExt()}`
	};
	const normalColor = 'rgba(255, 175, 102, 0)';
	const highlightColor = '#FFFCB0';
	const highlightTipsWordColor = 'rgba(255, 175, 102, 1)';
	const greyColor = '#8ea9b9';
	const timelineRef = useRef(null);
	const spritesRef = useRef(null);
	const classes = useStyle({normalColor});

	const init = useCallback(() => {
		timelineRef.current && timelineRef.current.pause();
		timelineRef.current && timelineRef.current.clear();
		timelineRef.current && timelineRef.current.kill();

		KnowledgeForReading.loadSfxAudios();

		if (!preloader) {
			wordCardDispatch({
				type: '@@word-card/preload-sound',
				payload: {
					name: WordCardDecoder.TIPS,
					assets: [
						wordTextSound,
						...wordPartsAudio,
						...wordTipsAudio,
						...wordAlphAudio,
						...wordBaseChangeAudio
					]
				}
			});
		}
	}, [
		wordPartsAudio,
		wordTipsAudio,
		wordAlphAudio,
		wordBaseChangeAudio,
		preloader,
		wordCardDispatch,
		wordTextSound
	]);

	const start = useCallback(() => {
		timelineRef.current.play();
	}, []);

	const stop = useCallback(() => {
		AudioPlayer2.stopAll();
		onComplete();
	}, [onComplete]);

	const playSound = useCallback(
		sound => () => {
			AudioPlayer2.play(sound);
		},
		[]
	);

	const destroy = useCallback(() => {
		AudioPlayer2.stopAll();
		timelineRef.current && timelineRef.current.pause();
		timelineRef.current && timelineRef.current.clear();
		timelineRef.current && timelineRef.current.kill();
		timelineRef.current = null;
	}, []);

	const addEnterStageScene = useCallback(
		(timeline, shouldRemoveHighlightor = false) => {
			const normalWordTips = wordTips.filter(
				x =>
					x.type === WordCardText.TIPS ||
					x.type === WordCardText.TIPSFOCUS ||
					x.type === WordCardText.TIPREPLACE
			);
			const wordSprites = normalWordTips.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			const separatorParts = wordTips.filter(
				x => x.type === WordCardText.SEPARATOR
			);

			const separatorSprites = separatorParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			const higlightorParts = wordTips.filter(
				x => x.type === WordCardText.TIPHIGHLIGHT
			);

			const highlightorSprites = higlightorParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			const replaceParts = wordTips.filter(
				x => x.type === WordCardText.TIPREPLACE
			);

			const replaceSprites = replaceParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline.set(spritesRef.current, {opacity: 1});
			if (separatorSprites.length > 0) {
				timeline.set(separatorSprites, {
					delay: -0.25,
					width: 0,
					height: 0,
					scale: 0,
					'margin-left': 0,
					'margin-right': 0,
					opacity: 0
				});
			}
			if (shouldRemoveHighlightor && highlightorSprites.length > 0) {
				timeline.set(highlightorSprites, {
					delay: -0.25,
					scale: 0,
					width: 0,
					height: 0,
					opacity: 0
				});
			}

			timeline.staggerFrom(
				wordSprites,
				1,
				{
					transformOrigin: '50% 50%',
					y: -50,
					opacity: 0,
					'background-color': normalColor,
					ease: Power4.easeOut,
					autoAlpha: 0,
					onStart: playSound(
						KnowledgeForReading.WORD_CARD_SFX.SHOW_SOUND.name
					)
				},
				0.2
			);
		},
		[normalColor, playSound, wordTips]
	);

	const addHighlightTipsScene = useCallback(
		(timeline, audioName) => {
			timeline.to(
				spritesRef.current,
				{
					duration: WordCardUtil.getDuration(audioName),
					delay: 0.1,
					onStart: playSound(audioName)
				},
				spritesRef.current
			);
		},
		[playSound, wordTips]
	);

	const readHighlightTipsScene = useCallback(
		(timeline, audioName, focusWords) => {
			let bgColor = isTipsHighlightWithSameColor
				? highlightTipsWordColor
				: highlightColor;

			timeline.add('readHighlightTipsScene');
			focusWords.forEach(x => {
				const sprite = spritesRef.current.querySelector(`#${x.id}`);
				timeline.set(
					sprite,
					{
						'background-color': bgColor,
						'border-radius': '0px',
						duration: WordCardUtil.getDuration(audioName)
					},
					'readHighlightTipsScene'
				);
			});
			timeline.to(
				focusWords,
				{
					duration: WordCardUtil.getDuration(audioName),
					delay: 0.25,
					onStart: playSound(audioName)
				},
				'readHighlightTipsScene'
			);
		},
		[highlightColor, playSound, wordTips]
	);

	const addSeperatorTipsScene = useCallback(
		(timeline, audioName) => {
			let bgColor = isTipsHighlightWithSameColor
				? highlightTipsWordColor
				: highlightColor;

			const focusWords = wordTips.filter(
				x => x.type === WordCardText.TIPSFOCUS
			);

			const separatorParts = wordTips.filter(
				x => x.type === WordCardText.SEPARATOR
			);

			const separatorSprites = separatorParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline.add('addSeperatorTipsScene').to(
				separatorSprites,
				{
					duration: WordCardUtil.getDuration(audioName),
					'background-color': normalColor,
					width: 20,
					height: 20,
					margin: '0px 4px',
					scale: 1,
					opacity: 1,
					delay: 1,
					onStart: playSound(audioName)
				},
				'addSeperatorTipsScene'
			);

			focusWords.forEach(x => {
				const sprite = spritesRef.current.querySelector(`#${x.id}`);
				timeline.set(
					sprite,
					{
						'background-color': bgColor,
						'border-radius': '4px'
					},
					'addSeperatorTipsScene'
				);
			});
		},
		[highlightColor, playSound, wordTips]
	);

	const removeReplaceWordScene = useCallback((timeline, replaceParts) => {
		const replaceSprites = replaceParts.map(x =>
			spritesRef.current.querySelector(`#${x.id}`)
		);

		timeline.set(replaceSprites, {
			scale: 0,
			width: 0,
			height: 0,
			opacity: 0
		});
	});

	const addSeperatorWithoutHighlightTipsScene = useCallback(
		(timeline, audioName, showHighlightor = false) => {
			const separatorParts = wordTips.filter(
				x => x.type === WordCardText.SEPARATOR
			);

			const separatorSprites = separatorParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			const highlightorParts = wordTips.filter(
				x => x.type === WordCardText.TIPHIGHLIGHT
			);

			const highlightorSprites = highlightorParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline.add('addSeperatorWithoutHighlightTipsScene').to(
				separatorSprites,
				{
					duration: WordCardUtil.getDuration(audioName),
					'background-color': normalColor,
					width: 20,
					height: 20,
					margin: '0px 4px',
					scale: 1,
					opacity: 1,
					delay: 1,
					onStart: playSound(audioName)
				},
				'addSeperatorWithoutHighlightTipsScene'
			);
			if (showHighlightor && highlightorSprites.length > 0) {
				timeline.to(
					highlightorSprites,
					{
						duration: WordCardUtil.getDuration(audioName),
						'background-color': normalColor,
						scale: 1,
						opacity: 1,
						margin: 0,
						width: 'auto',
						height: 'auto'
					},
					'addSeperatorWithoutHighlightTipsScene'
				);
			} else if (highlightorSprites.length > 0) {
				timeline.to(
					highlightorSprites,
					{
						duration: WordCardUtil.getDuration(audioName),
						'background-color': normalColor,
						delay: -0.25,
						opacity: 0
					},
					'addSeperatorWithoutHighlightTipsScene'
				);
			}
		},
		[normalColor, playSound, wordTips]
	);

	const removeHighlightTipsScene = useCallback(
		(timeline, audioName) => {
			timeline.add('removeHighlightTipsScene');

			wordTips.forEach(x => {
				const sprite = spritesRef.current.querySelector(`#${x.id}`);
				if (x.type === WordCardText.SILENT) {
					timeline.set(
						sprite,
						{
							'background-color': greyColor
						},
						'removeHighlightTipsScene'
					);
				} else {
					timeline.set(
						sprite,
						{
							'background-color': normalColor
						},
						'removeHighlightTipsScene'
					);
				}
			});
			if (audioName && audioName.length > 0) {
				timeline.to(
					spritesRef.current,
					{
						duration: WordCardUtil.getDuration(audioName),
						delay: 1,
						'background-color': normalColor,
						onStart: playSound(audioName)
					},
					'removeHighlightTipsScene'
				);
			}
		},
		[greyColor, normalColor, playSound, wordTips]
	);
	const addHighlightBaseWordTipsScene = useCallback(
		(timeline, focusWord, audioName) => {
			let bgColor = isTipsHighlightWithSameColor
				? highlightTipsWordColor
				: highlightColor;
			timeline.add('addHighlightBaseWordTipsScene');
			timeline.set(
				spritesRef.current,
				{
					duration: WordCardUtil.getDuration(audioName),
					delay: 0.25,
					onStart: playSound(audioName)
				},
				'addHighlightBaseWordTipsScene'
			);
			const sprite = spritesRef.current.querySelector(`#${focusWord.id}`);
			timeline.to(
				sprite,
				{
					'background-color': bgColor,
					'border-radius': 0,
					opacity: 1 //required for case 13 with baseChange 3
				},
				'addHighlightBaseWordTipsScene'
			);
			timeline.to(
				sprite,
				{
					delay: WordCardUtil.getDuration(audioName) + 0.25,
					'background-color': normalColor,
					'border-radius': 0
				},
				'addHighlightBaseWordTipsScene'
			);
		},
		[highlightColor, playSound, wordPartsAudio]
	);

	const addBlinkForHighlighWordTipsScene = useCallback(
		(timeline, focusWord, audioName) => {
			timeline.add('addBlinkForHighlighWordTipsScene');
			timeline.set(
				spritesRef.current,
				{
					duration: WordCardUtil.getDuration(audioName),
					delay: 0.25,
					onStart: playSound(audioName)
				},
				'addBlinkForHighlighWordTipsScene'
			);
			const sprite = spritesRef.current.querySelector(`#${focusWord.id}`);
			timeline.to(
				sprite,
				{
					'border-radius': 0,
					autoAlpha: 0,
					duration: 1,
					repeat: Math.ceil(WordCardUtil.getDuration(audioName)) - 1
				},
				'addBlinkForHighlighWordTipsScene'
			);
			timeline.to(
				sprite,
				{
					autoAlpha: 1,
					delay: WordCardUtil.getDuration(audioName) + 0.25,
					'background-color': normalColor,
					'border-radius': 0
				},
				'addBlinkForHighlighWordTipsScene'
			);
		},
		[highlightColor, playSound, wordPartsAudio]
	);

	const addHighlightElementAplhTipsScene = useCallback(
		(timeline, focusWord, audios) => {
			let bgColor = isTipsHighlightWithSameColor
				? highlightTipsWordColor
				: highlightColor;
			timeline.add('addHighlightElementAplhTipsScene');
			const sprite = spritesRef.current.querySelector(`#${focusWord.id}`);
			timeline.to(
				sprite,
				{
					'background-color': bgColor,
					'border-radius': 0
				},
				'addHighlightElementAplhTipsScene'
			);
			audios.forEach((audio, index) => {
				timeline.add(`addHighlightElementAplhTipsScene_${index}`);
				timeline.set(
					spritesRef.current,
					{
						duration: WordCardUtil.getDuration(audio.audioName),
						delay: 0.75,
						onStart: playSound(audio.audioName)
					},
					`addHighlightElementAplhTipsScene_${index}`
				);
			});
			timeline.to(
				sprite,
				{
					delay: audios.length,
					'background-color': normalColor,
					'border-radius': 0
				},
				`addHighlightElementAplhTipsScene_${audios.length}`
			);
		},
		[highlightColor, playSound, wordPartsAudio]
	);

	const addHighlightToBaseWordWithHighlightorScene = useCallback(
		(timeline, focusWords, audioName) => {
			let bgColor = isTipsHighlightWithSameColor
				? highlightTipsWordColor
				: highlightColor;
			timeline.add('addHighlightToBaseWordWithHighlightorScene');
			timeline.set(
				spritesRef.current,
				{
					duration: WordCardUtil.getDuration(audioName),
					delay: 0.25,
					onStart: playSound(audioName)
				},
				'addHighlightToBaseWordWithHighlightorScene'
			);
			focusWords.forEach(word => {
				const sprite = spritesRef.current.querySelector(`#${word.id}`);
				timeline.to(
					sprite,
					{
						'background-color': bgColor,
						'border-radius': 0
					},
					'addHighlightToBaseWordWithHighlightorScene'
				);
				timeline.to(
					sprite,
					{
						delay: WordCardUtil.getDuration(audioName) + 0.25,
						'background-color': normalColor,
						'border-radius': 0
					},
					'addHighlightToBaseWordWithHighlightorScene'
				);
			});
		},
		[highlightColor, playSound, wordPartsAudio]
	);

	const addTextSoundScene = useCallback(
		(timeline, audioName, index) => {
			timeline.add(`addTextSoundScene_${index}`).to(
				spritesRef.current,
				{
					duration: WordCardUtil.getDuration(audioName),
					delay: 0.1,
					onStart: playSound(audioName)
				},
				`addTextSoundScene_${index}`
			);
		},
		[playSound]
	);

	const addHighlightPartsScene = useCallback(
		timeline => {
			if (wordPartsAudio && wordPartsAudio.length > 0) {
				let seperatorLength = 0;
				const generateNewTips = [];
				let bgColor = isTipsHighlightWithSameColor
					? highlightTipsWordColor
					: highlightColor;
				wordTips.forEach(word => {
					if (word.type === WordCardText.SEPARATOR) {
						seperatorLength++;
					} else {
						if (!generateNewTips[seperatorLength]) {
							generateNewTips[seperatorLength] = [];
						}
						generateNewTips[seperatorLength].push(word);
					}
				});
				timeline.add('addHighlightPartsScene');

				generateNewTips.forEach((tip, index) => {
					timeline.add(`addHighlightPartsScene${index}`);
					timeline.set(
						spritesRef.current,
						{
							duration: WordCardUtil.getDuration(
								wordPartsAudio[index].audioName
							),
							delay: 0.25,
							onStart: playSound(wordPartsAudio[index].audioName)
						},
						`addHighlightPartsScene${index}`
					);
					tip.forEach(x => {
						const sprite = spritesRef.current.querySelector(
							`#${x.id}`
						);

						if (x.type !== WordCardText.SILENT) {
							timeline.to(
								sprite,
								{
									'background-color': bgColor,
									'border-radius': 0
								},
								`addHighlightPartsScene${index}`
							);
							timeline.to(
								sprite,
								{
									delay:
										WordCardUtil.getDuration(
											wordPartsAudio[index].audioName
										) + 0.25,
									'background-color': normalColor,
									'border-radius': 0
								},
								`addHighlightPartsScene${index}`
							);
						}
					});
				});
			}
		},
		[highlightColor, playSound, wordPartsAudio]
	);

	const addReplaceWordScene = useCallback((timeline, replaceParts) => {
		const replaceSprites = replaceParts.map(x =>
			spritesRef.current.querySelector(`#${x.id}`)
		);

		timeline.add('addReplaceWordScene').to(
			replaceSprites,
			{
				scale: 1,
				width: 'auto',
				height: 'auto',
				opacity: 1
			},
			'addReplaceWordScene'
		);
	}, []);

	const removeHighlightSilentTipsScene = useCallback(
		timeline => {
			const silentSprites = wordTips
				.filter(x => x.type === WordCardText.SILENT)
				.map(x => spritesRef.current.querySelector(`#${x.id}`));

			timeline.add('removeHighlightSilentTipsScene').to(
				silentSprites,
				{
					'background-color': normalColor
				},
				'removeHighlightSilentTipsScene'
			);
		},
		[normalColor, wordTips]
	);

	const addConnectPartsScene = useCallback(
		(
			timeline,
			shouldRemoveHighlightor = false,
			shouldHighlight = false,
			shouldHighlightSilentWords = false
		) => {
			let bgColor = isTipsHighlightWithSameColor
				? highlightTipsWordColor
				: highlightColor;
			const normalWordTips = wordTips.filter(
				x =>
					x.type === WordCardText.TIPS ||
					x.type === WordCardText.TIPSFOCUS ||
					x.type === WordCardText.TIPREPLACE
			);

			const separatorParts = wordTips.filter(
				x => x.type === WordCardText.SEPARATOR
			);

			const highlightorParts = wordTips.filter(
				x => x.type === WordCardText.TIPHIGHLIGHT
			);

			const silentParts = wordTips.filter(
				x => x.type === WordCardText.SILENT
			);

			const wordSprites = normalWordTips.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			const separatorSprites = separatorParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			const highlightorSprites = highlightorParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			const silentSprites = silentParts.map(x =>
				spritesRef.current.querySelector(`#${x.id}`)
			);

			timeline
				.add('ConnectPartsScene', '+=0.35')
				.to(
					wordSprites,
					{
						duration: 0.35,
						delay: 0.5,
						'background-color': bgColor
					},
					'ConnectPartsScene'
				)
				.to(
					separatorSprites,
					{
						transformOrigin: '50% 50%',
						duration: 0.35,
						delay: 0.5,
						width: 0,
						height: 0,
						scale: 0,
						'margin-left': 0,
						'margin-right': 0,
						opacity: 0,
						onStart: playSound(
							KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
						)
					},
					'ConnectPartsScene'
				);
			if (shouldRemoveHighlightor && highlightorSprites.length > 0) {
				timeline.set(highlightorSprites, {
					delay: -0.25,
					scale: 0,
					width: 0,
					height: 0,
					opacity: 0
				});
			}
			if (shouldHighlight && highlightorSprites.length > 0) {
				timeline.set(highlightorSprites, {
					duration: 0.35,
					'background-color': bgColor
				});
			}

			if (shouldHighlightSilentWords && silentSprites.length > 0) {
				timeline.set(silentSprites, {
					duration: 0.35,
					'background-color': bgColor
				});
			}
		},
		[normalColor, playSound, wordTips]
	);

	const addReadWordTextScene = useCallback(
		timeline => {
			let bgColor;
			if (!isTipsHighlightWithSameColor) {
				bgColor = highlightColor;
			} else {
				bgColor = '';
			}
			timeline
				.add(
					'ReadWordTextScene',
					`+=${WordCardUtil.getDuration(wordTextSound.audioName)}`
				)
				.set(spritesRef.current, {paddingLeft: 4, paddingRight: 4})
				.to(
					spritesRef.current,
					{
						duration: WordCardUtil.getDuration(
							wordTextSound.audioName
						),
						'background-color': bgColor,
						onStart: playSound(wordTextSound.audioName)
					},
					'ReadWordTextScene'
				);
		},
		[highlightColor, playSound, wordTextSound]
	);

	const addNormalizeWordTextScene = useCallback(
		timeline => {
			timeline.add('NormalizeWordTextScene', '+=0.2').to(
				spritesRef.current,
				{
					duration: 0.2,
					delay: 0.2,
					'background-color': normalColor,
					onStart: playSound(
						KnowledgeForReading.WORD_CARD_SFX.CONTRACT_SOUND.name
					)
				},
				'NormalizeWordTextScene'
			);
		},
		[normalColor]
	);

	const addHighlightWordTextScene = useCallback(
		timeline => {
			let bgColor = isTipsHighlightWithSameColor
				? highlightTipsWordColor
				: highlightColor;

			timeline.add('addHighlightWordTextScene');
			let delay = 0;
			wordTips.forEach(x => {
				const sprite = spritesRef.current.querySelector(`#${x.id}`);
				timeline
					.set(
						sprite,
						{
							'background-color': bgColor,
							'border-radius': '0px',
							delay: delay
						},
						x.id
					)
					.to(
						sprite,
						{
							'background-color': normalColor,
							delay: delay + 0.5
						},
						x.id
					);
			});
		},
		[highlightColor, wordTips]
	);

	const addLeaveStageScene = useCallback(
		timeline => {
			timeline.add('LeaveStageScene', '+=0.2').to(
				spritesRef.current,
				{
					duration: 0.2,
					delay: 1,
					opacity: 0,
					onStart: playSound(
						KnowledgeForReading.WORD_CARD_SFX.HIDE_SOUND.name
					),
					onComplete: () => stop()
				},
				'LeaveStageScene'
			);
		},
		[playSound, stop]
	);

	const setup = useCallback(() => {
		const timeline = new TimelineMax({paused: true});
		switch (decodingInfo.id) {
			case '1':
			case '2': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				readHighlightTipsScene(
					timeline,
					wordTextSound.audioName,
					focusWords
				);
				removeHighlightTipsScene(timeline);
				addTextSoundScene(timeline, wordTipsAudio[1].audioName, 0);
				addHighlightWordTextScene(timeline);
				readHighlightTipsScene(
					timeline,
					wordTextSound.audioName,
					focusWords
				);
				removeHighlightTipsScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}
			case '3': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPSFOCUS
				);
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				readHighlightTipsScene(
					timeline,
					wordPartsAudio[1].audioName,
					focusWords
				);
				addTextSoundScene(timeline, wordTipsAudio[1].audioName, 0);
				removeHighlightTipsScene(timeline);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightPartsScene(timeline);
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}
			case '12': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);
				const getElementSoundAudioName = () => {
					switch (decodingInfo.varCode) {
						case 0:
						case 5:
						case 6: {
							return wordTipsAudio[4].audioName;
						}
						case 1:
						case 2:
						case 3:
						case 4: {
							return wordTipsAudio[2].audioName;
						}
						case 9:
							return wordTipsAudio[3].audioName;
						default:
							return wordTipsAudio[5].audioName;
					}
				};
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightBaseWordTipsScene(
					timeline,
					focusWords[0],
					wordPartsAudio[0].audioName
				);
				addTextSoundScene(timeline, wordTipsAudio[1].audioName, 0);
				addHighlightBaseWordTipsScene(
					timeline,
					focusWords[1],
					getElementSoundAudioName()
				);
				if (decodingInfo.varCode !== 0) {
					addHighlightBaseWordTipsScene(
						timeline,
						focusWords[1],
						wordTipsAudio[decodingInfo.varCode + 5].audioName
					);
				}
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}

			case '13': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPSFOCUS
				);

				const highlightWords = wordTips.filter(
					x => x.type === WordCardText.TIPHIGHLIGHT
				);

				const replaceWords = wordTips.filter(
					x => x.type === WordCardText.TIPREPLACE
				);

				const normalWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);

				const getElementSoundAudioName = () => {
					switch (decodingInfo.element) {
						case 'ed': {
							return wordTipsAudio[2].audioName;
						}
						case 'ing': {
							return wordTipsAudio[3].audioName;
						}
						case 'es': {
							return wordTipsAudio[4].audioName;
						}
						default:
							return wordTipsAudio[2].audioName;
					}
				};
				switch (decodingInfo.baseChange) {
					case 1: {
						addEnterStageScene(timeline, true);
						addHighlightTipsScene(
							timeline,
							wordTipsAudio[0].audioName
						);
						removeReplaceWordScene(timeline, replaceWords);
						addSeperatorWithoutHighlightTipsScene(
							timeline,
							KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name,
							true
						);
						addHighlightToBaseWordWithHighlightorScene(
							timeline,
							[...focusWords, ...highlightWords],
							wordPartsAudio[0].audioName
						);
						addTextSoundScene(
							timeline,
							wordTipsAudio[1].audioName,
							0
						);
						addHighlightBaseWordTipsScene(
							timeline,
							normalWords[0],
							getElementSoundAudioName()
						);
						removeReplaceWordScene(timeline, highlightWords);
						addReplaceWordScene(timeline, replaceWords);
						addHighlightBaseWordTipsScene(
							timeline,
							replaceWords[0],
							wordBaseChangeAudio[0].audioName
						);
						addConnectPartsScene(timeline, true);
						addReadWordTextScene(timeline);
						addNormalizeWordTextScene(timeline);
						addLeaveStageScene(timeline);
						break;
					}
					case 3: {
						addEnterStageScene(timeline, false);
						addHighlightTipsScene(
							timeline,
							wordTipsAudio[0].audioName
						);
						addSeperatorWithoutHighlightTipsScene(
							timeline,
							KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name,
							false
						);
						addHighlightToBaseWordWithHighlightorScene(
							timeline,
							[...focusWords, ...highlightWords],
							wordPartsAudio[0].audioName
						);
						addTextSoundScene(
							timeline,
							wordTipsAudio[1].audioName,
							0
						);
						addHighlightBaseWordTipsScene(
							timeline,
							normalWords[0],
							getElementSoundAudioName()
						);
						addHighlightBaseWordTipsScene(
							timeline,
							highlightWords[0],
							wordBaseChangeAudio[0].audioName
						);
						addConnectPartsScene(timeline, false, true);
						addReadWordTextScene(timeline);
						addNormalizeWordTextScene(timeline);
						addLeaveStageScene(timeline);
						break;
					}

					case 5: {
						addEnterStageScene(timeline, true);
						addHighlightTipsScene(
							timeline,
							wordTipsAudio[0].audioName
						);
						removeReplaceWordScene(timeline, replaceWords);
						addSeperatorWithoutHighlightTipsScene(
							timeline,
							KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name,
							true
						);
						addHighlightToBaseWordWithHighlightorScene(
							timeline,
							focusWords,
							wordPartsAudio[0].audioName
						);
						addTextSoundScene(
							timeline,
							wordTipsAudio[1].audioName,
							0
						);
						addHighlightBaseWordTipsScene(
							timeline,
							normalWords[0],
							getElementSoundAudioName()
						);
						addHighlightToBaseWordWithHighlightorScene(
							timeline,
							focusWords,
							wordBaseChangeAudio[0].audioName
						);
						addReplaceWordScene(timeline, replaceWords);
						addConnectPartsScene(timeline, true);
						addReadWordTextScene(timeline);
						addNormalizeWordTextScene(timeline);
						addLeaveStageScene(timeline);
						break;
					}
					default: {
						addEnterStageScene(timeline, true);
						addHighlightTipsScene(
							timeline,
							wordTipsAudio[0].audioName
						);
						addSeperatorWithoutHighlightTipsScene(
							timeline,
							KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name,
							true
						);
						addHighlightToBaseWordWithHighlightorScene(
							timeline,
							[...focusWords, ...highlightWords],
							wordPartsAudio[0].audioName
						);
						addTextSoundScene(
							timeline,
							wordTipsAudio[1].audioName,
							0
						);
						addHighlightBaseWordTipsScene(
							timeline,
							normalWords[0],
							getElementSoundAudioName()
						);
						addHighlightBaseWordTipsScene(
							timeline,
							highlightWords[0],
							wordBaseChangeAudio[0].audioName
						);
						addConnectPartsScene(timeline, true);
						addReadWordTextScene(timeline);
						addNormalizeWordTextScene(timeline);
						addLeaveStageScene(timeline);
						break;
					}
				}
				break;
			}

			case '14': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);
				const getElementSoundAudioName = () => {
					switch (decodingInfo.varCode) {
						case 1: {
							return wordTipsAudio[2].audioName;
						}
						case 2: {
							return wordTipsAudio[3].audioName;
						}
						case 3: {
							return wordTipsAudio[17].audioName;
						}
						case 4: {
							return wordTipsAudio[19].audioName;
						}
						case 5: {
							return wordTipsAudio[21].audioName;
						}
						case 6: {
							return wordTipsAudio[23].audioName;
						}
						case 7: {
							return wordTipsAudio[25].audioName;
						}
						case 8: {
							return wordTipsAudio[27].audioName;
						}
						case 9: {
							return wordTipsAudio[29].audioName;
						}
						case 10: {
							return wordTipsAudio[31].audioName;
						}
						case 11: {
							return wordTipsAudio[33].audioName;
						}
						case 12: {
							return wordTipsAudio[35].audioName;
						}
						case 13: {
							return wordTipsAudio[37].audioName;
						}
						case 14: {
							return wordTipsAudio[39].audioName;
						}
						case 15: {
							return wordTipsAudio[4].audioName;
						}
						case 16: {
							return wordTipsAudio[41].audioName;
						}
						case 17: {
							return wordTipsAudio[43].audioName;
						}
						case 18: {
							return wordTipsAudio[45].audioName;
						}
						case 19: {
							return wordTipsAudio[47].audioName;
						}
						case 20: {
							return wordTipsAudio[49].audioName;
						}
						case 21: {
							return wordTipsAudio[51].audioName;
						}
						case 22: {
							return wordTipsAudio[53].audioName;
						}
						case 23: {
							return wordTipsAudio[5].audioName;
						}
						case 24: {
							return wordTipsAudio[55].audioName;
						}
						case 25: {
							return wordTipsAudio[6].audioName;
						}
						default:
							return wordTipsAudio[5].audioName;
					}
				};

				const getEndingAudioName = () => {
					switch (decodingInfo.varCode) {
						case 1: {
							return wordTipsAudio[7].audioName;
						}
						case 2: {
							return wordTipsAudio[8].audioName;
						}
						case 3: {
							return wordTipsAudio[18].audioName;
						}
						case 4: {
							return wordTipsAudio[20].audioName;
						}
						case 5: {
							return wordTipsAudio[22].audioName;
						}
						case 6: {
							return wordTipsAudio[24].audioName;
						}
						case 7: {
							return wordTipsAudio[26].audioName;
						}
						case 8: {
							return wordTipsAudio[28].audioName;
						}
						case 9: {
							return wordTipsAudio[30].audioName;
						}
						case 10: {
							return wordTipsAudio[32].audioName;
						}
						case 11: {
							return wordTipsAudio[34].audioName;
						}
						case 12: {
							return wordTipsAudio[36].audioName;
						}
						case 13: {
							return wordTipsAudio[38].audioName;
						}
						case 14: {
							return wordTipsAudio[40].audioName;
						}
						case 15: {
							return wordTipsAudio[9].audioName;
						}
						case 16: {
							return wordTipsAudio[42].audioName;
						}
						case 17: {
							return wordTipsAudio[44].audioName;
						}
						case 18: {
							return wordTipsAudio[46].audioName;
						}
						case 19: {
							return wordTipsAudio[48].audioName;
						}
						case 20: {
							return wordTipsAudio[50].audioName;
						}
						case 21: {
							return wordTipsAudio[52].audioName;
						}
						case 22: {
							return wordTipsAudio[54].audioName;
						}
						case 23: {
							return wordTipsAudio[10].audioName;
						}
						case 24: {
							return wordTipsAudio[56].audioName;
						}
						case 25: {
							return wordTipsAudio[11].audioName;
						}
						default:
							return wordTipsAudio[7].audioName;
					}
				};
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightBaseWordTipsScene(
					timeline,
					focusWords[1],
					wordPartsAudio[0].audioName
				);
				addTextSoundScene(timeline, wordTipsAudio[1].audioName, 0);
				addHighlightBaseWordTipsScene(
					timeline,
					focusWords[0],
					getElementSoundAudioName()
				);
				if (decodingInfo.varCode !== 0) {
					addHighlightBaseWordTipsScene(
						timeline,
						focusWords[0],
						getEndingAudioName()
					);
				}
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}

			case '16': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);
				const getElementSoundAudioName = () => {
					switch (decodingInfo.varCode) {
						case 1: {
							return wordTipsAudio[2].audioName;
						}
						case 2: {
							return wordTipsAudio[4].audioName;
						}
						case 3: {
							switch (decodingInfo.element) {
								case 'ion': {
									return wordTipsAudio[6].audioName;
								}
								case 'tion': {
									return wordTipsAudio[8].audioName;
								}
								case 'ation': {
									return wordTipsAudio[10].audioName;
								}
								default:
									return wordTipsAudio[12].audioName;
							}
						}
						case 4: {
							if (decodingInfo.element === 'ible') {
								return wordTipsAudio[14].audioName;
							} else {
								return wordTipsAudio[16].audioName;
							}
						}
						case 5: {
							if (decodingInfo.element === 'al') {
								return wordTipsAudio[18].audioName;
							} else {
								return wordTipsAudio[20].audioName;
							}
						}
						case 6: {
							return wordTipsAudio[22].audioName;
						}
						case 7: {
							return wordTipsAudio[24].audioName;
						}
						case 8: {
							return wordTipsAudio[26].audioName;
						}
						case 9: {
							return wordTipsAudio[28].audioName;
						}
						case 10: {
							return wordTipsAudio[30].audioName;
						}
						case 11: {
							if (decodingInfo.element === 'ous') {
								return wordTipsAudio[32].audioName;
							} else {
								return wordTipsAudio[34].audioName;
							}
						}
						case 12: {
							return wordTipsAudio[36].audioName;
						}
						case 13: {
							return wordTipsAudio[38].audioName;
						}
						case 14: {
							switch (decodingInfo.element) {
								case 'ive': {
									return wordTipsAudio[40].audioName;
								}
								case 'ative': {
									return wordTipsAudio[42].audioName;
								}
								default:
									return wordTipsAudio[44].audioName;
							}
						}
						case 15: {
							return wordTipsAudio[46].audioName;
						}
						case 16: {
							return wordTipsAudio[48].audioName;
						}
						case 17: {
							return wordTipsAudio[50].audioName;
						}
						case 18: {
							return wordTipsAudio[52].audioName;
						}
						default:
							return wordTipsAudio[4].audioName;
					}
				};

				const getEndingAudioName = () => {
					switch (decodingInfo.varCode) {
						case 1: {
							return wordTipsAudio[3].audioName;
						}
						case 2: {
							return wordTipsAudio[5].audioName;
						}
						case 3: {
							switch (decodingInfo.element) {
								case 'ion': {
									return wordTipsAudio[7].audioName;
								}
								case 'tion': {
									return wordTipsAudio[9].audioName;
								}
								case 'ation': {
									return wordTipsAudio[11].audioName;
								}
								default:
									return wordTipsAudio[13].audioName;
							}
						}
						case 4: {
							if (decodingInfo.element === 'ible') {
								return wordTipsAudio[15].audioName;
							} else {
								return wordTipsAudio[17].audioName;
							}
						}
						case 5: {
							if (decodingInfo.element === 'al') {
								return wordTipsAudio[19].audioName;
							} else {
								return wordTipsAudio[21].audioName;
							}
						}
						case 6: {
							return wordTipsAudio[23].audioName;
						}
						case 7: {
							return wordTipsAudio[25].audioName;
						}
						case 8: {
							return wordTipsAudio[27].audioName;
						}
						case 9: {
							return wordTipsAudio[29].audioName;
						}
						case 10: {
							return wordTipsAudio[31].audioName;
						}
						case 11: {
							if (decodingInfo.element === 'ous') {
								return wordTipsAudio[33].audioName;
							} else {
								return wordTipsAudio[35].audioName;
							}
						}
						case 12: {
							return wordTipsAudio[37].audioName;
						}
						case 13: {
							return wordTipsAudio[39].audioName;
						}
						case 14: {
							switch (decodingInfo.element) {
								case 'ive': {
									return wordTipsAudio[41].audioName;
								}
								case 'ative': {
									return wordTipsAudio[43].audioName;
								}
								default:
									return wordTipsAudio[45].audioName;
							}
						}
						case 15: {
							return wordTipsAudio[47].audioName;
						}
						case 16: {
							return wordTipsAudio[49].audioName;
						}
						case 17: {
							return wordTipsAudio[51].audioName;
						}
						case 18: {
							return wordTipsAudio[53].audioName;
						}
						default:
							return wordTipsAudio[5].audioName;
					}
				};
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightBaseWordTipsScene(
					timeline,
					focusWords[0],
					wordPartsAudio[0].audioName
				);
				addTextSoundScene(timeline, wordTipsAudio[1].audioName, 0);
				addHighlightBaseWordTipsScene(
					timeline,
					focusWords[1],
					getElementSoundAudioName()
				);
				if (decodingInfo.varCode !== 0) {
					addHighlightBaseWordTipsScene(
						timeline,
						focusWords[1],
						getEndingAudioName()
					);
				}
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}

			case '17': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightBaseWordTipsScene(
					timeline,
					focusWords[0],
					wordPartsAudio[0].audioName
				);
				addTextSoundScene(timeline, wordTipsAudio[1].audioName, 0);
				addHighlightElementAplhTipsScene(
					timeline,
					focusWords[1],
					wordAlphAudio
				);
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}

			case '18': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPSFOCUS
				);

				const highlightWords = wordTips.filter(
					x => x.type === WordCardText.TIPHIGHLIGHT
				);

				const normalWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);

				const getElementSoundAudioName = () => {
					switch (decodingInfo.varCode) {
						case 1: {
							return wordTipsAudio[2].audioName;
						}
						case 2: {
							return wordTipsAudio[3].audioName;
						}
						case 3: {
							switch (decodingInfo.element) {
								case 'ion': {
									return wordTipsAudio[4].audioName;
								}
								case 'tion': {
									return wordTipsAudio[5].audioName;
								}
								case 'ation': {
									return wordTipsAudio[6].audioName;
								}
								default:
									return wordTipsAudio[7].audioName;
							}
						}
						case 4: {
							if (decodingInfo.element === 'ible') {
								return wordTipsAudio[8].audioName;
							} else {
								return wordTipsAudio[9].audioName;
							}
						}
						case 5: {
							if (decodingInfo.element === 'al') {
								return wordTipsAudio[10].audioName;
							} else {
								return wordTipsAudio[11].audioName;
							}
						}
						case 6: {
							return wordTipsAudio[12].audioName;
						}
						case 7: {
							return wordTipsAudio[13].audioName;
						}
						case 8: {
							return wordTipsAudio[14].audioName;
						}
						case 9: {
							return wordTipsAudio[15].audioName;
						}
						case 10: {
							return wordTipsAudio[16].audioName;
						}
						case 11: {
							if (decodingInfo.element === 'ous') {
								return wordTipsAudio[17].audioName;
							} else {
								return wordTipsAudio[18].audioName;
							}
						}
						case 12: {
							return wordTipsAudio[19].audioName;
						}
						case 13: {
							return wordTipsAudio[20].audioName;
						}
						case 14: {
							switch (decodingInfo.element) {
								case 'ive': {
									return wordTipsAudio[21].audioName;
								}
								case 'ative': {
									return wordTipsAudio[22].audioName;
								}
								default:
									return wordTipsAudio[23].audioName;
							}
						}
						case 15: {
							return wordTipsAudio[24].audioName;
						}
						case 16: {
							return wordTipsAudio[25].audioName;
						}
						case 17: {
							return wordTipsAudio[26].audioName;
						}
						case 18: {
							return wordTipsAudio[27].audioName;
						}
						default:
							return wordTipsAudio[4].audioName;
					}
				};
				addEnterStageScene(timeline, true);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name,
					true
				);
				addHighlightToBaseWordWithHighlightorScene(
					timeline,
					[...focusWords, ...highlightWords],
					wordPartsAudio[0].audioName
				);
				addTextSoundScene(timeline, wordTipsAudio[1].audioName, 0);
				addHighlightBaseWordTipsScene(
					timeline,
					normalWords[0],
					getElementSoundAudioName()
				);
				addBlinkForHighlighWordTipsScene(
					timeline,
					highlightWords[0],
					wordBaseChangeAudio[0].audioName
				);
				addConnectPartsScene(timeline, true);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}

			case '19': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPSFOCUS
				);

				const highlightWords = wordTips.filter(
					x => x.type === WordCardText.TIPHIGHLIGHT
				);
				const normalWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);
				switch (decodingInfo.varCode) {
					case 2: {
						const alphAudios = [...wordAlphAudio];
						const alphWords = decodingInfo.markup
							.split('>')[1]
							.split('+')
							.slice(1);
						const newAlphAudios = alphAudios.splice(
							alphWords[0].length
						);
						if (decodingInfo.baseChange === 2) {
							addEnterStageScene(timeline, true);
							addHighlightTipsScene(
								timeline,
								wordTipsAudio[0].audioName
							);
							addSeperatorWithoutHighlightTipsScene(
								timeline,
								KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND
									.name,
								true
							);
							addTextSoundScene(
								timeline,
								wordTipsAudio[1].audioName,
								0
							);
							addHighlightToBaseWordWithHighlightorScene(
								timeline,
								[...normalWords, ...highlightWords],
								wordPartsAudio[0].audioName
							);
							addTextSoundScene(
								timeline,
								wordTipsAudio[3].audioName,
								1
							);
							addHighlightElementAplhTipsScene(
								timeline,
								focusWords[0],
								alphAudios
							);
							addTextSoundScene(
								timeline,
								wordTipsAudio[6].audioName,
								2
							);
							addHighlightElementAplhTipsScene(
								timeline,
								focusWords[1],
								newAlphAudios
							);
							removeHighlightTipsScene(timeline);
							addBlinkForHighlighWordTipsScene(
								timeline,
								highlightWords[0],
								wordBaseChangeAudio[0].audioName
							);
							addConnectPartsScene(timeline, true);
							addReadWordTextScene(timeline);
							addNormalizeWordTextScene(timeline);
							addLeaveStageScene(timeline);
							break;
						} else {
							addEnterStageScene(timeline);
							addHighlightTipsScene(
								timeline,
								wordTipsAudio[0].audioName
							);
							addSeperatorWithoutHighlightTipsScene(
								timeline,
								KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND
									.name
							);
							addTextSoundScene(
								timeline,
								wordTipsAudio[1].audioName,
								0
							);
							addHighlightBaseWordTipsScene(
								timeline,
								normalWords[0],
								wordPartsAudio[0].audioName
							);
							addTextSoundScene(
								timeline,
								wordTipsAudio[3].audioName,
								1
							);
							addHighlightElementAplhTipsScene(
								timeline,
								focusWords[0],
								alphAudios
							);
							addTextSoundScene(
								timeline,
								wordTipsAudio[6].audioName,
								2
							);
							addHighlightElementAplhTipsScene(
								timeline,
								focusWords[1],
								newAlphAudios
							);
							addConnectPartsScene(timeline);
							addReadWordTextScene(timeline);
							addNormalizeWordTextScene(timeline);
							addLeaveStageScene(timeline);
							break;
						}
					}

					default: {
						const prefixWordLength = decodingInfo.markup
							.split('<')[0]
							.replace('+', '').length;
						const prefixAudios = [...wordAlphAudio].splice(
							0,
							prefixWordLength
						);
						const suffixAudios = [...wordAlphAudio].splice(
							prefixWordLength
						);
						addEnterStageScene(timeline, true);
						addHighlightTipsScene(
							timeline,
							wordTipsAudio[0].audioName
						);
						addSeperatorWithoutHighlightTipsScene(
							timeline,
							KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name,
							true
						);
						addTextSoundScene(
							timeline,
							wordTipsAudio[1].audioName,
							0
						);
						addHighlightToBaseWordWithHighlightorScene(
							timeline,
							[...normalWords, ...highlightWords],
							wordPartsAudio[0].audioName
						);
						addTextSoundScene(
							timeline,
							wordTipsAudio[2].audioName,
							1
						);
						addHighlightElementAplhTipsScene(
							timeline,
							focusWords[0],
							prefixAudios
						);
						addTextSoundScene(
							timeline,
							wordTipsAudio[4].audioName,
							2
						);
						addHighlightElementAplhTipsScene(
							timeline,
							focusWords[1],
							suffixAudios
						);
						if (decodingInfo.baseChange !== 0) {
							removeHighlightTipsScene(timeline);
							addBlinkForHighlighWordTipsScene(
								timeline,
								highlightWords[0],
								wordBaseChangeAudio[0].audioName
							);
						}
						addConnectPartsScene(timeline, true);
						addReadWordTextScene(timeline);
						addNormalizeWordTextScene(timeline);
						addLeaveStageScene(timeline);
						break;
					}
				}
				break;
			}

			case '23': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPSFOCUS
				);
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightBaseWordTipsScene(
					timeline,
					focusWords[0],
					wordPartsAudio[0].audioName
				);
				addTextSoundScene(timeline, wordTipsAudio[1].audioName, 0);
				addHighlightBaseWordTipsScene(
					timeline,
					focusWords[1],
					wordPartsAudio[1].audioName
				);
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}

			case '24': {
				const focusWords = wordTips.filter(
					x =>
						x.type === WordCardText.TIPS ||
						x.type === WordCardText.SILENT
				);
				const getElementSoundAudioName = () => {
					switch (decodingInfo.varCode) {
						case 4: {
							return wordTipsAudio[11].audioName;
						}
						case 5: {
							return wordTipsAudio[1].audioName;
						}
						case 12: {
							return wordTipsAudio[3].audioName;
						}
						case 13: {
							return wordTipsAudio[5].audioName;
						}
						case 15: {
							return wordTipsAudio[13].audioName;
						}
						case 17: {
							return wordTipsAudio[7].audioName;
						}
						case 19: {
							return wordTipsAudio[9].audioName;
						}
						default:
							return wordTipsAudio[1].audioName;
					}
				};

				const getEndingAudioName = () => {
					switch (decodingInfo.varCode) {
						case 4: {
							return wordTipsAudio[12].audioName;
						}
						case 5: {
							return wordTipsAudio[2].audioName;
						}
						case 12: {
							return wordTipsAudio[4].audioName;
						}
						case 13: {
							return wordTipsAudio[6].audioName;
						}
						case 15: {
							return wordTipsAudio[14].audioName;
						}
						case 17: {
							return wordTipsAudio[8].audioName;
						}
						case 19: {
							return wordTipsAudio[10].audioName;
						}
						default:
							return wordTipsAudio[2].audioName;
					}
				};
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				readHighlightTipsScene(
					timeline,
					getElementSoundAudioName(),
					focusWords
				);
				addTextSoundScene(timeline, getEndingAudioName(), 0);
				removeHighlightTipsScene(timeline);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightPartsScene(timeline);
				removeHighlightSilentTipsScene(timeline);
				addConnectPartsScene(timeline, false, false, true);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}

			case '25': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPSFOCUS
				);
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				readHighlightTipsScene(
					timeline,
					wordTipsAudio[1].audioName,
					focusWords
				);
				addSeperatorTipsScene(timeline, wordTipsAudio[2].audioName);
				removeHighlightTipsScene(timeline, wordTipsAudio[3].audioName);
				addHighlightPartsScene(timeline);
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}

			case '26': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPSFOCUS
				);
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				readHighlightTipsScene(
					timeline,
					wordTipsAudio[1].audioName,
					focusWords
				);
				removeHighlightTipsScene(timeline);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightPartsScene(timeline);
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}
			case '28': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				readHighlightTipsScene(
					timeline,
					wordTipsAudio[1].audioName,
					focusWords
				);
				readHighlightTipsScene(
					timeline,
					wordTipsAudio[2].audioName,
					focusWords
				);
				removeHighlightTipsScene(timeline);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightPartsScene(timeline);
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}

			case '4':
			case '5':
			case '6':
			case '7':
			case '8':
			case '9':
			case '10':
			case '11':
			case '27':
			case '29': {
				const focusWords = wordTips.filter(
					x => x.type === WordCardText.TIPS
				);
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				readHighlightTipsScene(
					timeline,
					wordTipsAudio[1].audioName,
					focusWords
				);
				removeHighlightTipsScene(timeline);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightPartsScene(timeline);
				addConnectPartsScene(timeline);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}
			case '30': {
				const focusWords = wordTips.filter(
					x =>
						x.type === WordCardText.TIPSFOCUS ||
						x.type === WordCardText.SILENT
				);
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				readHighlightTipsScene(
					timeline,
					wordTipsAudio[1].audioName,
					focusWords
				);
				addSeperatorTipsScene(timeline, wordTipsAudio[2].audioName);
				removeHighlightTipsScene(
					timeline,
					KnowledgeForReading.WORD_CARD_SFX.EXPAND_SOUND.name
				);
				addHighlightPartsScene(timeline);
				removeHighlightSilentTipsScene(timeline);
				addConnectPartsScene(timeline, false, false, true);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}
			case '31': {
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				removeHighlightTipsScene(timeline);
				addSeperatorWithoutHighlightTipsScene(
					timeline,
					wordTipsAudio[1].audioName
				);
				addHighlightPartsScene(timeline);
				removeHighlightSilentTipsScene(timeline);
				addConnectPartsScene(timeline, false, false, true);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}
			case '32': {
				addEnterStageScene(timeline);
				addHighlightTipsScene(timeline, wordTipsAudio[0].audioName);
				addReadWordTextScene(timeline);
				addNormalizeWordTextScene(timeline);
				addTextSoundScene(timeline, wordTipsAudio[1].audioName, 0);
				addHighlightWordTextScene(timeline);
				addLeaveStageScene(timeline);
				break;
			}
			default:
				break;
		}

		timelineRef.current = timeline;
	}, [
		addEnterStageScene,
		addHighlightTipsScene,
		readHighlightTipsScene,
		addSeperatorTipsScene,
		removeHighlightTipsScene,
		removeReplaceWordScene,
		addSeperatorWithoutHighlightTipsScene,
		addHighlightBaseWordTipsScene,
		addBlinkForHighlighWordTipsScene,
		addHighlightElementAplhTipsScene,
		addHighlightToBaseWordWithHighlightorScene,
		addTextSoundScene,
		addHighlightPartsScene,
		addReplaceWordScene,
		removeHighlightSilentTipsScene,
		addConnectPartsScene,
		addReadWordTextScene,
		addNormalizeWordTextScene,
		addHighlightWordTextScene,
		addLeaveStageScene
	]);

	useEffect(() => {
		if (wordTips && wordTips.length > 0) {
			init();

			if (
				preloader &&
				preloader.status === WordCardPreloaderStatus.COMPLETED
			) {
				setup();
				start();
			}
		}

		return () => {
			destroy();
		};
	}, [wordTips, preloader, destroy, init, setup, start, wordTextSound]);

	const renderWordTips = () => {
		return (
			<div className="sprites">
				<div ref={spritesRef} className={classes.spriteWrapper}>
					{wordTips &&
						wordTips.map(x => (
							<div
								className={clsx({
									[classes.normalPartsWrapper]:
										x.type === WordCardText.TIPS ||
										x.type === WordCardText.TIPSFOCUS ||
										x.type === WordCardText.TIPHIGHLIGHT ||
										x.type === WordCardText.TIPREPLACE,
									[classes.silentWrapper]:
										x.type === WordCardText.SILENT,
									[classes.plusWrapper]:
										x.type === WordCardText.SEPARATOR
								})}
								key={x.id}
								id={x.id}
							>
								<span>{x.text}</span>
							</div>
						))}
				</div>
			</div>
		);
	};

	return <div className={classes.wrapper}>{renderWordTips()}</div>;
};

WordCardTips.defaultProps = {
	onComplete: () => {},
	isTipsHighlightWithSameColor: false
};

WordCardTips.propTypes = {
	decodingInfo: PropTypes.object,
	onComplete: PropTypes.func,
	isTipsHighlightWithSameColor: PropTypes.bool
};

export default React.memo(WordCardTips);
