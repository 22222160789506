import {MEDIA_SERVER_URL} from "../../../utils/constants";
import {getAudioExt} from "../../../utils/audio";
export const EXT = getAudioExt();
const FS_MEDIA_SERVER_URL = MEDIA_SERVER_URL+'assets/fs/';
const VO_BASE = `${FS_MEDIA_SERVER_URL}/activi/wbld/fs_wbld_`;

export const VO = {
	correct1: `${VO_BASE}corratt1_1_ha.${EXT}`,
	correct2: `${VO_BASE}corratt1_2_ha.${EXT}`,
	correct3: `${VO_BASE}corratt1_3_ha.${EXT}`,
	incorrect1: `${VO_BASE}less80percatt1_1_ha.${EXT}`,
	incorrect2: `${VO_BASE}less80percatt1_2_ha.${EXT}`,
	incorrect3: `${VO_BASE}less80percatt1_3_ha.${EXT}`,
	incorrect4: `${VO_BASE}less80percatt1_4_ha.${EXT}`,
	percatt1:`${VO_BASE}80percatt1_1_ha.${EXT}`,
	percatt2:`${VO_BASE}80percatt1_2_ha.${EXT}`,
	percatt3:`${VO_BASE}80percatt1_3_ha.${EXT}`,
	percatt4:`${VO_BASE}80percatt1_4_ha.${EXT}`,
	help1: `${VO_BASE}acthelp1_ha.${EXT}`,
	help2: `${VO_BASE}acthelp2_ha.${EXT}`,
	newword_1a: `${VO_BASE}newword_1a_ha.${EXT}`,
	lintro: `${VO_BASE}lintro_ha.${EXT}`,
	sintro1:`${VO_BASE}sintro_1_ha.${EXT}`,
	sintro2:`${VO_BASE}sintro_2_ha.${EXT}`,
	sintro3:`${VO_BASE}sintro_3_ha.${EXT}`,
	sintro4:`${VO_BASE}sintro_4_ha.${EXT}`,
	sintro5:`${VO_BASE}sintro_5_ha.${EXT}`,
	changewordinto1:`${VO_BASE}changewordinto_1_ha.${EXT}`,
	changewordinto2:`${VO_BASE}changewordinto_2_ha.${EXT}`,
	acthelp1: `${VO_BASE}acthelp1_ha.${EXT}`,
	acthelp2: `${VO_BASE}acthelp2_ha.${EXT}`,


};

export const MAX_ATTEMPTS = 3;
export const MIN_DISABLED = 1;
export const PCT_DISABLED = 0.25;
export const INTRO_VIDEO = `${VO_BASE}lintro_code.mp4`;
export const MODE = {
	START:0,
	START2MAIN:0.5,
	MAIN:1,
	NEWWORD:1.5,
	REVIEW:2,
}
