/*
 * This file contains the constants needed to map the activity data in the response from the API server
 * to the content data (JSON file) on the media server.
 */
export const activityConstants = {
	knowledge_for_reading: {
		assetCode: 'kfr',
		location: 'content/wordcards',
		naming_rule: 'r180u_wordcards_{asset_id}.json'
	},
	knowledge_for_reading_tasks: {
		assetCode: 'kfr',
		location: 'content/activi/{activity_asset_code}',
		naming_rule: 'r180u_content_{activity_asset_code}_{asset_id}.json'
	},
	word_match: {
		assetCode: 'wordmatch',
		location: 'content/wordcards',
		naming_rule: 'r180u_wordcards_{asset_id}.json'
	},
	anchor_video: {
		assetCode: 'anchorvideo',
		location: '',
		naming_rule: ''
	},
	synonyms_and_antonyms: {
		assetCode: 'synant',
		location: '',
		naming_rule: ''
	},
	zone_menu: {
		assetCode: 'zonemenu',
		location: '',
		naming_rule: ''
	},
	reading_strategy_selector: {
		assetCode: 'readselect',
		location: 'content/readselect',
		naming_rule: ''
	},
	segment_selection: {
		assetCode: 'segselect',
		location: 'content/segments',
		naming_rule: 'r180u_segments_{asset_id}.json'
	},
	read_and_record: {
		assetCode: 'readrecord',
		location: '',
		naming_rule: ''
	},
	reading_passage_rz: {
		assetCode: 'readpass',
		location: '',
		naming_rule: ''
	},
	fluency_assessment_selector: {
		assetCode: 'assesslists',
		location: 'content/assesslists',
		naming_rule: 'r180u_assesslist_{asset_id}.json'
	},
	spelling_assessment: {
		assetCode: 'assesslists',
		location: 'content/assesslists',
		naming_rule: 'r180u_assesslist_{asset_id}.json'
	},
	speed_challenge: {
		assetCode: 'assesslists',
		location: 'content/assesslists',
		naming_rule: 'r180u_assesslist_{asset_id}.json'
	},
	spelling_challenge: {
		assetCode: 'assesslists',
		location: 'content/assesslists',
		naming_rule: 'r180u_assesslist_{asset_id}.json'
	},
	word_card_selector: {
		assetCode: 'wordcardselect',
		location: 'content/wordcards',
		naming_rule: 'r180u_wordcards_{asset_id}.json'
	},
	example_non_example: {
		assetCode: 'example_non_example',
		location: '',
		naming_rule: ''
	},
	word_card_selector_tasks: {
		assetCode: 'wordcardselect',
		location: 'content/wordcards',
		naming_rule: 'r180u_wordcards_{asset_id}.json'
	},
	word_family: {
		assetCode: 'wordfamily',
		location: 'content/wordcards',
		naming_rule: 'r180u_wordcards_{asset_id}.json'
	},
	words_in_context: {
		assetCode: 'wordscontext',
		location: 'content/wordcards',
		naming_rule: 'r180u_wordcards_{asset_id}.json'
	},
	writing_landing: {
		assetCode: 'writing_argument',
		location: 'assets/dev/writing',
		naming_rule: 'r180u_writing_qa_{asset_id}.json'
	},
	writing: {
		assetCode: 'writing',
		location: 'content/activi/writing',
		naming_rule: 'r180u_content_writing_{asset_id}_informative.json'
	},
	writing_informative: {
		assetCode: 'writing',
		location: 'content/activi/writing',
		naming_rule: 'r180u_content_writing_{asset_id}_informative.json'
	},
	writing_argument: {
		assetCode: 'writing',
		location: 'content/activi/writing',
		naming_rule: 'r180u_content_writing_{asset_id}_argument.json'
	},
	writing_narrative: {
		assetCode: 'writing',
		location: 'content/activi/writing',
		naming_rule: 'r180u_content_writing_{asset_id}_narrative.json'
	},
	spelling_clinic: {
		name: 'spelling_clinic',
		displayName: 'Spelling Clinic',
		assetCode: 'spellclinic',
		serverId: 'spelling_clinic',
		location: 'assets/dev/spellclinic',
		naming_rule: 'r180u_spellclinic_qa_{asset_id}.json'
	},
	proofreading: {
		assetCode: 'proofread',
		location: 'assets/dev/proofread',
		naming_rule: 'r180u_proofread_qa_{asset_id}.json'
	},
	selfcheck: {
		assetCode: 'selfcheck',
		location: 'assets/dev/selfcheck',
		naming_rule: 'r180u_selfcheck_qa_{asset_id}.json'
	},
	word_clinic: {
		name: 'wordclinic',
		displayName: 'WordClinic',
		assetCode: 'wordclinic',
		serverId: 'wordclinic',
		location: 'assets/activi/wordclinic',
		naming_rule: 'r180u_wordclinic_{asset_id}.json'
	},
	word_assessment: {
		assetCode: 'assesslists',
		serverId: 'word_assessment',
		location: 'content/assesslists',
		naming_rule: 'r180u_assesslist_{asset_id}.json'
	},
	context_passages: {
		assetCode: 'cntxtpass',
		location: 'content/activi/cntxtpass',
		naming_rule: 'r180u_cntxtpass_{asset_id}_count.ext'
	},
	discrepancy_passages: {
		assetCode: 'discpass',
		location: '',
		naming_rule: ''
	},
	final_recording: {
		assetCode: 'finalrec',
		location: '',
		naming_rule: ''
	}
};
