import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {stripHtmlTags} from '@reading/r180/src/utils/stringUtils';
import SoundButton from '../SoundButton';
import {RadioCheck} from '../..';

export const useStyles = makeStyles(theme => ({
	wrapper: {
		fontSize: '16px',
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'top',
		'& .sound-button-wrapper': {
			marginRight: '8px',
			width: '32px',
			textAlign: 'left'
		}
	},
	textWrapper: {
		fontFamily: theme.fonts.ui,
		fontSize: '16px',
		lineHeight: '24px',
		color: theme.colors.black
	},
	questionText: {
		cursor: 'pointer'
	}
}));

const ChoiceResponseQuestionItem = props => {
	const {
		id,
		className,
		onSelect,
		question,
		isSelected,
		type,
		interactive,
		correctAnswers,
		correctiveFeedback
	} = props;
	const classes = useStyles();

	const status = question.status;
	let isCorrectAnswer = false;
	const handleClick = e => {
		e.preventDefault();
		onSelect(question);
	};
	if (
		type === 'single' &&
		correctAnswers &&
		Array.isArray(correctAnswers) &&
		correctAnswers.length > 0
	) {
		if (question.id === correctAnswers[0] && correctiveFeedback) {
			isCorrectAnswer = true;
		}
	}

	return (
		<div className={`${classes.wrapper} ${className}`} id={id}>
			<RadioCheck
				id={`${id}-radio-check`}
				item={question}
				isSelected={!correctiveFeedback ? isSelected : false}
				type={type}
				status={!correctiveFeedback ? status : ''}
				onSelect={onSelect}
				showCorrectiveFeedback={isCorrectAnswer}
			/>
			<div className="sound-button-wrapper">
				<SoundButton
					border={false}
					sound={question.sound || {src: '', name: ''}}
					size={32}
					className={'transparent'}
					disabled={!interactive}
				/>
			</div>
			<div className={classes.textWrapper}>
				<span
					data-testid="question-text"
					className={classes.questionText}
					onClick={handleClick}
					dangerouslySetInnerHTML={{__html: question.text}}
				></span>
			</div>
		</div>
	);
};

ChoiceResponseQuestionItem.defaultProps = {
	id: '',
	className: '',
	question: {},
	isSelected: true,
	type: 'single',
	correctiveFeedback: false
};
ChoiceResponseQuestionItem.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	question: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	isSelected: PropTypes.bool,
	type: PropTypes.string,
	correctiveFeedback: PropTypes.bool
};

export default ChoiceResponseQuestionItem;
