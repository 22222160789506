/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

import HMHRedux from '../redux-util';
import { CLIENT_BASE_URL } from '../../utils/constants';
import { isDemoMode } from '../../utils/ui';
// slice constants
const sliceName = 'activity';

// slice actions
export const getActivityAction = HMHRedux.getOne(sliceName);

export const updateActivityAction = createAsyncThunk(`${sliceName}/update`, async (params, thunkAPI) => {
	try {
		const response = await api[sliceName].updateActivity(params.data);
		return response;
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

//////////////
// For Mock testing only - this will never get called outside localhost
//////////////
export const seedInitialDataAction = createAsyncThunk('', async (params, thunkAPI) => {
	try {
		//////////////
		// DEMO MODE
		//////////////
		if (process.env.REACT_APP_PRELOAD_MOCK_ACTIVITY_DATA || isDemoMode()) {
			const l = window.location.pathname.replace(CLIENT_BASE_URL, '').split('/');
			let activity = l[3];
			if (window.location.pathname.indexOf('/zone_menu') > -1) {
				activity = 'zone_menu';
			}
			if (window.location.pathname.indexOf('/segment_selection') > -1) {
				activity = 'segment_selection';
			}
			try {
				let data = require(`../../mock/data/${activity}.json`);
				console.log('MOCK SERVER RESPONSE', data);
				return { ...data };
			} catch (e) {
				console.error(e);
				return { id: '' };
			}
		} else {
			return { id: 'nomock' };
		}
	} catch (err) {
		return thunkAPI.rejectWithValue(err);
	}
});

// slice reducers
export const activitySlice = HMHRedux.createSlice({
	name: sliceName,
	logoutAction: 'session/logout',
	initialState: {
		bundledActivityData: {}, activityData: {}, contentData: {}, powerWordData: '', zoneReportData: '', taskData: {}
	},
	reducers: {
		setTaskData: (state, action) => {
			state.taskData = action.payload;
		},
		setPowerWordsData: (state, action) => {
			state.powerwords = action.payload
		}
	},
	extraReducers: {
		[getActivityAction.fulfilled]: (state, action) => {
			state.bundledActivityData = action.payload;
			state.activityData = action.payload.activity || action.payload.state;
			Object.keys(action.payload).forEach(key => {
				if (key !== 'activity') {
					state[key] = action.payload[key];
				}
			});
		},
		[updateActivityAction.fulfilled]: (state, action) => {
			state.bundledActivityData = action.payload;
			state.activityData = action.payload.activity || action.payload.state;
			Object.keys(action.payload).forEach(key => {
				if (key !== 'activity') {
					state[key] = action.payload[key];
				}
			});
		},
		[seedInitialDataAction.fulfilled]: (state, action) => {
			state.bundledActivityData = action.payload;
			state.activityData = action.payload.activity || action.payload.state;
			Object.keys(action.payload).forEach(key => {
				if (key !== 'activity') {
					state[key] = action.payload[key];
				}
			});
		}
	}
});

export default activitySlice.reducer;
