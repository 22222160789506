import React, {useState, useEffect, useMemo, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import media from '../../api/media';
import {bulkReplace} from '../../utils/stringUtils';
import {createAssetId, getContentInfo} from '../../media/mediaUtil';
import {useStyles} from './SZFinalRecordingFluencyCheck.style';
import {
	ReadingPassage,
	TabBar,
	ReadAloudControlBar,
	AnchorVideo,
	ActivityButton,
	SoundButton,
	QuestionType,
	ActivitySupportVideoSummaryButton,
	CaptionedAudioPlayer2 as AudioPlayer2,
	ReadingQuestionPanel
} from '@reading/common';
import RecordControlBar from '@reading/common/src/activityComponents/RecordControlBar/RecordControlBar';
import Navbar from '../../containers/App/Navbar';
import ActivitySuspense from '../../containers/App/ActivitySuspense';
import WordCard from '@reading/common/src/components/WordCard/WordCard';
import {getAudioExt} from '../../utils/audio';
import AudioRecorder from '@reading/common/src/components/AudioRecorder/AudioRecorder';
import {Modal} from '@material-ui/core';
import useIdleHelp from '../../utils/useIdleHelp';
import {SZFinalRecordingFluencyCheck} from './SZFinalRecordingFluencyCheck';
import ConfirmationModal from '@reading/common/src/components/Modals/ConfirmationModal';
import {uiSlice} from '../../store/slices/ui';
import {MIN_REC_LENGTH_SEC, MODES} from '../ReadingPassage/ReadingPassageUtils';
import {sleep} from '../../utils/sleep';
import {MEDIA_SERVER_URL} from '../../utils/constants';
import {KnowledgeForReading} from '../WordCard/KnowledgeForReading';

const EXT = getAudioExt();

const SZFinalRecordingFluencyCheckActivity = () => {
	const audioRecorder = useMemo(() => {
		const audioRecorder = new AudioRecorder();
		audioRecorder.recordingReady = duration => {
			if (duration >= SZFinalRecordingFluencyCheck.MIN_REC_LENGTH_SEC) {
				setCanPlay(true);
				AudioPlayer2.play('mandrecordhelppost');
			} else {
				AudioPlayer2.play('feedback_tooshort');
				setCanPlay(false);
			}
			setRecordingDuration(duration);
		};
		return audioRecorder;
	}, []);

	const {
		activityData: {studentActivityId, activityServerId, stage, segment, level},
		readingsettings,
		settings
	} = useSelector(state => {
		return {
			activityData: state.activity.activityData,
			readingsettings: state.activity.readingsettings,
			settings: state.session.session.settings
		};
	});
	const [passageString, setPassageString] = useState(false);
	const [activeTab, setActiveTab] = useState(SZFinalRecordingFluencyCheck.tabs.allTabs[1]);
	const [previousTab, setPreviousTab] = useState(SZFinalRecordingFluencyCheck.tabs.allTabs[0]);
	const [segmentData, setSegmentData] = useState(false);
	const [fluencyQuestions, setFluencyQuestions] = useState([]);
	const [fluencyCheckOptions, setFluencyCheckOptions] = useState([]);
	const [showRecordingTips, setShowRecordingTips] = useState(false);
	const [canPlay, setCanPlay] = useState(false);
	const [cardWord, setCardWord] = useState(false);
	const [wordCardData, setWordCardData] = useState(false);
	const [mode, setMode] = useState(SZFinalRecordingFluencyCheck.MODES.RECORD);
	const [readWords, setReadWords] = useState(false);
	const [readPhrases, setReadPhrases] = useState(false);
	const [readMode, setReadMode] = useState('word');
	const [wordDelay, setWordDelay] = useState(SZFinalRecordingFluencyCheck.WORD_DELAYS[3]);
	const [playbackProgress, setPlaybackProgress] = useState(0);
	const [readAloudComplete, setReadAloudComplete] = useState(false);
	const [showRecordingSaveModal, setShowRecordingSaveModal] = useState(false);
	const [showRecordingSubmitModal, setShowRecordingSubmitModal] = useState(false);
	const [showRecordingKillModal, setShowRecordingKillModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [isFinalRecordingFluencyCheck, setFinalRecordingFluencyCheck] = useState(false);
	const [showFluencyScoreModal, setShowFluencyScoreModal] = useState(false);
	const [totalWordsCount, setTotalWordsCount] = useState(0);
	const [recordingDuration, setRecordingDuration] = useState('0:00');
	const [video, setVideo] = useState({pause: () => {}});
	const [loaded, setLoaded] = useState(false);
	const [sendProgressFlag, setSendProgressFlag] = useState(false);
	const [startRecordCountDown, setStartRecordCountDown] = useState(false);
	const [posterSrc, setPosterSrc] = useState('');
	const [videoSrc, setVideoSrc] = useState('');
	const [videoCaptionSrc, setVideoCaptionSrc] = useState('');
	const [elSummarySrc, setElSummarySrc] = useState('');
	const activityStartTime = useRef(new Date());
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const saveDelayTimeout = useRef(0);
	const [canSave, setCanSave] = useState(false);
	const whichHelp = () => {
		if (activeTab.id === '1') {
			return SZFinalRecordingFluencyCheck.readHelpSound;
		}
		if (activeTab.id === '2') {
			return SZFinalRecordingFluencyCheck.recordHelpSound;
		}
	};

	const handleGoOn = () => {
		if (activeTab.id === '1') {
			setActiveTab({id: '2'});
			setStartRecordCountDown(false);
			return;
		}
		if (activeTab.id === '2' && mode === SZFinalRecordingFluencyCheck.MODES.RECORD && canPlay) {
			setShowRecordingSaveModal(true);
		}
	};

	const isGoOnDisabled = () => {
		const DISABLED = true;
		const ENABLED = false;
		if (activeTab.id === '1') {
			return ENABLED;
		}
		if (activeTab.id === '2') {
			if (mode === SZFinalRecordingFluencyCheck.MODES.RECORD) {
				if (!canPlay) {
					return DISABLED;
				}
				return ENABLED;
			}
			if (
				mode === SZFinalRecordingFluencyCheck.MODES.RATE ||
				mode === SZFinalRecordingFluencyCheck.MODES.FLUENCY_CHECK
			) {
				return DISABLED;
			}
		}
	};

	useIdleHelp(whichHelp());

	useEffect(() => {
		if (stage && segment) {
			setPosterSrc(
				bulkReplace(SZFinalRecordingFluencyCheck.segmentFeature, {
					asset_id: createAssetId(stage, segment, level, false)
				})
			);
			setVideoSrc(
				bulkReplace(SZFinalRecordingFluencyCheck.segmentVideo, {
					asset_id: createAssetId(stage, segment, level, false)
				})
			);
			setVideoCaptionSrc(
				bulkReplace(SZFinalRecordingFluencyCheck.segmentVideoCaptions, {
					asset_id: createAssetId(stage, segment, level, false)
				})
			);
			setElSummarySrc(
				bulkReplace(SZFinalRecordingFluencyCheck.ELSummary, {
					asset_id: createAssetId(stage, segment, level, false)
				})
			);
		}
	}, [stage, segment]);

	useEffect(() => {
		if (passageString && segmentData) {
			setLoaded(true);
		}
	}, [passageString, segmentData]);

	useEffect(() => {
		audioRecorder.progressCallback = progress => {
			if (mode === SZFinalRecordingFluencyCheck.MODES.RECORD && activeTab.id === '2' && canPlay) {
				setPlaybackProgress(progress);
			}
		};
		audioRecorder.levelsCallback = level => {
			if (mode === SZFinalRecordingFluencyCheck.MODES.RECORD && activeTab.id === '2' && !canPlay) {
				let newStyle = {};
				newStyle.borderWidth = `${3 + 8 * level}px`;
				const size = 36 + 8 * level * 2;
				newStyle.width = `${size}px`;
				newStyle.height = `${size}px`;
				newStyle.borderRadius = `${size / 2}px`;
				const recElem = document.querySelector('.record');
				const stopElem = document.querySelector('.action-button');
				if (recElem) {
					recElem.style.borderWidth = newStyle.borderWidth;
				}
				if (stopElem) {
					stopElem.style.borderWidth = newStyle.borderWidth;
					stopElem.style.width = newStyle.width;
					stopElem.style.height = newStyle.height;
					stopElem.style.borderRadius = newStyle.borderRadius;
				}

				if (audioRecorder.micRecorder.state === 'recording') {
					if (level < SZFinalRecordingFluencyCheck.NOISE_THRESHOLD) {
						if (!audioRecorder.silenceTimeout) {
							audioRecorder.silenceTimeout = setTimeout(
								silenceTimeoutHandler,
								SZFinalRecordingFluencyCheck.SILENCE_TIME
							);
						}
					} else {
						clearTimeout(audioRecorder.silenceTimeout);
						audioRecorder.silenceTimeout = null;
					}
				}
				return newStyle;
			}
		};
	}, [mode, activeTab, canPlay]);

	useEffect(() => {
		const getPassageMedia = async () => {
			try {
				const payload = await media.getOne(
					bulkReplace(SZFinalRecordingFluencyCheck.PASSAGE_TEXT, {
						asset_id: createAssetId(stage, segment, level, true)
					})
				);
				setPassageString(payload);
			} catch (e) {
				setPassageString(false);
				dispatch(uiSlice.actions.setErrorMessage(e));
			}
		};
		if (activityServerId === 'final_recording' && stage && segment && level) {
			getPassageMedia();
			SZFinalRecordingFluencyCheck.preloadSound(stage, segment, level);
		}
	}, [activityServerId, stage, segment, level]);

	useEffect(() => {
		const getContentData = async () => {
			try {
				const payload = await media.getOne(bulkReplace(SZFinalRecordingFluencyCheck.SEGMENTS, {stage}));
				const segmentData = payload.segments.find(filterSegment => filterSegment.segment_number === segment);
				setSegmentData(segmentData);
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				setSegmentData({title: ''});
			}
		};
		if (stage) {
			getContentData();
		}
	}, [stage]);

	useEffect(() => {
		AudioPlayer2.stopAll();
		const getFluencyQuestions = async () => {
			if (settings.autoPlayEnabled) {
				await sleep(2000);
				AudioPlayer2.play('mandrecord');
			}
			try {
				const payload = await media.getOne(SZFinalRecordingFluencyCheck.FLUENCY_RATING_QUESTION);
				setFluencyQuestions(payload.questions);
			} catch (e) {
				setFluencyQuestions([]);
			}
		};
		getFluencyQuestions();
	}, []);

	useEffect(() => {
		const getWordCardData = async () => {
			try {
				let wordCardData = await media.getOne(
					bulkReplace(SZFinalRecordingFluencyCheck.WORD_CARD, {word: cardWord.word})
				);
				if (!wordCardData.contextualData[0]) {
					wordCardData.contextualData[0] = {
						contextualDefinition: wordCardData.definition,
						contextualSentence: wordCardData.sentence
					};
				}

				const data = {studentActivityId, activityServerId, stage, segment, level};
				const word = cardWord.word.toLowerCase();
				const constants = {
					word: word,
					asset_id: getContentInfo(data, false).assetId
				};
				const WORD_CTXT_URL = `${MEDIA_SERVER_URL}assets/dictio/ctxt/r180u_ctxt_{word}.${EXT}`;
				const WORD_DEF_URL = `${MEDIA_SERVER_URL}assets/dictio/def/r180u_def_def-{word}.${EXT}`;
				const word_text_url = bulkReplace(KnowledgeForReading.WORD_SOUND_URL, constants);
				const word_def_url = bulkReplace(WORD_DEF_URL, constants);
				const word_ctx_url = bulkReplace(WORD_CTXT_URL, constants);
				// the pronounced word
				AudioPlayer2.load({name: word, src: word_text_url});

				// the definition
				AudioPlayer2.load({name: `def-${word}`, src: word_def_url});

				// the context
				AudioPlayer2.load({name: `ctx-${word}`, src: word_ctx_url});

				setWordCardData(wordCardData);
			} catch (e) {
				dispatch(uiSlice.actions.setErrorMessage(e));
				setWordCardData(false);
				setCardWord(false);
			}
		};
		if (cardWord && cardWord.word) {
			getWordCardData();
		}
	}, [cardWord]);

	const getTabs = () => {
		if (startRecordCountDown) {
			return SZFinalRecordingFluencyCheck.tabs.onlyRecordActive;
		}
		return SZFinalRecordingFluencyCheck.tabs.allTabs;
	};

	const silenceTimeoutHandler = () => {
		audioRecorder.stopRecord(false);
		dispatch(uiSlice.actions.cancelRecording());
	};

	const handleFluencyCheckOptionSelect = option => {
		const isOptionSelected = fluencyCheckOptions.find(item => item.uid === option.uid);
		if (isOptionSelected) {
			const newFluencyCheckOptions = fluencyCheckOptions.filter(item => item.uid !== option.uid);
			setFluencyCheckOptions(newFluencyCheckOptions);
		} else {
			setFluencyCheckOptions([...fluencyCheckOptions, option]);
		}
	};

	const handleDeleteIconClick = option => {
		const newFluencyCheckOptions = fluencyCheckOptions.filter(item => item.uid !== option.uid);
		setFluencyCheckOptions(newFluencyCheckOptions);
	};

	const renderFluencyCheck = () => {
		return (
			<ReadingQuestionPanel
				onAnswerChange={option => handleDeleteIconClick(option)}
				onSubmit={() => {
					AudioPlayer2.stopAll();
					setFinalRecordingFluencyCheck(false);
					setShowFluencyScoreModal(true);
				}}
				questionType={QuestionType.HIGHLIGHT}
				title={"Select the words that you're not sure you got right."}
				titleSound={{
					name: 'Fluency_Check_Title',
					src: ' ' // TODO : No audio exists in legacy as well as media repo but design has audio icon
				}}
				buttonText={'Go On'}
				questions={fluencyCheckOptions}
				buttonIcon="arrow-right"
				isSuccessZone={true}
			/>
		);
	};

	const renderRateRecording = () => {
		return (
			<ReadingQuestionPanel
				onAnswerChange={() => {}} //Fun note, these answers are discarded!
				onSubmit={() => {
					AudioPlayer2.stopAll();
					AudioPlayer2.play('selfcheckratingresults');
					setMode(SZFinalRecordingFluencyCheck.MODES.ATTENTION);
				}}
				questionType={QuestionType.RATE_RECORDING}
				title={'You recorded your reading. Now rate your recording.'}
				titleSound={{
					name: 'Rate_Recording_Title',
					src: ' ' // TODO : No audio exists in legacy as well as media repo but design has audio icon
				}}
				buttonText={'Go On'}
				questions={fluencyQuestions}
				buttonIcon="arrow-right"
				isSuccessZone={true}
			/>
		);
	};

	const renderFocusAttention = () => {
		return (
			<ReadingQuestionPanel
				title={'Where will you focus your effort next time you practice reading fluently.'}
				titleSound={{
					name: 'Focus_Attention_Title',
					src: SZFinalRecordingFluencyCheck.Focus_Attention_Audio
				}}
				questionType={QuestionType.FOCUS_ATTENTION}
				questions={fluencyQuestions}
				onSubmit={() => setShowRecordingSubmitModal(true)}
				onAnswerChange={() => {}}
				buttonText="Go On"
				buttonIcon="arrow-right"
				isSuccessZone={true}
			/>
		);
	};

	const handleVideoPlay = video => {
		AudioPlayer2.stopAll();
		setVideo(video);
	};

	useEffect(() => {
		if (sendProgressFlag) {
			SZFinalRecordingFluencyCheck.sendProgressToServer({
				activity: {
					studentActivityId,
					isComplete: false
				},
				readingsettings
			});
			setSendProgressFlag(false);
		}
	}, [sendProgressFlag]);

	useEffect(() => {
		if (mode === SZFinalRecordingFluencyCheck.MODES.DONE) {
			SZFinalRecordingFluencyCheck.completeActivity(
				{
					activity: {
						isComplete: true,
						studentActivityId: studentActivityId,

						startTime: activityStartTime.current.toISOString(),
						endTime: new Date().toISOString(),
						recordingDuration: recordingDuration, //TODO: update as per the API
						wrongWordsSelected: fluencyCheckOptions,
						wordsCorrect: totalWordsCount - fluencyCheckOptions.length
					},
					readingsettings
				},
				history,
				dispatch
			);
		}
	}, [mode]);

	return (
		<div className={`${classes.wrapper}`}>
			<Navbar helpSoundUrl={whichHelp()} short={true} />
			<ActivitySuspense requiredRenderData={[{loaded}]} showSpinner={!loaded} title={'Final Recording'}>
				{cardWord && cardWord.word && wordCardData && (
					<Modal
						className={`${classes.wordCardModal} r180-modal`}
						open={cardWord && cardWord.word && wordCardData}
						onClose={() => {
							setCardWord(false);
						}}
					>
						<div className={classes.wordCardModalBody} data-testid={'wordCardModal'}>
							<WordCard
								wordCardData={wordCardData}
								card={{
									progress: 0,
									maxProgress: 0,
									word_card: {
										word_card_activities: [],
										word_card_sentence: wordCardData.sentence,
										word_card_definition: wordCardData.definition,
										word_text: wordCardData.text
									}
								}}
								taskData={{video_hint: ''}}
								hideVideoTool={true}
								handleClose={() => {
									setCardWord(false);
								}}
								showExitButton={true}
							/>
						</div>
					</Modal>
				)}
				{activeTab.id === '3' && (
					<Modal className={`${classes.wordCardModal} r180-modal`} open={activeTab.id === '3'}>
						<div className={`${classes.videoModalBody} modal-window`} data-testid={'videoModal'}>
							<AnchorVideo
								id="fluencyCheckVideo"
								containerStyle={{width: '865px'}}
								hideVideoTimeDuration
								poster={posterSrc}
								autoPlay={false}
								aria-label={'Anchor video'}
								onVideoPlay={handleVideoPlay}
								ended={true}
								hideCloseButton={false}
								handleCloseButton={() => {
									setActiveTab(previousTab);
								}}
							>
								<source src={videoSrc} type={'video/mp4'} />
								<track
									src={videoCaptionSrc}
									kind={'subtitles'}
									srcLang={'en'}
									label={'English subtitles'}
								/>
							</AnchorVideo>
							<ActivitySupportVideoSummaryButton soundUrl={elSummarySrc} video={video} />
						</div>
					</Modal>
				)}
				{showRecordingTips && (
					<Modal
						open={showRecordingTips}
						onClose={() => {
							setShowRecordingTips(false);
						}}
						className={`${classes.wordCardModal} r180-modal`}
					>
						<div className={classes.recordingTipsModalBody} data-testid={'recordingTipsModal'}>
							<button
								data-testid={'tipsClose'}
								className={classes.modalX}
								onClick={() => {
									setShowRecordingTips(false);
								}}
							>
								<i className="r180 close" />
							</button>
							<div className={classes.tipsTitle}>
								<SoundButton
									sound={{name: 'recordingTips', src: 'placeholder'}}
									size={32}
									border={false}
								/>
								<span className={classes.contentRight}>As you record your reading remember to:</span>
							</div>
							<div className={classes.tipsEntry}>
								<span className={classes.tipCheck}>
									<i className="r180 check" />
								</span>
								Pronounce the words carefully.
							</div>
							<div className={classes.tipsEntry}>
								<span className={classes.tipCheck}>
									<i className="r180 check" />
								</span>
								Use punctuation like periods, commas, and exclamation points to help you read with
								expression.
							</div>
							<div className={classes.tipsEntry}>
								<span className={classes.tipCheck}>
									<i className="r180 check" />
								</span>
								Try to make your reading sound clear and natural like the modeled reading.
							</div>
							<div className={classes.tipsButton}>
								<ActivityButton
									onClick={() => {
										setShowRecordingTips(false);
										setStartRecordCountDown(true);
									}}
									text={'Go On'}
									icon={'arrow-right'}
								/>
							</div>
						</div>
					</Modal>
				)}
				{showRecordingSaveModal && (
					<ConfirmationModal
						className={classes.recordingSaveConfirmModal}
						open={showRecordingSaveModal}
						onPrimaryButtonClicked={() => {
							AudioPlayer2.stopAll();

							//		AudioPlayer2.play('selfcheckratingintro');
							AudioPlayer2.play('selfcheck');
							setMode(SZFinalRecordingFluencyCheck.MODES.FLUENCY_CHECK);
							setFinalRecordingFluencyCheck(true);
							setShowRecordingSaveModal(false);
						}}
						onSecondaryButtonClicked={() => {
							setShowRecordingSaveModal(false);
						}}
						onCloseClicked={() => {
							setShowRecordingSaveModal(false);
						}}
						title={'Are you done listening to your recording and ready to start reviewing it?'}
						primaryButtonText={'Yes'}
						primaryButtonIcon={'check'}
						secondaryButtonIcon={'close'}
						secondaryButtonText={'No'}
					/>
				)}
				{showRecordingKillModal && (
					<ConfirmationModal
						className={classes.recordingKillConfirmModal}
						open={showRecordingKillModal}
						onPrimaryButtonClicked={() => {
							setCanPlay(false);
							setMode(SZFinalRecordingFluencyCheck.MODES.RECORD);
							setShowRecordingKillModal(false);
						}}
						onSecondaryButtonClicked={() => {
							setShowRecordingKillModal(false);
						}}
						onCloseClicked={() => {
							setShowRecordingKillModal(false);
						}}
						title={'Are you sure you want to start over?'}
						subTitle={'Your recording will be deleted!'}
						showWarningIcon={true}
						primaryButtonText={'Yes'}
						primaryButtonIcon={'check'}
						secondaryButtonIcon={'close'}
						secondaryButtonText={'No'}
					/>
				)}
				{showRecordingSubmitModal && (
					<ConfirmationModal
						open={showRecordingSubmitModal}
						onPrimaryButtonClicked={async () => {
							// TODO :: save your recording here
							AudioPlayer2.stopAll();
							await AudioPlayer2.playSync('submitrecordingaccepted');
							setShowRecordingSubmitModal(false);
							setShowConfirmationModal(true);
						}}
						onSecondaryButtonClicked={async () => {
							await AudioPlayer2.playSync('submitrecordingrejected');
							setMode(SZFinalRecordingFluencyCheck.MODES.ATTENTION);
							setShowRecordingSubmitModal(false);
						}}
						onCloseClicked={async () => {
							await AudioPlayer2.playSync('submitrecordingrejected');
							setMode(SZFinalRecordingFluencyCheck.MODES.ATTENTION);
							setShowRecordingSubmitModal(false);
						}}
						title={'Do you want to submit your recording?'}
						titleSound={{
							name: 'Submit_Recording_Title',
							src: SZFinalRecordingFluencyCheck.Submit_Recording_Title_Audio
						}}
						primaryButtonText={'Yes'}
						secondaryButtonText={'No'}
						primaryButtonIcon={'check'}
						secondaryButtonIcon={'x'}
					/>
				)}

				{showConfirmationModal && (
					<ConfirmationModal
						open={showConfirmationModal}
						onPrimaryButtonClicked={() => {
							setMode(SZFinalRecordingFluencyCheck.MODES.DONE);
							AudioPlayer2.stopAll();
							setShowConfirmationModal(false);
						}}
						onCloseClicked={() => {
							setMode(SZFinalRecordingFluencyCheck.MODES.DONE);
							AudioPlayer2.stopAll();
							setShowConfirmationModal(false);
						}}
						title={'Thanks for submitting your recording!'}
						titleSound={{
							name: 'Congrats_Title',
							src: ''
						}}
						primaryButtonText={'Go On'}
						primaryButtonIcon={'arrow-right'}
						showSecondaryButton={false}
					/>
				)}

				{showFluencyScoreModal && (
					<Modal
						open={showFluencyScoreModal}
						onClose={() => {
							setShowFluencyScoreModal(false);
							setMode(SZFinalRecordingFluencyCheck.MODES.RATE);
						}}
						className={`${classes.wordCardModal} r180-modal`}
					>
						<div className={classes.fluencyCheckScoresModalBody} data-testid={'fluencyCheckScoresModal'}>
							<div className={classes.fluencyScoreHeader}></div>
							<div className={classes.fluencyCorrect}>
								<span className={classes.fluencyCheck}>
									<i className="r180 check" />
								</span>
							</div>
							<div className={classes.successTitle}>Great Job!</div>
							<div className={classes.successBody}>
								<div className={classes.successContent}>
									<div className={classes.successBodyTitle}>Recording Time:</div>
									<div className={classes.successBodyContent}>
										{`${Math.floor(recordingDuration / 60)}:${(
											'0' + Math.floor(recordingDuration % 60)
										).slice(-2)}`}{' '}
										Minutes
									</div>
								</div>
								<div className={classes.successContent}>
									<div className={classes.successBodyTitle}>Words Correct:</div>
									<div className={classes.successBodyContent}>
										{totalWordsCount - fluencyCheckOptions.length}
									</div>
								</div>
							</div>
							<div className={classes.successBodyBorder}></div>
							<div className={classes.wrongWordsContent}>
								{fluencyCheckOptions.length > 0 && (
									<>
										<div className={classes.wrongWordsHeader}>Your words marked incorrect:</div>
										<div className={classes.wrongWordsWrapper}>
											{fluencyCheckOptions.map((option, index) => (
												<div key={index} className={classes.wrongWordsText}>
													{option.text}
												</div>
											))}
										</div>
									</>
								)}
							</div>
							<div className={classes.fluencyGoOnButton}>
								<ActivityButton
									onClick={() => {
										setShowFluencyScoreModal(false);
										setMode(SZFinalRecordingFluencyCheck.MODES.RATE);
									}}
									text={'Go On'}
									icon={'arrow-right'}
								/>
							</div>
							<div className={classes.fluencyScoreFooter}></div>
						</div>
					</Modal>
				)}

				{mode === SZFinalRecordingFluencyCheck.MODES.RECORD && (
					<ActivityButton
						onClick={handleGoOn}
						text={'Go On'}
						icon={'arrow-right'}
						disabled={isGoOnDisabled()}
						className={`${classes.goOn}`}
					/>
				)}
				<div className={`${classes.clearBoth}`} />

				<div className={`${classes.contentWrapper}`}>
					<div
						className={`${classes.leftColumn} ${mode !== SZFinalRecordingFluencyCheck.MODES.RECORD &&
							mode !== SZFinalRecordingFluencyCheck.MODES.DONE &&
							classes.leftNarrow}`}
					>
						<div className={`${classes.tabsAndControls}`}>
							<TabBar
								id={'tabs'}
								tabs={getTabs()}
								onClick={tab => {
									if (tab.id === '3') {
										setPreviousTab(activeTab);
									}
									setActiveTab(tab);
								}}
								selectedTab={activeTab.id}
								isSuccessZone={true}
							/>
							{activeTab.id === '1' && (
								<ReadAloudControlBar
									onPlay={() => {
										if (readMode === 'word') {
											setReadWords(true);
										} else {
											setReadPhrases(true);
										}
									}}
									onPause={() => {
										setReadWords(false);
										setReadPhrases(false);
									}}
									onReadTypeChange={type => {
										setReadMode(type);
									}}
									onInstructions={async () => {
										AudioPlayer2.stopAll();
										if (mode === MODES.FLUENCY_CHECK) {
											AudioPlayer2.play('selfcheckhelp');
										} else {
											AudioPlayer2.play('readaloud');
										}
									}}
									onSpeedChange={speed => {
										setWordDelay(SZFinalRecordingFluencyCheck.WORD_DELAYS[speed]);
									}}
									readAloudComplete={readAloudComplete}
									setReadAloudComplete={setReadAloudComplete}
								/>
							)}
							{activeTab.id === '2' && (
								<RecordControlBar
									onInstructions={() => {
										if (mode === MODES.FLUENCY_CHECK) {
											AudioPlayer2.play('selfcheckhelp');
										} else if (!canPlay) {
											AudioPlayer2.play('record');
										} else {
											AudioPlayer2.play('postrecord');
										}
									}}
									onStartOver={() => {
										audioRecorder.stopPlay();
										setShowRecordingKillModal(true);
									}}
									isSaveDisabled={!canSave || mode !== MODES.RECORD}
									isStartOverDisabled={!canPlay}
									onSave={() => {
										setShowRecordingSaveModal(true);
									}}
									onRecord={() => {
										clearTimeout(audioRecorder.silenceTimeout);
										saveDelayTimeout.current = setTimeout(() => {
											setCanSave(true);
										}, MIN_REC_LENGTH_SEC * 1000);
										setCanSave(false);
										AudioPlayer2.stopAll();
										audioRecorder.record();
									}}
									onPause={() => {
										audioRecorder.stopRecord(true);
										audioRecorder.stopPlay();
										setStartRecordCountDown(false);
										clearTimeout(audioRecorder.silenceTimeout);
									}}
									onDone={() => {
										audioRecorder.stopRecord(false);
										audioRecorder.stopPlay();
										setStartRecordCountDown(false);
										clearTimeout(audioRecorder.silenceTimeout);
									}}
									onPlay={() => {
										AudioPlayer2.stopAll();
										audioRecorder.play();
									}}
									canPlayRecording={canPlay}
									playbackProgress={playbackProgress}
									onRecordingSkipBack={() => {
										audioRecorder.seek(SZFinalRecordingFluencyCheck.SEEKSTART);
									}}
									onRecordingRewind={() => {
										audioRecorder.seek(SZFinalRecordingFluencyCheck.SEEKBACK);
									}}
									onRecordingFastForward={() => {
										audioRecorder.seek(SZFinalRecordingFluencyCheck.SEEKFORWARD);
									}}
									onRecordingSkipForward={() => {
										audioRecorder.seek(SZFinalRecordingFluencyCheck.SEEKEND);
									}}
									onSeekPercent={seekPercent => {
										audioRecorder.seekPercent(seekPercent);
									}}
									startRecordCountDown={startRecordCountDown}
									onRecordButtonClick={() => {
										setShowRecordingTips(true);
									}}
									isReadRecord={true}
								/>
							)}
						</div>
						<div className={`${classes.passage}`}>
							<ReadingPassage
								passageString={passageString}
								title={segmentData.name}
								setCardWord={setCardWord}
								readWords={readWords}
								readPhrases={readPhrases}
								wordDelay={wordDelay}
								stage={stage}
								segment={segment}
								level={level}
								setReadAloudComplete={isComplete => {
									setReadAloudComplete(isComplete);
									if (isComplete) {
										setReadWords(false);
										setReadPhrases(false);
									}
								}}
								isFinalRecordingFluencyCheck={isFinalRecordingFluencyCheck}
								fluencyCheckOptions={fluencyCheckOptions}
								onOptionSelect={option => handleFluencyCheckOptionSelect(option)}
								onWordAdded={count => setTotalWordsCount(count)}
							/>
						</div>
					</div>
					<div className={`${classes.rightColumn}`}>
						{mode === SZFinalRecordingFluencyCheck.MODES.FLUENCY_CHECK && renderFluencyCheck()}
						{mode === SZFinalRecordingFluencyCheck.MODES.RATE && renderRateRecording()}
						{mode === SZFinalRecordingFluencyCheck.MODES.ATTENTION && renderFocusAttention()}
					</div>
				</div>
			</ActivitySuspense>
		</div>
	);
};

export default SZFinalRecordingFluencyCheckActivity;
