import api from '../../api/api';
import {transitionToNextActivity} from '../../utils/navigation';
import {getRandomInt} from '../../utils/random';
import {FoundationalConstants} from './FoundationalConstants';

export const FoundationalUtil = {
	completeActivity: async (history, saveTemplate) => {
		await transitionToNextActivity(history, {save_template: saveTemplate});
	},

	sendProgressToServer: saveTemplate => {
		api.activity.updateActivity({save_template: saveTemplate});
	},

	getRandomSound: randomSoundArray => {
		return randomSoundArray[getRandomInt(0, randomSoundArray.length - 1)];
	},

	getActivitySettings: (activityData, contentData, settings, activityName) => {
		const activityTimeLimit =
			settings && settings.assessment_timeout
				? settings.assessment_timeout
				: FoundationalConstants.QUESTION_TIME_LIMIT;
		const isFirstVisit = contentData ? contentData.intro_vo === 'lintro' : true;
		const isAccuracyOnly = settings && settings.accuracy_only === true; // TODO This field not in settings code yet, proper name is unknown
		const isAutoplay = settings && settings.autoPlayEnabled === true;
		const numVisits = activityData ? activityData.total_activity_visits + 1 : 1;
		const isActivity = activityData ? activityData.activity_id === activityName : false;

		return {
			activityTimeLimit,
			isFirstVisit,
			isAccuracyOnly,
			isAutoplay,
			numVisits,
			isActivity
		};
	}
};
