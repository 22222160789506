import { CaptionedAudioPlayer2 as AudioPlayer2 } from '@reading/common';
import { getRandomInt } from '../../../utils/random';
import { bulkReplace } from '../../../utils/stringUtils';
import { loadSleep } from '../../../utils/ui';
import { FoundationalUtil } from '../FoundationalUtil';
import { FastTrackConstants } from './FastTrackConstants';

export const FastTrackUtil = {

	initialize: async(params) => {
		const { contentData } = params;

		const start = new Date().getTime();

		// load all the word sounds in this activity
		contentData.activity_words.forEach((word) => {
			const src = bulkReplace(FastTrackConstants.WORD_PATH, {word: word.text});
			AudioPlayer2.load({name: word.text, src: src});
		});

		// load the SFX
		AudioPlayer2.loadSound(FastTrackConstants.CORRECT);
		AudioPlayer2.loadSound(FastTrackConstants.INCORRECT);
		AudioPlayer2.loadSound(FastTrackConstants.COVER_UP);
		AudioPlayer2.loadSound(FastTrackConstants.COVER_OFF);
		AudioPlayer2.loadSound(FastTrackConstants.NO_RESPONSE);

		// load the help sound
		AudioPlayer2.loadSound(FastTrackConstants.INTRO_HELP);
		AudioPlayer2.loadSound(FastTrackConstants.HELP_1);
		AudioPlayer2.loadSound(FastTrackConstants.HELP_2);
		AudioPlayer2.loadSound(FastTrackConstants.HELP_3);

		// load the intros
		FastTrackConstants.NONSENSE_LONG_INTRO.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});
		FastTrackConstants.NONSENSE_MED_INTRO.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});
		FastTrackConstants.REAL_LONG_INTRO.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});
		FastTrackConstants.REAL_MED_INTRO.forEach((sound) => {
			AudioPlayer2.loadSound(sound);
		});

		const end = new Date().getTime();
		await loadSleep(end - start);
	},

	// this activity calls for playing random intros
	getRandomizedSound: (isFirstTime, type) => {
		if (type.indexOf('lintro') > -1) {
			if (isFirstTime) {
				return FoundationalUtil.getRandomSound(FastTrackConstants.REAL_LONG_INTRO);
			}
			else {
				return FoundationalUtil.getRandomSound(FastTrackConstants.NONSENSE_LONG_INTRO);
			}
		}
		else {
			if (isFirstTime) {
				return FoundationalUtil.getRandomSound(FastTrackConstants.REAL_MED_INTRO);
			}
			else {
				return FoundationalUtil.getRandomSound(FastTrackConstants.NONSENSE_MED_INTRO);
			}
		}
	},

	getHelpSound: (numVisits) => {
		switch (numVisits)
		{
			case 1: return FastTrackConstants.HELP_1;
			case 2: return FastTrackConstants.HELP_2;
			case 3: return FastTrackConstants.HELP_3;
			default: return FastTrackConstants.HELP_1;
		}
	}
}
