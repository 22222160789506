/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import HoverTip from '../HoverTip/HoverTip';

const useStyle = makeStyles(theme => ({
	wrapper: {
		position: 'relative',
		marginRight: '36px',
		'&.wide': {
			'& .icon-text': {
				maxWidth: '160px',
				marginTop: '4px'
			}
		},
		'&:last-of-type': {
			marginRight: '0 !important'
		},
		'&:hover': {
			marginRight: '34px',
			'& .icon-button': {
				boxShadow: '0 0 4px rgba(0, 0, 0, 0.25)',
				border: `1px solid ${theme.colors.readingZone.primary}`,
				height: '44px',
				width: '44px',
				lineHeight: '40px',
				top: '-2px',
				left: '-2px',
				'& i': {
					color: theme.colors.softBlack,
					top: '3.5px'
				}
			},
			'& .icon-text': {
				fontWeight: '700',
				left: '-2px',
				marginTop: '4px'
			}
		},
		'&.active, &:active': {
			'& .icon-button': {
				boxShadow: 'none',
				border: `2px solid ${theme.colors.readingZone.primary}`,
				color: theme.colors.readingZone.primary,
				'& i': {
					color: theme.colors.readingZone.primary,
					top: '1.5px'
				}
			},
			'& .icon-text': {
				fontWeight: '700'
			},
		}
	},
	label: {
		position: 'relative',
		display: 'block',
		textAlign: 'center',
		fontFamily: theme.fonts.button,
		fontSize: '18px',
		margin: '8px 0px 0 0',
		cursor: 'pointer',
		maxWidth: '80px',
		height: '80px',
		lineHeight: '20px'
	},
	iconWrapper: {
		position: 'relative',
		display: 'block',
		width: 40,
		height: 40,
		margin: 'auto',
		border: `1px solid ${theme.colors.paleGrey}`,
		borderRadius: '50%',
		backgroundColor: theme.colors.white,
		cursor: 'pointer',
		textAlign: 'center',
		lineHeight: '40px',
		transition: 'border-color 0.4s, box-shadow 0.4s, border-width 0.1s',
		'& i': {
			color: theme.colors.grey,
			fontSize: '22px',
			position: 'relative',
			top: '1.5px'
		}
	}
}));

const WordCardCircleButton = props => {
	const classes = useStyle();
	const {label, icon, onClick, index, active, className, hoverTitle} = props;

	const handleClick = e => {
		e.preventDefault();
		e.stopPropagation();
		onClick(label.toLowerCase());
	};

	return (
		<HoverTip title={hoverTitle} placement="top">
			<button
				className={`${classes.wrapper} ${className} ${active} word-card-circle-button`}
				onClick={e => handleClick(e)}
				data-testid="circle-button"
			>
				<div
					className={`${classes.iconWrapper} icon-button ${
						active ? 'active' : ''
					}`}
				>
					<i className={`r180 ${icon}`} />
				</div>
				<div
					className={`${classes.label} icon-text`}
					dangerouslySetInnerHTML={{__html: label}}
				></div>
			</button>
		</HoverTip>
	);
};

WordCardCircleButton.defaultProps = {
	className: '',
	label: '',
	icon: '',
	index: 1,
	active: false,
	hoverTitle: ''
};

WordCardCircleButton.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	hoverTitle: PropTypes.string,
	active: PropTypes.bool,
	index: PropTypes.number,
	onClick: PropTypes.func
};

export default React.memo(WordCardCircleButton);
